import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreTrainerData = defineStore('trainer-data', () => {
  const trainerCdValue = ref('');
  const trainerNews = ref([]);
  const thisWeekSpResult = ref([]);
  const CareerStatData = ref(null);
  const TrainerData = ref(null);
  const bigPrizeData = ref(null);
  const g1RaceResultData = ref(null);
  const dataTrainerExt = ref(null);
  const managementHorses = ref([]);
  const pastManagementHorses = ref([]);
  const schedule = ref([]);
  const totalRaces = ref(0);

  return {
    trainerCdValue,
    trainerNews,
    thisWeekSpResult,
    TrainerData,
    bigPrizeData,
    CareerStatData,
    g1RaceResultData,
    dataTrainerExt,
    managementHorses,
    pastManagementHorses,
    schedule,
    totalRaces,
  }
})

export default {
  useStoreTrainerData,
}