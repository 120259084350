<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { getJSON } from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH, POGPATH } from "../../assets/js/define";

import PogHeader from "../../components/pog/PogHeader.vue";
import pogFunc from "../../functions/pog.js";
const { loadPogUserJudge } = pogFunc();

onMounted(() => {
  loadPogUserJudge(() => {
    init();
  });
});

let rankingHorses = reactive([]);
const year = ref("");
const month = ref("");

let init = () => {
  document.body.className = "";
  let paramYear, paramMonth;
  const url = window.location.href;
  if (url.indexOf("Year=") > -1 && url.indexOf("Month=") > -1) {
    paramYear = url.split("Year=")[1];
    paramMonth = url.split("Month=")[1];
    paramYear = paramYear.slice(0, 4);
    paramMonth = paramMonth.slice(0, 2);
    year.value = paramYear;
    month.value = paramMonth;
  }

  const request =
    HOST +
    PATH +
    "POG/getPogRanking.js" +
    BACKWORDPATH +
    "?ScreenDiv=3&Year=" +
    paramYear +
    "&Month=" +
    paramMonth;

  getJSON(request, true, function (_, json) {
    rankingHorses = Object.assign(rankingHorses, json.SearchResult);
    document.body.className = "status-loaded";
  });
};

const horseLink = (code) => {
  if (code) {
    return HOST + "pog/" + "horse_detail?PedigreeNum=" + code;
  } else return "";
};

// もっと見る
const showLimit = 30;
let count = ref(30);
const ListItem = () => {
  const list = [...rankingHorses];
  if (list != undefined && list != null && list.length > 0) {
    return list.slice(0, count.value);
  } else {
    return [];
  }
};
const isMore = () => {
  count.value += showLimit;
};
</script>

<template>
  <main class="layout-main">
    <PogHeader></PogHeader>

    <section class="layout-section">
      <h2 class="heading-circle -padding">POG ランキング</h2>
      <div class="nav-tab -fit3">
        <ul data-trigger>
          <li>
            <a
              class="label"
              :href="
                '/pog/yearly_ranking?ScreenDiv=1&Year=' +
                year +
                '&Month=' +
                month
              "
              >年間</a
            >
          </li>
          <li>
            <a
              class="label"
              :href="
                '/pog/monthly_ranking?ScreenDiv=2&Year=' +
                year +
                '&Month=' +
                month
              "
              >月間</a
            >
          </li>
          <li class="-current">
            <a
              class="label"
              :href="
                '/pog/horse_ranking?ScreenDiv=3&Year=' +
                year +
                '&Month=' +
                month
              "
              >人気馬</a
            >
          </li>
        </ul>
      </div>

      <div class="layout-sectionheader -default -gray">
        <h1 class="heading-section">人気馬ランキング</h1>
      </div>

      <ul class="list-line -ranking">
        <li v-for="(rankingHorse, index) in ListItem()" :key="rankingHorse">
          <a :href="horseLink(rankingHorse.Code)" class="link-cell">
            <span v-if="index === 0" class="ranking"
              ><i class="icon-ranking -no1st"></i
            ></span>
            <span v-else-if="index === 1" class="ranking"
              ><i class="icon-ranking -no2nd"></i
            ></span>
            <span v-else-if="index === 2" class="ranking"
              ><i class="icon-ranking -no3rd"></i
            ></span>
            <span v-else class="ranking"
              ><i>{{ index + 1 }}</i></span
            >
            <span v-if="String(rankingHorse.Name)" class="name"
              >{{ rankingHorse.Name }}
            </span>
            <span v-if="String(rankingHorse.Pts)" class="total"
              >{{ rankingHorse.Pts }}人</span
            >
          </a>
        </li>
      </ul>

      <div v-if="ListItem().length - count >= 0" class="nav-group">
        <span @click="isMore" class="btn-basic -more">もっと見る</span>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
