<script setup>
import { ref, reactive, onMounted, computed, toRefs } from "vue";
import {
  getJSON,
  getUrlVars,
  getUserId,
  SUCCESS,
  getUserAgent,
  calcBracketNum,
  retrieveCourse,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  isDismissDialog,
  getCourse,
  PAYINGSUGOTOKU,
  isNar,
  hasBracketExactaOdds,
  getNonMemberId,
  getMenmerCourse,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  ODDSPATH,
  TOB_WIN,
  TOB_PLACE,
  TOB_BRACKET,
  TOB_BRACKET_EXACTA,
  TOB_EXACTA,
  TOB_QUINELLA,
  TOB_WIDE,
  TOB_TRIO,
  TOB_TRIFECTA,
  METHOD_NORMAL,
  JOINPATH,
} from "../../assets/js/define";
import raceOdds from "../../functions/odds/race-odds";
import raceDeadline from "../../functions/odds/race-deadline_countdown";
import SetBetConfModal from "../../components/utils/SetBetConfModal.vue";
const selectedHorse = ref();
const horseNum = ref();
const raceDORVal = ref();
const raceTrackCdVal = ref();
const raceNumVal = ref();
const hasBracketExacta = ref();
const { TimerCount } = raceDeadline();
const { setRaceBetByArray } = raceOdds();
let deadLineJudge = ref(false);
let oddsUpdInfo = ref("");
const horseParam = ref("");
const raceCardParam = ref("");
let isWebView = ref(false);
onMounted(() => {
  isWebView.value = getUserAgent();
  callRetrieveCourse(function (courseId) {
    if ((courseId === PAYINGNONE && !isWebView.value) || courseId === PAYINGSUGOTOKU_NONE) {
      location.href = HOST + JOINPATH;
    } else {
      const params = getUrlVars();
      horseNum.value = Number(params.HorseNum);
      raceDORVal.value = params.DOR;
      raceTrackCdVal.value = params.RacetrackCd;
      raceNumVal.value = params.RaceNum;
      horseParam.value = "data/horse/?PedigreeNum=" + params.PedigreeNum;
      raceCardParam.value =
          "race/card/?DOR=" +
          params.DOR +
          "&" +
          "RacetrackCd=" +
          params.RacetrackCd +
          "&RaceNum=" +
          params.RaceNum;
      doInit(params);
    }
  });
});

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false);
  }
};

const doInit = (params) => {
  selectedHorse.value = decodeURIComponent(params.HorseName);
  //馬オッズ設定
  setHorseOdds(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum,
    params.HorseNum,
    params.EntryNum
  );
};
let marks = reactive({});
let stamp = reactive({});
let oddsFlag = ref(false);
let oddsUpInfo = ref("");

const setHorseOdds = (DOR, RacetrackCd, RaceNum, HorseNum, EntryNum) => {
  //馬オッズJSON取得
  const oddsRequest =
    HOST +
    ODDSPATH +
    "horseOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    DOR +
    "&RacetrackCd=" +
    RacetrackCd +
    "&RaceNum=" +
    RaceNum +
    "&HorseNum=" +
    HorseNum +
    "&Waku=" +
    calcBracketNum(EntryNum, HorseNum) +
    "&NarFlag=" +
    isNar();

  const userId = getMenmerCourse() === PAYINGNONE && isWebView.value ? getNonMemberId() : getUserId();
  //myMarkを取得
  const markRequest =
    HOST +
    PATH +
    "RaceInfo/getMyMarks.js" +
    BACKWORDPATH +
    "?userid=" +
    encodeURIComponent(userId) +
    "&dor=" +
    DOR +
    "&racetrackcd=" +
    RacetrackCd +
    "&racenum=" +
    RaceNum;
  document.body.className = "";
  getJSON(markRequest, true, function (status, json) {
    if (status == SUCCESS) {
      if (json != undefined && json != null) {
        marks = Object.assign(marks, json);
      }
      for (let i = 0; i < 18; i++) {
        stamp[i + 1] = { status: false, selectedNum: marks["MyNo" + (i + 1)] };
        if (marks["MyNo" + (i + 1)] == "1") {
          marks["MyNo" + (i + 1)] = "◎";
        } else if (marks["MyNo" + (i + 1)] == "2") {
          marks["MyNo" + (i + 1)] = "○";
        } else if (marks["MyNo" + i] == "3") {
          marks["MyNo" + (i + 1)] = "▲";
        } else if (marks["MyNo" + i] == "4") {
          marks["MyNo" + (i + 1)] = "△";
        } else if (marks["MyNo" + i] == "5") {
          marks["MyNo" + (i + 1)] = "☆";
        } else {
          marks["MyNo" + (i + 1)] = "";
        }
      }
      oddsFlag.value = true;
      setOddData(oddsRequest, marks, EntryNum, DOR);
    }
    document.body.className = "status-loaded";
  });
};

let WinOddsData = reactive({});
let PlaceOddsData = reactive({});
let BracketQuinellaOddsData = reactive([]);
let BracketExactaOddsData = reactive([]);
let QuinellaOddsData = reactive([]);
let ExactaOddsData = reactive([]);
let WideOddsData = reactive([]);
let TrioOddsData = reactive([]);
let TrifectaOddsData = reactive([]);
let entryNum = ref();
const setOddData = (request, marks, entryNumArg, DOR) => {
  entryNum.value = entryNumArg;
  document.body.className = "";
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      try {
        if (json != undefined && json != null) {
          //単勝
          if (json.WinOdds != null && json.WinOdds.odds != null) {
            const winData = json.WinOdds;
            oddsUpInfo.value = winData.oddsUpdInfo;
            const horseNum = winData.name.replace("Odds", "");
            let winParam = "";
            for (let i = 1; i <= 18; i++) {
              if (i == horseNum) {
                winParam += 1;
              } else {
                winParam += 0;
              }
            }
            WinOddsData = Object.assign(WinOddsData, {
              odds1: horseNum,
              name: selectedHorse.value,
              odds: winData.odds,
              value: winParam,
            });
          }
          //複勝
          if (json.PlaceOdds != null && json.PlaceOdds.minodds != null) {
            const placeData = json.PlaceOdds;
            let placeParam = 0;
            const horseNum = placeData.minname.replace("MinOdds", "");
            for (let i = 1; i <= 18; i++) {
              if (i == horseNum) {
                placeParam += 1;
              } else {
                placeParam += 0;
              }
            }
            PlaceOddsData = Object.assign(PlaceOddsData, {
              odds1: horseNum,
              minOdds: placeData.minodds,
              maxOdds: placeData.maxodds,
              value: placeParam,
            });
          }
          //枠連
          if (
            json.BracketQuinellaOdds != undefined &&
            json.BracketQuinellaOdds != null
          ) {
            let bracketQData = json.BracketQuinellaOdds;
            for (
              let bracketQI = 0;
              bracketQI < bracketQData.length;
              bracketQI++
            ) {
              if (bracketQData[bracketQI].odds == undefined) {
                continue;
              }
              let bracketQOdds = bracketQData[bracketQI].name
                .replace("Odds", "")
                .split("_");
              let bracketQParamL = "";
              let bracketQParamR = "";
              for (let bracketQJ = 1; bracketQJ <= 18; bracketQJ++) {
                if (bracketQJ == bracketQOdds[0]) {
                  bracketQParamL += 1;
                } else {
                  bracketQParamL += 0;
                }
                if (bracketQJ == bracketQOdds[1]) {
                  bracketQParamR += 1;
                } else {
                  bracketQParamR += 0;
                }
              }
              BracketQuinellaOddsData.push({
                odds1: bracketQOdds[0],
                odds2: bracketQOdds[1],
                odds: bracketQData[bracketQI].odds,
                value: bracketQParamL + bracketQParamR,
              });
              BracketQuinellaOddsCheckList[bracketQI] = "";
              checkAllJudge[0] = false;
            }
          }
          //枠単
          if (
            json.BracketExactaOdds != undefined &&
            json.BracketExactaOdds != null
          ) {
            const params = getUrlVars()
            hasBracketExacta.value = hasBracketExactaOdds(params.RacetrackCd);
            let bracketEData = json.BracketExactaOdds;
            for (
              let bracketEI = 0;
              bracketEI < bracketEData.length;
              bracketEI++
            ) {
              if (bracketEData[bracketEI].odds == undefined) {
                continue;
              }
              let bracketEOdds = bracketEData[bracketEI].name
                .replace("Odds", "")
                .split("_");
              let bracketEParamL = "";
              let bracketEParamR = "";
              for (let bracketEJ = 1; bracketEJ <= 18; bracketEJ++) {
                if (bracketEJ == bracketEOdds[0]) {
                  bracketEParamL += 1;
                } else {
                  bracketEParamL += 0;
                }
                if (bracketEJ == bracketEOdds[1]) {
                  bracketEParamR += 1;
                } else {
                  bracketEParamR += 0;
                }
              }
              BracketExactaOddsData.push({
                odds1: bracketEOdds[0],
                odds2: bracketEOdds[1],
                odds: bracketEData[bracketEI].odds,
                value: bracketEParamL + bracketEParamR,
              });
              BracketExactaOddsCheckList[bracketEI] = "";
              checkAllJudge[0] = false;
            }
          }
          //馬連
          if (json.QuinellaOdds != undefined && json.QuinellaOdds != null) {
            let quinellaData = json.QuinellaOdds;

            for (
              let quinellaI = 0;
              quinellaI < quinellaData.length;
              quinellaI++
            ) {
              if (quinellaData[quinellaI].odds == undefined) {
                continue;
              }
              const quinellaOdds = quinellaData[quinellaI].name
                .replace("Odds", "")
                .split("_");
              let quinellaParamL = "";
              let quinellaParamR = "";
              for (let quinellaJ = 1; quinellaJ <= 18; quinellaJ++) {
                if (quinellaJ == quinellaOdds[0]) {
                  quinellaParamL += 1;
                } else {
                  quinellaParamL += 0;
                }
                if (quinellaJ == quinellaOdds[1]) {
                  quinellaParamR += 1;
                } else {
                  quinellaParamR += 0;
                }
              }
              QuinellaOddsData.push({
                odds1: quinellaOdds[0],
                odds2: quinellaOdds[1],
                odds: quinellaData[quinellaI].odds,
                value: quinellaParamL + quinellaParamR,
              });
              QuinellaOddsCheckList[quinellaI] = "";
              checkAllJudge[1] = false;
            }
          }
          //馬単
          if (json.ExactaOdds != undefined && json.ExactaOdds != null) {
            let exactaData = json.ExactaOdds;
            for (let extraI = 0; extraI < exactaData.length; extraI++) {
              if (exactaData[extraI].odds == undefined) {
                continue;
              }
              const extraOdds = exactaData[extraI].name
                .replace("Odds", "")
                .split("_");

              let extraParamL = "";
              let extraParamR = "";

              for (let extraJ = 1; extraJ <= 18; extraJ++) {
                if (extraJ == extraOdds[0]) {
                  extraParamL += 1;
                } else {
                  extraParamL += 0;
                }
                if (extraJ == extraOdds[1]) {
                  extraParamR += 1;
                } else {
                  extraParamR += 0;
                }
              }

              ExactaOddsData.push({
                odds1: extraOdds[0],
                odds2: extraOdds[1],
                odds: exactaData[extraI].odds,
                value: extraParamL + extraParamR,
              });
              ExactaOddsCheckList[extraI] = "";
              checkAllJudge[2] = false;
            }
          }
          //ワイド
          if (json.WideOdds != undefined && json.WideOdds != null) {
            let wideData = json.WideOdds;
            for (let wideI = 0; wideI < wideData.length; wideI++) {
              if (
                wideData[wideI].minodds == undefined ||
                wideData[wideI].maxodds == undefined
              ) {
                continue;
              }
              const wideOdds = wideData[wideI].minname
                .replace("MinOdds", "")
                .split("_");
              let wideParamL = "";
              let wideParamR = "";
              for (let wideJ = 1; wideJ <= 18; wideJ++) {
                if (wideJ == wideOdds[0]) {
                  wideParamL += 1;
                } else {
                  wideParamL += 0;
                }
                if (wideJ == wideOdds[1]) {
                  wideParamR += 1;
                } else {
                  wideParamR += 0;
                }
              }
              WideOddsData.push({
                odds1: wideOdds[0],
                odds2: wideOdds[1],
                minOdds: wideData[wideI].minodds,
                maxOdds: wideData[wideI].maxodds,
                value: wideParamL + wideParamR,
              });
              WideOddsCheckList[wideI] = "";
              checkAllJudge[4] = false;
            }
          }
          //三連複
          if (json.TrioOdds != undefined && json.TrioOdds != null) {
            let trioData = json.TrioOdds;

            for (let trioI = 0; trioI < trioData.length; trioI++) {
              if (trioData[trioI].odds == undefined) {
                continue;
              }
              const trioOdds = trioData[trioI].name
                .replace("Odds", "")
                .split("_");
              let trioParamL = "";
              let trioParamC = "";
              let trioParamR = "";

              for (let trioJ = 1; trioJ <= 18; trioJ++) {
                if (trioJ == trioOdds[0]) {
                  trioParamL += 1;
                } else {
                  trioParamL += 0;
                }
                if (trioJ == trioOdds[1]) {
                  trioParamC += 1;
                } else {
                  trioParamC += 0;
                }
                if (trioJ == trioOdds[2]) {
                  trioParamR += 1;
                } else {
                  trioParamR += 0;
                }
              }
              TrioOddsData.push({
                odds1: trioOdds[0],
                odds2: trioOdds[1],
                odds3: trioOdds[2],
                odds: trioData[trioI].odds,
                value: trioParamL + trioParamC + trioParamR,
              });
              TrioOddsCheckList[trioI] = "";
              checkAllJudge[5] = false;
            }
          }
          if (json.TrifectaOdds != undefined && json.TrifectaOdds != null) {
            let trifectaData = json.TrifectaOdds;

            for (
              let trifectaI = 0;
              trifectaI < trifectaData.length;
              trifectaI++
            ) {
              if (trifectaData[trifectaI].odds == undefined) {
                continue;
              }
              const trifectaOdds = trifectaData[trifectaI].name
                .replace("Odds", "")
                .split("_");
              let trifectaParamL = "";
              let trifectaParamC = "";
              let trifectaParamR = "";

              for (let trifectaJ = 1; trifectaJ <= 18; trifectaJ++) {
                if (trifectaJ == trifectaOdds[0]) {
                  trifectaParamL += 1;
                } else {
                  trifectaParamL += 0;
                }
                if (trifectaJ == trifectaOdds[1]) {
                  trifectaParamC += 1;
                } else {
                  trifectaParamC += 0;
                }
                if (trifectaJ == trifectaOdds[2]) {
                  trifectaParamR += 1;
                } else {
                  trifectaParamR += 0;
                }
              }
              TrifectaOddsData.push({
                odds1: trifectaOdds[0],
                odds2: trifectaOdds[1],
                odds3: trifectaOdds[2],
                odds: trifectaData[trifectaI].odds,
                value: trifectaParamL + trifectaParamC + trifectaParamR,
              });
              TrifectaOddsCheckList[trifectaI] = "";
              checkAllJudge[6] = false;
            }
          }
          const startTime = json.StartTime ? json.StartTime.replace(':', '') : ''
          deadLineJudge.value = TimerCount(DOR, startTime);
        }
      } catch (e) {
        alert("オッズのデータ表示に失敗しました。");
      }
      document.body.className = "status-loaded";
    }
  });
};

//チェックリスト関連
let WinOddsCheckBox = ref(false);
let PlaceOddsCheckBox = ref(false);
let BracketQuinellaOddsCheckList = reactive([]);
let BracketExactaOddsCheckList = reactive([]);
let QuinellaOddsCheckList = reactive([]);
let ExactaOddsCheckList = reactive([]);
let WideOddsCheckList = reactive([]);
let TrioOddsCheckList = reactive([]);
let TrifectaOddsCheckList = reactive([]);
let checkedSum = ref(0);
let checkAllJudgeWin = ref(false);
let checkAllJudgePlace = ref(false);
let checkAllJudge = reactive([]);

const checkJudge = (type) => {
  if (type == "win") {
    if (checkAllJudgeWin.value == true) {
      checkAllJudgeWin.value = false;
      WinOddsCheckBox.value = false;
    } else {
      checkAllJudgeWin.value = true;
      WinOddsCheckBox.value = true;
    }
  } else {
    if (checkAllJudgePlace.value == true) {
      checkAllJudgePlace.value = false;
      PlaceOddsCheckBox.value = false;
    } else {
      checkAllJudgePlace.value = true;
      PlaceOddsCheckBox.value = true;
    }
  }
};
const checkedAll = (type) => {
  let arr = [];
  if (type == 0) {
    arr = BracketQuinellaOddsCheckList;
  } else if (type == 1) {
    arr = QuinellaOddsCheckList;
  } else if (type == 2) {
    arr = ExactaOddsCheckList;
  } else if (type == 3) {
    arr = WideOddsCheckList;
  } else if (type == 4) {
    arr = TrioOddsCheckList;
  } else if (type == 5) {
    arr = TrifectaOddsCheckList;
  } else if (type == 6) {
    arr = BracketExactaOddsCheckList;
  }
  arr[1] = true;

  if (checkAllJudge[type] == true) {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = false;
    }
    checkAllJudge[type] = false;
  } else {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = true;
    }
    checkAllJudge[type] = true;
  }
};
const checkListSum = () => {
  let sum = 0;
  if (WinOddsCheckBox.value) sum++;
  if (PlaceOddsCheckBox.value) sum++;
  BracketQuinellaOddsCheckList.forEach((el) => {
    if (el) sum++;
  });
  BracketExactaOddsCheckList.forEach((el) => {
    if (el) sum++;
  });
  QuinellaOddsCheckList.forEach((el) => {
    if (el) sum++;
  });
  ExactaOddsCheckList.forEach((el) => {
    if (el) sum++;
  });
  WideOddsCheckList.forEach((el) => {
    if (el) sum++;
  });
  TrioOddsCheckList.forEach((el) => {
    if (el) sum++;
  });
  TrifectaOddsCheckList.forEach((el) => {
    if (el) sum++;
  });
  return sum;
};
// my印関連
const stampClass = (num) => {
  if (stamp[num]) {
    if (stamp[num].selectedNum == 0) {
      return "stamp-mark -none";
    } else if (stamp[num].selectedNum == 1) {
      return "stamp-mark -honmei";
    } else if (stamp[num].selectedNum == 2) {
      return "stamp-mark -taikou";
    } else if (stamp[num].selectedNum == 3) {
      return "stamp-mark -tanana";
    } else if (stamp[num].selectedNum == 4) {
      return "stamp-mark -renka";
    } else if (stamp[num].selectedNum == 5) {
      return "stamp-mark -hoshi";
    } else return "stamp-mark -none";
  } else {
    return "stamp-mark -none";
  }
};

const calcDigit = (num) => {
  num = String(num);
  if (num.length == 3) {
    return "-no1";
  } else if (num.length == 4) {
    return "-no2";
  } else if (num.length == 5) {
    return "-no3";
  } else {
    return "";
  }
};
// 買い目登録

let modalBetOpen = ref(false);
const setRaceBet = async () => {
  let setRaceBetParams = getUrlVars();
  setRaceBetParams.racetrackCd = setRaceBetParams.RacetrackCd;
  setRaceBetParams.raceNum = setRaceBetParams.RaceNum;

  //単勝
  let winBetArray = [];
  let winDoFlgArray = [];
  if (WinOddsCheckBox.value) {
    winBetArray.push(WinOddsData.value);
    winDoFlgArray.push(true);
  }
  //複勝
  let placeBetArray = [];
  let placeDoFlgArray = [];
  if (PlaceOddsCheckBox.value) {
    placeBetArray.push(WinOddsData.value);
    placeDoFlgArray.push(true);
  }
  //枠連
  let bracketQBetArray = new Array();
  let bracketQDoFlgArray = new Array();
  BracketQuinellaOddsCheckList.forEach((el, index) => {
    if (el) {
      bracketQBetArray.push(BracketQuinellaOddsData[index].value);
      bracketQDoFlgArray.push(true);
    }
  });
  //枠単
  let bracketEBetArray = new Array();
  let bracketEDoFlgArray = new Array();
  BracketExactaOddsCheckList.forEach((el, index) => {
    if (el) {
      bracketEBetArray.push(BracketExactaOddsData[index].value);
      bracketEDoFlgArray.push(true);
    }
  });

  //馬連
  let quinellaBetArray = new Array();
  let quinellaDoFlgArray = new Array();
  QuinellaOddsCheckList.forEach((el, index) => {
    if (el) {
      quinellaBetArray.push(QuinellaOddsData[index].value);
      quinellaDoFlgArray.push(true);
    }
  });

  //馬単
  let extraBetArray = new Array();
  let extraDoFlgArray = new Array();
  ExactaOddsCheckList.forEach((el, index) => {
    if (el) {
      extraBetArray.push(ExactaOddsData[index].value);
      extraDoFlgArray.push(true);
    }
  });
  //ワイド
  let wideBetArray = new Array();
  let wideDoFlgArray = new Array();
  WideOddsCheckList.forEach((el, index) => {
    if (el) {
      wideBetArray.push(WideOddsData[index].value);
      wideDoFlgArray.push(true);
    }
  });
  //三連複
  let trioBetArray = new Array();
  let trioDoFlgArray = new Array();
  TrioOddsCheckList.forEach((el, index) => {
    if (el) {
      trioBetArray.push(TrioOddsData[index].value);
      trioDoFlgArray.push(true);
    }
  });

  //三連単
  let trifectaBetArray = new Array();
  let trifectaDoFlgArray = new Array();
  TrifectaOddsCheckList.forEach((el, index) => {
    if (el) {
      trifectaBetArray.push(TrifectaOddsData[index].value);
      trifectaDoFlgArray.push(true);
    }
  });

  //順番に登録させるため再帰的に登録
  if (checkListSum()) {
    await setRaceBetPromise(
        setRaceBetParams,
        TOB_WIN,
        METHOD_NORMAL,
        winBetArray,
        winDoFlgArray
    );
    await setRaceBetPromise(
        setRaceBetParams,
        TOB_PLACE,
        METHOD_NORMAL,
        placeBetArray,
        placeDoFlgArray,
    );
    await setRaceBetPromise(
        setRaceBetParams,
        TOB_BRACKET,
        METHOD_NORMAL,
        bracketQBetArray,
        bracketQDoFlgArray,
    );
    await setRaceBetPromise(
        setRaceBetParams,
        TOB_BRACKET_EXACTA,
        METHOD_NORMAL,
        bracketEBetArray,
        bracketEDoFlgArray,
    );
    await setRaceBetPromise(
        setRaceBetParams,
        TOB_EXACTA,
        METHOD_NORMAL,
        extraBetArray,
        extraDoFlgArray,
    );
    await setRaceBetPromise(
        setRaceBetParams,
        TOB_QUINELLA,
        METHOD_NORMAL,
        quinellaBetArray,
        quinellaDoFlgArray,
    );
    await setRaceBetPromise(
        setRaceBetParams,
        TOB_WIDE,
        METHOD_NORMAL,
        wideBetArray,
        wideDoFlgArray,
    );
    await setRaceBetPromise(
        setRaceBetParams,
        TOB_TRIO,
        METHOD_NORMAL,
        trioBetArray,
        trioDoFlgArray,
    );
    await setRaceBetPromise(
        setRaceBetParams,
        TOB_TRIFECTA,
        METHOD_NORMAL,
        trifectaBetArray,
        trifectaDoFlgArray,
    );
    if (isDismissDialog() != 1) {
      modalBetOpen.value = true;
    } else {
      let url = HOST + "purchase";
      if (
          getCourse() == PAYINGSUGOTOKU ||
          getCourse() == PAYINGSUGOTOKU_NONE
      ) {
        url = location.pathname + "?url=" + HOST + "purchase";
      }
      location.href = url;
    }
  } else {
    alert("買い目をチェックしてください");
  }
};

const setRaceBetPromise = async (betParams, TOB, method, betArray, doFlgArray) => {
  return new Promise( (resolve, reject) => {
    if (betArray.length) {
      setRaceBetByArray(
          betParams,
          TOB,
          method,
          betArray,
          doFlgArray,
          function () {
            resolve();
          }
      );
    } else {
      resolve();
    }
  })
}
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">馬オッズ</h1>
      <a :href="raceCardParam" class="btn-basic">出馬表へ戻る</a>
    </section>

    <div class="container-horseodds">
      <div class="layout-title">
        <h2>
          <i
            :class="[
              calcBracketNum(entryNum, horseNum) < 10
                ? `icon-umaban -waku0${calcBracketNum(entryNum, horseNum)}`
                : `icon-umaban -waku${calcBracketNum(entryNum, horseNum)}`,
            ]"
            >{{ horseNum }}</i
          ><a :href="horseParam">{{ selectedHorse }}</a>
        </h2>
        <div class="nav">
          <span v-if="deadLineJudge" class="status">締め切り間近</span>
          <span :class="[oddsUpInfo != null ? 'icon-kakutei' : '']">{{
            oddsUpInfo
          }}</span>
        </div>
      </div>
    </div>

    <section class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">単勝</h2>
      </div>
      <table class="table-grid -fit -middle -center -odds">
        <thead>
          <tr>
            <th class="mystamp">my印</th>
            <th>馬番</th>
            <th>馬名</th>
            <th>オッズ</th>
            <th class="ranking">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="checkJudge('win')"
                    v-model="checkAllJudgeWin"
                    value=""
                    name="" /><i></i></label
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="mystamp -unselectable">
              <span
                v-if="oddsFlag"
                :class="
                  PlaceOddsData.odds1
                    ? stampClass(Number(WinOddsData.odds1))
                    : ''
                "
              ></span>
            </td>
            <td>
              <i
                :class="[
                  calcBracketNum(entryNum, WinOddsData.odds1) < 10
                    ? `icon-umaban -waku0${calcBracketNum(
                        entryNum,
                        WinOddsData.odds1
                      )}`
                    : `icon-umaban -waku${calcBracketNum(
                        entryNum,
                        WinOddsData.odds1
                      )}`,
                ]"
                >{{ WinOddsData.odds1 }}</i
              >
            </td>
            <td class="name">{{ selectedHorse }}</td>
            <td class="-right">
              <div :class="[`txt-odds ${calcDigit(WinOddsData.odds)}`]">
                {{ WinOddsData.odds }}
              </div>
            </td>
            <td>
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    value=""
                    v-model="WinOddsCheckBox"
                    name="" /><i></i></label
              ></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="layout-title">
        <h2 class="heading-circle -padding">複勝</h2>
      </div>
      <table class="table-grid -fit -middle -center -odds">
        <thead>
          <tr>
            <th class="mystamp">my印</th>
            <th>馬番</th>
            <th>馬名</th>
            <th>オッズ</th>
            <th class="ranking">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="checkJudge('place')"
                    v-model="checkAllJudgePlace"
                    value=""
                    name="" /><i></i></label
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="mystamp -unselectable">
              <span
                v-if="oddsFlag"
                :class="
                  PlaceOddsData.odds1
                    ? stampClass(Number(PlaceOddsData.odds1))
                    : ''
                "
              ></span>
            </td>
            <td>
              <i
                :class="[
                  calcBracketNum(entryNum, PlaceOddsData.odds1) < 10
                    ? `icon-umaban -waku0${calcBracketNum(
                        entryNum,
                        PlaceOddsData.odds1
                      )}`
                    : `icon-umaban -waku${calcBracketNum(
                        entryNum,
                        PlaceOddsData.odds1
                      )}`,
                ]"
                >{{ PlaceOddsData.odds1 }}</i
              >
            </td>
            <td class="name">{{ selectedHorse }}</td>
            <td>
              <div class="container-odds">
                <span
                  :class="[`txt-odds ${calcDigit(PlaceOddsData.minOdds)}`]"
                  >{{ PlaceOddsData.minOdds }}</span
                >
                <i class="connect"></i>
                <span
                  :class="[`txt-odds ${calcDigit(PlaceOddsData.maxOdds)}`]"
                  >{{ PlaceOddsData.maxOdds }}</span
                >
              </div>
            </td>
            <td>
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    value=""
                    v-model="PlaceOddsCheckBox"
                    name="" /><i></i></label
              ></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="layout-title">
        <h2 class="heading-circle -padding">枠連</h2>
      </div>
      <table class="table-grid -fit -middle -center -odds">
        <thead>
          <tr>
            <th>組み合わせ</th>
            <th>オッズ</th>
            <th class="ranking">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="checkedAll(0)"
                    v-model="checkAllJudge[0]"
                    value=""
                    name="" /><i></i></label
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(BracketQuinellaOdds, index) in BracketQuinellaOddsData"
            :key="BracketQuinellaOdds"
          >
            <td>
              <ul class="list-kaimoku">
                <li>
                  <i
                    :class="'icon-umaban -waku0' + BracketQuinellaOdds.odds1"
                    >{{ BracketQuinellaOdds.odds1 }}</i
                  >
                </li>
                <li>
                  <i
                      :class="'icon-umaban -waku0' + BracketQuinellaOdds.odds2"
                    >{{ BracketQuinellaOdds.odds2 }}</i
                  >
                </li>
              </ul>
            </td>
            <td class="-right">
              <div :class="[`txt-odds ${calcDigit(BracketQuinellaOdds.odds)}`]">
                {{ BracketQuinellaOdds.odds }}
              </div>
            </td>
            <td>
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    value="BracketQuinellaOdds.odds"
                    name=""
                    v-model="
                      BracketQuinellaOddsCheckList[index]
                    " /><i></i></label
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="hasBracketExacta" class="layout-title">
        <h2 class="heading-circle -padding">枠単</h2>
        <div class="nav">
          <p class="_caption">人気順10件を表示</p>
        </div>
      </div>

      <table v-if="hasBracketExacta" class="table-grid -fit -middle -center -odds">
        <thead>
        <tr>
          <th>組み合わせ</th>
          <th>オッズ</th>
          <th class="ranking">
              <span class="form-checkbox"
              ><label
              ><input
                  type="checkbox"
                  @click="checkedAll(6)"
                  v-model="checkAllJudge[6]"
                  value=""
                  name="" /><i></i></label
              ></span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(BracketExactaOdds, index) in BracketExactaOddsData"
            :key="BracketExactaOdds"
        >
          <td>
            <ul class="list-kaimoku -arrow">
              <li>
                <i
                    :class="'icon-umaban -waku0' + BracketExactaOdds.odds1"
                >{{ BracketExactaOdds.odds1 }}</i
                >
              </li>
              <li>
                <i
                    :class="'icon-umaban -waku0' + BracketExactaOdds.odds2"
                >{{ BracketExactaOdds.odds2 }}</i
                >
              </li>
            </ul>
          </td>
          <td class="-right">
            <div :class="[`txt-odds ${calcDigit(BracketExactaOdds.odds)}`]">
              {{ BracketExactaOdds.odds }}
            </div>
          </td>
          <td>
              <span class="form-checkbox"
              ><label
              ><input
                  type="checkbox"
                  value="BracketExactaOdds.odds"
                  name=""
                  v-model="
                      BracketExactaOddsCheckList[index]
                    " /><i></i></label
              ></span>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="layout-title">
        <h2 class="heading-circle -padding">馬連</h2>
        <div class="nav">
          <p class="_caption">人気順10件を表示</p>
        </div>
      </div>
      <table class="table-grid -fit -middle -center -odds">
        <thead>
          <tr>
            <th class="mystamp">my印</th>
            <th>組み合わせ</th>
            <th>オッズ</th>
            <th class="ranking">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="checkedAll(1)"
                    v-model="checkAllJudge[1]"
                    value=""
                    name="" /><i></i></label
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(QuinellaOdds, index) in QuinellaOddsData"
            :key="QuinellaOdds"
          >
            <td class="mystamp">
              <ul class="list-kaimoku">
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      QuinellaOdds.odds1
                        ? stampClass(Number(QuinellaOdds.odds1))
                        : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      QuinellaOdds.odds2
                        ? stampClass(Number(QuinellaOdds.odds2))
                        : ''
                    "
                  ></span>
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-kaimoku">
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, QuinellaOdds.odds1) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            QuinellaOdds.odds1
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            QuinellaOdds.odds1
                          )}`,
                    ]"
                    >{{ QuinellaOdds.odds1 }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, QuinellaOdds.odds2) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            QuinellaOdds.odds2
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            QuinellaOdds.odds2
                          )}`,
                    ]"
                    >{{ QuinellaOdds.odds2 }}</i
                  >
                </li>
              </ul>
            </td>
            <td class="-right">
              <div :class="[`txt-odds ${calcDigit(QuinellaOdds.odds)}`]">
                {{ QuinellaOdds.odds }}
              </div>
            </td>
            <td>
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    v-model="QuinellaOddsCheckList[index]"
                    value=""
                    name="" /><i></i></label
              ></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="layout-title">
        <h2 class="heading-circle -padding">馬単</h2>
        <div class="nav">
          <p class="_caption">人気順10件を表示</p>
        </div>
      </div>
      <table class="table-grid -fit -middle -center -odds">
        <thead>
          <tr>
            <th class="mystamp">my印</th>
            <th>組み合わせ</th>
            <th>オッズ</th>
            <th class="ranking">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="checkedAll(2)"
                    v-model="checkAllJudge[2]"
                    value=""
                    name="" /><i></i></label
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ExactaOdds, index) in ExactaOddsData" :key="ExactaOdds">
            <td class="mystamp">
              <ul class="list-kaimoku -arrow">
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      ExactaOdds.odds1
                        ? stampClass(Number(ExactaOdds.odds1))
                        : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      ExactaOdds.odds2
                        ? stampClass(Number(ExactaOdds.odds2))
                        : ''
                    "
                  ></span>
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-kaimoku -arrow">
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, ExactaOdds.odds1) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            ExactaOdds.odds1
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            ExactaOdds.odds1
                          )}`,
                    ]"
                    >{{ ExactaOdds.odds1 }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, ExactaOdds.odds2) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            ExactaOdds.odds2
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            ExactaOdds.odds2
                          )}`,
                    ]"
                    >{{ ExactaOdds.odds2 }}</i
                  >
                </li>
              </ul>
            </td>
            <td class="-right">
              <div :class="[`txt-odds ${calcDigit(ExactaOdds.odds)}`]">
                {{ ExactaOdds.odds }}
              </div>
            </td>
            <td>
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    v-model="ExactaOddsCheckList[index]"
                    value=""
                    name="" /><i></i></label
              ></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="layout-title">
        <h2 class="heading-circle -padding">ワイド</h2>
        <div class="nav">
          <p class="_caption">人気順10件を表示</p>
        </div>
      </div>
      <table class="table-grid -fit -middle -center -odds">
        <thead>
          <tr>
            <th class="mystamp">my印</th>
            <th>組み合わせ</th>
            <th>オッズ</th>
            <th class="ranking">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="checkedAll(3)"
                    v-model="checkAllJudge[3]"
                    value=""
                    name="" /><i></i></label
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(WideOdds, index) in WideOddsData" :key="WideOdds">
            <td class="mystamp">
              <ul class="list-kaimoku">
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      WideOdds.odds1 ? stampClass(Number(WideOdds.odds1)) : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      WideOdds.odds2 ? stampClass(Number(WideOdds.odds2)) : ''
                    "
                  ></span>
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-kaimoku">
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, WideOdds.odds1) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            WideOdds.odds1
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            WideOdds.odds1
                          )}`,
                    ]"
                    >{{ WideOdds.odds1 }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, WideOdds.odds2) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            WideOdds.odds2
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            WideOdds.odds2
                          )}`,
                    ]"
                    >{{ WideOdds.odds2 }}</i
                  >
                </li>
              </ul>
            </td>
            <td>
              <div class="container-odds">
                <span :class="[`txt-odds ${calcDigit(WideOdds.minOdds)}`]">{{
                  WideOdds.minOdds
                }}</span>
                <i class="connect"></i>
                <span :class="[`txt-odds ${calcDigit(WideOdds.maxOdds)}`]">{{
                  WideOdds.maxOdds
                }}</span>
              </div>
            </td>
            <td>
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    v-model="WideOddsCheckList[index]"
                    value=""
                    name="" /><i></i></label
              ></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="layout-title">
        <h2 class="heading-circle -padding">3連複</h2>
        <div class="nav">
          <p class="_caption">人気順10件を表示</p>
        </div>
      </div>
      <table class="table-grid -fit -middle -center -odds">
        <thead>
          <tr>
            <th class="mystamp">my印</th>
            <th>組み合わせ</th>
            <th>オッズ</th>
            <th class="ranking">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="checkedAll(4)"
                    v-model="checkAllJudge[4]"
                    value=""
                    name="sample1" /><i></i></label
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(TrioOdds, index) in TrioOddsData" :key="TrioOdds">
            <td>
              <ul class="list-kaimoku">
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      TrioOdds.odds1 ? stampClass(Number(TrioOdds.odds1)) : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      TrioOdds.odds2 ? stampClass(Number(TrioOdds.odds2)) : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      TrioOdds.odds3 ? stampClass(Number(TrioOdds.odds3)) : ''
                    "
                  ></span>
                </li>
              </ul>
            </td>
            <td class="wakuban">
              <ul class="list-kaimoku">
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, TrioOdds.odds1) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            TrioOdds.odds1
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            TrioOdds.odds1
                          )}`,
                    ]"
                    >{{ TrioOdds.odds1 }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, TrioOdds.odds2) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            TrioOdds.odds2
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            TrioOdds.odds2
                          )}`,
                    ]"
                    >{{ TrioOdds.odds2 }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, TrioOdds.odds3) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            TrioOdds.odds3
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            TrioOdds.odds3
                          )}`,
                    ]"
                    >{{ TrioOdds.odds3 }}</i
                  >
                </li>
              </ul>
            </td>
            <td class="-right">
              <div :class="[`txt-odds ${calcDigit(TrioOdds.odds)}`]">
                {{ TrioOdds.odds }}
              </div>
            </td>
            <td>
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    v-model="TrioOddsCheckList[index]"
                    value=""
                    name="sample1" /><i></i></label
              ></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="layout-title">
        <h2 class="heading-circle -padding">3連単</h2>
        <div class="nav">
          <p class="_caption">人気順10件を表示</p>
        </div>
      </div>
      <table class="table-grid -fit -middle -center -odds">
        <thead>
          <tr>
            <th class="mystamp">my印</th>
            <th>組み合わせ</th>
            <th>オッズ</th>
            <th class="ranking">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="checkedAll(5)"
                    v-model="checkAllJudge[5]"
                    value=""
                    name="sample1" /><i></i></label
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(TrifectaOdds, index) in TrifectaOddsData"
            :key="TrifectaOdds"
          >
            <td>
              <ul class="list-kaimoku -arrow">
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      TrifectaOdds.odds1
                        ? stampClass(Number(TrifectaOdds.odds1))
                        : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      TrifectaOdds.odds2
                        ? stampClass(Number(TrifectaOdds.odds2))
                        : ''
                    "
                  ></span>
                </li>
                <li>
                  <span
                    v-if="oddsFlag"
                    :class="
                      TrifectaOdds.odds3
                        ? stampClass(Number(TrifectaOdds.odds3))
                        : ''
                    "
                  ></span>
                </li>
              </ul>
            </td>
            <td class="wakuban">
              <ul class="list-kaimoku -arrow">
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, TrifectaOdds.odds1) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            TrifectaOdds.odds1
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            TrifectaOdds.odds1
                          )}`,
                    ]"
                    >{{ TrifectaOdds.odds1 }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, TrifectaOdds.odds2) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            TrifectaOdds.odds2
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            TrifectaOdds.odds2
                          )}`,
                    ]"
                    >{{ TrifectaOdds.odds2 }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      calcBracketNum(entryNum, TrifectaOdds.odds2) < 10
                        ? `icon-umaban -waku0${calcBracketNum(
                            entryNum,
                            TrifectaOdds.odds3
                          )}`
                        : `icon-umaban -waku${calcBracketNum(
                            entryNum,
                            TrifectaOdds.odds3
                          )}`,
                    ]"
                    >{{ TrifectaOdds.odds3 }}</i
                  >
                </li>
              </ul>
            </td>
            <td class="-right">
              <div :class="[`txt-odds ${calcDigit(TrifectaOdds.odds)}`]">
                {{ TrifectaOdds.odds }}
              </div>
            </td>
            <td>
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    v-model="TrifectaOddsCheckList[index]"
                    value=""
                    name="sample1" /><i></i></label
              ></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container-total">
        <dl class="amount">
          <dt>点数合計</dt>
          <dd>
            <span class="num">{{ checkListSum() }}</span
            >点
          </dd>
        </dl>
        <div @click="setRaceBet" class="btn-basic">買い目登録</div>
      </div>
      <div class="container-schedule">
        <a href="/purchase/" target="_blank" class="btn-basic -normal"> 購入予定一覧 </a>
        <p class="_caption">
          ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
        </p>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>

  <SetBetConfModal
    @closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
  ></SetBetConfModal>
</template>

<style lang="scss" src="../../assets/css/race/odds/style.scss" scoped></style>
