import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreJockeyData = defineStore('jockey-data', () => {
  const jockeyCdValue = ref('');
  const news = ref([]);
  const thisWeekSpResult = ref([]);
  const schedule = ref([]);
  const totalRaces = ref(0);
  const careerStatData = ref(null);
  const JockeyData = ref(null);
  const jockeyExtData = ref(null);
  const g1RaceResultData = ref(null);
  const bigPrizeData = ref(null);
  const imgPath = ref("");
  const uniformImgPath = ref("");

  return {
    jockeyCdValue,
    news,
    careerStatData,
    schedule,
    totalRaces,
    thisWeekSpResult,
    JockeyData,
    jockeyExtData,
    g1RaceResultData,
    bigPrizeData,
    imgPath,
    uniformImgPath,
  }
})

export default {
  useStoreJockeyData,
}