<script setup>
import { ref } from "vue";
import { HOST } from "../../assets/js/define";
import { userSettingURL } from "../../assets/js/common";
const emit = defineEmits(["modal-close"]);

const paymentCarrerLink = "loginPage";
const paymentCreditLink = "loginCreditPage";

const props = defineProps({
  modalOpen: Boolean,
});

const modalClose = () => {
  emit("modal-close", false);
};
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div v-if="modalOpen" class="modal-frame" @click="modalClose()">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                ログイン
              </div>
              <div class="modal-body">
                <div class="container-box -high">
                  <div class="form-login">
                    <div class="layout-sub">
                      <h2 class="heading-circle">キャリア決済をご利用の場合</h2>
                      <a
                        @click="userSettingURL(paymentCarrerLink)"
                        class="btn-basic -wide -window"
                        >キャリアIDでログイン</a
                      >
                      <p class="_caption _center">
                        ※キャリアIDでのログイン画面へ移ります。
                      </p>
                    </div>

                    <div class="layout-sub">
                      <h2 class="heading-circle">
                        クレジット決済をご利用の場合
                      </h2>
                      <a
                        @click="userSettingURL(paymentCreditLink)"
                        class="btn-basic -wide -window"
                        >メール会員でログイン</a
                      >
                      <p class="_caption _center">
                        ※メール会員でのログイン画面へ移ります。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="modal-close" @click="modalClose()"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
