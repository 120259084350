<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">収支管理</h1>
    </section>

    <section class="layout-section">
      <div>
        <div class="nav-tab -fit">
          <ul>
            <li>
              <a class="label" href="/balance/">年間別収支データ</a>
            </li>
            <li class="-current">
              <a class="label" href="/balance/category/">カテゴリ別データ</a>
            </li>
          </ul>
        </div>
        <div class="container-tabtarget">
          <div class="inner">
            <nav class="nav-multi -noborder">
              <ul class="list">
                <li>
                  <a href="/balance/sum_venue/" class="label">競馬場別</a>
                </li>
                <li>
                  <a href="/balance/sum_date_place/" class="label">開催別</a>
                </li>
                <li><a href="/balance/sum_jockey/" class="label">騎手別</a></li>
                <li>
                  <a href="/balance/sum_trainer/" class="label">調教師別</a>
                </li>
                <li>
                  <a href="/balance/sum_distance/" class="label">距離別</a>
                </li>
                <li>
                  <a href="/balance/sum_surface/" class="label">芝ダ障別</a>
                </li>
                <li>
                  <a href="/balance/sum_horse_age/" class="label"
                     :class="isNar ? '-disable' : ''">世代別</a>
                </li>
                <li>
                  <a href="/balance/sum_horse_number/" class="label">馬番別</a>
                </li>
                <li>
                  <a href="/balance/sum_bettype/" class="label">馬券別</a>
                </li>
                <li>
                  <a href="/balance/sum_grade/" class="label"
                     :class="isNar ? '-disable' : ''">クラス別</a>
                </li>
                <li>
                  <a href="/balance/sum_monthly/" class="label">月別</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import {
  getMenmerCourse,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  isNar,
} from "../../assets/js/common";
import { JOINPATH } from "../../assets/js/define";

export default {
  data() {
    return {
      isNar: false
    }
  },
  mounted() {
    document.body.className = "";
    let self = this
    self.isNar = isNar() === 1
    let mCourse = getMenmerCourse();
    if (mCourse === PAYINGNONE || mCourse === PAYINGSUGOTOKU_NONE) {
      location.href = HOST + JOINPATH;
    }
    document.body.className = "status-loaded";
  },
};
</script>

<style lang="scss" src="../../assets/css/balance/style.scss" scoped></style>
