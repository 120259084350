<script setup>
import { onMounted, ref } from "vue";
import {
  HOST,
  PATH,
  BACKWORDPATH
} from "../../../assets/js/define";
import {
  SUCCESS,
  PAYINGNONE,
  getJSON,
  getMenmerCourse,
  getUserId,
  setMailAddres,
  setMailConfigJusho,
  setMailConfigWin5,
  setMailConfigFavorite,
  setMailConfigPog,
  setMailConfigOshirase,
  setMailConfigAdd,
  setMailConfigDetail
} from "../../../assets/js/common";

const userId = ref('')
const email = ref('')
const errMessage = ref('')

//ユーザID取得
onMounted(() => {
  if (getMenmerCourse() === PAYINGNONE) {
    location.href = "/member";
  }
  userId.value = getUserId();
  document.body.className = "status-loaded";
});

const registerButton = async () => {
  if (!validateEmail(email.value)) {
    errMessage.value = 'メールアドレスの形式が正しくありません。'
    return;
  }

  document.body.className = "";
  const request =
        HOST +
        PATH +
        'Email/setEmailAddress.js' +
        BACKWORDPATH +
        '?userId=' +
        encodeURIComponent(userId.value) +
        '&email=' +
        encodeURIComponent(email.value)

  await getJSON(request, false, function (status, json) {
    if (status == SUCCESS) {
      setMailAddres(email.value)
      setMailConfigJusho(1);
      setMailConfigWin5(1);
      setMailConfigFavorite(1);
      setMailConfigPog(1);
      setMailConfigOshirase(1);
      setMailConfigAdd(1);
      setMailConfigDetail(2);
    } else {
      errMessage.value = 'エラー<br>通信中に問題が発生したため、登録できませんでした。'
    }
  });
  
  if (errMessage.value === '') {    
    location.replace(`${HOST}support/mail/setting`);
  } else {
    document.body.className = "status-loaded";
  }
}

const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    return regex.test(email);
}

//フォームを選択したタイミングで、登録時のエラーメッセージを非表示にする
function isSelect() {
  errMessage.value = '';
}
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">WIN!競馬メール配信 設定</h1>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner">
          <h2 class="heading-circle">メール配信設定</h2>

          <p>
            WIN!競馬では重賞枠順/結果・WIN5結果など、すぐに知りたい情報を確定時にメールで受け取れるサービスを行っております。是非、ご活用ください。
          </p>
          <p>以下のメールが受け取り可能です。</p>
          <ul class="list-disc">
            <li>重賞の枠順確定と、重賞結果</li>
            <li>WIN5対象レースの確定情報と、出走結果</li>
            <li>お気に入り出走馬の出走確定情報と、出走結果</li>
            <li>POG登録馬の出走確定と、出走結果</li>
            <li>WIN!競馬からのメンテナンスや競馬情報</li>
            <li>広告付きの通知</li>
          </ul>
          <dl class="form-set -mailSetting">
            <dt>メールアドレス</dt>
            <dd>
              <div
                v-if="errMessage"
                v-html="errMessage"
                class="system-message -center -error"
              ></div>
              <input
                @click="isSelect"
                v-model="email"
                type="text"
                class="form-text"
                placeholder="sample@winkeiba.jp"
              />
            </dd>
          </dl>
          <div class="nav-group">
            <a @click="registerButton()" class="btn-basic -wide">メールサービスに登録</a>
          </div>

          <div class="container-line">
            <ul class="list-disc">
              <li>
                ご登録頂いたメールアドレスは競馬情報のメール配信以外の目的では使用致しません。
              </li>
              <li>
                登録情報はプライバシーポリシーに基づき、適切に管理致します。<a
                  href="/support/policy/"
                  target="_blank"
                  >プライバシーポリシーはこちらから。</a
                >
              </li>
            </ul>
            <p class="_caption">
              ※ご登録にあたって、@winkeiba.jpからのメールを受信できる設定にしてください。
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
