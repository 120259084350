<template>
  <li v-for="data in dataArray">
    <div class="list-establishrecord">
      <div class="jockey">
        <router-link :to="data.url">{{ data.name }}</router-link>
      </div>
      <div class="place">{{ data.place }}</div>
      <div class="record">{{ data.wins }}</div>
    </div>
  </li>
</template>
<script>
export default {
  props: {
    dataArray: Array,
  },
};
</script>

<style lang="scss" src="../../assets/css/info/style.scss" scoped></style>
