import {BACKWORDPATH, RACEINFOPATH,} from "../../assets/js/define";
import {getJSON} from "../../assets/js/common";
import {storeToRefs} from "pinia";
import {useStoreWin5List} from "../../stores/win5-states";
import {useStoreCommon} from '../../stores/common-states'

let storeWin5List = null;

export async function fetch(yearMonth) {
  const storeCommon = useStoreCommon()
  const {host} = storeToRefs(storeCommon);
  storeWin5List = useStoreWin5List();

  await getWin5ResultList(host.value, yearMonth);
}

const getWin5ResultList = async function (host, yearMonth) {
  if (!yearMonth) {
    yearMonth = new Date().getFullYear() + new Date().getMonth;
  }

  const {
    win5List,
  } = storeToRefs(storeWin5List);

  const req =
    host +
    RACEINFOPATH +
    "getWin5List.js" +
    BACKWORDPATH +
    "?YearMonth=" +
    yearMonth;

  await getJSON(req, true, function (status, json) {
    setSelector(json?.Newest, yearMonth);
    win5List.value = json?.Win5List;
  });
}

const setSelector = async function (newestYearMonth, setYearMonth) {
  if (!newestYearMonth || newestYearMonth === 0) return;
  const {
    years,
    months,
    year,
    month,
  } = storeToRefs(storeWin5List);

  if (setYearMonth > newestYearMonth) {
    setYearMonth = newestYearMonth;
  }

  //予期せぬパラメータ回避用
  if (setYearMonth.match(/\d{4}\d{2}/)) {
    year.value = setYearMonth.slice(0, 4);
    month.value = ("0" + setYearMonth.slice(4, 6)).slice(-2);
  } else {
    //予期せぬパラメータの場合は最新の年月をセット
    year.value = newestYearMonth.slice(0, 4);
    month.value = ("0" + newestYearMonth.slice(4, 6)).slice(-2);
  }

  if (
    newestYearMonth.slice(0, 4) === setYearMonth.slice(0, 4) &&
    newestYearMonth.slice(4, 6) <= setYearMonth.slice(4, 6)
  ) {
    month.value = ("0" + newestYearMonth.slice(4, 6)).slice(-2);
  }

  const today = new Date();
  if (years.value.length === 0) {
    for (let i = newestYearMonth.slice(0, 4); i >= 2013; i = i - 1) {
      years.value.push(String(i));
    }
  }

  let oldMonth = 12;
  months.value.splice(0);
  if (
    newestYearMonth.slice(0, 4) === setYearMonth.slice(0, 4) &&
    newestYearMonth.slice(4, 6) >= setYearMonth.slice(4, 6)
  ) {
    months.value.splice(0);
    for (let i = 0; i < Number(newestYearMonth.slice(4, 6)); i++) {
      months.value.push(("0" + (newestYearMonth.slice(4, 6) - i)).slice(-2));
    }
  } else if (newestYearMonth !== setYearMonth) {
    for (let i = 0; i < 12; i++) {
      if (year === "2013") {
        if (i < 4) {
          months.value.push(("0" + (12 - i)).slice(-2));
        }
      } else if (today.getFullYear() === year) {
        if (12 - i <= month) {
          months.value.push(("0" + (12 - i)).slice(-2));
        }
      } else if (year > "2013") {
        months.value.push(("0" + (12 - i)).slice(-2));
      }
    }
  }

  if (month.value === undefined || month.value === null) {
    if (oldMonth < 10) {
      oldMonth = "0" + String(oldMonth);
    } else {
      oldMonth = String(oldMonth);
    }
    month.value = oldMonth;
  }
}
