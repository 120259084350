import {ref} from 'vue'
import {defineStore} from 'pinia'

export const useStoreStudData = defineStore('stud-data', () => {
  const breedingNumValue = ref('');
  const studData = ref(null);
  const careerStatData = ref([]);
  const bloodData = ref({});
  const crossAppend = ref([]);
  const offspringData = ref([]);
  const horseHairColor = ref({});


  return {
    breedingNumValue,
    studData,
    careerStatData,
    bloodData,
    crossAppend,
    offspringData,
    horseHairColor,
  }
})

export default {
  useStoreStudData,
}