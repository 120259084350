<script setup>
import {onMounted} from "vue";
import {getJSON, isNar, SUCCESS} from "../../../spa/assets/js/common";
import {BACKWORDPATH, HOST, PATH} from "../../../spa/assets/js/define";
import {useRouter} from "vue-router";
const router = useRouter()

onMounted( async () => {
  document.body.className = "";
  await loadDPointRace()
});

const loadDPointRace = async () => {
  const narFlag = isNar() === 1 ? '1' : '0'
  const request = HOST + PATH + "DPoint/getDPointRaceList.js" + BACKWORDPATH
    + "?narFlag=" + narFlag

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      if (json.status === 404) {
        router.push({ path: '/' });
      } else {
        router.push({
          path: '/race/results',
          query: {
            'DOR': json.dor,
            'RacetrackCd': json.racetrackCd,
            'RaceNum': json.raceNum
          }
        });
      }
    }
  });
}

</script>

<template>
  <main class="layout-main">
    <div class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>