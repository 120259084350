<script setup>
import {
  getUserAgent,
  getMenmerCourse,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
} from "../assets/js/common";
import {useRoute} from 'vue-router';
import {ref, onMounted, watch} from 'vue'

const isWebView = ref(false);
const isSugotoku = ref(false);
const isTop = ref(true);

const route = useRoute();

onMounted(() => {
  isTop.value = route.path === '/';
  isWebView.value = getUserAgent();
  const userCourse = getMenmerCourse();
  isSugotoku.value = userCourse === PAYINGSUGOTOKU || userCourse === PAYINGSUGOTOKU_NONE;
})

watch(route, (prev, next) => {
  isTop.value = next.path === '/';
})
</script>

<template>
  <footer class="layout-footer">
    <div class="container-wrapper">
      <ul class="sns" v-if="!isSugotoku">
        <li>
          <a href="https://twitter.com/winkeiba_dm" target="_blank"
            ><img src="../assets/images/common/sns/t.svg" alt="ツイッター"
          /></a>
        </li>
        <li>
          <a href="https://t.co/Y2h9ErjlxS" target="_blank"
            ><img src="../assets/images/common/sns/l.svg" alt="LINE"
          /></a>
        </li>
      </ul>

      <!-- TODO: リンクはアプリに合わせ後ほど修正 -->
      <!-- TODO: 一時的にコメントアウト -->
      <!--<div v-if="!isWebView && !isSugotoku" class="container-app">
        <ul>
          <li>
            <a href="https://play.google.com/store/" target="_blank"
              ><img src="../assets/images/common/googleplay.png" alt=""
            /></a>
          </li>
          <li>
            <a href="https://apps.apple.com/jp/app/xxxx" target="_blank"
              ><img src="../assets/images/common/apple.png" alt=""
            /></a>
          </li>
        </ul>
      </div>-->

      <ul class="link" v-if="!isWebView && !isSugotoku">
        <li><a href="/support/terms_all/">利用規約</a></li>
        <li><a href="/support/law/">特定商取引法の表記</a></li>
        <li v-if="isTop"><a href="/support/quit/">会員解約</a></li>
        <li><a href="/support/policy/">プライバシーポリシー</a></li>
      </ul>
      <p class="copyright">&copy;DIGIMERCE Inc. All Rights Reserved.</p>
    </div>
  </footer>
</template>
