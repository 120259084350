<script setup>
import { ref } from "vue";
import {CERTPATH, HOST} from "../assets/js/define";
import axios from "axios";
import {getJSON, SUCCESS, isNar} from "../assets/js/common";

const mail = ref("");
const password = ref("");
const isError = ref(false);

const loginFunc = async () => {
  document.body.className = "";
  const res = await axios
    .post("/auth/sign_in", {
      mail: mail.value,
      password: password.value,
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      isError.value = true;
      document.body.className = "status-loaded";
    });

  if (isError.value) {
    alert('認証情報が間違っています。')
    return
  }

  const userId = res?.user_id
  const userType = res?.user_type
  await axios
    .post("/auth/mobile_app", {
      userId: userId,
      role:
        userType === 2 ||
        userType === 3 ||
        userType === 5 ||
        userType === 6 ||
        userType === 8 ||
        userType === 9
          ? '3'
          : userType === 1 || userType === 4 || userType === 77
          ? '1'
          : 0,
    })
    .then((res) => {
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      console.log(error);
      isError.value = true;
      document.body.className = "status-loaded";
    });

  location.href= '/'
};

const loginButton = (login) => {
  var req =
      HOST +
      CERTPATH +
      login +
      "?narFlag=" +
      isNar();
  console.log(req)
  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      if (Object.keys(json).length) {
        console.log(json)
        location.href = json?.url ? json?.url : '/'
      }
    }
  });
}
</script>

<template>
  <div class="nav-mega">
    <div class="form-login">
      <div class="layout-sub">
        <h2 class="heading-circle">キャリア決済をご利用の場合</h2>
        <a @click="loginButton('loginPage')" class="btn-basic -wide -window"
          >キャリアIDでログイン</a
        >
        <p class="_caption _center">※キャリアIDでのログイン画面へ移ります。</p>
      </div>

      <div class="layout-sub">
        <h2 class="heading-circle">クレジット決済をご利用の場合</h2>
        <a @click="loginButton('loginCreditPage')" class="btn-basic -wide -window"
          >メール会員でログイン</a
        >
        <p class="_caption _center">※メール会員でのログイン画面へ移ります。</p>
      </div>

      <div class="layout-sub">
        <h2 class="heading-circle">アプリ会員IDでログイン</h2>

        <div v-if="isError" class="system-message -error">
          <ul class="list-disc">
            <li>ログインできません</li>
          </ul>
        </div>

        <dl class="form-set">
          <dt>メールアドレス</dt>
          <dd>
            <input
              type="text"
              class="form-text"
              v-model="mail"
              placeholder="sample@winkeiba.jp"
            />
          </dd>
          <dt>パスワード</dt>
          <dd>
            <input type="password" class="form-text" v-model="password" />
          </dd>
        </dl>
        <div class="nav-group">
          <div class="btn-basic -center" @click="loginFunc">ログイン</div>
        </div>
        <p class="p-caption">
          アプリ会員でログインする場合、メールアドレスとパスワードが必要です。必ず利用規約をお読みいただき、<a
            href="/support/terms_all"
            >利用規約</a
          >の規定を契約の内容とすることをご同意の上、ご登録ください。
        </p>
      </div>
    </div>
  </div>
</template>
