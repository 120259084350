<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">記録達成間近</h1>
    </section>

    <section class="layout-section" v-if="jockeyData.length">
      <div class="layout-title">
        <h2 class="heading-circle -padding">騎手</h2>
      </div>
      <ul class="list-line -dotted">
        <establish-record-list :data-array="jockeyData"></establish-record-list>
      </ul>
    </section>

    <section class="layout-section" v-if="trainerData.length">
      <div class="layout-title">
        <h2 class="heading-circle -padding">調教師</h2>
      </div>
      <ul class="list-line -dotted">
        <establish-record-list :data-array="trainerData"></establish-record-list>
      </ul>
    </section>

    <div v-if="!isExists" class="mt10 box5">
      <p>対象なし</p>
    </div>

    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import { getJSON, SUCCESS, isNar } from "../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  RACEINFOPATH,
} from "../../assets/js/define";
import EstablishRecordList from "../../components/info/EstablishRecordList.vue";
export default {
  components: { EstablishRecordList },
  data() {
    return {
      loadedPage: false,
      jockeyData: [],
      trainerData: [],
      isExists: true,
    };
  },
  mounted() {
    const self = this
    const req =
        HOST +
        RACEINFOPATH +
        "getEstablishRecord.js" +
        BACKWORDPATH +
        "?narFlag=" +
        isNar();
    getJSON(req, true, function (status, json) {
      if (status === SUCCESS) {
        self.jockeyData = json.jockey
        self.trainerData = json.trainer
        self.isExists = json.jockey.length > 0 || json.trainer.length > 0
        self.loadedPage = true
      }
    });
  },
};
</script>

<style lang="scss" src="../../assets/css/info/style.scss" scoped></style>
