<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getJSON,
  getUserId,
  SUCCESS,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  retrieveCourse,
} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH, JOINPATH } from "../../assets/js/define";
import Schedule from "../../components/fav/Schedule.vue";
import Schedule2 from "../../components/fav/Schedule2.vue";

// ユーザID
let mUserId = "";

let favContentList = reactive([]);
let nextTargetContentList = reactive([]);
const loadedPage = ref(false);

onMounted(() => {
  callRetrieveCourse(function (courseId) {
    if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
      location.href = HOST + JOINPATH;
    } else {
      doInit();
      document.body.className = "";
    }
  });
});

const callRetrieveCourse = function (callback) {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
}

const doInit = async () => {
  // ユーザID取得
  mUserId = getUserId();
  await getMyFavoriteRacehorseAll(mUserId);
  await getMyNextTargetRacehorseAll(mUserId);

  document.body.className = "status-loaded";
  loadedPage.value = true;
};

// 表示用にデータを加工してオブジェクトに格納する
const setData = (json, type) => {
  try {
    if (json != null) {
      for (let i = 0; i < json.length; i++) {
        let list = new Object();
        list.PedigreeNum = json[i].PedigreeNum;
        if(type === 'fav'){
          favContentList.push(list);
        } else if(type === 'nextTarget'){
          nextTargetContentList.push(list);
        }
      }
    }
  } catch (e) {}
};
const getMyFavoriteRacehorseAll = (mUserId) => {
  let request =
    HOST +
    PATH +
    "MyFavorite/getMyFavoriteRacehorseAll.js" +
    BACKWORDPATH +
    "?userID=" + encodeURIComponent(mUserId)
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      setData(json,'fav');
    }
  });
}

const getMyNextTargetRacehorseAll = (mUserId) => {
  let request =
    HOST +
    PATH +
    "MyNextTarget/getMyNextTargetRacehorseAll.js" +
    BACKWORDPATH +
    "?userID=" + encodeURIComponent(mUserId)
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      setData(json,'nextTarget');
    }
  });
}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -fav">
      <h1 class="heading-page">お気に入り/次走狙い馬出走情報</h1>
    </section>

    <div class="layout-title">
      <h2 class="heading-circle -padding">お気に入り出走馬一覧</h2>
    </div>
    <section
      v-if="favContentList.length && loadedPage"
      class="layout-section"
    >
      <Schedule />
    </section>
    <section v-else-if="loadedPage" class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">レース情報</h2>
      </div>
      <div class="inner">
        <h3 class="heading-message">お気に入りは登録されていません。</h3>
        <p>
          気になるキーワードで検索してお気に入りに登録してください。<br />お気に入りに登録すると
        </p>
        <ul class="list-number">
          <li>お気に入り競走馬、騎手、調教師の出場レースが一目でわかる</li>
          <li>お気に入り競走馬、騎手、調教師が出場するレースをお知らせ</li>
        </ul>
      </div>
    </section>

    <div class="layout-title">
      <h2 class="heading-circle -padding">次走狙い出走馬一覧</h2>
    </div>
    <section
      v-if="nextTargetContentList.length && loadedPage"
      class="layout-section"
    >
      <Schedule2 />
    </section>
    <section v-else-if="loadedPage" class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">レース情報</h2>
      </div>
      <div class="inner">
        <h3 class="heading-message">次走狙い馬は登録されていません。</h3>
        <p>
          気になるキーワードで検索して次走狙い馬に登録してください。<br />次走狙い馬に登録すると
        </p>
        <ul class="list-number">
          <li>次走狙い馬の出場レースが一目でわかる</li>
          <li>次走狙い馬が出場するレースをお知らせ</li>
        </ul>
      </div>
    </section>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/fav/style.scss" scoped></style>
