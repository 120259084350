<script setup>
import { ref, reactive, onMounted } from "vue";
import {getJSON, SUCCESS} from "../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  PATH
} from "../../assets/js/define";

const currentDateAndTime = ref("");
const lists = reactive([]);
onMounted(() => {
  const request =
      HOST +
      PATH +
      "Master/getJraHorseMaster.js" +
      BACKWORDPATH
  document.body.className = "";
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      currentDateAndTime.value = json.caption;
      for (let row of json.lists) {
        lists.push(
            {
              title: `${row._id}年産JRA登録馬`
              , link: `/horselist/detail?slug=${row._id}`
            }
        )
      }
    }
    document.body.className = "status-loaded";
  });
});
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">JRA登録馬一覧</h1>
    </section>

    <section class="layout-section">
      <div class="inner">
        <p style="white-space: pre">
          JRA(中央競馬会)登録馬一覧です。 <br> {{ currentDateAndTime }}
        </p>
      </div>
      <div class="container-box">
        <div class="inner" v-for="(list, index) in lists" :key="index">
          <h2 class="heading-circle">{{ list.title }}</h2>
          <div class="nav-multi -cols5 -noborder">
            <ul class="list">
              <li><router-link :to="list.link + '&key=a'" class="label">ア行</router-link></li>
              <li><router-link :to="list.link + '&key=ka'" class="label">カ行</router-link></li>
              <li><router-link :to="list.link + '&key=sa'" class="label">サ行</router-link></li>
              <li><router-link :to="list.link + '&key=ta'" class="label">タ行</router-link></li>
              <li><router-link :to="list.link + '&key=na'" class="label">ナ行</router-link></li>
              <li><router-link :to="list.link + '&key=ha'" class="label">ハ行</router-link></li>
              <li><router-link :to="list.link + '&key=ma'" class="label">マ行</router-link></li>
              <li><router-link :to="list.link + '&key=ya'" class="label">ヤ行</router-link></li>
              <li><router-link :to="list.link + '&key=ra'" class="label">ラ行</router-link></li>
              <li><router-link :to="list.link + '&key=wa'" class="label">ワ行</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/horselist/style.scss" scoped></style>
