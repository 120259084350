<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">調教師データ一覧(50音順)</h1>
    </section>

    <nav class="layout-title -hasborder">
      <h2 class="heading-circle -padding">
        {{ select ? select.name : "ア行" }}
      </h2>
      <div class="nav">
        <div v-if="select" class="form-select">
          <select v-model="select.id" @change="changeData(select.id)">
            <option disabled>調教師名絞込み</option>
            <option value="ｱ">ア行</option>
            <option value="ｶ">カ行</option>
            <option value="ｻ">サ行</option>
            <option value="ﾀ">タ行</option>
            <option value="ﾅ">ナ行</option>
            <option value="ﾊ">ハ行</option>
            <option value="ﾏ">マ行</option>
            <option value="ﾔ">ヤ行</option>
            <option value="ﾗ">ラ行</option>
            <option value="ﾜ">ワ行</option>
          </select>
        </div>
      </div>
    </nav>
    <div class="container-wrapper -pad">
      <div class="container-toggle" v-if="select">
        <ul>
          <li v-for="(item, index) in select.data" :key="index">
            <a
              class="label -anchor"
              :class="[item.disable ? 'disabled' : '']"
              @click="scroll('kana_' + index)"
            >
              {{ item.kana }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <section class="layout-section">
      <table v-if="select" class="table-grid -fit -padding">
        <thead>
          <tr class="-center header">
            <th>調教師名</th>
            <th>年齢</th>
            <th>所属</th>
          </tr>
        </thead>
        <tbody v-if="select.data">
          <template v-if="consonantNum === 0">
            <tr :id="'kana_' + 0">
              <th colspan="3">{{ select.data[0].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[0].data" :key="k">
              <td>
                <a :href="content.link">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">{{ content.belongs }}</td>
            </tr>

            <tr :id="'kana_' + 1">
              <th colspan="3">{{ select.data[1].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[1].data" :key="k">
              <td>
                <a :href="content.link">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">{{ content.belongs }}</td>
            </tr>
            <tr :id="'kana_' + 2">
              <th colspan="3">{{ select.data[2].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[2].data" :key="k">
              <td>
                <a :href="content.link">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">{{ content.belongs }}</td>
            </tr>

            <tr :id="'kana_' + 3">
              <th colspan="3">{{ select.data[3].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[3].data" :key="k">
              <td>
                <a :href="content.link">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">{{ content.belongs }}</td>
            </tr>

            <tr :id="'kana_' + 4">
              <th colspan="3">{{ select.data[4].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[4].data" :key="k">
              <td>
                <a :href="content.link">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">{{ content.belongs }}</td>
            </tr>
          </template>
          <template v-else-if="consonantNum === 1">
            <tr :id="'kana_' + 0">
              <th colspan="3">{{ select.data[0].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[0].data" :key="k">
              <td>
                <a :href="content.link">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">{{ content.belongs }}</td>
            </tr>

            <tr :id="'kana_' + 1" v-if="!select.data[1].disable">
              <th colspan="3">{{ select.data[1].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[1].data" :key="k">
              <td>
                <a :href="content.link">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">{{ content.belongs }}</td>
            </tr>
            <tr :id="'kana_' + 2" v-if="!select.data[2].disable">
              <th colspan="3">{{ select.data[2].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[2].data" :key="k">
              <td>
                <a :href="content.link">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">{{ content.belongs }}</td>
            </tr></template
          >
        </tbody>
      </table>
    </section>

    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import { ref } from "vue";
import {
  PAYINGNONE,
  SUCCESS,
  getJSON,
  getUserId,
  getMenmerCourse,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  DATAPATH,
  BACKWORDPATH,
} from "../../assets/js/define";
export default {
  data() {
    return {
      select: null,
      selected: "",
      data: null,
      mainKey: "",
      consonantNum: null,
      env: {
        GYOU: {
          A: {
            id: "ｱ",
            name: "ア行",
            data: ["ｱ", "ｲ", "ｳ", "ｴ", "ｵ"],
          },
          K: {
            id: "ｶ",
            name: "カ行",
            data: ["ｶ", "ｷ", "ｸ", "ｹ", "ｺ"],
          },
          S: {
            id: "ｻ",
            name: "サ行",
            data: ["ｻ", "ｼ", "ｽ", "ｾ", "ｿ"],
          },
          T: {
            id: "ﾀ",
            name: "タ行",
            data: ["ﾀ", "ﾁ", "ﾂ", "ﾃ", "ﾄ"],
          },
          N: {
            id: "ﾅ",
            name: "ナ行",
            data: ["ﾅ", "ﾆ", "ﾇ", "ﾈ", "ﾉ"],
          },
          H: {
            id: "ﾊ",
            name: "ハ行",
            data: ["ﾊ", "ﾋ", "ﾌ", "ﾍ", "ﾎ"],
          },
          M: {
            id: "ﾏ",
            name: "マ行",
            data: ["ﾏ", "ﾐ", "ﾑ", "ﾒ", "ﾓ"],
          },
          Y: {
            id: "ﾔ",
            name: "ヤ行",
            data: ["ﾔ", "ﾕ", "ﾖ"],
          },
          R: {
            id: "ﾗ",
            name: "ラ行",
            data: ["ﾗ", "ﾘ", "ﾙ", "ﾚ", "ﾛ"],
          },
          W: {
            id: "ﾜ",
            name: "ワ行",
            data: ["ﾜ", "ｦ", "ﾝ"],
          },
        },
      },
    };
  },
  mounted() {
    // 課金コース
    const self = this;
    let mCouese = PAYINGNONE;
    // ユーザID
    let mUserId = "";
    let mInfo = null;

    //会員コース確認
    mCouese = getMenmerCourse();
    //ユーザID確認
    mUserId = getUserId();
    // リクエストURL作成
    mInfo = getUlrParams();

    init();

    document.body.className = "status-loaded";

    function init() {
      self.mainKey = "ｱ";
      self.getTrainerData();
    }

    function getUlrParams() {
      // ページ数取得
      let pageNum = 1;
      let url = location.href;
      if (url.indexOf("#page-") > -1) {
        pageNum = url.slice(url.indexOf("#page-") + 6, url.length);
        url = url.slice(0, url.indexOf("#page-"));
      }

      // パラメータ取得
      let vars = [],
        hash;
      let hashes = url.slice(window.location.href.indexOf("?") + 1).split("&");
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        if (hash[0] != null && hash[0].indexOf("#page-") == -1) {
          // ページリンクは除外する
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
      }
      return { params: vars, page: pageNum };
    }
  },
  methods: {
    changeData(obj) {
      //セレクトボックスから選択した際に実行

      document.body.className = "";

      let self = this;
      if (obj) {
        self.mainKey = obj;
        self.getTrainerData();
      }

      document.body.className = "status-loaded";
    },
    changeTargetNum() {
      //現在選択されている母音の配列の位置を返す
      let self = this;
      let targetNum = 0;
      if (self.mainKey === "ｱ") {
        targetNum = 0;
      } else if (self.mainKey === "ｶ") {
        targetNum = 1;
      } else if (self.mainKey === "ｻ") {
        targetNum = 2;
      } else if (self.mainKey === "ﾀ") {
        targetNum = 3;
      } else if (self.mainKey === "ﾅ") {
        targetNum = 4;
      } else if (self.mainKey === "ﾊ") {
        targetNum = 5;
      } else if (self.mainKey === "ﾏ") {
        targetNum = 6;
      } else if (self.mainKey === "ﾔ") {
        targetNum = 7;
      } else if (self.mainKey === "ﾗ") {
        targetNum = 8;
      } else if (self.mainKey === "ﾜ") {
        targetNum = 9;
      }
      return targetNum;
    },
    kanaChange(str) {
      const kanaMap = {
        ｶﾞ: "ガ",
        ｷﾞ: "ギ",
        ｸﾞ: "グ",
        ｹﾞ: "ゲ",
        ｺﾞ: "ゴ",
        ｻﾞ: "ザ",
        ｼﾞ: "ジ",
        ｽﾞ: "ズ",
        ｾﾞ: "ゼ",
        ｿﾞ: "ゾ",
        ﾀﾞ: "ダ",
        ﾁﾞ: "ヂ",
        ﾂﾞ: "ヅ",
        ﾃﾞ: "デ",
        ﾄﾞ: "ド",
        ﾊﾞ: "バ",
        ﾋﾞ: "ビ",
        ﾌﾞ: "ブ",
        ﾍﾞ: "ベ",
        ﾎﾞ: "ボ",
        ﾊﾟ: "パ",
        ﾋﾟ: "ピ",
        ﾌﾟ: "プ",
        ﾍﾟ: "ペ",
        ﾎﾟ: "ポ",
        ｳﾞ: "ヴ",
        ﾜﾞ: "ヷ",
        ｦﾞ: "ヺ",
        ｱ: "ア",
        ｲ: "イ",
        ｳ: "ウ",
        ｴ: "エ",
        ｵ: "オ",
        ｶ: "カ",
        ｷ: "キ",
        ｸ: "ク",
        ｹ: "ケ",
        ｺ: "コ",
        ｻ: "サ",
        ｼ: "シ",
        ｽ: "ス",
        ｾ: "セ",
        ｿ: "ソ",
        ﾀ: "タ",
        ﾁ: "チ",
        ﾂ: "ツ",
        ﾃ: "テ",
        ﾄ: "ト",
        ﾅ: "ナ",
        ﾆ: "ニ",
        ﾇ: "ヌ",
        ﾈ: "ネ",
        ﾉ: "ノ",
        ﾊ: "ハ",
        ﾋ: "ヒ",
        ﾌ: "フ",
        ﾍ: "ヘ",
        ﾎ: "ホ",
        ﾏ: "マ",
        ﾐ: "ミ",
        ﾑ: "ム",
        ﾒ: "メ",
        ﾓ: "モ",
        ﾔ: "ヤ",
        ﾕ: "ユ",
        ﾖ: "ヨ",
        ﾗ: "ラ",
        ﾘ: "リ",
        ﾙ: "ル",
        ﾚ: "レ",
        ﾛ: "ロ",
        ﾜ: "ワ",
        ｦ: "ヲ",
        ﾝ: "ン",
        ｧ: "ァ",
        ｨ: "ィ",
        ｩ: "ゥ",
        ｪ: "ェ",
        ｫ: "ォ",
        ｯ: "ッ",
        ｬ: "ャ",
        ｭ: "ュ",
        ｮ: "ョ",
        "｡": "。",
        "､": "、",
        ｰ: "ー",
        "｢": "「",
        "｣": "」",
        "･": "・",
      };

      const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
      return str
        .replace(reg, function (match) {
          return kanaMap[match];
        })
        .replace(/ﾞ/g, "゛")
        .replace(/ﾟ/g, "゜");
    },
    getTrainerData() {
      let self = this;
      let request =
        HOST +
        PATH +
        "Search/searchTrainerList.js" +
        BACKWORDPATH +
        "?MainKey=" +
        self.mainKey +
        "&NarFlag=" +
        isNar();
      const useData = [];
      const messageFlag = true;

      Object.keys(self.env.GYOU).forEach((key) => {
        useData.push({
          id: self.env.GYOU[key].id,
          name: self.env.GYOU[key].name,
          data: [],
        });
      });

      getJSON(request, messageFlag, function (status, json) {
        if (status == SUCCESS) {
          if (json && json instanceof Array) {
            const data = json;
            const responseData = [];
            data.forEach((item) => {
              const kanaId = item.TrainerNameKana.substring(0, 1);

              //DOBを年齢に変更
              const birthDate =
                item.DOB.slice(0, 4) +
                "/" +
                item.DOB.slice(4, 6) +
                "/" +
                item.DOB.slice(6, 8);

              const ageCalculation = (birthDate, nowDate) => {
                let birthNumber =
                  birthDate.getFullYear() * 10000 +
                  (birthDate.getMonth() + 1) * 100 +
                  birthDate.getDate();
                let nowNumber =
                  nowDate.getFullYear() * 10000 +
                  (nowDate.getMonth() + 1) * 100 +
                  nowDate.getDate();

                return Math.floor((nowNumber - birthNumber) / 10000);
              };

              //詳細データへのリンク生成
              if (item.TrainerCd != "00000") {
                item.urlLink =
                  HOST + DATAPATH + "trainer?TrainerCd=" + item.TrainerCd;
              }

              responseData.push({
                id: kanaId,
                data: {
                  name: item.TrainerName,
                  age: ageCalculation(new Date(birthDate), new Date()),
                  belongs: item.BelongName,
                  link: item.urlLink,
                },
              });
            });

            Object.keys(self.env.GYOU).forEach((gyouKey) => {
              const gyou = self.env.GYOU[gyouKey];
              const gyouId = gyou.id;
              const gyouName = gyou.name;
              let record = useData.find(
                (useDataItem) => useDataItem.id === gyouId
              );
              if (!record) {
                record = {
                  id: gyouId,
                  name: gyouName,
                  data: [],
                };
                useData.push(record);
              }
              gyou.data.forEach((gyouDataId) => {
                const GyouData = {
                  id: gyouDataId,
                  name: gyouName,
                  kana: self.kanaChange(gyouDataId),
                  data: responseData
                    .filter((rData) => {
                      return rData.id === gyouDataId;
                    })
                    .map((rData) => rData.data),
                };
                if (gyouDataId === "ｦ" || gyouDataId === "ﾝ") {
                  GyouData["disable"] = true;
                }
                record.data.push(GyouData);
              });
            });
          }
        }
      }).then(() => {
        self.data = useData;
        const targetId = self.changeTargetNum();
        if (useData[targetId].data.length === 3) {
          self.consonantNum = 1;
        } else {
          self.consonantNum = 0;
        }

        self.select =
          useData.length > 0
            ? useData[targetId]
            : {
                id: "ｱ",
                name: "ア行",
                data: [
                  { id: "ｱ", data: [] },
                  { id: "ｲ", data: [] },
                  { id: "ｳ", data: [] },
                  { id: "ｴ", data: [] },
                  { id: "ｵ", data: [] },
                ],
              };
      });
    },
    scroll(id) {
      const scrollY = window.scrollY || window.pageYOffset;
      const kanaPosition = document
        .getElementById(id)
        .getBoundingClientRect().top;
      window.scrollTo({
        top: kanaPosition + scrollY - 50,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" src="../../assets/css/data/search/style.scss" scoped></style>
