<script setup>

</script>

<template>
    <main class="layout-main">

        <section class="layout-pageheader -default">
            <h1 class="heading-page">IPAT連動利用規約</h1>
        </section>

        <section class="layout-section">
            <div class="inner">
                <p>株式会社デジマース(以下当社)がサービス提供する競馬情報サービス「WIN!競馬」(以下本サービス)で提供されるIPAT連動機能(以下当機能)は、中央競馬会(以下JRA)が運営するJRAIPATへ、本サービス内で選択した買い目データを送信する入力補助機能です。
                </p>
                <ul class="list-disc">
                    <li>当機能はお客様の利用端末や利用状況によっては正常に動作しないことがあります。</li>
                    <li>当社は、当機能を利用して送信されたデータの正確性について一切保証致しません。</li>
                    <li>当社は事前の予告なしに、当機能の提供を停止、もしくは終了する場合があります。</li>
                    <li>当機能をご利用になる場合、投票するレース、買い目および購入金額等は、“必ず”IPATの合計金額確認画面で確認の上、投票手続きを行なってください。</li>
                    <li>当社は、IPATの利用に関する如何なるお問い合わせにも対応できません。</li>
                    <li>プログラムの瑕疵、プログラムの不具合、通信障害を含む何らかのトラブルが発生し、意図した投票が行われなかった場合、意図しない投票が行われた場合、その他如何なる場合においても、JRA、当社、他サービス提供に関係する第三者は一切の責任を負いません。ただし、故意または重過失による場合は、この限りではありません。
                    </li>
                </ul>
                <p class="p-from">2013年9月1日<br>
                    株式会社デジマース </p>
            </div>
            <div class="nav-group -prev">
                <router-link to="/purchase/ipat" class="btn-basic -gray -prev">購入予定一覧画面へ戻る</router-link>
            </div>
        </section>

    </main>


</template>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>