<script setup>
import {
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import { useRoute, useRouter } from 'vue-router';
import axios from "axios";
import { NAR_WP_HOST } from "../../../assets/js/define";

const route = useRoute();
const router = useRouter();

const my_id = ref("");
const selectedYear = ref("");

watch(route, (from, to) => {
  my_id.value = to.query["id"];
  selectedYear.value = to.query["id"];
  getData(my_id.value);
});

onMounted(() => {
  my_id.value = route.query["id"];
  selectedYear.value = route.query["id"];
  if (my_id.value) {
    getData(my_id.value);
  } else {
    getData();
  }
});

const selectYear = (e) => {
  router.replace({path: `${route.fullPath}`, query: {...route.query, id: e.target.value}});
};

let posts = reactive([]);
let list = reactive([]);
const getData = (id) => {
  let request_url = NAR_WP_HOST + "wp-json/wp/v2/jushonar/"
  request_url += id ?? "?per_page=1"
  document.body.className = "";
  axios
      .get(request_url)
      .then((response) => {
        let fetchData;
        if (Array.isArray(response.data)) {
          // id指定せず取得
          fetchData = response.data[0];
        } else {
          // id指定で取得
          fetchData = response.data;
        }
        Object.assign(posts, fetchData.acf);
        Object.assign(list, fetchData.list);
        selectedYear.value = fetchData.id;
        document.body.className = "status-loaded";
      })
      .catch((error) => {
        console.log(error);
        document.body.className = "status-loaded";
      });
};
</script>
<template>
  <main class="layout-main">
    <section class="layout-section">
      <div class="nav-round">
        <div class="inner -separate">
          <div class="-dayplace">
            <div class="form-select">
              <select v-model="selectedYear" v-on:change="selectYear">
                <option v-for="item in list" :key="item.id" :value="item.id">
                  {{ item.title }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <table class="table-grid -importantrace">
        <thead>
        <tr>
          <th class="mdcdr" rowspan="2">
            月/日<br /><span class="caption">競馬場:距離</span>
          </th>
          <th class="mdcdr">レース名</th>
        </tr>
        <tr>
          <th class="wn">優勝馬</th>
        </tr>
        </thead>

        <tbody v-for="post in posts.race">
        <tr>
          <th class="race g3" rowspan="2">
            <span v-html="post.date" class="date"></span><br /><span
              class="caption"
              v-html="post.keibajyo"
          ></span>
          </th>
          <td
              class="race g3"
              v-html="post.racename"
          >
          </td>
        </tr>
        <tr>
          <td v-html="post.win || '-'"></td>
        </tr>
        </tbody>
      </table>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../../assets/css/race/style.scss" scoped></style>