<script setup>
import { ref, onMounted } from "vue";
import {getJSON, getUrlVars, getUserAgent, getUserId, SUCCESS} from "../../../spa/assets/js/common";
import { BACKWORDPATH, HOST, PATH } from "../../../spa/assets/js/define";
import {useRouter} from "vue-router";
const router = useRouter()
const dPointPageView = ref("")
const errorMessage = ref("")
const height = ref(0)

onMounted( async () => {
  document.body.className = "";
  height.value = document.body.offsetHeight
  await loadData()
  document.body.className = "status-loaded";
});

const loadData = async () => {
  const params = getUrlVars();
  const isApp = params?.isApp || '0'
  const isWebView = getUserAgent() ? '1' : '0'
  const userId = getUserId()
  const request = HOST + PATH + "DPoint/getCouponList.js" + BACKWORDPATH
    + "?userId=" + encodeURIComponent(userId)
    + "&isApp=" + isApp
    + "&isWebView=" + isWebView

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      if (json?.status === 207) {
        router.push({ path: json.url });
        return
      } else if (isWebView === '1' && json?.status === 0) {
        location.replace(json.url)
        return
      }
      errorMessage.value = json.errorMessage
      dPointPageView.value = json.url
    }
  });
}

</script>

<template>
  <main class="layout-main">
    <section class="layout-section">
      <div>
        <span v-if="errorMessage || !dPointPageView">{{ errorMessage }}</span>
        <div v-else
             class="iframe-wrap"
             :style="{ height: height + 'px' }">
          <iframe
            allow="fullscreen"
            width="100%"
            :src="dPointPageView">
          </iframe>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/support/style.scss"></style>