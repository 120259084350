<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">検索結果</h1>
    </section>

    <h2 class="heading-circle -padding">
      レース検索結果: {{ numberOfSearchResults }}件
    </h2>
    <p
      v-if="numberOfSearchResults > 200 && loadedPage"
      class="system-message -padding _important"
    >
      最大件数を超えた為、200件まで表示します。<br />条件を絞って再検索をしてください。
    </p>
    <p
      v-if="numberOfSearchResults === 0 && loadedPage"
      class="system-message -padding _important"
    >
      検索結果はありません。
    </p>
    <section v-if="loadedPage" class="layout-section">
      <table class="table-line -fit -middle">
        <tbody>
          <tr v-for="(item, index) in ListItems" :key="index">
            <td>
              <a :href="item.UrlLink"
                >{{ item.RaceMainName }}({{ item.DOR }})</a
              >
            </td>
          </tr>
        </tbody>
      </table>

      <div class="nav-group">
        <span
          v-if="numberOfSearchResults - count > 0 && count <= 200"
          class="btn-basic -more"
          @click="isMore"
          >もっと見る</span
        >
      </div>
    </section>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import {
  PAYINGNONE,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  SUCCESS,
  getJSON,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  RACEPATH,
  DATAPATH,
  BACKWORDPATH,
  SORTHORSENAME,
  SORTSEX,
  SORTAGE,
  SORTCLASS,
} from "../../assets/js/define";
import { useRouter } from 'vue-router'
export default {
  data() {
    return {
      useData: [],
      numberOfSearchResults: null,
      count: 30,
      loadedPage: false,
      courseId: PAYINGNONE,
    };
  },
  mounted() {
    const self = this;
    const router = useRouter()
    // リクエストURL作成
    let mInfo = getUlrParams();
    const param = createParam(mInfo.params);

    const req = HOST + PATH + "getLoginInfo.js" + BACKWORDPATH;
    getJSON(req, true, (status, json) => {
      if (status == SUCCESS) {
        self.courseId = json.courseIdStr;
      }

      let request =
          HOST +
          PATH +
          "Search/searchRace.js" +
          BACKWORDPATH +
          "?narFlag=" +
          isNar();
      if (param != null && param != "") {
        request += "&" + param;
      }

      const messageFlag = true;
      document.body.className = "";
      getJSON(request, messageFlag, function (status, json) {
        if (status == SUCCESS) {
          if (json && json.length) {
            const useData = json;

            //検索結果の要素数を取得
            const numberOfSearchResults = useData[0].CountAll;

            //検索結果が一件の場合に詳細ページに表示
            if (numberOfSearchResults === 1) {
              const backCheck = sessionStorage.getItem('numberOfRaceSearchResults?' + useData[0].DOR + useData[0].RacetrackCd + useData[0].RaceNum)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              if (useData[0].DOR && useData[0].RacetrackCd && useData[0].RaceNum) {
                sessionStorage.setItem('numberOfRaceSearchResults?' + useData[0].DOR + useData[0].RacetrackCd + useData[0].RaceNum,1)
                let url =
                    '/' +
                    RACEPATH +
                    "card?DOR=" +
                    useData[0].DOR +
                    "&RacetrackCd=" +
                    useData[0].RacetrackCd +
                    "&RaceNum=" +
                    useData[0].RaceNum;

                router.push(url);
              }
            }

            self.numberOfSearchResults = numberOfSearchResults;
            self.useData = useData;

            //詳細データへのリンク生成
            for (let i = 0; i < useData.length; i++) {
              if (
                  useData[i].DOR && useData[i].DOR !== "0000000000" &&
                  useData[i].RacetrackCd && useData[i].RacetrackCd !== "00" &&
                  useData[i].RaceNum && useData[i].RaceNum !== "00"
              ) {
                useData[i].UrlLink =
                    HOST +
                    RACEPATH +
                    "card?DOR=" +
                    useData[i].DOR +
                    "&RacetrackCd=" +
                    useData[i].RacetrackCd +
                    "&RaceNum=" +
                    useData[i].RaceNum;
              }
            }

            self.useData = useData;
          } else {
            self.numberOfSearchResults = 0
          }
        }
        // 詳細ページへ遷移する場合、ローディングのマスクは表示したまま
        document.body.className = "status-loaded";
        self.loadedPage = true;
      });
    });

    function getUlrParams() {
      // ページ数取得
      let pageNum = 1;
      let url = location.href;
      if (url.indexOf("#page-") > -1) {
        pageNum = url.slice(url.indexOf("#page-") + 6, url.length);
        url = url.slice(0, url.indexOf("#page-"));
      }

      // パラメータ取得
      let vars = [],
        hash;
      let hashes = url.slice(window.location.href.indexOf("?") + 1).split("&");
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        if (hash[0] != null && hash[0].indexOf("#page-") == -1) {
          // ページリンクは除外する
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
      }
      return { params: vars, page: pageNum };
    }

    function createParam(params) {
      let param = "";

      if (
        params["keyword"] != undefined &&
        params["keyword"] != null &&
        params["keyword"] != ""
      ) {
        param += "keyword" + "=" + params["keyword"];
      }
      if (
        params["blankFlag"] != undefined &&
        params["blankFlag"] != null &&
        params["blankFlag"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "blankFlag" + "=" + params["blankFlag"];
      }
      if (
        params["searchType"] != undefined &&
        params["searchType"] != null &&
        params["searchType"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "searchType" + "=" + params["searchType"];
      }
      if (
        params["searchOption"] != undefined &&
        params["searchOption"] != null &&
        params["searchOption"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "searchOption" + "=" + params["searchOption"];
      } else {
        if (param != "") {
          param += "&";
        }
        param += "searchOption" + "=0";
      }
      if (
        params["sort"] != undefined &&
        params["sort"] != null &&
        (params["sort"] == String(SORTHORSENAME) ||
          params["sort"] == String(SORTSEX) ||
          params["sort"] == String(SORTAGE) ||
          params["sort"] == String(SORTCLASS))
      ) {
        if (param != "") {
          param += "&";
        }
        param += "sort" + "=" + params["sort"];
      } else {
        if (param != "") {
          param += "&";
        }
        param += "sort" + "=" + 0;
      }
      if (
        params["RaceYear"] != undefined &&
        params["RaceYear"] != null &&
        params["RaceYear"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "RaceYear" + "=" + params["RaceYear"];
      }
      if (
        params["Belong"] != undefined &&
        params["Belong"] != null &&
        params["Belong"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Belong" + "=" + params["Belong"];
      }
      if (
        params["HorseOwnerCd"] != undefined &&
        params["HorseOwnerCd"] != null &&
        params["HorseOwnerCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "HorseOwnerCd" + "=" + params["HorseOwnerCd"];
      }
      if (
        params["TrainerCd"] != undefined &&
        params["TrainerCd"] != null &&
        params["TrainerCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "TrainerCd" + "=" + params["TrainerCd"];
      }
      if (
        params["BreedingNum"] != undefined &&
        params["BreedingNum"] != null &&
        params["BreedingNum"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "BreedingNum" + "=" + params["BreedingNum"];
      }
      if (
        params["BreederCd"] != undefined &&
        params["BreederCd"] != null &&
        params["BreederCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "BreederCd" + "=" + params["BreederCd"];
      }
      if (
        params["ScreenDiv"] != undefined &&
        params["ScreenDiv"] != null &&
        params["ScreenDiv"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "ScreenDiv" + "=" + params["ScreenDiv"];
      }
      if (
        params["Word"] != undefined &&
        params["Word"] != null &&
        params["Word"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Word" + "=" + params["Word"];
      }
      if (
        params["Option"] != undefined &&
        params["Option"] != null &&
        params["Option"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Option" + "=" + params["Option"];
      }

      return param;
    }
  },
  computed: {
    ListItems() {
      let self = this;
      const list = self.useData;
      if (list != undefined && list != null && list.length > 0) {
        return list.slice(0, self.count);
      } else {
        return [];
      }
    },
  },
  methods: {
    isMore() {
      this.count += 30;
    },
  },
};
</script>

<style lang="scss" src="../../assets/css/data/search/style.scss" scoped></style>
