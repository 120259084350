<template>
  <div class="" data-target>
    <div class="item">
      <div class="inner" v-if="raceResultData.length">
        <table class="table-grid -racedetail -previous -center -middle">
          <thead>
            <tr>
              <th>成績</th>
              <th>騎手</th>
              <th>レース詳細</th>
              <th v-if="NARFLAG === 0">動画</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in raceResultData" :key="index">
              <tr :class="item.BgClass">
                <td class="grade -center">
                  {{ item.LeftTitle }}<br />{{ item.AbnormalDiv }}
                </td>
                <td class="jockey -center">
                  {{ item.JockeyName }}<br />{{ item.JockeyWeight }}
                </td>
                <td class="run">
                  <div class="previousrun">
                    {{ item.DOR }} {{ item.Racetrack }}
                    {{ item.RaceEntryNum }} {{ item.HorseNum }}
                    {{ item.WinOdds }}<br />
                    <a v-if="item.RaceResultUrl" :href="item.RaceResultUrl" class="card">
                      {{item.RaceTitle}}
                      <i :class="item.GradeIcon" v-if="item.GradeIcon !== ''"></i>
                    </a>
                    <span v-else class="card">
                      {{ item.RaceTitle }}
                      <i :class="item.GradeIcon" v-if="item.GradeIcon !== ''"></i>
                    </span>
                    <span class="place">
                      {{ item.TrackCd }} {{ item.Distance }} {{ item.TrackCondition }}
                    </span>
                    <br />
                    <div class="info">
                      <span class="time" v-if="item.PrizeRaceTime">
                        <span class="total">
                          {{ item.PrizeRaceTime }}
                        </span>
                        <span v-if="item.FurlongTime">
                          （<i class="txt-rank" :class="item.FurlongClass">
                          {{ item.FurlongTime }}
                          </i>）
                        </span>
                      </span>
                      <span class="hweight">
                        {{ item.Weight }}
                      </span>
                    </div>
                    <div class="info">
                      <span class="blinker">
                        <i
                          v-if="item.BlinkersDiv === '1'"
                          class="icon-blinker">
                        </i>
                      </span>
                      <span class="passing">{{ item.CorneringOrder }}</span>
                      <span class="horse">{{ item.Top2HorseName }}</span>
                    </div>
                  </div>
                </td>
                <td v-if="NARFLAG === 0" class="movie">
                  <template v-for="(i, index) in videoUrlArray" :key="index">
                    <a
                      v-if="
                        i.key === item.key &&
                        i.exist === 1 &&
                        courseId !== PAYINGDATA.PAYINGAPPPASS
                      "
                      :class="{
                        'icon-mov -premium': isNotPremium,
                        'icon-mov': !isNotPremium,
                      }"
                      href="javascript:void(0);"
                      @click="
                        isNotPremium ? openMember(i.url) : openVideoModal(i.url)
                      "
                    ></a>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="inner" v-else>
        <p>競走成績はまだありません。</p>
      </div>
    </div>
    <ShowMovieConfirm
        @openVideoConfirmed="openVideoConfirmed"
        :openMovieConfirm="openMovieConfirm">
    </ShowMovieConfirm>
    <ShowMovie
      @closeModal="openMovie = false"
      :openMovie="openMovie"
      :movieURL="movieURL"
    ></ShowMovie>
  </div>
</template>

<script>
import {
  monthDiv,
  dayDiv,
  isNar,
  PAYINGSUGOTOKU,
  getGradeIconClassNar,
  getGradeIconClass,
} from "../../../assets/js/common";
import {
  HOST,
  RACEPATH,
  DATAPATH,
  JRA_HOST,
  NAR_HOST,
} from "../../../assets/js/define";
import ShowMovie from "../../utils/ShowMovie.vue";
import ShowMovieConfirm from "../../../components/utils/ShowMovieConfirm.vue";
export default {
  name: "HorseRecord",
  data() {
    return {
      NARFLAG: null,
      openMovie: false,
      openMovieConfirm: false,
      movieURL: "",
      raceResultData: [],
    };
  },
  components: { ShowMovie, ShowMovieConfirm },
  props: {
    raceResult: Object,
    videoUrlArray: Array,
    PAYINGDATA: Object,
    courseId: String,
    isNotPremium: Boolean,
  },
  created() {
    let self = this;
    self.NARFLAG = isNar();

    //競走成績（全件表示）
    if (self.raceResult != undefined && self.raceResult != null) {
      let raceResultMax = self.raceResult.length;
      let oddsWishLst = []; //オッズ取得用のリストの初期化
      let nameWishLst = []; //レース名取得用のリストの初期化
      let preDate; //前回のレースの日
      let skipCount = 0
      self.raceResultData = [];
      for (let i = 0; i < raceResultMax; i++) {
        const exist = self.raceResultData.filter(raceResult => {
          return raceResult.DOR === self.raceResult[i].DOR.substr(2, 2) + "/" + monthDiv(self.raceResult[i].DOR) + "/" + dayDiv(self.raceResult[i].DOR);
        })
        if (exist.length) {
          skipCount ++
          continue
        }
        const isRace = true;
        let raceData = self.raceResult[i];
        let raceDataDetail = new Object();
        let leftTitle = (i + 1 + - skipCount) + "走前";
        if (i == 0) leftTitle = "前走";
        if (i == 1) leftTitle = "前々走";
        let dor = raceData.DOR;
        raceDataDetail.LeftTitle = leftTitle;

        //騎手名
        raceDataDetail.JockeyName = raceData.JockeyName;

        //騎手体重
        raceDataDetail.JockeyWeight = (raceData.BurdenWeight / 10).toFixed(1);

        //日付表示
        let date = raceData.DOR;
        date = date.substr(2, 2) + "/" + monthDiv(date) + "/" + dayDiv(date);
        raceDataDetail.DOR = date;

        // ビデオのKey
        raceDataDetail.key =
          raceData.DOR + raceData.RacetrackCd + raceData.RaceNum;

        //開催場
        raceDataDetail.Racetrack = raceData.Racetrack;

        //順位によるクラス分け
        if (
          "AbnormalDiv" in raceData &&
          raceData.AbnormalDiv != null &&
          /^(1|2|3|4|5)$/.test(raceData.AbnormalDiv)
        ) {
          let ACCIDENT_LBL = {
            1: "取消",
            2: "除外",
            3: "除外",
            4: "中止",
            5: "失格",
          };
          raceDataDetail.AbnormalDiv = ACCIDENT_LBL[raceData.AbnormalDiv];
        } else if ("RacePlace" in raceData && raceData.RacePlace != null) {
          let mHtml;
          //着順
          if (Number(raceData.RacePlace) > 0) {
            mHtml = Number(raceData.RacePlace) + "着";
          }
          if (
            "AbnormalDiv" in raceData &&
            raceData.AbnormalDiv != null &&
            /^7/.test(raceData.AbnormalDiv)
          ) {
            //降着時
            mHtml = "降着" + mHtml;
          }

          let racePlaceClass;
          if (raceData.RacePlace == 1) {
            racePlaceClass = "first";
          } else if (raceData.RacePlace == 2) {
            racePlaceClass = "second";
          } else if (raceData.RacePlace == 3) {
            racePlaceClass = "third";
          } else {
            racePlaceClass = "else";
          }

          raceDataDetail.AbnormalDiv = mHtml;
          raceDataDetail.RacePlaceClass = racePlaceClass;
        }

        //レース名設定、会場次第ではレース結果へ遷移
        if (
          "RaceTitle" in raceData &&
          raceData.RaceTitle != null &&
          raceData.RaceTitle !== ""
        ) {
          let url = ''
          if (Number(raceData.RacetrackCd) <= 10 && Number(raceData.DOR) > 20030101) {
            url =
                JRA_HOST +
                RACEPATH +
                "results?DOR=" +
                raceData.DOR +
                "&RacetrackCd=" +
                raceData.RacetrackCd +
                "&RaceNum=" +
                raceData.RaceNum;
          } else if (
            Number(raceData.RacetrackCd) >= 30 &&
            Number(raceData.RacetrackCd) <= 57  &&
            Number(raceData.DOR) >= 20200401
          ) {
            url =
                NAR_HOST +
                RACEPATH +
                "results?DOR=" +
                raceData.DOR +
                "&RacetrackCd=" +
                raceData.RacetrackCd +
                "&RaceNum=" +
                raceData.RaceNum;
          }
          raceDataDetail.RaceResultUrl = url;
        } else {
          //オッズ
          nameWishLst.push({
            DOR: raceData.DOR,
            RacetrackCd: raceData.RacetrackCd,
            RaceNum: raceData.RaceNum,
            HorseNum: raceData.HorseNum,
            OddsId: i,
          });
        }

        let raceRanking = (raceData.RacePlace *= 1);
        let bgClass = "run";
        if (raceRanking <= 3) {
          if (raceRanking == 1) {
            bgClass += " bg-rank01";
          } else if (raceRanking == 2) {
            bgClass += " bg-rank02";
          } else if (raceRanking == 3) {
            bgClass += " bg-rank03";
          }
        }
        raceDataDetail.BgClass = bgClass;

        let slicedTrack = raceData.Track.slice(0,1)
        if (raceData.TrackRotationDiv == "1") {
          slicedTrack += "左";
        } else if (raceData.TrackRotationDiv == "2") {
          slicedTrack += "右";
        } else if (raceData.TrackRotationDiv == "3") {
          slicedTrack += "直線";
        }
        raceDataDetail.TrackCd = slicedTrack;

        //近走成績下段
        //出走頭数
        let raceEntryNum = "";
        if (
          raceData.RaceEntryNum != undefined ||
          raceData.RaceEntryNum != null
        ) {
          raceEntryNum = raceData.RaceEntryNum + "頭";
        }
        raceDataDetail.RaceEntryNum = raceEntryNum;

        //枠番、馬番
        let bracketNum;
        if (raceData.BracketNum != undefined || raceData.BracketNum != null) {
          bracketNum = raceData.BracketNum + "枠";
        }
        let horseNum;
        if (raceData.HorseNum != undefined || raceData.HorseNum != null) {
          horseNum = raceData.HorseNum + "番";
        }
        raceDataDetail.BracketNum = bracketNum;
        raceDataDetail.HorseNum = horseNum;

        //オッズ
        oddsWishLst.push({
          DOR: raceData.DOR,
          RacetrackCd: raceData.RacetrackCd,
          RaceNum: raceData.RaceNum,
          HorseNum: raceData.HorseNum,
          FixedWinPopularRank: raceData.FixedWinPopularRank,
          OddsId: i,
        });

        //人気
        let winOdds;
        if (raceData.WinOdds && raceData.WinOdds > 0) {
          winOdds = raceData.WinOdds + "人気";
        }
        raceDataDetail.WinOdds = winOdds;

        //レース名
        raceDataDetail.RaceTitle = raceData.RaceTitle.substr(0, 6);

        //グレードコード
        let gradeIcon = "";
        if (Number(raceData.RacetrackCd) >= 30 && Number(raceData.RacetrackCd) <= 57 ) {
          gradeIcon =getGradeIconClassNar(raceData.GradeCd, raceData.RaceTitle, raceData.RaceRank)
        } else {
          if (raceData.SpecialRaceNum &&raceData.SpecialRaceNum !== '0000') {
            gradeIcon = getGradeIconClass(raceData.GradeCd, raceData.TrackTypeCd, raceData.RaceRegulationCd)
          }
        }
        raceDataDetail.GradeIcon = gradeIcon;

        //距離
        raceDataDetail.Distance = raceData.Distance;

        //トラックコンディション
        raceDataDetail.TrackCondition = raceData.TrackCondition;

        // ブリンカー区分
        raceDataDetail.BlinkersDiv = raceData.BlinkersDiv;

        //タイム
        let raceTime;
        if (
          raceData.PrizeRaceTime != undefined ||
          raceData.PrizeRaceTime != null
        ) {
          let time = raceData.PrizeRaceTime;
          if (time.length == 5) {
            let oneDecimal = time.substr(4, 1);
            if (oneDecimal.length == 0) {
              oneDecimal = 0;
            }
            raceTime = parseInt(time.substr(0, 2), 10) + ":";
            raceTime += time.substr(2, 2) + ".";
            raceTime += oneDecimal;
          } else if (time.length == 4) {
            raceTime = time.substr(0, 1) + ":";
            raceTime += time.substr(1, 2) + ".";
            raceTime += time.substr(3, 1);
          }

          if (raceTime == "0:00.0") {
            raceTime = "";
          }
        }
        raceDataDetail.PrizeRaceTime = raceTime;

        let furlongTime;
        if (raceData.FurlongTime != undefined || raceData.FurlongTime != null) {
          if (raceData.FurlongTime != "9999") {
            //取消・除外・中止(=9999)の場合、表示しない
            let furlongClass = "";
            if (raceData.FurlongFlg == 1) {
              furlongClass = "-no1";
            } else if (raceData.FurlongFlg == 2) {
              furlongClass = "-no2";
            } else if (raceData.FurlongFlg == 3) {
              furlongClass = "-no3";
            }
            furlongTime = raceData.FurlongTime;
            furlongTime = String(parseInt(furlongTime, 10) / 10);
            if (furlongTime.indexOf(".") == -1) {
              furlongTime = furlongTime + ".0";
            }

            if (furlongTime == "(0.0)") {
              furlongTime = "";
            }
            raceDataDetail.FurlongClass = furlongClass;
          }
        }
        raceDataDetail.FurlongTime = furlongTime;

        //優勝馬
        let top2HorseName;
        if (
          raceData.Top2HorseName != undefined ||
          raceData.Top2HorseName != null
        ) {
          top2HorseName = raceData.Top2HorseName;
        }
        let topUrl = "#";
        if (
          raceData.WinnerPedigreeNum != undefined &&
          raceData.WinnerPedigreeNum != null &&
          raceData.WinnerPedigreeNum != ""
        ) {
          topUrl =
            HOST + DATAPATH + "horse?PedigreeNum=" + raceData.WinnerPedigreeNum;
        }
        raceDataDetail.WinnerPedigreeNumUrl = topUrl;

        raceDataDetail.CorneringOrder = raceData.CorneringOrder;

        let timeGapWithTop2 = '';
        if (raceTime !== '' && raceData.TimeGapWithTop2 !== undefined && raceData.TimeGapWithTop2 !== null) {
          let timeGap = raceData.TimeGapWithTop2;
          if (
            (String(timeGap).indexOf(".") == -1 && timeGap.length == 3) ||
            (String(timeGap).indexOf(".") == -1 && timeGap.length == 4)
          ) {
            timeGap = timeGap * 1;
            if (timeGap == 0) {
              timeGap = "0.0";
            } else {
              if (timeGap > 0 || 0 > timeGap) {
                timeGap = timeGap / 10;
                if ("RacePlace" in raceData && raceData.RacePlace != null) {
                  if (
                    raceData.RacePlace * 1 == 1 &&
                    String(timeGap).substr(0, 1) != "-"
                  ) {
                    timeGap = "-" + timeGap;
                  } else if (
                    raceData.RacePlace * 1 != 1 &&
                    String(timeGap).substr(0, 1) == "-"
                  ) {
                    timeGap = String(timeGap).substr(
                      1,
                      String(timeGap).length - 1
                    );
                  }
                }
                if (String(timeGap).indexOf(".") == -1) {
                  timeGap += ".0";
                }
              }
            }
          } else if (timeGap == "999.9") {
            timeGap = "";
          } else if (100 <= timeGap * 1) {
            let tmp_min = Math.floor(timeGap / 100);
            let tmp_sec = tmp_min * 60;
            timeGap = timeGap - tmp_min * 100 + tmp_sec;
          } else if (timeGap != "" && String(timeGap).indexOf(".") == -1) {
            timeGap = timeGap + ".0";
          }

          if (timeGap == "") {
            timeGapWithTop2 = "";
          } else if (timeGap != "999.9") {
            if (timeGap > 0) {
              timeGap = '+' + timeGap
            }
            timeGapWithTop2 = "(" + timeGap + ")";
          }
        }
        raceDataDetail.Top2HorseName = top2HorseName + timeGapWithTop2;

        //馬体重

        let horse_weight = "";
        if (raceData.Weight != undefined && raceData.Weight != 0) {
          //登録されていないか、出走取消（=000）の場合
          if (raceData.Weight == 999) {
            //今走計量不能（=999）の場合
            horse_weight += "計不";
          } else if (raceData.Weight == 998) {
            //計量不能（=998）の場合
            horse_weight += "";
          } else {
            horse_weight += String(raceData.Weight);
          }
        }
        if (raceData.WeightDifference != undefined) {
          if (raceData.WeightDifference < 999) {
            //前走計測不能時（=999）か初出走、出走取消（=0）以外のみ表示
            horse_weight +=
              " (" +
              (raceData.WeightDifference > 0
                ? "+" + raceData.WeightDifference
                : raceData.WeightDifference) +
              ")";
          }
        }
        raceDataDetail.Weight = horse_weight;

        self.raceResultData.push(raceDataDetail);
      }
    }
  },
  methods: {
    openVideoModal: function (url) {
      if (this.courseId === PAYINGSUGOTOKU) {
        this.openMovieConfirm = true;
      } else {
        this.openMovie = true;
      }
      this.movieURL = url;

    },
    openVideoConfirmed: function (confirmed) {
      this.openMovieConfirm = false;
      this.openMovie = confirmed;
    },
    openMember: function (url) {
      location.href = url;
    },
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>
