<script setup>
import { onMounted, ref } from "vue";
import {
  COURSEID,
  getJSON,
  SUCCESS,
  setCookie,
  USERID,
  PAYINGNONE,
  retrieveCourse,
  setMailAddres
} from "../../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  JOINPATH
} from "../../../assets/js/define";

const userId = ref('')
const currentEmail = ref('');
const newEmail = ref('');
const errMessage = ref('')

const settingMailLink = HOST + "support/mail/setting";

//現在登録中のメールアドレスを記述
function setMailConfigs() {
  document.body.className = "";

  //ログイン情報取得
  let request = HOST + PATH + "getLoginInfo.js" + BACKWORDPATH;

  getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json != undefined) {
      let result = json;
      userId.value = result.userId;

      if (
        userId.value != undefined &&
        userId.value != null &&
        userId.value != "" &&
        result.mailAddress != undefined &&
        result.mailAddress != null &&
        result.mailAddress != ""
      ) {
        //ユーザID
        setCookie(USERID, userId.value);

        //コースID
        let courseId = result.courseId;
        setCookie(COURSEID, courseId);

        //登録アドレスの取得
        currentEmail.value = result.mailAddress;

        document.body.className = "status-loaded";
      } else if (
        userId.value === undefined ||
        userId.value === "" ||
        userId.value === null
      ) {
        //未ログイン
        const loginLink = HOST + "support/mail/settings_nologin";
        location.replace(loginLink);
      } else if (
        result.mailAddress === undefined ||
        result.mailAddress === "" ||
        result.mailAddress === null
      ) {
        //メールアドレス削除済み
        const registerLink = HOST + "support/mail/settings_registmail";
        location.replace(registerLink);
      }
    } else {
      alert("設定情報の取得に失敗しました。");

      document.body.className = "status-loaded";
    }
  });
}

const updateButton = async () => {
  if (!validateEmail(newEmail.value)) {
    errMessage.value = 'メールアドレスの形式が正しくありません。'
    return;
  }

  if (currentEmail.value === newEmail.value) {
    errMessage.value = 'メールアドレスが変更されていません。'
    return;
  }

  document.body.className = "";
  const request =
        HOST +
        PATH +
        'Email/setEmailAddress.js' +
        BACKWORDPATH +
        '?userId=' +
        encodeURIComponent(userId.value) +
        '&email=' +
        newEmail.value

  await getJSON(request, false, function (status, json) {
    if (status == SUCCESS) {
      setMailAddres(newEmail.value)
      location.replace(HOST + 'support/mail/setting');
    } else {
      errMessage.value = 'エラー<br>通信中に問題が発生したため、登録できませんでした。'
    }
  });
  document.body.className = "status-loaded";
}

const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    return regex.test(email);
}

//フォームを選択したタイミングで、登録時のエラーメッセージを非表示にする
function isSelect() {
  errMessage.value = '';
}

onMounted(() => {
  callRetrieveCourse(function (courseId) {
    if (courseId === PAYINGNONE) {
      location.href = HOST + JOINPATH;
    } else {
      setMailConfigs();
    }
  });
});
const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false);
  }
};

</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">WIN!競馬メール配信 設定</h1>
      <div class="nav">
        <a :href="settingMailLink" class="btn-basic">メール配信設定へ</a>
      </div>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner">
          <div class="layout-title">
            <h2 class="heading-circle -padding">メールアドレス変更</h2>
          </div>
          <ul class="list-disc">
            <li>
              ご登録頂いたメールアドレスは競馬情報のメール配信以外の目的では使用致しません。
            </li>
            <li>
              登録情報はプライバシーポリシーに基づき、適切に管理致します。<a
                href="/support/policy/"
                >プライバシーポリシーはこちらから。</a
              >
            </li>
          </ul>
          <ul class="list-option">
            <li>
              ご登録にあたって、@winkeiba.jpからのメールを受信できる設定にしてください。
            </li>
          </ul>
          <dl class="form-set -mailSetting">
            <dt>現在のメールアドレス</dt>
            <dd class="_bold">{{ currentEmail }}</dd>
            <dt>新しいメールアドレス</dt>
            <dd>
              <div
                v-if="errMessage"
                v-html="errMessage"
                class="system-message -center -error"
              ></div>
              <input
                @click="isSelect"
                v-model="newEmail"
                type="text"
                class="form-text"
                placeholder="sample@winkeiba.jp"
              />
            </dd>
          </dl>
          <div class="nav-group">
            <a @click="updateButton()" class="btn-basic -wide">メールアドレスを変更する</a>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
