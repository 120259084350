<script setup>
import { ref, reactive, onMounted } from "vue";
import { HOST, JRA_WP_HOST, PATH, BACKWORDPATH } from "../../../assets/js/define";
import {
  PAYINGNONE,
  PAYINGECONOMY,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  SUCCESS,
  getJSON,
} from "../../../assets/js/common";
import axios from "axios";

const API_URL = JRA_WP_HOST + "wp-json/wp/v2/sumii?_fields=name,slug,id,title";

let list = reactive({});

onMounted(() => {
  document.body.className = "";
  isPremiumUser();
  axios
    .get(API_URL)
    .then((response) => {
      processLink(response.data);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});

const processLink = (item) => {
  for (let i = 0; i < item.length; i++) {
    item[i].link = HOST + "column/sumii/article" + "?id=" + item[i].id;
  }
  Object.assign(list, item);
};

const premiumUser = ref(false);
const isPremiumUser = () => {
  //ユーザのコース情報を取得し、プレミアム以外であれば各会員登録ページへ遷移
  const logReq = HOST + PATH + "getUserCourse.js" + BACKWORDPATH;
  let courseId = PAYINGNONE;
  getJSON(logReq, true, function (status, json) {
    if (status === SUCCESS) {
      if (json && json.courseId && json.courseId != "") {
        courseId = json.courseId;
        if (courseId === PAYINGPREMIUM ||
            courseId === PAYINGPACK03 ||
            courseId === PAYINGPACK06 ||
            courseId === PAYINGPACK12) {
          premiumUser.value = true;
        } else if (courseId === PAYINGECONOMY) {
          window.location.href = HOST + "member/join_economy";
        } else if (courseId === PAYINGNONE) {
          window.location.href = HOST + "member";
        }
      }
    }
  });
};
</script>

<template>
  <main class="layout-main" v-if="premiumUser === true">
    <section class="layout-pageheader">
      <h1 class="heading-page">独占コラム</h1>
    </section>
    <section class="layout-section">
      <img src="/assets/images/clumn/sumii/column_banner_2.png" alt="" />
      <ul class="list-column -sumii">
        <li class="item" v-for="(item, index) in list" :key="index">
          <div class="summary">
            <a :href="item.link" class="link-cell">
              <div class="title">{{ item.title.rendered }}</div>
            </a>
          </div>
        </li>
      </ul>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../../assets/css/news/style.scss" scoped></style>
