<script setup>
import { ref } from "vue";
const emit = defineEmits(["closeDialog4", "receiveAmountAvgItem"]);

const amount = ref('1');

const props = defineProps({
  modalAmountAvgOpen: Boolean,
});

const modalCloseOK = () => {
  document.body.className = "";
  emit("receiveAmountAvgItem", amount.value);
};
const modalClose = () => {
  emit("closeDialog4", false);
};

</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalAmountAvgOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div @click.self="modalClose" v-if="modalAmountAvgOpen" class="modal-frame">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                配当均等
              </div>
              <div class="modal-body">
                <p>
                  全馬券に賭ける総額を指定します。
                </p>
                <input type="number" v-model="amount" max="9999" min="1"
                  class="form-text -count">00円
                <p class="p-caption">※オッズにより配当が均等になるよう調整され、組み合わせが解除されます。</p>
                <div class="modal-nav">
                  <span class="btn-basic -gray close-modal" @click="modalClose">
                    キャンセル
                  </span>
                  <span class="btn-basic" @click="modalCloseOK">
                    OK
                  </span>
                </div>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-text.-count {
  width: 6em;
  margin: 0 6px 0 0;
}
</style>
