<template>
  <section class="layout-pageheader -leading">
    <h1 class="heading-page">
      <router-link to="/leading/">
        リーディング
      </router-link>
    </h1>
  </section>
  <nav class="nav-multi">
    <ul class="list">
      <li :class="{'-current' : current === 'jockey'}">
        <router-link to="/leading/jockey" class="label">
          騎手
        </router-link>
      </li>
      <li :class="{'-current' : current === 'jockey_weightloss'}">
        <router-link to="/leading/jockey_weightloss" class="label">
          減量騎手
        </router-link>
      </li>
      <li :class="{'-current' : current === 'trainer'}">
        <router-link to="/leading/trainer" class="label">
          調教師
        </router-link>
      </li>
      <li :class="{'-current' : current === 'owner'}">
        <router-link to="/leading/owner" class="label">
          馬主
        </router-link>
      </li>
      <li :class="{'-current' : current === 'breeder'}">
        <router-link to="/leading/breeder" class="label">
          生産者
        </router-link>
      </li>
      <li :class="{'-current' : current === 'stud'}">
        <router-link to="/leading/stud" class="label">
          種牡馬
        </router-link>
      </li>
      <li :class="{'-current' : current === 'new_stallion'}">
        <router-link to="/leading/new_stallion" class="label">
          新種牡馬
        </router-link>
      </li>
      <li :class="{'-current' : current === 'bms'}">
        <router-link to="/leading/bms" class="label">
          母の父
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    current: String,
  },
};
</script>

<style lang="scss" src="../../assets/css/leading/style.scss" scoped></style>
