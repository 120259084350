<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  setDismissDialog,
  getUserId,
  SUCCESS,
  isDismissDialog,
  getCourse,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  isNar,
  isAndroid,
  getUserAgent,
} from "../../../assets/js/common";

import {
  HOST,
  PATH,
  BACKWORDPATH,
  TOB_TRIFECTA,
  METHOD_BOX,
} from "../../../assets/js/define";

import MyStampModal from "../../utils/MyStampModal.vue";
import SetBetConfModal from "../../utils/SetBetConfModal.vue";
import raceOdds from "../../../functions/odds/race-odds";
import raceDeadline from "../../../functions/odds/race-deadline_countdown";
import myMark from "../../../functions/odds/race-mymark";
import raceCommon from "../../../functions/odds/race-common";
import {useRoute} from "vue-router";

const { setRaceBetByArray, sortTioOdds } = raceOdds();
const { sortTrioNum } = raceCommon();
const { TimerCount } = raceDeadline();
const { loadMyMarkForDrop2, setMyMark } = myMark();

let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let deadLineJudge = ref(false);
let oddsUpdInfo = ref("");
let isOddsInfo = ref(false);
let loadedPage = ref(false)
const activeSortId = ref("sort_num");
const sortType = ref(3);
const showLimit = 50;

let params = {};

const route = useRoute();
watch(route, (from, to) => {
  clearSelected();
  loadPromisingBet();
  doInit(to.query);
})

onMounted(() => {
  document.body.className = "";
  params = getUrlVars();
  //パラメータチェック
  if (checkUrlVars(params)) {
    loadPromisingBet()
    doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});

const props = defineProps({
  raceDORVal: String,
  raceTrackCdVal: String,
  raceNumVal: String,
  deadLineJudge: Boolean,
});

let stamp = reactive({});
const stampClass = (num) => {
  if (stamp[num].selectedNum == 0) {
    return "stamp-mark -none";
  } else if (stamp[num].selectedNum == 1) {
    return "stamp-mark -honmei";
  } else if (stamp[num].selectedNum == 2) {
    return "stamp-mark -taikou";
  } else if (stamp[num].selectedNum == 3) {
    return "stamp-mark -tanana";
  } else if (stamp[num].selectedNum == 4) {
    return "stamp-mark -renka";
  } else if (stamp[num].selectedNum == 5) {
    return "stamp-mark -hoshi";
  } else return "stamp-mark -none";
};
const stampClassTable = (num) => {
  if (num == 0) {
    return "stamp-mark -none";
  } else if (num == 1) {
    return "stamp-mark -honmei";
  } else if (num == 2) {
    return "stamp-mark -taikou";
  } else if (num == 3) {
    return "stamp-mark -tanana";
  } else if (num == 4) {
    return "stamp-mark -renka";
  } else if (num == 5) {
    return "stamp-mark -hoshi";
  } else return "stamp-mark -none";
};
const oddsClass = (val) => {
  if (val == "---.-") {
    return "txt-odds";
  } else if (val > 0 && val < 10) {
    return "txt-odds -no1";
  } else if (val > 0 && val < 100) {
    return "txt-odds -no2";
  } else return "txt-odds -other";
};
//チェックリスト関連

//　選択した馬の組み合わせ表示用
let checkList = reactive({});
let checkedSum = ref(0);
let checkAllJudge = ref(false);
let selectList = reactive([]);
let oddsTable = reactive([]);
let checkStorageArray = reactive([])

const loadPromisingBet = () => {
  const checkListStorage = localStorage.getItem('trifecta_box' + location.search)
  if (checkListStorage) checkStorageArray = JSON.parse(checkListStorage)
}
const checkChange = (num, horseNum) => {
  selectList.splice(0);
  //相手馬
  if (checkList[num] === true) {
    checkList[num] = false;
    checkStorageArray?.splice(checkStorageArray?.indexOf(String(num)),1)
  } else {
    checkList[num] = true;
    checkStorageArray?.push(String(num))
  }

  for (let i = 0; i < 18; i++) {
    if (checkList[i] === true) {
      selectList.push(i);
    }
  }

  localStorage.setItem('trifecta_box' + location.search, JSON.stringify(checkStorageArray))
  checkCount();
};

const checkedHorseSum = () => {
  let checkedNum = 0;
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    if (checkList[i] === true) {
      checkedNum++;
    }
  }
  checkedSum.value = checkedNum;
};

const checkedAll = () => {
  if (checkAllJudge.value === true) {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      checkList[i] = false;
      checkStorageArray?.splice(checkStorageArray?.indexOf(String(i)),1)
    }
    checkAllJudge.value = false;
  } else {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      if (horses[i].AbnormalDiv === "1" || horses[i].AbnormalDiv === "3" || !horses[i].Odds) {
        //取消・中止はチェックしない
        checkList[i] = false;
      } else {
        checkList[i] = true;
        if (checkStorageArray?.includes(String(i))) continue
        checkStorageArray?.push(String(i))
      }
    }
    checkAllJudge.value = true;
  }
  selectList.splice(0);
  for (let i = 0; i < 18; i++) {
    if (checkList[i] === true) {
      selectList.push(i);
    }
  }
  localStorage.setItem('trifecta_box' + location.search, JSON.stringify(checkStorageArray))
  checkCount();
};
// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkList,() => {
  const checkLists = Object.values(checkList)
  if(checkLists.length) checkAllJudge.value = checkLists.find(check => check === false) === undefined
},{immediate:true})
//チェックカウント
let counts = ref(0);
const checkCount = () => {
  counts.value = 0;
  let count = selectList.length;
  if (count == 3) {
    counts.value = 6;
  }
  if (count == 4) {
    counts.value = 24;
  }
  if (count == 5) {
    counts.value = 60;
  }
  if (count == 6) {
    counts.value = 120;
  }
  if (count == 7) {
    counts.value = 210;
  }
  if (count == 8) {
    counts.value = 336;
  }
  if (count == 9) {
    counts.value = 504;
  }
  if (count == 10) {
    counts.value = 720;
  }
  if (count == 11) {
    counts.value = 990;
  }
  if (count == 12) {
    counts.value = 1320;
  }
  if (count == 13) {
    counts.value = 1716;
  }
  if (count == 14) {
    counts.value = 2184;
  }
  if (count == 15) {
    counts.value = 2730;
  }
  if (count == 16) {
    counts.value = 3360;
  }
  if (count == 17) {
    counts.value = 4080;
  }
  if (count == 18) {
    counts.value = 4896;
  }
  setSelected();
};
const wheelHeading = ref("");
let checkNumInfo = reactive([]);
const setSelected = () => {
  try {
    if (counts.value >= 3) {
      //買い目テーブル初期化
      checkNumInfo.splice(0);

      for (let j = 0; j < selectList.length; j++) {
        for (let k = 0; k < selectList.length; k++) {
          if (j == k) {
            continue;
          }
          for (let l = 0; l < selectList.length; l++) {
            if (j == l || k == l) {
              continue;
            }
            let odds =
              oddsForTable[
                "Odds" +
                  String(selectList[j] + 1) +
                  "_" +
                  String(selectList[k] + 1) +
                  "_" +
                  String(selectList[l] + 1)
              ];
            let odds_class = "";
            if (odds == undefined) {
              odds = "-";
            } else if (odds != "-") {
              if (parseFloat(odds) < 10) {
                odds_class = " -no1";
              } else if (parseFloat(odds) < 100) {
                odds_class = " -no2";
              }
            }
            const info = {
              mark1: stamp[selectList[j]].selectedNum,
              mark2: stamp[selectList[k]].selectedNum,
              mark3: stamp[selectList[l]].selectedNum,
              firstHorseNum: selectList[j] + 1,
              secondHorseNum: selectList[k] + 1,
              thirdHorseNum: selectList[l] + 1,
              odds: odds,
              odds_class: odds_class,
            };
            checkNumInfo.push(info);
          }
        }
      }

      //ソート
      checkNumInfo.sort(sortTioOdds(sortType.value));
      oddsTable.splice(0);
      setOdds();
      isOddsInfo.value = true;
    } else {
      //2つ以上の馬番が選択されていないので、オッズと買い目は非表示
      isOddsInfo.value = false;
    }
  } catch (e) {
    console.log(e);
  }
};

const isShowMore = ref(false);
const setOdds = () => {
  //初回は現在表示しているところから、50件分のHTMLを表示
  //もっと見るを押下するごとに+50件表示させる
  const nowOddsTableLength = oddsTable.length;
  for (
    let i = nowOddsTableLength;
    i < checkNumInfo.length && i < nowOddsTableLength + showLimit;
    i++
  ) {
    oddsTable.push(checkNumInfo[i]);
  }

  if (oddsTable.length === counts.value) {
    isShowMore.value = false;
  } else {
    isShowMore.value = true;
  }
};

//ソートを変更
const changeSort = (id) => {
  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_my") {
      if (sortType.value === 1) {
        sortType.value = 2;
      } else {
        sortType.value = 1;
      }
      activeSortId.value = "sort_my";
    } else if (id === "sort_num") {
      if (sortType.value === 3) {
        sortType.value = 4;
      } else {
        sortType.value = 3;
      }
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      if (sortType.value === 5) {
        sortType.value = 6;
      } else {
        sortType.value = 5;
      }
      activeSortId.value = "sort_odds";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを昇順でセットする
    if (id === "sort_my") {
      sortType.value = 1;
      activeSortId.value = "sort_my";
    } else if (id === "sort_num") {
      sortType.value = 3;
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      sortType.value = 5;
      activeSortId.value = "sort_odds";
    }
  }
  setSelected();
};

// modal
let left = ref("");
let top = ref("");
const openModal = (event, win) => {
  document.body.className = "status-loaded status-haslayerbg status-showstamp";
  // x座標
  left.value = event.pageX;
  // y座標
  top.value = event.pageY;
  stamp[win - 1].status = true;
};
const closeStampModal = (win) => {
  document.body.className = "status-loaded";
  if (stamp[win.num].selectedNum != win.selectedNum) {
    //my印を新しくセットした場合は、my印を更新する
    stamp[win.num].selectedNum = win.selectedNum;
    setMyMark(
      getUserId(),
      props.raceDORVal,
      props.raceTrackCdVal,
      props.raceNumVal,
      win.num + 1,
      stamp[win.num].selectedNum
    );
  }
  stamp[win.num].status = false;
};
// horses
let horses = reactive([]);
let mark = reactive({});
let marks = reactive({});
let oddsForTable = reactive({});
const doInit = async (params) => {
  marks = await loadMyMarkForDrop2(
    getUserId(),
    params.DOR,
    params.RacetrackCd,
    params.RaceNum
  );
  loadCandidacyTableForOddsBasic(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum,
    (result) => {
      // オッズ
      loadOddsWin(params.DOR, params.RacetrackCd, params.RaceNum);
    }
  );
  // 組み合わせのオッズ
  retrieveOdds(params.DOR, params.RacetrackCd, params.RaceNum, (result) => {
    oddsForTable = Object.assign(oddsForTable, result);
  });


};

let cancelList = reactive([]);
const loadCandidacyTableForOddsBasic = (
  dor,
  racetrackCd,
  raceNum,
  callback
) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "RaceInfo/candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == "success") {
      horses = Object.assign(horses, json["EntryHorses"]);
      //my印及びチェックボックスをデフォルト値をセット

      for (let i = 0; i < horses.length; i++) {
        let selectNum = 0;
        if (
          marks &&
          marks["MyNo" + (i + 1)] != undefined &&
          marks["MyNo" + (i + 1)] != "" &&
          marks["MyNo" + (i + 1)] != null
        ) {
          selectNum = marks["MyNo" + (i + 1)];
        }
        //出馬取消馬の場合は各ボタンを配置しないためのフラグ
        if (horses[i].AbnormalDiv === "1" || horses[i].AbnormalDiv === "3") {
          cancelList[i] = true;
        } else {
          cancelList[i] = false;
        }

        stamp[i] = { status: false, selectedNum: selectNum };
        checkList[i] = checkStorageArray?.includes(String(i)) ? true : false;
        if (checkList[i] === true) selectList.push(i);
      }
      checkCount();
    }
    if (callback != undefined) {
      callback();
    }
  });
};

//オッズの組み合わせを取得
const retrieveOdds = (dor, racetrackCd, raceNum, callback) => {
  const jsName = "trifectaOdds.js";

  try {
    const req =
      HOST +
      PATH +
      "Odds/" +
      jsName +
      BACKWORDPATH +
      "?DOR=" +
      dor +
      "&RacetrackCd=" +
      racetrackCd +
      "&RaceNum=" +
      raceNum +
      "&NarFlag=" +
      isNar();
    getJSON(req, true, function (status, json) {
      if (status == SUCCESS) {
        //データは常に１件が正しい。
        if (json.length == 1) {
          if (json[0].OddsUpdInfo != null) {
            oddsUpdInfo.value = json[0].OddsUpdInfo;
          }
          if (callback != undefined) {
            callback(json[0]);
          }
        } else {
          if (callback != undefined) {
            callback({});
          }
        }
      } else {
        if (callback != undefined) {
          callback({});
        }
      }
    });
  } catch (e) {
    if (callback != undefined) {
      callback({});
    }
  }
};

//オッズを取得する
let oddsWin = reactive([]);
const loadOddsWin = (DOR, RacetrackCd, RaceNum) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "Odds/winTicketOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    DOR +
    "&RacetrackCd=" +
    RacetrackCd +
    "&RaceNum=" +
    RaceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      //データは常に１件が正しい。
      if (json.length == 1) {
        const displayData = json[0];

        // オッズを格納
        for (let i = 0; i < horses.length; i++) {
          horses[i].Odds = displayData["Odds" + (i + 1)];
        }
      } else {
        location.href =
            HOST +
            "race/card?DOR=" +
            DOR +
            "&RacetrackCd=" +
            RacetrackCd +
            "&RaceNum=" +
            RaceNum;
      }
    }
    loadedPage.value = true
    document.body.className = "status-loaded";
  });
};

// 買い目登録
const setRaceBetWin = () => {
  let setRaceBetParams = getUrlVars();
  let openWindow
  if (isDismissDialog() == 1 && !isAndroid() && !getUserAgent()) {
    openWindow = window.open('', '_blank')
  }

  if (props.raceDORVal != null) {
    setRaceBetParams["DOR"] = props.raceDORVal;
  }
  if (props.raceTrackCdVal != null) {
    setRaceBetParams["RacetrackCd"] = props.raceTrackCdVal;
  }
  if (props.raceNumVal != null) {
    setRaceBetParams["RaceNum"] = props.raceNumVal;
  }
  let mainChekFlg = "";
  let betCount = 0;

  for (let i = 0; i < 18; i++) {
    if (checkList[i] === true) {
      mainChekFlg += "1";
      betCount++;
    } else {
      mainChekFlg += "0";
    }
  }

  if (betCount > 2) {
    let betArray = new Array();
    let doFlgArray = new Array();
    let methodType = METHOD_BOX;
    let tobType = TOB_TRIFECTA;
    betArray.push(mainChekFlg);
    doFlgArray.push(true);
    //順番に登録させるため再帰的に登録
    setRaceBetByArray(
      setRaceBetParams,
      tobType,
      methodType,
      betArray,
      doFlgArray,
      function () {
        if (isDismissDialog() != 1) {
          // AppPassはダイアログ非表示
          if (getCourse() == PAYINGAPPPASS) {
            //ダイアログ非表示フラグON
            setDismissDialog();
          } else {
            //モーダルウィンドウ表示
            raceBetCheck.value = true;
            modalBetOpen.value = true;
          }
        } else {
          let url = HOST + "purchase";
          if (
            (getCourse() == PAYINGSUGOTOKU ||
            getCourse() == PAYINGSUGOTOKU_NONE) && !getUserAgent()
          ) {
            url = location.pathname + "?url=" + HOST + "purchase";
          }

          if (isAndroid() || getUserAgent()) {
            location.href = url;
          }
          else { openWindow.location.href = url }
        }
      }
    );
    checkStorageArray.splice(0)
    localStorage.removeItem('trifecta_box' + location.search)
  } else {
    alert("1着馬と2着馬に有効な馬番を設定してください");
  }
};
// 買い目削除
const deleteSelected = () => {
  const result = confirm('3連単/ボックスに対して全ての買い目を削除しますか？')
  if (result) {
    clearStorage();
    clearSelected();
  }
}

const clearStorage = () => {
  localStorage.removeItem('trifecta_box' + location.search)
}

const clearSelected = () => {
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    checkList[i] = false;
  }
  oddsTable.splice(0);
  selectList.splice(0);
  checkStorageArray.splice(0)
  checkAllJudge.value = false
  checkCount();
}
</script>

<template>
  <section class="layout-section" v-if="loadedPage">
    <div class="layout-title">
      <h2 class="heading-circle -padding">3連単 / ボックス</h2>
      <div class="nav">
        <span v-if="props.deadLineJudge" class="status">締め切り間近</span>
        <span :class="[oddsUpdInfo ? 'icon-kakutei' : '']">{{
          oddsUpdInfo
        }}</span>
      </div>
    </div>
    <table class="table-grid -fit -middle -center -odds">
      <thead>
        <tr>
          <th class="mystamp">my印</th>
          <th>馬番</th>
          <th>馬名</th>
          <th>単オッズ</th>
          <th class="ranking">
            <span class="_nowrap"></span
            ><span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  value=""
                  name="sample1"
                  @click="checkedAll()"
                  v-model="checkAllJudge" /><i></i></label
            ></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(h, index) in horses"
          :key="h.horseNum"
          :class="[
            h.AbnormalDiv === '1' ? '-cancel' : '',
            h.AbnormalDiv === '3' ? '-cancel' : '',
            h.Odds ? '' : '-cancel',
          ]"
        >
          <td class="mystamp">
            <span
              :class="stampClass(h.HorseNum - 1)"
              @click="openModal($event, h.HorseNum)"
            ></span>
            <MyStampModal
              @closeModal="closeStampModal($event)"
              v-if="stamp[h.HorseNum - 1].status === true"
              :top="top"
              :left="left"
              :stampNum="h.HorseNum - 1"
              :selectStamp="stamp[h.HorseNum - 1].selectedNum"
            />
          </td>
          <td>
            <i
              :class="[
                h.BracketNum < 10
                  ? `icon-umaban -waku0${h.BracketNum}`
                  : `icon-umaban -waku0${h.BracketNum}`,
              ]"
              >{{ h.HorseNum }}</i
            >
          </td>
          <td class="name">{{ h.HorseName }}</td>
          <td class="-right">
            <div class="txt-odds -other" v-if="h.AbnormalDiv === '1' || h.AbnormalDiv === '3'">
              <span v-if="h.AbnormalDiv === '1'">取消</span>
              <span v-else>除外</span>
            </div>
            <div v-else class="txt-odds" :class="oddsClass(h.Odds)">
              {{ h.Odds }}
            </div>
          </td>

          <td>
            <span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  @click="checkChange(index, 'horse2')"
                  v-model="checkList[h.HorseNum - 1]"
                  value=""
                  name="" /><i></i></label
            ></span>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="nav-group -right">
      <span
        @click="deleteSelected"
        class="btn-basic -delete"
        >削除</span
      >
    </nav>
    <div class="container-total">
      <dl class="amount">
        <dt>点数合計</dt>
        <dd>
          <span class="num">{{ counts }}</span
          >点
        </dd>
      </dl>
      <div v-show="isOddsInfo" @click="setRaceBetWin" class="btn-basic">
        買い目登録
      </div>
    </div>
    <div v-show="isOddsInfo" class="container-wrapper -pad">
      <h3 class="heading-bar _center">ボックス　選択馬番</h3>
      <table class="table-grid -middle -selectednumber">
        <tr>
          <th class="_nowrap">選択</th>
          <td>
            <ul class="list-umaban">
              <li v-for="a in selectList" :key="a">
                <i
                  :class="[
                    horses[a].BracketNum < 10
                      ? `icon-umaban -waku0${horses[a].BracketNum}`
                      : `icon-umaban -waku0${horses[a].BracketNum}`,
                  ]"
                  >{{ horses[a].HorseNum }}</i
                >
              </li>
            </ul>
          </td>
        </tr>
      </table>

      <div class="icon-next"></div>

      <table class="table-grid -middle -padding -center">
        <thead>
          <tr>
            <th>
              my印<i
                class="filter-sort"
                :class="sortType === 1 ? '-asc' : sortType === 2 ? '-desc' : ''"
                @click="changeSort('sort_my')"
              ></i>
            </th>
            <th>
              組み合わせ<i
                class="filter-sort"
                :class="sortType === 3 ? '-asc' : sortType === 4 ? '-desc' : ''"
                @click="changeSort('sort_num')"
              ></i>
            </th>
            <th>
              オッズ<i
                class="filter-sort"
                :class="sortType === 5 ? '-asc' : sortType === 6 ? '-desc' : ''"
                @click="changeSort('sort_odds')"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody v-for="i in oddsTable" :key="i">
          <tr>
            <td>
              <ul class="list-kaimoku -arrow">
                <li>
                  <span
                    :class="
                      stampClassTable(
                        stamp[Number(i.firstHorseNum) - 1].selectedNum
                      )
                    "
                  ></span>
                </li>
                <li>
                  <span
                    :class="
                      stampClassTable(
                        stamp[Number(i.secondHorseNum) - 1].selectedNum
                      )
                    "
                  ></span>
                </li>
                <li>
                  <span
                    :class="
                      stampClassTable(
                        stamp[Number(i.thirdHorseNum) - 1].selectedNum
                      )
                    "
                  ></span>
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-kaimoku -arrow">
                <li>
                  <i
                    :class="[
                      horses[Number(i.firstHorseNum) - 1].BracketNum < 10
                        ? `icon-umaban -waku0${
                            horses[Number(i.firstHorseNum) - 1].BracketNum
                          }`
                        : `icon-umaban -waku0${
                            horses[Number(i.firstHorseNum) - 1].BracketNum
                          }`,
                    ]"
                    >{{ i.firstHorseNum }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      horses[Number(i.secondHorseNum - 1)] < 10
                        ? `icon-umaban
                      -waku0${horses[Number(i.secondHorseNum - 1)].BracketNum}`
                        : `icon-umaban
                      -waku0${horses[Number(i.secondHorseNum - 1)].BracketNum}`,
                    ]"
                    >{{ i.secondHorseNum }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      horses[Number(i.thirdHorseNum - 1)] < 10
                        ? `icon-umaban
                      -waku0${horses[Number(i.thirdHorseNum - 1)].BracketNum}`
                        : `icon-umaban
                      -waku0${horses[Number(i.thirdHorseNum - 1)].BracketNum}`,
                    ]"
                    >{{ i.thirdHorseNum }}</i
                  >
                </li>
              </ul>
            </td>
            <td v-if="i.odds === 999999999" class="-right" :class="oddsClass('---.-')">---.-</td>
            <td v-else class="-right" :class="oddsClass(i.odds)">{{ i.odds }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="isShowMore" class="nav-group">
        <span class="btn-basic" @click="setOdds"> もっと見る </span>
      </div>

      <div class="container-total -noborder">
        <dl class="amount">
          <dt>点数合計</dt>
          <dd>
            <span class="num">{{ counts }}</span
            >点
          </dd>
        </dl>
        <div @click="setRaceBetWin" class="btn-basic">買い目登録</div>
      </div>
    </div>
    <div class="container-schedule">
      <a href="/purchase/" target="_blank" class="btn-basic -normal">購入予定一覧</a>
      <p class="_caption">
        ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
      </p>
    </div>
  </section>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
  <SetBetConfModal
    v-on:closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
  ></SetBetConfModal>
</template>

<style
  lang="scss"
  src="../../../assets/css/race/odds/style.scss"
  scoped
></style>
