<script setup>
import { ref, onMounted, watch } from "vue";
import { setDismissDialog } from "../../assets/js/common";
let dialogChecked = ref(false);
const emit = defineEmits(["closeDialog2", "receiveAmountItem"]);

const defaultAmount = {
  target: {
    value: 1,
  },
};
const amount = ref(defaultAmount);

const props = defineProps({
  modalAmountOpen: Boolean,
});

watch(props, (newVal, oldVal) => {
  //モーダル展開時は初期値をセットする
  if (newVal.modalAmountOpen) {
    amount.value = defaultAmount;
  }
});

const modalCloseOK = () => {
  emit("receiveAmountItem", amount.value);
  emit("closeDialog2", false);
};
const modalClose = () => {
  emit("closeDialog2", false);
};

const resetRefundItem = (e) => {
  amount.value = e;
};
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div
          v-if="modalAmountOpen"
          class="modal-layer"
          style="display: block"
        ></div>
      </transition>
      <transition name="status-modal">
        <div
          @click.self="modalClose"
          v-if="modalAmountOpen"
          class="modal-frame"
        >
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                金額一括入力
              </div>
              <div class="modal-body">
                <p>1点あたりに賭ける金額を入力してください。</p>
                <input
                  type="number"
                  @change="resetRefundItem($event)"
                  value="1"
                  max="9999"
                  min="1"
                  class="form-text -count"
                />00円
                <p class="p-caption">
                  ※同じ式別のすべての買い目に同じ金額が指定されます。
                </p>
                <div class="modal-nav">
                  <span class="btn-basic -gray close-modal" @click="modalClose"
                    >キャンセル</span
                  >
                  <span class="btn-basic" @click="modalCloseOK">OK</span>
                </div>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-text.-count {
  width: 6em;
  margin: 0 6px 0 0;
}
</style>
