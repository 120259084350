<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">WIN!競馬 Xアカウント一覧</h1>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner">
          <ul class="list-box">
            <li class="card-twitter">
              <div class="thumb">
                <a href="https://twitter.com/winkeiba_dm" target="_blank"
                  ><img
                    src="/assets/images/twitter/sougou.png"
                    alt="WIN!競馬_総合"
                /></a>
              </div>
              <div class="detail">
                <div class="title">WIN!競馬_総合</div>
                <div class="follow">
                  <a
                    href="https://twitter.com/winkeiba_dm"
                    data-show-count="false"
                    data-lang="ja"
                    target="_blank"
                    >
                    <img
                    src="/assets/images/twitter/winkeiba_dm.png"
                    alt="X_winkeiba_dm"
                    />
                  </a>
                </div>
                <div class="caption">
                  WIN!競馬にまつわる情報(ＰＲ・更新情報・キャンペーン・ニュース)
                </div>
              </div>
            </li>
            <li class="card-twitter">
              <div class="thumb">
                <a href="https://twitter.com/winkeiba_jusyo" target="_blank"
                  ><img
                    src="/assets/images/twitter/juusyou.png"
                    alt="WIN!競馬_重賞"
                /></a>
              </div>
              <div class="detail">
                <div class="title">WIN!競馬_重賞</div>

                <div class="follow">
                  <a
                    href="https://twitter.com/winkeiba_jusyo"
                    data-show-count="false"
                    data-lang="ja"
                    target="_blank"
                    >
                    <img
                      src="/assets/images/twitter/winkeiba_jusyo.png"
                      alt="X_winkeiba_jusyo"
                    />
                  </a>
                </div>
                <div class="caption">JRA・地方の重賞枠順確定、結果をポスト</div>
              </div>
            </li>




            <li class="card-twitter">
              <div class="thumb">
                <a href="https://twitter.com/winkeiba_zenjou" target="_blank"
                  ><img
                    src="/assets/images/twitter/zenjyou.png"
                    alt="WIN競馬_全場"
                /></a>
              </div>
              <div class="detail">
                <div class="title">WIN競馬_全場</div>
                <div class="follow">
                  <a
                    href="https://twitter.com/winkeiba_zenjou"
                    data-show-count="false"
                    data-lang="ja"
                    target="_blank"
                    >
                    <img
                      src="/assets/images/twitter/winkeiba_zenjou.png"
                      alt="X_winkeiba_zenjou"
                    />
                  </a>
                </div>
                <div class="caption">
                  JRA全場のレース結果をポスト
                </div>
              </div>
            </li>
            <li class="card-twitter">
              <div class="thumb">
                <a href="https://twitter.com/winkeiba_henko" target="_blank"
                ><img
                  src="/assets/images/twitter/henkoujouhou.png"
                  alt="WIN!競馬_総合"
                /></a>
              </div>
              <div class="detail">
                <div class="title">WIN!競馬_変更情報</div>
                <div class="follow">
                  <a
                    href="https://twitter.com/winkeiba_henko"
                    data-show-count="false"
                    data-lang="ja"
                    target="_blank"
                  >
                    <img
                      src="/assets/images/twitter/winkeiba_henko.png"
                      alt="X_winkeiba_henko"
                    />
                  </a>
                </div>
                <div class="caption">JRAのレースに関する変更情報をポスト</div>
              </div>
            </li>
            <li class="card-twitter">
              <div class="thumb">
                <a href="https://twitter.com/winkeiba_win5" target="_blank"
                  ><img
                    src="/assets/images/twitter/WIN5.png"
                    alt="WIN!競馬_WIN5"
                /></a>
              </div>
              <div class="detail">
                <div class="title">WIN!競馬_WIN5</div>
                <div class="follow">
                  <a
                    href="https://twitter.com/winkeiba_win5"
                    data-show-count="false"
                    data-lang="ja"
                    target="_blank"
                    >
                    <img
                      src="/assets/images/twitter/winkeiba_win5.png"
                      alt="X_winkeiba_win5"
                    >
                  </a>
                </div>
                <div class="caption">
                  WIN5の結果をポスト
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>
