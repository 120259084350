<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import {
  getUrlVars,
  getJSON,
  SUCCESS,
  isFavorite,
  isNextTarget,
  FRACEHORSE,
  TRACEHORSE,
  isNar,
  getUserId,
} from "../../assets/js/common";

import {
  HOST,
  PATH,
  RACEPATH,
  BACKWORDPATH,
  RACEINFOPATH,
} from "../../assets/js/define";
import moment from "moment";
import {fetchPredictedSalesBanner} from '../../../spa/assets/js/wordpress'

const route = useRoute();
const currentDOR = ref("");
const pedigreeNums = ref([]);
const predictedSalesBanner = ref('');
onMounted(() => {
  //パラメータ取得
  let params = getUrlVars();
  let dor = "";
  if (params.DOR != undefined || params.DOR != null) {
    //クエリパラメータがある場合
    dor = params.DOR;
    currentDOR.value = dor;
  } else {
    //クエリパラメータがない場合は、今日の日付を取得
    let today = new Date();
    // 土曜の19:30以降は、翌日のデータを取得するため、DORに翌日の日にちを設定する
    if (today.getDay() === 6) {
      // 土曜(6）であるか
      // if ( today.getDay() === 6 || today.getDay() == 0 ) { // 土曜(6）or日曜(0）であるか ※３三日間開催用
      // if ( today.getDay() === 5 || today.getDay() == 6 ) { // 金曜(5）or土曜(6）であるか ※年末３三日間開催用 ※三日間対応（金土日）
      if (today.getHours() * 60 + today.getMinutes() >= 1170) {
        // 19:30以降であるか判定(19*60+30 1170)
        today.setDate(today.getDate() + 1);
      }
    }
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (String(month).length === 1) {
      month = "0" + month;
    } else {
      month = String(month);
    }
    let day = today.getDate();
    if (String(day).length === 1) {
      day = "0" + day;
    } else {
      day = String(day);
    }
    dor = String(year) + month + day;
  }
  //レース一覧差し込み
  getMyNextTargetRacehorse();
  raceListAll(dor);
  fetchPredictedSalesBanner(predictedSalesBanner, 'race-list');
});

const getMyNextTargetRacehorse = () => {
  let userId = getUserId();
  let request =
    HOST +
    PATH +
    "MyNextTarget/getMyNextTargetRacehorse.js" +
    BACKWORDPATH +
    "?userID=" + encodeURIComponent(userId)
    getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      if(json){
        pedigreeNums.value = json;
      }
    }
  });
}

watch(route, (prev, next) => {
  currentDOR.value = route.query['DOR'] ?? '';
  if(currentDOR.value) {
    raceListAll(currentDOR.value);
  }
})

//レースリスト表示
//日付
const preDateFlg = ref(true);
const prevHref = ref("");
const prevDate = ref("");
const nextDateFlg = ref(true);
const nextHref = ref("");
const nextDate = ref("");
const prevWeekFlg = ref(false);
const nextWeekFlg = ref(false);
const currentDate = ref("");

let payoutArray = reactive([]);
const mDateType = ref("0");

//レースリスト
let racePlaceArray = reactive([]);
let raceListArray = reactive([]);

const raceListAll = (dor) => {
  const reqToday =
    HOST +
    RACEINFOPATH +
    "getThisAndLastWeekRaceInfo.js" +
    BACKWORDPATH +
    "?NarFlag=" +
    isNar();
  try {
    document.body.className = "";
    getJSON(reqToday, true, function (statusToday, jsonToday) {
      if (statusToday == SUCCESS) {
        racePlaceArray.splice(0);
        raceListArray.splice(0);
        payoutArray.splice(0);
        jsonToday = jsonToday[0]
        let mDateArray = jsonToday["DateList"];
        // 重複を削除する
        mDateArray = mDateArray.filter((item, index, self) => {
          const dorList = self.map(item => item['DOR']);
          if (dorList.indexOf(item.DOR) === index) {
            return item;
          }
        });
        //念のため日付順でソート
        mDateArray.sort(function (a, b) {
          a = a["DOR"];
          b = b["DOR"];
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });

        mDateType.value = jsonToday["TodayRaceInfo"]["DateType"];
        let raceInfoJson;
        for (
          let i = 0;
          i < jsonToday["LastWeekRaceInfo"]["TargetDate"].length;
          i++
        ) {
          if (jsonToday["LastWeekRaceInfo"]["TargetDate"][i].DOR === dor) {
            raceInfoJson = jsonToday["LastWeekRaceInfo"];
          }
        }
        if (
          raceInfoJson == undefined &&
          jsonToday["NexrWeekRaceInfo"] != undefined
        ) {
          for (
            let i = 0;
            i < jsonToday["NexrWeekRaceInfo"]["TargetDate"].length;
            i++
          ) {
            if (jsonToday["NexrWeekRaceInfo"]["TargetDate"][i].DOR === dor) {
              raceInfoJson = jsonToday["NexrWeekRaceInfo"];
            }
          }
        }
        if (raceInfoJson === undefined) {
          raceInfoJson = jsonToday["TodayRaceInfo"];
        }

        if (mDateArray.length === 0) {
          return;
        }

        let mDate = new Date();
        if (typeof dor === "undefined" || dor === null || dor === "") {
          //dorが未設定の場合
          dor =
            mDate.getFullYear +
            ("0" + (mDate.getMonth + 1)).slice(-2) +
            ("0" + mDate.getDate).slice(-2);
        }

        //dor前後のレース日時を取得
        let preDor = "";
        let nextDor = "";
        let datecount = 0; //dor以降のレース日数

        if (mDateArray[mDateArray.length - 1].DOR < dor) {
          dor = mDateArray[mDateArray.length - 1].DOR;
          if (mDateArray.length >= 2) {
            preDor = mDateArray[mDateArray.length - 2].DOR;
          }
        } else if (mDateArray[0]?.DOR > dor) {
          dor = mDateArray[0]?.DOR;
          nextDor = mDateArray[1]?.DOR;
        } else {
          if (mDateArray.some(({DOR}) => DOR === dor)) {
            dor =  mDateArray.find(({DOR}) => DOR === dor).DOR
          } else {
            const dayNum = mDate.getDay()
            let isFriOrSat = dayNum >= 5 && dayNum <= 6

            dor = isFriOrSat ?
                moment().day(6).format('YYYYMMDD') :
                moment().day(0).add(7, 'd').format('YYYYMMDD')
            //TODO DORの直接書き換え（年末年始の特別対応）
            let today = moment().format('YYYYMMDD')
            if (Number(today) <= 20231228) {
              dor = '20231228'
            }
            //2024年1月4日までは日曜のレース（2024年1月7日）をデフォルト表示（2024年1月5日以降はTODOより前の処理で設定）
            else if (Number(today) >= 20231229 && Number(today) <= 20240104) {
              dor = '20240107'
            }
          }

          const index = mDateArray.findIndex(({DOR}) => DOR === dor)
          if (index > 0) {
            preDor = mDateArray[index - 1].DOR;
          }
          if (index < mDateArray.length - 1) {
            nextDor = mDateArray[index + 1].DOR;
          }
        }

        for (let i = 0; i < mDateArray.length; i++) {
          if (mDateArray[i].DOR > dor) {
            datecount++;
          }
        }

        let toDate = new Date(
          parseInt(dor.slice(0, 4), 10) +
            "/" +
            parseInt(dor.slice(4, 6), 10) +
            "/" +
            parseInt(dor.slice(6, 8), 10)
        );

        if (preDor === "") {
          //前の日は非表示
          preDateFlg.value = false;
          prevWeekFlg.value = false;
          prevHref.value = "";
          prevDate.value = "";
        } else {
          const preDay = new Date(
            parseInt(preDor.slice(0, 4), 10) +
              "/" +
              parseInt(preDor.slice(4, 6), 10) +
              "/" +
              parseInt(preDor.slice(6, 8), 10)
          );
          //前の日へのリンクボタンを設置
          prevHref.value = "/race/list?DOR=" + preDor;
          prevDate.value = getDisplayDateStrForMain(preDor);
          preDateFlg.value = true;
          //アイコンのクラスを設定
          if (toDate.getTime() - preDay.getTime() == 24 * 60 * 60 * 1000) {
            prevWeekFlg.value = false;
          } else {
            prevWeekFlg.value = true;
          }
        }

        if (nextDor == "") {
          //次の日がなければ非表示
          nextDateFlg.value = false;
          nextWeekFlg.value = false;
          nextHref.value = "";
          nextDate.value = "";
        } else {
          const nextDay = new Date(
            parseInt(nextDor.slice(0, 4), 10) +
              "/" +
              parseInt(nextDor.slice(4, 6), 10) +
              "/" +
              parseInt(nextDor.slice(6, 8), 10)
          );
          //次の日があればリンクボタンを設置
          nextHref.value = "/race/list?DOR=" + nextDor;
          nextDate.value = getDisplayDateStrForMain(nextDor);
          nextDateFlg.value = true;
          //アイコンのクラスを設定
          if (nextDay.getTime() - toDate.getTime() == 24 * 60 * 60 * 1000) {
            nextWeekFlg.value = false;
          } else {
            nextWeekFlg.value = true;
          }
        }

        //表示しているデータの日付をセット
        currentDate.value = getDisplayDateStrForMain(dor);
        currentDOR.value = dor

        let targetDOR = dor;

        try {
          const request =
            HOST +
            PATH +
            "RaceInfo/raceListTriple.js" +
            BACKWORDPATH +
            "?dor=" +
            targetDOR;
          getJSON(request, true, function (status, json) {
            if (status === SUCCESS) {
              try {
                //レース会場確認
                let idNum = new Object();
                let mRacetrackCdArray = raceInfoJson["TargetRacetrackCd"];
                if (
                  mRacetrackCdArray != null &&
                  mRacetrackCdArray != undefined
                ) {
                  for (let i = 0; i < mRacetrackCdArray.length; i++) {
                    idNum[mRacetrackCdArray[i].RacetrackCd] = i + 1;
                  }
                }

                if (json.length > 0) {
                  //レース一覧表示
                  let racetrack = "";
                  let raceArray = new Array();
                  let n = 0;
                  for (let j in json) {
                    let raceListUrl = "";
                    let useData = json[j];

                    //開催場表示
                    if (useData.RacetrackCd != racetrack) {
                      if (raceArray.length > 0) {
                        n++;
                      }
                      racetrack = useData.RacetrackCd;
                      raceListUrl =
                        "/race?DOR=" +
                        targetDOR +
                        "&RacetrackCd=" +
                        racetrack;
                      racePlaceArray.push({
                        place: useData.RacetrackName,
                        url: raceListUrl,
                      });
                      raceArray.push([]);
                    }
                    raceArray[n].push(useData);
                  }
                  //raceが中止などで抜け落ちている場合に空のデータを挿入する処理
                  raceArray.forEach((races) => {
                    if(races.length === 12) return;
                    for (let raceNum = 1; raceNum <= 12; raceNum++) {
                      if (races.some(race => Number(race.RaceNum) === raceNum)) continue;
                      const dummyRaceData = {
                        RacetrackCd: races[0].RacetrackCd,
                        Status: "1",
                        RaceNum: raceNum.toString().padStart(2, '0'),
                        GradeCd: "0",
                        PedigreeNum: "0",
                        RaceRegulationCd: "000",
                        RaceShortName6: "",
                        RacetrackName: "",
                        ResultFlg: false,
                        SpecialRaceNum: "0000",
                        TrackTypeCd: "0",
                        UnResultFlg: 0,
                        isLinkMode: 0,
                        isSpRace: false,
                      }
                      races.push(dummyRaceData)
                    }
                  })
                  //開催場ごとにソート
                  for (let i = 0; i < raceArray.length; i++) {
                    raceArray[i].sort(function (a, b) {
                      a = a["RaceNum"];
                      b = b["RaceNum"];
                      if (a > b) return -1;
                      if (a < b) return 1;
                      return 0;
                    });
                  }

                  //払戻金一覧へのリンクを表示
                  let payoutRmvCnt = 0;
                  for (let j = 0; j < 3; j++) {
                    let payoutUrl = "";
                    for (let k = 0; k < 12; k++) {
                      if (raceArray.length > j) {
                        if (
                          payoutUrl === "" &&
                          raceArray[j][k].ResultFlg === true &&
                          raceArray[j][k].Status === "1"
                        ) {
                          payoutUrl =
                            "/race/odds/payout?DOR=" +
                            targetDOR +
                            "&RacetrackCd=" +
                            raceArray[j][k].RacetrackCd;
                        }
                      }
                    }
                    if (raceArray[j] && payoutUrl !== "") {
                      payoutArray.push(payoutUrl);
                    }
                  }

                  transpose(raceArray).forEach((racelist) =>
                    raceListArray.push(racelist)
                  );
                }
              } catch (e) {
                alert("raceListAll : " + e);
              }
            }
            document.body.className = "status-loaded";
          });
        } catch (e) {
          alert("raceListAll : " + e);
          document.body.className = "status-loaded";
        }
      }
    });
  } catch (e) {
    alert("raceListAll : " + e);
    document.body.className = "status-loaded";
  }
};

//行列の入れ替え
const transpose = (array) => {
  const ROW = array.length;
  const COL = array[0].length;
  const col = COL - 1;
  const a = []; //new Array(COL);
  for (let c = 0; c < COL; c++) {
    a[c] = []; //new Array(ROW);
    for (let r = 0; r < ROW; r++) {
      a[c][r] = array[r][col - c];
    }
  }
  return a;
};

//グレードコードを返す関数
const getGradeTag = (race) => {
  //グレードアイコン
  let icon = "";
  if (race.GradeCd === "A") {
    if (race.TrackTypeCd === "3") {
      icon = "<i class='icon-race -jg1'></i>";
    } else {
      icon = "<i class='icon-race -g1'></i>";
    }
  } else if (race.GradeCd === "B") {
    if (race.TrackTypeCd === "3") {
      icon = "<i class='icon-race -jg2'></i>";
    } else {
      icon = "<i class='icon-race -g2'></i>";
    }
  } else if (race.GradeCd === "C") {
    if (race.TrackTypeCd === "3") {
      icon = "<i class='icon-race -jg3'></i>";
    } else {
      icon = "<i class='icon-race -g3'></i>";
    }
  } else if (race.GradeCd === "L") {
    icon = '<i class="icon-race -L"></i>';
  } else {
    if (race.isSpRace) {
      //重賞でない特別競走には競争条件をアイコン表示（その他重賞時には競争条件をアイコン表示）
      let regulation_txt = "";
      if (currentDOR.value.slice(0, 6) <= 201905) {
        //2019年5月以前のデータは読み込まれない想定だが、残しておく
        if (race.RaceRegulationCd === "005") {
          //500万下
          regulation_txt = "500";
        } else if (race.RaceRegulationCd === "010") {
          //1000万下
          regulation_txt = "1000";
        } else if (race.RaceRegulationCd === "016") {
          //1600万下
          regulation_txt = "1600";
        } else if (race.RaceRegulationCd === "999") {
          //オープン
          regulation_txt = race.TrackTypeCd === "3" ? "障OP" : "OP";
        }
      } else {
        if (race.RaceRegulationCd === "005") {
          //500万下
          regulation_txt = "1勝";
        } else if (race.RaceRegulationCd === "010") {
          //1000万下
          regulation_txt = "2勝";
        } else if (race.RaceRegulationCd === "016") {
          //1600万下
          regulation_txt = "3勝";
        } else if (race.RaceRegulationCd === "999") {
          //オープン
          regulation_txt = race.TrackTypeCd === "3" ? "障OP" : "OP";
        }
      }
      icon = '<i class="icon-race -nograde">' + regulation_txt + "</i>";
    }
  }
  return icon;
};

//引数値からユーザのお気に入り馬があるかどうかを返す関数
//引数 馬番(xxxxxxxx_xxxxxxxxx)
const favHorseSearch = (pedigreeNum) => {
  let favHorse = false;
  if (pedigreeNum != undefined && pedigreeNum != "") {
    let favHorseList = pedigreeNum.slice(1, pedigreeNum.length - 1).split("_");
    if (favHorseList.length > 0) {
      favHorseList.forEach((favHorseId) => {
        if (isFavorite(FRACEHORSE, favHorseId) === 1) {
          favHorse = true;
        }
      });
    }
  }
  return favHorse;
};

//引数値からユーザの次走狙い馬があるかどうかを返す関数
//引数 馬番(xxxxxxxx_xxxxxxxxx)
const nextTargetSearch = (pedigreeNum) => {
  let nextTarget = false;
  if (pedigreeNum != undefined && pedigreeNum != "") {
    let nextTargetList = pedigreeNum.slice(1, pedigreeNum.length - 1).split("_");
    if (nextTargetList.length > 0) {
      nextTargetList.forEach((nextTargetId) => {
        if(pedigreeNums.value.includes(nextTargetId)){
          if (isNextTarget(TRACEHORSE, nextTargetId) === 1) {
            nextTarget = true;
          }
        }
      });
    }
  }
  return nextTarget;
};

//レース表示部で使用する日付フォーマット取得関数（例：○/△（□））
const getDisplayDateStrForMain = (yyyymmdd) => {
  const DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  const month = yyyymmdd.slice(4, 6);
  const date = yyyymmdd.slice(6, 8);
  const mDate = new Date(parseInt(yyyymmdd.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day === "土") {
    day = '<span class="_sat">' + day + "</span>";
  } else if (day === "日") {
    day = '<span class="_sun">' + day + "</span>";
  }
  let str = month + "/" + date + " (" + day + ")";

  return str;
};
</script>
<template>
  <main class="layout-main">
    <section class="layout-section">
      <div class="list-day">
        <ul>
          <li>
            <router-link
              v-if="prevHref != '' || prevDate != ''"
              :to="prevHref"
              class="btn-arrow -prev"
              :class="prevWeekFlg === true ? '-week' : ''"
              v-html="prevDate"
            ></router-link>
          </li>
          <li class="-current">
            <span class="label" v-html="currentDate"></span>
          </li>
          <li>
            <router-link
              v-if="nextHref != '' || nextDate != ''"
              :to="nextHref"
              class="btn-arrow -next"
              :class="nextWeekFlg === true ? '-week' : ''"
              v-html="nextDate"
            >
            </router-link>
          </li>
        </ul>
      </div>
    </section>

    <section class="layout-section">
      <table class="table-cell -fit -middle -racejralist -jra">
        <thead>
          <tr class="-center">
            <th class="race">R</th>
            <th v-for="i in racePlaceArray.length" :key="i" class="place">
              <template v-if="racePlaceArray.length > i - 1">
                <router-link :to="racePlaceArray[i - 1].url">{{
                  racePlaceArray[i - 1].place
                }}</router-link>
              </template>
            </th>
          </tr>
        </thead>
        <tbody v-if="raceListArray.length > 0">
          <tr v-for="n in 12" :key="n">
            <th class="race">{{ n }}</th>
            <td
              v-for="o in raceListArray[n - 1].length"
              :key="o"
              class="title"
              :class="[
                raceListArray[n - 1].length > o - 1
                  ? raceListArray[n - 1][o - 1].TrackTypeCd === '1'
                    ? '-turf'
                    : raceListArray[n - 1][o - 1].TrackTypeCd === '2'
                    ? '-dart'
                    : raceListArray[n - 1][o - 1].TrackTypeCd === '3'
                    ? '-hurdle'
                    : ''
                  : '',
                raceListArray[n - 1].length > o - 1
                  ? raceListArray[n - 1][o - 1].ResultFlg === true &&
                    raceListArray[n - 1][o - 1].Status === '1'
                    ? '-kakutei'
                    : raceListArray[n - 1][o - 1].Status === '2'
                    ? '-cancel'
                    : raceListArray[n - 1][o - 1].UnResultFlg === 1
                    ? '-mikakutei'
                    : ''
                  : '',
                raceListArray[n - 1].length > o - 1
                  ? raceListArray[n - 1][o - 1].isLinkMode === null ||
                    raceListArray[n - 1][o - 1].isLinkMode === 0
                    ? '-unsettled'
                    : ''
                  : '',
                raceListArray[n - 1].length > o - 1
                  ? favHorseSearch(raceListArray[n - 1][o - 1].PedigreeNum) || nextTargetSearch(raceListArray[n - 1][o - 1].PedigreeNum)
                    ? '-fav'
                    : ''
                  : '',
              ]"
            >
              <template v-if="raceListArray[n - 1][o - 1] != undefined">
                <span
                  v-if="raceListArray[n - 1][o - 1].isLinkMode === 0"
                  class="link-cell">
                  <div class="inner">
                    <div class="info">
                      <span class="label">
                        {{ raceListArray[n - 1][o - 1].RaceShortName6 }}
                      </span>
                      <div>
                        <i v-html="getGradeTag(raceListArray[n - 1][o - 1])"></i>
                      </div>
                    </div>
                    <div class="mark"
                         v-if="favHorseSearch(raceListArray[n - 1][o - 1].PedigreeNum) ||
                          nextTargetSearch(raceListArray[n - 1][o - 1].PedigreeNum)">
                      <div class="upper">
                        <i v-if="favHorseSearch(raceListArray[n - 1][o - 1].PedigreeNum)"
                           class="icon-mark -horse">
                        </i>
                      </div>
                      <div class="lower">
                        <i v-if="nextTargetSearch(raceListArray[n - 1][o - 1].PedigreeNum)"
                           class="icon-mark -nextrun">
                        </i>
                      </div>
                    </div>
                  </div>
                </span>
                <router-link v-else-if="raceListArray[n - 1][o - 1].isLinkMode != 0"
                   class="link-cell"
                   :to="
                    raceListArray[n - 1][o - 1].ResultFlg &&
                     raceListArray[n - 1][o - 1].Status !== '2'
                      ? '/' +
                        RACEPATH +
                        'results?DOR=' +
                        currentDOR +
                        '&RacetrackCd=' +
                        raceListArray[n - 1][o - 1].RacetrackCd +
                        '&RaceNum=' +
                        raceListArray[n - 1][o - 1].RaceNum
                      : raceListArray[n - 1][o - 1].UnResultFlg === 1
                      ? '/' +
                        RACEPATH +
                        'odds/inquire?DOR=' +
                        currentDOR +
                        '&RacetrackCd=' +
                        raceListArray[n - 1][o - 1].RacetrackCd +
                        '&RaceNum=' +
                        raceListArray[n - 1][o - 1].RaceNum
                      : '/' +
                        RACEPATH +
                        'card?DOR=' +
                        currentDOR +
                        '&RacetrackCd=' +
                        raceListArray[n - 1][o - 1].RacetrackCd +
                        '&RaceNum=' +
                        raceListArray[n - 1][o - 1].RaceNum
                  ">
                  <div class="inner">
                    <div class="info">
                      <span class="label">
                        {{ raceListArray[n - 1][o - 1].RaceShortName6 }}
                      </span>
                      <div>
                        <i v-html="getGradeTag(raceListArray[n - 1][o - 1])"></i>
                      </div>
                    </div>
                    <div class="mark"
                         v-if="favHorseSearch(raceListArray[n - 1][o - 1].PedigreeNum) ||
                            nextTargetSearch(raceListArray[n - 1][o - 1].PedigreeNum)">
                      <div class="upper">
                        <i v-if="favHorseSearch(raceListArray[n - 1][o - 1].PedigreeNum)"
                           class="icon-mark -horse">
                        </i>
                      </div>
                      <div class="lower">
                        <i v-if="nextTargetSearch(raceListArray[n - 1][o - 1].PedigreeNum)"
                           class="icon-mark -nextrun">
                        </i>
                      </div>
                    </div>
                  </div>
                </router-link>
              </template>
            </td>
          </tr>
          <tr v-if="payoutArray.length" class="-center">
            <th class="race"></th>
            <td v-for="(payout, index) in payoutArray" :key="index" class="nav">
              <router-link v-if="payout" :to="payout" class="btn-basic -high">
                <em>
                  払戻金一覧へ
                </em>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section class="layout-section">
      <div class="inner">
        <div class="nav-bool -nomargin">
          <router-link
            v-if="mDateType != '1'"
            :to="'/info/entries_index?DOR=' + currentDOR"
            class="btn-basic -wide"
            >出走馬INDEX</router-link
          >
          <router-link :to="'/race/calendar?DOR=' + currentDOR" class="btn-basic -wide"
            >レースカレンダー</router-link
          >
        </div>
      </div>
    </section>
    <section class="layout-section -bordertop">
      <div class="inner">
        <div class="nav-bool -nomargin">
          <router-link :to="'/race/stallion'" class="btn-basic -wide"
          >種牡馬別出走産駒検索</router-link
          >
        </div>
      </div>
    </section>
    <section class="layout-section -bordertop">
      <div class="inner">
        <ul class="list-help">
          <li class="turf"><span class="label">芝</span></li>
          <li class="dart"><span class="label">ダート</span></li>
          <li class="hurdle"><span class="label">障害</span></li>
          <li class="kakutei"><span class="label">確定</span></li>
          <li class="mikakutei"><span class="label">未確定</span></li>
          <li class="cancel"><span class="label">中止</span></li>
        </ul>
      </div>
    </section>
    <section class="layout-section">
      <div class="inner" v-if="predictedSalesBanner" v-html="predictedSalesBanner"></div>
    </section>
  </main>
</template>
