<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">収支管理</h1>
    </section>

    <section class="layout-section">
      <div>
        <div class="nav-tab -fit">
          <ul>
            <li>
              <a class="label" href="/balance/">年間別収支データ</a>
            </li>
            <li class="-current">
              <a class="label" href="/balance/category/">カテゴリ別データ</a>
            </li>
          </ul>
        </div>
        <div class="container-tabtarget">
          <div class="inner">
            <nav class="nav-multi -noborder">
              <ul class="list">
                <li>
                  <a href="/balance/sum_venue/" class="label">競馬場別</a>
                </li>
                <li>
                  <a href="/balance/sum_date_place/" class="label">開催別</a>
                </li>
                <li>
                  <a href="/balance/sum_jockey/" class="label">騎手別</a>
                </li>
                <li class="-current">
                  <a href="/balance/sum_trainer/" class="label">調教師別</a>
                </li>
                <li>
                  <a href="/balance/sum_distance/" class="label">距離別</a>
                </li>
                <li>
                  <a href="/balance/sum_surface/" class="label">芝ダ障別</a>
                </li>
                <li>
                  <a href="/balance/sum_horse_age/" class="label"
                     :class="narFlag ? '-disable' : ''">世代別</a>
                </li>
                <li>
                  <a href="/balance/sum_horse_number/" class="label">馬番別</a>
                </li>
                <li>
                  <a href="/balance/sum_bettype/" class="label">馬券別</a>
                </li>
                <li>
                  <a href="/balance/sum_grade/" class="label"
                     :class="narFlag ? '-disable' : ''">クラス別</a>
                </li>
                <li>
                  <a href="/balance/sum_monthly/" class="label">月別</a>
                </li>
              </ul>
            </nav>
          </div>

          <div class="inner">
            <div class="-dayplace">
              <div class="form-select">
                <select v-model="selectedYear" v-on:change="getData">
                  <option v-for="(year, index) in yearList" :key="index">
                    {{ year }}年
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="inner">
            <h2 class="heading-circle">調教師別収支集計</h2>
            <nav class="nav-multi -noborder">
              <table class="table-cell">
                <tr class="-center">
                  <th class="_nowrap" rowspan="2">購入額<br />順位</th>
                  <th class="_nowrap" rowspan="2">調教師</th>
                  <th class="_nowrap">購入額<br />合計</th>
                  <th class="_nowrap">払戻金額<br />合計</th>
                  <th class="_nowrap">回収率</th>
                </tr>
                <tr class="-center">
                  <th class="_nowrap">購入<br />レース</th>
                  <th class="_nowrap">的中<br />レース</th>
                  <th class="_nowrap">的中率</th>
                </tr>
                <tbody v-for="(data, index) in dataList.tags" :key="index">
                  <tr>
                    <th class="-center _nowrap" rowspan="2">
                      {{ index + 1 }}
                    </th>
                    <th class="-center _nowrap" rowspan="2">
                      {{ data.item }}
                    </th>
                    <td class="amount">{{ data.sv ?? 0 }}円</td>
                    <td class="amount">{{ data.rv ?? 0 }}円</td>
                    <td class="amount">{{ data.rp ?? 0 }}%</td>
                  </tr>
                  <tr>
                    <td class="amount">{{ data.rc ?? 0 }}</td>
                    <td class="amount">{{ data.hc ?? 0 }}</td>
                    <td class="amount">{{ data.hp ?? 0 }}%</td>
                  </tr>
                </tbody>
              </table>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import { PAYINGNONE, PAYINGSUGOTOKU_NONE, retrieveCourse, isNar } from "../../assets/js/common";
import {HOST, PATH, JOINPATH } from "../../assets/js/define";
import { getYear, getDataSortAppend } from "./index";

export default {
  name: "SumTrainer",
  data() {
    return {
      //パラメータ(固定)
      HOST: HOST,
      PATH: PATH,

      selectedYear: new Date().getFullYear().toString(),
      yearList: [],
      dataList: {},
      narFlag: false,
    };
  },
  methods: {
    getData: async function () {
      document.body.className = "";
      let self = this;
      self.selectedYear = self.selectedYear.replace("年", "");
      self.dataList = await getDataSortAppend(self.selectedYear, "9");
      self.selectedYear = self.selectedYear + "年";
      document.body.className = "status-loaded";
    },
  },
  mounted() {
    let self = this;
    self.narFlag = isNar() === 1

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none");
      }
    }

    callRetrieveCourse(function (courseId) {
      if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
        location.href = HOST + JOINPATH;
      } else {
        self.yearList = getYear(self.narFlag);
        self.getData();
      }
    });
  },
};
</script>

<style lang="scss" src="../../assets/css/balance/style.scss" scoped></style>
