<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getUrlVars,
  getJSON,
  getCourse,
  SUCCESS,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  RACEPATH,
} from "../../assets/js/define";
import moment from "moment";
const mDays = ref("");
const loadedPage = ref(false);
let params = reactive({});

onMounted(() => {
  params = getUrlVars();
  const requestDate =
    HOST +
    PATH +
    "RaceInfo/getTodayRaceInfo.js" +
    BACKWORDPATH +
    "?NarFlag=" +
    isNar();
  getJSON(requestDate, true, callbackDate);
});

const callbackDate = (status, json) => {
  try {
    if (status == SUCCESS) {
      // タブの描画
      if (json.TargetDate != undefined && json.TargetDate != null) {
        const today = moment().format("YYYYMMDD");
        mDays.value = json.TargetDate.some((value) => value.DOR === today)
          ? today
          : json.TargetDate[0].DOR;
      }

      // 変更情報取得
      const request =
        HOST +
        PATH +
        "getChangeInfo.js" +
        BACKWORDPATH +
        "?dor=" +
        params.dor +
        "&narFlag=" +
        isNar();
      document.body.className = "";
      loadedPage.value = false;
      getJSON(request, true, callbackChange);
    }
  } catch (e) {
    alert("取得情報の表示に失敗しました");
  }
};

let changeData = reactive([]);
const callbackChange = (status, json) => {
  // 変更内容の描画
  try {
    if (status == SUCCESS) {
      if (json.length != undefined && json.length != null && json.length > 0) {
        // 変更情報当て込み
        for (let i = 0; i < json.length; i++) {
          if (params.dor === json[i].DOR) {
            //当日のデータのみ表示する
            changeData.push(json[i]);
          }
        }
      }
    }
  } catch (e) {
    alert("取得情報の表示に失敗しました");
  }
  document.body.className = "status-loaded";
  loadedPage.value = true;
};

//レースカレンダーボタンクリック時にURLを生成する
const clickRaceCalendar = () => {
  let url = HOST + RACEPATH + "calendar?DOR=" + mDays.value;
  location.href = url;
};
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">変更情報一覧</h1>
    </section>

    <section class="layout-section">
      <ul v-if="changeData.length && loadedPage" class="list-line -changes">
        <li v-for="(data, index) in changeData" :key="Index">
          <div class="list-changes">
            <div class="caption">
              {{ data.AnnouncementTime }} / {{ Number(data.RaceMeeting) }}回{{
                data.Racetrack
              }}{{ Number(data.RaceDay) }}日 / {{ data.Type }}
            </div>
            <div class="content" v-html="data.Contents"></div>
          </div>
        </li>
      </ul>
      <ul v-else-if="loadedPage">
        <li>
          <div class="list-changes">
            <div class="caption">変更情報はありません。</div>
          </div>
        </li>
      </ul>

      <div v-if="loadedPage" class="nav-group">
        <a @click="clickRaceCalendar()" class="btn-basic">レースカレンダー</a>
      </div>
    </section>

    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/info/style.scss" scoped></style>
