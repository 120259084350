<script setup>
import {onMounted, onServerPrefetch, ref, watch} from "vue";

import NewInfo from "../../components/data/trainer/NewInfo.vue";
import TotalResults from "../../components/data/trainer/TotalResults.vue";
import RaceWins from "../../components/data/trainer/RaceWins.vue";
import ManagementHorses from "../../components/data/trainer/ManagementHorses.vue";
import Achievement from "../../components/data/trainer/Achievement.vue";
import G1Results from "../../components/data/trainer/G1Results.vue";

import {
  ADD,
  changeFavoriteFromCookie, FRACEHORSE,
  FTRAINER,
  getJSON,
  getUserAgent,
  getUserId,
  isFavorite,
  isNar,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  PAYINGYAHOO,
  REMOVE,
  retrieveCourse,
  SUCCESS,
} from "../../assets/js/common";
import {BACKWORDPATH, HOST, PATH,} from "../../assets/js/define";
import {useStoreTrainerData} from '../../stores/data/trainer-states'
import {storeToRefs} from 'pinia'
import {useRoute} from 'vue-router'
import {fetch as fetchTrainer} from '../../functions/data/trainer'

const userCourse = ref("");
const courseId = ref('none');
const userId = ref('');
const isDisplayFav = ref(false);
const isWebView = ref(false);
const loadedPage = ref(false);
const isDisplayNar = ref(false);
const favClass = ref("");
const tab = ref("newInfo");
const isCurrentTab = ref("newInfo");

const storeTrainerData = useStoreTrainerData();
const {
  trainerCdValue,
  trainerNews,
  thisWeekSpResult,
  TrainerData,
  bigPrizeData,
  CareerStatData,
  g1RaceResultData,
  dataTrainerExt,
  managementHorses,
  pastManagementHorses,
  schedule,
  totalRaces,
} = storeToRefs(storeTrainerData);

const route = useRoute();
onServerPrefetch(async () => {
  await fetchTrainer(route.query["TrainerCd"]);
});

watch(route, async (from, to) => {
  await fetchTrainer(to.query["TrainerCd"]);
})

onMounted(async () => {
  document.body.className = "";
  isWebView.value = getUserAgent();
  isDisplayNar.value = isNar() === 1;

  if (trainerCdValue.value !== route.query["TrainerCd"]) {
    await fetchTrainer(route.query["TrainerCd"]);
  }

  if (!TrainerData.value) {
    alert('調教師データが存在しません。')
    loadedPage.value = true;
    document.body.className = "status-loaded";
    return;
  }

  callRetrieveCourse(function (jsonCourseId, showMarks, jsonUserId) {
    courseId.value = jsonCourseId;
    userId.value = jsonUserId || getUserId();
    trainerCdValue.value = route.query["TrainerCd"]

    //お気に入りの表示
    if (courseId.value !== PAYINGNONE && courseId.value !== PAYINGSUGOTOKU_NONE) {
      isDisplayFav.value = true;
      favClass.value = isFavorite(FTRAINER, trainerCdValue.value) === 1 ? '-registed' : '';

      managementHorses.value.forEach(function (horse) {
        horse.favClass = isFavorite(FRACEHORSE, horse.PedigreeNum) === 1 ? '-registed' : '';
      })
    }

    //課金コースのチェック、実績タブの非有効化
    if (courseId.value === PAYINGNONE) {
      // 無課金ユーザ会員登録ページ
      userCourse.value = "/member";
    } else if (courseId.value === PAYINGYAHOO) {
      // Y!プレミアムブリッジページ
      userCourse.value =
        "/member/error/y_premium_info_close";
    } else if (courseId.value === PAYINGSUGOTOKU_NONE) {
      // スゴ特無課金ページ
      userCourse.value = HOST + "joinSugotoku.js";
    }
  });

  loadedPage.value = true;
  document.body.className = "status-loaded";
})

const tabChange = (data) => {
  tab.value = data;
  isCurrentTab.value = data;
}

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false, '');
  }
}

const clickFav = async () => {
  try {
    document.body.className = "";
    if (isFavorite(FTRAINER, trainerCdValue.value) === 1) {
      // お気に入り登済みなので削除する
      const request =
        HOST +
        PATH +
        "MyFavorite/deleteMyFavoriteTrainer.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(userId.value) +
        "&trainerCd=" +
        trainerCdValue.value +
        "&narFlag=" +
        isNar();
      const order = REMOVE;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの削除に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FTRAINER,
        trainerCdValue.value,
        order
      );
      favClass.value = favJudge ? '' : '-registed';
    } else {
      // お気に入り未登録なので登録する
      const request =
        HOST +
        PATH +
        "MyFavorite/setMyFavoriteTrainer.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(userId.value) +
        "&trainerCd=" +
        trainerCdValue.value +
        "&isAuto=0" +
        "&narFlag=" +
        isNar();
      const order = ADD;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの登録に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FTRAINER,
        trainerCdValue.value,
        order
      );
      favClass.value = favJudge ? '-registed' : '';
    }
    document.body.className = "status-loaded";
  } catch (e) {
    console.log("error" + e);
  }
}

</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -trainer">
      <h1 class="heading-page">調教師データ</h1>
    </section>
    <section class="layout-section">
      <div class="summary-title">
        <div class="summary">
          <div class="inner" v-if="TrainerData">
            <h2 class="title" id="trainer_name">
              {{ TrainerData.TrainerName }}
            </h2>
            <div class="caption" v-html="TrainerData.RetireDiv"></div>
          </div>
          <ul class="list-icon -bottomleft">
            <li>
              <span
                v-if="isDisplayFav"
                class="icon-fav"
                :class="favClass"
                @click="clickFav"
              ></span>
            </li>
          </ul>
        </div>
        <div class="photo -profile" v-if="TrainerData">
          <img
            :src="TrainerData.FaceImgURL"
            alt=""
            class="trainer"
          />
        </div>
      </div>

      <div class="inner">
        <table class="table-grid -data" v-if="TrainerData">
          <tr>
            <th>所属</th>
            <td>{{ TrainerData.affiliation }}</td>
          </tr>
          <tr>
            <th>生年月日</th>
            <td>{{ TrainerData.DOB }}</td>
          </tr>
          <tr>
            <th>免許取得</th>
            <td>{{ TrainerData.LicenseDate }}</td>
          </tr>
          <tr>
            <th>本年勝利</th>
            <td v-html="TrainerData.LatestWinsRanking"></td>
          </tr>
          <tr>
            <th>通算成績</th>
            <td>{{ TrainerData.CareerWins }}勝</td>
          </tr>
        </table>
        <p v-if="TrainerData" class="p-caption -right">
          {{ TrainerData.LeadingUpdated }}
        </p>
      </div>

      <div data-tab='["data-trigger", "data-target"]'>
        <div class="nav-tab -fit5">
          <!-- tabの条件分岐 -->
          <ul data-trigger>
            <li
              @click="tabChange('newInfo')"
              :class="{ '-current': isCurrentTab === 'newInfo' }"
            >
              <span class="label">最新情報</span>
            </li>
            <li
              @click="tabChange('totalResults')"
              :class="{ '-current': isCurrentTab === 'totalResults' }"
            >
              <span class="label">通算成績</span>
            </li>
            <li v-if="userCourse">
              <a :href="userCourse">
                <span class="label">条件実績</span>
              </a>
            </li>
            <li
              v-else
              @click="tabChange('achievement')"
              :class="{ '-current': isCurrentTab === 'achievement' }"
            >
              <span class="label">条件実績</span>
            </li>
            <li
              @click="tabChange('raceWins')"
              :class="{ '-current': isCurrentTab === 'raceWins' }"
            >
              <span class="label">重賞勝鞍</span>
            </li>
            <li
              v-if="!isDisplayNar"
              @click="tabChange('g1Results')"
              :class="{ '-current': isCurrentTab === 'g1Results' }"
            >
              <span class="label">G1実績</span>
            </li>
            <li
              @click="tabChange('managementHorses')"
              :class="{ '-current': isCurrentTab === 'managementHorses' }"
            >
              <span class="label">管理馬</span>
            </li>
          </ul>
        </div>
        <NewInfo
          v-if="tab === 'newInfo'"
          :trainerNews="trainerNews"
          :thisWeekSpResult="thisWeekSpResult"
          :schedule="schedule"
          :totalRaces="totalRaces"
          :loadedPage="loadedPage"
          :isDisplayNar="isDisplayNar"
        />
        <TotalResults
          v-if="tab === 'totalResults'"
          :CareerStatData="CareerStatData"
          :isDisplayNar="isDisplayNar"
        />
        <RaceWins v-if="tab === 'raceWins'" :bigPrizeData="bigPrizeData"/>
        <Achievement
          v-if="tab === 'achievement'"
          :dataTrainerExt="dataTrainerExt"
        />
        <G1Results
          v-if="!isDisplayNar && tab === 'g1Results'"
          :g1RaceResultData="g1RaceResultData"
        />
        <ManagementHorses
          v-if="tab === 'managementHorses'"
          :managementHorses="managementHorses"
          :pastManagementHorses="pastManagementHorses"
          :isDisplayFav="isDisplayFav"
        />
      </div>
    </section>
    <template v-if="loadedPage">
      <p v-if="!isDisplayNar" class="p-caption -padding">
        ※総賞金・通算成績・条件実績・重賞勝鞍・G1実績・各馬一覧等のデータは開催終了の翌日の更新となります。<br>
      </p>
      <p class="p-caption -padding">
        ※出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。<br>
        　<template v-if="isDisplayNar">成績集計期間は2020年04月01日以降になります。</template>
      </p>
    </template>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading"/>
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/data/style.scss" scoped></style>
