import {
  getJSON,
  isFavorite,
  getCourse,
  isNar,
  setRaceBet4sg,
} from "../../assets/js/common";
import {
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  SUCCESS,
  FRACEHORSE,
  getUserAgent,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  RACEPATH,
  TOB_QUINELLA,
  TOB_EXACTA_MULTI,
  TOB_WIDE,
  TOB_TRIO,
  TOB_TRIFECTA,
  TOB_BRACKET,
  TOB_BRACKET_EXACTA,
} from '../../assets/js/define';

export default function () {
  const raceResultCheck = async (dor, racetrackCd, raceNum, callback) => {
    let params = "";
    var request =
      HOST +
      PATH +
      "Odds/winTicketOdds.js" +
      BACKWORDPATH +
      "?DOR=" +
      dor +
      "&RacetrackCd=" +
      racetrackCd +
      "&RaceNum=" +
      raceNum +
      "&NarFlag=" +
      isNar();
    await getJSON(request, true, function (status, json) {
      if (status == SUCCESS) {
        if (json == null || json.length == 0) {
          if (
            (getCourse() == PAYINGSUGOTOKU ||
            getCourse() == PAYINGSUGOTOKU_NONE) && !getUserAgent()
          ) {
            var url = HOST +
                RACEPATH +
                "card?DOR=" +
                dor +
                "&RacetrackCd=" +
                racetrackCd +
                "&RaceNum=" +
                raceNum;
            return (window.location.href = url);
          } else {
            var mHref =
              HOST +
              RACEPATH +
              "card?DOR=" +
              dor +
              "&RacetrackCd=" +
              racetrackCd +
              "&RaceNum=" +
              raceNum;
            return (window.location.href = mHref);
          }
        } else {
          if (callback != undefined) {
            callback();
          }
        }
      }
      params = json;
    });
    return params;
  };

  const loadCandidacyTableForOddsBasic = (
    dor,
    racetrackCd,
    raceNum,
    callback
  ) => {
    //パラメータからAPIコールを生成
    var req =
      HOST +
      PATH +
      "RaceInfo/candidacyTable.js" +
      BACKWORDPATH +
      "?DOR=" +
      dor +
      "&RacetrackCd=" +
      racetrackCd +
      "&RaceNum=" +
      raceNum +
      "&NarFlag=" +
      isNar();
    getJSON(req, true, function (status, json) {
      if (status == SUCCESS) {
        var intEntryNum = parseInt(json["EntryNum"], 10);
        var entryHorsesArray = json["EntryHorses"];

        for (var i = 1; i <= intEntryNum; i++) {
          var target = entryHorsesArray[i - 1];
          //馬名変更
          //$("#horse_name" + i).html(target["HorseName"]);
          //$("#horse_name" + i).attr("pnum", target["PedigreeNum"]);
          //#4412 お気に入りの赤文字表記
          if (target["PedigreeNum"] != undefined) {
            if (isFavorite(FRACEHORSE, target["PedigreeNum"])) {
              //$("#horse_name" + i).addClass("favorite-horse");
            } else {
              //$("#horse_name" + i).removeClass("favorite-horse");
            }
          }
          //枠色変更
          //document.getElementById("bracketNum" + i).className =
          //  "badge wk" + target["BracketNum"];
          //異常区分コード
          //$("#odds" + i).attr("abnormaldiv", target["AbnormalDiv"]);
          //表示
          //$("#" + "tr" + i).css("display", "");
        }
        for (var j = intEntryNum + 1; j <= 18; j++) {
          //$("#" + "tr" + j).css("display", "none");
        }
      }
      if (callback != undefined) {
        callback();
      }
    });
  };

  //単勝オッズ、枠連ボックス、馬連流し・ボックス・フォーメーション、馬単１着軸流し・２着軸流し・ボックス・フォーメーション
  //三連複１着軸流し・２着軸流し・ボックス・フォーメーション、三連単軸流しｘ6・ボックス・フォーメーションで使用
  const loadOddsWin = async (DOR, RacetrackCd, RaceNum, callback) => {
    //パラメータからAPIコールを生成
    let req =
      HOST +
      PATH +
      "Odds/winTicketOdds.js" +
      BACKWORDPATH +
      "?DOR=" +
      DOR +
      "&RacetrackCd=" +
      RacetrackCd +
      "&RaceNum=" +
      RaceNum +
      "&NarFlag=" +
      isNar();
    let oddsInfo = { upInfo: "", odds: [] };
    await getJSON(req, true, function (status, json) {
      if (status == SUCCESS) {
        //データは常に１件が正しい。
        if (json.length == 1) {
          var displayData = json[0];
          if (displayData["OddsUpdInfo"] != null) {
            // オッズ状況
            oddsInfo.upInfo = displayData["OddsUpdInfo"];
          }
          for (var i = 1; i <= 18; i++) {
            if (parseInt(displayData["EntryNum"], 10) < i) {
              continue;
            }
            oddsInfo.odds.push(displayData["Odds" + i]);
          }
        } else {
          if (
            (getCourse() == PAYINGSUGOTOKU ||
            getCourse() == PAYINGSUGOTOKU_NONE) && !getUserAgent()
          ) {
            location.href = HOST +
                RACEPATH +
                "card?DOR=" +
                DOR +
                "&RacetrackCd=" +
                RacetrackCd +
                "&RaceNum=" +
                RaceNum;
          } else {
            location.href = HOST +
                RACEPATH +
                "card?DOR=" +
                DOR +
                "&RacetrackCd=" +
                RacetrackCd +
                "&RaceNum=" +
                RaceNum;
          }
        }
      }
      if (callback != undefined) {
        callback();
      }
    });
    return oddsInfo;
  };

  //単勝オッズ　人気表示
  const loadOddsPopularWin = (DOR, RacetrackCd, RaceNum, callback) => {
    //パラメータからAPIコールを生成
    var req =
      HOST +
      PATH +
      "Odds/winTicketOdds.js" +
      BACKWORDPATH +
      "?DOR=" +
      DOR +
      "&RacetrackCd=" +
      RacetrackCd +
      "&RaceNum=" +
      RaceNum +
      "&NarFlag=" +
      isNar();
    getJSON(req, true, function (status, json) {
      if (status == SUCCESS) {
        //データは常に１件が正しい。
        if (json.length == 1) {
          var displayData = json[0];
          //新しいjsonを作る
          var oddsObj = new Array();
          for (var i = 1; i <= 18; i++) {
            var id = "Odds" + i;
            if (displayData[id] && displayData[id].indexOf("---.-") == -1) {
              var obj = { name: id, Odds: parseFloat(displayData[id]) };
              oddsObj.push(obj);
            }
          }
          //オッズでソートする
          var sort_by = function (field, reverse, primer) {
            reverse = reverse ? -1 : 1;
            return function (a, b) {
              a = a[field] * 10;
              b = b[field] * 10;
              if (typeof primer != "undefined") {
                a = primer(a);
                b = primer(b);
              }
              if (a < b) return reverse * -1;
              if (a > b) return reverse * 1;
            };
          };
          oddsObj.sort(sort_by("Odds", false, parseInt));
          //人気表示
          var preOdds;
          var popular = 1;
          for (var i = 0; i < oddsObj.length; i++) {
            var useOdds = oddsObj[i];
            //前のオッズと同じだった場合、同人気
            if (preOdds != useOdds.Odds) {
              popular = i + 1;
            }
            var num = useOdds.name.split("Odds")[1];
            /*if ($("#odds" + num) != undefined && $("#odds" + num) != null) {
              $("#odds" + num).html(
                $("#odds" + useOdds.name.split("Odds")[1]).text() +
                  "<br><span>(" +
                  popular +
                  "人気)</span>"
              );
            }*/
            preOdds = useOdds.Odds;
          }
        }
      }
      if (callback != undefined) {
        callback();
      }
    });
  };

  const setRaceBetByArray = (
    params,
    TOB,
    Method,
    betArray,
    doFlgArray,
    callback
  ) => {
    document.body.className = "";
    if (getCourse() == PAYINGSUGOTOKU || getCourse() == PAYINGSUGOTOKU_NONE) {
      setRaceBet4sg(params, TOB, Method, betArray, doFlgArray, null);
      callback()
    } else {
      var nowindex = 0;
      //順番に登録させるため再帰的に登録
      function setRaceBetloop(nowindex) {
        setRaceBet(
          params["DOR"],
          params["RacetrackCd"],
          params["RaceYear"],
          params["RaceNum"],
          params["RaceMeeting"],
          params["RaceDay"],
          TOB,
          Method,
          betArray[nowindex],
          doFlgArray[nowindex],
          function () {
            var nextindex = nowindex + 1;
            if (nextindex < betArray.length) {
              setRaceBetloop(nextindex);
            } else {
              callback();
            }
          }
        );
      }
      setRaceBetloop(nowindex);
    }
    document.body.className = "status-loaded";
  };

  //買い目登録
  const setRaceBetByParams = (params, TOB, Method, Bet, doflg, callback) => {
    setRaceBet(
      params["DOR"],
      params["RacetrackCd"],
      params["RaceYear"],
      params["RaceNum"],
      params["RaceMeeting"],
      params["RaceDay"],
      TOB,
      Method,
      Bet,
      doflg,
      callback
    );
  };

  //買い目登録
  const setRaceBet = (
    DOR,
    RacetrackCd,
    RaceYear,
    RaceNum,
    RaceMeeting,
    RaceDay,
    TOB,
    Method,
    Bet,
    doflg,
    callback
  ) => {
    if (doflg) {
      if (RaceNum < 10) {
        RaceNum = "0" + String(parseInt(RaceNum, 10));
      }
      //パラメータからAPIコールを生成
      let req =
        HOST +
        PATH +
        "Odds/setRaceBet.js" +
        BACKWORDPATH +
        "?dor=" +
        DOR +
        "&racetrackCd=" +
        RacetrackCd +
        "&raceYear=" +
        RaceYear +
        "&raceNum=" +
        RaceNum +
        "&raceMeeting=" +
        RaceMeeting +
        "&raceDay=" +
        RaceDay +
        "&TOB=" +
        TOB +
        "&method=" +
        Method +
        "&bet=" +
        Bet;
      getJSON(req, true, function (status, json) {
        //コールバック
        callback();
      });
    } else {
      //コールバック
      callback();
    }
  };
  //ソートを変更
  const changeSortForOdds = (obj) => {
    let sortType = 3;
    //ソート情報（sortType） 1:my印昇順 2:my印降順 3:馬番昇順 4:馬番降順 5:オッズ昇順 6:オッズ降順
    if ($("#" + obj.id).hasClass("tablesorter-headerAsc")) {
      // 昇順→降順
      $("#" + obj.id).removeClass("tablesorter-headerAsc");
      $("#" + obj.id).addClass("tablesorter-headerDesc");

      if (obj.id == "sort_my") {
        sortType = 2;
      } else if (obj.id == "sort_num") {
        sortType = 4;
      } else if (obj.id == "sort_odds") {
        sortType = 6;
      }
    } else if ($("#" + obj.id).hasClass("tablesorter-headerDesc")) {
      // 降順→ソートなし
      $("#" + obj.id).removeClass("tablesorter-headerDesc");
      sortType = 3; //デフォルト（馬番昇順）に戻る
    } else {
      // ソートなし→昇順
      $("#" + obj.id).addClass("tablesorter-headerAsc");

      if (obj.id == "sort_my") {
        sortType = 1;
      } else if (obj.id == "sort_num") {
        sortType = 3;
      } else if (obj.id == "sort_odds") {
        sortType = 5;
      }
    }
    return sortType;
  };

  //オッズ情報取得 type:識別
  function retrieveOdds(dor, racetrackCd, raceNum, type, callback) {
    var jsName = "";
    switch (type) {
      case TOB_BRACKET:
        jsName = "bracketQuinellaOdds.js";
        break;
      case TOB_BRACKET_EXACTA:
        jsName = "bracketExactaOdds.js";
        break;
      case TOB_QUINELLA:
        jsName = "quinellaOdds.js";
        break;
      case TOB_EXACTA_MULTI:
        jsName = "exactaOdds.js";
        break;
      case TOB_WIDE:
        jsName = "wideOdds.js";
        break;
      case TOB_TRIO:
        jsName = "trioOdds.js";
        break;
      case TOB_TRIFECTA:
        jsName = "trifectaOdds.js";
        break;
    }

    if (jsName != "") {
      try {
        var req =
          HOST +
          PATH +
          "Odds/" +
          jsName +
          BACKWORDPATH +
          "?DOR=" +
          dor +
          "&RacetrackCd=" +
          racetrackCd +
          "&RaceNum=" +
          raceNum +
          "&NarFlag=" +
          isNar();
        getJSON(req, true, function (status, json) {
          if (status == SUCCESS) {
            //データは常に１件が正しい。
            if (json.length == 1) {
              if (callback != undefined) {
                callback(json[0]);
              }
            } else {
              if (callback != undefined) {
                callback({});
              }
            }
          } else {
            if (callback != undefined) {
              callback({});
            }
          }
        });
      } catch (e) {
        if (callback != undefined) {
          callback({});
        }
      }
    } else {
      if (callback != undefined) {
        callback({});
      }
    }
  }

  //ソートを行う（３連複、３連単）
  const sortTioOdds = (sortType) => {
    return function (a, b) {
      let elementA, elementB;
      //ソート情報（sortType） 1:my印昇順 2:my印降順 3:馬番昇順 4:馬番降順 5:オッズ昇順 6:オッズ降順
      switch (sortType) {
        case 1: //my印昇順
        case 3: //馬番昇順
        case 5: //オッズ昇順
          elementA = a;
          elementB = b;
          break;
        case 2: //my印降順
        case 4: //馬番降順
        case 6: //オッズ降順
          elementA = b;
          elementB = a;
          break;
      }
      if (sortType == 5 || sortType == 6) {
        // オッズ無しフラグ
        let oddsFlagA = false;
        let oddsFlagB = false;
        if (elementA["odds"] === "---.-") {
          elementA["odds"] = 999999999;
          oddsFlagA = true;
        }
        if (elementB["odds"] === "---.-") {
          elementB["odds"] = 999999999;
          oddsFlagB = true;
        }

        if (Number(elementA["odds"]) === 0) return 1;
        if (Number(elementB["odds"]) === 0) return -1;
        if (Number(elementA["odds"]) < Number(elementB["odds"])) return -1;
        if (Number(elementA["odds"]) > Number(elementB["odds"])) return 1;
      } else {
        let element1_1,
          element1_2,
          element1_3,
          element2_1,
          element2_2,
          element2_3;
        if (sortType == 1 || sortType == 2) {
          element1_1 = elementA["mark1"] === 0 ? 6 : elementA["mark1"];
          element1_2 = elementA["mark2"] === 0 ? 6 : elementA["mark2"];
          element1_3 = elementA["mark3"] === 0 ? 6 : elementA["mark3"];
          element2_1 = elementB["mark1"] === 0 ? 6 : elementB["mark1"];
          element2_2 = elementB["mark2"] === 0 ? 6 : elementB["mark2"];
          element2_3 = elementB["mark3"] === 0 ? 6 : elementB["mark3"];
        } else {
          element1_1 = parseInt(elementA["firstHorseNum"], 10);
          element1_2 = parseInt(elementA["secondHorseNum"], 10);
          element1_3 = parseInt(elementA["thirdHorseNum"], 10);
          element2_1 = parseInt(elementB["firstHorseNum"], 10);
          element2_2 = parseInt(elementB["secondHorseNum"], 10);
          element2_3 = parseInt(elementB["thirdHorseNum"], 10);
        }

        if (element1_1 < element2_1) return -1;
        if (element1_1 > element2_1) return 1;

        if (element1_2 < element2_2) return -1;
        if (element1_2 > element2_2) return 1;

        if (element1_3 < element2_3) return -1;
        if (element1_3 >= element2_3) return 1;
      }
    };
  };

  function sortDoubleOdds(sortType) {
    return function (a, b) {
      var elementA, elementB;
      //ソート情報（sortType） 1:my印昇順 2:my印降順 3:馬番昇順 4:馬番降順 5:オッズ昇順 6:オッズ降順
      switch (sortType) {
        case 1: //my印昇順
        case 3: //馬番昇順
        case 5: //オッズ昇順
          elementA = a;
          elementB = b;
          break;
        case 2: //my印降順
        case 4: //馬番降順
        case 6: //オッズ降順
          elementA = b;
          elementB = a;
          break;
      }
      if (sortType == 5 || sortType == 6) {
        if (elementA["odds"] === "---.-") {
          elementA["odds"] = 999999999;
        }
        if (elementB["odds"] === "---.-") {
          elementB["odds"] = 999999999;
        }

        if (Number(elementA["odds"]) === 0) return 1;
        if (Number(elementB["odds"]) === 0) return -1;
        if (Number(elementA["odds"]) < Number(elementB["odds"])) return -1;
        if (Number(elementA["odds"]) > Number(elementB["odds"])) return 1;
      } else {
        var element1_1, element1_2, element2_1, element2_2;
        if (sortType == 1 || sortType == 2) {
          element1_1 = translateMyMark(elementA["mark1"]);
          element1_2 = translateMyMark(elementA["mark2"]);
          element2_1 = translateMyMark(elementB["mark1"]);
          element2_2 = translateMyMark(elementB["mark2"]);
        } else {
          element1_1 = parseInt(elementA["horse1"], 10);
          element1_2 = parseInt(elementA["horse2"], 10);
          element2_1 = parseInt(elementB["horse1"], 10);
          element2_2 = parseInt(elementB["horse2"], 10);
        }

        if (element1_1 < element2_1) return -1;
        if (element1_1 > element2_1) return 1;

        if (element1_2 < element2_2) return -1;
        if (element1_2 >= element2_2) return 1;
      }
    };
  }

  function translateMyMark(mark) {
    var result = 99;
    switch (mark) {
      case "◎":
        result = 1;
        break;
      case "○":
        result = 2;
        break;
      case "▲":
        result = 3;
        break;
      case "△":
        result = 4;
        break;
      case "☆":
        result = 5;
        break;
    }
    return result;
  }

  const oddsClass = (val) => {
    if (val === "---.-") {
      return "txt-odds";
    }
    const valNum = Number(val);
    if (valNum > 0 && valNum < 10) {
      return "txt-odds -no1";
    } else if (valNum > 0 && valNum < 100) {
      return "txt-odds -no2";
    } else return "txt-odds -other";
  };
  return {
    raceResultCheck,
    loadCandidacyTableForOddsBasic,
    loadOddsWin,
    loadOddsPopularWin,
    setRaceBetByArray,
    changeSortForOdds,
    sortTioOdds,
    retrieveOdds,
    sortDoubleOdds,
    setRaceBet,
    oddsClass,
  };
}
