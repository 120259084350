<script setup>
import {ref, onMounted, computed} from "vue";
import {
  getJSON,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  NAR_WP_HOST,
  JRA_WP_HOST,
  PATH,
  BACKWORDPATH,
  NEWSPATH,
  NEWSIMAGEPATH,
} from "../../assets/js/define";
import axios from "axios";
import moment from "moment";

const newsData = ref([])
const importantNews = ref([])
const count = ref(30)

onMounted(async () => {
  await getImportantNews()
  await getNews()
})

const getImportantNews = async () => {
  const wpUrl = isNar() === 1 ? NAR_WP_HOST + "wp-json/nar/index" : JRA_WP_HOST + "wp-json/jra/index";
  await axios.get(wpUrl)
      .then((response) => {
        if (response.data.importantrace.length > 0) {
          importantNews.value = response.data.importantrace.map((element) => {
            element.date = moment(element.date).format("YYYY.MM.DD HH:mm");
            return element
          });
        }
      })
      .catch((e) => console.log(e));
  count.value -= importantNews.value.length
}

const getNews = async () => {
  let request =
      HOST + PATH + "getNewsHeadline.js" + BACKWORDPATH + "?narFlag=" + isNar();
  document.body.className = "";
  await getJSON(request, true, function (status, json) {
    let data = json.SearchResult;

    let page = 1;

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        //リンクの作成
        data[i].urlLink =
            "/" +
            NEWSPATH +
            "article?FileCreateDate=" +
            data[i].FileCreateDate +
            "&SeqNo=" +
            data[i].SeqNo +
            "&Page=" +
            page;

        //日付の整形 / → . に変換
        data[i].AnnouncementTime = data[i].AnnouncementTime.replace(
            new RegExp("/", "g"),
            "."
        );
        data[i].path = data[i]?.ImageFileURL ? HOST + NEWSIMAGEPATH + data[i].ImageFileURL : '';
      }

      newsData.value = data;
    }

    document.body.className = "status-loaded";
  });
}

const listItems = computed(() => {
  return newsData.value.slice(0, count.value);
})

const isMore = () => {
  count.value += 30;
}

</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">ニュース</h1>
    </section>
    <section class="layout-section" v-if="newsData.length || importantNews.length">
      <ul class="list-news">
        <li class="item" v-for="race in importantNews">
          <div class="summary">
            <a :href="race.url"
               class="title"
               v-html="race.title">
            </a>
            <time class="caption"> {{ race.date }} 更新 </time>
          </div>
          <div class="thumb">
            <img v-if="race.thumbnail"
                 src="../../assets/images/common/blank_square.png"
                 alt=""
                 :style="{ 'background-image': 'url(' + race.thumbnail + ')' }"/>
            <!-- デジマース側でno image画像作成する可能性あるためコメントアウトで対応(小林 2022/12/22) -->
            <!-- <img
              v-else
              src="../../assets/images/common/blank_square.png"
              alt=""
              style="
                background-image: url(/assets/images/common/blank_winkeiba.png);
              "
            /> -->
          </div>
          <div class="important">
            <span class="icon-caution">注目!</span>
          </div>
        </li >
        <li class="item" v-for="data in listItems">
          <div class="summary">
            <router-link :to="data.urlLink" class="title">{{ data.Headline }}</router-link>
            <time class="caption">{{ data.AnnouncementTime }} 更新</time>
          </div>
          <div class="thumb">
            <img v-if="data.path"
                 src="../../assets/images/common/blank_square.png"
                 alt=""
                 :style="{ backgroundImage: 'url(' + data.path + ')' }"/>
          </div>
        </li>
      </ul>
      <div class="nav-group">
        <span v-if="listItems.length - count >= 0"
              class="btn-basic -more"
              @click="isMore()">
          もっと見る
        </span>
      </div>
    </section>
    <section v-else>
      ニュース情報はありません
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/news/style.scss" scoped></style>
