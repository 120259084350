<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getJSON,
  getUlrParams,
  SUCCESS,
} from "../../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  POGPATH,
  POGAPIPATH,
  DATAPATH,
} from "../../../assets/js/define";
import PogHeader from "../../../components/pog/PogHeader.vue";
import CommonSearch from "../../../components/pog/search/CommonSearch.vue";
import pogFunc from "../../../functions/pog.js";
const { loadPogUserJudge } = pogFunc();

//検索件数格納
const total = ref();
const mareData = reactive([]);

// もっと見る
const showLimit = 30;
const count = ref(30);
const ListItem = () => {
  const list = [...mareData];

  if (list != undefined && list != null && list.length > 0) {
    return list.slice(0, count.value);
  } else {
    return [];
  }
};
const isMore = () => {
  count.value += showLimit;
};

let createParam = function (params) {
  let param = "";
  if (
    params["keyword"] != undefined &&
    params["keyword"] != null &&
    params["keyword"] != ""
  ) {
    param += "keyword" + "=" + params["keyword"];
  } else {
    param += "keyword" + "=";
  }

  if (
    params["searchType"] != undefined &&
    params["searchType"] != null &&
    params["searchType"] != ""
  ) {
    if (param != "") {
      param += "&";
    }
    param += "searchType" + "=" + params["searchType"];
  }
  if (
    params["searchOption"] != undefined &&
    params["searchOption"] != null &&
    params["searchOption"] != ""
  ) {
    if (param != "") {
      param += "&";
    }
    param += "searchOption" + "=" + params["searchOption"];
  } else {
    if (param != "") {
      param += "&";
    }
    param += "searchOption" + "=0";
  }
  //パラメータが無い場合の処理
  if (param === "keyword=&searchOption=0") {
    return (param = "");
  }
  return param;
};

onMounted(() => {
  document.body.className = "";
  loadPogUserJudge(() => {
    try {
      // リクエストURL作成
      const info = getUlrParams();
      const param = createParam(info.params);

      //クエリ有のケース
      if (param != undefined && param != null && param != "") {
        let request = HOST + POGAPIPATH + "searchPOGParents.js" + BACKWORDPATH;
        if (param != null && param != "") {
          request += "?" + param;
        }

        const messageFlag = true;

        //APIからJSON取得
        getJSON(request, messageFlag, function (status, json) {
          if ((status = SUCCESS)) {
            if (json != undefined && json != null && json != "") {
              //取得データの格納
              const result = json;

              //表示件数の格納
              if (result.length > 0) {
                total.value = json[0].CountAll;
              }

              //詳細ページのリンク作成 pog/mare?BreedingNum=xxxxxxxxx
              for (let i = 0; i < result.length; i++) {
                result[i].UrlLink =
                  HOST +
                  DATAPATH +
                  "mare?" +
                  "BreedingNum=" +
                  result[i].BreedingNum;

                //産駒一覧アイコンのリンク
                if (result[i].HasPOG === 1) {
                  result[i].HorseOffSpringLink =
                    HOST +
                    POGPATH +
                    "search_horselist?ScreenDiv=5&Word=" +
                    result[i].HorseName +
                    "&Option=0";
                }
              }

              result.forEach((element) => mareData.push(element));
            }
          }
          document.body.className = "status-loaded";
        });

        //クエリ無のケース
      } else {
        let request = HOST + POGAPIPATH + "searchPOGParents.js" + BACKWORDPATH;

        request += "?" + "searchOption=0&sort=0";

        const messageFlag = true;

        //APIからJSON取得
        getJSON(request, messageFlag, function (status, json) {
          if (status === SUCCESS) {
            if (json != undefined && json != null && json != "") {
              //取得データの格納
              const result = json;

              //表示件数の格納
              if (result.length > 0) {
                total.value = json[0].CountAll;
              }

              //詳細ページのリンク作成 pog/mare?BreedingNum=xxxxxxxxx
              for (let i = 0; i < result.length; i++) {
                result[i].UrlLink =
                  HOST +
                  DATAPATH +
                  "mare?" +
                  "BreedingNum=" +
                  result[i].BreedingNum;

                //産駒一覧アイコンのリンク
                if (result[i].HasPOG === 1) {
                  result[i].HorseOffSpringLink =
                    HOST +
                    POGPATH +
                    "search_horselist?ScreenDiv=4&Word=" +
                    result[i].HorseName +
                    "&Option=0";
                }
              }

              result.forEach((element) => mareData.push(element));
            }
          }
          document.body.className = "status-loaded";
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
});
</script>

<template>
  <main class="layout-main">
    <PogHeader />

    <h2 class="heading-circle -padding">POG 検索</h2>
    <CommonSearch name="pog_mare" />

    <section class="layout-section">
      <div class="inner">
        <h2 class="heading-circle -nomargin">
          繁殖牝馬検索結果: {{ total || 0 }}件
        </h2>
        <div v-if="total > 200" class="system-message -notice">
          最大件数を超えた為、200件まで表示します。<br />条件を絞って再検索をして下さい。
        </div>
      </div>

      <table class="table-line -fit -middle -searchresult">
        <tbody>
          <tr v-for="(item, index) in ListItem()" :key="index">
            <td class="name">
              <a :href="item.UrlLink">{{ item.HorseName }}</a>
            </td>
            <td v-if="item.HasPOG === 1" class="note">
              <a :href="item.HorseOffSpringLink" class="btn-basic -min -gray"
                >産駒一覧</a
              >
            </td>
            <td v-else class="note"></td>
          </tr>
        </tbody>
      </table>

      <div v-if="ListItem().length - count >= 0" class="nav-group">
        <span class="btn-basic -more" @click="isMore">もっと見る</span>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
