<script setup>
import {ref, onMounted} from 'vue';
import {getJSON, getUrlVars, getAreas, isNar} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH } from "../../assets/js/define";
import ButtonList from "../../components/leading/ButtonList.vue";
import RankingList from "../../components/leading/RankingList.vue";
import {useStoreLeadingWeightLossJockey} from "../../stores/leading-states";
import {storeToRefs} from "pinia";

const updateDate = ref('');
const jockeyList = ref([]);
const years = ref([]);
const areas = ref({})
const params = ref({});
const narFlag = ref(false);

const store = useStoreLeadingWeightLossJockey()
const {selectYear, selectArea, isWin, moneyFlag} = storeToRefs(store);
const {resetList} = store;

function loadData() {
  document.body.className = "";

  let request =
    HOST + PATH + "Leading/weightLossJockeyLeadingAPI.js" + BACKWORDPATH + "?";
  request +=
    "RaceYear=" +
    selectYear.value +
    "&Area=" +
    selectArea.value +
    "&MoneyFlag=" +
    moneyFlag.value +
    "&NarFlag=" +
    isNar();
  getJSON(request, true, function (status, json) {
    updateDate.value = json[0]?.LeadingUpdDate
    jockeyList.value = json[0]?.Leading || [];
  });

  document.body.className = "status-loaded";
}

function changeWinSort(bool) {
  isWin.value = bool;
  moneyFlag.value = bool ? 0 : 1;
  resetData();
}

function resetData() {
  resetList();
  loadData();
}

onMounted(() => {
  narFlag.value = isNar() === 1
  // 1ページに表示する要素数
  const current = new Date();
  params.value = getUrlVars();

  for (let i = 2023; i <= current.getFullYear(); i++) {
    years.value.unshift(i);
  }

  const key = isNar() ? 'narWeightLossJockey' : 'jra'
  areas.value = getAreas(key);
  selectYear.value = selectYear.value || current.getFullYear();

  loadData();
});
</script>

<template>
  <main class="layout-main">
    <button-list current="jockey_weightloss"></button-list>

    <section class="layout-section">
      <nav class="layout-title">
        <h2 class="heading-circle -padding">減量騎手</h2>
        <div class="nav">
          <div class="form-select">
            <select v-model="selectYear" @change="resetData">
              <option v-for="(year, index) in years" :key="index" :value="year">
                {{ year }}年
              </option>
            </select>
          </div>
          <div class="form-select">
            <select v-model="selectArea" @change="resetData">
              <option v-for="area in areas" :value="area.id">
                {{ area.name }}
              </option>
            </select>
          </div>
        </div>
      </nav>

      <nav class="nav-sort">
        <ul>
          <input type="radio" id="leading_01" name="leadings" value="winner" />
          <li :class="{ '-current': isWin }">
            <span @click="changeWinSort(true)" class="label">勝利数順</span>
          </li>
          <li :class="{ '-current': !isWin }">
            <span @click="changeWinSort(false)" class="label">総賞金順</span>
          </li>
        </ul>
      </nav>

      <ranking-list ref="rankingList"
                    :leading-list="jockeyList"
                    :is-nar="narFlag"
                    :update-date="updateDate"
                    :type="'jockey_weightloss'">
      </ranking-list>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/leading/style.scss" scoped></style>
