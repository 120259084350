<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import {
  getJSON,
  SUCCESS,
  PAYINGNONE,
  retrieveCourse,
  PAYINGSUGOTOKU_NONE, getUserId,
} from "../../assets/js/common";
import {
  HOST,
  JRA_WP_HOST,
  POGPATH,
  BACKWORDPATH,
  POGAPIPATH,
} from "../../assets/js/define";
import CommonSearch from "../../components/pog/search/CommonSearch.vue";
import axios from "axios";
import pogFunc from "../../functions/pog.js";
const { loadPogUserJudge, getPogStartDate } = pogFunc();

// WP API URL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=pog-index";

//WP API URL(ガイド)
const API_URL_GUIDE = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=pogrule";

//ガイド モーダルウィンドウの表示制御
const modalOpen = ref(false);
const openModal = () => {
  modalOpen.value = !modalOpen.value;
  document.body.className = "status-modal status-loaded";
};

const closeModal = () => {
  modalOpen.value = !modalOpen.value;
  document.body.className = "status-loaded";
};

let guideHTML = reactive({});
const getRuleData = () => {
  axios
    .get(API_URL_GUIDE)
    .then((response) => {
      Object.assign(guideHTML, response.data[0]);
    })
    .catch((error) => {
      console.log(error);
      document.body.className = "status-loaded";
    });
};

//WPデータ取得
let wpContent = reactive({});
const getPogWpData = () => {
  axios
    .get(API_URL)
    .then((response) => {
      if ("acf" in response.data[0]) {
        Object.assign(wpContent, response.data[0].acf);
      }
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      console.log(error);
      document.body.className = "status-loaded";
    });
};

const yearlyRanking = ref("");
const monthlyRanking = ref("");
const horseRanking = ref("");
onMounted(() => {
  function callRetrieveCourse(callback) {
    try {
      retrieveCourse(callback);
    } catch (e) {
      callback("none");
    }
  }
  callRetrieveCourse(function (courseId, showMarks, userId) {
    if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
      location.href = "/pog/join";
    } else {
      //システム年度/月
      const day = new Date();
      const year = day.getFullYear();
      const month = day.getMonth() + 1;
      userId = userId || getUserId();
      //ユーザ情報取得
      getPogUserInfo(userId, function () {
        //月間TOP3
        getUserRankingTop3(year, month);
        //アーカイブ年度データ生成
        createSelectorOption();
        //WPデータ取得
        getPogWpData();
        getRuleData();
        //ランキングへのリンク生成
        yearlyRanking.value =
            HOST +
            POGPATH +
            "yearly_ranking?ScreenDiv=1&Year=" +
            year +
            "&Month=" +
            month;

        monthlyRanking.value =
            HOST +
            POGPATH +
            "monthly_ranking?ScreenDiv=2&Year=" +
            year +
            "&Month=" +
            month;

        horseRanking.value =
            HOST +
            POGPATH +
            "horse_ranking?ScreenDiv=3&Year=" +
            year +
            "&Month=" +
            month;
      });
    }
  });
});

onUpdated(() => {
  //POGアーカイブ用のイベント生成
  let pogInfo = document.getElementById("pog-year");
  if (pogInfo != null) {
    let node = pogInfo.firstElementChild;
    node.addEventListener("click", (e) => {
      e.target.value = "";
    });
    node.addEventListener("change", () => {
      if (node.value) {
        if (node.value.slice(0, 1)) {
          location.href = HOST + node.value.slice(1);
        } else {
          location.href = HOST + node.value;
        }
      }
    });
  }
});

//POGユーザ情報を取得する
const nickName = ref("-");
const rankAnnual = ref("-");
const rankMonth = ref("-");
const ptsAnnual = ref(0);
const prizeDiv = ref(0);
const entryList = reactive([]);
const getPogUserInfo = (userId, callback) => {
  const request =
    HOST +
    POGAPIPATH +
    "getPogUserInfo.js" +
    BACKWORDPATH +
    "?UserID=" +
    userId;

  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      if (json && json !== 1) {
        if (json.RankAnnual) {
          rankAnnual.value = json.RankAnnual;
        }
        if (json.RankMonth) {
          rankMonth.value = json.RankMonth;
        }
        if (json.NickName) {
          nickName.value = json.NickName;
        }
        if (json.PtsAnnual) {
          ptsAnnual.value = json.PtsAnnual;
        }
        if (json.PrizeDiv) {
          prizeDiv.value = json.PrizeDiv;
        }

        json.EntryLst.forEach((element) => entryList.push(element));
        callback();
      } else {
        location.href = "/pog/join";
      }
    } else {
      location.href = "/pog/join";
    }
  });
};

//年間TOP3を取得する
let searchResult = reactive([]);
const getUserRankingTop3 = (year, month) => {
  const request =
    HOST +
    POGAPIPATH +
    "getPogRanking.js" +
    BACKWORDPATH +
    "?ScreenDiv=1&Year=" +
    year +
    "&Month=" +
    month;
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      if (json && json.SearchResult && json.SearchResult.length) {
        for (let i = 0; i < 3; i++) {
          searchResult.push(json.SearchResult[i]);
        }
      }
    }
  });
};

//アーカイブ用のデータ生成
const year = ref("");
const month = ref("");
let yearOptions = reactive([]);
let createSelectorOption = async () => {
  const pogDate = await getPogStartDate();

  let second = pogDate.second;
  let first = pogDate.first;

  let result = "";
  year.value = first;
  const yearNumber = Number(first) - 2012;
  for (let i = 0; i < yearNumber; i++) {
    let optionValue = "";
    let optionStr = "";
    first--;
    second--;
    // yearの年度
    optionValue += first + 1;
    optionStr = `${Number(first) + 1}-${Number(second) + 1}`;
    yearOptions.push({ optionValue, optionStr });
  }
  return result;
};

//アーカイブ年度選択時の処理
const changeYear = (e) => {
  if (e != null && e.target.value != undefined && e.target.value != null) {
    const year = e.target.value.substring(0, 4);
    let mon = e.target.value.substring(4, 6);
    mon = String(Number(mon)); //一桁の場合0を削除
    const url = `${HOST}pog/past_yearly_ranking?ScreenDiv=4&Year=${year}&Month=5`;
    location.href = url;
  } else {
    alert("セレクターの値が不正です");
  }
};
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 v-if="'year' in wpContent" class="heading-page">
        {{ wpContent.year }}
      </h1>
      <div class="nav">
        <span @click="openModal()" class="btn-basic -rule -noarrow"
          >ルール</span
        >
      </div>
    </section>
    <section class="layout-section">
      <div class="container-box">
        <div v-if="'announce' in wpContent && wpContent.announce" class="inner">
          <div v-html="wpContent.announce" class="container-information"></div>
        </div>
        <div v-if="'message' in wpContent && wpContent.message && prizeDiv >= 1 && prizeDiv <= 5"
             class="inner">
          <a href="/pog/winner" class="btn-basic -wide -important">
            <div v-html="wpContent.message"></div>
          </a>
        </div>
        <div class="inner">
          <h2 class="heading-circle">順位</h2>
          <table class="table-cell -center -middle -pog">
            <tr>
              <th>年間</th>
              <th>月間</th>
            </tr>
            <tr>
              <td>
                <span class="rank">{{ rankAnnual }}</span
                ><span class="unit">位</span>
              </td>

              <td>
                <span class="rank">{{ rankMonth }}</span
                ><span class="unit">位</span>
              </td>
            </tr>
          </table>
        </div>

        <div class="inner">
          <div class="layout-title">
            <h2 class="heading-circle">登録馬</h2>
            <div class="info _caption -nic">
              {{ nickName }}様：<span class="point">{{ ptsAnnual }}pt</span>
            </div>
          </div>
          <ul v-if="entryList.length" class="list-box -ranking">
            <li v-for="(entry, index) in entryList" :key="index">
              <a
                :href="'/pog/horse_detail?PedigreeNum=' + entry.Code"
                class="link-cell"
              >
                <span class="name">{{ entry.Name }}</span>
                <span class="total">{{ entry.Pts }}pt</span>
              </a>
            </li>
          </ul>
        </div>

        <div class="inner">
          <h2 class="heading-circle">POGランキング</h2>
          <div class="layout-sub">
            <h3 class="heading-bar -nomargin">年間TOP3</h3>
            <ul class="list-box -fit -ranking -pog">
              <li v-for="(result, index) in searchResult" :key="index">
                <a
                  :href="'/pog/user_horse?userId=' + result.Code"
                  class="link-cell"
                >
                  <span class="ranking"
                    ><i
                      :class="
                        index === 0
                          ? 'icon-ranking -no1st'
                          : index === 1
                          ? 'icon-ranking -no2nd'
                          : index === 2
                          ? 'icon-ranking -no3rd'
                          : ''
                      "
                    ></i
                  ></span>
                  <span class="name">{{ result.Name }}</span>
                  <span class="total">{{ result.Pts }}pt</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="layout-sub">
            <h3 class="heading-bar -nomargin">ランキング一覧</h3>
            <ul class="list-box -pog">
              <li>
                <nav class="nav-multi -noborder -cols3 -color">
                  <ul class="list">
                    <li>
                      <a :href="yearlyRanking" class="label">年間</a>
                    </li>
                    <li>
                      <a :href="monthlyRanking" class="label">月間</a>
                    </li>
                    <li>
                      <a :href="horseRanking" class="label">人気馬</a>
                    </li>
                  </ul>
                </nav>
              </li>
            </ul>
          </div>
        </div>
        <div class="inner">
          <h2 class="heading-circle">POG検索</h2>
          <CommonSearch name="pog_racehorse" />
        </div>

        <!-- TODO: wordpressが完成次第、反映 -->
        <div class="inner">
          <h2 class="heading-circle">POG 情報</h2>
          <a v-if="'year' in wpContent" href="/pog/info" class="btn-basic -wide"
            >{{ wpContent.year.replace("POG", "") }}POG情報</a
          >
        </div>

        <div class="inner">
          <h2 class="heading-circle">POG アーカイブ</h2>
          <!-- TODO: idがないので、イベント監視ができない -->
          <template v-if="'archives' in wpContent">
            <span v-html="wpContent.archives"></span>
          </template>
          のアーカイブを見る
        </div>
      </div>
    </section>
    <teleport to="body" v-if="modalOpen">
      <div class="modal-layer"></div>
      <div @click.self="closeModal" class="modal-frame">
        <div class="modal-inner">
          <div class="modal-content">
            <div class="modal-title heading-bar">POG 遊び方</div>
            <div
              v-if="guideHTML.content"
              v-html="guideHTML.content.rendered"
              class="modal-body"
            ></div>
          </div>
          <span @click="closeModal" class="modal-close"></span>
        </div>
      </div>
    </teleport>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/pog/style.scss" scoped></style>
