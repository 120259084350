<template>
  <ul class="list-news" v-for="(raceData, raceIndex) in nextRacesData">
    <li data-accordion='["data-btn","data-detail"]' class="item -nopad">
      <div class="feed">
        <div class="head">
          <div class="title" v-html="raceData.name"></div>
          <div class="nav">
            <span v-if="checkCourse()"
                  class="icon-accordion"
                  :class="{ '-active': isWeekOpen[raceIndex] }"
                  @click="handleThisToggle(raceIndex)"
                  data-btn>
            </span>
            <router-link v-else
                         :to="courseId === PAYINGDATA.PAYINGECONOMY ? '/member/join_economy' : '/member'"
                         class="icon-accordion"
                         data-btn>
            </router-link>
          </div>
        </div>
        <transition name="topSlide"
                    @before-enter="beforeEnter"
                    @enter="enter"
                    @before-leave="beforeLeave"
                    @leave="leave">
          <div v-if="isWeekOpen[raceIndex] && Object.keys(raceData.data).length > 0"
               class="topSlide detail"
               :class="{ '-active': isWeekOpen[raceIndex] }">
            <table class="table-cell -center">
              <tbody>
              <template v-for="(race, index) in raceData.data">
                <template v-for="(r, i) in race" :key="i">
                  <tr>
                    <th v-if="displayDor(race, i)"
                        v-html="createDateStrShort(r.DOR)"
                        class="-left"
                        :rowspan="getDorRow(race, r)">
                    </th>
                    <td v-if="displayDor(race, i)" :rowspan="getDorRow(race, r)">
                      <span>
                        {{ getWeekName(index) }}
                      </span>
                    </td>
                    <td>{{ r.Track }}</td>
                    <td class="-left">
                      {{ parseInt(r.RaceNum, 10) }}R・{{r.RaceShortName6 }}
                      ({{ r.TrackTypeCd }}{{ r.Distance }}m・{{r.RaceSymbolCd }})
                      <span :class="getGradeIcon(r)"></span>
                    </td>
                  </tr>
                </template>
              </template>
              </tbody>
            </table>
          </div>
        </transition>
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  data() {
    return {
      isWeekOpen: [],
    };
  },
  props: {
    PAYINGDATA: Object,
    courseId: String,
    nextRacesData: Array,
  },
  methods: {
    checkCourse() {
      return this.courseId !== this.PAYINGDATA.PAYINGECONOMY &&
          this.courseId !== this.PAYINGDATA.PAYINGNONE &&
          this.courseId !== this.PAYINGDATA.PAYINGSUGOTOKU_NONE
    },
    //yyyymmddの文字列をmm/dd（曜日）の文字列に変更する
    createDateStrShort(date) {
      let w = ["日", "月", "火", "水", "木", "金", "土"];
      let result = "";
      if (date != undefined && date != null && date.length == 8) {
        const day = new Date(
          date.slice(0, 4) + "/" + date.slice(4, 6) + "/" + date.slice(6, 8)
        );
        let dayStr = "";
        if (day.getDay() == 6) {
          //土曜日
          dayStr = '<span class="_sat">土</span>';
        } else if (day.getDay() == 0) {
          //日曜日
          dayStr = '<span class="_sun">日</span>';
        } else {
          dayStr = w[day.getDay()];
        }
        result =
          String(parseInt(date.slice(4, 6), 10)) +
          "/" +
          String(parseInt(date.slice(6, 8), 10)) +
          "（" +
          dayStr +
          ")";
      }

      return result;
    },
    getWeekName(index) {
      return index === 'week0' ? '連闘' :
          '中' + index.replace("week", "") + '週'
    },
    displayDor(race, i) {
      return i === 0 || race[i - 1].DOR !== race[i].DOR
    },
    getDorRow(race, r) {
      return race.filter((val) => val.DOR === r.DOR).length
    },
    getGradeIcon(race) {
      switch (race.GradeCd) {
        case 'A':
          return "icon-race -g1"
        case 'B':
          return "icon-race -g2"
        case 'C':
          return "icon-race -g3"
        case 'L':
          return "icon-race -L"
        default:
          switch (race.RaceRegulationCd) {
            case '005':
              return "icon-race -p1"
            case '010':
              return "icon-race -p2"
            case '016':
              return "icon-race -p3"
            case '999':
              return "icon-race -op"
            default:
              return
          }
      }
    },
    handleThisToggle(index) {
      if(this.isWeekOpen.length === 0) {
        this.isWeekOpen = Array(this.nextRacesData.length).fill(false);
      }
      this.isWeekOpen.splice(index, 1, !this.isWeekOpen[index]);
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>
