import {reactive, ref} from 'vue'
import { defineStore } from 'pinia'

export const useStoreRaceResults = defineStore('race-results', () => {
  const raceResultList = ref([]);
  const raceRankingList = ref([]);
  const isDPointRace = ref(false);
  const bracketFlag = ref(false);
  const placeFlag = ref(false);
  const showPayBackFlg = ref(false);
  const returnNumber = ref("");
  const cornerLastRank = ref("");
  const raceResultParams = ref({
    dor: "",
    racetrackCd: "",
    raceNum: "",
    isNar: false,
  });
  const refunds = ref({
    win: [],
    place: [],
    bracket: [],
    quinella: [],
    wide: [],
    exacta: [],
    trio: [],
    trifecta: [],
  });

  return {
    raceResultList,
    raceRankingList,
    isDPointRace,
    bracketFlag,
    placeFlag,
    showPayBackFlg,
    returnNumber,
    cornerLastRank,
    refunds,
    raceResultParams,
  }
})


export default {
  useStoreRaceResults,
}