import {ref} from 'vue';
import {defineStore} from 'pinia'

export const useStoreTopNar = defineStore('top-nar', () => {
  const wpContents = ref({});
  const userCourse = ref(1);
  return {
    wpContents,
    userCourse,
  }
})

export default {
  useStoreTopNar,
}