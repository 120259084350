<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getJSON,
  getUserId,
  changeFavoriteFromCookie,
  SUCCESS,
  prizeToString,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  FRACEHORSE,
  REMOVE,
  PAYINGNONE,
  retrieveCourse,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  RACEPATH,
  JOINPATH
} from "../../assets/js/define";

import useStore from "../../stores/favorite-states"
import FavTabs from "../../components/fav/FavTabs.vue";
import AutoRegistAbout from "../../components/utils/AutoRegistAbout.vue";
import {storeToRefs} from "pinia";

const currentTab = "horse";

// 結果を反映するHTMLオブジェクト群
let mCheckNum = 0; // API使用回数
let mTasks = [];
let mFavApiFailed = false; // API終了フラグ

// ユーザID
let mUserId = "";

let mInfo = null;

let favContentListAll = ref([]);
let favContentList = ref([]);
let favCheckList = ref([]);
let showFavList = ref([]);

// もっと見る表示フラグ
const moreFlag = ref(false);
//削除ボタン表示フラグ
const favFlag = ref(false);
const loadedPage = ref(false);
const isSugotoku = ref(false);

const sortType = ref(0);
const activeSortId = ref("sort_name");
const moreLength = ref(0);
const selectStr = ref("JRA")
const narFlag = ref(false);

const store = useStore.useStoreFavoriteRacehorse();
const {displayType, count} = storeToRefs(store);
const {resetList} = store;

onMounted(() => {
  callRetrieveCourse(function (courseId, marks, userId) {
    narFlag.value = isNar() === 1
    selectStr.value = narFlag.value ? '地方' : 'JRA'
    mUserId = userId || getUserId();
    if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
      location.href = HOST + JOINPATH;
    } else {
      isSugotoku.value = courseId === PAYINGSUGOTOKU || courseId === PAYINGSUGOTOKU_NONE
      doInit();
      document.body.className = "";
    }
  });
});

const callRetrieveCourse = function (callback) {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
};

//自動登録機能モーダルの表示切り替え
const modalOpen = ref(false);
const thisModalUpdate = () => {
  modalOpen.value = true;
};

const doInit = () => {
  //データリセット
  moreLength.value = 0;
  favFlag.value = false;
  moreFlag.value = false;
  loadedPage.value = false;

  favContentListAll.value.splice(0);
  favContentList.value.splice(0);
  favCheckList.value.splice(0);
  showFavList.value.splice(0);

  // リクエストURL作成
  let request =
      HOST +
      PATH +
      "MyFavorite/listMyFavoriteRacehorse.js" +
      BACKWORDPATH +
      "?" +
      "userId=" + encodeURIComponent(mUserId) +
      "&sort=" + sortType.value +
      "&narFlag=" + isNar()
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      favContentListAll.value.splice(0);
      setData(json);
    }
    document.body.className = "status-loaded";
    loadedPage.value = true;
  });
};

// 表示用にデータを加工してオブジェクトに格納する
const setData = (json) => {
  try {
    if (json !== null) {
      for (let i = 0; i < json.length; i++) {
        let favList = {};
        // 名前
        favList.HorseName = json[i].HorseName;
        favList.PedigreeNum = json[i].PedigreeNum;

        //性別・馬齢
        favList.Sex = json[i].Sex;
        favList.RacehorseDiv = json[i].RacehorseDiv;
        favList.Age = favList.RacehorseDiv === 1 ? '' :json[i].Age;

        //出走
        if (json[i].RaceFlag !== null && json[i].RaceFlag !== 0) {
          if (
            json[i].Candidacy.DOR !== null &&
            json[i].Candidacy.RacetrackCd !== null &&
            json[i].Candidacy.RaceNum !== null
          ) {
            let url = HOST + RACEPATH;
            if (json[i].RaceFlag === 1) {
              url +=
                "card?DOR=" +
                json[i].Candidacy.DOR +
                "&RacetrackCd=" +
                json[i].Candidacy.RacetrackCd +
                "&RaceNum=" +
                json[i].Candidacy.RaceNum;
            } else if (json[i].RaceFlag === 2) {
              url +=
                "results?DOR=" +
                json[i].Candidacy.DOR +
                "&RacetrackCd=" +
                json[i].Candidacy.RacetrackCd +
                "&RaceNum=" +
                json[i].Candidacy.RaceNum;
            }
            favList.RaceURL = url;
          }
        }
        favList.RaceFlag = json[i].RaceFlag;

        // 賞金/クラス
        let prize_class = "";
        if (
          json[i].MainEarnings !== undefined &&
          json[i].MainEarnings !== null
        ) {
          prize_class += prizeToString(json[i].MainEarnings);
        } else {
          prize_class += prizeToString(0);
        }
        // JRA
        if (isNar() === 0 && json[i].RacehorseDiv === 0) {
          if (json[i].FlatRaceRank !== null && json[i].FlatRaceRank !== "") {
            prize_class += "<br/>平地：" + json[i].FlatRaceRank;
          }
          if (
            json[i].SteeplechaseRaceRank !== null &&
            json[i].SteeplechaseRaceRank !== "" &&
            json[i].SteeplechaseRaceRank !== "未出走" &&
            json[i].SteeplechaseRaceRank !== "新馬"
          ) {
            prize_class += "<br/>障害：" + json[i].SteeplechaseRaceRank;
          }
        }
        favList.PrizeClass = prize_class;

        favContentListAll.value.push(favList);
        favCheckList.value.push(false);
      }
      showContents(displayType.value);
    }
  } catch (e) {}
};

//「全ての競走馬」「現役馬」「抹消馬」イベント
const switchShowContents = (event) => {
  try {
    displayType.value = event.target.value;
    showContents(displayType.value);
  } catch (e) {
    console.log(e);
  }
  return false;
};

//コンテンツを表示する
const showContents = (loadingId) => {
  favContentList.value.splice(0);
  showFavList.value.splice(0);
  const favlength = favContentListAll.value.length;
  if (Number(loadingId) === 2) {
    //全ての競走馬の表示
    for (let i = 0; i < favlength; i++) {
      favContentList.value.push(favContentListAll.value[i]);
    }
  } else {
    //現役馬・抹消馬の表示
    for (let i = 0; i < favlength; i++) {
      if (favContentListAll.value[i].RacehorseDiv === Number(loadingId)) {
        favContentList.value.push(favContentListAll.value[i]);
      }
    }
  }
  setFavList();
};

//表示用リストを描画
const setFavList = () => {
  showFavList.value = favContentList.value.slice(0, count.value);
  moreFlag.value = showFavList.value.length !== favContentList.value.length;
};

//もっと見るボタン押下
const showMore = () => {
  count.value += 30;
  setFavList();
}

//ソートを変更
//ソート自体はAPIデータ取得時にバックエンドで実施される
const changeSort = (id) => {
  document.body.className = "";
  loadedPage.value = false;

  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_name") {
      if (sortType.value === 0) {
        sortType.value = 1;
      } else {
        sortType.value = 0;
      }
      activeSortId.value = "sort_name";
    } else if (id === "sort_sex_age") {
      if (sortType.value === 2) {
        sortType.value = 3;
      } else {
        sortType.value = 2;
      }
      activeSortId.value = "sort_sex_age";
    } else if (id === "sort_start") {
      if (sortType.value === 4) {
        sortType.value = 5;
      } else {
        sortType.value = 4;
      }
      activeSortId.value = "sort_start";
    } else if (id === "sort_prize") {
      if (sortType.value === 9) {
        sortType.value = 8;
      } else {
        sortType.value = 9;
      }
      activeSortId.value = "sort_prize";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを昇順でセットする
    if (id === "sort_name") {
      sortType.value = 0;
      activeSortId.value = "sort_name";
    } else if (id === "sort_sex_age") {
      sortType.value = 2;
      activeSortId.value = "sort_sex_age";
    } else if (id === "sort_start") {
      sortType.value = 4;
      activeSortId.value = "sort_start";
    } else if (id === "sort_prize") {
      sortType.value = 9;
      activeSortId.value = "sort_prize";
    }
  }
  doInit();

  document.body.className = "status-loaded";
};

// 「すべてを選択」クリック
const setAllSelect = (e) => {
  if (e.target.checked) {
    for (let i = 0; i < favCheckList.value.length; i++) {
      favCheckList.value[i] = true;
    }
    favFlag.value = true;
  } else {
    for (let i = 0; i < favCheckList.value.length; i++) {
      favCheckList.value[i] = false;
    }
    favFlag.value = false;
  }
};

//チェックリストクリック時更新
const setSelect = (index) => {
  favCheckList.value[index] = !favCheckList.value[index];
  moreLength.value = favCheckList.value.filter((n) => n === true).length;
  favFlag.value = moreLength.value > 0;
};

// 「削除」クリック
const deleteSelected = () => {
  try {
    document.body.className = "";

    if (favFlag.value === true) {
      mCheckNum = 0;
      mTasks = [];
      mFavApiFailed = false;

      // チェック済み項目の個数チェック
      for (let i = 0; i < favCheckList.value.length; i++) {
        if (favCheckList.value[i] && favContentList.value[i]) {
          mCheckNum++;
          mTasks.push({
            id: favContentList.value[i].PedigreeNum,
            name: favContentList.value[i].HorseName,
          });
          const id = favContentList.value[i].PedigreeNum;
          const request =
            HOST +
            PATH +
            "MyFavorite/deleteMyFavoriteRacehorse.js" +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(mUserId) +
            "&pedigreeNum=" +
            id;
          getJSON(
            request,
            false,
            function (status, json) {
              if (status === SUCCESS) {
                // cookieからお気に入り情報を削除
                changeFavoriteFromCookie(FRACEHORSE, id, REMOVE);
              } else {
                mFavApiFailed = true;
              }
              doInit();
            },
          );
        }
      }
    }

    document.body.className = "status-loaded";
  } catch (e) {
    console.log(e);
    document.body.className = "status-loaded";
  }
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -fav">
      <h1 class="heading-page">お気に入り</h1>
      <span class="btn-basic -tip" @click="thisModalUpdate()" v-if="!isSugotoku">
        自動登録機能について
      </span>
    </section>

    <FavTabs :currentTab="currentTab" />

    <section
      v-if="favContentListAll.length && loadedPage"
      class="layout-section"
    >
      <div class="layout-title">
        <h2 class="heading-circle -padding">競走馬</h2>
        <div class="nav">
          <div class="form-select">
            <select v-model="displayType" @change="switchShowContents">
              <option value="0">{{selectStr}}現役</option>
              <option value="1">{{selectStr}}抹消</option>
              <option value="2">{{selectStr}}全て</option>
            </select>
          </div>
        </div>
      </div>

      <table class="table-grid -fit -middle -padding">
        <thead>
          <tr class="-center -middle">
            <th>
              <span class="form-checkbox -zen"
                ><label
                  ><input
                    @click="setAllSelect($event)"
                    type="checkbox"
                    value=""
                    name="" /><i></i></label
              ></span>
            </th>
            <th>
              馬名<i
                class="filter-sort"
                :class="{ '-asc': sortType === 0, '-desc': sortType === 1 }"
                @click="changeSort('sort_name')"
              ></i>
            </th>
            <th>
              性別/馬齢<i
                class="filter-sort"
                :class="{ '-asc': sortType === 2, '-desc': sortType === 3 }"
                @click="changeSort('sort_sex_age')"
              ></i>
            </th>
            <th>
              出走<i
                class="filter-sort"
                :class="{ '-asc': sortType === 4, '-desc': sortType === 5 }"
                @click="changeSort('sort_start')"
              ></i>
            </th>
            <th>
              <span v-if="narFlag">地方獲得賞金</span>
              <span v-else>賞金/クラス</span>
              <i class="filter-sort"
                :class="{ '-asc': sortType === 8, '-desc': sortType === 9 }"
                @click="changeSort('sort_prize')"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(favContent, index) in showFavList"
            :key="index"
            :class="{ '-disable': favContent.RacehorseDiv === 1 }"
          >
            <td class="-center">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="setSelect(index)"
                    v-model="favCheckList[index]"
                    :value="favContent.PedigreeNum"
                    :name="favContent.HorseName" /><i></i></label
              ></span>
            </td>
            <td>
              <router-link :to="'/data/horse?PedigreeNum=' + favContent.PedigreeNum">{{
                favContent.HorseName
              }}</router-link>
            </td>
            <td class="-center">{{ favContent.Sex }}{{ favContent.Age }}</td>
            <td class="-center">
              <a v-if="favContent.RacehorseDiv !== 1"
                :href="favContent.RaceURL"
                class="icon-status"
                :class="{
                  '-kakutei': favContent.RaceFlag === 1,
                  '-result': favContent.RaceFlag === 2,
                }"
              ></a>
            </td>
            <td v-html="favContent.PrizeClass"></td>
          </tr>
        </tbody>
      </table>

      <p class="paragraph-leading" v-if="narFlag">成績集計期間: 2020年04月01日以降</p>

      <div v-if="moreFlag" class="nav-group -borderbottom">
        <span @click="showMore" class="btn-basic -more">もっと見る</span>
      </div>
      <nav class="nav-group -left">
        <span
          @click="deleteSelected"
          class="btn-basic -delete"
          :class="{ '-active': favFlag === true }"
          >削除</span
        >
      </nav>
      <div class="inner -favrace">
        <router-link to="/fav/race" class="btn-basic -wide">お気に入り/次走狙い馬出走情報へ</router-link>
      </div>
    </section>
    <section v-else-if="loadedPage" class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">競走馬</h2>
      </div>
      <div class="inner">
        <h3 class="heading-message">お気に入りは登録されていません。</h3>
        <p>
          気になるキーワードで検索してお気に入りに登録してください。<br />お気に入りに登録すると
        </p>
        <ul class="list-number">
          <li>お気に入り競走馬、騎手、調教師の出場レースが一目でわかる</li>
          <li>お気に入り競走馬、騎手、調教師が出場するレースをお知らせ</li>
        </ul>
      </div>
    </section>
    <AutoRegistAbout
      @modal-close="modalOpen = false"
      :modalOpen="modalOpen"
    ></AutoRegistAbout>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/fav/style.scss" scoped></style>
