<template>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
</template>

<script setup>
  import { onMounted } from 'vue'
  import { CERTPATH, HOST } from '../../assets/js/define'
  import axios from 'axios'
  /**
   * Get the URL parameter value
   *
   * @param  name {string} パラメータのキー文字列
   * @return  url {url} 対象のURL文字列（任意）
   */
  const getParam = (name) => {
    let url = window.location.href
    name = name.replace(/[\[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    let results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  onMounted(async () => {
    const loginMethod = getParam('method')
    const req = HOST + CERTPATH + loginMethod
    console.log(req)
    const res = await axios.get(req)
    location.href = res.data?.url ? res.data?.url : '/'
  })
</script>
