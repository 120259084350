import {BACKWORDPATH, DATAPATH, PATH} from "../../assets/js/define";
import {dayDiv, getJSON, monthDiv, prizeToString, SUCCESS, yearDiv,} from "../../assets/js/common";
import {
  dataBigPrizeWins,
  g1RaceResult,
  getNews,
  getSpecialRaceResults,
  setOddsHtml,
  weeklyRacing,
} from "../../assets/js/data-common";
import {storeToRefs} from "pinia";
import {useStoreCommon} from "../../stores/common-states"
import {useStoreTrainerData} from "../../stores/data/trainer-states";

let storeTrainerData = null;
let narFlag = 0;

export async function fetch(trainerCd) {
  const storeCommon = useStoreCommon()
  const {host, isNarSite} = storeToRefs(storeCommon);
  storeTrainerData = useStoreTrainerData();
  narFlag = isNarSite.value ? 1 : 0;

  await dataTrainer(trainerCd, host.value, isNarSite.value);
  await getWeeklyRacing(trainerCd, host.value);
  await getBigPrizeWins(trainerCd, host.value);
  if (!isNarSite.value) {
    await getG1RaceResult(trainerCd, host.value)
  }
  await getListTrainedHorse(trainerCd, host.value, isNarSite.value);
  await getListPastTrainedHorse(trainerCd, host.value, isNarSite.value);
}

// 調教師データ取得
const dataTrainer = async (trainerCd, host, isNar) => {
  const {
    trainerCdValue,
    TrainerData,
    CareerStatData,
    dataTrainerExt,
    trainerNews,
    thisWeekSpResult,
  } = storeToRefs(storeTrainerData);

  let request =
    host +
    PATH +
    "Master/trainerMasterAPI.js" +
    BACKWORDPATH +
    "?TrainerCd=" +
    trainerCd +
    "&narFlag=" +
    narFlag;
  request = encodeURI(request);
  await getJSON(request, true, async function (status, json) {
    if (status === SUCCESS && json?.length) {
      let useData = json[0].TrainerData;
      trainerCdValue.value = useData.TrainerCd

      let retireFlg; //登録状況があれば1、なければ0
      let retireDiv = "";
      if (useData.RetireDiv == "引退") {
        retireDiv = "<span class='icon-status -erase'></span>";
        retireFlg = "0";
      } else if (useData.RetireDiv == "停止") {
        retireDiv = "<span class='icon-status '></span>";
        retireFlg = "1";
      } else if (useData.RetireDiv == "現役") {
        retireDiv = "<span class='icon-status -active'></span>";
        retireFlg = "1";
      } else {
        retireFlg = "0";
      }

      //写真
      if (useData.FaceImgURL) {
        useData.FaceImgURL = host + "shared/img/database/trainer/" + useData.FaceImgURL;
      } else {
        useData.FaceImgURL = host + 'shared/img/database/trainer/no-photo-trainer.png';
      }

      //所属表示
      let affiliation = useData.Syozoku ?? '';
      if (!isNar) {
        if (useData.Syozoku == "関東") {
          affiliation = "美浦";
        } else if (useData.Syozoku == "関西") {
          affiliation = "栗東";
        } else if (useData.Syozoku == "") {
          if ("NationalityCd" in useData && useData.NationalityCd != null) {
            if (useData.NationalityCd != "日本人") {
              affiliation = "海外";
            } else if (
              "InvitationBelong" in useData &&
              useData.InvitationBelong != null
            ) {
              //地方名
              affiliation = useData.InvitationBelong;
            }
          }
        }
      }

      //生年月日
      let age;
      if (retireFlg == "1" && useData.RetireDiv != "引退") {
        age = "（" + useData.Age + "歳）";
      } else {
        age = "";
      }
      let date = useData.DOB;
      let birthDate = "-";
      if (date != "00000000") {
        birthDate =
          yearDiv(date) + "/" + monthDiv(date) + "/" + dayDiv(date) + age;
      }

      //免許取得年月日
      let licenseDate = "";
      let fromLicense = "";
      if (useData.LicenseDate) {
        licenseDate = yearDiv(useData.LicenseDate);
        if (
          retireFlg == "1" &&
          useData.RetireDiv != "引退" &&
          useData.Syozoku != ""
        ) {
          let myD = new Date();
          let thisYear = myD.getFullYear();
          fromLicense =
            "(" + String(thisYear - licenseDate + 1) + "年目)";
        } else if (
          retireFlg == "1" &&
          useData.RetireDiv == "引退" &&
          useData.Syozoku != ""
        ) {
          fromLicense = "(抹消)";
        }
        licenseDate = licenseDate + "年";
        useData.LicenseDate = licenseDate + fromLicense;
      }

      // 本年勝利
      let raceWins = useData.CurrentYearRaceWins && useData.CurrentYearRaceWins > 0 ?
        useData.CurrentYearRaceWins + "勝" : "-勝";

      // ランキングのURL生成
      let leadUrl = host + "leading/trainer?rank=" + useData.LatestWinsRanking;

      // ランキングのHTML生成
      useData.LatestWinsRanking = useData.LatestWinsRanking && useData.LatestWinsRanking > 0 ?
        raceWins +
        " (" +
        "<a href='" +
        leadUrl +
        "'>" +
        useData.LatestWinsRanking +
        "位</a>" +
        ")" : raceWins + '(-位)'

      // 成績集計日の生成
      if (useData.LeadingUpdated && 8 <= useData.LeadingUpdated.length) {
        let updatedL = useData.LeadingUpdated;
        let str =
          updatedL.slice(0, 4) +
          "年" +
          updatedL.slice(4, 6) +
          "月" +
          updatedL.slice(6, 8) +
          "日";
        if (updatedL.length > 8 && !isNar) {
          str += ' ' + updatedL.slice(8, 10) + ':' + updatedL.slice(10, 12);
        }
        useData.LeadingUpdated = "成績集計 " + str + "現在";
      }

      //調教師データ
      TrainerData.value = {
        TrainerName: useData.TrainerName,
        TrainerNameKana: useData.TrainerNameKana,
        TrainerNameAlpha: useData.TrainerNameAlpha,
        FaceImgURL: useData.FaceImgURL,
        affiliation: affiliation,
        RetireDiv: retireDiv,
        DOB: birthDate,
        LicenseDate: useData.LicenseDate,
        CareerWins: useData.CareerWins,
        CurrentYearRaceWins: useData.CurrentYearRaceWins,
        LatestWinsRanking: useData.LatestWinsRanking,
        LeadingUpdated: useData.LeadingUpdated,
      };

      CareerStatData.value = useData.CareerStat;
      dataTrainerExt.value = useData;

      //関連ニュース
      if (useData.TrainerNews.length) {
        trainerNews.value = await getNews(useData.TrainerNews, host)
      } else {
        trainerNews.value = []
      }

      //今週開催重賞実績
      if (useData?.ThisweekSPResult?.length) {
        thisWeekSpResult.value = await getSpecialRaceResults(useData.ThisweekSPResult)
      } else {
        thisWeekSpResult.value = []
      }
    }
  });
}

//今週出走データ
const getWeeklyRacing = async (mainKey, host) => {
  const {
    schedule,
    totalRaces,
  } = storeToRefs(storeTrainerData);

  const viewKbn = "1";
  const response = await weeklyRacing(viewKbn, mainKey, host, narFlag);
  schedule.value = await setOddsHtml(response?.schedule, host, narFlag);
  totalRaces.value = response?.totalRaces;
}

//重賞勝鞍データ取得
const getBigPrizeWins = async (mainKey, host) => {
  const {
    bigPrizeData,
  } = storeToRefs(storeTrainerData);

  const viewKbn = "1";
  bigPrizeData.value = await dataBigPrizeWins(viewKbn, mainKey, host, narFlag)
}

//GI実績データ
const getG1RaceResult = async (mainKey, host) => {
  const {
    g1RaceResultData,
  } = storeToRefs(storeTrainerData);

  const viewKbn = "1";

  g1RaceResultData.value = await g1RaceResult(viewKbn, mainKey, host);
}

const getListTrainedHorse = async (trainerCd, host, isNar) => {
  const {
    managementHorses,
  } = storeToRefs(storeTrainerData);

  let request =
    host +
    PATH +
    "Master/getListTrainedHorse.js" +
    BACKWORDPATH +
    "?TrainerCd=" +
    trainerCd +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json?.length) {
      let mOrgJson = json;

      for (let i = 0; i < mOrgJson.length; i++) {
        // 馬の性別ごとのbackground-colorの変更
        let sexClass = "";
        if (mOrgJson[i].Sex === "牡") {
          sexClass = "-left -male";
        } else if (mOrgJson[i].Sex === "牝") {
          sexClass = "-left -female";
        } else if (mOrgJson[i].Sex === "セ") {
          sexClass = "-left -senba";
        }
        mOrgJson[i].SexClass = sexClass;

        //賞金額を文字列（*億*万円）に整形する
        let mainEarnings = mOrgJson[i].MainEarnings ? Number(mOrgJson[i].MainEarnings) : 0;
        mainEarnings = prizeToString(mainEarnings);
        mOrgJson[i].MainEarnings = mainEarnings;

        //馬名→競走馬データに遷移
        let horseNameLink = "#";
        horseNameLink =
          host +
          DATAPATH +
          "horse?PedigreeNum=" +
          mOrgJson[i].PedigreeNum;
        mOrgJson[i].HorseNameLink = horseNameLink;

        // 入厩アイコン
        let stablingDiv = "";
        if (!isNar && mOrgJson[i].StablingFlg) {
          stablingDiv = mOrgJson[i].StablingFlg == 0 ?
            "icon-status -grazing" : "icon-status -stables";
        }

        mOrgJson[i].StablingDiv = stablingDiv;
      }

      managementHorses.value = mOrgJson;
    }
  });
}

const getListPastTrainedHorse = async (trainerCd, host, isNar) => {
  const {
    pastManagementHorses,
  } = storeToRefs(storeTrainerData);

  let request =
    host +
    PATH +
    "Master/getListPastTrainedHorse.js" +
    BACKWORDPATH +
    "?TrainerCd=" +
    trainerCd +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json?.length) {
      let pastTrained = json;
      for (let i = 0; i < pastTrained.length; i++) {
        let sexClass = "";
        if (pastTrained[i].Sex === "牡") {
          sexClass = "-left -male";
        } else if (pastTrained[i].Sex === "牝") {
          sexClass = "-left -female";
        } else if (pastTrained[i].Sex === "セ") {
          sexClass = "-left -senba";
        }
        pastTrained[i].SexClass = sexClass;

        //賞金額を文字列（*億*万円）に整形する
        let mainEarnings = pastTrained[i].MainEarnings ? Number(pastTrained[i].MainEarnings) : 0;
        mainEarnings = prizeToString(mainEarnings);
        pastTrained[i].MainEarnings = mainEarnings;

        //馬名→競走馬データに遷移
        let horseNameLink = "#";
        horseNameLink =
          host +
          DATAPATH +
          "horse?PedigreeNum=" +
          pastTrained[i].PedigreeNum;
        pastTrained[i].HorseNameLink = horseNameLink;

        // 入厩アイコン
        let stablingDiv = "";
        if (!isNar && pastTrained[i].StablingFlg) {
          stablingDiv = pastTrained[i].StablingFlg == 0 ?
            "icon-status -grazing" : "icon-status -stables";
        }

        pastTrained[i].StablingDiv = stablingDiv;
      }

      pastManagementHorses.value = pastTrained;
    }
  });
}