<template>
  <div class="container-tabtarget" data-target>
    <div class="item">
      <div class="inner">
        <h2 class="heading-circle">血統</h2>
        <table class="table-cell">
          <tbody v-if="pedigreeStatData && Object.keys(pedigreeStatData).length">
          <tr>
            <td rowspan="4" class="-male">
              <a :href="bloodData.Father.HorseURL">{{
                  bloodData.Father.HorseName
                }}</a>
              <div class="data">
                {{ bloodData.Father.HorseYOB }} {{ bloodData.Father.HorseHair }}
              </div>
            </td>
            <td rowspan="2" class="-male">
              <a :href="bloodData.Horse_4.HorseURL">{{
                  bloodData.Horse_4.HorseName
                }}</a>
              <div class="data">
                {{ bloodData.Horse_4.HorseYOB }}
              </div>
            </td>
            <td class="-male">
              <a :href="bloodData.Horse_8.HorseURL">{{
                  bloodData.Horse_8.HorseName
                }}</a>
            </td>
          </tr>
          <tr>
            <td class="-female">
              <a :href="bloodData.Horse_9.HorseURL">{{
                  bloodData.Horse_9.HorseName
                }}</a>
            </td>
          </tr>
          <tr>
            <td rowspan="2" class="-female">
              <a :href="bloodData.Horse_5.HorseURL">{{
                  bloodData.Horse_5.HorseName
                }}</a>
              <div class="data">
                {{ bloodData.Horse_5.HorseYOB }}
              </div>
            </td>
            <td class="-male">
              <a :href="bloodData.Horse_10.HorseURL">{{
                  bloodData.Horse_10.HorseName
                }}</a>
            </td>
          </tr>
          <tr>
            <td class="-female">
              <a :href="bloodData.Horse_11.HorseURL">{{
                  bloodData.Horse_11.HorseName
                }}</a>
            </td>
          </tr>
          <tr>
            <td rowspan="4" class="-female">
              <a :href="bloodData.Mother.HorseURL">{{
                  bloodData.Mother.HorseName
                }}</a>
              <div class="data">
                {{ bloodData.Mother.HorseYOB }} {{ bloodData.Mother.HorseHair }}
              </div>
            </td>
            <td rowspan="2" class="-male">
              <a :href="bloodData.Horse_6.HorseURL">{{
                  bloodData.Horse_6.HorseName
                }}</a>
              <div class="data">
                {{ bloodData.Horse_6.HorseYOB }}
              </div>
            </td>
            <td class="-male">
              <a :href="bloodData.Horse_12.HorseURL">{{
                  bloodData.Horse_12.HorseName
                }}</a>
            </td>
          </tr>
          <tr>
            <td class="-female">
              <a :href="bloodData.Horse_13.HorseURL">{{
                  bloodData.Horse_13.HorseName
                }}</a>
            </td>
          </tr>
          <tr>
            <td rowspan="2" class="-female">
              <a :href="bloodData.Horse_7.HorseURL">{{
                  bloodData.Horse_7.HorseName
                }}</a>
              <div class="data">
                {{ bloodData.Horse_7.HorseYOB }}
              </div>
            </td>
            <td class="-male">
              <a :href="bloodData.Horse_14.HorseURL">{{
                  bloodData.Horse_14.HorseName
                }}</a>
            </td>
          </tr>
          <tr>
            <td class="-female">
              <a :href="bloodData.Horse_15.HorseURL">{{
                  bloodData.Horse_15.HorseName
                }}</a>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td>該当する血統情報が見つかりませんでした。</td>
          </tr>
          </tbody>
        </table>
        <div class="horse-supplement">
          <em>5代血統内クロス</em><br />
          <div v-if="crossAppend.length">
            <span v-for="(item, index) in crossAppend" :key="index">
              {{ item.horseName }} {{ item.cross }}<br />
            </span>
          </div>
          <div v-else>
            なし
          </div>
        </div>
      </div>
      <div class="inner">
        <h2 class="heading-circle">兄弟馬一覧</h2>
        <table class="table-grid -middle">
          <tbody v-for="(bro, index) in offspringSetData" :key="index">
          <tr class="-left">
            <th colspan="4" :class="bro.sexClass">
              <template v-if="params.PedigreeNum == bro.PedigreeNum">
                <a :class="'bro.racehorseURL' != undefined || 'bro.racehorseURL' != null ? '' : ''" style="color: #000;">{{
                    bro.horseName
                  }}</a>
              </template>
              <template v-else>
                <a :href="bro.racehorseURL"
                   :class="'bro.racehorseURL' != undefined || 'bro.racehorseURL' != null ? '' : ''">{{
                    bro.horseName
                  }}</a>
              </template>
              <span>
                <span v-if="bro.SnkDataFlg === 1" class="icon-status -unregistered"></span>
                <span v-else-if="bro.racehorseDiv === 1" class="icon-status -erase"></span>
              </span>
            </th>
          </tr>
          <tr>
            <td>{{ bro.racehorseYOB }}年生<br />{{ bro.HairColorCd }}</td>
            <td>{{ bro.SexCd }}<span v-if="bro.racehorseDiv === 0 && bro.snkDataFlg === 0">{{ bro.Age }}</span></td>
            <td v-if="bro.FatherName">父:{{ bro.FatherName }}</td>
            <td v-else></td>
            <td v-if="bro.TotalEarnings">{{ bro.TotalEarnings }}</td>
            <td v-else>なし</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="inner" v-if="Object.keys(auctionInfo).length > 0">
        <h2 class="heading-circle">セリ情報</h2>
        <table class="table-grid -middle -seri">
          <tbody>
          <tr>
            <th>取引市場</th>
            <td>{{ auctionInfo.SalesYear.slice(0, 4) + '年' }} {{ auctionInfo.MarketName }}</td>
          </tr>
          <tr>
            <th>セリ区分</th>
            <td>{{ auctionInfo.SalesDiv }}</td>
          </tr>
          <tr>
            <th>落札価格</th>
            <td>{{ auctionInfo.SalesPrice }} (税込)</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrlVars } from "../../../assets/js/common";

export default {
  data() {
    return {
      bloodData: {},
      crossAppend: [],
      offspringSetData: [],
      params: null,
    };
  },
  props: {
    HOST: String,
    pedigreeStatData: Object,
    crossDegreeData: Object,
    horseHairColor: Object,
    offspringData: Object,
    auctionInfo: Object,
    narFlag: Number,
  },
  created() {
    let self = this;
    self.params = getUrlVars();

    //血統
    if (self.pedigreeStatData && Object.keys(self.pedigreeStatData).length) {
      //父
      let FatherName = "";
      let FatherURL = "";
      let FatherYOB = "";
      let FatherHairColor = "";
      if ("FatherName" in self.pedigreeStatData) {
        FatherName = self.pedigreeStatData.FatherName;
        if ("BreedingNum_Father" in self.pedigreeStatData) {
          FatherURL = self.HOST + "data/stud?BreedingNum=" + self.pedigreeStatData.BreedingNum_Father;
          FatherYOB = self.pedigreeStatData.YOB_Father;
          FatherHairColor = self.horseHairColor.HairColorFather;
        }
      }
      //父の父
      let HorseName_4 = "";
      let HorseURL_4 = "";
      let HorseYOB_4 = "";
      if ("HorseName_4" in self.pedigreeStatData) {
        HorseName_4 = self.pedigreeStatData.HorseName_4;
        if ("BreedingNum_4" in self.pedigreeStatData) {
          HorseURL_4 = self.HOST + "data/stud?BreedingNum=" + self.pedigreeStatData.BreedingNum_4;
          HorseYOB_4 = self.pedigreeStatData.YOB_4;
        }
      }
      //父の父の父
      let HorseName_8 = "";
      let HorseURL_8 = "";
      if ("HorseName_8" in self.pedigreeStatData) {
        HorseName_8 = self.pedigreeStatData.HorseName_8;
        if ("BreedingNum_8" in self.pedigreeStatData) {
          HorseURL_8 = self.HOST + "data/stud?BreedingNum=" + self.pedigreeStatData.BreedingNum_8;
        }
      }
      //父の父の母
      let HorseName_9 = "";
      let HorseURL_9 = "";
      if ("HorseName_9" in self.pedigreeStatData) {
        HorseName_9 = self.pedigreeStatData.HorseName_9;
        if ("BreedingNum_9" in self.pedigreeStatData) {
          HorseURL_9 = self.HOST + "data/mare?BreedingNum=" + self.pedigreeStatData.BreedingNum_9;
        }
      }
      //父の母
      let HorseName_5 = "";
      let HorseURL_5 = "";
      let HorseYOB_5 = "";
      if ("HorseName_5" in self.pedigreeStatData) {
        HorseName_5 = self.pedigreeStatData.HorseName_5;
        if ("BreedingNum_5" in self.pedigreeStatData) {
          HorseURL_5 = self.HOST + "data/mare?BreedingNum=" + self.pedigreeStatData.BreedingNum_5;
          HorseYOB_5 = self.pedigreeStatData.YOB_5;
        }
      }
      //父の母の父
      let HorseName_10 = "";
      let HorseURL_10 = "";
      if ("HorseName_10" in self.pedigreeStatData) {
        HorseName_10 = self.pedigreeStatData.HorseName_10;
        if ("BreedingNum_10" in self.pedigreeStatData) {
          HorseURL_10 = self.HOST + "data/stud?BreedingNum=" + self.pedigreeStatData.BreedingNum_10;
        }
      }
      //父の母の母
      let HorseName_11 = "";
      let HorseURL_11 = "";
      if ("HorseName_11" in self.pedigreeStatData) {
        HorseName_11 = self.pedigreeStatData.HorseName_11;
        if ("BreedingNum_11" in self.pedigreeStatData) {
          HorseURL_11 = self.HOST + "data/mare?BreedingNum=" + self.pedigreeStatData.BreedingNum_11;
        }
      }
      //母
      let MotherName = "";
      let MotherURL = "";
      let MotherYOB = "";
      let MotherHairColor = "";
      if ("MotherName" in self.pedigreeStatData) {
        MotherName = self.pedigreeStatData.MotherName;
        if ("BreedingNum_Mother" in self.pedigreeStatData) {
          MotherURL = self.HOST + "data/mare?BreedingNum=" + self.pedigreeStatData.BreedingNum_Mother;
          MotherYOB = self.pedigreeStatData.YOB_Mother;
          MotherHairColor = self.horseHairColor.HairColorMother;
        }
      }
      //母の父
      let HorseName_6 = "";
      let HorseURL_6 = "";
      let HorseYOB_6 = "";
      if ("HorseName_6" in self.pedigreeStatData) {
        HorseName_6 = self.pedigreeStatData.HorseName_6;
        if ("BreedingNum_6" in self.pedigreeStatData) {
          HorseURL_6 = self.HOST + "data/stud?BreedingNum=" + self.pedigreeStatData.BreedingNum_6;
          HorseYOB_6 = self.pedigreeStatData.YOB_6;
        }
      }
      //母の父の父
      let HorseName_12 = "";
      let HorseURL_12 = "";
      if ("HorseName_12" in self.pedigreeStatData) {
        HorseName_12 = self.pedigreeStatData.HorseName_12;
        if ("BreedingNum_12" in self.pedigreeStatData) {
          HorseURL_12 = self.HOST + "data/stud?BreedingNum=" + self.pedigreeStatData.BreedingNum_12;
        }
      }
      //母の父の母
      let HorseName_13 = "";
      let HorseURL_13 = "";
      if ("HorseName_13" in self.pedigreeStatData) {
        HorseName_13 = self.pedigreeStatData.HorseName_13;
        if ("BreedingNum_13" in self.pedigreeStatData) {
          HorseURL_13 = self.HOST + "data/mare?BreedingNum=" + self.pedigreeStatData.BreedingNum_13;
        }
      }
      //母の母
      let HorseName_7 = "";
      let HorseURL_7 = "";
      let HorseYOB_7 = "";
      if ("HorseName_7" in self.pedigreeStatData) {
        HorseName_7 = self.pedigreeStatData.HorseName_7;
        if ("BreedingNum_7" in self.pedigreeStatData) {
          HorseURL_7 = self.HOST + "data/mare?BreedingNum=" + self.pedigreeStatData.BreedingNum_7;
          HorseYOB_7 = self.pedigreeStatData.YOB_7;
        }
      }
      //母の母の父
      let HorseName_14 = "";
      let HorseURL_14 = "";
      if ("HorseName_14" in self.pedigreeStatData) {
        HorseName_14 = self.pedigreeStatData.HorseName_14;
        if ("BreedingNum_14" in self.pedigreeStatData) {
          HorseURL_14 =
            self.HOST +
            "data/stud?BreedingNum=" +
            self.pedigreeStatData.BreedingNum_14;
        }
      }
      //母の母の母
      let HorseName_15 = "";
      let HorseURL_15 = "";
      if ("HorseName_15" in self.pedigreeStatData) {
        HorseName_15 = self.pedigreeStatData.HorseName_15;
        if ("BreedingNum_15" in self.pedigreeStatData) {
          HorseURL_15 =
            self.HOST +
            "data/mare?BreedingNum=" +
            self.pedigreeStatData.BreedingNum_15;
        }
      }
      //5代血統クロス
      let crossAppend = [];
      if (self.crossDegreeData) {
        if (self.crossDegreeData && self.crossDegreeData.length) {
          for (let gen in self.crossDegreeData) {
            let horseName = self.crossDegreeData[gen].horseName;
            let cross = self.crossDegreeData[gen].cross;
            if (horseName !== ' ' && cross !== ' ') {
              crossAppend.push({ horseName, cross });
            }
          }
        }
      }

      //兄弟馬データ
      let offspringSetData = [];
      if (self.offspringData && self.offspringData.length) {
        for (let bro in self.offspringData) {
          let horseName = self.offspringData[bro].HorseName;
          let racehorseDiv = self.offspringData[bro].RacehorseDiv;
          let snkDataFlg = self.offspringData[bro].SnkDataFlg;
          let racehorseURL = self.HOST +
              "data/horse?PedigreeNum=" +
              self.offspringData[bro].PedigreeNum;
          let racehorseYOB = self.offspringData[bro].YOB;
          let HairColorCd = self.offspringData[bro].HairColorCd;
          let SexCd = self.offspringData[bro].SexCd;
          let FatherName = self.offspringData[bro].FatherName;
          let Age = self.offspringData[bro].Age;
          let PedigreeNum = self.offspringData[bro].PedigreeNum;
          let SnkDataFlg = self.offspringData[bro].SnkDataFlg;

          // 性別フォーマット
          let sexClass = "";
          if (SexCd == '1') {
            SexCd = '牡';
            sexClass = '-male';
          } else if (SexCd == '2') {
            SexCd = '牝';
            sexClass = '-female';
          } else if (SexCd == '3') {
            SexCd = 'セ';
          }

          //賞金フォーマット
          let TotalEarnings = "";
          if (
              "TotalEarnings" in self.offspringData[bro] &&
              self.offspringData[bro].TotalEarnings != null &&
              self.offspringData[bro].TotalEarnings !== "" &&
              self.offspringData[bro].TotalEarnings != 'なし'
          ) {
            TotalEarnings = parseInt(self.offspringData[bro].TotalEarnings, 10);
            if (Math.floor(TotalEarnings / 10000) > 0) {
              TotalEarnings =
                  Math.floor(TotalEarnings / 10000) +
                  "億" +
                  (TotalEarnings % 10000) +
                  "万";
            } else {
              TotalEarnings = TotalEarnings + "万";
            }
          } else if (self.offspringData[bro].TotalEarnings == 'なし') {
            TotalEarnings = 'なし';
          }

          offspringSetData.push({
            horseName,
            racehorseDiv,
            snkDataFlg,
            racehorseURL,
            racehorseYOB,
            HairColorCd,
            SexCd,
            sexClass,
            FatherName,
            TotalEarnings,
            Age,
            PedigreeNum,
            SnkDataFlg,
          });
        }
      }

      self.bloodData = {
        Father: {
          HorseName: FatherName,
          HorseURL: FatherURL,
          HorseYOB: FatherYOB,
          HorseHair: FatherHairColor,
        },
        Horse_4: {
          HorseName: HorseName_4,
          HorseURL: HorseURL_4,
          HorseYOB: HorseYOB_4,
        },
        Horse_8: {
          HorseName: HorseName_8,
          HorseURL: HorseURL_8,
        },
        Horse_9: {
          HorseName: HorseName_9,
          HorseURL: HorseURL_9,
        },
        Horse_5: {
          HorseName: HorseName_5,
          HorseURL: HorseURL_5,
          HorseYOB: HorseYOB_5,
        },
        Horse_10: {
          HorseName: HorseName_10,
          HorseURL: HorseURL_10,
        },
        Horse_11: {
          HorseName: HorseName_11,
          HorseURL: HorseURL_11,
        },
        Mother: {
          HorseName: MotherName,
          HorseURL: MotherURL,
          HorseYOB: MotherYOB,
          HorseHair: MotherHairColor,
        },
        Horse_6: {
          HorseName: HorseName_6,
          HorseURL: HorseURL_6,
          HorseYOB: HorseYOB_6,
        },
        Horse_12: {
          HorseName: HorseName_12,
          HorseURL: HorseURL_12,
        },
        Horse_13: {
          HorseName: HorseName_13,
          HorseURL: HorseURL_13,
        },
        Horse_7: {
          HorseName: HorseName_7,
          HorseURL: HorseURL_7,
          HorseYOB: HorseYOB_7,
        },
        Horse_14: {
          HorseName: HorseName_14,
          HorseURL: HorseURL_14,
        },
        Horse_15: {
          HorseName: HorseName_15,
          HorseURL: HorseURL_15,
        },
      };
      self.crossAppend = crossAppend;
      self.offspringSetData = offspringSetData;
    }
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>