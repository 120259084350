<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">払戻金一覧</h1>
    </section>

    <template v-if="isloaded === true">
      <section v-if="NARFLAG === 0" class="layout-section -payout">
        <div
          class="nav-tab -date -jra"
          :class="{
            '-fit2': DORList.length === 2,
            '-fit3': DORList.length === 3,
          }"
        >
          <ul>
            <li
              v-for="(data, index) in DORList"
              :key="index"
              :class="data.tag === 1 ? '-current' : ''"
              v-bind:onclick="`location.href='/race/odds/payout?RacetrackCd=${data.RacetrackCd}&DOR=${data.day}'`"
            >
              <div class="label">
                {{ Number(data.day.slice(4, 6)) }}/{{
                  Number(data.day.slice(6, 8))
                }}(<span
                  :class="{
                    'week _sat': data.date === '土',
                    'week _sun': data.date === '日',
                  }"
                  >{{ data.date }}</span
                >)
              </div>
            </li>
          </ul>
        </div>
        <div
          class="nav-tab -raceinfo -jra"
          :class="{
            '-fit2': race_place.length === 2,
            '-fit3': race_place.length === 3,
          }"
        >
          <ul>
            <li
              v-for="(data, index) in race_place"
              :key="index"
              v-bind:class="data.tag === 1 ? '-current' : ''"
            >
              <div
                class="label"
                v-bind:onclick="`location.href='/race/odds/payout?RacetrackCd=${data.RacetrackCd}&DOR=${data.DOR}'`"
              >
                <div class="place">{{ data.Racetrack }}</div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section v-if="NARFLAG === 1" class="layout-section">
        <div class="container-wrapper -info">
          {{ month }}/{{ day }}(
          <div v-html="date"></div>
          )
        </div>
      </section>

      <section v-if="NARFLAG === 1" class="layout-section">
        <div id="scrollGrid"
             class="nav-tab -scroll -raceinfo"
             :class="scrollGuid && displayScroll ? '' : '-moving'">
          <ul>
            <li
              v-for="(data, index) in race_place"
              :key="index"
              v-bind:class="data.tag === 1 ? '-current' : ''"
            >
              <div
                class="label"
                v-bind:onclick="`location.href='/race/odds/payout?RacetrackCd=${data.RacetrackCd}&DOR=${data.DOR}'`"
              >
                <div class="place">
                  {{ data.Racetrack }}
                  <i class="icon-time -night" v-if="data.isNighter"></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section class="layout-section">
        <div class="container-box">
          <div v-if="payout.length">
            <div v-for="(data, index) in payout" :key="index" class="inner">
              <div class="layout-title">
                <h2 class="heading-circle">
                  {{ data["raceName"] }}
                  <span :class="data['iconGrade'] === 'g1' ? 'icon-race -g1' :
                                data['iconGrade'] === 'jg1' ? 'icon-race -jg1' :
                                data['iconGrade'] === 'g2' ? 'icon-race -g2' :
                                data['iconGrade'] === 'jg2' ? 'icon-race -jg2' :
                                data['iconGrade'] === 'g3' ? 'icon-race -g3' :
                                data['iconGrade'] === 'jg3' ? 'icon-race -jg3' :
                                data['iconGrade'] === 'L' ? 'icon-race -L' :
                                data['iconGrade'] === '500' ? 'icon-race -p1' :
                                data['iconGrade'] === '1000' ? 'icon-race -p2' :
                                data['iconGrade'] === '1600' ? 'icon-race -p3' :
                                data['iconGrade'] === 'OP' ? 'icon-race -op' : ''">
                  </span>
                  <span v-if="is1000" class="icon-race -p2"></span>
                  <span class="-info">{{ data["trackDistance"] }}m</span>
                </h2>
                <div class="info">
                  <a
                    v-bind:href="`/race/results?DOR=${data['result_url'][0]}&RacetrackCd=${data['result_url'][1]}&RaceNum=${data['result_url'][2]}`"
                    ><i class="icon-arrow"></i
                  ></a>
                </div>
              </div>

              <table class="table-grid -middle -center -ranking -marginbottom">
                <tbody>
                  <tr>
                    <th :class="`bg-rank0${data.ranking[0].raceRank}`">
                      {{ data.ranking[0].raceRank }}着
                    </th>
                    <td
                      v-bind:class="`umaban bgcolor-waku${(
                        '0' + parseInt(data['ranking'][0]['wk'])
                      ).slice(-2)}`"
                    >
                      {{ data["ranking"][0]["horseNum"] }}
                    </td>
                    <td class="-left">
                      <a v-if="data['result_url'][1] !== '33'"
                         :href="`/data/horse?PedigreeNum=${data['ranking'][0]['PedigreeNum']}`">
                        {{ data["ranking"][0]["horseName"] }}
                      </a>
                      <span v-else>
                        {{ data["ranking"][0]["horseName"] }}
                      </span>
                    </td>
                    <td>{{ data["ranking"][0]["horseTime"] }}</td>
                    <td>{{ data["ranking"][0]["WinPopularRank"] }}人気</td>
                  </tr>
                  <tr>
                    <th :class="`bg-rank0${data.ranking[1].raceRank}`">
                      {{ data.ranking[1].raceRank }}着
                    </th>
                    <td
                      v-bind:class="`umaban bgcolor-waku${(
                        '0' + parseInt(data['ranking'][1]['wk'])
                      ).slice(-2)}`"
                    >
                      {{ data["ranking"][1]["horseNum"] }}
                    </td>
                    <td class="-left">
                      <a v-if="data['result_url'][1] !== '33'"
                         :href="`/data/horse?PedigreeNum=${data['ranking'][1]['PedigreeNum']}`">
                        {{ data["ranking"][1]["horseName"] }}
                      </a>
                      <span v-else>
                        {{ data["ranking"][1]["horseName"] }}
                      </span>
                    </td>
                    <td>{{ data["ranking"][1]["horseTime"] }}</td>
                    <td>{{ data["ranking"][1]["WinPopularRank"] }}人気</td>
                  </tr>
                  <tr>
                    <th :class="`bg-rank0${data.ranking[2].raceRank}`">
                      {{ data.ranking[2].raceRank }}着
                    </th>
                    <td
                      v-bind:class="`umaban bgcolor-waku${(
                        '0' + parseInt(data['ranking'][2]['wk'])
                      ).slice(-2)}`"
                    >
                    {{ data["ranking"][2]["horseNum"] }}
                  </td>
                  <td class="-left">
                    <a v-if="data['result_url'][1] !== '33'"
                       :href="`/data/horse?PedigreeNum=${data['ranking'][2]['PedigreeNum']}`">
                      {{ data["ranking"][2]["horseName"] }}
                    </a>
                    <span v-else>
                      {{ data["ranking"][2]["horseName"] }}
                    </span>
                  </td>
                  <td>{{ data["ranking"][2]["horseTime"] }}</td>
                  <td>{{ data["ranking"][2]["WinPopularRank"] }}人気</td>
                </tr>
                </tbody>
              </table>
  
              <table class="table-grid -middle -center -results">
                <tbody>
                <template v-for="(win, index) in data.payment_tansho" :key="win.popular">
                  <tr :class="data.payment_tansho.length === index + 1 ? '' : '-noborderbottom'">
                    <td v-if="index === 0"
                        :rowspan="index === 0 ? `${data.payment_tansho.length}` : ''"
                        class="line -win">
                      単勝
                    </td>
                    <td class="-left">
                      <i class="icon-umaban">{{ win.winNum[0] }}</i>
                    </td>
                    <td class="-right">{{ win.refund }}円</td>
                    <td class="-center">{{ win.popular }}人気</td>
                  </tr>
                </template>
                <template v-if="data.payment_fukusho.length">
                  <template  v-for="(place, index) in data.payment_fukusho" :key="place['_id']">
                    <tr :class="data.payment_fukusho.length === index + 1 ? '' : '-noborderbottom'">
                      <td v-if="index === 0"
                          :rowspan="index === 0 ? `${data.payment_fukusho.length}` : ''"
                          class="-borderbottom line -place">
                        複勝
                      </td>
                      <td class="-left">
                        <i class="icon-umaban">{{ place.winNum[0] }}</i>
                      </td>
                      <td class="-right">{{ place.refund }}円</td>
                      <td class="-center">{{ place.popular }}人気</td>
                    </tr>
                  </template>
                </template>
                <template v-for="(bracket, index) in data.payment_wakuren" :key="bracket.popular">
                  <tr :class="data.payment_wakuren.length === index + 1 ? '' : '-noborderbottom'">
                    <td v-if="index === 0"
                        :rowspan="index === 0 ? `${data.payment_wakuren.length}` : ''"
                        class="line -bracket">
                      枠連
                    </td>
                    <td class="-left">
                      <ul class="list-kaimoku">
                        <li>
                          <i class="icon-umaban">{{bracket.winNum[0] }}</i>
                        </li>
                        <li>
                          <i class="icon-umaban">{{bracket.winNum[1]}}</i>
                        </li>
                      </ul>
                    </td>
                    <td class="-right">{{ bracket.refund }}円</td>
                    <td class="-center">{{ bracket.popular }}人気</td>
                  </tr>
                </template>
                <template v-for="(bracket, index) in data.payment_wakutan" :key="bracket.popular">
                  <tr :class="data.payment_wakutan.length === index + 1 ? '' : '-noborderbottom'">
                    <td v-if="index === 0"
                        :rowspan="index === 0 ? `${data.payment_wakutan.length}` : ''"
                        class="line -bracket-exacta">
                      枠単
                    </td>
                    <td class="-left">
                      <ul class="list-kaimoku -arrow">
                        <li>
                          <i class="icon-umaban">{{bracket.winNum[0] }}</i>
                        </li>
                        <li>
                          <i class="icon-umaban">{{bracket.winNum[1]}}</i>
                        </li>
                      </ul>
                    </td>
                    <td class="-right">{{ bracket.refund }}円</td>
                    <td class="-center">{{ bracket.popular }}人気</td>
                  </tr>
                </template>
                <template v-for="(quinella, index) in data.payment_umaren" :key="quinella.popular">
                  <tr :class="data.payment_umaren.length === index + 1 ? '' : '-noborderbottom'">
                    <td v-if="index === 0"
                        :rowspan="index === 0 ? `${data.payment_umaren.length}` : ''"
                        class="line -quinella">
                      馬連
                    </td>
                    <td class="-left">
                      <ul class="list-kaimoku">
                        <li>
                          <i class="icon-umaban">{{quinella.winNum[0]}}</i>
                        </li>
                        <li>
                          <i class="icon-umaban">{{quinella.winNum[1]}}</i>
                        </li>
                      </ul>
                    </td>
                    <td class="-right">{{ quinella.refund }}円</td>
                    <td class="-center">{{ quinella.popular }}人気</td>
                  </tr>
                </template>
                <template v-for="(exacta, index) in data.payment_umatan" :key="exacta.popular">
                  <tr :class="data.payment_umatan.length === index + 1 ? '' : '-noborderbottom'">
                    <td v-if="index === 0"
                        :rowspan="index === 0 ? `${data.payment_umatan.length}` : ''"
                        class="line -exacta">
                      馬単
                    </td>
                    <td class="-left">
                      <ul class="list-kaimoku -arrow">
                        <li>
                          <i class="icon-umaban">{{exacta.winNum[0]}}</i>
                        </li>
                        <li>
                          <i class="icon-umaban">{{exacta.winNum[1] }}</i>
                        </li>
                      </ul>
                    </td>
                    <td class="-right">{{ exacta.refund }}円</td>
                    <td class="-center">{{ exacta.popular }}人気</td>
                  </tr>
                </template>
                <template v-for="(wide, index) in data.payment_wide" :key="wide.popular">
                  <tr :class="data.payment_wide.length === index + 1 ? '' : '-noborderbottom'">
                    <td v-if="index === 0"
                        :rowspan="index === 0 ? `${data.payment_wide.length}` : ''"
                        class="-borderbottom line -wide">
                      ワイド
                    </td>
                    <td class="-left">
                      <ul class="list-kaimoku">
                        <li>
                          <i class="icon-umaban">{{wide.winNum[0] }}</i>
                        </li>
                        <li>
                          <i class="icon-umaban">{{wide.winNum[1] }}</i>
                        </li>
                      </ul>
                    </td>
                    <td class="-right">{{ wide.refund }}円</td>
                    <td class="-center">{{ wide.popular }}人気</td>
                  </tr>
                </template>
                <template v-for="(trio, index) in data.payment_sanrenpuku" :key="trio.popular">
                  <tr :class="data.payment_sanrenpuku.length === index + 1 ? '' : '-noborderbottom'">
                    <td v-if="index === 0"
                        :rowspan="index === 0 ? `${data.payment_sanrenpuku.length}` : ''"
                        class="-borderbottom line -trio">
                      3連複
                    </td>
                    <td class="-left">
                      <ul class="list-kaimoku">
                        <li>
                          <i class="icon-umaban">{{trio.winNum[0] }}</i>
                        </li>
                        <li>
                          <i class="icon-umaban">{{trio.winNum[1] }}</i>
                        </li>
                        <li>
                          <i class="icon-umaban">{{trio.winNum[2]}}</i>
                        </li>
                      </ul>
                    </td>
                    <td class="-right">{{ trio.refund }}円</td>
                    <td class="-center">{{ trio.popular }}人気</td>
                  </tr>
                </template>
                <template v-for="(trifecta, index) in data.payment_sanrentan" :key="trifecta.popular">
                  <tr :class="data.payment_sanrentan.length === index + 1 ? '' : '-noborderbottom'">
                    <td v-if="index === 0"
                        :rowspan="index === 0 ? `${data.payment_sanrentan.length}` : ''"
                        class="-borderbottom line -trifecta">
                      3連単
                    </td>
                    <td class="-left">
                      <ul class="list-kaimoku -arrow">
                        <li>
                          <i class="icon-umaban">{{trifecta.winNum[0] }}</i>
                        </li>
                        <li>
                          <i class="icon-umaban">{{trifecta.winNum[1]}}</i>
                        </li>
                        <li>
                          <i class="icon-umaban">{{trifecta.winNum[2] }}</i>
                        </li>
                      </ul>
                    </td>
                    <td class="-right">{{ trifecta.refund }}円</td>
                    <td class="-center">{{ trifecta.popular }}人気</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <div class="inner">払戻金情報はありません</div>
          </div>
        </div>
      </section>
      <p class="p-caption -padding">
        ※出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。
      </p>
    </template>

    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import { SUCCESS } from "../../assets/js/common";
import {
  getJSON,
  getUrlVars,
  yearDiv,
  monthDiv,
  dayDiv,
  dayWeek,
  replaceComma,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  GLADE_G1,
  GLADE_G2,
  GLADE_G3,
  GLADE_L,
} from "../../assets/js/define";
import moment from "moment";

export default {
  name: "Payout",
  data() {
    return {
      NARFLAG: null,
      DORList: [],
      payout: [],
      tab: [],
      month: "",
      day: "",
      date: "",
      race_place: [],
      result_url: [],
      is1000: false,
      isloaded: false,
      scrollGuid: true,
      displayScroll: false,
      raceLength: 0,
    };
  },
  mounted() {
    let self = this;
    self.NARFLAG = isNar();

    // パラメータ取得
    let params = getUrlVars();

    let DOR;
    let RacetrackCd;

    DOR = params.DOR ?? (self.NARFLAG === 1 ? moment().format("YYYYMMDD") : '')
    RacetrackCd = params.RacetrackCd;

    if (!DOR && self.NARFLAG === 0) {
      //JRAのメニューから「払戻金一覧」に遷移した場合、先週レースの払戻金一覧を取得する
      let request =
          HOST +
          PATH +
          "RaceInfo/getTodayRaceInfo.js" +
          BACKWORDPATH +
          "?narFlag=" +
          self.NARFLAG;
      getJSON(request, true, function (status, json) {
        const today = moment().format('YYYYMMDD')

        const raceDate = json.TargetDate.map(val => val.DOR)
        DOR = raceDate.includes(today) ?
          today :
          moment().day(0).format('YYYYMMDD')

        //TODO DORの直接書き換え（年末年始の特別対応）
        if (Number(today) >= 20231228 && Number(today) <= 20240105) {
          DOR = '20231228'
        }
        // else if (Number(today) >= 20230105 && Number(today) <= 20230106) {
        //   DOR = '20230105'
        // }

        loadPayout(DOR, RacetrackCd);
      })
    } else {
      setDate(DOR)
      loadPayout(DOR, RacetrackCd);
    }

    //日付の取得
    function setDate(DOR) {
      const year = yearDiv(DOR);
      self.month = Number(monthDiv(DOR));
      self.day = Number(dayDiv(DOR));
      self.date = dayWeek(year, self.month, self.day);
    }

    //曜日を文字で取得
    let dayWeekStr = function (year, month, day) {
      let result = "";
      const myWeekTbl = new Array("日", "月", "火", "水", "木", "金", "土");
      const myDate = new Date(year, month - 1, day);
      const myWeek = myDate.getDay();
      result = myWeekTbl[myWeek];
      return result;
    };

    //払戻金一覧取得
    function loadPayout(DOR, RacetrackCd) {
      let request =
        HOST +
        PATH +
        "RaceInfo/getPayoutList.js" +
        BACKWORDPATH +
        "?dor=" +
        DOR +
        "&narFlag=" +
        self.NARFLAG;
      document.body.className = "";
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          try {
            if (
              json.DORList &&
              json.DORList.length > 0 &&
              json.RacetrackList &&
              json.RacetrackList[DOR] &&
              json.RacetrackList[DOR].length
            ) {
              self.DORList = json.DORList;
              self.raceLength = json.RacetrackList[DOR].length
              RacetrackCd = RacetrackCd ?? getRaceTrackCd(json.RacetrackList[DOR]);
              for (let i = 0; i < self.DORList.length; i++) {
                if (json.DORList[i] === DOR) {
                  self.DORList[i] = {
                    date: dayWeekStr(
                      Number(json.DORList[i].slice(0, 4)),
                      Number(json.DORList[i].slice(4, 6)),
                      Number(json.DORList[i].slice(6, 8))
                    ),
                    day: json.DORList[i],
                    tag: 1,
                    RacetrackCd,
                  };
                } else {
                  self.DORList[i] = {
                    date: dayWeekStr(
                      Number(json.DORList[i].slice(0, 4)),
                      Number(json.DORList[i].slice(4, 6)),
                      Number(json.DORList[i].slice(6, 8))
                    ),
                    day: json.DORList[i],
                    tag: 0,
                    RacetrackCd,
                  };
                }
              }
              const result = json.RaceResults.filter(
                (data) => data.DOR === DOR && data.RacetrackCd === RacetrackCd
              ).sort(function (a, b) {
                return a.RaceNum < b.RaceNum ? -1 : 1;
              });

              const place_list = json.RacetrackList;
              self.race_place = setTab(DOR, RacetrackCd, place_list);
              setPayout(DOR, RacetrackCd, result);
            }
          } catch (e) {
            console.log(e);
          }
        }
        self.isloaded = true;
        document.body.className = "status-loaded";
        if (isNar() === 1) {
          setTimeout(function () {
            self.scroll()
          }, 10);
        }
      });
    }

    //タブ
    function setTab(DOR, RacetrackCd, place_list) {
      let result = [];
      for (let key in place_list) {
        if (key === DOR) {
          for (let race_idx in place_list[key]) {
            if (RacetrackCd === place_list[key][race_idx]["RacetrackCd"]) {
              result.push({
                Racetrack: place_list[key][race_idx]["Racetrack"],
                RacetrackCd: place_list[key][race_idx]["RacetrackCd"],
                RaceNum: place_list[key][race_idx]["RaceNum"] * 1,
                StartTime: place_list[key][race_idx]["StartTime"],
                isNighter: place_list[key][race_idx]["isNighter"],
                tag: 1,
                DOR: DOR,
              });
            } else {
              result.push({
                Racetrack: place_list[key][race_idx]["Racetrack"],
                RacetrackCd: place_list[key][race_idx]["RacetrackCd"],
                RaceNum: place_list[key][race_idx]["RaceNum"] * 1,
                StartTime: place_list[key][race_idx]["StartTime"],
                isNighter: place_list[key][race_idx]["isNighter"],
                tag: 0,
                DOR: DOR,
              });
            }
          }
        }
      }

      if (self.NARFLAG === 1) {
        const sortArray = [44, 45, 43, 42, 30, 31, 32, 34, 35, 36, 37, 37, 38, 38, 40, 41, 46, 47, 48, 49, 50,
          51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 33];

        result.sort((x, y) => {
          return sortArray.indexOf(Number(x.RacetrackCd)) - sortArray.indexOf(Number(y.RacetrackCd));
        })
      }

      return result;
    }

    //各レース設定
    function setPayout(DOR, RacetrackCd, RaceResults) {
      let raceCount = 0; //描画したレースの数
      for (let i = 0; i < RaceResults.length; i++) {
        let result = RaceResults[i];
        if (result.DOR == DOR && result.RacetrackCd == RacetrackCd) {
          const one_data = {};
          one_data.raceName =
            parseInt(result.RaceNum, 10) + "R " + result.RaceName;

          one_data.trackDistance = result.TrackTypeCd + result.Distance;

          one_data.result_url = [DOR, RacetrackCd, result.RaceNum];

          //重賞アイコン、競争条件アイコン
          //グレードコード
          let icon = "";
          if (result.GradeCd == GLADE_G1) {
            icon = "g1";
            if (result.TrackTypeCd == "障") {
              icon = "jg1";
            }
          } else if (result.GradeCd == GLADE_G2) {
            icon = "g2";
            if (result.TrackTypeCd == "障") {
              icon = "jg2";
            }
          } else if (result.GradeCd == GLADE_G3) {
            icon = "g3";
            if (result.TrackTypeCd == "障") {
              icon = "jg3";
            }
            // 215 リステッドレース対応
          } else if (result.GradeCd == GLADE_L) {
            icon = "L";
          } else {
            if (result.isSpRace) {
              //#4838 重賞でない特別競走には競争条件をアイコン表示
              let regulation_txt = "";
              //20190730 WIN_KEIBA_SUPPORT-256
              if (result.DOR.slice(0, 6) <= 201905) {
                if (result.RaceRegulationCd == "005") {
                  //500万下
                  regulation_txt = "500";
                } else if (result.RaceRegulationCd == "010") {
                  //1000万下
                  regulation_txt = "1000";
                } else if (result.RaceRegulationCd == "016") {
                  //1600万下
                  regulation_txt = "1600";
                } else if (result.RaceRegulationCd == "999") {
                  //オープン
                  regulation_txt = "OP";
                }
              } else {
                if (result.RaceRegulationCd == "005") {
                  //500万下
                  regulation_txt = "500";
                } else if (result.RaceRegulationCd == "010") {
                  //1000万下
                  regulation_txt = "1000";
                } else if (result.RaceRegulationCd == "016") {
                  //1600万下
                  regulation_txt = "1600";
                } else if (result.RaceRegulationCd == "999") {
                  regulation_txt = "OP";
                }
              }
              icon = regulation_txt;
            }
          }
          one_data.iconGrade = icon;
          //払戻金情報があれば描画する
          if (result.Dividend) {
            if (result.Status === "1" && result.RaceRanking) {
              //1～3着描画
              one_data.ranking = setHorseHtml(result.RaceRanking);
              //払戻金描画
              one_data.payment_tansho = setPayHtml(result.Dividend, "単勝");
              one_data.payment_fukusho = setPayHtml(result.Dividend, "複勝");
              one_data.payment_wakuren = setPayHtml(result.Dividend, "枠連");
              one_data.payment_wakutan = setPayHtml(result.Dividend, "枠単");
              one_data.payment_umaren = setPayHtml(result.Dividend, "馬連");
              one_data.payment_umatan = setPayHtml(result.Dividend, "馬単");
              one_data.payment_wide = setPayHtml(result.Dividend, "ワイド");
              one_data.payment_sanrenpuku = setPayHtml(
                result.Dividend,
                "３連複"
              );
              one_data.payment_sanrentan = setPayHtml(
                result.Dividend,
                "３連単"
              );
              raceCount++;
            }
            self.payout.push(one_data);
          }
        }
      }
    }

    function getRaceTrackCd(RacetrackList) {
      const sortArray = [44, 45, 43, 42, 30, 31, 32, 34, 35, 36, 37, 37, 38, 38, 40, 41, 46, 47, 48, 49, 50,
        51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 33];

      if (isNar() === 1) {
        RacetrackList = RacetrackList.sort(function (a, b) {
          return sortArray.indexOf(Number(a.RacetrackCd)) - sortArray.indexOf(Number(b.RacetrackCd));
        })
      }
      return RacetrackList[0]?.RacetrackCd
    }

    //払戻金一覧のhtmlを作成
    function setPayHtml(Dividend, name) {
      let result_frame = [];
      let data;
      for (let i = 0; i < Dividend.length; i++) {
        data = Dividend[i];
        let result = {};
        if (data.TOB == name) {
          let rank = "-";
          if (data.PopularRanking) {
            rank = data.PopularRanking;
          }
          let num = data.WinNum;
          result["winNum"] = num.split("-");
          result["refund"] = replaceComma(String(data.Refund * 1));
          result["popular"] = rank;
          result_frame.push(result);
        }
      }

      return result_frame;
    }

    //1～3着馬のhtmlを作成
    function setHorseHtml(RaceRanking) {
      let result = [];

      for (let l = 0; l < RaceRanking.length; l++) {
        let horseDate = RaceRanking[l];
        if (horseDate.RaceRank <= 3) {
          let horseTime = 0;
          if (horseDate.RaceRank == 1 || RacetrackCd === "33") {
            horseTime = horseDate.Time;
          } else {
            horseTime = horseDate.HorseLengths;
          }
          //馬名、馬番、枠番、人気順
          result.push({
            wk: parseInt(horseDate.BracketNum, 10),
            PedigreeNum: horseDate.PedigreeNum,
            horseNum: horseDate.HorseNum,
            horseName: horseDate.HorseName,
            horseTime: horseTime,
            WinPopularRank: horseDate.WinPopularRank,
            raceRank: horseDate.RaceRank,
          });
        }
      }

      return result;
    }
  },
  methods: {
    scroll () {
      let grid = document.getElementById("scrollGrid");
      this.displayScroll = (window.innerWidth - this.raceLength * 90) < 0
      if (grid) {
        const currentList = document.querySelector('#scrollGrid > ul > li.-current');
        const currentPosition = currentList.getBoundingClientRect();
        grid.scrollLeft += currentPosition.left

        grid.addEventListener("scroll", (e) => {
          this.scrollGuid = grid.scrollLeft < 10;
        },{})
      }
    }
  }
};
</script>

<style lang="scss" src="../../assets/css/race/odds/style.scss" scoped></style>
