<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">コースデータ</h1>
    </section>

    <section class="layout-section">
      <div class="inner">
        <h2 class="heading-circle">{{ raceData.courseTitle }}</h2>

        <div>
          <img :src="raceData.thumbnailPath" />
        </div>

        <div v-if="courseInfo" class="couser-status">
          <div class="inner">
            <table class="table-grid -center">
              <tr>
                <th colspan="3">好走枠</th>
              </tr>
              <tr>
                <td :class="{'-on': courseInfo.kw_uchi === 1, '-on -g02': courseInfo.kw_uchi === 2 }">内枠</td>
                <td :class="{'-on': courseInfo.kw_naka === 1, '-on -g02': courseInfo.kw_naka === 2 }">中枠</td>
                <td :class="{'-on': courseInfo.kw_soto === 1, '-on -g02': courseInfo.kw_soto === 2 }">外枠</td>
              </tr>
            </table>

            <table class="table-grid -center">
              <tr>
                <th colspan="4">好走脚質</th>
              </tr>
              <tr>
                <td :class="{'-on': courseInfo.ks_nige === 1, '-on -g02': courseInfo.ks_nige === 2 }">逃げ</td>
                <td :class="{'-on': courseInfo.ks_senko === 1, '-on -g02': courseInfo.ks_senko === 2 }">先行</td>
                <td :class="{'-on': courseInfo.ks_sashi === 1, '-on -g02': courseInfo.ks_sashi === 2 }">差し</td>
                <td :class="{'-on': courseInfo.ks_oikomi === 1, '-on -g02': courseInfo.ks_oikomi === 2 }">追込</td>
              </tr>
            </table>
          </div>
          <p>{{ courseInfo.comment }}</p>
        </div>

        <table class="table-cell -middle -center" v-if="recordData">
          <tr>
            <th class="time">タイム</th>
            <th class="bamei">馬名</th>
            <th class="date">年月日</th>
          </tr>
          <tr>
            <th colspan="3" class="header -left">コースレコード</th>
          </tr>
          <tr>
            <td class="time">{{ recordData.time }}</td>
            <td class="bamei">
              {{ recordData.HorseName }}{{ recordData.Sex }}{{ recordData.Age
              }}<br />{{ recordData.JockyName }}{{ recordData.jockeyWeight }}
            </td>
            <td class="date">
              {{ recordData.date }}<br /><span class="weather">{{
                recordData.condition
              }}</span>
            </td>
          </tr>
          <tr v-if="raceInfo2years">
            <th colspan="3" class="header -left">2歳レコード</th>
          </tr>
          <tr v-if="raceInfo2years">
            <td class="time">{{ raceInfo2years.time }}</td>
            <td class="bamei">
              {{ raceInfo2years.HorseName }}{{ raceInfo2years.Sex
              }}{{ raceInfo2years.Age }}<br />{{ raceInfo2years.JockyName
              }}{{ raceInfo2years.jockeyWeight }}
            </td>
            <td class="date">
              {{ raceInfo2years.date }}<br /><span class="weather">{{
                raceInfo2years.condition
              }}</span>
            </td>
          </tr>
        </table>
      </div>
    </section>

    <section class="layout-section" v-if="courseId !== 'none'">
      <h2 class="heading-circle -padding -bordertop">勝馬頭数</h2>
      <div class="inner">
        <div class="container-footnote">
          <dl>
            <dt class="-past01"></dt>
            <dd>過去1年</dd>
            <dt class="-past02"></dt>
            <dd>過去2年</dd>
            <dt class="-past03"></dt>
            <dd>過去3年</dd>
          </dl>
        </div>
        <table class="table-cell -wingraph -detail">
          <tbody>
            <tr
              v-for="(winNum, index) in courseYearType"
              :key="index"
              class="-bar"
            >
              <td
                :class="
                  winNum[0] === 1
                    ? '-past01'
                    : winNum[0] === 2
                    ? '-past02'
                    : winNum[0] === 3
                    ? '-past03'
                    : winNum[0] === 5 || courseWinRate[0] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[1] === 1
                    ? '-past01'
                    : winNum[1] === 2
                    ? '-past02'
                    : winNum[1] === 3
                    ? '-past03'
                    : winNum[1] === 5 || courseWinRate[1] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[2] === 1
                    ? '-past01'
                    : winNum[2] === 2
                    ? '-past02'
                    : winNum[2] === 3
                    ? '-past03'
                    : winNum[2] === 5 || courseWinRate[2] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[3] === 1
                    ? '-past01'
                    : winNum[3] === 2
                    ? '-past02'
                    : winNum[3] === 3
                    ? '-past03'
                    : winNum[3] === 5 || courseWinRate[3] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[4] === 1
                    ? '-past01'
                    : winNum[4] === 2
                    ? '-past02'
                    : winNum[4] === 3
                    ? '-past03'
                    : winNum[4] === 5 || courseWinRate[4] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[5] === 1
                    ? '-past01'
                    : winNum[5] === 2
                    ? '-past02'
                    : winNum[5] === 3
                    ? '-past03'
                    : winNum[5] === 5 || courseWinRate[5] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[6] === 1
                    ? '-past01'
                    : winNum[6] === 2
                    ? '-past02'
                    : winNum[6] === 3
                    ? '-past03'
                    : winNum[6] === 5 || courseWinRate[6] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[7] === 1
                    ? '-past01'
                    : winNum[7] === 2
                    ? '-past02'
                    : winNum[7] === 3
                    ? '-past03'
                    : winNum[7] === 5 || courseWinRate[7] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[8] === 1
                    ? '-past01'
                    : winNum[8] === 2
                    ? '-past02'
                    : winNum[8] === 3
                    ? '-past03'
                    : winNum[8] === 5 || courseWinRate[8] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[9] === 1
                    ? '-past01'
                    : winNum[9] === 2
                    ? '-past02'
                    : winNum[9] === 3
                    ? '-past03'
                    : winNum[9] === 5 || courseWinRate[9] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[10] === 1
                    ? '-past01'
                    : winNum[10] === 2
                    ? '-past02'
                    : winNum[10] === 3
                    ? '-past03'
                    : winNum[10] === 5 || courseWinRate[10] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[11] === 1
                    ? '-past01'
                    : winNum[11] === 2
                    ? '-past02'
                    : winNum[11] === 3
                    ? '-past03'
                    : winNum[11] === 5 || courseWinRate[11] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[12] === 1
                    ? '-past01'
                    : winNum[12] === 2
                    ? '-past02'
                    : winNum[12] === 3
                    ? '-past03'
                    : winNum[12] === 5 || courseWinRate[12] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[13] === 1
                    ? '-past01'
                    : winNum[13] === 2
                    ? '-past02'
                    : winNum[13] === 3
                    ? '-past03'
                    : winNum[13] === 5 || courseWinRate[13] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[14] === 1
                    ? '-past01'
                    : winNum[14] === 2
                    ? '-past02'
                    : winNum[14] === 3
                    ? '-past03'
                    : winNum[14] === 5 || courseWinRate[14] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                :class="
                  winNum[15] === 1
                    ? '-past01'
                    : winNum[15] === 2
                    ? '-past02'
                    : winNum[15] === 3
                    ? '-past03'
                    : winNum[15] === 5 || courseWinRate[15] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                v-if="NARFLAG === 0"
                :class="
                  winNum[16] === 1
                    ? '-past01'
                    : winNum[16] === 2
                    ? '-past02'
                    : winNum[16] === 3
                    ? '-past03'
                    : winNum[16] === 5 || courseWinRate[16] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
              <td
                v-if="NARFLAG === 0"
                :class="
                  winNum[17] === 1
                    ? '-past01'
                    : winNum[17] === 2
                    ? '-past02'
                    : winNum[17] === 3
                    ? '-past03'
                    : winNum[17] === 5 || courseWinRate[17] === undefined
                    ? '-none'
                    : ''
                "
              ></td>
            </tr>

            <tr class="-center">
              <td class="bgcolor-waku01">1</td>
              <td class="bgcolor-waku01">2</td>
              <td class="bgcolor-waku02">3</td>
              <td class="bgcolor-waku02">4</td>
              <td class="bgcolor-waku03">5</td>
              <td class="bgcolor-waku03">6</td>
              <td class="bgcolor-waku04">7</td>
              <td class="bgcolor-waku04">8</td>
              <td class="bgcolor-waku05">9</td>
              <td class="bgcolor-waku05">10</td>
              <td class="bgcolor-waku06">11</td>
              <td class="bgcolor-waku06">12</td>
              <td class="bgcolor-waku07">13</td>
              <td class="bgcolor-waku07">14</td>
              <td
                :class="{
                  'bgcolor-waku07': NARFLAG === 0,
                  'bgcolor-waku08': NARFLAG === 1,
                }"
              >
                15
              </td>
              <td class="bgcolor-waku08">16</td>
              <td v-if="NARFLAG === 0" class="bgcolor-waku08">17</td>
              <td v-if="NARFLAG === 0" class="bgcolor-waku08">18</td>
            </tr>
          </tbody>
        </table>

        <h3 class="heading-item -sub">馬番ごとの勝率</h3>
        <table class="table-cell -center">
          <tbody v-if="courseWinRate">
            <tr>
              <td class="bgcolor-waku01">1</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[0]?.win === undefined ||
                    courseWinRate[0]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[0]?.win === undefined
                    ? "0.0"
                    : courseWinRate[0].win
                }}
              </td>
              <td class="bgcolor-waku01">2</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[1]?.win === undefined ||
                    courseWinRate[1]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[1]?.win === undefined
                    ? "0.0"
                    : courseWinRate[1].win
                }}
              </td>
              <td class="bgcolor-waku02">3</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[2]?.win === undefined ||
                    courseWinRate[2]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[2]?.win === undefined
                    ? "0.0"
                    : courseWinRate[2].win
                }}
              </td>
              <td class="bgcolor-waku02">4</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[3]?.win === undefined ||
                    courseWinRate[3]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[3]?.win === undefined
                    ? "0.0"
                    : courseWinRate[3].win
                }}
              </td>
            </tr>
            <tr>
              <td class="bgcolor-waku03">5</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[4]?.win === undefined ||
                    courseWinRate[4]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[4]?.win === undefined
                    ? "0.0"
                    : courseWinRate[4].win
                }}
              </td>
              <td class="bgcolor-waku03">6</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[5]?.win === undefined ||
                    courseWinRate[5]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[5]?.win === undefined
                    ? "0.0"
                    : courseWinRate[5].win
                }}
              </td>
              <td class="bgcolor-waku04">7</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[6]?.win === undefined ||
                    courseWinRate[6]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[6]?.win === undefined
                    ? "0.0"
                    : courseWinRate[6].win
                }}
              </td>
              <td class="bgcolor-waku04">8</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[7]?.win === undefined ||
                    courseWinRate[7]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[7]?.win === undefined
                    ? "0.0"
                    : courseWinRate[7].win
                }}
              </td>
            </tr>
            <tr>
              <td class="bgcolor-waku05">9</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[8]?.win === undefined ||
                    courseWinRate[8]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[8]?.win === undefined
                    ? "0.0"
                    : courseWinRate[8].win
                }}
              </td>
              <td class="bgcolor-waku05">10</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[9]?.win === undefined ||
                    courseWinRate[9]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[9]?.win === undefined
                    ? "0.0"
                    : courseWinRate[9].win
                }}
              </td>
              <td class="bgcolor-waku06">11</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[10]?.win === undefined ||
                    courseWinRate[10]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[10]?.win === undefined
                    ? "0.0"
                    : courseWinRate[10].win
                }}
              </td>
              <td class="bgcolor-waku06">12</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[11]?.win === undefined ||
                    courseWinRate[11]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[11]?.win === undefined
                    ? "0.0"
                    : courseWinRate[11].win
                }}
              </td>
            </tr>
            <tr>
              <td class="bgcolor-waku07">13</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[12]?.win === undefined ||
                    courseWinRate[12]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[12]?.win === undefined
                    ? "0.0"
                    : courseWinRate[12].win
                }}
              </td>
              <td class="bgcolor-waku07">14</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[13]?.win === undefined ||
                    courseWinRate[13]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[13]?.win === undefined
                    ? "0.0"
                    : courseWinRate[13].win
                }}
              </td>
              <td
                :class="{
                  'bgcolor-waku07': NARFLAG === 0,
                  'bgcolor-waku08': NARFLAG === 1,
                }"
              >
                15
              </td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[14]?.win === undefined ||
                    courseWinRate[14]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[14]?.win === undefined
                    ? "0.0"
                    : courseWinRate[14].win
                }}
              </td>
              <td class="bgcolor-waku08">16</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[15]?.win === undefined ||
                    courseWinRate[15]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[15]?.win === undefined
                    ? "0.0"
                    : courseWinRate[15].win
                }}
              </td>
            </tr>
            <tr v-if="NARFLAG === 0">
              <td class="bgcolor-waku08">17</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[16]?.win === undefined ||
                    courseWinRate[16]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[16]?.win === undefined
                    ? "0.0"
                    : courseWinRate[16].win
                }}
              </td>
              <td class="bgcolor-waku08">18</td>
              <td
                class="-right"
                :class="{
                  '-none':
                    courseWinRate[17]?.win === undefined ||
                    courseWinRate[17]?.win === '0.0',
                }"
              >
                {{
                  courseWinRate[17]?.win === undefined
                    ? "0.0"
                    : courseWinRate[17].win
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <p class="_right _caption">単位：％</p>
      </div>
    </section>

    <section class="layout-section" v-else-if="loadedPage">
      <h2 class="heading-circle -padding -bordertop">勝馬頭数</h2>
      <div class="inner">
        <div class="system-registmember -sub">
          <a
            v-if="!isWebView"
            href="/member"
            target="_blank"
            class="btn-basic -wide -window">
            こちらの機能をご利用いただくには、<br/>
            月額コースへの会員登録が必要です。
          </a>
          <router-link v-else to="/member" class="btn-basic -wide -window">
            こちらの機能をご利用いただくには、<br/>
            月額コースへの会員登録が必要です。
          </router-link>
        </div>
      </div>
    </section>

    <section class="layout-section">
      <div class="nav-group -bordertop">
        <span onclick="javascript:history.back();" class="btn-basic -prev -gray"
          >戻る</span
        >
      </div>
    </section>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import courseInfoJson from "../../assets/js/race/courseInfo.json";
import {
  getMenmerCourse,
  getUrlVars,
  getJSON,
  yearDiv,
  monthDiv,
  dayDiv,
  winFormat,
  SUCCESS,
  PAYINGNONE,
  retrieveCourse,
  getUserAgent,
  isNar,
} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH } from "../../assets/js/define";

export default {
  data() {
    return {
      NARFLAG: null,
      raceData: {
        courseTitle: null,
        thumbnailPath: null,
      },
      recordData: null,
      raceInfo2years: null,
      twoYearsOldRecordData: null,
      winningHorses: null,
      courseName: null,
      courseYearType: [],
      courseWinRate: null,
      loadedPage: false,
      isWebView: false,
      courseId: PAYINGNONE,
      courseInfo: {}
    };
  },
  mounted() {
    const self = this;

    self.NARFLAG = isNar();
    self.isWebView = getUserAgent();
    //パラメータ取得
    let params = getUrlVars();

    const racetrackCd = params.racetrackCd
    const distance = params.distance
    const trackTypeCd = params.trackTypeCd
    const trackCd = params.trackCd

    // コース情報の取得
    self.courseInfo = courseInfoJson['m_'+ racetrackCd]['d_'+ distance]['t_'+ trackCd];

    //会員コース確認
    callRetrieveCourse(function (courseId) {
      self.courseId = courseId;
      //コースデータ差し込み
      courseDataInfo(
          racetrackCd,
          trackTypeCd,
          distance,
          trackCd
      );
    });

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none", false);
      }
    }

    /******************************************************************
     *  コースデータ表示
     ******************************************************************/
    function courseDataInfo(racetrackCd, trackTypeCd, distance, trackCd) {
      const request =
        HOST +
        PATH +
        "RaceInfo/courseInfo.js" +
        BACKWORDPATH +
        "?racetrackCd=" +
        racetrackCd +
        "&trackTypeCd=" +
        trackTypeCd +
        "&distance=" +
        distance +
        "&trackCd=" +
        trackCd +
        "&narFlag=" +
        isNar();
      document.body.className = "";
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          try {
            let courseName = {
              "01": "札幌",
              "02": "函館",
              "03": "福島",
              "04": "新潟",
              "05": "東京",
              "06": "中山",
              "07": "中京",
              "08": "京都",
              "09": "阪神",
              10: "小倉",
              30: "門別",
              35: "盛岡",
              36: "水沢",
              42: "浦和",
              43: "船橋",
              44: "大井",
              45: "川崎",
              46: "金沢",
              47: "笠松",
              48: "名古屋",
              50: "園田",
              51: "姫路",
              54: "高知",
              55: "佐賀",
            };
            let courseImgHead = {
              "01": "sapporo",
              "02": "hakodate",
              "03": "fukusima",
              "04": "niigata",
              "05": "tokyo",
              "06": "nakayama",
              "07": "tyuukyou",
              "08": "kyouto",
              "09": "hansin",
              10: "kokura",
            };

            json.trackType = "";
            json.trackTypeImg = "";
            switch (trackTypeCd) {
              case "1":
                json.trackType = "芝";
                json.trackTypeImg = "siba";
                break;
              case "2":
                json.trackType = "ダート";
                json.trackTypeImg = "dirt";
                break;
              case "3":
                json.trackType = "障害";
                json.trackTypeImg = "syougai";
                break;
            }
            //#4185レース名と画像だけは表示するように対応
            json.courseTitle =
              courseName[racetrackCd] + json.trackType + distance + "m";

            //画像パスの作成
            json.thumbnailPath =
              "/assets/images/race/course/" +
              "c_" +
              racetrackCd +
              "_" +
              distance +
              "_" +
              trackCd +
              ".png";

            //データの格納
            self.courseName = json.courseTitle;
            self.raceData.courseTitle = json.courseTitle;
            self.raceData.thumbnailPath = json.thumbnailPath;

            //レコードの整形
            if (json != null && json != undefined && json != "") {
              if (json.length > 0) {
                //コースレコード情報の取得
                let useData = json[0];
                //エントリー情報の取得
                let entryData = json[1];

                const recordData = useData.CourseRecord.find(
                  (courseRecord) => courseRecord.RecordDiv === "1"
                );
                if (recordData) {
                  //レコードタイムを整形
                  recordData.time = recordData.CourseRecord;
                  let m = recordData.time.substr(0, 1);
                  let s = recordData.time.substr(1, 2);
                  let n = recordData.time.substr(3, 1);
                  recordData.time = m + "." + s + "." + n;

                  //斤量整形
                  recordData.jockeyWeight = recordData.Weight / 10;
                  recordData.jockeyWeight =
                    "(" + parseInt(recordData.jockeyWeight).toFixed(1) + "kg)";
                  //性別
                  if (recordData.Sex == "1") {
                    recordData.Sex = " 牡";
                  } else if (recordData.Sex == "2") {
                    recordData.Sex = " 牝";
                  } else if (recordData.Sex == "3") {
                    recordData.Sex = " セン";
                  } else {
                    recordData.Sex = " ";
                  }
                  //年月日整形用
                  recordData.date = recordData.RaceDate;
                  recordData.date =
                    yearDiv(recordData.date) +
                    "/" +
                    monthDiv(recordData.date) +
                    "/" +
                    dayDiv(recordData.date);
                  //天気
                  let tenki = {
                    晴: "1",
                    曇: "2",
                    雨: "3",
                    小雨: "4",
                    雪: "5",
                    小雪: "6",
                    "晴/曇": "FC",
                    "晴/雨": "FR",
                    "晴/雪": "FS",
                    "曇/晴": "CF",
                    "曇/雨": "CR",
                    "曇/雪": "CS",
                    "雨/晴": "RF",
                    "雨/雪": "RS",
                    "雨/曇": "RC",
                    "雪/晴": "SF",
                    "雪/雨": "SR",
                    "雪/曇": "SC",
                  };
                  let weatherText = "";
                  for (let k in tenki) {
                    if (recordData.Weather == tenki[k]) {
                      weatherText = k;
                    }
                  }
                  //馬場状態
                  let baba = {
                    "": "0",
                    良: "1",
                    稍重: "2",
                    重: "3",
                    不良: "4",
                  };
                  for (let k in baba) {
                    if (recordData.TrackConditionCdTurf == baba[k]) {
                      recordData.turfConditionText = "芝/" + k;
                    }
                    if (recordData.TrackConditionCdDirt == baba[k]) {
                      recordData.dirtConditionText = "ダ/" + k;
                    }
                  }
                  recordData.conditionText = "";
                  if (recordData.turfConditionText != undefined) {
                    recordData.conditionText = recordData.turfConditionText;
                    recordData.condition =
                      "(" + weatherText + "・" + recordData.conditionText + ")";
                  }
                  if (recordData.dirtConditionText != undefined) {
                    if (recordData.conditionText != "") {
                      recordData.conditionText += "・";
                    }
                    recordData.conditionText += recordData.dirtConditionText;
                    recordData.condition =
                      "(" + weatherText + "・" + recordData.conditionText + ")";
                  }

                  self.recordData = recordData;
                }

                const twoYearsRecordData = useData.CourseRecord.find(
                  (courseRecord) => courseRecord.RecordDiv === "2"
                );
                if (twoYearsRecordData) {
                  //レコードタイムを整形
                  twoYearsRecordData.time = twoYearsRecordData.CourseRecord;
                  let m = twoYearsRecordData.time.substr(0, 1);
                  let s = twoYearsRecordData.time.substr(1, 2);
                  let n = twoYearsRecordData.time.substr(3, 1);
                  twoYearsRecordData.time = m + "." + s + "." + n;

                  //斤量整形
                  twoYearsRecordData.jockeyWeight =
                    twoYearsRecordData.Weight / 10;
                  twoYearsRecordData.jockeyWeight =
                    "(" +
                    parseInt(twoYearsRecordData.jockeyWeight).toFixed(1) +
                    "kg)";
                  //性別
                  if (twoYearsRecordData.Sex == "1") {
                    twoYearsRecordData.Sex = " 牡";
                  } else if (twoYearsRecordData.Sex == "2") {
                    twoYearsRecordData.Sex = " 牝";
                  } else if (twoYearsRecordData.Sex == "3") {
                    twoYearsRecordData.Sex = " セン";
                  } else {
                    twoYearsRecordData.Sex = " ";
                  }
                  //年月日整形用
                  twoYearsRecordData.date = twoYearsRecordData.RaceDate;
                  twoYearsRecordData.date =
                    yearDiv(twoYearsRecordData.date) +
                    "/" +
                    monthDiv(twoYearsRecordData.date) +
                    "/" +
                    dayDiv(twoYearsRecordData.date);
                  //天気
                  let tenki = {
                    晴: "1",
                    曇: "2",
                    雨: "3",
                    小雨: "4",
                    雪: "5",
                    小雪: "6",
                    "晴/曇": "FC",
                    "晴/雨": "FR",
                    "晴/雪": "FS",
                    "曇/晴": "CF",
                    "曇/雨": "CR",
                    "曇/雪": "CS",
                    "雨/晴": "RF",
                    "雨/雪": "RS",
                    "雨/曇": "RC",
                    "雪/晴": "SF",
                    "雪/雨": "SR",
                    "雪/曇": "SC",
                  };
                  let weatherText = "";
                  for (let k in tenki) {
                    if (twoYearsRecordData.Weather == tenki[k]) {
                      weatherText = k;
                    }
                  }
                  //馬場状態
                  let baba = {
                    "": "0",
                    良: "1",
                    稍重: "2",
                    重: "3",
                    不良: "4",
                  };
                  for (let k in baba) {
                    if (twoYearsRecordData.TrackConditionCdTurf == baba[k]) {
                      twoYearsRecordData.turfConditionText = "芝/" + k;
                    }
                    if (twoYearsRecordData.TrackConditionCdDirt == baba[k]) {
                      twoYearsRecordData.dirtConditionText = "ダ/" + k;
                    }
                  }
                  twoYearsRecordData.conditionText = "";
                  if (twoYearsRecordData.turfConditionText != undefined) {
                    twoYearsRecordData.conditionText =
                      twoYearsRecordData.turfConditionText;
                    twoYearsRecordData.condition =
                      "(" +
                      weatherText +
                      "・" +
                      twoYearsRecordData.conditionText +
                      ")";
                  }
                  if (twoYearsRecordData.dirtConditionText != undefined) {
                    if (twoYearsRecordData.conditionText != "") {
                      twoYearsRecordData.conditionText += "・";
                    }
                    twoYearsRecordData.conditionText +=
                      twoYearsRecordData.dirtConditionText;
                    twoYearsRecordData.condition =
                      "(" +
                      weatherText +
                      "・" +
                      twoYearsRecordData.conditionText +
                      ")";
                  }
                  self.raceInfo2years = twoYearsRecordData;
                }

                if (entryData) {
                  drawCourseWinGraph(
                    useData.WinningStat,
                    entryData.EntryHorseNumAll
                  );
                }
              }
            }
          } catch (e) {
            console.log("courseDataInfo : " + e.message);
          }
        }
        self.loadedPage = true;
        document.body.className = "status-loaded";
      });
    }
    //勝馬頭数グラフ作画
    function drawCourseWinGraph(json, entryHorseNumAll) {
      //年度番号昇順に並べ替え：1~3
      json.sort(function (a, b) {
        a = a["YearType"];
        b = b["YearType"];
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      let maxCourseNum = 0;
      if (isNar() === 1) {
        maxCourseNum = 16;
      } else {
        maxCourseNum = 18;
      }

      //過去3年間の実績のみ集計（jsonは最大3）
      let stat = []; //集計用コンテナの初期化
      let yearStat = "";
      for (let i = 0; i < json.length; i++) {
        yearStat = json[i];

        for (let j = 0; j < maxCourseNum; j++) {
          if (!(j in stat)) {
            stat[j] = { t1: 0, t2: 0, t3: 0 }; //初期化
            if (!entryHorseNumAll[j]) {
              stat[j].t4 = "none";
            }
          }
          let horseNum = yearStat["Horse_" + ("0" + (j + 1)).slice(-2)] * 1;
          stat[j]["t" + (i + 1)] += horseNum;
        }
      }

      //最大馬数取得
      let maxStat = stat.map(function (value) {
        if (isNaN(value.t3)) {
          return 0;
        } else {
          return value.t1 + value.t2 + value.t3;
        }
      });
      let maxStatValue = Math.max.apply(null, maxStat);

      console.log("stat", stat);
      //グラフを描画するために配列を整形する
      let maxNum = maxStatValue;
      for (let l = 0; l < maxStatValue; l++) {
        self.courseYearType.push([]);
        for (let m = 0; m < maxCourseNum; m++) {
          if (stat[m].t4) {
            self.courseYearType[l].push(5);
          } else if (stat[m].t1 >= maxNum) {
            self.courseYearType[l].push(1);
          } else if (stat[m].t1 + stat[m].t2 >= maxNum) {
            self.courseYearType[l].push(2);
          } else if (stat[m].t1 + stat[m].t2 + stat[m].t3 >= maxNum) {
            self.courseYearType[l].push(3);
          } else {
            self.courseYearType[l].push(0);
          }
        }
        maxNum--;
      }

      console.log("courseYearType", self.courseYearType);

      let keys = Object.keys(stat);

      //馬番ごとの勝率を設定
      for (let i = 0; i < maxCourseNum; i++) {
        if (entryHorseNumAll.length > i) {
          entryHorseNumAll[i].win = winFormat(
            entryHorseNumAll[i].count,
            stat[keys[i]].t1 + stat[keys[i]].t2 + stat[keys[i]].t3
          );
        }
      }
      self.courseWinRate = entryHorseNumAll;

      return;
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/race/style.scss" scoped></style>
