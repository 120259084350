<script setup>
import {ref, reactive, onMounted, watch} from "vue";
import {useRoute} from "vue-router";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  getUserId,
  isFavorite,
  isNextTarget,
  FRACEHORSE,
  TRACEHORSE,
  isNar,
  SUCCESS,
} from "../../../assets/js/common";
import { HOST, PATH, BACKWORDPATH } from "../../../assets/js/define";

import MyStampModal from "../../../components/utils/MyStampModal.vue";

import raceOdds from "../../../functions/odds/race-odds";
import myMark from "../../../functions/odds/race-mymark";

const { setRaceBetByArray, sortDoubleOdds } = raceOdds();
const { loadMyMarkForDrop2, setMyMark, setMyMarkForSpecial } = myMark();

let checkAllJudge = ref(false);
let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let oddsUpdInfo = ref("");
const checkedHorse = reactive({});
const currentTab = "win";
const existHorseNum = ref(true);
const pedigreeNums = ref([]);

let raceDORVal = ref("");
let raceTrackCdVal = ref("");
let raceNumVal = ref("");
let raceTimeVal = ref("");
let mCourseVal = ref("");
let params = {};
let selectedColumn = ref("PopularStat");
let raceDOR = (param) => (raceDORVal = param);
let raceTrackCd = (param) => (raceTrackCdVal = param);
let raceNum = (param) => (raceNumVal = param);
let raceTime = (param) => (raceTimeVal = param);
let mCourse = (param) => (mCourseVal = param);

const modalOpen = ref(false);

const route = useRoute();
watch(route, async (from, to) => {
  await doInit(to.query);
});

// time
let time = ref("");
onMounted(() => {
  params = getUrlVars();
  getMyNextTargetRacehorse();
  //パラメータチェック
  if (checkUrlVars(params)) {
    doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
    document.body.className = "status-loaded";
  }
});

const getMyNextTargetRacehorse = () => {
  let userId = getUserId();
  let request =
    HOST +
    PATH +
    "MyNextTarget/getMyNextTargetRacehorse.js" +
    BACKWORDPATH +
    "?userID=" + encodeURIComponent(userId)
    getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      if(json){
        pedigreeNums.value = json;
      }
    }
  });
}

let stamp = reactive({});
const stampClass = (num) => {
  if (stamp[num].selectedNum == 0) {
    return "stamp-mark -none";
  } else if (stamp[num].selectedNum == 1) {
    return "stamp-mark -honmei";
  } else if (stamp[num].selectedNum == 2) {
    return "stamp-mark -taikou";
  } else if (stamp[num].selectedNum == 3) {
    return "stamp-mark -tanana";
  } else if (stamp[num].selectedNum == 4) {
    return "stamp-mark -renka";
  } else if (stamp[num].selectedNum == 5) {
    return "stamp-mark -hoshi";
  } else {
    return "stamp-mark -none";
  }
};

//チェックリスト関連
let checkList = reactive({});
let checkedSum = ref(0);

// modal
let left = ref("");
let top = ref("");
const openModal = (event, win) => {
  document.body.className = "status-loaded status-haslayerbg status-showstamp";
  // x座標
  left.value = event.pageX;
  // y座標
  top.value = event.pageY;
  stamp[win - 1].status = true;
};
const closeStampModal = (win) => {
  document.body.className = "status-loaded";
  if (stamp[win.num].selectedNum != win.selectedNum) {
    horses[win.num].selectedNum = win.selectedNum;
    //my印を新しくセットした場合は、my印を更新する
    stamp[win.num].selectedNum = win.selectedNum;
    if (existHorseNum.value) {
      setMyMark(
          getUserId(),
          params.DOR,
          params.RacetrackCd,
          params.RaceNum,
          win.num + 1,
          stamp[win.num].selectedNum
      );
    } else {
      setMyMarkForSpecial(
          getUserId(),
          params.DOR,
          params.RacetrackCd,
          params.RaceNum,
          horses[win.num].PedigreeNum,
          stamp[win.num].selectedNum
      );
    }
  }
  stamp[win.num].status = false;
};

// horses
let horses = reactive([]);
let mark = reactive({});
let marks = reactive({});
const doInit = async (params) => {
  marks = await loadMyMarkForDrop2(getUserId(), params.DOR, params.RacetrackCd, params.RaceNum);
  await Promise.all([await loadAnalysisDataByJockey(params.DOR, params.RacetrackCd, params.RaceNum)]);
};

//騎手別データ分析情報を取得する
const loadAnalysisDataByJockey = async (dor, racetrackCd, raceNum, callback) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "RaceInfo/analysisDataByJockey.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  document.body.className = "";
  await getJSON(req, true, function (status, json) {
    if (status == "success") {
      horses = Object.assign(horses, json["EntryHorses"]);
      let useData;

      //my印及びチェックボックスをデフォルト値をセット
      for (let i = 0; i < horses.length; i++) {
        useData = horses[i];
        let bracketNum = "";

        if (useData.BracketNum != null && useData.BracketNum !== "") {
          bracketNum = useData.BracketNum;
        }

        if (!useData.HorseNum) {
          useData.HorseNum = i + 1
          existHorseNum.value = false
        }

        //My印をセット
        let selectNum = 0;
        if (marks && Object.keys(marks).length) {
          if (marks.isSpFlg === '1') {
            selectNum = marks.SpecialMarks[useData.PedigreeNum] || 0;
          } else {
            selectNum = marks["MyNo" + (i + 1)] || 0;
          }
        }

        stamp[i] = { status: false, selectedNum: selectNum };
        checkList[i] = false;
        useData.selectedNum = stamp[i].selectedNum;

        let frame_num_class = "";
        let horse_num_class = "";
        if ("BracketNum" in useData && useData.BracketNum != null) {
          let frame = useData.BracketNum;
          frame_num_class = "wk" + frame;
          horse_num_class = "um" + frame;
        }

        //馬名
        let horseNameUrl = "";
        useData.favClass = "";
        if (useData.PedigreeNum != undefined) {
          horseNameUrl += "/data/horse?&PedigreeNum=" + useData.PedigreeNum;
          if (isFavorite(FRACEHORSE, useData.PedigreeNum) || (isNextTarget(TRACEHORSE, useData.PedigreeNum) && pedigreeNums.value.includes(useData.PedigreeNum))) {
            useData.favClass = "-favorite";
          }
        }
        useData.HorseNameUrl = horseNameUrl;

        //騎手
        let jockeyName = "";
        let jockeyNameUrl = "";
        if (useData.JockeyName != undefined) {
          jockeyName = useData.JockeyName;
          //全角スペースを半角スペース１つに置換
          if (jockeyName.match(/　/)) {
            jockeyName = String(jockeyName).replace(/　/g, " ");
            jockeyName = jockeyName.replace(/\s+/, " ");
          }
          //名前が5文字以上の場合 ※スペースを含む6文字以上
          if (jockeyName.substr(0, 1).match(/[Ａ-Ｚ]/i)) {
            //C.デムーロ、M.デムーロだけ「ファーストネームのアルファベット + ．+ 全角カナ2文字」
            if (jockeyName.trim() == "Ｃ．デムーロ") {
              jockeyName = "Ｃ．デム";
            } else if (jockeyName.trim() == "Ｍ．デムーロ") {
              jockeyName = "Ｍ．デム";
            } else if (jockeyName.trim() == "Ｔ．ベリー") {
              jockeyName = "Ｔ．ベリ";
            } else if (jockeyName.trim() == "Ｆ．ベリー") {
              jockeyName = "Ｆ．ベリ";
            } else {
              //他の外国騎手は、カタカナ表記の頭4文字
              var newJockeyName = jockeyName.substr(2, jockeyName.length - 2);
              if (newJockeyName.substr(0, 1).match(/[Ａ-Ｚ]/i)) {
                newJockeyName = newJockeyName.substr(2, newJockeyName.length - 2);
              }
              jockeyName = newJockeyName.substr(0, 4);
            }
          } else if (jockeyName.indexOf(" ") != -1 && jockeyName.length >= 6) {
            //スペースがあれば5文字
            jockeyName = jockeyName.substr(0, 5);
          } else if (jockeyName.indexOf(" ") == -1 && jockeyName.length >= 5) {
            //スペースがなければ4文字
            jockeyName = jockeyName.substr(0, 4);
          }
        }
        jockeyNameUrl += "/data/jockey?JockeyCd=" + useData.JockeyCd;
        useData.JockeyName = jockeyName;
        useData.JockeyNameUrl = jockeyNameUrl;

        //調教師
        let trainerName = "";
        if (useData.TrainerStat.TrainerName != undefined) {
          trainerName = useData.TrainerStat.TrainerName;
          //全角スペースを半角スペース１つに置換
          if (trainerName.match(/　/)) {
            trainerName = String(trainerName).replace(/　/g, " ");
            trainerName = trainerName.replace(/\s+/, " ");
          }
          if (trainerName.length >= 6) {
            //名前が5文字以上の場合
            if (trainerName.substr(0, 1).match(/[Ａ-Ｚ]/i)) {
              //他の外国騎手は、カタカナ表記の頭4文字
              var newTrainerName = trainerName.substr(2, trainerName.length - 2);
              if (newTrainerName.substr(0, 1).match(/[Ａ-Ｚ]/i)) {
                newTrainerName = newTrainerName.substr(2, newTrainerName.length - 2);
              }
              trainerName = newTrainerName.substr(0, 4);
            } else if (trainerName.indexOf(" ") != -1 && trainerName.length >= 6) {
              //スペースがあれば5文字
              trainerName = trainerName.substr(0, 5);
            } else if (trainerName.indexOf(" ") == -1 && trainerName.length >= 5) {
              //スペースがなければ4文字
              trainerName = trainerName.substr(0, 4);
            }
          }
        }
        useData.TrainerName = trainerName;
      }
    }
    if (callback != undefined) {
      callback();
    }
  });
  document.body.className = "status-loaded";
};
</script>

<template>
  <div class="item" v-if="horses.length">
    <div class="container-fiter">
      <div class="form-select">
        <select name="" v-model="selectedColumn">
          <option value="PopularStat">人気</option>
          <option value="TrainerStat">調教師</option>
          <option value="HorseOwnerStat">馬主</option>
          <option value="StallionStat">種牡馬</option>
          <option value="GrandFatherStat">母父</option>
          <option value="ChangeStat">乗り替わり</option>
        </select>
      </div>
      を軸にデータ分析をする
    </div>
    <div class="icon-next"></div>

    <div class="container-table -analytics">
      <table class="table-grid -center -middle -analytics">
        <thead>
          <tr>
            <th>馬番</th>
            <th>my印</th>
            <th>騎手</th>
            <th>項目</th>
            <th>着回数</th>
            <th>勝率</th>
            <th>連対率</th>
            <th>3着内率</th>
            <th>単勝回収率</th>
            <th>複勝回収率</th>
            <th>馬名</th>
          </tr>
        </thead>
        <tbody v-if="horses">
          <tr v-for="h in horses" :key="h.HorseNum">
            <td :class="[h.BracketNum < 10 ? `umaban bgcolor-waku0${h.BracketNum}` : `umaban bgcolor-waku0${h.BracketNum}`]">
              <i>{{ h.HorseNum }}</i>
            </td>
            <td class="mystamp">
              <span :class="stampClass(h.HorseNum - 1)" @click="openModal($event, h.HorseNum)"></span>
              <MyStampModal
                @closeModal="closeStampModal($event)"
                v-if="stamp[h.HorseNum - 1].status === true"
                :top="top"
                :left="left"
                :stampNum="h.HorseNum - 1"
                :selectStamp="stamp[h.HorseNum - 1].selectedNum"
              />
            </td>
            <td class="jockey">
              <router-link :to="h.JockeyNameUrl">{{ h.JockeyName }}</router-link>
            </td>
            <td class="koumoku">
              <i>{{ h[selectedColumn].Koumoku }}</i>
            </td>
            <td class="ranking">
              <i>{{
                h[selectedColumn].RaceRank1st +
                "-" +
                h[selectedColumn].RaceRank2nd +
                "-" +
                h[selectedColumn].RaceRank3rd +
                "-" +
                h[selectedColumn].RaceRankUnPlaced +
                "/" +
                h[selectedColumn].RaceTotal
              }}</i>
            </td>
            <td class="win">
              <i v-if="h[selectedColumn].RaceRank1st + h[selectedColumn].RaceRank2nd + h[selectedColumn].RaceRank3rd !== 0">{{
                parseInt((h[selectedColumn].RaceRank1st / h[selectedColumn].RaceTotal) * 100) + "%"
              }}</i>
              <i v-else>{{ "0%" }}</i>
            </td>
            <td class="ratio">
              <i v-if="h[selectedColumn].RaceRank1st + h[selectedColumn].RaceRank2nd + h[selectedColumn].RaceRank3rd !== 0">{{
                parseInt(((h[selectedColumn].RaceRank1st + h[selectedColumn].RaceRank2nd) / h[selectedColumn].RaceTotal) * 100) + "%"
              }}</i>
              <i v-else>{{ "0%" }}</i>
            </td>
            <td class="top3">
              <i v-if="h[selectedColumn].RaceRank1st + h[selectedColumn].RaceRank2nd + h[selectedColumn].RaceRank3rd !== 0">{{
                parseInt(((h[selectedColumn].RaceRank1st + h[selectedColumn].RaceRank2nd + h[selectedColumn].RaceRank3rd) / h[selectedColumn].RaceTotal) * 100) + "%"
              }}</i>
              <i v-else>{{ "0%" }}</i>
            </td>
            <td
              :class="`winrate ${
                h[selectedColumn].WinRateRank >= 1 && h[selectedColumn].WinRateRank <= 3 && h[selectedColumn].WinRate >= 100  ? 'bg-rank0'+h[selectedColumn].WinRateRank: ''
              }`"
            >
              <i>{{ h[selectedColumn].WinRate + "%" }}</i>
            </td>
            <td
              :class="`multirate ${
                h[selectedColumn].MultiRateRank >= 1 && h[selectedColumn].MultiRateRank <= 3 && h[selectedColumn].MultiRate >= 100 ? 'bg-rank0'+h[selectedColumn].MultiRateRank: ''
              }`"
            >
              <i>{{ h[selectedColumn].MultiRate + "%" }}</i>
            </td>
            <td class="horsename">
              <router-link :to="h.HorseNameUrl" :class="h.favClass">{{ h.HorseName }}</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="p-supplement">※集計期間：過去1年</p>
  </div>
</template>

<style>
.container-fiter {
  text-align: center;
  margin: 0 0 0 0;
  background-color: #dfeec9;
  padding: 10px 0;
  border-radius: 6px;
}
</style>
