<script setup>
import HeaderComp from "./components/HeaderComp.vue";
import FooterComp from "./components/FooterComp.vue";
import {useRoute} from "vue-router";
import {getUserId, getJSON, SUCCESS, isNar} from "./assets/js/common";
import {useMeta} from "vue-meta";
import axios from "axios";
import {HOST} from "./assets/js/define";
import {ref, onMounted, watch, nextTick} from "vue";
import { useStoreCommon } from "./stores/common-states";
const route = useRoute()
const hiddenFlgVal = ref(false)
const store = useStoreCommon();

const meta = {
  title: route.meta?.title ?? store.defaultTitle ?? process.env.TITLE ?? '',
  description: route.meta?.description ?? process.env.DESCRIPTION ?? '',
  keywords: route.meta?.keywords ?? process.env.KEYWORDS ?? '',
}

useMeta(meta)

watch(route, (from, to) => {
  document.title = to.meta?.title ?? store.defaultTitle ?? process.env.TITLE ?? '';
})

onMounted(() => {
  const mopoa = route.query.mopoa;
  if(mopoa) {
    const userId = getUserId();
    // mopoアクセス
    const urlSendMopo = `${HOST}webapi/sendMopo4sg.js/webapi?userId=${encodeURIComponent(userId)}&mopoa=${encodeURIComponent(mopoa)}`
    axios.get(urlSendMopo).then()
  }
  getJSON("/cert/topPage", true, function (status, json) {
    if (status === SUCCESS) {
      if (Object.keys(json).length) {
        if(json.url) {
          location.href = json.url;
        }
      }
    }
  });
})

const hiddenFlg = (value) => {
  hiddenFlgVal.value = value;
}
</script>

<template>
  <HeaderComp v-if="hiddenFlgVal === false"/>
  <router-view @hiddenFlg="hiddenFlg" />
  <FooterComp v-if="hiddenFlgVal === false"/>
</template>

