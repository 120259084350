<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import { JRA_WP_HOST } from "../../assets/js/define";
import axios from "axios";
import BeforeLoginModal from "../../components/utils/BeforeLoginModal.vue";

const modalOpen = ref(false);

// APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=nonmember";

let post = reactive({});

onMounted(() => {
  document.body.className = "";
  const request_url = API_URL;
  axios
    .get(request_url)
    .then((response) => {
      Object.assign(post, response.data[0]);
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});
onUpdated(() => {
  let isClickLogin = document.getElementById("show_login");
  isClickLogin.addEventListener("click", () => {
    modalOpen.value = true;
  });

  document.body.className = "status-loaded";
});
</script>

<template>
  <div v-if="post.content" v-html="post.content.rendered"></div>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
  <BeforeLoginModal :modalOpen="modalOpen" @modal-close="modalOpen = false" />
</template>
