<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">検索結果</h1>
    </section>
    <div v-if="allCount === 0 && loadedPage">
      <p class="system-message -padding _important">検索結果はありません。</p>
    </div>
    <div
      v-else-if="loadedPage"
      class="-active"
      data-accordion='["data-btn","data-detail"]'
      v-for="(item, i) in searchAllListItems"
      :key="i"
    >
      <div class="layout-title -search">
        <h2 class="heading-circle -padding">
          {{ item.name }}検索結果: {{ item.count }}件
        </h2>

        <div class="nav">
          <span
            class="icon-accordion -search"
            :class="{ '-active': item.isActive }"
            @click="changeActive(item)"
          ></span>
        </div>
      </div>

      <transition
        name="topSlide"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <section
          class="layout-section topSlide"
          :class="{ '-active': item.isActive }"
          v-if="item.isActive"
        >
          <table 
            class="table-line -fit -middle"
            :class="{ '-horse': item.isHorse }">
            <tbody>
              <tr
                v-for="(data, index) in item.data"
                :key="index"
                :class="{ '-disable': data.isRetire }"
              >
                <td
                  v-if="data.breederName"
                  class="name"
                >
                  <a :href="data.link">{{ data.breederName }}</a>
                </td>
                <td
                  v-if="!data.breederName && data.breederNameAlpha"
                  class="name"
                >
                  <a :href="data.link">{{ data.breederNameAlpha }}</a>
                </td>
                <td v-if="data.raceName && data.raceDor" class="name">
                  <a :href="data.link"
                    >{{ data.raceName }}({{ data.raceDor }})</a
                  >
                </td>
                <td v-if="!data.raceName && data.raceDor" class="name">
                  <a :href="data.link">({{ data.raceDor }})</a>
                </td>
                <td v-if="data.name" class="name">
                  <a :href="data.link">{{ data.name }}</a>
                </td>

                <td class="place" v-if="item.isHorse">
                  <span v-if="data.affiliation === 'JRA'" class="icon-mark -place -jra">
                    <i>JRA</i>
                  </span>
                  <span v-else-if="data.affiliation === 'NAR'" class="icon-mark -place -nar">
                    <i>地方</i>
                  </span>
                </td>

                <td
                  v-if="item.isHorse"
                  :class="{
                    'sex -nowrap': NARFLAG === 0,
                    'left -nowrap': NARFLAG === 1,
                  }"
                >
                  {{ data.sex
                  }}<template v-if="data.ageFlag">{{ data.age }}</template>
                </td>

                <td v-if="NARFLAG === 0 && item.isHorse" class="raceclass">
                  {{ data.flatRaceRank
                  }}<br v-if="data.flatRaceRank !== undefined" />{{
                    data.steeplechaseRaceRank
                  }}
                </td>
                <td v-if="NARFLAG === 0 && item.isHorse" class="pog">
                  <a
                    v-if="data.isPog"
                    :href="data.pogUrl"
                    class="icon-status -pog"
                  ></a>
                </td>

                <td class="-fav" :style="styles">
                  <span
                    v-if="data.isFavorite"
                    class="icon-fav"
                    :class="data.favClass"
                    @click="onClickFavorite(item.key, data)"
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="nav-group" v-if="item.count > 30">
            <a class="btn-basic -more" :href="item.url"> もっと見る </a>
          </div>
        </section>
      </transition>
    </div>

    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import {
  PAYINGNONE,
  PAYINGSUGOTOKU,
  PAYINGAPPPASS,
  PAYINGSUGOTOKU_NONE,
  FRACEHORSE,
  FBROODMARE,
  FJOCKEY,
  FTRAINER,
  FOWNER,
  FBREEDER,
  FSTALLION,
  ADD,
  REMOVE,
  SUCCESS,
  getJSON,
  isFavorite,
  changeFavoriteFromCookie,
  isNar, retrieveCourse, getUserId,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  RACEPATH,
  DATAPATH,
  BACKWORDPATH,
  SEARCHPATH,
  POGPATH,
} from "../../assets/js/define";
import { useRouter } from 'vue-router'

export default {
  data() {
    return {
      NARFLAG: null,
      searchAllListItems: [],
      searchAllData: null,
      allCount: null,
      loadedPage: false,
      isFav: true,
      iconClass: "",
      hasClass: "",
      //アイコン非表示用
      styles: {
        display: "",
      },
      env: {
        HORSE: {
          cookieName: FRACEHORSE,
          dataNumberName: "pedigreeNum",
          deleteJsFile: "MyFavorite/deleteMyFavoriteRacehorse.js",
          registJsFile: "MyFavorite/setMyFavoriteRacehorse.js",
        },
        JOCKEY: {
          cookieName: FJOCKEY,
          dataNumberName: "jockeyCd",
          deleteJsFile: "MyFavorite/deleteMyFavoriteJockey.js",
          registJsFile: "MyFavorite/setMyFavoriteJockey.js",
        },
        TRAINER: {
          cookieName: FTRAINER,
          dataNumberName: "trainerCd",
          deleteJsFile: "MyFavorite/deleteMyFavoriteTrainer.js",
          registJsFile: "MyFavorite/setMyFavoriteTrainer.js",
        },
        HORSEOWNER: {
          cookieName: FOWNER,
          dataNumberName: "horseOwnerCd",
          deleteJsFile: "MyFavorite/deleteMyFavoriteHorseOwner.js",
          registJsFile: "MyFavorite/setMyFavoriteHorseOwner.js",
        },
        BREEDER: {
          cookieName: FBREEDER,
          dataNumberName: "breederCd",
          deleteJsFile: "MyFavorite/deleteMyFavoriteBreeder.js",
          registJsFile: "MyFavorite/setMyFavoriteBreeder.js",
        },
        STUD: {
          cookieName: FSTALLION,
          dataNumberName: "breedingNum",
          deleteJsFile: "MyFavorite/deleteMyFavoriteStallion.js",
          registJsFile: "MyFavorite/setMyFavoriteStallion.js",
        },
        MARE: {
          cookieName: FBROODMARE,
          dataNumberName: "breedingNum",
          deleteJsFile: "MyFavorite/deleteMyFavoriteBroodMare.js",
          registJsFile: "MyFavorite/setMyFavoriteBroodMare.js",
        },
      },
      userId: "",
      courseId: PAYINGNONE,
    };
  },
  mounted() {
    const self = this;
    const router = useRouter()
    // リクエストURL作成
    let mInfo = getUlrParams();
    const param = createParam(mInfo.params);
    const messageFlag = true;
    let request =
      HOST +
      PATH +
      "Search/searchAllList.js" +
      BACKWORDPATH +
      "?narFlag=" +
      isNar();

    self.NARFLAG = isNar();

    if (param != null && param != "") {
      request += "&" + param;
    }

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none", false, "");
      }
    }

    callRetrieveCourse(function (courseId, showMarks, userId) {
      self.userId = userId || getUserId();
      self.courseId = courseId;

      //会員によってお気に入りアイコン非表示;
      if (
        self.courseId === PAYINGNONE ||
        self.courseId === PAYINGSUGOTOKU_NONE
      ) {
        self.styles.display = "none";
      }
      makeRequest();
    })

    function getUlrParams() {
      // ページ数取得
      let pageNum = 1;
      let url = location.href;
      if (url.indexOf("#page-") > -1) {
        pageNum = url.slice(url.indexOf("#page-") + 6, url.length);
        url = url.slice(0, url.indexOf("#page-"));
      }

      // パラメータ取得
      let vars = [],
        hash;
      let hashes = url.slice(window.location.href.indexOf("?") + 1).split("&");
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        if (hash[0] != null && hash[0].indexOf("#page-") == -1) {
          // ページリンクは除外する
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
      }
      return { params: vars, page: pageNum };
    }

    function createParam(params) {
      let param = "";

      if (
        params["keyword"] != undefined &&
        params["keyword"] != null &&
        params["keyword"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "keyword" + "=" + params["keyword"];
      } else {
        if (param != "") {
          param += "&";
        }
        param += "keyword" + "=";
      }
      if (
        params["blankFlag"] != undefined &&
        params["blankFlag"] != null &&
        params["blankFlag"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "blankFlag" + "=" + params["blankFlag"];
      } else {
        if (param != "") {
          param += "&";
        }
        param += "blankFlag" + "=0";
      }

      if (
        params["searchOption"] != undefined &&
        params["searchOption"] != null &&
        params["searchOption"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "searchOption" + "=" + params["searchOption"];
      } else {
        if (param != "") {
          param += "&";
        }
        param += "searchOption" + "=0";
      }
      if (
        params["RaceYear"] != undefined &&
        params["RaceYear"] != null &&
        params["RaceYear"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "RaceYear" + "=" + params["RaceYear"];
      }
      if (
        params["Belong"] != undefined &&
        params["Belong"] != null &&
        params["Belong"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Belong" + "=" + params["Belong"];
      }
      if (
        params["HorseOwnerCd"] != undefined &&
        params["HorseOwnerCd"] != null &&
        params["HorseOwnerCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "HorseOwnerCd" + "=" + params["HorseOwnerCd"];
      }
      if (
        params["TrainerCd"] != undefined &&
        params["TrainerCd"] != null &&
        params["TrainerCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "TrainerCd" + "=" + params["TrainerCd"];
      }
      if (
        params["BreedingNum"] != undefined &&
        params["BreedingNum"] != null &&
        params["BreedingNum"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "BreedingNum" + "=" + params["BreedingNum"];
      }
      if (
        params["BreederCd"] != undefined &&
        params["BreederCd"] != null &&
        params["BreederCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "BreederCd" + "=" + params["BreederCd"];
      }
      if (
        params["ScreenDiv"] != undefined &&
        params["ScreenDiv"] != null &&
        params["ScreenDiv"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "ScreenDiv" + "=" + params["ScreenDiv"];
      }
      if (
        params["Word"] != undefined &&
        params["Word"] != null &&
        params["Word"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Word" + "=" + params["Word"];
      }
      if (
        params["Option"] != undefined &&
        params["Option"] != null &&
        params["Option"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Option" + "=" + params["Option"];
      }
      if (
        params["trendSearch"] != undefined &&
        params["trendSearch"] != null &&
        params["trendSearch"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "trendSearch" + "=" + params["trendSearch"];
      }

      return param;
    }

    async function makeRequest() {
      document.body.className = "";
      await getJSON(request, messageFlag, function (status, json) {
        if (status == SUCCESS) {
          let data = json;
          self.searchAllData = data;

          //全件表示
          self.allCount =
            data.HorseCount +
            data.JockeyCount +
            data.TrainerCount +
            data.HorseOwnerCount +
            data.StallionCount +
            data.BroodmareCount +
            data.BreederCount +
            data.RaceCount;
          if (json.HorseCount) {
            //競走馬データ
            let horseData = data.Horse;

            //検索結果が1件だった場合、詳細ページへ遷移
            if (horseData.length === 1 && self.allCount === 1) {
              const backCheck = sessionStorage.getItem('numberOfSearchResults?' + horseData[0].PedigreeNum)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              sessionStorage.setItem('numberOfSearchResults?' + horseData[0].PedigreeNum, 1)
              const url =
                '/' + DATAPATH + "horse?PedigreeNum=" + horseData[0].PedigreeNum;
              router.push(url)
              return;
            }

            for (let i = 0; i < horseData.length; i++) {
              if (
                horseData[i].PedigreeNum &&
                horseData[i].PedigreeNum != "0000000000"
              ) {
                //競走馬データへのリンク生成
                horseData[i].UrlLink =
                  '/' +
                  DATAPATH +
                  "horse?PedigreeNum=" +
                  horseData[i].PedigreeNum;
              }

              //お気に入り機能のプロパティ定義
              horseData[i].isFav = false;

              //お気に入りの表示
              if (
                  !(
                      self.courseId == PAYINGNONE ||
                      self.courseId == PAYINGSUGOTOKU_NONE
                  )
              ) {
                horseData[i].isFav = true;
                if (
                    horseData[i].PedigreeNum &&
                    isFavorite(FRACEHORSE, horseData[i].PedigreeNum) == 1
                ) {
                  horseData[i].favClass = "-registed";
                } else {
                  horseData[i].favClass = "";
                }
              } else {
                horseData[i].isFav = false;
              }

              // レースランク
              // 抹消馬の場合は非表示
              if (
                  horseData[i].RacehorseDiv !== undefined &&
                  horseData[i].RacehorseDiv !== null &&
                  horseData[i].RacehorseDiv === 0
              ) {
                if (
                    horseData[i].FlatRaceRank !== null &&
                    horseData[i].FlatRaceRank !== ""
                ) {
                  //平地は競走成績の有無にかかわらず、全て馬の条件を表示(外国馬除く)
                  horseData[i].flatRaceRank =
                      "平地：" + horseData[i].FlatRaceRank;
                }

                if (
                    horseData[i].SteeplechaseRaceRank !== null &&
                    horseData[i].SteeplechaseRaceRank !== "" &&
                    horseData[i].SteeplechaseRaceRank !== "未出走" &&
                    horseData[i].SteeplechaseRaceRank !== "新馬"
                ) {
                  //障害の競走成績がある馬のみ表示
                  horseData[i].steeplechaseRaceRank =
                      "障害：" + horseData[i].SteeplechaseRaceRank;
                }
              }

              horseData[i].isPog = false
              console.log()
              // POG
              if (
                  self.courseId !== PAYINGNONE &&
                  horseData[i].POGFlag === 1
              ) {
                let url;
                horseData[i].isPog = true;
                url =
                    '/' +
                    POGPATH +
                    "horse_detail?PedigreeNum=" +
                    horseData[i].PedigreeNum;
                if (
                    self.courseId === PAYINGSUGOTOKU ||
                    self.courseId === PAYINGSUGOTOKU_NONE
                ) {
                  horseData[i].isPog = false;
                } else if (self.courseId == PAYINGAPPPASS) {
                  horseData[i].isPog = false;
                } else {
                  horseData[i].pogUrl = url;
                }
              }

              // 抹消されていた場合、グレーアウトする
              if (
                  horseData[i].RacehorseDiv != undefined &&
                  horseData[i].RacehorseDiv != null &&
                  horseData[i].RacehorseDiv == 0
              ) {
                horseData[i].hasClass = "-disable";
              } else {
                horseData[i].hasClass = "";
              }
            }
            self.searchAllListItems.push({
              key: "HORSE",
              name: "競走馬",
              count: self.searchAllData.HorseCount,
              isActive: false,
              url: '/' + DATAPATH + SEARCHPATH + "horse?" + param,
              isHorse: true,
              data: horseData.map((item) => {
                return {
                  name: item.HorseName,
                  number: item.PedigreeNum,
                  sex: item.Sex,
                  isFavorite: item.isFav,
                  flatRaceRank: item.flatRaceRank,
                  steeplechaseRaceRank: item.steeplechaseRaceRank,
                  link: item.UrlLink,
                  isRetire: item.RacehorseDiv === 1,
                  ageFlag: item.RacehorseDiv === 0,
                  age: item.Age,
                  favClass: item.favClass,
                  isPog: item.isPog,
                  pogUrl: item.pogUrl,
                  affiliation: item.Affiliation,
                };
              }),
            });
          }

          if (json.JockeyCount) {
            //騎手データ
            let jockeyData = data.Jockey;

            //検索結果が1件だった場合、詳細ページへ遷移
            if (jockeyData.length === 1  && self.allCount === 1) {
              const backCheck = sessionStorage.getItem('numberOfSearchResults?' + jockeyData[0].JockeyCd)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              sessionStorage.setItem('numberOfSearchResults?' + jockeyData[0].JockeyCd, 1)
              const url =
                  '/' + DATAPATH + "jockey?JockeyCd=" + jockeyData[0].JockeyCd;
              router.push(url)
              return
            }

            for (let i = 0; i < jockeyData.length; i++) {
              if (
                jockeyData[i].JockeyCd &&
                jockeyData[i].JockeyCd != "0000000000"
              ) {
                //詳細データへのリンク生成
                jockeyData[i].UrlLink =
                  '/' + DATAPATH + "jockey?JockeyCd=" + jockeyData[i].JockeyCd;
                //お気に入り機能のプロパティ定義
                jockeyData[i].isFav = false;
              }

              //お気に入りの表示
              if (
                !(
                  self.courseId == PAYINGNONE ||
                  self.courseId == PAYINGSUGOTOKU_NONE
                )
              ) {
                jockeyData[i].isFav = true;
                if (
                  jockeyData[i].JockeyCd &&
                  isFavorite(FJOCKEY, jockeyData[i].JockeyCd) == 1
                ) {
                  jockeyData[i].favClass = "-registed";
                } else {
                  jockeyData[i].favClass = "";
                }
              } else {
                jockeyData[i].isFav = false;
              }
            }

            self.searchAllListItems.push({
              key: "JOCKEY",
              name: "騎手",
              count: self.searchAllData.JockeyCount,
              isActive: false,
              url: '/' + DATAPATH + SEARCHPATH + "jockey?" + param,
              data: jockeyData.map((item) => {
                return {
                  name: item.JockeyName,
                  number: item.JockeyCd,
                  sex: null,
                  isFavorite: item.isFav,
                  flatRaceRank: null,
                  steeplechaseRaceRank: null,
                  link: item.UrlLink,
                  isRetire: item.RetireDiv === "引退",
                  ageFlag: null,
                  age: null,
                  favClass: item.favClass,
                };
              }),
            });
          }

          if (json.TrainerCount) {
            //調教師データ
            let trainerData = data.Trainer;

            //検索結果が1件だった場合、詳細ページへ遷移
            if (trainerData.length === 1  && self.allCount === 1) {
              const backCheck = sessionStorage.getItem('numberOfSearchResults?' + trainerData[0].TrainerCd)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              sessionStorage.setItem('numberOfSearchResults?' + trainerData[0].TrainerCd, 1)
              const url =
                  '/' + DATAPATH + "trainer?TrainerCd=" + trainerData[0].TrainerCd;
              router.push(url)
              return
            }
            for (let i = 0; i < trainerData.length; i++) {
              if (
                trainerData[i].TrainerCd &&
                trainerData[i].TrainerCd != "0000000000"
              ) {
                //詳細データへのリンク生成
                trainerData[i].UrlLink =
                  '/' +
                  DATAPATH +
                  "trainer?TrainerCd=" +
                  trainerData[i].TrainerCd;
              }
            }
            //お気に入り機能のプロパティ定義
            for (let i = 0; i < trainerData.length; i++) {
              if (trainerData[i].TrainerCd != "0000000000") {
                trainerData[i].isFav = false;
              }

              //お気に入りの表示
              if (
                !(
                  self.courseId == PAYINGNONE ||
                  self.courseId == PAYINGSUGOTOKU_NONE
                )
              ) {
                trainerData[i].isFav = true;
                if (
                  trainerData[i].TrainerCd &&
                  isFavorite(FTRAINER, trainerData[i].TrainerCd) == 1
                ) {
                  trainerData[i].favClass = "-registed";
                } else {
                  trainerData[i].favClass = "";
                }
              } else {
                trainerData[i].isFav = false;
              }
            }

            self.searchAllListItems.push({
              key: "TRAINER",
              name: "調教師",
              count: self.searchAllData.TrainerCount,
              isActive: false,
              url: '/' + DATAPATH + SEARCHPATH + "trainer?" + param,
              data: trainerData.map((item) => {
                return {
                  name: item.TrainerName,
                  number: item.TrainerCd,
                  sex: null,
                  isFavorite: item.isFav,
                  flatRaceRank: null,
                  steeplechaseRaceRank: null,
                  link: item.UrlLink,
                  isRetire: item.RetireDiv === "引退",
                  ageFlag: null,
                  age: null,
                  favClass: item.favClass,
                };
              }),
            });
          }

          if (json.HorseOwnerCount) {
            //馬主データ
            let horseOwnerData = data.HorseOwner;

            //検索結果が1件だった場合、詳細ページへ遷移
            if (horseOwnerData.length === 1  && self.allCount === 1) {
              const backCheck = sessionStorage.getItem('numberOfSearchResults?' + horseOwnerData[0].HorseOwnerCd)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              sessionStorage.setItem('numberOfSearchResults?' + horseOwnerData[0].HorseOwnerCd, 1)
              const url =
                  '/' +
                  DATAPATH +
                  "owner?HorseOwnerCd=" +
                  horseOwnerData[0].HorseOwnerCd;
              router.push(url)
              return;
            }
            for (let i = 0; i < horseOwnerData.length; i++) {
              //詳細データへのリンク表示
              if (
                horseOwnerData[i].HorseOwnerCd &&
                horseOwnerData[i].HorseOwnerCd != "0000000000"
              ) {
                horseOwnerData[i].UrlLink =
                  '/' +
                  DATAPATH +
                  "owner?HorseOwnerCd=" +
                  horseOwnerData[i].HorseOwnerCd;
              }
            }
            //お気に入り機能のプロパティ定義
            for (let i = 0; i < horseOwnerData.length; i++) {
              if (horseOwnerData[i].HorseOwnerCd != "0000000000") {
                horseOwnerData[i].isFav = false;
              }

              //お気に入りの表示
              if (
                !(
                  self.courseId == PAYINGNONE ||
                  self.courseId == PAYINGSUGOTOKU_NONE
                )
              ) {
                horseOwnerData[i].isFav = true;
                if (
                  horseOwnerData[i].HorseOwnerCd &&
                  isFavorite(FOWNER, horseOwnerData[i].HorseOwnerCd) == 1
                ) {
                  horseOwnerData[i].favClass = "-registed";
                } else {
                  horseOwnerData[i].favClass = "";
                }
              } else {
                horseOwnerData[i].isFav = false;
              }
            }
            self.searchAllListItems.push({
              key: "HORSEOWNER",
              name: "馬主",
              count: self.searchAllData.HorseOwnerCount,
              isActive: false,
              url: '/' + DATAPATH + SEARCHPATH + "owner?" + param,
              data: horseOwnerData.map((item) => {
                return {
                  name: item.HorseOwnerName,
                  number: item.HorseOwnerCd,
                  sex: null,
                  isFavorite: item.isFav,
                  flatRaceRank: null,
                  steeplechaseRaceRank: null,

                  link: item.UrlLink,
                  isRetire: null,
                  ageFlag: null,
                  age: null,
                  favClass: item.favClass,
                };
              }),
            });
          }

          if (json.BreederCount) {
            //生産者データ
            let breederData = data.Breeder;

            //検索結果が1件だった場合、詳細ページへ遷移
            if (breederData.length === 1  && self.allCount === 1) {
              const backCheck = sessionStorage.getItem('numberOfSearchResults?' + breederData[0].BreederCd)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              sessionStorage.setItem('numberOfSearchResults?' + breederData[0].BreederCd, 1)
              const url =
                  '/' +
                  DATAPATH +
                  "breeder?BreederCd=" +
                  breederData[0].BreederCd;
              router.push(url)
              return;
            }
            for (let i = 0; i < breederData.length; i++) {
              //詳細データへのリンク表示
              if (
                breederData[i].BreederCd &&
                breederData[i].BreederCd != "0000000000"
              ) {
                breederData[i].UrlLink =
                  '/' +
                  DATAPATH +
                  "breeder?BreederCd=" +
                  breederData[i].BreederCd;
              }
            }
            //お気に入り機能のプロパティ定義
            for (let i = 0; i < breederData.length; i++) {
              if (breederData[i].BreederCd != "0000000000") {
                breederData[i].isFav = false;
              }
              //お気に入りの表示
              if (
                !(
                  self.courseId == PAYINGNONE ||
                  self.courseId == PAYINGSUGOTOKU_NONE
                )
              ) {
                breederData[i].isFav = true;
                if (
                  breederData[i].BreederCd &&
                  isFavorite(FBREEDER, breederData[i].BreederCd) == 1
                ) {
                  breederData[i].favClass = "-registed";
                } else {
                  breederData[i].favClass = "";
                }
              } else {
                breederData[i].isFav = false;
              }
            }
            self.searchAllListItems.push({
              key: "BREEDER",
              name: "生産者",
              count: self.searchAllData.BreederCount,
              isActive: false,
              url: '/' + DATAPATH + SEARCHPATH + "breeder?" + param,
              data: breederData.map((item) => {
                return {
                  name: null,
                  number: item.BreederCd,
                  sex: null,
                  isFavorite: item.isFav,
                  flatRaceRank: null,
                  steeplechaseRaceRank: null,
                  link: item.UrlLink,
                  isRetire: null,
                  ageFlag: null,
                  age: null,
                  breederName: item.BreederName,
                  breederNameAlpha: item.BreederNameAlpha,
                  favClass: item.favClass,
                };
              }),
            });
          }

          if (json.StallionCount) {
            //種牡馬データ
            let stallionData = data.Stallion;
            
            //検索結果が1件だった場合、詳細ページへ遷移
            if (stallionData.length === 1 && self.allCount === 1) {
              const backCheck = sessionStorage.getItem('numberOfSearchResults?' + stallionData[0].BreedingNum)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              sessionStorage.setItem('numberOfSearchResults?' + stallionData[0].BreedingNum, 1)
              const url =
                  '/' +
                  DATAPATH +
                  "stud?BreedingNum=" +
                  stallionData[0].BreedingNum;
              router.push(url);
              return;
            }
            for (let i = 0; i < stallionData.length; i++) {
              //詳細データへのリンク表示
              if (
                stallionData[i].BreedingNum &&
                stallionData[i].BreedingNum != "0000000000"
              ) {
                stallionData[i].UrlLink =
                  '/' +
                  DATAPATH +
                  "stud?BreedingNum=" +
                  stallionData[i].BreedingNum;
              }
            }
            //お気に入り機能のプロパティ定義
            for (let i = 0; i < stallionData.length; i++) {
              if (stallionData[i].BreedingNum != "0000000000") {
                stallionData[i].isFav = false;
              }
              //お気に入りの表示
              if (
                !(
                  self.courseId == PAYINGNONE ||
                  self.courseId == PAYINGSUGOTOKU_NONE
                )
              ) {
                stallionData[i].isFav = true;
                if (
                  stallionData[i].BreedingNum &&
                  isFavorite(FSTALLION, stallionData[i].BreedingNum) == 1
                ) {
                  stallionData[i].favClass = "-registed";
                } else {
                  stallionData[i].favClass = "";
                }
              } else {
                stallionData[i].isFav = false;
              }
            }
            self.searchAllListItems.push({
              key: "STUD",
              name: "種牡馬",
              count: self.searchAllData.StallionCount,
              isActive: false,
              url: '/' + DATAPATH + SEARCHPATH + "stud?" + param,
              data: stallionData.map((item) => {
                return {
                  name: item.HorseName,
                  number: item.BreedingNum,
                  sex: null,
                  isFavorite: item.isFav,
                  flatRaceRank: null,
                  steeplechaseRaceRank: null,

                  link: item.UrlLink,
                  isRetire: null,
                  ageFlag: null,
                  age: null,
                  favClass: item.favClass,
                };
              }),
            });
          }

          if (json.BroodmareCount) {
            //繁殖牝馬データ
            let broodmareData = data.Broodmare;

            //検索結果が1件だった場合、詳細ページへ遷移
            if (broodmareData.length === 1 && self.allCount === 1) {
              const backCheck = sessionStorage.getItem('numberOfSearchResults?' + broodmareData[0].BreedingNum)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              sessionStorage.setItem('numberOfSearchResults?' + broodmareData[0].BreedingNum, 1)
              const url =
                  '/' +
                  DATAPATH +
                  "mare?BreedingNum=" +
                  broodmareData[0].BreedingNum;
              router.push(url);
              return;
            }
            for (let i = 0; i < broodmareData.length; i++) {
              //詳細データへのリンク表示
              if (
                broodmareData[i].BreedingNum &&
                broodmareData[i].BreedingNum != "0000000000"
              ) {
                broodmareData[i].UrlLink =
                  '/' +
                  DATAPATH +
                  "mare?BreedingNum=" +
                  broodmareData[i].BreedingNum;

                //お気に入り機能のプロパティ定義
                broodmareData[i].isFav = false;
                broodmareData[i].isFav = false;
              }

              //お気に入りの表示
              if (
                !(
                  self.courseId == PAYINGNONE ||
                  self.courseId == PAYINGSUGOTOKU_NONE
                )
              ) {
                broodmareData[i].isFav = true;
                if (
                  broodmareData[i].BreedingNum &&
                  isFavorite(FBROODMARE, broodmareData[i].BreedingNum) == 1
                ) {
                  broodmareData[i].favClass = "-registed";
                } else {
                  broodmareData[i].favClass = "";
                }
              } else {
                broodmareData[i].isFav = false;
              }
            }
            self.searchAllListItems.push({
              key: "MARE",
              name: "繁殖牝馬",
              count: self.searchAllData.BroodmareCount,
              isActive: false,
              url: '/' + DATAPATH + SEARCHPATH + "mare?" + param,
              data: broodmareData.map((item) => {
                return {
                  name: item.HorseName,
                  number: item.BreedingNum,
                  sex: null,
                  isFavorite: item.isFav,
                  flatRaceRank: null,
                  steeplechaseRaceRank: null,

                  link: item.UrlLink,
                  isRetire: null,
                  ageFlag: null,
                  age: null,
                  favClass: item.favClass,
                };
              }),
            });
          }

          if (json.RaceCount) {
            //レースデータ
            let raceData = data.Race;

            //検索結果が1件だった場合、詳細ページへ遷移
            if (raceData.length === 1 && self.allCount === 1) {
              const backCheck = sessionStorage.getItem('numberOfSearchResults?' + `race${raceData[0].DOR}${raceData[0].RacetrackCd}${raceData[0].RaceNum}`)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              sessionStorage.setItem('numberOfSearchResults?' + `race${raceData[0].DOR}${raceData[0].RacetrackCd}${raceData[0].RaceNum}`, 1)
              const url =
                  '/' +
                  RACEPATH +
                  "results?DOR=" +
                  raceData[0].DOR +
                  "&RacetrackCd=" +
                  raceData[0].RacetrackCd +
                  "&RaceNum=" +
                  raceData[0].RaceNum;
              router.push(url);
              return;
            }
            //詳細データへのリンク生成
            for (let i = 0; i < raceData.length; i++) {
              if (
                raceData[i].DOR != "0000000000" &&
                raceData[i].RacetrackCd != "00" &&
                raceData[i].RaceNum != "00"
              ) {
                raceData[i].UrlLink =
                  '/' +
                  RACEPATH +
                  "results?DOR=" +
                  raceData[i].DOR +
                  "&RacetrackCd=" +
                  raceData[i].RacetrackCd +
                  "&RaceNum=" +
                  raceData[i].RaceNum;
              }
            }
            self.searchAllListItems.push({
              key: "RACE",
              name: "レース",
              count: self.searchAllData.RaceCount,
              isActive: false,
              url: '/' + DATAPATH + SEARCHPATH + "race?" + param,
              data: raceData.map((item) => {
                return {
                  raceName: item.RaceMainName,
                  raceDor: item.DOR,
                  number: item.RaceNum,
                  sex: null,
                  isFavorite: null,
                  flatRaceRank: null,
                  steeplechaseRaceRank: null,
                  link: item.UrlLink,
                  isRetire: null,
                  ageFlag: null,
                  age: null,
                  favClass: null,
                };
              }),
            });
          }
        }
      });
      document.body.className = "status-loaded";
      self.loadedPage = true;
    };
  },
  methods: {
    changeActive(item) {
      item.isActive = !item.isActive;
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
    async onClickFavorite(key, data) {
      const env = this.env[key];
      try {
        document.body.className = "";

        if (isFavorite(env.cookieName, data.number) == 1) {
          // お気に入り未登録なので登録する
          const request =
            HOST +
            PATH +
            env.deleteJsFile +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(this.userId) +
            `&${env.dataNumberName}=` +
            data.number +
            "&narFlag=" +
            isNar();
          const order = REMOVE;
          await getJSON(request, false, function (status, json) {
            if (status == SUCCESS) {
            } else {
              alert("お気に入りの削除に失敗しました。");
            }
          });
          const favJudge = await changeFavoriteFromCookie(
            env.cookieName,
            data.number,
            order
          );
          if (favJudge) {
            //アイコンクラス変更
            data.favClass = "";
          } else {
            data.favClass = "";
          }
        } else {
          // お気に入り未登録なので登録する
          const request =
            HOST +
            PATH +
            env.registJsFile +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(this.userId) +
            `&${env.dataNumberName}=` +
            data.number +
            "&isPush=1" +
            "&isAuto=0" +
            "&narFlag=" +
            isNar();
          const order = ADD;
          await getJSON(request, false, function (status, json) {
            if (status == SUCCESS) {
              this.favStatus = SUCCESS;
            } else {
              alert("お気に入りの登録に失敗しました。");
            }
          });
          const favJudge = await changeFavoriteFromCookie(
            env.cookieName,
            data.number,
            order
          );
          if (favJudge) {
            data.favClass = "-registed";
          } else {
            data.favClass = "";
          }
        }

        document.body.className = "status-loaded";
        self.loadedPage = true;
      } catch (e) {
        console.log("error" + e);
      }
    },
  },
};
</script>

<style lang="scss" src="../../assets/css/data/search/style.scss" scoped></style>
