<script setup>
import {ref, reactive, onMounted, watch} from "vue";
import {
  getUrlVars,
  checkUrlVars,
  retrieveCourse,
  getJSON,
  getMenmerCourse,
  getUserId,
  SUCCESS,
  isFavorite,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  PAYINGSUGOTOKU,
  PAYINGECONOMY,
  PAYINGNONE,
  FRACEHORSE,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  JOINPATH,
  JOINECPATH,
  RACEINFOPATH,
} from "../../assets/js/define";

import RaceHeader from "../../components/race/RaceHeader.vue";
import MyStampModal from "../../components/utils/MyStampModal.vue";

import myMark from "../../functions/odds/race-mymark";
import {useRoute} from "vue-router";
const { loadMyMarkForDrop2, setMyMark } = myMark();

import {useStoreRaceCommon} from "../../stores/race-common-states";
import {storeToRefs} from "pinia";
const storeRaceCommon = useStoreRaceCommon();
const {
  raceDORVal,
  raceTrackCdVal,
  raceNumVal,
} = storeToRefs(storeRaceCommon);

let params = {};

let mCourse = ref(PAYINGNONE);

let stamp = reactive({});
const stampClass = (num) => {
  if (stamp[num].selectedNum == 0) {
    return "stamp-mark -none";
  } else if (stamp[num].selectedNum == 1) {
    return "stamp-mark -honmei";
  } else if (stamp[num].selectedNum == 2) {
    return "stamp-mark -taikou";
  } else if (stamp[num].selectedNum == 3) {
    return "stamp-mark -tanana";
  } else if (stamp[num].selectedNum == 4) {
    return "stamp-mark -renka";
  } else if (stamp[num].selectedNum == 5) {
    return "stamp-mark -hoshi";
  } else return "stamp-mark -none";
};

const route = useRoute();

watch(route, (from, to) => {
  doInit(to.query);
});

onMounted(() => {
  document.body.className = "";
  Object.assign(params, getUrlVars());
  //パラメータチェック
  if (checkUrlVars(params)) {
    callRetrieveCourse(function (courseId) {
      mCourse.value = courseId;
      //新聞印表示フラグ確認
      if (mCourse.value === PAYINGNONE) {
        location.href = HOST + JOINPATH;
      } else if (mCourse.value === PAYINGECONOMY) {
        location.href = HOST + JOINECPATH;
      } else if (
        mCourse.value === PAYINGPREMIUM ||
        mCourse.value === PAYINGSUGOTOKU ||
        mCourse === PAYINGPACK03 ||
        mCourse === PAYINGPACK06 ||
        mCourse === PAYINGPACK12
      ) {
        doInit(params);
      } else {
        location.href = HOST + JOINPATH;
      }
    });
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});

// modal
let left = ref("");
let top = ref("");
let stampNav = ref("");

const openModal = (event, win) => {
  document.body.className = "status-loaded status-haslayerbg status-showstamp";
  // x座標
  left.value = event.pageX;
  // y座標
  top.value = event.pageY;
  stamp[win - 1].status = true;
  stampNav.value = "top: " + top.value;
};

const closeStampModal = (win) => {
  if (stamp[win.num].selectedNum != win.selectedNum) {
    document.body.className = "";
    //my印を新しくセットした場合は、my印を更新する
    stamp[win.num].selectedNum = win.selectedNum;
    setMyMark(
      getUserId(),
      raceDORVal.value,
      raceTrackCdVal.value,
      raceNumVal.value,
      win.num + 1,
      stamp[win.num].selectedNum
    );
  } else {
    document.body.className = "status-loaded";
  }
  stamp[win.num].status = false;
};

//オッズの色を返す
const oddsClass = (val) => {
  if (val == "---.-") {
    return "txt-odds";
  } else if (val < 10) {
    return "txt-odds -no1";
  } else if (val < 100) {
    return "txt-odds -no2";
  } else return "txt-odds -other";
};

//ユーザのコースを取得
const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false);
  }
};

const doInit = async (params) => {
  marks = await loadMyMarkForDrop2(
    getUserId(),
    params.DOR,
    params.RacetrackCd,
    params.RaceNum
  );

  //新聞印集計表示
  setEntryList(params.DOR, params.RacetrackCd, params.RaceNum, function (err) {
    if (err) {
      alert("データの取得に失敗しました。");
      document.body.className = "status-loaded";
    }
  });
};

//新聞印集計表示
let horseLists = reactive([]);
let marks = reactive({});
const setEntryList = (dor, racetrackCd, raceNum, callback) => {
  //パラメータからリクエストURL作成
  const req =
    HOST +
    RACEINFOPATH +
    "candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&narFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status === SUCCESS) {
      if (
        json === null ||
        !("EntryHorses" in json) ||
        json.EntryHorses == null ||
        json.EntryHorses.length == 0
      ) {
        return callback(true);
      }

      let horses = json.EntryHorses;
      let spEntryFlag = 0;

      horseLists.splice(0, horseLists.length);
      json.EntryHorses.forEach((horse) => {
        horseLists.push(horse);
      });

      if (getMenmerCourse() != PAYINGNONE) {
        winTicketOdds(dor, racetrackCd, raceNum, function () {});
      }

      for (let i = 0; i < horses.length; i++) {
        //特別出走があるかどうか
        if ("BracketNum" in horses[i] && horses[i].BracketNum !== "") {
          spEntryFlag++;
        }

        let selectNum = 0;
        if (
          marks &&
          marks["MyNo" + (i + 1)] != undefined &&
          marks["MyNo" + (i + 1)] != "" &&
          marks["MyNo" + (i + 1)] != null
        ) {
          selectNum = marks["MyNo" + (i + 1)];
        }

        stamp[i] = { status: false, selectedNum: selectNum };
      }
      setNewspaperMarkSummary(dor, racetrackCd, raceNum);

      return callback(false);
    }
  });
};

let newsLists = reactive([]);
const setNewspaperMarkSummary = (dor, racetrackCd, raceNum) => {
  //パラメータからリクエストURL作成
  const request =
    HOST +
    PATH +
    "getNewspaperMarkSummary.js" +
    BACKWORDPATH +
    "?dor=" +
    dor +
    "&racetrackCd=" +
    racetrackCd +
    "&raceNum=" +
    raceNum +
    "&narFlag=" +
    isNar();
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      if (json == null || !("EntryLst" in json) || json.EntryLst.length == 0) {
        //新聞印データがない場合は空の数字をセットする
        let newsBackgroundColor = new Array();
        const nullVal = "-";
        newsBackgroundColor = new Array(horseLists.length);
        for (let n = 0; n < horseLists.length; n++) {
          newsBackgroundColor[n] = new Array(5);
          for (let i = 0; i < 5; i++) {
            newsBackgroundColor[n][i] = nullVal;
          }
        }
        newsLists.splice(0, newsLists.length);
        newsBkColorList.splice(0, newsBkColorList.length);
        for (let m = 0; m < newsBackgroundColor.length; m++) {
          newsLists.push({
            FavNum1: nullVal,
            FavNum2: nullVal,
            FavNum3: nullVal,
            FavNum4: nullVal,
            FavNum5: nullVal,
          });
          newsBkColorList.push(newsBackgroundColor[m]);
        }
        document.body.className = "status-loaded";
        return;
      }

      try {
        setBackgroudColor(json.EntryLst);
      } catch (e) {
        alert("setNewspaperMarkSummary : " + e);
      }
    }
  });
};

//オッズを取得する
let oddsWin = reactive([]);
const winTicketOdds = async (DOR, RacetrackCd, RaceNum) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "Odds/winTicketOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    DOR +
    "&RacetrackCd=" +
    RacetrackCd +
    "&RaceNum=" +
    RaceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      //データは常に１件が正しい。
      if (json.length == 1) {
        const displayData = json[0];
        let oddsObj = new Array();
        // オッズを格納
        for (let i = 0; i < horseLists.length; i++) {
          horseLists[i].Odds =
            mCourse === PAYINGNONE ? "---.-" : displayData["Odds" + (i + 1)];
          oddsObj.push({ name: "Odds" + i, Odds: horseLists[i].Odds });
        }
        //オッズでソートする
        let sort_by = function (field, reverse, primer) {
          reverse = reverse ? -1 : 1;
          return function (a, b) {
            a = a[field] === '---.-' ? 99999 : a[field] * 10;
            b = b[field] === '---.-' ? 99999 : b[field] * 10;
            if (typeof primer != "undefined") {
              a = primer(a);
              b = primer(b);
            }
            if (a < b) return reverse * -1;
            if (a > b) return reverse * 1;
          };
        };
        oddsObj.sort(sort_by("Odds", false, parseInt));
        //人気表示
        let preOdds;
        let popular = 1;
        for (let i = 0; i < oddsObj.length; i++) {
          let useOdds = oddsObj[i];
          //前のオッズと同じだった場合、同人気
          if (preOdds != useOdds.Odds) {
            popular = i + 1;
          }
          let num = useOdds.name.split("Odds")[1];
          preOdds = useOdds.Odds;
          horseLists[num].Popular = popular;
        }
      }
    }
  });
};

let newsBkColorList = reactive([]);
const setBackgroudColor = (lst) => {
  //同じ印の中で予想の多い順に背景色変更
  let newsBackgroundColor = new Array();
  let map = [
    { key: "FavNum1", id: "mark_double" },
    { key: "FavNum2", id: "mark_circle" },
    { key: "FavNum3", id: "mark_triangle_black" },
    { key: "FavNum4", id: "mark_triangle" },
    { key: "FavNum5", id: "mark_other" },
  ];
  let rankLst = new Array();

  horseLists.forEach(function (horse) {
    if (!lst.some(val => val.PedigreeNum === horse.PedigreeNum)) {
      lst.push({
        "PedigreeNum": horse.PedigreeNum,
        "HorseName": horse.HorseName,
        "BracketNum": horse.BracketNum,
        "HorseNum": horse.HorseNum,
        "FavNum1": 0,
        "FavNum2": 0,
        "FavNum3": 0,
        "FavNum4": 0,
        "FavNum5": 0
      })
    }
  })

  lst.sort(function (a, b) {
    return a.HorseNum - b.HorseNum
  }).forEach((news) => {
    rankLst.push(news);
  });

  newsBackgroundColor = new Array(lst.length);
  for (let n = 0; n < horseLists.length; n++) {
    newsBackgroundColor[n] = new Array(5);
    for (let i = 0; i < 5; i++) {
      let key = map[i].key;
      rankLst.sort(function (a, b) {
        a = a[key];
        b = b[key];
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      });
      let poll = rankLst[0][key];
      let poll2 = rankLst[1][key];
      let poll3 = rankLst[2][key];
      let favVal = i + 1;
      if (lst[n]["FavNum" + favVal] === poll) {
        //1位票数馬
        newsBackgroundColor[n][i] = 1;
      } else if (lst[n]["FavNum" + favVal] === poll2) {
        //2位票数馬
        newsBackgroundColor[n][i] = 2;
      } else if (lst[n]["FavNum" + favVal] === poll3) {
        //3位票数馬
        newsBackgroundColor[n][i] = 3;
      } else {
        newsBackgroundColor[n][i] = 0;
      }
    }
  }

  newsLists.splice(0, newsLists.length);
  lst.forEach((news) => {
    newsLists.push(news);
  });

  newsBkColorList.splice(0, newsBkColorList.length);
  for (let m = 0; m < newsBackgroundColor.length; m++) {
    newsBkColorList.push(newsBackgroundColor[m]);
  }
  document.body.className = "status-loaded";
};
</script>
<template>
  <main class="layout-main">
    <RaceHeader />
    <section class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">競馬新聞予想印集計</h2>
      </div>
      <table class="table-grid -fit -middle -center -marks">
        <tbody>
          <template v-for="(race, index) in horseLists" :key="index">
            <tr>
              <td rowspan="2">
                <i
                  :class="
                    race.BracketNum != undefined
                      ? 'icon-umaban -waku0' + race.BracketNum
                      : ''
                  "
                  >{{ race.HorseNum }}</i
                >
              </td>
              <td class="mystamp" rowspan="2">
                <span
                  :class="stampClass(race.HorseNum - 1)"
                  @click="openModal($event, race.HorseNum)"
                ></span>
                <MyStampModal
                  @closeModal="closeStampModal($event)"
                  v-if="stamp[race.HorseNum - 1].status === true"
                  :top="top"
                  :left="left"
                  :stampNum="race.HorseNum - 1"
                  :selectStamp="stamp[race.HorseNum - 1].selectedNum"
                />
              </td>
              <td colspan="5" class="name">
                <a
                  v-if="race.PedigreeNum != ''"
                  :href="'/data/horse?PedigreeNum=' + race.PedigreeNum"
                  :style="
                    isFavorite(FRACEHORSE, race.PedigreeNum) ? 'color:red' : ''
                  "
                  >{{ race.HorseName }}</a
                >
                <template v-else>{{ race.HorseName }}</template>
              </td>
              <td rowspan="2">
                <div :class="oddsClass(race.Odds)">
                  {{ race.Odds != undefined ? race.Odds : "---.-" }}
                </div>
                <div class="txt-odds -popular" v-if="race.Odds && race.Odds !== '---.-'">
                  ({{ race.Popular != undefined ? race.Popular : "-" }}人気)
                </div>
              </td>
            </tr>
            <tr v-if="newsLists.length > 0 && newsBkColorList.length > 0">
              <td
                class="predict"
                :class="
                  newsBkColorList[index][0] === 1
                    ? 'bg-rank01'
                    : newsBkColorList[index][0] === 2
                    ? 'bg-rank02'
                    : newsBkColorList[index][0] === 3
                    ? 'bg-rank03'
                    : ''
                "
              >
                <dl>
                  <dt><i class="stamp-mark -honmei"></i></dt>
                  <dd>{{ newsLists[index].FavNum1 }}</dd>
                </dl>
              </td>
              <td
                class="predict"
                :class="
                  newsBkColorList[index][1] === 1
                    ? 'bg-rank01'
                    : newsBkColorList[index][1] === 2
                    ? 'bg-rank02'
                    : newsBkColorList[index][1] === 3
                    ? 'bg-rank03'
                    : ''
                "
              >
                <dl>
                  <dt><i class="stamp-mark -taikou"></i></dt>
                  <dd>{{ newsLists[index].FavNum2 }}</dd>
                </dl>
              </td>
              <td
                class="predict"
                :class="
                  newsBkColorList[index][2] === 1
                    ? 'bg-rank01'
                    : newsBkColorList[index][2] === 2
                    ? 'bg-rank02'
                    : newsBkColorList[index][2] === 3
                    ? 'bg-rank03'
                    : ''
                "
              >
                <dl>
                  <dt><i class="stamp-mark -tanana"></i></dt>
                  <dd>{{ newsLists[index].FavNum3 }}</dd>
                </dl>
              </td>
              <td
                class="predict"
                :class="
                  newsBkColorList[index][3] === 1
                    ? 'bg-rank01'
                    : newsBkColorList[index][3] === 2
                    ? 'bg-rank02'
                    : newsBkColorList[index][3] === 3
                    ? 'bg-rank03'
                    : ''
                "
              >
                <dl>
                  <dt><i class="stamp-mark -renka"></i></dt>
                  <dd>{{ newsLists[index].FavNum4 }}</dd>
                </dl>
              </td>
              <td
                class="predict"
                :class="
                  newsBkColorList[index][4] === 1
                    ? 'bg-rank01'
                    : newsBkColorList[index][4] === 2
                    ? 'bg-rank02'
                    : newsBkColorList[index][4] === 3
                    ? 'bg-rank03'
                    : ''
                "
              >
                <dl>
                  <dt>その他</dt>
                  <dd>{{ newsLists[index].FavNum5 }}</dd>
                </dl>
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <div class="inner">
        <p class="p-caption">
          ※関東競馬専門紙主要6紙の印を集計しております。<br />
          ※出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。
        </p>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
