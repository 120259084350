import {reactive, ref} from 'vue';
import {defineStore} from 'pinia'

export const useStoreTopCommon = defineStore('top-common', () => {
  const display = ref(0)
  return {
    display
  }
})

export default {
  useStoreTopCommon,
}