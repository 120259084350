<script setup>
import { HOST } from "../../assets/js/define";
const favLink = "/fav/myNextTarget";
</script>

<template>
  <div class="container-box">
    <div class="inner">
      <div class="layout-title">
        <h2 class="heading-circle" v-if="isJra">今週の出走情報</h2>
        <h2 class="heading-circle" v-else>出走情報</h2>
        <div class="nav -text" v-if="schedule && schedule.allSaddle !== 0">{{ schedule.allSaddle }}鞍</div>
      </div>
      <div v-if="schedule && schedule.allSaddle === 0">
        <ul>
          <li>出走予定はありません。</li>
        </ul>
      </div>
      <div v-else>
        <template v-for="(item, index) in schedule" :key="index">
          <ul v-if="item.detail.length !== 0" class="list-news">
            <li class="item -nopad">
              <div class="feed">
                <div class="head">
                  <div class="title">
                    <div>
                      {{ item.year }}年{{ item.month }}月{{ item.day }}日(<span
                          v-html="item.dateTag"
                        ></span
                        >){{ item.displayPlace }} {{ item.scheduleCounter }}鞍
                    </div>
                  </div>
                  <div class="nav">
                    <span
                      class="icon-accordion"
                      :class="{ '-active': isWeekOpen[index] }"
                      @click="handleThisToggle(index, 'week')"
                      data-btn
                    ></span>
                  </div>
                </div>
                <transition
                  name="topSlide"
                  @before-enter="beforeEnter"
                  @enter="enter"
                  @before-leave="beforeLeave"
                  @leave="leave"
                >
                  <div
                    v-if="isWeekOpen[index]"
                    class="topSlide detail"
                    :class="{ '-active': isWeekOpen[index] }"
                  >
                    <table class="table-cell -center">
                      <tr>
                        <th class="_nowrap">R</th>
                        <th class="_nowrap">レース名</th>
                        <th class="_nowrap">馬名</th>
                        <th class="_nowrap">単オッズ</th>
                        <th class="_nowrap">着順</th>
                      </tr>
                      <tr
                        v-for="(detail, index) in item.detail"
                        :key="index"
                        class="list-news"
                      >
                        <td>{{ detail.raceNum }}R<br />{{ detail.startDate }}</td>
                        <td class="-left">
                          <component :is="detail.raceName"></component>
                          <i v-if="detail.icon" :class="detail.icon"></i>
                        </td>
                        <td class="-left">
                          <component :is="detail.horseName"></component>
                        </td>
                        <td v-html="detail.oddsHtml"></td>
                        <td class="_nowrap">
                          <component :is="detail.ranking"></component>
                        </td>
                      </tr>
                    </table>
                  </div>
                </transition>
              </div>
            </li>
          </ul>
        </template>
      </div>
    </div>
    <div class="inner" v-if="isJra">
      <div class="layout-title">
        <h2 class="heading-circle">特別レース登録情報</h2>
        <div class="nav -text" v-if="specials && specials.allSaddle !== 0">{{ specials.allSaddle }}鞍</div>
      </div>
      <div v-if="specials && specials.allSaddle === 0">
        <ul>
          <li>特別レース登録情報はありません。</li>
        </ul>
      </div>
      <div v-else>
        <template v-for="(item, index) in specials" :key="index">
          <ul v-if="item.detail.length !== 0" class="list-news">
            <li class="item -nopad">
              <div class="feed">
                <div class="head">
                  <div class="title">
                    <div>
                      {{ item.year }}年{{ item.month }}月{{ item.day }}日(<span
                        v-html="item.dateTag"
                    ></span
                    >){{ item.displayPlace }} {{ item.scheduleCounter }}鞍
                    </div>
                  </div>
                  <div class="nav">
                    <span
                        class="icon-accordion"
                        :class="{ '-active': isSpecialOpen[index] }"
                        @click="handleThisToggle(index, 'sp')"
                        data-btn
                    ></span>
                  </div>
                </div>
                <transition
                    name="topSlide"
                    @before-enter="beforeEnter"
                    @enter="enter"
                    @before-leave="beforeLeave"
                    @leave="leave"
                >
                  <div
                      v-if="isSpecialOpen[index]"
                      class="topSlide detail"
                      :class="{ '-active': isSpecialOpen[index] }"
                  >
                    <table class="table-cell -center">
                      <tr>
                        <th class="_nowrap">R</th>
                        <th class="_nowrap">レース名</th>
                        <th class="_nowrap">馬名</th>
                      </tr>
                      <tr
                          v-for="(detail, index) in item.detail"
                          :key="index"
                          class="list-news"
                      >
                        <td>{{ detail.raceNum }}R<br />{{ detail.startDate }}</td>
                        <td class="-left">
                          <component :is="detail.raceName"></component>
                          <i v-if="detail.icon" :class="detail.icon"></i>
                        </td>
                        <td class="-left">
                          <component :is="detail.horseName"></component>
                        </td>
                      </tr>
                    </table>
                  </div>
                </transition>
              </div>
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
  <div class="inner -favrace">
    <router-link :to="favLink" class="btn-basic -wide">次走狙い馬一覧へ</router-link>
  </div>
</template>

<script>
import {shallowRef} from "vue/dist/vue.esm-bundler";

import {
  getGradeIconClass,
  getGradeIconClassNar,
  SUCCESS,
} from "../../assets/js/common";
import {
  getJSON,
  getUserId,
  yearDiv,
  monthDiv,
  dayDiv,
  dayWeek,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  RACEPATH,
  DATAPATH,
  BACKWORDPATH,
} from "../../assets/js/define";
import moment from "moment";
export default {
  data() {
    return {
      schedule: null,
      isWeekOpen: [],
      specials: null,
      isSpecialOpen: [],
      isJra: null
    };
  },
  methods: {
    handleThisToggle(index, kind) {
      kind === 'week' ?
          this.isWeekOpen.splice(index, 1, !this.isWeekOpen[index]) :
          this.isSpecialOpen.splice(index, 1, !this.isSpecialOpen[index]) ;
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
  },
  mounted() {
    let self = this;
    self.isJra = isNar() === 0

    //開催場所
    const kaisaijo = {
      "01": "札幌",
      "02": "函館",
      "03": "福島",
      "04": "新潟",
      "05": "東京",
      "06": "中山",
      "07": "中京",
      "08": "京都",
      "09": "阪神",
      10: "小倉",
      30: "門別",
      31: "北見",
      32: "岩見",
      33: "帯広",
      34: "旭川",
      35: "盛岡",
      36: "水沢",
      37: "上山",
      38: "三条",
      39: "足利",
      40: "宇都",
      41: "高崎",
      42: "浦和",
      43: "船橋",
      44: "大井",
      45: "川崎",
      46: "金沢",
      47: "笠松",
      48: "名古屋",
      49: "紀三",
      50: "園田",
      51: "姫路",
      52: "益田",
      53: "福山",
      54: "高知",
      55: "佐賀",
      56: "荒尾",
      57: "中津",
      58: "札幌",
      59: "函館",
      60: "新潟",
      61: "中京",
    };

    const ACCIDENT_LBL = {
      1: "取消",
      2: "除外",
      3: "除外",
      4: "中止",
      5: "失格",
    };
    //出走確定
    setTimeout(function () {
      weeklyRacing("5");
    }, 1000);
    if (self.isJra) {
      specialEntryInfo("5");
    }

    async function weeklyRacing(viewKbn) {
      if (viewKbn === undefined || viewKbn == null) {
        viewKbn = "";
      }
      const request =
        HOST +
        PATH +
        "Master/weeklyRacingUpdateAPI.js" +
        BACKWORDPATH +
        "?ViewKbn=" +
        viewKbn +
        "&UserId=" +
        encodeURIComponent(getUserId()) +
        "&NarFlag=" +
        isNar();
      let messageFlag = true;
      await getJSON(request, messageFlag, async function (status, json) {
        if (status === SUCCESS) {
          let e = 1;
          let allSaddle = 0;
          let schedule = []
          if (json[0] != undefined && json[0] != null) {
            schedule = json[0].schedule;

            for (let i in json[0].schedule) {
              const useData = json[0].schedule[i];
              let oddsWishLst = []; //オッズ取得用のリストの初期化

              //開催日整形
              let date = useData.dor;
              let year = yearDiv(date);
              let month = monthDiv(date);
              let day = dayDiv(date);
              let dateTag = dayWeek(year, month, day);
              if (dateTag == "土") {
                dateTag = '<span class="_sat">' + dateTag + "</span>";
              } else if (dateTag == "日") {
                dateTag = '<span class="_sun">' + dateTag + "</span>";
              }

              //スケジュールの詳細
              if (
                "detail" in useData &&
                useData.detail != null &&
                useData.detail.length > 0
              ) {

                allSaddle += useData.scheduleCounter;

                useData.detail.sort(sort_by("raceNum", false, parseInt));

                for (let k in useData.detail) {
                  let scheduleDetail = useData.detail[k];

                  let icon = "";
                  if (isNar() === 1) {
                    icon = getGradeIconClassNar(scheduleDetail.gradeCd, scheduleDetail.raceName)
                  } else {
                    if (scheduleDetail.specialFlg) {
                      icon = getGradeIconClass(
                        scheduleDetail.gradeCd,
                        scheduleDetail.trackTypeCd,
                        scheduleDetail.raceRegulationCd
                      )
                    }
                  }

                  //詳細表示
                  let raceNum = "--";
                  if (
                    "raceNum" in scheduleDetail &&
                    scheduleDetail.raceNum != null
                  ) {
                    raceNum = scheduleDetail.raceNum * 1;
                  }

                  //20190904 発送時刻を追加
                  let startDate = "--";
                  if (
                    "startDate" in scheduleDetail &&
                    scheduleDetail.startDate != null
                  ) {
                    startDate =
                      scheduleDetail.startDate.substr(0, 2) +
                      ":" +
                      scheduleDetail.startDate.substr(2, 2);
                  }

                  //レース名→出馬表に遷移
                  let raceName = "――";
                  if (
                    "raceName" in scheduleDetail &&
                    scheduleDetail.raceName != null
                  ) {
                    raceName = scheduleDetail.raceName;
                  }
                  if (
                    "dor" in useData &&
                    useData.dor != null &&
                    "trackCd" in useData &&
                    useData.trackCd != null &&
                    "raceNum" in scheduleDetail &&
                    scheduleDetail.raceNum != null
                  ) {
                    const toCandidacy =
                      "/" +
                      RACEPATH +
                      "card?DOR=" +
                      useData.dor +
                      "&RacetrackCd=" +
                      useData.trackCd +
                      "&RaceNum=" +
                      scheduleDetail.raceNum;
                    raceName =
                      '<router-link to="' + toCandidacy + '">' + raceName + "</router-link>";
                  }
                  raceName = shallowRef({
                    template: "<span>" + raceName + "</span>"
                  });
                  //馬名→競走馬データに遷移
                  let horseName = "――";
                  if (
                    "horseName" in scheduleDetail &&
                    scheduleDetail.horseName != null
                  ) {
                    horseName = scheduleDetail.horseName;
                  }
                  if (
                    "pedigreeNum" in scheduleDetail &&
                    scheduleDetail.pedigreeNum != null
                  ) {
                    const toHorseData =
                      "/" +
                      DATAPATH +
                      "horse?PedigreeNum=" +
                      scheduleDetail.pedigreeNum;
                    horseName = '<router-link to="' + toHorseData + '">' + horseName + "</router-link>"
                  }
                  horseName = shallowRef({
                    template: "<span>" + horseName + "</span>"
                  });
                  //着順→レース結果に遷移
                  let ranking = "--";
                  if (
                    "ranking" in scheduleDetail &&
                    scheduleDetail.ranking != null
                  ) {
                    if (
                      "accidentCd" in scheduleDetail &&
                      scheduleDetail.accidentCd != null &&
                      /^(1|2|3|4|5)/.test(scheduleDetail.accidentCd)
                    ) {
                      ranking = ACCIDENT_LBL[scheduleDetail.accidentCd];
                    } else {
                      ranking = String(scheduleDetail.ranking) + "着";
                    }
                  }
                  if (ranking != "--") {
                    if (
                      "dor" in useData &&
                      useData.dor != null &&
                      "trackCd" in useData &&
                      useData.trackCd != null &&
                      "raceNum" in scheduleDetail &&
                      scheduleDetail.raceNum != null
                    ) {
                      const toRaceResult =
                        "/" +
                        RACEPATH +
                        "results?DOR=" +
                        useData.dor +
                        "&RacetrackCd=" +
                        useData.trackCd +
                        "&RaceNum=" +
                        scheduleDetail.raceNum;
                      ranking = '<router-link to="' + toRaceResult + '">' + ranking + "</router-link>"
                    }
                  }
                  ranking = shallowRef({
                    template: "<span>" + ranking + "</span>"
                  });

                  let horseNum = e;
                  if (
                    "horseNum" in scheduleDetail &&
                    scheduleDetail.horseNum != null
                  ) {
                    horseNum = scheduleDetail.horseNum;
                  }
                  //20190904 発送時刻追加
                  const mHtml =
                    '<tr id="entry_data' +
                    i +
                    '">' +
                    "<td>" +
                    raceNum +
                    'R<br><span class="red">' +
                    startDate +
                    "</span></td><td>" +
                    raceName +
                    icon +
                    "</td>" +
                    "<td>" +
                    horseName +
                    '</td><td id="Odds' +
                    scheduleDetail.pedigreeNum +
                    "_" +
                    date +
                    '"></td>' +
                    "<td>" +
                    ranking +
                    "</td>" +
                    "</tr>";
                  e++;

                  //オッズ・人気表示用リストアイテムをセット
                  oddsWishLst.push({
                    DOR: useData.dor,
                    RacetrackCd: useData.trackCd,
                    RaceNum: raceNum,
                    HorseNum: horseNum,
                    PedigreeNum: scheduleDetail.pedigreeNum,
                  });

                  scheduleDetail.startDate = startDate;
                  scheduleDetail.icon = icon;
                  scheduleDetail.ranking = ranking;
                  scheduleDetail.raceNum = raceNum;
                  scheduleDetail.raceName = raceName;
                  scheduleDetail.horseName = horseName;
                }
              }

              //最後にオッズなどを表示させる
              let singleOdds = await setOdds(oddsWishLst);

              for (let o in useData.detail) {
                let scheduleDetail = useData.detail[o];
                let raceNum = scheduleDetail.raceNum
                raceNum = raceNum > 9 ? String(raceNum) : '0' + String(raceNum)
                const key = String(useData.dor) + String(useData.trackCd) + raceNum

                scheduleDetail.oddsHtml = singleOdds.some(val => val.key === key && val.pedigreeNum === scheduleDetail.pedigreeNum) ?
                    singleOdds.find(val => val.key === key && val.pedigreeNum === scheduleDetail.pedigreeNum).mHtml : '';
              }

              schedule[i].year = year;
              schedule[i].month = month;
              schedule[i].day = day;
              schedule[i].displayPlace = kaisaijo[useData.trackCd];
              schedule[i].dateTag = dateTag;
              schedule[i].scheduleCounter = useData.scheduleCounter;
            }
          }
          schedule.allSaddle = allSaddle;
          self.schedule = schedule;

          self.isWeekOpen = Array(self.schedule.length).fill(true);

        }
      });
    }

    async function specialEntryInfo(viewKbn) {
      const request =
          HOST +
          PATH +
          "Master/getSpecialEntryInfoAPI.js" +
          BACKWORDPATH +
          "?UserId=" +
          encodeURIComponent(getUserId()) +
          "&NarFlag=" +
          isNar() +
          "&ViewKbn=" +
          viewKbn;
      let messageFlag = true;
      await getJSON(request, messageFlag, async function (status, json) {
        if (status === SUCCESS) {
          let allSaddle = 0;
          let schedule = []
          if (json[0] != undefined && json[0] != null) {
            schedule = json[0].schedule;

            for (let i in json[0].schedule) {
              const useData = json[0].schedule[i];

              //開催日整形
              let date = useData.dor;
              let year = yearDiv(date);
              let month = monthDiv(date);
              let day = dayDiv(date);
              let dateTag = dayWeek(year, month, day);
              if (dateTag == "土") {
                dateTag = '<span class="_sat">' + dateTag + "</span>";
              } else if (dateTag == "日") {
                dateTag = '<span class="_sun">' + dateTag + "</span>";
              }

              //スケジュールの詳細
              if (
                  "detail" in useData &&
                  useData.detail != null &&
                  useData.detail.length > 0
              ) {

                allSaddle += useData.scheduleCounter;

                useData.detail.sort(sort_by("raceNum", false, parseInt));

                for (let k in useData.detail) {
                  let scheduleDetail = useData.detail[k];

                  let icon = "";
                  if (isNar() === 1) {
                    icon = getGradeIconClassNar(scheduleDetail.gradeCd, scheduleDetail.raceName)
                  } else {
                    if (scheduleDetail.specialFlg) {
                      icon = getGradeIconClass(
                        scheduleDetail.gradeCd,
                        scheduleDetail.trackTypeCd,
                        scheduleDetail.raceRegulationCd
                      )
                    }
                  }

                  //詳細表示
                  let raceNum = "--";
                  if (
                      "raceNum" in scheduleDetail &&
                      scheduleDetail.raceNum != null
                  ) {
                    raceNum = scheduleDetail.raceNum * 1;
                  }

                  //レース名→出馬表に遷移
                  let raceName = "――";
                  if (
                      "raceName" in scheduleDetail &&
                      scheduleDetail.raceName != null
                  ) {
                    raceName = scheduleDetail.raceName;
                  }
                  if (
                      "dor" in useData &&
                      useData.dor != null &&
                      "trackCd" in useData &&
                      useData.trackCd != null &&
                      "raceNum" in scheduleDetail &&
                      scheduleDetail.raceNum != null
                  ) {
                    const toCandidacy =
                        "/" +
                        RACEPATH +
                        "card?DOR=" +
                        useData.dor +
                        "&RacetrackCd=" +
                        useData.trackCd +
                        "&RaceNum=" +
                        scheduleDetail.raceNum;
                    raceName =
                      '<router-link to="' + toCandidacy + '">' + raceName + "</router-link>";
                  }
                  raceName = shallowRef({
                    template: "<span>" + raceName + "</span>"
                  });
                  //馬名→競走馬データに遷移
                  let horseName = "――";
                  if (
                      "horseName" in scheduleDetail &&
                      scheduleDetail.horseName != null
                  ) {
                    horseName = scheduleDetail.horseName;
                  }
                  if (
                      "pedigreeNum" in scheduleDetail &&
                      scheduleDetail.pedigreeNum != null
                  ) {
                    const toHorseData =
                        HOST +
                        DATAPATH +
                        "horse?PedigreeNum=" +
                        scheduleDetail.pedigreeNum;
                    horseName =
                      '<router-link to="' + toHorseData + '">' + horseName + "</router-link>";
                  }
                  horseName = shallowRef({
                    template: "<span>" + horseName + "</span>"
                  });

                  scheduleDetail.icon = icon;
                  scheduleDetail.raceNum = raceNum;
                  scheduleDetail.raceName = raceName;
                  scheduleDetail.horseName = horseName;
                }
              }

              schedule[i].year = year;
              schedule[i].month = month;
              schedule[i].day = day;
              schedule[i].displayPlace = kaisaijo[useData.trackCd];
              schedule[i].dateTag = dateTag;
              schedule[i].scheduleCounter = useData.scheduleCounter;
            }
          }
          schedule.allSaddle = allSaddle;
          self.specials = schedule;

          self.isSpecialOpen = Array(self.specials.length).fill(true);
        }
      });
    }

    //今週のxxxのオッズ、人気欄を設定します
    async function setOdds(oddsWishLst) {
      if (oddsWishLst.length == 0) {
        return;
      } //データがなければ終了

      let lstObj = {};
      for (let i = 0; i < oddsWishLst.length; i++) {
        let mKey =
          oddsWishLst[i].DOR +
          ("0" + oddsWishLst[i].RacetrackCd).slice(-2) +
          ("0" + oddsWishLst[i].RaceNum).slice(-2);
        if (mKey in lstObj) {
          lstObj[mKey].push(oddsWishLst[i]);
        } else {
          lstObj[mKey] = [oddsWishLst[i]];
        }
      }
      let strLst = "";
      for (let key in lstObj) {
        if (strLst === "") {
          strLst += key;
        } else {
          strLst += "_" + key;
        }
      }

      let oddsHtml = []; //オッズのreturn用配列
      const req =
        HOST + PATH + "Master/getOddsAiO.js" + BACKWORDPATH + "?lst=" + strLst + "&narFlag=" + isNar();
      await getJSON(req, true, function (status, json) {
        if (status == SUCCESS) {
          if (json != null) {
            //検索結果がある場合のみ実行

            for (let key in lstObj) {
              let dor = key.slice(0, 8);
              let raceNum = key.slice(-2);

              if (
                "o" + key in json &&
                json["o" + key] != null &&
                json["o" + key].length > 0
              ) {
                let oddsLst = json["o" + key][0];

                for (let j = 0; j < lstObj[key].length; j++) {
                  let horseNum = lstObj[key][j].HorseNum;
                  let pedigreeNum = lstObj[key][j].PedigreeNum;
                  let mOdds = "---.-";
                  let mOrder = "--";
                  let mClass = "";

                  if (oddsLst["Odds" + horseNum * 1] && oddsLst["Odds" + horseNum * 1] !== '0.0') {
                    mOdds = oddsLst["Odds" + horseNum * 1]
                  }

                  if (mOdds !== "---.-") {
                    mOrder = 1;
                    if (mOdds * 1 < 10) {
                      mClass = ' style="color:red;"';
                    } else if (mOdds * 1 < 100) {
                      mClass = ' style="color:blue;"';
                    }
                    for (let i = 0; i < 18; i++) {
                      if (i != horseNum * 1 - 1) {
                        if (oddsLst["Odds" + (i + 1)] === "---.-") {
                          continue;
                        }
                        if (oddsLst["Odds" + (i + 1)] * 1 < mOdds * 1) {
                          mOrder += 1;
                        }
                      }
                    }
                  }

                  const mHtml =
                    "<span" +
                    mClass +
                    ">" +
                    mOdds +
                    "倍</span><br>" +
                    mOrder +
                    "人気";

                  oddsHtml.push({
                    mHtml: mHtml,
                    key: key,
                    pedigreeNum: pedigreeNum
                  });
                }
              }
            }
          }
        }
      });
      return oddsHtml;
    }

    //スケジュール詳細をレース番号でソート
    let sort_by = function (field, reverse, primer) {
      reverse = reverse ? -1 : 1;
      return function (a, b) {
        a = a[field] * 10;
        b = b[field] * 10;
        if (typeof primer != "undefined") {
          a = primer(a);
          b = primer(b);
        }
        if (a < b) return reverse * -1;
        if (a > b) return reverse * 1;
      };
    };
  },
};
</script>

<style lang="scss" src="../../assets/css/fav/style.scss" scoped></style>
