<script setup>
import { ref, reactive, onMounted } from "vue";
import {HOST, JRA_WP_HOST, POGPATH} from "../../../assets/js/define";
import axios from "axios";

//本番用 APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=pog-index";

const POG_SEARCH_HORSE = "pog_racehorse";
const POG_SEARCH_TRAINER = "pog_trainer";
const POG_SEARCH_BREEDER = "pog_breeder";
const POG_SEARCH_STUD = "pog_stud";
const POG_SEARCH_MARE = "pog_mare";
const POG_SEARCH_GRANDPA = "pog_bms";

//初期表示
const props = defineProps(["name"]);
const name = props.name;
const checkedName = ref(name);

const formText = ref("");
const formSelect = ref(0);
const searchOption = ref(checkedName);

const searchOptionChange = (data) => {
  searchOption.value = data;
};

//検索ボタン押下
const searchPogSubmit = (type) => {
  // 遷移先URL作成
  let url = getPogURL(type);

  // パラメータ作成
  const param = getPogParam(type);

  if (param != "") {
    url += param;
  }

  // 遷移
  window.location.href = url;
};

function getPogURL(type) {
  let str = HOST;
  switch (type) {
    case POG_SEARCH_HORSE:
      str += POGPATH + "search_list?ScreenDiv=0";
      break;
    case POG_SEARCH_TRAINER:
      str += POGPATH + "search_trainer?ScreenDiv=1";
      break;
    case POG_SEARCH_BREEDER:
      str += POGPATH + "search_breeder?ScreenDiv=3";
      break;
    case POG_SEARCH_STUD:
      str += POGPATH + "search_studlist?ScreenDiv=4";
      break;
    case POG_SEARCH_MARE:
      str += POGPATH + "search_marelist?ScreenDiv=5";
      break;
    case POG_SEARCH_GRANDPA:
      str += POGPATH + "search_bms?ScreenDiv=6";
      break;
  }
  return str;
}

function getPogParam(type) {
  let str = "&";
  let option = "0";
  switch (type) {
    //馬名検索
    case POG_SEARCH_HORSE:
      // キーワード
      if (formText.value != undefined) {
        str += "Word=" + formText.value;
      }
      // オプション
      if (formSelect.value != undefined) {
        option = formSelect.value;
      }
      if (str != "") {
        str += "&";
      }
      str += "Option=" + option;
      break;
    //調教師・生産者検索
    case POG_SEARCH_TRAINER:
    case POG_SEARCH_BREEDER:
      // キーワード
      if (formText.value != undefined) {
        str += "keyword=" + formText.value;
      }
      // オプション
      if (formSelect.value != undefined) {
        option = formSelect.value;
      }
      if (str != "") {
        str += "&";
      }
      str += "searchOption=" + option;
      break;
    //種牡馬検索
    case POG_SEARCH_STUD:
      // キーワード
      if (formText.value != undefined) {
        str += "keyword=" + formText.value;
      }
      //画面区分
      if (str != "") {
        str += "&";
      }
      str += "searchType=0";
      // オプション
      if (formSelect.value != undefined) {
        option = formSelect.value;
      }
      if (str != "") {
        str += "&";
      }
      str += "searchOption=" + option;
      break;
    //繁殖牝馬検索
    case POG_SEARCH_MARE:
      // キーワード
      if (formText.value != undefined) {
        str += "keyword=" + formText.value;
      }
      //画面区分
      if (str != "") {
        str += "&";
      }
      str += "searchType=1";
      //オプション
      if (formSelect.value != undefined) {
        option = formSelect.value;
      }
      if (str != "") {
        str += "&";
      }
      str += "searchOption=" + option;
      break;

    //母の父検索
    case POG_SEARCH_GRANDPA:
      //キーワード
      if (formText.value != undefined) {
        str += "keyword=" + formText.value;
      }
      //オプション
      if (formSelect.value != undefined) {
        option = formSelect.value;
      }
      if (str != "") {
        str += "&";
      }
      str += "searchOption=" + option;
      break;
  }

  return str;
}

let list = reactive({});
onMounted(() => {
  const request_url = API_URL;
  axios
    .get(request_url)
    .then((response) => {
      Object.assign(list, response.data[0].acf.keyword);
    })
    .catch((error) => {
      console.log(error);
    });
});
</script>

<template>
  <div>
    <section class="layout-section">
      <div class="search-container">
        <h3 class="heading-item">POG 検索</h3>
        <div class="block -radios">
          <div class="-kind">
            <div class="grid -cols3">
              <div class="form-radiogroup">
                <label
                  ><input
                    @click="searchOptionChange('pog_racehorse')"
                    type="radio"
                    name="cat"
                    value="pog_racehorse"
                    v-model="checkedName"
                  /><span>馬名</span></label
                >
              </div>
              <div class="form-radiogroup">
                <label
                  ><input
                    @click="searchOptionChange('pog_stud')"
                    type="radio"
                    name="cat"
                    value="pog_stud"
                    v-model="checkedName"
                  /><span>種牡馬</span></label
                >
              </div>
              <div class="form-radiogroup">
                <label
                  ><input
                    @click="searchOptionChange('pog_mare')"
                    type="radio"
                    name="cat"
                    value="pog_mare"
                    v-model="checkedName"
                  /><span>繁殖牝馬</span></label
                >
              </div>
              <div class="form-radiogroup">
                <label
                  ><input
                    @click="searchOptionChange('pog_bms')"
                    type="radio"
                    name="cat"
                    value="pog_bms"
                    v-model="checkedName"
                  /><span>母の父</span></label
                >
              </div>
              <div class="form-radiogroup">
                <label
                  ><input
                    @click="searchOptionChange('pog_trainer')"
                    type="radio"
                    name="cat"
                    value="pog_trainer"
                    v-model="checkedName"
                  /><span>調教師</span></label
                >
              </div>
              <div class="form-radiogroup">
                <label
                  ><input
                    @click="searchOptionChange('pog_breeder')"
                    type="radio"
                    name="cat"
                    value="pog_breeder"
                    v-model="checkedName"
                  /><span>生産者</span></label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="block">
          <div class="form-select">
            <select v-model="formSelect" name="">
              <option value="0" selected="">部分一致</option>
              <option value="1">前方一致</option>
              <option value="2">後方一致</option>
            </select>
          </div>
        </div>

        <div class="block search-nav">
          <input v-model="formText" type="text" class="form-text" /><button
            type="submit"
            name=""
            class="btn"
            @click="searchPogSubmit(searchOption)"
          ></button>
        </div>
      </div>
    </section>
    <div
      v-if="checkedName === 'pog_stud' && Object.keys(list).length > 0"
      class="search-container"
    >
      <h3 class="heading-item">人気の種牡馬</h3>
      <ul class="keyword">
        <li v-for="(popular, index) in list" :key="index">
          <a
            :href="
              HOST +
              POGPATH +
              'search_studlist?ScreenDiv=4&keyword=' +
              popular.word +
              '&searchType=0&searchOption=0'
            "
            class="btn-basic"
            >{{ popular.word }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
