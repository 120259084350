<script setup>
import { ref, reactive, onMounted } from "vue";
import { HOST, PATH, BACKWORDPATH, POGAPIPATH } from "../../assets/js/define";
import {
  getJSON,
  SUCCESS,
  yearDiv,
  monthDiv,
  dayDiv,
  getUrlVars,
  getUserId,
  setCookie,
  POGUSER,
  USERTYPE,
  PAYINGNONE,
} from "../../assets/js/common";
import PogHeader from "../../components/pog/PogHeader.vue";
import pogFunc from "../../functions/pog.js";
const { loadPogUserJudge } = pogFunc();

const registerStatus = ref("");
const message = ref("");
const birthDay = ref("");

const grade = reactive([]);
const isPogRegisterShow = ref(false);
const modalOpen = ref(false);
let params = ref({});
let userId = ref("");

const setPogRegisterShow = () => {
  isPogRegisterShow.value = isPogRegisterShow.value ? false : true;
};

let horseData = reactive({});
function getPogHorseDetail(PedigreeNum, callback) {
  const ACCIDENT_LBL = {
    1: "取消",
    2: "除外",
    3: "除外",
    4: "中止",
    5: "失格",
  };
  const req =
    HOST +
    PATH +
    "POG/getPogHorseDetail.js" +
    BACKWORDPATH +
    "?PedigreeNum=" +
    PedigreeNum;
  getJSON(req, true, function (status, json) {
    if (status === SUCCESS) {
      try {
        Object.assign(horseData, json);
        //成績
        if (json.RaceResult) {
          //レース日降順で並べ替え
          let a;
          let b;
          json.RaceResult.sort(function (a, b) {
            a = a["DOR"];
            b = b["DOR"];
            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
          });

          for (let i = 0; i < json.RaceResult.length; i++) {
            let useResult = json.RaceResult[i];
            let day = useResult.DOR;
            let hiduke = yearDiv(day) + "." + monthDiv(day) + "." + dayDiv(day);

            //レース名
            let raceName = "――";
            if (
              "RaceName" in useResult &&
              useResult.RaceName !== null &&
              useResult.RaceName !== ""
            ) {
              raceName = useResult.RaceName;
            }
            //着順
            let racePlace = "-";
            if (
              "RaceResult" in useResult &&
              useResult.RaceResult !== null &&
              useResult.RaceResult !== ""
            ) {
              if (/[1-5]/.test(useResult.AccidentCd)) {
                racePlace = ACCIDENT_LBL[useResult.AccidentCd];
              } else {
                racePlace = useResult.RaceResult * 1;
              }
            }
            //ポイント
            let point = 0;
            if ("Point" in useResult && useResult.Point !== null) {
              point = useResult.Point;
            }

            const gradeComp = {
              hiduke,
              raceName,
              racePlace,
              point,
            };
            grade.push(gradeComp);
          }
        }
        //生年月日
        if (horseData.DOB) {
          birthDay.value =
            horseData.DOB || ""
              ? (birthDay.value = `${horseData.DOB.substring(0, 4)}年${Number(
                  horseData.DOB.substring(4, 6)
                )}月${Number(horseData.DOB.substring(6, 8))}日`)
              : "";
        }
        return callback();
      } catch (e) {
        alert("getPogHorseDetail : " + e);
      }
    }
  });
}
function setPogHorse() {
  document.body.className = "";
  modalOpen.value = true;
  const request =
    HOST +
    POGAPIPATH +
    "setPogHorse.js" +
    BACKWORDPATH +
    "?UserId=" +
    userId +
    "&PedigreeNum=" +
    params.PedigreeNum;
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      try {
        if (json !== undefined && json !== null && json.length === 0) {
          //成功
          registerStatus.value = "登録完了";
          message.value = horseData.HorseName + "を登録しました。";
        } else {
          registerStatus.value = "登録失敗";
          message.value =
            "この馬はすでに登録されているか、登録上限を超えているため登録できませんでした。";
        }
      } catch (e) {
        alert("setPogHorse : " + e);
      }
    }
    document.body.className = "status-loaded";
  });
}
const modalClose = () => {
  modalOpen.value = false;
  isPogRegisterShow.value = false;
};

onMounted(() => {
  document.body.className = "";
  loadPogUserJudge(() => {
    params = getUrlVars();
    userId = getUserId();
    getPogHorseDetail(params.PedigreeNum, function () {
      document.body.className = "status-loaded";
    });
  });
});
</script>

<template>
  <main class="layout-main">
    <PogHeader />

    <section class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">POG 馬詳細</h2>
      </div>

      <div class="container-box">
        <div v-if="isPogRegisterShow" class="container-box">
          <div class="inner">
            <p class="p-message -normargin -center">
              <em>この馬をPOG馬として登録しますか?</em>
            </p>
            <p class="p-caption -normargin -center -caution">
              ※一度登録した馬は変更できません
            </p>

            <div class="nav-bool">
              <span class="btn-basic -gray" @click="setPogRegisterShow()"
                >キャンセル</span
              >
              <span
                class="btn-basic -addpog"
                @click="setPogHorse()"
                >登録</span
              >
            </div>
          </div>
        </div>
        <div class="inner">
          <h2 class="heading-circle">
            馬名: {{ horseData.HorseName
            }}<span
              v-if="horseData.RacehorseDiv"
              class="icon-status -erase"
            ></span>
          </h2>
          <table class="table-grid -data">
            <tbody>
              <tr>
                <th>性別</th>
                <td>{{ horseData.Sex }}</td>
              </tr>
              <tr>
                <th>毛色</th>
                <td>{{ horseData.HairColor }}</td>
              </tr>
              <tr>
                <th>生年月日</th>
                <td>
                  {{ birthDay }}
                </td>
              </tr>
              <tr>
                <th>父</th>
                <td>{{ horseData.FatherName }}</td>
              </tr>
              <tr>
                <th>母</th>
                <td>{{ horseData.MotherName }}</td>
              </tr>
              <tr>
                <th>母父</th>
                <td>{{ horseData.MotherFatherName }}</td>
              </tr>
              <tr>
                <th>生産者</th>
                <td>{{ horseData.BreederName }}</td>
              </tr>
              <tr>
                <th>調教師</th>
                <td>{{ horseData.TrainerName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="inner">
          <h2 class="heading-circle">成績</h2>
          <table class="table-grid -data" v-if="grade.length > 0">
            <thead>
              <tr class="-center">
                <th class="-date">日付</th>
                <th class="-race">レース</th>
                <th class="-ranking">着順</th>
                <th class="-point">ポイント</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in grade">
                <td class="-date">{{ item.hiduke }}</td>
                <td class="-race">{{ item.raceName }}</td>
                <td class="-ranking">{{ item.racePlace }}</td>
                <td class="-point">{{ item.point }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else>成績はありません。</div>
        </div>
        <div
          v-if="
            !isPogRegisterShow &&
            1 !== horseData.EnableFlag &&
            0 === horseData.RacehorseDiv
          "
          class="inner"
        >
          <h2 class="heading-circle">POG登録</h2>
          <a class="btn-basic -wide -center" @click="setPogRegisterShow()"
            >POG登録</a
          >
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>

  <teleport to="body">
    <div class="status-modal" v-if="modalOpen">
      <div class="modal-layer"></div>
      <div class="modal-frame">
        <div class="modal-inner">
          <div class="modal-content">
            <div class="modal-title heading-bar" style="display: block">
              {{ registerStatus }}
            </div>
            <div class="modal-body">
              <p>{{ message }}</p>
              <div class="modal-nav">
                <span @click="modalClose()" class="btn-basic -gray close-modal"
                  >閉じる</span
                >
              </div>
            </div>
          </div>
          <span @click="modalClose()" class="modal-close"></span>
        </div>
      </div>
    </div>
  </teleport>
</template>
