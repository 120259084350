<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">固め打ち騎手一覧</h1>
    </section>

    <section class="layout-section" v-if="jockeyData && loadedPage">
      <table class="table-grid -fit -padding">
        <tbody v-for="(data, index) in jockeyData" :key="index">
          <tr>
            <th colspan="3">
              <a :href="data.urlLink">{{ data.JockeyName }}</a>
            </th>
          </tr>
          <tr
            class="-center"
            v-for="(content, key) in data.Contents"
            :key="key"
          >
            <td>{{ content.Racetrack }}{{ content.RaceNum }}R</td>
            <td>
              単勝{{ content.Refund }}円（{{ content.WinPopularRank }}番人気）
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <div v-else-if="loadedPage" class="mt10 box5">
      <p>2勝以上の騎手はいません。</p>
    </div>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import { getJSON, SUCCESS, isNar } from "../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  RACEINFOPATH,
  DATAPATH,
} from "../../assets/js/define";
export default {
  data() {
    return {
      jockeyData: null,
      loadedPage: false,
    };
  },
  mounted() {
    const self = this;

    const mDate = new Date();
    // 土曜の19:30以降は、翌日のデータを取得するため、DORに翌日の日にちを設定する
    if (mDate.getDay() == 6) {
      // 土曜(6）であるか
      // if ( mDate.getDay() == 6 || mDate.getDay() == 0 ) { // 土曜(6）or日曜(0）であるか ※３三日間開催用
      // if ( mDate.getDay() == 5 || mDate.getDay() == 6 ) { // 金曜(5）or土曜(6）であるか ※年末３三日間開催用 ※三日間対応（金土日）
      if (mDate.getHours() * 60 + mDate.getMinutes() >= 1170) {
        // 19:30以降であるか判定(19*60+30 1170)
        mDate.setDate(mDate.getDate() + 1);
      }
    }
    const toDate =
      mDate.getFullYear() +
      ("0" + (mDate.getMonth() + 1)).slice(-2) +
      ("0" + mDate.getDate()).slice(-2);

    //オッズ一覧の表示
    displayOdds(toDate);

    //オッズ一覧の表示
    function displayOdds(dateLstStr) {
      const req =
        HOST +
        RACEINFOPATH +
        "getMultiWinners.js" +
        BACKWORDPATH +
        "?dor=" +
        dateLstStr +
        "&narFlag=" +
        isNar();
      document.body.className = "";
      //defaultオッズ一覧を取得～描画
      getJSON(req, true, function (status, json) {
        if (status == SUCCESS) {
          let data = json;

          let keys = Object.keys(data);
          let key = keys[0];
          if (keys.length) {
            for (let i = 0; i < data[key].length; i++) {
              let race = data[key][i];

              //レース結果用URL
              race.urlLink =
                HOST + DATAPATH + "jockey?JockeyCd=" + race.JockeyCd;

              for (let j = 0; j < race.Contents.length; j++) {
                let content = race.Contents[j];

                //金額をカンマ区切りで整形
                content.Refund = content.Refund.toLocaleString();

                //レースナンバーの修正
                content.RaceNum = Number(content.RaceNum);
              }
            }
            self.jockeyData = data[key];
            document.body.className = "status-loaded";
            self.loadedPage = true;
            return;
          }
        }
        document.body.className = "status-loaded";
        self.loadedPage = true;
      });
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/info/style.scss" scoped></style>
