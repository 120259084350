<script setup>
import { ref, reactive, onMounted, nextTick } from "vue";
import {
  getUrlVars,
  getJSON,
  SUCCESS,
  isFavorite,
  isNextTarget,
  dayWeek,
  retrieveCourse,
  FRACEHORSE,
  FJOCKEY,
  FTRAINER,
  TRACEHORSE,
  PAYINGPREMIUM,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  PAYINGAPPPASS,
  isNar,
  getUserId,
  getGradeIconClass,
  getGradeIconClassNar,
} from "../../assets/js/common";

import {
  HOST,
  PATH,
  MEDIAPATH,
  BACKWORDPATH,
  RACEINFOPATH,
} from "../../assets/js/define";
import moment from "moment";
import ShowMovie from "../../components/utils/ShowMovie.vue";
import ShowMovieConfirm from "../../components/utils/ShowMovieConfirm.vue";

let raceLists = reactive([]);
let params = reactive({});
let toDay = ref("");
let thisWeek = ref("");
const todayRaceFlag = ref(false);
let DOR = ref("");
const scrollGuid = ref(true);
const payoutURL = ref("");
const loadedPage = ref(false);
let initialDisplay = ref(true);
let raceLength = ref(0);
let displayScroll =  ref(false);
const pedigreeNums = ref([]);

//JRA用
const setDOR = ref(""); //現在のDOR
const setRacetrack = ref(""); //現在の競馬場

const narFlag = ref(0);

onMounted(() => {
  //パラメータ取得
  Object.assign(params, getUrlVars());
  narFlag.value = isNar()
  getMyNextTargetRacehorse();
  if (isNar() === 0) {
    //JRAのみ
    getRaceDay(function () {
      loadRaceHeader(DOR.value, setRacetrack.value, function () {
        raceList(setDOR.value, setRacetrack.value);
      });
    })
  } else {
    //NARのみ
    doInit(params);
  }
});

const getMyNextTargetRacehorse = () => {
  let userId = getUserId();
  let request =
    HOST +
    PATH +
    "MyNextTarget/getMyNextTargetRacehorse.js" +
    BACKWORDPATH +
    "?userID=" + encodeURIComponent(userId)
    getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      if(json){
        pedigreeNums.value = json;
      }
    }
  });
}

// スクロールアイコンの制御
const displayGuid = () => {
  const grid = document.getElementById("scrollGrid");
  displayScroll.value = (window.innerWidth - raceLength.value * 90) < 0
  if (grid) {
    const currentList = document.querySelector('#scrollGrid > ul > li.-current');
    const currentPosition = currentList.getBoundingClientRect();
    grid.scrollLeft += currentPosition.left

    grid.addEventListener("scroll", () => {
      scrollGuid.value = document.getElementById("scrollGrid").scrollLeft < 10
    });
  }
}

const getRaceDay = (callback) => {
  if (params.DOR && params.RacetrackCd) {
    DOR.value = params.DOR
    setDOR.value = params.DOR
    setRacetrack.value = params.RacetrackCd
    callback();
  } else {
    const request =
        HOST +
        RACEINFOPATH +
        "getTodayRaceInfo.js" +
        BACKWORDPATH +
        "?narFlag=" +
        isNar();
    try {
      getJSON(request, true, function (status, json) {
        let dor = getDOR(json.TargetDate)
        DOR.value = params.DOR || dor;
        setDOR.value = params.DOR || dor;
        setRacetrack.value = params.RacetrackCd || json.TargetRacetrackCd[0].RacetrackCd;
        callback();
      })
    } catch (e) {
      alert("raceList : " + e);
    }
  }
}

//日付の取得（月〜土曜の場合は土曜のレースを表示、日曜の場合は日曜のレースを表示）
const getDOR = (targetDateArray) => {
  const today = moment().format('YYYYMMDD')
  return targetDateArray.some(val => val.DOR === today) ? today : targetDateArray[0]?.DOR
}

const doInit = async (params) => {
  let dor, raceTrackCd;
  let numMonth, numDay;
  if (params.DOR) {
    todayRaceFlag.value = moment().format('YYYYMMDD') ===  params.DOR;
    //クエリパラメータにレースIDとDORが設定されている場合は、その値を設定する
    DOR.value = params.DOR;
    toDay.value =
      Number(DOR.value.substr(4, 2)) + "/" + Number(DOR.value.substr(6, 2));
    thisWeek.value = dayWeek(
      Number(DOR.value.substr(0, 4)),
      Number(DOR.value.substr(4, 2)),
      Number(DOR.value.substr(6, 2))
    );
    await getSelectDayRaceInfo(DOR.value, params.RacetrackCd);
  } else {
    todayRaceFlag.value = true;
    //クエリパラメータがない場合は本日の日付をDORに変換し設定する
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    toDay.value = numMonth = month;
    if (String(month).length == 1) {
      month = "0" + month;
    } else {
      month = String(month);
    }
    let day = (numDay = today.getDate());
    toDay.value += "/" + day;
    if (String(day).length == 1) {
      day = "0" + day;
    } else {
      day = String(day);
    }
    dor = String(year) + month + day;
    DOR.value = dor;
    thisWeek.value = dayWeek(year, numMonth, numDay);
    await getThisDayRaceInfo(dor);
  }
};

//DOR,レースCdからレース情報を取得（日付指定）
const getSelectDayRaceInfo = (dor, racetrackCd) => {
  const request =
    HOST +
    RACEINFOPATH +
    "getRaceHeader.js" +
    BACKWORDPATH +
    "?dor=" +
    dor +
    "&narFlag=" +
    isNar();
  try {
    getJSON(request, true, function (statusToday, jsonToday) {
      if (statusToday === SUCCESS) {
        if (jsonToday.length > 0) {
          const todayRaceInfo = jsonToday[0].TargetRacetrackCd[dor];
          if (todayRaceInfo) {
            if (isNar() === 1) {
              const sortArray = [44, 45, 43, 42, 30, 31, 32, 34, 35, 36, 37, 37, 38, 38, 40, 41, 46, 47, 48, 49, 50,
                51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 33];
              todayRaceInfo.sort(function(x, y) {
                return sortArray.indexOf(Number(x.RacetrackCd)) - sortArray.indexOf(Number(y.RacetrackCd));
              })
            }
            //指定の日時にレースがある場合は生成
            Object.assign(todayRaceTrackCd, todayRaceInfo);
            raceLength.value = todayRaceInfo.length
            let info = todayRaceInfo.find(
              (info) => info.RacetrackCd === racetrackCd
            );
            if (info) {
              //指定の競走馬のレースが該当日にある場合は生成
              targetRacetrackCd.value = info.RacetrackCd;
              targetRacetrackName.value = info.Racetrack;
              raceList(DOR.value, targetRacetrackCd.value);
            } else {
              document.body.className = "status-loaded";
              loadedPage.value = true;
            }
          }
        }
      }
    });
  } catch (e) {
    console.log("raceList : " + e);
  }
};

//DORからレース名とレース情報を取得（本日指定）
let todayRaceTrackCd = reactive({});
const targetRacetrackCd = ref("");
const targetRacetrackName = ref("");
const getThisDayRaceInfo = (dor) => {
  const request =
    HOST +
    RACEINFOPATH +
    "getThisAndLastWeekRaceInfo.js" +
    BACKWORDPATH +
    "?NarFlag=" +
    isNar();
  try {
    getJSON(request, true, function (statusToday, jsonToday) {
      if (statusToday === SUCCESS) {
        if (jsonToday.length > 0) {
          const todayRaceInfo =
            jsonToday[0].TodayRaceInfo.TargetRacetrackCd[dor];
          if (todayRaceInfo) {
            if (isNar() === 1) {
              const sortArray = [44, 45, 43, 42, 30, 31, 32, 34, 35, 36, 37, 37, 38, 38, 40, 41, 46, 47, 48, 49, 50,
                51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 33];
              todayRaceInfo.sort(function(x, y) {
                return sortArray.indexOf(Number(x.RacetrackCd)) - sortArray.indexOf(Number(y.RacetrackCd));
              })
            }
            raceLength.value = todayRaceInfo.length
            Object.assign(todayRaceTrackCd, todayRaceInfo);
            targetRacetrackCd.value = todayRaceTrackCd[0].RacetrackCd;
            targetRacetrackName.value = todayRaceTrackCd[0].Racetrack;
            raceList(DOR.value, targetRacetrackCd.value);
          } else {
            document.body.className = "status-loaded";
            loadedPage.value = true;
          }
        }
      }
    });
  } catch (e) {
    console.log("raceList : " + e);
  }
};

//表示する競馬場の変更
const changeTrack = (raceTrackCd, raceTrack) => {
  initialDisplay.value = true;
  targetRacetrackCd.value = raceTrackCd;
  targetRacetrackName.value = raceTrack;
  raceList(DOR.value, targetRacetrackCd.value);
};

//表示する日付の変更(JRAのみ)
const changeDOR = (dor) => {
  setDOR.value = dor;
  raceList(setDOR.value, setRacetrack.value);
};

//表示する競馬場の変更(JRAのみ)
const changeTrackJRA = (racetrack) => {
  setRacetrack.value = racetrack;
  raceList(setDOR.value, setRacetrack.value);
};

//レース一覧表示
const raceList = (dor, racetrackCd) => {
  raceLists.splice(0);
  const request =
    HOST +
    PATH +
    "RaceInfo/raceList.js" +
    BACKWORDPATH +
    "?dor=" +
    dor +
    "&racetrackCd=" +
    racetrackCd +
    "&narFlag=" +
    isNar();
  document.body.className = "";
  loadedPage.value = false;
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      try {
        if (json[0] == undefined || json[0] == null) {
          //取得したjsonにデータがなかった場合
        } else {
          //データを差し込むときに対応するリストを表示する。
          for (let i in json) {
            let useData = json[i];

            //グレードタグ付与
            let gradeData = useData.GradeCd;
            let raceName = useData.RaceMainName;
            let finishedFlg = false;
            let gradeIcon = "";
            if (isNar() === 1) {
              gradeIcon = getGradeIconClassNar(gradeData, raceName)
            } else {
              if (useData.isSpRace) {
                gradeIcon = getGradeIconClass(gradeData, useData.TrackTypeCd, useData.RaceRegulationCd)
              }
            }

            //レース時刻前後のフラグ付与（現在時刻で判別）
            const date = new Date();
            const nowDatetime =
              date.getFullYear() +
              ("0" + (date.getMonth() + 1)).slice(-2) +
              ("0" + date.getDate()).slice(-2) +
              ("0" + date.getHours()).slice(-2) +
              ("0" + date.getMinutes()).slice(-2);
            const datetime = useData.DOR + useData.StartTime;
            finishedFlg = Number(nowDatetime) > Number(datetime);

            //お気に入りタグの表示
            let id = useData.PedigreeNum;
            let isFhorse = false;
            let isFjockey = false;
            let isFtrainer = false;
            let isThorse = false;

            if (id != undefined && id != "") {
              id = id.slice(1, id.length - 1).split("_");
              for (let k in id) {
                if (isFavorite(FRACEHORSE, id[k]) == 1) {
                  isFhorse = true;
                break;
                }
              }
              for (let k in id) {
                if (isNextTarget(TRACEHORSE, id[k]) && pedigreeNums.value.includes(id[k])) {
                  isThorse = true;
                break;
                }
              }
            }

            id = useData.JockeyCd;
            if (id != undefined && id != "") {
              id = id.slice(1, id.length - 1).split("_");
              for (let k in id) {
                if (isFavorite(FJOCKEY, id[k]) == 1) {
                  isFjockey = true;
                  break;
                }
              }
            }

            id = useData.TrainerCd;
            if (id != undefined && id != "") {
              id = id.slice(1, id.length - 1).split("_");
              for (let k in id) {
                if (isFavorite(FTRAINER, id[k]) == 1) {
                  isFtrainer = true;
                  break;
                }
              }
            }

            //確定済が１つでもある場合は払戻金一覧を表示させる
            if (useData.ResultFlg == true && useData.Status == 1) {
              payoutURL.value =
                HOST +
                "race/odds/payout" +
                "?DOR=" +
                useData.DOR +
                "&RacetrackCd=" +
                useData.RacetrackCd;
            }

            let cancelFlg = false;
            //JRAのみレース中止リンクの付与
            if (isNar() === 0) {
              if (
                useData.PedigreeNum === undefined ||
                useData.PedigreeNum === "" ||
                (useData.Status != undefined && useData.Status === "2")
              ) {
                cancelFlg = true;
              }
            }

            raceLists.push({
              DOR: useData.DOR,
              Distance: useData.Distance,
              GradeCd: useData.GradeCd,
              GradeIcon: gradeIcon,
              Mark: isFhorse || isFjockey || isFtrainer || isThorse,
              isFhorse: isFhorse,
              isFjockey: isFjockey,
              isFtrainer: isFtrainer,
              isThorse: isThorse,
              JockeyCd: useData.JockeyCd,
              PedigreeNum: useData.PedigreeNum,
              RaceMainName: useData.RaceMainName,
              RaceNum: useData.RaceNum,
              RaceShortName: useData.RaceShortName,
              RaceShortName10: useData.RaceShortName10,
              RaceSymbolCd: useData.RaceSymbolCd,
              RacetrackCd: useData.RacetrackCd,
              ResultFlg: useData.ResultFlg,
              SpecialRaceNum: useData.SpecialRaceNum,
              StartTime: useData.StartTime,
              Status: useData.Status,
              TrackType: useData.TrackType,
              TrainerCd: useData.TrainerCd,
              WeightTypeCd: useData.WeightTypeCd,
              Win5RaceNum: useData.Win5RaceNum,
              isSpRace: useData.isSpRace,
              racetrackCd: useData.racetrackCd,
              UnResultFlg: useData.UnResultFlg,
              isIPAT: useData.isIPAT,
              finishedFlg: finishedFlg,
              cancelFlg: cancelFlg,
            });
          }
        }

        if (isNar() === 0) {
          //JRAのみ動画リンクを表示する
          setVideoLink(dor, racetrackCd, json);
        }
      } catch (e) {
        console.log("raceList : " + e);
      }
    }
    document.body.className = "status-loaded";
    loadedPage.value = true;

    if (isNar() === 1) {
      setTimeout(function () {
        displayGuid()
      }, 10);
    }
  });
};

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false);
  }
};

//JRAのみ
const DORTab = ref(2); //日付タブのインデックス
const PlaceTab = ref(3); //場所タブのインデックス
let DORArray = reactive([]); //日付タブのデータ
let placeArray = reactive([]); //場所タブのデータ
const loadRaceHeader = (setdor, setracetrackCd, callback) => {
  //パラメータからAPIコールを生成
  let req =
    HOST + RACEINFOPATH + "getRaceHeader.js" + BACKWORDPATH + "?dor=" + setdor;

  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      try {
        json = json[0]
        if (
          json.TargetDate &&
          json.TargetDate.length > 0 &&
          json.TargetRacetrackCd
        ) {
          let DORList = json.TargetDate;
          let RacetrackList = json.TargetRacetrackCd;

          //開催年月日タブと表示番号の設定
          let dayTab = {};
          for (let i = 0; i < DORList.length; i++) {
            dayTab[DORList[i]] = i + 1;
          }

          //タブの列数
          DORTab.value = DORList.length > 2 ? 3 : 2;
          PlaceTab.value = RacetrackList.length > 2 ? 3 : 2;


          //競馬場データの挿入
          Object.assign(placeArray, RacetrackList);

          for (let i = 0; i < DORList.length; i++) {
            //描画
            DORArray.push(DORList[i]);
          }
        }

        if (callback) {
          callback();
        }
      } catch (e) {
        console.log(e);
      }
    }
  });
};

//レース動画リンク(JRAのみ)
let videoLinkArray = reactive([]);
const setVideoLink = (dor, racetrackCd, json) => {
  videoLinkArray = reactive([])
  let COMMON_VIDEO_URL = HOST + MEDIAPATH + "videoOnDemand.js" + BACKWORDPATH;
  let COMMON_JOIN_NONMEMBER_URL = HOST + "member"; //一般入会ページ用URL
  let COMMON_JOIN_SUGOTOKU_URL = HOST + "joinSugotoku.js"; //スゴ得非会員ページ用URL

  let reqLst = [];
  for (let i = 0; i < json.length; i++) {
    reqLst.push(setDOR.value + setRacetrack.value + json[i].RaceNum);
  }

  if (reqLst.length == 0) {
    return;
  }

  callRetrieveCourse(function (courseId, marks, userid) {
    let request =
      HOST +
      MEDIAPATH +
      "getVideoUrl.js" +
      BACKWORDPATH +
      "?Lst=" +
      reqLst.join(",");
    getJSON(request, true, function (status, results) {
      if (status == SUCCESS) {
        for (let i = 0; i < results.length; i++) {
          if (/^1$/.test(results[i].Exist)) {
            let user = courseId;
            if (
              PAYINGPREMIUM === user ||
              PAYINGPACK03 === user ||
              PAYINGPACK06 === user ||
              PAYINGPACK12 === user
            ) {
              //premium
              let videoUrl =
                COMMON_VIDEO_URL +
                "?DOR=" +
                results[i].Key.slice(0, 8) +
                "&RacetrackCd=" +
                results[i].Key.slice(8, 10) +
                "&RaceNum=" +
                results[i].Key.slice(-2) +
                "&Expir=" +
                results[i].Expir +
                "&x=.mp4";
              videoLinkArray.push({ classNames: "premium", link: videoUrl });
            } else if (PAYINGSUGOTOKU === user) {
              //sugotoku
              let videoUrl =
                COMMON_VIDEO_URL +
                "?DOR=" +
                results[i].Key.slice(0, 8) +
                "&RacetrackCd=" +
                results[i].Key.slice(8, 10) +
                "&RaceNum=" +
                results[i].Key.slice(-2) +
                "&Expir=" +
                results[i].Expir +
                "&x=.mp4";
              videoLinkArray.push({ classNames: "sugotoku", link: videoUrl });
            } else if (PAYINGSUGOTOKU_NONE === user) {
              //sugotoku_none
              let videoUrl = COMMON_JOIN_SUGOTOKU_URL;
              videoLinkArray.push({
                classNames: "sugotoku_none",
                link: videoUrl,
              });
            } else if (PAYINGAPPPASS === user) {
              // AppPass
              // AppPassユーザの場合はレース動画ボタンを非表示にする。
              videoLinkArray.push({ classNames: "apppass", link: "" });
            } else {
              //一般
              videoLinkArray.push({
                classNames: "none",
                link: COMMON_JOIN_NONMEMBER_URL,
              });
            }
          } else {
            videoLinkArray.push("");
          }
        }
      }
    });
  });
};
let openMovie = ref(false);
let openMovieConfirm = ref(false);
const movieURL = ref("");
const openVideoModal = function (array) {
  if (PAYINGSUGOTOKU === array.classNames) {
    //動画視聴確認モーダルを開く
    openMovieConfirm.value = true
    movieURL.value = array.link;
  } else if (PAYINGPREMIUM === array.classNames ||
      PAYINGPACK03 === array.classNames ||
      PAYINGPACK06 === array.classNames ||
      PAYINGPACK12 === array.classNames) {
    //動画モーダルを開く
    openMovie.value = true;
    movieURL.value = array.link;
  } else {
    location.href = array.link;
  }
}

const openVideoConfirmed = (confirmed) => {
  openMovieConfirm.value = false;
  openMovie.value = confirmed;
}

//レース表示部で使用する日付フォーマット取得関数（例：○/△（□））
const getDisplayDateStrForMain = (yyyymmdd) => {
  const DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  const month = parseInt(yyyymmdd.slice(4, 6), 10);
  const date = parseInt(yyyymmdd.slice(6, 8), 10);
  const mDate = new Date(parseInt(yyyymmdd.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day == "土") {
    day = '<span class="week _sat">' + day + "</span>";
  } else if (day == "日") {
    day = '<span class="week _sun">' + day + "</span>";
  } else {
    day = '<span class="week">' + day + "</span>";
  }
  const str = month + "/" + date + " (" + day + ")";

  return str;
};
</script>
<template>
  <main class="layout-main">
    <section v-if="narFlag === 0" class="layout-section">
      <div
        class="nav-tab -date -jra" :class="'-fit' + DORTab">
        <ul>
          <li
            v-for="(dor, index) in DORArray"
            :key="index"
            :class="setDOR === dor ? '-current' : ''"
          >
            <div
              @click="changeDOR(dor)"
              class="label"
              v-html="getDisplayDateStrForMain(dor)"
            ></div>
          </li>
        </ul>
      </div>
      <div class="nav-tab -raceinfo -jra" :class="'-fit' + PlaceTab">
        <ul>
          <li
            v-for="(place, index) in placeArray[setDOR]"
            :key="index"
            :class="setRacetrack === place.RacetrackCd ? '-current' : ''"
          >
            <div @click="changeTrackJRA(place.RacetrackCd)" class="label">
              <div class="place">{{ place.Racetrack }}</div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section v-if="loadedPage && narFlag === 1" class="layout-section">
      <div v-if="toDay" class="container-wrapper -info">
        {{ toDay }} (<span v-html="thisWeek" />)
      </div>
    </section>

    <section v-if="loadedPage" class="layout-section">
      <div
        v-if="narFlag === 1"
        id="scrollGrid"
        class="nav-tab -scroll -raceinfo"
        :class="scrollGuid && displayScroll ? '' : '-moving'"
      >
        <ul>
          <li
            v-for="(track, index) in todayRaceTrackCd"
            :key="index"
            :class="track.RacetrackCd === targetRacetrackCd ? '-current' : ''"
            @click="changeTrack(track.RacetrackCd, track.Racetrack)"
          >
            <div class="label">
              <div class="place">
                {{ track.Racetrack }}
                <i v-if="track.isNighter" class="icon-time -night"></i>
              </div>

              <div v-if="todayRaceFlag && track.nextStartTime" class="info">
                <span class="r">{{ Number(track.nextRaceNum) }}R</span>
                <time>{{ track.nextStartTime }}</time>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <table
        v-if="raceLists.length && loadedPage"
        class="table-grid -racelist"
        :class="narFlag === 0 ? '' : '-nomovie'"
      >
        <tbody>
          <tr
            v-for="(Race, index) in raceLists"
            :key="index"
            :class="[
              Race.finishedFlg === true ? '-finished' : '',
              narFlag === 0
                ? Race.TrackType === '芝'
                  ? '-turf'
                  : Race.TrackType === 'ダート'
                  ? '-dart'
                  : Race.TrackType === '障害'
                  ? '-hurdle'
                  : ''
                : '',
              Race.cancelFlg === true && Race.finishedFlg === false
                ? '-unsettled'
                : '',
            ]"
          >
            <td class="number">{{ Number(Race.RaceNum) }}</td>
            <td v-if="Race.UnResultFlg === 1" class="status -mikakutei">
              <span class="label">未確</span>
            </td>
            <td
              v-else-if="Race.Status === '2' && narFlag === 1"
              class="status -cancel"
            >
              <span class="label">中止</span>
            </td>
            <td
              v-else-if="
                Race.Status === '2' &&
                narFlag === 0 &&
                Race.finishedFlg === true
              "
              class="status -cancel"
            >
              <span class="label">中止</span>
            </td>
            <td
              v-else-if="Race.ResultFlg === true && narFlag === 1"
              class="status -kakutei"
            >
              <span class="label">確定</span>
            </td>
            <td
              v-else-if="
                Race.ResultFlg === true &&
                narFlag === 0 &&
                Race.finishedFlg === true
              "
              class="status -kakutei"
            >
              <span class="label">確定</span>
            </td>
            <td v-else class="status"><span class="label"></span></td>

            <td
              class="race"
              :class="
                Race.TrackType === '芝'
                  ? '-turf'
                  : Race.TrackType === 'ダート'
                  ? '-dirt'
                  : Race.TrackType === '障害'
                  ? '-hurdle'
                  : ''
              "
            >
              <div class="item">
                <div class="info">
                  <a v-if="(!Race.PedigreeNum && Race.RacetrackCd !== '33') || Race.Status === undefined"
                     class="name defcursor"
                  >
                    <div class="title">
                      <div class="inner">{{ Race.RaceMainName }}</div>
                      <div class="grade">
                        <i v-if="Race.GradeIcon" :class="Race.GradeIcon"></i>
                        <i v-if="Race.Win5RaceNum > 0" class="icon-race -win5"></i>
                      </div>
                    </div>
                    <div class="caption">
                      {{ Race.StartTime.substr(0, 2) }}:{{Race.StartTime.substr(2, 4) }}発走/{{ Race.TrackType.substr(0, 1)}}{{ Race.Distance }}m
                      <i v-if="narFlag === 1 && Race.isIPAT" class="icon-ipat -bg">IPAT</i>
                    </div>
                  </a>
                  <router-link v-else
                      :to="
                    (Race.Status !== '2' && Race.ResultFlg === true)
                      ? '/race/results?DOR=' +
                        Race.DOR +
                        '&RacetrackCd=' +
                        Race.RacetrackCd +
                        '&RaceNum=' +
                        Race.RaceNum
                      : Race.UnResultFlg === 1
                      ? '/race/odds/inquire?DOR=' +
                        Race.DOR +
                        '&RacetrackCd=' +
                        Race.RacetrackCd +
                        '&RaceNum=' +
                        Race.RaceNum
                      : '/race/card?DOR=' +
                        Race.DOR +
                        '&RacetrackCd=' +
                        Race.RacetrackCd +
                        '&RaceNum=' +
                        Race.RaceNum
                  "
                      class="name"
                      :class="
                    Race.Status == '2'
                      ? 'defcursor'
                      : ''
                  "
                  >
                    <div class="title">
                      <div class="inner">{{ Race.RaceMainName }}</div>
                      <div class="grade">
                        <i v-if="Race.GradeIcon" :class="Race.GradeIcon"></i>
                        <i v-if="Race.Win5RaceNum > 0" class="icon-race -win5"></i>
                      </div>
                    </div>
                    <div class="caption">
                      {{ Race.StartTime.substr(0, 2) }}:{{Race.StartTime.substr(2, 4) }}発走/{{ Race.TrackType.substr(0, 1)}}{{ Race.Distance }}m
                      <i v-if="narFlag === 1 && Race.isIPAT" class="icon-ipat -bg">IPAT</i>
                    </div>
                  </router-link>
                  <div class="mark" v-if="Race.Mark">
                    <div class="upper">
                      <i v-if="Race.isFjockey" class="icon-mark -jockey"></i>
                      <i v-if="Race.isFtrainer"  class="icon-mark -trainer"></i>
                      <i v-if="Race.isFhorse" class="icon-mark -horse"></i>
                    </div>
                    <div class="lower">
                      <i v-if="Race.isThorse" class="icon-mark -nextrun"></i>
                    </div>
                  </div>
                </div>
                <a
                  v-if="
                    narFlag === 0 &&
                    videoLinkArray.length > 0 &&
                    videoLinkArray[index] != ''
                  "
                  :class="
                    videoLinkArray[index].classNames === 'premium' ||
                    videoLinkArray[index].classNames === 'sugotoku' ||
                    videoLinkArray[index].classNames === 'sugotoku_none'
                      ? 'icon-mov'
                      : 'icon-mov -premium'
                  "
                  href="#"
                  @click="openVideoModal(videoLinkArray[index])"
                ></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else-if="loadedPage" class="inner">
        <p class="system-message -norace">開催レースはありません</p>
      </div>
    </section>
    <section v-if="payoutURL && loadedPage" class="layout-section">
      <div class="inner">
        <router-link :to="payoutURL" class="btn-basic -wide -high">払戻金一覧へ</router-link>
      </div>
    </section>
    <section v-if="loadedPage" class="layout-section -bordertop">
      <div class="inner">
        <div class="nav-toggle">
          <div class="nav-bool -nomargin">
            <router-link
              v-if="narFlag === 0"
              :to="'/race/list?DOR=' + DOR"
              class="btn-basic -wide"
              >レース一覧</router-link
            >
            <router-link
              v-else-if="narFlag === 1"
              :to="'/info/entries_index?DOR=' + DOR"
              class="btn-basic -wide"
              >出走馬INDEX</router-link
            >
            <router-link :to="'/race/calendar?DOR=' + DOR" class="btn-basic -wide"
              >レースカレンダー</router-link
            >
          </div>
        </div>
      </div>
    </section>
    <section v-if="loadedPage" class="layout-section -bordertop">
      <div class="inner">
        <div class="nav-bool -nomargin">
          <router-link :to="'/race/stallion'" class="btn-basic -wide"
          >種牡馬別出走産駒検索</router-link
          >
        </div>
      </div>
    </section>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
    <ShowMovieConfirm
        @openVideoConfirmed="openVideoConfirmed"
        :openMovieConfirm="openMovieConfirm">
    </ShowMovieConfirm>
    <ShowMovie
        @closeModal="openMovie = false"
        :openMovie="openMovie"
        :movieURL="movieURL">
    </ShowMovie>
  </main>
</template>
<style lang="scss" src="../../assets/css/race/style.scss" scoped></style>
