<script setup>
import {ref} from 'vue'
import ProductionDetailHorses from "./ProductionDetailHorses.vue";

const productionHorseTab = ref("productionHorses");
const isCurrentProductionHorseTab = ref("productionHorses");

const props = defineProps({
  productionHorses: Array,
  pastProductionHorses: Array,
  isDisplayFav: Boolean,
});

const tabClick = (data) => {
  productionHorseTab.value = data;
  isCurrentProductionHorseTab.value = data;
}
</script>

<template>
  <div>
    <div class="container-toggle -marginbottom -white">
      <ul data-btn>
        <li
          @click="tabClick('productionHorses')"
          :class="{ '-current': isCurrentProductionHorseTab === 'productionHorses' }"
        >
          <span class="label">代表生産馬一覧</span>
        </li>
        <li
          @click="tabClick('pastProduction')"
          :class="{ '-current': isCurrentProductionHorseTab === 'pastProduction' }"
        >
          <span class="label">過去代表生産馬</span>
        </li>
      </ul>
    </div>
    <ProductionDetailHorses
      v-if="productionHorseTab === 'productionHorses'"
      :productionHorses="productionHorses"
      :title="'代表生産馬一覧'"
      :isDisplayFav="isDisplayFav"
    />
    <ProductionDetailHorses
      v-if="productionHorseTab === 'pastProduction'"
      :productionHorses="pastProductionHorses"
      :title="'過去代表生産馬'"
      :isDisplayFav="false"
    />
  </div>
</template>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>