<script setup>
import { computed, onMounted} from "vue";
import { prizeToString } from "../../assets/js/common";
import useStore from "../../stores/leading-states"
import { storeToRefs } from 'pinia';
import {useRoute} from "vue-router";

const props = defineProps({
  leadingList: Array,
  isNar: Boolean,
  updateDate: String,
  type: {
    type: String,
    default: "",
  },
})

const store = {
  'jockey': useStore.useStoreLeadingJockey(),
  'jockey_weightloss': useStore.useStoreLeadingWeightLossJockey(),
  'trainer': useStore.useStoreLeadingTrainer(),
  'owner': useStore.useStoreLeadingOwner(),
  'breeder': useStore.useStoreLeadingBreeder(),
  'stud': useStore.useStoreLeadingStud(),
  'bms': useStore.useStoreLeadingBms(),
  'new_stallion': useStore.useStoreLeadingNewStud()
};

const { count, isWeekOpen, isAllOpen } = storeToRefs(store[props.type]);

const listItems = computed(() => {
  const list = props.leadingList;
  return list.slice(0, count.value);
})

onMounted(() => {
  const route = useRoute();
  const rank = Number(route.query.rank) ?? 0;
  if(rank > 0) {
    count.value = rank - (rank % 10) + 10;
    setTimeout(function () {
      const target = document.getElementById('bottom');
      target.scrollIntoView(false);
    }, 500);
  }
})

function isMore() {
  count.value += 30;
}

function handleThisToggle(index) {
  if (isWeekOpen.value.length === 0) {
    isWeekOpen.value = Array(props.leadingList.length).fill(false);
  }
  isWeekOpen.value.splice(index, 1, !isWeekOpen.value[index]);
}

function handleThisToggleAll() {
  isAllOpen.value = !isAllOpen.value;
  props.leadingList.forEach(function (value, index) {
    isWeekOpen.value[index] = isAllOpen.value
  })
}

function beforeEnter(el) {
  el.style.height = "0";
}

function enter(el) {
  el.style.height = el.scrollHeight + "px";
}

function beforeLeave(el) {
  el.style.height = el.scrollHeight + "px";
}

function leave(el) {
  el.style.height = "0";
}

function getPrize(prize) {
  return prizeToString(prize)
}

</script>
<template>
  <ul class="list-line -dotted">
    <li class="header">
      <div class="list-wins">
        <div class="item">
          <span v-if="updateDate">
            {{ updateDate }}
          </span>
        </div>
        <div class="item -nav">
          <span class="icon-accordion"
                :class="{ '-active': isAllOpen === true }"
            @click="handleThisToggleAll()"
            data-trigger>
          </span>
        </div>
      </div>
    </li>
    <li data-accordion='["data-trigger","data-target"]'
        v-for="(item, index) in listItems"
        :key="index">
      <div class="list-wins">
        <div class="item -ranking">
          <i v-if="item.Order < 4" class="icon-prize" :class="`-no${item.Order}`"></i>
          <div v-else>{{ item.Order }}</div>
        </div>
        <div class="item">
          <router-link :to="item.Url">
            <span :class="[item.Mark ? `icon-weight ${item.Mark}` : '' ]">
              {{ item.Name }}
            </span>
            <span v-if="isNar && item.Syozoku && !item.Mark">
              （{{ item.Syozoku }}）
            </span>
          </router-link>
          <div class="info" v-if="item.Mark">
            <span class="meta">({{ item.LicenseDate }}/{{ item.Syozoku }})</span>
            <span class="meta">{{ item.CareerWins }}</span>
          </div>
        </div>
        <div class="item -winner">
          <ul class="list-numofwins" v-if="!item.RaceEntryNum">
            <li>{{ item.Place1st }}</li>
            <li>{{ item.Place2nd }}</li>
            <li>{{ item.Place3rd }}</li>
            <li>{{ item.Unplaced }}</li>
          </ul>
          <div class="p-prizemoney">{{ getPrize(item.TotalEarnings) }}</div>
        </div>
        <div class="item -nav">
          <span class="icon-accordion"
                :class="{ '-active': isWeekOpen[index] }"
                @click="handleThisToggle(index)"
                data-trigger>
          </span>
        </div>
      </div>
      <transition name="topSlide"
                  @before-enter="beforeEnter"
                  @enter="enter"
                  @before-leave="beforeLeave"
                  @leave="leave">
        <div v-if="isWeekOpen[index]"
             data-target
             class="topSlide"
             :class="{ '-active': isWeekOpen[index] }">
          <div class="container-detail">
            <table class="table-grid -center -padding -prize">
              <tr>
                <td class="-center">
                  <span class="icon-race" :class="isNar ? '-jpn1' : '-g1'"></span>
                  {{ item.G1RaceWins }}勝
                </td>
                <td class="-center">
                  <span class="icon-race" :class="isNar ? '-jpn2' : '-g2'"></span>
                  {{ item.G2RaceWins }}勝
                </td>
                <td class="-center">
                  <span class="icon-race" :class="isNar ? '-jpn3' : '-g3'"></span>
                  {{ item.G3RaceWins }}勝
                </td>
              </tr>
            </table>
            <table class="table-grid -center -padding -prize" v-if="item.WinRate">
              <tr>
                <td class="-center">
                  <dl class="item">
                    <dt>勝率</dt>
                    <dd>{{ item.WinRate }}</dd>
                  </dl>
                </td>
                <td class="-center">
                  <dl class="item">
                    <dt>連対率</dt>
                    <dd>{{ item.MultiRate }}</dd>
                  </dl>
                </td>
                <td class="-center">
                  <dl class="item">
                    <dt>3着内率</dt>
                    <dd>{{ item.Win3Rate }}</dd>
                  </dl>
                </td>
              </tr>
            </table>
            <ul class="list-record">
              <template v-if="item.RaceEntryNum">
                <li>
                  <dl class="item">
                    <dt>出走頭数</dt>
                    <dd>{{ item.RaceEntryNum }}</dd>
                  </dl>
                </li>
                <li>
                  <dl class="item">
                    <dt>出走回数</dt>
                    <dd>{{ item.RaceRunNum }}</dd>
                  </dl>
                </li>
                <li>
                  <dl class="item">
                    <dt>勝馬頭数</dt>
                    <dd>{{ item.WinningStatNum }}</dd>
                  </dl>
                </li>
                <li>
                  <dl class="item">
                    <dt>勝利回数</dt>
                    <dd>{{ item.WinNum }}</dd>
                  </dl>
                </li>
                <li>
                  <dl class="item">
                    <dt>勝馬率</dt>
                    <dd>{{ item.WinningStatNumRate }}%</dd>
                  </dl>
                </li>
                <li>
                  <dl class="item">
                    <dt>E・I</dt>
                    <dd>{{ item.EI }}</dd>
                  </dl>
                </li>
                <li>
                  <dl class="item">
                    <dt>総賞金</dt>
                    <dd>{{ getPrize(item.TotalEarnings) }}</dd>
                  </dl>
                </li>
              </template>
              <template v-else>
                <li v-if="item.Syozoku">
                  <dl class="item">
                    <dt>所属</dt>
                    <dd>{{ item.Syozoku }}</dd>
                  </dl>
                </li>
                <li>
                  <dl class="item">
                    <dt>総賞金</dt>
                    <dd>{{ getPrize(item.TotalEarnings) }}</dd>
                  </dl>
                </li>
                <li>
                  <dl class="item">
                    <dt>勝利数</dt>
                    <dd>
                      <ul class="list-numofwins">
                        <li>{{ item.Place1st }}</li>
                        <li>{{ item.Place2nd }}</li>
                        <li>{{ item.Place3rd }}</li>
                        <li>{{ item.Unplaced }}</li>
                      </ul>
                    </dd>
                  </dl>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </transition>
    </li>
  </ul>
  <div class="inner" v-if="type === 'new_stallion'">
    <p v-if="isNar">※新馬戦開始時からの1年間を集計期間としています。</p>
    <p v-else>※新馬戦開始時からダービー終了までを集計期間としています。</p>
  </div>
  <div class="layout-meta" v-if="type === 'weight'">
    <ul>
      <li><i class="icon-weight -f1"></i>:-4㌔</li>
      <li><i class="icon-weight -m1"></i>:-3㌔</li>
      <li><i class="icon-weight -m2"></i>:-2㌔</li>
      <li><i class="icon-weight -m3"></i>:-1㌔</li>
    </ul>
  </div>

  <div class="nav-group" id="bottom">
    <span v-if="listItems.length - count >= 0 && leadingList.length - count > 0"
      class="btn-basic -more"
      @click="isMore">
      もっと見る
    </span>
  </div>
</template>

<style lang="scss" src="../../assets/css/leading/style.scss" scoped></style>
