<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">単勝1倍台</h1>
    </section>

    <section class="layout-section">
      <table class="table-line -middle -fit -pays1" v-if="raceData">
        <tbody>
          <tr
            :class="race.ResultFlg ? '-finished' : ''"
            v-for="(race, index) in raceData"
            :key="index"
          >
            <td class="status">
              <span v-if="race.ResultFlg" class="label">確定</span>
            </td>
            <td>
              <a :href="race.urlLink" class="link-cell">
                <div class="inner">
                  <div>
                    <div class="title">{{ race.HorseName }}</div>
                    <span class="caption"
                      >{{ race.Racetrack }}{{ race.RaceNum * 1 }}R/{{
                        race.BracketNum
                      }}枠{{ race.HorseNum }}番/{{ race.RaceName }}/{{
                        race.TrackTypeCd.slice(0, 1)
                      }}{{ race.Distance }}m</span
                    >
                  </div>
                  <div class="txt-odds -no1">{{ race.WinOdds }}倍</div>
                </div>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <ol v-else class="mt10 box5">
        <p>{{ noDataValue }}</p>
      </ol>
      <div class="container-caption">
        <p class="_caption">
          ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
        </p>
      </div>
    </section>

    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import { getJSON, SUCCESS, isNar } from "../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  RACEPATH,
  ODDSPATH,
} from "../../assets/js/define";
export default {
  data() {
    return {
      raceData: null,
      noDataValue: null,
    };
  },
  mounted() {
    const self = this;
    const mDate = new Date();
    // 土曜の19:30以降は、翌日のデータを取得するため、DORに翌日の日にちを設定する
    if (mDate.getDay() == 6) {
      // 土曜(6）であるか
      // if ( mDate.getDay() == 6 || mDate.getDay() == 0 ) { // 土曜(6）or日曜(0）であるか ※３三日間開催用
      // if ( mDate.getDay() == 5 || mDate.getDay() == 6 ) { // 金曜(5）or土曜(6）であるか ※年末３三日間開催用 ※三日間対応（金土日）
      if (mDate.getHours() * 60 + mDate.getMinutes() >= 1170) {
        // 19:30以降であるか判定(19*60+30 1170)
        mDate.setDate(mDate.getDate() + 1);
      }
    }

    let strDate =
      mDate.getFullYear() +
      ("0" + (mDate.getMonth() + 1)).slice(-2) +
      ("0" + mDate.getDate()).slice(-2);
    // WIN_KEIBA_SUPPORT-614 2021-2022年末年始対応
    // ホープフルステークスの対応(飛び石開催になり、デジマース様判断で前日に開催中表示の運用有り)
    // if (strDate == "20211227") {
    //   strDate = "20211228";
    // }

    //日付タブの取得～表示
    displayOdds(strDate);

    //オッズ一覧の表示
    function displayOdds(dateLstStr) {
      //レース結果情報のためレース3場版を取得

      let req =
        HOST +
        ODDSPATH +
        "getPays1x.js" +
        BACKWORDPATH +
        "?dor=" +
        dateLstStr +
        "&narFlag=" +
        isNar();
      document.body.className = "";
      //defaultオッズ一覧を取得～描画
      getJSON(req, true, function (status, json) {
        if (status == SUCCESS) {
          let data = json;

          let keys = Object.keys(data);

          if (keys.length) {
            let key = keys[0]; //要素は1つのみ

            for (let i = 0; i < data[key].length; i++) {
              let race = data[key][i];

              //レースステータスによって表示させるページを変更する
              let statusPath = race.ResultFlg ? "results" : "card";

              race.urlLink =
                HOST +
                RACEPATH +
                statusPath +
                "?DOR=" +
                key +
                "&RacetrackCd=" +
                race.RacetrackCd +
                "&RaceNum=" +
                race.RaceNum;
            }

            self.raceData = data[key];
          } else {
            self.noDataValue = "単勝1倍台はありません。";
          }
        }
        document.body.className = "status-loaded";
      });
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/info/style.scss" scoped></style>
