<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">レース傾向</h1>
    </section>

    <section class="trend-lastweek base-bg">
      <div v-if="raceInfoArray.length > 0" class="layout-title">
        <h2 class="heading-circle -padding">{{ formatday }}{{ place }}</h2>
      </div>
      <trend-table :is-jra="isJra"
                   :is-top="false"
                   :race-info-array="raceInfoArray"
                   :jockey-array="jockeyArray"
                   :stud-array="studArray"
                   :loaded-page="loadedPage"
                   :is-non-member="member">
      </trend-table>
    </section>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import {
  getJSON,
  getUrlVars,
  SUCCESS,
  getMenmerCourse,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  calcBracketNum,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  RACEINFOPATH,
} from "../../assets/js/define";
import TrendTable from "../../components/info/TrendTable.vue";

export default {
  name: "trend",
  components: {TrendTable},
  data() {
    return {
      formatday: null,
      place: null,
      raceInfoArray: [],
      jockeyArray: [],
      studArray: [],
      member: false,
      loadedPage: false,
      isJra: true,
    };
  },
  mounted() {
    let self = this;
    self.isJra = isNar() === 0;

    // レース傾向表示
    const params = getUrlVars();
    const raceTrend = function (DOR, RacetrackCd, RaceMeeting, RaceDay, isPre) {
      //パラメータからAPIコールを生成
      const req =
        HOST +
        RACEINFOPATH +
        "racingTrendAPI.js" +
        BACKWORDPATH +
        "?DOR=" +
        DOR +
        "&RacetrackCd=" +
        RacetrackCd +
        "&RaceMeeting=" +
        RaceMeeting +
        "&RaceDay=" +
        RaceDay +
        "&isPre=" +
        isPre +
        "&NarFlag=" +
        isNar();
      document.body.className = "";
      self.loadedPage = false;
      getJSON(req, true, function (status, json) {
        if (status === SUCCESS) {
          try {
            if (json !== undefined && json !== null) {
              let useDor = DOR;
              if (json.DOR !== "" && json.isPre === "1") {
                useDor = json.DOR;
              }

              //見出しに日付と競走場表示
              const dateDor = new Date(
                Number(useDor.substr(0, 4)),
                Number(useDor.substr(4, 2)) - 1,
                Number(useDor.substr(6, 2))
              );
              const w = ["日", "月", "火", "水", "木", "金", "土"];
              self.formatday = useDor.substr(4, 2) +
                  "/" +
                  useDor.substr(6, 2) +
                  "(" +
                  w[dateDor.getDay()] +
                  ")";

              const place = {
                "01": "札幌",
                "02": "函館",
                "03": "福島",
                "04": "新潟",
                "05": "東京",
                "06": "中山",
                "07": "中京",
                "08": "京都",
                "09": "阪神",
                10: "小倉",
                30: "門別",
                35: "盛岡",
                36: "水沢",
                42: "浦和",
                43: "船橋",
                44: "大井",
                45: "川崎",
                46: "金沢",
                47: "笠松",
                48: "名古屋",
                50: "園田",
                51: "姫路",
                54: "高知",
                55: "佐賀",
              };

              self.place = place[RacetrackCd];
              self.jockeyArray = json.JockeyList
              self.studArray = json.StudList

              if (json.TrendList && json.TrendList.length > 0) {
                const raceInfos = json.TrendList;

                //レース昇順にソート
                raceInfos.sort(function (a, b) {
                  a = a["RaceNum"];
                  b = b["RaceNum"];
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
                });
                let trendMax = 3;
                if (!(getMenmerCourse() == PAYINGNONE || getMenmerCourse() == PAYINGSUGOTOKU_NONE)) {
                  trendMax = raceInfos.length;
                } else {
                  if (raceInfos.length < 3) {
                    trendMax = raceInfos.length;
                  }
                  self.member = true;
                }
                if (trendMax >= 12) {
                  trendMax = raceInfos.length;
                }
                let raceInfoArray = [];
                for (let i = 0; i < trendMax; i++) {
                  let isCancel = false;
                  if (raceInfos[i].Status == 2) {
                    //レース中止の場合
                    isCancel = true;
                  }
                  //レース番号
                  const raceNum = parseInt(raceInfos[i].RaceNum, 10) + "R";

                  //天気
                  const weatherCd = raceInfos[i].WeatherCd || "";

                  // 人気
                  const popular = raceInfos[i].Popular;

                  //距離
                  let distance = "";
                  let trackType = "";
                  if (raceInfos[i].TrackType) {
                    distance += raceInfos[i].TrackType.slice(0, 1);
                    //レース番号のクラス設定
                    if (raceInfos[i].TrackType === "芝") {
                      trackType = "-turf";
                    } else if (raceInfos[i].TrackType === "ダート") {
                      trackType = "-dart";
                    } else if (raceInfos[i].TrackType === "障害") {
                      trackType = "-hurdle";
                    }
                  }

                  if (raceInfos[i].Distance) {
                    distance += raceInfos[i].Distance + "m";
                  }

                  //馬場状態
                  let condition;
                  if (raceInfos[i].TrackConditionCdTurf) {
                    condition = raceInfos[i].TrackConditionCdTurf;
                  }
                  if (raceInfos[i].TrackConditionCdDirt) {
                    condition = raceInfos[i].TrackConditionCdDirt;
                  }

                  //馬番
                  let horseNum;
                  let horseNumColor;
                  if (raceInfos[i].HorseNum) {
                    horseNum = raceInfos[i].HorseNum;
                    horseNumColor =
                      "icon-umaban -waku" +
                      0 +
                      calcBracketNum(
                        raceInfos[i].EntryNum,
                        raceInfos[i].HorseNum
                      );
                  }
                  //コーナー通過順
                  let corneringOrder = raceInfos[i].CorneringOrder || "";

                  // JRA
                  //ペース/決め手
                  let paceStr = "";
                  let paceColor;
                  if (isNar() === 0 && raceInfos[i].Pace) {
                    if (raceInfos[i].Pace === "H") {
                      paceStr += raceInfos[i].Pace;
                      paceColor = "text-pace -high";
                    } else if (raceInfos[i].Pace === "M") {
                      paceStr += raceInfos[i].Pace;
                      paceColor = "text-pace -middle";
                    } else if (raceInfos[i].Pace === "S") {
                      paceStr += raceInfos[i].Pace;
                      paceColor = "text-pace -slow";
                    }
                  }

                  //決め手
                  const clincher = raceInfos[i].RunningStyle;

                  //勝利騎手
                  const jockeyName = raceInfos[i].JockeyName;
                  //騎手名太字
                  const nameBold = self.jockeyArray.includes(raceInfos[i].JockeyCd);
                  //勝利騎手のリンク先
                  let jockeyNameUrl = raceInfos[i].JockeyCd ?
                      "/data/jockey?JockeyCd=" + raceInfos[i].JockeyCd : "";

                  //騎手傾向
                  const jockeyData = raceInfos[i].JockeyData

                  //種牡馬傾向
                  const studData = raceInfos[i].StudData

                  const raceInfo = {
                    raceNum,
                    weatherCd,
                    distance,
                    popular,
                    trackType,
                    condition,
                    paceStr,
                    clincher,
                    corneringOrder,
                    horseNum,
                    paceColor,
                    horseNumColor,
                    isCancel,
                    jockeyName,
                    jockeyNameUrl,
                    nameBold,
                    jockeyData,
                    studData,
                  };

                  const exist = raceInfoArray.filter(race => {
                    return raceInfo.raceNum === race.raceNum
                  })

                  if (exist.length) continue
                  raceInfoArray.push(raceInfo);
                }
                self.raceInfoArray = raceInfoArray;
              }
            }
          } catch (e) {
            alert(
              "loadTrendInfo : レース傾向の表示中にエラーが発生しました。 " + e
            );
          }
        }
        document.body.className = "status-loaded";
        self.loadedPage = true;
      });
    };
    raceTrend(
      params.DOR,
      params.RacetrackCd,
      params.RaceMeeting,
      params.RaceDay,
      params.isPre
    );
  },
};
</script>

<style lang="scss" src="../../assets/css/info/style.scss" scoped></style>
