<script setup>
import { ref, reactive, onMounted, computed, toRefs, onUpdated } from "vue";
import { HOST, JRA_WP_HOST } from "../../assets/js/define";
import axios from "axios";
import { getUrlVars } from "../../assets/js/common";

const saleIndexLink = HOST + "sales";

const API_URL = JRA_WP_HOST + "wp-json/jra/sales?sale_type_slug=";

const list = reactive({});
const newsList = reactive({});
onMounted(() => {
  document.body.className = "";
  getYear();
  const request_url = API_URL + year;
  axios
    .get(request_url)
    .then((response) => {
      Object.assign(list, response.data);
      processSale(list);
      processNews(list.news);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});

const thisPageYear = ref("");
const content = ref("");
const title = ref("");
const processSale = (list) => {
  thisPageYear.value = list.top[0].slug;
  content.value = list.top[0].articles[0].content;
  title.value = list.top[0].articles[0].title;
};

const processNews = (news) => {
  for (let i = 0; i < news.length; i++) {
    for (let l = 0; l < news[i].articles.length; l++) {
      news[i].articles[l].urlLink =
        HOST + "sales/article?id=" + news[i].articles[l].ID;

      let year = news[i].articles[l].modified.substr(0, 4);
      let month = news[i].articles[l].modified.substr(5, 2);
      let day = news[i].articles[l].modified.substr(8, 2);
      let time = news[i].articles[l].modified.substr(11, 5);

      news[i].articles[l].modified =
        year + "." + month + "." + day + " " + time;
    }
  }
  Object.assign(newsList, news);
};

let year = "";
const getYear = () => {
  const params = getUrlVars();
  year = params.slug;
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">{{ thisPageYear }}セレクトセール特集</h1>
      <div class="nav">
        <a :href="saleIndexLink" class="btn-basic -prev">セール特集一覧へ</a>
      </div>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner -padding">
          <h2 class="heading-copy">
            {{ title }}
          </h2>
          <div v-if="content" v-html="content"></div>
        </div>
        <div v-if="newsList">
          <div class="inner" v-for="(news, index) in newsList" :key="index">
            <h2 class="heading-circle">
              {{ news.articles[0].title }}
            </h2>
            <div v-for="(article, key) in news.articles" :key="key">
              <ul class="list-news -selectsale" v-if="key > 0">
                <li class="item">
                  <a :href="article.urlLink" class="link-cell">
                    <div class="title">
                      {{ article.title }}
                    </div>
                    <time class="caption"> {{ article.modified }} 更新</time>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="loading-anim">
        <div class="inner">
          <img src="/assets/images/common/loading.svg" alt="" class="loading" />
        </div>
      </div>
    </section>
  </main>
</template>
