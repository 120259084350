<script setup>
import { onMounted, ref } from "vue";
import {
  COURSEID,
  getJSON,
  SUCCESS,
  setCookie,
  USERID,
} from "../../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
} from "../../../assets/js/define";

const userId = ref('')

//現在登録中のメールアドレスを記述
function setMailConfigs() {
  document.body.className = "";

  //ログイン情報取得
  let request = HOST + PATH + "getLoginInfo.js" + BACKWORDPATH;

  getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json != undefined) {
      let result = json;
      userId.value = result.userId;

      if (
        userId.value != undefined &&
        userId.value != null &&
        userId.value != "" &&
        result.mailAddress != undefined &&
        result.mailAddress != null &&
        result.mailAddress != ""
      ) {
        //ユーザID
        setCookie(USERID, userId.value);
        //コースID
        let courseId = result.courseId;
        setCookie(COURSEID, courseId);
      } else if (
        userId.value === undefined ||
        userId.value === "" ||
        userId.value === null
      ) {
        alert('ログインしてください。')
      } else if (
        result.mailAddress === undefined ||
        result.mailAddress === "" ||
        result.mailAddress === null
      ) {
        alert('配信用メールアドレスは削除済です。')
        location.replace(HOST);
      }
    } else {
      alert("設定情報の取得に失敗しました。");
      location.replace(HOST);
    }
    document.body.className = "status-loaded";
  });
}

const deleteEmail = async () => {
  if (
    userId.value === undefined ||
    userId.value === null ||
    userId.value === ""
  ) {
    alert('ログインしてください。')
    return
  }
  document.body.className = "";
  const request =
        HOST +
        PATH +
        'Email/deleteEmailAddress.js' +
        BACKWORDPATH +
        '?userId=' +
        encodeURIComponent(userId.value)

  await getJSON(request, false, function (status, json) {
    if (status == SUCCESS) {
      location.href = HOST
    } else {
      alert('メールアドレスの削除に失敗しました。')
      location.href = HOST
    }
  });
}

onMounted(() => {
  setMailConfigs();
});

</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">WIN!競馬メール配信 設定</h1>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner">
          <h2 class="heading-circle">メール配信解除</h2>
          <p>メール配信を解除すると、以下の配信が解除されます。</p>
          <ul class="list-disc">
            <li>重賞の枠順確定と、重賞結果</li>
            <li>WIN5対象レースの確定情報と、出走結果</li>
            <li>お気に入り出走場の出走確定情報と、出走結果</li>
            <li>POG登録馬の出走確定と、出走結果</li>
            <li>WIN!競馬からのメンテナンスや競馬情報</li>
            <li>広告付きの通知</li>
          </ul>
          <p>@winkeiba.jpからメール配信解除の確認のメールをお送りします。</p>
          <div class="nav-group">
            <a @click="deleteEmail()" class="btn-basic -wide">通知機能を解除</a>
          </div>
          <div class="nav-group">
            <a :href="HOST" class="btn-basic -wide">TOPへ戻る</a>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
