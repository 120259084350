<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  setDismissDialog,
  getUserId,
  SUCCESS,
  isDismissDialog,
  getCourse,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  isNar,
  isAndroid,
  getUserAgent,
} from "../../../assets/js/common";

import {
  HOST,
  PATH,
  BACKWORDPATH,
  METHOD_WHEEL,
  TOB_WIDE,
  RACEPATH,
} from "../../../assets/js/define";

import MyStampModal from "../../../components/utils/MyStampModal.vue";
import SetBetConfModal from "../../../components/utils/SetBetConfModal.vue";
import raceOdds from "../../../functions/odds/race-odds";
import raceDeadline from "../../../functions/odds/race-deadline_countdown";
import myMark from "../../../functions/odds/race-mymark";
import {useRoute} from "vue-router";

const { setRaceBetByArray, sortDoubleOdds } = raceOdds();
const { TimerCount } = raceDeadline();
const { loadMyMarkForDrop2, setMyMark } = myMark();

let checkAllJudge = ref(false);
let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let deadLineJudge = ref(false);
let loadedPage = ref(false)
let oddsUpdInfo = ref("");
const checkedHorse = reactive({});
const currentTab = "bracket";

let params = {};

const props = defineProps({
  raceDORVal: String,
  raceTrackCdVal: String,
  raceNumVal: String,
  deadLineJudge: Boolean,
});

let time = ref("");

const route = useRoute();
watch(route, (from, to) => {
  clearSelected();
  loadPromisingBet();
  doInit(to.query);
})

onMounted(() => {
  document.body.className = "";
  params = getUrlVars();
  //パラメータチェック
  if (checkUrlVars(params)) {
    loadPromisingBet()
    doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});

let stamp = reactive({});
const stampClass = (num) => {
  if (stamp[num].selectedNum == 0) {
    return "stamp-mark -none";
  } else if (stamp[num].selectedNum == 1) {
    return "stamp-mark -honmei";
  } else if (stamp[num].selectedNum == 2) {
    return "stamp-mark -taikou";
  } else if (stamp[num].selectedNum == 3) {
    return "stamp-mark -tanana";
  } else if (stamp[num].selectedNum == 4) {
    return "stamp-mark -renka";
  } else if (stamp[num].selectedNum == 5) {
    return "stamp-mark -hoshi";
  } else return "stamp-mark -none";
};
const stampClassTable = (num) => {
  if (num == 0) {
    return "stamp-mark -none";
  } else if (num == 1) {
    return "stamp-mark -honmei";
  } else if (num == 2) {
    return "stamp-mark -taikou";
  } else if (num == 3) {
    return "stamp-mark -tanana";
  } else if (num == 4) {
    return "stamp-mark -renka";
  } else if (num == 5) {
    return "stamp-mark -hoshi";
  } else return "stamp-mark -none";
};
const oddsClass = (val) => {
  if (val == "---.-") {
    return "txt-odds";
  } else if (val > 0 && val < 10) {
    return "txt-odds -no1";
  } else if (val > 0 && val < 100) {
    return "txt-odds -no2";
  } else return "txt-odds -other";
};

//チェックリスト関連
let checkList = reactive({});
//　選択した馬の組み合わせ表示用
let info = reactive([]);
let axisHorse = ref(null);
let checkStorageArray = reactive([])
let axisHorseStorage = ref(null)
const loadPromisingBet = () => {
  const checkListStorage = localStorage.getItem('wide_wheel' + location.search)
  const axisStorage = localStorage.getItem('wide_axis' + location.search)
  if (checkListStorage) checkStorageArray = JSON.parse(checkListStorage)
  if (axisStorage) axisHorseStorage.value = Number(axisStorage)
}
const checkChange = (num, horseNum) => {
  if (horseNum == "horse1") {
    axisHorse.value = num;
    axisHorseStorage.value = axisHorse.value
    localStorage.setItem('wide_axis' + location.search, axisHorseStorage.value)
  } else {
    if (checkList[num] === true) {
      checkList[num] = false;
      checkStorageArray?.splice(checkStorageArray?.indexOf(String(num)),1)
    } else {
      checkList[num] = true;
      checkStorageArray?.push(String(num))
    }
    localStorage.setItem('wide_wheel' + location.search, JSON.stringify(checkStorageArray))
  }
  setSelected();
};
let checkNumInfo = new Array();
let oddsTable = reactive([]);
const setSelected = () => {
  info.splice(0);
  let horse1 = 0;
  let horse2 = 0;
  let conf = 0;

  if (axisHorse.value != null) {
    Object.keys(checkList).forEach((c) => {
      if (checkList[c] == false) {
        return;
      } else if (checkList[c] == true && axisHorse.value == c) {
        return;
      } else if (checkList[c] == true && axisHorse.value < c) {
        horse1 = axisHorse.value + 1;
        horse2 = Number(c) + 1;
      } else if (checkList[c] == true && axisHorse.value > c) {
        horse1 = Number(c) + 1;
        horse2 = axisHorse.value + 1;
      }

      conf = info.findIndex((i) => i.horse1 === horse1 && i.horse2 === horse2);
      if (conf == -1) {
        const maxOdds = oddsForTable["MaxOdds" + horse1 + "_" + horse2]
          ? oddsForTable["MaxOdds" + horse1 + "_" + horse2]
          : "---.-";
        const minOdds = oddsForTable["MinOdds" + horse1 + "_" + horse2]
          ? oddsForTable["MinOdds" + horse1 + "_" + horse2]
          : "---.-";
        info.push({
          horse1: horse1,
          horse2: horse2,
          mark1: translateMyMarkToChar(stamp[horse1 - 1].selectedNum),
          mark2: translateMyMarkToChar(stamp[horse2 - 1].selectedNum),
          odds: minOdds,
          maxOdds: maxOdds,
          minOdds: minOdds,
        });
      }
    });
  }
  info.sort(sortDoubleOdds(sortType.value));
};
const translateMyMarkToChar = (m) => {
  let result = "-";
  if (m == 1) {
    result = "◎";
  } else if (m == 2) {
    result = "○";
  } else if (m == 3) {
    result = "▲";
  } else if (m == 4) {
    result = "△";
  } else if (m == 5) {
    result = "☆";
  } else {
    result = "-";
  }
  return result;
};
const checkedAll = () => {
  if (checkAllJudge.value == true) {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      checkList[i] = false;
      checkStorageArray?.splice(checkStorageArray?.indexOf(String(i)),1)
    }
    checkAllJudge.value = false;
  } else {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      if (horses[i].AbnormalDiv === "1" || horses[i].AbnormalDiv === "3" || !horses[i].Odds) {
        //取消・中止はチェックしない
        checkList[i] = false;
      } else {
        checkList[i] = true;
        if (checkStorageArray?.includes(String(i))) continue
        checkStorageArray?.push(String(i))
      }
    }
    checkAllJudge.value = true;
  }
  localStorage.setItem('wide_wheel' + location.search, JSON.stringify(checkStorageArray))
  setSelected();
};
// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkList,() => {
  const checkLists = Object.values(checkList)
  if(checkLists.length) checkAllJudge.value = checkLists.find(check => check === false) === undefined
},{immediate:true})
const checkListNum = () => {
  let arr = [];

  for (let i = 0; i < Object.keys(checkList).length; i++) {
    if (checkList[i] == true && i != axisHorse.value) {
      arr.push(i);
    }
  }

  return arr;
};

// modal
let left = ref("");
let top = ref("");
const openModal = (event, place) => {
  document.body.className = "status-loaded status-haslayerbg status-showstamp";
  // x座標
  left.value = event.pageX;
  // y座標
  top.value = event.pageY;
  stamp[place - 1].status = true;
};

const closeStampModal = (place) => {
  document.body.className = "status-loaded";
  if (stamp[place.num].selectedNum != place.selectedNum) {
    //my印を新しくセットした場合は、my印を更新する
    stamp[place.num].selectedNum = place.selectedNum;
    setMyMark(
      getUserId(),
      props.raceDORVal,
      props.raceTrackCdVal,
      props.raceNumVal,
      place.num + 1,
      stamp[place.num].selectedNum
    );
  }
  stamp[place.num].status = false;
};

// horses
let horses = reactive([]);
let mark = reactive({});
let marks = reactive({});
let oddsForTable = reactive({});
const doInit = async (params) => {
  marks = await loadMyMarkForDrop2(
    getUserId(),
    params.DOR,
    params.RacetrackCd,
    params.RaceNum
  );
  loadCandidacyTableForOddsBasic(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum,
    (result) => {
      loadOddsWin(params.DOR, params.RacetrackCd, params.RaceNum);
    }
  );
  retrieveOdds(params.DOR, params.RacetrackCd, params.RaceNum, (result) => {
    oddsForTable = Object.assign(oddsForTable, result);
  });


};
//レース詳細情報を取得する
let cancelList = reactive([]);
const loadCandidacyTableForOddsBasic = (
  dor,
  racetrackCd,
  raceNum,
  callback
) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "RaceInfo/candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == "success") {
      horses = Object.assign(horses, json["EntryHorses"]);

      //my印及びチェックボックスをデフォルト値をセット

      for (let i = 0; i < horses.length; i++) {
        let selectNum = 0;
        if (
          marks &&
          marks["MyNo" + (i + 1)] != undefined &&
          marks["MyNo" + (i + 1)] != "" &&
          marks["MyNo" + (i + 1)] != null
        ) {
          selectNum = marks["MyNo" + (i + 1)];
        }

        //出馬取消馬の場合は各ボタンを配置しないためのフラグ
        if (horses[i].AbnormalDiv === "1" || horses[i].AbnormalDiv === "3") {
          cancelList[i] = true;
        } else {
          cancelList[i] = false;
        }

        stamp[i] = { status: false, selectedNum: selectNum };
        checkList[i] = checkStorageArray?.includes(String(i)) ? true : false;
      }
      axisHorse.value = axisHorseStorage.value
      setSelected()
    }
    if (callback != undefined) {
      callback();
    }
  });
};
const loadOddsWin = (DOR, RacetrackCd, RaceNum) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "Odds/winTicketOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    DOR +
    "&RacetrackCd=" +
    RacetrackCd +
    "&RaceNum=" +
    RaceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      //データは常に１件が正しい。
      if (json.length == 1) {
        const displayData = json[0];

        // オッズを格納
        for (let i = 0; i < horses.length; i++) {
          horses[i].Odds = displayData["Odds" + (i + 1)];
        }
      } else {
        location.href = HOST +
            RACEPATH +
            "card?DOR=" +
            DOR +
            "&RacetrackCd=" +
            RacetrackCd +
            "&RaceNum=" +
            RaceNum;
      }
    }
    loadedPage.value = true
    document.body.className = "status-loaded";
  });
};
function retrieveOdds(dor, racetrackCd, raceNum, callback) {
  const jsName = "wideOdds.js";
  if (jsName != "") {
    try {
      const req =
        HOST +
        PATH +
        "Odds/" +
        jsName +
        BACKWORDPATH +
        "?DOR=" +
        dor +
        "&RacetrackCd=" +
        racetrackCd +
        "&RaceNum=" +
        raceNum +
        "&NarFlag=" +
        isNar();
      getJSON(req, true, function (status, json) {
        if (status == SUCCESS) {
          //データは常に１件が正しい。
          if (json.length == 1) {
            oddsUpdInfo.value = json[0]["OddsUpdInfo"];
            if (callback != undefined) {
              callback(json[0]);
            }
          } else {
            if (callback != undefined) {
              callback({});
            }
          }
        } else {
          if (callback != undefined) {
            callback({});
          }
        }
      });
    } catch (e) {
      if (callback != undefined) {
        callback({});
      }
    }
  } else {
    if (callback != undefined) {
      callback({});
    }
  }
}
//買い目登録
const setRaceBetWin = () => {
  let setRaceBetParams = getUrlVars();
  let openWindow
  if (isDismissDialog() == 1 && !isAndroid() && !getUserAgent()) {
    openWindow = window.open('', '_blank')
  }

  let mainChekFlg = "";
  let isFirst = false;
  let isSecond = false;
  if (info.length != 0) {
    // レースヘッダから情報を取得
    if (props.raceDORVal != null) {
      setRaceBetParams["DOR"] = props.raceDORVal;
    }
    if (props.raceTrackCdVal != null) {
      setRaceBetParams["RacetrackCd"] = props.raceTrackCdVal;
    }
    if (props.raceNumVal != null) {
      setRaceBetParams["RaceNum"] = props.raceNumVal;
    }

    for (let i = 0; i < 18; i++) {
      if (i == axisHorse.value) {
        mainChekFlg += "1";
        isFirst = true;
      } else {
        mainChekFlg += "0";
      }
    }
    for (let i = 0; i < 18; i++) {
      if (checkList[i] === true && i !== axisHorse.value) {
        mainChekFlg += "1";
        isSecond = true;
      } else {
        mainChekFlg += "0";
      }
    }
  }
  if (isFirst && isSecond) {
    let betArray = new Array();
    let doFlgArray = new Array();
    let methodType = METHOD_WHEEL;
    betArray.push(mainChekFlg);
    doFlgArray.push(true);
    //順番に登録させるため再帰的に登録
    setRaceBetByArray(
      setRaceBetParams,
      TOB_WIDE,
      methodType,
      betArray,
      doFlgArray,
      function () {
        if (isDismissDialog() != 1) {
          // AppPassはダイアログ非表示
          if (getCourse() == PAYINGAPPPASS) {
            //ダイアログ非表示フラグON
            setDismissDialog();
          } else {
            //モーダルウィンドウ表示
            raceBetCheck.value = true;
            modalBetOpen.value = true;
          }
        } else {
          let url = HOST + "purchase";
          if (
            (getCourse() == PAYINGSUGOTOKU ||
            getCourse() == PAYINGSUGOTOKU_NONE) && !getUserAgent()
          ) {
            url = location.pathname + "?url=" + HOST + "purchase";
          }

          if (isAndroid() || getUserAgent()) {
            location.href = url;
          }
          else { openWindow.location.href = url }
        }
      }
    );
    checkStorageArray.splice(0)
    axisHorseStorage.value = null
    localStorage.removeItem('wide_wheel' + location.search)
    localStorage.removeItem('wide_axis' + location.search)
  } else if (!isFirst) {
    alert("軸馬を選択してください");
  } else {
    alert("有効な相手馬を選択してください");
  }
};
//買い目削除
const deleteSelected = () => {
  const result = confirm('ワイド/流しに対して全ての買い目を削除しますか？')
  if (result) {
    clearStorage();
    clearSelected();
  }
}

const clearStorage = () => {
  localStorage.removeItem('wide_wheel' + location.search)
  localStorage.removeItem('wide_axis' + location.search)
}

const clearSelected = () => {
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    checkList[i] = false;
  }
  axisHorse.value = null
  axisHorseStorage.value = null
  checkStorageArray.splice(0)
  checkAllJudge.value = false
  setSelected();
}

//ソートを変更
const activeSortId = ref("sort_num");
const sortType = ref(3);

const changeSort = (id) => {
  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_my") {
      if (sortType.value === 1) {
        sortType.value = 2;
      } else {
        sortType.value = 1;
      }
      activeSortId.value = "sort_my";
    } else if (id === "sort_num") {
      if (sortType.value === 3) {
        sortType.value = 4;
      } else {
        sortType.value = 3;
      }
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      if (sortType.value === 5) {
        sortType.value = 6;
      } else {
        sortType.value = 5;
      }
      activeSortId.value = "sort_odds";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを昇順でセットする
    if (id === "sort_my") {
      sortType.value = 1;
      activeSortId.value = "sort_my";
    } else if (id === "sort_num") {
      sortType.value = 3;
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      sortType.value = 5;
      activeSortId.value = "sort_odds";
    }
  }
  setSelected();
};

// 流し　選択馬番の軸馬、相手の表示判定
const dispSelectedHorses = () => {
  let notAxis = false;
  Object.keys(checkList).forEach((c) => {
    if (checkList[c] == true && c != axisHorse.value) {
      notAxis = true;
    }
  });
  return notAxis;
};
</script>

<template>
  <section class="layout-section" v-if="loadedPage">
    <div class="layout-title">
      <h2 class="heading-circle -padding">ワイド / 流し</h2>
      <div class="nav">
        <span v-if="props.deadLineJudge" class="status">締め切り間近</span>
        <span :class="[oddsUpdInfo ? 'icon-kakutei' : '']">{{
          oddsUpdInfo
        }}</span>
      </div>
    </div>
    <table class="table-grid -fit -middle -center -odds">
      <thead>
        <tr>
          <th class="mystamp">my印</th>
          <th>馬番</th>
          <th>馬名</th>
          <th>単オッズ</th>
          <th>軸馬</th>
          <th class="ranking">
            相手馬<br /><span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  @click="checkedAll()"
                  v-model="checkAllJudge"
                  value=""
                  name="" /><i></i></label
            ></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(h, index) in horses"
          :key="h.horseNum"
          :class="[
            h.AbnormalDiv === '1' ? '-cancel' : '',
            h.AbnormalDiv === '3' ? '-cancel' : '',
            h.Odds ? '' : '-cancel',
          ]"
        >
          <td class="mystamp">
            <span
              :class="stampClass(h.HorseNum - 1)"
              @click="openModal($event, h.HorseNum)"
            ></span>
            <MyStampModal
              @closeModal="closeStampModal($event)"
              v-if="stamp[h.HorseNum - 1].status === true"
              :top="top"
              :left="left"
              :stampNum="h.HorseNum - 1"
              :selectStamp="stamp[h.HorseNum - 1].selectedNum"
            />
          </td>
          <td>
            <i
              :class="[
                h.BracketNum < 10
                  ? `icon-umaban -waku0${h.BracketNum}`
                  : `icon-umaban -waku${h.BracketNum}`,
              ]"
              >{{ h.HorseNum }}</i
            >
          </td>
          <td class="name">{{ h.HorseName }}</td>
          <td class="-right">
            <div class="txt-odds -other" v-if="h.AbnormalDiv === '1' || h.AbnormalDiv === '3'">
              <span v-if="h.AbnormalDiv === '1'">取消</span>
              <span v-else>除外</span>
            </div>
            <div v-else class="txt-odds" :class="oddsClass(h.Odds)">
              {{ h.Odds }}
            </div>
          </td>
          <td>
            <span
              class="form-radio"
              v-if="cancelList[h.HorseNum - 1] === false"
            >
              <label
                ><input
                  type="radio"
                  v-model="axisHorse"
                  :value="index"
                  @click="checkChange(index, 'horse1')" /><i></i></label
            ></span>
          </td>
          <td>
            <span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  @click="checkChange(index, 'horse2')"
                  v-model="checkList[index]"
                  value=""
                  name="" /><i></i></label
            ></span>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="nav-group -right">
      <span
        @click="deleteSelected"
        class="btn-basic -delete"
        >削除</span
      >
    </nav>
    <div class="container-total">
      <dl class="amount">
        <dt>点数合計</dt>
        <dd>
          <span class="num">{{ info.length }}</span
          >点
        </dd>
      </dl>
      <div @click="setRaceBetWin" v-show="info.length" class="btn-basic">
        買い目登録
      </div>
    </div>
    <div class="container-wrapper -pad" v-show="info.length">
      <h3 class="heading-bar _center">流し　選択馬番</h3>
      <table class="table-grid -middle -selectednumber">
        <tr>
          <th class="_nowrap">軸馬</th>
          <td>
            <ul class="list-umaban">
              <li v-if="axisHorse != null && dispSelectedHorses()">
                <i
                  :class="[
                    horses[axisHorse].BracketNum < 10
                      ? `icon-umaban -waku0${horses[axisHorse].BracketNum}`
                      : `icon-umaban -waku${horses[axisHorse].BracketNum}`,
                  ]"
                  >{{ axisHorse + 1 }}
                </i>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th class="_nowrap">相手</th>
          <td>
            <ul class="list-umaban">
              <li v-for="c in checkListNum()" :key="c">
                <i
                  :class="[
                    horses[c].BracketNum < 10
                      ? `icon-umaban -waku0${horses[c].BracketNum}`
                      : `icon-umaban -waku${horses[c].BracketNum}`,
                  ]"
                >
                  {{ c + 1 }}
                </i>
              </li>
            </ul>
          </td>
        </tr>
      </table>

      <div class="icon-next"></div>

      <table class="table-grid -middle -padding -center">
        <thead>
          <tr>
            <th>
              my印<i
                class="filter-sort"
                :class="sortType === 1 ? '-asc' : sortType === 2 ? '-desc' : ''"
                @click="changeSort('sort_my')"
              ></i>
            </th>
            <th>
              組み合わせ<i
                class="filter-sort"
                :class="sortType === 3 ? '-asc' : sortType === 4 ? '-desc' : ''"
                @click="changeSort('sort_num')"
              ></i>
            </th>
            <th>
              オッズ<i
                class="filter-sort"
                :class="sortType === 5 ? '-asc' : sortType === 6 ? '-desc' : ''"
                @click="changeSort('sort_odds')"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in info" :key="i">
            <td>
              <ul class="list-kaimoku">
                <li>
                  <span
                    :class="
                      stampClassTable(stamp[Number(i.horse1) - 1].selectedNum)
                    "
                  ></span>
                </li>
                <li>
                  <span
                    :class="
                      stampClassTable(stamp[Number(i.horse2) - 1].selectedNum)
                    "
                  ></span>
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-kaimoku">
                <li>
                  <i
                    :class="[
                      horses[i.horse1 - 1].BracketNum < 10
                        ? `icon-umaban -waku0${horses[i.horse1 - 1].BracketNum}`
                        : `icon-umaban -waku${horses[i.horse1 - 1].BracketNum}`,
                    ]"
                    >{{ i.horse1 }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      horses[i.horse2 - 1].BracketNum < 10
                        ? `icon-umaban -waku0${horses[i.horse2 - 1].BracketNum}`
                        : `icon-umaban -waku${horses[i.horse2 - 1].BracketNum}`,
                    ]"
                    >{{ i.horse2 }}</i
                  >
                </li>
              </ul>
            </td>
            <td class="-center">
              <div class="container-odds">
                <span class="txt-odds" :class="oddsClass(i.minOdds)">{{
                  i.minOdds
                }}</span>
                <i class="connect"></i>
                <span class="txt-odds" :class="oddsClass(i.maxOdds)">{{
                  i.maxOdds
                }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container-total -noborder">
        <dl class="amount">
          <dt>点数合計</dt>
          <dd>
            <span class="num">{{ info.length }}</span
            >点
          </dd>
        </dl>
        <div @click="setRaceBetWin" class="btn-basic">買い目登録</div>
      </div>
    </div>
    <div class="container-schedule">
      <a href="/purchase" target="_blank" class="btn-basic -normal">購入予定一覧</a>
      <p class="_caption">
        ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
      </p>
    </div>
  </section>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
  <SetBetConfModal
    @closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
  ></SetBetConfModal>
</template>

<style
  lang="scss"
  src="../../../assets/css/race/odds/style.scss"
  scoped
></style>
