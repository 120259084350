<template>
  <section class="layout-section">
    <div class="layout-sectionheader -check">
      <h1 class="heading-section">チェックポイント</h1>
    </div>
    <div class="link-cell card-point -fit">
      <div v-if="userCourse === 1 || userCourse === 2"
           class="system-registmember -flow">
        <div class="inner">
          <router-link :to="userCourse === 2 ? '/member/join_economy' : '/member'"
                       target="_blank"
                       class="btn-basic -wide -center -window">
            こちらの機能をご利用いただくには、<br>
            プレミアムコースへの会員登録が必要です。
          </router-link>
        </div>
      </div>
      <div class="corner" v-if="Object.keys(recommendData).length">
        <router-link class="label" :to="recommendData.analysisHref">
          厳選1頭<br />推奨馬
        </router-link>
      </div>
      <div class="corner" v-else><a class="label">厳選1頭<br />推奨馬</a></div>
      <router-link v-if="Object.keys(recommendData).length" :to="recommendData.entryHref" class="summary">
        <div class="inner">
          <p class="title">
            {{ recommendData.HorseName}}
          </p>
          <p class="sub">
            {{ recommendData.RacetrackName}}{{ Number(recommendData.RaceNum) }}R　{{ recommendData.RaceName }}
          </p>
          <ul class="list-meta">
            <li>
              {{ recommendData.TrackTypeCd }}{{ recommendData.Distance }}m
            </li>
            <li>
              {{ recommendData.StartTime }} 発走
            </li>
          </ul>
        </div>
      </router-link>
    </div>

    <div class="link-cell card-point -fit">
      <div class="corner">
        <router-link class="label" to="/info/pays1">
          単勝<br />1倍台
        </router-link>
      </div>
      <router-link
          v-if="Object.keys(pays1xData).length"
          :to="pays1xData.mHref"
          class="summary"
      >
        <div class="inner">
          <p class="title">
            {{ pays1xData.race.HorseName
            }}<span class="txt-odds -no1"
          >{{ pays1xData.race.WinOdds }}倍</span
          >
          </p>
          <ul class="list-meta">
            <li>
              {{ pays1xData.race.Racetrack
              }}{{ Number(pays1xData.race.RaceNum) }}R
            </li>
            <li>
              {{ pays1xData.race.BracketNum }}枠{{
                pays1xData.race.HorseNum
              }}番
            </li>
            <li>{{ pays1xData.race.RaceName }}</li>
            <li>
              {{ pays1xData.TrackTypeCd }}{{ pays1xData.race.Distance }}m
            </li>
          </ul>
        </div>
      </router-link>
      <router-link v-else-if="loadedPage" to="/info/pays1" class="summary">
        <div class="inner">
          <p class="title">単勝1倍台はありません。</p>
        </div>
      </router-link>
    </div>
    <div class="link-cell card-point -fit">
      <div class="corner">
        <router-link to="/info/bigdividend" class="label">
          高額配当<br />一覧
        </router-link>
      </div>
      <router-link
          v-if="Object.keys(highDividendData).length"
          :to="highDividendData.mHref"
          class="summary"
      >
        <div class="inner">
          <p class="title">
            {{ highDividendData.race.Racetrack
            }}{{ Number(highDividendData.race.RaceNum) }}Rで{{
              highDividendData.race.Contents[0].TOB
            }}{{ highDividendData.Refund }}円
          </p>
        </div>
      </router-link>
      <router-link v-else-if="loadedPage" to="/info/bigdividend" class="summary">
        <div class="inner">
          <p class="title">高額配当は出ていません。</p>
        </div>
      </router-link>
    </div>
    <div class="link-cell card-point -fit">
      <div class="corner">
        <router-link to="/info/jockeylist" class="label">
          固め打ち<br />騎手
        </router-link>
      </div>
      <router-link
          v-if="Object.keys(multiWinnersData).length"
          :to="multiWinnersData.mHref"
          class="summary"
      >
        <div class="inner">
          <p class="title">
            {{ multiWinnersData.JockeyName }}騎手が{{
              multiWinnersData.jockey.Contents[0].Racetrack
            }}{{ multiWinnersData.RaceNum }}Rで本日{{
              multiWinnersData.jockey.WinNum
            }}勝！
          </p>
        </div>
      </router-link>
      <router-link v-else-if="loadedPage" to="/info/jockeylist" class="summary">
        <div class="inner">
          <p class="title">2勝以上の騎手はいません。</p>
        </div>
      </router-link>
    </div>
    <div class="link-cell card-point -fit -noarrow">
      <div v-if="userCourse === 1 || userCourse === 2"
           class="system-registmember -flow">
        <div class="inner">
          <router-link :to="userCourse === 2 ? '/member/join_economy' : '/member'"
                       target="_blank"
                       class="btn-basic -wide -center -window">
            こちらの機能をご利用いただくには、<br>
            プレミアムコースへの会員登録が必要です。
          </router-link>
        </div>
      </div>
      <div class="corner">
        <router-link to="/info/establish_record" class="label">
          記録達成<br>間近
        </router-link>
      </div>
      <div class="summary" v-if="establishRecordData.length">
        <div class="inner">
          <ul class="list">
            <li v-for="data in establishRecordData">
              <router-link :to="data.url" class="title">
                {{ data.name }}{{ data.place }}
              </router-link>
              <span class="meta -important">
                  {{ data.wins }}
                </span>
            </li>
          </ul>
        </div>
      </div>
      <router-link v-else-if="loadedPage" to="/info/establish_record" class="summary">
        <div class="inner">
          <p class="title">対象なし</p>
        </div>
      </router-link>
    </div>
    <div class="link-cell card-point -fit -noarrow">
      <div class="corner">
        <router-link to="/leading/jockey_weightloss" class="label">
          減量騎手<br>ランキング
        </router-link>
      </div>
      <div class="summary">
        <div class="inner">
          <ul class="list -weightranking">
            <li v-for="item in weightLossJockeyData">
              <i :class="`icon-ranking -no${item.Order}st`"></i>
              <router-link :to="item.Url" class="title">
                  <span :class="`icon-weight ${item.Mark}`">
                    {{ item.Name.replace(/　/g, "") }}
                  </span>
              </router-link>
              <span class="meta">
                  ({{ item.LicenseDate }}/{{ item.Syozoku }})
                </span>
              <span class="meta">
                  {{ item.CareerWins }}
                </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CheckPoint",
  props: {
    userCourse: Number,
    loadedPage: Boolean,
    recommendData: Object,
    pays1xData: Object,
    highDividendData: Object,
    multiWinnersData: Object,
    establishRecordData: Object,
    weightLossJockeyData: Object,
  }
}
</script>