import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreOwnerData = defineStore('owner-data', () => {
  const horseOwnerCdValue = ref('');
  const ownerData = ref(null);
  const careerStatData = ref(null);
  const bigPrizeData = ref([]);
  const belongHorse = ref([]);
  const schedule = ref([]);
  const totalRaces = ref(0);

  return {
    horseOwnerCdValue,
    ownerData,
    careerStatData,
    bigPrizeData,
    belongHorse,
    schedule,
    totalRaces,
  }
})

export default {
  useStoreOwnerData,
}