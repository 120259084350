<template>
  <div class="autocomplete">
    <input
      type="text"
      @input="onChange"
      v-model="search"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      class="autocomplete-input"
    />
    <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result }}
      </li>
    </ul>
  </div>
</template>

<script>
import { getJSON, SUCCESS, isNar } from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH} from "../../assets/js/define";
export default {
  name: "SearchAutocomplete",
  props: ["searchOption"],
  data() {
    return {
      isOpen: false,
      results: [],
      search: "",
      oldSearch: "",
      arrowCounter: -1,
      items: [],
    };
  },
  watch: {
    items: function (value, oldValue) {
      if (value !== oldValue) {
        this.results = value;
      }
    },
    search: function (value, oldValue) {
      if (value !== oldValue) {
        this.$emit("searchWord", this.search);
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    setResult(result) {
      this.search = result;
      this.isOpen = false;
    },
    onChange() {
      let self = this;
      this.$emit("input", this.search);
      if (this.search !== null && this.search !== undefined && this.search !== "" && this.oldSearch !== this.search) {
        //パラメータからAPIコールを生成
        let req =
          HOST +
          PATH +
          "getSuggestWord.js" +
          BACKWORDPATH +
          "?" +
          "Search=" +
          this.search +
          "&SearchOption=" +
          this.searchOption +
          "&NarFlag=" +
          isNar();
        document.body.className = "";
        getJSON(req, true, function (status, json) {
          if (status == SUCCESS) {
            if (json.length && self.oldSearch === self.search) {
              self.items = [];
              for (let i = 0; i < json.length; i++) {
                if (json[i] != "" && json[i] != null) {
                  self.items.push(json[i]);
                }
              }
              self.isOpen = true;
            } else {
              self.items = [];
              self.isOpen = false;
            }
          }
        });
        this.results = this.items;
        this.oldSearch = this.search;
        document.body.className = "status-loaded";
      } else {
        self.items = [];
        self.isOpen = false;
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.items = [];
        this.isOpen = false;
        this.arrowCounter = -1;
        this.oldSearch = "";
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      if (this.results[this.arrowCounter] != null) {
        this.search = this.results[this.arrowCounter];
      }
      this.isOpen = false;
      this.arrowCounter = -1;
      this.oldSearch = "";
    },
  },
};
</script>

<style>
.autocomplete-input {
  width: 100%;
  height: 25px;
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border-top: 1px solid #eeeeee;
  height: 100%;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #378d2c;
  color: white;
}
</style>
