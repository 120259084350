<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">高額配当一覧</h1>
    </section>

    <section class="layout-section" v-if="useData && loadedPage">
      <table class="table-grid -fit -padding">
        <tbody v-for="(data, index) in useData" :key="index">
          <tr>
            <th colspan="3">
              {{ data.Racetrack }}{{ data.RaceNum * 1 }}R &emsp;{{
                data.RaceName
              }}
            </th>
          </tr>
          <tr
            class="-center"
            v-for="(content, index) in data.Contents"
            :key="index"
          >
            <td>{{ content.TOB }}</td>
            <td>{{ content.WinNum }}</td>
            <td>
              {{ content.Refund }}円（{{ content.PopularRanking }}番人気）
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <div v-else-if="loadedPage" class="mt10 box5">
      <p>{{ noDataValue }}</p>
    </div>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import { getJSON, SUCCESS, isNar } from "../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  RACEINFOPATH,
} from "../../assets/js/define";
export default {
  data() {
    return {
      useData: null,
      loadedPage: false,
      noDataValue: null,
    };
  },
  mounted() {
    const self = this;
    //日付の取得表示
    displayDateTab();
    document.body.className = "";

    function displayDateTab() {
      let req =
        HOST +
        RACEINFOPATH +
        "getTodayRaceInfo.js" +
        BACKWORDPATH +
        "?NarFlag=" +
        isNar();
      getJSON(req, true, function (status, json) {
        if (status == SUCCESS) {
          if (json == null) {
            return;
          }

          let mDate = new Date();
          // 土曜の19:30以降は、翌日のデータを取得するため、DORに翌日の日にちを設定する
          if (mDate.getDay() == 6) {
            // 土曜(6）であるか
            // if ( mDate.getDay() == 6 || mDate.getDay() == 0 ) { // 土曜(6）or日曜(0）であるか ※３三日間開催用
            // if ( mDate.getDay() == 5 || mDate.getDay() == 6 ) { // 金曜(5）or土曜(6）であるか ※年末３三日間開催用 ※三日間対応（土金日)
            if (mDate.getHours() * 60 + mDate.getMinutes() >= 1170) {
              // 19:30以降であるか判定(19*60+30 1170)
              mDate.setDate(mDate.getDate() + 1);
            }
          }
          let date =
            mDate.getFullYear() +
            ("0" + (mDate.getMonth() + 1)).slice(-2) +
            ("0" + mDate.getDate()).slice(-2);
          // todate = "20171228";

          //オッズ一覧の表示
          displayOdds(date);
        }
      });
    }
    //オッズ一覧の表示
    function displayOdds(dateLstStr) {
      let req =
        HOST +
        RACEINFOPATH +
        "getHighDividend.js" +
        BACKWORDPATH +
        "?dor=" +
        dateLstStr +
        "&narFlag=" +
        isNar();
      //defaultオッズ一覧を取得～描画
      getJSON(req, true, function (status, json) {
        if (status == SUCCESS) {
          const data = json;

          let keys = Object.keys(data);
          if (keys.length) {
            for (let k = 0; k < keys.length; k++) {
              let key = keys[k];

              for (let i = 0; i < data[key].length; i++) {
                let race = data[key][i];

                for (let j = 0; j < race.Contents.length; j++) {
                  //オッズの整形
                  let odds = race.Contents[j];
                  let winNum = odds.WinNum;
                  if (/(馬単|３連単|枠単)/.test(odds.TOB)) {
                    winNum = odds.WinNum.replace(/-/g, "→");
                  }
                  odds.WinNum = winNum;

                  //金額をカンマ区切りで整形
                  odds.Refund = odds.Refund.toLocaleString();
                }
              }
            }
            self.useData = data[Object.keys(data)];
          } else {
            self.noDataValue = "データがありません";
          }
          document.body.className = "status-loaded";
          self.loadedPage = true;
          return;
        }
      });
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/info/style.scss" scoped></style>
