<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">収支管理</h1>
    </section>

    <section class="layout-section">
      <div>
        <div class="nav-tab -fit">
          <ul>
            <li>
              <a class="label" href="/balance/">年間別収支データ</a>
            </li>
            <li class="-current">
              <a class="label" href="/balance/category/">カテゴリ別データ</a>
            </li>
          </ul>
        </div>
        <div class="container-tabtarget">
          <div class="inner">
            <nav class="nav-multi -noborder">
              <ul class="list">
                <li class="-current">
                  <a href="/balance/sum_venue/" class="label">競馬場別</a>
                </li>
                <li>
                  <a href="/balance/sum_date_place/" class="label">開催別</a>
                </li>
                <li>
                  <a href="/balance/sum_jockey/" class="label">騎手別</a>
                </li>
                <li>
                  <a href="/balance/sum_trainer/" class="label">調教師別</a>
                </li>
                <li>
                  <a href="/balance/sum_distance/" class="label">距離別</a>
                </li>
                <li>
                  <a href="/balance/sum_surface/" class="label">芝ダ障別</a>
                </li>
                <li>
                  <a href="/balance/sum_horse_age/" class="label"
                     :class="narFlag ? '-disable' : ''">世代別</a>
                </li>
                <li>
                  <a href="/balance/sum_horse_number/" class="label">馬番別</a>
                </li>
                <li>
                  <a href="/balance/sum_bettype/" class="label">馬券別</a>
                </li>
                <li>
                  <a href="/balance/sum_grade/" class="label"
                     :class="narFlag ? '-disable' : ''">クラス別</a>
                </li>
                <li>
                  <a href="/balance/sum_monthly/" class="label">月別</a>
                </li>
              </ul>
            </nav>
          </div>

          <div class="inner">
            <div class="-dayplace">
              <div class="form-select">
                <select v-model="selectedYear" v-on:change="getData">
                  <option v-for="(year, index) in yearList" :key="index">
                    {{ year }}年
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="inner">
            <h2 class="heading-circle">競馬場別収支集計</h2>
            <nav class="nav-multi -noborder">
              <table class="table-cell">
                <tr class="-center">
                  <th rowspan="2">競馬場</th>
                  <th>購入額合計</th>
                  <th>払戻金額合計</th>
                  <th>回収率</th>
                </tr>
                <tr class="-center">
                  <th>購入レース</th>
                  <th>的中レース</th>
                  <th>的中率</th>
                </tr>
                <tr class="-total">
                  <th class="amount -center -separate" rowspan="2">合計</th>
                  <td class="amount">{{ dataList.sv_sum ?? 0 }}円</td>
                  <td class="amount">{{ dataList.rv_sum ?? 0 }}円</td>
                  <td class="amount">{{ dataList.rp_sum ?? 0 }}%</td>
                </tr>
                <tr class="-total">
                  <td class="amount -separate">
                    {{ dataList.rc_sum ?? 0 }}
                  </td>
                  <td class="amount -separate">
                    {{ dataList.hc_sum ?? 0 }}
                  </td>
                  <td class="amount -separate">{{ dataList.hp_sum ?? 0 }}%</td>
                </tr>

                <tbody v-for="(data, index) in dataList.tags" :key="index">
                  <tr>
                    <th class="-center" rowspan="2">
                      {{ getPlace(data.item) ?? "" }}
                    </th>
                    <td class="amount">{{ data.sv ?? 0 }}円</td>
                    <td class="amount">{{ data.rv ?? 0 }}円</td>
                    <td class="amount">{{ data.rp ?? 0 }}%</td>
                  </tr>
                  <tr>
                    <td class="amount">{{ data.rc ?? 0 }}</td>
                    <td class="amount">{{ data.hc ?? 0 }}</td>
                    <td class="amount">{{ data.hp ?? 0 }}%</td>
                  </tr>
                </tbody>
              </table>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import { PAYINGNONE, PAYINGSUGOTOKU_NONE, retrieveCourse, isNar } from "../../assets/js/common";
import { HOST, PATH, JOINPATH } from "../../assets/js/define";
import { getYear, getDataCommon } from "./index";
export default {
  name: "SumVenue",
  data() {
    return {
      //パラメータ(固定)
      HOST: HOST,
      PATH: PATH,

      selectedYear: new Date().getFullYear().toString(),
      yearList: [],
      dataList: {},
      placeList: [],
      getPlaces: {
        "01": "札幌",
        "02": "函館",
        "03": "福島",
        "04": "新潟",
        "05": "東京",
        "06": "中山",
        "07": "中京",
        "08": "京都",
        "09": "阪神",
        10: "小倉",
        30: "門別",
        33: "帯広",
        35: "盛岡",
        36: "水沢",
        42: "浦和",
        43: "船橋",
        44: "大井",
        45: "川崎",
        46: "金沢",
        47: "笠松",
        48: "名古屋",
        50: "園田",
        51: "姫路",
        54: "高知",
        55: "佐賀",
      },
      narFlag: false,
    };
  },
  methods: {
    getData: async function () {
      document.body.className = "";
      let self = this;
      self.selectedYear = self.selectedYear.replace("年", "");
      const placeList = self.narFlag
        ? [
            "33",
            "30",
            "35",
            "36",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "50",
            "51",
            "54",
            "55",
          ]
        : ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"];
      let tags = [];
      const data = await getDataCommon(self.selectedYear, "1", placeList);
      for (let i = 0; i < placeList.length; i++) {
        const dis = placeList[i];
        if (data.tags) {
          const result = data.tags.filter(
            (value) => String(value.item) === dis
          );
          if (result.length > 0) tags.push(result[0]);
          else tags.push({ item: dis });
        } else tags.push({ item: dis });
      }
      data.tags = tags;
      self.dataList = data;
      self.selectedYear = self.selectedYear + "年";
    },
    getPlace(place) {
      return this.getPlaces[place];
    },
  },
  mounted() {
    let self = this;
    self.narFlag = isNar() === 1

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none");
      }
    }

    callRetrieveCourse(function (courseId) {
      if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
        location.href = HOST + JOINPATH;
      } else {
        self.yearList = getYear(self.narFlag);
        self.getData();
        document.body.className = "status-loaded";
      }
    });
  },
};
</script>

<style lang="scss" src="../../assets/css/balance/style.scss" scoped></style>
