<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  SUCCESS,
  isDismissDialog,
  isNar,
  isAndroid,
  getUserAgent,
  isSugotoku,
} from "../../../assets/js/common";

import {
  HOST,
  PATH,
  BACKWORDPATH,
  METHOD_FORMATION,
  TOB_BRACKET_EXACTA,
} from "../../../assets/js/define";

import raceOdds from "../../../functions/odds/race-odds";
import raceDeadline from "../../../functions/odds/race-deadline_countdown";
import SetBetConfModal from "../../../components/utils/SetBetConfModal.vue";
import {useRoute, useRouter} from "vue-router";

const {setRaceBetByArray, loadOddsWin, retrieveOdds, sortDoubleOdds, oddsClass} = raceOdds();
const {TimerCount} = raceDeadline();

let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let deadLineJudge = ref(false);
let loadedPage = ref(false)
let oddsUpdInfo = ref("");
let isOddsInfo = ref(false);
let cancelList = reactive([]);
const activeSortId = ref("sort_num");
const sortType = ref(3);
const checkedHorse = reactive({});
const router = useRouter();
const isWebView = ref(false);
const isSugotokuMember = ref(false);

let params = {};

const props = defineProps({
  raceDORVal: String,
  raceTrackCdVal: String,
  raceNumVal: String,
  deadLineJudge: Boolean,
});

let time = ref("");

const route = useRoute();
watch(route, (from, to) => {
  clearSelected();
  loadPromisingBet();
  doInit(to.query);
})

onMounted(async () => {
  document.body.className = "";
  params = getUrlVars();
  isWebView.value = getUserAgent();
  isSugotokuMember.value = isSugotoku();
  //パラメータチェック
  if (checkUrlVars(params)) {
    loadPromisingBet()
    await doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});

//チェックリスト関連
let checkList1 = reactive({});
let checkList2 = reactive({});
let checkedSum1 = ref(0);
let checkedSum2 = ref(0);
let checkAllJudge1 = ref(false);
let checkAllJudge2 = ref(false);
let check1StorageArray = reactive([])
let check2StorageArray = reactive([])
const loadPromisingBet = () => {
  const check1ListStorage = localStorage.getItem('bracket_exacta_formation_1' + location.search)
  const check2ListStorage = localStorage.getItem('bracket_exacta_formation_2' + location.search)
  if(check1ListStorage) check1StorageArray = JSON.parse(check1ListStorage)
  if(check2ListStorage) check2StorageArray = JSON.parse(check2ListStorage)
}
//チェックフォーメーションアクション時
const checkChange = (n, num) => {
  if (n == 1) {
    if (checkList1[num] === true) {
      checkList1[num] = false;
      check1StorageArray?.splice(check1StorageArray?.indexOf(String(num)),1)
    } else {
      checkList1[num] = true;
      check1StorageArray?.push(String(num))
    }
  } else {
    if (checkList2[num] === true) {
      checkList2[num] = false;
      check2StorageArray?.splice(check2StorageArray?.indexOf(String(num)),1)
    } else {
      checkList2[num] = true;
      check2StorageArray?.push(String(num))
    }
  }
  localStorage.setItem('bracket_exacta_formation_1' + location.search, JSON.stringify(check1StorageArray))
  localStorage.setItem('bracket_exacta_formation_2' + location.search, JSON.stringify(check2StorageArray))
  setSelected(n);
};

const checkedHorseSum = (n) => {
  let checkedNum = 0;
  if (n == 1) {
    for (let i = 0; i < Object.keys(checkList1).length; i++) {
      if (checkList1[i] === true) {
        checkedNum++;
      }
    }
    checkedSum1.value = checkedNum;
  } else if (n == 2) {
    for (let i = 0; i < Object.keys(checkList2).length; i++) {
      if (checkList2[i] === true) {
        checkedNum++;
      }
    }
    checkedSum2.value = checkedNum;
  }
};
const checkedAll = (n) => {
  if (n == 1) {
    if (checkAllJudge1.value == true) {
      for (let i = 0; i < Object.keys(checkList1).length; i++) {
        checkList1[i] = false;
        check1StorageArray?.splice(check1StorageArray?.indexOf(String(i)),1)
      }
      checkAllJudge1.value = false;
    } else {
      for (let i = 0; i < Object.keys(checkList1).length; i++) {
        if (cancelList[i] === true) {
          //取消・中止はチェックしない
          checkList1[i] = false;
        } else {
          checkList1[i] = true;
          if (check1StorageArray?.includes(String(i))) continue
          check1StorageArray?.push(String(i))
        }
      }
      checkAllJudge1.value = true;
    }
    localStorage.setItem('bracket_exacta_formation_1' + location.search, JSON.stringify(check1StorageArray))
    checkedHorseSum(1);
  } else {
    if (checkAllJudge2.value == true) {
      for (let i = 0; i < Object.keys(checkList2).length; i++) {
        checkList2[i] = false;
        check2StorageArray?.splice(check2StorageArray?.indexOf(String(i)),1)
      }
      checkAllJudge2.value = false;
    } else {
      for (let i = 0; i < Object.keys(checkList2).length; i++) {
        if (cancelList[i] === true) {
          //取消・中止はチェックしない
          checkList2[i] = false;
        } else {
          checkList2[i] = true;
          if (check2StorageArray?.includes(String(i))) continue
          check2StorageArray?.push(String(i))
        }
      }
      checkAllJudge2.value = true;
    }
    localStorage.setItem('bracket_exacta_formation_2' + location.search, JSON.stringify(check2StorageArray))
    checkedHorseSum(2);
  }
  setSelected(n);
};

// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkList1,() => {
  const checkLists = Object.values(checkList1)
  if(checkLists.length) checkAllJudge1.value = checkLists.find(check => check === false) === undefined
},{immediate:true})
watch(checkList2,() => {
  const checkLists = Object.values(checkList2)
  if(checkLists.length) checkAllJudge2.value = checkLists.find(check => check === false) === undefined
},{immediate:true})

// 選択馬番表示
let selectedNum = reactive([]);

let info = reactive([]);
const onlyChecked = (checkList) => {
  let trueArr = [];
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    if (checkList[i] === true) {
      trueArr.push(i + 1);
    }
  }
  return trueArr;
};
let checkNumInfo = reactive([]);
let oddsTable = reactive([]);
const setSelected = (n) => {
  info.splice(0);
  let horse1 = 0;
  let horse2 = 0;
  let conf = 0;
  checkNumInfo.splice(0);
  Object.keys(checkList1).forEach((f) => {
    if (checkList1[f]) {
      Object.keys(checkList2).forEach((s) => {
        const bracketNum = Number(s) + 1;
        const bracketData = bracketArray.filter(bracket => bracket.waku === bracketNum && bracket.abnormalDiv === '0');
        if (!checkList2[s] || (checkList2[s] && f === s && bracketData.length < 2)) {
          return;
        }
        horse1 = String(Number(f) + 1);
        horse2 = String(Number(s) + 1);

        conf = info.findIndex(
          (i) => i.horse1 === horse1 && i.horse2 === horse2
        );
        if (conf == -1) {
          info.push({
            horse1: horse1,
            horse2: horse2,
            odds: oddsJson["Odds" + horse1 + "_" + horse2],
          });
        }
      });
    }
  });
  checkNumInfo = Object.assign(checkNumInfo, info);
  checkNumInfo.sort(sortDoubleOdds(sortType.value));
  oddsTable.splice(0);
  checkedHorseSum(n);
  setOdds();
};

const isShowMore = ref(false);
const showLimit = 50;
const setOdds = () => {
  //初回は現在表示しているところから、50件分のHTMLを表示
  //もっと見るを押下するごとに+50件表示させる
  const nowOddsTableLength = oddsTable.length;

  for (
    let i = nowOddsTableLength;
    i < checkNumInfo.length && i < nowOddsTableLength + showLimit;
    i++
  ) {
    oddsTable.push(checkNumInfo[i]);
  }

  if (checkNumInfo.length === oddsTable.length) {
    isShowMore.value = false;
  } else {
    isShowMore.value = true;
  }
};

//ソートを変更
const changeSort = (id) => {
  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_num") {
      if (sortType.value === 3) {
        sortType.value = 4;
      } else {
        sortType.value = 3;
      }
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      if (sortType.value === 5) {
        sortType.value = 6;
      } else {
        sortType.value = 5;
      }
      activeSortId.value = "sort_odds";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを昇順でセットする
    if (id === "sort_num") {
      sortType.value = 3;
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      sortType.value = 5;
      activeSortId.value = "sort_odds";
    }
  }
  setSelected(0);
};

// horses
let horses = reactive([]);
let oddsJson = reactive({});
const doInit = async (params) => {
  //オッズ情報取得
  retrieveOdds(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum,
    TOB_BRACKET_EXACTA,
    function (result) {
      oddsJson = result;
    }
  );

  //枠単オッズ読み込み
  loadOddsBracketExacta(params.DOR, params.RacetrackCd, params.RaceNum);
};

//レース詳細情報を取得する
let bracketArray = reactive([]);
let oddsInfo = reactive([]);
let wakuRow = reactive([]);
const oddsUpInfo = ref("");
const loadOddsBracketExacta = async (dor, racetrackCd, raceNum, callback) => {
  //振り直したOddsIdで単勝オッズを設定
  const oddsList = await loadOddsWin(dor, racetrackCd, raceNum);
  oddsInfo.splice(0, oddsInfo.length);
  for (let i = 0; i < oddsList.odds.length; i++) {
    oddsInfo.push(oddsList.odds[i]);
  }
  //出馬表から取消・除外などの情報を取得
  //パラメータからAPIコールを生成
  let req =
    HOST +
    PATH +
    "RaceInfo/candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      let intEntryNum = parseInt(json["EntryNum"], 10);
      let entryHorsesArray = json["EntryHorses"];

      let limitHorseNo = [];
      limitHorseNo.push("dummy");
      limitHorseNo.push([0, 1, 0, 0, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 0, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 8]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 9]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 8, 10]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 7, 9, 11]);
      limitHorseNo.push([0, 1, 2, 3, 4, 6, 8, 10, 12]);
      limitHorseNo.push([0, 1, 2, 3, 5, 7, 9, 11, 13]);
      limitHorseNo.push([0, 1, 2, 4, 6, 8, 10, 12, 14]);
      limitHorseNo.push([0, 1, 3, 5, 7, 9, 11, 13, 15]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 14, 16]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 14, 17]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 15, 18]);

      //出頭数から上記の表のどれを使用するか決定
      let limitHorseNoArray = limitHorseNo[intEntryNum];
      let countNum = 0;
      //全馬について
      bracketArray.splice(0, bracketArray.length);
      for (let waku = 1; waku <= 8; waku++) {
        try {
          let isCancel = false;

          let row = 0;
          //前の枠との差が１頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 1) {
            const horse1 = entryHorsesArray[limitHorseNoArray[waku] - 1];
            const index = horse1['HorseNum'] - 1;
            row = 1;

            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3" || !oddsInfo[index]) {
              isCancel = true;
            }
            bracketArray.push({
              waku: waku,
              horseNum: horse1["HorseNum"],
              horseName: horse1["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse1["AbnormalDiv"],
            });
          }

          //前の枠との差が２頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 2) {
            let clancelCount = 0;
            let horse1 = entryHorsesArray[limitHorseNoArray[waku - 1]];
            let horse2 = entryHorsesArray[limitHorseNoArray[waku - 1] + 1];
            const index1 = horse1['HorseNum'] - 1;
            const index2 = horse2['HorseNum'] - 1;
            row = 2;
            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3" || !oddsInfo[index1]) {
              clancelCount++;
            }
            if (horse2["AbnormalDiv"] == "1" || horse2["AbnormalDiv"] == "3" || !oddsInfo[index2]) {
              clancelCount++;
            }

            if (clancelCount > 0) {
              if (clancelCount == 2) {
                //チェック不可
                isCancel = true;
              }
            }

            bracketArray.push({
              waku: waku,
              horseNum: horse1["HorseNum"],
              horseName: horse1["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse1["AbnormalDiv"],
            });

            bracketArray.push({
              waku: waku,
              horseNum: horse2["HorseNum"],
              horseName: horse2["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse2["AbnormalDiv"],
            });
          }

          //前の枠との差が３頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 3) {
            let clancelCount = 0;
            let horse1 = entryHorsesArray[limitHorseNoArray[waku - 1]];
            let horse2 = entryHorsesArray[limitHorseNoArray[waku - 1] + 1];
            let horse3 = entryHorsesArray[limitHorseNoArray[waku - 1] + 2];
            const index1 = horse1['HorseNum'] - 1;
            const index2 = horse2['HorseNum'] - 1;
            const index3 = horse3['HorseNum'] - 1;
            row = 3;
            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3" || !oddsInfo[index1]) {
              clancelCount++;
            }
            if (horse2["AbnormalDiv"] == "1" || horse2["AbnormalDiv"] == "3" || !oddsInfo[index2]) {
              clancelCount++;
            }
            if (horse3["AbnormalDiv"] == "1" || horse3["AbnormalDiv"] == "3" || !oddsInfo[index3]) {
              clancelCount++;
            }

            if (clancelCount > 1) {
              if (clancelCount == 3) {
                //チェック不可
                isCancel = true;
              }
            }

            bracketArray.push({
              waku: waku,
              horseNum: horse1["HorseNum"],
              horseName: horse1["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse1["AbnormalDiv"],
            });

            bracketArray.push({
              waku: waku,
              horseNum: horse2["HorseNum"],
              horseName: horse2["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse2["AbnormalDiv"],
            });

            bracketArray.push({
              waku: waku,
              horseNum: horse3["HorseNum"],
              horseName: horse3["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse3["AbnormalDiv"],
            });
          }
          cancelList[countNum] = isCancel;
          wakuRow[countNum] = row;
          checkList1[countNum] = check1StorageArray?.includes(String(countNum)) ? true : false;
          checkList2[countNum] = check2StorageArray?.includes(String(countNum)) ? true : false;
          countNum++;
        } catch (e) {
          console.log(e);
        }
      }
    }
  });

  req =
    HOST +
    PATH +
    "Odds/bracketExactaOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS && json.length == 1) {
      const displayData = json[0];
      if (displayData["OddsUpdInfo"] != null)
        oddsUpInfo.value = displayData["OddsUpdInfo"];
    }
  });
};

// 買い目登録
const setRaceBetWin = () => {
  let setRaceBetParams = getUrlVars();
  let openWindow
  if (isDismissDialog() == 1 && !isAndroid() && !getUserAgent() && !isSugotoku()) {
    openWindow = window.open('', '_blank')
  }

  if (props.raceDORVal != null) {
    setRaceBetParams["DOR"] = props.raceDORVal;
  }
  if (props.raceTrackCdVal != null) {
    setRaceBetParams["RacetrackCd"] = props.raceTrackCdVal;
  }
  if (props.raceNumVal != null) {
    setRaceBetParams["RaceNum"] = props.raceNumVal;
  }
  let mainCheckFlag = "";
  let isFirst = false;
  let isSecond = false;

  // 1着馬
  for (let i = 0; i < 18; i++) {
    if (checkList1[i] === true) {
      mainCheckFlag += "1";
      isFirst = true;
    } else {
      mainCheckFlag += "0";
    }
  }

  // 2着馬
  for (let i = 0; i < 18; i++) {
    if (checkList2[i] === true) {
      mainCheckFlag += "1";
      isSecond = true;
    } else {
      mainCheckFlag += "0";
    }
  }
  if (isFirst && isSecond) {
    let betArray = new Array();
    let doFlgArray = new Array();
    let methodType = METHOD_FORMATION;
    betArray.push(mainCheckFlag);
    doFlgArray.push(true);
    //順番に登録させるため再帰的に登録
    setRaceBetByArray(
      setRaceBetParams,
      TOB_BRACKET_EXACTA,
      methodType,
      betArray,
      doFlgArray,
      function () {
        if (isDismissDialog() != 1) {
          //モーダルウィンドウ表示
          raceBetCheck.value = true;
          modalBetOpen.value = true;
        } else {
          if (isAndroid() || getUserAgent() || isSugotoku()) {
            router.push("/purchase/spat4")
          }
          else {
            openWindow.location.href = HOST + "purchase/spat4"
          }
        }
      }
    );
    check1StorageArray.splice(0)
    check2StorageArray.splice(0)
    localStorage.removeItem('exacta_formation_1' + location.search)
    localStorage.removeItem('exacta_formation_2' + location.search)
  } else {
    alert("1着馬と2着馬を選択してください");
  }
};

// 買い目削除
const deleteSelected = () => {
  const result = confirm('枠単/フォーメーションに対して全ての買い目を削除しますか？')
  if (result) {
    clearStorage()
    clearSelected()
  }
}

const clearStorage = () => {
  localStorage.removeItem('bracket_exacta_formation' + location.search)
  localStorage.removeItem('bracket_exacta_formation_couple' + location.search)
}

const clearSelected = () => {
  for (let i = 0; i < Object.keys(checkList1).length; i++) {
    checkList1[i] = false;
  }
  for (let i = 0; i < Object.keys(checkList2).length; i++) {
    checkList2[i] = false;
  }
  check1StorageArray.splice(0)
  check2StorageArray.splice(0)
  checkAllJudge1.value = false
  checkAllJudge2.value = false
  setSelected(1);
  setSelected(2);
}

watch(oddsInfo, (val) => {
  loadedPage.value = true
  document.body.className = "status-loaded";
});
</script>

<template>
  <section class="layout-section" v-if="loadedPage">
    <div class="layout-title">
      <h2 class="heading-circle -padding">枠単 / フォーメーション</h2>
      <div class="nav">
        <span v-if="props.deadLineJudge" class="status">締め切り間近</span>
        <span :class="[oddsUpInfo ? 'icon-kakutei' : '']">{{
            oddsUpInfo
          }}</span>
      </div>
    </div>
    <table class="table-grid -fit -middle -center -odds">
      <thead>
      <tr>
        <th>枠番</th>
        <th>馬番</th>
        <th>馬名</th>
        <th>単オッズ</th>
        <th class="ranking">
          1着馬<br />
          <span class="form-checkbox">
            <label>
              <input
                type="checkbox"
                value=""
                name=""
                @click="checkedAll(1)"
                v-model="checkAllJudge1"/><i></i>
            </label>
          </span>
        </th>

        <th class="ranking">
          2着馬<br />
          <span class="form-checkbox">
            <label>
              <input
                type="checkbox"
                value=""
                name=""
                @click="checkedAll(2)"
                v-model="checkAllJudge2"/><i></i>
            </label>
          </span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(bracket, index) in bracketArray"
        :key="index"
        :class="[
            index === 0
              ? bracket.waku === bracketArray[index + 1].waku
                ? '-rows'
                : ''
              : bracket.waku != bracketArray[index - 1].waku
              ? bracket.waku === bracketArray[index + 1].waku
                ? '-rows'
                : ''
              : '',
          ]"
      >
        <td v-if="bracket.rows === 1" :class="'bgcolor-waku0' + bracket.waku">
          {{ bracket.waku }}
        </td>
        <td
          v-else-if="
              (index === 0 && bracket.waku === bracketArray[index + 1].waku) ||
              (index != 0 &&
                bracket.waku != bracketArray[index - 1].waku &&
                bracket.waku === bracketArray[index + 1].waku)
            "
          :class="'bgcolor-waku0' + bracket.waku"
          :rowspan="bracket.rows"
        >
          {{ bracket.waku }}
        </td>

        <td :style="[bracket.isCancel ? 'background-color:#ccc' : '']">
          <i :class="['icon-umaban -waku0' + bracket.waku]">{{
              bracket.horseNum
            }}</i>
        </td>
        <td
          class="name"
          :style="[bracket.isCancel ? 'background-color:#ccc' : '']">
          {{ bracket.horseName }}
        </td>
        <td
          class="-right"
          :class="[bracket.waku != bracketArray[index + 1] ? '-last' : '']"
          :style="[bracket.isCancel ? 'background-color:#ccc' : '']">
          <div v-if="!oddsInfo[index]"
               class="txt-odds -other">
          </div>
          <div class="txt-odds -other"
               v-else-if="bracket.abnormalDiv === '1' || bracket.abnormalDiv === '3'">
            <span v-if="bracket.abnormalDiv === '1'">取消</span>
            <span v-else-if="bracket.abnormalDiv === '3'">除外</span>
          </div>
          <div
            v-else
            class="txt-odds"
            :class="oddsClass(oddsInfo[index])">
            <span>{{ oddsInfo[index] }}</span>
          </div>
        </td>
        <td v-if="bracket.rows === 1" :rowspan="bracket.rows"
            :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '']">
            <span class="form-checkbox">
              <label v-if="!bracket.isCancel  && oddsInfo[index]">
                <input
                  type="checkbox"
                  @click="checkChange(1, bracket.waku - 1)"
                  v-model="checkList1[bracket.waku - 1]"
                  value=""
                  name=""/>
                <i></i>
              </label>
            </span>
        </td>
        <td v-else-if="
              (index === 0 && bracket.waku === bracketArray[index + 1].waku) ||
              (index != 0 &&
                bracket.waku != bracketArray[index - 1].waku &&
                bracket.waku === bracketArray[index + 1].waku)"
            :rowspan="bracket.rows"
            class="-row"
            :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '']"
            ref="rm">
            <span class="form-checkbox">
              <label v-if="!bracket.isCancel  && oddsInfo[index]">
                <input
                  type="checkbox"
                  @click="checkChange(1, bracket.waku - 1)"
                  v-model="checkList1[bracket.waku - 1]"
                  value=""
                  name="" />
                <i></i>
              </label>
            </span>
        </td>
        <td v-if="bracket.rows === 1" :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '']">
          <span class="form-checkbox">
              <label v-if="!bracket.isCancel  && oddsInfo[index]">
                <input
                  type="checkbox"
                  @click="checkChange(2, bracket.waku - 1)"
                  v-model="checkList2[bracket.waku - 1]"
                  value=""
                  name=""/>
                <i></i>
              </label>
            </span>
        </td>
        <td
          v-else-if="
              (index === 0 && bracket.waku === bracketArray[index + 1].waku) ||
              (index != 0 &&
                bracket.waku != bracketArray[index - 1].waku &&
                bracket.waku === bracketArray[index + 1].waku)"
          :rowspan="bracket.rows"
          :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '']">
            <span class="form-checkbox">
              <label v-if="!bracket.isCancel  && oddsInfo[index]">
                <input
                  type="checkbox"
                  @click="checkChange(2, bracket.waku - 1)"
                  v-model="checkList2[bracket.waku - 1]"
                  value=""
                  name="" />
                <i></i>
              </label>
            </span>
        </td>
      </tr>
      </tbody>
    </table>

    <nav class="nav-group -right">
      <span @click="deleteSelected" class="btn-basic -delete">
        削除
      </span>
    </nav>
    <div class="container-total">
      <dl class="amount">
        <dt>点数合計</dt>
        <dd>
          <span class="num">{{ checkNumInfo.length }}</span>点
        </dd>
      </dl>
      <div v-show="isOddsInfo" @click="setRaceBetWin" class="btn-basic">
        買い目登録
      </div>
    </div>

    <div class="container-wrapper -pad" v-show="checkNumInfo.length">
      <h3 class="heading-bar _center">フォーメーション　選択枠番</h3>
      <table class="table-grid -middle -selectednumber">
        <tr>
          <th class="_nowrap">1頭目</th>
          <td>
            <ul class="list-umaban">
              <li v-for="a in onlyChecked(checkList1)" :key="a">
                <i :class="`icon-umaban -waku0${a}`">
                  {{ a }}
                </i>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th class="_nowrap">2頭目</th>
          <td>
            <ul class="list-umaban">
              <li v-for="a in onlyChecked(checkList2)" :key="a">
                <i :class="`icon-umaban -waku0${a}`">
                  {{ a }}
                </i>
              </li>
            </ul>
          </td>
        </tr>
      </table>

      <nav class="nav-group -right">
        <span
          @click="deleteSelected"
          class="btn-basic -delete">
          削除
        </span>
      </nav>
      <div class="icon-next"></div>

      <table class="table-grid -middle -padding -center">
        <thead>
        <tr>
          <th>
            枠番<i
            class="filter-sort"
            :class="sortType === 3 ? '-asc' : sortType === 4 ? '-desc' : ''"
            @click="changeSort('sort_num')"
          ></i>
          </th>
          <th>
            オッズ<i
            class="filter-sort"
            :class="sortType === 5 ? '-asc' : sortType === 6 ? '-desc' : ''"
            @click="changeSort('sort_odds')"
          ></i>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(conbi, index) in oddsTable" :key="index">
          <td>
            <ul class="list-kaimoku -arrow">
              <li>
                <i class="icon-umaban" :class="'-waku0' + conbi.horse1">{{
                    conbi.horse1
                  }}</i>
              </li>
              <li>
                <i class="icon-umaban" :class="'-waku0' + conbi.horse2">{{
                    conbi.horse2
                  }}</i>
              </li>
            </ul>
          </td>
          <td v-if="!conbi.odds" class="txt-odds" :class="oddsClass('---.-')">
            ---.-
          </td>
          <td v-else class="txt-odds" :class="oddsClass(conbi.odds)">
            {{ conbi.odds }}
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="isShowMore" class="nav-group">
        <span class="btn-basic" @click="setOdds"> もっと見る </span>
      </div>

      <div class="container-total -noborder">
        <dl class="amount">
          <dt>点数合計</dt>
          <dd>
            <span class="num">{{ checkNumInfo.length }}</span
            >点
          </dd>
        </dl>
        <div @click="setRaceBetWin" class="btn-basic">買い目登録</div>
      </div>
    </div>
    <div class="container-schedule">
      <router-link to="/purchase/spat4/" v-if="isWebView || isSugotokuMember" class="btn-basic -normal">購入予定一覧</router-link>
      <a href="/purchase/spat4/" v-else target="_blank" class="btn-basic -normal">購入予定一覧</a>
      <p class="_caption">
        ※出馬表・結果・成績・オッズなどのデータについては、<br/>必ず主催者発表のものと照合し確認してください。
      </p>
    </div>
  </section>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading"/>
    </div>
  </div>
  <SetBetConfModal
    @closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
    :isSpat4="true"
  ></SetBetConfModal>
</template>

<style
  lang="scss"
  src="../../../assets/css/race/odds/style.scss"
  scoped
></style>
