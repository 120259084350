import {getMenmerCourse, getUserAgent, isSugotoku, PAYINGECONOMY, PAYINGNONE} from './common';
import axios from 'axios';
import {JRA_WP_HOST} from './define';

// 非会員専用エリアを非表示（非会員且つ月額Webのみ表示）
const hiddenNonMember = () => {
  if (getMenmerCourse() === PAYINGNONE && !getUserAgent()) return;

  const displayOnlyNonMember = document.getElementsByClassName("is-non-member");
  for (let i = 0; i < displayOnlyNonMember.length; i++) {
    displayOnlyNonMember[i].style.display = "none"
  }
}

// スゴ得会員専用エリアを非表示（スゴ得Webのみ表示）
const hiddenSugotoku = () => {
  if (isSugotoku() && !getUserAgent()) return;

  const displayOnlySugotoku = document.getElementsByClassName("is-sugotoku");
  for (let i = 0; i < displayOnlySugotoku.length; i++) {
    displayOnlySugotoku[i].style.display = "none"
  }
}

// 月額Web用エリアを非表示（スゴ得ユーザー以外表示）
const hiddenMonthlyWeb = () => {
  if (!isSugotoku()) return;

  const displayOnlyMonthlyWeb = document.getElementsByClassName("is-monthly-web");
  for (let i = 0; i < displayOnlyMonthlyWeb.length; i++) {
    displayOnlyMonthlyWeb[i].style.display = "none"
  }
}

// 予想販売バナーを取得
const fetchPredictedSalesBanner = async (predictedSalesBanner, key) => {
  let courseName = 'member-1000';
  if (isSugotoku()) {
    courseName = getUserAgent() ?
      'member-sugotoku-app' :
      'member-sugotoku';
  } else {
    const userCourse = getMenmerCourse();
    if(userCourse === PAYINGNONE) courseName = 'member-none';
    if(userCourse === PAYINGECONOMY) courseName = 'member-300';
  }

  predictedSalesBanner.value = await axios
    .get(JRA_WP_HOST + 'wp-json/wp/v2/pages?slug=predicted-sales')
    .then((response) => {
      const {acf} = response.data[0];
      if (!acf || !acf.kind.includes(courseName)) return ''

      return acf.banner[key] ?? '';
    })
    .catch((error) => {
      console.log(error);
      return ''
    });
}

export {
  hiddenNonMember,
  hiddenSugotoku,
  hiddenMonthlyWeb,
  fetchPredictedSalesBanner,
}