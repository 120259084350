<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import {useRoute} from "vue-router";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  setDismissDialog,
  getUserId,
  SUCCESS,
  isDismissDialog,
  getCourse,
  PAYINGECONOMY,
  PAYINGNONE,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  isNar,
  isAndroid,
  getUserAgent,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  TOB_WIN,
  METHOD_NORMAL,
} from "../../assets/js/define";

import MyStampModal from "../../components/utils/MyStampModal.vue";
import SetBetConfModal from "../../components/utils/SetBetConfModal.vue";
import OddsTabs from "../../components/odds/OddTabs.vue";
import RaceHeader from "../../components/race/RaceHeader.vue";
import WinOddsModal from "../../components/utils/WinOddsModal.vue";

import myMark from "../../functions/odds/race-mymark";
import raceOdds from "../../functions/odds/race-odds";
const { setRaceBetByArray, raceResultCheck } = raceOdds();
const { loadMyMarkForDrop2, setMyMark } = myMark();

import {useStoreRaceCommon} from "../../stores/race-common-states";
import {storeToRefs} from "pinia";
const storeRaceCommon = useStoreRaceCommon();
const {
  raceDORVal,
  raceTrackCdVal,
  raceNumVal,
  deadLineVal,
} = storeToRefs(storeRaceCommon);

let checkAllJudge = ref(false);
let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let loadedPage = ref(false)
let oddsUpdInfo = ref("");
const checkedHorse = reactive({});
const currentTab = "win";

let params = {};
const bracketFlag = ref(true);

// time
let time = ref("");

const route = useRoute();

watch(route, (from, to) => {
  params = to.query;
  loadPromisingBet();
  fetchOdds(to.query);
  doInit(to.query);
});

onMounted(() => {
  document.body.className = "";
  Object.assign(params, getUrlVars());
  //パラメータチェック
  if (checkUrlVars(params)) {
    loadPromisingBet();
    fetchOdds(params);
    doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});

const fetchOdds = async (p) => {
  bracketFlag.value = true;
  var req =
    HOST +
    PATH +
    "Odds/bracketQuinellaOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    p.DOR +
    "&RacetrackCd=" +
    p.RacetrackCd +
    "&RaceNum=" +
    p.RaceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      if (json[0].SaleDiv === 0) bracketFlag.value = false
    }
  });
}

let stamp = reactive({});
const stampClass = (num) => {
  if (stamp[num].selectedNum == 0) {
    return "stamp-mark -none";
  } else if (stamp[num].selectedNum == 1) {
    return "stamp-mark -honmei";
  } else if (stamp[num].selectedNum == 2) {
    return "stamp-mark -taikou";
  } else if (stamp[num].selectedNum == 3) {
    return "stamp-mark -tanana";
  } else if (stamp[num].selectedNum == 4) {
    return "stamp-mark -renka";
  } else if (stamp[num].selectedNum == 5) {
    return "stamp-mark -hoshi";
  } else return "stamp-mark -none";
};
const oddsClass = (val) => {
  if (val == "---.-") {
    return "txt-odds";
  } else if (val > 0 && val < 10) {
    return "txt-odds -no1";
  } else if (val > 0 && val < 100) {
    return "txt-odds -no2";
  } else return "txt-odds -other";
};

//チェックリスト関連
let checkList = reactive({});
let checkedSum = ref(0);
let checkStorageArray = reactive([])
const loadPromisingBet = () => {
  const checkListStorage = localStorage.getItem('win' + location.search)
  if (checkListStorage) checkStorageArray = JSON.parse(checkListStorage)
}
const checkChange = (num) => {
  if (checkList[num] === true) {
    checkList[num] = false;
    checkStorageArray?.splice(checkStorageArray?.indexOf(String(num)),1)
  } else {
    checkList[num] = true;
    checkStorageArray?.push(String(num))
  }
  localStorage.setItem('win' + location.search, JSON.stringify(checkStorageArray))
  checkedHorseSum();
};
const checkedHorseSum = () => {
  let checkedNum = 0;
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    if (checkList[i] === true) {
      checkedNum++;
    }
  }

  checkedSum.value = checkedNum;
};
const checkedAll = () => {
  if (checkAllJudge.value == true) {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      checkList[i] = false;
      checkStorageArray?.splice(checkStorageArray?.indexOf(String(i)),1)
    }
    checkAllJudge.value = false;
  } else {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      if (horses[i].AbnormalDiv === "1" || horses[i].AbnormalDiv === "3" || !horses[i].Odds) {
        //取消・中止はチェックしない
        checkList[i] = false;
      } else {
        checkList[i] = true;
        if (checkStorageArray?.includes(String(i))) continue
        checkStorageArray?.push(String(i))
      }
    }
    checkAllJudge.value = true;
  }
  localStorage.setItem('win' + location.search, JSON.stringify(checkStorageArray))
  checkedHorseSum();
};
// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkList,() => {
  const checkLists = Object.values(checkList)
  if(checkLists.length) checkAllJudge.value = checkLists.find(check => check === false) === undefined
},{immediate:true})
// modal
let left = ref("");
let top = ref("");
let stampNav = ref("");

const openModal = (event, win) => {
  document.body.className = "status-loaded status-haslayerbg status-showstamp";
  // x座標
  left.value = event.pageX;
  // y座標
  top.value = event.pageY;
  stamp[win - 1].status = true;
  stampNav.value = "top: " + top.value;
};

const closeStampModal = (win) => {
  if (stamp[win.num].selectedNum != win.selectedNum) {
    const selectedIndex = horses.findIndex(horse => horse.HorseNum === win.num + 1);
    horses[selectedIndex].SelectedNum = win.selectedNum;
    //my印を新しくセットした場合は、my印を更新する
    stamp[win.num].selectedNum = win.selectedNum;

    setMyMark(
      getUserId(),
      raceDORVal.value,
      raceTrackCdVal.value,
      raceNumVal.value,
      win.num + 1,
      stamp[win.num].selectedNum
    );
  }
  stamp[win.num].status = false;
};

// horses
let horses = reactive([]);
let mark = reactive({});
let marks = reactive({});
const doInit = async (params) => {
  marks = await loadMyMarkForDrop2(
    getUserId(),
    params.DOR,
    params.RacetrackCd,
    params.RaceNum
  );
  raceResultCheck(params.DOR, params.RacetrackCd, params.RaceNum, function () {
    loadCandidacyTableForOddsBasic(
      params.DOR,
      params.RacetrackCd,
      params.RaceNum
    );
  });
  winOddsModal('exist');
};

//レース詳細情報を取得する
const loadCandidacyTableForOddsBasic = (
  dor,
  racetrackCd,
  raceNum,
  callback
) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "RaceInfo/candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == "success") {
      horses = Object.assign(horses, json["EntryHorses"]);

      //my印及びチェックボックスをデフォルト値をセット

      for (let i = 0; i < horses.length; i++) {
        let selectNum = 0;
        if (
          marks &&
          marks["MyNo" + (i + 1)] != undefined &&
          marks["MyNo" + (i + 1)] != "" &&
          marks["MyNo" + (i + 1)] != null
        ) {
          selectNum = marks["MyNo" + (i + 1)];
        }

        stamp[i] = { status: false, selectedNum: selectNum };
        // TODO:ローカルストレージみて更新する
        checkList[i] = checkStorageArray?.includes(String(i)) ? true : false;

        horses[i].SelectedNum = stamp[i].selectedNum;
      }
      checkedHorseSum();
      loadOddsWin(params.DOR, params.RacetrackCd, params.RaceNum);
    }
    if (callback != undefined) {
      callback();
    }
  });
};

//オッズを取得する
let oddsWin = reactive([]);
const loadOddsWin = (DOR, RacetrackCd, RaceNum) => {
  //パラメータからAPIコールを生成
  var req =
    HOST +
    PATH +
    "Odds/winTicketOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    DOR +
    "&RacetrackCd=" +
    RacetrackCd +
    "&RaceNum=" +
    RaceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      //データは常に１件が正しい。
      if (json.length == 1) {
        var displayData = json[0];
        if (displayData["OddsUpdInfo"] != null)
          oddsUpdInfo.value = displayData["OddsUpdInfo"];

        // オッズを格納
        for (let i = 0; i < horses.length; i++) {
          horses[i].Odds = displayData["Odds" + (i + 1)];
        }
        // 人気表示
        loadOddsPopularWin(json[0])
      } else {
        location.href =
            HOST +
            "race/card?DOR=" +
            DOR +
            "&RacetrackCd=" +
            RacetrackCd +
            "&RaceNum=" +
            RaceNum;
      }
    }
    loadedPage.value = true
    document.body.className = "status-loaded";
  });
};

let winOddsGraphData = reactive([]);
let winOddsGraphDate = reactive([]);
let winOddsGraphGradeCd = ref("");
let winOddsGraphDOR = ref("");
let winOddsGraphIsNar = ref(false);
let winOddsGraphIsNighter = ref(false);
let winOddsGraphRacetrackCd = ref("");
const modalOpen = ref(false);
let oddsButtonFlg = ref(true);

const winOddsModal = (type,targetHorseNum = null) => {
  const courseId = getCourse();
  //非会員は会員登録に遷移
  if (courseId === PAYINGECONOMY && type !== 'exist') {
    location.href = HOST + 'member/join_economy';
  } else if(courseId === PAYINGNONE && type !== 'exist') {
    location.href = HOST + 'member';
  } else {
    Object.assign(params, getUrlVars());
    //パラメータからAPIコールを生成
    if (checkUrlVars(params)) {
      var req =
        HOST +
        PATH +
        "Odds/winTicketOddsGraph.js" +
        BACKWORDPATH +
        "?DOR=" +
        params.DOR +
        "&RacetrackCd=" +
        params.RacetrackCd +
        "&RaceNum=" +
        params.RaceNum +
        "&NarFlag=" +
        isNar();
      getJSON(req, true, function (status, json) {
        winOddsGraphIsNar.value = isNar() === 1;
        if (status == SUCCESS && json.date.length) {
          const targetWinOddsData = {};
          Object.assign(targetWinOddsData, json);
          Object.assign(winOddsGraphDate, json.date);
          winOddsGraphData.splice(0);
          winOddsGraphGradeCd.value = targetWinOddsData.gradeCd;
          winOddsGraphDOR.value = params.DOR;
          winOddsGraphIsNighter.value = targetWinOddsData.isNighter === 1;
          winOddsGraphRacetrackCd.value = params.RacetrackCd;
          let horseNums = [];
          if(type === 'top3'){
            popularOdds.filter((popularOdd, index) => {
              if (popularOdd <= 3) {
                horseNums.push(index + 1);
              }
            });
          } else if(type === 'single'){
            horseNums.push(targetHorseNum);
          }
          for (let i = 0; i < targetWinOddsData.horse.length; i++) {
            if (horseNums.includes(targetWinOddsData.horse[i].umaban)) {
              winOddsGraphData.push({
                umaban: targetWinOddsData.horse[i].umaban,
                bracketNum: targetWinOddsData.horse[i].bracketNum,
                name: targetWinOddsData.horse[i].name,
                odds: targetWinOddsData.horse[i].odds,
              });
            }
          }
        } else if(type === 'exist' && !json.length){
          const currentDate = new Date();
          const today = currentDate.getFullYear() + ('0'+(currentDate.getMonth()+1)).slice(-2) + ('0'+currentDate.getDate()).slice(-2);
          if(params.DOR < today){
              oddsButtonFlg.value = false;
          }
        }
        if(type !== 'exist'){
          modalOpen.value = true;
        }
      });
    }
  }
};

//人気表示
let popularOdds = reactive([]);
// TODO: race-odds.jsの同関数と共通化
const loadOddsPopularWin = (displayData) => {
  //新しいjsonを作る
  var oddsObj = new Array();
  for (var i = 1; i <= 18; i++) {
    var id = "Odds" + i;
    displayData[id] = displayData[id] === "---.-" || displayData[id] === "0.0" ? 9999 : displayData[id];
    if (displayData[id]) {
      var obj = { name: id, Odds: parseFloat(displayData[id]) };
      oddsObj.push(obj);
    }
  }
  //オッズでソートする
  var sort_by = function (field, reverse, primer) {
    reverse = reverse ? -1 : 1;
    return function (a, b) {
      a = a[field] * 10;
      b = b[field] * 10;
      if (typeof primer != "undefined") {
        a = primer(a);
        b = primer(b);
      }
      if (a < b) return reverse * -1;
      if (a > b) return reverse * 1;
    };
  };
  oddsObj.sort(sort_by("Odds", false, parseInt));
  //人気表示
  let preOdds = 0.0;
  let prePopular = 1;
  let popular = 1;
  let popularNumber = [];
  for (let i = 0; i < oddsObj.length; i++) {
    let useOdds = oddsObj[i];
    //前のオッズと同じだった場合、同人気
    let targetNumber = 0
    if (i > 0 && oddsObj[i].Odds === preOdds) {
      targetNumber = prePopular;
    } else {
      targetNumber = popular;
      prePopular = popular
    }
    preOdds = oddsObj[i].Odds;
    popular++
    let num = useOdds.name.split("Odds")[1];
    popularNumber.push({
      horseNum: Number(num),
      popular: targetNumber
    });
  }

  //列順にスタックする
  for (let n = 0; n < popularNumber.length; n++) {
    let targetNumber = popularNumber.find(val => val.horseNum === n + 1);
    popularOdds.push(targetNumber.popular);
  }
};

//ソートを変更
const activeSortId = ref("sort_num");
const sortType = ref(1);

//ソートを変更
const changeSort = (id) => {
  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_num") {
      if (sortType.value === 2) {
        sortType.value = 1;
        horses.sort(sort_by("HorseNum", true, parseInt));
      } else {
        sortType.value = 2;
        horses.sort(sort_by("HorseNum", false, parseInt));
      }
      activeSortId.value = "sort_num";
    } else if (id === "sort_my") {
      if (sortType.value === 4) {
        sortType.value = 3;
        horses.sort(sort_by("SelectedNum", false, parseInt));
      } else {
        sortType.value = 4;
        horses.sort(sort_by("SelectedNum", true, parseInt));
      }
      activeSortId.value = "sort_my";
    } else if (id === "sort_odds") {
      if (sortType.value === 6) {
        sortType.value = 5;
        horses.sort(sort_by("Odds", true, parseInt));
      } else {
        sortType.value = 6;
        horses.sort(sort_by("Odds", false, parseInt));
      }
      activeSortId.value = "sort_odds";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを降順でセットする
    if (id === "sort_num") {
      sortType.value = 1;
      horses.sort(sort_by("HorseNum", true, parseInt));
      activeSortId.value = "sort_num";
    } else if (id === "sort_my") {
      sortType.value = 3;
      horses.sort(sort_by("SelectedNum", false, parseInt));
      activeSortId.value = "sort_my";
    } else if (id === "sort_odds") {
      sortType.value = 5;
      horses.sort(sort_by("Odds", true, parseInt));
      activeSortId.value = "sort_odds";
    }
  }
};

//ソート
let sort_by = function (field, reverse, primer) {
  reverse = reverse ? -1 : 1;
  const oddsSort = field === "Odds"
  return function (a, b) {
    a = a[field] * 10;
    b = b[field] * 10;
    if (field === "SelectedNum") {
      a = exchangeNum(a);
      b = exchangeNum(b);
    }
    if (typeof primer != "undefined") {
      a = oddsSort && (a <= 0 || isNaN(a)) ? 9999 * reverse * -1 : primer(a);
      b = oddsSort && (b <= 0 || isNaN(b)) ? 9999 * reverse * -1 : primer(b);
    }
    if (a > b) return reverse * -1;
    if (a < b) return reverse * 1;
  };
};

let exchangeNum = (num) => {
  switch (num) {
    case 10:
      return 50;
      break;
    case 20:
      return 40;
      break;
    case 30:
      return 30;
      break;
    case 40:
      return 20;
      break;
    case 50:
      return 10;
      break;
    default:
      return 0;
      break;
  }
};

//買い目登録
const setRaceBetWin = () => {
  const sum = checkedSum.value;
  let openWindow
  if (isDismissDialog() == 1 && !isAndroid() && !getUserAgent()) {
    openWindow = window.open('', '_blank')
  }

  if (sum != "0") {
    let setRaceBetParams = getUrlVars();
    // レースヘッダから情報を取得
    if (raceDORVal.value != null) {
      setRaceBetParams["DOR"] = raceDORVal.value;
    }
    if (raceTrackCdVal.value != null) {
      setRaceBetParams["RacetrackCd"] = raceTrackCdVal.value;
    }
    if (raceNumVal.value != null) {
      setRaceBetParams["RaceNum"] = raceNumVal.value;
    }

    const betArray = [
      "100000000000000000",
      "010000000000000000",
      "001000000000000000",
      "000100000000000000",
      "000010000000000000",
      "000001000000000000",
      "000000100000000000",
      "000000010000000000",
      "000000001000000000",
      "000000000100000000",
      "000000000010000000",
      "000000000001000000",
      "000000000000100000",
      "000000000000010000",
      "000000000000001000",
      "000000000000000100",
      "000000000000000010",
      "000000000000000001",
    ];

    let doFlgArray = [];
    for (var m = 0; m < 18; m++) {
      if (checkList[m] == true) {
        doFlgArray.push(true);
      } else {
        doFlgArray.push(false);
      }
    }
    //順番に登録させるため再帰的に登録
    setRaceBetByArray(
      setRaceBetParams,
      TOB_WIN,
      METHOD_NORMAL,
      betArray,
      doFlgArray,
      function () {
        if (isDismissDialog() != 1) {
          // AppPassはダイアログ非表示
          if (getCourse() == PAYINGAPPPASS) {
            //ダイアログ非表示フラグON
            setDismissDialog();
          } else {
            //モーダルウィンドウ表示
            raceBetCheck.value = true;
            modalBetOpen.value = true;
          }
        } else {
          var url = HOST + "purchase";
          if (
            (getCourse() == PAYINGSUGOTOKU ||
            getCourse() == PAYINGSUGOTOKU_NONE) && !getUserAgent()
          ) {
            url = location.pathname + "?url=" + HOST + "purchase";
          }

          if (isAndroid() || getUserAgent()) {
            location.href = url;
          }
          else { openWindow.location.href = url }
        }
      }
    );
    checkStorageArray.splice(0)
    localStorage.removeItem('win' + location.search)
  } else {
    alert("1頭もチェックされていません");
  }
};
// 買い目削除
const deleteSelected = () => {
  const result = confirm('単勝に対して全ての買い目を削除しますか？')
  if (result) {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      checkList[i] = false;
    }
    localStorage.removeItem('win' + location.search)
    checkStorageArray.splice(0)
    checkAllJudge.value = false
    checkedHorseSum();
  }
}
</script>

<template>
  <main class="layout-main">
    <RaceHeader />

    <!--  Odds Tabs -->
    <nav class="nav-multi">
      <OddsTabs
        v-if="Object.keys(params).length"
        :currentTab="currentTab"
        :params="params"
        :bracketFlag="bracketFlag"
      ></OddsTabs>
    </nav>
    <section class="layout-section" v-if="loadedPage">
      <div class="layout-title">
        <h2 class="heading-circle -padding">単勝</h2>
        <div class="nav">
          <i v-if="oddsButtonFlg" class="icon-race -oddsranking -haslabel" @click="winOddsModal('top3')">人気上位3頭</i>
          <span v-if="deadLineVal" class="status">締め切り間近</span>
          <span :class="[oddsUpdInfo ? 'icon-kakutei' : '']">{{
            oddsUpdInfo
          }}</span>
        </div>
      </div>
      <table class="table-grid -fit -middle -center -odds">
        <thead>
          <tr>
            <th class="mystamp">my印
              <i
                class="filter-sort"
                :class="sortType === 3 ? '-asc' : sortType === 4 ? '-desc' : ''"
                @click="changeSort('sort_my')"
              >
              </i>
            </th>
            <th>馬番
              <i
                class="filter-sort"
                :class="sortType === 1 ? '-asc' : sortType === 2 ? '-desc' : ''"
                @click="changeSort('sort_num')"
              ></i>
            </th>
            <th>馬名</th>
            <th>オッズ
              <i
                class="filter-sort"
                :class="sortType === 5 ? '-asc' : sortType === 6 ? '-desc' : ''"
                @click="changeSort('sort_odds')"
              ></i>
            </th>
            <th class="ranking">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    value=""
                    name=""
                    @click="checkedAll()"
                    v-model="checkAllJudge" /><i></i></label
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="h in horses"
            :key="h.horseNum"
            :class="[
              h.AbnormalDiv === '1' ? '-cancel' : '',
              h.AbnormalDiv === '3' ? '-cancel' : '',
              h.Odds ? '' : '-cancel'
            ]"
          >
            <td class="mystamp">
              <span
                :class="stampClass(h.HorseNum - 1)"
                @click="openModal($event, h.HorseNum)"
              ></span>
              <MyStampModal
                @closeModal="closeStampModal($event)"
                v-if="stamp[h.HorseNum - 1].status === true"
                :top="top"
                :left="left"
                :stampNum="h.HorseNum - 1"
                :selectStamp="stamp[h.HorseNum - 1].selectedNum"
              />
            </td>
            <td>
              <i
                :class="[
                  h.BracketNum < 10
                    ? `icon-umaban -waku0${h.BracketNum}`
                    : `icon-umaban -waku0${h.BracketNum}`,
                ]"
                >{{ h.HorseNum }}</i
              >
            </td>
            <td class="name">{{ h.HorseName }}</td>
            <td v-if="h.AbnormalDiv === '1' || h.AbnormalDiv === '3'">
              <div class="txt-odds -other" >
                <span v-if="h.AbnormalDiv === '1'">取消</span>
                <span v-else>除外</span>
              </div>
            </td>
            <td class="odds" v-else>
              <div class="info">
                <div class="txt">
                  <div :class="oddsClass(h.Odds)">
                    {{ h.Odds }}
                  </div>
                  <div v-if="popularOdds[h.HorseNum - 1] && h.Odds && h.Odds !== '---.-'" class="txt-odds -popular">
                    ({{ popularOdds[h.HorseNum - 1] }}人気)
                  </div>
                </div>
                <div v-if="oddsButtonFlg" class="graph"><i class="icon-race -oddsranking" @click="winOddsModal('single', h.HorseNum)"></i></div>
              </div>
            </td>
            <td>
              <span class="form-checkbox">
                <label>
                  <input
                    type="checkbox"
                    @click="checkChange(h.HorseNum - 1)"
                    v-model="checkList[h.HorseNum - 1]"
                    value=""
                    name="" />
                  <i></i>
                </label>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <nav class="nav-group -right">
        <span
          @click="deleteSelected"
          class="btn-basic -delete"
          >削除</span
        >
      </nav>
      <div class="container-total">
        <dl class="amount">
          <dt>点数合計</dt>
          <dd>
            <span class="num">{{ checkedSum }}</span
            >点
          </dd>
        </dl>
        <div @click="setRaceBetWin" class="btn-basic">買い目登録</div>
      </div>
      <div class="container-schedule">
        <a href="/purchase" target="_blank" class="btn-basic -normal">購入予定一覧</a>
        <p class="_caption">
          ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
        </p>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
  <SetBetConfModal
    v-on:closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
  ></SetBetConfModal>
  <WinOddsModal
    v-if="modalOpen === true"
    v-on:closeDialog="modalOpen = false"
    :modalOpen="modalOpen"
    :winOddsGraphData="winOddsGraphData"
    :winOddsGraphDate="winOddsGraphDate"
    :winOddsGraphGradeCd="winOddsGraphGradeCd"
    :winOddsGraphDOR="winOddsGraphDOR"
    :winOddsGraphIsNar="winOddsGraphIsNar"
    :winOddsGraphIsNighter="winOddsGraphIsNighter"
    :winOddsGraphRacetrackCd="winOddsGraphRacetrackCd"
  ></WinOddsModal>
</template>

<style lang="scss" src="../../assets/css/race/odds/style.scss" scoped></style>
