<script setup>
import {
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  setDismissDialog,
  SUCCESS,
  isDismissDialog,
  getCourse,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  isNar,
  isAndroid,
  getUserAgent,
} from "../../../assets/js/common";

import {
  HOST,
  PATH,
  BACKWORDPATH,
  TOB_BRACKET,
  METHOD_NORMAL,
} from "../../../assets/js/define";

import raceOdds from "../../../functions/odds/race-odds";
import raceDeadline from "../../../functions/odds/race-deadline_countdown";
import SetBetConfModal from "../../../components/utils/SetBetConfModal.vue";
import {useRoute} from "vue-router";

const { setRaceBetByArray, oddsClass } = raceOdds();
const { TimerCount } = raceDeadline();

let checkAllJudge = ref(false);
let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let deadLineJudge = ref(false);
let loadedPage = ref(false)
let bracketArray = reactive([]);
let oddsUpdInfo = ref("");
const checkedHorse = reactive({});
const currentTab = "bracket";

let params = {};

const props = defineProps({
  raceDORVal: String,
  raceTrackCdVal: String,
  raceNumVal: String,
  deadLineJudge: Boolean,
});

let time = ref("");

const route = useRoute();
watch(route, (from, to) => {
  clearSelected();
  loadPromisingBet();
  doInit(to.query);
})

onMounted(async () => {
  document.body.className = "";
  params = getUrlVars();
  //パラメータチェック
  if (checkUrlVars(params)) {
    loadPromisingBet()
    doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});

//チェックリスト関連
let checkList = reactive({});
let checkedSum = ref(0);
let checkStorageArray = reactive([])
const loadPromisingBet = () => {
  const checkListStorage = localStorage.getItem('bracket_formation' + location.search)
  if(checkListStorage) checkStorageArray = JSON.parse(checkListStorage)
}
const checkChange = (num) => {
  if (checkList[num] === true) {
    checkList[num] = false;
    checkStorageArray?.splice(checkStorageArray?.indexOf(String(num)),1)
  } else {
    checkList[num] = true;
    checkStorageArray?.push(String(num))
  }
  localStorage.setItem('bracket_formation' + location.search, JSON.stringify(checkStorageArray))
  checkedHorseSum();
};
const checkedHorseSum = () => {
  let checkedNum = 0;
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    if (checkList[i] === true) {
      checkedNum++;
    }
  }
  checkedSum.value = checkedNum;
};
const checkedAll = () => {
  if (checkAllJudge.value == true) {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      checkList[i] = false;
      checkStorageArray?.splice(checkStorageArray?.indexOf(String(i)),1)
    }
    checkAllJudge.value = false;
  } else {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      if (bracketArray[i].isCancel || !bracketArray[i].odds || bracketArray[i].odds === '---.-') {
        //取消・中止はチェックしない
        checkList[i] = false;
      } else {
        checkList[i] = true;
        if (checkStorageArray?.includes(String(i))) continue
        checkStorageArray?.push(String(i))
      }
    }
    checkAllJudge.value = true;
  }
  localStorage.setItem('bracket_formation' + location.search, JSON.stringify(checkStorageArray))
  checkedHorseSum();
};
// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkList,() => {
  let removeKeyArr = bracketArray.filter(element => element.isCancel || !element.isShow )
  if(removeKeyArr.length) removeKeyArr = removeKeyArr.map(element => element['key'])
  const checkLists = Object.values(checkList)
  let canCheckLists = checkLists
  if(removeKeyArr.length) canCheckLists = checkLists.filter((item, index) => !removeKeyArr.includes(index))
  if(checkLists.length) checkAllJudge.value = canCheckLists.find(check => check === false) === undefined
},{immediate:true})
// horses
let horses = reactive([]);
const doInit = async (params) => {
  /*loadCandidacyTableForOddsBasic(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum
  );
  loadOddsWin(params.DOR, params.RacetrackCd, params.RaceNum);*/

  //枠連オッズ読み込み
  loadOddsBracketQuinella(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum,
    function () {
      loadedPage.value = true
      document.body.className = "status-loaded";
    }
  );
};

//レース詳細情報を取得する
const flg = ref(false);
const loadOddsBracketQuinella = async (dor, racetrackCd, raceNum, callback) => {
  //出馬表から取消・除外などの情報を取得
  //パラメータからAPIコールを生成
  var req =
    HOST +
    PATH +
    "RaceInfo/candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      var intEntryNum = parseInt(json["EntryNum"], 10);
      var entryHorsesArray = json["EntryHorses"];

      var limitHorseNo = [];
      limitHorseNo.push("dummy");
      limitHorseNo.push([0, 1, 0, 0, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 0, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 8]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 9]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 8, 10]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 7, 9, 11]);
      limitHorseNo.push([0, 1, 2, 3, 4, 6, 8, 10, 12]);
      limitHorseNo.push([0, 1, 2, 3, 5, 7, 9, 11, 13]);
      limitHorseNo.push([0, 1, 2, 4, 6, 8, 10, 12, 14]);
      limitHorseNo.push([0, 1, 3, 5, 7, 9, 11, 13, 15]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 14, 16]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 14, 17]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 15, 18]);

      //出頭数から上記の表のどれを使用するか決定
      var limitHorseNoArray = limitHorseNo[intEntryNum];
      var waku = 0;
      var wakuInfo = new Object();
      //全馬について
      for (var waku = 1; waku <= 8; waku++) {
        try {
          wakuInfo[waku] = {
            check: true,
            douwaku_check: true,
            douwaku_exist: true,
            txt: "",
          };
          //前の枠との差が１頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 1) {
            //１頭枠を利用
            var horse1 = entryHorsesArray[limitHorseNoArray[waku] - 1];
            //同枠チェック不可
            wakuInfo[waku].douwaku_check = false;
            wakuInfo[waku].douwaku_exist = false;
            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3") {
              //チェック不可
              wakuInfo[waku].check = false;
              if (horse1["AbnormalDiv"] == "1") wakuInfo[waku].txt = "取消";
              if (horse1["AbnormalDiv"] == "3") wakuInfo[waku].txt = "除外";
            }
          }
          //前の枠との差が２頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 2) {
            //２頭枠を表示
            var clancelCount = 0;
            var horse1 = entryHorsesArray[limitHorseNoArray[waku - 1]];
            var horse2 = entryHorsesArray[limitHorseNoArray[waku - 1] + 1];
            var adnormalTxt = "";
            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3") {
              if (horse1["AbnormalDiv"] == "1") adnormalTxt = "取消";
              if (horse1["AbnormalDiv"] == "3") adnormalTxt = "除外";
              clancelCount++;
            }
            if (horse2["AbnormalDiv"] == "1" || horse2["AbnormalDiv"] == "3") {
              if (adnormalTxt != "") {
                adnormalTxt += "/";
              }
              if (horse2["AbnormalDiv"] == "1") adnormalTxt += "取消";
              if (horse2["AbnormalDiv"] == "3") adnormalTxt += "除外";
              clancelCount++;
            }
            wakuInfo[waku].douwaku_exist = true;
            if (clancelCount > 0) {
              //同枠選択チェック不可
              wakuInfo[waku].douwaku_check = false;
              wakuInfo[waku].txt = adnormalTxt;
              if (clancelCount == 2) {
                //チェック不可
                wakuInfo[waku].check = false;
              }
            }
          }
          //前の枠との差が３頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 3) {
            //３頭枠を表示
            var clancelCount = 0;
            var horse1 = entryHorsesArray[limitHorseNoArray[waku - 1]];
            var horse2 = entryHorsesArray[limitHorseNoArray[waku - 1] + 1];
            var horse3 = entryHorsesArray[limitHorseNoArray[waku - 1] + 2];
            var adnormalTxt = "";
            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3") {
              if (horse1["AbnormalDiv"] == "1") adnormalTxt = "取消";
              if (horse1["AbnormalDiv"] == "3") adnormalTxt = "除外";
              clancelCount++;
            }
            if (horse2["AbnormalDiv"] == "1" || horse2["AbnormalDiv"] == "3") {
              if (adnormalTxt != "") {
                adnormalTxt += "/";
              }
              if (horse2["AbnormalDiv"] == "1") adnormalTxt += "取消";
              if (horse2["AbnormalDiv"] == "3") adnormalTxt += "除外";
              clancelCount++;
            }
            if (horse3["AbnormalDiv"] == "1" || horse3["AbnormalDiv"] == "3") {
              if (adnormalTxt != "") {
                adnormalTxt += "/";
              }
              if (horse3["AbnormalDiv"] == "1") adnormalTxt += "取消";
              if (horse3["AbnormalDiv"] == "3") adnormalTxt += "除外";
              clancelCount++;
            }
            wakuInfo[waku].douwaku_exist = true;
            if (clancelCount > 1) {
              //同枠選択チェック不可
              wakuInfo[waku].douwaku_check = false;
              wakuInfo[waku].txt = adnormalTxt;
              if (clancelCount == 3) {
                //チェック不可
                wakuInfo[waku].check = false;
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
      //オッズを設定
      //パラメータからAPIコールを生成
      var req =
        HOST +
        PATH +
        "Odds/bracketQuinellaOdds.js" +
        BACKWORDPATH +
        "?DOR=" +
        dor +
        "&RacetrackCd=" +
        racetrackCd +
        "&RaceNum=" +
        raceNum +
        "&NarFlag=" +
        isNar();
      getJSON(req, true, function (status, json) {
        if (status == SUCCESS) {
          //データは常に１件が正しい。
          if (json.length == 1) {
            let countNum = 0;
            const displayData = json[0];
            if (displayData["OddsUpdInfo"] != null)
              oddsUpdInfo.value = displayData["OddsUpdInfo"];

            bracketArray.splice(0, bracketArray.length);
            for (let i = 1; i <= 8; i++) {
              for (let j = i; j <= 8; j++) {
                let bracketObject = {
                  key: countNum,
                  waku1: i,
                  waku2: j,
                  isShow: true, //表示するかどうか
                  isCancel: false, //表示はするがグレーアウトするかどうか
                  adnormalTxt: "",
                  waku1Color: "-waku0" + i,
                  waku2Color: "-waku0" + j,
                  odds: "---.-",
                };
                checkList[countNum] = checkStorageArray?.includes(String(countNum)) ? true : false;
                try {
                  if (i == j && !wakuInfo[i].douwaku_exist) {
                    //同枠及び1頭枠は表示しない
                    bracketObject.isShow = false;
                  } else if (
                    (i == j && !wakuInfo[i].douwaku_check) ||
                    !wakuInfo[i].check ||
                    !wakuInfo[j].check
                  ) {
                    let adnormalTxt = wakuInfo[i].txt;
                    if (i != j && wakuInfo[j].txt != "") {
                      if (adnormalTxt != "") {
                        adnormalTxt += "/";
                      }
                      adnormalTxt += wakuInfo[j].txt;
                    }
                    bracketObject.adnormalTxt = adnormalTxt;
                    bracketObject.isCancel = true;
                  } else {
                    bracketObject.odds = displayData["Odds" + i + "_" + j];
                    if (!bracketObject.odds || bracketObject.odds == "---.-") {
                      bracketObject.odds = "---.-";
                    }
                  }

                  bracketArray.push(bracketObject);
                } catch (e) {
                  console.log(e);
                }
                countNum++;
              }
            }
            checkedHorseSum();
            return callback();
          } else {
            location.href =
                HOST +
                "race/card?DOR=" +
                dor +
                "&RacetrackCd=" +
                racetrackCd +
                "&RaceNum=" +
                raceNum;
            if (callback != undefined) {
              callback();
            }
          }
        }
      });
    }
  });
};

//ソートを変更
const activeSortId = ref("sort_num");
const sortType = ref(1);

//ソートを変更
const changeSort = (id) => {
  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_num") {
      if (sortType.value === 2) {
        sortType.value = 1;
        bracketArray.sort(sort_by("HorseNum", false, parseInt));
      } else {
        sortType.value = 2;
        bracketArray.sort(sort_by("HorseNum", true, parseInt));
      }
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      if (sortType.value === 6) {
        sortType.value = 5;
        bracketArray.sort(sort_by("odds", true, parseInt));
      } else {
        sortType.value = 6;
        bracketArray.sort(sort_by("odds", false, parseInt));
      }
      activeSortId.value = "sort_odds";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを降順でセットする
    if (id === "sort_num") {
      sortType.value = 1;
      bracketArray.sort(sort_by("HorseNum", false, parseInt));
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      sortType.value = 5;
      bracketArray.sort(sort_by("odds", true, parseInt));
      activeSortId.value = "sort_odds";
    }
  }
};

//ソート
let sort_by = function (field, reverse, primer) {
  reverse = reverse ? -1 : 1;
  if (field === "HorseNum") {
    return function (a, b) {
      if(a["waku1"] !== b["waku1"]) {
        a = a["waku1"] * 10;
        b = b["waku1"] * 10;
        if (typeof primer != "undefined") {
          a = primer(a);
          b = primer(b);
        }
        if (a < b) return reverse * -1;
        if (a > b) return reverse * 1;
      }

      if(a["waku2"] !== b["waku2"]) {
        a = a["waku2"] * 10;
        b = b["waku2"] * 10;
        if (typeof primer != "undefined") {
          a = primer(a);
          b = primer(b);
        }
        if (a < b) return reverse * -1;
        if (a > b) return reverse * 1;
      }

      return 0
    };
  } else {
    return function (a, b) {
      a = a[field] * 10;
      b = b[field] * 10;
      if (typeof primer != "undefined") {
        a = primer(a);
        b = primer(b);
      }
      if (a > b) return reverse * -1;
      if (a < b) return reverse * 1;
    };
  }
};

//買い目登録
const setRaceBetWin = () => {
  const sum = checkedSum.value;
  let openWindow
  if (isDismissDialog() == 1 && !isAndroid() && !getUserAgent()) {
    openWindow = window.open('', '_blank')
  }
  if (sum != 0) {
    let setRaceBetParams = getUrlVars();
    // レースヘッダから情報を取得
    if (props.raceDORVal != null) {
      setRaceBetParams["DOR"] = props.raceDORVal;
    }
    if (props.raceTrackCdVal != null) {
      setRaceBetParams["RacetrackCd"] = props.raceTrackCdVal;
    }
    if (props.raceNumVal != null) {
      setRaceBetParams["RaceNum"] = props.raceNumVal;
    }

    const betArraybase = [
      "100000000000000000",
      "010000000000000000",
      "001000000000000000",
      "000100000000000000",
      "000010000000000000",
      "000001000000000000",
      "000000100000000000",
      "000000010000000000",
    ];
    let betArray = [];
    let doFlgArray = [];
    let checkFlg = false;

    let checkListCnt = 0;
    for (let i = 1; i <= 8; i++) {
      for (let j = i; j <= 8; j++) {
        betArray.push(betArraybase[i - 1] + betArraybase[j - 1]);
        doFlgArray.push(checkList[checkListCnt]);
        if (checkList[checkListCnt]) {
          checkFlg = true;
        }
        checkListCnt++;
      }
    }
    if (checkFlg) {
      //順番に登録させるため再帰的に登録
      setRaceBetByArray(
        setRaceBetParams,
        TOB_BRACKET,
        METHOD_NORMAL,
        betArray,
        doFlgArray,
        function () {
          if (isDismissDialog() != 1) {
            // AppPassはダイアログ非表示
            if (getCourse() == PAYINGAPPPASS) {
              //ダイアログ非表示フラグON
              setDismissDialog();
            } else {
              //モーダルウィンドウ表示
              raceBetCheck.value = true;
              modalBetOpen.value = true;
            }
          } else {
            var url = HOST + "purchase";
            if (
            (getCourse() == PAYINGSUGOTOKU ||
            getCourse() == PAYINGSUGOTOKU_NONE) && !getUserAgent()
            ) {
              url = location.pathname + "?url=" + HOST + "purchase";
            }
            if (isAndroid() || getUserAgent()) {
              location.href = url;
            }
            else { openWindow.location.href = url }
          }
        }
      );
    } else {
      alert("枠番をチェックしてください");
    }
    checkStorageArray.splice(0)
    localStorage.removeItem('bracket_formation' + location.search)
  } else {
    alert("枠番をチェックしてください");
  }
};
// 買い目削除
const deleteSelected = () => {
  const result = confirm('枠連/枠番順に対して全ての買い目を削除しますか？')
  if (result) {
    clearStorage()
    clearSelected()
  }
}

const clearStorage = () => {
  localStorage.removeItem('bracket_formation' + location.search)
}

const clearSelected = () => {
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    checkList[i] = false;
  }
  checkStorageArray.splice(0)
  checkAllJudge.value = false;
  checkedHorseSum();
}
</script>

<template>
  <section class="layout-section" v-if="loadedPage">
    <div class="layout-title">
      <h2 class="heading-circle -padding">枠連 / 枠番順</h2>
      <div class="nav">
        <span v-if="props.deadLineJudge" class="status">締め切り間近</span>
        <span :class="[oddsUpdInfo ? 'icon-kakutei' : '']">{{
          oddsUpdInfo
        }}</span>
      </div>
    </div>
    <table class="table-grid -fit -middle -center -odds">
      <thead>
        <tr>
          <th>組み合わせ
            <i
              class="filter-sort"
              :class="sortType === 1 ? '-asc' : sortType === 2 ? '-desc' : ''"
              @click="changeSort('sort_num')"
            ></i>
          </th>
          <th>オッズ
            <i
              class="filter-sort"
              :class="sortType === 5 ? '-asc' : sortType === 6 ? '-desc' : ''"
              @click="changeSort('sort_odds')"
            ></i>
          </th>
          <th class="ranking">
            <span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  value=""
                  name=""
                  @click="checkedAll()"
                  v-model="checkAllJudge" /><i></i></label
            ></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(bracket, index) in bracketArray"
            :key="index"
            :class="[
                bracket.isCancel || bracket.odds === '---.-' ? '-cancel' : '',
                ]"
        >
          <td v-if="bracket.isShow">
            <ul class="list-kaimoku">
              <li>
                <i class="icon-umaban" :class="bracket.waku1Color">{{
                  bracket.waku1
                }}</i>
              </li>
              <li>
                <i class="icon-umaban" :class="bracket.waku2Color">{{
                  bracket.waku2
                }}</i>
              </li>
            </ul>
          </td>
          <td v-if="bracket.isShow === true">
            <div v-if="bracket.adnormalTxt" class="txt-odds">
              {{ bracket.adnormalTxt }}
            </div>
            <div
              v-else-if="bracket.odds"
              class="txt-odds"
              :class="oddsClass(bracket.odds)"
            >
              {{ bracket.odds }}
            </div>
          </td>
          <td v-if="bracket.isShow === true">
            <span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  @click="checkChange(bracket.key)"
                  v-model="checkList[bracket.key]"
                  value=""
                  name="" /><i></i></label
            ></span>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="nav-group -right">
      <span
        @click="deleteSelected"
        class="btn-basic -delete"
        >削除</span
      >
    </nav>
    <div class="container-total">
      <dl class="amount">
        <dt>点数合計</dt>
        <dd>
          <span class="num">{{ checkedSum }}</span
          >点
        </dd>
      </dl>
      <div @click="setRaceBetWin" class="btn-basic">買い目登録</div>
    </div>
    <div class="container-schedule">
      <a href="/purchase" target="_blank" class="btn-basic -normal">購入予定一覧</a>
      <p class="_caption">
        ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
      </p>
    </div>
  </section>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
  <SetBetConfModal
    @closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
  ></SetBetConfModal>
</template>

<style
  lang="scss"
  src="../../../assets/css/race/odds/style.scss"
  scoped
></style>
