<script setup>
import { ref, reactive, onMounted, onUpdated, toRefs } from "vue";
import { HOST, PATH, BACKWORDPATH, JRA_WP_HOST } from "../../assets/js/define";
import { SUCCESS, getJSON, userSettingURL } from "../../assets/js/common";
import axios from "axios";
import moment from "moment";

// APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=join-premiumonly";

let list = reactive({});
onMounted(() => {
  document.body.className = "";
  const request_url = API_URL;
  axios
    .get(request_url)
    .then((response) => {
      Object.assign(list, response.data[0]);
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});

onUpdated(() => {
  const modal = initModal();

  // modal
  let modalInner = document.getElementById("modal-add-premium");
  document.getElementById("modal-add-premium").remove();

  // event
  let bPremiumTrial = document.getElementById("b-premium-trial");
  bPremiumTrial.addEventListener("click", function (e) {
    e.preventDefault();

    modal.show(
      "プレミアムコースお試し入会決済方法選択",
      modalInner.innerHTML,
      "joinCreditPremiumTrial",
      "joinPremiumTrial"
    );
  });

  document.body.className = "status-loaded";
});

const initModal = () => {
  const layer = document.createElement("div");
  layer.classList.add("modal-layer");
  const frame = document.createElement("div");
  frame.classList.add("modal-frame");
  const content = document.createElement("div");
  content.classList.add("modal-content");
  const inner = document.createElement("div");
  inner.classList.add("modal-inner");
  const header = document.createElement("div");
  header.classList.add("modal-title", "heading-bar");
  const body = document.createElement("div");
  body.classList.add("modal-body");
  const close = document.createElement("span");
  close.classList.add("modal-close");

  layer.appendChild(frame);
  frame.appendChild(inner);
  inner.appendChild(content);
  inner.appendChild(close);
  content.appendChild(header);
  content.appendChild(body);

  document.body.appendChild(layer);
  document.body.appendChild(frame);

  inner.addEventListener("click", (e) => {
    e.stopPropagation();
  });
  frame.addEventListener("click", () => {
    document.body.classList.remove("status-modal");
    body.innerHTML = "";
  });
  close.addEventListener("click", () => {
    document.body.classList.remove("status-modal");
    body.innerHTML = "";
  });

  function setClose() {
    const closeButton = document.getElementsByClassName("close-modal");
    if (closeButton.length === 0) {
      return;
    }
    for (let i = 0; i < closeButton.length; i++) {
      closeButton[i].addEventListener("click", () => {
        document.body.classList.remove("status-modal");
        body.innerHTML = "";
      });
    }
  }

  return {
    show: (title, html, credit, career) => {
      if (title) {
        header.innerHTML = title;
        header.style.display = "block";
      } else {
        header.style.display = "none";
      }
      body.innerHTML = html;
      document.body.classList.add("status-modal");

      //モーダル先のイベント指定はこちらで実施
      let creditElement = document.getElementById("b-credit");
      let careerElement = document.getElementById("b-carrier");

      if (creditElement) {
        creditElement.addEventListener("click", function (e) {
          userSettingURL(credit);
        });
      }
      if (careerElement) {
        careerElement.addEventListener("click", function (e) {
          userSettingURL(career);
        });
      }

      setClose();
    },
    watchMovie: (url) => {
      body.innerHTML = '<video autoplay src="' + url + '"></video>';
      header.style.display = "none";
      frame.classList.add("-movie");
      document.body.classList.add("status-modal");
    },
    showImage: (url) => {
      body.innerHTML = '<img src="' + url + '">';
      header.style.display = "none";
      frame.classList.add("-image");
      document.body.classList.add("status-modal");
    },
    showHorseWeight: (title, url) => {
      if (title) {
        header.innerHTML = title;
        header.style.display = "block";
      } else {
        header.style.display = "none";
      }
      body.innerHTML = '<iframe src="' + url + '"></iframe>';
      frame.classList.add("-weightgraph");
      document.body.classList.add("status-modal");
    },
    showURL: (title, url) => {
      if (title) {
        header.innerHTML = title;
        header.style.display = "block";
      } else {
        header.style.display = "none";
      }
      const xhr = new XMLHttpRequest();
      xhr.open("get", url);
      xhr.send();
      xhr.addEventListener("readystatechange", () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          body.innerHTML = xhr.response;
          document.body.classList.add("status-modal");
        }
      });
    },
  };
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">会員登録 プレミアムコース</h1>
    </section>
    <div v-if="list.content" v-html="list.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/member/style.scss"></style>
