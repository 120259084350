import { ref } from "vue";

// 1:開催場別
// 2:開催別（開催場、開催回次）
// 3:距離別
// 4:コース別（トラック分類）
// 5:世代別（競走種別）
// 6:条件別（グレードコード、競走条件）
// 7:月別（開催年月日の年月）
// 8:騎手別
// 9:調教師別
// 10:馬番別
// 11:式別別

import {
  PAYINGSUGOTOKU_NONE,
  PAYINGSUGOTOKU,
} from "../../assets/js/common";
import {
  getJSON,
  getCourse,
  getUserId,
  replaceComma,
  recoveryRate,
  isNar,
} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH } from "../../assets/js/define";

//現在の年から2010年までの要素を用意
export function getYear(narFlag) {
  let year = [];
  const date = new Date();
  const minYear = narFlag ? 2020 : 2010;
  for (let i = date.getFullYear(); i >= minYear ; i--) {
    year.push(i);
  }
  return year;
}

export async function getDataCommon(year, kbn, placeList) {
  const data = await getSumCommon(year, kbn, placeList);
  return data;
}

export async function getDataAppend(year, kbn) {
  const data = await getSumAppend(year, kbn);
  return data;
}

export async function getDataSortAppend(year, kbn) {
  const data = await getSumSortAppend(year, kbn);
  return data;
}

/******************************************************************
 *  収支集計 基本系共通
 * 1,3,4,5,6,7,10,11
 ******************************************************************/
export async function getSumCommon(year, kbn, placeList) {
  let request = "";
  if (getCourse() == PAYINGSUGOTOKU || getCourse() == PAYINGSUGOTOKU_NONE) {
    request =
      HOST +
      PATH +
      "Balance/getBalanceSummary4sg.js" +
      BACKWORDPATH +
      "?userId=" +
      encodeURIComponent(getUserId()) +
      "&year=" +
      year +
      "&kbn=" +
      kbn +
      "&narFlag=" +
      isNar();
  } else {
    request =
      HOST +
      PATH +
      "Balance/getBalanceSummary.js" +
      BACKWORDPATH +
      "?year=" +
      year +
      "&kbn=" +
      kbn +
      "&narFlag=" +
      isNar();
  }
  document.body.className = "";
  let json = await new Promise((resolve) => {
    getJSON(
      request,
      true,
      (r, data) => resolve(data),
      (document.body.className = "status-loaded")
    );
  });

  try {
    if (json && json.length > 0) {
      let data = {};
      let tags = [];
      let sumSpend = 0;
      let sumRefund = 0;
      let sumRaceCount = 0;
      let sumHitCount = 0;
      if (placeList && placeList.length) {
        json = json.filter((value) => placeList.includes(value.item));
      }
      for (let j in json) {
        let tag = {};

        tag.item = json[j].item;

        //購入額
        tag.sv = replaceComma(json[j].Spend);
        sumSpend += parseInt(json[j].Spend);
        //払戻金
        tag.rv = replaceComma(json[j].TotalRefund);
        sumRefund += parseInt(json[j].TotalRefund);
        //回収率
        tag.rp = replaceComma(
          recoveryRate(parseInt(json[j].Spend), parseInt(json[j].TotalRefund))
        );
        //購入レース
        tag.rc = json[j].RaceCount;
        sumRaceCount += parseInt(json[j].RaceCount);
        //的中レース
        tag.hc = json[j].HitCount;
        sumHitCount += parseInt(json[j].HitCount);
        //的中率
        tag.hp = recoveryRate(
          parseInt(json[j].RaceCount),
          parseInt(json[j].HitCount)
        );
        tags.push(tag);
      }

      data.tags = tags;

      //合計購入額
      data.sv_sum = replaceComma(sumSpend);
      //合計払戻金
      data.rv_sum = replaceComma(sumRefund);
      //合計回収率
      data.rp_sum = replaceComma(recoveryRate(sumSpend, sumRefund));
      //合計購入レース
      data.rc_sum = sumRaceCount;
      //合計的中レース
      data.hc_sum = sumHitCount;
      //合計的中率
      data.hp_sum = recoveryRate(sumRaceCount, sumHitCount);

      return data;
    }
    return {};
  } catch (e) {
    console.log("getSumVenue : " + e);
    return {};
  }
}

/******************************************************************
 *  収支集計 追加系共通
 * 2
 ******************************************************************/
export async function getSumAppend(year, kbn) {
  let request = "";
  if (getCourse() == PAYINGSUGOTOKU || getCourse() == PAYINGSUGOTOKU_NONE) {
    request =
      HOST +
      PATH +
      "Balance/getBalanceSummary4sg.js" +
      BACKWORDPATH +
      "?userId=" +
      encodeURIComponent(getUserId()) +
      "&year=" +
      year +
      "&kbn=" +
      kbn +
      "&narFlag=" +
      isNar();
  } else {
    request =
      HOST +
      PATH +
      "Balance/getBalanceSummary.js" +
      BACKWORDPATH +
      "?year=" +
      year +
      "&kbn=" +
      kbn +
      "&narFlag=" +
      isNar();
  }
  document.body.className = "";
  const json = await new Promise((resolve) => {
    getJSON(
      request,
      true,
      (r, data) => resolve(data),
      (document.body.className = "status-loaded")
    );
  });
  try {
    if (json && json.length > 0) {
      let data = {};
      let tags = [];
      let sumSpend = 0;
      let sumRefund = 0;
      let sumRaceCount = 0;
      let sumHitCount = 0;
      for (let j in json) {
        let tag = {};

        tag.RaceMeeting = json[j].RaceMeeting;
        tag.RacetrackCd = json[j].RacetrackCd;

        //購入額
        tag.sv = replaceComma(json[j].Spend);
        sumSpend += parseInt(json[j].Spend);
        //払戻金
        tag.rv = replaceComma(json[j].TotalRefund);
        sumRefund += parseInt(json[j].TotalRefund);
        //回収率
        tag.rp = replaceComma(
          recoveryRate(parseInt(json[j].Spend), parseInt(json[j].TotalRefund))
        );
        //購入レース
        tag.rc = json[j].RaceCount;
        sumRaceCount += parseInt(json[j].RaceCount);
        //的中レース
        tag.hc = json[j].HitCount;
        sumHitCount += parseInt(json[j].HitCount);
        //的中率
        tag.hp = recoveryRate(
          parseInt(json[j].RaceCount),
          parseInt(json[j].HitCount)
        );
        tags.push(tag);
      }

      data.tags = tags;

      //合計購入額
      data.sv_sum = replaceComma(sumSpend);
      //合計払戻金
      data.rv_sum = replaceComma(sumRefund);
      //合計回収率
      data.rp_sum = replaceComma(recoveryRate(sumSpend, sumRefund));
      //合計購入レース
      data.rc_sum = sumRaceCount;
      //合計的中レース
      data.hc_sum = sumHitCount;
      //合計的中率
      data.hp_sum = recoveryRate(sumRaceCount, sumHitCount);

      return data;
    }
    return {};
  } catch (e) {
    console.log("getSumVenue : " + e);
    return {};
  }
}

/******************************************************************
 *  収支集計 騎手、調教師共通
 * 8,9
 ******************************************************************/
export async function getSumSortAppend(year, kbn) {
  let request = "";
  if (getCourse() == PAYINGSUGOTOKU || getCourse() == PAYINGSUGOTOKU_NONE) {
    request =
      HOST +
      PATH +
      "Balance/getBalanceSummary4sg.js" +
      BACKWORDPATH +
      "?userId=" +
      encodeURIComponent(getUserId()) +
      "&year=" +
      year +
      "&kbn=" +
      kbn +
      "&narFlag=" +
      isNar();
  } else {
    request =
      HOST +
      PATH +
      "Balance/getBalanceSummary.js" +
      BACKWORDPATH +
      "?year=" +
      year +
      "&kbn=" +
      kbn +
      "&narFlag=" +
      isNar();
  }

  const json = await new Promise((resolve) => {
    getJSON(request, true, (r, data) => resolve(data));
  });
  try {
    if (json && json.length > 0) {
      let data = {};
      let tags = [];
      let sumSpend = 0;
      let sumRefund = 0;
      let sumRaceCount = 0;
      let sumHitCount = 0;
      //合計金額順でソート
      json.sort(function (a, b) {
        return b.Spend - a.Spend;
      });

      for (let j in json) {
        let tag = {};
        tag.num = parseInt(j) + 1;

        tag.item = json[j].item;

        //購入額
        tag.sv = replaceComma(json[j].Spend);
        sumSpend += parseInt(json[j].Spend);
        //払戻金
        tag.rv = replaceComma(json[j].TotalRefund);
        sumRefund += parseInt(json[j].TotalRefund);
        //回収率
        tag.rp = replaceComma(
          recoveryRate(parseInt(json[j].Spend), parseInt(json[j].TotalRefund))
        );
        //購入レース
        tag.rc = json[j].RaceCount;
        sumRaceCount += parseInt(json[j].RaceCount);
        //的中レース
        tag.hc = json[j].HitCount;
        sumHitCount += parseInt(json[j].HitCount);
        //的中率
        tag.hp = recoveryRate(
          parseInt(json[j].RaceCount),
          parseInt(json[j].HitCount)
        );
        tags.push(tag);
      }

      data.tags = tags;

      //合計購入額
      data.sv_sum = replaceComma(sumSpend);
      //合計払戻金
      data.rv_sum = replaceComma(sumRefund);
      //合計回収率
      data.rp_sum = replaceComma(recoveryRate(sumSpend, sumRefund));
      //合計購入レース
      data.rc_sum = sumRaceCount;
      //合計的中レース
      data.hc_sum = sumHitCount;
      //合計的中率
      data.hp_sum = recoveryRate(sumRaceCount, sumHitCount);

      return data;
    }
    return {};
  } catch (e) {
    console.log("getSumVenue : " + e);
    return {};
  }
}
