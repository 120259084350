<script setup>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";
import {JRA_WP_HOST, NAR_WP_HOST} from "../../assets/js/define";
import { isNar } from "../../assets/js/common";

let list = reactive({});
onMounted(() => {
  document.body.className = "";
  const request_url = isNar() === 1 ?
      NAR_WP_HOST + "wp-json/wp/v2/pages?slug=pack-rule" :
      JRA_WP_HOST + "wp-json/wp/v2/pages?slug=pack-rule";
  axios
    .get(request_url)
    .then((response) => {
      Object.assign(list, response.data[0]);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});
</script>

<template>
  <main class="layout-main">
    <div v-if="list.content" v-html="list.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/member/style.scss"></style>
