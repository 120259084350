<script setup></script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">500エラー</h1>
    </section>

    <section class="layout-section">
      <div class="container-box -fit">
        <div class="inner">
          <p>
            お探しのページを表示できません。<br />システムエラーが発生しております。<br />お手数ですがしばらく時間をおいてから再度お試しください。または、下記のリンクからご覧になりたいページをお探しください。
          </p>

          <figure class="container-figure">
            <div class="thumb">
              <img src="/assets/images/common/uma_img.png" alt="" />
            </div>
          </figure>
          <div class="nav-group">
            <a class="btn-basic -wide -center -noarrow" id="b-premium" href="/"
              >WIN!競馬トップへ
            </a>
            <a v-if="!isSugotoku"
              class="btn-basic -wide -center -noarrow"
              id="b-premium"
              href="/support/inquiry"
              >お問い合わせ</a
            >
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import {getCourse, PAYINGSUGOTOKU, PAYINGSUGOTOKU_NONE} from "../../assets/js/common";

export default {
  data() {
    return {
      isSugotoku: false,
    };
  },
  mounted() {
    let self = this;
    self.isSugotoku = getCourse() === PAYINGSUGOTOKU || getCourse() === PAYINGSUGOTOKU_NONE
  },
}
</script>