<script setup>
const emit = defineEmits(["closeModal"]);

const props = defineProps({
  openImage: Boolean,
  imageURL: String,
});

const modalClose = () => {
  emit("closeModal", false);
};
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="openImage" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div
          @click.self="modalClose"
          v-if="openImage"
          class="modal-frame -image"
        >
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: none"></div>
              <div class="modal-body">
                <img :src="'/shared/img/database/racehorse/' + imageURL" alt="" />
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
