<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">検索結果</h1>
    </section>

    <h2 class="heading-circle -padding">
      繁殖牝馬検索結果: {{ numberOfSearchResults }}件
    </h2>
    <p
      v-if="numberOfSearchResults > 200 && loadedPage"
      class="system-message -padding _important"
    >
      最大件数を超えた為、200件まで表示します。<br />条件を絞って再検索をしてください。
    </p>
    <p
      v-if="numberOfSearchResults === 0 && loadedPage"
      class="system-message -padding _important"
    >
      検索結果はありません。
    </p>
    <section v-if='loadedPage' class="layout-section">
      <table class="table-line -fit -middle">
        <tbody>
          <tr v-for="(item, index) in ListItems" :key="index">
            <td>
              <a :href="item.UrlLink">{{ item.HorseName }}</a>
            </td>
            <td class="-fav" :style="styles">
              <span
                v-if="item.isFav"
                class="icon-fav"
                :class="item.favClass"
                @click="clickFav(index)"
              ></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="nav-group">
        <span
          v-if="numberOfSearchResults - count > 0 && count <= 200"
          class="btn-basic -more"
          @click="isMore"
          >もっと見る</span
        >
      </div>
    </section>
    <div v-if='!loadedPage' class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import {
  PAYINGNONE,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  FBROODMARE,
  ADD,
  REMOVE,
  SUCCESS,
  getJSON,
  isFavorite,
  changeFavoriteFromCookie,
  isNar, retrieveCourse, getUserId,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  DATAPATH,
  BACKWORDPATH,
  SORTHORSENAME,
  SORTSEX,
  SORTAGE,
  SORTCLASS,
} from "../../assets/js/define";
import { useRouter } from 'vue-router'
export default {
  data() {
    return {
      useData: null,
      //検索結果数
      numberOfSearchResults: null,
      count: 30,
      isFav: true,
      favClass: "",
      loadedPage: false,
      //アイコン非表示用
      styles: {
        visibility: "visible",
      },
      userId: "",
      courseId: PAYINGNONE,
    };
  },
  mounted() {
    const self = this;
    const router = useRouter()
    // リクエストURL作成
    let mInfo = getUlrParams();
    const param = createParam(mInfo.params);

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none", false, "");
      }
    }

    callRetrieveCourse(function (courseId, showMarks, userId) {
      self.userId = userId || getUserId();
      self.courseId = courseId;
    })

    const req = HOST + PATH + "getLoginInfo.js" + BACKWORDPATH;
    getJSON(req, true, (status, json) => {
      if (status == SUCCESS) {
        self.userId = json.userId;
        self.courseId = json.courseIdStr;
      }

      //会員によってお気に入りアイコン非表示;
      if (self.courseId === PAYINGNONE || self.courseId === PAYINGSUGOTOKU_NONE) {
        self.styles.visibility = "hidden";
      } else {
        self.styles.visibility = "visible";
      }

      let request =
          HOST +
          PATH +
          "Search/searchBreedingHorse.js" +
          BACKWORDPATH +
          "?narFlag=" +
          isNar() +
          "&searchType=1";
      if (param != null && param != "") {
        request += "&" + param;
      }

      const messageFlag = true;
      document.body.className = "";
      getJSON(request, messageFlag, function (status, json) {
        if (status == SUCCESS) {
          if (json && json.length) {
            const useData = json;

            //検索結果の要素数を取得
            const numberOfSearchResults = useData[0].CountAll;
            //検索結果が1件だった場合、詳細ページへ遷移
            if (numberOfSearchResults === 1) {
              const backCheck = sessionStorage.getItem('numberOfMareSearchResults?' + useData[0].BreedingNum)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              if (useData[0].BreedingNum) {
                sessionStorage.setItem('numberOfMareSearchResults?' + useData[0].BreedingNum,1)
                router.push('/' + DATAPATH + "mare?BreedingNum=" + useData[0].BreedingNum);
                return;
              }
            }

            self.numberOfSearchResults = numberOfSearchResults;
            self.useData = useData;

            for (let i = 0; i < useData.length; i++) {
              if (useData[i].BreedingNum && useData[i].BreedingNum != "0000000000") {
                //詳細データへのリンク生成
                useData[i].UrlLink =
                    HOST + DATAPATH + "mare?BreedingNum=" + useData[i].BreedingNum;

                //お気に入り機能のプロパティ定義
                useData[i].isFav = false;
              }

              //お気に入りの表示
              if (
                  self.courseId !== PAYINGNONE &&
                  self.courseId !== PAYINGSUGOTOKU_NONE
              ) {
                useData[i].isFav = true;
                if (useData[i].BreedingNum && isFavorite(FBROODMARE, useData[i].BreedingNum) == 1) {
                  useData[i].favClass = "-registed";
                } else {
                  useData[i].favClass = "";
                }
              } else {
                useData[i].isFav = false;
              }
            }

            self.useData = useData;
          } else {
            self.numberOfSearchResults = 0
          }
        }
        // 詳細ページへ遷移する場合、ローディングのマスクは表示したまま
        document.body.className = "status-loaded";
        self.loadedPage = true;
      });
    });

    function getUlrParams() {
      // ページ数取得
      let pageNum = 1;
      let url = location.href;
      if (url.indexOf("#page-") > -1) {
        pageNum = url.slice(url.indexOf("#page-") + 6, url.length);
        url = url.slice(0, url.indexOf("#page-"));
      }

      // パラメータ取得
      let vars = [],
        hash;
      let hashes = url.slice(window.location.href.indexOf("?") + 1).split("&");
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        if (hash[0] != null && hash[0].indexOf("#page-") == -1) {
          // ページリンクは除外する
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
      }
      return { params: vars, page: pageNum };
    }

    function createParam(params) {
      let param = "";

      if (
        params["keyword"] != undefined &&
        params["keyword"] != null &&
        params["keyword"] != ""
      ) {
        param += "keyword" + "=" + params["keyword"];
      }
      if (
        params["blankFlag"] != undefined &&
        params["blankFlag"] != null &&
        params["blankFlag"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "blankFlag" + "=" + params["blankFlag"];
      }
      if (
        params["searchType"] != undefined &&
        params["searchType"] != null &&
        params["searchType"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "searchType" + "=" + params["searchType"];
      }
      if (
        params["searchOption"] != undefined &&
        params["searchOption"] != null &&
        params["searchOption"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "searchOption" + "=" + params["searchOption"];
      } else {
        if (param != "") {
          param += "&";
        }
        param += "searchOption" + "=0";
      }
      if (param != "") {
          param += "&";
        }
        param += "searchOptionWord" + "=mare";
      if (
        params["sort"] != undefined &&
        params["sort"] != null &&
        (params["sort"] == String(SORTHORSENAME) ||
          params["sort"] == String(SORTSEX) ||
          params["sort"] == String(SORTAGE) ||
          params["sort"] == String(SORTCLASS))
      ) {
        if (param != "") {
          param += "&";
        }
        param += "sort" + "=" + params["sort"];
      } else {
        if (param != "") {
          param += "&";
        }
        param += "sort" + "=" + 0;
      }
      if (
        params["RaceYear"] != undefined &&
        params["RaceYear"] != null &&
        params["RaceYear"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "RaceYear" + "=" + params["RaceYear"];
      }
      if (
        params["Belong"] != undefined &&
        params["Belong"] != null &&
        params["Belong"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Belong" + "=" + params["Belong"];
      }
      if (
        params["HorseOwnerCd"] != undefined &&
        params["HorseOwnerCd"] != null &&
        params["HorseOwnerCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "HorseOwnerCd" + "=" + params["HorseOwnerCd"];
      }
      if (
        params["TrainerCd"] != undefined &&
        params["TrainerCd"] != null &&
        params["TrainerCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "TrainerCd" + "=" + params["TrainerCd"];
      }
      if (
        params["BreedingNum"] != undefined &&
        params["BreedingNum"] != null &&
        params["BreedingNum"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "BreedingNum" + "=" + params["BreedingNum"];
      }
      if (
        params["BreederCd"] != undefined &&
        params["BreederCd"] != null &&
        params["BreederCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "BreederCd" + "=" + params["BreederCd"];
      }
      if (
        params["ScreenDiv"] != undefined &&
        params["ScreenDiv"] != null &&
        params["ScreenDiv"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "ScreenDiv" + "=" + params["ScreenDiv"];
      }
      if (
        params["Word"] != undefined &&
        params["Word"] != null &&
        params["Word"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Word" + "=" + params["Word"];
      }
      if (
        params["Option"] != undefined &&
        params["Option"] != null &&
        params["Option"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Option" + "=" + params["Option"];
      }

      return param;
    }
  },
  computed: {
    ListItems() {
      let self = this;
      const list = self.useData;
      if (list != undefined && list != null && list.length > 0) {
        return list.slice(0, self.count);
      } else {
        return [];
      }
    },
  },
  methods: {
    isMore() {
      this.count += 30;
    },
    //お気に入り登録用
    clickFav: async function (index) {
      try {
        document.body.className = "";

        if (isFavorite(FBROODMARE, this.useData[index].BreedingNum) == 1) {
          // お気に入り未登録なので登録する
          const request =
            HOST +
            PATH +
            "MyFavorite/deleteMyFavoriteBroodmare.js" +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(this.userId) +
            "&breedingNum=" +
            this.useData[index].BreedingNum;
          const order = REMOVE;
          await getJSON(request, false, function (status, json) {
            if (status == SUCCESS) {
            } else {
              alert("お気に入りの削除に失敗しました。");
            }
          });
          const favJudge = await changeFavoriteFromCookie(
            FBROODMARE,
            this.useData[index].BreedingNum,
            order
          );
          if (favJudge) {
            //アイコンクラス変更
            this.useData[index].favClass = "";
          } else {
            this.useData[index].favClass = "";
          }
        } else {
          // お気に入り未登録なので登録する
          const request =
            HOST +
            PATH +
            "MyFavorite/setMyFavoriteBroodmare.js" +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(this.userId) +
            "&breedingNum=" +
            this.useData[index].BreedingNum +
            "&isAuto=0";
          const order = ADD;
          await getJSON(request, false, function (status, json) {
            if (status == SUCCESS) {
              this.favStatus = SUCCESS;
            } else {
              alert("お気に入りの登録に失敗しました。");
            }
          });
          const favJudge = await changeFavoriteFromCookie(
            FBROODMARE,
            this.useData[index].BreedingNum,
            order
          );
          if (favJudge) {
            this.useData[index].favClass = "-registed";
          } else {
            this.useData[index].favClass = "";
          }
        }

        document.body.className = "status-loaded";
      } catch (e) {
        console.log("error" + e);
      }
    },
  },
};
</script>

<style lang="scss" src="../../assets/css/data/search/style.scss" scoped></style>
