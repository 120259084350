/******************************************************************
 *　フロント共通
 ******************************************************************/

import $ from "jquery";
import axios from "axios";
import axiosAdapter from "axios/lib/adapters/http";
import {BACKWORDPATH, CERTPATH, COOKIE_DOMAIN, DATAPATH, DEBUG, HOST, JRA_WP_HOST, PATH, RACEPATH} from "./define";
import {accidentObject, RacetrackCd} from "./code-master";

// Cookieキー名
let USERID = "user_id"; // ユーザID
let NONMEMBERID = "non_member_id"; // 非会員識別ID
let USERTYPE = "user_type"; // ユーザの課金コース
let COURSEID = "course_id"; // ユーザの課金コースID
let DATETYPE = "date_type"; //todayRaceInfoの曜日タイプを格納（曜日タイプ "0":開催中、"1":月～水、"2":木～金）
let DEFAULT_RACETRACKCD = "DEFAULT_RacetrackCd"; //ログインInfoの東西区分などから設定される競馬場コード
let POGUSER = "pog_user"; // POG会員
let FRACEHORSE = "f_racehouse"; //お気に入り競走馬
let FBREEDER = "f_breeder"; //お気に入り生産者
let FBROODMARE = "f_broodmare"; //お気に入り繁殖牝馬
let FOWNER = "f_owner"; //お気に入り馬主
let FJOCKEY = "f_jockey"; //お気に入り騎手
let FSTALLION = "f_stallion"; //お気に入り種牡馬
let FTRAINER = "f_trainer"; //お気に入り調教師
let TRACEHORSE = "t_racehouse"; //次走狙い馬
let BLAYNID = "blaynId"; //blayn mailのユーザ識別コード

let DEFAULT_PURCHASE = "default_purchase"; //

let PAYTYPE = "pay_type"; // 支払い種別識別ID (0:キャリア,1:クレジットカード,2:重複)

// let DIALOG = "dialog"; //

let CARDCHECK = "card_check"; //
let MULTICHECK = "multi_check"; //

let MULTIDIALOG = "multi_dialog"; // 重複入会判定値(0:重複なし,1:クレジットキャリア重複)
let CARDDIALOG = "card_dialog"; // クレジットカード判定値(0:重複なし,1:カード変更通知[当月利用可],2:カード変更通知[当月利用不可])

let MIGRATEDIALOG = "migrate_dialog";
let MIGRATECHECK = "migrate_check"; //

let JOINSTATUS = "join_status"; // 加入コースカンマ区切りで登録

let IPAT_CONFIRM = "ipat_confirm"; //IPAT連動規約同意
let SUGOTOKU_IPAT_CONFIRM = "sugotoku_confirm"; //スゴ得IPAT利用同意
let IPAT_INETID = "ipat_inetid"; //IPAT連動INETID
let IPAT_LOGIN = "ipat_login"; //IPAT連動加入者番号
let IPAT_PASS = "ipat_pass"; //IPAT連動暗証番号
let IPAT_P_ARS = "ipat_pars"; //IPAT連動P-ARS番号

let PURCHASEDIALOG = "purchasedialog"; //買い目登録タップ時のダイアログ非表示フラグ
let IPATDIALOG = "ipatdialog"; //IPAT投票タップ時のダイアログ非表示フラグ
let UOATAPPDIALOG = "ipatappdialog"; //IPAT投票タップ時のダイアログ非表示フラグ（アプリ）

let MAIL_ADDRESS = "mail_address"; //メール配信を設定済みか否か（"":設定なし）
let MAIL_FIRST = "mail_first"; //初回起動か否か（"0":初回起動、"1":起動済み）
let MAIL_DETAIL = "mail_detail"; //メール設定、詳細（"2":未設定、"1":設定済み）
let MAIL_JUSHO = "mail_jusho"; //メール設定、重賞（"2":未設定、"1":設定済み）
let MAIL_WIN5 = "mail_win5"; //メール設定、WIN5（"2":未設定、"1":設定済み）
let MAIL_FAVORITE = "mail_favorite"; //メール設定、お気に入り（"2":未設定、"1":設定済み）
let MAIL_POG = "mail_pog"; //メール設定、POG（"2":未設定、"1":設定済み）
let MAIL_NEXTTARGET = "mail_nexttaget"; //メール設定、次走狙い馬（"2":未設定、"1":設定済み）
let MAIL_OSHIRASE = "mail_oshirase"; //メール設定、お知らせ（"2":未設定、"1":設定済み）
let MAIL_ADD = "mail_add"; //メール設定、広告（"2":未設定、"1":設定済み）

// 課金コース
let PAYINGNONE = "none"; // 無課金
let PAYINGECONOMY = "economy"; // 300円コース
let PAYINGPREMIUM = "premium"; // 980円コース
let PAYINGYAHOO = "yahoo"; // YAHOOプレミアム
let PAYINGSMARTPASS = "smartpass"; // スマートパス
let PAYINGSUGOTOKU = "sugotoku"; // スゴ得会員
let PAYINGSUGOTOKU_NONE = "sugotoku_none"; // スゴ得非会員
let PAYINGAPPPASS = "apppass"; // AppPass会員

//PACK ADD START
let PAYINGPACK03 = "pack03"; //パック会員（３ケ月）
let PAYINGPACK06 = "pack06"; //パック会員（半年）
let PAYINGPACK12 = "pack12"; //パック会員（年間）

let EXPIRATIONDATE03 = "expiration_date03"; //
let EXPIRATIONDATE06 = "expiration_date06"; //
let EXPIRATIONDATE12 = "expiration_date12"; //
//PACK ADD END

// コースId(サーバーとの同期)
let COURSE_SUGOTOKU = "8"; // スゴ得会員
let COURSE_NOT_SUGOTOKU = "9"; // スゴ得非会員

// WebAPIの実行結果
let SUCCESS = "success";
let FAILED = "failed";

// モーダルウィンドウ/ローディングの動作
let SHOW = "show";
let HIDDEN = "hidden";

// お気に入りの動作
let ADD = "add";
let REMOVE = "remove";

// ローディング表示中フラグ
let mLoading = false;

// 入会状態
let PAYTYPECAREER = 0;
let PAYTYPECREDIT = 1;
// let PAYTYPEMULTI  = 2;

// #21354
let JIBUN_BANNER_DISP = 0;
let JIBUN_SP_BANNER_DISP = 0;
let JIBUN_201809_AIRTICLE_DISP = 0;
let JIBUN_201809_BANNER_DISP = 0;
// WIN_KEIBA_SUPPORT-239 じぶん銀行広告対応
let JIBUN_201904_BANNER_DISP = 1;
let JIBUN_201904_AIRTICLE_DISP = 1;
//let JIBUN_201904_BANNER_DISP = 0;
//let JIBUN_201904_AIRTICLE_DISP = 0;

// #175
// 2018/12/29 00:00までは1 以降は0を使用
//STG用 : let JIBUN_IPAT_REPLACE=new Date().valueOf()>new Date(2018,11,5,11,45,0).valueOf();
//let JIBUN_IPAT_REPLACE=new Date().valueOf()>new Date(2018,11,7,18,30,0).valueOf();
let JIBUN_IPAT_REPLACE =
  new Date().valueOf() > new Date(2018, 11, 29, 0, 0, 0).valueOf();
if (JIBUN_IPAT_REPLACE) {
  JIBUN_201809_AIRTICLE_DISP = 0;
  JIBUN_201809_BANNER_DISP = 0;
} else {
  JIBUN_201809_AIRTICLE_DISP = 1;
  JIBUN_201809_BANNER_DISP = 1;
}

let JIBUN_FUNCTION_MENU_DISP = 0;
// 20190805 じぶん銀行非表示対応
//let JIBUN_FUNCTION_MENU=new Date().valueOf()>new Date(2019,3,12,19,0,0).valueOf();
//let JIBUN_FUNCTION_MENU=new Date().valueOf() < new Date(2019,7,5,0,0,0).valueOf();
//20190904 じぶん銀行表示対応 201909から201912まで
//let JIBUN_FUNCTION_MENU=new Date().valueOf() > new Date(2019,8,5,0,0,0).valueOf();
let JIBUN_FUNCTION_MENU =
  new Date().valueOf() < new Date(2019, 11, 29, 0, 0, 0).valueOf();
if (JIBUN_FUNCTION_MENU) {
  JIBUN_FUNCTION_MENU_DISP = 1;
}

//-----------------------------------------------------------------
//  WebAPIを使用し、JSONを取得する
//-----------------------------------------------------------------
//  引数
//  url : リクエストURL
//  messageFlag : 通信失敗時のメッセージ表示フラグ true:表示する
//  callback : callbackメソッド
//  item1 : 任意のオブジェクト
//-----------------------------------------------------------------

let getJSON = async function (request, messageFlag, callback, item1) {
  if (request == null || request == "") {
    callback(FAILED, null, item1);
  } else {
    // 本番モード
    // WebAPIを使用する
    await axios
      .get(request, { "Content-Type": "application/json", timeout: 600000, adapter: axiosAdapter }) //結合テストのためタイムアウト10分に設定、初期値120000
      .then((response) => {
        callback(SUCCESS, response.data, item1);
      })
      .catch((e) => {
        if (messageFlag) {
          console.log(e);
        }
      });
  }
};

const getRequest = async (url) => {
  const result = await axios
    .get(url, { "Content-Type": "application/json", adapter: axiosAdapter })
    .then((response) => {
      return response.data[0] || null;
    })
    .catch((e) => {
      return null;
    });

  return result;
};

let getJSON_async = function (request, messageFlag, callback, item1) {
  if (request == null || request == "") {
    callback(FAILED, null, item1);
  } else {
    // 本番モード
    try {
      // WebAPIを使用する
      $.ajax({
        type: "GET",
        url: request,
        dataType: "json",
        cache: true,
        async: true,
        timeout: 600000, // (タイムアウト30秒)結合テストのため一時的にタイムアウト600秒
        success: function (json, type) {
          callback(SUCCESS, json, item1);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          if (messageFlag) {
            console.log(errorThrown)
          }
          callback(FAILED, textStatus, item1);
        },
      });
    } catch (e) {
      if (messageFlag) {
        console.log(e)
      }
    }
  }
};

//-----------------------------------------------------------------
//  POG会員であるか確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 0:POG会員でない 1:POG会員
//-----------------------------------------------------------------
let isPogUser = function () {
  let result = 0;

  let str = getCookie(POGUSER);

  if (str == "1") {
    result = 1;
  }

  return result;
};

//-----------------------------------------------------------------
//  POG会員フラグを保存する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  user : 0:POG会員でない 1:POG会員
//-----------------------------------------------------------------
let setPogUser = function (user) {
  if (user == 1) {
    setCookie(POGUSER, "1");
  } else {
    setCookie(POGUSER, "0");
  }
};

//-----------------------------------------------------------------
//  お気に入り登録済みか確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  引数
//  type : cookieのキー名
//  id : 血統登録番号など、ユニークなID
//  戻り値
//  result : 0:お気に入りでない 1:お気に入り登録済み
//-----------------------------------------------------------------
let isFavorite = function (name, id) {
  // 自動登録設定つきのidだった場合、はずす
  if (id.indexOf("a") > -1) {
    id.replace("a", "");
  } else if (id.indexOf("n") > -1) {
    id.replace("n", "");
  }
  let result = 0;

  let str = getCookie(name);

  if (name == FRACEHORSE || name == FJOCKEY || name == FSTALLION) {
    // 競走馬、騎手、種牡馬は自動登録設定がない
    if (str != null && str != "") {
      if (str.indexOf("_" + id + "_") > -1) {
        result = 1;
      }
    }
  } else {
    // 調教師、馬主、生産者、繁殖牝馬は自動登録設定に1文字使用している
    let re = new RegExp("_." + id + "_");
    if (str != null && str != "") {
      if (str.match(re)) {
        result = 1;
      }
    }
  }

  return result;
};

//-----------------------------------------------------------------
//  お気に入りが自動登録設定済みか確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  引数
//  type : cookieのキー名
//  id : 血統登録番号など、ユニークなID
//  戻り値
//  result : 0:自動登録していない、あるいは該当するidがお気に入り登録されていない 1:自動登録している
//-----------------------------------------------------------------
let isAutoFavorite = function (name, id) {
  let result = 0;
  let str = getCookie(name);
  let re = new RegExp("_." + id + "_", "g");
  if (str != null && str != "") {
    let resultstr = str.match(re);
    if (resultstr != null && resultstr.length > 0) {
      if (resultstr[0].indexOf("a") > -1) {
        result = 1;
      }
    }
  }
  return result;
};

//-----------------------------------------------------------------
//  お気に入り情報を変更する
//  変更先はcookie
//-----------------------------------------------------------------
//  引数
//  name : cookieのキー名
//  id : 血統登録番号など、ユニークなID
//  order : ADD:登録 REMOVE:削除
//-----------------------------------------------------------------
let changeFavoriteFromCookie = async function (name, id, order) {
  let str = "";
  let pre = getCookie(name);

  if (order == ADD) {
    // 調教師、馬主、生産者、繁殖牝馬の場合で自動登録設定がidに含まれなかった場合、n（設定なし）を付与する
    if (
      name == FTRAINER ||
      name == FOWNER ||
      name == FBREEDER ||
      name == FBROODMARE
    ) {
      if (id.indexOf("a") == -1 && id.indexOf("n") == -1) {
        id = "n" + id;
      }
    }
    // お気に入り数の制限内であれば追加
    if (isAllowedAddFav()) {
      if (pre == null || pre == "") {
        str = "_" + id + "_";
      } else {
        if (isFavorite(name, id) == 1) {
          return false;
          alert("既にお気に入りに登録されています。");
        }
        str = pre + id + "_";
      }
      setCookie(name, str);
      return true;
    } else {
      let course = getMenmerCourse();
      if (course == PAYINGSUGOTOKU || course == PAYINGSUGOTOKU_NONE) {
        alert(
          "お気に入りの登録数が上限を超えています。\nご利用のコースでは100件までお気に入りを登録可能です。"
        );
        return false;
      } else {
        alert(
          "お気に入りの登録数が上限を超えています。\nご利用のコースでは100件までお気に入りを登録可能です。\nプレミアム機能追加コースではお気に入りの登録を無制限にご利用頂けます。"
        );
        return false;
      }
    }
  } else if (order == REMOVE) {
    if (pre == null || pre == "") {
      str = "";
    } else {
      if (name == FRACEHORSE || name == FJOCKEY || name == FSTALLION) {
        if (pre.indexOf("_" + id + "_") > -1) {
          str = pre.replace("_" + id, "");
        }
      } else {
        let re = new RegExp("_." + id, "g");
        let newId = pre.match(re);
        if (newId != null && newId.length > 0) {
          str = pre.replace(newId[0], "");
        } else {
          str = pre;
        }
      }
    }
    setCookie(name, str);
    return true;
  }
};

//-----------------------------------------------------------------
//  次走狙い馬登録済みか確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  引数
//  type : cookieのキー名
//  id : 血統登録番号など、ユニークなID
//  戻り値
//  result : 0:次走狙い馬でない 1:次走狙い馬登録済み
//-----------------------------------------------------------------
let isNextTarget = function (name, id) {
  let result = 0;

  let str = getCookie(name);

  if (name == TRACEHORSE) {
    if (str != null && str != "") {
      if (str.indexOf("_" + id + "_") > -1) {
        result = 1;
      }
    }
  }

  return result;
};

//-----------------------------------------------------------------
//  次走狙い馬情報を変更する
//  変更先はcookie
//-----------------------------------------------------------------
//  引数
//  name : cookieのキー名
//  id : 血統登録番号など、ユニークなID
//  order : ADD:登録 REMOVE:削除
//-----------------------------------------------------------------
let changeNextTargetFromCookie = async function (name, id, order) {
  let str = "";
  let pre = getCookie(name);

  if (order == ADD) {
    // 次走狙い馬数の制限内であれば追加
    if (isAllowedAddNextTarget()) {
      if (pre == null || pre == "") {
        str = "_" + id + "_";
      } else {
        if (isNextTarget(name, id) == 1) {
          return false;
          alert("既に次走狙い馬に登録されています。");
        }
        str = pre + id + "_";
      }
      setCookie(name, str);
      return true;
    } else {
      let course = getMenmerCourse();
      if (course == PAYINGSUGOTOKU || course == PAYINGSUGOTOKU_NONE) {
        alert(
          "次走狙い馬の登録数が上限を超えています。\nご利用のコースでは100件まで次走狙い馬を登録可能です。"
        );
        return false;
      } else {
        alert(
          "次走狙い馬数が上限を超えています。\nご利用のコースでは100件まで次走狙い馬を登録可能です。\nプレミアム機能追加コースでは次走狙い馬の登録を無制限にご利用頂けます。"
        );
        return false;
      }
    }
  } else if (order == REMOVE) {
    if (pre == null || pre == "") {
      str = "";
    } else {
      if (name == TRACEHORSE) {
        if (pre.indexOf("_" + id + "_") > -1) {
          str = pre.replace("_" + id, "");
        }
      }
    }
    setCookie(name, str);
    return true;
  }
};

//-----------------------------------------------------------------
//  次走狙い馬情報を取得する
//  取得先はcookie
//-----------------------------------------------------------------
//  引数
//  name : cookieのキー名
//-----------------------------------------------------------------
let getNextTargetFromCookie = function (name) {
  let result = getCookie(name);
  if (result == null || result == "" || result == "_" || result == "__") {
    result = "";
  }
  return result;
};

//-----------------------------------------------------------------
//  LocalStorageが利用できるか確認する
//-----------------------------------------------------------------
function storageAvailable() {
  try {
    let storage = window["localStorage"],
      x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

//-----------------------------------------------------------------
//  IPATログイン情報の保存
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//-----------------------------------------------------------------
let saveIPATLoginInfo = function (inetid, userid, pass, pars) {
  // WIN_KEIBA_SUPPORT-252 iOS safariに保存したIPAT情報について
  let ret = {};
  if (storageAvailable()) {
    localStorage.setItem(IPAT_INETID, inetid);
    localStorage.setItem(IPAT_LOGIN, userid);
    localStorage.setItem(IPAT_PASS, pass);
    localStorage.setItem(IPAT_P_ARS, pars);

    // COOKIEにも保存する
    setCookie(IPAT_INETID, inetid);
    setCookie(IPAT_LOGIN, userid);
    setCookie(IPAT_PASS, pass);
    setCookie(IPAT_P_ARS, pars);
  } else {
    setCookie(IPAT_INETID, inetid);
    setCookie(IPAT_LOGIN, userid);
    setCookie(IPAT_PASS, pass);
    setCookie(IPAT_P_ARS, pars);
  }
};

//-----------------------------------------------------------------
//  IPATログイン情報の取得
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//-----------------------------------------------------------------
let loadIPATLoginInfo = function (inetid, userid, pass, pars) {
  // WIN_KEIBA_SUPPORT-252 iOS safariに保存したIPAT情報について
  let ret = {};
  if (storageAvailable()) {
    // COOKIEのみ設定されている場合は、COOKIEの設定値をLocalStorageにコピーする
    if (!localStorage.getItem(IPAT_INETID) && getCookie(IPAT_INETID)) {
      localStorage.setItem(IPAT_INETID, getCookie(IPAT_INETID));
      localStorage.setItem(IPAT_LOGIN, getCookie(IPAT_LOGIN));
      localStorage.setItem(IPAT_PASS, getCookie(IPAT_PASS));
      localStorage.setItem(IPAT_P_ARS, getCookie(IPAT_P_ARS));
    }

    ret = {
      inetid: localStorage.getItem(IPAT_INETID),
      userid: localStorage.getItem(IPAT_LOGIN),
      pass: localStorage.getItem(IPAT_PASS),
      pars: localStorage.getItem(IPAT_P_ARS),
    };
  } else {
    ret = {
      inetid: getCookie(IPAT_INETID),
      userid: getCookie(IPAT_LOGIN),
      pass: getCookie(IPAT_PASS),
      pars: getCookie(IPAT_P_ARS),
    };
  }

  return ret;
};

//-----------------------------------------------------------------
//  ユーザの課金コースを取得する
//-----------------------------------------------------------------
//  戻り値：課金コース
//-----------------------------------------------------------------
const getMenmerCourse = function () {
  let str = getCookie(USERTYPE);
  if (str == null || str == "") {
    str = PAYINGNONE;
  }
  return str;
};

//PACK ADD START
//-----------------------------------------------------------------
//  有効期限を取得する(３ヶ月パック)
//-----------------------------------------------------------------
//  戻り値：
//-----------------------------------------------------------------
let getExpirationdate03 = function () {
  let str = getCookie(EXPIRATIONDATE03);
  if (str == null || str == "") {
    str = "";
  }
  return str;
};

//-----------------------------------------------------------------
//  有効期限を取得する(半年パック)
//-----------------------------------------------------------------
//  戻り値：
//-----------------------------------------------------------------
let getExpirationdate06 = function () {
  let str = getCookie(EXPIRATIONDATE06);
  if (str == null || str == "") {
    str = "";
  }
  return str;
};

//-----------------------------------------------------------------
//  有効期限を取得する(年間パック)
//-----------------------------------------------------------------
//  戻り値：
//-----------------------------------------------------------------
let getExpirationdate12 = function () {
  let str = getCookie(EXPIRATIONDATE12);
  if (str == null || str == "") {
    str = "";
  }
  return str;
};

//PACK ADD END

//-----------------------------------------------------------------
//  ユーザの決済種別を取得する
//-----------------------------------------------------------------
//  戻り値：決済種別
//-----------------------------------------------------------------
let getMemberPayType = function () {
  let str = getCookie(PAYTYPE);
  if (str == null || str == "") {
    str = PAYTYPECAREER;
  }
  return str;
};

let getJoinStatus = function () {
  let str = getCookie(JOINSTATUS);
  if (str == null || str == "") {
    str = "";
  } else {
    str = decodeURIComponent(str);
  }
  return str;
};

//-----------------------------------------------------------------
//  ユーザIDを取得する
//-----------------------------------------------------------------
//  戻り値：ユーザID
//-----------------------------------------------------------------
let getUserId = function () {
  let str = getCookie(USERID);
  if (str == null) {
    str = "";
  }
  return str;
};

//-----------------------------------------------------------------
//  非会員識別IDを取得する
//-----------------------------------------------------------------
//  戻り値：非会員識別ID
//-----------------------------------------------------------------
let getNonMemberId = function () {
  let str = getCookie(NONMEMBERID);
  if (str == null) {
    str = "";
  }
  return str;
};

//-----------------------------------------------------------------
//  コースIdを取得する
//-----------------------------------------------------------------
//  戻り値：コース
//-----------------------------------------------------------------
let getCourseId = function () {
  let str = getCookie(COURSEID);
  if (str == null) {
    str = "";
  }
  return str;
};

//-----------------------------------------------------------------
//  コースIdを取得する
//-----------------------------------------------------------------
//  戻り値：コース
//-----------------------------------------------------------------
const getCourse = () => {
  let result = null;
  try {
    const cookieName = "user_type=";
    const allcookies = document.cookie;
    const position = allcookies.indexOf(cookieName);
    if (position != -1) {
      const startIndex = position + cookieName.length;
      let endIndex = allcookies.indexOf(";", startIndex);
      if (endIndex == -1) {
        endIndex = allcookies.length;
      }
      result = decodeURIComponent(allcookies.substring(startIndex, endIndex));
    }
  } catch (e) {
    console.log(e)
  }
  if (result == null || result == "") {
    result = "none";
  }
  return result;
};

//-----------------------------------------------------------------
//  IPAT連動規約同意済か確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  引数
//  type : cookieのキー名
//  id : 血統登録番号など、ユニークなID
//  戻り値
//  result : 0:お気に入りでない 1:お気に入り登録済み
//-----------------------------------------------------------------
let isIPAT_CONFIRM = function () {
  let str = getCookie(IPAT_CONFIRM);
  return str != null && str == "yes";
};

let setIPAT_CONFIRM = function () {
  set30daysCookie(IPAT_CONFIRM, "yes");
};

//-----------------------------------------------------------------
//  スゴ得IPAT利用同意済か確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  引数
//  type : cookieのキー名
//  id : 血統登録番号など、ユニークなID
//  戻り値
//  result : 0:お気に入りでない 1:お気に入り登録済み
//-----------------------------------------------------------------
let isSUGOTOKU_IPAT_CONFIRM = function () {
  let str = getCookie(SUGOTOKU_IPAT_CONFIRM);
  return str != null && str == "yes";
};

let setSUGOTOKU_IPAT_CONFIRM = function () {
  set30daysCookie(SUGOTOKU_IPAT_CONFIRM, "yes");
};

let isSaveINETID = function () {
  // WIN_KEIBA_SUPPORT-252 iOS safariに保存したIPAT情報について
  let str = null;
  if (storageAvailable()) {
    // COOKIEのみ設定されている場合は、COOKIEの設定値をLocalStorageにコピーする
    if (!localStorage.getItem(IPAT_INETID) && getCookie(IPAT_INETID)) {
      localStorage.setItem(IPAT_INETID, getCookie(IPAT_INETID));
      localStorage.setItem(IPAT_LOGIN, getCookie(IPAT_LOGIN));
      localStorage.setItem(IPAT_PASS, getCookie(IPAT_PASS));
      localStorage.setItem(IPAT_P_ARS, getCookie(IPAT_P_ARS));
    }
    str = localStorage.getItem(IPAT_INETID);
  } else {
    str = getCookie(IPAT_INETID);
  }
  return !(str == null || str == "");
};

//-----------------------------------------------------------------
//  買い目登録時のダイアログの表示フラグを確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 0:表示 1:非表示
//-----------------------------------------------------------------
let isDismissDialog = function () {
  let result = 0;

  let str = getCookie(PURCHASEDIALOG);
  if (str == "1") {
    result = 1;
  }

  return result;
};

//-----------------------------------------------------------------
//  買い目登録時のダイアログの表示フラグを保存する
//  保存先はcookie
//-----------------------------------------------------------------
let setDismissDialog = function () {
  setCookie(PURCHASEDIALOG, "1");
};

//-----------------------------------------------------------------
//  IPAT連動時のダイアログの表示フラグを確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 0:表示 1:非表示
//-----------------------------------------------------------------
let isDismissIpatDialog = function () {
  let result = 0;

  let str = getCookie(IPATDIALOG);
  if (str == "1") {
    result = 1;
  }

  return result;
};

//-----------------------------------------------------------------
//  IPAT連動時のダイアログの表示フラグを保存する
//  保存先はcookie
//-----------------------------------------------------------------
let setDismissIpatDialog = function () {
  setCookie(IPATDIALOG, "1");
};

//-----------------------------------------------------------------
//  IPAT連動時のダイアログの表示フラグを確認する(アプリ)
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 0:表示 1:非表示
//-----------------------------------------------------------------
let isDismissAppDialog = function () {
  let result = 0;

  let str = getCookie(UOATAPPDIALOG);
  if (str == "1") {
    result = 1;
  }

  return result;
};

//-----------------------------------------------------------------
//  IPAT連動時のダイアログの表示フラグを保存する(アプリ)
//  保存先はcookie
//-----------------------------------------------------------------
let setDismissAppDialog = function () {
  setCookie(UOATAPPDIALOG, "1");
};

//-----------------------------------------------------------------
//  ローディングの制御
//-----------------------------------------------------------------
//  引数
//  id : 要素のid
//  order : 動作 SHOW : 表示 HIDDEN : 非表示
//  modal : true : モーダルウィンドウとしてローディング表示 false : 通常のローディング
//-----------------------------------------------------------------
export let controlLoading = function (id, order, modal) {
  if (id == null || id == "") {
    return;
  }
  if (order == SHOW) {
    mLoading = true;
    // Loadingアニメーション開始
    let l = Math.floor(($(window).width() - $("#" + id).width()) / 2);
    let t = Math.floor(($(window).height() - $("#" + id).height()) / 2);

    //ローディングアニメーションを表示する
    if (!$("#" + id).length) {
      if (modal) {
        $("body").append('<div class="modal-backdrop fade in"></div>');
      }
      $("body").append(
        '<div id="lbLoading"><img src="' +
          HOST +
          'shared/img/now_loading.png"></div>'
      );
    } else {
      if (modal) {
        $(".modal-backdrop").show();
      }
    }
    $("#" + id)
      .css({
        position: "absolute",
        top: t,
        left: l,
      })
      .show();
  } else if (order == HIDDEN) {
    mLoading = false;
    // Loadingアニメーション停止
    $("#" + id).hide();
    $(".modal-backdrop").hide();
  }
};

//-----------------------------------------------------------------
//  ローディング状況の取得
//-----------------------------------------------------------------
//  戻り値
//  loading : true : ローディング中 false : ローディングしていない
//-----------------------------------------------------------------
let getLoadingFlg = function () {
  return mLoading;
};
//-----------------------------------------------------------------

// ユーザのお気に入り制限を確認する
function isAllowedAddFav() {
  let result = false;
  let count = 0;

  let course = getMenmerCourse();
  if (
    course == PAYINGPREMIUM ||
    course == PAYINGPACK03 ||
    course == PAYINGPACK06 ||
    course == PAYINGPACK12
  ) {
    result = true;
  } else if (course != PAYINGNONE) {
    // 競走馬登録数
    count += calFavNum(FRACEHORSE, 10); // 血統登録番号 10桁
    // 生産者登録数確認
    count += calFavNum(FBREEDER, 7); // 自動登録設定（a:設定済み、n:設定なし）1桁 + 生産者コード 6桁
    // 繁殖牝馬登録数確認
    count += calFavNum(FBROODMARE, 11); // 自動登録設定（a:設定済み、n:設定なし）1桁 + 繁殖馬コード 10桁
    // 馬主登録数確認
    count += calFavNum(FOWNER, 7); // 自動登録設定（a:設定済み、n:設定なし）1桁 + 馬主コード 6桁
    // 騎手登録数確認
    count += calFavNum(FJOCKEY, 5); // 騎手コード 5桁
    // 種牡馬登録数確認
    count += calFavNum(FSTALLION, 10); // 繁殖馬コード 10桁
    // 調教師登録数確認
    count += calFavNum(FTRAINER, 6); // 自動登録設定（a:設定済み、n:設定なし）1桁 + 調教師コード 5桁
    count = Math.floor(count);
    if (count <= 100) {
      // プレミアム以外のコースでは、お気に入りは100件まで
      result = true;
    }
  }

  return result;
}

// ユーザの次走狙い馬制限を確認する
function isAllowedAddNextTarget() {
  let result = false;
  let count = 0;

  let course = getMenmerCourse();
  if (
    course == PAYINGPREMIUM ||
    course == PAYINGPACK03 ||
    course == PAYINGPACK06 ||
    course == PAYINGPACK12
  ) {
    result = true;
  } else if (course != PAYINGNONE) {
    // 競走馬登録数
    count += calFavNum(TRACEHORSE, 10); // 血統登録番号 10桁
    if (count <= 100) {
      // プレミアム以外のコースでは、お気に入りは100件まで
      result = true;
    }
  }

  return result;
}

function calFavNum(name, num) {
  let result = 0;

  let str = getCookie(name);
  if (str != null && str != "") {
    //割り切れるはず
    result = (str.length - 1) / (num + 1);
  }

  return result;
}

// Cookieを取り出す
function getCookie(name) {
  let result = null;

  let cookieName = name + "=";
  let allcookies = document.cookie;

  let position = allcookies.indexOf(cookieName);
  if (position != -1) {
    let startIndex = position + cookieName.length;

    let endIndex = allcookies.indexOf(";", startIndex);
    if (endIndex == -1) {
      endIndex = allcookies.length;
    }

    result = decodeURIComponent(allcookies.substring(startIndex, endIndex));
  }

  if (name == USERTYPE) {
    let courseArr = [
      PAYINGNONE,
      PAYINGECONOMY,
      PAYINGPREMIUM,
      PAYINGYAHOO,
      PAYINGSMARTPASS,
      PAYINGSUGOTOKU,
      PAYINGSUGOTOKU_NONE,
      PAYINGAPPPASS,
      PAYINGPACK03,
      PAYINGPACK06,
      PAYINGPACK12,
    ];
    if (courseArr.indexOf(result) <= -1) {
      let position = allcookies.indexOf(
        cookieName,
        allcookies.indexOf(cookieName) + 1
      );
      if (position != -1) {
        let startIndex = position + cookieName.length;
        let endIndex = allcookies.indexOf(";", startIndex);
        if (endIndex == -1) {
          endIndex = allcookies.length;
        }
        result = decodeURIComponent(allcookies.substring(startIndex, endIndex));
      }
    }
  }

  return result;
}

// Cookieを保存する
function setCookie(name, str) {
  let expire = new Date();
  expire.setTime(
    expire.getTime() +
      1000 * 3600 * 24 * 365 * (35 - ("0" + expire.getFullYear()).slice(-2) * 1)
  );
  let cookie = `${name}=${encodeURIComponent(str)}; expires=${expire.toUTCString()};path=/;sameSite=lax`;
  if(COOKIE_DOMAIN) {
    cookie += `;domain=${COOKIE_DOMAIN}`
  }
  document.cookie = cookie;
}

// Cookieを保存する
function setCookieDay(name, str) {
  let expire = new Date();
  expire.setTime(expire.getTime() + 1000 * 3600 * 24);
  let cookie = `${name}=${encodeURIComponent(str)}; expires=${expire.toUTCString()};path=/;sameSite=lax`;
  if(COOKIE_DOMAIN) {
    cookie += `;domain=${COOKIE_DOMAIN}`
  }
  document.cookie = cookie;
}

function getBet4Array() {
  let obj = new Object();
  try {
    obj["dor"] = window.localStorage.getItem("bet.dor");
    obj["racetrackCd"] = window.localStorage.getItem("bet.racetrackCd");
    obj["raceNum"] = window.localStorage.getItem("bet.raceNum");
    obj["TOB"] = window.localStorage.getItem("bet.TOB");
    obj["method"] = window.localStorage.getItem("bet.method");
    obj["bet"] = window.localStorage.getItem("bet.bet");
    obj["puid"] = window.localStorage.getItem("bet.puid");
    obj["amount"] = window.localStorage.getItem("bet.amount");
  } catch (e) {
    alert("プライベートブラウズをOFFにしてご使用ください。");
    return;
  }

  let results = new Array();
  if (obj["dor"] != null) {
    for (let i in obj["dor"].split("_")) {
      if (0 < i) {
        let temp = new Object();
        temp["dor"] = decodeURIComponent(obj["dor"].split("_")[i]);
        temp["racetrackCd"] = decodeURIComponent(
          obj["racetrackCd"].split("_")[i]
        );
        temp["raceNum"] = decodeURIComponent(obj["raceNum"].split("_")[i]);
        temp["TOB"] = decodeURIComponent(obj["TOB"].split("_")[i]);
        temp["method"] = decodeURIComponent(obj["method"].split("_")[i]);
        temp["bet"] = decodeURIComponent(obj["bet"].split("_")[i]);
        temp["puid"] = decodeURIComponent(obj["puid"].split("_")[i]);
        temp["amount"] = decodeURIComponent(obj["amount"].split("_")[i]);
        results.push(temp);
      }
    }
  }

  return results;
}

function setBet4Array(array) {
  clearBet4Array();

  let dor = "";
  let racetrackCd = "";
  let raceNum = "";
  let TOB = "";
  let method = "";
  let bet = "";
  let puid = "";
  let amount = "";

  for (let i in array) {
    for (let key in array[i]) {
      if (key == "dor") {
        dor = dor + "_" + array[i][key];
      } else if (key == "racetrackCd") {
        racetrackCd = racetrackCd + "_" + array[i][key];
      } else if (key == "raceNum") {
        raceNum = raceNum + "_" + array[i][key];
      } else if (key == "TOB") {
        TOB = TOB + "_" + array[i][key];
      } else if (key == "method") {
        method = method + "_" + array[i][key];
      } else if (key == "bet") {
        bet = bet + "_" + array[i][key];
      } else if (key == "puid") {
        puid = puid + "_" + array[i][key];
      } else if (key == "amount") {
        amount = amount + "_" + array[i][key];
      }
    }
  }

  try {
    window.localStorage.setItem("bet.dor", encodeURIComponent(dor));
    window.localStorage.setItem(
      "bet.racetrackCd",
      encodeURIComponent(racetrackCd)
    );
    window.localStorage.setItem("bet.raceNum", encodeURIComponent(raceNum));
    window.localStorage.setItem("bet.TOB", encodeURIComponent(TOB));
    window.localStorage.setItem("bet.method", encodeURIComponent(method));
    window.localStorage.setItem("bet.bet", encodeURIComponent(bet));
    window.localStorage.setItem("bet.puid", encodeURIComponent(puid));
    window.localStorage.setItem("bet.amount", encodeURIComponent(amount));
  } catch (e) {
    alert("プライベートブラウズをOFFにしてご使用ください。");
    return;
  }
}

function clearBet4Array() {
  try {
    window.localStorage.removeItem("bet.dor");
    window.localStorage.removeItem("bet.racetrackCd");
    window.localStorage.removeItem("bet.raceNum");
    window.localStorage.removeItem("bet.TOB");
    window.localStorage.removeItem("bet.method");
    window.localStorage.removeItem("bet.bet");
    window.localStorage.removeItem("bet.puid");
    window.localStorage.removeItem("bet.amount");
  } catch (e) {
    alert("プライベートブラウズをOFFにしてご使用ください。");
    return;
  }
}

const setRaceBet4sg = (params, TOB, Method, betArray, doFlgArray, amountArray) => {
  let raceNum = params["RaceNum"];
  if (raceNum < 10) {
      raceNum = "0" + String(parseInt(raceNum,10));
  }

  let array = getBet4Array();
  if (array.length == 0) {
      array = new Array();
  }

  for (let i in betArray) {
      if (doFlgArray[i]) {
          let obj = new Object();
          obj["dor"] = params["DOR"];
          obj["racetrackCd"] = params["RacetrackCd"];
          obj["raceNum"] = raceNum;
          obj["TOB"] = TOB;
          obj["method"] = Method;
          obj["bet"] = betArray[i];
          obj["puid"] = createPuid();
          if (amountArray == null) {
              obj["amount"] = "1";
          } else {
              obj["amount"] = amountArray[i];
          }
          array.push(obj);
      }
  }

  setBet4Array(array);
}

/**
 * UUIDの生成を行います.（スゴ得用）
 */
function createPuid() {
  let deviceid = "";
  let random = 0;
  for (let i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0;

    if (i == 8 || i == 12 || i == 16 || i == 20) {
      deviceid += "-";
    }
    deviceid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(
      16
    );
  }
  return deviceid;
}

// IPAT利用規約の非表示化を30日有効クッキーで制御
function set30daysCookie(name, str) {
  let expire = new Date();
  expire.setTime(expire.getTime() + 1000 * 3600 * 24 * 30);
  document.cookie =
    name +
    "=" +
    encodeURIComponent(str) +
    "; expires=" +
    expire.toUTCString() +
    ";path=/" +
    (COOKIE_DOMAIN ? `;domain=${COOKIE_DOMAIN}` : '') +
    ";sameSite=strict"
}

//-----------------------------------------------------------------
//  URLパラメータを配列に整形
//-----------------------------------------------------------------
//  戻り値
//  lets : 連想配列型のオブジェクト
//-----------------------------------------------------------------
function getUrlVars() {
  let lets = new Object(),
    params;
  let temp_params = window.location.search.substring(1).split("&");
  for (let i = 0; i < temp_params.length; i++) {
    params = temp_params[i].split("=");
    lets[params[0]] = params[1];
  }
  return lets;
}

//-----------------------------------------------------------------
//  URLパラメータの配列にundefindが含まれていないかチェックする
//-----------------------------------------------------------------
//  引数
//  obj : URLパラメータの配列
//  戻り値
//  result : true undefindが含まれない false undefindが含まれる
//-----------------------------------------------------------------
function checkUrlVars(obj) {
  let result = true;
  for (let i in obj) {
    if (obj[i] == undefined || obj[i] == "undefined") {
      result = false;
    }
  }
  return result;
}

//-----------------------------------------------------------------
//  パラメータの取得
//  URLからパラメータを取得する。
//  ページの指定があればそれも取得する。ページのデフォルトは1。
//-----------------------------------------------------------------
//  戻り値
//  [params:パラメータの配列。キーは属性名, page:ページ数]
//-----------------------------------------------------------------
function getUlrParams() {
  // ページ数取得
  let pageNum = 1;
  let url = location.href;
  if (url.indexOf("#page-") > -1) {
    pageNum = url.slice(url.indexOf("#page-") + 6, url.length);
    url = url.slice(0, url.indexOf("#page-"));
  }

  // パラメータ取得
  let vars = [],
    hash;
  let hashes = url.slice(window.location.href.indexOf("?") + 1).split("&");
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    if (hash[0] != null && hash[0].indexOf("#page-") == -1) {
      // ページリンクは除外する
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
  }
  return { params: vars, page: pageNum };
}

//-----------------------------------------------------------------
//  率計算
//-----------------------------------------------------------------
//  引数
//  first : 1着回数
//  second：2着回数
//  third：3着回数
//  unplaced：着外回数
//  total：競走回数
//-----------------------------------------------------------------
//戦歴合計
let totalRes = function (first, second, third, unplaced) {
  let result = 0;
  result = first + second + third + unplaced;
  return result;
};

//勝率
let rateFirst = function (first, total) {
  let result = 0;
  // 0/0はNaNになるので計算はしない
  if (total > 0) {
    result = Math.floor((first / total) * 1000) / 1000;
  }
  return rateFormat(result);
};

//連対率
let rateSecond = function (first, second, total) {
  let result = 0;
  // 0/0はNaNになるので計算はしない
  if (total > 0) {
    result = Math.floor(((first + second) / total) * 1000) / 1000;
  }
  return rateFormat(result);
};

//3着内率
let rateThird = function (first, second, third, total) {
  let result = 0;
  // 0/0はNaNになるので計算はしない
  if (total > 0) {
    result = Math.floor(((first + second + third) / total) * 1000) / 1000;
  }
  return rateFormat(result);
};

let rateFormat = function (val) {
  if (val == 1) return "1.000";
  if (val == 0) return "0.000";
  let ret = val + "000";
  return ret.substr(0, 5);
};

//パーセンテージを小数点以下1桁まで返却する
let winFormat = function (max, param) {
  let result = 0;
  // 0/0はNaNになるので計算はしない
  if (param == 0 || max == 0) {
    return "0.0";
  }
  if (param > 0 && max > 0) {
    result = String(Math.floor((param / max) * 1000));
    if (result.length == 1) {
      result = "0" + result;
    }
    result =
      result.slice(0, result.length - 1) +
      "." +
      result.slice(result.length - 1, result.length);
  }
  return result;
};

//-----------------------------------------------------------------
//  年月日データからそれぞれを抜き出す
//-----------------------------------------------------------------
//  引数
//  date : 元となるデータ
//  year : 曜日を取得したい年
//  month: 曜日を取得したい月
//  day　: 曜日を取得したい日
//-----------------------------------------------------------------
//年
let yearDiv = function (date) {
  let result = "";
  if (date != undefined && date != null && date.length == 8) {
    result = date.substr(0, 4);
  } else {
    result = "error";
  }
  return result;
};
//月
let monthDiv = function (date) {
  let result = "";
  if (date != undefined && date != null && date.length == 8) {
    result = date.substr(4, 2);
  } else {
    result = "error";
  }
  return result;
};
//日
let dayDiv = function (date) {
  let result = "";
  if (date != undefined && date != null && date.length == 8) {
    result = date.substr(6, 2);
  } else {
    result = "error";
  }
  return result;
};

//曜日
let dayWeek = function (year, month, day) {
  let result = "";
  const myWeekTbl = new Array("日", "月", "火", "水", "木", "金", "土");
  const myDate = new Date(year, month - 1, day);
  const myWeek = myDate.getDay();
  result = myWeekTbl[myWeek];
  if (result == "土") {
    result = '<span class="_sat">' + result + "</span>";
  } else if (result == "日") {
    result = '<span class="_sun">' + result + "</span>";
  }
  return result;
};

//-----------------------------------------------------------------
//  出頭数、馬番から枠番を返却
//-----------------------------------------------------------------
//  引数
//  entryNum : 出頭数
//  horseNum : 馬番
//-----------------------------------------------------------------
let calcBracketNum = (entryNum, horseNum) => {
  let intEntryNum = parseInt(entryNum, 10);
  let intHorseNum = parseInt(horseNum, 10);

  if (intHorseNum == 1) return 1;
  if (intHorseNum == 2) {
    //2:15以下で2。16以上で1
    if (intEntryNum <= 15) return 2;
    if (intEntryNum >= 16) return 1;
  }
  if (intHorseNum == 3) {
    //3:14以下で3。15以上で2
    if (intEntryNum <= 14) return 3;
    if (intEntryNum >= 15) return 2;
  }
  if (intHorseNum == 4) {
    //4:13以下で4。14、15で3、16以上で2
    if (intEntryNum <= 13) return 4;
    if (intEntryNum == 14 || intEntryNum == 15) return 3;
    if (intEntryNum >= 16) return 2;
  }
  if (intHorseNum == 5) {
    //5:12以下で5。13、14で4。15以上で3
    if (intEntryNum <= 12) return 5;
    if (intEntryNum == 13 || intEntryNum == 14) return 4;
    if (intEntryNum >= 15) return 3;
  }
  if (intHorseNum == 6) {
    //6:11以下で6。12、13で5。14、15で4。16以上で3。
    if (intEntryNum <= 11) return 6;
    if (intEntryNum == 12 || intEntryNum == 13) return 5;
    if (intEntryNum == 14 || intEntryNum == 15) return 4;
    if (intEntryNum >= 16) return 3;
  }
  if (intHorseNum == 7) {
    //7:10以下で7。11、12で6。13、14で5。15以上で4
    if (intEntryNum <= 10) return 7;
    if (intEntryNum == 11 || intEntryNum == 12) return 6;
    if (intEntryNum == 13 || intEntryNum == 14) return 5;
    if (intEntryNum >= 15) return 4;
  }
  if (intHorseNum == 8) {
    //8: 9以下で8。10、11で7。12、13で6。14、15で5。16以上で4
    if (intEntryNum <= 9) return 8;
    if (intEntryNum == 10 || intEntryNum == 11) return 7;
    if (intEntryNum == 12 || intEntryNum == 13) return 6;
    if (intEntryNum == 14 || intEntryNum == 15) return 5;
    if (intEntryNum >= 16) return 4;
  }
  if (intHorseNum == 9) {
    //9:10以下で8。11、12で7。13、14で6。15以上で5
    if (intEntryNum <= 10) return 8;
    if (intEntryNum == 11 || intEntryNum == 12) return 7;
    if (intEntryNum == 13 || intEntryNum == 14) return 6;
    if (intEntryNum >= 15) return 5;
  }
  if (intHorseNum == 10) {
    //10:11以下で8。12、13で7。14、15で6。16以上で5
    if (intEntryNum <= 11) return 8;
    if (intEntryNum == 12 || intEntryNum == 13) return 7;
    if (intEntryNum == 14 || intEntryNum == 15) return 6;
    if (intEntryNum >= 16) return 5;
  }
  if (intHorseNum == 11) {
    //11:12以下で8。13、14で7。15以上で6
    if (intEntryNum <= 12) return 8;
    if (intEntryNum == 13 || intEntryNum == 14) return 7;
    if (intEntryNum >= 15) return 6;
  }
  if (intHorseNum == 12) {
    //12:13以下で8。14、15で7。16以上で6
    if (intEntryNum <= 13) return 8;
    if (intEntryNum == 14 || intEntryNum == 15) return 7;
    if (intEntryNum >= 16) return 6;
  }
  if (intHorseNum == 13) {
    //13:14以下で8。15以上で7
    if (intEntryNum <= 14) return 8;
    if (intEntryNum >= 15) return 7;
  }
  if (intHorseNum == 14) {
    //14:15以下で8。16以上で7
    if (intEntryNum <= 15) return 8;
    if (intEntryNum >= 16) return 7;
  }
  if (intHorseNum == 15) {
    //15:17以下で7。18で8
    if (intEntryNum <= 17) return 8;
    if (intEntryNum == 18) return 7;
  }
  if (intHorseNum == 16) return 8;
  if (intHorseNum == 17) return 8;
  if (intHorseNum == 18) return 8;
};

const isCoupleBracketNum = (entryNum, bracketNum) => {
  const intEntryNum = parseInt(entryNum, 10);
  const intBracketNum = parseInt(bracketNum, 10);

  return intEntryNum >= 8 * 2 - (intBracketNum - 1);
};

//-----------------------------------------------------------------
//  賞金額を文字列（*億*万円）に整形する
//-----------------------------------------------------------------
//  引数
//  prize : 賞金額（100円単位）
//-----------------------------------------------------------------

let prizeToString = function (prize) {
  let result = "";
  let str = String(prize);
  if (str.length > 6) {
    result += str.substring(0, str.length - 6) + "億";
    result += str.substring(str.length - 6, str.length - 2) + "万円";
  } else if (str.length > 2) {
    result += str.substring(0, str.length - 2) + "万円";
  } else {
    result += "-";
  }
  return result;
};

//-----------------------------------------------------------------
//  賞金額を文字列（*億*万円）に整形する
//-----------------------------------------------------------------
//  引数
//  prize : 賞金額（1円単位）
//-----------------------------------------------------------------
let prizeToStringForSale = function (prize) {
  let result = "";
  let str = String(prize);
  if (str.length > 8) {
    result += str.substring(0, str.length - 8) + "億";
    result += str.substring(str.length - 8, str.length - 4) + "万円";
  } else if (str.length > 4) {
    result += str.substring(0, str.length - 4) + "万円";
  } else {
    result += "-";
  }
  return result;
};

//-----------------------------------------------------------------
//  収支の回収率、収支合計を計算 回収率は%表示、小数点以下切り捨て
//-----------------------------------------------------------------
//  引数
//  spend : 購入額
//  refund：払戻額
//-----------------------------------------------------------------
//回収率
let recoveryRate = function (spend, refund) {
  if (spend == null || isNaN(spend) || refund == null || isNaN(refund)) {
    return "0";
  }
  if (spend * 1 == 0) {
    return "0";
  } else {
    let recovery = "";
    recovery = String(Math.floor((refund * 100) / (spend * 1)));
    return recovery;
  }
};
//収支合計
let sumTotal = function (spend, refund) {
  let sum = "";
  sum = refund - spend;
  return sum;
};
let sumTotalEx = function (spend, refund, returned) {
  let sum = "";
  sum = refund - spend + returned;
  return sum;
};

//-----------------------------------------------------------------
//  金額を3ケタごとにカンマで区切る
//-----------------------------------------------------------------
//  引数
//  prize : 金額
//-----------------------------------------------------------------
let replaceComma = function (prize) {
  let comma3 = "";
  comma3 = String(prize).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  return comma3;
};

//-----------------------------------------------------------------
//  yyyymmddの文字列を を mm/dd(曜日)の文字列に変更する
//-----------------------------------------------------------------
//  引数
//  dateStr : yyyymmddの文字列
//-----------------------------------------------------------------
function date2String(dateStr) {
  let result = "";
  try {
    if (dateStr != undefined && dateStr != null) {
      let year = dateStr.substring(0, 4);
      let mon = dateStr.substring(4, 6);
      let date = dateStr.substring(6);
      result = mon + "/" + date + "(";

      dd = new Date(Number(year), Number(mon) - 1, Number(date));
      let day = dd.getDay();
      switch (day) {
        case 0:
          result += "日";
          break;
        case 1:
          result += "月";
          break;
        case 2:
          result += "火";
          break;
        case 3:
          result += "水";
          break;
        case 4:
          result += "木";
          break;
        case 5:
          result += "金";
          break;
        case 6:
          result += "土";
          break;
      }
      result += ")";
    }
  } catch (e) {
    //alert("date2String(" + dateStr + ")" + e);
  }
  return result;
}

//-----------------------------------------------------------------
//  mm/dd hh:MMの文字列を を mm月dd日 hh時:MM日の文字列に変更する
//-----------------------------------------------------------------
//  引数
//  dateStr : mm/dd hh:MMの文字列
//-----------------------------------------------------------------
function datetime2String(dateStr) {
  let result = "";
  try {
    if (dateStr != undefined && dateStr != null) {
      let mon = dateStr.substring(0, 2);
      let date = dateStr.substring(3, 5);
      let hh = dateStr.substring(6, 8);
      let mm = dateStr.substring(9);

      //分は数字にしないこと！　12:00が12:0となってしまいます！
      result +=
        Number(mon) + "月" + Number(date) + "日 " + Number(hh) + ":" + mm;
    }
  } catch (e) {
    //alert("datetime2String(" + dateStr + ")" + e);
  }
  return result;
}
//-----------------------------------------------------------------
//日付フォーマット関数（例：×年○月△日（□））
//-----------------------------------------------------------------
//  引数:dateStr=yyyymmddの文字列
//-----------------------------------------------------------------
function date2StringTypeYmdd(dateStr) {
  let DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  let month = parseInt(dateStr.slice(4, 6), 10);
  let date = parseInt(dateStr.slice(6, 8), 10);
  let mDate = new Date(parseInt(dateStr.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day == "土") {
    day = '<span class="sat">' + day + "</span>";
  } else if (day == "日") {
    day = '<span class="sun">' + day + "</span>";
  }
  let str =
    dateStr.slice(0, 4) + "年" + month + "月" + date + "日(" + day + ")";

  return str;
}
//-----------------------------------------------------------------
//日付フォーマット関数（例：×年○月△日（□））
//-----------------------------------------------------------------
//  引数:dateStr=yyyymmddの文字列
//-----------------------------------------------------------------
function date2StringTypeMMdd(dateStr) {
  let str = "";
  if (dateStr != undefined && dateStr != null && dateStr.length == 8) {
    let DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
    let month = parseInt(dateStr.slice(4, 6), 10);
    let date = parseInt(dateStr.slice(6, 8), 10);
    let mDate = new Date(parseInt(dateStr.slice(0, 4), 10), month - 1, date);
    let day = DAY_LBL[mDate.getDay()];
    if (day == "土") {
      day = '<span class="sat">' + day + "</span>";
    } else if (day == "日") {
      day = '<span class="sun">' + day + "</span>";
    }
    str = month + "月" + date + "日(" + day + ")";
  }

  return str;
}

// PACK ADD START
//-----------------------------------------------------------------
//翌日を求める
//-----------------------------------------------------------------
//  引数:dateStr=yyyymmddの文字列
//-----------------------------------------------------------------
function date2StringTypeNextDay(dateStr) {
  let result = "";
  if (dateStr != undefined && dateStr != null && dateStr.length == 8) {
    let year = parseInt(dateStr.slice(0, 4), 10);
    let month = parseInt(dateStr.slice(4, 6), 10);
    let day = parseInt(dateStr.slice(6, 8), 10);

    let plmdate = new Date(year, month - 1, day);
    plmdate.setDate(plmdate.getDate() + 1);

    let y = plmdate.getFullYear();
    let m = plmdate.getMonth() + 1;
    let d = plmdate.getDate();
    result = y + "/" + m + "/" + d;
  }

  return result;
}
// PACK ADD END

//-----------------------------------------------------------------
//  オッズと金額から払戻金を計算する
//-----------------------------------------------------------------
//  引数
//  odds : オッズ （1.1など、小数点第一位まで含んだ文字列）
//  amount : 購入金額。100円単位
//-----------------------------------------------------------------
//  戻り値
// refund : 払戻金（1円単位/文字列）
//-----------------------------------------------------------------
let calRefund = function (odds, amount) {
  let payment = parseInt(amount, 10);
  let cal = odds;
  let refund = 0;

  if (cal != undefined && cal != null && cal != "") {
    cal = cal.replace(".", "");
    cal = parseInt(cal, 10);
  } else {
    cal = 0;
  }

  if (!isNaN(payment) && !isNaN(cal)) {
    refund = (payment * cal) / 10;
  }
  return String(refund);
};
//-----------------------------------------------------------------
//  ユーザーエージェントを確認し、Androidアプリ（月額版orスマパス版）からのアクセスか確認する
//-----------------------------------------------------------------
//  戻り値
//  true : アプリからのアクセス , false :  アプリ以外からのアクセス
//-----------------------------------------------------------------
let isAccessFromAPK = function () {
  let userAgent = window.navigator.userAgent.toLowerCase();
  let isAndroid = false;
  if (userAgent.indexOf("winkeiba") > -1) {
    isAndroid = true;
  }
  return isAndroid;
};
//-----------------------------------------------------------------
//  ユーザーエージェントを確認し、Android2.X系のアクセスか確認する
//-----------------------------------------------------------------
//  戻り値
//  true : Android2.X系からのアクセス , false :  Android2.X系以外からのアクセス
//-----------------------------------------------------------------
let isAccessFromAndroid2 = function () {
  let userAgent = window.navigator.userAgent.toLowerCase();
  let isAndroid2 = false;
  if (userAgent.match(/android 2./i)) {
    isAndroid2 = true;
  }
  return isAndroid2;
};

//-----------------------------------------------------------------
//  メール配信リリース後の初回アクセスか確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 0:初回 1:初回ではない
//-----------------------------------------------------------------
let isFirstAccessForMail = function () {
  let result = 0;
  let str = getCookie(MAIL_FIRST);
  if (str == "1") {
    result = 1;
  }
  return result;
};

//-----------------------------------------------------------------
//  メール配信リリース後のアクセスフラグを立てる
//  登録先はcookie
//-----------------------------------------------------------------
let setFirstAccessFlgForMail = function () {
  setCookie(MAIL_FIRST, "1");
};

//-----------------------------------------------------------------
//  メールアドレスを確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : "":設定なし、メールアドレス
//-----------------------------------------------------------------
let isMailAddres = function () {
  let result = "";
  let str = getCookie(MAIL_ADDRESS);
  if (str != null && str != undefined && str != "") {
    result = str;
  }
  return result;
};

//-----------------------------------------------------------------
//  メールアドレスを保存する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  mail : メールアドレス
//-----------------------------------------------------------------
let setMailAddres = function (mail) {
  setCookie(MAIL_ADDRESS, mail);
};

//-----------------------------------------------------------------
//  メール配信、詳細の設定を確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let isMailConfigDetail = function () {
  let result = "2";
  let str = getCookie(MAIL_DETAIL);
  if (str == "1") {
    result = "1";
  }
  return result;
};

//-----------------------------------------------------------------
//  メール配信、詳細の設定を更新する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  user : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let setMailConfigDetail = function (flg) {
  if (flg == "1") {
    setCookie(MAIL_DETAIL, "1");
  } else {
    setCookie(MAIL_DETAIL, "2");
  }
};

//-----------------------------------------------------------------
//  メール配信、重賞の設定を確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let isMailConfigJusho = function () {
  let result = "2";
  let str = getCookie(MAIL_JUSHO);
  if (str == "1") {
    result = "1";
  }
  return result;
};

//-----------------------------------------------------------------
//  メール配信、重賞の設定を更新する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  user : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let setMailConfigJusho = function (flg) {
  if (flg == "1") {
    setCookie(MAIL_JUSHO, "1");
  } else {
    setCookie(MAIL_JUSHO, "2");
  }
};

//-----------------------------------------------------------------
//  メール配信、WIN5の設定を確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let isMailConfigWin5 = function () {
  let result = "2";
  let str = getCookie(MAIL_WIN5);
  if (str == "1") {
    result = "1";
  }
  return result;
};

//-----------------------------------------------------------------
//  メール配信、WIN5の設定を更新する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  user : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let setMailConfigWin5 = function (flg) {
  if (flg == "1") {
    setCookie(MAIL_WIN5, "1");
  } else {
    setCookie(MAIL_WIN5, "2");
  }
};

//-----------------------------------------------------------------
//  メール配信、お気に入りの設定を確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let isMailConfigFavorite = function () {
  let result = "2";
  let str = getCookie(MAIL_FAVORITE);
  if (str == "1") {
    result = "1";
  }
  return result;
};

//-----------------------------------------------------------------
//  メール配信、お気に入りの設定を更新する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  user : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let setMailConfigFavorite = function (flg) {
  if (flg == "1") {
    setCookie(MAIL_FAVORITE, "1");
  } else {
    setCookie(MAIL_FAVORITE, "2");
  }
};

//-----------------------------------------------------------------
//  メール配信、POGの設定を確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let isMailConfigPog = function () {
  let result = "2";
  let str = getCookie(MAIL_POG);
  if (str == "1") {
    result = "1";
  }
  return result;
};

//-----------------------------------------------------------------
//  メール配信、POGの設定を更新する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  user : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let setMailConfigPog = function (flg) {
  if (flg == "1") {
    setCookie(MAIL_POG, "1");
  } else {
    setCookie(MAIL_POG, "2");
  }
};

//-----------------------------------------------------------------
//  メール配信、次走狙い馬の設定を更新する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  user : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let setMailConfigNextTarget = function (flg) {
  if (flg == "1") {
    setCookie(MAIL_NEXTTARGET, "1");
  } else {
    setCookie(MAIL_NEXTTARGET, "2");
  }
};

//-----------------------------------------------------------------
//  メール配信、お知らせの設定を確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let isMailConfigOshirase = function () {
  let result = "2";
  let str = getCookie(MAIL_OSHIRASE);
  if (str == "1") {
    result = "1";
  }
  return result;
};

//-----------------------------------------------------------------
//  メール配信、お知らせの設定を更新する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  user : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let setMailConfigOshirase = function (flg) {
  if (flg == "1") {
    setCookie(MAIL_OSHIRASE, "1");
  } else {
    setCookie(MAIL_OSHIRASE, "2");
  }
};

//-----------------------------------------------------------------
//  メール配信、広告の設定を確認する
//  確認先はcookie
//-----------------------------------------------------------------
//  戻り値
//  result : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let isMailConfigAdd = function () {
  let result = "2";
  let str = getCookie(MAIL_ADD);
  if (str == "1") {
    result = "1";
  }
  return result;
};

//-----------------------------------------------------------------
//  メール配信、広告の設定を更新する
//  保存先はcookie
//-----------------------------------------------------------------
//  引数
//  user : 2:配信なし 1:配信あり
//-----------------------------------------------------------------
let setMailConfigAdd = function (flg) {
  if (flg == "1") {
    setCookie(MAIL_ADD, "1");
  } else {
    setCookie(MAIL_ADD, "2");
  }
};

//-----------------------------------------------------------------
//  base64エンコードを行う
//-----------------------------------------------------------------
//  引数
//  エンコードを行う文字列
//-----------------------------------------------------------------
let encodeString = function (str) {
  let base64list =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  //Base64エンコード
  let resultStr = "",
    p = -6,
    a = 0,
    i = 0,
    v = 0,
    c;
  while (i < str.length || p > -6) {
    if (p < 0) {
      if (i < str.length) {
        c = str.charCodeAt(i++);
        v += 8;
      } else {
        c = 0;
      }
      a = ((a & 255) << 8) | (c & 255);
      p += 8;
    }
    resultStr += base64list.charAt(v > 0 ? (a >> p) & 63 : 64);
    p -= 6;
    v -= 6;
  }
  return resultStr;
};

//-----------------------------------------------------------------
//  競走条件アイコンのテキスト一覧（field名は2007.競走条件コード）
//-----------------------------------------------------------------
let REGULATION_TEXT = {
  "001": "100",
  "002": "200",
  "003": "300",
  "004": "400",
  "005": "500",
  "006": "600",
  "007": "700",
  "008": "800",
  "009": "900",
  "010": "1000",
  "011": "1100",
  "012": "1200",
  "013": "1300",
  "014": "1400",
  "015": "1500",
  "016": "1600",
  "017": "1700",
  "018": "1800",
  "019": "1900",
  "020": "2000",
  "021": "2100",
  "022": "2200",
  "023": "2300",
  "024": "2400",
  "025": "2500",
  "026": "2600",
  "027": "2700",
  "028": "2800",
  "029": "2900",
  "030": "3000",
  "031": "3100",
  "032": "3200",
  "033": "3300",
  "034": "3400",
  "035": "3500",
  "036": "3600",
  "037": "3700",
  "038": "3800",
  "039": "3900",
  "040": "4000",
  "041": "4100",
  "042": "4200",
  "043": "4300",
  "044": "4400",
  "045": "4500",
  "046": "4600",
  "047": "4700",
  "048": "4800",
  "049": "4900",
  "050": "5000",
  "051": "5100",
  "052": "5200",
  "053": "5300",
  "054": "5400",
  "055": "5500",
  "056": "5600",
  "057": "5700",
  "058": "5800",
  "059": "5900",
  "060": "6000",
  "061": "6100",
  "062": "6200",
  "063": "6300",
  "064": "6400",
  "065": "6500",
  "066": "6600",
  "067": "6700",
  "068": "6800",
  "069": "6900",
  "070": "7000",
  "071": "7100",
  "072": "7200",
  "073": "7300",
  "074": "7400",
  "075": "7500",
  "076": "7600",
  "077": "7700",
  "078": "7800",
  "079": "7900",
  "080": "8000",
  "081": "8100",
  "082": "8200",
  "083": "8300",
  "084": "8400",
  "085": "8500",
  "086": "8600",
  "087": "8700",
  "088": "8800",
  "089": "8900",
  "090": "9000",
  "091": "9100",
  "092": "9200",
  "093": "9300",
  "094": "9400",
  "095": "9500",
  "096": "9600",
  "097": "9700",
  "098": "9800",
  "099": "9900",
  100: "1億",
  701: "新馬",
  702: "未出走",
  703: "未勝利",
  999: "OP",
};

//-----------------------------------------------------------------
//  競走条件アイコンのテキスト一覧（field名は2007.競走条件コード）
//-----------------------------------------------------------------
let REGULATION_TEXT_NEW = {
  "001": "100",
  "002": "200",
  "003": "300",
  "004": "400",
  "005": "1勝・500",
  "006": "600",
  "007": "700",
  "008": "800",
  "009": "900",
  "010": "2勝・1000",
  "011": "1100",
  "012": "1200",
  "013": "1300",
  "014": "1400",
  "015": "1500",
  "016": "3勝・1600",
  "017": "1700",
  "018": "1800",
  "019": "1900",
  "020": "2000",
  "021": "2100",
  "022": "2200",
  "023": "2300",
  "024": "2400",
  "025": "2500",
  "026": "2600",
  "027": "2700",
  "028": "2800",
  "029": "2900",
  "030": "3000",
  "031": "3100",
  "032": "3200",
  "033": "3300",
  "034": "3400",
  "035": "3500",
  "036": "3600",
  "037": "3700",
  "038": "3800",
  "039": "3900",
  "040": "4000",
  "041": "4100",
  "042": "4200",
  "043": "4300",
  "044": "4400",
  "045": "4500",
  "046": "4600",
  "047": "4700",
  "048": "4800",
  "049": "4900",
  "050": "5000",
  "051": "5100",
  "052": "5200",
  "053": "5300",
  "054": "5400",
  "055": "5500",
  "056": "5600",
  "057": "5700",
  "058": "5800",
  "059": "5900",
  "060": "6000",
  "061": "6100",
  "062": "6200",
  "063": "6300",
  "064": "6400",
  "065": "6500",
  "066": "6600",
  "067": "6700",
  "068": "6800",
  "069": "6900",
  "070": "7000",
  "071": "7100",
  "072": "7200",
  "073": "7300",
  "074": "7400",
  "075": "7500",
  "076": "7600",
  "077": "7700",
  "078": "7800",
  "079": "7900",
  "080": "8000",
  "081": "8100",
  "082": "8200",
  "083": "8300",
  "084": "8400",
  "085": "8500",
  "086": "8600",
  "087": "8700",
  "088": "8800",
  "089": "8900",
  "090": "9000",
  "091": "9100",
  "092": "9200",
  "093": "9300",
  "094": "9400",
  "095": "9500",
  "096": "9600",
  "097": "9700",
  "098": "9800",
  "099": "9900",
  100: "1億",
  701: "新馬",
  702: "未出走",
  703: "未勝利",
  999: "OP",
};

//コース情報を取得 #4999
function retrieveCourse(callback) {
  if (COURSE_SUGOTOKU == getCourseId()) {
    callback(PAYINGSUGOTOKU, true);
  } else if (COURSE_NOT_SUGOTOKU == getCourseId()) {
    callback(PAYINGSUGOTOKU_NONE, false);
  } else {
    let logReq = HOST + PATH + "getUserCourse.js" + BACKWORDPATH;
    getJSON(logReq, true, function (status, json) {
      let courseId = PAYINGNONE;
      let showMarks = false;
      let userId = "";
      if (status == SUCCESS) {
        if (json && json.courseId && json.courseId != "") {
          courseId = json.courseId;
        }
        if (json && json.showMarks && json.showMarks != "") {
          showMarks = json.showMarks;
        }
        if (json && json.userId && json.userId != "") {
          userId = json.userId;
        }
      }
      callback(courseId, showMarks, userId);
    });
  }
}

// 非同期処理を行いたいため追加（上の影響範囲がわからないため一旦）
async function syncRetrieveCourse(callback) {
  if (COURSE_SUGOTOKU == getCourseId()) {
    callback(PAYINGSUGOTOKU, true);
  } else if (COURSE_NOT_SUGOTOKU == getCourseId()) {
    callback(PAYINGSUGOTOKU_NONE, false);
  } else {
    let logReq = HOST + PATH + "getUserCourse.js" + BACKWORDPATH;
    await getJSON(logReq, true, function (status, json) {
      let courseId = PAYINGNONE;
      let showMarks = false;
      let userId = '';
      if (status == SUCCESS) {
        if (json && json.courseId && json.courseId != "") {
          courseId = json.courseId;
        }
        if (json && json.showMarks && json.showMarks != "") {
          showMarks = json.showMarks;
        }
        if (json && json.userId && json.userId != "") {
          userId = json.userId;
        }
      }
      callback(courseId, showMarks, userId);
    });
  }
}

const getUserAgent = () => {
  const userAgent = window.navigator.userAgent;
  return userAgent.indexOf("WINKEIBA/APP") !== -1 ? true : false;
};

const sugotokuGetUserAgent = () => {
  const userAgent = window.navigator.userAgent;
  return userAgent.includes("WINKEIBA/APP") && !userAgent.includes("iPhone")
};

const callDart = () => {
  console.log("call dart function");
  // Flutterのname.postMessage()
  getData.postMessage();
};

//-----------------------------------------------------------------
//  弁天系の遷移先URLを返す
//-----------------------------------------------------------------
const userSettingURL = (fname) => {
  const req = HOST + CERTPATH + fname + "?redirect=0";
  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      if (Object.keys(json).length) {
        location.href = json.url;
      }
    }
  });
};

function isNar () {
  const url = location.href;
  return url.indexOf('nar') > -1 ? 1 : 0;
}

const isAtag = (url) => {
  const regex = new RegExp('http*');
  return regex.test(url)
}

//-----------------------------------------------------------------
// 旧Androidユーザーであるか判定
// ※ 新Androidアプリリリース後に削除予定
// 戻り値: boolean
//-----------------------------------------------------------------
const isAndroid = () => {
  const regexpA = /Android/i
  const regexpB = /wv/i
  if (window.navigator.userAgent.search(regexpA) && window.navigator.userAgent.search(regexpB) !== -1) {
    return true
  }
  return false
}

// スゴ得ユーザーである
const isSugotoku = ()  => {
  const userCourse = getMenmerCourse()
  return userCourse === PAYINGSUGOTOKU || userCourse === PAYINGSUGOTOKU_NONE
}

//アプリダウンロード用バナー取得
const getTopBanner = async (isAndroid = undefined, isIphone = undefined) => {
  if (isAndroid === undefined && isIphone === undefined) {
    const viewType = await isIphoneOrIsAndroid()
    isAndroid = viewType?.isAndroid
    isIphone = viewType?.isIphone
  }

  let key = ''
  if (isAndroid) {
    key = 'android'
  } else if (isIphone) {
    key = 'ios'
  }

  const isNotMember = getMenmerCourse() === PAYINGNONE
  const isNewsPage = location.href.indexOf('news/article') > -1
  const isHorsePage = location.href.indexOf('data/horse') > -1

  if (isNewsPage && key) {
    key = isNotMember ? 'news_' + key : ''
  } else if (isHorsePage && key) {
    key = isNotMember ? 'horse_' + key : ''
  }

  if (!key) return ''

  return await axios.get(JRA_WP_HOST + 'wp-json/wp/v2/pages/?slug=banner')
      .then((response) => {
        return response.data[0].acf.free_area[key]
      })
      .catch((error) => {
        console.log(error);
        return ''
      });
}

//iPhone、もしくはAndroidであるか
const isIphoneOrIsAndroid = async () => {
  const request =
      HOST +
      PATH +
      "getLoginInfo.js" +
      BACKWORDPATH +
      "?NarFlag=0";
  let response = {}
  await getJSON(request, false, (status, json) => {
    response = {
      isIphone: json.isIphone,
      isAndroid: json.isAndroid
    }
  });
  return response
}

// オッズデフォルト表示設定取得
const getCustomizeOddsType = async (userId) => {
  const request = HOST + PATH + "Member/getCustomizeOddsType.js" + BACKWORDPATH +
      "?userId=" +
      encodeURIComponent(userId);

  let customValue = '1'

  await getJSON(request, true, function (status, json) {
      if (json.customizeOddsType) customValue = json.customizeOddsType
  });
  return customValue
}

// TOPデフォルトオッズ設定
const setOddsUrl = async (defaultTOB) => {
  const bidUrl = {
    1: "win", //単勝
    2: "place", //複勝
    3: "bracket", //枠連
    4: "quinella", //馬連
    5: "exacta", //馬単
    6: "wide", //ワイド
    7: "trio", //三連複
    8: "trifecta", //三連単
  };
  return defaultTOB && bidUrl[defaultTOB]
    ? bidUrl[defaultTOB]
    : bidUrl["1"];//何もセットされていない場合は単勝
};

// 枠単オッズ表示
const hasBracketExactaOdds = (raceTrackCd) => {
    // 浦和・船橋・大井・川崎・金沢
    const bracketExactaOddsRaceTrackCds = ['42','43','44','45','46'];
    return bracketExactaOddsRaceTrackCds.includes(raceTrackCd) && isNar();
};

const scrollFormationFunc = () => {
  const target = document.getElementsByClassName("container-total")
  if (target[0]) {
    if(window.scrollY >= target[0].offsetTop - window.innerHeight) {
      return false
    } else {
      return true
    }
  }
}

const getAreas = (key) => {
  switch (key) {
    case 'jra':
      return [
        { id: 0, name: "全国" },
        { id: 1, name: "関東" },
        { id: 2, name: "関西" },
      ]
    case 'narWeightLossJockey' :
      return [
        { id: 0, name: "全国" },
        { id: 23, name: "大井" },
        { id: 24, name: "川崎" },
        { id: 26, name: "船橋" },
        { id: 25, name: "浦和" },
        { id: 5, name: "北海道" },
        { id: 6, name: "岩手" },
        { id: 9, name: "金沢" },
        { id: 10, name: "愛知" },
        { id: 11, name: "笠松" },
        { id: 12, name: "兵庫" },
        { id: 15, name: "高知" },
        { id: 17, name: "佐賀" },
      ]
    default :
      return [
        { id: 0, name: "全国" },
        { id: 44, name: "大井" },
        { id: 45, name: "川崎" },
        { id: 43, name: "船橋" },
        { id: 42, name: "浦和" },
        { id: 30, name: "門別" },
        { id: 35, name: "盛岡" },
        { id: 36, name: "水沢" },
        { id: 46, name: "金沢" },
        { id: 48, name: "名古屋" },
        { id: 47, name: "笠松" },
        { id: 50, name: "園田" },
        { id: 51, name: "姫路" },
        { id: 54, name: "高知" },
        { id: 55, name: "佐賀" },
      ]
  }
}


// グレードコード、トラック分類、競走条件からグレードアイコンのclassを取得する
const getGradeIconClass = (gradeCd, trackTypeCd, raceRegulationCd) => {
  let tag = '';
  tag = {
    'A': '-g1',
    'B': '-g2',
    'C': '-g3',
    'L': '-L'
  }[gradeCd] ?? '';
  if(tag === '') {
    tag = {
      '005': '-p1',
      '010': '-p2',
      '016': '-p3',
      '999': '-op'
    }[raceRegulationCd] ?? '';
  }
  if(trackTypeCd === '3' && ['-g1','-g2','-g3','-op'].includes(tag)) // 障害
  {
    tag = tag.replace('-', '-j');
  }
  return tag ? `icon-race ${tag}` : '';
}
// グレードコード、レース名、レースランクからグレードアイコンのclassを取得する（NAR）
const getGradeIconClassNar = (gradeCd, raceMainName, raceRank = '') => {
  let tag = '';
  if (gradeCd === 'A' && raceMainName.includes('東京大賞典')) {
    return 'icon-race -g1';
  }
  tag = {
    'A': '-jpn1',
    'B': '-jpn2',
    'C': '-jpn3',
  }[gradeCd] ?? '';
  if(tag === '' && raceRank) {
    tag = '-nograde';
  }
  return tag ? `icon-race ${tag}` : '';
}

//ユーザー関連のcookieをリセットする
const resetUserCookie = async () => {
  const domain = COOKIE_DOMAIN ? `;domain=${COOKIE_DOMAIN}` : ''
  document.cookie = `user_type=;maxAge=0;${domain}`;
  document.cookie = `user_id=;maxAge=0;${domain}`;
  document.cookie = `pay_type=0;${domain}`;
  document.cookie = `pog_user=0;${domain}`;
}

//ソートを行う
const sortBy = (field, reverse, primer) => {
  reverse = reverse ? -1 : 1;
  return function (a, b) {
    a = a[field] * 10;
    b = b[field] * 10;
    if (typeof primer != "undefined") {
      a = primer(a);
      b = primer(b);
    }
    if (a < b) return reverse * -1;
    if (a > b) return reverse * 1;
  };
}

//デフォルトの購入タイプを取得・設定
let getDefaultPurchase = function () {
  return getCookie(DEFAULT_PURCHASE) ?? 'spat4';
};
let setDefaultPurchase = function (purchaseType) {
  set30daysCookie(DEFAULT_PURCHASE, purchaseType);
};

export {
  PAYINGNONE,
  PAYINGECONOMY,
  PAYINGPREMIUM,
  PAYINGYAHOO,
  PAYINGSMARTPASS,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  SHOW,
  HIDDEN,
  SUCCESS,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  FRACEHORSE,
  FBROODMARE,
  FJOCKEY,
  FTRAINER,
  FOWNER,
  FBREEDER,
  FSTALLION,
  TRACEHORSE,
  ADD,
  REMOVE,
  USERTYPE,
  USERID,
  COURSEID,
  MAIL_DETAIL,
  MAIL_JUSHO,
  MAIL_WIN5,
  MAIL_FAVORITE,
  MAIL_POG,
  MAIL_NEXTTARGET,
  MAIL_OSHIRASE,
  MAIL_ADD,
  MAIL_ADDRESS,
  PAYTYPE,
  JOINSTATUS,
  MIGRATEDIALOG,
  MULTIDIALOG,
  CARDDIALOG,
  REGULATION_TEXT,
  REGULATION_TEXT_NEW,
  DATETYPE,
  DEFAULT_RACETRACKCD,
  POGUSER,
  IPAT_INETID,
  EXPIRATIONDATE03,
  EXPIRATIONDATE06,
  EXPIRATIONDATE12,
  MULTICHECK,
  MIGRATECHECK,
  CARDCHECK,
};

export {
  winFormat,
  getJSON_async,
  getUrlVars,
  checkUrlVars,
  getUlrParams,
  retrieveCourse,
  totalRes,
  rateFirst,
  rateSecond,
  rateThird,
  yearDiv,
  monthDiv,
  dayDiv,
  dayWeek,
  getMenmerCourse,
  getJSON,
  date2String,
  date2StringTypeMMdd,
  prizeToString,
  calcBracketNum,
  getCookie,
  setCookie,
  isFavorite,
  isNextTarget,
  getCourse,
  isDismissDialog,
  setDismissDialog,
  getUserId,
  calRefund,
  recoveryRate,
  sumTotalEx,
  replaceComma,
  isAccessFromAndroid2,
  date2StringTypeYmdd,
  isPogUser,
  isAccessFromAPK,
  changeFavoriteFromCookie,
  getNextTargetFromCookie,
  changeNextTargetFromCookie,
  isAutoFavorite,
  getCourseId,
  loadIPATLoginInfo,
  saveIPATLoginInfo,
  setIPAT_CONFIRM,
  isIPAT_CONFIRM,
  setSUGOTOKU_IPAT_CONFIRM,
  isSUGOTOKU_IPAT_CONFIRM,
  isSaveINETID,
  getRequest,
  getUserAgent,
  sugotokuGetUserAgent,
  callDart,
  setMailAddres,
  setMailConfigJusho,
  setMailConfigWin5,
  setMailConfigFavorite,
  setMailConfigPog,
  setMailConfigNextTarget,
  setMailConfigOshirase,
  setMailConfigAdd,
  setMailConfigDetail,
  encodeString,
  getExpirationdate03,
  getExpirationdate06,
  getExpirationdate12,
  date2StringTypeNextDay,
  setPogUser,
  getMemberPayType,
  getJoinStatus,
  userSettingURL,
  setCookieDay,
  isNar,
  isAtag,
  syncRetrieveCourse,
  getBet4Array,
  clearBet4Array,
  setBet4Array,
  setRaceBet4sg,
  isAndroid,
  getTopBanner,
  getCustomizeOddsType,
  setOddsUrl,
  scrollFormationFunc,
  getAreas,
  getGradeIconClassNar,
  getGradeIconClass,
  resetUserCookie,
  isSugotoku,
  isIphoneOrIsAndroid,
  sortBy,
  hasBracketExactaOdds,
  isCoupleBracketNum,
  getNonMemberId,
  getDefaultPurchase,
  setDefaultPurchase,
};
