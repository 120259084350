/*
  ■コード関数一覧
    ・2001.競馬場コード:         RacetrackCd(code)
    ・2003.グレードコード：      GradeCd(code)
    ・2006.競走記号コード：      RaceSymbolCd(code)
    ・2007.競走条件コード：      RaceRegulationCd(code)
    ・2009.トラックコード：      TrackCd(code)
    ・2008.重量種別コード：      WeightTypeCd(code)
    ・2010.馬場状態コード：      TrackConditionCd(code)
    ・2011.天候コード：          WeatherCd(code)
    ・2012.レコード区分：        RecordDiv(code)
    ・2102.着差コード：          HorseLengthsCd(code)
    ・2202.性別コード：          SexCd(code)
    ・2203.毛色コード：          CoatColor(code)
    ・2204.馬記号コード：        HorseSymbolCd(code)
    ・2301.東西区分コード：      EastWestDiv(code)
    ・2303.騎手見習い記号コード：JockeyTrainingCd(code)
    ・2401.所属コード：          BelongCd(code)
      参考元：http://cattle.sakura.ne.jp/07-3-99-CODE.htm
*/
//存在チェック
function checkExist(array,code){
    if (array[code] == undefined) {
        //エラーログ出力
        //ToDo
        return '';
    } else {
        return array[code];
    }
}

//2001.競馬場コード
var innerRacetrackCd = {
    '01' : '札幌',
    '02' : '函館',
    '03' : '福島',
    '04' : '新潟',
    '05' : '東京',
    '06' : '中山',
    '07' : '中京',
    '08' : '京都',
    '09' : '阪神',
    '10' : '小倉',
    '30' : '門別',
    '31' : '北見',
    '32' : '岩見',
    '33' : '帯広',
    '34' : '旭川',
    '35' : '盛岡',
    '36' : '水沢',
    '37' : '上山',
    '38' : '三条',
    '39' : '足利',
    '40' : '宇都',
    '41' : '高崎',
    '42' : '浦和',
    '43' : '船橋',
    '44' : '大井',
    '45' : '川崎',
    '46' : '金沢',
    '47' : '笠松',
    '48' : '名古屋',
    '49' : '紀三',
    '50' : '園田',
    '51' : '姫路',
    '52' : '益田',
    '53' : '福山',
    '54' : '高知',
    '55' : '佐賀',
    '56' : '荒尾',
    '57' : '中津',
    '58' : '札幌',
    '59' : '函館',
    '60' : '新潟',
    '61' : '中京',
    'A0' : '他外',
    'A2' : '日本',
    'A4' : 'アメ',
    'A6' : 'イギ',
    'A8' : 'フラ',
    'B0' : 'イン',
    'B2' : 'アイ',
    'B4' : 'ニュ',
    'B6' : 'オー',
    'B8' : 'カナ',
    'C0' : 'イタ',
    'C2' : 'ドイ',
    'C5' : 'オマ',
    'C6' : 'イラ',
    'C7' : '(ア)',
    'C8' : 'シリ',
    'D0' : 'スウ',
    'D2' : 'ハン',
    'D4' : 'ポル',
    'D6' : 'ロシ',
    'D8' : 'ウル',
    'E0' : 'ペル',
    'E2' : 'アル',
    'E4' : 'ブラ',
    'E6' : 'ベル',
    'E8' : 'トル',
    'F0' : '韓国',
    'F1' : '中国',
    'F2' : 'チリ',
    'F8' : 'パナ',
    'G0' : '香港',
    'G2' : 'スペ',
    'H0' : '西独',
    'H2' : '南ア',
    'H4' : 'スイ',
    'H6' : 'モナ',
    'H8' : 'フィ',
    'I0' : 'プエ',
    'I2' : 'コロ',
    'I4' : 'チェ',
    'I6' : 'チェ',
    'I8' : 'スロ',
    'J0' : 'エク',
    'J2' : 'ギリ',
    'J4' : 'マレ',
    'J6' : 'メキ',
    'J8' : 'モロ',
    'K0' : 'パキ',
    'K2' : 'ポー',
    'K4' : 'パラ',
    'K6' : 'サウ',
    'K8' : 'キプ',
    'L0' : 'タイ',
    'L2' : 'ウク',
    'L4' : 'ベネ',
    'L6' : 'ユー',
    'L8' : 'デン',
    'M0' : 'シン',
    'M2' : '澳門',
    'M4' : '墺',
    'M6' : 'ヨル',
    'M8' : 'カタ'
}
exports.RacetrackCd = function (code) {
    return checkExist(innerRacetrackCd,code);
};
exports.RacetrackArray = innerRacetrackCd;


//2003.グレードコード
var innerGradeCd = {
    'A' : 'G1'
    , 'B' : 'G2'
    , 'C' : 'G3'
    , 'D' : 'その他の重賞'
    , 'L' : 'リステッド'
    , '_' : '重賞以外'
    , '0' : '重賞以外'
}
exports.GradeCd = function (code) {
    return checkExist(innerGradeCd,code);
};
exports.GradeArray = innerGradeCd;

//2006.競走記号コード
var innerRaceSymbolCd = {
    '000': ''
    , '001': '(指)'
    , '002': '若手'
    , '003': '[指]'
    , '004': '(特指)'
    , '020': '牝'
    , '021': '牝 (指)'
    , '023': '牝 [指]'
    , '024': '牝 (特指)'
    , '030': '牡・ｾﾝ'
    , '031': '牡・ｾﾝ (指)'
    , '033': '牡・ｾﾝ [指]'
    , '034': '牡・ｾﾝ (特指)'
    , '040': '牡・牝'
    , '041': '牡・牝 (指)'
    , '043': '牡・牝 [指]'
    , '044': '牡・牝 (特指)'
    , 'A00': '(混)'
    , 'A01': '(混)(指)'
    , 'A02': '(混) 若手'
    , 'A03': '(混)[指]'
    , 'A04': '(混)(特指)'
    , 'A10': '(混) 牡'
    , 'A11': '(混) 牡 (指)'
    , 'A13': '(混) 牡 [指]'
    , 'A14': '(混) 牡 (特)'
    , 'A20': '(混) 牝'
    , 'A21': '(混) 牝 (指)'
    , 'A23': '(混) 牝 [指]'
    , 'A24': '(混) 牝 (特指)'
    , 'A30': '(混) 牡・ｾﾝ'
    , 'A31': '(混) 牡・ｾﾝ (指)'
    , 'A33': '(混) 牡・ｾﾝ [指]'
    , 'A34': '(混) 牡・ｾﾝ (特指)'
    , 'A40': '(混) 牡・牝'
    , 'A41': '(混) 牡・牝 (指)'
    , 'B00': '(父)'
    , 'B01': '(父)(指)'
    , 'B03': '(父)[指]'
    , 'B04': '(父)(特指)'
    , 'C00': '(市)'
    , 'C01': '(市)(指)'
    , 'C03': '(市)[指]'
    , 'C04': '(市)(特指)'
    , 'D00': '(抽)'
    , 'D01': '(抽)(指)'
    , 'D03': '(抽)[指]'
    , 'E00': '[抽]'
    , 'E01': '[抽](指)'
    , 'E03': '[抽][指]'
    , 'F00': '(市)(抽)'
    , 'F01': '(市)(抽)(指)'
    , 'F03': '(市)(抽)[指]'
    , 'F04': '(市)(抽)(特指)'
    , 'G00': '(抽) '
    , 'G01': '(抽)(指)'
    , 'G03': '(抽)[指]'
    , 'H00': '(抽)'
    , 'H01': '(抽)(指)'
    , 'I00': '[抽]'
    , 'I01': '[抽](指)'
    , 'I03': '[抽][指]'
    , 'J00': '[抽]'
    , 'J01': '[抽](指)'
    , 'K00': '(市)(抽)'
    , 'K01': '(市)(抽)(指)'
    , 'K03': '(市)(抽)[指]'
    , 'L00': '(市)(抽)'
    , 'L01': '(市)(抽)(指)'
    , 'L03': '(市)(抽)[指]'
    , 'M00': '九州'
    , 'M01': '九州 (指)'
    , 'M03': '九州 [指]'
    , 'M04': '九州 (特指)'
    , 'N00': '(国際)'
    , 'N01': '(国際)(指)'
    , 'N03': '(国際)[指]'
    , 'N04': '(国際)(特指)'
    , 'N20': '(国際) 牝'
    , 'N21': '(国際) 牝 (指)'
    , 'N23': '(国際) 牝 [指]'
    , 'N24': '(国際) 牝 (特指)'
    , 'N30': '(国際) 牡・ｾﾝ'
    , 'N31': '(国際) 牡・ｾﾝ (指)'
    , 'N40': '(国際) 牡・牝'
    , 'N41': '(国際) 牡・牝 (指)'
    , 'N44': '(国際) 牡・牝 (特指)'
};
exports.RaceSymbolCd = function (code) {
    return checkExist(innerRaceSymbolCd,code);
};
exports.RaceSymbolArray = innerRaceSymbolCd;

//2006.競走記号を2→3バイトに変換(翌日/当日出馬表用)
var convert3ByteRaceSymbol = {
    '  ': '000'
    , 'G ': '001'
    , ' X': '002'
    , '5 ': '003'
    , '6 ': '004'
    , 'A ': '020'
    , 'AG': '021'
    , 'A5': '023'
    , 'A6': '024'
    , 'T ': '030'
    , 'TG': '031'
    , 'T5': '033'
    , 'T6': '034'
    , ' Y': '040'
    , 'YG': '041'
    , 'Y5': '043'
    , 'Y6': '044'
    , 'B ': 'A00'
    , 'O ': 'A01'
    , 'BX': 'A02'
    , 'B5': 'A03'
    , 'B6': 'A04'
    , 'S ': 'A10'
    , 'SG': 'A11'
    , 'S5': 'A13'
    , 'S6': 'A14'
    , 'Q ': 'A20'
    , 'QG': 'A21'
    , 'Q5': 'A23'
    , 'Q6': 'A24'
    , 'U ': 'A30'
    , 'UG': 'A31'
    , 'U5': 'A33'
    , 'U6': 'A34'
    , 'BY': 'A40'
    , 'OY': 'A41'
    , 'C ': 'B00'
    , 'CG': 'B01'
    , 'C5': 'B03'
    , 'C6': 'B04'
    , 'E ': 'C00'
    , 'EG': 'C01'
    , 'E5': 'C03'
    , 'E6': 'C04'
    , 'D ': 'D00'
    , 'DG': 'D01'
    , 'D5': 'D03'
    , 'F ': 'E00'
    , 'FG': 'E01'
    , 'F5': 'E03'
    , 'P ': 'F00'
    , 'PG': 'F01'
    , 'P5': 'F03'
    , 'P6': 'F04'
    , 'I ': 'G00'
    , 'IG': 'G01'
    , 'I5': 'G03'
    , 'J ': 'H00'
    , 'JG': 'H01'
    , 'K ': 'I00'
    , 'KG': 'I01'
    , 'K5': 'I03'
    , 'L ': 'J00'
    , 'LG': 'J01'
    , 'M ': 'K00'
    , 'MG': 'K01'
    , 'M5': 'K03'
    , 'N ': 'L00'
    , 'NG': 'L01'
    , 'N5': 'L03'
    , 'H ': 'M00'
    , 'HG': 'M01'
    , 'H5': 'M03'
    , 'H6': 'M04'
    , 'V ': 'N00'
    , 'VG': 'N01'
    , 'V5': 'N03'
    , 'V6': 'N04'
    , '2 ': 'N20'
    , '2G': 'N21'
    , '25': 'N23'
    , '26': 'N24'
    , '3 ': 'N30'
    , '3G': 'N31'
    , '4 ': 'N40'
    , '4G': 'N41'
    , '46': 'N44'
};
exports.Convert3ByteRaceSymbol = function (code) {
    return checkExist(convert3ByteRaceSymbol,code);
};

//2006.競走記号コード（省略版）
var innerShortRaceSymbolCd = {
    '000': ''
    , '001': ''
    , '002': '若手'
    , '003': ''
    , '004': ''
    , '020': '牝'
    , '021': '牝'
    , '023': '牝'
    , '024': '牝'
    , '030': '牡・ｾﾝ'
    , '031': '牡・ｾﾝ'
    , '033': '牡・ｾﾝ'
    , '034': '牡・ｾﾝ'
    , '040': '牡・牝'
    , '041': '牡・牝'
    , '043': '牡・牝'
    , '044': '牡・牝'
    , 'A00': '混'
    , 'A01': '混'
    , 'A02': '混・若手'
    , 'A03': '混'
    , 'A04': '混'
    , 'A10': '混・牡'
    , 'A11': '混・牡'
    , 'A13': '混・牡'
    , 'A14': '混・牡'
    , 'A20': '混・牝'
    , 'A21': '混・牝'
    , 'A23': '混・牝'
    , 'A24': '混・牝'
    , 'A30': '混・牡・ｾﾝ'
    , 'A31': '混・牡・ｾﾝ'
    , 'A33': '混・牡・ｾﾝ'
    , 'A34': '混・牡・ｾﾝ'
    , 'A40': '混・牡・牝'
    , 'A41': '混・牡・牝'
    , 'B00': ''
    , 'B01': ''
    , 'B03': ''
    , 'B04': ''
    , 'C00': ''
    , 'C01': ''
    , 'C03': ''
    , 'C04': ''
    , 'D00': ''
    , 'D01': ''
    , 'D03': ''
    , 'E00': ''
    , 'E01': ''
    , 'E03': ''
    , 'F00': ''
    , 'F01': ''
    , 'F03': ''
    , 'F04': ''
    , 'G00': ' '
    , 'G01': ''
    , 'G03': ''
    , 'H00': ''
    , 'H01': ''
    , 'I00': ''
    , 'I01': ''
    , 'I03': ''
    , 'J00': ''
    , 'J01': ''
    , 'K00': ''
    , 'K01': ''
    , 'K03': ''
    , 'L00': ''
    , 'L01': ''
    , 'L03': ''
    , 'M00': '九州'
    , 'M01': '九州'
    , 'M03': '九州'
    , 'M04': '九州'
    , 'N00': '国際'
    , 'N01': '国際'
    , 'N03': '国際'
    , 'N04': '国際'
    , 'N20': '国際・牝'
    , 'N21': '国際・牝'
    , 'N23': '国際・牝'
    , 'N24': '国際・牝'
    , 'N30': '国際・牡・ｾﾝ'
    , 'N31': '国際・牡・ｾﾝ'
    , 'N40': '国際・牡・牝'
    , 'N41': '国際・牡・牝'
    , 'N44': '国際・牡・牝'
};
exports.ShortRaceSymbolCd = function (code) {
    return checkExist(innerShortRaceSymbolCd,code);
};
exports.ShortRaceSymbolArray = innerShortRaceSymbolCd;

//2005.競走種別コード
var innerRaceTypeCd = {
      '00' : ''
    , '11' : '2歳'
    , '12' : '3歳'
    , '13' : '3歳以上'
    , '14' : '4歳以上'
    , '18' : '障害3歳以上'
    , '19' : '障害4歳以上'
    , '21' : '2歳'
    , '22' : '3歳'
    , '23' : '3歳以上'
    , '24' : '4歳以上'
}
exports.RaceTypeCd = function (code) {
    return checkExist(innerRaceTypeCd, code);
};
exports.RaceTypeArray = innerRaceTypeCd;

//競走種別コード(省略版)
var innerShortRaceTypeCd = {
      '00' : ''
    , '11' : '2歳'
    , '12' : '3歳'
    , '13' : '3歳上'
    , '14' : '4歳上'
    , '18' : '障害3歳上'
    , '19' : '障害4歳上'
    , '21' : '2歳'
    , '22' : '3歳'
    , '23' : '3歳上'
    , '24' : '4歳上'
}
exports.ShortRaceTypeCd = function (code) {
    return checkExist(innerShortRaceTypeCd, code);
};

//競走種別コード（馬柱用）
var innerShortRaceTypeCd6 = {
    '00' : ''
    , '11' : '2歳'
    , '12' : '3歳'
    , '13' : '3歳以上'
    , '14' : '4歳以上'
    , '18' : '障害'
    , '19' : '障害'
    , '21' : '2歳'
    , '22' : '3歳'
    , '23' : '3歳以上'
    , '24' : '4歳以上'
}
exports.ShortRaceTypeCd6 = function (code) {
    return checkExist(innerShortRaceTypeCd6, code);
};

//2005.競走種別コード(2000年まで)
var innerRaceTypeOldCd = {
      '00' : ''
    , '11' : '3歳'
    , '12' : '4歳'
    , '13' : '4歳以上'
    , '14' : '5歳以上'
    , '18' : '障害4歳以上'
    , '19' : '障害5歳以上'
    , '21' : '3歳'
    , '22' : '4歳'
    , '23' : '4歳以上'
    , '24' : '5歳以上'
}
exports.RaceTypeOldCd = function (code) {
    return checkExist(innerRaceTypeOldCd, code);
};

//2007.競走条件コード
var innerRaceRegulationCd ={
    '000' : '',
    '001' : '100万円以下',
    '002' : '200万円以下',
    '003' : '300万円以下',
    '004' : '400万円以下',
    '005' : '500万円以下',
    '006' : '600万円以下',
    '007' : '700万円以下',
    '008' : '800万円以下',
    '009' : '900万円以下',
    '010' : '1000万円以下',
    '011' : '1100万円以下',
    '012' : '1200万円以下',
    '013' : '1300万円以下',
    '014' : '1400万円以下',
    '015' : '1500万円以下',
    '016' : '1600万円以下',
    '017' : '1700万円以下',
    '018' : '1800万円以下',
    '019' : '1900万円以下',
    '020' : '2000万円以下',
    '021' : '2100万円以下',
    '022' : '2200万円以下',
    '023' : '2300万円以下',
    '024' : '2400万円以下',
    '025' : '2500万円以下',
    '026' : '2600万円以下',
    '027' : '2700万円以下',
    '028' : '2800万円以下',
    '029' : '2900万円以下',
    '030' : '3000万円以下',
    '031' : '3100万円以下',
    '032' : '3200万円以下',
    '033' : '3300万円以下',
    '034' : '3400万円以下',
    '035' : '3500万円以下',
    '036' : '3600万円以下',
    '037' : '3700万円以下',
    '038' : '3800万円以下',
    '039' : '3900万円以下',
    '040' : '4000万円以下',
    '041' : '4100万円以下',
    '042' : '4200万円以下',
    '043' : '4300万円以下',
    '044' : '4400万円以下',
    '045' : '4500万円以下',
    '046' : '4600万円以下',
    '047' : '4700万円以下',
    '048' : '4800万円以下',
    '049' : '4900万円以下',
    '050' : '5000万円以下',
    '051' : '5100万円以下',
    '052' : '5200万円以下',
    '053' : '5300万円以下',
    '054' : '5400万円以下',
    '055' : '5500万円以下',
    '056' : '5600万円以下',
    '057' : '5700万円以下',
    '058' : '5800万円以下',
    '059' : '5900万円以下',
    '060' : '6000万円以下',
    '061' : '6100万円以下',
    '062' : '6200万円以下',
    '063' : '6300万円以下',
    '064' : '6400万円以下',
    '065' : '6500万円以下',
    '066' : '6600万円以下',
    '067' : '6700万円以下',
    '068' : '6800万円以下',
    '069' : '6900万円以下',
    '070' : '7000万円以下',
    '071' : '7100万円以下',
    '072' : '7200万円以下',
    '073' : '7300万円以下',
    '074' : '7400万円以下',
    '075' : '7500万円以下',
    '076' : '7600万円以下',
    '077' : '7700万円以下',
    '078' : '7800万円以下',
    '079' : '7900万円以下',
    '080' : '8000万円以下',
    '081' : '8100万円以下',
    '082' : '8200万円以下',
    '083' : '8300万円以下',
    '084' : '8400万円以下',
    '085' : '8500万円以下',
    '086' : '8600万円以下',
    '087' : '8700万円以下',
    '088' : '8800万円以下',
    '089' : '8900万円以下',
    '090' : '9000万円以下',
    '091' : '9100万円以下',
    '092' : '9200万円以下',
    '093' : '9300万円以下',
    '094' : '9400万円以下',
    '095' : '9500万円以下',
    '096' : '9600万円以下',
    '097' : '9700万円以下',
    '098' : '9800万円以下',
    '099' : '9900万円以下',
    '100' : '1億円以下',
    '701' : '新馬',
    '702' : '未出走',
    '703' : '未勝利',
    '999' : 'オープン'
}
exports.RaceRegulationCd = function (code) {
    return checkExist(innerRaceRegulationCd,code);
};
exports.RaceRegulationArray = innerRaceRegulationCd;

//20190730 WIN_KEIBA_SUPPORT-256
//2007.競走条件コード(2019年夏季以降)
var innerRaceRegulationNewCd ={
    '000' : '',
    '001' : '100万円以下',
    '002' : '200万円以下',
    '003' : '300万円以下',
    '004' : '400万円以下',
    '005' : '1勝クラス(500万下)',
    '006' : '600万円以下',
    '007' : '700万円以下',
    '008' : '800万円以下',
    '009' : '900万円以下',
    '010' : '2勝クラス(1000万下)',
    '011' : '1100万円以下',
    '012' : '1200万円以下',
    '013' : '1300万円以下',
    '014' : '1400万円以下',
    '015' : '1500万円以下',
    '016' : '3勝クラス(1600万下)',
    '017' : '1700万円以下',
    '018' : '1800万円以下',
    '019' : '1900万円以下',
    '020' : '2000万円以下',
    '021' : '2100万円以下',
    '022' : '2200万円以下',
    '023' : '2300万円以下',
    '024' : '2400万円以下',
    '025' : '2500万円以下',
    '026' : '2600万円以下',
    '027' : '2700万円以下',
    '028' : '2800万円以下',
    '029' : '2900万円以下',
    '030' : '3000万円以下',
    '031' : '3100万円以下',
    '032' : '3200万円以下',
    '033' : '3300万円以下',
    '034' : '3400万円以下',
    '035' : '3500万円以下',
    '036' : '3600万円以下',
    '037' : '3700万円以下',
    '038' : '3800万円以下',
    '039' : '3900万円以下',
    '040' : '4000万円以下',
    '041' : '4100万円以下',
    '042' : '4200万円以下',
    '043' : '4300万円以下',
    '044' : '4400万円以下',
    '045' : '4500万円以下',
    '046' : '4600万円以下',
    '047' : '4700万円以下',
    '048' : '4800万円以下',
    '049' : '4900万円以下',
    '050' : '5000万円以下',
    '051' : '5100万円以下',
    '052' : '5200万円以下',
    '053' : '5300万円以下',
    '054' : '5400万円以下',
    '055' : '5500万円以下',
    '056' : '5600万円以下',
    '057' : '5700万円以下',
    '058' : '5800万円以下',
    '059' : '5900万円以下',
    '060' : '6000万円以下',
    '061' : '6100万円以下',
    '062' : '6200万円以下',
    '063' : '6300万円以下',
    '064' : '6400万円以下',
    '065' : '6500万円以下',
    '066' : '6600万円以下',
    '067' : '6700万円以下',
    '068' : '6800万円以下',
    '069' : '6900万円以下',
    '070' : '7000万円以下',
    '071' : '7100万円以下',
    '072' : '7200万円以下',
    '073' : '7300万円以下',
    '074' : '7400万円以下',
    '075' : '7500万円以下',
    '076' : '7600万円以下',
    '077' : '7700万円以下',
    '078' : '7800万円以下',
    '079' : '7900万円以下',
    '080' : '8000万円以下',
    '081' : '8100万円以下',
    '082' : '8200万円以下',
    '083' : '8300万円以下',
    '084' : '8400万円以下',
    '085' : '8500万円以下',
    '086' : '8600万円以下',
    '087' : '8700万円以下',
    '088' : '8800万円以下',
    '089' : '8900万円以下',
    '090' : '9000万円以下',
    '091' : '9100万円以下',
    '092' : '9200万円以下',
    '093' : '9300万円以下',
    '094' : '9400万円以下',
    '095' : '9500万円以下',
    '096' : '9600万円以下',
    '097' : '9700万円以下',
    '098' : '9800万円以下',
    '099' : '9900万円以下',
    '100' : '1億円以下',
    '701' : '新馬',
    '702' : '未出走',
    '703' : '未勝利',
    '999' : 'オープン'
}
exports.RaceRegulationNewCd = function (code) {
    return checkExist(innerRaceRegulationNewCd,code);
};
exports.RaceRegulationNewArray = innerRaceRegulationNewCd;

//競走条件コード(省略版)
var innerShortRaceRegulationCd ={
    '000' : '',
    '001' : '100万下',
    '002' : '200万下',
    '003' : '300万下',
    '004' : '400万下',
    '005' : '500万下',
    '006' : '600万下',
    '007' : '700万下',
    '008' : '800万下',
    '009' : '900万下',
    '010' : '1000万下',
    '011' : '1100万下',
    '012' : '1200万下',
    '013' : '1300万下',
    '014' : '1400万下',
    '015' : '1500万下',
    '016' : '1600万下',
    '017' : '1700万下',
    '018' : '1800万下',
    '019' : '1900万下',
    '020' : '2000万下',
    '021' : '2100万下',
    '022' : '2200万下',
    '023' : '2300万下',
    '024' : '2400万下',
    '025' : '2500万下',
    '026' : '2600万下',
    '027' : '2700万下',
    '028' : '2800万下',
    '029' : '2900万下',
    '030' : '3000万下',
    '031' : '3100万下',
    '032' : '3200万下',
    '033' : '3300万下',
    '034' : '3400万下',
    '035' : '3500万下',
    '036' : '3600万下',
    '037' : '3700万下',
    '038' : '3800万下',
    '039' : '3900万下',
    '040' : '4000万下',
    '041' : '4100万下',
    '042' : '4200万下',
    '043' : '4300万下',
    '044' : '4400万下',
    '045' : '4500万下',
    '046' : '4600万下',
    '047' : '4700万下',
    '048' : '4800万下',
    '049' : '4900万下',
    '050' : '5000万下',
    '051' : '5100万下',
    '052' : '5200万下',
    '053' : '5300万下',
    '054' : '5400万下',
    '055' : '5500万下',
    '056' : '5600万下',
    '057' : '5700万下',
    '058' : '5800万下',
    '059' : '5900万下',
    '060' : '6000万下',
    '061' : '6100万下',
    '062' : '6200万下',
    '063' : '6300万下',
    '064' : '6400万下',
    '065' : '6500万下',
    '066' : '6600万下',
    '067' : '6700万下',
    '068' : '6800万下',
    '069' : '6900万下',
    '070' : '7000万下',
    '071' : '7100万下',
    '072' : '7200万下',
    '073' : '7300万下',
    '074' : '7400万下',
    '075' : '7500万下',
    '076' : '7600万下',
    '077' : '7700万下',
    '078' : '7800万下',
    '079' : '7900万下',
    '080' : '8000万下',
    '081' : '8100万下',
    '082' : '8200万下',
    '083' : '8300万下',
    '084' : '8400万下',
    '085' : '8500万下',
    '086' : '8600万下',
    '087' : '8700万下',
    '088' : '8800万下',
    '089' : '8900万下',
    '090' : '9000万下',
    '091' : '9100万下',
    '092' : '9200万下',
    '093' : '9300万下',
    '094' : '9400万下',
    '095' : '9500万下',
    '096' : '9600万下',
    '097' : '9700万下',
    '098' : '9800万下',
    '099' : '9900万下',
    '100' : '1億円下',
    '701' : '新馬',
    '702' : '未出走',
    '703' : '未勝利',
    '999' : 'オープン'
}
exports.ShortRaceRegulationCd = function (code) {
    return checkExist(innerShortRaceRegulationCd,code);
};

//20190730 WIN_KEIBA_SUPPORT-256 add
//競走条件コード(省略版)
var innerShortRaceRegulationNewCd ={
    '000' : '',
    '001' : '100万下',
    '002' : '200万下',
    '003' : '300万下',
    '004' : '400万下',
    '005' : '1勝クラス(500万下)',
    '006' : '600万下',
    '007' : '700万下',
    '008' : '800万下',
    '009' : '900万下',
    '010' : '2勝クラス(1000万下)',
    '011' : '1100万下',
    '012' : '1200万下',
    '013' : '1300万下',
    '014' : '1400万下',
    '015' : '1500万下',
    '016' : '3勝クラス(1600万下)',
    '017' : '1700万下',
    '018' : '1800万下',
    '019' : '1900万下',
    '020' : '2000万下',
    '021' : '2100万下',
    '022' : '2200万下',
    '023' : '2300万下',
    '024' : '2400万下',
    '025' : '2500万下',
    '026' : '2600万下',
    '027' : '2700万下',
    '028' : '2800万下',
    '029' : '2900万下',
    '030' : '3000万下',
    '031' : '3100万下',
    '032' : '3200万下',
    '033' : '3300万下',
    '034' : '3400万下',
    '035' : '3500万下',
    '036' : '3600万下',
    '037' : '3700万下',
    '038' : '3800万下',
    '039' : '3900万下',
    '040' : '4000万下',
    '041' : '4100万下',
    '042' : '4200万下',
    '043' : '4300万下',
    '044' : '4400万下',
    '045' : '4500万下',
    '046' : '4600万下',
    '047' : '4700万下',
    '048' : '4800万下',
    '049' : '4900万下',
    '050' : '5000万下',
    '051' : '5100万下',
    '052' : '5200万下',
    '053' : '5300万下',
    '054' : '5400万下',
    '055' : '5500万下',
    '056' : '5600万下',
    '057' : '5700万下',
    '058' : '5800万下',
    '059' : '5900万下',
    '060' : '6000万下',
    '061' : '6100万下',
    '062' : '6200万下',
    '063' : '6300万下',
    '064' : '6400万下',
    '065' : '6500万下',
    '066' : '6600万下',
    '067' : '6700万下',
    '068' : '6800万下',
    '069' : '6900万下',
    '070' : '7000万下',
    '071' : '7100万下',
    '072' : '7200万下',
    '073' : '7300万下',
    '074' : '7400万下',
    '075' : '7500万下',
    '076' : '7600万下',
    '077' : '7700万下',
    '078' : '7800万下',
    '079' : '7900万下',
    '080' : '8000万下',
    '081' : '8100万下',
    '082' : '8200万下',
    '083' : '8300万下',
    '084' : '8400万下',
    '084' : '8400万下',
    '085' : '8500万下',
    '086' : '8600万下',
    '087' : '8700万下',
    '088' : '8800万下',
    '089' : '8900万下',
    '090' : '9000万下',
    '091' : '9100万下',
    '092' : '9200万下',
    '093' : '9300万下',
    '094' : '9400万下',
    '095' : '9500万下',
    '096' : '9600万下',
    '097' : '9700万下',
    '098' : '9800万下',
    '099' : '9900万下',
    '100' : '1億円下',
    '701' : '新馬',
    '702' : '未出走',
    '703' : '未勝利',
    '999' : 'オープン'
}
exports.ShortRaceRegulationNewCd = function (code) {
    return checkExist(innerShortRaceRegulationNewCd, code);
};

//競走条件コード(10文字)
var innerShortRaceRegulationCd10 ={
    '000' : '',
    '005' : '1勝クラス',
    '010' : '2勝クラス',
    '016' : '3勝クラス',
    '701' : '新馬',
    '702' : '未出走',
    '703' : '未勝利',
    '999' : 'オープン'
}
exports.ShortRaceRegulationCd10 = function (code) {
    return checkExist(innerShortRaceRegulationCd10, code);
};

//競走条件コード(6文字)
var innerShortRaceRegulationCd6 ={
    '000' : '',
    '005' : '1勝',
    '010' : '2勝',
    '016' : '3勝',
    '701' : '新馬',
    '702' : '未出走',
    '703' : '未勝利',
    '999' : 'オープン'
}
exports.ShortRaceRegulationCd6 = function (code) {
    return checkExist(innerShortRaceRegulationCd6, code);
};


//2009.トラックコード
var innerTrackCd = {
      '00' : '0'    //'海外・地方成績時の既定値'
    , '10' : '1'    //'平地　芝 　　 直線'
    , '11' : '1'    //'平地　芝 　　 左'
    , '12' : '1'    //'平地　芝 　　左 外'
    , '13' : '1'    //'平地　芝 　　左 内 -> 外'
    , '14' : '1'    //'平地　芝 　　左 外 -> 内'
    , '15' : '1'    //'平地　芝 　　左 内２周'
    , '16' : '1'    //'平地　芝 　　左 外２周'
    , '17' : '1'    //'平地　芝 　　右'
    , '18' : '1'    //'平地　芝 　　右 外回り'
    , '19' : '1'    //'平地　芝 　　右 内 -> 外'
    , '20' : '1'    //'平地　芝 　　右 外 -> 内'
    , '21' : '1'    //'平地　芝 　　右 内２周'
    , '22' : '1'    //'平地　芝 　　右 外２周'
    , '23' : '2'    //'平地　ダート 左'
    , '24' : '2'    //'平地　ダート 右'
    , '25' : '2'    //'平地　ダート　左　内回り'
    , '26' : '2'    //'平地　ダート　右　外回り'
    , '27' : '2'    //'平地　サンド　左'
    , '28' : '2'    //'平地　サンド　右'
    , '29' : '2'    //'平地　ダート 直線'
    , '51' : '3'    //'障害　芝 襷'
    , '52' : '3'    //'障害　芝 -> ダート'
    , '53' : '3'    //'障害　芝 左'
    , '54' : '3'    //'障害　芝'
    , '55' : '3'    //'障害　芝 外回り'
    , '56' : '3'    //'障害　芝 外 -> 内'
    , '57' : '3'    //'障害　芝 内 -> 外'
    , '58' : '3'    //'障害　芝 内２周'
    , '59' : '3'    //'障害　芝 外２周'
}
exports.TrackCd = function (code) {
    return checkExist(innerTrackCd, code);
};
exports.TrackArray = innerTrackCd;

var innerTrackCd2 = {
      '00' : '海外・地方成績時の既定値'
    , '10' : '平地　芝 　　 直線'
    , '11' : '平地　芝 　　 左'
    , '12' : '平地　芝 　　左 外'
    , '13' : '平地　芝 　　左 内 -> 外'
    , '14' : '平地　芝 　　左 外 -> 内'
    , '15' : '平地　芝 　　左 内２周'
    , '16' : '平地　芝 　　左 外２周'
    , '17' : '平地　芝 　　右'
    , '18' : '平地　芝 　　右 外回り'
    , '19' : '平地　芝 　　右 内 -> 外'
    , '20' : '平地　芝 　　右 外 -> 内'
    , '21' : '平地　芝 　　右 内２周'
    , '22' : '平地　芝 　　右 外２周'
    , '23' : '平地　ダート 左'
    , '24' : '平地　ダート 右'
    , '25' : '平地　ダート　左　内回り'
    , '26' : '平地　ダート　右　外回り'
    , '27' : '平地　サンド　左'
    , '28' : '平地　サンド　右'
    , '29' : '平地　ダート 直線'
    , '51' : '障害　芝 襷'
    , '52' : '障害　芝 -> ダート'
    , '53' : '障害　芝 左'
    , '54' : '障害　芝'
    , '55' : '障害　芝 外回り'
    , '56' : '障害　芝 外 -> 内'
    , '57' : '障害　芝 内 -> 外'
    , '58' : '障害　芝 内２周'
    , '59' : '障害　芝 外２周'
}
exports.TrackCd2 = function (code) {
    return checkExist(innerTrackCd2, code);
};
exports.TrackArray2 = innerTrackCd2;

// 変更情報・コース変更での表示用
var innerTrackCd3 = {
      '00' : ''
    , '10' : '芝直'
    , '11' : '芝'
    , '12' : '芝外'
    , '13' : '芝'
    , '14' : '芝'
    , '15' : '芝'
    , '16' : '芝'
    , '17' : '芝'
    , '18' : '芝外'
    , '19' : '芝'
    , '20' : '芝'
    , '21' : '芝'
    , '22' : '芝'
    , '23' : 'ダ'
    , '24' : 'ダ'
    , '25' : 'ダ'
    , '26' : 'ダ外'
    , '27' : 'サ'
    , '28' : 'サ'
    , '29' : 'ダ直'
    , '51' : '障芝'
    , '52' : '障芝'
    , '53' : '障芝'
    , '54' : '障芝'
    , '55' : '障芝外'
    , '56' : '障芝'
    , '57' : '障芝'
    , '58' : '障芝'
    , '59' : '障芝'
}
exports.TrackCd3 = function (code) {
    return checkExist(innerTrackCd3, code);
};
exports.TrackArray3 = innerTrackCd3;

// トラック回り区分
// （1:左回り、2:右回り、3:直線）
var innerTrackRotationDiv = {
      '00' : ''
    , '10' : '3'    //'平地　芝 　　 直線'
    , '11' : '1'    //'平地　芝 　　 左'
    , '12' : '1'    //'平地　芝 　　左 外'
    , '13' : '1'    //'平地　芝 　　左 内 -> 外'
    , '14' : '1'    //'平地　芝 　　左 外 -> 内'
    , '15' : '1'    //'平地　芝 　　左 内２周'
    , '16' : '1'    //'平地　芝 　　左 外２周'
    , '17' : '2'    //'平地　芝 　　右'
    , '18' : '2'    //'平地　芝 　　右 外回り'
    , '19' : '2'    //'平地　芝 　　右 内 -> 外'
    , '20' : '2'    //'平地　芝 　　右 外 -> 内'
    , '21' : '2'    //'平地　芝 　　右 内２周'
    , '22' : '2'    //'平地　芝 　　右 外２周'
    , '23' : '1'    //'平地　ダート 左'
    , '24' : '2'    //'平地　ダート 右'
    , '25' : '1'    //'平地　ダート　左　内回り'
    , '26' : '2'    //'平地　ダート　右　外回り'
    , '27' : '1'    //'平地　サンド　左'
    , '28' : '2'    //'平地　サンド　右'
    , '29' : '3'    //'平地　ダート 直線'
    , '51' : ''     //'障害　芝 襷'
    , '52' : ''     //'障害　芝 -> ダート'
    , '53' : ''     //'障害　芝 左'
    , '54' : ''     //'障害　芝'
    , '55' : ''     //'障害　芝 外回り'
    , '56' : ''     //'障害　芝 外 -> 内'
    , '57' : ''     //'障害　芝 内 -> 外'
    , '58' : ''     //'障害　芝 内２周'
    , '59' : ''     //'障害　芝 外２周'
}
exports.TrackRotationDiv = function (code) {
    return checkExist(innerTrackRotationDiv, code);
};

var innerTrackName = {
      '0' : ''
    , '1' : '芝'
    , '2' : 'ダート'
    , '3' : '障害'
}
exports.TrackName = function (code) {
    return checkExist(innerTrackName, code);
};

exports.TrackShortNameArray = innerTrackShortName;
var innerTrackShortName = {
      '0' : ''
    , '1' : '芝'
    , '2' : 'ダ'
    , '3' : '障'
}
exports.TrackShortName = function (code) {
    return checkExist(innerTrackShortName, code);
};
exports.TrackShortNameArray = innerTrackShortName;

//2010.重量種別コード
var innerWeightTypeCd = {
    '0' : '',
    '1' : 'ハンデ',
    '2' : '別定',
    '3' : '馬齢',
    '4' : '定量'
}
exports.WeightTypeCd = function (code) {
    return checkExist(innerWeightTypeCd,code);
};
exports.WeightTypeArray = innerWeightTypeCd;

//2010.馬場状態コード
var innerTrackConditionCd = {
    '0' : '',
    '1' : '良',
    '2' : '稍重',
    '3' : '重',
    '4' : '不良'
}
exports.TrackConditionCd = function (code) {
    return checkExist(innerTrackConditionCd,code);
};
exports.TrackConditionArray = innerTrackConditionCd;

//1文字馬場状態コード
var innerShortTrackConditionCd = {
    '0' : '',
    '1' : '良',
    '2' : '稍',
    '3' : '重',
    '4' : '不'
}
exports.ShortTrackConditionCd = function (code) {
    return checkExist(innerShortTrackConditionCd,code);
};
exports.ShortTrackConditionArray = innerShortTrackConditionCd;

//2011.天候コード
var innerWeatherCd = {
    '0' : '',
    '1' : '晴',
    '2' : '曇',
    '3' : '雨',
    '4' : '小雨',
    '5' : '雪',
    '6' : '小雪',
    'FC' : '晴/曇',
    'FR' : '晴/雨',
    'FS' : '晴/雪',
    'CF' : '曇/晴',
    'CR' : '曇/雨',
    'CS' : '曇/雪',
    'RF' : '雨/晴',
    'RS' : '雨/雪',
    'RC' : '雨/曇',
    'SF' : '雪/晴',
    'SR' : '雪/雨',
    'SC' : '雪/曇'
}
exports.WeatherCd = function (code) {
    return checkExist(innerWeatherCd,code);
};
exports.WeatherArray = innerWeatherCd;

//2102.着差コード
//※本来３バイト表示だが、データの都合上スペースは除く
var innerHorseLengthsCd = {
    ''    : '',
    '12'  : '1/2',
    '34'  : '3/4',
    '1'   : '１',
    '112' : '１ 1/2',
    '114' : '１ 1/4',
    '134' : '１ 3/4',
    '2'   : '２',
    '212' : '２ 1/2',
    '3'   : '３',
    '312' : '３ 1/2',
    '4'   : '４',
    '5'   : '５',
    '6'   : '６',
    '7'   : '７',
    '8'   : '８',
    '9'   : '９',
    'A'   : 'ｱﾀﾏ',
    'D'   : '同着',
    'H'   : 'ハナ',
    'K'   : 'クビ',
    'T'   : '大差',
    'Z'   : '１０'
}
exports.HorseLengthsCd = function (code) {
    return checkExist(innerHorseLengthsCd,code);
};

//2202.性別コード
var innerSexCd = {
    '0' : '',
    '1' : '牡',
    '2' : '牝',
    '3' : 'セ'
}
exports.SexCd = function (code) {
    return checkExist(innerSexCd,code);
};
exports.SexArray = innerSexCd;

//2203.毛色コード
var innerCoatColor = {
    '00' : '',
    '01' : '栗毛',
    '02' : '栃栗毛',
    '03' : '鹿毛',
    '04' : '黒鹿毛',
    '05' : '青鹿毛',
    '06' : '青毛',
    '07' : '芦毛',
    '08' : '栗粕毛',
    '09' : '鹿粕毛',
    '10' : '青粕毛',
    '11' : '白毛'
}
exports.CoatColor = function (code) {
    return checkExist(innerCoatColor,code);
};
exports.CoatColorArray = innerCoatColor;

//2204.馬記号コード
var innerHorseSymbolCd = {
    '00' : '',
    '01' : '(抽)',
    '02' : '[抽]',
    '03' : '(父)',
    '04' : '(市)',
    '05' : '(地)',
    '06' : '(外)',
    '07' : '(父)(抽)',
    '08' : '(父)(市)',
    '09' : '(父)(地)',
    '10' : '(市)(地)',
    '11' : '(外)(地)',
    '12' : '(父)(市)(地)',
    '15' : '(招)' ,
    '16' : '(招)(外)',
    '17' : '(招)(父)',
    '18' : '(招)(市)',
    '19' : '(招)(父)(市)',
    '20' : '(父)(外)',
    '21' : '[地]',
    '22' : '(外)[地]',
    '23' : '(父)[地]',
    '24' : '(市)[地]',
    '25' : '(父)(市)[地]',
    '26' : '[外]',
    '27' : '(父)[外]',
    '40' : '(父)(外)(地)',
    '41' : '(父)(外)[地]'
}
exports.HorseSymbolCd = function (code) {
    return checkExist(innerHorseSymbolCd,code);
};
exports.HorseSymbolArray = innerHorseSymbolCd;

//2301.東西区分コード
var innerEastWestDiv ={
    '0' : '',
    '1' : '関東',
    '2' : '関西',
    '3' : '招待',
    '4' : '無所属',
    '5' : '北海道',
    '6' : '岩手',
    '7' : '上山',
    '8' : '新潟',
    '9' : '金沢',
    '10' : '愛知',
    '11' : '笠松',
    '12' : '兵庫',
    '13' : '福山',
    '14' : '益田',
    '15' : '高知',
    '16' : '中津',
    '17' : '佐賀',
    '18' : '荒尾',
    '19' : 'ばんえい',
    '20' : '宇都宮',
    '21' : '栃木',
    '22' : '高崎',
    '23' : '大井',
    '24' : '川崎',
    '25' : '浦和',
    '26' : '船橋',
    '27' : '外国'
}
exports.EastWestDiv = function (code) {
    return checkExist(innerEastWestDiv,code);
};
exports.EastWestDivArray = innerEastWestDiv;

//2301.東西区分コード
var innerEastWestDivShort ={
    '0':'',
    '1':'東',
    '2':'西',
    '3':'',
    '4':'無',
    '5':'北',
    '6':'岩',
    '7':'上',
    '8':'新',
    '9':'金',
    '10':'愛',
    '11':'笠',
    '12':'兵',
    '13':'福',
    '14':'益',
    '15':'高',
    '16':'中',
    '17':'佐',
    '18':'荒',
    '19':'ば',
    '20':'宇',
    '21':'栃',
    '22':'高',
    '23':'大',
    '24':'川',
    '25':'浦',
    '26':'船',
    '27':'外'
}
exports.EastWestDivShort = function (code) {
    return checkExist(innerEastWestDivShort,code);
};

//2303.騎手見習い記号コード
var innerJockeyTrainingCd = {
    '0' : '',
    '1' : '☆',
    '2' : '△',
    '3' : '▲',
    '4' : '★',
    '9' : '◇'
}
exports.JockeyTrainingCd = function (code) {
    return checkExist(innerJockeyTrainingCd,code);
};
exports.JockeyTrainingArray = innerJockeyTrainingCd;

//2401.所属コード
var innerBelongCd = {
    '00' : '',
    '01' : '北見',
    '02' : '岩見沢',
    '03' : '帯広',
    '04' : '旭川',
    '05' : '函館',
    '06' : '盛岡',
    '07' : '水沢',
    '08' : '上山',
    '09' : '三条',
    '10' : '足利',
    '11' : '宇都宮',
    '12' : '高崎',
    '13' : '浦和',
    '14' : '船橋',
    '15' : '大井',
    '16' : '川崎',
    '17' : '金沢',
    '18' : '笠松',
    '19' : '名古屋',
    '20' : '紀三井寺',
    '21' : '園田',
    '22' : '姫路',
    '23' : '益田',
    '24' : '福山',
    '25' : '高知',
    '26' : '佐賀',
    '27' : '荒尾',
    '28' : '中津',
    '29' : '札幌',
    '30' : '新潟',
    '31' : '中京',
    '41' : '北海道',
    '42' : '東北',
    '43' : '北関東',
    '44' : '南関東',
    '45' : '東海',
    '46' : '九州',
    '52' : '岩手',
    '53' : '山形',
    '54' : '新潟',
    '55' : '栃木',
    '56' : '群馬',
    '57' : '埼玉',
    '58' : '千葉',
    '59' : '東京',
    '60' : '神奈川',
    '61' : '石川',
    '62' : '岐阜',
    '63' : '愛知',
    '64' : '兵庫',
    '65' : '島根',
    '66' : '広島',
    '67' : '高知',
    '68' : '熊本',
    '69' : '大分',
    '81' : '境町',
    '82' : '野田',
    '83' : '小向',
    '84' : '弥富',
    '85' : '小林',
    '86' : '西脇',
    '87' : '門別'
}
exports.BelongCd = function (code) {
    return checkExist(innerBelongCd,code);
};
exports.BelongArray = innerBelongCd;

//外人区分コード
var innerNationalityCd = {
    '0' : '日本人',
    '1' : '国内在住外国人',
    '2' : '国外在住外国人'
}
exports.NationalityCd = function (code) {
    return checkExist(innerNationalityCd,code);
};
exports.NationalityArray = innerNationalityCd;

//競走馬登録状況
var innerRetireDiv = {
    '0' : '現役',
    '1' : '引退'
}
exports.RetireDiv = function (code) {
    return checkExist(innerRetireDiv,code);
};
exports.RetireDivArray = innerRetireDiv;

//20190730 WIN_KEIBA_SUPPORT-256
//クラス名を追加
//競走馬クラス区分
var innerRaceRank = {
    '0' : '',
    '1' : '新馬',
    '2' : '未勝利',
    '3' : '1勝クラス・500万下',
    '4' : '2勝クラス・1000万下',
    '5' : '3勝クラス・1600万下',
    '6' : 'オープン',
    '7' : '未出走'
}
exports.RaceRank = function (code) {
    return checkExist(innerRaceRank,code);
};
exports.RaceRankArray = innerRaceRank;

//20190730 WIN_KEIBA_SUPPORT-256
//クラス名を追加
//短縮版競走馬クラス区分
var innerShortRaceRank = {
    '0' : '',
    '1' : '新馬',
    '2' : '未勝利',
    '3' : '1勝クラス・500万下',
    '4' : '2勝クラス・1000万下',
    '5' : '3勝クラス・1600万下',
    '6' : 'オープン',
    '7' : '未出走'
}
exports.ShortRaceRank = function (code) {
    return checkExist(innerShortRaceRank,code);
};
exports.ShortRaceRankArray = innerShortRaceRank;

const searchShortRaceRank = {
    '0' : '',
    '1' : '新馬',
    '2' : '未勝利',
    '3' : '1勝クラス',
    '4' : '2勝クラス',
    '5' : '3勝クラス',
    '6' : 'オープン',
    '7' : '未出走'
}
exports.SearchRaceRank = function (code) {
    return checkExist(searchShortRaceRank,code);
};
exports.ShortRaceRankArray = innerShortRaceRank;

//集約距離区分
var innerDistanceDiv = {
    '0' : '',
    '1' : '～1200m',
    '2' : '～1600m',
    '3' : '～2000m',
    '4' : '～2400m',
    '5' : '～3000m',
    '6' : '3001m～'
}
exports.DistanceDiv = function (code) {
    return checkExist(innerDistanceDiv,code);
};
exports.DistanceArray = innerDistanceDiv;

//場コード変換表：天気予報XML変換用
var innerYohouRacetrackCd = {
    '1400': '01'        //札幌競馬場
    , '2300': '02'      //函館競馬場
    , '3610': '03'      //福島競馬場
    , '5410': '04'      //新潟競馬場
    , '4410': '05'      //東京競馬場
    , '4510': '06'      //中山競馬場
    , '5110': '07'      //中京競馬場
    , '6100': '08'      //京都競馬場
    , '6310': '09'      //阪神競馬場
    , '8220': '10'      //小倉競馬場
};
exports.yohouRacetrackCd = function (code) {
    return checkExist(innerYohouRacetrackCd,code);
};

//天候コード変換表：天気予報XML変換用
var innerYohouWeatherCd = {
    '100': '1'          //晴
    , '130': '1'        //晴
    , '131': '1'        //晴
    , '200': '2'        //曇
    , '209': '2'        //曇
    , '231': '2'        //曇
    , '300': '3'        //雨
    , '304': '3'        //雨
    , '306': '3'        //雨
    , '308': '3'        //雨
    , '328': '3'        //雨
    , '329': '3'        //雨
    , '350': '3'        //雨
    , '400': '5'        //雪
    , '405': '5'        //雪
    , '340': '5'        //雪
    , '425': '5'        //雪
    , '426': '5'        //雪
    , '427': '5'        //雪
    , '406': '5'        //雪
    , '407': '5'        //雪
    , '450': '5'        //雪
    , '101': 'FC'       //晴/曇
    , '110': 'FC'       //晴/曇
    , '111': 'FC'       //晴/曇
    , '132': 'FC'       //晴/曇
    , '102': 'FR'       //晴/雨
    , '103': 'FR'       //晴/雨
    , '106': 'FR'       //晴/雨
    , '107': 'FR'       //晴/雨
    , '120': 'FR'       //晴/雨
    , '121': 'FR'       //晴/雨
    , '122': 'FR'       //晴/雨
    , '104': 'FR'       //晴/雨
    , '105': 'FR'       //晴/雨
    , '124': 'FR'       //晴/雨
    , '160': 'FR'       //晴/雨
    , '170': 'FR'       //晴/雨
    , '108': 'FR'       //晴/雨
    , '123': 'FR'       //晴/雨
    , '140': 'FR'       //晴/雨
    , '110': 'FR'       //晴/雨
    , '111': 'FR'       //晴/雨
    , '112': 'FR'       //晴/雨
    , '113': 'FR'       //晴/雨
    , '114': 'FR'       //晴/雨
    , '118': 'FR'       //晴/雨
    , '126': 'FR'       //晴/雨
    , '127': 'FR'       //晴/雨
    , '128': 'FR'       //晴/雨
    , '129': 'FR'       //晴/雨
    , '115': 'FR'       //晴/雨
    , '116': 'FR'       //晴/雨
    , '117': 'FR'       //晴/雨
    , '181': 'FR'       //晴/雨
    , '119': 'FR'       //晴/雨
    , '125': 'FR'       //晴/雨
    , '104': 'FS'       //晴/雪
    , '105': 'FS'       //晴/雪
    , '124': 'FS'       //晴/雪
    , '160': 'FS'       //晴/雪
    , '170': 'FS'       //晴/雪
    , '115': 'FS'       //晴/雪
    , '116': 'FS'       //晴/雪
    , '117': 'FS'       //晴/雪
    , '181': 'FS'       //晴/雪
    , '201': 'CF'       //曇/晴
    , '223': 'CF'       //曇/晴
    , '210': 'CF'       //曇/晴
    , '211': 'CF'       //曇/晴
    , '202': 'CR'       //曇/雨
    , '203': 'CR'       //曇/雨
    , '206': 'CR'       //曇/雨
    , '207': 'CR'       //曇/雨
    , '220': 'CR'       //曇/雨
    , '221': 'CR'       //曇/雨
    , '222': 'CR'       //曇/雨
    , '208': 'CR'       //曇/雨
    , '240': 'CR'       //曇/雨
    , '212': 'CR'       //曇/雨
    , '213': 'CR'       //曇/雨
    , '214': 'CR'       //曇/雨
    , '218': 'CR'       //曇/雨
    , '224': 'CR'       //曇/雨
    , '225': 'CR'       //曇/雨
    , '226': 'CR'       //曇/雨
    , '227': 'CR'       //曇/雨
    , '219': 'CR'       //曇/雨
    , '204': 'CS'       //曇/雪
    , '205': 'CS'       //曇/雪
    , '260': 'CS'       //曇/雪
    , '270': 'CS'       //曇/雪
    , '250': 'CS'       //曇/雪
    , '215': 'CS'       //曇/雪
    , '216': 'CS'       //曇/雪
    , '217': 'CS'       //曇/雪
    , '228': 'CS'       //曇/雪
    , '229': 'CS'       //曇/雪
    , '230': 'CS'       //曇/雪
    , '281': 'CS'       //曇/雪
    , '301': 'RF'       //雨/晴
    , '302': 'RF'       //雨/晴
    , '311': 'RF'       //雨/晴
    , '316': 'RF'       //雨/晴
    , '320': 'RF'       //雨/晴
    , '323': 'RF'       //雨/晴
    , '324': 'RF'       //雨/晴
    , '325': 'RF'       //雨/晴
    , '303': 'RS'       //雨/雪
    , '309': 'RS'       //雨/雪
    , '322': 'RS'       //雨/雪
    , '314': 'RS'       //雨/雪
    , '315': 'RS'       //雨/雪
    , '326': 'RS'       //雨/雪
    , '327': 'RS'       //雨/雪
    , '313': 'RC'       //雨/曇
    , '317': 'RC'       //雨/曇
    , '321': 'RC'       //雨/曇
    , '401': 'SF'       //雪/晴
    , '402': 'SF'       //雪/晴
    , '411': 'SF'       //雪/晴
    , '420': 'SF'       //雪/晴
    , '361': 'SF'       //雪/晴
    , '403': 'SR'       //雪/雨
    , '409': 'SR'       //雪/雨
    , '414': 'SR'       //雪/雨
    , '422': 'SR'       //雪/雨
    , '423': 'SR'       //雪/雨
    , '424': 'SR'       //雪/雨
    , '413': 'SC'       //雪/曇
    , '421': 'SC'       //雪/曇
    , '371': 'SC'       //雪/曇
};
exports.yohouWeatherCd = function (code) {
    return checkExist(innerYohouWeatherCd,code);
};

//競走種別コード実績集計用
var innerRaceTypeStatCd = {
      '00' : ''
    , '11' : '2歳'
    , '12' : '3歳'
    , '13' : '3歳以上'
    , '14' : '4歳以上'
    , '18' : '3歳以上'
    , '19' : '4歳以上'
    , '21' : '2歳'
    , '22' : '3歳'
    , '23' : '3歳以上'
    , '24' : '4歳以上'
}
exports.RaceTypeStatCd = function (code) {
    return checkExist(innerRaceTypeStatCd, code);
};

//変更情報コード変換用
var innerChangeInfoCd = {
      'A301' : '出走取消'
    , 'A328' : '翌日出走取消'
    , 'A302' : '競走除外'
    , 'A304' : '騎手変更'
    , 'A329' : '翌日騎手変更'
    , 'A306' : '発走時刻変更'
    , 'A307' : '馬場状態変更'
    , 'A308' : '天候状態変更'
    , 'A310' : '負担重量超過'
    , 'A323' : 'レース中止'
    , 'A324' : 'コース変更'
    , 'A325' : 'グレード変更'
}
exports.ChangeInfoCd = function (code) {
    return checkExist(innerChangeInfoCd, code);
};

//脚質コード変換用
var innerRunningStyleCd = {
      '0' : ''
    , '1' : '逃'
    , '2' : '先'
    , '3' : '差'
    , '4' : '追'
}
exports.RunningStyleCd = function (code) {
    return checkExist(innerRunningStyleCd, code);
};

//競走除外事由コード
var innerCancelReasonCd = {
    '001' : '疾病'
    , '002' : '事故'
    , '003' : 'その他'
    , '_' : '該当なし'          //nullがセットされた場合
}
exports.CancelReasonCd = function (code) {
    return checkExist(innerCancelReasonCd,code);
};

//騎手変更事由コード
var innerJockeyChangeReasonCd = {
    '1' : '騎乗停止'
    , '2' : '落馬負傷'
    , '3' : '事故'
    , '4' : '負担重量超過'
    , '5' : '病気'
    , '6' : '調教中負傷'
    , '7' : '負傷'
    , '8' : '誤記'
    , '9' : '検査'
    , '_' : '該当なし'          //nullがセットされた場合
}
exports.JockeyChangeReasonCd = function (code) {
    return checkExist(innerJockeyChangeReasonCd,code);
};

//トラック回り
var innerTrackRotation = {
      '0' : ''
    , '1' : '左回'
    , '2' : '右回'
    , '3' : '直線'
}
exports.TrackRotation = function (code) {
    return checkExist(innerTrackRotation,code);
};

var innerRaceCancelReasonCd = {
    '1' : '強風のため中止'
    , '2' : '台風のため中止'
    , '3' : '雪のため中止'
    , '4' : 'その他'
    , '_' : '該当なし'          //nullがセットされた場合
}
exports.RaceCancelReasonCd = function (code) {
    return checkExist(innerRaceCancelReasonCd,code);
};

var innerCourseChangeReasonCd = {
    '1' : '強風'
    , '2' : '台風'
    , '3' : '雪'
    , '4' : 'その他'
    , '_' : '該当なし'          //nullがセットされた場合
}
exports.CourseChangeReasonCd = function (code) {
    return checkExist(innerCourseChangeReasonCd,code);
};

var innerGradeChangeReasonCd = {
    '1' : '使用馬場'
    , '2' : '競走距離'
    , '3' : '使用馬場・競走距離'
    , '4' : '施行場'
    , '5' : '施行場及び使用馬場'
    , '6' : '施行場及び競走距離'
    , '7' : '施行場及び使用距離・競走距離'
    , '_' : '該当なし'          //nullがセットされた場合
}
exports.GradeChangeReasonCd = function (code) {
    return checkExist(innerGradeChangeReasonCd,code);
};

//入退会
var innerDeleteFlg = {
      '0' : '入会'
    , '1' : '退会'
}
exports.DeleteFlg = function (code) {
    return checkExist(innerDeleteFlg,code);
};
exports.DeleteFlgArray = innerDeleteFlg;

//コース名称
var innerCourseId = {
      '1' : 'エコノミーコース'
    , '2' : 'エコノミー+プレミアム'
    , '3' : 'プレミアムコース'
    , '4' : 'auスマートパス'
    , '5' : 'Yahoo!プレミアム'
    , '6' : 'お試し'
}
exports.CourseId = function (code) {
    return checkExist(innerCourseId,code);
};
exports.CourseIdArray = innerCourseId;

//特別競走番号
var innerSpecialRaceNum = {
    '0001': '桜花賞'
    , '0002': '皐月賞'
    , '0003': '優駿牝馬'
    , '0004': '東京優駿'
    , '0005': '菊花賞'
    , '0006': '天皇賞（春）'
    , '0007': '天皇賞（秋）'
    , '0008': '有馬記念'
    , '0009': '宝塚記念'
    , '0010': '安田記念'
    , '0011': 'エリザベス女王杯'
    , '0012': '朝日杯フューチュリティステークス'
    , '0013': '農林水産省賞典　阪神ジュベナイルフィリーズ'
    , '0014': 'マイルチャンピオンシップ'
    , '0015': 'ジャパンカップ'
    , '0016': 'スプリンターズステークス'
    , '0017': 'ＮＨＫマイルカップ'
    , '0018': '秋華賞'
    , '0019': '高松宮記念'
    , '0020': 'フェブラリーステークス'
};
exports.SpecialRaceNum = function (code) {
    return checkExist(innerSpecialRaceNum,code);
};
exports.SpecialRaceNumArray = innerSpecialRaceNum;

//式別
var innerTOB = {
      '0' : ''
    , '1' : '単勝'
    , '2' : '複勝'
    , '3' : '枠連'
    , '4' : '馬連'
    , '5' : 'ワイド'
    , '6' : '馬単'
    , '7' : '３連複'
    , '8' : '３連単'
    , '9' : '馬単マルチ'
    , '10' : '３連単マルチ'
    , '11' : '枠単'
    , '12' : '枠単マルチ'
}
exports.TOB = function (code) {
    return checkExist(innerTOB,code);
};

//レコード区分
var innerRecordDiv = {
    '0' : ''  // 海外、地方成績の規定値
  , '1' : '基'  //基準タイム
  , '2' : 'R' //レコードタイム
  , '3' : '参'  // 参考タイム
};
exports.RecordDiv = function(code) {
    return checkExist(innerRecordDiv,code);
};

//オッズ更新情報
var innerOddsUpdInfo = {
    '1' : 'オッズ 最終'    //締切済
  , '2' : 'オッズ 確定'    //確定済
};
exports.OddsUpdInfo = function(code) {
    return checkExist(innerOddsUpdInfo,code);
};

const pushTypeList = {
    '1': '重賞枠順確定',
    '2': '重賞結果',
    '4': 'WIN5結果',
    '5': 'お気に入り登録馬枠順確定',
    '6': 'お気に入り登録馬出走結果',
    '7': 'POG登録馬枠順確定',
    '8': 'POG登録馬出走結果',
    '25': '新聞印集計完了',
    '26': '新馬枠順確定',
    '27': '新馬結果',
    '28': '次走狙い馬枠順確定',
    '29': '次走狙い馬出走結果',
    // 買い目は文言が決まり次第確定させる
    '30': '買い目のレース結果確定',
}

exports.convertPushTypeToTitle = (pushType) => {
    return pushTypeList[pushType]
}

const innerAccidentCd = {
    '1': "取消",
    '2': "除外",
    '3': "除外",
    '4': "中止",
    '5': "失格",
};

exports.AccidentCd = (code) => {
    return checkExist(innerAccidentCd,code);
}

const innerAccidentCdShort = {
    '1': "取",
    '2': "除",
    '3': "除",
    '4': "中",
    '5': "失",
};

exports.AccidentCdShort = (code) => {
    return checkExist(innerAccidentCdShort,code);
}
