//今週出走データ
import {BACKWORDPATH, DATAPATH, HOST, NEWSIMAGEPATH, NEWSPATH, PATH, RACEPATH} from './define'
import {accidentObject, RacetrackCd} from './code-master'
import {
  calcBracketNum,
  dayDiv,
  dayWeek, getGradeIconClass,
  getGradeIconClassNar,
  getJSON,
  monthDiv,
  sortBy,
  SUCCESS,
  yearDiv
} from './common';
import codeMaster from '../../../api/util/codeMaster';

// 関連ニュース取得
const getNews = async (news, host) => {
  const response = [];

  const newsArray = [];
  for (let i = 0; i < news.length; i++) {
    const useNewsData = news[i];
    const sort = useNewsData.DeliveryDate.replace(/[:\/ ]/g, "");

    //最新情報タブの関連ニュースの日付フォーマット変更
    useNewsData.DeliveryDate = String(useNewsData.DeliveryDate).substring(0, 4) +
      "." +
      String(useNewsData.DeliveryDate).substring(5, 7) +
      "." +
      String(useNewsData.DeliveryDate).substring(8, 10) +
      " " +
      String(useNewsData.DeliveryDate).substring(11, 16);

    const obj = {
      NewsCd: useNewsData.NewsCd,
      Headline: useNewsData.Headline,
      DeliveryDate: useNewsData.DeliveryDate,
      ImageFileURL: useNewsData.ImageFileURL ? HOST + NEWSIMAGEPATH + useNewsData.ImageFileURL : '',
      Sort: sort,
    };
    newsArray.push(obj);
  }

  newsArray.sort(sortBy("Sort", true, parseInt));

  //14日以内のものだけ表示
  let seqNo = "";
  for (let i = 0; i < newsArray.length; i++) {
    const useNews = newsArray[i];
    //データ作成日
    const dataCreate = useNews.NewsCd.substr(0, 8);
    let createDate = new Date(
      yearDiv(dataCreate),
      monthDiv(dataCreate) - 1,
      dayDiv(dataCreate),
      0,
      0,
      0
    );
    createDate = createDate.getTime();
    //14日後
    let after = 14;
    after = after * 24 * 60 * 60 * 1000;
    let deleteDate = new Date(createDate + after);
    deleteDate = deleteDate.getTime();
    //今日
    let today = new Date();
    today = today.getTime();
    if (createDate <= today && deleteDate >= today) {
      //ニュース表示
      let url = "";
      let imgUrl = "";
      if (newsArray[i].NewsCd) {
        let newsCd = newsArray[i].NewsCd;
        createDate = newsCd.substr(0, 8);
        seqNo = newsCd.substr(8, 4);
        url =
          host +
          NEWSPATH +
          "article?FileCreateDate=" +
          createDate +
          "&SeqNo=" +
          seqNo +
          "&Page=1";
        imgUrl = newsArray[i].ImageFileURL;
      } else {
        url = "#";
      }

      response.push({
        Headline: newsArray[i].Headline,
        DeliveryDate: newsArray[i].DeliveryDate,
        Url: url,
        ImageFileURL: imgUrl,
      });
    }
  }

  return response;
}

// 今週開催重賞実績
const getSpecialRaceResults = async (thisWeekResult) => {
  const resultArray = [];
  for (let j = 0; j < thisWeekResult.length; j++) {
    if (thisWeekResult[j]?.Detail?.length) {
      let details = [];
      for (let l = 0; l < thisWeekResult[j].Detail.length; l++) {
        let resultClass = "";
        let winPopularRank = "";
        let ranking = "";
        if (thisWeekResult[j].Detail[l].AccidentCd === 1) {
          winPopularRank = "-";
          ranking = "取消";
        } else if (
          thisWeekResult[j].Detail[l].AccidentCd === 2 ||
          thisWeekResult[j].Detail[l].AccidentCd === 3
        ) {
          winPopularRank = "-";
          ranking = "除外";
        } else if (thisWeekResult[j].Detail[l].AccidentCd === 4) {
          winPopularRank =
            thisWeekResult[j].Detail[l].WinPopularRank;
          ranking = "中止";
        } else {
          winPopularRank =
            thisWeekResult[j].Detail[l].WinPopularRank;
          ranking =
            parseInt(thisWeekResult[j].Detail[l].RaceResult, 10) +
            "着";
          if (parseInt(thisWeekResult[j].Detail[l].RaceResult, 10) === 1) {
            resultClass = "first";
          } else if (parseInt(thisWeekResult[j].Detail[l].RaceResult, 10) === 2) {
            resultClass = "second";
          } else if (parseInt(thisWeekResult[j].Detail[l].RaceResult, 10) === 3) {
            resultClass = "third";
          }
        }

        let oddsClass = "";
        if (thisWeekResult[j].Detail[l].Odds * 1 < 10) {
          oddsClass = "txt-odds -no1";
        } else if (thisWeekResult[j].Detail[l].Odds * 1 < 100) {
          oddsClass = "txt-odds -no2";
        }

        thisWeekResult[j].Detail[l].OddsClass = oddsClass;

        let resultUrl = "";
        if (thisWeekResult[j].Detail[l].DOR > "20030101") {
          //着順リンク
          resultUrl =
            HOST +
            "race/results?DOR=" +
            thisWeekResult[j].Detail[l].DOR +
            "&RacetrackCd=" +
            thisWeekResult[j].Detail[l].RacetrackCd +
            "&RaceNum=" +
            thisWeekResult[j].Detail[l].RaceNum;
        } else {
          //結果がないレースはリンク無効
          resultUrl = ranking;
        }
        const detail = thisWeekResult[j].Detail[l];
        let detailContent = {
          SpRaceNo: detail.SpRaceNo,
          YEAR: detail.YEAR,
          BracketNum: detail.BracketNum,
          HorseNum: detail.HorseNum,
          HorseStyle: calcBracketNum(18, detail.HorseNum),
          HorseName: detail.HorseName,
          PedigreeNum: detail.PedigreeNum,
          WinPopularRank: detail.WinPopularRank,
          RaceResult: detail.RaceResult,
          AccidentCd: detail.AccidentCd,
          DOR: detail.DOR,
          RaceMeeting: detail.RaceMeeting,
          RaceDay: detail.RaceDay,
          RaceNum: detail.RaceNum,
          RacetrackCd: detail.RacetrackCd,
          Odds: detail.Odds,
          OddsClass: detail.OddsClass,
          Rank: winPopularRank,
          Ranking: ranking,
          ResultClass: resultClass,
          ResultUrl: resultUrl,
        };
        details.push(detailContent);
      }
      let thisData = {
        RaceName: thisWeekResult[j].RaceName,
        SpecialRaceNum: thisWeekResult[j].SpecialRaceNum,
        GradeCd: thisWeekResult[j].GradeCd,
        TrackTypeCd: thisWeekResult[j].TrackTypeCd,
        Detail: details,
        RaceCounter: thisWeekResult[j].RaceCounter,
        WinCounter: thisWeekResult[j].WinCounter,
        Entry: thisWeekResult[j].Entry,
      };
      if (!resultArray.includes(thisWeekResult[j].SpecialRaceNum)) {
        resultArray.push(thisData);
      }
    }
  }
  return resultArray;
}

// 今週出走データ
const weeklyRacing = async (viewKbn, mainKey, host, narFlag) => {
  if (!viewKbn) viewKbn = "";
  if (!mainKey) mainKey = "";

  let response = {};

  let request =
    host +
    PATH +
    "Master/weeklyRacingUpdateAPI.js" +
    BACKWORDPATH +
    "?ViewKbn=" +
    viewKbn +
    "&MainKey=" +
    mainKey +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);
  await getJSON(request, true, async function (status, json) {
    if (status === SUCCESS && json?.length) {
      const scheduleArray = json[0].schedule;
      let allSaddle = 0;

      for (let i in scheduleArray) {
        const useData = json[0].schedule[i];
        let oddsWishLst = []; //オッズ取得用のリストの初期化

        //開催日整形
        let date = useData.dor;
        let year = yearDiv(date);
        let month = monthDiv(date);
        let day = dayDiv(date);

        //開催場所
        const kaisaijo = RacetrackCd;
        const ACCIDENT_LBL = accidentObject;

        let displayPlace = "";
        let dateTag = "";

        //スケジュールの詳細
        if (useData?.detail?.length) {
          //見出し表示
          displayPlace = kaisaijo[useData.trackCd];
          dateTag = dayWeek(year, month, day);
          if (dateTag === "土") {
            dateTag = '<span class="_sat">' + dateTag + "</span>";
          } else if (dateTag === "日") {
            dateTag = '<span class="_sun">' + dateTag + "</span>";
          }

          allSaddle += useData.scheduleCounter;

          useData.detail.sort(sortBy("raceNum", false, parseInt));

          for (let k in useData.detail) {
            let scheduleDetail = useData.detail[k];

            //グレードコード
            let icon = "";
            if (narFlag === 1) {
              icon = getGradeIconClassNar(scheduleDetail.gradeCd, scheduleDetail.raceName)
            } else {
              if (scheduleDetail.specialFlg) {
                icon = getGradeIconClass(
                  scheduleDetail.gradeCd,
                  scheduleDetail.trackTypeCd,
                  scheduleDetail.raceRegulationCd
                )
              }
            }
            if (icon) {
              icon = `<i class="icon-race ${icon}"></i>`;
            }

            //詳細表示
            let raceNum = scheduleDetail?.raceNum ? scheduleDetail.raceNum * 1 : "--";

            let startDate = "--";
            if (scheduleDetail?.startDate) {
              startDate =
                scheduleDetail.startDate.substr(0, 2) +
                ":" +
                scheduleDetail.startDate.substr(2, 2);
            }

            //レース名→出馬表に遷移
            let raceName = scheduleDetail.raceName || "――";

            let horseName = scheduleDetail?.horseName || "――";

            if (scheduleDetail?.pedigreeNum) {
              const toHorseData =
                HOST +
                DATAPATH +
                "horse?PedigreeNum=" +
                scheduleDetail.pedigreeNum;
              horseName =
                '<a href="' + toHorseData + '">' + horseName + "</a>";
            }

            //着順→レース結果に遷移
            let ranking = "--";
            if(
              "ranking" in scheduleDetail &&
              scheduleDetail.ranking != null
            ){
              if (/^(1|2|3|4|5)/.test(scheduleDetail?.accidentCd)) {
                ranking = ACCIDENT_LBL[scheduleDetail.accidentCd];
              } else if (scheduleDetail.ranking > 50) {
                switch (scheduleDetail.ranking) {
                  case 53:
                    ranking = "中止"
                    break;
                  case 55:
                    ranking = "除外"
                    break;
                  case 56:
                    ranking = "取消"
                    break;
                }
              } else {
                ranking = String(scheduleDetail.ranking) + "着";
              }
            }

            if (useData?.dor && useData?.trackCd && scheduleDetail?.raceNum) {
              let toCandidacy =
                HOST +
                RACEPATH +
                "card?DOR=" +
                useData.dor +
                "&RacetrackCd=" +
                useData.trackCd +
                "&RaceNum=" +
                scheduleDetail.raceNum;
              raceName =
                '<a href="' + toCandidacy + '">' + raceName + "</a>";

              if (ranking !== "--") {
                const toRaceResult =
                  HOST +
                  RACEPATH +
                  "results?DOR=" +
                  useData.dor +
                  "&RacetrackCd=" +
                  useData.trackCd +
                  "&RaceNum=" +
                  scheduleDetail.raceNum;
                ranking =
                  '<a href="' + toRaceResult + '">' + ranking + "</a>";
              }
            }

            //オッズ・人気表示用リストアイテムをセット
            oddsWishLst.push({
              DOR: useData.dor,
              RacetrackCd: useData.trackCd,
              RaceNum: raceNum,
              HorseNum: scheduleDetail.horseNum,
              PedigreeNum: scheduleDetail.pedigreeNum,
            });

            scheduleDetail.startDate = startDate;
            scheduleDetail.ranking = ranking;
            scheduleDetail.raceNum = raceNum;
            scheduleDetail.raceName = raceName + icon;
            scheduleDetail.horseName = horseName;
          }
        }

        scheduleArray[i].year = year;
        scheduleArray[i].month = month;
        scheduleArray[i].day = day;
        scheduleArray[i].displayPlace = displayPlace;
        scheduleArray[i].dateTag = dateTag;
        scheduleArray[i].scheduleCounter = useData.scheduleCounter;
        scheduleArray[i].oddsWishLst = oddsWishLst;
      }

      response = {
        totalRaces: allSaddle,
        schedule: scheduleArray
      }
    }
  });
  return response;
}

// 今週出走データ内のオッズデータ取得
const setOddsHtml = async (schedule, host, narFlag) => {
  if (!schedule?.length) return schedule;

  for (let useData of schedule) {
    let singleOdds = await getOddsAiO(useData.oddsWishLst, host, narFlag);

    for (let o in useData.detail) {
      let scheduleDetail = useData.detail[o];
      let raceNum = scheduleDetail.raceNum
      raceNum = raceNum > 9 ? String(raceNum) : '0' + String(raceNum)
      const key = String(useData.dor) + String(useData.trackCd) + raceNum

      const oddsHtml = singleOdds.find(val => {
        return val.key === key && val.pedigreeNum === scheduleDetail.pedigreeNum
      });
      scheduleDetail.oddsHtml = oddsHtml?.mHtml || '';
    }
  }
  return schedule;
}

// オッズ一括取得
const getOddsAiO = async (oddsWishLst, host, narFlag) => {
  if (!oddsWishLst?.length) {
    return;
  }

  let lstObj = {};
  for (let i = 0; i < oddsWishLst.length; i++) {
    let mKey =
      oddsWishLst[i].DOR +
      ("0" + oddsWishLst[i].RacetrackCd).slice(-2) +
      ("0" + oddsWishLst[i].RaceNum).slice(-2);
    if (mKey in lstObj) {
      lstObj[mKey].push(oddsWishLst[i]);
    } else {
      lstObj[mKey] = [oddsWishLst[i]];
    }
  }
  let strLst = "";
  for (let key in lstObj) {
    if (strLst === "") {
      strLst += key;
    } else {
      strLst += "_" + key;
    }
  }

  let oddsHtml = []; //オッズのreturn用配列
  const req =
    host +
    PATH +
    "Master/getOddsAiO.js" +
    BACKWORDPATH +
    "?lst=" +
    strLst +
    "&narFlag=" +
    narFlag;
  await getJSON(req, true, function (status, json) {
    if (status === SUCCESS) {
      if (json != null) {
        //検索結果がある場合のみ実行
        for (let key in lstObj) {
          if (json["o" + key]?.length) {
            let oddsLst = json["o" + key][0];

            for (let j = 0; j < lstObj[key].length; j++) {
              let horseNum = lstObj[key][j].HorseNum;
              let pedigreeNum = lstObj[key][j].PedigreeNum;
              let mOdds = "---.-";
              let mOrder = "--";
              let mClass = "";

              if (oddsLst["Odds" + horseNum * 1] && oddsLst["Odds" + horseNum * 1] !== '0.0') {
                mOdds = oddsLst["Odds" + horseNum * 1]
              }

              if (mOdds !== "---.-") {
                mOrder = 1;
                if (mOdds * 1 < 10) {
                  mClass = ' class="txt-odds -no1"';
                } else if (mOdds * 1 < 100) {
                  mClass = ' class="txt-odds -no2"';
                } else {
                  mClass = ' class="txt-odds -no3"';
                }
                for (let i = 0; i < 18; i++) {
                  if (i != horseNum * 1 - 1) {
                    if (oddsLst["Odds" + (i + 1)] === "---.-" || oddsLst["Odds" + (i + 1)] === '0.0') {
                      continue;
                    }
                    if (oddsLst["Odds" + (i + 1)] * 1 < mOdds * 1) {
                      mOrder += 1;
                    }
                  }
                }
              }

              const mHtml =
                "<span" +
                mClass +
                ">" +
                mOdds +
                "倍</span><br>" +
                mOrder +
                "人気";

              oddsHtml.push({
                mHtml: mHtml,
                key: key,
                pedigreeNum: pedigreeNum
              });
            }
          }
        }
      }
    }
  });
  return oddsHtml;
}

// GI実績データ
const g1RaceResult = async (viewKbn, mainKey, host) => {
  let response = [];

  if (!mainKey) mainKey = "";

  let request =
    host +
    PATH +
    "g1RaceResultAPI.js" +
    BACKWORDPATH +
    "?APICd=" +
    viewKbn +
    "&MainKey=" +
    mainKey;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      response = json
    }
  });

  return response;
}

// 重賞勝鞍データ取得
const dataBigPrizeWins = async (viewKbn, mainKey, host, narFlag) => {
  let response = [];

  if (!mainKey) mainKey = "";

  let request =
    host +
    PATH +
    "Master/patternRaceWinningStatAPI.js" +
    BACKWORDPATH +
    "?ViewKbn=" +
    viewKbn +
    "&MainKey=" +
    mainKey +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json[0]) {
      response = json[0].stat.map((result) => {
        const icon = narFlag === 1 ?
          getGradeIconClassNar(result.gradeCd, result.raceName) :
          getGradeIconClass(result.gradeCd, result.trackTypeCd, '');

        return {
          ...result,
          icon: icon,
        }
      });
    }
  });

  return response;
}

//血統取得
const getBlood = (pedigreeInfo, horseHairColor, host) => {
  //父
  let FatherName = "";
  let FatherURL = "";
  let FatherYOB = "";
  let FatherHairColor = "";
  if (pedigreeInfo?.FatherName) {
    if (pedigreeInfo?.BreedingNum_Father) {
      FatherName = pedigreeInfo.FatherName;
      FatherURL =
        host +
        DATAPATH +
        "stud?BreedingNum=" +
        pedigreeInfo.BreedingNum_Father;
      FatherYOB = pedigreeInfo.YOB_Father;
      FatherHairColor = horseHairColor.HairColorFather;
    } else {
      FatherName = pedigreeInfo.FatherName;
    }
  }
  //父の父
  let HorseName_4 = "";
  let HorseURL_4 = "";
  let HorseYOB_4 = "";
  if (pedigreeInfo?.HorseName_4) {
    if (pedigreeInfo?.BreedingNum_4) {
      HorseName_4 = pedigreeInfo.HorseName_4;
      HorseURL_4 =
        host +
        DATAPATH +
        "stud?BreedingNum=" +
        pedigreeInfo.BreedingNum_4;
      HorseYOB_4 = pedigreeInfo.YOB_4;
    } else {
      HorseName_4 = pedigreeInfo.HorseName_4;
    }
  }
  //父の父の父
  let HorseName_8 = "";
  let HorseURL_8 = "";
  if (pedigreeInfo?.HorseName_8) {
    if (pedigreeInfo?.BreedingNum_8) {
      HorseName_8 = pedigreeInfo.HorseName_8;
      HorseURL_8 =
        host +
        DATAPATH +
        "stud?BreedingNum=" +
        pedigreeInfo.BreedingNum_8;
    } else {
      HorseName_8 = pedigreeInfo.HorseName_8;
    }
  }
  //父の父の母
  let HorseName_9 = "";
  let HorseURL_9 = "";
  if (pedigreeInfo?.HorseName_9) {
    if (pedigreeInfo?.BreedingNum_9) {
      HorseName_9 = pedigreeInfo.HorseName_9;
      HorseURL_9 =
        host +
        DATAPATH +
        "mare?BreedingNum=" +
        pedigreeInfo.BreedingNum_9;
    } else {
      HorseName_9 = pedigreeInfo.HorseName_9;
    }
  }
  //父の母
  let HorseName_5 = "";
  let HorseURL_5 = "";
  let HorseYOB_5 = "";
  if (pedigreeInfo?.HorseName_5) {
    if (pedigreeInfo?.BreedingNum_5) {
      HorseName_5 = pedigreeInfo.HorseName_5;
      HorseURL_5 =
        host +
        DATAPATH +
        "mare?BreedingNum=" +
        pedigreeInfo.BreedingNum_5;
      HorseYOB_5 = pedigreeInfo.YOB_5;
    } else {
      HorseName_5 = pedigreeInfo.HorseName_5;
    }
  }
  //父の母の父
  let HorseName_10 = "";
  let HorseURL_10 = "";
  if (pedigreeInfo?.HorseName_10) {
    if (pedigreeInfo?.BreedingNum_10) {
      HorseName_10 = pedigreeInfo.HorseName_10;
      HorseURL_10 =
        host +
        DATAPATH +
        "stud?BreedingNum=" +
        pedigreeInfo.BreedingNum_10;
    } else {
      HorseName_10 = pedigreeInfo.HorseName_10;
    }
  }
  //父の母の母
  let HorseName_11 = "";
  let HorseURL_11 = "";
  if (pedigreeInfo?.HorseName_11) {
    if (pedigreeInfo?.BreedingNum_11) {
      HorseName_11 = pedigreeInfo.HorseName_11;
      HorseURL_11 =
        host +
        DATAPATH +
        "mare?BreedingNum=" +
        pedigreeInfo.BreedingNum_11;
    } else {
      HorseName_11 = pedigreeInfo.HorseName_11;
    }
  }
  //母
  let MotherName = "";
  let MotherURL = "";
  let MotherYOB = "";
  let MotherHairColor = "";
  if (pedigreeInfo?.MotherName) {
    if (pedigreeInfo?.BreedingNum_Mother) {
      MotherName = pedigreeInfo.MotherName;
      MotherURL =
        host +
        DATAPATH +
        "mare?BreedingNum=" +
        pedigreeInfo.BreedingNum_Mother;
      MotherYOB = pedigreeInfo.YOB_Mother;
      MotherHairColor = horseHairColor.HairColorMother;
    } else {
      MotherName = pedigreeInfo.MotherName;
    }
  }
  //母の父
  let HorseName_6 = "";
  let HorseURL_6 = "";
  let HorseYOB_6 = "";
  if (pedigreeInfo?.HorseName_6) {
    if (pedigreeInfo?.BreedingNum_6) {
      HorseName_6 = pedigreeInfo.HorseName_6;
      HorseURL_6 =
        host +
        DATAPATH +
        "stud?BreedingNum=" +
        pedigreeInfo.BreedingNum_6;
      HorseYOB_6 = pedigreeInfo.YOB_6;
    } else {
      HorseName_6 = pedigreeInfo.HorseName_6;
    }
  }
  //母の父の父
  let HorseName_12 = "";
  let HorseURL_12 = "";
  if (pedigreeInfo?.HorseName_12) {
    if (pedigreeInfo?.BreedingNum_12) {
      HorseName_12 = pedigreeInfo.HorseName_12;
      HorseURL_12 =
        host +
        DATAPATH +
        "stud?BreedingNum=" +
        pedigreeInfo.BreedingNum_12;
    } else {
      HorseName_12 = pedigreeInfo.HorseName_12;
    }
  }
  //母の父の母
  let HorseName_13 = "";
  let HorseURL_13 = "";
  if (pedigreeInfo?.HorseName_13) {
    if (pedigreeInfo?.BreedingNum_13) {
      HorseName_13 = pedigreeInfo.HorseName_13;
      HorseURL_13 =
        host +
        DATAPATH +
        "mare?BreedingNum=" +
        pedigreeInfo.BreedingNum_13;
    } else {
      HorseName_13 = pedigreeInfo.HorseName_13;
    }
  }
  //母の母
  let HorseName_7 = "";
  let HorseURL_7 = "";
  let HorseYOB_7 = "";
  if (pedigreeInfo?.HorseName_7) {
    if (pedigreeInfo?.BreedingNum_7) {
      HorseName_7 = pedigreeInfo.HorseName_7;
      HorseURL_7 =
        host +
        DATAPATH +
        "mare?BreedingNum=" +
        pedigreeInfo.BreedingNum_7;
      HorseYOB_7 = pedigreeInfo.YOB_7;
    } else {
      HorseName_7 = pedigreeInfo.HorseName_7;
    }
  }
  //母の母の父
  let HorseName_14 = "";
  let HorseURL_14 = "";
  if (pedigreeInfo?.HorseName_14) {
    if (pedigreeInfo?.BreedingNum_14) {
      HorseName_14 = pedigreeInfo.HorseName_14;
      HorseURL_14 =
        host +
        DATAPATH +
        "stud?BreedingNum=" +
        pedigreeInfo.BreedingNum_14;
    } else {
      HorseName_14 = pedigreeInfo.HorseName_14;
    }
  }
  //母の母の母
  let HorseName_15 = "";
  let HorseURL_15 = "";
  if (pedigreeInfo?.HorseName_15) {
    if (pedigreeInfo?.BreedingNum_15) {
      HorseName_15 = pedigreeInfo.HorseName_15;
      HorseURL_15 =
        host +
        DATAPATH +
        "mare?BreedingNum=" +
        pedigreeInfo.BreedingNum_15;
    } else {
      HorseName_15 = pedigreeInfo.HorseName_15;
    }
  }

  return {
    Father: {
      HorseName: FatherName,
      HorseURL: FatherURL,
      HorseYOB: FatherYOB,
      HorseHair: FatherHairColor,
    },
    Horse_4: {
      HorseName: HorseName_4,
      HorseURL: HorseURL_4,
      HorseYOB: HorseYOB_4,
    },
    Horse_8: {
      HorseName: HorseName_8,
      HorseURL: HorseURL_8,
    },
    Horse_9: {
      HorseName: HorseName_9,
      HorseURL: HorseURL_9,
    },
    Horse_5: {
      HorseName: HorseName_5,
      HorseURL: HorseURL_5,
      HorseYOB: HorseYOB_5,
    },
    Horse_10: {
      HorseName: HorseName_10,
      HorseURL: HorseURL_10,
    },
    Horse_11: {
      HorseName: HorseName_11,
      HorseURL: HorseURL_11,
    },
    Mother: {
      HorseName: MotherName,
      HorseURL: MotherURL,
      HorseYOB: MotherYOB,
      HorseHair: MotherHairColor,
    },
    Horse_6: {
      HorseName: HorseName_6,
      HorseURL: HorseURL_6,
      HorseYOB: HorseYOB_6,
    },
    Horse_12: {
      HorseName: HorseName_12,
      HorseURL: HorseURL_12,
    },
    Horse_13: {
      HorseName: HorseName_13,
      HorseURL: HorseURL_13,
    },
    Horse_7: {
      HorseName: HorseName_7,
      HorseURL: HorseURL_7,
      HorseYOB: HorseYOB_7,
    },
    Horse_14: {
      HorseName: HorseName_14,
      HorseURL: HorseURL_14,
    },
    Horse_15: {
      HorseName: HorseName_15,
      HorseURL: HorseURL_15,
    },
  };
}

export {
  getNews,
  getSpecialRaceResults,
  weeklyRacing,
  setOddsHtml,
  getOddsAiO,
  g1RaceResult,
  dataBigPrizeWins,
  getBlood,
};