<script setup>
import ManagementDetailHorse from "./ManagementDetailHorse.vue";
import {ref} from 'vue'

const managementHorseTab = ref("management");
const isCurrentTab = ref("management");

const props = defineProps({
  managementHorses: Array,
  pastManagementHorses: Array,
  isDisplayFav: Boolean,
});

const tabClick = (data) => {
  managementHorseTab.value = data;
  isCurrentTab.value = data;
}
</script>

<template>
  <div class="container-tabtarget" data-target>
    <div class="container-toggle -marginbottom -white">
      <ul data-btn>
        <li @click="tabClick('management')" :class="{ '-current': isCurrentTab === 'management' }">
          <span class="label">管理馬一覧</span>
        </li>
        <li @click="tabClick('pastG1Results')" :class="{ '-current': isCurrentTab === 'pastG1Results' }">
          <span class="label">過去管理G1馬</span>
        </li>
      </ul>
    </div>
    <ManagementDetailHorse v-if="managementHorseTab === 'management'"
                           :managementHorses="managementHorses"
                           :title="'管理馬一覧'"
                           :isDisplayFav="isDisplayFav"
    />
    <ManagementDetailHorse v-if="managementHorseTab === 'pastG1Results'"
                           :managementHorses="pastManagementHorses"
                           :title="'過去管理G1馬'"
                           :isDisplayFav="false"
    />
  </div>
</template>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>