<script setup>
import {ref, reactive, onMounted, onServerPrefetch, watch} from "vue";
import {
  getJSON,
  SUCCESS,
  PAYINGSUGOTOKU,
  PAYINGECONOMY,
  PAYINGNONE,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  PAYINGSUGOTOKU_NONE,
  setCookie,
  DATETYPE,
  getMenmerCourse,
  isSaveINETID,
  loadIPATLoginInfo,
  replaceComma,
  getUserAgent,
  getUserId,
  isAndroid,
  getTopBanner,
  getCustomizeOddsType,
  setOddsUrl,
  isAtag,
  getGradeIconClass,
} from "../../assets/js/common";
import {
  DEBUG,
  HOST,
  PATH,
  BACKWORDPATH,
  JOINPATH,
  JRA_WP_HOST,
  JRA_HOST,
} from "../../assets/js/define";
import {
  showLastFixedResultRaceInfo,
  loadTodayRaceInfo,
  loadRecommend,
  loadPays1x,
  loadHighDividend,
  loadMultiWinners,
  loadUpcomingRaceInfo,
  loadNewsHeadline,
  loadChangeInfo,
  addFigure,
  getYesterday,
  getRelatedMovies,
  shouldShowFavoriteButton,
} from "../../functions/top/common";
import { setData } from "../../functions/topInit";
import moment from "moment";
import Slider from "./Slider.vue";
import TrendTable from "../info/TrendTable.vue"
import GridMovie from "./movie/GridMovie.vue";
import CheckPoint from "./CheckPoint.vue";

let slider = reactive([]);
let advertisement = reactive([]);
let announce = reactive([]);
let importantrace = reactive([]);
let campaign = reactive([]);
let information = reactive([]);
const banner = ref("");
const freeArea = ref("");
const isWebView = ref(false);
const isSugotokuAndroidWebView = ref(false);
const isAndroidWebView = ref(false);
const isIphoneWebView = ref(false);
const isSugotoku = ref(false);
const isLogin = ref(true);
let mUserId = "";
const oddsPath = ref("win")
let defaultTOB = ref(1)
import {useStoreTopJra} from "../../stores/top/jra-states";
import {fetch as fetchStoreTopJra} from "../../functions/top/jra";
import {storeToRefs} from "pinia";
const storeTopJra = useStoreTopJra();
const {
  wpContents,
  userCourse, //1:無課金 2:300会員 3:スゴ得会員 4:1000会員
} = storeToRefs(storeTopJra);

onServerPrefetch(async () => {
  await fetchStoreTopJra();
  await loadWp(1);
  loadedPage.value = true;
});

onMounted(async () => {
  isWebView.value = getUserAgent();
  isSugotokuAndroidWebView.value = isAndroid();
  if(Object.keys(wpContents.value || {}).length === 0) {
    await fetchStoreTopJra();
  }

  let userQuery = getParam('session')
  const request =
    HOST +
    PATH +
      "getLoginInfo.js" +
      BACKWORDPATH +
      "?NarFlag=0&sessionString=" +
      userQuery;
  getJSON(request, false, async (status, json) => {
    setData(status, json);
    //ユーザコースをセット
    let courseId = json.courseIdStr;
    mUserId = json.userId || getUserId();
    isLogin.value = !!mUserId;
    isIphoneWebView.value = json.isIphone;
    isAndroidWebView.value = json.isAndroid;
    if (isWebView.value && json.paymentMethod === null && json.openId !== null && json.contentId !== null) {
      let userType
      if (json.openId.includes('https://') || json.contentId.includes('dm1e100500020')) {
        if (json.courseId == 7) {
          userType = 6
        } else if (json.courseId == 1) {
          userType = 4
        } else {
          userType = 5
        }
      } else {
       if (json.courseId == 7) {
          userType = 9
        } else if (json.courseId == 1) {
          userType = 7
        } else {
          userType = 8
        }
      }

      callDart(mUserId, userType)
    }

    if (courseId === PAYINGNONE) {
      userCourse.value = 1;
    } else if (courseId === PAYINGECONOMY) {
      userCourse.value = 2;
    } else if (courseId === PAYINGSUGOTOKU) {
      userCourse.value = 3;
      isSugotoku.value = true
      defaultTOB.value = await getCustomizeOddsType(mUserId)
      oddsPath.value = await setOddsUrl(defaultTOB.value)
    } else if ([PAYINGPREMIUM, PAYINGPACK03, PAYINGPACK06, PAYINGPACK12].includes(courseId)) {
      userCourse.value = 4;
      defaultTOB.value = await getCustomizeOddsType(mUserId)
      oddsPath.value = await setOddsUrl(defaultTOB.value)
    }

    if (courseId !== PAYINGNONE) {
      loadFav();
    }
    loadRace();
    loadWp(userCourse.value);
    //banner
    if (!isWebView.value) {
      banner.value = await getTopBanner(isAndroidWebView.value, isIphoneWebView.value)
    }
  });
});

const callDartForIpatSignUp = (loginInfo) => {
  try {
    window.flutter_inappwebview.callHandler('save_ipat_info', {
      inet_id: loginInfo.inetId,
      user_id: loginInfo.userId,
      pars: loginInfo.pars
    })

    window.flutter_inappwebview.callHandler(
      "launch_ipat",
      {
        "launch_type": 3,
        "launch_url": JRA_HOST + 'purchase/appStaticIpat?info=&',
      }
    )
  } catch (err) {
    console.log(err)
    console.log(typeof(err))
    alert(err)
  }
}

const callDart = (userId, userType) => {
  try {
    console.log('dart呼び出し')
    window.flutter_inappwebview.callHandler(
      "complete_cert_logon",
      {
        "user_id": userId,
        "user_type": userType
      }
    );

    return
  } catch (err) {
    console.log(err)
    return
  }
}

const loadFav = () => {
  //今週のレース情報を取得
  const request =
    HOST +
    PATH +
    "MyFavorite/getMyFavorite.js" +
    BACKWORDPATH +
    "?userID=" +
    encodeURIComponent(mUserId) +
    "&narFlag=0"
  getJSON(request, true, function (status, json) {});
};

const loadWp = async (course) => {
  const courseKey = {
    2: 'member-300',
    4: 'member-1000',
    1: 'member-none',
    3: 'member-sugotoku-app',
  }[course] ?? 1
  //advertisement
  wpContents.value.advertisement?.forEach(element => {
    element.member[courseKey] ? advertisement.push(element) : '';
  });
  //announce
  wpContents.value.announce?.forEach(element => element.url = "/announce/?id=" + element.ID);
  wpContents.value.announce?.forEach(element => {
    element.member[courseKey] ? announce.push(element) : '';
  });
  //importantrace
  wpContents.value.importantrace?.forEach((element) => importantrace.push(element));
  //campaign
  wpContents.value.campaign?.forEach(element => {
    element.member[courseKey] ? campaign.push(element) : '';
  });
  //information
  wpContents.value.information?.forEach(element => element.url = "/information?id=" + element.ID);
  wpContents.value.information?.forEach(element => {
    element.member[courseKey] ? information.push(element) : '';
  });
  //free_area
  freeArea.value = isSugotoku.value
    ? wpContents.value.free_area?.sugotoku_app
    : wpContents.value.free_area?.basic
}

const shouldShowFavButton = ref(false);
let raceInfo = reactive({});
let hassomajika = reactive([]);
let kakutei = reactive({});
let pays1xData = reactive({});
let recommendData = reactive({});
let highDividendData = reactive({});
let multiWinnersData = reactive({});
let establishRecordData = reactive([]);
let weightLossJockeyData = reactive([]);
let win5Data = reactive({});
let newsMarksData = reactive([]);
let newsHeadlineData = reactive({});
let todayRace = reactive({});
let targetDOR = ref("");
let TargetchangeInfo = reactive({});
let targetTrackCd = ref("");
let targetRaceInfo = reactive({});
let loadedPage = ref(false);

let strDate = ref("");
let currentTime = ref(0);
let closingTime = ref(0);
let openingTime = ref(0);
let dateType = ref("");
let relatedMovies = reactive({});

const loadRace = async () => {
  document.body.className = "";
  let params;
  //本番の時
  let mDate = new Date();
  strDate.value =
    mDate.getFullYear() +
    ("0" + (mDate.getMonth() + 1)).slice(-2) +
    ("0" + mDate.getDate()).slice(-2);
  params = { DOR: strDate.value };

  if (userCourse.value !== 1) {
    shouldShowFavoriteButton(shouldShowFavButton, mUserId);
  }

  //APIコールstep1
  let req =
    HOST +
    PATH +
    "Top/getTopInfo.js" +
    BACKWORDPATH +
    "?dor=" +
    params.DOR +
    "&narFlag=0"

  await getJSON(req, true, function (status, jsonall) {
    if (status == SUCCESS) {
      Object.assign(raceInfo, jsonall);
      let json = jsonall.results1;
      let json2 = jsonall.results2;
      let json3 = jsonall.results3;
      let showMarks = jsonall.showMarks;
      if (Object.keys(json).length > 0) {
        let gtri = json.getTodayRaceInfo;
        dateType.value = gtri.DateType;
        setCookie(DATETYPE, gtri.DateType); //曜日情報

        //レース開催中の場合
        //当日表示レース情報読み込み
        Object.assign(
          todayRace,
          loadTodayRaceInfo(
            params.DOR,
            gtri,
            json2,
            json3,
            json2.getChangeInfo,
            showMarks
          )
        );
        targetDOR.value = moment().format('YYYYMMDD')

        if (
          Object.keys(todayRace).length &&
          todayRace.mDateArray.length &&
          Object.keys(todayRace.placeTab).length
        ) {
          targetTrackCd.value = todayRace.defaultRaceUrlArray.find(val => val.dor === targetDOR.value).raceTrackCd;
          Object.assign(
            targetRaceInfo,
            todayRace.raceList[targetDOR.value][targetTrackCd.value]
          );
        }

        //変更情報取得
        let changeInfo = loadChangeInfo(
          targetDOR.value,
          "index",
          json2.getChangeInfo
        );
        Object.assign(TargetchangeInfo, changeInfo[0]);

        //発走間近情報読み込み
        const upcomingRaceInfo = loadUpcomingRaceInfo(
          json.getUpcomingRacesInfo
        );
        upcomingRaceInfo.race.forEach((element) => hassomajika.push(element));

        //厳選1頭推奨馬
        const recommend= loadRecommend(json.getRecommend);
        Object.assign(recommendData, recommend);

        //単勝1倍台情報読み込み
        const pays1x = loadPays1x(params.DOR, json.getPays1x);
        Object.assign(pays1xData, pays1x);

        //高額配当情報読み込み
        const highDividend = loadHighDividend(params.DOR, json.getHighDividend);
        Object.assign(highDividendData, highDividend);

        //固め打ち騎手情報読み込み
        const multiWinners = loadMultiWinners(params.DOR, json.getMultiWinners);
        Object.assign(multiWinnersData, multiWinners);

        //記録達成間近読み込み
        json.getEstablishRecord.top.forEach(val => establishRecordData.push(val))

        //減量騎手ランキング近読み込み
        json.getWeightLossJockeyLeading.forEach(val => weightLossJockeyData.push(val))

        //WIN5情報読み込み
        Object.assign(win5Data, json.getWin5TopInfo);
        const mDate = new Date();
        currentTime.value = mDate.getHours() * 60 + mDate.getMinutes();
        closingTime.value =
            Math.floor(win5Data.ClosingTime / 10000) * 60 +
            Math.floor((win5Data.ClosingTime % 10000) / 100);
        openingTime.value = 18 * 60 + 30;

        //新聞印情報読み込み
        for (let [key, value] of Object.entries(json3)) {
          if (key.indexOf('n') > -1) {
            newsMarksData.push({
              key: key,
              value: value,
              isExist: Object.keys(value).length > 0 && value.EntryLst.length > 0
            })
          }
        }

        showRemainsVote();

        //ニュース情報読み込み
        const newsHeadline = loadNewsHeadline(json.getNewsHeadline);
        Object.assign(newsHeadlineData, newsHeadline);

        displayRaceSlider(json.getTodayRaceInfo.PatternRaces)
      }
    }
  });

  // 関連レース動画取得
  try {
    let mainRaces = {};
    Object.values(todayRace.raceList).forEach(list => {
      Object.values(list).forEach(r => {
        mainRaces[r.mainRace.SpecialRaceNum ?? "0000"] = r.mainRace.PedigreeNum.split("_").filter(n => n);
      })
    })
    Object.assign(relatedMovies, await getRelatedMovies(mainRaces));
  } catch (e) {
    console.log(e);
  }

  // 最終確定表示処理
  const lastFixedResultRaceInfo = await showLastFixedResultRaceInfo();
  Object.assign(kakutei, lastFixedResultRaceInfo);

  document.body.className = "status-loaded";
  loadedPage.value = true;
};

//トップページのスライド画像の描画関数
const displayRaceSlider = function (mRaceArray) {
  //スライド表示順をセットg1->g2->g3順
  const g1Races = [
    ...mRaceArray.filter(v => 'G1' === v.Grade),
    ...mRaceArray.filter(v => 'G2' === v.Grade),
    ...mRaceArray.filter(v => 'G3' === v.Grade)
  ]

  //描画
  for (let i = 0; i < g1Races.length; i++) {
    const grade = g1Races[i].Grade.toLowerCase();
    const raceUrl = todayRace.raceList[g1Races[i].DOR][g1Races[i].RacetrackCd].mainRace.ResultFlg
        ? '/race/results' : '/race/card'
    const linkUrl = `${raceUrl}?DOR=${g1Races[i].DOR}&RacetrackCd=${g1Races[i].RacetrackCd}&RaceNum=${g1Races[i].RaceNum}`
    const imageUrl = `${HOST}shared/img/database/race/${g1Races[i].RaceImgURL}`
    const raceLabel = `${getDisplayDateStrForMain(g1Races[i].DOR, 1)} ${g1Races[i].Racetrack}${g1Races[i].RaceNum}R`
    const gradeIcon = `icon-race -${grade}`
    const html = `
    <a href="${linkUrl}" class="item -jra -${grade}">
      <div class="image">
              <img src="${imageUrl}" alt="race-bannar">
            </div>
      <div class="raceInfo">
        <span class="name">${g1Races[i].RaceMainName}</span>
        <span class="detail">${raceLabel}<i class="${gradeIcon}"></i></span>
          </div>
        </a>
`
    slider.push(html)
  }
}

//日付タブの変更
const changeDate = (DOR) => {
  targetDOR.value = DOR;
  //競馬場タブの初期化
  targetTrackCd.value = todayRace.defaultRaceUrlArray.find(val => val.dor === DOR).raceTrackCd;

  Object.assign(
    targetRaceInfo, todayRace.raceList[targetDOR.value][targetTrackCd.value]
  )

  //変更情報の変更
  let changeInfo = loadChangeInfo(
    targetDOR.value,
    "index",
    raceInfo.results2.getChangeInfo
  );

  if (changeInfo[0]) {
    //変更情報取得
    Object.assign(TargetchangeInfo, changeInfo[0]);
  } else {
    for (let key in TargetchangeInfo) {
      delete TargetchangeInfo[key];
    }
  }
};

//競馬場タブの変更
const changeField = (racetrackCd) => {
  targetTrackCd.value = racetrackCd;
  Object.assign(
    targetRaceInfo, todayRace.raceList[targetDOR.value][targetTrackCd.value]
  )
};

//レースのURL取得
const getRaceUrl = (mainRace) => {
  const param = '?DOR=' + mainRace.DOR +
      '&RacetrackCd=' + mainRace.RacetrackCd +
      '&RaceNum=' + mainRace.RaceNum
  return mainRace.ResultFlg ? '/race/results' + param :
      mainRace.UnResultFlg === 1 ? '/race/odds/inquire' + param :
          '/race/card' + param
}

//WIN5購入ボタン押下処理
const purchaseWIN5 = () => {
  document.body.className = "";
  const yesterday = getYesterday(win5Data.DOR, -1);
  if (
    (/^0/.test(dateType.value) ||
      (/^2/.test(dateType.value) && strDate.value === win5Data.DOR) ||
      (currentTime.value - openingTime.value >= 0 &&
        strDate.value === yesterday))
  ) {
    //#1363 #4935 非会員の場合は入会画面へ遷移
    if (getMenmerCourse() === PAYINGNONE && !isWebView.value) {
      location.href = HOST + JOINPATH;
    } else if (getMenmerCourse() === PAYINGSUGOTOKU_NONE) {
      location.href = location.pathname + "?url=" + HOST + "joinSugotoku.js";
    } else {
      if (isSaveINETID() == false) {
        //INET ID未入力の場合
        location.href = "./purchase/ipat/setting?ipatBet=gowin5";
      }

      let loginInfo = loadIPATLoginInfo();
      const request =
        HOST +
        PATH +
        "Odds/purchase.js" +
        BACKWORDPATH +
        "?inetId=" +
        loginInfo.inetid +
        "&uid=" +
        loginInfo.userid +
        "&pass=" +
        loginInfo.pass +
        "&pars=" +
        loginInfo.pars +
        '&userId=' +
        encodeURIComponent(getUserId());
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          try {
            if (json.status == "NG") {
              alert(json.message);
            } else {
              if (isWebView.value) {
                callDartForIpatSignUp(loginInfo)
              } else {
                let form = document.createElement('form');
                form.action = 'https://www.ipat.jra.go.jp/sp/pw_760_i.cgi';
                form.method = "POST";
                document.body.append(form);

                form.addEventListener("formdata", (e) => {
                  var fd = e.formData;
                  fd.set("g", "732");
                  fd.set("uh", json.jra_uh);
                  fd.set("inetid", json.jra_inetid);
                  fd.set("u", json.jra_u);
                  fd.set("nm", json.jra_nm);
                  fd.set("zj", json.jra_zj);
                  fd.set("uk", "0");

                });

                form.submit();
              }
            }
            document.body.className = "status-loaded";
          } catch (e) {
            console.log(e)
            document.body.className = "status-loaded";
          }
        }
      });

    }
  } else {
    alert("WIN5は現在購入できません。");
    document.body.className = "status-loaded";
  }
};

//WIN5 払戻金・票数表示
const sellVote = ref("");
const hitVote = ref("");
const refundSum = ref("");
const remainVote = ref("");
//「WIN5を購入する」ボタンの表示
const showVoteButton = (win5Data) => {
  return !isSugotoku.value && //スゴ得はWIN5ボタンを表示しない
      win5Data.RefundInfo.length <= 0 && //全レースが確定している場合表示しない
      !(currentTime.value - closingTime.value + 1 >= 0 && strDate.value === win5Data.DOR)
}
//「対象レース一覧」ボタンの表示
const showRaceListButton = (win5Data) => {
  //WIN5レース前日の19:30以降とWIN5レース当日に表示
  return (currentTime.value - openingTime.value >= 0 && strDate.value === getYesterday(win5Data.DOR, -1)) ||
      strDate.value === win5Data.DOR
}
//票数表示
const showRemainsVote = () => {
  if (Object.keys(win5Data).length > 0) {
    sellVote.value = replaceComma(win5Data.SellVote);
    if (win5Data.RefundInfo.length > 0) {
      //全レース確定時には、的中票数/発売票数を表示
      let refund = win5Data.RefundInfo[0].Refund;
      let hit = win5Data.RefundInfo[0].HitVote;
      if (refund === 0 && hit === 0) {
        refund = "-"; //#4910 的中がない場合の払戻金表示修正 0円→-円
      }
      hitVote.value = replaceComma(hit);
      refundSum.value = replaceComma(refund);
    } else {
      //確定前の場合は、RemainsVoteが格納されている最後のデータを表示
      let mArray = win5Data.SearchHorseList;
      let vote = "";
      for (let i = 0; i < mArray.length; i++) {
        //描画
        if (
          "HorseNum" in mArray[i] &&
          mArray[i].HorseNum != null &&
          mArray[i].HorseNum != ""
        ) {
          //「残り票数/発売票数」
          if (
            "RemainsVote" in mArray[i] &&
            mArray[i].RemainsVote != null &&
            mArray[i].RemainsVote != ""
          ) {
            vote = replaceComma(mArray[i].RemainsVote);
          }
        }
        //「残り票数/発売票数」表示
        if (
          vote != "" &&
          win5Data.RefundInfo.length == 0 &&
          i == mArray.length - 1
        ) {
          remainVote.value = vote;
        }
      }
    }
  }
};

//レース表示部で使用する日付フォーマット取得関数（例：○/△（□））
const getDisplayDateStrForMain = (yyyymmdd, type = 0) => {
  let DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  let month = parseInt(yyyymmdd.slice(4, 6), 10);
  let date = parseInt(yyyymmdd.slice(6, 8), 10);
  let mDate = new Date(parseInt(yyyymmdd.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day == "土") {
    day = '<span class="_sat">' + day + "</span>";
  } else if (day == "日") {
    day = '<span class="_sun">' + day + "</span>";
  }
  return type > 0 ?
      month + "/" + date + "(" + day + ")" :
      month + "月" + date + "日" + "(" + day + ")";
};

const getGradeCd = (mainRace) => {
  return getGradeIconClass(mainRace.GradeCd, mainRace.TrackTypeCd, mainRace.RaceRegulationCd);
}

const existNewsMarks = (dor, racetrackCd, raceNum) => {
  return newsMarksData.some(val => val.key === 'n' + dor + racetrackCd + raceNum) &&
      newsMarksData.find(val => val.key === 'n' + dor + racetrackCd + raceNum).isExist
}

/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
const getParam = (name) => {
  let url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  let results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

</script>
<template>
  <main class="layout-main">
    <div class="welcome" v-if="!isLogin && !isWebView">
      こちらから <router-link to="/login">ログイン</router-link> /
      <router-link to="/member">会員登録</router-link><span> してください</span>
    </div>
    <div v-if="banner" v-html="banner"></div>

    <section class="layout-section">
      <div
        v-if="userCourse === 2 || userCourse === 3 || userCourse === 4"
        class="container-announce"
      >
        <template v-if="hassomajika.length">
          <router-link
            v-for="(hasso, index) in hassomajika"
            :to="hasso.mHref"
            :key="index"
            :class="
              hasso.UnResultFlg === 1
                ? '-nyusensokuho'
                : hasso.fiveMin === true
                ? '-hassomajika'
                : ''
            "
          >
            <dl>
              <dt v-if="hasso.UnResultFlg === 1">入線速報！</dt>
              <dt v-else>発走間近！</dt>
              <dd>
                <span class="sub">{{ hasso.mArray.RaceShortName }}</span>
                <span class="main"
                  >{{ hasso.raceFieldName
                  }}{{ Number(hasso.mArray.RaceNum) }}R/<time>{{
                    hasso.mTime
                  }}</time></span
                >
              </dd>
            </dl>
          </router-link>
        </template>
      </div>
      <router-link class="container-kakutei" v-if="Object.keys(kakutei).length > 0" :to="kakutei.href">
        <dl>
          <dt>最新確定</dt>
          <dd>
            {{ kakutei.Racetrack }}{{ kakutei.RaceNum }}R {{ kakutei.RaceName }}
          </dd>
        </dl>
      </router-link>
    </section>

    <section class="layout-section">
      <slider v-if="loadedPage" :slider="slider"></slider>

      <div class="container-banner" v-if="Object.keys(advertisement).length">
        <div
          v-for="item in advertisement"
          :class="item.size"
          class="link"
        >
          <a
              v-if="item.url"
              :href="item.url"
              :target="item.window ? '_blank' : '_self'"
          >
            <img
                class="-contain"
                :src="item.img"
                v-bind:alt="item.title"
            />
          </a>
          <router-link
              v-else
              :to="'/advertisement/?id=' + item.ID"
              :target="item.window ? '_blank' : '_self'"
          >
            <img
                class="-contain"
                :src="item.img"
                v-bind:alt="item.title"
            />
          </router-link>
        </div>
      </div>

      <div
        class="container-wrapper -pad"
        v-if="announce.length > 0 || shouldShowFavButton"
      >
        <ul class="container-report" v-if="announce.length > 0">
          <li v-for="item in announce">
            <a class="link-cell" v-if="item.link_url && isAtag(item.link_url)" :href="item.link_url">
              <span>{{ item.title }}</span>
              <time class="caption">{{ moment(item.date).format("YYYY.MM.DD HH:mm") }}</time>
            </a>
            <router-link v-else-if="item.link_url" class="link-cell" :to="item.link_url">
              <span>{{ item.title }}</span>
              <time class="caption">{{ moment(item.date).format("YYYY.MM.DD HH:mm") }}</time>
            </router-link>
            <router-link class="link-cell" v-else :to="item.url">
              <span>{{ item.title }}</span>
              <time class="caption">{{ moment(item.date).format("YYYY.MM.DD H:mm") }}</time>
            </router-link>
          </li>
        </ul>
        <router-link
          v-if="shouldShowFavButton"
          to="/fav/race"
          class="btn-basic -wide -center -imporntat"
          >MYお気に入り/次走狙い 出走予定</router-link
        >
      </div>
    </section>

    <section v-if="'mDateArray' in todayRace" class="layout-section">
      <div class="layout-sectionheader -race">
        <h1 class="heading-section">レース情報</h1>
      </div>
      <div
        class="nav-tab -date"
        :class="todayRace.mDateArray.length === 3 ? '-fit3' : '-fit2'"
      >
        <ul>
          <li
            v-for="date in todayRace.mDateArray"
            :class="date.DOR === targetDOR ? '-current' : ''"
            @click="changeDate(date.DOR)"
          >
            <div class="label" v-html="date.date"></div>
          </li>
        </ul>
      </div>
      <div class="container-box">
        <div
          v-if="Object.keys(TargetchangeInfo).length"
          class="container-changeinfo"
        >
          <div class="summary">
            <span>{{ TargetchangeInfo.Contents }}</span>
          </div>
          <div class="nav">
            <router-link :to="'/info?dor=' + TargetchangeInfo.DOR" class="btn-basic"
              >変更情報一覧</router-link
            >
          </div>
        </div>
      </div>
      <div
        v-if="targetDOR.length"
        class="nav-tab -hasweather -jra"
        :class="todayRace.placeTab[targetDOR].length === 3 ? '-fit3' : '-fit2'"
      >
        <ul>
          <li
            v-for="place in todayRace.placeTab[targetDOR]"
            :class="place.RacetrackCd === targetTrackCd ? '-current' : ''"
            @click="changeField(place.RacetrackCd)"
          >
            <div class="label">
              <div class="place">
                <span class="name">{{ place.Racetrack }}</span>
                <div class="container-condition -jra">
                  <div class="weather">
                    <i
                      :class="
                        place.Weather != undefined
                          ? 'icon-weather -w' + place.Weather
                          : ''
                      "
                      >{{ place.Weather === undefined ? "-" : "" }}</i
                    >
                  </div>
                  <div class="status">
                    <ul>
                      <li>
                        {{
                          place.TrackConditionCdTurf != undefined &&
                          place.TrackConditionCdTurf != ""
                            ? "芝/" + place.TrackConditionCdTurf
                            : "芝/-"
                        }}
                      </li>
                      <li>
                        {{
                          place.TrackConditionCdDirt != undefined &&
                          place.TrackConditionCdDirt != ""
                            ? "ダ/" + place.TrackConditionCdDirt
                            : "ダ/-"
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="inner">
        <div
          v-if="Object.keys(targetRaceInfo).length && targetRaceInfo != undefined"
          class="layout-sub -min">
          <ul class="grid-block -cols2 -haspadding -jra">
            <li>
              <router-link
                :to="
                  '/race?DOR=' +
                  targetRaceInfo.mainRace.DOR +
                  '&RacetrackCd=' +
                  targetRaceInfo.mainRace
                    .RacetrackCd
                "
                class="btn-basic -hasicon -race"
                >レース一覧</router-link
              >
            </li>
            <li>
              <router-link
                :to="
                  '/race/odds/payout?DOR=' +
                  targetRaceInfo.mainRace.DOR +
                  '&RacetrackCd=' +
                  targetRaceInfo.mainRace
                    .RacetrackCd
                "
                class="btn-basic -hasicon -payout"
                >払戻金一覧</router-link
              >
            </li>
          </ul>
        </div>
        <div
          v-if="
            Object.keys(targetRaceInfo).length &&
            todayRace.raceList[targetDOR][targetTrackCd] != undefined
          "
          class="container-line"
        >
          <div class="title" :class="
                todayRace.raceList[targetDOR][targetTrackCd].mainRace.Status === '2'
                  ? '-cancel'
                  : todayRace.raceList[targetDOR][targetTrackCd].mainRace.ResultFlg === true
                  ? '-kakutei'
                  : todayRace.raceList[targetDOR][targetTrackCd].mainRace.UnResultFlg === 1
                  ? '-mikakutei'
                  : ''
              "
            >
            <router-link :to="getRaceUrl(todayRace.raceList[targetDOR][targetTrackCd].mainRace)">
              <h3 class="heading-forbox">
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .RaceMainName
                }}
                <i :class="getGradeCd(todayRace.raceList[targetDOR][targetTrackCd].mainRace)"></i>
              </h3>
            </router-link>
            <ul class="list-meta">
              <li>
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .racetrackCd
                }}
              </li>
              <li>
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .TrackType
                }}{{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .DistanceShow
                }}m
              </li>
              <li>
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .StartTimeShow
                }}
                発走
              </li>
            </ul>
          </div>
          <div class="layout-sub -min">
            <ul
              class="grid-block -haspadding -cols3 -jra"
            >
              <li>
                <router-link
                  :to="
                  userCourse === 1 && !isWebView
                    ? '/member'
                    : '/race/odds/' + oddsPath + '?DOR=' +
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR +
                      '&RacetrackCd=' +
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace
                        .RacetrackCd +
                      '&RaceNum=' +
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace
                        .RaceNum
                  "
                  class="btn-basic -hasicon -odds"
                  >オッズ</router-link
                >
              </li>
              <li>
                <router-link
                  :to="
                  userCourse === 1
                    ? '/member'
                    : '/race/analysis?DOR=' +
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR +
                      '&RacetrackCd=' +
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace
                        .RacetrackCd +
                      '&RaceNum=' +
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace
                        .RaceNum
                  "
                  class="btn-basic -hasicon -data"
                  >データ分析</router-link
                >
              </li>
              <li>
                <router-link
                  :to="
                    userCourse === 4 || userCourse === 3
                      ? '/race/marks?DOR=' +
                        todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .DOR +
                        '&RacetrackCd=' +
                        todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .RacetrackCd +
                        '&RaceNum=' +
                        todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .RaceNum
                      : userCourse === 2
                      ? '/member/join_economy'
                      : '/member'
                  "
                  class="btn-basic -hasicon -newspaper"
                  :class="existNewsMarks(
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR,
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace.RacetrackCd,
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace.RaceNum
                      )
                       ? '' : '-disable'"
                  >新聞印<i v-if="userCourse === 1 || userCourse === 2" class="icon-premium"></i
                ></router-link>
              </li>
            </ul>
          </div>
          <div
            v-if="
              todayRace.raceList[targetDOR][targetTrackCd].candidacyTable.length
            "
            class="layout-sub"
          >
            <div class="layout-title -marginbottom">
              <h4 class="heading-circle">
                単勝人気<span class="caption">{{
                  todayRace.oddsObj[targetDOR][targetTrackCd]
                }}</span>
              </h4>
              <div class="nav">
                <router-link
                  :to="
                    '/race/odds/win?DOR=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR +
                    '&RacetrackCd=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RacetrackCd +
                    '&RaceNum=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RaceNum
                  "
                  class="btn-basic"
                  >もっと見る</router-link
                >
              </div>
              <div v-if="userCourse === 1 && !isWebView" class="system-registmember -flow">
                <div class="inner">
                  <router-link
                      to="/member"
                      target="_blank"
                      class="btn-basic -wide -center -window"
                  >こちらの機能をご利用いただくには、<br />
                    月額コースへの会員登録が必要です。</router-link
                  >
                </div>
              </div>
            </div>
            <table class="table-line -center -middle -popular">
              <tbody>
                <tr>
                  <td class="ranking"><i class="icon-ranking -no1st"></i></td>
                  <td class="umaban">
                    <i
                      :class="
                        'icon-umaban -waku0' +
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].BracketNum
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].HorseNum
                      }}</i
                    >
                  </td>
                  <td class="horse">
                    <router-link
                      :to="
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].dataUrl
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].horseName
                      }}</router-link
                    >
                  </td>
                  <td
                    class="odds"
                    v-html="
                      todayRace.raceList[targetDOR][targetTrackCd]
                        .candidacyTable[0].iconColor
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="ranking"><i class="icon-ranking -no2st"></i></td>
                  <td class="umaban">
                    <i
                      :class="
                        'icon-umaban -waku0' +
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].BracketNum
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].HorseNum
                      }}</i
                    >
                  </td>
                  <td class="horse">
                    <router-link
                      :to="
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].dataUrl
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].horseName
                      }}</router-link
                    >
                  </td>
                  <td
                    class="odds"
                    v-html="
                      todayRace.raceList[targetDOR][targetTrackCd]
                        .candidacyTable[1].iconColor
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="ranking"><i class="icon-ranking -no3st"></i></td>
                  <td class="umaban">
                    <i
                      :class="
                        'icon-umaban -waku0' +
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].BracketNum
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].HorseNum
                      }}</i
                    >
                  </td>
                  <td class="horse">
                    <router-link
                      :to="
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].dataUrl
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].horseName
                      }}</router-link
                    >
                  </td>
                  <td
                    class="odds"
                    v-html="
                      todayRace.raceList[targetDOR][targetTrackCd]
                        .candidacyTable[2].iconColor
                    "
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
          <GridMovie v-if="relatedMovies[todayRace.raceList[targetDOR][targetTrackCd].mainRace.SpecialRaceNum]"
                     :races="Object.values(relatedMovies[todayRace.raceList[targetDOR][targetTrackCd].mainRace.SpecialRaceNum]) ?? []"
                     :userCourse="userCourse"
                     :loginUrl="{ 1: '/member/', 2: '/member/join_economy' }[userCourse] ?? ''"
          />
        </div>
        <div v-if="Object.keys(todayRace.raceTrend[targetDOR][targetTrackCd].trend).length"
             class="container-line">
          <div class="layout-title -marginbottom">
            <h4 class="heading-circle">本日のレース傾向</h4>
            <div class="nav">
              <router-link :to="todayRace.raceTrend[targetDOR][targetTrackCd].mHref"
                 class="btn-basic">
                前回の傾向
              </router-link>
            </div>
          </div>
          <div class="layout-sub">
            <trend-table :is-jra="true"
                         :is-top="true"
                         :race-info-array="todayRace.raceTrend[targetDOR][targetTrackCd].trend"
                         :jockey-array="todayRace.raceTrend[targetDOR][targetTrackCd].jockeyArray"
                         :stud-array="todayRace.raceTrend[targetDOR][targetTrackCd].studArray"
                         :loaded-page="loadedPage"
                         :is-non-member="userCourse === 1">
            </trend-table>
          </div>
        </div>
      </div>
    </section>

    <check-point :user-course="userCourse"
                 :loaded-page="loadedPage"
                 :recommend-data="recommendData"
                 :pays1x-data="pays1xData"
                 :high-dividend-data="highDividendData"
                 :multi-winners-data="multiWinnersData"
                 :establish-record-data="establishRecordData"
                 :weight-loss-jockey-data="weightLossJockeyData">
    </check-point>

    <section
      v-if="Object.keys(win5Data).length"
      class="layout-section"
    >
      <div class="layout-sectionheader -win5">
        <h1 class="heading-section">WIN5</h1>
        <div class="nav">
          <router-link
            :to="'/win5?YearMonth=' + win5Data.DOR.slice(0, 6)"
            class="btn-basic"
            >過去の結果一覧</router-link
          >
        </div>
      </div>
      <div class="container-box">
        <div class="inner">
          <div class="layout-title -marginbottom -top">
            <h2 class="heading-item -wi5logo">
              <img src="/assets/images/win5/logo.png" alt="WIN5" />
            </h2>
            <div class="info">
              {{ win5Data.DOR.slice(0, 4) }}.{{
                ("00" + win5Data.DOR.slice(4, 6)).slice(-2)
              }}.{{ ("00" + win5Data.DOR.slice(6, 8)).slice(-2) }}
            </div>
          </div>

          <dl class="container-win5carryover">
            <dt>
              {{
                win5Data.RefundInfo.length > 0 ? "次回" : "今回"
              }}キャリーオーバー
            </dt>
            <dd>
              &yen;
              {{
                win5Data.RefundInfo.length > 0
                  ? addFigure(win5Data.CarryOver || 0)
                  : addFigure(win5Data.PreCarryOver || 0)
              }}
            </dd>
          </dl>

          <div class="container-win5result -marginbottom">
            <div class="layout-title">
              <h3 class="title">的中馬番</h3>
              <div v-if="win5Data.RefundInfo.length > 0">
                <span v-html="getDisplayDateStrForMain(win5Data.DOR)"></span>
              </div>
              <div
                v-else-if="
                  currentTime - closingTime + 1 >= 0 && strDate === win5Data.DOR
                "
              >
                発売締切
              </div>
              <div
                v-else-if="
                  strDate === win5Data.DOR ||
                  (currentTime - openingTime >= 0 &&
                    strDate === getYesterday(win5Data.DOR, -1))
                "
                class="info"
              >
                締切 {{ win5Data.ClosingTime.slice(0, 2) }}:{{
                  win5Data.ClosingTime.slice(2, 4)
                }}
              </div>
            </div>
            <ul>
              <li
                v-for="(race, index) in win5Data.SearchHorseList"
                :key="index"
              >
                <dl>
                  <dt v-if="race.BracketNum === ''">{{ index + 1 }}R</dt>
                  <dt v-else class="kakutei">{{ index + 1 }}R</dt>
                  <dd>
                    <div>
                      {{ race.Racetrack + race.RaceNum * 1 + "R" }}
                    </div>
                    {{ race.length }}
                    <i :class="'icon-umaban -waku0' + race.BracketNum">{{
                      race.BracketNum != "" ? race.HorseNum * 1 : "?"
                    }}</i>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
          <table v-if="win5Data.RefundInfo.length > 0" class="table-grid -win5">
            <tbody>
              <tr>
                <th>払戻金</th>
                <td>{{ refundSum }}円</td>
              </tr>
              <tr>
                <th>的中票数 / 発売票数</th>
                <td>{{ hitVote }}票 / {{ sellVote }}票</td>
              </tr>
            </tbody>
          </table>
          <table v-else-if="remainVote != ''" class="table-grid -win5">
            <tbody>
              <tr>
                <th>残り票数 / 発売票数</th>
                <td>{{ remainVote }}票 / {{ sellVote }}票</td>
              </tr>
            </tbody>
          </table>
          <div class="layout-narrow -padding">
            <a
              v-if="showVoteButton(win5Data)"
              @click="purchaseWIN5()"
              href="#"
              class="btn-basic -wide -important"
              >WIN5を購入</a
            >
            <router-link v-if="showRaceListButton(win5Data)"
               :to="'/win5/results_more?DOR=' + win5Data.DOR"
               class="btn-basic -wide"
            >対象レース一覧</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <section class="layout-section">
      <div class="layout-sectionheader -news">
        <h1 class="heading-section">ニュース</h1>
        <div class="nav">
          <router-link class="btn-basic" to="/news/">ニュース一覧</router-link>
        </div>
      </div>

      <ul class="list-news">
        <li
          v-if="Object.keys(importantrace).length"
          class="item"
          v-for="race in importantrace"
        >
          <div class="summary">
            <a v-if="isAtag(race.url)" :href="race.url" class="title" v-html="race.title"></a>
            <router-link v-else :to="race.url" class="title" v-html="race.title"></router-link>
            <time class="caption"
              >{{ moment(race.date).format("YYYY.MM.DD HH:mm") }} 更新</time
            >
          </div>
          <div class="thumb">
            <img
              v-if="race.thumbnail"
              src="../../assets/images/common/blank_square.png"
              alt=""
              :style="{ 'background-image': 'url(' + race.thumbnail + ')' }"
            />
            <!-- デジマース側でno image画像作成する可能性あるためコメントアウトで対応(小林 2022/12/22) -->
            <!-- <img
              v-else
              src="../../assets/images/common/blank_square.png"
              alt=""
              style="
                background-image: url(/assets/images/common/blank_winkeiba.png);
              "
            /> -->
          </div>
          <div class="important"><span class="icon-caution">注目!</span></div>
        </li>

        <!--以下通常NEWS-->
        <li
          v-if="Object.keys(newsHeadlineData).length"
          v-for="news in newsHeadlineData"
          class="item"
        >
          <div class="summary">
            <a v-if="isAtag(news.mHref)" :href="news.mHref" class="title">{{ news.Headline }}</a>
            <router-link v-else :to="news.mHref" class="title">{{ news.Headline }}</router-link>
            <time class="caption">{{ news.mUpdateTime }} 更新</time>
          </div>
          <div class="thumb">
            <img
              v-if="news.ImageFileURL"
              src="../../assets/images/common/blank_square.png"
              alt=""
              :style="{ backgroundImage: 'url(' + news.ImageFileURL + ')' }"
            />
          </div>
        </li>
      </ul>
    </section>

    <section class="layout-section" v-if="Object.keys(campaign).length && !isSugotoku">
      <div class="layout-sectionheader -oshirase">
        <h1 class="heading-section">キャンペーン</h1>
      </div>
      <div class="container-banner">
        <div v-for="item in campaign"
          :class="item.size"
          class="link"
        >
          <a
            v-if="item.url"
            :href="item.url"
            :target="item.window ? '_blank' : '_self'"
          >
            <img
              src="../../assets/images/index/blank_campaign.png"
              v-bind:alt="item.title"
              :style="{ 'background-image': 'url(' + item.img + ')' }"
            />
          </a>
          <router-link
            v-else
            :to="'/campaign/?id=' + item.ID"
            :target="item.window ? '_blank' : '_self'"
          >
            <img
              src="../../assets/images/index/blank_campaign.png"
              v-bind:alt="item.title"
              :style="{ 'background-image': 'url(' + item.img + ')' }"
            />
          </router-link>
        </div>
      </div>
    </section>

    <section class="layout-section" v-if="Object.keys(information).length">
      <div class="layout-sectionheader -information">
        <h1 class="heading-section">INFORMATION</h1>
      </div>
      <ul class="list-news">
        <li
          class="item"
          v-for="info in information"
        >
          <div class="summary">
            <a v-if="info.link_url && isAtag(info.link_url)" :href="info.link_url" target="_blank">
              <div class="title" v-html="info.title"></div>
              <time class="caption">{{moment(info.date).format("YYYY.MM.DD HH:mm")}}</time>
            </a>
            <router-link v-else-if="info.link_url" :to="info.link_url" target="_blank">
              <div class="title" v-html="info.title"></div>
              <time class="caption">{{moment(info.date).format("YYYY.MM.DD HH:mm")}}</time>
            </router-link>
            <router-link v-else :to="info.url">
              <div class="title" v-html="info.title"></div>
              <time class="caption">{{moment(info.date).format("YYYY.MM.DD HH:mm")}}</time>
            </router-link>
          </div>
        </li>
      </ul>
    </section>

    <section class="layout-section" v-if="freeArea" v-html="freeArea"></section>

    <section class="layout-section" v-if="isSugotoku && isSugotokuAndroidWebView">
      <h2 class="heading-circle -padding -bordertop">サポート</h2>
      <ul class="list-line">
        <li class="item">
          <router-link to="/horselist/" class="link-cell">JRA登録馬一覧</router-link>
        </li>
        <li class="item">
          <a href="https://www.sugotoku.docomo.ne.jp/cs/faq.html" class="link-cell">よくある質問／お問い合わせ</a>
        </li>
        <li class="item">
          <a href="https://www.sugotoku.docomo.ne.jp/cs/representation.html" class="link-cell">提供事業者に関する表示</a>
        </li>
      </ul>
    </section>
    <section class="layout-section" v-else-if="isSugotoku && isWebView">
      <h2 class="heading-circle -padding -bordertop">サポート</h2>
      <ul class="list-line">
        <li class="item">
          <router-link to="/horselist/" class="link-cell">JRA登録馬一覧</router-link>
        </li>
      </ul>
    </section>

    <div class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style scoped>
.link-cell .inner {
  display: block;
}

a {
  cursor: pointer;
}

.welcome {
  background-color: #9fc97a;
  text-align: center;
  padding: 6px 0;
}
.welcome a {
  color:white;
  text-decoration:underline;
}
.welcome span {
  color:black;
}

</style>