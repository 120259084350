<script setup>
import {onMounted, reactive, ref} from "vue";
import {BACKWORDPATH, HOST, PATH, RACEINFOPATH} from "../../assets/js/define";
import {dayWeek, getJSON, getUrlVars, isNar, SUCCESS} from "../../assets/js/common";
import moment from "moment/moment";
import {useRouter} from "vue-router";

const router = useRouter()

const raceLength = ref(0);
let houseList = ref([]);
let params = ref({});
let toDay = ref("");
let thisWeek = ref("");
const todayRaceFlag = ref(false);
let DOR = ref("");
const scrollGuid = ref(true);
const loadedPage = ref(false);
let displayScroll =  ref(false);

//JRA用
const setDOR = ref(""); //現在のDOR
const setRacetrack = ref(""); //現在の競馬場
const DORTab = ref(2); //日付タブのインデックス
const PlaceTab = ref(3); //場所タブのインデックス
let DORArray = reactive([]); //日付タブのデータ
let placeArray = reactive([]); //場所タブのデータ

const narFlag = ref(null);

//DORからレース名とレース情報を取得（本日指定）
let todayRaceTrackCd = reactive({});
const targetRacetrackCd = ref("");
const targetRacetrackName = ref("");

onMounted(() => {
  //パラメータ取得
  Object.assign(params, getUrlVars());
  narFlag.value = isNar()

  if (isNar() === 0) {
    //JRAのみ
    getRaceDay(function () {
      loadRaceHeader(DOR.value, setRacetrack.value, function () {
        getStallionList(setDOR.value, setRacetrack.value);
      });
    })
  } else {
    //NARのみ
    doInit(params);

  }
});

const doInit = async (params) => {
  let dor, raceTrackCd;
  let numMonth, numDay;
    todayRaceFlag.value = true;
    //クエリパラメータがない場合は本日の日付をDORに変換し設定する
    const today = new Date();

    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    toDay.value = numMonth = month;
    if (String(month).length == 1) {
      month = "0" + month;
    } else {
      month = String(month);
    }
    let day = (numDay = today.getDate());
    toDay.value += "/" + day;
    if (String(day).length == 1) {
      day = "0" + day;
    } else {
      day = String(day);
    }
    dor = String(year) + month + day;
    DOR.value = dor;
    thisWeek.value = dayWeek(year, numMonth, numDay);
    await getThisDayRaceInfo(dor);
};

const getThisDayRaceInfo = (dor) => {
  const request =
      HOST +
      RACEINFOPATH +
      "getThisAndLastWeekRaceInfo.js" +
      BACKWORDPATH +
      "?NarFlag=" +
      isNar();
  try {
    getJSON(request, true, function (statusToday, jsonToday) {
      if (statusToday === SUCCESS) {
        if (jsonToday.length > 0) {
          const todayRaceInfo =
              jsonToday[0].TodayRaceInfo.TargetRacetrackCd[dor];
          if (todayRaceInfo) {
            if (isNar() === 1) {
              const sortArray = [44, 45, 43, 42, 30, 31, 32, 34, 35, 36, 37, 37, 38, 38, 40, 41, 46, 47, 48, 49, 50,
                51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 33];
              todayRaceInfo.sort(function(x, y) {
                return sortArray.indexOf(Number(x.RacetrackCd)) - sortArray.indexOf(Number(y.RacetrackCd));
              })
            }
            Object.assign(todayRaceTrackCd, todayRaceInfo);
            // targetRacetrackCd.value = todayRaceTrackCd[0].RacetrackCd;
            // targetRacetrackName.value = todayRaceTrackCd[0].Racetrack;
            getStallionList(DOR.value, targetRacetrackCd.value);
          } else {
            document.body.className = "status-loaded";
            loadedPage.value = true;
          }
        }
      }
    });
  } catch (e) {
    console.log("raceList : " + e);
  }
};
const getRaceDay = (callback) => {
  if (params.DOR && params.RacetrackCd) {
    DOR.value = params.DOR
    setDOR.value = params.DOR
    setRacetrack.value = params.RacetrackCd
    callback();
  } else {
    const request =
        HOST +
        RACEINFOPATH +
        "getTodayRaceInfo.js" +
        BACKWORDPATH +
        "?narFlag=" +
        isNar();
    try {
      getJSON(request, true, function (status, json) {
        let dor = getDOR(json.TargetDate)
        DOR.value = params.DOR || dor;
        setDOR.value = params.DOR || dor;
        // setRacetrack.value = params.RacetrackCd || json.TargetRacetrackCd[0].RacetrackCd;
        callback();
      })
    } catch (e) {
      alert("getStallionList : " + e);
    }
  }
}

const getDOR = (targetDateArray) => {
  const today = moment().format('YYYYMMDD')
  return targetDateArray.some(val => val.DOR === today) ? today : targetDateArray[0]?.DOR
}

const loadRaceHeader = (setdor, setracetrackCd, callback) => {
  //パラメータからAPIコールを生成
  let req =
      HOST + RACEINFOPATH + "getRaceHeader.js" + BACKWORDPATH + "?dor=" + setdor;

  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      try {
        json = json[0]
        if (
            json.TargetDate &&
            json.TargetDate.length > 0 &&
            json.TargetRacetrackCd
        ) {
          let DORList = json.TargetDate;
          let RacetrackList = json.TargetRacetrackCd;

          //開催年月日タブと表示番号の設定
          let dayTab = {};
          for (let i = 0; i < DORList.length; i++) {
            dayTab[DORList[i]] = i + 1;
          }

          //タブの列数
          DORTab.value = DORList.length > 2 ? 3 : 2;
          PlaceTab.value = RacetrackList.length > 2 ? 3 : 2;


          //競馬場データの挿入
          Object.assign(placeArray, RacetrackList);

          for (let i = 0; i < DORList.length; i++) {
            //描画
            DORArray.push(DORList[i]);
          }
        }

        if (callback) {
          callback();
        }
      } catch (e) {
        console.log(e);
      }
    }
  });
};

//DOR,レースCdからレース情報を取得（日付指定）
const getSelectDayRaceInfo = (dor, racetrackCd) => {
  const request =
      HOST +
      RACEINFOPATH +
      "getRaceHeader.js" +
      BACKWORDPATH +
      "?dor=" +
      dor +
      "&narFlag=" +
      isNar();
  try {
    getJSON(request, true, function (statusToday, jsonToday) {
      if (statusToday === SUCCESS) {
        if (jsonToday.length > 0) {
          const todayRaceInfo = jsonToday[0].TargetRacetrackCd[dor];
          if (todayRaceInfo) {
            if (isNar() === 1) {
              const sortArray = [44, 45, 43, 42, 30, 31, 32, 34, 35, 36, 37, 37, 38, 38, 40, 41, 46, 47, 48, 49, 50,
                51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 33];
              todayRaceInfo.sort(function(x, y) {
                return sortArray.indexOf(Number(x.RacetrackCd)) - sortArray.indexOf(Number(y.RacetrackCd));
              })
            }
            //指定の日時にレースがある場合は生成
            Object.assign(todayRaceTrackCd, todayRaceInfo);
            raceLength.value = todayRaceInfo.length
            let info = todayRaceInfo.find(
                (info) => info.RacetrackCd === racetrackCd
            );
            if (info) {
              //指定の競走馬のレースが該当日にある場合は生成
              targetRacetrackCd.value = info.RacetrackCd;
              targetRacetrackName.value = info.Racetrack;
              getStallionList(DOR.value, targetRacetrackCd.value);
            } else {
              document.body.className = "status-loaded";
              loadedPage.value = true;
            }
          }
        }
      }
    });
  } catch (e) {
    console.log("getStallionList : " + e);
  }
};

//表示する競馬場の変更
const changeTrack = (raceTrackCd, raceTrack) => {
  loadedPage.value = false;
  targetRacetrackCd.value = raceTrackCd;
  targetRacetrackName.value = raceTrack;
  getStallionList(DOR.value, targetRacetrackCd.value);
};

//表示する日付の変更(JRAのみ)
const changeDOR = (dor) => {
  loadedPage.value = false;
  setDOR.value = dor;
  getStallionList(setDOR.value, setRacetrack.value);
};

//表示する競馬場の変更(JRAのみ)
const changeTrackJRA = (racetrack) => {
  loadedPage.value = false;
  setRacetrack.value = racetrack;
  getStallionList(setDOR.value, setRacetrack.value);
};

const getStallionList = (dor, racetrackCd) => {
  houseList.value.splice(0);
  const request =
      HOST +
      PATH +
      "RaceInfo/getStallionRaceCount.js" +
      BACKWORDPATH +
      "?dor=" +
      dor +
      "&racetrackCd=" +
      racetrackCd +
      "&narFlag=" +
      isNar();
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      for(let horse of json) {
        houseList.value.push({
          houseName: horse.houseName,
          breedingNum: horse.breedingNum,
          count: horse.count,
        })
      }
    }
    loadedPage.value = true;
  });

}


//レース表示部で使用する日付フォーマット取得関数（例：○/△（□））
const getDisplayDateStrForMain = (yyyymmdd) => {
  const DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  const month = parseInt(yyyymmdd.slice(4, 6), 10);
  const date = parseInt(yyyymmdd.slice(6, 8), 10);
  const mDate = new Date(parseInt(yyyymmdd.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day == "土") {
    day = '<span class="week _sat">' + day + "</span>";
  } else if (day == "日") {
    day = '<span class="week _sun">' + day + "</span>";
  } else {
    day = '<span class="week">' + day + "</span>";
  }
  const str = month + "/" + date + " (" + day + ")";

  return str;
};



</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">出走種牡馬リスト</h1>
    </section>
    <section class="layout-section -tab">
      <div
          v-if="narFlag===0"
          class="nav-tab -date -jra" :class="'-fit' + DORTab">
        <ul>
          <li
              v-for="(dor, index) in DORArray"
              :key="index"
              :class="setDOR === dor ? '-current' : ''"
          >
            <div
                @click="changeDOR(dor)"
                class="label"
                v-html="getDisplayDateStrForMain(dor)"
            ></div>
          </li>
        </ul>
      </div>
      <div v-if="narFlag === 1" class="layout-title">
        <h2 class="heading-circle -padding">{{ toDay }}  (<span v-html="thisWeek" />)</h2>
      </div>
      <div
          v-if="narFlag === 1"
          id="scrollGrid"
          class="nav-tab -scroll -raceinfo"
          :class="scrollGuid && displayScroll ? '' : '-moving'"
      >

        <ul>
          <li
              :class="targetRacetrackCd === '' ? '-current' : ''"
              @click="changeTrack('', '')"
          >
            <div class="label">
              <div class="place">
                全て
              </div>
            </div>
          </li>
          <li
              v-for="(track, index) in todayRaceTrackCd"
              :key="index"
              :class="track.RacetrackCd === targetRacetrackCd ? '-current' : ''"
              @click="changeTrack(track.RacetrackCd, track.Racetrack)"
          >
            <div class="label">
              <div class="place">
                {{ track.Racetrack }}
                <i v-if="track.isNighter" class="icon-time -night"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="nav-tab -raceinfo -jra" :class="'-fit' + PlaceTab">
        <ul>
          <li
              :class="setRacetrack === '' ? '-current' : ''"
          >
            <div @click="changeTrackJRA('')" class="label">
              <div class="place">全て</div>
            </div>
          </li>
          <li
              v-for="(place, index) in placeArray[setDOR]"
              :key="index"
              :class="setRacetrack === place.RacetrackCd ? '-current' : ''"
          >
            <div @click="changeTrackJRA(place.RacetrackCd)" class="label">
              <div class="place">{{ place.Racetrack }}</div>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class="layout-section">
      <div class="inner">
        <table class="table-grid -middle  -padding -stallion" v-if="houseList.length > 0">
          <thead>
          <tr class="-center">
            <th>種牡馬名</th>
            <th>頭数</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="house of houseList">
            <td class="-horse">
              <router-link :to="`/data/stud?BreedingNum=${house.breedingNum}&tab=race`">
                {{ house.houseName }}
              </router-link>
            </td>
            <td class="-count -center">{{ house.count }}頭</td>
          </tr>
          </tbody>
        </table>
        <p v-else-if="loadedPage" class="p-message -hasheight">出走産駒はいません。</p>
      </div>
    </section>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>


<style scoped>

</style>