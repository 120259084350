<script setup>
import { ref, onMounted } from "vue";
import { isNar } from "../../assets/js/common";

const NARFLAG = ref(0);

onMounted(() => {
  NARFLAG.value = isNar()
});

</script>

<style lang="scss" src="../../assets/css/race/style.scss" scoped></style>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">出馬表の見方</h1>
      <div class="info">
        <a href="javascript:history.back(-1);" class="btn-basic -prev">戻る</a>
      </div>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner">
          <h2 class="heading-circle">レース情報</h2>
          <div class="container-help">
            <div class="image">
              <img :src="`/assets/images/race/help/${NARFLAG ? 'nar' : 'jra'}/title.jpg`" alt="" />
            </div>
            <div class="explain">
              <ul class="list-circlenumber" v-if="NARFLAG">
                <li>開催日・開催競馬場・レース番号</li>
                <li>レース確定済</li>
                <li>レース名</li>
                <li>交流重賞グレード</li>
                <li>レース移動</li>
                <li>発走時間</li>
                <li>レースデータ/コースデータ詳細リンク</li>
                <li>トラック条件、距離</li>
                <li>賞金</li>
                <li>出走頭数</li>
                <li>天候/馬場状態</li>
                <li>負担重量…ハンデの場合は詳細リンク</li>
              </ul>
              <ul class="list-circlenumber" v-else>
                <li>開催日・開催競馬場・レース番号</li>
                <li>レース確定済</li>
                <li>レース名</li>
                <li>重賞レースグレード</li>
                <li>WIN5対象レース</li>
                <li>レース条件</li>
                <li>レース移動</li>
                <li>発走時間</li>
                <li>レースデータ/コースデータ詳細リンク</li>
                <li>トラック条件、距離</li>
                <li>本賞金</li>
                <li>出走頭数</li>
                <li>天候/馬場状態</li>
                <li>負担重量…ハンデの場合は詳細リンク</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="inner">
          <h2 class="heading-circle">出馬表</h2>
          <div class="container-help">
            <div class="image">
              <img :src="`/assets/images/race/help/${NARFLAG ? 'nar' : 'jra'}/list.jpg`" alt="" />
            </div>
            <div class="explain">
              <ul class="list-circlenumber" v-if="NARFLAG">
                <li>馬番（背景色は枠番）</li>
                <li>my印…自分の予想をタップで入力可能</li>
                <li>単勝オッズ・人気順</li>
                <li>父馬名</li>
                <li>馬名</li>
                <li>母馬名（母父馬名）</li>
                <li>性齢/毛色</li>
                <li>脚質</li>
                <li>馬体重（増減）</li>
                <li>レース間隔</li>
                <li>騎手名</li>
                <li>斤量(★:4kg減 ▲:3kg減 △◇:2kg減 ☆:1kg減)</li>
                <li>
                  勝負服<br /><span class="_caption"
                    >※実際の勝負服と異なる場合があります</span
                  >
                </li>
                <li>調教師名［所属］</li>
                <li>馬主名</li>
              </ul>
              <ul class="list-circlenumber" v-else>
                <li>馬番（背景色は枠番）</li>
                <li>my印…自分の予想をタップで入力可能</li>
                <li>単勝オッズ・人気順</li>
                <li>新聞印集計値（競馬新聞6紙の◎印を集計して表示しています）</li>
                <li>父馬名</li>
                <li>馬名</li>
                <li>去勢明け初戦</li>
                <li>母馬名（母父馬名）</li>
                <li>脚質</li>
                <li>ブリンカー（初の場合は初心者マーク）</li>
                <li>性齢/毛色</li>
                <li>馬体重（増減）</li>
                <li>レース間隔</li>
                <li>前走から継続騎乗（詳細版のみ）（【初】：初騎乗　【替】前走から乗り替わり）</li>
                <li>騎手名</li>
                <li>斤量(★:4kg減 ▲:3kg減 △◇:2kg減 ☆:1kg減)/勝利数（詳細版のみ）</li>
                <li>調教師名［所属］</li>
                <li>馬主名</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="inner">
          <h2 class="heading-circle">近走成績</h2>
          <div class="container-help">
            <div class="image">
              <img :src="`/assets/images/race/help/${NARFLAG ? 'nar' : 'jra'}/run.jpg`" alt="" />
            </div>
            <div class="explain">
              <ul class="list-circlenumber" v-if="NARFLAG">
                <li>過去出走レース</li>
                <li>確定着順［○:良 □:稍重 ●:重 ■:不良］</li>
                <li>開催日</li>
                <li>開催競馬場</li>
                <li>出走頭数</li>
                <li>馬番</li>
                <li>単勝人気</li>
                <li>レース名</li>
                <li>重賞レースグレード</li>
                <li>トラック条件、距離</li>
                <li>コーナー通過順位</li>
                <li>騎手名・斤量</li>
                <li>馬体重（増減）</li>
                <li>タイム（後半3F）…<i class="icon-record">R</i>はレコード</li>
                <li>1(2)着馬（着差）</li>
              </ul>
              <ul class="list-circlenumber" v-else>
                <li>過去出走レース</li>
                <li>確定着順［○:良 □:稍重 ●:重 ■:不良］</li>
                <li>開催日</li>
                <li>開催競馬場</li>
                <li>出走頭数</li>
                <li>馬番</li>
                <li>単勝人気</li>
                <li>レース名</li>
                <li>レースグレード</li>
                <li>トラック条件、距離</li>
                <li>コーナー通過順位</li>
                <li>騎手名・斤量</li>
                <li>馬体重（増減）</li>
                <li>B：ブリンカー/H：ペース</li>
                <li>タイム（後半3F）…<i class="icon-record">R</i>はレコード</li>
                <li>1(2)着馬（着差）</li>
                <li>上位入線馬の脚質（詳細版のみ）</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
