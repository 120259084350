<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getJSON,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  retrieveCourse,
  SUCCESS
} from "../../assets/js/common";
import {BACKWORDPATH, HOST, JRA_WP_HOST, PATH} from "../../assets/js/define";
import axios from "axios";
import PogHeader from "../../components/pog/PogHeader.vue";

//デバッグ用 APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pog?per_page=1";

const post = reactive([]);
const lists = reactive([]);
const pogNewsList = ref("")
const winNewsList = ref("")
const caption = ref("")

onMounted(() => {
  callRetrieveCourse(function (courseId) {
    axios
      .get(API_URL)
      .then((response) => {
        Object.assign(post, response.data);
        if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
          pogNewsList.value = post[0].acf.ordinary_pog_news
          winNewsList.value = post[0].acf.ordinary_win_news
        } else {
          pogNewsList.value = post[0].acf.member_pog_news
          winNewsList.value = post[0].acf.member_win_news
        }
        document.body.className = "status-loaded";
      })
      .catch((error) => {
        console.log(error);
        document.body.className = "status-loaded";
      });

    const request =
        HOST +
        PATH +
        "Master/getJraHorseMaster.js" +
        BACKWORDPATH
    document.body.className = "";
    getJSON(request, true, function (status, json) {
      if (status === SUCCESS) {
        caption.value = json.caption;
        for (let row of json.lists) {
          if (Number(row._id) === (json.pogYear - 2)) {
            lists.push(
                {
                  title: `${row._id}年産JRA登録馬`
                  , link: `/horselist/detail?slug=${row._id}`
                }
            )
            break
          }
        }
      }
      document.body.className = "status-loaded";
    });
  });
});

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false);
  }
};
</script>

<template>
  <main class="layout-main">
    <PogHeader />
    <section class="layout-section">
      <div class="container-box">
        <div class="inner">
          <h2 class="heading-circle">POG関連ニュース</h2>
          <div v-html="pogNewsList"></div>
        </div>

        <div class="inner">
          <h2 class="heading-circle">勝利馬ニュース</h2>
          <div v-html="winNewsList"></div>
        </div>

        <div class="inner" v-for="(list, index) in lists" :key="index">
          <h2 class="heading-circle">{{ list.title }}</h2>
          <nav class="nav-multi -cols5 -noborder -hasarrow">
            <ul class="list">
              <li><router-link :to="list.link + '&key=a'" class="label">ア行</router-link></li>
              <li><router-link :to="list.link + '&key=ka'" class="label">カ行</router-link></li>
              <li><router-link :to="list.link + '&key=sa'" class="label">サ行</router-link></li>
              <li><router-link :to="list.link + '&key=ta'" class="label">タ行</router-link></li>
              <li><router-link :to="list.link + '&key=na'" class="label">ナ行</router-link></li>
              <li><router-link :to="list.link + '&key=ha'" class="label">ハ行</router-link></li>
              <li><router-link :to="list.link + '&key=ma'" class="label">マ行</router-link></li>
              <li><router-link :to="list.link + '&key=ya'" class="label">ヤ行</router-link></li>
              <li><router-link :to="list.link + '&key=ra'" class="label">ラ行</router-link></li>
              <li><router-link :to="list.link + '&key=wa'" class="label">ワ行</router-link></li>
            </ul>
          </nav>
          <p class="p-caption">{{ caption }}</p>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
