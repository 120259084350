import {
  MULTICHECK,
  MIGRATECHECK,
  CARDCHECK,
  getCookie,
  setCookie,
  MAIL_DETAIL,
  MAIL_JUSHO,
  MAIL_WIN5,
  MAIL_FAVORITE,
  MAIL_POG,
  MAIL_OSHIRASE,
  MAIL_ADD,
  MAIL_ADDRESS,
  PAYTYPE,
  JOINSTATUS,
  MIGRATEDIALOG,
  MULTIDIALOG,
  CARDDIALOG,
  setCookieDay,
} from "../assets/js/common";

export const setData = function (status, json) {
  try {
    if (json != null) {
      setCookie("DEFAULT_TOB", json.defaultTOB);
      setCookie("DEFAULT_RacetrackCd", json.defaultRace);
      setCookie(MAIL_DETAIL, json.detail);
      setCookie(MAIL_JUSHO, json.jusho);
      setCookie(MAIL_WIN5, json.win5);
      setCookie(MAIL_FAVORITE, json.fav);
      setCookie(MAIL_POG, json.pog);
      setCookie(MAIL_OSHIRASE, json.oshirase);
      setCookie(MAIL_ADD, json.add);
      setCookie(MAIL_ADDRESS, json.mailAddress);
      setCookie(PAYTYPE, json.payType);
      setCookie(JOINSTATUS, json.joinStatus);
      setCookie(MIGRATEDIALOG, json.migrateDialog);
      setCookie(MULTIDIALOG, json.multiDialog);
      setCookie(CARDDIALOG, json.cardDialog);
      
      // ファビコンとウェブクリップの設定用エレメント
      const element1 = document.createElement("link");
      element1.setAttribute("rel", "shortcut icon");
      element1.setAttribute("type", "image/vnd.microsoft.icon");
      const element2 = document.createElement("link");
      element2.setAttribute("rel", "apple-touch-icon");

      // 重複入会時の警告ダイアログ表示
      if (json.multiDialog != "0") {

        var multiChecke = getCookie(MULTICHECK);

        if (multiChecke != 1) {
          if (json.multiDialog == 1) {
            
            if (json.migrateDialog == "1" || json.migrateDialog == "2") {
              var migrateChecke = getCookie(MIGRATECHECK);
              if (migrateChecke != 1) {
                setCookie(MIGRATECHECK, 1);
              }
            }

            setCookieDay(MULTICHECK, 1);
          } else if (json.multiDialog == 2) {
            setCookieDay(MULTICHECK, 1);

          } else if (json.multiDialog == 3) {
            setCookieDay(MULTICHECK, 1);

          } else if (json.multiDialog == 4) {
            setCookieDay(MULTICHECK, 1);

          } else if (json.multiDialog == 5) {
            //クレジット重複（エコノミー＋プレミアム＋パック）
            setCookieDay(MULTICHECK, 1);
            
          } else if (json.multiDialog == 6) {
            setCookieDay(MULTICHECK, 1);
            
          }
        }
      } else if (json.migrateDialog != "0") {
        var migrateChecke = getCookie(MIGRATECHECK);
        if (migrateChecke != 1) {
          if (json.migrateDialog == "1") {
            setCookie(MIGRATECHECK, 1);
          } else if (json.migrateDialog == "2") {
            setCookie(MIGRATECHECK, 1);
          }
        }
      }

      // クレジットカード情報の洗い替え確認結果NGダイアログ
      if (json.cardDialog != "0") {
        var cardChecke = getCookie(CARDCHECK);

        if (cardChecke != 1) {
          if (json.cardDialog == 1) {
            setCookieDay(CARDCHECK, 1);
            
          } else {
            setCookieDay(CARDCHECK, 1);
            
          }
        }
      }

      document.getElementsByTagName("head")[0].appendChild(element1);
      document.getElementsByTagName("head")[0].appendChild(element2);
    }
  } catch (e) {
    alert("ユーザの課金コース取得に失敗しました：" + e);
  }
};
