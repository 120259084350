<script setup>
import {onMounted, onServerPrefetch, ref, watch} from "vue";
import {JRA_WP_HOST} from "../../assets/js/define";
import {dayWeek, replaceComma} from "../../assets/js/common";
import axios from "axios";
import {useRoute, useRouter} from 'vue-router'
import {fetch as fetchWin5List} from '../../functions/race/win5-list'
import {useStoreWin5List} from '../../stores/win5-states'
import {storeToRefs} from 'pinia'

const open = ref(false);
const post = ref({});

const storeWin5List = useStoreWin5List();
const {
  win5List,
  years,
  months,
  year,
  month
} = storeToRefs(storeWin5List);

const route = useRoute();
const router = useRouter();
onServerPrefetch(async () => {
  await fetchWin5List(route.query["YearMonth"]);
});

watch(route, async (from, to) => {
  await fetchWin5List(to.query["YearMonth"]);
})

onMounted(async () => {
  document.body.className = "";
  const yearMonth = year.value + month.value
  if (yearMonth !== route.query["YearMonth"]) {
    await fetchWin5List(route.query["YearMonth"]);
  }
  getModalInfo();
  document.body.className = "status-loaded";
});

function getModalInfo() {
  const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=win5guide";
  axios
    .get(API_URL)
    .then((response) => {
      post.value = response.data[0];
    })
    .catch((error) => {
      console.log(error);
    });
}

const showModal = (isOpen) => {
  open.value = !open.value;
  document.body.className = isOpen ? "status-modal status-loaded" : "status-loaded";
}

const setYearMonth = function (year, month) {
  const query = year + month
  router.push({query: {YearMonth: query}})
}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">WIN5</h1>
      <div class="nav">
        <span class="btn-basic -rule -noarrow" @click="showModal(true)">
          ガイド
        </span>
      </div>
    </section>

    <section class="layout-section">
      <div class="layout-title -padding">
        <h2 class="heading-circle -padding">過去の結果一覧</h2>
        <div class="nav">
          <div class="form-select">
            <select @change="setYearMonth(year, month)" v-model="year">
              <option v-for="(selectYear, index) in years" :key="index" :value="selectYear">
                {{ selectYear }}年
              </option>
            </select>
          </div>
          <div class="form-select">
            <select @change="setYearMonth(year, month)" v-model="month">
              <option v-for="(selectMonth, index) in months" :key="index" :value="selectMonth">
                {{ Number(selectMonth) }}月
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="container-box" v-if="win5List.length">
        <div v-for="(data, index) in win5List" :key="index" class="inner">
          <div class="layout-title -marginbottom">
            <h3 class="heading-item">
              {{ Number(data.DOR.substr(0, 2)) }}/{{
                Number(data.DOR.substr(2, 4))
              }}
              (
              <span v-html="dayWeek(year, data.DOR.substr(0, 2), data.DOR.substr(2, 4))">
              </span>
              )
            </h3>
            <div class="nav">
              <router-link
                :to="'/win5/results_more?DOR=' + year + data.DOR"
                class="btn-basic">
                詳細
              </router-link>
            </div>
          </div>

          <table v-for="(horseData, index) in data.SearchHorseList" :key="index" class="table-grid -win5">
            <tbody>
            <tr>
              <th>的中馬番</th>
              <td>{{ horseData.HitHorceNum }}</td>
            </tr>
            <tr>
              <th>払戻金</th>
              <td v-if="horseData.Refund === 0 && horseData.HitVote === 0">
                -
              </td>
              <td v-else>
                {{ replaceComma(parseInt(horseData.Refund, 10)) }}円
              </td>
            </tr>
            <tr>
              <th>的中票数 / 発売票数</th>
              <td>
                {{ horseData.HitVote }}票 /
                {{ replaceComma(data.SellVote) }}票
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <ul class="list-option -white">
          <li>
            出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。
          </li>
        </ul>
      </div>
      <div class="loading-anim">
        <div class="inner">
          <img src="/assets/images/common/loading.svg" alt="" class="loading"/>
        </div>
      </div>
    </section>

    <teleport to="body" v-if="open">
      <div class="modal-layer"></div>
      <div class="modal-frame" @click="showModal(false)">
        <div class="modal-inner">
          <div class="modal-content">
            <div class="modal-title heading-bar">WIN5 ガイド</div>
            <div class="modal-body">
              <div
                class="body"
                v-if="post.content"
                v-html="post.content.rendered"
              ></div>
            </div>
          </div>

          <span @click="showModal(false)" class="modal-close"></span>
        </div>
      </div>
    </teleport>
  </main>
</template>

<style lang="scss" src="../../assets/css/win5/style.scss" scoped></style>
