export default function () {
  //2つの要素を持つオブジェクトを昇順とする
  let al, ar, bl, br;
  const sortDoubleNum = (field1, field2) => {
    return function (a, b) {
      al = a[field1];
      ar = a[field2];
      bl = b[field1];
      br = b[field2];

      if (al < bl) return -1;
      if (al > bl) return 1;
      if (al == bl) {
        if (ar < br) return -1;
        if (ar > br) return 1;
      }
    };
  };

  // 3つの要素を持つオブジェクトを昇順する
  const sortTrioNum = (field1, field2, field3) => {
    return function (a, b) {
      const al = a[field1];
      const ac = a[field2];
      const ar = a[field3];
      const bl = b[field1];
      const bc = b[field2];
      const br = b[field3];

      if (al < bl) return -1;
      if (al > bl) return 1;
      if (al == bl) {
        if (ac < bc) return -1;
        if (ac > bc) return 1;
        if (ac == bc) {
          if (ar < br) return -1;
          if (ar > br) return 1;
        }
      }
    };
  };
  return {
    sortDoubleNum,
    sortTrioNum,
  };
}