import {ref} from 'vue'
import {defineStore} from 'pinia'

export const useStoreWin5List = defineStore('win5-list', () => {
  const win5List = ref([]);
  const years = ref([]);
  const months = ref([]);
  const year = ref("");
  const month = ref("");

  return {
    win5List,
    years,
    months,
    year,
    month
  }
})

export const useStoreWin5Detail = defineStore('win5-detail', () => {
  const currentTime = ref("");
  const yyyymmdd = ref("");
  const win5DorHtml = ref("");
  const win5DorStr = ref("");
  const resultCount = ref(0);
  const win5DetailData = ref({});
  return {
    currentTime,
    yyyymmdd,
    win5DorHtml,
    win5DorStr,
    resultCount,
    win5DetailData,
  }
})

export default {
  useStoreWin5List,
  useStoreWin5Detail,
}