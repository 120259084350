<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import { HOST, JRA_WP_HOST } from "../../assets/js/define";
import axios from "axios";
import {getUrlVars, isSugotoku} from "../../assets/js/common";

const API_URL = JRA_WP_HOST + "wp-json/wp/v2/sale/";

const backToListLink = ref("");
const isSugotokuUser = ref(false)

onMounted(() => {
  document.body.className = "";
  isSugotokuUser.value = isSugotoku()
  getId();
  const request_url = API_URL + id;
  axios
    .get(request_url)
    .then((response) => {
      document.title = response.data.title.rendered;
      processArticle(response.data);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});

onUpdated(() => {
  createSnsLink();
  backToListLink.value = HOST + "sales/list?slug=" + article.slug;
  document.body.className = "status-loaded";
});

const article = reactive({});
const processArticle = (item) => {
  let year = item.modified.substr(0, 4);
  let month = item.modified.substr(5, 2);
  let day = item.modified.substr(8, 2);
  let time = item.modified.substr(11, 5);
  item.modified = year + "." + month + "." + day + " " + time;
  Object.assign(article, item);
};

let id = "";
const getId = () => {
  const params = getUrlVars();
  id = params.id;
};

const createSnsLink = () => {
  const pageTitleWp = document.title;
  const pageTitleWpEnc = encodeURIComponent(document.title);

  const myUrl = document.URL;
  let myCanonical = myUrl;

  //DEBUG facebook単体テスト用
  //myCanonical = myUrl.replace(HOST, "https://www.winkeiba.jp/");
  const myCanonicalEnc = encodeURIComponent(myCanonical);

  const fbLink =
    "http://www.facebook.com/sharer/sharer.php?u=" +
    myCanonicalEnc +
    "&t=" +
    pageTitleWpEnc;

  const twitterLink =
    "http://twitter.com/intent/tweet?url=" +
    myCanonicalEnc +
    "&text=" +
    pageTitleWpEnc +
    "&via=winkeiba_dm&hashtags=keiba%2Cjra";

  const hatenaLink =
    "http://b.hatena.ne.jp/add?mode=confirm&url=" +
    myCanonicalEnc +
    "&title=" +
    pageTitleWp;

  const lineLink =
    "http://line.naver.jp/R/msg/text/?" + pageTitleWpEnc + " " + myCanonicalEnc;

  let fb = document.getElementById("sns-f");
  fb.href = fbLink;

  let twitter = document.getElementById("sns-t");
  twitter.href = twitterLink;

  let hatena = document.getElementById("sns-h");
  hatena.href = hatenaLink;

  let line = document.getElementById("sns-l");
  line.href = lineLink;
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">{{ article.slug }}セレクトセール特集</h1>
      <div class="nav">
        <a :href="backToListLink" class="btn-basic -prev">一覧へ戻る</a>
      </div>
    </section>

    <section class="layout-section">
      <h2 class="heading-circle -padding -borderbottom">
        {{ article.subtitle }}
      </h2>
      <div class="inner">
        <div class="news-block">
          <div class="header" v-if="article.title">
            <h2 class="heading-copy">
              {{ article.title.rendered }}
            </h2>
            <time class="container-meta">{{ article.modified }} 更新</time>

            <ul class="news-sns" v-if="!isSugotokuUser">
              <li>
                <a href="" id="sns-f" target="_blank"
                  ><img src="/assets/images/common/sns/c/f.svg" alt="facebook"
                /></a>
              </li>
              <li>
                <a href="" id="sns-t" target="_blank"
                  ><img src="/assets/images/common/sns/c/t.svg" alt="twitter"
                /></a>
              </li>
              <li>
                <a href="" id="sns-h" target="_blank"
                  ><img
                    src="/assets/images/common/sns/c/h.svg"
                    alt="hatena blog"
                /></a>
              </li>
              <li>
                <a href="" id="sns-l" target="_blank"
                  ><img src="/assets/images/common/sns/c/l.svg" alt="line"
                /></a>
              </li>
            </ul>
          </div>
          <div v-if="article.content" v-html="article.content.rendered"></div>
        </div>
        <div class="nav-group">
          <a :href="backToListLink" class="btn-basic -prev">一覧へ戻る</a>
        </div>
      </div>
      <div class="loading-anim">
        <div class="inner">
          <img src="/assets/images/common/loading.svg" alt="" class="loading" />
        </div>
      </div>
    </section>
  </main>
</template>
