<script setup>
import { ref, reactive, onMounted } from "vue";
import { setDismissDialog } from "../../assets/js/common";
const { Chart } = process.browser ? require("highcharts-vue") : null;
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts from "highcharts";
HighchartsMore(Highcharts);

let dialogChecked = ref(false);
const emit = defineEmits(["closeDialog"]);
let chartWinOdds = reactive({});
const DOR = ref();
const yesterdayDOR = ref();
const now = ref();
const gradeCd = ref();

const props = defineProps({
  modalOpen: Boolean,
  winOddsGraphData: Object,
  winOddsGraphDate: Object,
  winOddsGraphGradeCd: String,
  winOddsGraphDOR: String,
  winOddsGraphIsNar: Boolean,
  winOddsGraphIsNighter: Boolean,
  winOddsGraphRacetrackCd: String,
});

const modalClose = () => {
  //「今後このダイアログを表示しない」確認
  if (dialogChecked.value) {
    //ダイアログ非表示フラグON
    setDismissDialog();
  }
  emit("closeDialog", false);
};

onMounted(() => {
  //オッズ分析モーダル表示可否判定 JRAはレース当日0時〜、重賞は前日0時〜。NARは10:15〜、ナイターは12:15〜(高知は10:15~)。
  const nowDate = new Date();
  const dorDate = new Date(
    props.winOddsGraphDOR.substr(0, 4) +
      "/" +
      props.winOddsGraphDOR.substr(4, 2) +
      "/" +
      props.winOddsGraphDOR.substr(6, 2) +
      " 00:00:00"
  );
  DOR.value = props.winOddsGraphDOR;
  yesterdayDOR.value =
    dorDate.getFullYear() + ("0" + (dorDate.getMonth() + 1)).slice(-2) + ("0" + (dorDate.getDate() - 1)).slice(-2);
  now.value = nowDate.getFullYear() + ("0" + (nowDate.getMonth() + 1)).slice(-2) + ("0" + nowDate.getDate()).slice(-2) + ("0" + nowDate.getHours()).slice(-2) + ("0" + nowDate.getMinutes()).slice(-2) + ("0" + nowDate.getSeconds()).slice(-2);
  gradeCd.value = props.winOddsGraphGradeCd;

  setGraph(props.winOddsGraphDate, props.winOddsGraphData);
});

//グラフをセットする
const setGraph = (categories, winOddsGraphData) => {
  const graph_unit_w = 100;

  let seriesArray = [];
  let lineColor = "";
  let lastOdds = [];
  for (let i = 0; i < winOddsGraphData.length; i++) {
    lastOdds.push({ umaban: winOddsGraphData[i].umaban, odds: winOddsGraphData[i].odds[winOddsGraphData[i].odds.length -1], name:winOddsGraphData[i].name });
  }
  const sortedOdds = lastOdds.sort(function(a, b) {
    return (a.odds < b.odds) ? -1 : 1;
  });
  const minOdds = sortedOdds[0];
  let maxOdds = 0;
  if(lastOdds.length >= 3){
    maxOdds = sortedOdds[2];
  }
  for (let i = 0; i < winOddsGraphData.length; i++) {
    switch (winOddsGraphData[i].bracketNum) {
      case 1:
        lineColor = "#ccc";
        break;
      case 2:
        lineColor = "#000";
        break;
      case 3:
        lineColor = "#da3232";
        break;
      case 4:
        lineColor = "#116FBF";
        break;
      case 5:
        lineColor = "#f1e647";
        break;
      case 6:
        lineColor = "#228b32";
        break;
      case 7:
        lineColor = "#f1852d";
        break;
      case 8:
        lineColor = "#f6629b";
        break;
      default:
        lineColor = "";
    }
    if (winOddsGraphData[i].umaban === minOdds.umaban && lastOdds.length >= 3 && minOdds.odds > 2) {
      seriesArray.push({
        name: winOddsGraphData[i].umaban + "." + winOddsGraphData[i].name,
        data: winOddsGraphData[i].odds,
        type: "line",
        zIndex: 9,
        lineColor: lineColor,
        marker: {
          fillColor: lineColor,
          lineWidth: 2,
          lineColor: lineColor,
        },
        dataLabels: {
          y: 25,
        },
      });
    } else {
      seriesArray.push({
        name: winOddsGraphData[i].umaban + "." + winOddsGraphData[i].name,
        data: winOddsGraphData[i].odds,
        type: "line",
        zIndex: 9,
        lineColor: lineColor,
        marker: {
          fillColor: lineColor,
          lineWidth: 2,
          lineColor: lineColor,
        },
      });
    }
  }

  let chartParam = {
    chart: {
      type: "arearange",
      width: window.innerWidth,
      height: 400,
      //spacing: 30,
      spacingTop: 30,
      spacingLeft: 5,
      spacingRight: 20,
      spacingBottom: 20,
      marginRight: 130,
      //marginLeft:30,
      scrollablePlotArea: {
        minWidth: categories.length * graph_unit_w < 1000 ? 1000 : categories.length * graph_unit_w,
        scrollPositionX: 1,
        opacity: 0,
      },
      events: {
        render() {
          const chart = this;
          const series = chart.series;

          series.forEach((s) => {
            const len = s.data.length;
            const point = s.data[len - 1];
            let yNum = 0;

            if(point.series.name === minOdds.umaban + "." + minOdds.name){
              yNum = -20;
            }
            if(point.series.name === maxOdds.umaban + "." + maxOdds.name){
              yNum = 20;
            }
              chart.renderer
                .text(point.series.name, point.plotX + chart.plotLeft + 20, point.plotY + chart.plotTop - yNum)
                .attr({
                  zIndex: 5,
                })
                .add();
          });
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },

    xAxis: {
      categories: categories,
      labels: {
        rotation: -45,
      },
    },

    yAxis: {
      title: {
        text: null,
      },
      labels: {
        format: "{value:.1f}", // y軸の目盛り幅が値によって動的に変わる
        style: {
          fontSize: "10px",
          marginLeft: "-5px",
        },
      },
      endOnTick: true,
      maxPadding: 0.5,
      allowDecimals: false,
    },
    tooltip: {
      enabled: false,
    },

    plotOptions: {
      series: {
        enableMouseTracking: false,
      },
      line: {
        dataLabels: {
          enabled: true,
          pointFormat: "{point.y:,.1f}",
        },
      },
    },
    legend: {
      enabled: false,
    },

    series: seriesArray,
  };
  Object.assign(chartWinOdds, chartParam);
};

//グラフ表示条件
const isDisplayGraph = () => {
  const jraImportantRace = !props.winOddsGraphIsNar &&
      now.value >= yesterdayDOR.value &&
      ['A','B','C','D'].includes(gradeCd.value)
  const jraRace = !props.winOddsGraphIsNar &&
      now.value >= DOR.value &&
      !['A','B','C','D'].includes(gradeCd.value)
  const narRace = props.winOddsGraphIsNar &&
      (!props.winOddsGraphIsNighter || props.winOddsGraphRacetrackCd === '54') &&
      now.value >= DOR.value + '101500'
  const narNighterRace = props.winOddsGraphIsNar &&
      (props.winOddsGraphIsNighter && props.winOddsGraphRacetrackCd !== '54') &&
      now.value >= DOR.value + '121500'
  return (jraImportantRace || jraRace || narRace || narNighterRace) && props.winOddsGraphData.length
}
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div @click.self="modalClose" v-if="modalOpen" class="modal-frame -oddsranking">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">オッズ分析</div>
              <div class="modal-body">
                <body class="page-oddsranking">
                  <section v-if="isDisplayGraph()" class="layout-section">
                    <div v-if="modalOpen">
                      <Chart v-if="chartWinOdds"
                             class="chart-oddsranking"
                             id="chart-oddsranking"
                             :options="chartWinOdds">
                      </Chart>
                    </div>
                    <div class="inner">
                      <div class="list-raceresult -line -auto">
                        <ul>
                          <li v-for="(horse, index) in winOddsGraphData" :key="index">
                            <div class="umaban">
                              <i
                                :class="[
                                  horse.bracketNum < 10
                                    ? `icon-umaban -waku0${horse.bracketNum}`
                                    : `icon-umaban -waku${horse.bracketNum}`,
                                ]"
                              >
                                {{ horse.umaban }}</i
                              >
                            </div>
                            <div class="horse">{{ horse.name }}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                  <section v-else class="layout-section">
                    <div v-if="modalOpen">
                      <p style="text-align: center; padding:100px 0; font-size: 20px">
                        <b v-if="winOddsGraphIsNar">発売開始15分後からご覧いただけます。</b>
                        <b v-else>レース当日（重賞は前日）深夜0時からご覧いただけます。</b>
                      </p>
                    </div>
                  </section>
                </body>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-content {
  overflow-y: visible;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.layout-section {
  height: 500px !important;
}

.page-oddsranking {
  height: 580px;
  min-height: 580px;
  background-color: #fff;
}

.highcharts-container {
  margin: 0 auto;
}
</style>
