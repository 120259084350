<script setup>
import {ref, onMounted, onServerPrefetch, watch} from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  SUCCESS,
  retrieveCourse,
  getUserId,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  PAYINGYAHOO,
  PAYINGNONE,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  PAYINGECONOMY,
  PAYINGSMARTPASS,
  getUserAgent,
  getRequest,
  isNar,
  getCustomizeOddsType,
  setOddsUrl,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  RACEPATH,
  RACEINFOPATH,
  JOINPATH,
  JOINECPATH,
  DEBUG,
  GLADE_G1,
  GLADE_G2,
  GLADE_G3,
  GLADE_L,
} from "../../assets/js/define";


import WeightTableModal from "../../components/utils/WeightTableModal.vue";
import raceDeadline from "../../functions/odds/race-deadline_countdown";
import moment from "moment";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
import {fetch, fetch as fetchHeader} from "../../functions/race/race-common";
import {storeToRefs} from "pinia";
import { useStoreRaceCommon } from "../../stores/race-common-states";
import { useStoreCommon } from "../../stores/common-states";

const { TimerCount } = raceDeadline();
const props = defineProps({
  inquirePageFlag: Boolean,
  method: String,
  isDPointRace: Boolean,
});

const emit = defineEmits([
  "params",
  "mCourse",
]);

let params = {};
const targetTab = ref("");
const modalOpen = ref(false);
const mCourse = ref("");
let isWebView = ref(false);
const isNewspaperMark = ref(false);
const userId = ref('');
const narFlag = ref(0);
const url = ref("");

const storeCommon = useStoreCommon();
const { host, isNarSite } = storeToRefs(storeCommon);
const storeRaceCommon = useStoreRaceCommon()
const {
  raceInfo,
  formatDay,
  raceTrackCdDisplay,
  raceNumDisplay,
  resultsURL,
  oddsInquireURL,
  weightTableData,
  raceURL,
  courseURL,
  gradeIcon,
  fullgateCount,
  methodVal,
  raceDORVal,
  raceTrackCdVal,
  raceNumVal,
  deadLineVal,
  paramsVal,
  racePrev,
  raceNext,
  oddsExists,
} = storeToRefs(storeRaceCommon);

onServerPrefetch(async () => {
  await fetchHeader(
    route.query["DOR"],
    route.query["RacetrackCd"],
    route.query["RaceNum"],
    isNarSite.value,
    route.path,
  );
  isLoaded.value = true;
});

watch(route, async (from, to) => {
  storeRaceCommon.reset();
  await Promise.all([
    fetchHeader(
      to.query["DOR"],
      to.query["RacetrackCd"],
      to.query["RaceNum"],
      isNarSite.value,
      route.path,
    ),
    doInit(to.query)
  ]);
  isLoaded.value = true;
})

onMounted(async () => {
  isWebView.value = getUserAgent();
  narFlag.value = isNar()
  params = getUrlVars();
  userId.value = getUserId()
  checkLocalStorage()

  const method = route.path.split('/').pop();
  if(
    route.query["DOR"] !== raceDORVal.value ||
    route.query["RacetrackCd"] !== raceTrackCdVal.value ||
    route.query["RaceNum"] !== raceNumVal.value ||
    method !== methodVal?.value
  ){
    storeRaceCommon.reset();
    await fetchHeader(
      route.query["DOR"],
      route.query["RacetrackCd"],
      route.query["RaceNum"],
      isNarSite.value,
      route.path,
    );
  }

  //パラメータチェック
  if (checkUrlVars(params)) {
    //リンクの取得
    url.value = route.path;
    mCourse.value = PAYINGNONE;
    callRetrieveCourse(function (courseId) {
      mCourse.value = courseId;
      emit("mCourse", mCourse.value);

      //ハンデ処理
      if (mCourse.value === PAYINGYAHOO) {
        // Y!プレミアムの場合、ハンデボタンタップでブリッジページへ
        handicapURL.value = HOST + "/member/error/y_premium_info_close";
        handicapFlg.value = true;
      } else if (mCourse.value === PAYINGSUGOTOKU_NONE) {
        // 非スゴ得会員の場合、ハンデボタンタップで会員登録へ
        handicapURL.value = HOST + "joinSugotoku.js";
        handicapFlg.value = true;
      } else if (mCourse.value === PAYINGNONE) {
        // 無課金の場合、ハンデボタンタップで会員登録へ
        handicapURL.value = HOST + JOINPATH;
        handicapFlg.value = true;
      } else {
        handicapFlg.value = false;
      }
      const isPayingNone = mCourse.value === PAYINGNONE;
      const isAnalysisPage = url.value.indexOf("analysis") !== -1;
      const isOddsPage = url.value.indexOf("odds") !== -1;
      const isNotWebView = !isWebView.value;

      if ((isPayingNone && isAnalysisPage) || (isPayingNone && isNotWebView && isOddsPage)) {
        router.push(`/${JOINPATH}`);
      }
      else {
        doInit(params);
      }
    });
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
    // TODO 例外が発生した時にローディングが止まるように実装し直す　小林　2023-06-05
    isLoaded.value = true;
  }
});
const checkLocalStorage = () => {
  const today = moment().format('YYYYMMDD')
  for (const key in localStorage) {
    if (key.indexOf('ipat') !== 0 && key.indexOf('bet') !== 0) {
      if (key.indexOf(today) === -1) localStorage.removeItem(key)
    }
  }
}
const doInit = async (params) => {
  const pathArray = route.path.split('/').slice(-2);
  targetTab.value = pathArray[0] === 'odds'
    ? pathArray[0]
    : pathArray[1] ?? 'card';
  //レースヘッダー系共通設定
  await setRaceTabLink(params);

  // オッズ照合ページでレース確定の場合レース確定ページに遷移
  if (targetTab.value === 'odds' &&
    pathArray[1] === 'inquire' &&
    raceInfo.value['Status'] === '1' &&
    raceInfo.value['IsRaceResult']
  ) {
    await router.push(resultsURL.value);
  }
  isLoaded.value = true;
};

let cardURL = ref("");
let oddsURL = ref("");
let marksURL = ref("");
let analysisURL = ref("");
let defaultTOB = ref(1)

//レース基本情報取得
let isLoaded = ref(false);
const handicapURL = ref("");
const handicapFlg = ref(false);

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false);
  }
};

const premiumFlg = ref(false);
async function setRaceTabLink(params) {
  try {
    //データがない場合の処理
    // レースヘッダに「このレースはありません」の表記
    //タブは押下できないようにする
    if (raceInfo.value) {
      cardURL.value =
          "/race/card" +
          "?DOR=" +
          params["DOR"] +
          "&RacetrackCd=" +
          params["RacetrackCd"] +
          "&RaceNum=" +
          params["RaceNum"];
    }

    //コース分岐
    if (mCourse.value === PAYINGNONE) {
      if(isWebView.value) {
        setTOBUrl(params["DOR"], params["RacetrackCd"], params["RaceNum"]);
      }else{
        oddsURL.value = HOST + JOINPATH;
      }
      analysisURL.value = HOST + JOINPATH;
      marksURL.value = HOST + JOINPATH;
      premiumFlg.value = false;
    } else if (mCourse.value === PAYINGSUGOTOKU_NONE) {
      oddsURL.value = HOST + "joinSugotoku.js";
      analysisURL.value = HOST + "joinSugotoku.js";
      marksURL.value = HOST + "joinSugotoku.js";
      premiumFlg.value = false;
    } else if (
      mCourse.value === PAYINGPREMIUM ||
      mCourse.value === PAYINGSUGOTOKU ||
      mCourse.value === PAYINGPACK03 ||
      mCourse.value === PAYINGPACK06 ||
      mCourse.value === PAYINGPACK12
    ) {
      defaultTOB.value = await getCustomizeOddsType(userId.value)

      setTOBUrl(params["DOR"], params["RacetrackCd"], params["RaceNum"]); //defaultオッズページの設定
      if (raceInfo.value !== [] || raceInfo.value !== undefined) {
        analysisURL.value =
          "/race/analysis" +
          "?DOR=" +
          params["DOR"] +
          "&RacetrackCd=" +
          params["RacetrackCd"] +
          "&RaceNum=" +
          params["RaceNum"];
      }
      if (isNar() === 0) {
        marksURL.value =
          "/race/marks" +
          "?DOR=" +
          params["DOR"] +
          "&RacetrackCd=" +
          params["RacetrackCd"] +
          "&RaceNum=" +
          params["RaceNum"];
      }
      premiumFlg.value = true;
      isNewspaperMark.value = raceInfo.value.IsNewspaperMark === 1
    } else {
      setTOBUrl(params["DOR"], params["RacetrackCd"], params["RaceNum"]); //defaultオッズページの設定
      if (
        mCourse.value === PAYINGSMARTPASS ||
        mCourse.value === PAYINGECONOMY ||
        mCourse.value === PAYINGAPPPASS
      ) {
        if (raceInfo.value != [] || raceInfo.value != undefined) {
          analysisURL.value =
            "/race/analysis" +
            "?DOR=" +
            params["DOR"] +
            "&RacetrackCd=" +
            params["RacetrackCd"] +
            "&RaceNum=" +
            params["RaceNum"];
        }
        if (isNar() === 0) {
          marksURL.value = HOST + JOINECPATH;
        }
      }
      premiumFlg.value = false;
    }

  } catch (e) {
    if (DEBUG) alert(e);
  }
}

//ログイン情報を見て、defaultオッズページをセットする
const setTOBUrl = async (dor, racetrackCd, raceNum) => {
  let path = await setOddsUrl(defaultTOB.value)
  oddsURL.value =
    "/" +
    RACEPATH +
    "odds/" +
    path +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum;
};

//ハンデ処理
const handicapOpen = () => {
  if (handicapFlg.value) {
    //会員登録誘導リンクが格納されている場合
    location.href = handicapURL.value;
  } else {
    //プレミアム会員の場合は斤量表を表示する
    modalOpen.value = true;
  }
};

//賞金オープン
const prizeFlg = ref(false);
const openPrize = () => {
  if (prizeFlg.value === true) {
    prizeFlg.value = false;
  } else {
    prizeFlg.value = true;
  }
};
//天気アイコン
const weatherClass = (weatherCd) => {
  return weatherCd === '晴'
      ? 'icon-weather -w1'
      : weatherCd === '曇'
          ? 'icon-weather -w2'
          : weatherCd === '雨'
              ? 'icon-weather -w3'
              : weatherCd === '小雨'
                  ? 'icon-weather -w4'
                  : weatherCd === '雪'
                      ? 'icon-weather -w5'
                      : weatherCd === '小雪'
                          ? 'icon-weather -w6'
                          : ''
}

//dポイント 成果送信
const sendCampaignResults = async (isApp) => {
  const request = HOST + PATH + "DPoint/sendCampaignResults.js" + BACKWORDPATH
    + "?userId=" + encodeURIComponent(userId.value)
    + "&isApp=" + isApp
    + "&isWebView=" + (isWebView.value ? '1' : '0')

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      const statusArray = [0, 203]
      if (statusArray.includes(json.status)) {
        router.push({ path: '/dpoint/coupon_list', query: { 'isApp': isApp } });
      } else {
        alert(json.error_message)
      }
    }
  });
}

</script>
<template>
  <section v-if="isLoaded">
    <section v-if="props.inquirePageFlag" class="layout-pageheader -default">
      <h1 class="heading-page">
        オッズ照合
      </h1>
    </section>
    <section v-else class="layout-section">
      <div class="nav-round -raceinfo">
        <div class="inner -separate">
          <div class="-dayplace">
            <span class="day">{{ formatDay }}</span
            ><span class="place">{{ raceTrackCdDisplay }}</span
            ><span class="race">{{ raceNumDisplay }}</span>
          </div>

          <div v-if="raceInfo.DOR" class="-race">
            <router-link v-if="racePrev" :to="racePrev" class="btn-arrow -prev"></router-link>
            <router-link v-if="raceNext" :to="raceNext" class="btn-arrow -next"></router-link>
          </div>
        </div>
      </div>
    </section>

    <section v-if="props.isDPointRace && mCourse === PAYINGSUGOTOKU"
             class="layout-section -forweb">
      <div class="inner" v-if="isWebView">
        <img @click="sendCampaignResults(1)"
             style="cursor: pointer"
             src="/assets/images/dPoint/banner_app.png"
             alt="app_dpoint_banner"/>
      </div>
      <div class="inner">
        <img @click="sendCampaignResults(0)"
             style="cursor: pointer"
             src="/assets/images/dPoint/banner_web.png"
             alt="web_dpoint_banner"/>
      </div>
    </section>

    <section v-if="raceInfo.DOR" class="layout-section">
      <div
        class="container-racedetail"
        :class="
          raceInfo.Status === '2'
            ? '-cancel'
            : raceInfo.IsRaceResult === true
            ? '-kakutei'
            : raceInfo.UnResultFlg === 1
            ? '-mikakutei'
            : ''
        "
      >
        <h1 class="title">
          {{ raceInfo.RaceMainName}}
          <i v-if="gradeIcon" :class="gradeIcon"></i>
          <i v-if="raceInfo.Win5RaceNum > 0" class="icon-race -win5"></i>
          <span v-if="fullgateCount" class="fullgateText">フルゲート{{ fullgateCount }}頭</span>
        </h1>
        <div class="caption">
          <span>
            {{ raceInfo.StartTime.substr(0, 2) }}:{{
              raceInfo.StartTime.substr(2, 2)
            }}
          </span>
          / {{ raceInfo.TrackType }}{{ raceInfo.Distance }}m /
          {{ raceInfo.EntryNum }}頭
          {{ raceInfo.RaceSymbolCd ? `/ ${raceInfo.RaceSymbolCd}` : '' }}
        </div>
        <div class="caption">
          <router-link v-if="raceURL" class="icon-race -race" :to="raceURL">
            レースデータ
          </router-link>
          <router-link v-else-if="courseURL" class="icon-race -course" :to="courseURL">
            コースデータ
          </router-link>
          <i @click="openPrize()" class="icon-race -prize"
             :class="prizeFlg === true ? '-show' : ''"
             v-if="raceInfo.MainEarnings1st">
            <span v-if="narFlag === 0">
              本賞金
            </span>
            <span v-else>
              賞金
            </span>
            <div class="data">
              <table class="table-cell">
                <tbody>
                  <tr>
                    <th>賞金<br /><span class="_caption">(万円)</span></th>
                    <td>
                      {{ raceInfo.MainEarnings1st / 100 }}<br />{{
                        raceInfo.MainEarnings2nd / 100
                      }}<br />{{ raceInfo.MainEarnings3rd / 100 }}<br />{{
                        raceInfo.MainEarnings4th / 100
                      }}<br />{{ raceInfo.MainEarnings5th / 100 }}
                    </td>
                  </tr>
                  <tr>
                    <th>付加賞金<br /><span class="_caption">(万円)</span></th>
                    <td>
                      {{
                        raceInfo.AdditionalEarnings1st
                          ? raceInfo.AdditionalEarnings1st / 100
                          : "-"
                      }}<br />
                      {{
                        raceInfo.AdditionalEarnings2nd
                          ? raceInfo.AdditionalEarnings2nd / 100
                          : "-"
                      }}<br />
                      {{
                        raceInfo.AdditionalEarnings3rd
                          ? raceInfo.AdditionalEarnings3rd / 100
                          : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> </i
          >
          <template v-if="raceInfo.WeatherCd">
            <i class="icon-race -sample">
              <i :class="weatherClass(raceInfo.WeatherCd)"></i>
              {{ raceInfo.WeatherCd || "-" }}/
              <span v-if="raceInfo.TrackType === '芝' || raceInfo.TrackType === '障害'">
                芝{{ raceInfo.TrackConditionCdTurf }}
              </span>
              <span v-if="raceInfo.TrackType === 'ダート' || raceInfo.TrackCd === '52'">
                <span v-if="raceInfo.TrackCd === '52'">・</span>ダ{{ raceInfo.TrackConditionCdDirt }}
              </span>
            </i>
          </template>
          <i
            v-if="raceInfo.WeightTypeCd === '1'"
            @click="handicapOpen()"
            class="icon-race -handicap"
            >ハンデ</i
          ><i v-else-if="raceInfo.WeightTypeCd === '2'" class="icon-race -kg"
            >別定</i
          ><i v-else-if="raceInfo.WeightTypeCd === '3'" class="icon-race -kg"
            >馬齢</i
          ><i v-else-if="raceInfo.WeightTypeCd === '4'" class="icon-race -kg"
            >定量</i
          >
        </div>
      </div>
      <div class="collate-odds"
           v-if="raceInfo.UnResultFlg === 1 && !props.inquirePageFlag">
        <router-link :to="oddsInquireURL" class="btn-basic -wide">オッズ照合</router-link>
      </div>
    </section>
    <section v-if="!raceInfo.DOR">
      <div class="container-racedetail">このレースはありません</div>
    </section>
    <div
      v-if="raceInfo.DOR"
      class="nav-tab"
      :class="narFlag === 0 ? '-fit5' : '-fit4'"
    >
      <ul data-trigger="">
        <li :class="targetTab === 'card' ? '-current' : ''">
          <router-link :to="cardURL" class="label">出馬表</router-link>
        </li>
        <li v-if="oddsExists" :class="targetTab === 'odds' ? '-current' : ''">
          <router-link :to="oddsURL" class="label">オッズ</router-link>
        </li>
        <li v-else class="-inactive">
          <a class="label">オッズ</a>
        </li>
        <li v-if="raceInfo.RacetrackCd !== '33'"
            :class="targetTab === 'analysis' ? '-current' : ''">
          <router-link :to="analysisURL" class="label">データ分析</router-link>
        </li>
        <li v-else class="-inactive">
          <a class="label">データ分析</a>
        </li>
        <li v-if="narFlag === 0 && premiumFlg"
          :class="!isNewspaperMark ? '-inactive' : targetTab === 'marks' ? '-current' : ''">
          <router-link :to="marksURL" class="label">新聞印</router-link>
        </li>
        <li v-else-if="narFlag === 0 && !premiumFlg">
          <router-link :to="marksURL" class="label">
            新聞印
            <i class="icon-premium"></i>
          </router-link>
        </li>
        <li
          v-if="raceInfo.Status === '1' && raceInfo.IsRaceResult === true"
          :class="[targetTab === 'results' ? '-current' : '']"
        >
          <router-link :to="resultsURL" class="label">レース結果</router-link>
        </li>
        <li v-else class="-inactive">
          <a class="label">レース結果</a>
        </li>
      </ul>
    </div>
    <div v-else class="nav-tab -fit4">
      <ul data-trigger="">
        <li class="-current -inactive">
          <span class="label">出馬表</span>
        </li>
        <li class="-inactive">
          <span class="label">オッズ</span>
        </li>
        <li class="-inactive">
          <span class="label">データ分析</span>
        </li>
        <li class="-inactive">
          <span class="label">レース結果</span>
        </li>
      </ul>
    </div>
    <WeightTableModal
      v-if="modalOpen === true"
      v-on:closeDialog="modalOpen = false"
      :modalOpen="modalOpen"
      :weightTableData="weightTableData"
    >
    </WeightTableModal>
  </section>
  <div class="loading-anim">
    <div class="inner">
      <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
</template>
