<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  SUCCESS,
  isDismissDialog,
  isNar,
  isAndroid,
  getUserAgent,
  isSugotoku,
} from "../../../assets/js/common";

import {
  HOST,
  PATH,
  BACKWORDPATH,
  TOB_BRACKET_EXACTA,
  METHOD_NORMAL,
  METHOD_BOX,
} from "../../../assets/js/define";

import raceOdds from "../../../functions/odds/race-odds";
import raceDeadline from "../../../functions/odds/race-deadline_countdown";
import SetBetConfModal from "../../../components/utils/SetBetConfModal.vue";
import {useRoute, useRouter} from "vue-router";

const { setRaceBetByArray, loadOddsWin, retrieveOdds, sortDoubleOdds, oddsClass } = raceOdds();
const { TimerCount } = raceDeadline();

let checkAllJudge = ref(false);
let checkAllJudgeCouple = ref(false);
let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let deadLineJudge = ref(false);
let oddsUpdInfo = ref("");
let isOddsInfo = ref(false);
let loadedPage = ref(false)
let cancelList = reactive([]);
let cancelCoupleList = reactive([]);
const activeSortId = ref("sort_num");
const sortType = ref(3);
const checkedHorse = reactive({});
const router = useRouter();
const isWebView = ref(false);
const isSugotokuMember = ref(false);

let params = {};

const props = defineProps({
  raceDORVal: String,
  raceTrackCdVal: String,
  raceNumVal: String,
  deadLineJudge: Boolean,
});

let time = ref("");

const route = useRoute();
watch(route, (from, to) => {
  clearSelected();
  loadPromisingBet();
  doInit(to.query);
})

onMounted(async () => {
  document.body.className = "";
  params = getUrlVars();
  isWebView.value = getUserAgent();
  isSugotokuMember.value = isSugotoku();
  //パラメータチェック
  if (checkUrlVars(params)) {
    loadPromisingBet()
    await doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});

//チェックリスト関連
let checkList = reactive({});
let checkCoupleList = reactive({});
let checkedSum = ref(0);
let checkStorageArray = reactive([])
let checkCoupleStorageArray = reactive([])
const loadPromisingBet = () => {
  const checkListStorage = localStorage.getItem('bracket_exacta_box' + location.search)
  const checkCoupleListStorage = localStorage.getItem('bracket_exacta_box_couple' + location.search)
  if(checkListStorage) checkStorageArray = JSON.parse(checkListStorage)
  if(checkCoupleListStorage) checkCoupleStorageArray = JSON.parse(checkCoupleListStorage)
}
//チェックボックスアクション時
const checkChange = (num) => {
  if (checkList[num] === true) {
    checkList[num] = false;
    checkStorageArray?.splice(checkStorageArray?.indexOf(String(num)),1)
  } else {
    checkList[num] = true;
    checkStorageArray?.push(String(num))
  }

  localStorage.setItem('bracket_exacta_box' + location.search, JSON.stringify(checkStorageArray))
  checkCount();
};

//同枠チェックボックスアクション時
const checkCoupleChange = (num) => {
  if (checkCoupleList[num] === true) {
    checkCoupleList[num] = false;
    checkCoupleStorageArray?.splice(checkCoupleStorageArray?.indexOf(String(num)),1)
  } else {
    checkCoupleList[num] = true;
    checkCoupleStorageArray?.push(String(num))
  }

  localStorage.setItem('bracket_exacta_box_couple' + location.search, JSON.stringify(checkCoupleStorageArray))
  checkCount();
};

const checkedAll = (type) => {
  if (type === "normal") {
    //通常チェック
    if (checkAllJudge.value == true) {
      for (let i = 0; i < Object.keys(checkList).length; i++) {
        checkList[i] = false;
        checkStorageArray?.splice(checkStorageArray?.indexOf(String(i)),1)
      }
      checkAllJudge.value = false;
    } else {
      for (let i = 0; i < Object.keys(checkList).length; i++) {
        if (cancelList[i] === true) {
          //取消・中止はチェックしない
          checkList[i] = false;
        } else {
          checkList[i] = true;
          if (checkStorageArray?.includes(String(i))) continue
          checkStorageArray?.push(String(i))
        }
      }
      checkAllJudge.value = true;
    }
    localStorage.setItem('bracket_exacta_box' + location.search, JSON.stringify(checkStorageArray))
    checkCount();
  } else {
    //同枠チェック
    if (checkAllJudgeCouple.value == true) {
      for (let i = 0; i < Object.keys(checkCoupleList).length; i++) {
        checkCoupleList[i] = false;
        checkCoupleStorageArray?.splice(checkCoupleStorageArray?.indexOf(String(i)),1)
      }
      checkAllJudgeCouple.value = false;
    } else {
      for (let i = 0; i < Object.keys(checkCoupleList).length; i++) {
        if (cancelCoupleList[i] === false) {
          //取消・中止はチェックしない
          checkCoupleList[i] = false;
        } else {
          checkCoupleList[i] = true;
          if (checkCoupleStorageArray?.includes(String(i))) continue
          checkCoupleStorageArray?.push(String(i))
        }
      }
      checkAllJudgeCouple.value = true;
    }
    localStorage.setItem('bracket_exacta_box_couple' + location.search, JSON.stringify(checkCoupleStorageArray))
    checkCount();
  }
};

// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkList,() => {
  const checkLists = Object.values(checkList)
  let canCheckLists = checkLists
  let cancelArr = []
  cancelList.forEach((item, index) => {
    if (!item) cancelArr.push(index)
  })
  if(cancelArr.length)canCheckLists = checkLists.filter((item, index) => !cancelArr.includes(index))
  if(checkLists.length) checkAllJudge.value = checkLists.find(check => check === false) === undefined
},{immediate:true})
// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkCoupleList,() => {
  const checkLists = Object.values(checkCoupleList)
  let canCheckLists = checkLists
  let cancelArr = []
  cancelCoupleList.forEach((item, index) => {
    if (!item) cancelArr.push(index)
  })
  if(cancelArr.length)canCheckLists = checkLists.filter((item, index) => !cancelArr.includes(index))
  if(checkLists.length) checkAllJudgeCouple.value = canCheckLists.find(check => check === false) === undefined
},{immediate:true})
const checkCount = () => {
  const sums = listCount();
  checkedSum.value = sums[2];
  if (checkedSum.value > 0) {
    // 買い目表示
    setSelected(sums[0], sums[1]);
    isOddsInfo.value = true;
  } else {
    //買い目登録非表示フラグ
    isOddsInfo.value = false;
  }
};

// チェックリストカウント
const listCount = () => {
  let wakucount = 0;
  let addcount = 0;
  let mainChekFlg = "";
  let coupledChekFlg = "";
  let addNum = [];
  for (let i = 0; i < 8; i++) {
    if (checkList[i] === true) {
      if (wakuRow[i] === 1) {
        mainChekFlg += "1";
        coupledChekFlg += "0";
        wakucount++;
      } else if (wakuRow[i] === 2 || wakuRow[i] === 3) {
        mainChekFlg += "1";
        wakucount++;
        if (checkCoupleList[i] === true) {
          coupledChekFlg += "1";
          addNum.push(i);
          addcount++;
        } else {
          coupledChekFlg += "0";
        }
      }
    } else {
      mainChekFlg += "0";
      coupledChekFlg += "0";
    }
  }
  //整形
  for (let j = 9; j <= 18; j++) {
    mainChekFlg += "0";
    coupledChekFlg += "0";
  }
  let sum = 0;
  if (wakucount == 1 && addcount > 0) sum = addcount;
  if (wakucount == 2) sum = 2 + addcount;
  if (wakucount == 3) sum = 6 + addcount;
  if (wakucount == 4) sum = 12 + addcount;
  if (wakucount == 5) sum = 20 + addcount;
  if (wakucount == 6) sum = 30 + addcount;
  if (wakucount == 7) sum = 42 + addcount;
  if (wakucount == 8) sum = 56 + addcount;

  return [mainChekFlg, coupledChekFlg, sum, addNum];
};

// 選択馬番表示
let selectedNum = reactive([]);
let selectObjects = reactive([]);
const same_bracket = ref("");
const setSelected = (mainCheck, coupledCheck) => {
  //選択馬番を表示
  try {
    //ボックスでチェックされた数分繰り返し
    let checkCount = 0;
    selectedNum.splice(0);
    selectObjects.splice(0);
    same_bracket.value = "";
    for (let i = 0; i < 8; i++) {
      let wakuNo = i + 1;
      let mainArgs = mainCheck + "";
      let coupleArgs = coupledCheck + "";
      if (mainArgs.substr(i, 1) == "1") {
        //選択枠順の中
        selectedNum[i] = wakuNo;
        //画面要素を動的に追加
        if (coupleArgs.substr(i, 1) == "1") {
          //同枠選択時
          let odds = oddsJson["Odds" + wakuNo + "_" + wakuNo];
          selectObjects.push({
            horse1: wakuNo,
            horse2: wakuNo,
            odds: odds,
          });

          if (same_bracket.value != "") {
            same_bracket.value += " ";
          }
          same_bracket.value += wakuNo;
          checkCount++;
        }
        for (let j = i + 1; j < 8; j++) {
          let wakuNo2 = j + 1;
          if (mainArgs.substr(j, 1) == "1") {
            selectObjects.push({
              horse1: wakuNo,
              horse2: wakuNo2,
              odds: oddsJson["Odds" + wakuNo + "_" + wakuNo2],
            });
            selectObjects.push({
              horse1: wakuNo2,
              horse2: wakuNo,
              odds: oddsJson["Odds" + wakuNo2 + "_" + wakuNo],
            });
            checkCount++;
          }
        }
      }
    }
    selectObjects.sort(sortDoubleOdds(sortType.value));
    oddsTable.splice(0);
    setOdds();
  } catch (e) {
    console.log(e);
  }
};

const isShowMore = ref(false);
const oddsTable = reactive([]);
const showLimit = 50;

const setOdds = () => {
  //初回は現在表示しているところから、50件分のHTMLを表示
  //もっと見るを押下するごとに+50件表示させる
  const nowOddsTableLength = oddsTable.length;

  for (
    let i = nowOddsTableLength;
    i < selectObjects.length && i < nowOddsTableLength + showLimit;
    i++
  ) {
    oddsTable.push(selectObjects[i]);
  }
  selectObjects.length === oddsTable.length ? isShowMore.value = false : isShowMore.value = true;
};
//ソートを変更
const changeSort = (id) => {
  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_num") {
      if (sortType.value === 3) {
        sortType.value = 4;
      } else {
        sortType.value = 3;
      }
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      if (sortType.value === 5) {
        sortType.value = 6;
      } else {
        sortType.value = 5;
      }
      activeSortId.value = "sort_odds";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを昇順でセットする
    if (id === "sort_num") {
      sortType.value = 3;
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      sortType.value = 5;
      activeSortId.value = "sort_odds";
    }
  }
  checkCount();
};

// horses
let horses = reactive([]);
let oddsJson = reactive({});
const doInit = async (params) => {
  //オッズ情報取得
  retrieveOdds(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum,
    TOB_BRACKET_EXACTA,
    function (result) {
      oddsJson = result;
    }
  );

  //枠単オッズ読み込み
  loadOddsBracketExacta(params.DOR, params.RacetrackCd, params.RaceNum);
};

//レース詳細情報を取得する
let bracketArray = reactive([]);
let oddsInfo = reactive([]);
let wakuRow = reactive([]);
const oddsUpInfo = ref("");
const loadOddsBracketExacta = async (dor, racetrackCd, raceNum, callback) => {
  //振り直したOddsIdで単勝オッズを設定
  const oddsList = await loadOddsWin(dor, racetrackCd, raceNum);
  oddsInfo.splice(0, oddsInfo.length);
  for (let i = 0; i < oddsList.odds.length; i++) {
    oddsInfo.push(oddsList.odds[i]);
  }
  //出馬表から取消・除外などの情報を取得
  //パラメータからAPIコールを生成
  let req =
    HOST +
    PATH +
    "RaceInfo/candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      let intEntryNum = parseInt(json["EntryNum"], 10);
      let entryHorsesArray = json["EntryHorses"];

      let limitHorseNo = [];
      limitHorseNo.push("dummy");
      limitHorseNo.push([0, 1, 0, 0, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 0, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 8]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 9]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 8, 10]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 7, 9, 11]);
      limitHorseNo.push([0, 1, 2, 3, 4, 6, 8, 10, 12]);
      limitHorseNo.push([0, 1, 2, 3, 5, 7, 9, 11, 13]);
      limitHorseNo.push([0, 1, 2, 4, 6, 8, 10, 12, 14]);
      limitHorseNo.push([0, 1, 3, 5, 7, 9, 11, 13, 15]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 14, 16]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 14, 17]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 15, 18]);

      //出頭数から上記の表のどれを使用するか決定
      let limitHorseNoArray = limitHorseNo[intEntryNum];
      let countNum = 0;
      //全馬について
      bracketArray.splice(0, bracketArray.length);
      for (let waku = 1; waku <= 8; waku++) {
        try {
          let isCancel = false;
          let isCouple = true;

          let row = 0;
          //前の枠との差が１頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 1) {
            isCouple = false;
            const horse1 = entryHorsesArray[limitHorseNoArray[waku] - 1];
            const index = horse1['HorseNum'] - 1;
            row = 1;

            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3" || !oddsInfo[index]) {
              //同枠チェック不可
              isCancel = true;
            }
            bracketArray.push({
              waku: waku,
              horseNum: horse1["HorseNum"],
              horseName: horse1["HorseName"],
              isCancel: isCancel,
              isCouple: isCouple,
              rows: row,
              abnormalDiv: horse1["AbnormalDiv"],
            });
          }

          //前の枠との差が２頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 2) {
            let clancelCount = 0;
            let horse1 = entryHorsesArray[limitHorseNoArray[waku - 1]];
            let horse2 = entryHorsesArray[limitHorseNoArray[waku - 1] + 1];
            const index1 = horse1['HorseNum'] - 1;
            const index2 = horse2['HorseNum'] - 1;
            row = 2;
            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3" || !oddsInfo[index1]) {
              clancelCount++;
            }
            if (horse2["AbnormalDiv"] == "1" || horse2["AbnormalDiv"] == "3" || !oddsInfo[index2]) {
              clancelCount++;
            }

            if (clancelCount > 0) {
              //同枠選択チェック不可
              isCouple = false;
              if (clancelCount == 2) {
                //チェック不可
                isCancel = true;
              }
            }

            bracketArray.push({
              waku: waku,
              horseNum: horse1["HorseNum"],
              horseName: horse1["HorseName"],
              isCancel: isCancel,
              isCouple: isCouple,
              rows: row,
              abnormalDiv: horse1["AbnormalDiv"],
            });

            bracketArray.push({
              waku: waku,
              horseNum: horse2["HorseNum"],
              horseName: horse2["HorseName"],
              isCancel: isCancel,
              isCouple: isCouple,
              rows: row,
              abnormalDiv: horse2["AbnormalDiv"],
            });
          }

          //前の枠との差が３頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 3) {
            let clancelCount = 0;
            let horse1 = entryHorsesArray[limitHorseNoArray[waku - 1]];
            let horse2 = entryHorsesArray[limitHorseNoArray[waku - 1] + 1];
            let horse3 = entryHorsesArray[limitHorseNoArray[waku - 1] + 2];
            const index1 = horse1['HorseNum'] - 1;
            const index2 = horse2['HorseNum'] - 1;
            const index3 = horse3['HorseNum'] - 1;
            row = 3;
            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3" || !oddsInfo[index1]) {
              clancelCount++;
            }
            if (horse2["AbnormalDiv"] == "1" || horse2["AbnormalDiv"] == "3" || !oddsInfo[index2]) {
              clancelCount++;
            }
            if (horse3["AbnormalDiv"] == "1" || horse3["AbnormalDiv"] == "3" || !oddsInfo[index3]) {
              clancelCount++;
            }

            if (clancelCount > 1) {
              //同枠選択チェック不可
              isCouple = false;
              if (clancelCount == 3) {
                //チェック不可
                isCancel = true;
              }
            }

            bracketArray.push({
              waku: waku,
              horseNum: horse1["HorseNum"],
              horseName: horse1["HorseName"],
              isCancel: isCancel,
              isCouple: isCouple,
              rows: row,
              abnormalDiv: horse1["AbnormalDiv"],
            });

            bracketArray.push({
              waku: waku,
              horseNum: horse2["HorseNum"],
              horseName: horse2["HorseName"],
              isCancel: isCancel,
              isCouple: isCouple,
              rows: row,
              abnormalDiv: horse2["AbnormalDiv"],
            });

            bracketArray.push({
              waku: waku,
              horseNum: horse3["HorseNum"],
              horseName: horse3["HorseName"],
              isCancel: isCancel,
              isCouple: isCouple,
              rows: row,
              abnormalDiv: horse3["AbnormalDiv"],
            });
          }
          cancelCoupleList[countNum] = isCouple;
          cancelList[countNum] = isCancel;
          wakuRow[countNum] = row;
          checkList[countNum] = checkStorageArray?.includes(String(countNum)) ? true : false;
          checkCoupleList[countNum] = checkCoupleStorageArray?.includes(String(countNum)) ? true : false;
          countNum++;
        } catch (e) {
          console.log(e);
        }
      }
      checkCount()
    }
  });

  req =
    HOST +
    PATH +
    "Odds/bracketExactaOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      //データは常に１件が正しい。
      if (json.length == 1) {
        const displayData = json[0];
        if (displayData["OddsUpdInfo"] != null)
          oddsUpInfo.value = displayData["OddsUpdInfo"];
      }
    }
  });
};

//買い目登録
const setRaceBetWin = async () => {
  const sums = listCount();
  let openWindow
  if (isDismissDialog() == 1 && !isAndroid() && !getUserAgent() && !isSugotoku()) {
    openWindow = window.open('', '_blank')
  }

  if (sums[2] > 0) {
    let mainCheckFlg = sums[0];
    let setRaceBetParams = getUrlVars();
    // レースヘッダから情報を取得
    if (props.raceDORVal != null) {
      setRaceBetParams["DOR"] = props.raceDORVal;
    }
    if (props.raceTrackCdVal != null) {
      setRaceBetParams["RacetrackCd"] = props.raceTrackCdVal;
    }
    if (props.raceNumVal != null) {
      setRaceBetParams["RaceNum"] = props.raceNumVal;
    }
    setRaceBetBox(mainCheckFlg, setRaceBetParams, function () {
      setRaceBetNormal(sums, mainCheckFlg, setRaceBetParams, function () {
        if (isDismissDialog() != 1) {
          //モーダルウィンドウ表示
          raceBetCheck.value = true;
          modalBetOpen.value = true;
        } else {
          if (isAndroid() || getUserAgent() || isSugotoku()) {
            router.push("/purchase/spat4")
          }
          else {
            openWindow.location.href = HOST + "purchase/spat4"
          }
        }
      })
    })
    checkStorageArray.splice(0)
    checkCoupleStorageArray.splice(0)
    localStorage.removeItem('bracket_exacta_box' + location.search)
    localStorage.removeItem('bracket_exacta_box_couple' + location.search)
  } else {
    alert("2つの枠番をチェックしてください");
  }
};
// 買い目削除
const deleteSelected = () => {
  const result = confirm('枠単/ボックスに対して全ての買い目を削除しますか？')
  if (result) {
    clearStorage()
    clearSelected()
  }
}

const clearStorage = () => {
  localStorage.removeItem('bracket_exacta_box' + location.search)
  localStorage.removeItem('bracket_exacta_box_couple' + location.search)
}

const clearSelected = () => {
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    checkList[i] = false;
  }
  for (let i = 0; i < Object.keys(checkCoupleList).length; i++) {
    checkCoupleList[i] = false;
  }
  checkStorageArray.splice(0)
  checkCoupleStorageArray.splice(0)
  checkAllJudge.value = false
  checkAllJudgeCouple.value = false
  checkCount();

}

const setRaceBetBox = (mainCheckFlg, setRaceBetParams, callback) => {
  const matches = mainCheckFlg.match(/1/g);
  if (matches && matches.length > 1) {
    let betArray = [];
    let doFlgArray = [];
    betArray.push(mainCheckFlg);
    doFlgArray.push(true);
    //順番に登録させるため再帰的に登録
    setRaceBetByArray(
      setRaceBetParams,
      TOB_BRACKET_EXACTA,
      METHOD_BOX,
      betArray,
      doFlgArray,
      function () {
        callback();
      }
    );
  } else {
    callback();
  }
}

const setRaceBetNormal = (sums, mainCheckFlg, setRaceBetParams, callback) => {
  //#5172 coupledChekFlgが同枠込みであった場合は、同枠分を枠単/通常で登録する
  if (sums[3] && sums[3].length > 0) {

    const coupledVote = {
      0: "100000000000000000100000000000000000",
      1: "010000000000000000010000000000000000",
      2: "001000000000000000001000000000000000",
      3: "000100000000000000000100000000000000",
      4: "000010000000000000000010000000000000",
      5: "000001000000000000000001000000000000",
      6: "000000100000000000000000100000000000",
      7: "000000010000000000000000010000000000",
    };
    let coupledBetArray = [];
    let coupledDoFlgArray = [];
    for (let i = 0; i < sums[3].length; i++) {
      coupledBetArray.push(coupledVote[sums[3][i]]);
      coupledDoFlgArray.push(true);
    }
    //順番に登録させるため再帰的に登録
    setRaceBetByArray(
      setRaceBetParams,
      TOB_BRACKET_EXACTA,
      METHOD_NORMAL,
      coupledBetArray,
      coupledDoFlgArray,
      function () {
        callback();
      }
    );
  } else {
    callback();
  }
}
watch(oddsInfo, (val) => {
  loadedPage.value = true
  document.body.className = "status-loaded";
});
</script>

<template>
  <section class="layout-section" v-if="loadedPage">
    <div class="layout-title">
      <h2 class="heading-circle -padding">枠単 / ボックス</h2>
      <div class="nav">
        <span v-if="props.deadLineJudge" class="status">締め切り間近</span>
        <span :class="[oddsUpInfo ? 'icon-kakutei' : '']">{{
            oddsUpInfo
          }}</span>
      </div>
    </div>
    <table class="table-grid -fit -middle -center -odds">
      <thead>
      <tr>
        <th>枠番</th>
        <th>馬番</th>
        <th>馬名</th>
        <th>単オッズ</th>
        <th class="ranking">
            <span class="form-checkbox">
              <label>
                <input
                  type="checkbox"
                  value=""
                  name=""
                  @click="checkedAll('normal')"
                  v-model="checkAllJudge" /><i></i>
              </label>
            </span>
        </th>

        <th class="ranking">
          <div class="caption">同枠選択</div>
          <span class="form-checkbox">
            <label>
              <input
                type="checkbox"
                value=""
                name=""
                @click="checkedAll('couple')"
                v-model="checkAllJudgeCouple" /><i></i>
            </label>
          </span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(bracket, index) in bracketArray"
        :key="index"
        :class="[
            index === 0
              ? bracket.waku === bracketArray[index + 1].waku
                ? '-rows'
                : ''
              : bracket.waku != bracketArray[index - 1].waku
              ? bracket.waku === bracketArray[index + 1].waku
                ? '-rows'
                : ''
              : '',
          ]"
      >
        <td v-if="bracket.rows === 1" :class="'bgcolor-waku0' + bracket.waku">
          {{ bracket.waku }}
        </td>
        <td
          v-else-if="
              (index === 0 && bracket.waku === bracketArray[index + 1].waku) ||
              (index != 0 &&
                bracket.waku != bracketArray[index - 1].waku &&
                bracket.waku === bracketArray[index + 1].waku)
            "
          :class="'bgcolor-waku0' + bracket.waku"
          :rowspan="bracket.rows"
        >
          {{ bracket.waku }}
        </td>

        <td
          :style="[
              bracket.abnormalDiv === '1' || bracket.abnormalDiv === '3' || !oddsInfo[index] ? 'background-color:#ccc' : '',
            ]"
        >
          <i :class="['icon-umaban -waku0' + bracket.waku]">{{
              bracket.horseNum
            }}</i>
        </td>
        <td
          class="name"
          :style="[
              bracket.abnormalDiv === '1' || bracket.abnormalDiv === '3' || !oddsInfo[index] ? 'background-color:#ccc' : '',
            ]"
        >
          {{ bracket.horseName }}
        </td>
        <td
          class="-right"
          :class="[bracket.waku != bracketArray[index + 1] ? '-last' : '']"
          :style="[
              bracket.abnormalDiv === '1'|| bracket.abnormalDiv === '3' || !oddsInfo[index] ? 'background-color:#ccc' : '',
            ]"
        >
          <div v-if="!oddsInfo[index]"
               class="txt-odds -other">
          </div>
          <div class="txt-odds -other"
               v-else-if="bracket.abnormalDiv === '1' || bracket.abnormalDiv === '3'">
            <span v-if="bracket.abnormalDiv === '1'">取消</span>
            <span v-else-if="bracket.abnormalDiv === '3'">除外</span>
          </div>
          <div
            v-else
            class="txt-odds"
            :class="oddsClass(oddsInfo[index])">
            <span>{{ oddsInfo[index] }}</span>
          </div>
        </td>
        <td
          v-if="bracket.rows === 1"
          :rowspan="bracket.rows"
          :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '' ]"
        >
            <span class="form-checkbox">
              <label v-if="!bracket.isCancel">
                <input
                  type="checkbox"
                  @click="checkChange(bracket.waku - 1)"
                  v-model="checkList[bracket.waku - 1]"
                  value=""
                  name=""
                />
                <i></i>
              </label>
            </span>
        </td>
        <td
          v-else-if="
              (index === 0 && bracket.waku === bracketArray[index + 1].waku) ||
              (index != 0 &&
                bracket.waku != bracketArray[index - 1].waku &&
                bracket.waku === bracketArray[index + 1].waku)
            "
          :rowspan="bracket.rows"
          class="-row"
          :style="[
                bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '',
                ]"
          ref="rm"
        >
            <span class="form-checkbox">
              <label v-if="!bracket.isCancel">
                <input type="checkbox"
                       @click="checkChange(bracket.waku - 1)"
                       v-model="checkList[bracket.waku - 1]"
                       value=""
                       name="" />
                <i></i>
              </label>
            </span>
        </td>
        <td
          v-if="bracket.rows === 1"
          :style="[
              bracket.abnormalDiv === '1' || bracket.abnormalDiv === '3' || !oddsInfo[index] ? 'background-color:#ccc' : '',
            ]"
        ></td>
        <td
          v-else-if="
              (index === 0 && bracket.waku === bracketArray[index + 1].waku) ||
              (index != 0 &&
                bracket.waku != bracketArray[index - 1].waku &&
                bracket.waku === bracketArray[index + 1].waku)
            "
          :rowspan="bracket.rows"
          :style="[
              bracket.abnormalDiv === '1' || bracket.abnormalDiv === '3' || bracket.isCouple === false || !oddsInfo[index]
                ? 'background-color:#ccc'
                : '',
            ]"
        >
            <span class="form-checkbox"
            ><label v-if="bracket.isCouple">
                <input
                  type="checkbox"
                  @click="checkCoupleChange(bracket.waku - 1)"
                  v-model="checkCoupleList[bracket.waku - 1]"
                  value=""
                  name="" />
                <i></i
                ></label>
            </span>
        </td>
      </tr>
      </tbody>
    </table>

    <nav class="nav-group -right">
      <span @click="deleteSelected" class="btn-basic -delete">
        削除
      </span>
    </nav>
    <div class="container-total">
      <dl class="amount">
        <dt>点数合計</dt>
        <dd>
          <span class="num">{{ checkedSum }}</span>点
        </dd>
      </dl>
      <div v-show="isOddsInfo" @click="setRaceBetWin" class="btn-basic">
        買い目登録
      </div>
    </div>

    <div v-show="isOddsInfo" class="container-wrapper -pad">
      <h3 class="heading-bar _center">選択枠番</h3>
      <table class="table-grid -middle -selectednumber">
        <tr>
          <th class="_nowrap">選択</th>
          <td>
            <ul class="list-umaban">
              <li
                v-for="(select, index) in selectedNum"
                :key="index"
                v-show="select"
              >
                <i class="icon-umaban" :class="'-waku0' + select">{{
                    select
                  }}</i>
              </li>
            </ul>
            <p v-if="same_bracket">
              同枠：{{ same_bracket }}<br />※同枠は枠単/通常の買い目として追加
            </p>
          </td>
        </tr>
      </table>

      <div class="icon-next"></div>

      <table class="table-grid -middle -padding -center">
        <thead>
        <tr>
          <th>
            組み合わせ<i
            class="filter-sort"
            :class="sortType === 3 ? '-asc' : sortType === 4 ? '-desc' : ''"
            @click="changeSort('sort_num')"
          ></i>
          </th>
          <th>
            オッズ<i
            class="filter-sort"
            :class="sortType === 5 ? '-asc' : sortType === 6 ? '-desc' : ''"
            @click="changeSort('sort_odds')"
          ></i>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(conbi, index) in oddsTable" :key="index">
          <td>
            <ul class="list-kaimoku -arrow">
              <li>
                <i class="icon-umaban" :class="'-waku0' + conbi.horse1">{{
                    conbi.horse1
                  }}</i>
              </li>
              <li>
                <i class="icon-umaban" :class="'-waku0' + conbi.horse2">{{
                    conbi.horse2
                  }}</i>
              </li>
            </ul>
          </td>
          <td v-if="!conbi.odds" class="txt-odds" :class="oddsClass('---.-')">
            ---.-
          </td>
          <td v-else class="txt-odds" :class="oddsClass(conbi.odds)">
            {{ conbi.odds }}
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="isShowMore" class="nav-group">
        <span class="btn-basic" @click="setOdds"> もっと見る </span>
      </div>

      <div class="container-total -noborder">
        <dl class="amount">
          <dt>点数合計</dt>
          <dd>
            <span class="num">{{ checkedSum }}</span
            >点
          </dd>
        </dl>
        <div v-show="isOddsInfo" @click="setRaceBetWin" class="btn-basic">
          買い目登録
        </div>
      </div>
    </div>

    <div class="container-schedule">
      <router-link to="/purchase/spat4/" v-if="isWebView || isSugotokuMember" class="btn-basic -normal">購入予定一覧</router-link>
      <a href="/purchase/spat4/" v-else target="_blank" class="btn-basic -normal">購入予定一覧</a>
      <p class="_caption">
        ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
      </p>
    </div>
  </section>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
  <SetBetConfModal
    @closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
    :isSpat4="true"
  ></SetBetConfModal>
</template>

<style
  lang="scss"
  src="../../../assets/css/race/odds/style.scss"
  scoped
></style>
