<script setup>
import { ref, onMounted, onUpdated } from "vue";
import {getJSON, isNar} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH } from "../../assets/js/define";
import ButtonList from "../../components/leading/ButtonList.vue";
import RankingList from "../../components/leading/RankingList.vue";
import { useStoreLeadingBms } from "../../stores/leading-states";
import {storeToRefs} from "pinia";

const updateDate = ref('');
const bmsList = ref([]);
const years = ref([]);
const narFlag = ref(false);

const store = useStoreLeadingBms();
const {selectYear} = storeToRefs(store);
const {resetList} = store;

function loadData() {
  document.body.className = "";

  let request =
    HOST + PATH + "Leading/bmsLeadingAPI.js" + BACKWORDPATH + "?";
  request += "RaceYear=" + selectYear.value + "&NarFlag=" + isNar();
  getJSON(request, true, function (status, json) {
    updateDate.value = json[0]?.LeadingUpdDate
    bmsList.value = json[0]?.Leading || [];
  });

  document.body.className = "status-loaded";
}

function resetData() {
  resetList();
  loadData();
}

onMounted(() => {
  narFlag.value = isNar() === 1
  // 1ページに表示する要素数
  const current = new Date();

  let minYear = isNar() ? 2022 : 2003;
  for (let i = minYear; i <= current.getFullYear(); i++) {
    years.value.unshift(i);
  }

  selectYear.value = selectYear.value || current.getFullYear();

  loadData();
});

</script>

<style lang="scss" src="../../assets/css/leading/style.scss" scoped></style>

<template>
  <main class="layout-main">
    <button-list current="bms"></button-list>

    <section class="layout-section">
      <nav class="layout-title">
        <h2 class="heading-circle -padding">母の父</h2>
        <div class="nav">
          <div class="form-select">
            <select v-model="selectYear" @change="resetData">
              <option v-for="(year, index) in years" :key="index" :value="year">
                {{ year }}年
              </option>
            </select>
          </div>
        </div>
      </nav>

      <ranking-list ref="rankingList"
                    :leading-list="bmsList"
                    :is-nar="narFlag"
                    :update-date="updateDate"
                    type="bms">
      </ranking-list>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
