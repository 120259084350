<script>
import {getUserId, isNar} from '../../assets/js/common'
  import { HOST, PATH, BACKWORDPATH } from '../../assets/js/define'
  import axios from 'axios'

  export default {
    data() {
      return {
        inetId: '',
        userId: '',
        pass: '',
        pars: '',
        launchType: 1,
        ipatBet: 0,
      }
    },
    mounted() {
      this.inetId = this.getParam('inetId')
      this.userId = this.getParam('userId')
      this.pars = this.getParam('pars')
      this.ipatBet = this.getParam('info')
      this.launchType = Number(this.getParam('launchType'))
    },
    methods: {
      // IPAT連動
      async voteIPAT(inetId, userId, pass, pars) {
        try {
          const request =
            HOST +
            PATH +
            'Odds/purchase.js' +
            BACKWORDPATH +
            '?inetId=' +
            inetId +
            '&uid=' +
            userId +
            '&pass=' +
            pass +
            '&pars=' +
            pars +
            '&narFlag=' +
            isNar() +
            '&userId=' +
            encodeURIComponent(getUserId());
          let response = await axios
            .get(request)
            .then((res) => res.data)
            .catch((err) => err)
          console.log(response)
          if (response.status == 'NG') {
            alert(response.message)
            return
          }

          const voteUrl =
            isNar() === 1 ? 'https://n.ipat.jra.go.jp/sp/pw_741_i.cgi' : 'https://www.ipat.jra.go.jp/sp/pw_741_i.cgi'
          const loginUrl =
            isNar() === 1 ? 'https://n.ipat.jra.go.jp/sp/pw_732_i.cgi' : 'https://www.ipat.jra.go.jp/sp/pw_732_i.cgi'

          let form = document.createElement('form')
          form.action = this.ipatBet
            ? voteUrl
            : this.launchType !== 3
            ? loginUrl
            : 'https://www.ipat.jra.go.jp/sp/pw_760_i.cgi'
          form.method = 'POST'
          document.body.append(form)
          form.addEventListener('formdata', (e) => {
            var fd = e.formData
            fd.set('g', this.launchType === 3 ? '732' : '740')
            fd.set('mli', response.jra_mli)
            fd.set('uh', response.jra_uh)
            fd.set('inetid', response.jra_inetid)
            fd.set('u', response.jra_u)
            fd.set('nm', response.jra_nm)
            fd.set('zj', response.jra_zj)
            fd.set("uk", "0");

            //50項目まで不足分は0クリア（JRAの場合255項目）
            if (this.launchType === 2) {
              if (isNar() === 1) {
                for (let i = 0; i < 50; i++) {
                  let setName = i + 1 + ''
                  if (i < 9) setName = '0' + setName
                  let setValue = this.ipatBet.substr(i * 30, 29)
                  if (setValue == '00000000000000000000000000000') setValue = '0'
                  fd.set(setName, setValue)
                }
              } else {
                for (let i = 0; i < 255; i++) {
                  let setName = i + 1 + ''
                  setName = '000' + setName
                  setName = setName.slice(-3)

                  if (this.ipatBet) {
                    var setValue = this.ipatBet.substr(i * 28, 27)
                    if (setValue == '000000000000000000000000000') setValue = '0'
                  } else {
                    setValue = '0'
                  }
                  fd.set(setName, setValue)
                }
              }
            }
          })

          form.submit()
        } catch (e) {
          console.log(e)
          alert('予期せぬエラーが起きました。時間を置いて最初からお試しください')
        }
      },
      getParam(name) {
        let url = window.location.href
        name = name.replace(/[\[\]]/g, '\\$&')
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
        let results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return ''
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
      },
    },
  }
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">IPAT登録内容の設定</h1>
    </section>

    <section class="layout-section">
      <div class="inner">
        <p class="_center">IPATログイン情報を入力してください。</p>
        <table class="table-form">
          <tr>
            <th>INET-ID</th>
            <td><input v-model="inetId" type="text" class="form-text" /></td>
          </tr>
          <tr>
            <th>加入者番号</th>
            <td><input v-model="userId" type="text" class="form-text" /></td>
          </tr>
          <tr>
            <th>暗証番号</th>
            <td><input v-model="pass" type="password" class="form-text" pattern="*" /></td>
          </tr>
          <tr>
            <th>P-ARS番号</th>
            <td><input v-model="pars" type="text" class="form-text" /></td>
          </tr>
        </table>
      </div>

      <div class="nav-group -save">
        <span class="btn-basic" @click="voteIPAT(inetId, userId, pass, pars)">
          {{ launchType === 1 ? 'IPATログイン' : launchType === 2 ? 'IPAT投票' : 'WIN5購入へ' }}</span
        >
      </div>
    </section>
  </main>
</template>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>
