<script setup>
import { ref, reactive, onMounted } from "vue";
import { HOST, JRA_WP_HOST } from "../../assets/js/define";
import axios from "axios";

const API_URL = JRA_WP_HOST + "wp-json/wp/v2/feature-cat?per_page=30&_fields=name,slug,id";
const LIST_URL = JRA_WP_HOST + "wp-json/wp/v2/feature?per_page=30&feature-cat=";
let yearList = reactive([]);
const year = ref("");
onMounted(() => {
  document.body.className = "";
  axios
    .get(API_URL)
    .then((response) => {
      Object.assign(yearList, response.data);
      year.value = yearList[0].name;
      initFeatureList(yearList[0].name);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      console.log(error)
      document.body.className = "status-loaded";
    });
});

const initFeatureList = (year) => {
  const selectYear = yearList.find(val => val.name === year)
  const req = LIST_URL + selectYear.id;
  axios
      .get(req)
      .then((response) => {
        processList(response.data);
        document.body.className = "status-loaded";
      })
      .catch((error) => {
        console.log(error)
        document.body.className = "status-loaded";
      });
};
const changeFeatureList = (e) => {
  document.body.className = "";
  year.value = e.target.value;
  initFeatureList(year.value)
};
const eyeCatchList = reactive([]);
const tabList = reactive([]);
const processList = (list) => {
  eyeCatchList.splice(0);
  tabList.splice(0);

  for (let i = 0; i < list.length; i++) {
    if (Object.keys(list[i].eyecatch).length === 0) {
      list[i].urlLink = HOST + "feature/article?slug=" + list[i].slug;
      tabList.push(list[i]);
    }
    if (Object.keys(list[i].eyecatch).length > 0) {
      list[i].urlLink = HOST + "feature/article?slug=" + list[i].slug;
      eyeCatchList.push(list[i]);
    }
  }
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">特集ページ一覧</h1>
      <div class="nav">
        <div class="form-select">
          <select @change="changeFeatureList($event)">
            <option v-for="(year, index) in yearList" :key="index">
              {{ year.name }}
            </option>
          </select>
        </div>
      </div>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner">
          <h2 class="heading-circle">{{ year }} G1特集</h2>
          <ul class="list-feature">
            <li
              class="is-banner"
              v-for="(banner, index) in eyeCatchList"
              :key="index"
            >
              <a :href="banner.urlLink"
                ><img :src="banner.eyecatch.url" alt=""
              /></a>
            </li>
            <li v-for="(tab, index) in tabList" :key="index">
              <a :href="tab.urlLink" class="btn-basic">{{
                tab.title.rendered
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="loading-anim">
        <div class="inner">
          <img src="/assets/images/common/loading.svg" alt="" class="loading" />
        </div>
      </div>
    </section>
  </main>
</template>
