<script setup>
import { ref, reactive, onMounted } from "vue";
import { setDismissDialog, isAccessFromAndroid2 } from "../../assets/js/common";
const { Chart } = process.browser ? require('highcharts-vue') : null;
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts from "highcharts";
HighchartsMore(Highcharts);

let dialogChecked = ref(false);
const emit = defineEmits(["closeDialog"]);
let chartWeight = reactive({});
const renWeightTitle = ref("");

const props = defineProps({
  modalOpen: Boolean,
  targetWeightData: Object,
});

const modalClose = () => {
  //「今後このダイアログを表示しない」確認
  if (dialogChecked.value) {
    //ダイアログ非表示フラグON
    setDismissDialog();
  }
  emit("closeDialog", false);
};

onMounted(() => {
  if (
    (!props.targetWeightData ||
      props.targetWeightData === null ||
      props.targetWeightData === undefined) &&
    props.modalOpen === true
  ) {
    return;
  } else {
    setHorseWeightGraph(
      props.targetWeightData.PedigreeNum,
      props.targetWeightData.dor,
      props.targetWeightData.horseWeight,
      props.targetWeightData.horseName,
      props.targetWeightData.weightArray,
      props.targetWeightData.horse_weight
    );
  }
});

const setHorseWeightGraph = (
  PedigreeNum,
  DOR,
  weight,
  horseName,
  weightArray,
  horseWeight
) => {
  if (weightArray != undefined && weightArray != null) {
    let weightInfo = new Array();
    let ranges = new Array(); //連対時馬体重
    let categories = new Array(); //見出し
    let raceData = new Array(); //出走日とアクシデントコードと馬体重
    let maxWeight = 0;
    let minWeight = 999;
    let isAndroid2 = isAccessFromAndroid2(); //Android2.x系からのアクセス

    for (let j = weightArray.length - 1; j >= 0; j = j - 1) {
      let url = "";
      let icon;
      let weightObj = new Object();
      let pacePlace = weightArray[j].RacePlace;
      if (pacePlace != undefined && pacePlace != null && pacePlace.length > 2) {
        pacePlace = pacePlace.slice(pacePlace.length - 2, pacePlace.length);
      }

      if (isAndroid2) {
        let icon = null;
        let squareColor = "";
        if (pacePlace == 1) {
          icon = { symbol: "square", lineWidth: 8, lineColor: "#AA0000" };
          squareColor = "#AA0000";
        } else if (pacePlace == 2) {
          icon = { symbol: "square", lineWidth: 8, lineColor: "#003399" };
          squareColor = "#003399";
        } else if (pacePlace == 3) {
          icon = { symbol: "square", lineWidth: 8, lineColor: "#7F7F00" };
          squareColor = "#7F7F00";
        }
      } else {
        //let url = HOST + 'shared/img/number/' + pacePlace + '.gif';
        url = "/assets/images/race/graph/" + pacePlace + ".png";
        icon = new Object(url);
        icon.symbol = "url(" + url + ")";
      }
      if (weightArray[j].Weight == undefined) {
        continue;
      }
      if (
        weightArray[j].Weight == 0 ||
        weightArray[j].Weight == 998 ||
        weightArray[j].Weight == 999
      ) {
        weightObj.y = null;
      } else {
        weightObj.y = weightArray[j].Weight;
      }
      weightObj.marker = icon;
      //#21357 start
      //            //#5902 前走除外の場合も馬体重グラフでは取消同様にプロットなし
      //            if (weightArray[j].AccidentCd != "2" && weightArray[j].AccidentCd != "3") {
      if (
        weightArray[j].AccidentCd != "2" &&
        weightArray[j].AccidentCd != "3" &&
        weightArray[j].AccidentCd != "6"
      ) {
        //#21357 end
        weightInfo.push(weightObj);
      } else {
        weightInfo.push(null);
      }
      raceData.push({
        dor: weightArray[j].DOR,
        accidentCd: weightArray[j].AccidentCd,
        weight: weightArray[j].Weight,
        pacePlace: pacePlace,
      });
      if (pacePlace == "01" || pacePlace == "02") {
        if (
          weightArray[j].Weight > maxWeight &&
          weightArray[j].Weight != 998 &&
          weightArray[j].Weight != 999
        ) {
          maxWeight = weightArray[j].Weight;
        }
        if (weightArray[j].Weight < minWeight && weightArray[j].Weight != 0) {
          minWeight = weightArray[j].Weight;
        }
      }
    }
    if (weight == 998 || weight == 999) {
      //計測不能
      weightInfo.push(null);
      raceData.push({ dor: DOR, accidentCd: -1, weight: weight });
    } else if (weight == 0) {
      //計測不能
      weightInfo.push(null);
      raceData.push({ dor: DOR, accidentCd: 1, weight: weight });
    } else if (weight != undefined) {
      weightInfo.push(weight);
      raceData.push({ dor: DOR, accidentCd: -1, weight: weight });
    }

    let count = weightInfo.length - 1;
    if (weight == undefined) {
      count = weightInfo.length;
    }
    for (let j = 0; j < weightInfo.length; j++) {
      if (
        (weight != undefined && j < weightInfo.length - 2) ||
        (weight == undefined && j < weightInfo.length - 1)
      ) {
        categories.push(count + "走前");
        count = count - 1;
      } else if (
        (weight != undefined && j == weightInfo.length - 2) ||
        (weight == undefined && j == weightInfo.length - 1)
      ) {
        categories.push("前走");
      } else if (j == weightInfo.length - 1) {
        categories.push("今回");
      }
    }

    if (maxWeight != 0) {
      for (let l = 0; l < categories.length; l++) {
        ranges.push([categories[l], minWeight, maxWeight]);
      }
    }
    if (isAndroid2) {
      let android2txt =
        "<br>グラフ中の□は、赤1着・青2着・黄3着を、○は着外を表します";
      renWeightTitle.value =
        "連対時馬体重:" + minWeight + "kg - " + maxWeight + "kg";
    } else {
      let android2txt = "";
    }

    setGraph(
      categories,
      weightInfo,
      ranges,
      minWeight,
      maxWeight,
      raceData,
      horseWeight,
      isAndroid2
    );
  }
};

//グラフをセットする
const setGraph = (
  categories,
  info,
  ranges,
  minWeight,
  maxWeight,
  raceData,
  horseWeight,
  isAndroid2
) => {
  let toolTipsParam = {
    crosshairs: true,
    shared: true,
    useHTML: true,
    headerFormat: "",
    pointFormat: "<span>{series.name}: </span>" + "<span>{point.y} </span>",
    footerFormat: "",
    valueSuffix: "kg",
  };
  if (isAndroid2) {
    toolTipsParam = { enabled: false };
  }

  let chartParam = {
    chart: {
      type: "arearange",
      width: 280,
      height: 280,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
      //text: 'コレクターアイテム'
    },

    xAxis: {
      //type: 'datetime'
      categories: categories,
      labels: {
        formatter: function () {
          let labelText = this.value;
          let classText = "";
          let unMeasure = "";
          for (let i = 0; i < categories.length; i++) {
            if (categories[i] == this.value) {
              if (raceData[i].accidentCd == "1") {
                unMeasure = "<br>取消";
              } else if (
                raceData[i].accidentCd == "2" ||
                raceData[i].accidentCd == "3"
              ) {
                unMeasure = "<br>除外";
              } else if (raceData[i].accidentCd == "4") {
                unMeasure = "<br>中止";
              } else if (raceData[i].accidentCd == "5") {
                unMeasure = "<br>失格";
                //#21357 start
              } else if (raceData[i].accidentCd == "6") {
                unMeasure = "<br>地方";
                classText = "fill: red;";
                //#21357 end
              } else if (
                raceData[i].weight == 998 ||
                raceData[i].weight == 999
              ) {
                unMeasure = "<br>計不";
                if (
                  raceData[i].pacePlace &&
                  parseInt(raceData[i].pacePlace, 10) > 0
                ) {
                  unMeasure +=
                    "<br>" + parseInt(raceData[i].pacePlace, 10) + "着";
                }
              } else if (
                (info[i].y <= maxWeight && info[i].y >= minWeight) ||
                (info[i] <= maxWeight && info[i] >= minWeight)
              ) {
                classText = "fill: red;";
              }
              if (raceData[i - 1] != undefined && raceData[i] != undefined) {
                let preDor = new Date(
                  raceData[i - 1].dor.slice(0, 4) +
                    "/" +
                    raceData[i - 1].dor.slice(4, 6) +
                    "/" +
                    raceData[i - 1].dor.slice(6, 8)
                );
                let thisDor = new Date(
                  raceData[i].dor.slice(0, 4) +
                    "/" +
                    raceData[i].dor.slice(4, 6) +
                    "/" +
                    raceData[i].dor.slice(6, 8)
                );
                let times = parseInt(
                  (thisDor.getTime() - preDor.getTime()) / 1000 / 60 / 60 / 24,
                  10
                );
                //#21357 start
                //if (times > 90) {
                if (times > 90 && raceData[i].accidentCd != "6") {
                  //#21357 end
                  classText +=
                    "text-decoration: underline;font-weight:bold;font-size:110%";
                }
              }
            }
          }
          return (
            '<span style="' +
            classText +
            '">' +
            this.value +
            unMeasure +
            "</span>"
          );
        },
        style: {
          fontSize: "12px",
        },
      },
    },

    yAxis: {
      endOnTick: true,
      maxPadding: 0.5,
      title: {
        text: null,
      },
      allowDecimals: false,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    tooltip: toolTipsParam,
    plotOptions: {
      line: {
        dataLabels: { enabled: false },
        enableMouseTracking: false,
      },
    },

    legend: {
      enabled: false,
    },

    series: [
      {
        name: "馬体重",
        //data: averages,
        data: info,
        type: "line",
        zIndex: 9,
        marker: {
          fillColor: "white",
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[0],
        },
      },
      {
        name: "連対時馬体重",
        data: ranges,
        type: "arearange",
        lineWidth: 0,
        linkedTo: ":previous",
        color: Highcharts.getOptions().colors[0],
        fillOpacity: 0.3,
        zIndex: 0,
      },
    ],
  };
  Object.assign(chartWeight, chartParam);
};
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div
          @click.self="modalClose"
          v-if="modalOpen"
          class="modal-frame -weightgraph"
        >
          <div class="modal-inner">
            <div class="modal-content">
              <div
                v-if="renWeightTitle"
                class="modal-title heading-bar"
                style="display: block"
              >
                {{ renWeightTitle }}
              </div>
              <div class="modal-title heading-bar" style="display: block">
                馬体重推移
              </div>
              <div class="modal-body">
                <body class="page-hourseweight">
                  <section class="layout-section">
                    <div class="graph-header">
                      <h1>
                        <span class="heading-circle" id="horse_weight_name">{{
                          targetWeightData.horseName
                        }}</span
                        ><span class="txtbox" id="horse_weight">{{
                          targetWeightData.horse_weight
                        }}</span>
                      </h1>
                    </div>
                    <div v-if="modalOpen" class="inner">
                      <Chart
                        v-if="chartWeight"
                        class="chart-weight"
                        id="chart-weight"
                        :options="chartWeight"
                      ></Chart>
                      <div class="container-graphnote">
                        <p>
                          下線は出走間隔が3ヶ月以上を示します赤文字は連対時馬体重の範囲内を示します<br />※前走がJRA登録馬としての出走ではない時、馬体重グラフが表示できない場合がございます。
                        </p>
                      </div>
                    </div>
                  </section>
                </body>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-body {
  height: 500px;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.layout-section {
  height: 500px !important;
}

.page-hourseweight {
  height: 500px;
  min-height: 500px;
}

.highcharts-container {
  margin: 0 auto;
}
</style>
