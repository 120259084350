export default function () {
  const TimerCount = (dor, raceTime) => {
    const now = new Date();
    // 対象の日だけに表示
    const nowDateStr =
      now.getFullYear() +
      ("0" + (now.getMonth() + 1)).slice(-2) +
      ("0" + now.getDate()).slice(-2);
    if (dor == nowDateStr) {
      let nowTime = String(now.getHours()) + String(now.getMinutes());
      let lag = parseInt(raceTime, 10) - parseInt(nowTime, 10);
      if (6 > lag && lag > 0) {
        //開始時間から6分以内なら表示
        return true;
      }
    }
    return false;
  };
  return { TimerCount };
}
