<script setup>
import { ref, reactive, onMounted } from "vue";
import { SUCCESS } from "../../assets/js/common";
import { getJSON, isFavorite, FRACEHORSE, prizeToString} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH, RACEINFOPATH } from "../../assets/js/define";

const column = ref("stallionAgedTwo");
const isCurrentColumn = ref("stallionAgedTwo");
let horses = reactive([]);
let date = ref();

onMounted(() => {
  //let self = this;
  // self.NARFLAG = isNar();
  const currentDate = new Date();
  date.value =currentDate.getFullYear() + "年" + ('0' + (currentDate.getMonth() + 1)).slice(-2) + "月" +  ('0' + currentDate.getDate()).slice(-2)+ "日";
  setEarningsRanking("stallionAgedTwo", self);
});

const setEarningsRanking = (horseType) => {
  column.value = horseType;
  isCurrentColumn.value = horseType;
  const request = HOST + PATH + "EarningsRanking/earningsRankingListAPI.js" + BACKWORDPATH + "?HorseType=" + horseType;
  console.log(request);
  document.body.className = "";
  horses.splice(0, horses.length); //配列をリアクティブに初期化
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      try {
        console.log("ジェイソン");
        console.log(JSON.stringify(json));
        if (json != undefined && json != null) {
          if (json[0] != undefined) {
            horses = Object.assign(horses, json);
            let useData;
            for (let i = 0; i < horses.length; i++) {
              useData = horses[i];
              let horseNameUrl = "";
              if (useData.PedigreeNum != undefined) {
                horseNameUrl += HOST + "data/horse?&PedigreeNum=" + useData.PedigreeNum;
              }
              useData.HorseNameUrl = horseNameUrl;
              useData.Earnings = prizeToString(useData.Earnings);
              useData.Amount = prizeToString(useData.Amount);
            }
          }
        }
        // console.log("horses");
        // console.log(JSON.stringify(horses));
      } catch (e) {
        console.log(e);
      }
    }
    document.body.className = "status-loaded";
  });
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">2歳・3歳収得賞金ランキング</h1>
    </section>

    <section class="layout-section -youngranking">
      <div class="inner">
        <p>集計期間　2歳：6月～12月</p>
        <div data-tab='["data-trigger", "data-target"]'>
          <div class="nav-tab -fit3">
            <ul data-trigger="">
              <li @click="setEarningsRanking('stallionAgedTwo')" :class="{ '-current': isCurrentColumn === 'stallionAgedTwo' }">
                <span class="label">2歳牡馬</span>
              </li>
              <li @click="setEarningsRanking('mareAgedTwo')" :class="{ '-current': isCurrentColumn === 'mareAgedTwo' }">
                <span class="label">2歳牝馬</span>
              </li>
              <li
                @click="setEarningsRanking('stallionAgedThree')"
                :class="{ '-current': isCurrentColumn === 'stallionAgedThree' }"
              >
                <span class="label">3歳牡馬</span>
              </li>
              <li @click="setEarningsRanking('mareAgedThree')" :class="{ '-current': isCurrentColumn === 'mareAgedThree' }">
                <span class="label">3歳牝馬</span>
              </li>
            </ul>
          </div>
          <div class="/*container-tabtarget*/" data-target="">
            <div class="item -current" style="">
              <div class="inner">
                <table class="table-grid -middle -center -youngranking">
                  <thead>
                    <tr>
                      <th class="rank">順位</th>
                      <th class="horse">馬名</th>
                      <th class="jockey">調教師</th>
                      <th class="earnings">収得賞金</th>
                      <th class="amount">総賞金</th>
                    </tr>
                  </thead>
                  <tbody v-if="horses.length">
                    <tr v-for="h in horses" :key="h.HorseNum" class="-dart">
                      <td class="rank">{{ h.Rank }}</td>
                      <td class="horse -left">
                        <a :href="h.HorseNameUrl">{{ h.HorseName }}</a>
                      </td>
                      <td class="trainer -left">{{ h.TrainerName }}</td>
                      <td class="earnings -right">{{ h.Earnings }}</td>
                      <td class="amount -right">{{ h.Amount }}</td>
                    </tr>
                  </tbody>
                </table>
                <p class="p-caption -right">{{ date }}現在</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="scss" src="../../assets/css/leading/style.scss" scoped></style>
