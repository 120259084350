<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import {
  encodeString,
  getUserId,
  getJSON,
  SUCCESS,
} from "../../assets/js/common";
import {
  HOST,
  POGAPIPATH,
  BACKWORDPATH,
  JRA_WP_HOST,
} from "../../assets/js/define";
import axios from "axios";
import PogHeader from "../../components/pog/PogHeader.vue";
import pogFunc from "../../functions/pog.js";
const { loadPogUserJudge } = pogFunc();
const moment = require('moment')

const mailLink = ref("");
const winningTitle = ref("");

//POG ランキング + 名前取得
const getPogUserInfo = (userId) => {
  const request =
    HOST +
    POGAPIPATH +
    "getPogUserInfo.js" +
    BACKWORDPATH +
    "?UserID=" +
    userId;
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      if (json != undefined || json != null) {
        if (json.PrizeDiv > 5) {
          location.href = HOST + "pog/mypage"
        }
        winningTitle.value =
          "ランキング" + json.PrizeDiv + "位　" + json.NickName + "様";

        let title = document.getElementById("winning_title");
        title.textContent = winningTitle.value;

        let link = document.getElementById("mail_btn");
        link.href = mailLink.value;
      }
    }
  });
};

//本番用 APIURL
//const API_URL = HOST + "wp-json/wp/v2/pages?slug=winner";
//デバッグ用 APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=winner";

let list = reactive({});
onMounted(() => {
  loadPogUserJudge(() => {
    document.body.className = "";
    const request_url = API_URL;
    axios
      .get(request_url)
      .then((response) => {
        Object.assign(list, response.data[0]);
      })
      .catch((error) => {
      });
  });
});

onUpdated(() => {
  const userId = getUserId();

  //ユーザー情報取得
  getPogUserInfo(userId);

  //ユーザー情報エンコード
  const base64Str = encodeString(userId);
  // POGの昨年度
  const pogLastYear = moment().format('YYYY') - 1;

  mailLink.value =
    "mailto:" +
    `winkeiba_pog@digimerce.com?subject=WIN!%E7%AB%B6%E9%A6%ACPOG${pogLastYear}%E5%85%A5%E8%B3%9E%E8%B3%9E%E5%93%81%E5%BF%9C%E5%8B%9F&body=%e3%83%a6%e3%83%bc%e3%82%b6%e3%83%bc%e5%90%8d%ef%bc%9a%20%0d%0a%e3%81%8a%e5%90%8d%e5%89%8d%20%ef%bc%9a%20%0d%0a%e3%81%94%e4%bd%8f%e6%89%80%20%ef%bc%9a%20%0d%0a%e3%83%a1%e3%83%bc%e3%83%ab%e3%82%a2%e3%83%89%e3%83%ac%e3%82%b9%ef%bc%9a%20%0d%0a%e2%80%bb%e4%bb%a5%e4%b8%8b%e3%81%af%e5%89%8a%e9%99%a4%e3%81%9b%e3%81%9a%e3%81%ab%e3%81%9d%e3%81%ae%e3%81%be%e3%81%be%e3%83%a1%e3%83%bc%e3%83%ab%e3%82%92%e3%81%8a%e9%80%81%e3%82%8a%e3%81%8f%e3%81%a0%e3%81%95%e3%81%84%e3%80%82%0d%0a%20` +
    base64Str;

  document.body.className = "status-loaded";
});
</script>

<template>
  <main class="layout-main">
    <PogHeader />
    <div v-if="list.content" v-html="list.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/pog/style.scss" scoped></style>
