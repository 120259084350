import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStorePageArticle = defineStore('page-article', () => {
  const articleQuery = ref('');
  const title = ref('');
  const content = ref('');
  const format = ref(false);
  const hasArticle = () => {
    return title.value || content.value
  }
  const isSameArticle = (queryString) => {
    return queryString === articleQuery.value
  }

  return { articleQuery, title, content, format, hasArticle, isSameArticle }
})

export default {
  useStorePageArticle,
}