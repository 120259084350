<template>
  <div data-tab='["data-trigger", "data-target"]'>
    <div class="nav-tab -fit3">
      <ul data-trigger="">
        <li :class="{ '-current': tab === 'race' }" @click="tabChange('race')">
          <span class="label">レース</span>
        </li>
        <li :class="{ '-current': tab === 'jockey' }" @click="tabChange('jockey')">
          <span class="label">騎手</span>
        </li>
        <li :class="{ '-current': tab === 'stud' }" @click="tabChange('stud')">
          <span class="label">種牡馬</span>
        </li>
      </ul>
    </div>
    <div class="/*container-tabtarget*/" data-target>
      <div class="item -current">
        <div class="inner">
          <div class="table-scroll -trend">
            <table class="table-grid -middle -center -trend -fit"
                   :class="getTableClass(tab)"
                   v-if="raceInfoArray.length > 0">
              <thead>
              <tr>
                <th>R</th>
                <template v-if="tab !== 'jockey'">
                  <th>距離</th>
                  <th>馬場<br />状態</th>
                </template>
                <template v-if="isRaceTab">
                  <th>決め手</th>
                  <th>勝馬<br />通過順</th>
                  <th>勝馬<br />馬番</th>
                  <th v-if="isJra && !isTop">勝馬<br />人気</th>
                  <th>勝利騎手</th>
                </template>
                <template v-else>
                  <th>1着</th>
                  <th>2着</th>
                  <th>3着</th>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(trend, index) in raceInfoArray"
                  :key="index"
                  :class="trend.trackType">
                <td class="race">
                  {{ trend.raceNum }}
                  <i :class="trend.weatherCd ? 'icon-weather -w' + trend.weatherCd: ''"></i>
                </td>
                <template v-if="!trend.isCancel">
                  <template v-if="tab !== 'jockey'">
                    <td class="distance">
                      {{ trend.distance }}
                    </td>
                    <td class="baba">
                      {{ trend.condition }}
                    </td>
                  </template>

                  <template v-if="isRaceTab">
                    <td class="base">
                      <span>
                        <span v-if="isJra && trend.paceStr !== ''"
                              :class="trend.paceColor">
                          {{ trend.paceStr }}
                        </span>
                        <span v-if="isJra && trend.paceStr !== ''">/</span>
                          {{ trend.clincher }}
                        </span>
                    </td>
                    <td class="passing">
                      {{ trend.corneringOrder }}
                    </td>
                    <td>
                      <i :class="trend.horseNumColor">{{ trend.horseNum }}</i>
                    </td>
                    <td v-if="isJra && !isTop" class="popular">
                      {{ trend.popular }}
                    </td>
                    <td class="jockey">
                      <router-link :to="trend.jockeyNameUrl">
                        <span class="rank" :class="{'-most': trend.nameBold}">
                          {{ trend.jockeyName }}
                        </span>
                      </router-link>
                    </td>
                  </template>
                  <template v-else v-for="trendData in (tab === 'jockey' ? trend.jockeyData : trend.studData)">
                    <td class="jockey">
                      <div v-for="data in trendData">
                        <router-link v-if="data.url" :to="data.url">
                          <span class="rank" :class="{'-most': isBold(data.code, tab)}">
                            {{ data.name }}
                          </span>
                        </router-link>
                        <span v-else class="rank"></span>
                        <span class="rank" :class="{'-most': data.rankBold}">
                        {{ data.rank }}
                      </span>
                      </div>
                    </td>
                  </template>
                </template>
                <template v-else>
                  <td :colspan="calculateColSpan()">中止</td>
                </template>
              </tr>
              </tbody>
            </table>
            <div v-else-if="loadedPage">
              レース傾向はありません。
            </div>
          </div>
        </div>
        <ul class="list-caption -padding" v-if="raceInfoArray.length > 1">
          <li>
            <span v-if="tab === 'stud'">
              2勝以上の種牡馬は太字表記
            </span>
            <span v-else>
              2勝以上の騎手は太字表記
            </span>
          </li>
          <li v-if="!isRaceTab">
            2桁人気は太字表記
          </li>
        </ul>
      </div>
    </div>
  </div>
  <router-link to="/member" v-if="isNonMember" :target="isTop ? '_blank' : ''">
    ※4R以降のレース傾向をご覧頂くには、 月額コースへの会員登録が必要です。
  </router-link>
</template>

<script>

export default {
  props: {
    isJra: Boolean,
    isTop: Boolean,
    raceInfoArray: Array,
    jockeyArray: Array,
    studArray: Array,
    loadedPage: Boolean,
    isNonMember: Boolean
  },
  data: function () {
    return {
      tab: 'race',
      isRaceTab: true,
    }
  },
  methods: {
    calculateColSpan() {
      return !this.isRaceTab ? 5 : this.isJra ? 7 : 6
    },
    tabChange(data) {
      this.tab = data
      this.isRaceTab = data === 'race'
    },
    getTableClass(tab) {
      let className = (tab === 'jockey') ? ' -jockey' : ''

      if (this.isTop) {
        return className + ' -home'
      }

      className += (tab !== 'race') ? ' -padding -fit' : ' -fit'
      return className
    },
    isBold(code, type) {
      const array = type === 'jockey' ? this.jockeyArray : this.studArray
      return array.includes(code)
    }
  }
};
</script>

<style lang="scss" src="../../assets/css/info/style.scss" scoped></style>
