<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">収支管理</h1>
    </section>

    <section class="layout-section">
      <div class="nav-tab -fit">
        <ul>
          <li class="-current">
            <a class="label" href="/balance/">年間別収支データ</a>
          </li>
          <li>
            <a class="label" href="/balance/category/">カテゴリ別データ</a>
          </li>
        </ul>
      </div>
      <div class="container-tabtarget">
        <div class="inner">
          <div class="layout-sub">
            <table class="table-cell">
              <tbody v-for="(data, index) in yearData" :key="index">
                <tr>
                  <th colspan="4" class="-left header">
                    <a class="date" v-bind:href="`${data.hrefTag}`">
                      {{ data.headTitle }}
                      <i class="arrow"></i>
                    </a>
                    <span class="form-checkbox">
                    <label>
                      <input
                          type="checkbox"
                          v-model="delChecked"
                          v-bind:value="`${data.delId}`"
                          name=""
                          v-bind:id="`${data.delId}`"
                      />
                      <i></i>
                    </label>
                  </span>
                  </th>
                </tr>
                <tr>
                  <th>購入額</th>
                  <td class="amount">{{ data.spend ?? 0 }}円</td>
                  <th>払戻額</th>
                  <td class="amount">{{ data.refund ?? 0 }}円</td>
                </tr>
                <tr>
                  <th>回収率</th>
                  <td class="per">{{ data.rate ?? 0 }}%</td>
                  <th>返還金</th>
                  <td class="amount">{{ data.returned ?? 0 }}円</td>
                </tr>
                <tr>
                  <th>収支合計</th>
                  <td
                    v-bind:class="`amount ${
                      parseInt(data.sum) > 0 ? '-plus' : '-minus'
                    }`"
                    colspan="3"
                  >
                    {{ data.sum ?? 0 }}円
                  </td>
                </tr>
              </tbody>
            </table>

            <nav class="nav-group -left">
              <a
                v-on:click="deleteBalanceYearly(delChecked)"
                class="btn-basic -delete"
                >削除</a
              >
            </nav>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import {
  PAYINGSUGOTOKU_NONE,
  PAYINGSUGOTOKU,
  SUCCESS,
  PAYINGNONE,
  getUserId,
} from "../../assets/js/common";
import {
  getJSON,
  retrieveCourse,
  replaceComma,
  recoveryRate,
  sumTotalEx,
  getUrlVars,
  isNar,
} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH, JOINPATH } from "../../assets/js/define";

export default {
  name: "PurchaseYear",
  data() {
    return {
      //パラメータ(固定)
      HOST: HOST,
      PATH: PATH,

      yearData: [],
      delChecked: [],
      courseId: '',
      userId: '',
    };
  },
  methods: {
    /******************************************************************
     *　年間収支削除
     ******************************************************************/
    deleteBalanceYearly(dor) {
      this.deleteCore("1", dor);
    },
    deleteCore(type, dor) {
      document.body.className = "";

      const delList = dor.join("_");
      if (delList.length == "") {
        alert("削除項目がありません。");
        document.body.className = "status-loaded";
        return;
      }

      let request = "";
      if (this.courseId == PAYINGSUGOTOKU || this.courseId == PAYINGSUGOTOKU_NONE) {
        request =
          HOST +
          PATH +
          "Balance/deleteBalance4sg.js" +
          BACKWORDPATH +
          "?userId=" +
          encodeURIComponent(this.userId) +
          "&type=" +
          type +
          "&dors=" +
          delList +
          "&narFlag=" +
          isNar();
      } else {
        request =
          HOST +
          PATH +
          "Balance/deleteBalance.js" +
          BACKWORDPATH +
          "?type=" +
          type +
          "&dors=" +
          delList +
          "&narFlag=" +
          isNar();
      }
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          location.reload();
        }
      });

      document.body.className = "status-loaded";
    },
  },
  mounted() {
    let self = this;

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none");
      }
    }

    callRetrieveCourse(function (courseId, marks, userId) {
      if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
        location.href = HOST + JOINPATH;
      } else {
        self.courseId = courseId
        self.userId = userId || getUserId();
        // パラメータ取得
        let params = getUrlVars();
        let date = new Date();
        let argyear = date.getFullYear();
        let argmonth = date.getMonth() + 1;

        if (params.year) argyear = params.year;
        if (params.month) argmonth = params.month;
        getBalancePurchase(argyear, argmonth);
      }
    });

    /******************************************************************
     *　収支一覧取得
     ******************************************************************/
    function getBalancePurchase(argyear, argmonth, callback) {
      let request = "";
      if (self.courseId == PAYINGSUGOTOKU || self.courseId == PAYINGSUGOTOKU_NONE) {
        request =
          HOST +
          PATH +
          "Balance/getBalanceList4sg.js" +
          BACKWORDPATH +
          "?userId=" +
          encodeURIComponent(self.userId) +
          "&year=" +
          argyear +
          "&month=" +
          argmonth +
          "&narFlag=" +
          isNar();
      } else {
        request =
          HOST +
          PATH +
          "Balance/getBalanceList.js" +
          BACKWORDPATH +
          "?year=" +
          argyear +
          "&month=" +
          argmonth +
          "&narFlag=" +
          isNar();
      }
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          try {
            if (json != undefined && json != null && json.length > 0) {
              let yearData = [];

              for (let i = 0; i < json.length; i++) {
                let data = {};

                let type = json[i].Type;
                let year = json[i].Year;
                let spend = parseInt(json[i].Spend) ?? 0;
                let refund = parseInt(json[i].Refund) ?? 0;
                let returned = parseInt(json[i].Returned) ?? 0;
                let delId = "";
                let headTitle = "";
                let hrefTag = "";

                if (type == 0) {
                  //年の場合
                  delId = year;
                  headTitle = year + "年";
                  hrefTag = HOST + "balance?year=" + year;

                  data.delId = delId;
                  data.hrefTag = hrefTag;
                  data.headTitle = headTitle;
                  data.spend = replaceComma(spend);
                  data.refund = replaceComma(refund);
                  data.rate = replaceComma(recoveryRate(spend, refund));
                  data.returned = replaceComma(returned);
                  data.sum = sumTotalEx(spend, refund, returned) > 0 ? '+' : '';
                  data.sum += replaceComma(sumTotalEx(spend, refund, returned));

                  yearData.push(data);
                }
              }
              self.yearData = yearData;
            }
            if (callback != undefined) {
              callback();
            }
          } catch (e) {
            console.log("getBalancePurchase : " + e);
          }
        }
        document.body.className = "status-loaded";
      });
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/balance/style.scss" scoped></style>
