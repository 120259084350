<script setup>
import { ref } from "vue";
import { onMounted } from "vue";
import {
  COURSEID,
  getCourseId,
  getJSON,
  getUserId,
  SUCCESS,
  setCookie,
  USERID,
  setMailConfigJusho,
  setMailConfigWin5,
  setMailConfigFavorite,
  setMailConfigPog,
  setMailConfigNextTarget,
  setMailConfigOshirase,
  setMailConfigAdd,
  setMailConfigDetail, PAYINGNONE, retrieveCourse,
} from "../../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  DATAPATH,
  POGPATH,
  POGAPIPATH, JOINPATH,
} from "../../../assets/js/define";

import CancelEmailNortification from "../../../components/utils/CancelEmailNotification.vue";

const jusho = ref("2");
const win5 = ref("2");
const fav = ref("2");
const pog = ref("2");
const nextTarget = ref("2");
const news = ref("2");
const ad = ref("2");
const detail = ref("2");
const checkedJusho = ref(false);
const checkedWin5 = ref(false);
const checkedFav = ref(false);
const checkedPog = ref(false);
const checkedNextTarget = ref(false);
const checkedNews = ref(false);
const checkedAd = ref(false);
const checkedDetail = ref(false);

//ログイン時 0
//未ログイン時 1
//メールアドレス削除済み 2
const pageFlag = ref("");

//TOPページへのリンク
const topPageLink = HOST;
const address = ref("");

//メールアドレス変更ページへのリンク
const changeAddressLink = HOST + "support/mail/change";

//モーダル
const modalOpen = ref(false);

//プレミアム会員分岐
const isPremium = ref(false);

//現在登録中のメールアドレスなど各設定値を記述
function setMailConfigs() {
  document.body.className = "";

  //ログイン情報取得
  let request = HOST + PATH + "getLoginInfo.js" + BACKWORDPATH;

  getJSON(request, true, function (status, json) {
    //controlLoading(loadingId, HIDDEN);
    if (status === SUCCESS && json != undefined) {
      let result = json;
      const userId = result.userId;

      if (
        userId != undefined &&
        userId != null &&
        userId != "" &&
        result.mailAddress != undefined &&
        result.mailAddress != null &&
        result.mailAddress != ""
      ) {
        pageFlag.value = 0;

        //ユーザID
        setCookie(USERID, userId);

        //コースID
        let courseId = result.courseId;
        setCookie(COURSEID, courseId);

        //登録アドレスの取得
        address.value = result.mailAddress;

        //今週の重賞レース
        if (result.jusho === "1") {
          checkedJusho.value = true;
          jusho.value = "1"
        }
        setMailConfigJusho(result.jusho);
        //今週のWIN5対象レース
        if (result.win5 === "1") {
          checkedWin5.value = true;
          win5.value = "1"
        }
        setMailConfigWin5(result.win5);
        //お気に入り登録場出走
        if (result.fav === "1") {
          checkedFav.value = true;
          fav.value = "1"
        }
        setMailConfigFavorite(result.fav);
        //POG登録場出走
        if (result.pog === "1") {
          checkedPog.value = true;
          pog.value = "1"
        }
        setMailConfigPog(result.pog);
        //次走狙い馬出走
        if (result.nextTarget === "1") {
          checkedNextTarget.value = true;
          nextTarget.value = "1"
        }
        setMailConfigNextTarget(result.nextTarget);
        //WIN!競馬からのお知らせ
        if (result.oshirase === "1") {
          checkedNews.value = true;
          news.value = "1"
        }
        setMailConfigOshirase(result.oshirase);
        //広告付きの通知を受け取る
        if (result.add === "1") {
          checkedAd.value = true;
          ad.value = "1"
        }
        setMailConfigAdd(result.add);
        //詳細内容配信 プレミアムユーザ以外は押下負荷
        if (
          courseId === "2" ||
          courseId === "3" ||
          courseId === "6" ||
          courseId === "7" ||
          courseId === "11" ||
          courseId === "12" ||
          courseId === "13"
        ) {
          isPremium.value = true;
          if (result.detail === "1") {
            checkedDetail.value = true;
          } else {
            checkedDetail.value = false;
          }
        } else {
          checkedDetail.value = false;
        }
        setMailConfigDetail(result.detail);

        document.body.className = "status-loaded";
      } else if (userId === undefined || userId === "" || userId === null) {
        //未ログイン
        const loginLink = HOST + "support/mail/settings_nologin";
        location.replace(loginLink);
      } else if (
        result.mailAddress === undefined ||
        result.mailAddress === "" ||
        result.mailAddress === null
      ) {
        //メールアドレス削除済み
        const registerLink = HOST + "support/mail/settings_registmail";
        location.replace(registerLink);
      }
    } else {
      alert("設定情報の取得に失敗しました。");

      document.body.className = "status-loaded";
    }
  });
}

//-----------------------------------------------------------------
//  メール配信設定を更新
//-----------------------------------------------------------------
function saveMailConfig(checkedValue, checkedName) {
  document.body.className = "";

  if (checkedName === "jusho") {
    jusho.value = checkedValue === false ? "1" : "2"; //今週の重賞レース
  }
  if (checkedName === "win5") {
    win5.value = checkedValue === false ? "1" : "2"; //今週のWIN5対象レース
  }
  if (checkedName === "fav") {
    fav.value = checkedValue === false ? "1" : "2"; //お気に入り登録場出走
  }
  if (checkedName === "pog") {
    pog.value = checkedValue === false ? "1" : "2"; //POG登録場出走
  }
  if (checkedName === "nextTarget") {
    nextTarget.value = checkedValue === false ? "1" : "2"; //次走狙い馬出走
  }
  if (checkedName === "oshirase") {
    news.value = checkedValue === false ? "1" : "2"; //WIN!競馬からのお知らせ
  }
  if (checkedName === "ad") {
    ad.value = checkedValue === false ? "1" : "2"; //広告付きの通知を受け取る
  }
  if (checkedName === "detail") {
    detail.value = checkedValue === false ? "1" : "2"; //詳細内容配信
  }

  let request =
    HOST +
    PATH +
    "setMailConfigInfo.js" +
    BACKWORDPATH +
    "?" +
    "userId=" +
    encodeURIComponent(getUserId()) +
    "&courseId=" +
    getCourseId() +
    "&type10=" +
    jusho.value +
    "&type11=" +
    jusho.value +
    "&type12=" +
    win5.value +
    "&type13=" +
    win5.value +
    "&type14=" +
    fav.value +
    "&type15=" +
    fav.value +
    "&type16=" +
    pog.value +
    "&type17=" +
    pog.value +
    "&type18=" +
    news.value +
    "&type19=" +
    ad.value +
    "&type20=" +
    detail.value +
    "&type31=" +
    nextTarget.value +
    "&type32=" +
    nextTarget.value;

  getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json.length === 0) {
      //設定を保存
      setMailConfigDetail(detail.value);
      setMailConfigJusho(jusho.value);
      setMailConfigWin5(win5.value);
      setMailConfigFavorite(fav.value);
      setMailConfigPog(pog.value);
      setMailConfigNextTarget(nextTarget.value);
      setMailConfigOshirase(news.value);

      setMailConfigAdd(ad.value);

      document.body.className = "status-loaded";
    } else {
      alert("設定を変更に失敗しました。時間を置いて再度お試しください。");
      document.body.className = "status-loaded";
    }
  });
}

function cancelMail() {
  document.body.className = "";

  let request =
    HOST +
    PATH +
    "setMailConfigInfo.js" +
    BACKWORDPATH +
    "?" +
    "userId=" +
    encodeURIComponent(getUserId()) +
    "&courseId=" +
    getCourseId() +
    "&type10=2" +
    "&type11=2" +
    "&type12=2" +
    "&type13=2" +
    "&type14=2" +
    "&type15=2" +
    "&type16=2" +
    "&type17=2" +
    "&type18=2" +
    "&type19=2" +
    "&type20=2" +
    "&type31=2" +
    "&type32=2";
  getJSON(request, true, function (status, json) {
    //controlLoading(loadingId, HIDDEN);
    if (status === SUCCESS && json.length === 0) {
      //チェックを外す
      checkedJusho.value = false;
      checkedWin5.value = false;
      checkedFav.value = false;
      checkedPog.value = false;
      checkedNextTarget.value = false;
      checkedNews.value = false;
      checkedAd.value = false;
      checkedDetail.value = false;
      //設定を保存
      setMailConfigDetail("2");
      setMailConfigJusho("2");
      setMailConfigWin5("2");
      setMailConfigFavorite("2");
      setMailConfigPog("2");
      setMailConfigNextTarget("2");
      setMailConfigOshirase("2");
      setMailConfigAdd("2");

      document.body.className = "status-loaded";
    } else {
      alert("メール配信の解除に失敗しました。時間を置いて再度お試しください。");
      document.body.className = "status-loaded";
    }
  });
}

onMounted(() => {
  callRetrieveCourse(function (courseId) {
    if (courseId === PAYINGNONE) {
      location.href = HOST + JOINPATH;
    } else {
      setMailConfigs();
    }
  });
});
const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false);
  }
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">WIN!競馬メール配信 設定</h1>
    </section>
    <section class="layout-section">
      <div v-if="pageFlag === 0" class="container-box">
        <div class="inner">
          <h2 class="heading-circle">メール配信設定</h2>
          <ul class="list-box">
            <li>
              <div class="card-check">
                <dl class="title">
                  <dt>詳細内容配信</dt>
                  <dd>
                    レース結果の詳細を表示します<br />※プレミアムコース会員限定
                  </dd>
                </dl>
                <div class="nav">
                  <span
                    :class="{ '-disable': isPremium === false }"
                    class="form-toggle"
                    ><label
                      ><input
                        @click="saveMailConfig(checkedDetail, 'detail')"
                        v-model="checkedDetail"
                        type="checkbox"
                        name=""
                        value=""
                        checked="" /><i></i></label
                  ></span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="inner">
          <h2 class="heading-circle">メール配信サービス設定</h2>
          <p>メール配信するサービスを選択します</p>
          <ul class="list-box">
            <li>
              <div class="card-check">
                <dl class="title">
                  <dt>今週の重賞レース</dt>
                  <dd>金曜日に重賞の枠順確定を、週末に重賞結果をお知らせ</dd>
                </dl>
                <div class="nav">
                  <span class="form-toggle"
                    ><label
                      ><input
                        @click="saveMailConfig(checkedJusho, 'jusho')"
                        type="checkbox"
                        name=""
                        value=""
                        v-model="checkedJusho" /><i></i></label
                  ></span>
                </div>
              </div>
            </li>
            <li>
              <div class="card-check">
                <dl class="title">
                  <dt>今週のWIN5対象レース</dt>
                  <dd>WIN5の対象レースの確定情報と、結果をお知らせ</dd>
                </dl>
                <div class="nav">
                  <span class="form-toggle"
                    ><label
                      ><input
                        @click="saveMailConfig(checkedWin5, 'win5')"
                        type="checkbox"
                        name=""
                        value=""
                        v-model="checkedWin5" /><i></i></label
                  ></span>
                </div>
              </div>
            </li>
            <li>
              <div class="card-check">
                <dl class="title">
                  <dt>お気に入り登録馬出走</dt>
                  <dd>お気に入り登録馬の出走確定情報と、出走結果をお知らせ</dd>
                </dl>
                <div class="nav">
                  <span class="form-toggle"
                    ><label
                      ><input
                        @click="saveMailConfig(checkedFav, 'fav')"
                        type="checkbox"
                        name=""
                        value=""
                        v-model="checkedFav" /><i></i></label
                  ></span>
                </div>
              </div>
            </li>
            <li>
              <div class="card-check">
                <dl class="title">
                  <dt>POG登録馬出走</dt>
                  <dd>POG登録馬の出走確定情報と出走結果をお知らせ</dd>
                </dl>
                <div class="nav">
                  <span class="form-toggle"
                    ><label
                      ><input
                        @click="saveMailConfig(checkedPog, 'pog')"
                        type="checkbox"
                        name=""
                        value=""
                        v-model="checkedPog" /><i></i></label
                  ></span>
                </div>
              </div>
            </li>
            <li>
              <div class="card-check">
                <dl class="title">
                  <dt>次走狙い馬</dt>
                  <dd>次走狙い馬の出走確定情報と出走結果をお知らせ</dd>
                </dl>
                <div class="nav">
                  <span class="form-toggle"
                    ><label
                      ><input
                        @click="saveMailConfig(checkedNextTarget, 'nextTarget')"
                        type="checkbox"
                        name=""
                        value=""
                        v-model="checkedNextTarget" /><i></i></label
                  ></span>
                </div>
              </div>
            </li>
            <li>
              <div class="card-check">
                <dl class="title">
                  <dt>WIN!競馬からのお知らせ</dt>
                  <dd>WIN!競馬からメンテナンスや競馬情報をお知らせ</dd>
                </dl>
                <div class="nav">
                  <span class="form-toggle"
                    ><label
                      ><input
                        @click="saveMailConfig(checkedNews, 'oshirase')"
                        type="checkbox"
                        name=""
                        value=""
                        v-model="checkedNews" /><i></i></label
                  ></span>
                </div>
              </div>
            </li>
            <li>
              <div class="card-check">
                <dl class="title">
                  <dt>広告付きの通知を受け取る</dt>
                </dl>
                <div class="nav">
                  <span class="form-toggle"
                    ><label
                      ><input
                        @click="saveMailConfig(checkedAd, 'ad')"
                        type="checkbox"
                        name=""
                        value=""
                        v-model="checkedAd" /><i></i></label
                  ></span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="inner">
          <h2 class="heading-circle">メールアドレス設定</h2>
          <table class="table-grid -padding">
            <tr>
              <th>現在のメールアドレス</th>
              <td>{{ address }}</td>
            </tr>
          </table>
          <div class="nav-group">
            <a
              :href="changeAddressLink"
              class="btn-basic -wide -center -noarrow"
              >メールアドレスを変更する</a
            >
          </div>
        </div>
        <div class="inner">
          <h2 class="heading-circle">通知機能を解除</h2>
          <div class="nav-group">
            <span
              @click="modalOpen = true"
              class="btn-basic -wide -center -noarrow"
              id="quitPush"
              >通知機能を解除する</span
            >
          </div>
          <div class="_right">
            <a href="/support/mail/unsubscribe">メールアドレスの解除はこちら</a>
          </div>
        </div>
      </div>
    </section>
    <CancelEmailNortification
      @cancel="cancelMail()"
      @modal-close="modalOpen = false"
      :modalOpen="modalOpen"
    />
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
