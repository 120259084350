<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getJSON,
  getUlrParams,
  SUCCESS,
} from "../../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  POGPATH,
  DATAPATH,
  SORTHORSENAME,
  SORTSEX,
  SORTAGE,
  SORTCLASS,
} from "../../../assets/js/define";
import PogHeader from "../../../components/pog/PogHeader.vue";
import CommonSearch from "../../../components/pog/search/CommonSearch.vue";
import pogFunc from "../../../functions/pog.js";
const { loadPogUserJudge } = pogFunc();

//検索件数格納
const total = ref();
const trainerData = reactive([]);

// もっと見る
const showLimit = 30;
const count = ref(30);
const ListItem = () => {
  const list = [...trainerData];

  if (list != undefined && list != null && list.length > 0) {
    return list.slice(0, count.value);
  } else {
    return [];
  }
};
const isMore = () => {
  count.value += showLimit;
};

//-----------------------------------------------------------------
//  検索用パラメータの作成
//-----------------------------------------------------------------
//  引数
//  params:パラメータの配列。キーは属性名
//  戻り値
//  param:パラメータの文字列
//-----------------------------------------------------------------

const createParam = function (params) {
  let param = "";
  if (
    params["keyword"] != undefined &&
    params["keyword"] != null &&
    params["keyword"] != ""
  ) {
    param += "keyword" + "=" + params["keyword"];
  } else {
    param += "keyword" + "=";
  }
  if (
    params["searchOption"] != undefined &&
    params["searchOption"] != null &&
    params["searchOption"] != ""
  ) {
    if (param != "") {
      param += "&";
    }
    param += "searchOption" + "=" + params["searchOption"];
  } else {
    if (param != "") {
      param += "&";
    }
    param += "searchOption" + "=0";
  }
  if (
    params["sort"] != undefined &&
    params["sort"] != null &&
    (params["sort"] == String(SORTHORSENAME) ||
      params["sort"] == String(SORTSEX) ||
      params["sort"] == String(SORTAGE) ||
      params["sort"] == String(SORTCLASS))
  ) {
    if (param != "") {
      param += "&";
    }
    param += "sort" + "=" + params["sort"];
  } else {
    if (param != "") {
      param += "&";
    }
    param += "sort" + "=" + 0;
  }

  if (
    params["ScreenDiv"] != undefined &&
    params["ScreenDiv"] != null &&
    params["ScreenDiv"] != ""
  ) {
    if (param != "") {
      param += "&";
    }
    param += "ScreenDiv" + "=" + params["ScreenDiv"];
  }

  return param;
};

onMounted(() => {
  document.body.className = "";
  loadPogUserJudge(() => {
    try {
      // リクエストURL作成
      const info = getUlrParams();

      const param = createParam(info.params);

      //クエリ有の場合
      if (info != undefined && info.params.length === 3) {
        let request = HOST + PATH + "Search/searchTrainer.js" + BACKWORDPATH;
        if (param != null && param != "") {
          request += "?" + param + "&blankFlag=0"; // TODO 抹消フラグ確認 現在値：含めない
        }

        const messageFlag = true;

        //APIからJSON取得
        getJSON(request, messageFlag, function (status, json) {
          if ((status = SUCCESS)) {
            if (json != undefined && json != null && json != "") {
              //取得データの格納
              const result = json;

              //表示件数の格納
              if (result.length > 0) {
                total.value = json[0].CountAll;
              }

              //詳細ページのリンク作成
              for (let i = 0; i < result.length; i++) {
                result[i].UrlLink =
                  HOST +
                  DATAPATH +
                  "trainer?" +
                  "TrainerCd=" +
                  result[i].TrainerCd;

                //管理馬一覧のリンク
                result[i].ManageHorseListLink =
                  HOST +
                  POGPATH +
                  "search_managehorselist?ScreenDiv=1&Word=" +
                  result[i].TrainerName +
                  "&Option=0";
              }

              result.forEach((element) => trainerData.push(element));
            }
          }
          document.body.className = "status-loaded";
        });
        //クエリ無の場合
      } else if (info.params.length === 1) {
        let request = HOST + PATH + "Search/searchTrainer.js" + BACKWORDPATH;

        request += "?searchOption=0&sort=0&blankFlag=0";

        const messageFlag = true;

        //APIからJSON取得
        getJSON(request, messageFlag, function (status, json) {
          if ((status = SUCCESS)) {
            if (json != undefined && json != null && json != "") {
              //取得データの格納
              const result = json;

              //表示件数の格納
              if (result.length > 0) {
                total.value = json[0].CountAll;
              }

              //詳細ページのリンク作成
              for (let i = 0; i < result.length; i++) {
                result[i].UrlLink =
                  HOST +
                  DATAPATH +
                  "trainer?" +
                  "TrainerCd=" +
                  result[i].TrainerCd;

                //管理馬一覧のリンク
                result[i].ManageHorseListLink =
                  HOST +
                  POGPATH +
                  "search_managehorselist?ScreenDiv=1&Word=" +
                  result[i].TrainerName +
                  "&Option=0";
              }

              result.forEach((element) => trainerData.push(element));
            }
          }
          document.body.className = "status-loaded";
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
});
</script>

<template>
  <main class="layout-main">
    <PogHeader />

    <h2 class="heading-circle -padding">POG 検索</h2>
    <CommonSearch name="pog_trainer" />
    <section class="layout-section">
      <div class="inner">
        <h2 class="heading-circle -nomargin">
          調教師検索結果: {{ total || 0 }}件
        </h2>
        <div v-if="total > 200" class="system-message -notice">
          最大件数を超えた為、200件まで表示します。<br />条件を絞って再検索をして下さい。
        </div>
      </div>

      <table class="table-line -fit -middle -searchresult">
        <tbody>
          <tr v-for="(item, index) in ListItem()" :key="index">
            <td class="name">
              <a :href="item.UrlLink">{{ item.TrainerName }}</a>
            </td>
            <td class="note">
              <a :href="item.ManageHorseListLink" class="btn-basic -min -gray"
                >管理馬一覧</a
              >
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="ListItem().length - count >= 0" class="nav-group">
        <span class="btn-basic -more" @click="isMore">もっと見る</span>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
