<template>
  <div class="item">
    <h3 class="heading-item">{{ distanceVal }}m</h3>
    <table
      class="table-grid -center -middle -padding"
      v-if="distanceData.length"
    >
      <tbody>
        <tr>
          <th>番</th>
          <th>出走馬名</th>
          <th>実績</th>
          <th>勝率</th>
          <th class="_nowrap">連対率</th>
          <th class="_nowrap">3着内率</th>
        </tr>
        <tr v-for="(data, index) in distanceData" :key="index">
          <td v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`">
            {{ data.horseNum }}
          </td>
          <td class="-left">
            <router-link
              v-if="data.pedigreeNum && data.IsExistRaceHorseMaster"
              :to="`/data/horse?PedigreeNum=${data.pedigreeNum}`"
              :class="data.favClass"
            >
              {{ data.horseName }}
            </router-link>
            <span v-else>{{ data.horseName }}</span>
          </td>
          <td>
            [{{ data.distance_win_first }}.{{ data.distance_win_second }}.{{
              data.distance_win_third
            }}.{{ data.distance_win_unplaced }}]
          </td>
          <td>{{ data.distanceRate1st }}</td>
          <td>{{ data.distanceRate2nd }}</td>
          <td>{{ data.distanceRate3rd }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Distance",
  data() {
    return {};
  },
  props: {
    distanceData: Array,
    distanceVal: Number,
  },
  setup(props) {
    return {
      props,
    };
  },
  created() {
    let self = this;
  },
};
</script>

<style lang="scss" src="../../../assets/css/race/style.scss" scoped></style>
