import {NAR_WP_HOST} from '../../assets/js/define';
import {getJSON, SUCCESS} from '../../assets/js/common';
import {useStoreTopNar} from "../../stores/top/nar-states";
import {storeToRefs} from "pinia";

export async function fetch() {
  await fetchWordPressContents();
}

async function fetchWordPressContents() {
  const store = useStoreTopNar();
  const {
    wpContents,
  } = storeToRefs(store);
  const wpUrl = NAR_WP_HOST + "wp-json/nar/index";
  await getJSON(wpUrl, true, (status, data) => {
    if(status !== SUCCESS) {
      wpContents.value = {};
    }
    wpContents.value = data;
  })
}
