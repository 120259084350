<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import {ref} from 'vue'

const emit = defineEmits(["imageURL"]);

const props = defineProps({
  thumbnail: Array,
  host: String,
});

const clickThumbnail = (href) => {
  emit("imageURL", href);
}

const navigation =  ref({
  enabled: true,
  nextEl: ".swiper-button.-next",
  prevEl: ".swiper-button.-prev",
})

const pagination =  ref({
  enabled: true,
})
</script>

<template>
  <template v-if="thumbnail.length > 0">
    <swiper :navigation="navigation"
            :pagination="pagination"
            :loop="true">
        <swiper-slide v-for="(thumb, index) in thumbnail" :key="index">
          <div style="cursor: pointer"
               @click="clickThumbnail(thumb.FaceImgURL)"
               v-if="thumb.FaceImgURL">
            <img :src="host + 'shared/img/database/racehorse/' + thumb.FaceImgURL"
                 alt=""/>
          </div>
          <div class="caption">
            {{ thumb.RecordDate.slice(0, 4) }}.{{
              thumb.RecordDate.slice(4, 6)
            }}.{{ thumb.RecordDate.slice(6, 8) }}
            {{ thumb.RaceName }}
          </div>
        </swiper-slide>
      <div class="swiper-button -prev"></div>
      <div class="swiper-button -next"></div>
    </swiper>
  </template>
  <template v-else>
    <img src="/assets/images/common/blank_horse.png"
         alt=""/>
  </template>
</template>

<style scoped>
/* firefox レイアウト崩れ対策 */
@-moz-document url-prefix() {
  .swiper {
    height: auto !important;
  }
  .swiper-wrapper {
    height: auto !important;
  }
}
</style>

<style lang="scss" src="../../../assets/css/lib/swiper/swiper.scss"></style>
