<template>
  <div class="item">
    <div class="inner">
      <h2 class="heading-item">{{ raceName }} 勝馬頭数</h2>
      <div class="container-footnote">
        <dl>
          <dt class="-past10"></dt>
          <dd>過去10年</dd>
          <dt class="-past20"></dt>
          <dd>過去20年</dd>
        </dl>
      </div>

      <table class="table-cell -wingraph">
        <tbody>
          <tr v-for="(winNum, index) in yearType" :key="index" class="-bar">
            <td
              :class="
                winNum[0] === 1
                  ? '-past10'
                  : winNum[0] === 2
                  ? '-past20'
                  : winNum[0] === 5 || raceWinRate[0] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[1] === 1
                  ? '-past10'
                  : winNum[1] === 2
                  ? '-past20'
                  : winNum[1] === 5 || raceWinRate[1] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[2] === 1
                  ? '-past10'
                  : winNum[2] === 2
                  ? '-past20'
                  : winNum[2] === 5 || raceWinRate[2] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[3] === 1
                  ? '-past10'
                  : winNum[3] === 2
                  ? '-past20'
                  : winNum[3] === 5 || raceWinRate[3] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[4] === 1
                  ? '-past10'
                  : winNum[4] === 2
                  ? '-past20'
                  : winNum[4] === 5 || raceWinRate[4] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[5] === 1
                  ? '-past10'
                  : winNum[5] === 2
                  ? '-past20'
                  : winNum[5] === 5 || raceWinRate[5] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[6] === 1
                  ? '-past10'
                  : winNum[6] === 2
                  ? '-past20'
                  : winNum[6] === 5 || raceWinRate[6] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[7] === 1
                  ? '-past10'
                  : winNum[7] === 2
                  ? '-past20'
                  : winNum[7] === 5 || raceWinRate[7] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[8] === 1
                  ? '-past10'
                  : winNum[8] === 2
                  ? '-past20'
                  : winNum[8] === 5 || raceWinRate[8] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[9] === 1
                  ? '-past10'
                  : winNum[9] === 2
                  ? '-past20'
                  : winNum[9] === 5 || raceWinRate[9] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[10] === 1
                  ? '-past10'
                  : winNum[10] === 2
                  ? '-past20'
                  : winNum[10] === 5 || raceWinRate[10] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[11] === 1
                  ? '-past10'
                  : winNum[11] === 2
                  ? '-past20'
                  : winNum[11] === 5 || raceWinRate[11] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[12] === 1
                  ? '-past10'
                  : winNum[12] === 2
                  ? '-past20'
                  : winNum[12] === 5 || raceWinRate[12] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[13] === 1
                  ? '-past10'
                  : winNum[13] === 2
                  ? '-past20'
                  : winNum[13] === 5 || raceWinRate[13] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[14] === 1
                  ? '-past10'
                  : winNum[14] === 2
                  ? '-past20'
                  : winNum[14] === 5 || raceWinRate[14] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              :class="
                winNum[15] === 1
                  ? '-past10'
                  : winNum[15] === 2
                  ? '-past20'
                  : winNum[15] === 5 || raceWinRate[15] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              v-if="NARFLAG === 0"
              :class="
                winNum[16] === 1
                  ? '-past10'
                  : winNum[16] === 2
                  ? '-past20'
                  : winNum[16] === 5 || raceWinRate[16] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
            <td
              v-if="NARFLAG === 0"
              :class="
                winNum[17] === 1
                  ? '-past10'
                  : winNum[17] === 2
                  ? '-past20'
                  : winNum[17] === 5 || raceWinRate[17] === undefined
                  ? '-none'
                  : ''
              "
            ></td>
          </tr>
          <tr class="-center">
            <td class="bgcolor-waku01">1</td>
            <td class="bgcolor-waku01">2</td>
            <td class="bgcolor-waku02">3</td>
            <td class="bgcolor-waku02">4</td>
            <td class="bgcolor-waku03">5</td>
            <td class="bgcolor-waku03">6</td>
            <td class="bgcolor-waku04">7</td>
            <td class="bgcolor-waku04">8</td>
            <td class="bgcolor-waku05">9</td>
            <td class="bgcolor-waku05">10</td>
            <td class="bgcolor-waku06">11</td>
            <td class="bgcolor-waku06">12</td>
            <td class="bgcolor-waku07">13</td>
            <td class="bgcolor-waku07">14</td>
            <td
              :class="{
                'bgcolor-waku07': NARFLAG === 0,
                'bgcolor-waku08': NARFLAG === 1,
              }"
            >
              15
            </td>
            <td class="bgcolor-waku08">16</td>
            <td v-if="NARFLAG === 0" class="bgcolor-waku08">17</td>
            <td v-if="NARFLAG === 0" class="bgcolor-waku08">18</td>
          </tr>
        </tbody>
      </table>

      <h3 class="heading-item -sub">馬番ごとの勝率</h3>
      <table class="table-cell -center">
        <tbody v-if="raceWinRate">
          <tr>
            <td class="bgcolor-waku01">1</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[0]?.win === undefined ||
                  raceWinRate[0]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[0]?.win === undefined ? "0.0" : raceWinRate[0].win
              }}
            </td>
            <td class="bgcolor-waku01">2</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[1]?.win === undefined ||
                  raceWinRate[1]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[1]?.win === undefined ? "0.0" : raceWinRate[1].win
              }}
            </td>
            <td class="bgcolor-waku02">3</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[2]?.win === undefined ||
                  raceWinRate[2]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[2]?.win === undefined ? "0.0" : raceWinRate[2].win
              }}
            </td>
            <td class="bgcolor-waku02">4</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[3]?.win === undefined ||
                  raceWinRate[3]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[3]?.win === undefined ? "0.0" : raceWinRate[3].win
              }}
            </td>
          </tr>
          <tr>
            <td class="bgcolor-waku03">5</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[4]?.win === undefined ||
                  raceWinRate[4]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[4]?.win === undefined ? "0.0" : raceWinRate[4].win
              }}
            </td>
            <td class="bgcolor-waku03">6</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[5]?.win === undefined ||
                  raceWinRate[5]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[5]?.win === undefined ? "0.0" : raceWinRate[5].win
              }}
            </td>
            <td class="bgcolor-waku04">7</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[6]?.win === undefined ||
                  raceWinRate[6]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[6]?.win === undefined ? "0.0" : raceWinRate[6].win
              }}
            </td>
            <td class="bgcolor-waku04">8</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[7]?.win === undefined ||
                  raceWinRate[7]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[7]?.win === undefined ? "0.0" : raceWinRate[7].win
              }}
            </td>
          </tr>
          <tr>
            <td class="bgcolor-waku05">9</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[8]?.win === undefined ||
                  raceWinRate[8]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[8]?.win === undefined ? "0.0" : raceWinRate[8].win
              }}
            </td>
            <td class="bgcolor-waku05">10</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[9]?.win === undefined ||
                  raceWinRate[9]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[9]?.win === undefined ? "0.0" : raceWinRate[9].win
              }}
            </td>
            <td class="bgcolor-waku06">11</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[10]?.win === undefined ||
                  raceWinRate[10]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[10]?.win === undefined ? "0.0" : raceWinRate[10].win
              }}
            </td>
            <td class="bgcolor-waku06">12</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[11]?.win === undefined ||
                  raceWinRate[11]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[11]?.win === undefined ? "0.0" : raceWinRate[11].win
              }}
            </td>
          </tr>
          <tr>
            <td class="bgcolor-waku07">13</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[12]?.win === undefined ||
                  raceWinRate[12]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[12]?.win === undefined ? "0.0" : raceWinRate[12].win
              }}
            </td>
            <td class="bgcolor-waku07">14</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[13]?.win === undefined ||
                  raceWinRate[13]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[13]?.win === undefined ? "0.0" : raceWinRate[13].win
              }}
            </td>
            <td
              :class="{
                'bgcolor-waku07': NARFLAG === 0,
                'bgcolor-waku08': NARFLAG === 1,
              }"
            >
              15
            </td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[14]?.win === undefined ||
                  raceWinRate[14]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[14]?.win === undefined ? "0.0" : raceWinRate[14].win
              }}
            </td>
            <td class="bgcolor-waku08">16</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[15]?.win === undefined ||
                  raceWinRate[15]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[15]?.win === undefined ? "0.0" : raceWinRate[15].win
              }}
            </td>
          </tr>
          <tr v-if="NARFLAG === 0">
            <td class="bgcolor-waku08">17</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[16]?.win === undefined ||
                  raceWinRate[16]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[16]?.win === undefined ? "0.0" : raceWinRate[16].win
              }}
            </td>
            <td class="bgcolor-waku08">18</td>
            <td
              class="-right"
              :class="{
                '-none':
                  raceWinRate[17]?.win === undefined ||
                  raceWinRate[17]?.win === '0.0',
              }"
            >
              {{
                raceWinRate[17]?.win === undefined ? "0.0" : raceWinRate[17].win
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <p class="_right _caption">単位：％</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    raceName: String,
    yearType: Array,
    raceWinRate: Array,
    NARFLAG: Number,
  },

  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style lang="scss" src="../../../assets/css/race/style.scss" scoped></style>
