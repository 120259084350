<script setup>
import { ref } from "vue";
let dialogChecked = ref(false);
const emit = defineEmits(["modal-close"]);

const props = defineProps({
  modalOpen: Boolean,
});

const modalClose = () => {
  emit("modal-close", false);
};
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div @click.self="modalClose" v-if="modalOpen" class="modal-frame">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                自動登録機能とは？
              </div>
              <div class="modal-body">
                <section class="layout-section">
                  <div class="inner">
                    <p>
                      調教師の管理馬、馬主の所有馬、生産者の生産馬、繁殖牝馬の産駒が、新たに競走馬登録された際に、自動的にお客様のお気に入りリストの中に、その競走馬が追加される機能です。
                    </p>

                    <p>
                      新しい競走馬が追加されるたびに、お気に入り登録をする必要が無くなる、とても便利な機能です。
                    </p>

                    <div class="layout-sub">
                      <div class="layout-title">
                        <h2 class="heading-bar -padding">
                          こんな人にオススメ！
                        </h2>
                      </div>
                      <ul class="list-disc">
                        <li>愛馬会法人の社員の方</li>
                        <li>愛馬をお持ちの馬主、その関係者の方</li>
                        <li>生産者の方</li>
                        <li>調教師ならびに厩舎関係者の方</li>
                        <li>思い入れのある繁殖牝馬がいる方</li>
                      </ul>
                    </div>

                    <div class="layout-sub">
                      <div class="layout-title">
                        <h2 class="heading-bar -padding">使い方</h2>
                      </div>
                      <ul class="list-circlenumber">
                        <li>
                          自動登録機能を使いたい調教師・馬主・生産者・繁殖牝馬をお気に入り登録してください。
                        </li>

                        <li>
                          <span class="image-inline"
                            ><img
                              src="../../assets/images/common/fav/check_off.png"
                              alt="OFFボタン"
                              style="width: 50px" /></span
                          >お気に入り一覧ページにて、OFFボタンをタップしてください。
                        </li>

                        <li>
                          <span class="image-inline"
                            ><img
                              src="../../assets/images/common/fav/check_on.png"
                              alt="OFFボタン"
                              style="width: 50px" /></span
                          >OFFボタンがONになれば、自動追加がスタートします。
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
