<script setup>
import { ref, reactive, onMounted } from "vue";
import { getUrlVars, getJSON, SUCCESS } from "../../assets/js/common";
import {
  HOST,
  POGAPIPATH,
  POGPATH,
  BACKWORDPATH,
} from "../../assets/js/define";

import PogHeader from "../../components/pog/PogHeader.vue";
import pogFunc from "../../functions/pog.js";
const { loadPogUserJudge } = pogFunc();

const myPageLink = ref("");
onMounted(() => {
  document.body.className = "";
  loadPogUserJudge(() => {
    const params = getUrlVars();
    const userId = params["userId"];
    const year = params["Year"];

    if (userId) {
      //ユーザー情報取得
      userHorseList(userId, year);
    }

    //マイページへのリンク
    myPageLink.value = HOST + POGPATH + "mypage";
  });
});

let horses = reactive({});
const userHorseList = (UserId, Year) => {
  let req =
    HOST +
    POGAPIPATH +
    "getPogUserInfo.js" +
    BACKWORDPATH +
    "?UserID=" +
    UserId;
  if (Year != undefined) {
    req += "&Year=" + Year;
  }
  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      if (Object.keys(json).length) {
        if (json != undefined || json != null) {
          //獲得ポイント降順で並べ替え
          json.EntryLst.sort(function (a, b) {
            const al = a["Pts"];
            const ar = a["Name"];
            const bl = b["Pts"];
            const br = b["Name"];

            if (al < bl) return 1;
            if (al > bl) return -1;
            if (al == bl) {
              if (ar < br) return -1;
              if (ar > br) return 1;
            }
          });
          Object.assign(horses, json);
        } else {
          alert("データを取得できませんでした。");
        }
      } else {
        alert("データを取得できませんでした。");
      }
    } else {
      alert("データを取得できませんでした。");
    }
    document.body.className = "status-loaded";
  });
};

const horseLink = (code) => {
  let url = "";
  if (code) {
    url = HOST + "pog/" + "horse_detail?PedigreeNum=" + code;
  }
  return url;
};
</script>
<template>
  <main class="layout-main">
    <PogHeader></PogHeader>

    <section class="layout-section">
      <h2 class="heading-circle -padding">
        {{ horses.NickName }}さんの登録馬一覧
      </h2>
      <ul class="list-line -ranking">
        <li v-for="horse in horses.EntryLst" :key="horse">
          <a :href="horseLink(horse.Code)" class="link-cell">
            <span class="name">{{ horse.Name }}</span>
            <span class="total">{{ horse.Pts }}pt</span>
          </a>
        </li>
      </ul>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
