import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreBreederData = defineStore('breeder-data', () => {
  const breederCdValue = ref('');
  const breederData = ref(null);
  const careerStatData = ref(null);
  const schedule = ref([]);
  const totalRaces = ref(0);
  const productionHorses = ref([]);
  const pastProductionHorses = ref([]);

  return {
    breederCdValue,
    breederData,
    careerStatData,
    schedule,
    totalRaces,
    productionHorses,
    pastProductionHorses,
  }
})

export default {
  useStoreBreederData,
}