<script setup>
import { ref } from "vue";
const emit = defineEmits(["modal-close ", "cancel"]);

const props = defineProps({
  modalOpen: Boolean,
});

const CancelEmailNortification = () => {
  emit("cancel", true);
};

const modalClose = () => {
  emit("modal-close", false);
};
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div @click="modalClose()" v-if="modalOpen" class="modal-frame">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                メール配信設定
              </div>
              <div class="modal-body">
                <p>通知機能を解除してよろしいですか？</p>
                <div class="modal-nav">
                  <span
                    @click="modalClose()"
                    class="btn-basic -gray close-modal"
                    >解除しない</span
                  ><span @click="CancelEmailNortification()" class="btn-basic"
                    >解除する</span
                  >
                </div>
              </div>
            </div>
            <span @click="modalClose()" class="modal-close"></span>
          </div>
        </div>
      </transition></div
  ></teleport>
</template>

<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
