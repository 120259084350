<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">検索結果</h1>
    </section>

    <h2 class="heading-circle -padding">
      競走馬検索結果: {{ numberOfSearchResults }}件
    </h2>
    <p
      v-if="numberOfSearchResults > 200 && loadedPage"
      class="system-message -padding _important"
    >
      最大件数を超えた為、200件まで表示します。<br />条件を絞って再検索をしてください。
    </p>
    <p
      v-if="numberOfSearchResults === 0 && loadedPage"
      class="system-message -padding _important"
    >
      検索結果はありません。
    </p>
    <section class="layout-section">
      <table class="table-line -fit -middle -horse">
        <tbody :key="index">
          <tr
            v-for="(item, index) in ListItems"
            :key="index"
            :class="item.hasClass"
          >
            <td class="name">
              <a :href="item.UrlLink">{{ item.HorseName }}</a>
            </td>
            <td class="place">
              <span v-if="item.Affiliation === 'JRA'" class="icon-mark -place -jra">
                <i>JRA</i>
              </span>
              <span v-else-if="item.Affiliation === 'NAR'" class="icon-mark -place -nar">
                <i>地方</i>
              </span>
            </td>
            <td
              :class="{
                'sex -nowrap': NARFLAG === 0,
                'left -nowrap': NARFLAG === 1,
              }"
            >
              {{ item.Sex }}{{ item.RacehorseDiv === 0 ? item.Age : "" }}
            </td>
            <td v-if="NARFLAG === 0" class="raceclass">
              {{ item.flatRaceRank
              }}<br v-if="item.flatRaceRank !== undefined" />{{
                item.steeplechaseRaceRank
              }}
            </td>
            <td v-if="NARFLAG === 0" class="pog">
              <a
                v-if="item.isPog"
                :href="item.pogUrl"
                class="icon-status -pog"
              ></a>
            </td>
            <td class="-fav" :style="styles">
              <span
                v-if="item.isFav"
                class="icon-fav"
                :class="item.favClass"
                @click="clickFav(index)"
              ></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="nav-group">
        <span
          v-if="numberOfSearchResults - count > 0 && count <= 200"
          class="btn-basic -more"
          @click="isMore"
          >もっと見る</span
        >
      </div>
    </section>

    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import {
  PAYINGNONE,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  FRACEHORSE,
  ADD,
  REMOVE,
  SUCCESS,
  getJSON,
  isFavorite,
  changeFavoriteFromCookie,
  isNar, retrieveCourse, getUserId,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  DATAPATH,
  BACKWORDPATH,
  SORTHORSENAME,
  SORTSEX,
  SORTAGE,
  SORTCLASS,
  POGPATH,
} from "../../assets/js/define";
import { useRouter } from 'vue-router'
export default {
  data() {
    return {
      NARFLAG: null,
      useData: null,
      //検索結果数
      numberOfSearchResults: null,
      count: 30,
      isFav: true,
      favClass: "",
      hasClass: "",
      loadedPage: false,
      //アイコン非表示用
      styles: {
        display: "",
      },
      userId: "",
      courseId: PAYINGNONE,
    };
  },
  mounted() {
    const self = this;
    const router = useRouter()
    self.NARFLAG = isNar()
    // リクエストURL作成
    let mInfo = getUlrParams();
    const param = createParam(mInfo.params);

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none", false, "");
      }
    }

    callRetrieveCourse(function (courseId, showMarks, userId) {
      self.userId = userId || getUserId();
      self.courseId = courseId;

      //会員によってお気に入りアイコン非表示;
      if (self.courseId == PAYINGNONE || self.courseId == PAYINGSUGOTOKU_NONE) {
        self.styles.display = "none";
      }

      let request =
        HOST +
        PATH +
        "Search/searchRacehorse.js" +
        BACKWORDPATH +
        "?narFlag=" +
        isNar();
      if (param != null && param != "") {
        request += "&" + param;
      }

      const messageFlag = true;
      document.body.className = "";
      getJSON(request, messageFlag, function (status, json) {
        if (status == SUCCESS) {
          if (json && json.HorseCount > 0) {
            let useData = json.Horse;

            //検索結果の要素数を取得
            const numberOfSearchResults = json.HorseCount;
            //検索結果が1件だった場合、詳細ページへ遷移
            if (numberOfSearchResults === 1) {
              const backCheck = sessionStorage.getItem('numberOfHorseSearchResults?' + useData[0].PedigreeNum)
              console.log(backCheck)
              if (backCheck) {
                router.push('/' + DATAPATH)
                return
              }
              if (useData[0].PedigreeNum) {
                sessionStorage.setItem('numberOfHorseSearchResults?' + useData[0].PedigreeNum, 1)
                router.push('/' +
                    DATAPATH +
                    "horse?PedigreeNum=" +
                    useData[0].PedigreeNum
                )
                return;
              }
            }

            self.numberOfSearchResults = numberOfSearchResults;
            self.useData = useData;

            for (let i = 0; i < useData.length; i++) {
              //競走馬データへのリンク生成
              if (
                useData[i].PedigreeNum &&
                useData[i].PedigreeNum !== "0000000000"
              ) {
                useData[i].UrlLink =
                  HOST +
                  DATAPATH +
                  "horse?PedigreeNum=" +
                  useData[i].PedigreeNum;

                //お気に入り機能のプロパティ定義
                useData[i].isFav = false;
              }

              //お気に入りの表示
              if (
                self.courseId !== PAYINGNONE &&
                self.courseId !== PAYINGSUGOTOKU_NONE
              ) {
                useData[i].isFav = true;
                if (
                  useData[i].PedigreeNum &&
                  isFavorite(FRACEHORSE, useData[i].PedigreeNum) == 1
                ) {
                  useData[i].favClass = "-registed";
                } else {
                  useData[i].favClass = "";
                }
              } else {
                useData[i].isFav = false;
              }

              // JRA
              if (isNar() === 0) {
                // レースランク
                // 抹消馬の場合は非表示
                if (
                    useData[i].RacehorseDiv !== undefined &&
                    useData[i].RacehorseDiv !== null &&
                    useData[i].RacehorseDiv === 0
                ) {
                  if (
                      useData[i].FlatRaceRank !== null &&
                      useData[i].FlatRaceRank !== ""
                  ) {
                    //平地は競走成績の有無にかかわらず、全て馬の条件を表示(外国馬除く)
                    useData[i].flatRaceRank = "平地：" + useData[i].FlatRaceRank;
                  }
                  if (
                      useData[i].SteeplechaseRaceRank !== null &&
                      useData[i].SteeplechaseRaceRank !== "" &&
                      useData[i].SteeplechaseRaceRank !== "未出走" &&
                      useData[i].SteeplechaseRaceRank !== "新馬"
                  ) {
                    //障害の競走成績がある馬のみ表示
                    useData[i].steeplechaseRaceRank =
                        "障害：" + useData[i].SteeplechaseRaceRank;
                  }
                }
                // POG
                useData[i].isPog = false;
                if (self.courseId !== PAYINGNONE && useData[i].POGFlag === 1) {
                  let url;
                  useData[i].isPog = true;
                  url =
                      HOST +
                      POGPATH +
                      "horse_detail?PedigreeNum=" +
                      useData[i].PedigreeNum;
                  if (
                      self.courseId === PAYINGSUGOTOKU ||
                      self.courseId === PAYINGSUGOTOKU_NONE ||
                      self.courseId === PAYINGAPPPASS
                  ) {
                    useData[i].isPog = false;
                  } else {
                    useData[i].pogUrl = url;
                  }
                }
              }
                // 抹消されていた場合、グレーアウトする
                useData[i].hasClass =
                    useData[i].RacehorseDiv === 1 ? "-disable" : "";
            }

            self.useData = useData;
          } else {
            self.numberOfSearchResults = 0
          }
        }
        // 詳細ページへ遷移する場合、ローディングのマスクは表示したまま
        document.body.className = "status-loaded";
        self.loadedPage = true;
      });
    })

    function getUlrParams() {
      // ページ数取得
      let pageNum = 1;
      let url = location.href;
      if (url.indexOf("#page-") > -1) {
        pageNum = url.slice(url.indexOf("#page-") + 6, url.length);
        url = url.slice(0, url.indexOf("#page-"));
      }

      // パラメータ取得
      let vars = [],
        hash;
      let hashes = url.slice(window.location.href.indexOf("?") + 1).split("&");
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        if (hash[0] != null && hash[0].indexOf("#page-") == -1) {
          // ページリンクは除外する
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
      }
      return { params: vars, page: pageNum };
    }

    function createParam(params) {
      let param = ''
      if (
        params["keyword"] != undefined &&
        params["keyword"] != null &&
        params["keyword"] != ""
      ) {
        param += "keyword" + "=" + params["keyword"];
      }
      if (
        params["blankFlag"] != undefined &&
        params["blankFlag"] != null &&
        params["blankFlag"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "blankFlag" + "=" + params["blankFlag"];
      }
      if (
        params["searchType"] != undefined &&
        params["searchType"] != null &&
        params["searchType"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "searchType" + "=" + params["searchType"];
      }
      if (
        params["searchOption"] != undefined &&
        params["searchOption"] != null &&
        params["searchOption"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "searchOption" + "=" + params["searchOption"];
      } else {
        if (param != "") {
          param += "&";
        }
        param += "searchOption" + "=0";
      }
      if (
        params["sort"] != undefined &&
        params["sort"] != null &&
        (params["sort"] == String(SORTHORSENAME) ||
          params["sort"] == String(SORTSEX) ||
          params["sort"] == String(SORTAGE) ||
          params["sort"] == String(SORTCLASS))
      ) {
        if (param != "") {
          param += "&";
        }
        param += "sort" + "=" + params["sort"];
      } else {
        if (param != "") {
          param += "&";
        }
        param += "sort" + "=" + 0;
      }
      if (
        params["RaceYear"] != undefined &&
        params["RaceYear"] != null &&
        params["RaceYear"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "RaceYear" + "=" + params["RaceYear"];
      }
      if (
        params["Belong"] != undefined &&
        params["Belong"] != null &&
        params["Belong"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Belong" + "=" + params["Belong"];
      }
      if (
        params["HorseOwnerCd"] != undefined &&
        params["HorseOwnerCd"] != null &&
        params["HorseOwnerCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "HorseOwnerCd" + "=" + params["HorseOwnerCd"];
      }
      if (
        params["TrainerCd"] != undefined &&
        params["TrainerCd"] != null &&
        params["TrainerCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "TrainerCd" + "=" + params["TrainerCd"];
      }
      if (
        params["BreedingNum"] != undefined &&
        params["BreedingNum"] != null &&
        params["BreedingNum"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "BreedingNum" + "=" + params["BreedingNum"];
      }
      if (
        params["BreederCd"] != undefined &&
        params["BreederCd"] != null &&
        params["BreederCd"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "BreederCd" + "=" + params["BreederCd"];
      }
      if (
        params["ScreenDiv"] != undefined &&
        params["ScreenDiv"] != null &&
        params["ScreenDiv"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "ScreenDiv" + "=" + params["ScreenDiv"];
      }
      if (
        params["Word"] != undefined &&
        params["Word"] != null &&
        params["Word"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Word" + "=" + params["Word"];
      }
      if (
        params["Option"] != undefined &&
        params["Option"] != null &&
        params["Option"] != ""
      ) {
        if (param != "") {
          param += "&";
        }
        param += "Option" + "=" + params["Option"];
      }

      return param;
    }
  },
  computed: {
    ListItems() {
      let self = this;
      const list = self.useData;
      if (list != undefined && list != null && list.length > 0) {
        return list.slice(0, self.count);
      } else {
        return [];
      }
    },
  },
  methods: {
    isMore() {
      this.count += 30;
    },
    //お気に入り登録用
    clickFav: async function (index) {
      try {
        document.body.className = "";
        if (isFavorite(FRACEHORSE, this.useData[index].PedigreeNum) == 1) {
          // お気に入り未登録なので登録する
          const request =
            HOST +
            PATH +
            "MyFavorite/deleteMyFavoriteRacehorse.js" +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(this.userId) +
            "&pedigreeNum=" +
            this.useData[index].PedigreeNum;
          const order = REMOVE;
          await getJSON(request, false, function (status, json) {
            if (status == SUCCESS) {
            } else {
              alert("お気に入りの削除に失敗しました。");
            }
          });
          const favJudge = await changeFavoriteFromCookie(
            FRACEHORSE,
            this.useData[index].PedigreeNum,
            order
          );
          if (favJudge) {
            //アイコンクラス変更
            this.useData[index].favClass = "";
          } else {
            this.useData[index].favClass = "";
          }
        } else {
          // お気に入り未登録なので登録する
          const request =
            HOST +
            PATH +
            "MyFavorite/setMyFavoriteRacehorse.js" +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(this.userId) +
            "&pedigreeNum=" +
            this.useData[index].PedigreeNum +
            "&isPush=1";
          const order = ADD;
          await getJSON(request, false, function (status, json) {
            if (status == SUCCESS) {
              this.favStatus = SUCCESS;
            } else {
              alert("お気に入りの登録に失敗しました。");
            }
          });
          const favJudge = await changeFavoriteFromCookie(
            FRACEHORSE,
            this.useData[index].PedigreeNum,
            order
          );
          if (favJudge) {
            this.useData[index].favClass = "-registed";
          } else {
            this.useData[index].favClass = "";
          }
        }

        document.body.className = "status-loaded";
      } catch (e) {
        console.log("error" + e);
      }
    },
  },
};
</script>

<style lang="scss" src="../../assets/css/data/search/style.scss" scoped></style>
