<script setup>
import {ref, onMounted, watch} from "vue";
import {useRoute} from "vue-router";

import SetBetConfModal from "../../components/utils/SetBetConfModal.vue";
import OddsTabs from "../../components/odds/OddTabs.vue";
import RaceHeader from "../../components/race/RaceHeader.vue";

import BracketExactaFormation from "../../components/odds/bracketExacta/Formation.vue";
import BracketExactaPopularity from "../../components/odds/bracketExacta/Popularity.vue";
import BracketExactaWheel1 from "../../components/odds/bracketExacta/Wheel1.vue";
import BracketExactaWheel2 from "../../components/odds/bracketExacta/Wheel2.vue";
import BracketExactaBox from "../../components/odds/bracketExacta/Box.vue";
import {getUrlVars, isNar, getJSON, SUCCESS} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
} from "../../assets/js/define";

import {useStoreRaceCommon} from "../../stores/race-common-states";
import {storeToRefs} from "pinia";
const storeRaceCommon = useStoreRaceCommon();
const {
  raceDORVal,
  raceTrackCdVal,
  raceNumVal,
  deadLineVal,
} = storeToRefs(storeRaceCommon);

let modalBetOpen = ref(false);
const currentTab = "bracket_exacta";
let params = ref({});
const bracketFlag = ref(true);
let method = ref("");

// 馬券種類タブ
const currentSubTab = ref("formation");

const route = useRoute();

watch(route, async (from, to) => {
  params.value = to.query;
  await fetchOdds(to.query);
});

onMounted(async () => {
  params.value = getUrlVars();
  await fetchOdds(params.value);
});

const fetchOdds = async (params) => {
  bracketFlag.value = true;
  if (params.method) {
    currentSubTab.value = params.method;
    method.value = params.method;
    selected.value = params.method.indexOf('wheel') !== -1 ? params.method :"";
    if (selected.value === "wheel_1") {
      selectedWheel.value = "1着軸流し";
    } else if (selected.value === "wheel_2") {
      selectedWheel.value = "2着軸流し";
    } else {
      selectedWheel.value = "流し";
    }
  }

  var req =
    HOST +
    PATH +
    "Odds/bracketQuinellaOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    params.DOR +
    "&RacetrackCd=" +
    params.RacetrackCd +
    "&RaceNum=" +
    params.RaceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      if (json[0].SaleDiv === 0) bracketFlag.value = false
    }
  });
}

const selected = ref("");
const selectedWheel = ref("流し");
const changeVoteTab = (data) => {
  const url = new URL(window.location);
  if (data === "wheel") {
    currentSubTab.value = selected.value;
    url.searchParams.set('method', selected.value);
    method.value = selected.value;

    if (selected.value === "wheel_1") {
      selectedWheel.value = "1着軸流し";
    } else if (selected.value === "wheel_2") {
      selectedWheel.value = "2着軸流し";
    }
  } else {
    selectedWheel.value = "流し";
    selected.value = "";
    currentSubTab.value = data;

    if (data !== "formation") {
      url.searchParams.set('method', data);
      method.value = data;
    } else {
      url.searchParams.delete('method');
      method.value = '';
    }
  }
  window.history.pushState({}, '', url);
};
</script>
<template>
  <main class="layout-main">
    <RaceHeader
      :method="method"
    ></RaceHeader>
    <nav class="nav-multi">
      <OddsTabs v-if="Object.keys(params).length" :currentTab="currentTab" :params="params" :bracketFlag="bracketFlag"></OddsTabs>
      <div class="container-wrapper -pad">
        <div class="container-toggle -kinds">
          <ul>
            <li
              @click="changeVoteTab('formation')"
              class="-free"
              :class="[currentSubTab === 'formation' ? '-current' : '']"
            >
              <div class="label">フォーメーション</div>
            </li>
            <li
              @click="changeVoteTab('popular')"
              class="-box"
              :class="[currentSubTab === 'popular' ? '-current' : '']"
            >
              <div class="label">人気順</div>
            </li>
            <li
              class="-nagashi"
              :class="[
                currentSubTab === 'wheel_1' || currentSubTab === 'wheel_2'
                  ? '-current'
                  : '',
              ]"
            >
              <div class="label nav-pulldown">{{ selectedWheel }}</div>
              <select v-model="selected" @change="changeVoteTab('wheel')">
                <option disabled value="initial">選択</option>
                <option value="wheel_1">1着軸流し</option>
                <option value="wheel_2">2着軸流し</option>
              </select>
            </li>
            <li
              @click="changeVoteTab('box')"
              class="-box"
              :class="[currentSubTab === 'box' ? '-current' : '']"
            >
              <div class="label">ボックス</div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <BracketExactaFormation
      v-if="currentSubTab === 'formation'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></BracketExactaFormation>
    <BracketExactaPopularity
      v-else-if="currentSubTab === 'popular'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    >
    </BracketExactaPopularity>
    <BracketExactaWheel1
      v-else-if="currentSubTab === 'wheel_1'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></BracketExactaWheel1>
    <BracketExactaWheel2
      v-else-if="currentSubTab === 'wheel_2'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></BracketExactaWheel2>
    <BracketExactaBox
      v-else
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></BracketExactaBox>
  </main>
</template>

<style lang="scss" src="../../assets/css/race/odds/style.scss" scoped></style>