import {BACKWORDPATH, HOST, PATH} from "../../assets/js/define";
import {getJSON, SUCCESS,} from "../../assets/js/common";
import {
  dataBigPrizeWins,
  g1RaceResult,
  getNews,
  getSpecialRaceResults,
  setOddsHtml,
  weeklyRacing,
} from "../../assets/js/data-common";
import {storeToRefs} from "pinia";
import {useStoreCommon} from "../../stores/common-states"
import {useStoreJockeyData} from "../../stores/data/jockey-states";

let storeJockeyData = null;
let narFlag = 0;

export async function fetch(jockeyCd) {
  const storeCommon = useStoreCommon()
  const {host, isNarSite} = storeToRefs(storeCommon);
  storeJockeyData = useStoreJockeyData();
  narFlag = isNarSite.value ? 1 : 0;

  await dataJockeyExt(jockeyCd, host.value);
  await dataJockey(jockeyCd, host.value, isNarSite.value);
  await getWeeklyRacing(jockeyCd, host.value);
  await getBigPrizeWins(jockeyCd, host.value);
  if (!isNarSite.value) {
    await getG1RaceResult(jockeyCd, host.value)
  }
}

// 騎手データ取得
const dataJockey = async (jockeyCd, host, isNar) => {
  const {
    jockeyCdValue,
    imgPath,
    uniformImgPath,
    JockeyData,
    news,
    thisWeekSpResult,
  } = storeToRefs(storeJockeyData);

  let request =
    host +
    PATH +
    "Master/jockeyMasterAPI.js" +
    BACKWORDPATH +
    "?JockeyCd=" +
    jockeyCd +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);
  await getJSON(request, true, async function (status, json) {
    if (status === SUCCESS && json?.length && json[0]?.JockeyData) {
      let jockeyData = json[0].JockeyData;
      jockeyCdValue.value = jockeyData.JockeyCd;

      imgPath.value = jockeyData.FaceImgURL
        ? `${host}shared/img/database/jockey/${jockeyData.FaceImgURL}`
        : `${host}images/common/blank_profile_jockey.png`;

      uniformImgPath.value = jockeyData.JockeyCd
        ? `${host}assets/images/data/images/jockey/${jockeyData.JockeyCd.slice(1)}.png`
        : `${host}images/common/blank_profile_jockey.png`

      //騎手歴のデータ作成
      let date = new Date();
      let thisYear = date.getFullYear();
      let licenseYear = String(jockeyData.LicenseDate).substring(0, 4);
      licenseYear = Number(licenseYear);
      let yearsOfExperience = thisYear - licenseYear + 1;
      yearsOfExperience = `（${yearsOfExperience}年目）`;

      //所属
      let belongs = jockeyData.TrainerName;
      if (!jockeyData.TrainerName) {
        const affiliation = "フリー";
        let div = '';
        if (jockeyData.EastWestDiv === "関東") {
          div = "（美浦）";
        } else if (jockeyData.EastWestDiv === "関西") {
          div = "（栗東）";
        } else if (jockeyData.EastWestDiv === "") {
          if (jockeyData?.NationalityCd !== "日本人") {
            div = "(海外)";
          } else if (jockeyData?.InvitationBelong) {
            div = "(" + jockeyData.InvitationBelong + ")";
          }
        }
        belongs = affiliation + div;
      }

      //本年勝利・リーディングURL生成
      let raceWins = jockeyData.CurrentYearRaceWins && jockeyData.CurrentYearRaceWins > 0 ?
        jockeyData.CurrentYearRaceWins + "勝" : "-勝";
      let leadUrl = HOST + "leading/jockey?rank=" + jockeyData.LatestWinsRanking;
      // ランキングのHTML生成
      jockeyData.LatestWinsRanking =
        jockeyData.LatestWinsRanking && jockeyData.LatestWinsRanking > 0
          ? raceWins +
          " (" +
          "<a href='" +
          leadUrl +
          "'>" +
          jockeyData.LatestWinsRanking +
          "位</a>" +
          ")"
          : raceWins + "(-位)";

      //競走馬ページのデータ出力・HTML印字
      let currentJockeyData;

      let displayUpdated = '';
      // 成績集計日の生成
      if (jockeyData.LeadingUpdated && 8 <= jockeyData.LeadingUpdated.length) {
        let updatedL = jockeyData.LeadingUpdated;
        let str =
          updatedL.slice(0, 4) +
          "年" +
          updatedL.slice(4, 6) +
          "月" +
          updatedL.slice(6, 8) +
          "日";
        if (updatedL.length > 8 && isNar) {
          str += ' ' + updatedL.slice(8, 10) + ':' + updatedL.slice(10, 12);
        }
        displayUpdated = "成績集計 " + str + "現在";
      }
      const birthday = String(jockeyData.DOB).substring(0, 4) +
        "/" +
        String(jockeyData.DOB).substring(4, 6) +
        "/" +
        String(jockeyData.DOB).substring(6, 8);
      const age = `（${jockeyData.Age}歳）`;
      const licenseDate = String(jockeyData.LicenseDate).substring(0, 4) + "年"

      //dataの格納
      currentJockeyData = {
        JockeyName: jockeyData.JockeyName,
        JockeyNameKana: jockeyData.JockeyNameKana,
        JockeyNameAlpha: jockeyData.JockeyNameAlpha,
        RetireDiv: jockeyData.RetireDiv,
        EastWestDiv: jockeyData.EastWestDiv,
        Belongs: belongs,
        DOB: birthday,
        Age: age,
        LicenseDate: licenseDate,
        YearsOfExperience: yearsOfExperience,
        CareerWins: jockeyData.CareerWins,
        CurrentYearRaceWins: jockeyData.CurrentYearRaceWins,
        LatestWinsRanking: jockeyData.LatestWinsRanking,
        LeadingUpdated: displayUpdated,
      };
      JockeyData.value = currentJockeyData;

      //　関連ニュース
      if (jockeyData?.JockeyNews?.length) {
        news.value = await getNews(jockeyData.JockeyNews, host)
      } else {
        news.value = []
      }

      //今週開催重賞実績
      if (jockeyData?.ThisweekSPResult?.length) {
        thisWeekSpResult.value = await getSpecialRaceResults(jockeyData.ThisweekSPResult)
      } else {
        thisWeekSpResult.value = []
      }
    }
  });
}

//騎手データ（追加データ込）取得
const dataJockeyExt = async (jockeyCd, host) => {
  const {
    careerStatData,
    jockeyExtData,
  } = storeToRefs(storeJockeyData);

  let request =
    host +
    PATH +
    "Master/jockeyMasterAPI.js" +
    BACKWORDPATH +
    "?JockeyCd=" +
    jockeyCd +
    "&ext=1" +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);
  await getJSON(request, false, function (status, json) {
    if (status === SUCCESS) {
      if (!json?.length || !json[0]?.JockeyData) {
        return;
      }

      const jockeyData = json[0].JockeyData;

      for (let i in jockeyData.CareerStat) {
        let targetStat = jockeyData.CareerStat[i];
        let setYear = targetStat.YEAR + "年";
        if (targetStat.YEAR === 9999) {
          setYear = "累計";
        }
      }
      careerStatData.value = jockeyData.CareerStat;
      jockeyExtData.value = jockeyData;
    }
  });
}

//今週出走データ
const getWeeklyRacing = async (mainKey, host) => {
  const {
    schedule,
    totalRaces,
  } = storeToRefs(storeJockeyData);

  const viewKbn = "0";
  const response = await weeklyRacing(viewKbn, mainKey, host, narFlag);
  schedule.value = await setOddsHtml(response?.schedule, host, narFlag);
  totalRaces.value = response?.totalRaces;
}

//重賞勝鞍データ取得
const getBigPrizeWins = async (mainKey, host) => {
  const {
    bigPrizeData,
  } = storeToRefs(storeJockeyData);

  const viewKbn = "0";
  bigPrizeData.value = await dataBigPrizeWins(viewKbn, mainKey, host, narFlag)
}

//GI実績データ
const getG1RaceResult = async (mainKey, host) => {
  const {
    g1RaceResultData,
  } = storeToRefs(storeJockeyData);

  const viewKbn = "0";
  g1RaceResultData.value = await g1RaceResult(viewKbn, mainKey, host);
}