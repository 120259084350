import {BACKWORDPATH, RACEINFOPATH} from "../../assets/js/define";
import {getJSON, SUCCESS} from "../../assets/js/common";
import {storeToRefs} from "pinia";
import {useStoreWin5Detail} from "../../stores/win5-states";
import {useStoreCommon} from '../../stores/common-states'
import moment from 'moment'

let storeWin5Detail = null;

export async function fetch(dor) {
  const storeCommon = useStoreCommon()
  const {host} = storeToRefs(storeCommon);
  storeWin5Detail = useStoreWin5Detail();

  await getWin5PastResult(host.value, dor);
}

const getWin5PastResult = async function (host, dor) {
  const {
    yyyymmdd,
    currentTime,
    resultCount,
    win5DetailData,
  } = storeToRefs(storeWin5Detail);

  const req = host + RACEINFOPATH + "getWin5Detail.js" + BACKWORDPATH + "?DOR=" + dor;
  await getJSON(req, true, function (status, json) {
    if (status === SUCCESS) {
      try {
        if ("RaceInfo" in json) {
          yyyymmdd.value = moment().format('YYYYMMDD');
          currentTime.value = moment().format('HHmmss');
          for (let i = 0; i < json.RaceInfo.length; i++) {
            if (json.RaceInfo[i].HorseNum !== "" && /[0-9]/.test(json.RaceInfo[i].HorseNum)) {
              resultCount.value++;
            }
          }
          win5DetailData.value = json
          getDateStrType3(json);
        }
      } catch (e) {
        alert("getWin5Todayresult : " + e);
      }
    }
  });
}

//表示部で使用する日付フォーマット取得関数（例：■/○/△（□））
const getDateStrType3 = (win5DetailData) => {
  const {
    win5DorHtml,
    win5DorStr,
  } = storeToRefs(storeWin5Detail);

  const dor = win5DetailData.DOR;

  const DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  const year = dor.slice(0, 4);
  const month = parseInt(dor.slice(4, 6), 10);
  const date = parseInt(dor.slice(6, 8), 10);
  const mDate = new Date(parseInt(dor.slice(0, 4), 10), month - 1, date);
  const day = DAY_LBL[mDate.getDay()]

  let color;
  if (day === "日") {
    color = "_sun";
  } else if (day === "土") {
    color = "_sat";
  }

  let html =
    "<h2 class='heading-item'>" +
    year +
    "/" +
    month +
    "/" +
    date +
    "(<span class=" +
    color +
    ">" +
    day +
    "</span>)";

  if (win5DetailData.SearchHorseList.length > 0) {
    html += "の結果";
  }

  win5DorHtml.value = html;
  win5DorStr.value = year + "/" + month + "/" + date + "(" + day + ")";
}
