<script setup></script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">会員状態エラー</h1>
    </section>

    <section class="layout-section">
      <div class="container-box -fit">
        <div class="inner">
          <p>いつもWIN!競馬をご利用いただき誠にありがとうございます。</p>
          <p>
            お客様は現在、「プレミアム機能追加コース(月額700円・税抜)」と「プレミアムコース(月額1,000円・税抜)」の両方に入会されている状態です。
          </p>
          <p>
            「プレミアムコース」のみで、全ての機能をご利用いただけますので、「プレミアム機能追加コース」を退会して頂き、サービスをご利用いただけますようよろしくお願い致します。
          </p>

          <a
            class="btn-basic -wide -center -noarrow"
            href="/support/quit/premium_plus"
            >プレミアム機能追加コースを退会</a
          >
        </div>

        <div class="inner">
          <p>お問い合わせはこちら</p>
          <p>
            <a href="mailto:info-winkeiba@digimerce.com"
              >info-winkeiba@digimerce.com</a
            >
          </p>
        </div>
      </div>
    </section>
  </main>
</template>
