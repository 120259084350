<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import { HOST, JRA_WP_HOST } from "../../assets/js/define";
import {userSettingURL, getUserAgent, getJoinStatus} from "../../assets/js/common";
import axios from "axios";

// APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=join_economy";

let list = reactive({});
let isWebview = ref(false);
onMounted( () => {
  document.body.className = "";
  axios
    .get(API_URL)
    .then((response) => {
      Object.assign(list, response.data[0]);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });

  isWebview.value = getUserAgent()
});

onUpdated(() => {
  if (!isWebview.value) {
    const modal = initModal();

    let modalAddPremium = document.getElementById("modal-add-premium");
    let modalPremium = document.getElementById("modal-premium");
    document.getElementById("modal-add-premium").remove();
    document.getElementById("modal-premium").remove();

    //プレミアム機能追加
    let bAddPremium = document.getElementById("b-add-premium");
    bAddPremium.addEventListener("click", function (e) {
      e.preventDefault();

      modal.show(
        "プレミアム機能追加コース決済方法選択",
        modalAddPremium.innerHTML,
        "",
        "courseUpgrade"
      );
    });

    //プレミアムコース
    let bPremium = document.getElementById("b-premium");
    bPremium.addEventListener("click", function (e) {
      e.preventDefault();
      modal.show(
        "プレミアムコース決済方法選択",
        modalPremium.innerHTML,
        "joinCreditPremium",
        ""
      );
    });
    checkJoinStatus();

    let bPack12 = document.getElementById("b-pack-12");
    bPack12.addEventListener("click", function (e) {
      e.preventDefault();
      userSettingURL("joinCreditPack12");
    });
    let bPack06 = document.getElementById("b-pack-06");
    bPack06.addEventListener("click", function (e) {
      e.preventDefault();
      userSettingURL("joinCreditPack06");
    });
    let bPack03 = document.getElementById("b-pack-03");
    bPack03.addEventListener("click", function (e) {
      e.preventDefault();
      userSettingURL("joinCreditPack03");
    });

    let bPremiumTrial = document.getElementById("b-premium-trial");
    bPremiumTrial.addEventListener("click", function (e) {
      e.preventDefault();
      userSettingURL("joinPremiumTrial");
    });

    let bAddPremiumTrial = document.getElementById("b-add-premium-trial");
    bAddPremiumTrial.addEventListener("click", function (e) {
      e.preventDefault();
      userSettingURL("courseUpgradeTrial");
    });

    let digitalCourse = document.getElementById("detail-course");
    digitalCourse.href = HOST + "member/pack_rule";

    document.body.className = "status-loaded";
  }
});

const callDart = () => {
  window.flutter_inappwebview.callHandler("subscription_upgrade");
}

const initModal = () => {
  const layer = document.createElement("div");
  layer.classList.add("modal-layer");
  const frame = document.createElement("div");
  frame.classList.add("modal-frame");
  const content = document.createElement("div");
  content.classList.add("modal-content");
  const inner = document.createElement("div");
  inner.classList.add("modal-inner");
  const header = document.createElement("div");
  header.classList.add("modal-title", "heading-bar");
  const body = document.createElement("div");
  body.classList.add("modal-body");
  const close = document.createElement("span");
  close.classList.add("modal-close");

  layer.appendChild(frame);
  frame.appendChild(inner);
  inner.appendChild(content);
  inner.appendChild(close);
  content.appendChild(header);
  content.appendChild(body);

  document.body.appendChild(layer);
  document.body.appendChild(frame);

  inner.addEventListener("click", (e) => {
    e.stopPropagation();
  });
  frame.addEventListener("click", () => {
    document.body.classList.remove("status-modal");
    body.innerHTML = "";
  });
  close.addEventListener("click", () => {
    document.body.classList.remove("status-modal");
    body.innerHTML = "";
  });

  function setClose() {
    const closeButton = document.getElementsByClassName("close-modal");
    if (closeButton.length === 0) {
      return;
    }
    for (let i = 0; i < closeButton.length; i++) {
      closeButton[i].addEventListener("click", () => {
        document.body.classList.remove("status-modal");
        body.innerHTML = "";
      });
    }
  }

  return {
    show: (title, html, credit, career) => {
      if (title) {
        header.innerHTML = title;
        header.style.display = "block";
      } else {
        header.style.display = "none";
      }

      body.innerHTML = html;
      document.body.classList.add("status-modal");

      //モーダル先のイベント指定はこちらで実施
      let creditElement = document.getElementById("b-credit");
      let careerElement = document.getElementById("b-career");

      if (creditElement) {
        creditElement.addEventListener("click", function (e) {
          userSettingURL(credit);
        });
      }
      if (careerElement) {
        careerElement.addEventListener("click", function (e) {
          userSettingURL(career);
        });
      }

      setClose();
    },
    watchMovie: (url) => {
      body.innerHTML = '<video autoplay src="' + url + '"></video>';
      header.style.display = "none";
      frame.classList.add("-movie");
      document.body.classList.add("status-modal");
    },
    showImage: (url) => {
      body.innerHTML = '<img src="' + url + '">';
      header.style.display = "none";
      frame.classList.add("-image");
      document.body.classList.add("status-modal");
    },
    showHorseWeight: (title, url) => {
      if (title) {
        header.innerHTML = title;
        header.style.display = "block";
      } else {
        header.style.display = "none";
      }
      body.innerHTML = '<iframe src="' + url + '"></iframe>';
      frame.classList.add("-weightgraph");
      document.body.classList.add("status-modal");
    },
    showURL: (title, url) => {
      if (title) {
        header.innerHTML = title;
        header.style.display = "block";
      } else {
        header.style.display = "none";
      }
      const xhr = new XMLHttpRequest();
      xhr.open("get", url);
      xhr.send();
      xhr.addEventListener("readystatechange", () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          body.innerHTML = xhr.response;
          document.body.classList.add("status-modal");
        }
      });
    },
  };
};

const checkJoinStatus = () => {
  const joinStatus = getJoinStatus();
  const joinStatusArr = joinStatus.split(",");

  //キャリア会員の場合
  if (joinStatusArr.includes("1")) {
    const premiumPack = document.getElementById("course_premium_pack");
    premiumPack.style.display = "none";

    const premium = document.getElementById("course_premium");
    premium.style.display = "none";

  //クレジット会員の場合
  } else if (joinStatusArr.includes("11")) {
    const premiumFree = document.getElementById("course_premium_free");
    premiumFree.style.display = "none";

    const premiumPlusFree = document.getElementById("course_premium_plus_free");
    premiumPlusFree.style.display = "none";

    const premiumPlus = document.getElementById("course_premium_plus");
    premiumPlus.style.display = "none";
  }
};
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">会員登録</h1>
    </section>
    <div v-if="list.content && !isWebview" v-html="list.content.rendered"></div>
    <div v-else>
      <p>アプリ会員の方は以下のボタンからプレミアム登録をお願いします。</p>
        <a
          class="btn-basic -wide -center -imporntat"
          @click="callDart()"
          >アプリ登録ページへ</a
        >
      <p>
        WEB版のエコノミーコース会員の方はブラウザよりプレミアムコースまたはプレミアム機能追加コースに登録をお願いいたします。
      </p>
    </div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/member/style.scss"></style>
