<template>
  <div class="item">
    <div class="inner">
      <div
        v-for="(breederResult, index) in raceTotalResultData"
        :key="index"
        class="layout-sub"
        :class="{ '-total': breederResult.setYear === '通算' }"
      >
        <div class="layout-title">
          <h2 class="heading-circle">{{ breederResult.setYear }}</h2>
          <div v-if="breederResult.setYear === '通算'" class="nav">
            [ 1着 2着 3着 着外 ]
          </div>
        </div>
        <div class="p-total">
          [ {{ breederResult.first }} {{ breederResult.second }}
          {{ breederResult.third }} {{ breederResult.unplaced }} ]
        </div>
        <div id="chart-total" class="graph-stack-results">
          <Chart :options="breederResult.graph" catchLegendEvents="true" />
        </div>
        <div class="container-detail">
          <table class="table-grid -center -padding -prize">
            <tbody>
              <tr>
                <td class="-center">
                  <span
                    :class="{
                      'icon-race -g1': NARFLAG === 0,
                      'icon-race -jpn1': NARFLAG === 1,
                    }"
                  ></span
                  >{{ breederResult.G1Win }}勝
                </td>
                <td class="-center">
                  <span
                    :class="{
                      'icon-race -g2': NARFLAG === 0,
                      'icon-race -jpn2': NARFLAG === 1,
                    }"
                  ></span
                  >{{ breederResult.G2Win }}勝
                </td>
                <td class="-center">
                  <span
                    :class="{
                      'icon-race -g3': NARFLAG === 0,
                      'icon-race -jpn3': NARFLAG === 1,
                    }"
                  ></span
                  >{{ breederResult.G3Win }}勝
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
          <table class="table-grid -center -padding -prize">
            <tbody>
              <tr>
                <td class="-center">
                  <dl class="item">
                    <dt>勝率</dt>
                    <dd>{{ breederResult.yearRate1st }}</dd>
                  </dl>
                </td>
                <td class="-center">
                  <dl class="item">
                    <dt>連対率</dt>
                    <dd>{{ breederResult.yearRate2nd }}</dd>
                  </dl>
                </td>
                <td class="-center">
                  <dl class="item">
                    <dt>3着内率</dt>
                    <dd>{{ breederResult.yearRate3rd }}</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>

          <ul class="list-record">
            <li>
              <dl class="item">
                <dt>本賞金</dt>
                <dd>{{ breederResult.mainEarn }}</dd>
              </dl>
            </li>
            <li>
              <dl class="item">
                <dt>付加賞金</dt>
                <dd>{{ breederResult.addEarn }}</dd>
              </dl>
            </li>
            <li>
              <dl v-if="NARFLAG === 0" class="item">
                <dt>特別</dt>
                <dd>{{ breederResult.SPWin }}勝</dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { Chart } = process.browser ? require("highcharts-vue") : null;
</script>
<script>
import {
  rateFirst,
  rateSecond,
  rateThird,
  prizeToString,
  isNar,
} from "../../../assets/js/common.js";
export default {
  data() {
    return {
      raceTotalResultData: [],
      NARFLAG: null,
    };
  },
  props: {
    careerStatData: Object,
  },
  created() {
    let self = this;
    self.NARFLAG = isNar();

    for (let i in self.careerStatData) {
      let targetStat = self.careerStatData[i];

      const first = Number(targetStat.Place1st);
      const second = Number(targetStat.Place2nd);
      const third = Number(targetStat.Place3rd);
      const unplaced = Number(targetStat.PlaceAll) - first - second - third;
      const total = Number(targetStat.PlaceAll);

      //年度別率
      const yearRate1st =
        (rateFirst(first, total) * 100).toFixed(1).toString() + "%";
      const yearRate2nd =
        (rateSecond(first, second, total) * 100).toFixed(1).toString() + "%";
      const yearRate3rd =
        (rateThird(first, second, third, total) * 100).toFixed(1).toString() +
        "%";

      let setYear = targetStat.YEAR + "年";
      if (String(targetStat.YEAR) === "9999") {
        //YEARが9999の場合は通算とする
        setYear = "通算";
      }

      //グラフの作成
      targetStat.graph = setGraph(first, second, third, unplaced);
      const graph = targetStat.graph;

      //データ格納
      const totalResults = {
        setYear: setYear,
        first: first,
        second: second,
        third: third,
        unplaced: unplaced,
        G1Win: targetStat.G1Win,
        G2Win: targetStat.G2Win,
        G3Win: targetStat.G3Win,
        yearRate1st: yearRate1st,
        yearRate2nd: yearRate2nd,
        yearRate3rd: yearRate3rd,
        mainEarn: prizeToString(targetStat.MainEarn),
        addEarn: prizeToString(targetStat.AddEarn),
        SPWin: targetStat.SPWin,
        graph: graph,
      };

      self.raceTotalResultData.push(totalResults);
    }

    function setGraph(first, second, third, unplaced) {
      first = parseInt(first, 10);
      second = parseInt(second, 10);
      third = parseInt(third, 10);
      unplaced = parseInt(unplaced, 10);
      let racetrackChartData = {
        credits: {
          enabled: false,
        },
        chart: {
          type: "bar",
          margin: 0,
          width: 400,
          height: 20,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: ["通算"],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
          },
          lineColor: "#fff",
          gridLineWidth: 0,
        },
        yAxis: {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            enabled: false,
          },
          gridLineWidth: 0,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            enableMouseTracking: false,
            stacking: "percent",
            borderWidth: 0,
            pointPadding: 0,
            groupPadding: 0,
            dataLabels: {
              enabled: false,
              color: "white",
            },
          },
        },
        series: [
          { name: "着外", data: [unplaced], color: "#aaa" },
          { name: "3着", data: [third], color: "#FFCC00" },
          { name: "2着", data: [second], color: "#2F93C6" },
          { name: "1着", data: [first], color: "#D13636" },
        ],
      };
      return racetrackChartData;
    }
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>
