<script setup>
import {onMounted, onServerPrefetch, watch} from "vue";
import {storeToRefs} from "pinia";
import {useStoreCampaignArticle} from "../../stores/campaign-states"
import {fetch as fetchArticle} from "../../functions/campaign/article";
import {useRoute} from "vue-router";

const store = useStoreCampaignArticle();
const { title, content, date } = storeToRefs(store);
const route = useRoute();

onServerPrefetch(async () => {
  await fetchArticle(route.query.id);
})

watch(route, async (from, to) => {
  document.body.className = "";
  await fetchArticle(to.query.id);
  document.body.className = "status-loaded";
})

onMounted(async () => {
  window.scrollTo(0,0);
  document.body.className = "";
  if(!store.hasArticle() || !store.isSameArticle(route.query.id)) {
    await fetchArticle(route.query.id);
  }
  document.body.className = "status-loaded";
});
</script>
<template>
  <main class="layout-main">
    <template v-if="title">
      <section
        class="layout-pageheader -default -editpage"
      >
        <h1 class="heading-page">{{ title }}</h1>
        <time v-if="date" class="container-meta"
          >{{
            date.slice(0, 4) +
            "." +
            date.slice(5, 7) +
            "." +
            date.slice(8, 10) +
            " " +
            date.slice(11, 13) +
            ":" +
            date.slice(14, 16)
          }}<span class="label">更新</span></time
        >
      </section>

      <section v-if="content" class="layout-section">
        <div class="edit-block">
          <div class="body" v-html="content"></div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="layout-section">
        <div class="edit-block">
          <div class="body">
            <div class="inner">
              <p class="system-404">Not Found</p>
            </div>
          </div>
        </div>
      </section>
    </template>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
