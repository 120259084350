<script setup>
import { ref, onMounted } from "vue";
import {
  getJoinStatus,
  getMemberPayType,
  getMenmerCourse,
  setCookie,
  PAYTYPE,
  USERTYPE,
  JOINSTATUS,
  PAYINGNONE,
  PAYINGPREMIUM,
  userSettingURL,
} from "../../../assets/js/common";
import { HOST } from "../../../assets/js/define";

const isLinked = ref(0);
const isCareer = ref(false);
const isCredit = ref(false);

const currentLoginLink = ref("");
const loginedLink = ref("");

onMounted(() => {
  //DEBUG 紐付け済みの場合
  //setCookie(JOINSTATUS, "1,10");
  //setCookie(PAYTYPE, 1);

  //DEBUG 統合可能 && クレジット決済
  //setCookie(JOINSTATUS, "1");
  //setCookie(PAYTYPE, 1);

  //DEBUG 統合可能 && キャリア決済
  // setCookie(JOINSTATUS, "1");
  // setCookie(PAYTYPE, 2);
  //setCookie(USERTYPE, PAYINGNONE);
  //setCookie(USERTYPE, PAYINGPREMIUM);

  let joinStatus = getJoinStatus();
  let joinStatusArr = joinStatus.split(",");

  if (getMenmerCourse() === "none") {
    location.href = "/member/migrate/nonmember";
  } else {
    if (
      (joinStatusArr.indexOf("1") >= 0 ||
        joinStatusArr.indexOf("2") >= 0 ||
        joinStatusArr.indexOf("3") >= 0 ||
        joinStatusArr.indexOf("4") >= 0 ||
        joinStatusArr.indexOf("5") >= 0) &&
      (joinStatusArr.indexOf("10") >= 0 ||
        joinStatusArr.indexOf("11") >= 0 ||
        joinStatusArr.indexOf("12") >= 0)
    ) {
      // 既に会員情報が紐づけられている
      isLinked.value = 1;
    } else {
      // 会員情報の統合が可能
      isLinked.value = 2;

      const payType = getMemberPayType();

      if (payType === "1") {
        //クレジット決済ユーザ
        isCredit.value = true;
        currentLoginLink.value = "migrateFromCredit";
        loginedLink.value = "migrateToCarrer";
      } else {
        //キャリア決済ユーザ
        isCareer.value = true;
        currentLoginLink.value = "migrateFromCarrer";
        loginedLink.value = "migrateToCredit";
      }
    }
  }
});
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">会員情報統合</h1>
    </section>

    <section class="layout-section">
      <div class="inner" v-if="isLinked === 1">
        <div class="layout-sub">
          <p>
            本機能は、キャリア決済とクレジット決済の有料コースに、それぞれ別の会員として入会されたお客様が、会員情報を統合する機能をご提供しております。お客様はすでに会員情報の統合を実施しておりますので、本機能はご利用になれません。
          </p>

          <a href="/" class="btn-basic -wide -center">TOPへ戻る</a>
        </div>
      </div>
      <div class="inner" v-if="isLinked === 2">
        <div class="layout-sub">
          <p>
            本機能は、キャリア決済とクレジット決済の有料コースに、それぞれ別の会員として入会されたお客様が、会員情報を統合する機能をご提供しております。
          </p>

          <p v-if="isCareer === true">
            現在キャリア決済でご利用されており、クレジット決済による有料コースにも入会されているお客様は、本画面より会員情報を引継ぐことができます。<br />※
            キャリア決済同士、またはクレジット決済同士のユーザの引継ぎは対象外となります。
          </p>
          <p v-if="isCredit === true">
            現在クレジット決済でご利用されており、キャリア決済による有料コースにも入会されているお客様は、本画面より会員情報を引継ぐことができます。<br />※
            キャリア決済同士、またはクレジット決済同士のユーザの引継ぎは対象外となります。
          </p>

          <p>引継ぎ対象は以下の設定情報になります。</p>
          <ul class="list-disc">
            <li>お気に入り情報</li>
            <li>POG登録情報</li>
            <li>My印設定情報</li>
            <li>収支管理情報</li>
            <li>記念日馬券</li>
            <li>馬券コレクション</li>
          </ul>
          <p>
            会員情報の引継ぎをご希望のお客様は、以下のボタンより引き継ぎ先（または引継ぎ元）のアカウントでのログインを行ってください。
          </p>
          <ul class="list-box -padding">
            <li>
              <h2 class="heading-item _center">
                ログイン済みのユーザ情報を利用する
              </h2>
              <p>
                現在ログインしているユーザ情報を、これからログインするユーザの情報に引き継ぎます。
              </p>
              <a
                @click="userSettingURL(currentLoginLink)"
                class="btn-basic -wide -center"
                >会員情報を統合する</a
              >
            </li>
            <li>
              <h2 class="heading-item _center">
                ログイン後のユーザ情報を利用する
              </h2>
              <p>
                これからログインするユーザの情報を、現在ログインしているユーザに引き継ぎます。
              </p>
              <a
                @click="userSettingURL(loginedLink)"
                class="btn-basic -wide -center"
                >会員情報を統合する</a
              >
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>
