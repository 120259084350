<script setup>
import { reactive, onMounted, onUpdated } from "vue";
import {BACKWORDPATH, HOST, JRA_WP_HOST, POGAPIPATH} from "../../assets/js/define";
import {
  getJSON, getUserId,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  retrieveCourse,
} from "../../assets/js/common";

import axios from "axios";

//本番用 APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=join";

let list = reactive({});
onMounted(() => {
  document.body.className = "";
  const request_url = API_URL;
  axios
    .get(request_url)
    .then((response) => {
      Object.assign(list, response.data[0]);
    })
    .catch((error) => {
      console.log(error);
    });
});

onUpdated(() => {
  //DOM取得
  let bannerLink = document.getElementById("join-pog");
  let memberLink01 = document.getElementById("regist-user01");
  let memberLink02 = document.getElementById("regist-user02");

  if (bannerLink === null || memberLink01 === null || memberLink02 === null) {
    document.body.className = "status-loaded";
  }
  //POG情報リンク
  let pogLink = document.getElementById("regist-pog");

  pogLink.href = HOST + "pog/info";

  //非会員の場合は会員登録画面へ
  callRetrieveCourse(function (courseId, showMarks, userId) {
    if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
      // 無課金ユーザ会員登録ページ
      bannerLink.href = HOST + "member";
      memberLink01.href = HOST + "member";
      memberLink02.href = HOST + "member";
    } else {
      userId = userId || getUserId();
      const request =
          HOST +
          POGAPIPATH +
          "getPogUserInfo.js" +
          BACKWORDPATH +
          "?UserID=" +
          userId;
      getJSON(request, true, function (status, json) {
        if (json && json !== 1) {
          //POG会員の場合はマイページ
          bannerLink.href = HOST + "pog/mypage";
          memberLink01.href = HOST + "pog/mypage";
          memberLink02.href = HOST + "pog/mypage";
        } else {
          //POG会員未登録の場合は登録ページ
          bannerLink.href = HOST + "pog/name";
          memberLink01.href = HOST + "pog/name";
          memberLink02.href = HOST + "pog/name";
        }
      });
    }
    document.body.className = "status-loaded";
  });
});

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">POG ユーザー登録</h1>
    </section>
    <div v-if="list.content" v-html="list.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/pog/style.scss" scoped></style>
