<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">WIN!競馬メール配信 設定</h1>
    </section>

    <section class="layout-section">
      <div class="inner">
        <h2 class="heading-circle">メール配信設定</h2>
        <p class="p-message">
          未認証です。<br />
          ページ上部
          <img src="/assets/images/common/gnav/login.svg" class="icon-img" />
          からログインしてください。
        </p>
      </div>
    </section>
  </main>
</template>
