import {BACKWORDPATH, HOST, PATH} from "../../assets/js/define";
import {getJSON, prizeToString, SUCCESS,} from "../../assets/js/common";
import {dataBigPrizeWins, setOddsHtml, weeklyRacing,} from "../../assets/js/data-common";
import {storeToRefs} from "pinia";
import {useStoreCommon} from "../../stores/common-states"
import {useStoreOwnerData} from "../../stores/data/owner-states";

let storeOwnerData = null;
let narFlag = 0;

export async function fetch(horseOwnerCd) {
  const storeCommon = useStoreCommon()
  const {host, isNarSite} = storeToRefs(storeCommon);
  storeOwnerData = useStoreOwnerData();
  narFlag = isNarSite.value ? 1 : 0;

  await dataOwner(horseOwnerCd, host.value, isNarSite.value);
  await getWeeklyRacing(horseOwnerCd, host.value);
  await getBigPrizeWins(horseOwnerCd, host.value);
  await getListBelongHorse(horseOwnerCd, host.value);
}

//馬主データ
const dataOwner = async (horseOwnerCd, host, isNar) => {
  const {
    horseOwnerCdValue,
    ownerData,
    careerStatData,
  } = storeToRefs(storeOwnerData);

  let request =
    host +
    PATH +
    "Master/horseOwnerMasterAPI.js" +
    BACKWORDPATH +
    "?HorseOwnerCd=" +
    horseOwnerCd +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status !== SUCCESS || !json?.length) {
      return;
    }
    try {
      let useData = json[0];
      horseOwnerCdValue.value = useData.HorseOwnerCd;

      //賞金額を文字列（*億*万円）に整形する
      let latestAnnualEarnings = '-'
      if (useData.LatestAnnualEarnings && useData.LatestAnnualEarnings > 0) {
        latestAnnualEarnings = Number(useData.LatestAnnualEarnings);
        latestAnnualEarnings = prizeToString(latestAnnualEarnings);
      }
      useData.LatestAnnualEarnings = latestAnnualEarnings;

      // 本年勝利
      let raceWins = useData.CurrentYearRaceWins && useData.CurrentYearRaceWins > 0 ?
        useData.CurrentYearRaceWins + "勝" : "-勝";

      // ランキングのURL生成
      let leadUrl = host + "leading/owner?rank=" + useData.LatestWinsRanking;

      //勝負服設定
      useData.Style = ""
      if (useData.UniformURL) {
        let uni1 = ""
        let uni2 = ""
        let uni3 = ""
        let uni4 = ""
        const uniformString = useData.UniformURL + "";
        const uniArray = uniformString.split("，");
        //色
        const colorId = {
          "白": "01", "黒": "02", "赤": "03", "青": "04",
          "黄": "05", "緑": "06", "桃": "07", "水色": "08",
          "紫": "09", "薄紫": "10", "鼠": "11", "海老": "12",
          "茶": "13"
        };
        //胴模様
        const bodyId = {
          "一本輪": "body_line1",
          "二本輪": "body_line2",
          "三本輪": "body_line3",
          "一文字": "itimonnji",
          "山形一文字": "yamagataitimonnji",
          "山形一本輪": "body_yamagataline1",
          "山形二本輪": "body_yamagataline2",
          "山形三本輪": "body_yamagataline3",
          "菱山形": "body_hishigata",
          "帯": "body_obi",
          "山形帯": "body_yamagataobi",
          "襷": "body_tasuki",
          "十字襷": "body_cross",
          "縦縞": "body_stripe",
          "格子": "body_check",
          "元禄": "body_genroku",
          "ダイヤモンド": "body_diamond",
          "うろこ": "body_uroko",
          "井桁絣": "body_igetagasuri",
          "玉霰": "body_tamaarare",
          "星散": "body_star",
          "蛇目散": "body_jame",
          "銭形散": "body_zenigata",
          "鋸歯形": "body_nokogiri"
        }
        //袖模様
        const sleeveId = {
          "一本輪": "sleeve_line1",
          "二本輪": "sleeve_line2",
          "三本輪": "sleeve_line3",
          "山形一本輪": "sleeve_yamagataline1",
          "山形二本輪": "sleeve_yamagataline2",
          "山形三本輪": "sleeve_yamagataline3",
          "菱山形": "sleeve_hishigata",
          "縦縞": "sleeve_stripe",
          "格子": "sleeve_check",
          "元禄": "sleeve_genroku",
          "ダイヤモンド": "sleeve_diamond",
          "うろこ": "sleeve_uroko",
          "井桁絣": "sleeve_igetagasuri",
          "玉霰": "sleeve_tamaarare",
          "星散": "sleeve_star",
          "蛇目散": "sleeve_jame",
          "銭形散": "sleeve_zenigata"
        }

        //胴模様設定 引数値は必ず胴様色＋模様　例：白一本輪、白縦縞
        function setBodyPattern(bodyString) {
          //先頭１文字ないし２文字は袖色
          //1文字目が色を想定
          var checkColor = colorId[bodyString.substr(0, 1)];
          var checkPattern = bodyId[bodyString.substr(1)];
          if (!checkColor) {
            //１文字目だけで色とならない場合、1～2文字目を設定
            checkColor = colorId[bodyString.substr(0, 2)];
            checkPattern = bodyId[bodyString.substr(2)];
          }
          if (checkPattern) {
            uni3 = HOST + "shared/img/uniform/" + checkPattern + "_" + checkColor + ".png"
          } else {
            //JRAのルール外の場合
            uni1 = HOST + "shared/img/uniform/NO-IMAGE_syoubufuku.png"
          }
        }

        //袖色設定 引数値は必ず色名＋袖　例：青袖、水色袖
        function setSleeveColor(sleeveString) {
          var checkColor = colorId[sleeveString.substr(0, 1)];
          if (!checkColor) {
            //１文字目だけで色とならない場合、1～2文字目を設定
            checkColor = colorId[sleeveString.substr(0, 2)];
          }
          if (checkColor) {
            uni2 = HOST + "shared/img/uniform/" + "sleeve_color_" + checkColor + ".png"
          } else {
            //JRAのルール外の場合
            uni1 = HOST + "shared/img/uniform/NO-IMAGE_syoubufuku.png"
          }
        }

        //袖模様設定 引数値は必ず模様色＋模様　例：黄うろこ、白縦縞
        function setSleevePattern(sleeveString) {
          //先頭１文字ないし２文字は袖色
          //1文字目が色を想定
          var checkColor = colorId[sleeveString.substr(0, 1)];
          var checkPattern = sleeveId[sleeveString.substr(1)];
          var sleeveSubstr = sleeveString.substr(1);
          if (!checkColor) {
            //１文字目だけで色とならない場合、1～2文字目を設定
            checkColor = colorId[sleeveString.substr(0, 2)];
            checkPattern = sleeveId[sleeveString.substr(2)];
            sleeveSubstr = sleeveString.substr(2);
          }

          if (checkPattern) {
            uni4 = HOST + "shared/img/uniform/" + checkPattern + "_" + checkColor + ".png"
          } else if (sleeveSubstr == "一文字" || sleeveSubstr == "山形一文字" || sleeveSubstr == "帯" ||
            sleeveSubstr == "山形帯" || sleeveSubstr == "襷" || sleeveSubstr == "十字襷" || sleeveSubstr == "鋸歯形") {
            //胴だけで袖に無い模様は何もしない
          } else {
            //JRAのルール外の場合
            uni1 = HOST + "shared/img/uniform/NO-IMAGE_syoubufuku.png"
          }
        }

        if (uniArray.length > 0) {
          //b.一番左は胴色で確定なので配置、袖色も胴色と同じ可能性が高いので配置
          var baseColor = colorId[uniArray[0]];
          if (baseColor) {
            uni1 = host + "shared/img/uniform/" + "body_color_" + baseColor + ".png"
            uni2 = host + "shared/img/uniform/" + "sleeve_color_" + baseColor + ".png"
            if (uniArray.length > 1) {
              var secondChar = uniArray[1];
              var thirdChar = "";
              if (uniArray.length > 2) thirdChar = uniArray[2];
              if (secondChar.substr(0, 1) == "袖") {
                //3番目がある場合（黄鋸歯形）
                if (uniArray.length > 2) {
                  setBodyPattern(thirdChar);
                  setSleevePattern(secondChar.substr(1));
                } else {
                  //	◎1.２番目の文字の中の先頭文字が「袖」の場合（I：袖模様）
                  setSleevePattern(secondChar.substr(1));
                }

              } else if (secondChar.substr(secondChar.length - 1, 1) == "袖") {
                //	2.２番目の文字の最後が「袖」の場合
                if (uniArray.length == 2) {
                  //◎2-1.３番目がない場合（F：袖色）
                  setSleeveColor(secondChar);
                } else {
                  //"黄，青袖，赤二本輪"
                  //2-2.３番目がある場合（G：袖色、模様）
                  setSleeveColor(secondChar);
                  setSleevePattern(thirdChar);
                  setBodyPattern(thirdChar);
                }
              } else if (secondChar.indexOf("袖") > 0) {
                if (uniArray.length == 2) {
                  //3-1.２番目の文字の中間に「袖」の場合（H:袖色袖模様）
                  var splitarray = secondChar.split("袖");
                  setSleeveColor(splitarray[0]);
                  setSleevePattern(splitarray[1]);
                } else {
                  //3-2.３番目がある場合（J:袖色 袖模様, 模様）※鋸歯限定
                  var splitarray = secondChar.split("袖");
                  setSleeveColor(splitarray[0]);
                  setSleevePattern(splitarray[1]);
                  setBodyPattern(thirdChar);
                }
              } else {
                //	4.２番目に「袖」が含まれない場合
                if (uniArray.length == 2) {
                  // ◎4-1.３番目が無い場合（B:模様）
                  setBodyPattern(secondChar);
                  setSleevePattern(secondChar);

                } else {
                  //4-2.３番目がある場合
                  var thirdChar = uniArray[2] + "";
                  if (thirdChar.substr(0, 1) == "袖") {
                    // 4-2-1.３番目の文字の先頭に「袖」の場合（E:模様、袖模様）
                    setBodyPattern(secondChar);
                    setSleevePattern(thirdChar.substr(1));
                  } else if (thirdChar.substr(thirdChar.length - 1, 1) == "袖") {
                    // 4-2-2.３番目の文字の最後が「袖」の場合（C:模様、袖色）
                    setBodyPattern(secondChar);
                    setSleeveColor(thirdChar);
                  } else if (thirdChar.indexOf("袖") > 0) {
                    // 4-2-3.３番目の文字の中間が「袖」の場合（D:模様、袖色袖模様）
                    setBodyPattern(secondChar);
                    var splitarray = thirdChar.split("袖");
                    setSleeveColor(splitarray[0]);
                    setSleevePattern(splitarray[1]);
                  }
                }
              }
            }
          }
        }
        useData.Style = 'background-image:' +
          `url(${host}shared/img/uniform/fuku-waku.png),` +
          'url(' + uni4 + '),' +
          'url(' + uni3 + '),' +
          'url(' + uni2 + '),' +
          'url(' + uni1 + ')'
      }

      // ランキングのHTML生成
      if (
        useData.LatestWinsRanking &&
        useData.LatestWinsRanking > 0
      ) {
        useData.LatestWinsRanking = raceWins +
          "(" +
          "<a href='" +
          leadUrl +
          "'>" +
          useData.LatestWinsRanking +
          "位</a>" +
          ")";
      } else {
        useData.LatestWinsRanking = raceWins + '(-位)'
      }

      let str;
      if (useData.LeadingUpdated && 8 <= useData.LeadingUpdated.length) {
        let updatedL = useData.LeadingUpdated;
        str =
          updatedL.slice(0, 4) +
          "年" +
          updatedL.slice(4, 6) +
          "月" +
          updatedL.slice(6, 8) +
          "日";
        if (updatedL.length > 8 && !isNar) {
          str += ' ' + updatedL.slice(8, 10) + ':' + updatedL.slice(10, 12);
        }
      }
      useData.LeadingUpdated = "成績集計　" + str + "現在";

      ownerData.value = useData;
      careerStatData.value = useData.CareerStat;
    } catch (e) {
      console.log("取得データの表示に失敗しました: " + e);
    }
  });
}

//今週出走データ
const getWeeklyRacing = async (mainKey, host) => {
  const {
    schedule,
    totalRaces,
  } = storeToRefs(storeOwnerData);

  const viewKbn = "2";
  const response = await weeklyRacing(viewKbn, mainKey, host, narFlag);
  schedule.value = await setOddsHtml(response?.schedule, host, narFlag);
  totalRaces.value = response?.totalRaces;
}

//重賞勝鞍データ取得
const getBigPrizeWins = async (mainKey, host) => {
  const {
    bigPrizeData,
  } = storeToRefs(storeOwnerData);

  const viewKbn = "2";
  bigPrizeData.value = await dataBigPrizeWins(viewKbn, mainKey, host, narFlag)
}

//馬主所有馬一覧
const getListBelongHorse = async (horseOwnerCd, host) => {
  const {
    belongHorse,
  } = storeToRefs(storeOwnerData);

  let request =
    host +
    PATH +
    "Master/getListBelongHorse.js" +
    BACKWORDPATH +
    "?HorseOwnerCd=" +
    horseOwnerCd +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json?.length) {
      belongHorse.value = json;
    }
  });
}
