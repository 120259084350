<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">収支管理</h1>
    </section>

    <section class="layout-section">
      <div>
        <div class="nav-tab -fit">
          <ul>
            <li class="-current">
              <a class="label" href="/balance/">年間別収支データ</a>
            </li>
            <li>
              <a class="label" href="/balance/category/">カテゴリ別データ</a>
            </li>
          </ul>
        </div>
        <div class="container-tabtarget">
          <div class="layout-title">
            <h2 class="heading-circle -white">{{ headData.title }}</h2>
            <div class="nav">
              <a :href="headData.url" class="btn-basic">月収支一覧</a>
            </div>
          </div>

          <div class="inner">
            <div class="layout-sub">
              <h3 class="heading-bar">日別収支合計</h3>
              <table class="table-cell">
                <tbody>
                  <tr>
                    <th>購入額</th>
                    <td class="amount">{{ headData.purchase ?? 0 }}円</td>
                    <th>払戻額</th>
                    <td class="amount">{{ headData.refund ?? 0 }}円</td>
                  </tr>
                  <tr>
                    <th>回収率</th>
                    <td class="per">{{ headData.recovery ?? 0 }}%</td>
                    <th>返還金</th>
                    <td class="amount">{{ headData.returned ?? 0 }}円</td>
                  </tr>
                  <tr>
                    <th>収支合計</th>
                    <td
                      v-bind:class="`amount ${
                        parseInt(headData.sum) > 0 ? '-plus' : '-minus'
                      }`"
                      colspan="3"
                    >
                      {{ headData.sum ?? 0 }}円
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="inner">
            <h3 class="heading-bar">日別詳細</h3>
            <div class="layout-sub">
              <table class="table-cell -center -dailydetails">
                <table v-for="(data, index) in raceData" :key="index">
                  <thead>
                    <tr>
                      <th colspan="4" class="-left header">
                        {{ data.headTitle }}
                      </th>
                    </tr>
                    <tr v-if="String(data.methods) === '0'">
                      <th class="-kaimoku">買い目</th>
                      <th class="-odds">オッズ</th>
                      <th class="-amount">購入額</th>
                      <th class="-check">
                        <span class="form-checkbox -center -zen">
                          <label>
                            <input
                              type="checkbox"
                              v-model="delAllChecked"
                              v-bind:value="`${data.checkboxId}`"
                              name=""
                              v-bind:id="`${data.checkboxId}`"
                              v-on:change="
                                AllChecked(data.checkboxId, data.race)
                              "
                            />
                            <i></i>
                          </label>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="String(data.methods) === '1'">
                      <th class="-kaimoku">買い目</th>
                      <th class="-amount">購入<br />点数</th>
                      <th class="">購入額</th>
                      <th class="-check">
                        <span class="form-checkbox -center -zen">
                          <label>
                            <input
                              type="checkbox"
                              v-model="delAllChecked"
                              v-bind:value="`${data.checkboxId}`"
                              name=""
                              v-bind:id="`${data.checkboxId}`"
                              v-on:change="
                                AllChecked(data.checkboxId, data.race)
                              "
                            />
                            <i></i>
                          </label>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="String(data.methods) === '2'">
                      <th class="-kaimoku">買い目</th>
                      <th class="-amount">購入点数</th>
                      <th class="">購入額</th>
                      <th class="-check">
                        <span class="form-checkbox -center -zen">
                          <label>
                            <input
                              type="checkbox"
                              v-model="delAllChecked"
                              v-bind:value="`${data.checkboxId}`"
                              name=""
                              v-bind:id="`${data.checkboxId}`"
                              v-on:change="
                                AllChecked(data.checkboxId, data.race)
                              "
                            />
                            <i></i>
                          </label>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-for="(race, index) in data.race" :key="index">
                    <!-- 2列以上の買い目の時に1番目の買い目を表示 -->
                    <tr
                      v-if="
                        String(race.lines) === '2' || String(race.lines) === '3'
                      "
                    >
                      <td class="-kaimoku">
                        <dl class="list-order">
                          <dt>{{ race.firstTitle }}</dt>
                          <dd>
                            <i
                              v-for="(left, indexs) in race.left"
                              :key="indexs"
                              v-bind:class="`icon-umaban -waku0${left.wNo}`"
                            >
                              {{ left.betCheck }}
                            </i>
                            {{ race.same_bracket }}
                          </dd>
                        </dl>
                      </td>
                      <td v-if="String(race.lines) === '2'" rowspan="2">
                        {{ race.ten ?? 0 }}点
                      </td>
                      <td
                        v-if="String(race.lines) === '2'"
                        rowspan="2"
                        class="-right"
                      >
                        {{ race.buy1 ?? 0 }}<br />{{ race.buy2 }}
                      </td>
                      <td v-if="String(race.lines) === '3'" rowspan="3">
                        {{ race.ten ?? 0 }}点
                      </td>
                      <td
                        v-if="String(race.lines) === '3'"
                        rowspan="3"
                        class="-right"
                      >
                        {{ race.buy1 ?? 0 }}<br />{{ race.buy2 }}
                      </td>
                      <td rowspan="4" class="-check -borderbottom">
                        <span class="form-checkbox -center">
                          <label
                            ><input
                              type="checkbox"
                              v-model="delChecked"
                              v-bind:value="`${race.id}`"
                              name=""
                              v-bind:id="`${race.id}`" /><i></i
                          ></label>
                        </span>
                      </td>
                    </tr>
                    <!-- 2列以上の買い目の時に2番目の買い目を表示 -->
                    <tr
                      v-if="
                        String(race.lines) === '2' || String(race.lines) === '3'
                      "
                    >
                      <td>
                        <dl class="list-order">
                          <dt>{{ race.secondTitle }}</dt>
                          <dd>
                            <i
                              v-for="(center, indexs) in race.center"
                              :key="indexs"
                              v-bind:class="`icon-umaban -waku0${center.wNo}`"
                            >
                              {{ center.betCheck }}
                            </i>
                          </dd>
                        </dl>
                      </td>
                    </tr>
                    <!-- 3列以上の買い目の時に3番目の買い目を表示 -->
                    <tr v-if="String(race.lines) === '3'">
                      <td>
                        <dl class="list-order">
                          <dt>{{ race.thirdTitle }}</dt>
                          <dd>
                            <i
                              v-for="(right, indexs) in race.right"
                              :key="indexs"
                              v-bind:class="`icon-umaban -waku0${right.wNo}`"
                            >
                              {{ right.betCheck }}
                            </i>
                          </dd>
                        </dl>
                      </td>
                    </tr>
                    <tr v-if="String(race.lines) === '1'">
                      <td v-if="String(race.type) === '0'">
                        <i v-bind:class="`icon-umaban -waku0${race.wNo}`">
                          {{ race.leftNo }}
                        </i>
                      </td>
                      <td v-if="String(race.type) === '1'">
                        <i v-bind:class="`icon-umaban -waku0${race.wNol}`">
                          {{ race.leftNo }}
                        </i>
                        <i class="sprite-icons icon-fuku">-</i>
                        <i v-bind:class="`icon-umaban -waku0${race.wNoc}`">
                          {{ race.centerNo }}
                        </i>
                      </td>
                      <td v-if="String(race.type) === '2'">
                        <i
                          v-for="(left, indexs) in race.left"
                          :key="indexs"
                          v-bind:class="`icon-umaban -waku0${left.wNo}`"
                        >
                          {{ left.betCheck }}
                        </i>
                        {{ race.same_bracket }}
                      </td>
                      <td v-if="String(race.type) === '3'">
                        <i v-bind:class="`icon-umaban -waku0${race.wNol}`">
                          {{ race.leftNo }}
                        </i>
                        <i class="sprite-icons icon-fuku">-</i>
                        <i v-bind:class="`icon-umaban -waku0${race.wNoc}`">
                          {{ race.centerNo }}
                        </i>
                        <i class="sprite-icons icon-fuku">-</i>
                        <i v-bind:class="`icon-umaban -waku0${race.wNor}`">
                          {{ race.rightNo }}
                        </i>
                      </td>
                      <td
                        v-if="
                          String(race.type) === '0' ||
                          String(race.type) === '1' ||
                          String(race.type) === '3'
                        "
                        v-bind:id="`${data.oddsId}`"
                      >
                        <span v-if="race.Odds1Color && race.Odds2Color">
                          <span :class="`txt-odds -no${race.Odds1Color}`">
                            {{ race.Odds1 }}
                          </span>
                          <span class="txt-odds -no0">-</span>
                          <span :class="`txt-odds -no${race.Odds2Color}`">
                            {{ race.Odds2 }}
                          </span>
                        </span>
                        <span v-else :class="`txt-odds -no${race.OddsColor}`">
                          {{ race.Odds }}
                        </span>
                      </td>
                      <td
                        class="-right"
                        v-if="
                          String(race.type) === '0' ||
                          String(race.type) === '1' ||
                          String(race.type) === '3'
                        "
                      >
                        {{ race.spend }}円
                      </td>
                      <td v-if="String(race.type) === '2'">
                        {{ race.ten ?? 0 }}点
                      </td>
                      <td class="-right" v-if="String(race.type) === '2'">
                        {{ race.buy1 ?? 0 }}<br />{{ race.buy2 }}
                      </td>
                      <td rowspan="2" class="-check -borderbottom">
                        <span class="form-checkbox -center">
                          <label
                            ><input
                              type="checkbox"
                              v-model="delChecked"
                              v-bind:value="`${race.id}`"
                              name=""
                              v-bind:id="`${race.id}`" /><i></i
                          ></label>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="race.returned == 0">
                      <td colspan="3" class="-payout -left -borderbottom">
                        <div class="frame">
                          <div class="total">
                            払戻額: {{ race.totalRefund ?? 0 }}円
                          </div>
                          <span
                            class="btn-basic -gray"
                            v-on:click="createTicketImage(race.id)"
                          >
                            馬券を作成する
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="3" class="-payout -left -borderbottom">
                        <div class="frame">
                          <div class="total">
                            払戻額: {{ race.totalRefund ?? 0 }}円 / 返還金：
                            {{ race.returned ?? 0 }}円
                          </div>
                          <span
                            class="btn-basic -gray"
                            v-on:click="createTicketImage(race.id)"
                          >
                            馬券を作成する
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </table>

              <nav class="nav-group -left">
                <a
                  v-on:click="deleteBalanceDaily(delChecked)"
                  class="btn-basic -delete"
                  >削除</a
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import {
  PAYINGSUGOTOKU_NONE,
  PAYINGSUGOTOKU,
  PAYINGNONE,
  SUCCESS,
  getUserId,
} from "../../assets/js/common";
import {
  getJSON,
  dayWeek,
  retrieveCourse,
  calcBracketNum,
  replaceComma,
  recoveryRate,
  getUrlVars,
  sumTotalEx,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  METHOD_NORMAL,
  METHOD_BOX,
  METHOD_WHEEL,
  METHOD_WHEEL_1,
  METHOD_WHEEL_2,
  METHOD_WHEEL_3,
  METHOD_WHEEL_1_2,
  METHOD_WHEEL_1_3,
  METHOD_WHEEL_2_3,
  METHOD_FORMATION,
  TOB_WIN,
  TOB_PLACE,
  TOB_EXACTA_MULTI,
  TOB_TRIFECTA_MULTI,
  TOB_BRACKET_EXACTA_MULTI,
  TOB_BRACKET,
  TOB_BRACKET_EXACTA,
  TOB_QUINELLA,
  TOB_EXACTA,
  TOB_WIDE,
  TOB_TRIFECTA,
  TOB_TRIO,
  JOINPATH
} from "../../assets/js/define";

export default {
  name: "PurchaseDaily",
  data() {
    return {
      //パラメータ(固定)
      HOST: HOST,
      PATH: PATH,

      headData: {},
      raceData: [],
      spend_total: null,
      refund_total: null,
      recovery_total: null,
      returned_total: null,
      total: null,
      date_info_total: null,
      delChecked: [],
      delAllChecked: [],
      courseId: '',
      userId: '',
    };
  },
  methods: {
    AllChecked(checkboxId, raceData) {
      let self = this;

      const data = raceData.map((e) => e.id);
      const checked = self.delAllChecked.includes(checkboxId);

      if (checked) {
        self.delChecked = self.delChecked.concat(data);
      } else {
        for (let i = 0; i < data.length; i++)
          self.delChecked = self.delChecked.filter(function (x) {
            return x !== data[i];
          });
      }

      self.delChecked = self.delChecked.filter(function (x, i, self) {
        return self.indexOf(x) === i;
      });
    },

    /******************************************************************
     *　日別収支削除
     ******************************************************************/
    deleteBalanceDaily(dor) {
      this.deleteDailyDetail(dor);
    },

    /******************************************************************
     *　日別収支詳細削除
     ******************************************************************/
    deleteDailyDetail(dor) {
      document.body.className = "";
      let delList = dor.join("_");
      if (delList.length == "") {
        alert("削除項目がありません。");
        document.body.className = "status-loaded";
        return;
      }

      //WEB APIへのリクエスト
      let req = "";
      if (this.courseId == PAYINGSUGOTOKU || this.courseId == PAYINGSUGOTOKU_NONE) {
        req =
          HOST +
          PATH +
          "Balance/deleteBalanceDaily4sg.js" +
          BACKWORDPATH +
          "?userId=" +
          encodeURIComponent(this.userId) +
          "&delList=" +
          delList +
          "&narFlag=" +
          isNar();
      } else {
        req =
          HOST +
          PATH +
          "Balance/deleteBalanceDaily.js" +
          BACKWORDPATH +
          "?delList=" +
          delList +
          "&narFlag=" +
          isNar();
      }
      getJSON(req, true, function (status, json) {
        if (status == SUCCESS) {
          location.reload();
        }
      });
      document.body.className = "status-loaded";
    },

    //「馬券を作成する」クリック
    createTicketImage(id) {
      document.body.className = "";
      let request =
        HOST +
        PATH +
        "TicketCollection/bettingTicketCollectionRegistAPI.js" +
        BACKWORDPATH +
        "?id=" +
        id +
        "&narFlag=" +
        isNar();
      getJSON(request, true, function (status, json) {
        let isSuccess = false;
        if (status == SUCCESS) {
          if (json != undefined && json != null && json[0].value == 0) {
            isSuccess = true;
          }
        }
        if (isSuccess) {
          alert("馬券を作成しました");
        } else {
          alert("馬券を作成の作成に失敗しました");
        }
      });
      document.body.className = "status-loaded";
    },
  },
  mounted() {
    let self = this;

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none");
      }
    }

    callRetrieveCourse(function (courseId, marks, userId) {
      if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
        location.href = HOST + JOINPATH;
      } else {
        self.courseId = courseId
        self.userId = userId || getUserId();
        // パラメータ取得
        let params = getUrlVars();
        let date = new Date();
        let argyear = date.getFullYear();
        let argmonth = ("0" + (date.getMonth() + 1)).slice(-2);
        let argdate = ("0" + date.getDate()).slice(-2);
        let argdor = argyear + argmonth + argdate;

        if (params.dor) argdor = params.dor;

        getBalancePurchase(argdor);
        getBalanceDailyDetail(argdor);
      }
    });

    /******************************************************************
     *　収支一覧取得
     ******************************************************************/
    function getBalancePurchase(argdor, callback) {
      let argyear = argdor.substr(0, 4);
      let argmonth = argdor.substr(4, 2);
      let request = "";
      if (self.courseId == PAYINGSUGOTOKU || self.courseId == PAYINGSUGOTOKU_NONE) {
        request =
          HOST +
          PATH +
          "Balance/getBalanceList4sg.js" +
          BACKWORDPATH +
          "?userId=" +
          encodeURIComponent(self.userId) +
          "&year=" +
          argyear +
          "&month=" +
          argmonth +
          "&narFlag=" +
          isNar();
      } else {
        request =
          HOST +
          PATH +
          "Balance/getBalanceList.js" +
          BACKWORDPATH +
          "?year=" +
          argyear +
          "&month=" +
          argmonth +
          "&narFlag=" +
          isNar();
      }
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          try {
            if (json != undefined && json != null && json.length > 0) {
              let headData = {};

              for (let i = 0; i < json.length; i++) {
                let type = json[i].Type;
                let year = json[i].Year;
                let month = json[i].Month;
                let dor = json[i].DOR;
                let spend = parseInt(json[i].Spend) ?? 0;
                let refund = parseInt(json[i].Refund) ?? 0;
                let returned = parseInt(json[i].Returned) ?? 0;

                if (argdor === dor) {
                  month = month.charAt(0) === "0" ? month.substring(1) : month;
                  headData.title =
                    year +
                    "年 " +
                    month +
                    "月" +
                    parseInt(dor.substr(6, 2), 10) +
                    "日";
                  headData.purchase = replaceComma(spend);
                  headData.refund = replaceComma(refund);
                  headData.recovery = replaceComma(recoveryRate(spend, refund));
                  headData.returned = replaceComma(returned);
                  headData.sum =
                    sumTotalEx(spend, refund, returned) > 0 ? "+" : "";
                  headData.sum += replaceComma(
                    sumTotalEx(spend, refund, returned)
                  );
                  headData.url =
                    "/balance/purchase_monthly?year=" +
                    year +
                    "&month=" +
                    json[i].Month;
                }
              }
              self.headData = headData;
            }
            if (callback != undefined) {
              callback();
            }

            if (!Object.keys(self.headData).length) {
              argmonth =
                argmonth.charAt(0) === "0" ? argmonth.substring(1) : argmonth;
              let headData = {};
              headData.title =
                argyear +
                "年 " +
                argmonth +
                "月" +
                parseInt(argdor.substr(6, 2), 10) +
                "日";
              headData.url =
                  "/balance/purchase_monthly?year=" +
                  argyear +
                  "&month=" +
                  argmonth;
              self.headData = headData;
            }
          } catch (e) {
            console.log("getBalancePurchase : " + e);
            document.body.className = "status-loaded";
          }
        }
      });
    }

    function getBalanceDailyDetail(dor, callback) {
      document.body.className = "";
      let request = "";
      if (self.courseId == PAYINGSUGOTOKU || self.courseId == PAYINGSUGOTOKU_NONE) {
        request =
          HOST +
          PATH +
          "Balance/getBalanceDailyDetail4sg.js" +
          BACKWORDPATH +
          "?userId=" +
          encodeURIComponent(self.userId) +
          "&dor=" +
          dor +
          "&narFlag=" +
          isNar();
      } else {
        request =
          HOST +
          PATH +
          "Balance/getBalanceDailyDetail.js" +
          BACKWORDPATH +
          "?dor=" +
          dor +
          "&narFlag=" +
          isNar();
      }
      getJSON(request, true, async function (status, json) {
        if (status == SUCCESS) {
          try {
            let spendSum = 0;
            let refundSum = 0;
            let returnedSum = 0;

            //変数初期化
            let raceData = [];
            let prevTargetData = {};
            //登録データを上から表示

            let jsonRaceInfo = [];

            for (let i = 0; i < json.length; i++) {
              let targetData = json[i];
              if (targetData == null) continue;
              if (targetData.DOR !== dor) continue;

              spendSum += parseInt(targetData.Spend);
              refundSum += parseInt(targetData.TotalRefund);
              returnedSum += parseInt(targetData.Returned);

              let reqRaceInfo =
                HOST +
                PATH +
                "RaceInfo/raceBasicInfo.js" +
                BACKWORDPATH +
                "?dor=" +
                targetData.DOR +
                "&racetrackCd=" +
                targetData.RacetrackCd +
                "&raceNum=" +
                targetData.RaceNum +
                "&narFlag=" +
                isNar();
              try {
                jsonRaceInfo.push(
                  await new Promise((resolve) => {
                    getJSON(reqRaceInfo, true, (r, data) => resolve(data));
                  })
                );
              } catch (e) {
                console.log(e);
              }
            }

            for (let i = 0; i < jsonRaceInfo.length; i++) {
              let targetData = json[i];
              if (targetData == null) continue;
              if (targetData.DOR !== dor) continue;
              const isBracket = [TOB_BRACKET, TOB_BRACKET_EXACTA, TOB_BRACKET_EXACTA_MULTI].includes(targetData.TOB);
              const isBracketExacta = [TOB_BRACKET, TOB_BRACKET_EXACTA, TOB_BRACKET_EXACTA_MULTI].includes(targetData.TOB);

              let tags = {};
              let raceInfos = jsonRaceInfo[i][0];

              //出頭数
              let raceEntryNum = raceInfos["EntryNum"];

              //１つ前表示データと開催日、開催場、レース番号、識別、方法のいずれかが異なるか、基本以外の買い方の場合
              if (
                prevTargetData.DOR != targetData.DOR ||
                prevTargetData.RacetrackCd != targetData.RacetrackCd ||
                prevTargetData.RaceNum != targetData.RaceNum ||
                prevTargetData.TOB != targetData.TOB ||
                prevTargetData.Method != targetData.Method ||
                targetData.Method != METHOD_NORMAL
              ) {
                //親のテーブルを追加する。
                targetData.tableId = "betTable" + i;
                let place = {
                  "01": "札幌",
                  "02": "函館",
                  "03": "福島",
                  "04": "新潟",
                  "05": "東京",
                  "06": "中山",
                  "07": "中京",
                  "08": "京都",
                  "09": "阪神",
                  10: "小倉",
                  30: "門別",
                  31: "北見",
                  32: "岩見",
                  33: "帯広",
                  34: "旭川",
                  35: "盛岡",
                  36: "水沢",
                  37: "上山",
                  38: "三条",
                  39: "足利",
                  40: "宇都",
                  41: "高崎",
                  42: "浦和",
                  43: "船橋",
                  44: "大井",
                  45: "川崎",
                  46: "金沢",
                  47: "笠松",
                  48: "名古屋",
                  49: "紀三",
                  50: "園田",
                  51: "姫路",
                  52: "益田",
                  53: "福山",
                  54: "高知",
                  55: "佐賀",
                  56: "荒尾",
                  57: "中津",
                  58: "札幌",
                  59: "函館",
                  60: "新潟",
                  61: "中京",
                };
                //注意：define値と同期
                let tobArray = {
                  1: "単勝",
                  2: "複勝",
                  3: "枠連",
                  4: "馬連",
                  5: "ワイド",
                  6: "馬単",
                  7: "3連複",
                  8: "3連単",
                  9: "馬単",
                  10: "3連単",
                  11: "枠単",
                  12: "枠単",
                };
                //注意：define値と同期
                let methodArr = {
                  0: "通常",
                  1: "フォーメーション",
                  2: "ボックス",
                  3: "流し",
                  4: "1着軸流し",
                  5: "2着軸流し",
                  6: "3着軸流し",
                  7: "1・2着軸流し",
                  8: "1・3着軸流し",
                  9: "2・3着軸流し",
                  10: "1頭軸流し",
                  11: "2頭軸流し"
                };
                //ヘッダ生成
                let headTitle =
                  targetData.DOR.substr(0, 4) +
                  "/" +
                  targetData.DOR.substr(4, 2) +
                  "/" +
                  targetData.DOR.substr(6, 2) +
                  " " +
                  place[targetData.RacetrackCd] +
                  targetData.RaceNum +
                  "R " +
                  tobArray[targetData.TOB];
                let Method = targetData.Method;
                if (targetData.TOB == TOB_TRIO) {
                  if (Method === "4") {
                    Method = "10";
                  } else if (Method === "5") {
                    Method = "11";
                  }
                }
                if (targetData.TOB != TOB_WIN && targetData.TOB != TOB_PLACE) {
                  headTitle += " " + methodArr[Method];
                }
                if (
                  targetData.TOB == TOB_EXACTA_MULTI ||
                  targetData.TOB == TOB_BRACKET_EXACTA_MULTI ||
                  targetData.TOB == TOB_TRIFECTA_MULTI
                ) {
                  headTitle += "マルチ";
                }
                targetData.headTitle = headTitle;

                if (targetData.Method == METHOD_NORMAL) {
                  //各種通常の場合
                  targetData.methods = 0;
                } else if (targetData.Method == METHOD_BOX) {
                  //各種ボックスの場合
                  targetData.methods = 1;
                } else {
                  //以外の場合
                  targetData.methods = 2;
                }
                if (targetData.FixDiv == "0") {
                  //払い戻し額未確定
                  targetData.tag = true;
                }
              }

              //１列目値の特定
              let leftNo = [];
              let leftFormatTags = "";
              let same_bracket = "";
              tags.left = [];
              for (let betCheck = 1; betCheck <= 18; betCheck++) {
                if (targetData.Bet.substr(betCheck - 1, 1) == "1") {
                  leftNo.push(betCheck);
                  let wNo =
                    isBracket
                      ? betCheck
                      : calcBracketNum(raceEntryNum, betCheck);
                  tags.left.push({ wNo: wNo, betCheck: betCheck });
                }
              }

              if (
                isBracket &&
                targetData.Method == METHOD_BOX
              ) {
                //枠連ボックスの場合、同枠をチェック
                let same_bracket = "";
                for (let sameCheck = 19; sameCheck <= 36; sameCheck++) {
                  if (targetData.Bet.substr(sameCheck - 1, 1) == "1") {
                    if (same_bracket != "") {
                      same_bracket += " ";
                    }
                    same_bracket += String(sameCheck - 18);
                  }
                }
                if (same_bracket != "") {
                  same_bracket = "同枠：" + same_bracket;
                }
              }

              //２列目値の特定
              let centerNo = [];
              let centerFormatTags = "";
              tags.center = [];
              for (let betCheck = 19; betCheck <= 36; betCheck++) {
                if (targetData.Bet.substr(betCheck - 1, 1) == "1") {
                  const horseNum = betCheck - 18;
                  centerNo.push(horseNum);
                  let wNo = isBracketExacta ? horseNum : calcBracketNum(raceEntryNum, horseNum);
                  tags.center.push({ wNo: wNo, betCheck: betCheck - 18 });
                }
              }

              //３列目値の特定
              let rightNo = [];
              let rightFormatTags = "";
              tags.right = [];
              for (let betCheck = 37; betCheck <= 54; betCheck++) {
                if (targetData.Bet.substr(betCheck - 1, 1) == "1") {
                  const horseNum = betCheck - 36;
                  rightNo.push(horseNum);
                  let wNo = isBracketExacta ? horseNum : calcBracketNum(raceEntryNum, horseNum);
                  tags.right.push({ wNo: wNo, betCheck: betCheck - 36 });
                }
              }

              //■明細が１行となる通常、ボックスの場合
              if (
                targetData.Method == METHOD_NORMAL ||
                targetData.Method == METHOD_BOX
              ) {
                tags.lines = 1;
                if (targetData.TOB == TOB_WIN) {
                  //単勝の場合
                  tags.type = 0;
                  tags.wNo = calcBracketNum(raceEntryNum, leftNo[0]);
                  tags.leftNo = leftNo[0];
                  tags.betCheck = "";
                  tags.spend = replaceComma(targetData.Spend);
                } else if (targetData.TOB == TOB_PLACE) {
                  //複勝の場合
                  tags.type = 0;
                  tags.wNo = calcBracketNum(raceEntryNum, leftNo[0]);
                  tags.leftNo = leftNo[0];
                  tags.betCheck = '';
                  tags.spend = replaceComma(targetData.Spend);
                } else if (
                  (targetData.TOB == TOB_BRACKET ||
                    targetData.TOB == TOB_BRACKET_EXACTA ||
                    targetData.TOB == TOB_BRACKET_EXACTA_MULTI ||
                    targetData.TOB == TOB_QUINELLA ||
                    targetData.TOB == TOB_EXACTA ||
                    targetData.TOB == TOB_EXACTA_MULTI) &&
                  targetData.Method == METHOD_NORMAL
                ) {
                  //枠連基本、枠単基本、馬連基本、馬単基本、ワイド基本の場合
                  tags.type = 1;
                  let wNol = isBracket
                      ? leftNo[0]
                      : calcBracketNum(raceEntryNum, leftNo[0]);
                  let wNoc = isBracket
                      ? centerNo[0]
                      : calcBracketNum(raceEntryNum, centerNo[0]);
                  tags.wNol = wNol;
                  tags.leftNo = leftNo[0];
                  tags.wNoc = wNoc;
                  tags.centerNo = centerNo[0];
                  tags.index = i;
                  tags.spend = replaceComma(targetData.Spend);
                } else if (
                    targetData.TOB == TOB_WIDE &&
                    targetData.Method == METHOD_NORMAL
                ) {
                  //枠連基本、馬連基本、馬単基本、ワイド基本の場合
                  tags.type = 1;
                  let wNol = isBracket
                          ? leftNo[0]
                          : calcBracketNum(raceEntryNum, leftNo[0]);
                  let wNoc = isBracket
                          ? centerNo[0]
                          : calcBracketNum(raceEntryNum, centerNo[0]);
                  tags.wNol = wNol;
                  tags.leftNo = leftNo[0];
                  tags.wNoc = wNoc;
                  tags.centerNo = centerNo[0];
                  tags.index = i;
                  tags.spend = replaceComma(targetData.Spend);
                } else if (targetData.Method == METHOD_BOX) {
                  tags.type = 2;
                  //枠連基本、馬連基本、馬単基本、ワイド、3連複、3連単ボックス基本の場合
                  let ten = parseInt(targetData.Count);
                  //各 10,000円(合計 990,000円)
                  let buy1 = "各" + replaceComma(targetData.Spend / ten) + "円";
                  let buy2 = "(合計 " + replaceComma(targetData.Spend) + "円)";

                  if (
                    targetData.TOB == TOB_BRACKET &&
                    same_bracket &&
                    same_bracket != ""
                  ) {
                    tags.same_bracket = same_bracket;
                  }
                  tags.leftFormatTags = leftFormatTags;
                  tags.ten = ten;
                  tags.buy1 = buy1;
                  tags.buy2 = buy2;
                } else if (
                  (targetData.TOB == TOB_TRIO ||
                    targetData.TOB == TOB_TRIFECTA ||
                    targetData.TOB == TOB_TRIFECTA_MULTI) &&
                  targetData.Method == METHOD_NORMAL
                ) {
                  tags.type = 3;
                  //3連複、3連単の基本
                  let wNol = calcBracketNum(raceEntryNum, leftNo[0]);
                  let wNoc = calcBracketNum(raceEntryNum, centerNo[0]);
                  let wNor = calcBracketNum(raceEntryNum, rightNo[0]);
                  tags.wNol = wNol;
                  tags.leftNo = leftNo[0];
                  tags.wNoc = wNoc;
                  tags.centerNo = centerNo[0];
                  tags.wNor = wNor;
                  tags.rightNo = rightNo[0];
                  tags.index = i;
                  tags.spend = replaceComma(targetData.Spend);
                }

                tags.returned = 0;
                tags.totalRefund = replaceComma(targetData.TotalRefund);
                tags.id = targetData.id;
                if (targetData.Returned != 0) {
                  tags.returned = replaceComma(targetData.Returned);
                }
              }
              //■明細が２行となる馬連、馬単、ワイドの流し、フォーメーション、
              // 3連複 1頭軸流し・2頭軸流し、3連単 1着軸流し・2着軸流し・3着軸流しの場合
              if (
                ((targetData.TOB == TOB_EXACTA ||
                  targetData.TOB == TOB_EXACTA_MULTI ||
                  targetData.TOB == TOB_BRACKET_EXACTA ||
                  targetData.TOB == TOB_BRACKET_EXACTA_MULTI ||
                  targetData.TOB == TOB_QUINELLA ||
                  targetData.TOB == TOB_WIDE) &&
                  (targetData.Method == METHOD_FORMATION ||
                    targetData.Method == METHOD_WHEEL ||
                    targetData.Method == METHOD_WHEEL_1 ||
                    targetData.Method == METHOD_WHEEL_2)) ||
                ((targetData.TOB == TOB_TRIO ||
                  targetData.TOB == TOB_TRIFECTA ||
                  targetData.TOB == TOB_TRIFECTA_MULTI) &&
                  (targetData.Method == METHOD_WHEEL_1 ||
                    targetData.Method == METHOD_WHEEL_2 ||
                    targetData.Method == METHOD_WHEEL_3))
              ) {
                tags.lines = 2;

                let ten = parseInt(targetData.Count);
                let firstTitle = "軸馬";
                if (targetData.Method == METHOD_FORMATION) firstTitle = "1頭目";
                if (
                  targetData.TOB == TOB_TRIO &&
                  targetData.Method == METHOD_WHEEL_1
                )
                  firstTitle = "1頭軸";
                if (
                  targetData.TOB == TOB_TRIO &&
                  targetData.Method == METHOD_WHEEL_2
                )
                  firstTitle = "2頭軸";
                if (
                  (targetData.TOB == TOB_TRIFECTA ||
                    targetData.TOB == TOB_TRIFECTA_MULTI) &&
                  targetData.Method == METHOD_WHEEL_1
                )
                  firstTitle = "1着軸";
                if (
                  (targetData.TOB == TOB_TRIFECTA ||
                    targetData.TOB == TOB_TRIFECTA_MULTI) &&
                  targetData.Method == METHOD_WHEEL_2
                )
                  firstTitle = "2着軸";
                if (
                  (targetData.TOB == TOB_TRIFECTA ||
                    targetData.TOB == TOB_TRIFECTA_MULTI) &&
                  targetData.Method == METHOD_WHEEL_3
                )
                  firstTitle = "3着軸";

                let secondTitle = "相手";
                if (targetData.Method == METHOD_FORMATION)
                  secondTitle = "2頭目";

                //各 10,000円(合計 990,000円)
                let buy1 = "各" + replaceComma(targetData.Spend / ten) + "円";
                let buy2 = "(合計 " + replaceComma(targetData.Spend) + "円)";

                tags.firstTitle = firstTitle;
                tags.leftFormatTags = leftFormatTags;
                tags.ten = ten;
                tags.buy1 = buy1;
                tags.buy2 = buy2;
                tags.secondTitle = secondTitle;
                tags.centerFormatTags = centerFormatTags;

                tags.returned = 0;
                tags.totalRefund = replaceComma(targetData.TotalRefund);
                tags.id = targetData.id;

                if (targetData.Returned != 0) {
                  tags.returned = replaceComma(targetData.Returned);
                }
              }

              //■明細が３行となる３連単、３連複の1・2着軸流し、1・3着軸流し、2・3着軸流し、フォーメーションの場合
              if (
                (targetData.TOB == TOB_TRIO ||
                  targetData.TOB == TOB_TRIFECTA ||
                  targetData.TOB == TOB_TRIFECTA_MULTI) &&
                (targetData.Method == METHOD_WHEEL_1_2 ||
                  targetData.Method == METHOD_WHEEL_1_3 ||
                  targetData.Method == METHOD_WHEEL_2_3 ||
                  targetData.Method == METHOD_FORMATION)
              ) {
                tags.lines = 3;

                let ten = parseInt(targetData.Count);

                let firstTitle = "1着軸";
                let secondTitle = "2着軸";
                let thirdTitle = "3着軸";
                if (targetData.Method == METHOD_WHEEL_2_3) firstTitle = "相手";
                if (targetData.Method == METHOD_WHEEL_1_3) secondTitle = "相手";
                if (targetData.Method == METHOD_WHEEL_1_2) thirdTitle = "相手";

                if (targetData.Method == METHOD_FORMATION) {
                  firstTitle = "1頭目";
                  secondTitle = "2頭目";
                  thirdTitle = "3頭目";
                }
                //各 10,000円(合計 990,000円)
                let buy1 = "各" + replaceComma(targetData.Spend / ten) + "円";
                let buy2 = "(合計 " + replaceComma(targetData.Spend) + "円)";
                tags.firstTitle = firstTitle;
                tags.leftFormatTags = leftFormatTags;
                tags.ten = ten;
                tags.buy1 = buy1;
                tags.buy2 = buy2;
                tags.secondTitle = secondTitle;
                tags.centerFormatTags = centerFormatTags;
                tags.thirdTitle = thirdTitle;
                tags.rightFormatTags = rightFormatTags;

                tags.returned = 0;
                tags.totalRefund = replaceComma(targetData.TotalRefund);
                tags.id = targetData.id;

                if (targetData.Returned != 0) {
                  tags.returned = replaceComma(targetData.Returned);
                }
              }

              targetData.tags = tags;
              targetData.checkboxId = "post" + i;
              targetData.amountId = "amount" + i;
              targetData.oddsId = "odds" + i;
              targetData.payoutId = "payout" + i;

              //オッズ取得用
              if (leftNo != []) {
                targetData.oddsIndexString = "dummy" + i + leftNo[0];
              }
              if (centerNo != []) {
                targetData.oddsIndexString += "_" + centerNo[0];
              }
              if (rightNo != []) {
                targetData.oddsIndexString = "_" + rightNo[0];
              }
              //変数に保存

              if (
                prevTargetData.DOR != targetData.DOR ||
                prevTargetData.RacetrackCd != targetData.RacetrackCd ||
                prevTargetData.RaceNum != targetData.RaceNum ||
                prevTargetData.TOB != targetData.TOB ||
                prevTargetData.Method != targetData.Method ||
                targetData.Method != METHOD_NORMAL
              ) {
                raceData.push({
                  race: [tags],
                  id: targetData.id,
                  tableId: targetData.tableId,
                  checkboxId: targetData.checkboxId,
                  amountId: targetData.amountId,
                  oddsId: targetData.oddsId,
                  payoutId: targetData.payoutId,
                  DOR: targetData.DOR,
                  RacetrackCd: targetData.RacetrackCd,
                  RaceNum: targetData.RaceNum,
                  TOB: targetData.TOB,
                  methods: targetData.methods,
                  Method: targetData.Method,
                  headTitle: targetData.headTitle,
                });
              } else {
                raceData[raceData.length - 1].race.push(tags);
              }

              prevTargetData = targetData;
              self.raceData = raceData;
            }

            self.spend_total = replaceComma(spendSum);
            self.refund_total = replaceComma(refundSum);
            self.recovery_total = recoveryRate(spendSum, refundSum);
            self.returned_total = replaceComma(returnedSum);
            self.total = replaceComma(
              sumTotalEx(spendSum, refundSum, returnedSum)
            );
            self.date_info_total =
              dor.substr(0, 4) +
              "年" +
              dor.substr(4, 2) +
              "月" +
              dor.substr(6, 2) +
              "日（" +
              dayWeek(dor.substr(0, 4), dor.substr(4, 2), dor.substr(6, 2)) +
              "）";

            //オッズを最新値に更新
            uploadOdds();

            if (callback != undefined) {
              callback();
            }
          } catch (e) {
            console.log(e);
            document.body.className = "status-loaded";
          }
        } else {
          document.body.className = "status-loaded";
        }
      });
    }

    //オッズ更新
    function uploadOdds() {
      //買い方基本について全オッズ再取得
      for (let i = 0; i < self.raceData.length; i++) {
        let targetData = self.raceData[i];
        for (let j = 0; j < targetData.race.length; j++) {
          //買い方が基本の場合のみ
          if (targetData.Method == METHOD_NORMAL) {
            let callJS = "Odds/winTicketOdds.js";
            if (targetData.TOB == TOB_PLACE) callJS = "Odds/placeTicketOdds.js"; //複勝
            if (targetData.TOB == TOB_BRACKET)
              callJS = "Odds/bracketQuinellaOdds.js"; //枠連
            if (targetData.TOB == TOB_BRACKET_EXACTA || targetData.TOB == TOB_BRACKET_EXACTA_MULTI)
              callJS = "Odds/bracketExactaOdds.js"; //枠単
            if (targetData.TOB == TOB_QUINELLA) callJS = "Odds/quinellaOdds.js"; //馬連
            if (targetData.TOB == TOB_WIDE) callJS = "Odds/wideOdds.js"; //ワイド
            if (targetData.TOB == TOB_EXACTA_MULTI || targetData.TOB == TOB_EXACTA)
              callJS = "Odds/exactaOdds.js"; //馬単
            if (targetData.TOB == TOB_TRIO) callJS = "Odds/trioOdds.js"; //３連複
            if (
                targetData.TOB == TOB_TRIFECTA ||
                targetData.TOB == TOB_TRIFECTA_MULTI
            )
              callJS = "Odds/trifectaOdds.js"; //３連単

            let req =
                HOST +
                PATH +
                callJS +
                BACKWORDPATH +
                "?RacetrackCd=" +
                targetData.RacetrackCd +
                "&DOR=" +
                targetData.DOR +
                "&RaceNum=" +
                targetData.RaceNum +
                "&NarFlag=" +
                isNar();
            getJSON(req, true, function (status, json, data) {
              if (status == SUCCESS) {
                //データは常に１件が正しい。
                if (json != undefined && json.length > 0) {
                  let displayData = json[0];

                  let oddsString = targetData.race[j].leftNo; //単勝、複勝

                  //馬単、枠単
                  if (targetData.TOB == TOB_EXACTA || targetData.TOB == TOB_BRACKET_EXACTA) {
                    oddsString = targetData.race[j].leftNo + "_" + targetData.race[j].centerNo;
                  }
                  //枠連、馬連、ワイド
                  if (
                      targetData.TOB == TOB_BRACKET ||
                      targetData.TOB == TOB_QUINELLA ||
                      targetData.TOB == TOB_WIDE
                  ) {
                    if (targetData.race[j].leftNo < targetData.race[j].centerNo) {
                      oddsString = targetData.race[j].leftNo + "_" + targetData.race[j].centerNo;
                    } else {
                      oddsString = targetData.race[j].centerNo + "_" + targetData.race[j].leftNo;
                    }
                  }
                  //３連単
                  if (targetData.TOB == TOB_TRIFECTA) {
                    oddsString = targetData.race[j].leftNo + "_" + targetData.race[j].centerNo + "_" + targetData.race[j].rightNo;
                  }
                  //３連複
                  if (targetData.TOB == TOB_TRIO) {
                    if (targetData.race[j].leftNo > targetData.race[j].centerNo && targetData.race[j].leftNo > targetData.race[j].rightNo) {
                      if (targetData.race[j].centerNo > targetData.race[j].rightNo) {
                        oddsString =
                            targetData.race[j].rightNo + "_" + targetData.race[j].centerNo + "_" + targetData.race[j].leftNo;
                      } else if (targetData.race[j].centerNo < targetData.race[j].rightNo) {
                        oddsString =
                            targetData.race[j].centerNo + "_" + targetData.race[j].rightNo + "_" + targetData.race[j].leftNo;
                      }
                    } else if (
                        targetData.race[j].centerNo > targetData.race[j].leftNo &&
                        targetData.race[j].centerNo > targetData.race[j].rightNo
                    ) {
                      if (targetData.race[j].leftNo > targetData.race[j].rightNo) {
                        oddsString =
                            targetData.race[j].rightNo + "_" + targetData.race[j].leftNo + "_" + targetData.race[j].centerNo;
                      } else if (targetData.race[j].leftNo < targetData.race[j].rightNo) {
                        oddsString =
                            targetData.race[j].leftNo + "_" + targetData.race[j].rightNo + "_" + targetData.race[j].centerNo;
                      }
                    } else if (
                        targetData.race[j].rightNo > targetData.race[j].leftNo &&
                        targetData.race[j].rightNo > targetData.race[j].centerNo
                    ) {
                      if (targetData.race[j].leftNo > targetData.race[j].centerNo) {
                        oddsString =
                            targetData.race[j].centerNo + "_" + targetData.race[j].leftNo + "_" + targetData.race[j].rightNo;
                      } else if (targetData.race[j].leftNo < targetData.race[j].rightNo) {
                        oddsString =
                            targetData.race[j].leftNo + "_" + targetData.race[j].centerNo + "_" + targetData.race[j].rightNo;
                      }
                    }
                  }

                  //オッズ設定
                  if (targetData.TOB == TOB_PLACE || targetData.TOB == TOB_WIDE) {
                    let odds1 = displayData["MinOdds" + oddsString]
                    let odds1Color = '0';
                    if (Number(odds1) < 10) {
                      odds1Color = '1';
                    } else if (Number(odds1) < 100) {
                      odds1Color = '2';
                    }
                    let odds2 = displayData["MaxOdds" + oddsString]
                    let odds2Color = '0';
                    if (Number(odds2) < 10) {
                      odds2Color = '1';
                    } else if (Number(odds2) < 100) {
                      odds2Color = '2';
                    }
                    targetData.race[j].Odds1 = odds1;
                    targetData.race[j].Odds2 = odds2;
                    targetData.race[j].Odds1Color = odds1Color;
                    targetData.race[j].Odds2Color = odds2Color;
                  } else {
                    //その他
                    if (displayData["Odds" + oddsString] !== undefined) {
                      targetData.race[j].Odds = displayData["Odds" + oddsString];
                      let oddsColor = '0'
                      if (Number(displayData["Odds" + oddsString]) < 10) {
                        oddsColor = '1';
                      } else if (Number(displayData["Odds" + oddsString]) < 100) {
                        oddsColor = '2';
                      }
                      targetData.race[j].OddsColor = oddsColor
                    }
                  }
                }
              }
            });
          }
        }
        document.body.className = "status-loaded";
      }
      document.body.className = "status-loaded";
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/balance/style.scss" scoped></style>
