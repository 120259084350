import {
  BACKWORDPATH,
  DEBUG,
  GLADE_G1,
  GLADE_G2,
  GLADE_G3,
  GLADE_L,
  HOST,
  JRA_HOST,
  NAR_HOST,
  JOINPATH,
  PATH,
  RACEINFOPATH
} from "../../assets/js/define";
import {getGradeIconClass, getGradeIconClassNar, getJSON, getRequest, isNar, SUCCESS} from '../../assets/js/common';
import { RacetrackCd } from "../../assets/js/code-master";
import { storeToRefs } from "pinia";
import { useStoreRaceCommon } from "../../stores/race-common-states"
import raceDeadline from "../../functions/odds/race-deadline_countdown";
const { TimerCount } = raceDeadline();

export async function fetch(dor, racetrackCd, raceNum, isNar, urlPath) {
  const store = useStoreRaceCommon();
  const { raceDORVal, raceTrackCdVal, raceNumVal } = storeToRefs(store);
  raceDORVal.value = dor;
  raceTrackCdVal.value = racetrackCd;
  raceNumVal.value = raceNum;

  await Promise.all([
    loadBasicInfo(dor, racetrackCd, raceNum, isNar, urlPath),
    loadOddsExists(dor, racetrackCd, raceNum, isNar),
    loadPrevNextRace(dor, racetrackCd, raceNum, isNar, urlPath)
  ]);
}

export async function loadOddsExists(dor, racetrackCd, raceNum, isNar) {
  const store = useStoreRaceCommon();
  const { oddsExists } = storeToRefs(store);
  const host = isNar ? NAR_HOST : JRA_HOST;
  const response = await getRequest(
    `${host}${PATH}Odds/winTicketOdds.js${BACKWORDPATH}?DOR=${dor}&RacetrackCd=${racetrackCd}&RaceNum=${raceNum}&NarFlag=${isNar ? 1 : 0}`
  );
  oddsExists.value = !!(response?.Odds1);
}

export async function loadBasicInfo(dor, racetrackCd, raceNum, isNar, urlPath) {
  const store = useStoreRaceCommon();
  const {
    raceInfo,
    formatDay,
    raceTrackCdDisplay,
    raceNumDisplay,
    resultsURL,
    oddsInquireURL,
    weightTableData,
    raceURL,
    courseURL,
    gradeIcon,
    fullgateCount,
    methodVal,
    raceDORVal,
    raceTrackCdVal,
    raceNumVal,
    paramsVal,
    deadLineVal,
  } = storeToRefs(store);

  const host = isNar ? NAR_HOST : JRA_HOST;
  raceNum = ("0" + raceNum).slice(-2);
  const req = `${host}${PATH}RaceInfo/raceBasicInfo.js${BACKWORDPATH}?dor=${dor}&racetrackCd=${racetrackCd}&raceNum=${raceNum}&narFlag=${isNar ? 1 : 0}`;
  await getJSON(req, true, function (status, json) {
    const displayData = json[0];
    if (status !== SUCCESS) {
      return;
    }
    try {
      //データは常に１件が正しい。
      if (json.length !== 1) {
        return;
      }

      //日付、競馬場、レース番号表示
      //開催年月日タブと表示番号の設定
      const dorstr = displayData.DOR;
      const dateDor = new Date(
        Number(dorstr.substr(0, 4)),
        Number(dorstr.substr(4, 2)) - 1,
        Number(dorstr.substr(6, 2))
      );
      const month =
        dorstr.substr(4, 1) === "0"
          ? dorstr.substr(5, 1)
          : dorstr.substr(4, 2);
      const day =
        dorstr.substr(6, 1) === "0"
          ? dorstr.substr(7, 1)
          : dorstr.substr(6, 2);
      const w = ["日", "月", "火", "水", "木", "金", "土"];
      formatDay.value =
        month + "/" + day + " (" + w[dateDor.getDay()] + ")";

      if (displayData.RacetrackCd !== undefined) {
        raceTrackCdDisplay.value = RacetrackCd[displayData.RacetrackCd];
      }
      if (displayData.RaceNum !== undefined) {
        raceNumDisplay.value = displayData.RaceNum * 1 + "R";
      }

      //レース結果フラグ
      //未確定時はオッズ照合。レース前の場合はレース結果の非活性。レース確定の場合はレース結果ボタンとなる
      if (displayData["Status"] === "1" && displayData["IsRaceResult"]) {
        //レース確定時
        resultsURL.value = `/race/results?DOR=${dor}&RacetrackCd=${racetrackCd}&RaceNum=${raceNum}`;

        // if (props.inquirePageFlag) {
        //   location.href = resultsURL.value;
        // }
      } else if (displayData["UnResultFlg"] === 1) {
        //レース未確定時
        oddsInquireURL.value = `/race/odds/inquire?DOR=${dor}&RacetrackCd=${racetrackCd}&RaceNum=${raceNum}`;
      }

      //出馬表データが有る場合、斤量表を設定する
      if (displayData["WeightTypeCd"] === "1") {
        let horseArray = displayData["EntryHorses"];
        if (horseArray.length > 0 && horseArray[0].Weight !== "000") {
          if (horseArray.length > 0) {
            //負担重量の逆順ででソート
            horseArray.sort(function (a, b) {
              const w1 = a["Weight"];
              const w2 = b["Weight"];
              if (w1 < w2) return 1;
              if (w1 > w2) return -1;
              return 0;
            });
            horseArray.forEach(function (value, index) {
              horseArray[index].Age = displayData["ageList"][horseArray[index].PedigreeNum]
            })
            weightTableData.value = horseArray;
          }
        }
      }

      //グレードアイコン
      if (isNar) {
        if (displayData['GradeCd']) {
          gradeIcon.value = getGradeIconClassNar(displayData['GradeCd'], displayData['RaceMainName'])
        }
      } else {
        if (displayData["SpecialRaceNum"] && displayData["SpecialRaceNum"] !== "0000") {
          gradeIcon.value = getGradeIconClass(
            displayData['GradeCd'],
            displayData['TrackTypeCd'],
            displayData['RaceRegulationCd']
          )
        }
      }

      //レースデータ・コースデータ
      if (
        displayData["SpecialRaceNum"] &&
        displayData["SpecialRaceNum"] !== "0000"
      ) {
        //レースデータのリンク
        raceURL.value =
          "/race/data" +
          "?raceCd=" +
          displayData["SpecialRaceNum"] +
          "&racetrackCd=" +
          racetrackCd +
          "&trackTypeCd=" +
          displayData['TrackTypeCd'] +
          "&distance=" +
          displayData["Distance"] +
          "&trackCd=" +
          displayData["TrackCd"];
      } else if (racetrackCd !== "33") {
        courseURL.value =
          "/race/course" +
          "?racetrackCd=" +
          racetrackCd +
          "&trackTypeCd=" +
          displayData['TrackTypeCd'] +
          "&distance=" +
          displayData["Distance"] +
          "&trackCd=" +
          displayData["TrackCd"];
      }

      //フルゲート頭数
      fullgateCount.value = displayData["FullGate"] ?? ''

      raceInfo.value = json[0];

      //データを親コンポーネントに返す
      paramsVal.value = {
        "DOR": dor,
        "RacetrackCd": racetrackCd,
        "RaceNum": raceNum,
      };
      methodVal.value = urlPath.split('/').pop();
      deadLineVal.value = TimerCount(raceInfo.value.DOR, raceInfo.value.StartTime);
    } catch (e) {
      console.log(e);
    }
  });
}

export async function loadPrevNextRace(dor, racetrackCd, raceNum, isNar, urlPath) {
  const store = useStoreRaceCommon();
  const { racePrev, raceNext } = storeToRefs(store);
  racePrev.value = "";
  raceNext.value = "";
  const host = isNar ? NAR_HOST : JRA_HOST;

  //レースタブ左右レース番号変更
  const req = `${host}${RACEINFOPATH}raceList.js${BACKWORDPATH}?dor=${dor}&racetrackCd=${racetrackCd}&narFlag=${isNar ? 1 : 0}`;
  await getJSON(req, true, function (status, json) {
    if (status !== SUCCESS) {
      return;
    }
    let maxRaceNum = "12";
    if (json != null && json.length > 0) {
      maxRaceNum = String(json.length);
    }

    const method = urlPath.split('/').pop();
    const methodURL =
      ['card','analysis','result','marks'].includes(method)
        ? "&method=" + method
        : "";

    // 1つ前のレースを付与する
    if (raceNum !== "01") {
      for (let i = 0; i < json.length; i++) {
        let tmpPrevNo = parseInt(raceNum, 10) - 1;
        if (tmpPrevNo < 10) tmpPrevNo = "0" + tmpPrevNo;
        if (json[i].RaceNum === String(tmpPrevNo)) {
          if (json[i].Status !== "0") {
            let prevNo = parseInt(raceNum, 10) - 1;
            if (prevNo < 10) prevNo = "0" + prevNo;
            racePrev.value = `${urlPath}?DOR=${dor}&RacetrackCd=${racetrackCd}&RaceNum=${prevNo}${methodURL}`;
          }
          break;
        }
      }
    }

    //1つ後のレースを付与する
    if (raceNum !== maxRaceNum) {
      for (let i = 0; i < json.length; i++) {
        let tmpNextNo = parseInt(raceNum, 10) + 1;
        if (tmpNextNo < 10) tmpNextNo = "0" + tmpNextNo;
        if (json[i].RaceNum === String(tmpNextNo)) {
          if (json[i].Status !== "0") {
            let nextNo = parseInt(raceNum, 10) + 1;
            if (nextNo < 10) nextNo = "0" + nextNo;
            raceNext.value = `${urlPath}?DOR=${dor}&RacetrackCd=${racetrackCd}&RaceNum=${nextNo}${methodURL}`;
          }
          break;
        }
      }
    }
  });

}
