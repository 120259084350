<script setup>
import {onMounted, onServerPrefetch, ref, watch} from "vue";
import Blood from "../../components/data/stud/Blood.vue";
import TotalResults from "../../components/data/stud/TotalResults.vue";
import Achievement from "../../components/data/stud/Achievement.vue";
import MainFoal from "../../components/data/stud/MainFoal.vue";
import Race from "../../components/data/stud/Race.vue";

import {
  ADD,
  changeFavoriteFromCookie,
  FSTALLION,
  getJSON,
  getUrlVars,
  getUserId,
  isFavorite,
  isNar,
  PAYINGECONOMY,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  REMOVE,
  retrieveCourse,
  SUCCESS,
} from "../../assets/js/common";
import {BACKWORDPATH, HOST, PATH,} from "../../assets/js/define";

import {useRoute, useRouter} from 'vue-router'
import {useStoreStudData} from '../../stores/data/stud-states'
import {storeToRefs} from 'pinia'
import {fetch as fetchStud} from '../../functions/data/stud'

const favClass = ref('');
const isDisplayFav = ref(false);
const loadedPage = ref(false);
const isDisplayNar = ref(false);
const courseId = ref('');
const userId = ref('');
const tab = ref("blood");
const isCurrentTab = ref("blood");

const storeStud = useStoreStudData();
const {
  breedingNumValue,
  studData,
  careerStatData,
  bloodData,
  crossAppend,
  offspringData,
  horseHairColor,
} = storeToRefs(storeStud);

const route = useRoute();
const router = useRouter();
onServerPrefetch(async () => {
  await fetchStud(route.query["BreedingNum"]);
});

watch(route, async (from, to) => {
  await fetchStud(to.query["BreedingNum"]);
})

onMounted(async () => {
  //パラメータ取得
  document.body.className = "";
  isDisplayNar.value = isNar() === 1;
  let params = getUrlVars();

  if (breedingNumValue.value !== route.query["BreedingNum"]) {
    await fetchStud(route.query["BreedingNum"]);
  }

  if (!studData.value) {
    alert('種牡馬データが存在しません。')
    loadedPage.value = true;
    document.body.className = "status-loaded";
    return;
  }

  callRetrieveCourse(async function (jsonCourseId, showMarks, jsonUserId) {
    courseId.value = jsonCourseId;
    userId.value = jsonUserId || getUserId();
    breedingNumValue.value = params.BreedingNum

    //お気に入りの表示
    if (courseId.value !== PAYINGNONE && courseId.value !== PAYINGSUGOTOKU_NONE) {
      isDisplayFav.value = true;
      favClass.value = isFavorite(FSTALLION, breedingNumValue.value) === 1 ? '-registed' : '';
    }

    loadedPage.value = true;
    document.body.className = "status-loaded";
  });
})

const tabChange = (data) => {
  tab.value = data;
  isCurrentTab.value = data;
}

const raceTabClick = () => {
  if (courseId.value === PAYINGECONOMY || courseId.value === PAYINGNONE) {
    router.push('/member/join_economy')
  } else {
    tabChange('race')
  }
}

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false, '');
  }
}

const clickFav = async () => {
  try {
    document.body.className = "";
    if (isFavorite(FSTALLION, breedingNumValue.value) === 1) {
      // お気に入り登録済みなので削除する
      const request =
        HOST +
        PATH +
        "MyFavorite/deleteMyFavoriteStallion.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(userId.value) +
        "&breedingNum=" +
        breedingNumValue.value;
      const order = REMOVE;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの削除に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FSTALLION,
        breedingNumValue.value,
        order
      );
      favClass.value = favJudge ? '' : '-registed';
    } else {
      // お気に入り未登録なので登録する
      const request =
        HOST +
        PATH +
        "MyFavorite/setMyFavoriteStallion.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(userId.value) +
        "&breedingNum=" +
        breedingNumValue.value;
      const order = ADD;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの登録に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FSTALLION,
        breedingNumValue.value,
        order
      );
      favClass.value = favJudge ? '-registed' : '';
    }

    document.body.className = "status-loaded";
  } catch (e) {
    console.log("error" + e);
  }
}

</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -horse">
      <h1 class="heading-page">種牡馬データ</h1>
      <a class="btn-basic" v-if="studData?.UrlLink" :href="studData.UrlLink">競走馬データ</a>
    </section>
    <section class="layout-section">
      <div class="summary-title">
        <div class="summary">
          <div class="inner" v-if="studData">
            <h2 class="title">{{ studData.HorseName }}</h2>
            <div class="caption">
              {{ studData.YOB }} {{ studData.HairColorName }}
            </div>
          </div>
          <ul class="list-icon -bottomleft">
            <li>
              <span
                v-if="isDisplayFav"
                class="icon-fav"
                :class="favClass"
                @click="clickFav"
              ></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="inner" v-if="studData">
        <table class="table-grid -data">
          <tr>
            <th>産地</th>
            <td>{{ studData.BreedingCenterName }}</td>
          </tr>
          <tr>
            <th>本年獲得賞金</th>
            <td>
              {{ studData.LatestAnnualEarnings }}
              <span v-html="studData.LatestWinsRanking"></span>
            </td>
          </tr>
        </table>
        <p class="p-caption -right">{{ studData.LeadingUpdated }}</p>
      </div>
      <div data-tab='["data-trigger", "data-target"]'>
        <div class="nav-tab -fit4">
          <ul data-trigger>
            <li
              @click="tabChange('blood')"
              :class="{ '-current': isCurrentTab === 'blood' }"
            >
              <span class="label">血統</span>
            </li>
            <li
              @click="tabChange('totalResults')"
              :class="{ '-current': isCurrentTab === 'totalResults' }"
            >
              <span class="label">産駒通算成績</span>
            </li>
            <li
              @click="tabChange('achievement')"
              :class="{ '-current': isCurrentTab === 'achievement' }"
            >
              <span class="label">産駒条件実績</span>
            </li>
            <li
              @click="tabChange('mainFoal')"
              :class="{ '-current': isCurrentTab === 'mainFoal' }"
            >
              <span class="label">主な産駒</span>
            </li>
            <li
              @click="raceTabClick()"
              :class="{ '-current': isCurrentTab === 'race', '-premium': (
                    courseId === PAYINGECONOMY || courseId === PAYINGNONE
                    ) }"
            >
              <span class="label">出走産駒</span>
            </li>
          </ul>
        </div>
        <div class="container-tabtarget" data-target>
          <Blood
            v-if="tab === 'blood'"
            :HOST="HOST"
            :bloodData="bloodData"
            :crossAppend="crossAppend"
            :horseHairColor="horseHairColor"
          />
          <TotalResults
            v-if="tab === 'totalResults'"
            :careerStatData="careerStatData"
          />
          <Achievement
            v-if="tab === 'achievement'"
            :studData="studData"
          />
          <MainFoal v-if="tab === 'mainFoal'"
                    :offspringData="offspringData"/>
          <Race v-if="!(
                    courseId === PAYINGECONOMY || courseId === PAYINGNONE
                    )" v-show="tab === 'race'"></Race>
        </div>
      </div>
    </section>
    <template v-if="loadedPage">
      <p v-if="!isDisplayNar" class="p-caption -padding">
        ※総賞金・通算成績・条件実績・重賞勝鞍・G1実績・各馬一覧等のデータは開催終了の翌日の更新となります。<br>
      </p>
      <p class="p-caption -padding">
        ※出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。<br>
        　<template v-if="isDisplayNar">成績集計期間は2020年04月01日以降になります。</template>
      </p>
    </template>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading"/>
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/data/style.scss" scoped></style>
