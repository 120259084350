<script setup>
import { ref, reactive, onMounted, onBeforeUpdate, watch } from "vue";
import { useRouter } from "vue-router";
import {
  PAYINGNONE,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  PAYINGSMARTPASS,
  PAYINGYAHOO,
  PAYINGECONOMY,
  PAYINGSUGOTOKU_NONE,
  PAYINGSUGOTOKU,
  PAYINGAPPPASS,
  getJSON,
  isNar,
  SHOW,
  HIDDEN,
  SUCCESS,
} from "../../assets/js/common";

import {
  HOST,
  PATH,
  URLPATH,
  BACKWORDPATH,
  RACEPATH,
  SEARCHPATH,
  DATAPATH,
} from "../../assets/js/define";

import SearchAutoComplete from '../../components/search/SearchAutoComplete.vue'
import LeadingIndex from '../../pages/leading/Index.vue'
const router = useRouter();

const formText = ref("");
const errorText = ref("");
const containStrike = ref(false);
const isViewCheck = ref(true);
const isHorse = ref(true);
const checkboxText = ref("引退馬を含む");
const formSelect = ref(1);
const searchOption = ref("racehorse");
const trendWordArray = ref([]);
const trendSearchFlag = ref(false);

const SEARCH_ALL = "all";
const SEARCH_HORSE = "racehorse";
const SEARCH_JOCKEY = "jockey";
const SEARCH_TRAINER = "trainer";
const SEARCH_OWNER = "owner";
const SEARCH_BREEDER = "breeder";
const SEARCH_STUD = "stud";
const SEARCH_MARE = "mare";
const SEARCH_RACE = "race";

onMounted(async () => {
  // １件検索に引っかかった時用のセッションストレージ削除
  clearSessionStorage()
  document.body.className = "status-loaded";
  // セッションストレージみて値代入
  getSearchOption()
  // 人気のキーワード取得
  await getTrendWord()
})

const getSearchOption = () => {
  if(sessionStorage.getItem('searchTab')) searchOption.value = sessionStorage.getItem('searchTab') 
  if(sessionStorage.getItem('checkboxText')) checkboxText.value = sessionStorage.getItem('checkboxText')
  if(sessionStorage.getItem('formSelect')) formSelect.value = Number(sessionStorage.getItem('formSelect'))
  if(sessionStorage.getItem('strikeFlag')) containStrike.value = sessionStorage.getItem('strikeFlag') == 1 ? true : false
  if(sessionStorage.getItem('checkBoxView')) isViewCheck.value = sessionStorage.getItem('checkBoxView') == 1 ? true : false
  if(sessionStorage.getItem('isHorseView')) isHorse.value = sessionStorage.getItem('isHorseView') == 1 ? true : false

  let radioDom = document.getElementById(searchOption.value);
  radioDom.checked = true
}

const clearSessionStorage = () => {
  for (const key in sessionStorage) {
      if (key.indexOf('numberOf') !== -1) sessionStorage.removeItem(key)
    }
}

const searchOptionChange = (data) => {
  searchOption.value = data;
  isViewCheck.value = true;
  containStrike.value = false;
  errorText.value = ""

  isHorse.value = data === SEARCH_HORSE || data === SEARCH_STUD || data === SEARCH_MARE
  formSelect.value = isHorse.value ? 1 : 0

  switch (data) {
    case SEARCH_HORSE:
      checkboxText.value = "引退馬を含む";
      break;
    case SEARCH_JOCKEY:
      checkboxText.value = "引退騎手を含む";
      break;
    case SEARCH_TRAINER:
      checkboxText.value = "引退調教師を含む";
      break;
    default:
      isViewCheck.value = false;
      break;
  }
};

//検索ボタン押下
const searchSubmit = (type) => {
  errorText.value = ""
  if (isHorse.value && formText.value.length < 2) {
    errorText.value = "２文字以上入力してください"
  } else if (!isHorse.value && formText.value.length < 1) {
    errorText.value = "１文字以上入力してください"
  } else {
    setSearchOption()

    router.push(`${getURL(type)}?${getParam(type)}`);
  }
};

const setSearchOption = () => {
  // １件検索に引っかかった時用のセッションストレージ削除
  clearSessionStorage()
  sessionStorage.setItem('checkboxText',checkboxText.value)
  sessionStorage.setItem('searchTab', searchOption.value)
  sessionStorage.setItem('formSelect', formSelect.value)
  sessionStorage.setItem('strikeFlag', containStrike.value ? 1 : 0)
  sessionStorage.setItem('checkBoxView', isViewCheck.value ? 1 : 0)
  sessionStorage.setItem('isHorseView', isHorse.value ? 1 : 0)
}

// URL取得
const getURL = (type) => {
  let str = '/';
  switch (type) {
    case SEARCH_ALL:
      str += DATAPATH + SEARCHPATH;
      break;
    case SEARCH_HORSE:
      str += DATAPATH + SEARCHPATH + "horse";
      break;
    case SEARCH_JOCKEY:
      str += DATAPATH + SEARCHPATH + "jockey";
      break;
    case SEARCH_TRAINER:
      str += DATAPATH + SEARCHPATH + "trainer";
      break;
    case SEARCH_OWNER:
      str += DATAPATH + SEARCHPATH + "owner";
      break;
    case SEARCH_BREEDER:
      str += DATAPATH + SEARCHPATH + "breeder";
      break;
    case SEARCH_STUD:
      str += DATAPATH + SEARCHPATH + "stud";
      break;
    case SEARCH_MARE:
      str += DATAPATH + SEARCHPATH + "mare";
      break;
    case SEARCH_RACE:
      str += DATAPATH + SEARCHPATH + "race";
      break;
  }
  return str;
};

// パラメータ取得
const getParam = (type) => {
  let str = "";
  str += "keyword=" + formText.value;

  if (type === SEARCH_HORSE || type === SEARCH_JOCKEY || type === SEARCH_TRAINER) {
    if (containStrike.value !== undefined) {
      str += containStrike.value === true ? "&blankFlag=1" : "&blankFlag=0";
    }
  } else if (type === SEARCH_ALL) {
    str += "&blankFlag=1";
  }

  const option = formSelect.value || "0";
  str += "&searchOption=" + option;

  str += trendSearchFlag.value === true ? "&trendSearch=1" : "&trendSearch=0";

  return str;
};
//検索inputの入力値に更新
const updateFormText = (search) => {
  formText.value = search;
}

// 人気のキーワード取得
const getTrendWord = async () => {
  const request =
      HOST +
      PATH +
      "Search/searchTrend.js" +
      BACKWORDPATH +
      "?NarFlag=" +
      isNar();
  await getJSON(request, false, function (status, json) {
    if (status == SUCCESS) {
      trendWordArray.value = json
    }
  })
}

// 人気のキーワードを押下時の検索処理
const trendSearch = (word, type) => {
  formSelect.value = 0;
  trendSearchFlag.value = true;
  formText.value = word;
  searchSubmit(type)
}

const displayText = () => {
  return searchOption.value === SEARCH_ALL || (searchOption.value === SEARCH_HORSE && containStrike.value)
}

</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">データ検索</h1>
    </section>

    <section class="layout-section">
      <div class="search-container">
        <h2 class="heading-circle">キーワード検索</h2>
        <div class="block -radios">
          <div class="-kind">
            <div class="grid -cols4">
              <div class="form-radiogroup">
                <label>
                  <input @click="searchOptionChange('racehorse')" type="radio" name="cat" id="racehorse" checked />
                  <span>競走馬</span>
                </label>
              </div>
              <div class="form-radiogroup">
                <label>
                  <input @click="searchOptionChange('jockey')" type="radio" name="cat" id="jockey" />
                  <span>騎手</span>
                </label>
              </div>
              <div class="form-radiogroup">
                <label>
                  <input @click="searchOptionChange('trainer')" type="radio" name="cat" id="trainer" />
                  <span>調教師</span></label>
              </div>
              <div class="form-radiogroup">
                <label>
                  <input @click="searchOptionChange('owner')" type="radio" name="cat" id="owner" />
                  <span>馬主</span>
                </label>
              </div>
              <div class="form-radiogroup">
                <label>
                  <input @click="searchOptionChange('breeder')" type="radio" name="cat" id="breeder" />
                  <span>生産者</span>
                </label>
              </div>
              <div class="form-radiogroup">
                <label>
                  <input @click="searchOptionChange('stud')" type="radio" name="cat" id="stud" />
                  <span>種牡馬</span>
                </label>
              </div>
              <div class="form-radiogroup">
                <label>
                  <input @click="searchOptionChange('mare')" type="radio" name="cat" id="mare" />
                  <span>繁殖牝馬</span>
                </label>
              </div>
              <div class="form-radiogroup">
                <label>
                  <input @click="searchOptionChange('race')" type="radio" name="cat" id="race" />
                  <span>レース名</span>
                </label>
              </div>
            </div>
          </div>
          <div class="-all">
            <div class="form-radiogroup">
              <label>
                <input @click="searchOptionChange('all')" type="radio" name="cat" id="all" />
                <span>全て</span>
              </label>
            </div>
          </div>
        </div>

        <div class="block" v-if="isViewCheck">
          <div class="form-checkboxgroup">
            <label>
              <input v-model="containStrike" type="checkbox" />
              <span>{{ checkboxText }}</span>
            </label>
          </div>
        </div>

        <div class="block">
          <div class="form-select">
            <select v-model="formSelect" name="">
              <option value="0" selected="">部分一致</option>
              <option value="1">前方一致</option>
              <option value="2">後方一致</option>
              <option value="3">完全一致</option>
            </select>
          </div>
        </div>

        <div v-if="errorText" class="_important">
          {{ errorText }}
        </div>

        <div class="block search-nav">
          <SearchAutoComplete class="form-text" @searchWord="updateFormText" :searchOption="searchOption"/>
          <button
            type="submit"
            name=""
            class="btn"
            @click="searchSubmit(searchOption)"
          ></button>
        </div>

        <div v-if="displayText()">
          結果が表示されるまでに時間がかかる場合があります
        </div>
      </div>
      <div class="search-container -bordertop">
        <div class="container-datalist">
          <a href="/data/search/jockey_list" class="btn-basic">騎手データ一覧(50音順)</a>
          <a href="/data/search/trainer_list" class="btn-basic">調教師データ一覧(50音順)</a>
        </div>
      </div>

      <div class="search-container -bordertop">
        <h2 class="heading-circle">人気のキーワード</h2>
        <div class="container-keyword">
          <ul>
            <template v-for="trendWord in trendWordArray">
              <li class="btn-basic" @click="trendSearch(trendWord.Word, 'all')">{{trendWord.Word}}</li>
            </template>
          </ul>
        </div>
      </div>

      <leading-index :is-leading-page="false"></leading-index>
    </section>

    <div class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/data/style.scss" scoped></style>
