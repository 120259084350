<template>
  <nav class="nav-multi">
    <ul class="list">
      <li :class="[currentTab === 'horse' ? '-current' : '']">
        <router-link
          to="/fav/index"
          class="label"
          >競走馬</router-link
        >
      </li>
      <li :class="[currentTab === 'jockey' ? '-current' : '']">
        <router-link
          to="/fav/jockey"
          class="label"
          >騎手</router-link
        >
      </li>
      <li :class="[currentTab === 'trainer' ? '-current' : '']">
        <router-link
          to="/fav/trainer"
          class="label"
          >調教師</router-link
        >
      </li>
      <li :class="[currentTab === 'owner' ? '-current' : '']">
        <router-link
          to="/fav/owner"
          class="label"
          >馬主</router-link
        >
      </li>
      <li :class="[currentTab === 'breeder' ? '-current' : '']">
        <router-link
          to="/fav/breeder"
          class="label"
          >生産者</router-link
        >
      </li>
      <li :class="[currentTab === 'stud' ? '-current' : '']">
        <router-link
          to="/fav/stud"
          class="label"
          >種牡馬</router-link
        >
      </li>
      <li :class="[currentTab === 'mare' ? '-current' : '']">
        <router-link
          to="/fav/mare"
          class="label"
          >繁殖牝馬</router-link
        >
      </li>
      <li :class="[currentTab === 'myNextTarget' ? '-current' : '']">
        <router-link v-if="courseId !== PAYINGECONOMY"
          to="/fav/myNextTarget"
          class="label"
          >次走狙い</router-link
        >
        <a v-else
          :href="HOST + 'member/join_economy'"
          class="label">
          次走狙い
          <i class="icon-premium" v-if="courseId === PAYINGECONOMY"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import {
  HOST,
} from "../../assets/js/define";
import {
  getCourse,
  PAYINGECONOMY,
} from "../../assets/js/common";
export default {
  props: {
    currentTab: String,
    params: Object,
  },
  data() {
    return {
      HOST: HOST,
      PAYINGECONOMY: PAYINGECONOMY,
      courseId:"",
    };
  },
  mounted() {
    this.courseId = getCourse();
  },
};

</script>

<style lang="scss" src="../../assets/css/fav/style.scss" scoped></style>
