import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreFavoriteRacehorse = defineStore('favorite-racehorse', () => {
  const displayType = ref(0);
  const count = ref(30);
  const resetList = () => {
    displayType.value = 0;
    count.value = 30;
  }
  return { displayType, count, resetList }
})

export const useStoreFavoriteJockey = defineStore('favorite-jockey', () => {
  const displayType = ref(0);
  const count = ref(30);
  const resetList = () => {
    displayType.value = 0;
    count.value = 30;
  }
  return { displayType, count, resetList }
})

export const useStoreFavoriteTrainer = defineStore('favorite-trainer', () => {
  const displayType = ref(0);
  const count = ref(30);
  const resetList = () => {
    displayType.value = 0;
    count.value = 30;
  }
  return { displayType, count, resetList }
})

export const useStoreFavoriteOwner = defineStore('favorite-owner', () => {
  const count = ref(30);
  const resetList = () => {
    count.value = 30;
  }
  return { count, resetList }
})

export const useStoreFavoriteBreeder = defineStore('favorite-breeder', () => {
  const count = ref(30);
  const resetList = () => {
    count.value = 30;
  }
  return { count, resetList }
})

export const useStoreFavoriteStud = defineStore('favorite-stud', () => {
  const count = ref(30);
  const resetList = () => {
    count.value = 30;
  }
  return { count, resetList }
})

export const useStoreFavoriteMare = defineStore('favorite-mare', () => {
  const count = ref(30);
  const resetList = () => {
    count.value = 30;
  }
  return { count, resetList }
})

export const useStoreFavoriteNextTarget = defineStore('favorite-mare', () => {
  const count = ref(30);
  const resetList = () => {
    count.value = 30;
  }
  return { count, resetList }
})


export default {
  useStoreFavoriteRacehorse,
  useStoreFavoriteJockey,
  useStoreFavoriteTrainer,
  useStoreFavoriteOwner,
  useStoreFavoriteBreeder,
  useStoreFavoriteStud,
  useStoreFavoriteMare,
  useStoreFavoriteNextTarget,
}