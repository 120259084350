<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import axios from "axios";
import { getUserId, encodeString } from "../../../assets/js/common";
import {JRA_WP_HOST} from "../../../assets/js/define";

//本番用 APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages/?slug=inquiry";

let post = reactive({});

onMounted(() => {
  document.body.className = "";
  const request_url = API_URL;
  axios
    .get(request_url)
    .then((response) => {
      Object.assign(post, response.data[0]);
    })
    .catch((error) => {
    });
});
onUpdated(() => {
  //メイラーの設定
  const userId = getUserId();
  const base64Str = encodeString(userId);
  let link = document.getElementById("contactBtn");
  link.href =
    "mailto:" +
    "info-winkeiba@digimerce.com?subject=&body=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9：%0d%0a%0d%0a%0d%0a%e2%80%bb%e4%bb%a5%e4%b8%8b%e3%81%af%e5%89%8a%e9%99%a4%e3%81%9b%e3%81%9a%e3%81%ab%e3%81%9d%e3%81%ae%e3%81%be%e3%81%be%e3%83%a1%e3%83%bc%e3%83%ab%e3%82%92%e3%81%8a%e9%80%81%e3%82%8a%e3%81%8f%e3%81%a0%e3%81%95%e3%81%84%e3%80%82%0a%20" +
    base64Str;

  let agree = document.getElementById("agree");
  const contactBtn = document.getElementById("contactBtn");
  agree.addEventListener("click", (e) => {
    if (e.target.checked) {
      contactBtn.classList.remove("-disable");
    } else {
      contactBtn.classList.add("-disable");
    }
  });
  document.body.className = "status-loaded";
});
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">お問い合わせ</h1>
    </section>
    <div v-if="post.content" v-html="post.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../../assets/css/support/style.scss" scoped></style>
