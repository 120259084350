import {BACKWORDPATH, DATAPATH, HOST, PATH} from "../../assets/js/define";
import {getJSON, prizeToString, SUCCESS,} from "../../assets/js/common";
import {setOddsHtml, weeklyRacing,} from "../../assets/js/data-common";
import {storeToRefs} from "pinia";
import {useStoreCommon} from "../../stores/common-states"
import {useStoreBreederData} from "../../stores/data/breeder-states";

let storeBreederData = null;
let narFlag = 0;

export async function fetch(breederCd) {
  const storeCommon = useStoreCommon()
  const {host, isNarSite} = storeToRefs(storeCommon);
  storeBreederData = useStoreBreederData();
  narFlag = isNarSite.value ? 1 : 0;

  await dataBreeder(breederCd, host.value, isNarSite.value);
  await getWeeklyRacing(breederCd, host.value);
  await getListBreederHorse(breederCd, host.value, isNarSite.value);
  await getListPastBreederHorse(breederCd, host.value, isNarSite.value);
}

//生産者データ差し込み
const dataBreeder = async (breederCd, host, isNar) => {
  const {
    breederCdValue,
    breederData,
    careerStatData,
  } = storeToRefs(storeBreederData);

  //生産者データ表示
  let request =
    host +
    PATH +
    "Master/breederMasterAPI.js" +
    BACKWORDPATH +
    "?BreederCd=" +
    breederCd +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      if (!json?.length) {
        return;
      }

      let useData = json[0];
      breederCdValue.value = useData.BreederCd;

      //賞金額を文字列（*億*万円）に整形する
      let latestAnnualEarnings = Number(useData.LatestAnnualEarnings);
      latestAnnualEarnings = prizeToString(latestAnnualEarnings);
      useData.LatestAnnualEarnings = latestAnnualEarnings;

      // 本年勝利
      let raceWins = useData.CurrentYearRaceWins && useData.CurrentYearRaceWins > 0 ?
        useData.CurrentYearRaceWins + "勝" : "-勝";
      // ランキングのURL生成
      let leadUrl = HOST + "leading/breeder?rank=" + useData.LatestWinsRanking;

      // ランキングのHTML生成
      useData.LatestWinsRanking = useData.LatestWinsRanking && useData.LatestWinsRanking > 0 ?
        raceWins +
        "(" +
        "<a href='" +
        leadUrl +
        "'>" +
        useData.LatestWinsRanking +
        "位</a>" +
        ")" : raceWins + '(-位)'


      let str;
      if (useData.LeadingUpdated && 8 <= useData.LeadingUpdated.length) {
        let updatedL = useData.LeadingUpdated;
        str =
          updatedL.slice(0, 4) +
          "年" +
          updatedL.slice(4, 6) +
          "月" +
          updatedL.slice(6, 8) +
          "日";
        if (updatedL.length > 8 && !isNar) {
          str += ' ' + updatedL.slice(8, 10) + ':' + updatedL.slice(10, 12);
        }
      }
      useData.LeadingUpdated = "成績集計　" + str + "現在";
      breederData.value = useData;
      careerStatData.value = useData.CareerStat;
    }
  });
}

//今週出走データ
const getWeeklyRacing = async (mainKey, host) => {
  const {
    schedule,
    totalRaces,
  } = storeToRefs(storeBreederData);

  const viewKbn = "3";
  const response = await weeklyRacing(viewKbn, mainKey, host, narFlag);
  schedule.value = await setOddsHtml(response?.schedule, host, narFlag);
  totalRaces.value = response?.totalRaces;
}

//生産者生産馬一覧
const getListBreederHorse = async (breederCd, host, isNar) => {
  const {
    productionHorses,
  } = storeToRefs(storeBreederData);

  let request =
    host +
    PATH +
    "Master/getListBreederHorse.js" +
    BACKWORDPATH +
    "?BreederCd=" +
    breederCd +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json?.length > 0) {
      let mOrgJson = json;

      for (let i = 0; i < mOrgJson.length; i++) {
        // 馬の性別ごとのbackground-colorの変更
        let sexClass;
        if (mOrgJson[i].Sex === "牡") {
          sexClass = "-left -male";
        } else if (mOrgJson[i].Sex === "牝") {
          sexClass = "-left -female";
        } else if (mOrgJson[i].Sex === "セ") {
          sexClass = "-left -senba";
        }
        mOrgJson[i].SexClass = sexClass;

        //賞金額を文字列（*億*万円）に整形する
        let mainEarnings = 'なし'
        if (mOrgJson[i].MainEarnings) {
          mainEarnings = Number(mOrgJson[i].MainEarnings);
          mainEarnings = prizeToString(mainEarnings);
        }
        mOrgJson[i].MainEarnings = mainEarnings;

        //馬名→競走馬データに遷移
        mOrgJson[i].HorseNameLink = HOST +
          DATAPATH +
          "horse?PedigreeNum=" +
          mOrgJson[i].PedigreeNum;

        // 入厩アイコン
        let stablingDiv = "";
        if (!isNar && mOrgJson[i].StablingFlg) {
          stablingDiv = mOrgJson[i].StablingFlg === 0 ?
            "icon-status -grazing" : "icon-status -stables";
        }

        mOrgJson[i].StablingDiv = stablingDiv;
      }

      productionHorses.value = mOrgJson;
    }
  });
}

//生産者過去管理G1実績取得
const getListPastBreederHorse = async (breederCd, host, isNar) => {
  const {
    pastProductionHorses,
  } = storeToRefs(storeBreederData);

  let request =
    host +
    PATH +
    "Master/getListPastBreederHorse.js" +
    BACKWORDPATH +
    "?BreederCd=" +
    breederCd +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json?.length > 0) {
      let pastBreeder = json;
      for (let i = 0; i < pastBreeder.length; i++) {
        // 馬の性別ごとのbackground-colorの変更
        let sexClass = "";
        if (pastBreeder[i].Sex === "牡") {
          sexClass = "-left -male";
        } else if (pastBreeder[i].Sex === "牝") {
          sexClass = "-left -female";
        } else if (pastBreeder[i].Sex === "セ") {
          sexClass = "-left -senba";
        }
        pastBreeder[i].SexClass = sexClass;

        //賞金額を文字列（*億*万円）に整形する
        let mainEarnings = 'なし'
        if (pastBreeder[i].MainEarnings) {
          mainEarnings = Number(pastBreeder[i].MainEarnings);
          mainEarnings = prizeToString(mainEarnings);
        }
        pastBreeder[i].MainEarnings = mainEarnings;

        //馬名→競走馬データに遷移
        pastBreeder[i].HorseNameLink = HOST +
          DATAPATH +
          "horse?PedigreeNum=" +
          pastBreeder[i].PedigreeNum;

        // 入厩アイコン
        let stablingDiv = "";
        if (!isNar && pastBreeder[i].StablingFlg) {
          stablingDiv = pastBreeder[i].StablingFlg === 0 ?
            "icon-status -grazing" : "icon-status -stables";
        }

        pastBreeder[i].StablingDiv = stablingDiv;
      }

      pastProductionHorses.value = pastBreeder;
    }
  });
}
