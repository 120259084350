<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getJSON,
  getUlrParams,
  SUCCESS,
} from "../../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  POGPATH,
  SORTHORSENAME,
  SORTSEX,
  SORTAGE,
  SORTCLASS,
} from "../../../assets/js/define";

import PogHeader from "../../../components/pog/PogHeader.vue";
import pogFunc from "../../../functions/pog.js";
const { loadPogUserJudge } = pogFunc();

//検索件数格納
const total = ref();
const useData = reactive([]);

// もっと見る
const showLimit = 30;
const count = ref(30);
const ListItem = () => {
  const list = [...useData];

  if (list != undefined && list != null && list.length > 0) {
    return list.slice(0, count.value);
  } else {
    return [];
  }
};
const isMore = () => {
  count.value += showLimit;
};

//-----------------------------------------------------------------
//  検索用パラメータの作成
//-----------------------------------------------------------------
//  引数
//  params:パラメータの配列。キーは属性名
//  戻り値
//  param:パラメータの文字列
//-----------------------------------------------------------------
const createParam = function (params) {
  let param = "";

  if (
    params["searchOption"] != undefined &&
    params["searchOption"] != null &&
    params["searchOption"] != ""
  ) {
    if (param != "") {
      param += "&";
    }
    param += "searchOption" + "=" + params["searchOption"];
  } else {
    if (param != "") {
      param += "&";
    }
    param += "searchOption" + "=0";
  }
  if (
    params["sort"] != undefined &&
    params["sort"] != null &&
    (params["sort"] == String(SORTHORSENAME) ||
      params["sort"] == String(SORTSEX) ||
      params["sort"] == String(SORTAGE) ||
      params["sort"] == String(SORTCLASS))
  ) {
    if (param != "") {
      param += "&";
    }
    param += "sort" + "=" + params["sort"];
  } else {
    if (param != "") {
      param += "&";
    }
    param += "sort" + "=" + 0;
  }

  if (
    params["ScreenDiv"] != undefined &&
    params["ScreenDiv"] != null &&
    params["ScreenDiv"] != ""
  ) {
    if (param != "") {
      param += "&";
    }
    param += "ScreenDiv" + "=" + params["ScreenDiv"];
  }
  if (
    params["Word"] != undefined &&
    params["Word"] != null &&
    params["Word"] != ""
  ) {
    if (param != "") {
      param += "&";
    }
    param += "Word" + "=" + params["Word"];
  }
  if (
    params["Option"] != undefined &&
    params["Option"] != null &&
    params["Option"] != ""
  ) {
    if (param != "") {
      param += "&";
    }
    param += "Option" + "=" + params["Option"];
  }

  return param;
};

onMounted(() => {
  document.body.className = "";
  loadPogUserJudge(() => {
    try {
      // リクエストURL作成
      const info = getUlrParams();

      const param = createParam(info.params);

      //クエリ有のケース
      if (info.params != undefined && info.params.length === 3) {
        let request = HOST + PATH + "POG/findPogHorse.js" + BACKWORDPATH;
        if (param != null && param != "") {
          request += "?" + param;
        }

        const messageFlag = true;

        //APIからJSON取得
        getJSON(request, messageFlag, function (status, json) {
          if ((status = SUCCESS)) {
            if (json) {
              if (json === 1) {
                document.body.className = "status-loaded";
                return;
              }
              //取得データの格納
              const result = json.SearchResult;

              //検索件数の格納
              if (result) {
                total.value = json.Total;
              }

              for (let i = 0; i < result.length; i++) {
                // 名前
                if (result[i].RacehorseName != null) {
                  result[i].UrlLink =
                    HOST +
                    POGPATH +
                    "horse_detail?PedigreeNum=" +
                    result[i].PedigreeNum;
                }

                //出走済判定の初期値
                result[i].IsAlreadyRun = false;

                //POG登録
                if (
                  result[i].PedigreeNum &&
                  result[i].EnableFlag == 0 &&
                  result[i].RacehorseDiv == 0
                ) {
                  result[i].PogUrlLink =
                      HOST +
                      POGPATH +
                      "horse_detail?PedigreeNum=" +
                      result[i].PedigreeNum;
                } else {
                  //出走済みのクラスを付与
                  result[i].IsAlreadyRun = true;

                  //抹消馬の場合は文字色を修飾
                  if (result[i].RacehorseDiv != 0) {
                    result[i].Erasure = true;
                  }
                }

                result.forEach((element) => useData.push(element));
              }
            }
          }
          document.body.className = "status-loaded";
        });
        //クエリ無しのケース
      } else if (info.params.length === 1) {
        let request = HOST + PATH + "POG/findPogHorse.js" + BACKWORDPATH;

        request += "?" + "searchOption=0&sort=0";

        const messageFlag = true;

        getJSON(request, messageFlag, function (status, json) {
          if ((status = SUCCESS)) {
            if (json != undefined && json != null && json != "") {
              return json;
            }
          }
          document.body.className = "status-loaded";
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
});
</script>
<template>
  <main class="layout-main">
    <PogHeader />

    <section class="layout-section">
      <div class="layout-title -borderbottom">
        <h2 class="heading-circle -padding">POG 検索</h2>
        <div class="nav">
          <span class="btn-basic -prev" @click="$router.go(-1)">戻る</span>
        </div>
      </div>

      <div class="inner">
        <h3 class="heading-item -nomargin">産駒一覧: {{ total || 0 }}件</h3>
        <div v-if="total > 200" class="system-message -notice">
          最大件数を超えた為、200件まで表示します。<br />条件を絞って再検索をして下さい。
        </div>
      </div>
      <table class="table-line -fit -middle -searchresult">
        <tbody>
          <tr
            v-for="(item, index) in ListItem()"
            :key="index"
            :class="{ '-disable': item.IsAlreadyRun }"
          >
            <td class="name" :class="{ deReg: item.Erasure }">
              <a :href="item.UrlLink">{{ item.RacehorseName }}</a>
            </td>
            <td v-if="item.IsAlreadyRun != true" class="note">
              <a :href="item.PogUrlLink" class="btn-basic -min -gray"
                >POG登録</a
              >
            </td>
            <td v-if="item.IsAlreadyRun === true" class="note">
              <a :href="item.PogUrlLink" class="btn-basic -min -gray -disable"
                >出走済</a
              >
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="ListItem().length - count >= 0" class="nav-group">
        <span class="btn-basic -more" @click="isMore">もっと見る</span>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
