<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getJSON,
  getUlrParams,
  getUrlVars,
  SUCCESS,
} from "../../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  POGPATH,
  POGAPIPATH,
  DATAPATH,
} from "../../../assets/js/define";
import PogHeader from "../../../components/pog/PogHeader.vue";
import CommonSearch from "../../../components/pog//search/CommonSearch.vue";
import pogFunc from "../../../functions/pog.js";
const { loadPogUserJudge } = pogFunc();

//検索件数格納
const total = ref();
const studData = reactive([]);

//マイページリンク
const myPageLink = HOST + POGPATH + "mypage";

// もっと見る
const showLimit = 30;
const count = ref(30);
const ListItem = () => {
  const list = [...studData];

  if (list != undefined && list != null && list.length > 0) {
    return list.slice(0, count.value);
  } else {
    return [];
  }
};
const isMore = () => {
  count.value += showLimit;
};

onMounted(() => {
  document.body.className = "";
  loadPogUserJudge(() => {
    try {
      // リクエストURL作成
      let search = "";
      let urlParams = null;
      let info = null;

      if (window.location.href.indexOf("&#038;") > -1) {
        let url = window.location.href;
        url = url.slice(url.indexOf("?") + 1, url.length);
        search = url;

        info = {
          params: ["ScreenDiv", "keyword", "searchType", "searchOption"],
          page: 1,
        };
        (urlParams = new Object()), params;
        let temp_params = url.split("&#038;");
        for (let i = 0; i < temp_params.length; i++) {
          params = temp_params[i].split("=");
          urlParams[params[0]] = params[1];
        }
      } else {
        urlParams = getUrlVars();
        info = getUlrParams();
      }

      //クエリ有りの場合
      if (
        urlParams != undefined &&
        urlParams != null &&
        urlParams != "" &&
        info.params.length === 4
      ) {
        let request = HOST + POGAPIPATH + "searchPOGParents.js" + BACKWORDPATH;

        if (urlParams != null && urlParams != "") {
          request +=
            "?keyword=" +
            urlParams["keyword"] +
            "&searchType=0&searchOption=" +
            urlParams["searchOption"];
        }

        const messageFlag = true;

        //APIからJSON取得
        getJSON(request, messageFlag, function (status, json) {
          if (status === SUCCESS) {
            if (json != undefined && json != null && json != "") {
              //JSON格納
              const result = json;

              //表示件数の格納
              if (result) {
                total.value = result[0].CountAll;
              }

              //種牡馬詳細ページへのリンク作成
              for (let i = 0; i < result.length; i++) {
                //リンクの初期値
                result[i].UrlLink =
                  HOST +
                  DATAPATH +
                  "stud" +
                  "?" +
                  "BreedingNum" +
                  "=" +
                  result[i].BreedingNum;

                //産駒一覧アイコンのリンク
                if (result[i].HasPOG === 1) {
                  result[i].HorseOffSpringLink =
                    HOST +
                    POGPATH +
                    "search_horselist?ScreenDiv=4&Word=" +
                    result[i].HorseName +
                    "&Option=0";
                }
              }
              result.forEach((element) => studData.push(element));
            }
          }
          document.body.className = "status-loaded";
        });
      } else if (info.params.length === 1) {
        //クエリ無しの場合
        let request = HOST + POGAPIPATH + "searchPOGParents.js" + BACKWORDPATH;
        request += "?keyword=undefined&searchType=0&searchOption=undefined";

        const messageFlag = true;

        //APIからJSON取得
        getJSON(request, messageFlag, function (status, json) {
          if (status === SUCCESS) {
            if (json != undefined && json != null && json != "") {
              //JSON格納
              const result = json;

              //表示件数の格納
              if (result) {
                total.value = result[0].CountAll;
              }

              //種牡馬詳細ページへのリンク作成
              for (let i = 0; i < result.length; i++) {
                //リンクの初期値
                result[i].UrlLink =
                  HOST +
                  DATAPATH +
                  "stud" +
                  "?" +
                  "BreedingNum" +
                  "=" +
                  result[i].BreedingNum;

                //産駒一覧アイコンのリンク
                if (result[i].HasPOG === 1) {
                  result[i].HorseOffSpringLink =
                    HOST +
                    POGPATH +
                    "search_horselist?ScreenDiv=4&Word=" +
                    result[i].HorseName +
                    "&Option=0";
                }
              }
              result.forEach((element) => studData.push(element));
            }
          }
          document.body.className = "status-loaded";
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
});
</script>

<template>
  <main class="layout-main">
    <PogHeader />

    <h2 class="heading-circle -padding">POG 検索</h2>
    <CommonSearch name="pog_stud" />

    <section class="layout-section">
      <div class="inner">
        <h2 class="heading-circle -nomargin">
          種牡馬検索結果: {{ total || 0 }}件
        </h2>
        <div v-if="total > 200" class="system-message -notice">
          最大件数を超えた為、200件まで表示します。<br />条件を絞って再検索をして下さい。
        </div>
      </div>

      <table class="table-line -fit -middle -searchresult">
        <tbody>
          <tr v-for="(item, index) in ListItem()" :key="index">
            <td class="name">
              <a :href="item.UrlLink">{{ item.HorseName }}</a>
            </td>
            <td v-if="item.HasPOG === 1" class="note">
              <a :href="item.HorseOffSpringLink" class="btn-basic -min -gray"
                >産駒一覧</a
              >
            </td>
            <td v-else class="note">
              <a></a>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="ListItem().length - count >= 0" class="nav-group">
        <span class="btn-basic -more" @click="isMore">もっと見る</span>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
