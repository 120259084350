<template>
  <ul class="list">
    <li :class="[currentTab === 'win' ? '-current' : '']">
      <router-link
        :to="{
          path: '/race/odds/win',
          query: {
            DOR: params.DOR,
            RacetrackCd: params.RacetrackCd,
            RaceNum: params.RaceNum,
          },
        }"
        class="label"
        >単勝</router-link
      >
    </li>
    <li :class="[currentTab === 'place' ? '-current' : '']">
      <router-link
        :to="{
          path: '/race/odds/place',
          query: {
            DOR: params.DOR,
            RacetrackCd: params.RacetrackCd,
            RaceNum: params.RaceNum,
          },
        }"
        class="label"
        >複勝</router-link
      >
    </li>
    <li :class="[currentTab === 'bracket' ? '-current' : '', bracketFlag ? '': '-disable']">
      <router-link
        :to="{
          path: '/race/odds/bracket',
          query: {
            DOR: params.DOR,
            RacetrackCd: params.RacetrackCd,
            RaceNum: params.RaceNum,
          },
        }"
        class="label"
        >枠連</router-link
      >
    </li>
    <template v-if="hasBracketExactaOdds(params.RacetrackCd)">
      <li :class="[currentTab === 'bracket_exacta' ? '-current' : '', bracketExactaFlag ? '': '-disable']">
        <router-link
          :to="{
            path: '/race/odds/bracket_exacta',
            query: {
              DOR: params.DOR,
              RacetrackCd: params.RacetrackCd,
              RaceNum: params.RaceNum,
            },
          }"
          class="label"
          >枠単</router-link
        >
      </li>
    </template>
    <li :class="[currentTab === 'quinella' ? '-current' : '']">
      <router-link
        :to="{
          path: '/race/odds/quinella',
          query: {
            DOR: params.DOR,
            RacetrackCd: params.RacetrackCd,
            RaceNum: params.RaceNum,
          },
        }"
        class="label"
        >馬連</router-link
      >
    </li>
    <li :class="[currentTab === 'exacta' ? '-current' : '']">
      <router-link
        :to="{
          path: '/race/odds/exacta',
          query: {
            DOR: params.DOR,
            RacetrackCd: params.RacetrackCd,
            RaceNum: params.RaceNum,
          },
        }"
        class="label"
        >馬単</router-link
      >
    </li>
    <li :class="[currentTab === 'wide' ? '-current' : '']">
      <router-link
        :to="{
          path: '/race/odds/wide',
          query: {
            DOR: params.DOR,
            RacetrackCd: params.RacetrackCd,
            RaceNum: params.RaceNum,
          },
        }"
        class="label"
        >ワイド</router-link
      >
    </li>
    <li :class="[currentTab === 'trio' ? '-current' : '']">
      <router-link
        :to="{
          path: '/race/odds/trio',
          query: {
            DOR: params.DOR,
            RacetrackCd: params.RacetrackCd,
            RaceNum: params.RaceNum,
          },
        }"
        class="label"
        >3連複</router-link
      >
    </li>
    <li :class="[currentTab === 'trifecta' ? '-current' : '']">
      <router-link
        :to="{
          path: '/race/odds/trifecta',
          query: {
            DOR: params.DOR,
            RacetrackCd: params.RacetrackCd,
            RaceNum: params.RaceNum,
          },
        }"
        class="label"
        >3連単</router-link
      >
    </li>
  </ul>
</template>

<script>
import {
  hasBracketExactaOdds,
  getJSON,
  isNar,
  SUCCESS
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
} from "../../assets/js/define";

export default {
  props: {
    currentTab: String,
    params: Object,
    bracketFlag: { type: Boolean, default: true },
  },
  data() {
    return {
      bracketExactaFlag: false
    }
  },
  methods: {
    hasBracketExactaOdds,
    async fetchBracketExactaOdds(params) {
      const {DOR, RacetrackCd, RaceNum} = params;
      const req = `${HOST}${PATH}Odds/bracketExactaOdds.js${BACKWORDPATH}?DOR=${DOR}&RacetrackCd=${RacetrackCd}&RaceNum=${RaceNum}`;
      await getJSON(req,true,  (status, json) => {
        if (status === SUCCESS && json.length > 0 && json[0].SaleDiv !== 0) this.bracketExactaFlag = true;
      });
    },
  },
  mounted() {
    this.fetchBracketExactaOdds(this.params)
  }
};
</script>

<style lang="scss" src="../../assets/css/race/odds/style.scss" scoped></style>
