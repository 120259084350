import {
  BACKWORDPATH,
  DATAPATH,
  HOST,
  JRA_HOST,
  NAR_HOST,
  NEWSIMAGEPATH,
  NEWSPATH,
  PATH,
  POGPATH
} from "../../assets/js/define";
import {
  dayDiv,
  getJSON,
  monthDiv,
  prizeToString,
  SUCCESS, yearDiv
} from "../../assets/js/common";
import { storeToRefs } from "pinia";
import { useStoreCommon } from "../../stores/common-states"
import { useStoreHorseData } from "../../stores/horse-data-states";

export async function fetch(pedigreeNum) {
  const storeCommon = useStoreCommon()
  const {host, isNarSite} = storeToRefs(storeCommon);
  const storeHorseData = useStoreHorseData();

  if(isNarSite.value) {
    await Promise.all([
      dataHorse(pedigreeNum, storeHorseData, host.value, isNarSite.value),
      dataBrother(pedigreeNum, "bro", storeHorseData, host.value, isNarSite.value),
    ]);
  } else {
    await Promise.all([
      dataHorse(pedigreeNum, storeHorseData, host.value, isNarSite.value),
      dataNextRace(pedigreeNum, storeHorseData, host.value),
      dataBrother(pedigreeNum, "bro", storeHorseData, host.value, isNarSite.value),
    ]);
  }

  const { horseData } = storeToRefs(storeHorseData);
  if (!horseData.value?.RaceHorseData) {
    return;
  }
  await dataBreed(pedigreeNum, horseData.value.RaceHorseData.SexCd, storeHorseData, host.value, isNarSite.value);
}

async function dataHorse(pedigreeNum, storeHorseData, host, isNar) {
  const {
    news,
    horseData,
    auctionInfo,
    raceResult,
    specialEntryData,
    horseResultData,
    narHorseResultData,
    jraOutHorseResultData,
    bestRecordData,
    narBestRecordData,
    pedigreeStatData,
    crossDegreeData,
    horseHairColor,
    horsePedigreeNum,
    loadingInfo,
    horseSymbol1,
    horseSymbol2,
    isPogHorse,
  } = storeToRefs(storeHorseData);

  //競走馬データ表示
  const request = `${host}webapi/Master/racehorseMasterAPI.js${BACKWORDPATH}?PedigreeNum=${pedigreeNum}&NarFlag=${isNar ? 1 : 0}`;

  await getJSON(request, true, (status, json) => {
    if (status !== SUCCESS) {
      return;
    }
    if (!json || !json[0]) {
      return;
    }
    const _raceHorseData = json[0].RaceHorseData;
    const _auctionInfo = _raceHorseData.AuctionInfo.length ? _raceHorseData.AuctionInfo[0] : {};
    const _raceResultData = json[0].RaceResult;
    const _pedigreeStatData = json[0].PedigreeStat;
    const _horseResultData = json[0].RaceHorseResult;
    const _narHorseResultData = json[0].NarRaceHorseResult;
    const _jraOutHorseResultData = json[0].JraOutRaceHorseResult;
    const _raceHorseNews = json[0].RacehorseNews;
    const _specialEntryData = {
      SpecialEntry: json[0].SpecialEntry,
      SpecialEntryDiv: json[0].SpecialEntryDiv,
    };
    const _bestRecordData = json[0].BestRecord;
    const _narBestRecordData = json[0].NarBestRecord;
    const _crossDegreeData = json[0].CrossDegree;

    if (!isNar) {
      [horseSymbol1.value,horseSymbol2.value]  = getHorseSymbol(_raceHorseData.HorseSymbolCd);
    }

    //勝負服設定
    const uniforms = getUniform(_raceHorseData, host);
    _raceHorseData.uni1 = uniforms.uni1;
    _raceHorseData.uni2 = uniforms.uni2;
    _raceHorseData.uni3 = uniforms.uni3;
    _raceHorseData.uni4 = uniforms.uni4;

    const style = 'background-image:' +
      `url(${host}shared/img/uniform/fuku-waku.png),` +
      'url(' + _raceHorseData.uni4 + '),' +
      'url(' + _raceHorseData.uni3 + '),' +
      'url(' + _raceHorseData.uni2 + '),' +
      'url(' + _raceHorseData.uni1 + ')'

    _raceHorseData.ThumbnailInfo = _raceHorseData.ThumbnailInfo.filter(val => val.FaceImgURL !== "")
      .sort(function (a, b) {
        return b.RecordDate - a.RecordDate
      })

    // 競走馬基本データ
    let currentHorseData = {
      RaceHorseData: {
        HorseName: _raceHorseData.HorseName,
        StablingFlg: _raceHorseData.StablingFlg,
        SexCd: _raceHorseData.SexCd,
        age:
          _raceHorseData.isActive !== 1 ? _raceHorseData.age : "",
        DOB:
          String(_raceHorseData.DOB).substring(0, 4) +
          "年" +
          String(_raceHorseData.DOB).substring(4, 6) +
          "月" +
          String(_raceHorseData.DOB).substring(6, 8) +
          "日",
        class: _raceHorseData.class,
        HairColorCd: _raceHorseData.HairColorCd,
        SnkDataFlg: _raceHorseData.SnkDataFlg,
        RetireDiv: _raceHorseData.RetireDiv,
        IsActive: _raceHorseData.isActive,
        IsNarData: _raceHorseData.isNarData,
        Thumbnail: _raceHorseData.ThumbnailInfo,
        FlatRaceAcquisitionEarnings: _raceHorseData.FlatRaceAcquisitionEarnings,
        SteeplechaseRaceAcquisitionEarnings: _raceHorseData.SteeplechaseRaceAcquisitionEarnings,
        HorseSymbolCd: _raceHorseData.HorseSymbolCd,
        Class: _raceHorseData.class,
        Style: style,
      },
    };

    //競走馬詳細データ
    //父
    let FatherName = "";
    let FatherURL = "";
    if ("FatherName" in _pedigreeStatData) {
      FatherName = _pedigreeStatData.FatherName;
      if ("BreedingNum_Father" in _pedigreeStatData) {
        FatherURL =
          host +
          "data/stud?BreedingNum=" +
          _pedigreeStatData.BreedingNum_Father;
      }
    }

    //母
    let MotherName = "";
    let MotherURL = "";
    if ("MotherName" in _pedigreeStatData) {
      MotherName = _pedigreeStatData.MotherName;
      if ("BreedingNum_Mother" in _pedigreeStatData) {
        MotherURL =
          host +
          "data/mare?BreedingNum=" +
          _pedigreeStatData.BreedingNum_Mother;
      }
    }

    //母の父
    let HorseName_6 = "";
    let HorseURL_6 = "";
    if ("HorseName_6" in _pedigreeStatData) {
      HorseName_6 = _pedigreeStatData.HorseName_6;
      if ("BreedingNum_6" in _pedigreeStatData) {
        HorseURL_6 =
          host +
          "data/stud?BreedingNum=" +
          _pedigreeStatData.BreedingNum_6;
      }
    }

    //戦績
    let first = Number(_horseResultData.Place1st) || 0;
    let narFirst = Number(_narHorseResultData.Place1st) || 0;
    let jraOutFirst = Number(_jraOutHorseResultData.Place1st) || 0;
    const total1st = first + narFirst + jraOutFirst;
    let second = Number(_horseResultData.Place2nd) || 0;
    let narSecond = Number(_narHorseResultData.Place2nd) || 0;
    let jraOutSecond = Number(_jraOutHorseResultData.Place2nd) || 0;
    const total2nd = second + narSecond + jraOutSecond;
    let third = Number(_horseResultData.Place3rd) || 0;
    let narThird = Number(_narHorseResultData.Place3rd) || 0;
    let jraOutThird = Number(_jraOutHorseResultData.Place3rd) || 0;
    const total3rd = third + narThird + jraOutThird;
    let unplaced = Number(_horseResultData.Unplaced) || 0;
    let narUnplaced = Number(_narHorseResultData.Unplaced) || 0;
    let jraOutUnplaced = Number(_jraOutHorseResultData.Unplaced) || 0;
    const totalUnplaced = unplaced + narUnplaced + jraOutUnplaced;
    const foughtres =
      total1st +
      total2nd +
      total3rd +
      totalUnplaced +
      "戦 [" +
      total1st +
      " " +
      total2nd +
      " " +
      total3rd +
      " " +
      totalUnplaced +
      "]";

    //調教師
    let trainerName = _raceHorseData.TrainerName;
    let trainerGroup = "";
    if (_raceHorseData.TrainerGroup) {
      trainerGroup = "(" + _raceHorseData.TrainerGroup + ")";
    } else {
      trainerGroup = _raceHorseData.InvitationBelong
        ? "(" + _raceHorseData.InvitationBelong + ")"
        : isNar ? "" : "(海外)";
    }
    let trainerURL = "";
    if (_raceHorseData.TrainerCd) {
      trainerURL = (_raceHorseData.isNarData ? NAR_HOST : JRA_HOST) + "data/trainer?TrainerCd=" + _raceHorseData.TrainerCd
    }

    // 馬主
    let horseOwnerName = _raceHorseData.HorseOwnerName;
    let horseOwnerURL = "";
    if (_raceHorseData.HorseOwnerCd) {
      horseOwnerURL = (_raceHorseData.isNarData ? NAR_HOST : JRA_HOST) + "data/owner?HorseOwnerCd=" + _raceHorseData.HorseOwnerCd
    }

    //生産者
    let breederName = _raceHorseData.BreederName;
    let breederURL = _raceHorseData.BreederCd
      ? host + "data/breeder?BreederCd=" + _raceHorseData.BreederCd
      : "";

    //産地
    let breedingCenter = "";
    _raceHorseData.BreedingCenterName !== undefined
      ? (breedingCenter = _raceHorseData.BreedingCenterName)
      : (breedingCenter = "――");

    //　関連ニュース
    if (_raceHorseNews.length) {
      //新しいjsonをつくる
      let newsObj = new Array();
      for (let i = 0; i < _raceHorseNews.length; i++) {
        const useNewsData = _raceHorseNews[i];
        let sort = useNewsData.DeliveryDate.replace(/[:\/ ]/g, "");

        //最新情報タブの関連ニュースの日付フォーマット変更
        let deliveryDate =
          String(useNewsData.DeliveryDate).substring(0, 4) +
          "." +
          String(useNewsData.DeliveryDate).substring(5, 7) +
          "." +
          String(useNewsData.DeliveryDate).substring(8, 10) +
          " " +
          String(useNewsData.DeliveryDate).substring(11, 16);

        _raceHorseNews[0].DeliveryDate = deliveryDate;
        useNewsData.DeliveryDate = deliveryDate;

        const obj = {
          NewsCd: useNewsData.NewsCd,
          Headline: useNewsData.Headline,
          DeliveryDate: useNewsData.DeliveryDate,
          ImageFileURL: useNewsData.ImageFileURL ? HOST + NEWSIMAGEPATH + useNewsData.ImageFileURL : '',
          Sort: sort,
        };
        newsObj.push(obj);
      }

      //関連ニュースデータソート
      let sort_by = function (field, reverse, primer) {
        reverse = reverse ? -1 : 1;
        return function (a, b) {
          a = a[field] * 10;
          b = b[field] * 10;
          if (typeof primer != "undefined") {
            a = primer(a);
            b = primer(b);
          }
          if (a < b) return reverse * -1;
          if (a > b) return reverse * 1;
        };
      };
      newsObj.sort(sort_by("Sort", true, parseInt));
      let articleCount = 0; //表示記事数
      //14日以内のものだけ表示
      let seqNo = "";
      for (let i = 0; i < newsObj.length; i++) {
        const useNews = newsObj[i];
        //データ作成日
        const dataCreate = useNews.NewsCd.substr(0, 8);
        let createDate = new Date(
          yearDiv(dataCreate),
          monthDiv(dataCreate) - 1,
          dayDiv(dataCreate),
          0,
          0,
          0
        );
        createDate = createDate.getTime();
        //14日後
        let after = 14;
        after = after * 24 * 60 * 60 * 1000;
        let deleteDate = new Date(createDate + after);
        deleteDate = deleteDate.getTime();
        //今日
        let today = new Date();
        today = today.getTime();
        if (createDate <= today && deleteDate >= today) {
          articleCount++;
          //ニュース表示
          let url = "";
          let imgUrl = "";
          if (newsObj[i].NewsCd != null) {
            let newsCd = newsObj[i].NewsCd;
            createDate = newsCd.substr(0, 8);
            seqNo = newsCd.substr(8, 4);
            url =
              host +
              NEWSPATH +
              "article?FileCreateDate=" +
              createDate +
              "&SeqNo=" +
              seqNo +
              "&Page=1";
            imgUrl = newsObj[i].ImageFileURL;
          } else {
            url = "#";
          }
          //データ格納
          news.value.push({
            Headline: newsObj[i].Headline,
            DeliveryDate: newsObj[i].DeliveryDate,
            url: url,
            ImageFileURL: imgUrl,
          });
        }
      }
    }

    //せり情報
    if (Object.keys(_auctionInfo).length > 0) {
      const salesPrice = String(_auctionInfo.SalesPrice).slice(0, -2)
      _auctionInfo.SalesPrice = prizeToString(Number(salesPrice))
    }

    //競走馬詳細データ格納
    currentHorseData.RaceHorseDataDetails = {
      FatherName: FatherName,
      FatherURL: FatherURL,
      MotherName: MotherName,
      MotherURL: MotherURL,
      HorseName_6: HorseName_6,
      HorseURL_6: HorseURL_6,
      foughtres: foughtres,
      NarMainEarnings: prizeToString(_raceHorseData.NarMainEarnings),
      MainEarnings: prizeToString(_raceHorseData.MainEarnings),
      TrainerName: trainerName,
      TrainerGroup: trainerGroup,
      TrainerURL: trainerURL,
      HorseOwnerName: horseOwnerName,
      HorseOwnerURL: horseOwnerURL,
      BreederName: breederName,
      BreederURL: breederURL,
      BreedingCenter: breedingCenter,
    };

    //dataに格納
    horseData.value = currentHorseData;
    auctionInfo.value = _auctionInfo;
    raceResult.value = _raceResultData;
    specialEntryData.value = _specialEntryData;
    horseResultData.value = _horseResultData;
    narHorseResultData.value = _narHorseResultData;
    jraOutHorseResultData.value = _jraOutHorseResultData;
    bestRecordData.value = _bestRecordData;
    narBestRecordData.value = _narBestRecordData;
    pedigreeStatData.value = _pedigreeStatData;
    crossDegreeData.value = _crossDegreeData;
    horseHairColor.value = {
      HairColorFather: json[0].HairColor_Father,
      HairColorMother: json[0].HairColor_Mother,
    };
    horsePedigreeNum.value = pedigreeNum;
    isPogHorse.value = json[0].POGFlag === 1;
    loadingInfo.value = true;
  });
}

async function dataBrother(mNum, mode, storeHorseData, host, isNar) {
  const { offspringData } = storeToRefs(storeHorseData);
  const request =
    `${host}webapi/Master/offspringMasterAPI.js${BACKWORDPATH}?num=${mNum}&mode=${mode}&narFlag=${isNar ? 1 : 0}`;
  await getJSON(request, true, function (status, json) {
    if (status !== SUCCESS) {
      return;
    }
    if (json[0] != null || json[0] !== undefined) {
      offspringData.value = json[0].Offspring;
    }
  });
}

async function dataBreed(pedigreeNum, SexCd, storeHorseData, host, isNar) {
  const { breedHorseURL, breedingTitle } = storeToRefs(storeHorseData);

  const request = `${host}webapi/Master/getBreedingNum.js${BACKWORDPATH}?PedigreeNum=${pedigreeNum}&NarFlag=${isNar ? 1 : 0}`;
  await getJSON(request, true, function (status, json_breedingNum) {
    if (status === SUCCESS) {
      if (
        json_breedingNum !== undefined &&
        json_breedingNum != null &&
        json_breedingNum[0] !== undefined
      ) {
        const breedingNumData = json_breedingNum[0];
        const breedingNum = breedingNumData.BreedingNum;
        if (SexCd !== undefined && SexCd != null) {
          if (SexCd === "牡") {
            breedHorseURL.value =
              host + DATAPATH + "stud?BreedingNum=" + breedingNum;
            breedingTitle.value = "種牡馬データ";
          } else if (SexCd === "牝") {
            breedHorseURL.value =
              host + DATAPATH + "mare?BreedingNum=" + breedingNum;
            breedingTitle.value = "繁殖牝馬データ";
          }
        }
      }
    }
  });
}

async function dataNextRace(pedigreeNum, storeHorseData, host) {
  const { nextRacesData } = storeToRefs(storeHorseData);
  const request = `${host}webapi/Master/nextRacesDataAPI.js${BACKWORDPATH}?PedigreeNum=${pedigreeNum}`;
  await getJSON(request, true, function (status, json) {
    if (status !== SUCCESS) {
      return;
    }
    if (json && Object.keys(json).length) {
      const precedencePeriod = json.PrecedencePeriod

      if (Object.keys(precedencePeriod.monthInfo).length) {
        const name =
          "1ヶ月内のレース " +
          createDateStr(precedencePeriod.PrecedenceFrom) +
          "～" +
          createDateStr(precedencePeriod.PrecedenceTo);
        nextRacesData.value.push({
          name: name,
          data: precedencePeriod.monthInfo
        })
      }
      if (Object.keys(precedencePeriod.raceInfo).length) {
        const name =
          "優先出走期間 " +
          createDateStr(precedencePeriod.PrecedenceFrom) +
          "～" +
          createDateStr(precedencePeriod.PrecedenceTo);
        nextRacesData.value.push({
          name: name,
          data: precedencePeriod.raceInfo
        });
      }
      if (Object.keys(precedencePeriod.classicInfo).length) {
        nextRacesData.value.push({
          name: '優先出走レース',
          data: precedencePeriod.classicInfo
        });
      }
    }
  })
}

function getHorseSymbol(horseSymbolCd) {
  //馬記号
  // 〇抽や〇市、〇父、□抽は廃止
  return {
    '05': ["-maruchi", ""],
    '06': ["-marugai", ""],
    '09': ["-maruchi", ""],
    '10': ["-maruchi", ""],
    '11': ["-marugai", "-maruchi"],
    '12': ["-maruchi", ""],
    '15': ["-marusho", ""],
    '16': ["-marusho", "-marugai"],
    '17': ["-marusho", ""],
    '18': ["-marusho", ""],
    '19': ["-marusho", ""],
    '20': ["-marugai", ""],
    '21': ["-kakuchi", ""],
    '22': ["-marugai", "-kakuchi"],
    '23': ["-kakuchi", ""],
    '24': ["-kakuchi", ""],
    '25': ["-kakuchi", ""],
    '26': ["-kakugai", ""],
    '27': ["-kakugai", ""],
    '41': ["-marugai", "-kakuchi"],
    '00': ["", ""],
  }[horseSymbolCd] ?? ["", ""];
}

function getUniform(raceHorseData, host) {
  const uniformCd = raceHorseData.UniformCd;
  let uni1 = "";
  let uni2 = "";
  let uni3 = "";
  let uni4 = "";

  if (raceHorseData.isNarData) {
    return {
      uni1, uni2, uni3, uni4
    }
  }

  const uniArray = String(uniformCd).split("，");
  //色
  const colorId = {
    "白": "01", "黒": "02", "赤": "03", "青": "04",
    "黄": "05", "緑": "06", "桃": "07", "水色": "08",
    "紫": "09", "薄紫": "10", "鼠": "11", "海老": "12",
    "茶": "13"
  };
  //胴模様
  const bodyId = {
    "一本輪": "body_line1",
    "二本輪": "body_line2",
    "三本輪": "body_line3",
    "一文字": "itimonnji",
    "山形一文字": "yamagataitimonnji",
    "山形一本輪": "body_yamagataline1",
    "山形二本輪": "body_yamagataline2",
    "山形三本輪": "body_yamagataline3",
    "菱山形": "body_hishigata",
    "帯": "body_obi",
    "山形帯": "body_yamagataobi",
    "襷": "body_tasuki",
    "十字襷": "body_cross",
    "縦縞": "body_stripe",
    "格子": "body_check",
    "元禄": "body_genroku",
    "ダイヤモンド": "body_diamond",
    "うろこ": "body_uroko",
    "井桁絣": "body_igetagasuri",
    "玉霰": "body_tamaarare",
    "星散": "body_star",
    "蛇目散": "body_jame",
    "銭形散": "body_zenigata",
    "鋸歯形": "body_nokogiri"
  }
  //袖模様
  let sleeveId = {
    "一本輪": "sleeve_line1",
    "二本輪": "sleeve_line2",
    "三本輪": "sleeve_line3",
    "山形一本輪": "sleeve_yamagataline1",
    "山形二本輪": "sleeve_yamagataline2",
    "山形三本輪": "sleeve_yamagataline3",
    "菱山形": "sleeve_hishigata",
    "縦縞": "sleeve_stripe",
    "格子": "sleeve_check",
    "元禄": "sleeve_genroku",
    "ダイヤモンド": "sleeve_diamond",
    "うろこ": "sleeve_uroko",
    "井桁絣": "sleeve_igetagasuri",
    "玉霰": "sleeve_tamaarare",
    "星散": "sleeve_star",
    "蛇目散": "sleeve_jame",
    "銭形散": "sleeve_zenigata"
  }

  //胴模様設定 引数値は必ず胴様色＋模様　例：白一本輪、白縦縞
  function setBodyPattern(bodyString) {
    //先頭１文字ないし２文字は袖色
    //1文字目が色を想定
    let checkColor = colorId[bodyString.substr(0, 1)];
    let checkPattern = bodyId[bodyString.substr(1)];
    if (!checkColor) {
      //１文字目だけで色とならない場合、1～2文字目を設定
      checkColor = colorId[bodyString.substr(0, 2)];
      checkPattern = bodyId[bodyString.substr(2)];
    }
    if (checkPattern) {
      uni3 = host + "shared/img/uniform/" + checkPattern + "_" + checkColor + ".png"
    } else {
      //JRAのルール外の場合
      uni1 = host + "shared/img/uniform/NO-IMAGE_syoubufuku.png"
    }
  }

  //袖色設定 引数値は必ず色名＋袖　例：青袖、水色袖
  function setSleeveColor(sleeveString) {
    let checkColor = colorId[sleeveString.substr(0, 1)];
    if (!checkColor) {
      //１文字目だけで色とならない場合、1～2文字目を設定
      checkColor = colorId[sleeveString.substr(0, 2)];
    }
    if (checkColor) {
      uni2 = host + "shared/img/uniform/" + "sleeve_color_" + checkColor + ".png"
    } else {
      //JRAのルール外の場合
      uni1 = host + "shared/img/uniform/NO-IMAGE_syoubufuku.png"
    }
  }

  //袖模様設定 引数値は必ず模様色＋模様　例：黄うろこ、白縦縞
  function setSleevePattern(sleeveString) {
    //先頭１文字ないし２文字は袖色
    //1文字目が色を想定
    let checkColor = colorId[sleeveString.substr(0, 1)];
    let checkPattern = sleeveId[sleeveString.substr(1)];
    let sleeveSubstr = sleeveString.substr(1);
    if (!checkColor) {
      //１文字目だけで色とならない場合、1～2文字目を設定
      checkColor = colorId[sleeveString.substr(0, 2)];
      checkPattern = sleeveId[sleeveString.substr(2)];
      sleeveSubstr = sleeveString.substr(2);
    }

    if (checkPattern) {
      uni4 = host + "shared/img/uniform/" + checkPattern + "_" + checkColor + ".png"
    } else if (sleeveSubstr == "一文字" || sleeveSubstr == "山形一文字" || sleeveSubstr == "帯" ||
      sleeveSubstr == "山形帯" || sleeveSubstr == "襷" || sleeveSubstr == "十字襷" || sleeveSubstr == "鋸歯形") {
      //胴だけで袖に無い模様は何もしない
    } else {
      //JRAのルール外の場合
      uni1 = host + "shared/img/uniform/NO-IMAGE_syoubufuku.png"
    }
  }

  if (uniArray.length === 0) {
    return { uni1, uni2, uni3, uni4 }
  }
  //b.一番左は胴色で確定なので配置、袖色も胴色と同じ可能性が高いので配置
  let baseColor = colorId[uniArray[0]];
  if (!baseColor) {
    return { uni1, uni2, uni3, uni4 }
  }
  uni1 = host + "shared/img/uniform/" + "body_color_" + baseColor + ".png"
  uni2 = host + "shared/img/uniform/" + "sleeve_color_" + baseColor + ".png"
  if (uniArray.length <= 1) {
    return { uni1, uni2, uni3, uni4 }
  }
  let secondChar = uniArray[1];
  let thirdChar = "";
  if (uniArray.length > 2) thirdChar = uniArray[2];
  if (secondChar.substr(0, 1) === "袖") {
    //3番目がある場合（黄鋸歯形）
    if (uniArray.length > 2) {
      setBodyPattern(thirdChar);
      setSleevePattern(secondChar.substr(1));
    } else {
      //	◎1.２番目の文字の中の先頭文字が「袖」の場合（I：袖模様）
      setSleevePattern(secondChar.substr(1));
    }

  } else if (secondChar.substr(secondChar.length - 1, 1) === "袖") {
    //	2.２番目の文字の最後が「袖」の場合
    if (uniArray.length === 2) {
      //◎2-1.３番目がない場合（F：袖色）
      setSleeveColor(secondChar);
    } else {
      //"黄，青袖，赤二本輪"
      //2-2.３番目がある場合（G：袖色、模様）
      setSleeveColor(secondChar);
      setSleevePattern(thirdChar);
      setBodyPattern(thirdChar);
    }
  } else if (secondChar.indexOf("袖") > 0) {
    if (uniArray.length === 2) {
      //3-1.２番目の文字の中間に「袖」の場合（H:袖色袖模様）
      let splitarray = secondChar.split("袖");
      setSleeveColor(splitarray[0]);
      setSleevePattern(splitarray[1]);
    } else {
      //3-2.３番目がある場合（J:袖色 袖模様, 模様）※鋸歯限定
      let splitarray = secondChar.split("袖");
      setSleeveColor(splitarray[0]);
      setSleevePattern(splitarray[1]);
      setBodyPattern(thirdChar);
    }
  } else {
    //	4.２番目に「袖」が含まれない場合
    if (uniArray.length === 2) {
      // ◎4-1.３番目が無い場合（B:模様）
      setBodyPattern(secondChar);
      setSleevePattern(secondChar);

    } else {
      //4-2.３番目がある場合
      let thirdChar = uniArray[2] + "";
      if (thirdChar.substr(0, 1) === "袖") {
        // 4-2-1.３番目の文字の先頭に「袖」の場合（E:模様、袖模様）
        setBodyPattern(secondChar);
        setSleevePattern(thirdChar.substr(1));
      } else if (thirdChar.substr(thirdChar.length - 1, 1) === "袖") {
        // 4-2-2.３番目の文字の最後が「袖」の場合（C:模様、袖色）
        setBodyPattern(secondChar);
        setSleeveColor(thirdChar);
      } else if (thirdChar.indexOf("袖") > 0) {
        // 4-2-3.３番目の文字の中間が「袖」の場合（D:模様、袖色袖模様）
        setBodyPattern(secondChar);
        let splitarray = thirdChar.split("袖");
        setSleeveColor(splitarray[0]);
        setSleevePattern(splitarray[1]);
      }
    }
  }

  return {
    uni1, uni2, uni3, uni4
  }
}

function createDateStr(date) {
  const w = ["日", "月", "火", "水", "木", "金", "土"];
  let result = "";
  if (date !== undefined && date !== null && date.length === 8) {
    const day = new Date(
      date.slice(0, 4) + "/" + date.slice(4, 6) + "/" + date.slice(6, 8)
    );
    let dayStr = "";
    dayStr = w[day.getDay()];
    result =
      String(parseInt(date.slice(4, 6), 10)) +
      "月" +
      String(parseInt(date.slice(6, 8), 10)) +
      "日（" +
      dayStr +
      ")";
  }

  return result;
}
