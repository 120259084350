<script setup>
import {ref, onMounted, watch} from "vue";
import {useRoute} from "vue-router";

import SetBetConfModal from "../../components/utils/SetBetConfModal.vue";
import OddsTabs from "../../components/odds/OddTabs.vue";
import RaceHeader from "../../components/race/RaceHeader.vue";

import QuinellaFormation from "../../components/odds/quinella/Formation.vue";
import QuinellaPopularity from "../../components/odds/quinella/Popularity.vue";
import QuinellaWheel from "../../components/odds/quinella/Wheel.vue";
import QuinellaBox from "../../components/odds/quinella/Box.vue";
import {getUrlVars, isNar, getJSON, SUCCESS} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
} from "../../assets/js/define";

import {useStoreRaceCommon} from "../../stores/race-common-states";
import {storeToRefs} from "pinia";
const storeRaceCommon = useStoreRaceCommon();
const {
  raceDORVal,
  raceTrackCdVal,
  raceNumVal,
  deadLineVal,
} = storeToRefs(storeRaceCommon);

let modalBetOpen = ref(false);
const currentTab = "quinella";
let params = ref({});
const bracketFlag = ref(true);
let method = ref("");

// 馬券種類タブ
const currentSubTab = ref("formation");

const route = useRoute();
watch(route, async (from, to) => {
  params = to.query;
  await fetchOdds(to.query);
});

onMounted(async () => {
  params.value = getUrlVars();
  await fetchOdds(params.value);
});

const fetchOdds = async (params) => {
  bracketFlag.value = true;
  if (params.method) {
    currentSubTab.value = params.method;
    method.value = params.method;
  }

  var req =
    HOST +
    PATH +
    "Odds/bracketQuinellaOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    params.DOR +
    "&RacetrackCd=" +
    params.RacetrackCd +
    "&RaceNum=" +
    params.RaceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      if (json[0].SaleDiv === 0) bracketFlag.value = false
    }
  });
}

const changeVoteTab = (data) => {
  currentSubTab.value = data;
  const url = new URL(window.location);
  if (data !== "formation") {
    url.searchParams.set('method', data);
    method.value = data;
  } else {
    url.searchParams.delete('method');
    method.value = '';
  }
  window.history.pushState({}, '', url);
};
</script>
<template>
  <main class="layout-main">
    <RaceHeader
      :method="method"
    ></RaceHeader>

    <nav class="nav-multi">
      <OddsTabs v-if="Object.keys(params).length" :currentTab="currentTab" :params="params" :bracketFlag="bracketFlag"></OddsTabs>
      <div class="container-wrapper -pad">
        <div class="container-toggle -kinds">
          <ul>
            <li
              @click="changeVoteTab('formation')"
              class="-free"
              :class="[currentSubTab === 'formation' ? '-current' : '']"
            >
              <div class="label">フォーメーション</div>
            </li>
            <li
              @click="changeVoteTab('popular')"
              class="-box"
              :class="[currentSubTab === 'popular' ? '-current' : '']"
            >
              <div class="label">人気順</div>
            </li>
            <li
              @click="changeVoteTab('nagashi')"
              class="-nagashi"
              :class="[currentSubTab === 'nagashi' ? '-current' : '']"
            >
              <div class="label">流し</div>
            </li>
            <li
              @click="changeVoteTab('box')"
              class="-box"
              :class="[currentSubTab === 'box' ? '-current' : '']"
            >
              <div class="label">ボックス</div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <QuinellaFormation
      v-if="currentSubTab === 'formation'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></QuinellaFormation>
    <QuinellaPopularity
      v-else-if="currentSubTab === 'popular'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    >
    </QuinellaPopularity>
    <QuinellaWheel
      v-else-if="currentSubTab === 'nagashi'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></QuinellaWheel>
    <QuinellaBox
      v-else
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></QuinellaBox>
  </main>

  <SetBetConfModal
    v-on:closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
  ></SetBetConfModal>
</template>

<style lang="scss" src="../../assets/css/race/odds/style.scss" scoped></style>
