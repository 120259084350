<template>
  <div class="inner">
    <h2 class="heading-circle">
      購入ナンバー選択 <span class="_caption">※最大3つまで登録可</span>
    </h2>
    <div class="container-purchasenumber">
      <table class="table-line -middle">
        <tr v-for="(memoriallist, index) in memorialData" :key="index">
          <th>
            <span class="form-checkbox">
              <label>
                <input
                  @change="click(memoriallist, index, $event)"
                  type="checkbox"
                  v-model="memoriallist.CheckNumList"
                  name="sample"
                /><i></i>
              </label>
            </span>
          </th>
          <td style="width: 80%">
            <ul class="list-kaimoku -arrow">
              <li v-for="(num, index) in memoriallist.Number" :key="index">
                <i
                  :class="[
                    'icon-umaban',
                    { '-waku01': num < 3 },
                    { '-waku02': num > 2 && num < 5 },
                    { '-waku03': num > 4 && num < 7 },
                    { '-waku04': num > 6 && num < 9 },
                    { '-waku05': num > 8 && num < 11 },
                    { '-waku06': num > 10 && num < 13 },
                    { '-waku07': num > 12 && num < 16 },
                    { '-waku08': num > 15 },
                  ]"
                >
                  {{ num }}
                </i>
              </li>
            </ul>
            <div class="registname">{{ memoriallist.Keyword }}</div>
          </td>
          <td>
            <span
              class="btn-basic -delete -notext"
              @click="clickDelete(memoriallist.Number)"
              >削除</span
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { getJSON, getCookie, USERID, isNar } from "../../assets/js/common";
import {HOST, BACKWORDPATH, PATH} from "../../assets/js/define";
export default {
  props: {
    memorialData: {
      type: Object,
    },
    race_list: {
      type: Object,
    },
  },

  setup(props, context) {
    const checkData = ref([]);
    const click = (memory, index, e) => {
      if (e.target.checked) {
        memory.CheckNumList = true;
      } else {
        memory.CheckNumList = false;
      }
    };

    const clickDelete = (number) => {
      document.body.className = "";
      var userId = getCookie(USERID);
      let numberList = number.num1 + "→" + number.num2 + "→" + number.num3;

      if (userId == null || userId === "") {
        alert("削除できません");
        document.body.className = "status-loaded";
        return;
      }

      const conFlg = confirm("本当に削除しますか？");
      if (conFlg) {
        const req =
          HOST +
          PATH +
          "MemorialTicket/memorialBettingTicketDelAPI.js" +
          BACKWORDPATH +
          "?userid=" +
          encodeURIComponent(userId) +
          "&numberlist=" +
          numberList +
          "&narFlag=" +
          isNar()
        getJSON(req, true, function (status, json) {
          context.emit("delete");
        });
      }
      document.body.className = "status-loaded";
    };
    return {
      clickDelete,
      click,
      checkData,
    };
  },
};
</script>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>
