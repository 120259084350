import {BACKWORDPATH, DATAPATH, HOST, PATH} from "../../assets/js/define";
import {getJSON, SUCCESS,} from "../../assets/js/common";
import {storeToRefs} from "pinia";
import {useStoreCommon} from "../../stores/common-states"
import {useStoreMareData} from "../../stores/data/mare-states";
import {getBlood} from '../../assets/js/data-common'

let storeMareData = null;
let narFlag = 0;

export async function fetch(breedingNum) {
  const storeCommon = useStoreCommon()
  const {host, isNarSite} = storeToRefs(storeCommon);
  storeMareData = useStoreMareData();
  narFlag = isNarSite.value ? 1 : 0;

  await dataBreeding(breedingNum, host.value);
  await dataBrother(breedingNum, host.value);
}

const dataBreeding = async (breedingNum, host) => {
  const {
    breedingNumValue,
    mareData,
    bloodData,
    crossAppend,
  } = storeToRefs(storeMareData);

  let request =
    host +
    PATH +
    "Master/breedingMasterAPI.js" +
    BACKWORDPATH +
    "?BreedingNum=" +
    breedingNum +
    "&NarFlag=" +
    narFlag;
  request = encodeURI(request);

  await getJSON(request, true, function (status, json) {
    if (status !== SUCCESS || !json?.length) {
      return;
    }
    const useData = json[0];
    const pedigreeInfo = json[0].PedigreeInfo;
    breedingNumValue.value = useData.BreedingNum;

    //競走馬データへのリンク生成
    let urlLink;
    if (useData.PedigreeNum !== "0000000000") {
      urlLink =
        host +
        DATAPATH +
        "horse?PedigreeNum=" +
        useData.PedigreeNum;
    }
    useData.UrlLink = urlLink;
    mareData.value = useData;

    const horseHairColor = {
      HairColorFather: useData.HairColorCd_Father,
      HairColorMother: useData.HairColorCd_Mother,
    };

    if (pedigreeInfo && Object.keys(pedigreeInfo).length) {
      bloodData.value = getBlood(pedigreeInfo, horseHairColor, host);
    }

    crossAppend.value = useData.CrossDegree;
  });
}

const dataBrother = async (breedingNum, host) => {
  const {
    offspringData
  } = storeToRefs(storeMareData);

  const mode = "dam";
  let request =
    host +
    PATH +
    "Master/offspringMasterAPI.js" +
    BACKWORDPATH +
    "?num=" +
    breedingNum +
    "&mode=" +
    mode +
    "&narFlag=" +
    narFlag;
  request = encodeURI(request);
  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json?.length) {
      offspringData.value = json[0].Offspring.map((broodMareData) => {
        //抹消アイコンの設定
        let erasureIcon = "";
        if (broodMareData.SnkDataFlg == 0 && broodMareData.RacehorseDiv == 1) {
          //1は抹消馬の為、抹消アイコン追加
          erasureIcon = "icon-status -erase";
        }
        broodMareData.ErasureIcon = erasureIcon;

        //馬名のリンクを生成
        if (broodMareData.PedigreeNum != "0000000000") {
          let racehorseURL =
            HOST +
            DATAPATH +
            "horse?PedigreeNum=" +
            broodMareData.PedigreeNum;
          broodMareData.RacehorseURL = racehorseURL;
        }

        // 馬の性別ごとにbackground-colorの変更
        let sexClass = "";
        let sexName = "";
        if (broodMareData.SexCd === "1") {
          sexClass = "-left -male";
          sexName = "牡";
        } else if (broodMareData.SexCd === "2") {
          sexClass = "-left -female";
          sexName = "牝";
        } else if (broodMareData.SexCd === "3") {
          sexClass = "-left -senba";
          sexName = "セ";
        }
        broodMareData.SexClass = sexClass;
        broodMareData.SexName = sexName;

        // 馬齢表示条件
        if (
          broodMareData.RacehorseDiv !== 0 ||
          broodMareData.SnkDataFlg !== 0
        ) {
          broodMareData.Age = "";
        }

        //賞金フォーマット
        let totalEarnings = "なし";
        if (broodMareData.TotalEarnings !== "なし") {
          totalEarnings = parseInt(broodMareData.TotalEarnings, 10);
          if (Math.floor(totalEarnings / 10000) > 0) {
            totalEarnings =
              Math.floor(totalEarnings / 10000) +
              "億" +
              (totalEarnings % 10000) +
              "万";
          } else {
            totalEarnings = totalEarnings + "万";
          }
        }
        broodMareData.TotalEarnings = totalEarnings;

        // 入退厩情報
        let stablingFlg = "";
        if (broodMareData.SnkDataFlg == 1) {
          stablingFlg = "icon-status -unregistered";
        }
        broodMareData.SnkDataFlg = stablingFlg;

        return broodMareData;
      });
    }
  });
}