<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  SUCCESS,
  isDismissDialog,
  isNar,
  isAndroid,
  getUserAgent,
  isSugotoku,
} from "../../../assets/js/common";

import {
  HOST,
  PATH,
  BACKWORDPATH,
  RACEPATH,
  METHOD_NORMAL,
  TOB_BRACKET_EXACTA,
} from "../../../assets/js/define";

import SetBetConfModal from "../../../components/utils/SetBetConfModal.vue";
import raceOdds from "../../../functions/odds/race-odds";
import raceDeadline from "../../../functions/odds/race-deadline_countdown";
import {useRoute, useRouter} from "vue-router";

const { setRaceBetByArray } = raceOdds();
const { TimerCount } = raceDeadline();

let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let loadedPage = ref(false)
let oddsUpdInfo = ref("");
const checkedHorse = reactive({});
const router = useRouter();
const isWebView = ref(false);
const isSugotokuMember = ref(false);

let params = {};

const route = useRoute();
watch(route, (from, to) => {
  clearSelected();
  loadPromisingBet();
  doInit(to.query);
})

onMounted(() => {
  document.body.className = "";
  params = getUrlVars();
  isWebView.value = getUserAgent();
  isSugotokuMember.value = isSugotoku();
  //パラメータチェック
  if (checkUrlVars(params)) {
    loadPromisingBet()
    doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});
const props = defineProps({
  raceDORVal: String,
  raceTrackCdVal: String,
  raceNumVal: String,
  deadLineJudge: Boolean,
});
const doInit = async (params) => {
  raceResultCheck(params.DOR, params.RacetrackCd, params.RaceNum, function () {
    //枠単オッズ(人気順)読み込み
    loadOddBracketExactaForPopularity(
      params.DOR,
      params.RacetrackCd,
      params.RaceNum,
      function () {
        loadedPage.value = true
        document.body.className = "status-loaded";
      }
    );
  });
};
const raceResultCheck = (dor, racetrackCd, raceNum, callback) => {
  const request =
    HOST +
    PATH +
    "Odds/winTicketOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      if (json == null || json.length == 0) {
        const url = HOST +
            RACEPATH +
            "card?DOR=" +
            dor +
            "&RacetrackCd=" +
            racetrackCd +
            "&RaceNum=" +
            raceNum;
        return (window.location.href = url);
      } else {
        if (callback != undefined) {
          callback();
        }
      }
    }
  });
};
let tmpOddsObj = reactive([]);
let oddsObj = reactive([]);
let checkList = reactive({});
let displayData = reactive({});
//枠単（人気順）
const loadOddBracketExactaForPopularity = (dor, racetrackCd, raceNum, callback) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "Odds/bracketExactaOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      try {
        displayData = Object.assign(displayData, json[0]);
        if (displayData["OddsUpdInfo"] != null)
          oddsUpdInfo.value = displayData["OddsUpdInfo"];
        tmpOddsObj.splice(0, tmpOddsObj.length);
        for (let i = 1; i <= 8; i++) {
          for (let j = 1; j <= 8; j++) {
            const id = "Odds" + i + "_" + j;
            if (displayData[id] && displayData[id].indexOf("---.-") == -1) {
              displayData[id] = displayData[id] === '0.0' ? 9999 : displayData[id];
              tmpOddsObj.push({
                name: id,
                Odds: parseFloat(displayData[id]),
                left: i,
                right: j,
              });
            }
          }
        }

        // "Odds"で昇順でソート
        const sort_by = function (field, reverse, primer) {
          reverse = reverse ? -1 : 1;
          return function (a, b) {
            a = a[field] * 10;
            b = b[field] * 10;
            if (typeof primer != "undefined") {
              a = primer(a);
              b = primer(b);
            }
            if (a < b) return reverse * -1;
            if (a > b) return reverse * 1;
          };
        };
        tmpOddsObj.sort(sort_by("Odds", false, parseInt));
        oddsObj = Object.assign(oddsObj, tmpOddsObj.slice(0, 20));
        for (let i = 0; i < Object.keys(oddsObj).length; i++) {
          checkList[i] = checkStorageArray?.includes(String(i)) ? true : false;
        }
        checkedHorseSum()
        if (oddsObj && Object.keys(oddsObj).length) {
          for (let m = 0; m < Object.keys(oddsObj).length; m++) {
            const uma = oddsObj[m].name.substring(4).split("_");
            let value = "";
            for (let p = 1; p <= 18; p++) {
              if (p == parseInt(uma[1], 10)) {
                value += "1";
              } else {
                value += "0";
              }
            }
          }
        }
        return callback();
      } catch (e) {
        console.log(e);
        return callback();
      }
    }
  });
};
const smallNumberPoint = (argValue) => {
  if (argValue >= 9999) {
    argValue = '0.0'
  } else if (String(argValue).indexOf(".") == -1) {
    argValue = argValue + ".0";
  }
  return argValue;
};

// オッズの色
const oddsClass = (val) => {
  if (val == "---.-") {
    return "txt-odds";
  } else if (val > 0 && val < 10) {
    return "txt-odds -no1";
  } else if (val > 0 && val < 100) {
    return "txt-odds -no2";
  } else return "txt-odds -other";
};
//チェックリスト関連
let checkAllJudge = ref(false);
let checkedSum = ref(0);
let checkStorageArray = reactive([])
const loadPromisingBet = () => {
  const checkListStorage = localStorage.getItem('bracket_exacta_popularity' + location.search)
  if (checkListStorage) checkStorageArray = JSON.parse(checkListStorage)
}
const checkChange = (num) => {
  if (checkList[num] === true) {
    checkList[num] = false;
    checkStorageArray?.splice(checkStorageArray?.indexOf(String(num)),1)
  } else {
    checkList[num] = true;
    checkStorageArray?.push(String(num))
  }
  localStorage.setItem('bracket_exacta_popularity' + location.search, JSON.stringify(checkStorageArray))
  checkedHorseSum();
};
const checkedHorseSum = () => {
  let checkedNum = 0;
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    if (checkList[i] === true) {
      checkedNum++;
    }
  }
  checkedSum.value = checkedNum;
};
const checkedAll = () => {
  if (checkAllJudge.value == true) {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      checkList[i] = false;
      checkStorageArray?.splice(checkStorageArray?.indexOf(String(i)),1)
    }
    checkAllJudge.value = false;
  } else {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      checkList[i] = true;
      if (checkStorageArray?.includes(String(i))) continue
      checkStorageArray?.push(String(i))
    }
    checkAllJudge.value = true;
  }
  localStorage.setItem('bracket_exacta_popularity' + location.search, JSON.stringify(checkStorageArray))
  checkedHorseSum();
};
// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkList,() => {
  const checkLists = Object.values(checkList)
  if(checkLists.length) checkAllJudge.value = checkLists.find(check => check === false) === undefined
},{immediate:true})
// 買い目登録
const setRaceBetWin = () => {
  let setRaceBetParams = getUrlVars();
  let openWindow
  if (isDismissDialog() == 1 && !isAndroid() && !getUserAgent() && !isSugotoku()) {
    openWindow = window.open('', '_blank')
  }

  if (props.raceDORVal != null) {
    setRaceBetParams["DOR"] = props.raceDORVal;
  }
  if (props.raceTrackCdVal != null) {
    setRaceBetParams["RacetrackCd"] = props.raceTrackCdVal;
  }
  if (props.raceNumVal != null) {
    setRaceBetParams["RaceNum"] = props.raceNumVal;
  }

  let betArray = [];
  let doFlgArray = [];

  oddsObj.forEach((o, index) => {
    if (checkList[index]) {
      const bracketNumArray = o.name.substring(4).split("_").map(num => parseInt(num, 10));
      let value = "";
      for (let o = 1; o <= 18; o++) {
        value += o === bracketNumArray[0] ? "1" : "0";
      }
      for (let p = 1; p <= 18; p++) {
        value += p === bracketNumArray[1] ? "1" : "0";
      }
      betArray.push(value);
      doFlgArray.push(true);
    }
  });
  if (betArray.length > 0) {
    //順番に登録させるため再帰的に登録
    setRaceBetByArray(
      setRaceBetParams,
      TOB_BRACKET_EXACTA,
      METHOD_NORMAL,
      betArray,
      doFlgArray,
      function () {
        if (isDismissDialog() != 1) {
          //モーダルウィンドウ表示
          raceBetCheck.value = true;
          modalBetOpen.value = true;
        } else {
          if (isAndroid() || getUserAgent() || isSugotoku()) {
            router.push("/purchase/spat4")
          }
          else {
            openWindow.location.href = HOST + "purchase/spat4"
          }
        }
      }
    );
    checkStorageArray.splice(0)
    localStorage.removeItem('bracket_exacta_popularity' + location.search)
  } else {
    alert("項目が選択されていません。");
  }
};
// 買い目削除
const deleteSelected = () => {
  const result = confirm('枠単/人気順に対して全ての買い目を削除しますか？')
  if (result) {
    clearStorage()
    clearSelected()
  }
}

const clearStorage = () => {
  localStorage.removeItem('bracket_exacta_popularity' + location.search)
}

const clearSelected = () => {
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    checkList[i] = false;
  }
  checkStorageArray.splice(0)
  checkAllJudge.value = false
  checkedHorseSum();
}
</script>

<template>
  <section class="layout-section" v-if="loadedPage">
    <div class="layout-title">
      <h2 class="heading-circle -padding">枠単 / 人気順</h2>
      <div class="nav">
        <span v-if="props.deadLineJudge" class="status">締め切り間近</span>
        <span :class="[oddsUpdInfo ? 'icon-kakutei' : '']">{{oddsUpdInfo}}</span>
      </div>
    </div>
    <table class="table-grid -fit -middle -center -odds">
      <thead>
        <tr>
          <th>組み合わせ</th>
          <th>オッズ</th>
          <th class="ranking">
            <span class="form-checkbox">
              <label>
                <input type="checkbox"
                       @click="checkedAll()"
                       v-model="checkAllJudge"
                       value=""
                       name="" /><i></i>
              </label>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in oddsObj" :key="o">
          <td>
            <ul class="list-kaimoku -arrow">
              <li>
                <i :class="`icon-umaban -waku0${o.left}`">
                  {{ o.left }}
                </i>
              </li>
              <li>
                <i :class="`icon-umaban -waku0${o.right}`">
                  {{ o.right }}
                </i>
              </li>
            </ul>
          </td>
          <td class="-right">
            <div class="txt-odds" :class="oddsClass(o.Odds)">
              {{ smallNumberPoint(o.Odds) }}
            </div>
          </td>
          <td>
            <span class="form-checkbox">
              <label>
                <input type="checkbox"
                       @click="checkChange(index)"
                       v-model="checkList[index]"
                       value=""
                       name="" />
                <i></i>
              </label>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="nav-group -right">
      <span @click="deleteSelected" class="btn-basic -delete">削除</span>
    </nav>  
    <div class="container-total">
      <dl class="amount">
        <dt>点数合計</dt>
        <dd>
          <span class="num">{{ checkedSum }}</span>点
        </dd>
      </dl>
      <div @click="setRaceBetWin" class="btn-basic">買い目登録</div>
    </div>
    <div class="container-schedule">
      <router-link to="/purchase/spat4/" v-if="isWebView || isSugotokuMember" class="btn-basic -normal">購入予定一覧</router-link>
      <a href="/purchase/spat4/" v-else target="_blank" class="btn-basic -normal">購入予定一覧</a>
      <p class="_caption">
        ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
      </p>
    </div>
  </section>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
  <SetBetConfModal
    @closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
    :isSpat4="true">
  </SetBetConfModal>
</template>

<style
  lang="scss"
  src="../../../assets/css/race/odds/style.scss"
  scoped>
</style>
