<script setup>
import { ref, onMounted } from "vue";
import {getJSON, getUrlVars, SUCCESS} from "../../assets/js/common";
import {BACKWORDPATH, HOST, JRA_WP_HOST, PATH} from "../../assets/js/define";
import axios from "axios";

const checkedSyllabary = ref("a");

const yearTitle = ref("");

const lists = ref('');
const listA = ref('');
const listKA = ref('');
const listSA = ref('');
const listTA = ref('');
const listNA = ref('');
const listHA = ref('');
const listMA = ref('');
const listYA = ref('');
const listRA = ref('');
const listWA = ref('');

const A = "a";
const KA = "ka";
const SA = "sa";
const TA = "ta";
const NA = "na";
const HA = "ha";
const MA = "ma";
const YA = "ya";
const RA = "ra";
const WA = "wa";

function getSyllabaryData(data, word) {
  if (word === A) {
    listA.value = data
  } else if (word === KA) {
    listKA.value = data
  } else if (word === SA) {
    listSA.value = data
  } else if (word === TA) {
    listTA.value = data
  } else if (word === NA) {
    listNA.value = data
  } else if (word === HA) {
    listHA.value = data
  } else if (word === MA) {
    listMA.value = data
  } else if (word === YA) {
    listYA.value = data
  } else if (word === RA) {
    listRA.value = data
  } else if (word === WA) {
    listWA.value = data
  }
}

onMounted(() => {
  getParam();

  document.body.className = "";
  const request =
      HOST +
      PATH +
      "Master/getJraHorseMasterDetail.js" +
      BACKWORDPATH +
      "?slug=" + year;
  document.body.className = "";
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      yearTitle.value = json.title.rendered;

      //5音ごとに格納
      getSyllabaryData(json.acf.a, "a");
      getSyllabaryData(json.acf.ka, "ka");
      getSyllabaryData(json.acf.sa, "sa");
      getSyllabaryData(json.acf.ta, "ta");
      getSyllabaryData(json.acf.na, "na");
      getSyllabaryData(json.acf.ha, "ha");
      getSyllabaryData(json.acf.ma, "ma");
      getSyllabaryData(json.acf.ya, "ya");
      getSyllabaryData(json.acf.ra, "ra");
      getSyllabaryData(json.acf.wa, "wa");
    }
    document.body.className = "status-loaded";
  });

})
let year = "";
let syllabary = "";
function getParam() {
  const params = getUrlVars();

  year = params.slug;
  syllabary = params.key;

  //クエリが渡ってきていない場合の初期値をア行とする
  checkedSyllabary.value = syllabary || A;
}

function changeSyllabary(word) {
  checkedSyllabary.value = word;
}
function createLink(row) {
  return `/data/horse?PedigreeNum=${row.PedigreeNum}`
}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">JRA登録馬一覧</h1>
      <div class="nav">
        <router-link to="/horselist/" class="btn-basic -prev">一覧へ戻る</router-link>
      </div>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner" data-tab='["data-trigger", "data-target"]'>
          <div class="layout-subsection">
            <h2 class="heading-circle">{{ yearTitle }}</h2>
            <div class="nav-multi -cols5 -noborder">
              <ul class="list" data-trigger>
                <li
                  @click="changeSyllabary('a')"
                  data-sort="a"
                  :class="[checkedSyllabary === 'a' ? '-current' : '']"
                >
                  <span class="label">ア行</span>
                </li>
                <li
                  @click="changeSyllabary('ka')"
                  data-sort="ka"
                  :class="[checkedSyllabary === 'ka' ? '-current' : '']"
                >
                  <span class="label">カ行</span>
                </li>
                <li
                  @click="changeSyllabary('sa')"
                  data-sort="sa"
                  :class="[checkedSyllabary === 'sa' ? '-current' : '']"
                >
                  <span class="label">サ行</span>
                </li>
                <li
                  @click="changeSyllabary('ta')"
                  data-sort="ta"
                  :class="[checkedSyllabary === 'ta' ? '-current' : '']"
                >
                  <span class="label">タ行</span>
                </li>
                <li
                  @click="changeSyllabary('na')"
                  data-sort="na"
                  :class="[checkedSyllabary === 'na' ? '-current' : '']"
                >
                  <span class="label">ナ行</span>
                </li>
                <li
                  @click="changeSyllabary('ha')"
                  data-sort="ha"
                  :class="[checkedSyllabary === 'ha' ? '-current' : '']"
                >
                  <span class="label">ハ行</span>
                </li>
                <li
                  @click="changeSyllabary('ma')"
                  data-sort="ma"
                  :class="[checkedSyllabary === 'ma' ? '-current' : '']"
                >
                  <span class="label">マ行</span>
                </li>
                <li
                  @click="changeSyllabary('ya')"
                  data-sort="ya"
                  :class="[checkedSyllabary === 'ya' ? '-current' : '']"
                >
                  <span class="label">ヤ行</span>
                </li>
                <li
                  @click="changeSyllabary('ra')"
                  data-sort="ra"
                  :class="[checkedSyllabary === 'ra' ? '-current' : '']"
                >
                  <span class="label">ラ行</span>
                </li>
                <li
                  @click="changeSyllabary('wa')"
                  data-sort="wa"
                  :class="[checkedSyllabary === 'wa' ? '-current' : '']"
                >
                  <span class="label">ワ行</span>
                </li>
              </ul>
            </div>
          </div>
          <transition name="top" mode="out-in">

            <div data-target v-if="checkedSyllabary === 'a'">
              <h2 class="heading-item">ア行</h2>
              <div class="item">
                <table class="table-grid -padding -horselist">
                  <thead>
                    <tr class="-center">
                      <th class="name">馬名</th>
                      <th class="sex">性別</th>
                      <th class="birth">生年月日</th>
                      <th class="hair">毛色</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="-center" v-for="(row, index) in listA" >
                      <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                      <td class="sex">{{ row.Sex }}</td>
                      <td class="birth">{{ row.Birth }}</td>
                      <td class="hair">{{ row.Hair }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div data-target v-else-if="checkedSyllabary === 'ka'">
              <h2 class="heading-item">カ行</h2>
              <div class="item">
                <table class="table-grid -padding -horselist">
                  <thead>
                  <tr class="-center">
                    <th class="name">馬名</th>
                    <th class="sex">性別</th>
                    <th class="birth">生年月日</th>
                    <th class="hair">毛色</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="-center" v-for="(row, index) in listKA" >
                    <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                    <td class="sex">{{ row.Sex }}</td>
                    <td class="birth">{{ row.Birth }}</td>
                    <td class="hair">{{ row.Hair }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div data-target v-else-if="checkedSyllabary === 'sa'">
              <h2 class="heading-item">サ行</h2>
              <table class="table-grid -padding -horselist">
                <thead>
                <tr class="-center">
                  <th class="name">馬名</th>
                  <th class="sex">性別</th>
                  <th class="birth">生年月日</th>
                  <th class="hair">毛色</th>
                </tr>
                </thead>
                <tbody>
                <tr class="-center" v-for="(row, index) in listSA" >
                  <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                  <td class="sex">{{ row.Sex }}</td>
                  <td class="birth">{{ row.Birth }}</td>
                  <td class="hair">{{ row.Hair }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div data-target v-else-if="checkedSyllabary === 'ta'">
              <h2 class="heading-item">タ行</h2>
              <table class="table-grid -padding -horselist">
                <thead>
                <tr class="-center">
                  <th class="name">馬名</th>
                  <th class="sex">性別</th>
                  <th class="birth">生年月日</th>
                  <th class="hair">毛色</th>
                </tr>
                </thead>
                <tbody>
                <tr class="-center" v-for="(row, index) in listTA" >
                  <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                  <td class="sex">{{ row.Sex }}</td>
                  <td class="birth">{{ row.Birth }}</td>
                  <td class="hair">{{ row.Hair }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div data-target v-else-if="checkedSyllabary === 'na'">
              <h2 class="heading-item">ナ行</h2>
              <table class="table-grid -padding -horselist">
                <thead>
                <tr class="-center">
                  <th class="name">馬名</th>
                  <th class="sex">性別</th>
                  <th class="birth">生年月日</th>
                  <th class="hair">毛色</th>
                </tr>
                </thead>
                <tbody>
                <tr class="-center" v-for="(row, index) in listNA" >
                  <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                  <td class="sex">{{ row.Sex }}</td>
                  <td class="birth">{{ row.Birth }}</td>
                  <td class="hair">{{ row.Hair }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div data-target v-else-if="checkedSyllabary === 'ha'">
              <h2 class="heading-item">ハ行</h2>
              <table class="table-grid -padding -horselist">
                <thead>
                <tr class="-center">
                  <th class="name">馬名</th>
                  <th class="sex">性別</th>
                  <th class="birth">生年月日</th>
                  <th class="hair">毛色</th>
                </tr>
                </thead>
                <tbody>
                <tr class="-center" v-for="(row, index) in listHA" >
                  <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                  <td class="sex">{{ row.Sex }}</td>
                  <td class="birth">{{ row.Birth }}</td>
                  <td class="hair">{{ row.Hair }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div data-target v-else-if="checkedSyllabary === 'ma'">
              <h2 class="heading-item">マ行</h2>
              <table class="table-grid -padding -horselist">
                <thead>
                <tr class="-center">
                  <th class="name">馬名</th>
                  <th class="sex">性別</th>
                  <th class="birth">生年月日</th>
                  <th class="hair">毛色</th>
                </tr>
                </thead>
                <tbody>
                <tr class="-center" v-for="(row, index) in listMA" >
                  <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                  <td class="sex">{{ row.Sex }}</td>
                  <td class="birth">{{ row.Birth }}</td>
                  <td class="hair">{{ row.Hair }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div data-target v-else-if="checkedSyllabary === 'ya'">
              <h2 class="heading-item">ヤ行</h2>
              <table class="table-grid -padding -horselist">
                <thead>
                <tr class="-center">
                  <th class="name">馬名</th>
                  <th class="sex">性別</th>
                  <th class="birth">生年月日</th>
                  <th class="hair">毛色</th>
                </tr>
                </thead>
                <tbody>
                <tr class="-center" v-for="(row, index) in listYA" >
                  <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                  <td class="sex">{{ row.Sex }}</td>
                  <td class="birth">{{ row.Birth }}</td>
                  <td class="hair">{{ row.Hair }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div data-target v-else-if="checkedSyllabary === 'ra'">
              <h2 class="heading-item">ラ行</h2>
              <table class="table-grid -padding -horselist">
                <thead>
                <tr class="-center">
                  <th class="name">馬名</th>
                  <th class="sex">性別</th>
                  <th class="birth">生年月日</th>
                  <th class="hair">毛色</th>
                </tr>
                </thead>
                <tbody>
                <tr class="-center" v-for="(row, index) in listRA" >
                  <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                  <td class="sex">{{ row.Sex }}</td>
                  <td class="birth">{{ row.Birth }}</td>
                  <td class="hair">{{ row.Hair }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div data-target v-else-if="checkedSyllabary === 'wa'">
              <h2 class="heading-item">ワ行</h2>
              <table class="table-grid -padding -horselist">
                <thead>
                <tr class="-center">
                  <th class="name">馬名</th>
                  <th class="sex">性別</th>
                  <th class="birth">生年月日</th>
                  <th class="hair">毛色</th>
                </tr>
                </thead>
                <tbody>
                <tr class="-center" v-for="(row, index) in listWA" >
                  <td class="name"><router-link :to="createLink(row)">{{ row.HorseName }}</router-link></td>
                  <td class="sex">{{ row.Sex }}</td>
                  <td class="birth">{{ row.Birth }}</td>
                  <td class="hair">{{ row.Hair }}</td>
                </tr>
                </tbody>
              </table>
            </div>

          </transition>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/horselist/style.scss" scoped></style>
<style lang="scss" scoped>
.bottom-enter-active {
  transition: opacity 0.1s;
}

.bottom-leave {
  display: none;
}

.bottom-enter-from,
.bottom-leave-to {
  opacity: 0;
}

.top-enter-active,
.bottom-leave-active {
  transition: opacity 0.1s;
}

.top-enter-from,
.top-leave-to {
  opacity: 0;
}
</style>
