<script setup>
import { ref, reactive, onMounted, computed, toRefs } from "vue";
import { HOST, JRA_WP_HOST, PATH, BACKWORDPATH } from "../../../assets/js/define";
import axios from "axios";
import {
  getUrlVars,
  PAYINGNONE,
  PAYINGECONOMY,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  SUCCESS,
  getJSON,
} from "../../../assets/js/common";

const API_URL = JRA_WP_HOST + "wp-json/wp/v2/sumii/";

let list = reactive({});
onMounted(() => {
  document.body.className = "";
  isPremiumUser();
  getUrlId();
  const request_url = API_URL + id.value;

  axios
    .get(request_url)
    .then((response) => {
      Object.assign(list, response.data);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});

const id = ref(0);
const getUrlId = () => {
  const params = getUrlVars();
  id.value = params.id;
};

const premiumUser = ref(false);
const isPremiumUser = () => {
  //ユーザのコース情報を取得し、プレミアム以外であれば各会員登録ページへ遷移
  const logReq = HOST + PATH + "getUserCourse.js" + BACKWORDPATH;
  getJSON(logReq, true, function (status, json) {
    let courseId = PAYINGNONE;

    if (status === SUCCESS) {
      if (json && json.courseId && json.courseId != "") {
        courseId = json.courseId;
        if ([PAYINGPREMIUM, PAYINGPACK03, PAYINGPACK06, PAYINGPACK12].includes(courseId)) {
          premiumUser.value = true;
          return;
        }
      }
    }
    if (courseId === PAYINGECONOMY) {
      window.location.href = HOST + "member/join_economy";
    } else if (courseId === PAYINGNONE) {
      window.location.href = HOST + "member";
    }
  });
};
</script>
<template>
  <main class="layout-main" v-if="premiumUser === true">
    <section class="layout-pageheader">
      <h1 class="heading-page">独占コラム</h1>
      <a href="/column/sumii/" class="btn-basic -prev">一覧</a>
    </section>
    <div class="image-fit">
      <img src="/assets/images/clumn/sumii/column_banner_2.png" alt="" />
    </div>
    <div v-if="list.content" v-html="list.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../../assets/css/news/style.scss" scoped></style>
