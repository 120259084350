import {
  retrieveCourse,
  getJSON, SUCCESS,
} from '../assets/js/common';
import {BACKWORDPATH, HOST, POGAPIPATH} from "../assets/js/define";
import moment from "moment";
export default function () {
  //POG会員状況によってページ遷移する
  const loadPogUserJudge = async (callback) => {
    callRetrieveCourse(function (courseId, showMarks, userId) {
      const request =
          HOST +
          POGAPIPATH +
          "getPogUserInfo.js" +
          BACKWORDPATH +
          "?UserID=" +
          userId;

      getJSON(request, true, function (status, json) {
        if (json && json !== 1) {
          callback();
        } else {
          //非会員・POGユーザ未登録の場合はPOGユーザ登録画面へ
          location.href = "/pog/join";
        }
      });
    });
  };
  function callRetrieveCourse(callback) {
    try {
      retrieveCourse(callback);
    } catch (e) {
      callback("none");
    }
  }
  //現在のPOG年度を取得
  const getCurrentYear = async () => {
    const currentDate = moment();

    const request =
      HOST +
      POGAPIPATH +
      "getPogStartDate.js" +
      BACKWORDPATH;

    let pogStartDate = '';
    await getJSON(request, true, function (status, json) {
      if (json && json !== 1) {
        pogStartDate = json.pogStartDate;
      }
    });
    if (pogStartDate && moment(pogStartDate).isSameOrBefore(currentDate)) {
      return currentDate.format('YYYY');
    } else {
      return currentDate.subtract(1, 'y').format('YYYY');
    }
  };

  const getPogStartDate = async () => {
    const currentDate = moment();
    const currentYear = currentDate.year();
    let lastTuesday = moment(`${currentYear}-05-01`);
    lastTuesday = lastTuesday.endOf('month').startOf('week').add(2, 'days'); // 月末の週の火曜日
    if (lastTuesday.month() !== 4) {
      lastTuesday = lastTuesday.subtract(1, 'weeks');
    }

    const returnObj = {
      nextSeasonStart: lastTuesday.format('YYYYMMDD'),
      first: (currentYear - 1).toString(),
      second: currentYear.toString()
    };
    let pogStartDate;
    const pogStartRequest =
      HOST +
      POGAPIPATH +
      "getPogStartDate.js" +
      BACKWORDPATH;
    await getJSON(pogStartRequest, true, function (status, json) {
      if (json.pogStartDate) {
        pogStartDate = json.pogStartDate;

        // 年度情報の設定
        const startDate = new Date(
          parseInt(pogStartDate.slice(0, 4)),
          parseInt(pogStartDate.slice(4, 6)) - 1,
          parseInt(pogStartDate.slice(6, 8))
        );
        returnObj.nextSeasonStart = pogStartDate;
        returnObj.second = startDate.getFullYear().toString();
        returnObj.first = (startDate.getFullYear() - 1).toString();
      }
    });
    return returnObj;
  }
  return {
    loadPogUserJudge,
    getCurrentYear,
    getPogStartDate
  };
}
