<script setup>
import {onMounted, onServerPrefetch, ref, watch} from "vue";
import NewInfo from "../../components/data/owner/NewInfo.vue";
import TotalResults from "../../components/data/owner/TotalResults.vue";
import Race from "../../components/data/owner/Race.vue";
import OwnedHorse from "../../components/data/owner/OwnedHorse.vue";

import {
  ADD,
  changeFavoriteFromCookie,
  FOWNER, FRACEHORSE,
  getJSON,
  getUrlVars,
  getUserId,
  isFavorite,
  isNar,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  REMOVE,
  retrieveCourse,
  SUCCESS,
} from "../../assets/js/common";
import {BACKWORDPATH, HOST, PATH,} from "../../assets/js/define";
import {useStoreOwnerData} from '../../stores/data/owner-states';
import {storeToRefs} from 'pinia'
import {useRoute} from 'vue-router'
import {fetch as fetchOwner} from '../../functions/data/owner'

const favClass = ref('');
const isDisplayFav = ref(false);
const loadedPage = ref(false);
const courseId = ref('');
const userId = ref('');
const isDisplayNar = ref(false);
const tab = ref("newInfo");
const isCurrentTab = ref("newInfo");

const storeOwner = useStoreOwnerData();
const {
  horseOwnerCdValue,
  ownerData,
  careerStatData,
  bigPrizeData,
  belongHorse,
  schedule,
  totalRaces,
} = storeToRefs(storeOwner);

const route = useRoute();
onServerPrefetch(async () => {
  await fetchOwner(route.query["HorseOwnerCd"]);
  loadedPage.value = true;
});

watch(route, async (from, to) => {
  await fetchOwner(to.query["HorseOwnerCd"]);
})

onMounted(async () => {
  //パラメータ取得
  document.body.className = "";
  isDisplayNar.value = isNar() === 1
  let params = getUrlVars();

  if (horseOwnerCdValue.value !== route.query["HorseOwnerCd"]) {
    await fetchOwner(route.query["HorseOwnerCd"]);
  }

  if (!ownerData.value) {
    alert('馬主データが存在しません。')
    loadedPage.value = true;
    document.body.className = "status-loaded";
    return;
  }

  callRetrieveCourse(async function (jsonCourseId, showMarks, jsonUserId) {
    courseId.value = jsonCourseId;
    userId.value = jsonUserId || getUserId();
    horseOwnerCdValue.value = params.HorseOwnerCd

    //お気に入りの表示
    if (courseId.value !== PAYINGNONE && courseId.value !== PAYINGSUGOTOKU_NONE) {
      isDisplayFav.value = true;
      favClass.value = isFavorite(FOWNER, horseOwnerCdValue.value) === 1 ? '-registed' : '';

      belongHorse.value.forEach(function (horse) {
        horse.favClass = isFavorite(FRACEHORSE, horse.PedigreeNum) === 1 ? '-registed' : '';
      })
    }

    loadedPage.value = true;
    document.body.className = "status-loaded";
  });
})

const tabChange = (data) => {
  tab.value = data;
  isCurrentTab.value = data;
}

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false, '');
  }
}

const clickFav = async () => {
  try {
    document.body.className = "";

    if (isFavorite(FOWNER, horseOwnerCdValue.value) === 1) {
      // お気に入り登録済みなので削除する
      const request =
        HOST +
        PATH +
        "MyFavorite/deleteMyFavoriteHorseOwner.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(userId.value) +
        "&horseOwnerCd=" +
        horseOwnerCdValue.value +
        "&narFlag=" +
        isNar();
      const order = REMOVE;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの削除に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FOWNER,
        horseOwnerCdValue.value,
        order
      );
      favClass.value = favJudge ? '' : '-registed';
    } else {
      // お気に入り未登録なので登録する
      const request =
        HOST +
        PATH +
        "MyFavorite/setMyFavoriteHorseOwner.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(userId.value) +
        "&horseOwnerCd=" +
        horseOwnerCdValue.value +
        "&isAuto=0" +
        "&narFlag=" +
        isNar();
      const order = ADD;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの登録に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FOWNER,
        horseOwnerCdValue.value,
        order
      );
      favClass.value = favJudge ? '-registed' : '';
    }

    document.body.className = "status-loaded";
  } catch (e) {
    console.log("error" + e);
  }
}

</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -horse">
      <h1 class="heading-page">馬主データ</h1>
    </section>

    <section class="layout-section" v-if="ownerData && loadedPage">
      <div class="summary-title">
        <div class="summary">
          <div class="inner">
            <h2 class="title">{{ ownerData.HorseOwnerName }}</h2>
            <div class="caption">
              {{ ownerData.HorseOwnerNameAlpha }}
            </div>
          </div>
          <ul class="list-icon -bottomleft">
            <li>
              <span
                v-if="isDisplayFav"
                class="icon-fav"
                :class="favClass"
                @click="clickFav"
              ></span>
            </li>
          </ul>
        </div>
        <div class="photo -owner">
          <img src="../../assets/images/common/blank_square.png" alt :style="ownerData.Style">
        </div>
      </div>
      <div class="inner">
        <table class="table-grid -data" v-if="ownerData">
          <tr>
            <th>本年勝利</th>
            <td v-html="ownerData.LatestWinsRanking"></td>
          </tr>
          <tr>
            <th>本年度獲得賞金</th>
            <td>{{ ownerData.LatestAnnualEarnings }}</td>
          </tr>
          <tr>
            <th>通算成績</th>
            <td>{{ ownerData.CareerWins }}勝</td>
          </tr>
        </table>
        <p class="p-caption -right" v-if="ownerData">
          {{ ownerData.LeadingUpdated }}
        </p>
      </div>
      <div data-tab='["data-trigger", "data-target"]'>
        <div class="nav-tab -fit4">
          <ul data-trigger>
            <li
              @click="tabChange('newInfo')"
              :class="{ '-current': isCurrentTab === 'newInfo' }"
            >
              <span class="label">最新情報</span>
            </li>
            <li
              @click="tabChange('totalResults')"
              :class="{ '-current': isCurrentTab === 'totalResults' }"
            >
              <span class="label">通算成績</span>
            </li>
            <li
              @click="tabChange('race')"
              :class="{ '-current': isCurrentTab === 'race' }"
            >
              <span class="label">重賞勝鞍</span>
            </li>
            <li
              @click="tabChange('ownedHorse')"
              :class="{ '-current': isCurrentTab === 'ownedHorse' }"
            >
              <span class="label">所有馬一覧</span>
            </li>
          </ul>
        </div>
        <div class="container-tabtarget" data-target>
          <NewInfo
            v-if="tab === 'newInfo'"
            :schedule="schedule"
            :totalRaces="totalRaces"
            :loadedPage="loadedPage"
            :isDisplayNar="isDisplayNar"
          />
          <TotalResults
            v-if="tab === 'totalResults'"
            :careerStatData="careerStatData"
          />
          <Race v-if="tab === 'race'" :bigPrizeData="bigPrizeData"/>
          <OwnedHorse v-if="tab === 'ownedHorse'"
                      :belongHorse="belongHorse"
                      :isDisplayFav="isDisplayFav"
          />
        </div>
      </div>
    </section>
    <template v-if="loadedPage">
      <p v-if="!isDisplayNar" class="p-caption -padding">
        ※総賞金・通算成績・条件実績・重賞勝鞍・G1実績・各馬一覧等のデータは開催終了の翌日の更新となります。<br>
      </p>
      <p class="p-caption -padding">
        ※出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。<br>
        　<template v-if="isDisplayNar">成績集計期間は2020年04月01日以降になります。</template>
      </p>
    </template>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading"/>
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/data/style.scss" scoped></style>
