<template>
  <div class="container-tabtarget" data-target>
    <div class="container-toggle -marginbottom -white">
      <ul data-btn>
        <li
          @click="tabClick('thisYear')"
          :class="{ '-current': isCurrentAchievementTab === 'thisYear' }"
        >
          <span class="label">今年度</span>
        </li>
        <li
          @click="tabClick('total')"
          :class="{ '-current': isCurrentAchievementTab === 'total' }"
        >
          <span class="label">通算</span>
        </li>
      </ul>
    </div>
    <AchievementDetail
      v-if="achievementTab === 'thisYear'"
      :raceTrackData="raceTrackData"
      :sexData="sexData"
      :ageData="ageData"
      :raceConditionData="raceConditionData"
      :distanceData="distanceData"
      :ageGraph="ageGraph"
      :conditionGraph="conditionGraph"
      :distanceGraph="distanceGraph"
      :NARFLAG="NARFLAG"
    />
    <AchievementDetail
      v-if="achievementTab === 'total'"
      :raceTrackData="raceTrackAllData"
      :sexData="sexAllData"
      :ageData="ageAllData"
      :raceConditionData="raceConditionAllData"
      :distanceData="distanceAllData"
      :ageGraph="ageAllGraph"
      :conditionGraph="conditionAllGraph"
      :distanceGraph="distanceAllGraph"
      :NARFLAG="NARFLAG"
    />
  </div>
</template>

<script>
import {
  totalRes,
  winFormat,
  rateFirst,
  rateSecond,
  rateThird,
  isNar,
} from "../../../assets/js/common.js";
import AchievementDetail from "./AchievementDetail.vue";

export default {
  data() {
    return {
      NARFLAG: null,
      raceTrackData: {
        RaceFirstRow: [
          {
            Cd: "44",
            name: "大井",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "45",
            name: "川崎",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "43",
            name: "船橋",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "42",
            name: "浦和",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "30",
            name: "門別",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
        RaceFirstRowJRA: [
          {
            Cd: "05",
            name: "東京",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "06",
            name: "中山",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "08",
            name: "京都",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "09",
            name: "阪神",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "07",
            name: "中京",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
        RaceSecondRow: [
          {
            Cd: "35",
            name: "盛岡",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "36",
            name: "水沢",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "48",
            name: "名古屋",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "47",
            name: "笠松",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "50",
            name: "園田",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
        RaceSecondRowJRA: [
          {
            Cd: "01",
            name: "札幌",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "02",
            name: "函館",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "03",
            name: "福島",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "04",
            name: "新潟",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "10",
            name: "小倉",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
        RaceThirdRow: [
          {
            Cd: "51",
            name: "姫路",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "46",
            name: "金沢",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "54",
            name: "高知",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "55",
            name: "佐賀",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "33",
            name: "帯広ば",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
      },
      sexData: ["0戦 [0 0 0 0]", "0戦 [0 0 0 0]", "0戦 [0 0 0 0]"],
      ageData: [
        {
          title: "2歳",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        {
          title: "3歳",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        {
          title: "3歳以上",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        {
          title: "4歳以上",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
      ],
      raceConditionData: {
        701: {
          title: "新馬・未勝利",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        "005": {
          title: "1勝クラス500万下",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        "010": {
          title: "2勝クラス1000万下",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        "016": {
          title: "3勝クラス1600万下",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        999: {
          title: "オープン",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        sp: {
          title: "重賞",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        g1: {
          title: "G1",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
      },
      distanceData: {
        1: {
          title: "～1200m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        2: {
          title: "～1600m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        3: {
          title: "～2000m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        4: {
          title: "～2400m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        5: {
          title: "～3000m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        6: {
          title: "3001m〜",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
      },

      raceTrackAllData: {
        RaceFirstRow: [
          {
            Cd: "44",
            name: "大井",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "45",
            name: "川崎",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "43",
            name: "船橋",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "42",
            name: "浦和",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "30",
            name: "門別",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
        RaceFirstRowJRA: [
          {
            Cd: "05",
            name: "東京",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "06",
            name: "中山",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "08",
            name: "京都",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "09",
            name: "阪神",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "07",
            name: "中京",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
        RaceSecondRow: [
          {
            Cd: "35",
            name: "盛岡",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "36",
            name: "水沢",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "48",
            name: "名古屋",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "47",
            name: "笠松",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "50",
            name: "園田",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
        RaceSecondRowJRA: [
          {
            Cd: "01",
            name: "札幌",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "02",
            name: "函館",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "03",
            name: "福島",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "04",
            name: "新潟",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "10",
            name: "小倉",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
        RaceThirdRow: [
          {
            Cd: "51",
            name: "姫路",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "46",
            name: "金沢",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "54",
            name: "高知",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "55",
            name: "佐賀",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
          {
            Cd: "33",
            name: "帯広ば",
            place1st: "0",
            place2nd: "0",
            place3rd: "0",
            unplaced: "0",
            rate1st: "0.0%",
            rate2nd: "0.0%",
            rate3rd: "0.0%",
          },
        ],
      },
      sexAllData: ["0戦 [0 0 0 0]", "0戦 [0 0 0 0]", "0戦 [0 0 0 0]"],
      ageAllData: [
        {
          title: "2歳",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        {
          title: "3歳",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        {
          title: "3歳以上",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        {
          title: "4歳以上",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
      ],
      raceConditionAllData: {
        701: {
          title: "新馬・未勝利",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        "005": {
          title: "1勝クラス500万下",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        "010": {
          title: "2勝クラス1000万下",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        "016": {
          title: "3勝クラス1600万下",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        999: {
          title: "オープン",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        sp: {
          title: "重賞",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        g1: {
          title: "G1",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
      },
      distanceAllData: {
        1: {
          title: "～1200m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        2: {
          title: "～1600m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        3: {
          title: "～2000m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        4: {
          title: "～2400m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        5: {
          title: "～3000m",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
        6: {
          title: "3001m〜",
          totalRes: "0戦",
          totalResDetail: "[0 0 0 0]",
          rate1st: "0.0%",
          rate2nd: "0.0%",
          rate3rd: "0.0%",
        },
      },

      ageGraph: null,
      conditionGraph: null,
      distanceGraph: null,
      ageAllGraph: null,
      conditionAllGraph: null,
      distanceAllGraph: null,
      achievementTab: "thisYear",
      isCurrentAchievementTab: "thisYear",
    };
  },
  components: {
    AchievementDetail,
  },
  props: {
    dataTrainerExt: Object,
  },
  mounted() {
    let self = this;
    self.NARFLAG = isNar();

    //グラフ化用データオブジェクト
    let dataFrotGraph = new Object();
    //実績表示
    const useData = self.dataTrainerExt;
    if (!useData?.TypeStat) return;

    for (let i in useData.TypeStat) {
      let targetStat = useData.TypeStat[i];
      let type = targetStat.Type;
      let keyCode = targetStat.KeyCode;

      let first = 0;
      if ("Place1st" in targetStat && targetStat.Place1st != undefined) {
        first = Number(targetStat.Place1st);
      }
      let second = 0;
      if ("Place2nd" in targetStat && targetStat.Place2nd != undefined) {
        second = Number(targetStat.Place2nd);
      }
      let third = 0;
      if ("Place3rd" in targetStat && targetStat.Place3rd != undefined) {
        third = Number(targetStat.Place3rd);
      }
      let unplaced = 0;
      if ("Unplaced" in targetStat && targetStat.Unplaced != undefined) {
        unplaced = Number(targetStat.Unplaced);
      }
      let total = totalRes(first, second, third, unplaced);
      //通算 グレード別実績データ定義:1
      //通算 馬齢別実績データ定義:2
      //通算 性別実績データ定義:3
      //通算 距離別実績データ定義:4
      //通算 競争条件別実績データ定義:5
      //通算 開催場別実績データ定義:6
      //今年度 グレード別実績データ定義:11
      //今年度 馬齢別実績データ定義:12
      //今年度 性別実績データ定義:13
      //今年度 距離別実績データ定義:14
      //今年度 競争条件別実績データ定義:15
      //今年度 開催場別実績データ定義:16

      if (dataFrotGraph[type] === undefined) {
        dataFrotGraph[type] = new Object();
      }

      //コース
      if (type === 16 || type === 6) {
        //trackCd 東京 05 中山 06 京都 08 阪神 09 中京 07
        //trackCd 札幌 01 函館 02 福島 03 新潟 04 小倉 10
        let trackData = "";
        if (type === 6) {
          trackData = self.raceTrackAllData;
        } else {
          trackData = self.raceTrackData;
        }
        let found;
        if (isNar() === 0) {
          found = trackData.RaceFirstRowJRA.findIndex(
            (element) => element.Cd == keyCode
          );
          if (found > -1) {
            trackData.RaceFirstRowJRA[found].place1st = first;
            trackData.RaceFirstRowJRA[found].place2nd = second;
            trackData.RaceFirstRowJRA[found].place3rd = third;
            trackData.RaceFirstRowJRA[found].unplaced = unplaced;
            trackData.RaceFirstRowJRA[found].rate1st =
              (rateFirst(first, total) * 100).toFixed(1).toString() + "%";
            trackData.RaceFirstRowJRA[found].rate2nd =
              (rateSecond(first, second, total) * 100).toFixed(1).toString() +
              "%";
            trackData.RaceFirstRowJRA[found].rate3rd =
              (rateThird(first, second, third, total) * 100)
                .toFixed(1)
                .toString() + "%";
          }

          found = trackData.RaceSecondRowJRA.findIndex(
            (element) => element.Cd == keyCode
          );
          if (found > -1) {
            trackData.RaceSecondRowJRA[found].place1st = first;
            trackData.RaceSecondRowJRA[found].place2nd = second;
            trackData.RaceSecondRowJRA[found].place3rd = third;
            trackData.RaceSecondRowJRA[found].unplaced = unplaced;
            trackData.RaceSecondRowJRA[found].rate1st =
              (rateFirst(first, total) * 100).toFixed(1).toString() + "%";
            trackData.RaceSecondRowJRA[found].rate2nd =
              (rateSecond(first, second, total) * 100).toFixed(1).toString() +
              "%";
            trackData.RaceSecondRowJRA[found].rate3rd =
              (rateThird(first, second, third, total) * 100)
                .toFixed(1)
                .toString() + "%";
          }
        } else if (isNar() === 1) {
          found = trackData.RaceFirstRow.findIndex(
            (element) => element.Cd == keyCode
          );
          if (found > -1) {
            trackData.RaceFirstRow[found].place1st = first;
            trackData.RaceFirstRow[found].place2nd = second;
            trackData.RaceFirstRow[found].place3rd = third;
            trackData.RaceFirstRow[found].unplaced = unplaced;
            trackData.RaceFirstRow[found].rate1st =
              (rateFirst(first, total) * 100).toFixed(1).toString() + "%";
            trackData.RaceFirstRow[found].rate2nd =
              (rateSecond(first, second, total) * 100).toFixed(1).toString() +
              "%";
            trackData.RaceFirstRow[found].rate3rd =
              (rateThird(first, second, third, total) * 100)
                .toFixed(1)
                .toString() + "%";
          }

          found = trackData.RaceSecondRow.findIndex(
            (element) => element.Cd == keyCode
          );
          if (found > -1) {
            trackData.RaceSecondRow[found].place1st = first;
            trackData.RaceSecondRow[found].place2nd = second;
            trackData.RaceSecondRow[found].place3rd = third;
            trackData.RaceSecondRow[found].unplaced = unplaced;
            trackData.RaceSecondRow[found].rate1st =
              (rateFirst(first, total) * 100).toFixed(1).toString() + "%";
            trackData.RaceSecondRow[found].rate2nd =
              (rateSecond(first, second, total) * 100).toFixed(1).toString() +
              "%";
            trackData.RaceSecondRow[found].rate3rd =
              (rateThird(first, second, third, total) * 100)
                .toFixed(1)
                .toString() + "%";
          }
        }

        found = trackData.RaceThirdRow.findIndex(
          (element) => element.Cd == keyCode
        );
        if (found > -1) {
          trackData.RaceThirdRow[found].place1st = first;
          trackData.RaceThirdRow[found].place2nd = second;
          trackData.RaceThirdRow[found].place3rd = third;
          trackData.RaceThirdRow[found].unplaced = unplaced;
          trackData.RaceThirdRow[found].rate1st =
            (rateFirst(first, total) * 100).toFixed(1).toString() + "%";
          trackData.RaceThirdRow[found].rate2nd =
            (rateSecond(first, second, total) * 100).toFixed(1).toString() +
            "%";
          trackData.RaceThirdRow[found].rate3rd =
            (rateThird(first, second, third, total) * 100)
              .toFixed(1)
              .toString() + "%";
        }
      }

      //牡・牝・セン
      if (type === 13 || type === 3) {
        let sexData = "";
        if (type === 3) {
          sexData = self.sexAllData;
        } else {
          sexData = self.sexData;
        }

        sexData[keyCode - 1] =
          totalRes(first, second, third, unplaced) +
          "戦 [" +
          first +
          " " +
          second +
          " " +
          third +
          " " +
          unplaced +
          "]";
      }

      //馬齢
      if (type === 12 || type === 2) {
        let ageData = "";
        if (type === 2) {
          ageData = self.ageAllData;
        } else {
          ageData = self.ageData;
        }

        ageData[keyCode - 11].totalRes =
          totalRes(first, second, third, unplaced) + "戦 ";
        ageData[keyCode - 11].totalResDetail =
          "[" + first + " " + second + " " + third + " " + unplaced + "]";

        ageData[keyCode - 11].rate1st =
          (rateFirst(first, total) * 100).toFixed(1).toString() + "%";
        ageData[keyCode - 11].rate2nd =
          (rateSecond(first, second, total) * 100).toFixed(1).toString() + "%";
        ageData[keyCode - 11].rate3rd =
          (rateThird(first, second, third, total) * 100).toFixed(1).toString() +
          "%";
        dataFrotGraph[type][keyCode] = new Array(
          parseFloat(winFormat(total, first)),
          parseFloat(winFormat(total, first + second)),
          parseFloat(winFormat(total, first + second + third))
        );
      }

      //条件
      if (type === 15 || type === 5) {
        let raceConditionData = "";
        if (type === 5) {
          raceConditionData = self.raceConditionAllData;
        } else {
          raceConditionData = self.raceConditionData;
        }
        raceConditionData[keyCode].rate1st =
          (rateFirst(first, total) * 100).toFixed(1).toString() + "%";
        raceConditionData[keyCode].rate2nd =
          (rateSecond(first, second, total) * 100).toFixed(1).toString() + "%";
        raceConditionData[keyCode].rate3rd =
          (rateThird(first, second, third, total) * 100).toFixed(1).toString() +
          "%";
      }

      //距離
      if (type === 14 || type === 4) {
        let distanceData = "";
        if (type === 4) {
          distanceData = self.distanceAllData;
        } else {
          distanceData = self.distanceData;
        }
        distanceData[keyCode].totalRes =
          totalRes(first, second, third, unplaced) + "戦 ";
        distanceData[keyCode].totalResDetail =
          "[" + first + " " + second + " " + third + " " + unplaced + "]";

        distanceData[keyCode].rate1st =
          (rateFirst(first, total) * 100).toFixed(1).toString() + "%";
        distanceData[keyCode].rate2nd =
          (rateSecond(first, second, total) * 100).toFixed(1).toString() + "%";
        distanceData[keyCode].rate3rd =
          (rateThird(first, second, third, total) * 100).toFixed(1).toString() +
          "%";
      }

      if (
        type === 15 ||
        type === 14 ||
        type === 2 ||
        type === 5 ||
        type === 4
      ) {
        dataFrotGraph[type][keyCode] = new Array(
          parseFloat(winFormat(total, first)),
          parseFloat(winFormat(total, first + second)),
          parseFloat(winFormat(total, first + second + third))
        );
      }
    }

    //実績のグラフ表示
    // //今年度馬齢
    setAgeGraph(dataFrotGraph, "2");
    setAgeGraph(dataFrotGraph, "12");

    // //今年度条件
    setConditionGraph(dataFrotGraph, "5");
    setConditionGraph(dataFrotGraph, "15");
    // //今年度距離
    setDistanceGraph(dataFrotGraph, "4");
    setDistanceGraph(dataFrotGraph, "14");

    //馬齢グラフ生成
    function setAgeGraph(info, type) {
      let param1 =
        info[type] != undefined && info[type]["11"] != undefined
          ? info[type]["11"]
          : new Array(0, 0, 0);
      let param2 =
        info[type] != undefined && info[type]["12"] != undefined
          ? info[type]["12"]
          : new Array(0, 0, 0);
      let param3 =
        info[type] != undefined && info[type]["13"] != undefined
          ? info[type]["13"]
          : new Array(0, 0, 0);
      let param4 =
        info[type] != undefined && info[type]["14"] != undefined
          ? info[type]["14"]
          : new Array(0, 0, 0);

      let ageGraph = {
        credits: {
          enabled: false,
        },
        chart: {
          type: "bar",
          marginRight: 30,
          height: 300,
        },
        colors: ["#D13636", "#116FBF", "#FFCC00"],
        title: {
          text: null,
        },
        xAxis: {
          categories: ["2歳", "3歳", "3歳以上", "4歳以上"],
          title: {
            text: null,
          },
        },
        yAxis: {
          max: 100,
          title: {
            text: null,
          },
          labels: {
            format: "{value} %",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 10,
          },
          series: {
            enableMouseTracking: false,
          },
        },
        series: [
          {
            name: "勝率",
            data: [param1[0], param2[0], param3[0], param4[0]],
          },
          {
            name: "連対率",
            data: [param1[1], param2[1], param3[1], param4[1]],
          },
          {
            name: "3着内率",
            data: [param1[2], param2[2], param3[2], param4[2]],
          },
        ],
      };

      if (type === "2") {
        self.ageAllGraph = ageGraph;
      } else {
        self.ageGraph = ageGraph;
      }
    }

    //今年度実績/条件のグラフ表示
    function setConditionGraph(info, type) {
      const param1 =
        info[type] != undefined && info[type]["701"] != undefined
          ? info[type]["701"]
          : new Array(0, 0, 0);
      const param2 =
        info[type] != undefined && info[type]["005"] != undefined
          ? info[type]["005"]
          : new Array(0, 0, 0);
      const param3 =
        info[type] != undefined && info[type]["010"] != undefined
          ? info[type]["010"]
          : new Array(0, 0, 0);
      const param4 =
        info[type] != undefined && info[type]["016"] != undefined
          ? info[type]["016"]
          : new Array(0, 0, 0);
      const param5 =
        info[type] != undefined && info[type]["999"] != undefined
          ? info[type]["999"]
          : new Array(0, 0, 0);
      const param6 =
        info[type] != undefined && info[type]["sp"] != undefined
          ? info[type]["sp"]
          : new Array(0, 0, 0);
      const param7 =
        info[type] != undefined && info[type]["g1"] != undefined
          ? info[type]["g1"]
          : new Array(0, 0, 0);

      let conditionGraph = {
        credits: {
          enabled: false,
        },
        chart: {
          type: "bar",
          marginRight: 30,
          height: 300,
        },
        colors: ["#D13636", "#2F93C6", "#FFCC00"],
        title: {
          text: null,
        },
        xAxis: {
          categories: [
            "新馬・未勝利",
            "1勝クラス・500万下",
            "2勝クラス・1000万下",
            "3勝クラス・1600万下",
            "オープン",
            "重賞",
            "G1",
          ],
        },
        yAxis: {
          max: 100,
          title: {
            text: null,
          },
          labels: {
            format: "{value} %",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 10,
          },
          series: {
            enableMouseTracking: false,
          },
        },
        series: [
          {
            name: "勝率",
            data: [
              param1[0],
              param2[0],
              param3[0],
              param4[0],
              param5[0],
              param6[0],
              param7[0],
            ],
          },
          {
            name: "連対率",
            data: [
              param1[1],
              param2[1],
              param3[1],
              param4[1],
              param5[1],
              param6[1],
              param7[1],
            ],
          },
          {
            name: "3着内率",
            data: [
              param1[2],
              param2[2],
              param3[2],
              param4[2],
              param5[2],
              param6[2],
              param7[2],
            ],
          },
        ],
      };
      if (type === "5") {
        self.conditionAllGraph = conditionGraph;
      } else {
        self.conditionGraph = conditionGraph;
      }
    }

    //今年度実績/距離のグラフ表示
    function setDistanceGraph(info, type) {
      const param1 =
        info[type] != undefined && info[type]["1"] != undefined
          ? info[type]["1"]
          : new Array(0, 0, 0);
      const param2 =
        info[type] != undefined && info[type]["2"] != undefined
          ? info[type]["2"]
          : new Array(0, 0, 0);
      const param3 =
        info[type] != undefined && info[type]["3"] != undefined
          ? info[type]["3"]
          : new Array(0, 0, 0);
      const param4 =
        info[type] != undefined && info[type]["4"] != undefined
          ? info[type]["4"]
          : new Array(0, 0, 0);
      const param5 =
        info[type] != undefined && info[type]["5"] != undefined
          ? info[type]["5"]
          : new Array(0, 0, 0);
      const param6 =
        info[type] != undefined && info[type]["6"] != undefined
          ? info[type]["6"]
          : new Array(0, 0, 0);

      let distanceGraph = {
        credits: {
          enabled: false,
        },
        chart: {
          type: "bar",
          marginRight: 30,
          height: 300,
        },
        colors: ["#D13636", "#2F93C6", "#FFCC00"],
        title: {
          text: null,
        },
        xAxis: {
          categories: [
            "～1200m",
            "～1600m",
            "～2000m",
            "～2400m",
            "～3000m",
            "3001m～",
          ],
        },
        yAxis: {
          max: 100,
          title: {
            text: null,
          },
          labels: {
            format: "{value} %",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 10,
          },
          series: {
            enableMouseTracking: false,
          },
        },
        series: [
          {
            name: "勝率",
            data: [
              param1[0],
              param2[0],
              param3[0],
              param4[0],
              param5[0],
              param6[0],
            ],
          },
          {
            name: "連対率",
            data: [
              param1[1],
              param2[1],
              param3[1],
              param4[1],
              param5[1],
              param6[1],
            ],
          },
          {
            name: "3着内率",
            data: [
              param1[2],
              param2[2],
              param3[2],
              param4[2],
              param5[2],
              param6[2],
            ],
          },
        ],
      };
      if (type === "4") {
        self.distanceAllGraph = distanceGraph;
      } else {
        self.distanceGraph = distanceGraph;
      }
      self.distanceGraph = distanceGraph;
    }
  },
  methods: {
    tabClick(data) {
      this.achievementTab = data;
      this.isCurrentAchievementTab = data;
    },
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>
