import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreNewsList = defineStore('news-list', () => {
  const list = ref([]);

  const hasList = () => {
    return !!list.value?.length
  }

  return { list, hasList }
})

export default {
  useStoreNewsList,
}