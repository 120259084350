<script setup>
import {
  ref,
  onMounted,
} from "vue";
import {isNar} from "../../assets/js/common";
import JraRace from "../../components/race/important/JraRace.vue";
import NarRace from "../../components/race/important/NarRace.vue";

const display = ref("")
onMounted(() => {
  display.value = isNar() === 1 ? "1" : "0"
});

</script>
<template>
  <JraRace v-if="display === '0'"></JraRace>
  <NarRace v-else-if="display === '1'"></NarRace>
</template>