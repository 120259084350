<template>
  <div class="item">
    <table
      class="table-grid -center -middle -padding"
      v-if="jockeyInfoData.length"
    >
      <tbody>
        <tr>
          <th>番</th>
          <th>出走馬名<br />騎手名</th>
          <th>勝率</th>
          <th>連対率</th>
          <th>3着内率</th>
        </tr>
        <tr v-for="(data, index) in jockeyInfoData" :key="index">
          <td v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`">
            {{ data.horseNum }}
          </td>
          <td class="-left">
            <span :class="data.favClass">{{ data.horseName }}</span><br>
            <router-link
              v-if="data.jockeyCd"
              :to="`/data/jockey?JockeyCd=${data.jockeyCd}`"
            >
              {{ data.jockeyName }}
            </router-link>
            <span v-else>{{ data.jockeyName }}</span>
          </td>
          <td>{{ data.jockeyRate1st }}</td>
          <td>{{ data.jockeyRate2nd }}</td>
          <td>{{ data.jockeyRate3rd }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Jockey",
  data() {
    return {};
  },
  props: {
    jockeyInfoData: Array,
  },
  setup(props) {
    return {
      props,
    };
  },
  created() {
    let self = this;
  },
};
</script>

<style lang="scss" src="../../../assets/css/race/style.scss" scoped></style>
