<script setup>
import { ref, reactive, onMounted, computed, toRefs, onUpdated } from "vue";
import { HOST, JRA_WP_HOST } from "../../../assets/js/define";
import { userSettingURL } from "../../../assets/js/common";
import axios from "axios";

//本番用 APIURL
//const API_URL = HOST +"wp-json/wp/v2/pages?slug=policy";
//デバッグ用 APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=pack06_credit";

let post = reactive({});
onMounted(() => {
  const request_url = API_URL;
  axios
    .get(request_url)
    .then((response) => {
      Object.assign(post, response.data[0]);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      console.log(error);
      document.body.className = "status-loaded";
    });
});

onUpdated(() => {
  //ボタンのリンクの決定
  let planCancel = document.getElementById("plan-cancel");

  planCancel.addEventListener("click", function (e) {
    e.preventDefault();
    userSettingURL("cancelCreditPack06");
  });
});
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">6ヶ月パックコース解約</h1>
    </section>

    <div class="body" v-if="post.content" v-html="post.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
