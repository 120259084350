<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import {
  isDismissDialog,
  getCourse,
  PAYINGAPPPASS,
  setDismissDialog,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  getJSON,
  isNar,
} from "../../assets/js/common";
import {HOST, TOB_TRIFECTA, METHOD_NORMAL, PATH, BACKWORDPATH} from "../../assets/js/define";
import SetAnniversaryModal from "../../components/utils/SetAnniversaryModal.vue";

const props = defineProps({
  raceHead: {
    type: Array,
  },
  raceList: {
    type: Array,
  },
  dateList: {
    type: Array,
  },
  canSelect: {
    type: Boolean,
  },
  memorialData: {
    type: Array,
  },
  today: {
    type: String,
  },
  raceTrack: {
    type: String,
  },
})
const emit = defineEmits(['search'])

let totalCount = ref(0)
let modalAnniversaryOpen = ref(false)
let checkNumFlg = ref(false)
let url = ref('')
let narFlag = ref(false)
// let selectDate = ref(props.currentDate)
let selectDate = ref('')
let selectArea = ref('')
let memorialData = ref([])

onMounted(() => {
  narFlag.value = isNar() === 1
  selectDate.value = props.today
  selectArea.value = props.raceTrack
  props.memorialData.forEach(val => memorialData.value.push(val))
})

watch(() => props.memorialData, (data) => {
  memorialData.value.splice(0, memorialData.value.length)
  props.memorialData.forEach(val => memorialData.value.push(val))

  totalCount.value = 0;
  for (let i = 0; i < memorialData.value.length; i++) {
    if (memorialData.value[i].CheckNumList) {
      checkNumFlg.value = true;
      let allList = props.raceList;
      for (let j = 0; j < allList.length; j++) {
        if (allList[j].isChecked) {
          totalCount.value += 1;
        }
      }
    }
  }
},{deep: true})

const searchRaceData = () => {
  let raceTrackCd = props.raceHead.find(
      (item) => item.Racetrack === selectArea.value
  ).RacetrackCd;
  let dor = props.dateList.find(
      (item) => item.DOR === selectDate.value
  ).DOR;
  emit("search", {
    dor: dor,
    raceTrackCd: raceTrackCd
  });
}
const checkRace = (item, e) => {
  totalCount.value = 0;
  item.isChecked = e.target.checked;
  for (let i = 0; i < memorialData.value.length; i++) { 
    if (memorialData.value[i].CheckNumList) {
      checkNumFlg.value = true;
      let allList = props.raceList;
      for (let j = 0; j < allList.length; j++) {
        if (allList[j].isChecked) {
          totalCount.value += 1;
        }
      }
    }
  }
}
const checkRaceAll = (e) => {
  for (let i = 0; i < props.raceList.length; i++) {
    if (canPurchase(props.raceList[i])) {
      checkRace(props.raceList[i], e);
    }
  }
}
const register = async() => {
  document.body.className = "";
  if (!checkNumFlg.value && totalCount.value === 0) {
    alert("購入ナンバーが選択されていません");
    document.body.className = "status-loaded";
  } else {
    let template = "000000000000000000";
    let betLst = [];
    let maxNoLst = [];
    let ele = memorialData.value;
    //BETナンバー生成
    for (let i = 0; i < ele.length; i++) {
      //チェックされていない購入ナンバーは検証対象外
      if (!memorialData.value[i].CheckNumList) {
        continue;
      }

      let num = memorialData.value[i].Number;
      let lst = [num.num1, num.num2, num.num3];

      let myBet = "";
      let maxNo = 0;
      for (let j = 0; j < lst.length; j++) {
        let pos = lst[j] * 1;
        if (pos !== 18) {
          myBet +=
              template.slice(0, pos - 1) +
              "1" +
              template.slice(-1 * (18 - pos));
        } else {
          myBet += template.slice(0, pos - 1) + "1";
        }
        if (maxNo < pos) {
          maxNo = pos;
        }
      }
      betLst.push(myBet);
      maxNoLst.push(maxNo);
    }

    //買い目取得～買い目リスト生成
    let isMaxFlg = false;
    let el = props.raceList;
    let checkFlg = false;
    let purchaseRaceList = [];
    //レースチェック確認
    for (let n = 0; n < el.length; n++) {
      let raceDetail = el[n];
      if (raceDetail.isChecked) {
        checkFlg = true;
        for (let k = 0; k < betLst.length; k++) {
          //購入ナンバーが各レース出馬数を超えている場合は買い目取得しない
          if (raceDetail.EntryNum < maxNoLst[k]) {
            isMaxFlg = true;
            continue;
          }
          purchaseRaceList.push({
            raceDetail: raceDetail,
            bet: betLst[k]
          })
        }
      }
    }

    if (isMaxFlg) {
      alert("出走頭数を超える購入ナンバーが含まれるレースが存在します。該当レースの買い目登録は行われません。");
      if (purchaseRaceList.length === 0) {
        document.body.className = "status-loaded";
      }
    }
    if (!checkFlg) {
      alert("レースが選択されていません。");
      document.body.className = "status-loaded";
      return;
    }

    for (let i = 0; i < purchaseRaceList.length; i++) {
      let raceDetail = purchaseRaceList[i].raceDetail;
      if (raceDetail.isChecked) {
        await setRaceBet(
            raceDetail.DOR,
            raceDetail.RacetrackCd,
            raceDetail.DOR.slice(0, 4),
            raceDetail.RaceNum,
            raceDetail.RaceMeeting,
            raceDetail.RaceDay,
            TOB_TRIFECTA,
            METHOD_NORMAL,
            purchaseRaceList[i].bet,
        )
      }
      if (i >= purchaseRaceList.length - 1) {
        document.body.className = "status-loaded";
        //全て読み終わったら表示
        if (isDismissDialog() != 1) {
          // AppPassはダイアログ非表示
          if (getCourse() == PAYINGAPPPASS) {
            //ダイアログ非表示フラグON
            setDismissDialog();
          }
          //モーダルウィンドウ表示
          modalAnniversaryOpen.value = true;
        } else {
          let url = HOST + "purchase/";
          location.href = url;
        }
      }
    }

    //買い目登録
    async function setRaceBet (
        DOR,
        RacetrackCd,
        RaceYear,
        RaceNum,
        RaceMeeting,
        RaceDay,
        TOB,
        Method,
        Bet,
    ) {
      return new Promise((resolve, reject) => {
        if (RaceNum < 10) {
          RaceNum = "0" + String(parseInt(RaceNum, 10));
        }
        //パラメータからAPIコールを生成
        let req =
            HOST +
            PATH +
            "Odds/setRaceBet.js" +
            BACKWORDPATH +
            "?dor=" +
            DOR +
            "&racetrackCd=" +
            RacetrackCd +
            "&raceYear=" +
            RaceYear +
            "&raceNum=" +
            RaceNum +
            "&raceMeeting=" +
            RaceMeeting +
            "&raceDay=" +
            RaceDay +
            "&TOB=" +
            TOB +
            "&method=" +
            Method +
            "&bet=" +
            Bet;
        getJSON(req, true, function (status, json) {
          resolve();
        });
      })
    }
  }
}
const canPurchase = (item) => {
  return ( 
    item.isReleased && 
    item.UnResultFlg === 0 && 
    !item.IsRaceResult &&
    item.Status !== "2"  // Status:"2" はレース中止
  )
}
</script>
_
<template>
  <div class="inner">
    <h2 class="heading-circle">レース選択</h2>
    <div class="raceinfo">
      <div v-if="narFlag">
        {{ dateList[0].date }} (<span
          :class="[
            'text-week',
            { ' -sat': dateList[0].week === '土' },
            { '-sun': dateList[0].week === '日' },
          ]"
          >{{ dateList[0].week }}</span
        >)
      </div>
      <span class="form-select" v-else>
        <select v-model="selectDate" @change="searchRaceData()">
          <option v-for="date in dateList" :value="date.DOR">
            {{ date.date }}({{ date.week }})
          </option>
        </select>
      </span>
      <!-- ▼ 当日開催している会場のみ選択可能 ▼ -->
      <span class="form-select" v-if="selectArea">
        <select v-model="selectArea" @change="searchRaceData()">
          <option v-for="(name, index) in raceHead" :key="index">
            {{ name.Racetrack }}
          </option>
        </select>
      </span>
      <!-- ▲ 当日開催している会場のみ選択可能 ▲ -->
    </div>
    <div class="container-purchasenumber">
      <table class="table-grid -racelist -hasside">
        <thead>
          <tr>
            <th class="number">R</th>
            <th class="place" colspan="2">{{ selectArea }}</th>
            <th class="check">
              <span class="form-checkbox -zen"
                ><label
                  ><input
                    type="checkbox"
                    @change="checkRaceAll($event)"
                    name="sample" /><i></i
                ></label>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in raceList"
            :key="key"
            :class="{
              '-finished': !canPurchase(item),
              '-unsettled -finished': !item.isReleased
            }"
          >
            <td class="number" style="background-color: #efefef">
              {{ key + 1 }}
            </td>
            <td v-if="item.UnResultFlg === 1" class="status -mikakutei">
              <span class="label">未確</span>
            </td>
            <td v-else-if="item.Status === '2'" class="status -cancel">
              <span class="label">中止</span>
            </td>
            <td v-else-if="item.IsRaceResult === true" class="status -kakutei">
              <span class="label">確定</span>
            </td>
            <td v-else class="status"><span class="label"></span></td>
            <td class="race -turf">
              <div class="item">
                <div class="info">
                  <div v-if="item.Status === '2'" class="name">
                    <div class="title">
                      <div class="inner">
                        {{ item.RaceMainName }}
                      </div>
                    </div>
                  </div>
                  <router-link v-else-if="item.isReleased" :to="item.RaceUrl" class="name">
                    <div class="title">
                      <div class="inner">
                        {{ item.RaceMainName }}
                      </div>
                    </div>
                    <div class="caption">
                      {{ item.StartTime }}発走/{{ item.TrackTypeCd
                      }}{{ item.Distance }}m
                    </div>
                  </router-link>
                  <div v-else class="name">
                    <div class="title">
                      <div class="inner">
                        {{ item.RaceMainName }}
                      </div>
                    </div>
                    <div class="caption">
                      {{ item.StartTime }}発走/{{ item.TrackTypeCd
                      }}{{ item.Distance }}m
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="check">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @change="checkRace(item, $event)"
                    v-model="item.isChecked"
                    name="sample" /><i></i></label
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="container-total -noborder">
      <dl class="amount">
        <dt>点数合計</dt>
        <dd>
          <span class="num">{{ totalCount }}</span
          >点
        </dd>
      </dl>
      <div class="btn-basic" @click="register()">買い目登録</div>
    </div>

    <div class="container-schedule">
      <router-link to="/purchase/">
        <div class="btn-basic -normal">購入予定一覧</div>
      </router-link>
      <p class="_caption">
        ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
      </p>
    </div>
  </div>
  <SetAnniversaryModal
    v-on:closeDialog="modalAnniversaryOpen = false"
    :modalAnniversaryOpen="modalAnniversaryOpen"
  >
  </SetAnniversaryModal>
</template>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>
