<script setup>
import { HOST } from "../../assets/js/define";
import { userSettingURL } from "../../assets/js/common";
const emit = defineEmits(["modal-close"]);
const props = defineProps({
  modalOpen: Boolean,
  isPack03: Number,
  isPack06: Number,
  isPack12: Number,
});

const joinCreditPack03 = "joinCreditPack03";
const joinCreditPack06 = "joinCreditPack06";
const joinCreditPack12 = "joinCreditPack12";

const modalClose = () => {
  emit("modal-close", false);
};
</script>

<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div @click="modalClose()" v-if="modalOpen" class="modal-frame">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                パックを選ぶ
              </div>
              <div class="modal-body">
                <ul class="list-box -padding -course">
                  <li v-if="isPack12 != 1">
                    <p class="_center">
                      22%オフでプレミアムコースが使える！<br />最もお得で一番人気のパック！
                    </p>
                    <a
                      @click="userSettingURL(joinCreditPack12)"
                      class="btn-basic -wide -center"
                      >12ヶ月パックコース&nbsp;（¥10,257 税込）</a
                    >
                  </li>
                  <li v-if="isPack06 != 1">
                    <p class="_center">
                      17％オフでプレミアムコースが使える！<br />お得な6ヶ月パックコース！
                    </p>
                    <a
                      @click="userSettingURL(joinCreditPack06)"
                      class="btn-basic -wide -center"
                      >6ヶ月パックコース&nbsp;（¥5,445 税込）</a
                    >
                  </li>
                  <li v-if="isPack03 != 1">
                    <p class="_center">
                      17％オフでプレミアムコースが使える！<br />こまめにプランを検討したい方におすすめ！
                    </p>
                    <a
                      @click="userSettingURL(joinCreditPack03)"
                      class="btn-basic -wide -center"
                      >3ヶ月パックコース&nbsp;（¥2,723 税込）</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <span @click="modalClose()" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
