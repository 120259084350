<script setup>
import { watch, ref } from "vue";

const props = defineProps({
  raceType: {
    hurdle: String,
    age: String,
  },
  raceRegulation: String,
  headingInfo: Object,
  raceInfo: Object,
});

let existData = ref(false)
let existHeadingInfo = ref([])
let existRaceInfo = ref({})

watch(() => props.raceInfo, (newRaceInfo) => {
  // propsにデータがあるかどうか
  for(let dor in newRaceInfo) {
    if (Object.keys(newRaceInfo[dor]).length === 0) {
      existData.value = false
    } else {
      existData.value = true
      break
    }
  }
  //回次ごとにデータがあるかどうか
  const hasRaceFlagArray = props.headingInfo.map((meeting) => {
    return Object.values(meeting.RaceDays).some((raceDay) => {
      const dor = raceDay.DOR || "";
      return newRaceInfo[dor] && newRaceInfo[dor].length > 0;
    });
  });
  
  [existHeadingInfo.value, existRaceInfo.value] = getExistRaces(props.headingInfo, newRaceInfo, hasRaceFlagArray)
})
const getExistRaces = (headingInfo, raceInfo, hasRaceFlagArray) => {
  const existHeadingInfo = headingInfo.filter((meeting, i) => !!hasRaceFlagArray[i]);
  const raceExistDays = existHeadingInfo.flatMap((meeting) => {
    return Object.values(meeting.RaceDays || {}).map((raceDay) => {
      return raceDay.DOR || "";
    });
  });

  let existRaceInfo = {}
  for(let day of raceExistDays) {
    existRaceInfo[day] = raceInfo[day]
  }
  return [existHeadingInfo, existRaceInfo];
};

const isHorseAge = (race) => {
  return race.WeightType === '馬齢'
}
const cellStyles = (race) => {
  return {
    '-turf': race.TrackTypeCd === '1',
    '-dart': race.TrackTypeCd === '2',
    '-hurdle': race.TrackTypeCd === '3',
    '-special': race.SpecialRaceNum !== '0000'
  }
}
const markStyles = (raceSymbol) => {
    return {
      '-circle': raceSymbol.includes('('),
      '-square': raceSymbol.includes('[')
    }
}
const removeBrackets = (value) => {
  return value.replace(/[()\[\]]/g, '');
}
const isOver3Letters = (value) => {
  return value.length > 3
}
</script>

<template>
  <div>
    <div class="table-scroll -channel" v-if="existData">
      <div class="list-channel -head">
        <dl class="trackCd">
          <dt><span class="val">種別</span></dt>
          <dd>
            <dl class="raceTypeCd">
              <dd>
                <dl class="raceRegulationCd">
                  <dt>
                    <span class="val">競走<br />条件</span>
                  </dt>
                  <dd>
                    <div class="racetrack">
                      <dl
                        class="racetrack"
                        v-for="meeting in existHeadingInfo"
                        :key="meeting">
                        <dt>
                          {{ meeting.RaceYear }}年 第{{ parseInt(meeting.RaceMeeting) || ' ' }}回
                          {{ meeting.RacetrackName }}
                        </dt>
                        <dd>
                          <ul class="days">
                            <li
                              class="day"
                              v-for="raceDay in meeting.RaceDays"
                              :key="raceDay">
                              <dl class="specialRaceNum">
                                <dt>
                                  <span class="val">競走<br />番号</span>
                                </dt>
                                <dd class="val">
                                  <div class="date">
                                    <span class="num">{{ raceDay.RaceDaySymbol }}</span>
                                    <time>{{ raceDay.Date }}</time>
                                  </div>
                                </dd>
                              </dl>
                            </li>
                          </ul>
                        </dd>
                      </dl>
                    </div>
                  </dd>
                </dl>
              </dd>
            </dl>
          </dd>
        </dl>
      </div>
      <div class="list-channel">
        <dl class="trackCd">
          <dt>
            <span class="val">{{ props.raceType.hurdle }}</span>
          </dt>
          <dd>
            <dl class="raceTypeCd">
              <dt>
                <span class="val" v-if="isOver3Letters(props.raceType.age)">
                  {{ props.raceType.age.slice(0,2) }}<br>
                  {{ props.raceType.age.slice(2,4) }}
                </span>
                <span class="val" v-else>
                  {{ props.raceType.age }}
                </span>
              </dt>
              <dd>
                <dl class="raceRegulationCd">
                  <dt>
                    <span class="val">{{ props.raceRegulation }}</span>
                  </dt>
                  <dd>
                    <ul class="days">
                      <li
                        class="day"
                        v-for="racesOfTheDor in existRaceInfo"
                        :key="racesOfTheDor">
                        <div class="inner">
                          <template v-for="(race, index) in racesOfTheDor" :key="index">                              
                            <dl
                              class="specialRaceNum"
                              :class="cellStyles(race)">
                              <dt>
                                <span class="val">{{ parseInt(race.RaceNum) || ' ' }}</span>
                              </dt>
                              <dd>
                                <span class="distance">
                                  {{ race.Distance }} ({{ race.TrackName }})
                                </span>
                                <div class="race">{{ race.RaceMainName }}</div>
                                <div class="grade" v-if="race.Grade">({{ race.Grade }})</div>
                                <div class="meta">
                                  <template
                                    v-for="(raceSymbol,index) in race.RaceSymbolArray" :key="index">
                                    <span 
                                      class="mark"
                                      :class="markStyles(raceSymbol)">
                                      {{ removeBrackets(raceSymbol) }}
                                    </span>                                  
                                  </template>
                                </div>
                                <div class="juryo" v-if="!isHorseAge(race)">{{ race.WeightType }}</div>
                              </dd>
                            </dl>
                          </template>
                        </div>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </dd>
            </dl>
          </dd>
        </dl>
      </div>
    </div>

    <div v-if="!existData">
      <p>データが存在しません</p>
    </div>

  </div>
</template>

