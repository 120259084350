<script setup>
import { ref, reactive, onMounted } from "vue";
import { getJSON, SUCCESS } from "../../assets/js/common";
import {
  HOST,
  BACKWORDPATH,
  POGAPIPATH,
  POGPATH,
} from "../../assets/js/define";

import PogHeader from "../../components/pog/PogHeader.vue";
import pogFunc from "../../functions/pog.js";
const { loadPogUserJudge, getCurrentYear, getPogStartDate } = pogFunc();

onMounted(() => {
  loadPogUserJudge(() => {
    init();
  });
});

let rankingHorses = reactive([]);
const year = ref("");
const paramYear = ref("")
const paramMonth = ref("")
const yearMonth = ref("");
const pogNextSeasonStart = ref("");
const pogSeasonSecond = ref("");
const pogSeasonFirst = ref("");

const init = async () => {
  document.body.className = "";
  let url = window.location.href;
  const currentYear = await getCurrentYear();

  if (url.indexOf("Year=") > -1 && url.indexOf("Month=") > -1) {
    paramYear.value = url.split("Year=")[1];
    paramMonth.value = url.split("Month=")[1];
    paramYear.value = paramYear.value.slice(0, 4);
    paramMonth.value = paramMonth.value.slice(0, 2);

    //年度で検索を行うため、yearを調整
    year.value = paramYear.value > currentYear ? currentYear : paramYear.value;

    yearMonth.value = paramYear.value + ("00" + Number(paramMonth.value)).slice(-2);
  }
  const pogDate = await getPogStartDate();

  pogNextSeasonStart.value = pogDate.nextSeasonStart;
  pogSeasonSecond.value = pogDate.second;
  pogSeasonFirst.value = pogDate.first;

  let info = { Year: paramYear.value, Month: paramMonth.value };
  const request =
    HOST +
    POGAPIPATH +
    "getPogRanking.js" +
    BACKWORDPATH +
    "?ScreenDiv=2&Year=" +
    paramYear.value +
    "&Month=" +
    paramMonth.value;

  getJSON(
    request,
    true,
    function (status, json, info) {
      if (status == SUCCESS) {
        let year = paramYear.value;
        let mon = paramMonth.value;

        const mDate = new Date();
        const strDate =
          mDate.getFullYear() +
          ("0" + (mDate.getMonth() + 1)).slice(-2) +
          ("0" + mDate.getDate()).slice(-2);
        if (strDate >= pogNextSeasonStart.value && paramYear.value === pogSeasonSecond.value) {
          year = pogSeasonSecond.value;
        } else if (paramYear.value === pogSeasonFirst.value) {
          year = pogSeasonFirst.value;
        }

        if (year && mon) {
          createSelectorOption(Number(year), Number(mon));
          if (info.Year && info.Month) {
            let selStr = info.Year;
            if (info.Month.length == 1) {
              selStr += "0";
            }
            selStr += info.Month;
          }

          // ランキング結果 を描画
          getJSON(request, true, function (_, json) {
            rankingHorses = Object.assign(rankingHorses, json.SearchResult);
            document.body.className = "status-loaded";
          });
        } else {
          alert("POG情報の取得に失敗しました");
          document.body.className = "status-loaded";
        }
      }
    },
    info
  );
};

let monthOptions = reactive([]);
let createSelectorOption = (year, month) => {
  year = month >= 6 && month <= 12 ? year : year - 1 ;
  let result = "";
  let m = 6;
  for (let i = 0; i < 12; i++) {
    let optionValue = "";
    let optionStr = "";
    let mon = month;

    if (m >= 6 && m <= 12) {
      // yearの年度
      optionValue = String(year);
      optionStr = String(year) + ".";
      mon = m;
      m++;
    } else {
      // yearの年度
      optionValue = String(year + 1);
      optionStr = String(year + 1) + ".";
      if (m == 13) {
        m = 1;
      }
      mon = m;
      m++;
    }

    let monStr = String(mon);
    if (monStr.length == 1) {
      monStr = "0" + monStr;
    }
    optionValue += monStr;
    optionStr += monStr;
    monthOptions.push({ optionValue: optionValue, optionStr: optionStr });
  }
  return result;
};

const horseLink = (code) => {
  if (code) {
    return HOST + "pog/" + "user_horse?userId=" + code + "&Year=" + year.value;
  } else return "";
};

// もっと見る
const showLimit = 30;
let count = ref(30);
const ListItem = () => {
  const list = [...rankingHorses];
  if (list != undefined && list != null && list.length > 0) {
    return list.slice(0, count.value);
  } else {
    return [];
  }
};
const isMore = () => {
  count.value += showLimit;
};

const changeMonth = (e) => {
  if (e != null && e.target.value != undefined && e.target.value != null) {
    if (e.target.value.length == 6) {
      const year = e.target.value.substring(0, 4);
      let mon = e.target.value.substring(4, 6);
      mon = String(Number(mon)); //一桁の場合0を削除
      const url =
        HOST +
        POGPATH +
        "monthly_ranking?ScreenDiv=2&Year=" +
        year +
        "&Month=" +
        mon;
      location.href = url;
    } else {
      alert("セレクターの値が不正です : " + e.target.value);
    }
  } else {
    alert("セレクターの値が不正です");
  }
};
</script>

<template>
  <main class="layout-main">
    <PogHeader></PogHeader>

    <section class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">POG ランキング</h2>
      </div>
      <div class="nav-tab -fit3">
        <ul data-trigger>
          <li>
            <a
              class="label"
              :href="
                '/pog/yearly_ranking?ScreenDiv=1&Year=' +
                paramYear +
                '&Month=' +
                paramMonth
              "
              >年間</a
            >
          </li>
          <li class="-current">
            <a
              class="label"
              :href="
                '/pog/monthly_ranking?ScreenDiv=2&Year=' +
                paramYear +
                '&Month=' +
                paramMonth
              "
              >月間</a
            >
          </li>
          <li>
            <a
              class="label"
              :href="
                '/pog/horse_ranking?ScreenDiv=3&Year=' +
                paramYear +
                '&Month=' +
                paramMonth
              "
              >人気馬</a
            >
          </li>
        </ul>
      </div>

      <div class="layout-sectionheader -default -gray">
        <h1 class="heading-section">月間ランキング</h1>
        <div class="nav">
          <div class="form-select">
            <select @change="changeMonth" v-model="yearMonth">
              <option
                v-for="monthOption in monthOptions"
                :key="monthOption"
                :value="monthOption.optionValue"
              >
                {{ monthOption.optionStr.substring(0, 4) }}年{{
                  Number(monthOption.optionStr.substring(5, 7))
                }}月
              </option>
            </select>
          </div>
        </div>
      </div>

      <ul class="list-line -ranking">
        <li v-for="(rankingHorse, index) in ListItem()" :key="rankingHorse">
          <a :href="horseLink(rankingHorse.Code)" class="link-cell">
            <span v-if="index === 0" class="ranking"
              ><i class="icon-ranking -no1st"></i
            ></span>
            <span v-else-if="index === 1" class="ranking"
              ><i class="icon-ranking -no2nd"></i
            ></span>
            <span v-else-if="index === 2" class="ranking"
              ><i class="icon-ranking -no3rd"></i
            ></span>
            <span v-else class="ranking"
              ><i>{{ index + 1 }}</i></span
            >
            <span v-if="String(rankingHorse.Name)" class="name"
              >{{ rankingHorse.Name }}
            </span>
            <span v-if="String(rankingHorse.Pts)" class="total"
              >{{ rankingHorse.Pts }}pt</span
            >
          </a>
        </li>
      </ul>

      <div v-if="ListItem().length - count >= 0" class="nav-group">
        <span @click="isMore" class="btn-basic -more">もっと見る</span>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
