<script setup>
import { ref } from "vue";
import {getGradeIconClass, PAYINGSUGOTOKU} from "../../../assets/js/common";
import ShowMovieConfirm from "../../utils/ShowMovieConfirm.vue";
import ShowMovie from "../../utils/ShowMovie.vue";
const { races, userCourse, loginUrl } = defineProps({
  races: Array,
  userCourse: Number, // 1:未ログイン 2:エコノミー 3:スゴ得 4:プレミアム
  loginUrl: String
});
const openMovieConfirm = ref(false);
const openMovie = ref(false);
const movieUrl = ref('');

const openVideoModal = (url) => {
  if (userCourse === 3) {
    openMovieConfirm.value = true;
  } else {
    openMovie.value = true;
  }
  movieUrl.value = url;
}

const openVideoConfirmed = (confirmed) => {
  openMovieConfirm.value = false;
  openMovie.value = confirmed;
}

function accordion(e) {
  const trigger = e.target;
  const accordion = trigger.parentElement.parentElement;

  if( accordion.classList.contains('-active') ) {
    accordion.classList.remove("-active");
    trigger.classList.remove("-active");
    Array.from(accordion.children).forEach(c => {
      c.classList.remove("-active");
      if(c.classList.contains('data-target')) {
        slideUp(c, 2);
      }
    });
  } else {
    accordion.classList.add("-active");
    trigger.classList.add("-active");
    Array.from(accordion.children).forEach(c => {
      c.classList.add("-active");
      if(c.classList.contains('data-target')) {
        c.style.display = "block";
        slideDown(c, 5);
      }
    });
  }
}

const slideDown = ( target, speed ) => {
  try {
    let distance = 0;

    //get target height
    target.style.display = "block";
    target.style.height = "auto";
    let myheight = target.offsetHeight;
    target.style.height = 0 + 'px';

    let myInterval = setInterval( function(){
      target.style.height = distance + 'px';
      distance += (myheight - distance) / speed ;
      if( distance  >= myheight - 1 ){
        clearInterval(myInterval)
        target.style.height = myheight + 'px';
      }
    }, 10);
  } catch (e) {
    console.log(e);
  }
}

const slideUp = ( target, speed ) => {
  try {
    let distance = target.offsetHeight;

    let myInterval = setInterval( function(){
      distance -= distance / speed ;

      target.style.height = distance + 'px';
      if( distance <=  1 ){
        clearInterval(myInterval)
        target.style.height = 0 + 'px';
      }
    }, 10);
  } catch (e) {
    console.log(e);
  }
}

const rankText = (raceRank, accidentCd) => {
  return {
    "1":"取消",
    "2":"除外",
    "3":"除外",
    "4":"中止",
    "5":"失格",
    "6":`${raceRank}着(再騎)`,
    "7":`${raceRank}着(降着)`,
  }[accidentCd ?? 0] ?? `${raceRank}着`
}

</script>

<template>
  <div class="movie">
    <div :class="`grid-movie -weekday -num${races.length ?? '3'}`">
      <ul>
        <li v-for="v in races.filter(r => r.MovieUrl && r.Entries?.length > 0)">
          <router-link class="mov-play" :to="[1,2].includes(userCourse) ? loginUrl : '#'" @click="openVideoModal(v.MovieUrl)">
            <div class="thumb">
              <img v-if="v.ThumbUrl"
                   src="../../../assets/images/common/mov_frame.png"
                   :style="`background-image:url(${v.ThumbUrl}),url(/assets/images/uploads/mov.jpg);`"
                   alt="">
              <img v-else
                   src="../../../assets/images/common/mov_frame.png"
                   :style="`background-image:url(/assets/images/uploads/mov.jpg)`"
                   alt="">
            </div>
          </router-link>
          <div class="title">
            <h3 class="heading-forbox">
              <router-link :to="`/race/card?DOR=${v.DOR}&RacetrackCd=${v.RacetrackCd}&RaceNum=${v.RaceNum}`">
                {{ v.RaceShortName6 || v.RaceMainName || v.CommonRaceName }}
                <i :class="getGradeIconClass(v.GradeCd, v.TrackTypeCd, v.RaceRegulationCd)"></i>
              </router-link>
            </h3>
            <ul class="list-meta">
              <li>{{v.Date}}</li>
              <li>{{v.Racetrack}}</li>
              <li>{{{1:'芝', 2:'ダ', 3:'障'}[v.TrackTypeCd] ?? ''}}{{v.Distance}}m</li>
            </ul>
          </div>

          <div class="list-raceresult">
            <ul>
              <li v-for="e in v.Entries.slice(0, 3)">
                <div class="wakuban"><i :class="`icon-umaban -waku${e.BracketNum.toString().padStart(2,'0')}`">{{e.HorseNum}}</i></div>
                <div class="horse">{{e.HorseName}}</div>
                <div class="rank">{{rankText(e.RaceRank, e.AccidentCd)}}</div>
              </li>
            </ul>
            <div v-if="v.Entries.at(3)" class="data-accordion">
              <ul class="data-target" style="display: none; overflow: hidden;">
                <li v-for="e in v.Entries.slice(3)">
                  <div class="wakuban"><i :class="`icon-umaban -waku${e.BracketNum.toString().padStart(2,'0')}`">{{e.HorseNum}}</i></div>
                  <div class="horse">{{e.HorseName}}</div>
                  <div class="rank">{{rankText(e.RaceRank, e.AccidentCd)}}</div>
                </li>
              </ul>
              <div class="nav-group">
                <span class="btn-basic -more -openclose data-trigger" @click="accordion"></span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <ShowMovieConfirm
      @openVideoConfirmed="openVideoConfirmed"
      :openMovieConfirm="openMovieConfirm">
    </ShowMovieConfirm>
    <ShowMovie
      @closeModal="openMovie = false"
      :openMovie="openMovie"
      :movieURL="movieUrl">
    </ShowMovie>
  </div>
</template>