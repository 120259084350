<script setup>
import { ref, onMounted } from "vue";
import {
  setPogUser,
  getUrlVars,
  getUserId,
  SUCCESS,
  getJSON,
  getMenmerCourse,
  PAYINGNONE,
} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH } from "../../assets/js/define";

const checkWord = ref("");
const enterFlag = ref(0);

const registPageFlag = ref(1);
const confirmPageFlag = ref(0);

const userId = ref("");

const errFlag = ref(0);
const errMessage = ref("");

//ユーザID取得
onMounted(() => {
  if (getMenmerCourse() === PAYINGNONE) {
    location.href = "/member";
  }
  userId.value = getUserId();
  document.body.className = "status-loaded";
});

function checkRegistration(word) {
  if (word === "") {
    //未入力のエラーメッセージ表記
    enterFlag.value = 1;
  } else if (word.length > 10) {
    //文字数制限のエラーメッセージ表記
    registPageFlag.value = 1;
  } else if (word.length > 0) {
    //確認画面に遷移
    registPageFlag.value = 0;
    confirmPageFlag.value = 1;
  }
}

function isCancel() {
  registPageFlag.value = 1;
  confirmPageFlag.value = 0;
}

//フォームを選択したタイミングで、登録時のエラーメッセージを非表示にする
function isSelect() {
  errFlag.value = 0;
}

function setPogUserName(userId, nickName) {
  document.body.className = "";
  const request =
    HOST +
    PATH +
    "POG/setPogUser.js" +
    BACKWORDPATH +
    "?UserId=" +
    userId +
    "&NickName=" +
    nickName;
  getJSON(request, true, function (status, json) {
    if (json === 0) {
      setPogUser(1);
      location.href = HOST + "pog/mypage";
    } else if (json === 1) {
      registPageFlag.value = 1;
      confirmPageFlag.value = 0;
      errFlag.value = 1;
      errMessage.value =
        "エラー<br>通信中に問題が発生したため、登録できませんでした。";
    } else if (json === 2) {
      registPageFlag.value = 1;
      confirmPageFlag.value = 0;
      errFlag.value = 1;
      errMessage.value =
        "エラー<br>1ユーザーが登録できるPOGユーザーは1件までです。また、他の人が使用しているユーザー名は使用できません。";
    }
  });
  document.body.className = "status-loaded";
}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">POG ユーザー名登録</h1>
    </section>

    <section class="layout-section">
      <div class="container-box -pogregist">
        <div class="inner">
          <div class="layout-form">
            <div
              v-if="checkWord.length > 10"
              class="system-message -center -error"
            >
              ユーザー名は全角10文字以内にしてください。
            </div>
            <div
              v-if="enterFlag === 1 && checkWord.length === 0"
              class="system-message -center -error"
            >
              ユーザー名が未入力です。
            </div>
            <div
              v-if="errFlag === 1"
              v-html="errMessage"
              class="system-message -center -error"
            ></div>
            <transition name="top" mode="out-in">
              <div v-if="registPageFlag === 1" id="input">
                登録したいユーザー名を入力してください。
                <ul class="list-option -nomargin -important">
                  <li>全角10文字以内</li>
                </ul>

                <div class="nav-group">
                  <input
                    @click="isSelect"
                    type="text"
                    class="form-text"
                    placeholder="ユーザー名"
                    id="myname"
                    v-model="checkWord"
                  />
                </div>
                <div class="nav-group">
                  <p class="p-center">
                    上記で問題なければ確認ボタンを押してください。
                  </p>
                  <span @click="checkRegistration(checkWord)" class="btn-basic"
                    >確認する</span
                  >
                </div>
              </div>

              <div v-else-if="confirmPageFlag === 1" id="confirm">
                <div class="nav-group">
                  <table class="table-grid -center -pogname">
                    <tr>
                      <th>ユーザー名</th>
                      <td v-if="checkWord">
                        <em>{{ checkWord }}</em>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="nav-group">
                  <p class="p-center">
                    上記で問題なければ登録ボタンを押してください。<br />
                    <strong>※一度登録した内容は変更できません。</strong>
                  </p>
                  <div class="nav-bool">
                    <span
                      @click="isCancel()"
                      class="btn-basic -gray"
                      id="cancel"
                      >キャンセル</span
                    ><span
                      @click="setPogUserName(userId, checkWord)"
                      class="btn-basic"
                      id="regist"
                      >登録する</span
                    >
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" scoped>
.bottom-enter-active {
  transition: opacity 0.6s;
}

.bottom-leave {
  display: none;
}

.bottom-enter-from,
.bottom-leave-to {
  opacity: 0;
}

.top-enter-active,
.bottom-leave-active {
  transition: opacity 0.6s;
}

.top-enter-from,
.top-leave-to {
  opacity: 0;
}
</style>
<style lang="scss" src="../../assets/css/pog/style.scss" scoped></style>
