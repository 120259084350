import {getJSON, SUCCESS} from '../../assets/js/common';
import {JRA_WP_HOST, NAR_WP_HOST} from '../../assets/js/define';
import {useStorePageArticle} from "../../stores/page-states";
import {useStoreCommon} from "../../stores/common-states";
import {storeToRefs} from 'pinia';

export async function fetch(query) {
  const storeCommon = useStoreCommon();
  const apiUrl = `${storeCommon.isNarSite ? NAR_WP_HOST : JRA_WP_HOST}wp-json/wp/v2/pages/`;
  const store = useStorePageArticle();
  const {articleQuery, title, content, format} = storeToRefs(store);

  let param = ''

  articleQuery.value = JSON.stringify(query);

  if(query['id']) {
    param = `?id=${query['id']}`;
  }else if(query['slug']) {
    param = `?slug=${query['slug']}`;
  } else {
    title.value = '';
    content.value = '';
    format.value = '';
    return;
  }

  await getJSON(`${apiUrl}${param}`, true, function (status, json) {
    if (status !== SUCCESS || !json?.length) {
      return;
    }
    title.value = json[0]?.title?.rendered ?? '';
    content.value = json[0]?.content?.rendered ?? '';
    format.value = json[0]?.format ?? '';
  });
}

