/******************************************************************
 *　フロントでの共通定数
 ******************************************************************/

// ホスト ---------------------------------------------------------------
export const HOST = process.env.HOST;
export const JRA_HOST = process.env.JRA_HOST;
export const NAR_HOST = process.env.NAR_HOST;
export const NAR_WP_HOST = process.env.NAR_WP_HOST
export const JRA_WP_HOST = process.env.JRA_WP_HOST
//-----------------------------------------------------------------------

// モード ---------------------------------------------------------------
export const DEBUG = process.env.DEBUG_MODE === "true"; // デバッグモード WEBAPIの結果をダミーファイルから取得する リリース時はfalse
//-----------------------------------------------------------------------

// APIパス---------------------------------------------------------------
export const PATH = "webapi/";
export const CERTPATH = "cert/";
export const RACEINFOPATH = "webapi/RaceInfo/";
export const POGAPIPATH = "webapi/POG/";
export const ODDSPATH = "webapi/Odds/";
export const MEDIAPATH = "webapi/Media/";
export const BACKWORDPATH = "/webapi";
//-----------------------------------------------------------------------

// URLパス --------------------------------------------------------------
export const URLPATH = "w/";
export const DATAPATH = "data/"; // データページ
export const SEARCHPATH = "search/"; // 検索ページ
export const POGPATH = "pog/"; // POGページ
export const RACEPATH = "race/"; // レースページ
export const FAVPATH = "w/fav/"; // お気に入りページ
export const LEADINGPATH = "w/leading/"; // リーディングページ
export const WIN5PATH = "w/win5/"; // WIN5ページ
export const NEWSPATH = "news/"; // ニュース詳細ページ
export const NEWSIMAGEPATH = "shared/newsimg/"; // ニュース詳細ページ
export const YAHOOCLOSEPATH = "wp/static/y_premium_info_close/"; //Yahooプレミアム終了ページ
export const JOINPATH = "member"; //会員登録ページ
export const JOINECPATH = "member/join_economy"; //プレミアム追加コース会員登録ページ
//-----------------------------------------------------------------------

// ページング用定数-------------------------------------------------------
export const PRETEXT = "<"; // 前へのテキスト
export const NEXTTEXT = ">"; // 次へのテキスト
export const LISTMAX = 200; // 表示可能な要素件数上限
export const WARNINGTEXT =
  "最大件数を超えた為、200件まで表示します。\n条件を絞って再検索をして下さい。"; // 表示可能な要素件数上限を超えた場合に表示する文言
//-----------------------------------------------------------------------

// 検索用定数 ----------------------------------------------------------
export const SORTHORSENAME = 0; // 馬名ソート
export const SORTSEX = 1; // 性別ソート
export const SORTAGE = 2; // 馬齢ソート
export const SORTCLASS = 3; // クラスソート
//-----------------------------------------------------------------------

// グレードコード ----------------------------------------------------------
export const GLADE_G1 = "A"; //G1
export const GLADE_G2 = "B"; //G2
export const GLADE_G3 = "C"; //G3
export const GLADE_Ge = "D"; //その他重賞
export const GLADE_nG = " "; //重賞以外
export const GLADE_L = "L"; //リステッド
//-----------------------------------------------------------------------

// 重量種別(ハンデ表示対象) ----------------------------------------------------------
export const HAS_HANDICAP = { 1: "on", 2: "on", 3: "off", 4: "off" }; //1:ハンデ、2：別定、3:馬齢（対象外）、4：定量（対象外）
//-----------------------------------------------------------------------

//識別 1:単勝、2:複勝、3:枠連、4:馬連、5:ワイド、6:馬単、7:３連複、8:３連単、9:馬単マルチ、10:三連単マルチ
export const TOB_WIN = "1";
export const TOB_PLACE = "2";
export const TOB_BRACKET = "3";
export const TOB_QUINELLA = "4";
export const TOB_WIDE = "5";
export const TOB_EXACTA = "6";
export const TOB_TRIO = "7";
export const TOB_TRIFECTA = "8";
export const TOB_EXACTA_MULTI = "9";
export const TOB_TRIFECTA_MULTI = "10";
export const TOB_BRACKET_EXACTA = "11";
export const TOB_BRACKET_EXACTA_MULTI = "12";

//方式 0:通常、1:フォーメーション、2:ボックス、3:流し、4,5,6:１軸流し、7,8,9:2軸流し
export const METHOD_NORMAL = "0";
export const METHOD_FORMATION = "1";
export const METHOD_BOX = "2";
export const METHOD_WHEEL = "3";
export const METHOD_WHEEL_1 = "4";
export const METHOD_WHEEL_2 = "5";
export const METHOD_WHEEL_3 = "6";
export const METHOD_WHEEL_1_2 = "7";
export const METHOD_WHEEL_1_3 = "8";
export const METHOD_WHEEL_2_3 = "9";

// メール配信の送信アドレス ----------------------------------------------
export const MAIL_ENTRY = "entry";
export const MAIL_UNSUBSCRIBE = "unsubscribe";
export const MAIL_DOMAIN = "winkeiba.jp";
//export const MAIL_DOMAIN = "staging.winkeiba.jp"
//-----------------------------------------------------------------------


// データ検索サジェストの表示件数 ----------------------------------------------
export const SUGGEST_NUM = 5;
//-----------------------------------------------------------------------

// cookieドメイン
export const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN ?? '';


export default {
  PATH: "webapi/",
};
