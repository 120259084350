<script setup>
import {getUrlVars} from '../../assets/js/common'
import spat4Common from "../../functions/odds/spat4";

const { padToDigits, getSpat4ApiUrl } = spat4Common();

const voteSpat4 = async () => {
  const params = getUrlVars();
  const itemPrize = params['prize'];
  const betParamsArray = params['info'] ? params['info'].split(',') : [];

  let form = document.createElement('form');
  form.action = await getSpat4ApiUrl();
  form.method = "POST";
  document.body.append(form);

  const fd = new FormData(form);

  fd.set("V_CNT", betParamsArray.length);
  fd.set("V_MNY", itemPrize);

  for (let i = 0; i < betParamsArray.length; i++) {
    if (i >= 120) break;
    const bet = betParamsArray[i];
    const voteParam = `V${padToDigits((i + 1), 3)}`;
    fd.set(voteParam, bet);
  }

  for (let [key, value] of fd.entries()) {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }

  form.submit();
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">購入予定一覧</h1>
    </section>
    <section class="layout-section">
      <div class="inner">
        <p class="_center">
          <span @click="voteSpat4()" class="btn-basic">
              投票画面に遷移する
          </span>
        </p>
      </div>
    </section>
  </main>
</template>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>
