<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  SUCCESS,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  PAYINGSUGOTOKU,
  PAYINGECONOMY,
  PAYINGNONE,
  getCourse
} from "../../assets/js/common";
import { 
  HOST,
  PATH,
  BACKWORDPATH,
  JOINPATH,
  JOINECPATH
} from "../../assets/js/define";
import { useRouter } from 'vue-router'

import RaceTable from "../../components/program/RaceTable.vue"

// 今日の日付を取ってきてdorへ格納
const now = new Date();
const year = now.getFullYear().toString();
const month = ("00" + (now.getMonth() + 1)).slice(-2).toString();
const day = ("00" + now.getDate()).slice(-2).toString();
const today = year + month + day;
const dor = today;

let raceTypeCd = ref("11");
let raceRegulationCd = ref("701");

let raceType = ref({
  hurdle: "平地",
  age: "2歳",
});
let raceRegulation = ref("新馬");

let headingInfo = ref({});
let raceInfo = ref({});
let filteredRaceInfo = ref({});

onMounted(async() => {
  document.body.className = "";

  //プレミアム以外は会員登録ページへ遷移
  await checkUserCourse();
  // param確認
  let params = {}
  if (checkUrlVars(params)) {
    params = getUrlVars();
  }
  if (params.RaceTypeCd) {
    raceTypeCd.value = params.RaceTypeCd;
  }
  if (params.RaceRegulationCd) {
    raceRegulationCd.value = params.RaceRegulationCd;
  }

  await getProgramInfo(dor);
  //選択したタブの情報からレースデータをフィルタ
  filteredRaceInfo.value = setFilteredRaceInfo(raceInfo.value, raceTypeCd.value, raceRegulationCd.value);
  //選択したタブの情報を格納
  raceType.value = setRaceType(raceTypeCd.value)
  raceRegulation.value = setRaceRegulation(raceRegulationCd.value)

  document.body.className = "status-loaded";
});

const checkUserCourse = async() => {
  const router = useRouter()
  const request = HOST + PATH + "getUserCourse.js" + BACKWORDPATH;
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      if (json && json.courseId && json.courseId != "") {
        const courseId = json.courseId;
        if (
          courseId === PAYINGPREMIUM ||
          courseId === PAYINGPACK03 ||
          courseId === PAYINGPACK06 ||
          courseId === PAYINGPACK12 ||
          getCourse() === PAYINGSUGOTOKU
        ){
          return
        } else if (courseId === PAYINGECONOMY) {
          const url = "/" + JOINECPATH
          router.push(url);
        } else if (courseId === PAYINGNONE) {
          const url = "/" + JOINPATH
          router.push(url);
        }
      }
    }
  });
}
//apiからデータを取ってくる
async function getProgramInfo (dor) {
  const request =
    HOST +
    PATH +
    "RaceInfo/getProgramInfo.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor 

  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS && json != undefined) {
      // raceInfo, headingInfoに格納
      headingInfo.value = {
        East: json.East.HeadingInfo,
        West: json.West.HeadingInfo,
        Local: json.Local.HeadingInfo,
      };
      raceInfo.value = {
        East: json.East.RaceInfo,
        West: json.West.RaceInfo,
        Local: json.Local.RaceInfo,
      };

      for (let area in headingInfo.value) {
        for (let meeting in headingInfo.value[area]) {
          for (let raceDay of headingInfo.value[area][meeting].RaceDays) {
            const dorString = raceDay.DOR;

            // headingInfo.valueのDORを参照して、Dateを作成(2月10日(土))
            const date = dorString ? formatDorToDate(dorString) : "";
            if (date !== "") {
              raceDay.Date = date;
            }
            // headingInfo.valueのRaceDayを参照して、RaceDaySymbolを作成(①)
            const raceDaySymbol = getRaceDaySymbol(
              raceDay.RaceDay
            );
            if (raceDaySymbol) {
              raceDay.RaceDaySymbol = raceDaySymbol;
            }
          }
        }
      }
    }
  });
};
const setFilteredRaceInfo = (raceInfo, raceTypeCd, raceRegulationCd) => {
  let filteredRaceInfo = {
    East: {},
    West: {},
    Local: {}
  }
  for(let area in raceInfo) {
    for(let dor in raceInfo[area]) {
      if(!filteredRaceInfo[area][dor]) {
        filteredRaceInfo[area][dor] = []  
      }
      filteredRaceInfo[area][dor] = raceInfo[area][dor].filter((race)=>{
        return race.RaceTypeCd === raceTypeCd && race.RaceRegulationCd === raceRegulationCd
      })
    }
  }
  return filteredRaceInfo
}
const setRaceType = (raceTypeCd) => {
  let raceType = {
    value: '',
    hurdle: '',
    age: ''
  }
  if(raceTypeCdList[raceTypeCd]) {
    raceType.hurdle = raceTypeCdList[raceTypeCd].includes('障害') ? '障害' : '平地'
    raceType.age = setRaceTypeAge(raceTypeCdList[raceTypeCd])
  }
  return raceType
}
const setRaceTypeAge = (raceType) => {
  if(raceType.includes('障害')) {
      return raceType.slice(2,6)
  } else if(raceType.includes('以上')) {
      return raceType.slice(0,4)
  } else {
      return raceType.slice(0,2)
  } 
}
const setRaceRegulation = (raceRegulationCd) => {
  return raceRegulationCdList[raceRegulationCd]
    ? raceRegulationCdList[raceRegulationCd]
    : ''
}
const formatDorToDate = (dorString) => {
  if (dorString && dorString.length === 8) {
    const dorYear = dorString.slice(0, 4);
    const dorMonth =
      dorString.slice(4, 6) >= "10"
        ? dorString.slice(4, 6)
        : dorString.slice(5, 6);
    const dorDay =
      dorString.slice(6, 8) >= "10"
        ? dorString.slice(6, 8)
        : dorString.slice(7, 8);
    const dorDate = new Date(`${dorYear}/${dorMonth}/${dorDay}`);
    const dorWeekday = ["日", "月", "火", "水", "木", "金", "土"][
      dorDate.getDay()
    ];

    const formattedDate = `${dorMonth}月${dorDay}日(${dorWeekday})`;
    return formattedDate;
  } else {
    return;
  }
};

const getRaceDaySymbol = (raceDay) => {
  return symbolMap[raceDay];
};

//タブ切り替え
const switchTabs = (type, selectedTab) => {
  if (type === "raceType") {
    raceTypeCd.value = selectedTab;
  } else if (type === "raceRegulation") {
    raceRegulationCd.value = selectedTab;
  }
  // 表示するデータ取得
  filteredRaceInfo.value = setFilteredRaceInfo(raceInfo.value, raceTypeCd.value, raceRegulationCd.value)
  raceType.value = setRaceType(raceTypeCd.value)
  raceRegulation.value = setRaceRegulation(raceRegulationCd.value)
};

const symbolMap = {
  "01": "①",
  "02": "②",
  "03": "③",
  "04": "④",
  "05": "⑤",
  "06": "⑥",
  "07": "⑦",
  "08": "⑧",
  "09": "⑨",
  "10": "⑩",
  "11": "⑪",
  "12": "⑫",
};
const raceTypeCdList = {
  '00' : '',
  '11' : '2歳',
  '12' : '3歳',
  '13' : '3歳上',
  '14' : '4歳上',
  '18' : '障害3歳以上',
  '19' : '障害4歳以上',
  '21' : '2歳',
  '22' : '3歳',
  '23' : '3歳以上',
  '24' : '4歳以上'
}
const raceRegulationCdList = {
  '000' : '',
  '005' : '1勝',
  '010' : '2勝',
  '016' : '3勝',
  '701' : '新馬',
  '702' : '未出走',
  '703' : '未勝利',
  '999' : 'オープン'
}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">番組表</h1>
    </section>

    <section class="layout-section">
      <div class="nav-tab -fit5 -channel -auto">
        <ul>
          <li
            @click="switchTabs('raceType', '11')"
            :class="{ '-current': raceTypeCd === '11' }">
            <span class="label">2歳</span>
          </li>
          <li
            @click="switchTabs('raceType', '12')"
            :class="{ '-current': raceTypeCd === '12' }">
            <span class="label">3歳</span>
          </li>
          <li
            @click="switchTabs('raceType', '13')"
            :class="{ '-current': raceTypeCd === '13' }">
            <span class="label">3歳上</span>
          </li>
          <li
            @click="switchTabs('raceType', '14')"
            :class="{ '-current': raceTypeCd === '14' }">
            <span class="label">4歳上</span>
          </li>
          <li
            @click="switchTabs('raceType', '18')"
            :class="{ '-current': raceTypeCd === '18' }">
            <span class="label">障害3歳上</span>
          </li>
          <li
            @click="switchTabs('raceType', '19')"
            :class="{ '-current': raceTypeCd === '19' }">
            <span class="label">障害4歳上</span>
          </li>
        </ul>
      </div>
      <div class="inner">
        <div class="container-toggle -auto">
          <ul>
            <li
              @click="switchTabs('raceRegulation', '701')"
              :class="{
                '-current': raceRegulationCd === '701',
              }">
              <span class="label">新馬</span>
            </li>
            <li
              @click="switchTabs('raceRegulation', '703')"
              :class="{
                '-current': raceRegulationCd === '703',
              }">
              <span class="label">未勝利</span>
            </li>
            <li
              @click="switchTabs('raceRegulation', '005')"
              :class="{
                '-current': raceRegulationCd === '005',
              }">
              <span class="label">1勝</span>
            </li>
            <li
              @click="switchTabs('raceRegulation', '010')"
              :class="{
                '-current': raceRegulationCd === '010',
              }">
              <span class="label">2勝</span>
            </li>
            <li
              @click="switchTabs('raceRegulation', '016')"
              :class="{
                '-current': raceRegulationCd === '016',
              }">
              <span class="label">3勝</span>
            </li>
            <li
              @click="switchTabs('raceRegulation', '999')"
              :class="{
                '-current': raceRegulationCd === '999',
              }">
              <span class="label">オープン</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="inner">
        <h2 class="heading-bar">東</h2>
        <race-table
          :raceType="raceType"
          :raceRegulation="raceRegulation"
          :headingInfo="headingInfo.East"
          :raceInfo="filteredRaceInfo.East" />
      </div>
      <div class="inner">
        <h2 class="heading-bar">西</h2>
        <race-table
          :raceType="raceType"
          :raceRegulation="raceRegulation"
          :headingInfo="headingInfo.West"
          :raceInfo="filteredRaceInfo.West" />
      </div>
      <div class="inner">
        <h2 class="heading-bar">ローカル</h2>
        <race-table
          :raceType="raceType"
          :raceRegulation="raceRegulation"
          :headingInfo="headingInfo.Local"
          :raceInfo="filteredRaceInfo.Local" />
      </div>
      <div class="inner">
        <p class="p-caption">
          ※負担重量を特に記載していない競走は馬齢重量とします。<br>
          ※太枠内の競走を特別競走とします。
        </p>
      </div>
    </section>
  </main>
</template>