<script setup>
import {ref, onMounted, watch} from "vue";
import {useRoute} from "vue-router";
import Course from "../../components/race/analysis/Course.vue";
import Jockey from "../../components/race/analysis/Jockey.vue";
import Condition from "../../components/race/analysis/Condition.vue";
import Distance from "../../components/race/analysis/Distance.vue";
import RaceCourse from "../../components/race/analysis/RaceCourse.vue";
import RaceHeader from "../../components/race/RaceHeader.vue";
import RaceData from "../../components/race/analysis/RaceData.vue";
import CourseData from "../../components/race/analysis/CourseData.vue";
import HorseData from "../../components/race/analysis/HorseData.vue";
import JockeyData from "../../components/race/analysis/JockeyData.vue";

import { SUCCESS, FRACEHORSE, TRACEHORSE } from "../../assets/js/common";
import {
  getJSON,
  getUrlVars,
  retrieveCourse,
  calcBracketNum,
  isFavorite,
  isNextTarget,
  rateFirst,
  rateSecond,
  rateThird,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  RACEINFOPATH,
} from "../../assets/js/define";
import moment from "moment";
import {useStoreRaceCommon} from "../../stores/race-common-states";
import {storeToRefs} from "pinia";

const storeRaceCommon = useStoreRaceCommon()
const {
  raceInfo,
  raceTrackCdDisplay,
} = storeToRefs(storeRaceCommon);

//データ
const PAYINGDATA = ref(null);
const bestTimeInfoData = ref([]);
const furlongTimeInfoTitle = ref(null);
const furlongTimeInfoData = ref([]);
const rotateInfoTitle = ref(null);
const rotateInfoData = ref([]);
const courseStatData = ref([]);
const jockeyInfoData = ref([]);
const conditionHead = ref(null);
const condition1Data = ref([]);
const condition2Data = ref([]);
const condition3Data = ref([]);
const condition4Data = ref([]);
const distanceData = ref([]);
const placeData = ref([]);

const isBestTimeOpen = ref(false);
const isFurlongTimeOpen = ref(false);
const isRotateInfoOpen = ref(false);
const courseId = ref('');
const NARFLAG = ref(null);

const tab = ref("course");
const isCurrentTab = ref("course");

const column = ref("race");
const isCurrentColumn = ref("race");
const analysisDataFlg = ref(false);

const route = useRoute();

const tabChange = (data) => {
  tab.value = data;
  isCurrentTab.value = data;
};
const columnChange = (data) => {
  column.value = data;
  isCurrentColumn.value = data;
};

const handleBestTimeToggle = () => {
  isBestTimeOpen.value = !isBestTimeOpen.value;
};

const handleFurlongTimeToggle = () => {
  isFurlongTimeOpen.value = !isFurlongTimeOpen.value;
};

const handleRotateInfoToggle = () => {
  isRotateInfoOpen.value = !isRotateInfoOpen.value;
};

const beforeEnter = (el) => {
  el.style.height = "0";
};

const enter = (el) => {
  el.style.height = el.scrollHeight + "px";
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + "px";
};

const leave = (el) => {
  el.style.height = "0";
};

watch(route, (from, to) => {
  setAnalysis(to.query.DOR, to.query.RacetrackCd, to.query.RaceNum);
});

onMounted(() => {
  NARFLAG.value = isNar();

  // // パラメータ取得
  let params = getUrlVars();
  callRetrieveCourse(function (cId) {
    courseId.value = cId;
    setAnalysis(params.DOR, params.RacetrackCd, params.RaceNum);
  });

  //データ分析表示判定
  let now = new Date();
  let todayHyphen = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
  let dorHyphen = params.DOR.slice(0, 4) + "-" + params.DOR.slice(4, 6) + "-" + params.DOR.slice(6, 8);
  let twoDaysBefore = moment(todayHyphen).add('days', -2).format("YYYY-MM-DD")
  let tomorrow = moment(todayHyphen).add('days', 1).format("YYYY-MM-DD")
  let twoWeekBefore = moment(todayHyphen).add('days', -14).format("YYYY-MM-DD")
  let nextWeek = moment(todayHyphen).add('days', 7).format("YYYY-MM-DD")

  if(isNar() === 1 && (moment(dorHyphen).isBefore(twoDaysBefore) ||
    moment(dorHyphen).isAfter(tomorrow))){
    analysisDataFlg.value = false;
    document.body.className = "status-loaded";
  } else if(isNar() === 0 && (moment(dorHyphen).isBefore(twoWeekBefore) ||
    moment(dorHyphen).isAfter(nextWeek))){
    analysisDataFlg.value = false;
    document.body.className = "status-loaded";
  } else {
    analysisDataFlg.value = true;
  }

  function callRetrieveCourse(callback) {
    try {
      retrieveCourse(callback);
    } catch (e) {
      callback("none");
    }
  }
});

function setAnalysis(DOR, RacetrackCd, RaceNum) {
  const request =
    HOST +
    RACEINFOPATH +
    "racingAnalyzerAPI.js" +
    BACKWORDPATH +
    "?DOR=" +
    DOR +
    "&RacetrackCd=" +
    RacetrackCd +
    "&RaceNum=" +
    RaceNum +
    "&NarFlag=" +
    isNar();
  // document.body.className = "";
  getJSON(request, true, function (status, json) {
    if (status !== SUCCESS) {
      return;
    }
    try {
      if (json != undefined && json != null) {
        let analysisData = {};
        if (json[0] != undefined) {
          analysisData = json[0];
        }

        if (Object.keys(analysisData).length) {
          //持ち時計
          let _bestTimeInfoData = [];
          if (
            analysisData.BestTimeInfo &&
            analysisData.BestTimeInfo.length > 0
          ) {
            for (let i = 0; i < analysisData.BestTimeInfo.length; i++) {
              if (
                analysisData.BestTimeInfo[i].BestTime != undefined &&
                analysisData.BestTimeInfo[i].BestTime != ""
              ) {
                let data = {};

                data.horseName = analysisData.BestTimeInfo[i].HorseName;
                data.racetrackName =
                  analysisData.BestTimeInfo[i].RacetrackName;

                if (analysisData.BestTimeInfo[i].BestTime.length >= 5) {
                  const bestTime = analysisData.BestTimeInfo[i].BestTime;
                  data.bestTime =
                    parseInt(bestTime.slice(0, 2), 10) +
                    ":" +
                    bestTime.slice(2, 4) +
                    "." +
                    bestTime.slice(4, 5);
                } else {
                  data.bestTime = "-";
                }

                if (
                  analysisData.BestTimeInfo[i].Weight != undefined &&
                  analysisData.BestTimeInfo[i].Weight != null &&
                  analysisData.BestTimeInfo[i].Weight != ""
                ) {
                  data.weight = analysisData.BestTimeInfo[i].Weight + "kg";
                } else {
                  data.weight = "-";
                }

                if (
                  analysisData.BestTimeInfo[i].RaceResult != undefined &&
                  analysisData.BestTimeInfo[i].RaceResult != null &&
                  analysisData.BestTimeInfo[i].RaceResult != ""
                ) {
                  data.raceResult =
                    parseInt(analysisData.BestTimeInfo[i].RaceResult, 10) +
                    "着";
                } else {
                  data.raceResult = "-";
                }

                if (
                  analysisData.BestTimeInfo[i].HorseNum != undefined &&
                  analysisData.BestTimeInfo[i].HorseNum != null
                ) {
                  data.horseNum = analysisData.BestTimeInfo[i].HorseNum;
                } else {
                  data.horseNum = "";
                }

                //お気に入りの赤文字表記
                if (isFavorite(FRACEHORSE, analysisData.BestTimeInfo[i].PedigreeNum) ||
                  isNextTarget(TRACEHORSE, analysisData.BestTimeInfo[i].PedigreeNum)) {
                  data.favClass = "favorite-horse";
                } else {
                  data.favClass = "";
                }

                data.bracketNum = calcBracketNum(
                  analysisData.EntryNum,
                  analysisData.BestTimeInfo[i].HorseNum
                );
                _bestTimeInfoData.push(data);
              }
            }
            bestTimeInfoData.value.splice(0, bestTimeInfoData.value.length)
            bestTimeInfoData.value = _bestTimeInfoData;
          }

          //上がり３F
          let _furlongTimeInfoData = [];
          if (
            analysisData.BestFurlongTimeInfo &&
            analysisData.BestFurlongTimeInfo.length > 0
          ) {
            //障害の場合、「平均1F」
            if (analysisData.TrackType == "障害") {
              furlongTimeInfoTitle.value = "平均1F　上位順";
            } else {
              furlongTimeInfoTitle.value = "上がり3F　上位順";
            }
            for (
              let i = 0;
              i < analysisData.BestFurlongTimeInfo.length;
              i++
            ) {
              if (
                analysisData.BestFurlongTimeInfo[i].FurlongTime !=
                undefined &&
                analysisData.BestFurlongTimeInfo[i].FurlongTime != ""
              ) {
                let data = {};

                data.horseName =
                  analysisData.BestFurlongTimeInfo[i].HorseName;
                data.racetrackName =
                  analysisData.BestFurlongTimeInfo[i].RacetrackName;

                if (
                  analysisData.BestFurlongTimeInfo[i].FurlongTime !=
                  undefined &&
                  analysisData.BestFurlongTimeInfo[i].FurlongTime != null &&
                  analysisData.BestFurlongTimeInfo[i].FurlongTime != ""
                ) {
                  data.furlongTime =
                    analysisData.BestFurlongTimeInfo[i].FurlongTime;
                } else {
                  data.furlongTime = "";
                }
                if (
                  analysisData.BestFurlongTimeInfo[i].Distance !=
                  undefined &&
                  analysisData.BestFurlongTimeInfo[i].Distance != null &&
                  analysisData.BestFurlongTimeInfo[i].Distance != ""
                ) {
                  data.distance =
                    analysisData.BestFurlongTimeInfo[i].Distance;
                } else {
                  data.distance = "-";
                }
                if (
                  analysisData.BestFurlongTimeInfo[i].RaceResult !=
                  undefined &&
                  analysisData.BestFurlongTimeInfo[i].RaceResult != null &&
                  analysisData.BestFurlongTimeInfo[i].RaceResult != ""
                ) {
                  data.raceResult =
                    parseInt(
                      analysisData.BestFurlongTimeInfo[i].RaceResult,
                      10
                    ) + "着";
                } else {
                  data.raceResult = "-";
                }
                if (
                  analysisData.BestFurlongTimeInfo[i].HorseNum !=
                  undefined &&
                  analysisData.BestFurlongTimeInfo[i].HorseNum != null
                ) {
                  data.horseNum =
                    analysisData.BestFurlongTimeInfo[i].HorseNum;
                } else {
                  data.horseNum = "";
                }

                // お気に入りの赤文字表記
                if (isFavorite(FRACEHORSE, analysisData.BestFurlongTimeInfo[i].PedigreeNum) ||
                  isNextTarget(TRACEHORSE, analysisData.BestFurlongTimeInfo[i].PedigreeNum)) {
                  data.favClass = "favorite-horse";
                } else {
                  data.favClass = "";
                }

                data.bracketNum = calcBracketNum(
                  analysisData.EntryNum,
                  analysisData.BestFurlongTimeInfo[i].HorseNum
                );
                _furlongTimeInfoData.push(data);
              }
            }
            furlongTimeInfoData.value.splice(0, furlongTimeInfoData.value.length);
            furlongTimeInfoData.value = _furlongTimeInfoData;
          }

          //右回/左回/障害実績
          if (analysisData.TrackType == "障害") {
            rotateInfoTitle.value = "実績　上位順";
          } else {
            rotateInfoTitle.value =
              analysisData.TrackRotation + "実績　上位順";
          }

          let rotateInfo = [];
          if (
            analysisData.RotationStatInfo &&
            analysisData.RotationStatInfo.length > 0
          ) {
            rotateInfo = analysisData.RotationStatInfo;
          }
          if (
            analysisData.SteeplechaseStat &&
            analysisData.SteeplechaseStat.length > 0
          ) {
            rotateInfo = analysisData.SteeplechaseStat;
            if (
              analysisData.TrackRotation == "" &&
              analysisData.TrackRotation
            ) {
            }
          }
          let _rotateInfoData = [];
          if (
            rotateInfo &&
            rotateInfo.length > 0
          ) {
            for (let i = 0; i < rotateInfo.length; i++) {
              if (
                !(
                  rotateInfo[i].Place1st == 0 &&
                  rotateInfo[i].Place2nd == 0 &&
                  rotateInfo[i].Place3rd == 0 &&
                  rotateInfo[i].Unplaced == 0
                )
              ) {
                let data = {};

                data.horseName = rotateInfo[i].HorseName;
                data.place1st = rotateInfo[i].Place1st;
                data.place2nd = rotateInfo[i].Place2nd;
                data.place3rd = rotateInfo[i].Place3rd;
                data.unplaced = rotateInfo[i].Unplaced;

                if (
                  rotateInfo[i].HorseNum != undefined &&
                  rotateInfo[i].HorseNum != null
                ) {
                  data.horseNum = rotateInfo[i].HorseNum;
                } else {
                  data.horseNum = "-";
                }
                //お気に入りの赤文字表記
                if (isFavorite(FRACEHORSE, rotateInfo[i].PedigreeNum) ||
                  isNextTarget(TRACEHORSE, rotateInfo[i].PedigreeNum)) {
                  data.favClass = "favorite-horse";
                } else {
                  data.favClass = "";
                }

                data.bracketNum = calcBracketNum(
                  analysisData.EntryNum,
                  rotateInfo[i].HorseNum
                );
                _rotateInfoData.push(data);
              }
            }
            rotateInfoData.value.splice(0, rotateInfoData.value.length);
            rotateInfoData.value = _rotateInfoData;
          }

          //rowspan用に枠数を確認
          let info = analysisData.CourseStat;
          let rowSpans = checkBracketNum(info);

          //コース
          let _courseStatData = [];
          if (
            analysisData.CourseStat &&
            analysisData.CourseStat.length > 0
          ) {
            let courseInfo = analysisData.CourseStat.sort((x, y) => {return x.HorseNum - y.HorseNum});
            let preBracketNum = "0"; //ひとつ前の枠番

            for (let i = 0; i < courseInfo.length; i++) {
              let data = {};

              data.place1st = courseInfo[i].Place1st;
              data.place2nd = courseInfo[i].Place2nd;
              data.place3rd = courseInfo[i].Place3rd;
              data.unplaced = courseInfo[i].Unplaced;

              if (preBracketNum != courseInfo[i].BracketNum) {
                data.bracketNum = courseInfo[i].BracketNum;
                preBracketNum = courseInfo[i].BracketNum;
              } else {
                data.bracketNum = preBracketNum;
              }

              if (
                courseInfo[i].PedigreeNum &&
                courseInfo[i].PedigreeNum !== "0000000000"
              ) {
                //#4412 お気に入りの赤文字表記
                if (isFavorite(FRACEHORSE, courseInfo[i].PedigreeNum) || isNextTarget(
                  TRACEHORSE, courseInfo[i].PedigreeNum
                )) {
                  data.favClass = "favorite-horse";
                } else {
                  data.favClass = "";
                }
                data.pedigreeNum = courseInfo[i].PedigreeNum;
              }
              data.horseNum = courseInfo[i].HorseNum;
              data.horseName = courseInfo[i].HorseName;
              data.IsExistRaceHorseMaster = courseInfo[i].IsExistRaceHorseMaster

              _courseStatData.push(data);
            }
            courseStatData.value.splice(0, courseStatData.value.length);
            courseStatData.value = _courseStatData;
          }

          //騎手
          let _jockeyInfoData = [];
          if (
            analysisData.JockeyStat &&
            analysisData.JockeyStat.length > 0
          ) {
            let jockeyInfo = analysisData.JockeyStat.sort((x, y) => {return x.HorseNum - y.HorseNum});

            let preBracketNum = "0"; //ひとつ前の枠番
            for (let i = 0; i < jockeyInfo.length; i++) {
              let data = {};
              let jockey_win_first = parseInt(jockeyInfo[i].Place1st, 10);
              let jockey_win_second = parseInt(jockeyInfo[i].Place2nd, 10);
              let jockey_win_third = parseInt(jockeyInfo[i].Place3rd, 10);
              let jockey_win_unplaced = parseInt(
                jockeyInfo[i].Unplaced,
                10
              );
              let jockey_win_total =
                jockey_win_first +
                jockey_win_second +
                jockey_win_third +
                jockey_win_unplaced;
              //勝率
              data.jockeyRate1st =
                (rateFirst(jockey_win_first, jockey_win_total) * 100)
                  .toFixed(1)
                  .toString() + "%";
              data.jockeyRate2nd =
                (
                  rateSecond(
                    jockey_win_first,
                    jockey_win_second,
                    jockey_win_total
                  ) * 100
                )
                  .toFixed(1)
                  .toString() + "%";
              data.jockeyRate3rd =
                (
                  rateThird(
                    jockey_win_first,
                    jockey_win_second,
                    jockey_win_third,
                    jockey_win_total
                  ) * 100
                )
                  .toFixed(1)
                  .toString() + "%";

              if (preBracketNum != jockeyInfo[i].BracketNum) {
                data.bracketNum = jockeyInfo[i].BracketNum;
                preBracketNum = jockeyInfo[i].BracketNum;
              } else {
                data.bracketNum = preBracketNum;
              }
              //#5167騎手/調教師マスタに存在するデータかチェック

              if (jockeyInfo[i].IsJockeyMaster != 0) {
                data.jockeyCd = isNar() === 1 ? String(jockeyInfo[i].JockeyCd).padStart(6, '0') : jockeyInfo[i].JockeyCd
              }
              data.jockeyName = jockeyInfo[i].JockeyName;
              data.horseName = jockeyInfo[i].HorseName;
              data.horseNum = jockeyInfo[i].HorseNum;

              //お気に入りの赤文字表記
              if (isFavorite(FRACEHORSE, jockeyInfo[i].PedigreeNum) ||
                isNextTarget(TRACEHORSE, jockeyInfo[i].PedigreeNum)) {
                data.favClass = "favorite-horse";
              } else {
                data.favClass = "";
              }

              _jockeyInfoData.push(data);
            }
            jockeyInfoData.value.splice(0, jockeyInfoData.value.length);
            jockeyInfoData.value = _jockeyInfoData;
          }

          //馬場
          if (
            analysisData.TrackCondition1Stat &&
            analysisData.TrackCondition1Stat.length > 0
          ) {
            const condition1Info = analysisData.TrackCondition1Stat.sort((x, y) => {return x.HorseNum - y.HorseNum});
            condition1Data.value = setConditionData(
              condition1Info,
              rowSpans
            );
          }
          if (
            analysisData.TrackCondition2Stat &&
            analysisData.TrackCondition2Stat.length > 0
          ) {
            const condition2Info = analysisData.TrackCondition2Stat.sort((x, y) => {return x.HorseNum - y.HorseNum});
            condition2Data.value = setConditionData(
              condition2Info,
              rowSpans
            );
          }
          if (
            analysisData.TrackCondition3Stat &&
            analysisData.TrackCondition3Stat.length > 0
          ) {
            const condition3Info = analysisData.TrackCondition3Stat.sort((x, y) => {return x.HorseNum - y.HorseNum});
            condition3Data.value = setConditionData(
              condition3Info,
              rowSpans
            );
          }
          if (
            analysisData.TrackCondition4Stat &&
            analysisData.TrackCondition4Stat.length > 0
          ) {
            let condition4Info = analysisData.TrackCondition4Stat.sort((x, y) => {return x.HorseNum - y.HorseNum});
            condition4Data.value = setConditionData(
              condition4Info,
              rowSpans
            );
          }

          //距離
          let _distanceData = [];
          if (
            analysisData.DistanceStat &&
            analysisData.DistanceStat.length > 0
          ) {
            let distanceInfo = analysisData.DistanceStat.sort((x, y) => {return x.HorseNum - y.HorseNum});
            let preBracketNum = "0"; //ひとつ前の枠番
            for (let i = 0; i < distanceInfo.length; i++) {
              let data = {};
              let distance_win_first = parseInt(
                distanceInfo[i].Place1st,
                10
              );
              let distance_win_second = parseInt(
                distanceInfo[i].Place2nd,
                10
              );
              let distance_win_third = parseInt(
                distanceInfo[i].Place3rd,
                10
              );
              let distance_win_unplaced = parseInt(
                distanceInfo[i].Unplaced,
                10
              );
              let distance_win_total =
                distance_win_first +
                distance_win_second +
                distance_win_third +
                distance_win_unplaced;
              //勝率
              data.distance_win_first = distance_win_first;
              data.distance_win_second = distance_win_second;
              data.distance_win_third = distance_win_third;
              data.distance_win_unplaced = distance_win_unplaced;
              data.distanceRate1st =
                (rateFirst(distance_win_first, distance_win_total) * 100)
                  .toFixed(1)
                  .toString() + "%";
              data.distanceRate2nd =
                (
                  rateSecond(
                    distance_win_first,
                    distance_win_second,
                    distance_win_total
                  ) * 100
                )
                  .toFixed(1)
                  .toString() + "%";
              data.distanceRate3rd =
                (
                  rateThird(
                    distance_win_first,
                    distance_win_second,
                    distance_win_third,
                    distance_win_total
                  ) * 100
                )
                  .toFixed(1)
                  .toString() + "%";

              if (preBracketNum != distanceInfo[i].BracketNum) {
                data.bracketNum = distanceInfo[i].BracketNum;
                preBracketNum = distanceInfo[i].BracketNum;
              } else {
                data.bracketNum = preBracketNum;
              }

              if (
                distanceInfo[i].PedigreeNum &&
                distanceInfo[i].PedigreeNum !== "0000000000"
              ) {
                //#4412 お気に入りの赤文字表記
                if (isFavorite(FRACEHORSE, distanceInfo[i].PedigreeNum) || isNextTarget(TRACEHORSE, distanceInfo[i].PedigreeNum)) {
                  data.favClass = "favorite-horse";
                } else {
                  data.favClass = "";
                }
                data.pedigreeNum = distanceInfo[i].PedigreeNum;
              }
              data.horseName = distanceInfo[i].HorseName;
              data.horseNum = distanceInfo[i].HorseNum;
              data.IsExistRaceHorseMaster = distanceInfo[i].IsExistRaceHorseMaster

              _distanceData.push(data);
            }
            distanceData.value.splice(0, distanceData.value.length);
            distanceData.value = _distanceData;
          }

          //競馬場
          let _placeData = [];
          if (
            analysisData.RacetrackStat &&
            analysisData.RacetrackStat.length > 0
          ) {
            let placeInfo = analysisData.RacetrackStat.sort((x, y) => {return x.HorseNum - y.HorseNum});

            let preBracketNum = "0"; //ひとつ前の枠番
            for (let i = 0; i < placeInfo.length; i++) {
              let data = {};
              if (preBracketNum != placeInfo[i].BracketNum) {
                data.bracketNum = placeInfo[i].BracketNum;
                preBracketNum = placeInfo[i].BracketNum;
              } else {
                data.bracketNum = preBracketNum;
              }

              data.horseName = placeInfo[i].HorseName;
              data.horseNum = placeInfo[i].HorseNum;
              data.IsExistRaceHorseMaster = placeInfo[i].IsExistRaceHorseMaster;
              data.place1st = placeInfo[i].Place1st;
              data.place2nd = placeInfo[i].Place2nd;
              data.place3rd = placeInfo[i].Place3rd;
              data.unplaced = placeInfo[i].Unplaced;

              if (
                placeInfo[i].PedigreeNum &&
                placeInfo[i].PedigreeNum !== "0000000000"
              ) {
                //#4412 お気に入りの赤文字表記
                if (isFavorite(FRACEHORSE, placeInfo[i].PedigreeNum) || isNextTarget(TRACEHORSE, placeInfo[i].PedigreeNum)) {
                  data.favClass = "favorite-horse";
                } else {
                  data.favClass = "";
                }
                data.pedigreeNum = placeInfo[i].PedigreeNum;
              }

              _placeData.push(data);
            }
            placeData.value.splice(0, placeData.value.length);
            placeData.value = _placeData;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    document.body.className = "status-loaded";
  });
}

//同じ枠番の頭数を確認する
function checkBracketNum(data) {
  let count = new Object();
  for (let j = 0; j < data.length; j++) {
    if (count[data[j].BracketNum] == undefined) {
      count[data[j].BracketNum] = 1;
    } else {
      count[data[j].BracketNum] = count[data[j].BracketNum] + 1;
    }
  }
  return count;
}

//馬場状態セット
function setConditionData(conditionInfo, spans) {
  let preBracketNum = "0";
  let condition = [];
  for (let i = 0; i < conditionInfo.length; i++) {
    let data = {};
    if (preBracketNum != conditionInfo[i].BracketNum) {
      data.bracketNum = conditionInfo[i].BracketNum;
      data.rowSpans = spans[conditionInfo[i].BracketNum];
      preBracketNum = conditionInfo[i].BracketNum;
    } else {
      data.bracketNum = preBracketNum;
      data.rowSpans = spans[preBracketNum];
    }
    if (
      conditionInfo[i].PedigreeNum &&
      conditionInfo[i].PedigreeNum !== "0000000000"
    ) {
      //#4412 お気に入りの赤文字表記
      if (isFavorite(FRACEHORSE, conditionInfo[i].PedigreeNum) || isNextTarget(TRACEHORSE, conditionInfo[i].PedigreeNum)) {
        data.favClass = "favorite-horse";
      } else {
        data.favClass = "";
      }
      data.pedigreeNum = conditionInfo[i].PedigreeNum;
    }
    data.horseName = conditionInfo[i].HorseName;
    data.horseNum = conditionInfo[i].HorseNum;
    data.IsExistRaceHorseMaster = conditionInfo[i].IsExistRaceHorseMaster;
    data.place1st = conditionInfo[i].Place1st;
    data.place2nd = conditionInfo[i].Place2nd;
    data.place3rd = conditionInfo[i].Place3rd;
    data.unplaced = conditionInfo[i].Unplaced;
    condition.push(data);
  }
  return condition;
}
</script>

<template>
  <main class="layout-main">
    <RaceHeader />

    <section class="layout-section">
      <div class="container-tabtarget">

        <div class="layout-sub" v-if="(courseId !== 'economy')">
          <div class="inner">
            <div data-tab='["data-trigger","data-target"]'>
              <div class="container-toggle -marginbottom">
                <ul data-trigger>
                  <li
                    @click="columnChange('race')"
                    :class="{ '-current': isCurrentColumn === 'race' }"
                  >
                    <span class="label">レース</span>
                  </li>
                  <li
                    @click="columnChange('course')"
                    :class="{ '-current': isCurrentColumn === 'course' }"
                  >
                    <span class="label">コース</span>
                  </li>
                  <li
                    @click="columnChange('horse')"
                    :class="{ '-current': isCurrentColumn === 'horse' }"
                  >
                    <span class="label">出走馬</span>
                  </li>
                  <li
                    @click="columnChange('jockey')"
                    :class="{ '-current': isCurrentColumn === 'jockey' }"
                  >
                    <span class="label">騎手</span>
                  </li>
                </ul>
              </div>

              <div class="" data-target v-if="analysisDataFlg">
                <RaceData
                  v-if="column === 'race'"
                />
                <CourseData
                  v-if="column === 'course'"
                />
                <HorseData
                  v-if="column === 'horse'"
                />
                <JockeyData
                  v-if="column === 'jockey'"
                />
              </div>
              <div class="" data-target v-else>
                <p v-if="!NARFLAG">データ分析（回収率）はレース当週およびレース前週のみの公開です。</p>
                <p v-if="NARFLAG">データ分析（回収率）はレース2日前～翌日まで公開されます。</p>
              </div>
            </div>
          </div>
        </div>

        <div class="layout-sub">
          <h2 class="heading-circle -white">
            上位データ({{ NARFLAG ? "地方" : "中央" }}{{ raceInfo.TrackType }})
          </h2>
          <div class="inner" v-if="bestTimeInfoData.length">
            <h3 class="heading-bar">持ち時計　上位順</h3>
            <table class="table-grid -center -padding">
              <tbody>
                <tr
                  v-for="(data, index) in bestTimeInfoData.slice(0, 3)"
                  :key="index"
                >
                  <td v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`">
                    {{ data.horseNum }}
                  </td>
                  <td v-bind:class="`${data.favClass} -left`">
                    {{ data.horseName }}
                  </td>
                  <td class="-right">
                    <span>{{ data.racetrackName }} / </span>
                    <span>{{ data.bestTime }} / </span>
                    <span>{{ data.raceResult }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              data-accordion='["data-opner","data-acdtarget"]'
              class="item -nopad"
              v-if="bestTimeInfoData.length > 3"
            >
              <div class="nav-group">
                <div
                  class="btn-basic -accordion"
                  :class="{ '-active': isBestTimeOpen }"
                  @click="handleBestTimeToggle()"
                  data-opner
                >
                  すべて見る
                </div>
              </div>
              <transition
                name="topSlide"
                @before-enter="beforeEnter"
                @enter="enter"
                @before-leave="beforeLeave"
                @leave="leave"
              >
                <div
                  v-if="isBestTimeOpen"
                  class="topSlide"
                  :class="{'-active': isBestTimeOpen}"
                >
                  <table class="table-grid -center -padding">
                    <tbody>
                      <tr
                        v-for="(data, index) in bestTimeInfoData.slice(3)"
                        :key="index"
                      >
                        <td
                          v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`"
                        >
                          {{ data.horseNum }}
                        </td>
                        <td v-bind:class="`${data.favClass} -left`">
                          {{ data.horseName }}
                        </td>
                        <td class="-right">
                          <span>{{ data.racetrackName }} / </span>
                          <span>{{ data.bestTime }} / </span>
                          <span>{{ data.raceResult }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </transition>
            </div>
          </div>

          <div class="inner" v-if="furlongTimeInfoData.length">
            <h3 class="heading-bar">{{ furlongTimeInfoTitle }}</h3>
            <table class="table-grid -center -padding">
              <tbody>
                <tr
                  v-for="(data, index) in furlongTimeInfoData.slice(0, 3)"
                  :key="index"
                >
                  <td v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`">
                    {{ data.horseNum }}
                  </td>
                  <td v-bind:class="`${data.favClass} -left`">
                    {{ data.horseName }}
                  </td>
                  <td class="-right">
                    <span>{{ data.racetrackName }} / </span>
                    <span>{{ data.furlongTime }} / </span>
                    <span>{{ data.raceResult }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              data-accordion='["data-opner","data-acdtarget"]'
              class="item -nopad"
              v-if="furlongTimeInfoData.length > 3"
            >
              <div class="nav-group">
                <div
                  class="btn-basic -accordion"
                  :class="{ '-active': isFurlongTimeOpen }"
                  @click="handleFurlongTimeToggle()"
                  data-opner
                >
                  すべて見る
                </div>
              </div>
              <transition
                name="topSlide"
                @before-enter="beforeEnter"
                @enter="enter"
                @before-leave="beforeLeave"
                @leave="leave"
              >
                <div
                  v-if="isFurlongTimeOpen"
                  class="topSlide"
                  :class="{'-active': isFurlongTimeOpen}"
                >
                  <table class="table-grid -center -padding">
                    <tbody>
                      <tr
                        v-for="(data, index) in furlongTimeInfoData.slice(3)"
                        :key="index"
                      >
                        <td
                          v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`"
                        >
                          {{ data.horseNum }}
                        </td>
                        <td v-bind:class="`${data.favClass} -left`">
                          {{ data.horseName }}
                        </td>
                        <td class="-right">
                          <span>{{ data.racetrackName }} / </span>
                          <span>{{ data.furlongTime }} / </span>
                          <span>{{ data.raceResult }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </transition>
            </div>
          </div>

          <div class="inner" v-if="rotateInfoData.length">
            <h3 class="heading-bar">{{ rotateInfoTitle }}</h3>
            <table class="table-grid -center -padding">
              <tbody>
                <tr
                  v-for="(data, index) in rotateInfoData.slice(0, 3)"
                  :key="index"
                >
                  <td v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`">
                    {{ data.horseNum }}
                  </td>
                  <td v-bind:class="`${data.favClass} -left`">
                    {{ data.horseName }}
                  </td>
                  <td class="-right">
                    [{{ data.place1st }}.{{ data.place2nd }}.{{
                      data.place3rd
                    }}.{{ data.unplaced }}]
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              data-accordion='["data-opner","data-acdtarget"]'
              class="item -nopad"
              v-if="rotateInfoData.length > 3"
            >
              <div class="nav-group">
                <div
                  class="btn-basic -accordion"
                  :class="{ '-active': isRotateInfoOpen }"
                  @click="handleRotateInfoToggle()"
                  data-opner
                >
                  すべて見る
                </div>
              </div>
              <transition
                name="topSlide"
                @before-enter="beforeEnter"
                @enter="enter"
                @before-leave="beforeLeave"
                @leave="leave"
              >
                <div
                  v-if="isRotateInfoOpen"
                  class="topSlide"
                  :class="{'-active': isRotateInfoOpen}"
                >
                  <table class="table-grid -center -padding">
                    <tbody>
                      <tr
                        v-for="(data, index) in rotateInfoData.slice(3)"
                        :key="index"
                      >
                        <td
                          v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`"
                        >
                          {{ data.horseNum }}
                        </td>
                        <td v-bind:class="`${data.favClass} -left`">
                          {{ data.horseName }}
                        </td>
                        <td class="-right">
                          [{{ data.place1st }}.{{ data.place2nd }}.{{
                            data.place3rd
                          }}.{{ data.unplaced }}]
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="layout-sub">
          <h2 class="heading-circle -white">詳細データ</h2>
          <div class="inner">
            <div data-tab='["data-trigger","data-target"]'>
              <div class="container-toggle -marginbottom">
                <ul data-trigger>
                  <li
                    @click="tabChange('course')"
                    :class="{ '-current': isCurrentTab === 'course' }"
                  >
                    <span class="label">コース</span>
                  </li>
                  <li
                    @click="tabChange('jockey')"
                    :class="{ '-current': isCurrentTab === 'jockey' }"
                  >
                    <span class="label">騎手</span>
                  </li>
                  <li
                    @click="tabChange('condition')"
                    :class="{ '-current': isCurrentTab === 'condition' }"
                  >
                    <span class="label">馬場</span>
                  </li>
                  <li
                    @click="tabChange('distance')"
                    :class="{ '-current': isCurrentTab === 'distance' }"
                  >
                    <span class="label">距離</span>
                  </li>
                  <li
                    @click="tabChange('raceCourse')"
                    :class="{ '-current': isCurrentTab === 'raceCourse' }"
                  >
                    <span class="label">競馬場</span>
                  </li>
                </ul>
              </div>

              <div class="" data-target>
                <Course
                  v-if="tab === 'course'"
                  :courseStatData="courseStatData"
                  :trackTypeCdVal="raceInfo.TrackType"
                  :NARFLAG="NARFLAG"
                />
                <Jockey
                  v-if="tab === 'jockey'"
                  :jockeyInfoData="jockeyInfoData"
                />
                <Condition
                  v-if="tab === 'condition'"
                  :condition1Data="condition1Data"
                  :condition2Data="condition2Data"
                  :condition3Data="condition3Data"
                  :condition4Data="condition4Data"
                />
                <Distance
                  v-if="tab === 'distance'"
                  :distanceData="distanceData"
                  :distanceVal="raceInfo.Distance"
                />
                <RaceCourse
                  v-if="tab === 'raceCourse'"
                  :placeData="placeData"
                  :raceTrackNameVal="raceTrackCdDisplay"
                />
              </div>
              <p class="p-supplement">
                ※出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/race/style.scss" scoped></style>
