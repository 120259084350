<script setup>
import {onMounted, onServerPrefetch, watch} from "vue";
import moment from "moment";
import {fetch as fetchArticle} from "../../functions/announce/article";
import {useStoreAnnounceArticle} from "../../stores/announce-states";
import {storeToRefs} from "pinia";
import {useRoute} from "vue-router";

const store = useStoreAnnounceArticle();
const { title, content, modified } = storeToRefs(store);
const route = useRoute();

onServerPrefetch(async () => {
  await fetchArticle(route.query.id);
})

watch(route, async (from, to) => {
  document.body.className = "";
  await fetchArticle(to.query.id);
  document.body.className = "status-loaded";
})

onMounted(async () => {
  window.scrollTo(0,0);
  document.body.className = "";
  if(!store.hasArticle() || !store.isSameArticle(route.query.id)) {
    await fetchArticle(route.query.id);
  }
  document.body.className = "status-loaded";
});
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">アナウンス</h1>
    </section>

    <section class="layout-section">
      <div class="inner -pad">
        <div class="news-block">
          <div class="header">
            <h1
              class="heading-copy"
              v-if="title"
              v-html="title"
            ></h1>
            <time class="container-meta"
              >{{ moment(modified).format("YYYY.MM.DD HH:mm") }} 更新</time
            >
          </div>

          <div
            class="body"
            v-if="content"
            v-html="content"
          ></div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
