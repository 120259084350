<script setup>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";
import {BACKWORDPATH, HOST, JRA_WP_HOST, POGAPIPATH} from "../../assets/js/define";
import {getJSON, retrieveCourse, getUserId} from "../../assets/js/common";
// APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=pog-index";

let post = reactive({});
let isPog = ref(false);
onMounted(() => {
  callRetrieveCourse(function (courseId, showMarks, userId) {
    userId = userId || getUserId()
    const request =
        HOST +
        POGAPIPATH +
        "getPogUserInfo.js" +
        BACKWORDPATH +
        "?UserID=" +
        userId;
    getJSON(request, true, function (status, json) {
      isPog.value = json && json !== 1
    });
  });
  axios
    .get(API_URL)
    .then((response) => {
      Object.assign(post, response.data[0]);
    })
    .catch((error) => {
      console.log(error);
      document.body.className = "status-loaded";
    });
});

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
}
</script>
<template>
  <section class="layout-pageheader">
    <h1 v-if="post.acf" class="heading-page">{{ post.acf.year }}</h1>
    <div class="nav">
      <a v-if="isPog" href="/pog/mypage" class="btn-basic">マイページへ</a>
      <a v-else href="/pog/join" class="btn-basic">POG ユーザー登録へ</a>
    </div>
  </section>
</template>
<style lang="scss" src="../../assets/css/pog/style.scss" scoped></style>
