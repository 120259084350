<script setup>
import {ref, onMounted, watch, onServerPrefetch} from "vue";
import {
  getMenmerCourse,
  getUserAgent,
  getTopBanner,
  isSugotoku,
  PAYINGNONE,
} from "../../assets/js/common";
import {useRoute} from "vue-router";
import {storeToRefs} from "pinia";
import {fetch as fetchArticle} from "../../functions/news/article";
import {fetch as fetchList} from "../../functions/news/list";
import {useStoreNewsArticle} from "../../stores/news/article-states";
import {useStoreNewsList} from "../../stores/news/list-states";

const route = useRoute();

const joinLink = ref("")
const banner = ref("")
const loadedPage = ref(false)
const isSugotokuUser = ref(false)

const storeArticle = useStoreNewsArticle();
const { seqNo, article, snsShareLink, snsShareTitle } = storeToRefs(storeArticle);
const storeList = useStoreNewsList();
const { list } = storeToRefs(storeList);

const fetch = async () => {
  return await Promise.all([
    fetchArticle(
      route.query.FileCreateDate,
      route.query.SeqNo,
      route.query.Page
    ),
    fetchList()
  ])
}

onServerPrefetch(async () => {
  await fetch();
})

watch(route, async (from, to) => {
  document.body.className = "";
  await fetch();
  window.scrollTo(0,0);
  document.body.className = "status-loaded";
})

onMounted(async () => {
  document.body.className = "";
  loadedPage.value = false;
  if(!storeArticle.isSameArticle(
    route.query.FileCreateDate,
    route.query.SeqNo,
    route.query.Page
  )) {
    await fetch();
  }
  await pageLoad();
  window.scrollTo(0,0);
  document.body.className = "status-loaded";
  loadedPage.value = true;
})

const pageLoad = async () => {
  const isWebView = getUserAgent();
  const userCourse = getMenmerCourse();

  if (userCourse === PAYINGNONE) joinLink.value = "/member/"
  isSugotokuUser.value = isSugotoku()

  if (!isWebView && !isSugotokuUser.value) {
    banner.value = await getTopBanner()
  }
}

</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">ニュース詳細</h1>
      <router-link to="/news/" class="btn-basic">ニュース一覧</router-link>
    </section>

    <div v-if="banner" v-html="banner"></div>

    <section class="layout-section" v-if="article">
      <div class="inner">
        <div class="news-block">
          <div class="header">
            <h1 class="heading-copy">
              {{ article.Headline }}
            </h1>
            <time class="container-meta">
              {{ article.AnnouncementTime }} 更新
            </time>
            <ul class="news-sns" v-if="!isSugotokuUser">
              <li>
                <a :href="`https://www.facebook.com/sharer/sharer.php?u=${snsShareLink}&t=${snsShareTitle}`"
                   target="_blank">
                  <img src="../../assets/images/common/sns/c/f.svg" alt="facebook"/>
                </a>
              </li>
              <li>
                <a :href="`https://twitter.com/intent/tweet?url=${snsShareLink}&text=${snsShareTitle}&via=winkeiba_dm&hashtags=keiba,jra`"
                   target="_blank">
                  <img src="../../assets/images/common/sns/c/t.svg" alt="twitter"/>
                </a>
              </li>
              <li>
                <a :href="`https://b.hatena.ne.jp/add?mode=confirm&url=${snsShareLink}&title=${snsShareTitle}`"
                   target="_blank">
                  <img src="../../assets/images/common/sns/c/h.svg" alt="hatena blog"/>
                </a>
              </li>
              <li>
                <a :href="`https://line.naver.jp/R/msg/text/?${snsShareTitle} ${snsShareLink}`"
                   target="_blank">
                  <img src="../../assets/images/common/sns/c/l.svg" alt="line"/>
                </a>
              </li>
            </ul>
          </div>

          <figure class="container-figure" v-if="article.Path">
            <div class="thumb">
              <img :src="article.Path" alt="" />

              <figcaption class="caption">
                {{ article.ImageDescription }}
              </figcaption>
            </div>
          </figure>

          <div class="body" v-html="article.Contents"></div>
          <div class="from" v-if="article.Publisher">
            {{ article.Publisher }}
          </div>
          <div class="container-registmember" v-if="joinLink">
            <p class="copy">
              今なら予想に役立つ便利機能が使い放題の<br />プレミアムコースが無料で試せる！
            </p>
            <router-link
              :to="joinLink"
              target="_blank"
              class="btn-basic -wide -center -window">
              お試し無料はこちら !
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <p id="news_text" v-else-if="loadedPage">
      ニュースの詳細が見つかりませんでした。
    </p>

    <section v-if="list.length" class="layout-section -bordertop">
      <ul class="list-news">
        <li class="item" v-for="data of list" :key="data.index">
          <div class="summary">
            <router-link :to="data.urlLink" class="title">
              {{ data.Headline }}
            </router-link>
            <time class="caption">
              {{ data.AnnouncementTime }} 更新
            </time>
          </div>
          <div class="thumb">
            <img v-if="data.Path"
                 src="../../assets/images/common/blank_square.png"
                 alt=""
                 :style="{ backgroundImage: 'url(' + data.Path + ')' }"/>
          </div>
        </li>
      </ul>
    </section>
    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/news/style.scss" scoped></style>
