<script setup>
import LoginModal from "./LoginModal.vue";
</script>

<template>
  <header class="layout-header" style="width: 100%; max-width: 808px">
    <div class="inner" v-if="loading">
      <div class="winkeiba">
        <router-link :to="topPath"
          ><img src="../assets/images/common/logo.svg" alt="WINKEIBA"
        /></router-link>
      </div>
      <nav class="nav-global">
        <ul class="place">
          <li :class="isJra ? '-current' : ''">
            <a :href="jraPath ?? '/'">JRA</a>
          </li>
          <li :class="!isJra ? '-current' : ''">
            <a :href="narPath ?? '/'">地方</a>
          </li>
        </ul>
        <ul class="main">
          <li :class="isCurrentPage('/race') ? '-current' : ''">
            <router-link class="btn" :to="!isJra ? '/race/' : '/race/list'"
              ><img src="../assets/images/common/gnav/race.svg" /><span
                class="label"
                >レース</span
              ></router-link
            >
          </li>
          <li :class="isCurrentPage('/data') ? '-current' : ''">
            <router-link class="btn" to="/data/"
              ><img src="../assets/images/common/gnav/search_g.svg" /><span
                class="label"
                >データ</span
              ></router-link
            >
          </li>
          <li v-if="!isNonMember" :class="isCurrentPage('/fav') ? '-current' : ''">
            <router-link class="btn" to="/fav/index">
              <img src="../assets/images/common/gnav/fav.svg" />
              <span class="label">お気に入り</span>
            </router-link>
          </li>
          <li v-else-if="isMailMember">
            <div class="btn">
              <img src="../assets/images/common/gnav/account.svg">
              <span class="label">メール会員</span>
            </div>
          </li>
          <li :class="{ '-login -nav': isLoginModal }" v-else>
            <a
              v-if="!isWebView"
              class="btn"
              @click="isLoginModalFunc()"
            >
              <img src="../assets/images/common/gnav/login.svg" /><span
                class="label"
                >ログイン</span
              >
            </a>
            <a v-else @click.prevent @click="callDart()" class="btn">
              <img src="../assets/images/common/gnav/login.svg" /><span
                class="label"
                >ログイン</span
              >
            </a>
            <LoginModal v-if="isLoginModal" />
          </li>
          <li :class="menuOpenFlg === true ? '-nav' : ''">
            <div class="btn" @click="menuOpen()">
              <div class="nav-burger">
                <span></span><span></span><span></span>
              </div>
            </div>
            <div class="nav-mega" @click="handleMenuClick">
              <h2 class="subtitle">レース</h2>
              <ul class="nav-link">
                <li class="-raceinfo">
                  <router-link to="/race/">レース情報</router-link>
                </li>
                <li class="-racecalendar">
                  <router-link to="/race/calendar">レースカレンダー</router-link>
                </li>
                <li v-if="isJra" class="-channel">
                  <router-link to="/program">番組表</router-link>
                </li>
                <li class="-importantrace">
                  <router-link to="/race/importantrace">重賞レース</router-link>
                </li>
                <li class="-payout">
                  <router-link to="/race/odds/payout">払戻金一覧</router-link>
                </li>
                <li v-if="isJra" class="-win5">
                  <router-link
                    :to="'/win5?YearMonth=' + year + ('00' + month).slice(-2)"
                    >WIN5</router-link
                  >
                </li>
              </ul>

              <h2 class="subtitle">データベース</h2>
              <ul class="nav-link">
                <li class="-searchindex"><router-link to="/data/">検索INDEX</router-link></li>
                <li v-if="isJra" class="-horselist">
                  <router-link to="/horselist/">JRA登録馬一覧</router-link>
                </li>
              </ul>

              <h2 class="subtitle">コンテンツ</h2>
              <ul class="nav-link">
                <li class="-news"><router-link to="/news/">ニュース</router-link></li>
                <li class="-leading"><router-link to="/leading/">リーディング</router-link></li>
                <li v-if="isJra" class="-earningsRanking">
                  <router-link :to="isPremium ? '/earningsRanking' : memberUrl ">2歳・3歳収得賞金ランキング</router-link>
                </li>
                <li v-if="isJra" class="-contents">
                  <router-link to="/feature/">G1特集</router-link>
                </li>
                <li v-if="isJra" class="-sale">
                  <router-link to="/sales/">セレクトセール特集</router-link>
                </li>
                <li v-if="isJra && !isSugotoku" class="-column">
                  <router-link :to="isPremium ? '/column/sumii' : memberUrl">角居勝彦氏の独占コラム</router-link>
                </li>
                <li v-if="isJra" class="-beginner">
                  <router-link to="/page/?slug=beginner/">初心者向け競馬入門</router-link>
                </li>
              </ul>

              <h2 class="subtitle">{{ isSugotoku ? 'ユーザメニュー' : '会員メニュー'}}</h2>
              <ul class="nav-link">
                <li class="-favorite">
                  <router-link :to="isNonMember ? memberUrl : '/fav/index' ">お気に入り</router-link>
                </li>
                <li v-if="isJra && !isSugotoku" class="-pog">
                  <router-link :to="isNonMember ? memberUrl : '/pog/mypage' ">POG</router-link>
                </li>
                <li class="-collection">
                  <router-link :to="isNonMember ? memberUrl : '/collection' ">馬券コレクション</router-link>
                </li>
                <li class="-anniversary" v-if="!isSugotoku">
                  <router-link :to="isNonMember ? memberUrl :  '/purchase/anniversary' ">記念日馬券</router-link>
                </li>
                <li class="-balance">
                  <router-link :to="isNonMember ? memberUrl : '/balance' ">収支管理</router-link>
                </li>
                <li class="-purchase">
                  <router-link to="/purchase/" target="_blank">購入予定一覧</router-link>
                </li>
                <li class="-custom">
                  <router-link to="/member/customize_odds">オッズデフォルト表示設定</router-link>
                </li>
                <li class="-dpoint" v-if="isSugotoku">
                  <router-link to="/dpoint/coupon_list">これまでの応募履歴</router-link>
                </li>
                <li class="-custom">
                  <router-link to="/member/customize_card">出馬表カスタマイズ</router-link>
                </li>
                <li v-if="isJra && !isSugotoku" class="-member">
                  <router-link :to="isNonMember ? memberUrl : '/member/contract'">会員情報 設定</router-link>
                </li>
                <li v-if="isJra && !isSugotoku" class="-mail">
                  <router-link :to="isNonMember ? memberUrl : '/support/mail/setting' ">メール配信 設定</router-link>
                </li>
              </ul>
              <h2 class="subtitle" v-if="!isWebView || !isSugotoku">SNS</h2>
              <ul class="nav-link" v-if="!isWebView || !isSugotoku">
                <li class="-twitter">
                  <router-link to="/twitter/">Xアカウント一覧</router-link>
                </li>
              </ul>

              <h2 class="subtitle" v-if="!isSugotoku">サポート</h2>
              <ul class="nav-link" v-if="!isSugotoku">
                <li class="-env"><router-link to="/support/device/">推奨環境</router-link></li>
                <li class="-faq"><router-link to="/support/faq/">よくあるご質問</router-link></li>
                <li class="-howto"><router-link to="/support/help/">ご利用方法</router-link></li>
                <li class="-contact">
                  <router-link to="/support/inquiry/">お問い合わせ</router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import moment from "moment";
import {
  getMenmerCourse,
  PAYINGSUGOTOKU_NONE,
  PAYINGNONE,
  PAYINGECONOMY,
  PAYINGSUGOTOKU,
  PAYINGAPPPASS,
  getUserAgent,
  isNar,
  getJSON, getDefaultPurchase,
} from "../assets/js/common";
import {JRA_HOST, NAR_HOST, HOST, PATH, BACKWORDPATH} from "../assets/js/define";
import { setData } from "../functions/topInit";
import axios from "axios";
import { useRoute } from "vue-router";

export default {
  name: "HeaderComp",
  props: {
    msg: String,
  },
  data() {
    return {
      loginOpenFlg: false,
      menuOpenFlg: false,
      mypageOpenFlg: false,
      userCourse: "",
      isPremium: false,
      isWebView: false,
      isNonMember: false,
      isEconomy: false,
      dor: moment().format("YYYYMMDD"),
      path: this.$route.path,
      year: moment().format("YYYY"),
      month: moment().format("MM"),
      isLoginModal: false,
      isJra: null,
      topPath: "/",
      jraPath: JRA_HOST,
      narPath: NAR_HOST,
      loading: false,
      isMailMember: false,
      isSugotoku: false,
      isLoginPage: false,
      route:  useRoute(),
      memberUrl: "",
    };
  },
  mounted() {
    // ログインページではログインアイコンを表示しない
    this.isLoginPage = this.$route.path === "/login";
    const request =
    HOST +
    PATH +
      "getLoginInfo.js" +
      BACKWORDPATH +
      "?NarFlag=0";
    getJSON(request, false, (status, json) => {
      setData(status, json);
      this.isWebView = getUserAgent();
      this.isJra = isNar() === 0;
      this.userCourse = getMenmerCourse();
      this.isMailMember = json.courseId === "0" && json.payType === 1
      this.isPremium =
        this.userCourse !== PAYINGNONE && this.userCourse !== PAYINGSUGOTOKU_NONE && this.userCourse !== PAYINGECONOMY;
      this.isSugotoku = this.userCourse === PAYINGSUGOTOKU || this.userCourse === PAYINGSUGOTOKU_NONE
      if (this.isJra) {
        if (this.isSugotoku) {
          // スゴ得日次アクセス保存
          if(json.userId !== undefined) {
            const dailyAccess4sgUrl = `${HOST}${PATH}dailyAccess4sg.js${BACKWORDPATH}/` +
              `?userId=${encodeURIComponent(json.userId)}` +
              `&device=スゴ得アプリ` +
              `&userAgent=${encodeURIComponent(json.userAgent ?? null)}`;
            axios.get(dailyAccess4sgUrl).then();
          }
        }
      }
      //JRAのみ
      if (
        this.userCourse === PAYINGNONE ||
        this.userCourse === PAYINGSUGOTOKU_NONE
      ) {
        this.isNonMember = true;
        this.memberUrl = '/member'
      } else if (this.userCourse === PAYINGECONOMY) {
        this.isEconomy = true;
        this.memberUrl = '/member/join_economy'
      }
      //ヘッダーアイコン押下時のページ遷移
      if (this.$route.path === "/index_sugotoku") {
        this.topPath = "/index_sugotoku";
      } else {
        this.topPath = "/";
      }
      this.loading = true
    })
  },
  destroyed() {
    window.removeEventListener("flutterInAppWebViewPlatformReady", {});
  },
  watch: {
    route: {
      handler(to) {
        this.path = to.path
        if(this.menuOpenFlg) this.menuOpenFlg = false
      }, deep: true , immediate : true
    }
  },
  methods: {
    callDart() {
      window.flutter_inappwebview.callHandler("open_login_modal");
    },
    isLoginModalFunc() {
      this.isLoginModal = !this.isLoginModal && !this.isLoginPage;
    },
    menuOpen() {
      if (this.loginOpenFlg) {
        this.loginOpenFlg = false;
      }
      this.menuOpenFlg = !this.menuOpenFlg
    },
    isCurrentPage(hoge) {
      const reg = new RegExp("^" + hoge);
      return reg.test(this.path);
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
    getParam(name) {
      let url = window.location.href
      name = name.replace(/[\[\]]/g, '\\$&')
      let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
      let results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ''
      return decodeURIComponent(results[2].replace(/\+/g, ' '))
    },
    handleMenuClick(event) {
      const clickedElement = event.target.closest('a');

      if (clickedElement) {
        const href = clickedElement.getAttribute('href');

        if (href.includes(this.$route.path)) {
          this.menuOpenFlg = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.topSlide {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}
</style>
