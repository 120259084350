import { createSSRApp } from "vue";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
import { createWebHistory } from "vue-router";
import { createAppRouter } from "./router/router.js";
import { QueryClient, hydrate, VUE_QUERY_CLIENT } from "vue-query";
import VueGtag from "vue-gtag";
import App from "./App.vue";
import { createPinia } from "pinia";

import "./assets/css/style.scss";

const start = async () => {
  const app = createSSRApp(App);

  const pinia = createPinia()
  app.use(pinia);

  // pinia hydrate
  const piniaState = JSON.parse(
    document.querySelector("[data-pinia-state]").textContent
  );
  if(piniaState) {
    pinia.state.value = piniaState;
  }

  const router = createAppRouter({ history: createWebHistory(), hostname: `${location.hostname}/`, isClient: true });
  app.use(router);

  const metaManager = createMetaManager(false, {
    description: {
      tag: "meta",
    },
    keywords: {
      tag: "meta",
    }
  });
  app.use(metaManager);
  app.use(metaPlugin);

  // query hydrate
  const queryState = JSON.parse(
    document.querySelector("[data-query-state]").textContent
  );
  const client = new QueryClient();
  hydrate(client, queryState);

  client.mount();
  app.provide(VUE_QUERY_CLIENT, client);
  
  // Googleアナリティクス
  let tagOptions = ''
  if(process.env.APP_MODE === 'production') {
    tagOptions = {
      pageTrackerTemplate(to) {
        return {
          page_path: to.href,
          page_location: to.href,
        }
      },
      includes: [
        { id: process.env.ANALYTICS_ID_2 },
      ],
      config: {
        id: process.env.ANALYTICS_ID_1,
        linker: {
          'domains': ['www.winkeiba.jp', 'www.nar.winkeiba.jp']
        }
      }
    };
  } else if(process.env.APP_MODE === 'staging'){
    tagOptions =  {
      pageTrackerTemplate(to) {
        return {
          page_path: to.href,
          page_location: to.href,
          page_host: to.host,
          page_hostname: to.hostname
        }
      },
      includes: [
        { id: process.env.ANALYTICS_ID_4 },
      ],
      config: {
        id: process.env.ANALYTICS_ID_3,
        linker: {
          'domains': ['staging-jra.winkeiba.jp', 'staging.nar.winkeiba.jp']
        }
      }
    };
  }
  if(tagOptions) {
    app.use(VueGtag, tagOptions, router);
  }
  
  await router.isReady();

  app.mount("#app");
};

start();
