import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreFeatureArticle = defineStore('feature-article', () => {
  const articleSlug = ref('');
  const title = ref('');
  const content = ref('');
  const hasArticle = () => {
    return title.value || content.value
  }
  const isSameArticle = (slug) => {
    return slug === articleSlug.value
  }

  return { articleSlug, title, content, hasArticle, isSameArticle }
})

export default {
  useStoreFeatureArticle,
}