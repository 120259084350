<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">収支管理</h1>
    </section>

    <section class="layout-section">
      <div>
        <div class="nav-tab -fit">
          <ul>
            <li class="-current">
              <a class="label" href="/balance/">年間別収支データ</a>
            </li>
            <li>
              <a class="label" href="/balance/category/">カテゴリ別データ</a>
            </li>
          </ul>
        </div>
        <div class="container-tabtarget">
          <div class="layout-title">
            <h2 class="heading-circle -white">{{ headData.title }}</h2>
            <div class="nav">
              <a href="/balance/purchase_year/" class="btn-basic"
                >年別収支一覧</a
              >
            </div>
          </div>

          <div class="inner">
            <div class="layout-sub">
              <h3 class="heading-bar">月別収支合計</h3>
              <table class="table-cell">
                <tbody>
                  <tr>
                    <th>購入額</th>
                    <td class="amount">{{ headData.purchase ?? 0 }}円</td>
                    <th>払戻額</th>
                    <td class="amount">{{ headData.refund ?? 0 }}円</td>
                  </tr>
                  <tr>
                    <th>回収率</th>
                    <td class="per">{{ headData.recovery ?? 0 }}%</td>
                    <th>返還金</th>
                    <td class="amount">{{ headData.returned ?? 0 }}円</td>
                  </tr>
                  <tr>
                    <th>収支合計</th>
                    <td
                      v-bind:class="`amount ${
                        parseInt(headData.sum) > 0 ? '-plus' : '-minus'
                      }`"
                      colspan="3"
                    >
                      {{ headData.sum ?? 0 }}円
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="inner">
            <div class="layout-sub">
              <h3 class="heading-bar">日別収支</h3>
              <table class="table-cell">
                <tbody v-for="(data, index) in dayData" :key="index">
                  <tr>
                    <th colspan="4" class="-left header">
                      <div class="date">
                        <span v-html="data.headTitle"></span>
                        <a v-bind:href="`${data.hrefTag}`">
                          <i class="arrow"></i>
                        </a>
                      </div>
                      <span class="form-checkbox">
                        <label>
                          <input
                            type="checkbox"
                            v-model="delChecked"
                            v-bind:value="`${data.delId}`"
                            name=""
                            v-bind:id="`${data.delId}`"
                          /><i></i>
                        </label>
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>購入額</th>
                    <td class="amount">{{ data.spend ?? 0 }}円</td>
                    <th>払戻額</th>
                    <td class="amount">{{ data.refund ?? 0 }}円</td>
                  </tr>
                  <tr>
                    <th>回収率</th>
                    <td class="per">{{ data.rate ?? 0 }}%</td>
                    <th>返還金</th>
                    <td class="amount">{{ data.returned ?? 0 }}円</td>
                  </tr>
                  <tr>
                    <th>収支合計</th>
                    <td
                      v-bind:class="`amount ${
                        parseInt(data.sum) > 0 ? '-plus' : '-minus'
                      }`"
                      colspan="3"
                    >
                      {{ parseInt(data.sum) > 0 ? '+' + data.sum : data.sum }}円
                    </td>
                  </tr>
                </tbody>
              </table>

              <nav class="nav-group -left">
                <a
                  v-on:click="deleteBalanceDaily(delChecked)"
                  class="btn-basic -delete"
                  >削除</a
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<script>
import {
  PAYINGSUGOTOKU_NONE,
  PAYINGSUGOTOKU,
  SUCCESS,
  PAYINGNONE,
  getUserId,
} from "../../assets/js/common";
import {
  getJSON,
  dayWeek,
  retrieveCourse,
  replaceComma,
  recoveryRate,
  getUrlVars,
  sumTotalEx,
  isNar,
} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH, JOINPATH } from "../../assets/js/define";

export default {
  name: "PurchaseMonthly",
  data() {
    return {
      //パラメータ(固定)
      HOST: HOST,
      PATH: PATH,

      headData: {},
      dayData: [],
      delChecked: [],
      courseId: '',
      userId: '',
    };
  },
  methods: {
    /******************************************************************
     *　日別収支削除
     ******************************************************************/
    deleteBalanceDaily(dor) {
      this.deleteCore("3", dor);
    },
    deleteCore(type, dor) {
      document.body.className = "";

      const delList = dor.join("_");
      if (delList.length == "") {
        alert("削除項目がありません。");
        document.body.className = "status-loaded";
        return;
      }

      let request = "";
      if (this.courseId == PAYINGSUGOTOKU || this.courseId == PAYINGSUGOTOKU_NONE) {
        request =
          HOST +
          PATH +
          "Balance/deleteBalance4sg.js" +
          BACKWORDPATH +
          "?userId=" +
          encodeURIComponent(this.userId) +
          "&type=" +
          type +
          "&dors=" +
          delList +
          "&narFlag=" +
          isNar();
      } else {
        request =
          HOST +
          PATH +
          "Balance/deleteBalance.js" +
          BACKWORDPATH +
          "?type=" +
          type +
          "&dors=" +
          delList +
          "&narFlag=" +
          isNar();
      }
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          location.reload();
        }
      });

      document.body.className = "status-loaded";
    },
  },
  mounted() {
    let self = this;

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none");
      }
    }

    callRetrieveCourse(function (courseId, marks, userId) {
      if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
        location.href = HOST + JOINPATH;
      } else {
        self.courseId = courseId
        self.userId = userId || getUserId();
        // パラメータ取得
        let params = getUrlVars();
        let date = new Date();
        let argyear = date.getFullYear();
        let argmonth = date.getMonth() + 1;

        if (params.year) argyear = params.year;
        if (params.month) argmonth = params.month;

        getBalancePurchase(argyear, argmonth);
      }
    });

    /******************************************************************
     *　収支一覧取得
     ******************************************************************/
    function getBalancePurchase(argyear, argmonth, callback) {
      let request = "";
      if (self.courseId == PAYINGSUGOTOKU || self.courseId == PAYINGSUGOTOKU_NONE) {
        request =
          HOST +
          PATH +
          "Balance/getBalanceList4sg.js" +
          BACKWORDPATH +
          "?userId=" +
          encodeURIComponent(self.userId) +
          "&year=" +
          argyear +
          "&month=" +
          argmonth +
          "&narFlag=" +
          isNar();
      } else {
        request =
          HOST +
          PATH +
          "Balance/getBalanceList.js" +
          BACKWORDPATH +
          "?year=" +
          argyear +
          "&month=" +
          argmonth +
          "&narFlag=" +
          isNar();
      }
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          try {
            if (json != undefined && json != null && json.length > 0) {
              let dayData = [];
              let headData = {};
              for (let i = 0; i < json.length; i++) {
                let data = {};

                let type = json[i].Type;
                let year = json[i].Year;
                let month = json[i].Month;
                let dor = json[i].DOR;
                let spend = parseInt(json[i].Spend) ?? 0;
                let refund = parseInt(json[i].Refund) ?? 0;
                let returned = parseInt(json[i].Returned) ?? 0;
                let rowNum = json[i].RowNum;
                let unfixedRowNum = json[i].UnfixedRowNum;
                let delId = "";
                let headTitle = "";
                let hrefTag = "";

                if (type == 1) {
                  //月の場合
                  if (argyear == year && argmonth == month) {
                    month = month.charAt(0) === '0' ? month.substring(1) : month;
                    headData.title = year + "年 " + month + "月";
                    headData.purchase = replaceComma(spend);
                    headData.refund = replaceComma(refund);
                    headData.recovery = replaceComma(
                      recoveryRate(spend, refund)
                    );
                    headData.sum = sumTotalEx(spend, refund, returned) > 0 ? '+' : '';
                    headData.sum += replaceComma(
                      sumTotalEx(spend, refund, returned)
                    );

                    self.headData = headData;
                  }
                } else if (type == 2) {
                  //日の場合
                  if (argyear == year && argmonth == month) {
                    delId = dor;
                    headTitle =
                      parseInt(month, 10) +
                      "月" +
                      parseInt(dor.substr(6, 2), 10) +
                      "日（" +
                      dayWeek(year, month, dor.substr(6, 2)) +
                      "）";

                    headTitle +=
                      rowNum + "件" + "（未確定：" + unfixedRowNum + "件）";
                    hrefTag = HOST + "balance/purchase_daily?dor=" + dor;

                    data.delId = delId;
                    data.hrefTag = hrefTag;
                    data.headTitle = headTitle;
                    data.spend = replaceComma(spend);
                    data.refund = replaceComma(refund);
                    data.rate = replaceComma(recoveryRate(spend, refund));
                    data.returned = replaceComma(returned);
                    data.sum = replaceComma(
                      sumTotalEx(spend, refund, returned)
                    );

                    dayData.push(data);
                  }
                }
              }
              self.dayData = dayData;
            }
            if (callback != undefined) {
              callback();
            }
            if (!Object.keys(self.headData).length) {
              let headData = {};
              argmonth = argmonth.charAt(0) === '0' ? argmonth.substring(1) : argmonth;
              headData.title = argyear + "年 " + argmonth + "月";
              self.headData = headData;
            }
          } catch (e) {
            console.log("getBalancePurchase : " + e);
          }
        }
        document.body.className = "status-loaded";
      });
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/balance/style.scss" scoped></style>
