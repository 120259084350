<script setup>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";
import {JRA_WP_HOST} from "../../../assets/js/define";

//本番用 APIURL
const API_URL = JRA_WP_HOST + "/wp-json/wp/v2/pages?slug=help";

let post = reactive({});
onMounted(() => {
  document.body.className = "";

  axios
    .get(API_URL)
    .then((response) => {
      Object.assign(post, response.data[0]);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      console.log(error);
      document.body.className = "status-loaded";
    });
});
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">ご利用方法</h1>
    </section>

    <div
      v-if="Object.keys(post).length"
      class="body"
      v-html="post.content.rendered"
    ></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
