import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreCommon = defineStore('common', () => {
  const host = ref('');
  const isNarSite = ref(false);
  const defaultTitle = ref('');

  return { host, isNarSite, defaultTitle }
})


export default {
  useStoreCommon,
}