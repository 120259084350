<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from 'vue-router'
import {
    getJSON,
    getUserId,
    PAYINGECONOMY,
    PAYINGPACK03,
    PAYINGPACK06,
    PAYINGPACK12,
    PAYINGPREMIUM,
    PAYINGSUGOTOKU,
    syncRetrieveCourse,
    getCustomizeOddsType
} from "../../assets/js/common";
import { BACKWORDPATH, HOST, JOINECPATH, JOINPATH, PATH } from "../../assets/js/define";

const router = useRouter()

// ラジオボタン表示Item
const radioItems = [
    { name: '単勝', value: '1' },
    { name: '複勝', value: '2' },
    { name: '枠連', value: '3' },
    { name: '馬連', value: '4' },
    { name: '馬単', value: '5' },
    { name: 'ワイド', value: '6' },
    { name: '3連複', value: '7' },
    { name: '3連単', value: '8' }
]
// ラジオボタン選択Item
const pickCustomizeOddsType = ref('1');
// ユーザID
const userId = ref('');

// コース
const mCourseVal = ref('');

// メッセージポップ表示
const isMessagePop = ref(false);

onMounted(async () => {
    await callRetrieveCourse(function (courseId) {
        mCourseVal.value = courseId;
    });

    async function callRetrieveCourse(callback) {
        try {
            await syncRetrieveCourse(callback);
        } catch (e) {
            callback("none", false, '');
        }
    }
    userId.value = getUserId()

    if (
        (
            mCourseVal.value === PAYINGPREMIUM ||
            mCourseVal.value === PAYINGSUGOTOKU ||
            mCourseVal.value === PAYINGPACK03 ||
            mCourseVal.value === PAYINGPACK06 ||
            mCourseVal.value === PAYINGPACK12) &&
        userId.value) {
        pickCustomizeOddsType.value = await getCustomizeOddsType(userId.value);
    } else if (mCourseVal.value === PAYINGECONOMY) {
        await router.push('/' + JOINECPATH)
    } else {
        await router.push('/' + JOINPATH)
    }
})

/**
 * 決定ボタン実行
 */
const execDecision = async () => {
    const request = HOST + PATH + "Member/setCustomizeOddsType.js" + BACKWORDPATH +
        "?userId=" +
        encodeURIComponent(userId.value) +
        "&customizeOddsType=" +
        pickCustomizeOddsType.value;

    await getJSON(request, true, function (status, json) {
        if (json[0] === 0) {
            isMessagePop.value = true
            setTimeout(() => { isMessagePop.value = false }, 3000)
        } else if (json[0] === 1) {
            alert('変更に失敗しました')
        }
    });
}

</script>

<template>
    <main class="layout-main">
        <section class="layout-pageheader -default">
            <h1 class="heading-page">オッズデフォルト表示設定</h1>
        </section>
        <section class="layout-section">
            <div class="inner">
                <div class="container-customcard">
                    <div class="system-message -pop" v-if="isMessagePop">変更しました</div>
                    <p>オッズのデフォルト表示を選択してください。</p>
                    <div class="form">
                        <ul class="list">
                            <template v-for="item of radioItems">
                                <li>
                                    <div class="form-radiogroup">
                                        <label>
                                            <input type="radio" :value="item.value" v-model="pickCustomizeOddsType"><span>{{ item.name }}</span>
                                        </label>
                                    </div>
                                </li>
                            </template>
                        </ul>
                        <div class="form-group">
                            <span class="btn-basic" @click="execDecision">決定</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>


<style scoped lang="scss">
.container-customcard .form {
    width: 220px;
    margin: 0 auto;
    padding: 0 0 30px 0;

    .form-group .btn-basic {
        width: 200px;
    }
}
</style>
