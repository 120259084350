<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getJSON,
  getUserId,
  changeFavoriteFromCookie,
  SUCCESS,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  FJOCKEY,
  REMOVE,
  PAYINGNONE,
  retrieveCourse,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  JOINPATH
} from "../../assets/js/define";

import useStore from "../../stores/favorite-states"
import FavTabs from "../../components/fav/FavTabs.vue";
import AutoRegistAbout from "../../components/utils/AutoRegistAbout.vue";
import {storeToRefs} from "pinia";

const currentTab = "jockey";

// 1ページに表示する要素数
const mMax = 30; //30

// 結果を反映するHTMLオブジェクト群
let mCheckNum = 0; // API使用回数
let mTasks = [];
let mFavApiFailed = false; // API終了フラグ

// ユーザID
let mUserId = "";
let mInfo = null;

let favContentListAll = ref([]);
let favContentList = ref([]);
let favCheckList = ref([]);
let showFavList = ref([]);

// もっと見る表示フラグ
const moreFlag = ref(false);
//削除ボタン表示フラグ
const favFlag = ref(false);
const isSugotoku = ref(false);
const loadedPage = ref(false);

const sortType = ref(0);
const activeSortId = ref("sort_name");
const moreLength = ref(0);
const updateDate = ref("");
const narFlag = ref(false);

const store = useStore.useStoreFavoriteJockey();
const {displayType, count} = storeToRefs(store);
const {resetList} = store;

onMounted(() => {
  callRetrieveCourse(function (courseId, showMarks, userId) {
    narFlag.value = isNar() === 1
    if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
      location.href = HOST + JOINPATH;
    } else {
      mUserId = userId || getUserId();
      isSugotoku.value = courseId === PAYINGSUGOTOKU || courseId === PAYINGSUGOTOKU_NONE
      doInit();
      document.body.className = "";
    }
  });
});

const callRetrieveCourse = function (callback) {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
}

//自動登録機能モーダルの表示切り替え
const modalOpen = ref(false);
const thisModalUpdate = () => {
  modalOpen.value = true;
};

const doInit = () => {
  //データリセット
  moreLength.value = 0;
  favFlag.value = false;
  moreFlag.value = false;
  loadedPage.value = false;

  favContentListAll.value.splice(0);
  favContentList.value.splice(0);
  favCheckList.value.splice(0);
  showFavList.value.splice(0);

  // ユーザID取得
  let request =
      HOST +
      PATH +
      "MyFavorite/listMyFavoriteJockey.js" +
      BACKWORDPATH +
      "?" +
      "userId=" +
      encodeURIComponent(mUserId) +
      "&sort=" +
      sortType.value +
      "&narFlag=" +
      isNar();
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      favContentListAll.value.splice(0);
      setData(json);
    }
    document.body.className = "status-loaded";
    loadedPage.value = true;
  });
};

// 表示用にデータを加工してオブジェクトに格納する
const setData = (json) => {
  try {
    if (json != null) {
      updateDate.value =json[0].LeadingUpdateDate

      for (let i = 0; i < json.length; i++) {
        let favList = new Object();
        // 名前
        favList.JockeyName = json[i].JockeyName;
        favList.JockeyCd = json[i].JockeyCd;

        //年齢
        let birthYear = String(json[i].DOB).substring(0, 4);
        let birthMonth = String(json[i].DOB).substring(4, 6);
        let birthDay = String(json[i].DOB).substring(6, 8);
        let birthDate = {
          birthYear: birthYear,
          birthMonth: birthMonth,
          birthDay: birthDay,
        };

        function getAge(birthDate) {
          // 引退騎手はソートの場合、0と同様のため
          if (json[i].RetireDiv === "引退") { return 0 }

          // 今日
          let today = new Date();
          // 今年の誕生日
          let thisYearBirthDay = new Date(
            today.getFullYear(),
            birthDate.birthMonth,
            birthDate.birthMonth - 1,
            today.birthDay
          );
          //年齢
          let age = today.getFullYear() - birthYear;
          if (today < thisYearBirthDay) {
            //今年まだ誕生日が来ていない
            age--;
          }
          return age;
        }

        favList.Age = getAge(birthDate);

        //所属
        const EastWestDiv = json[i].EastWestDiv; //所属No.
        const innerEastWestDiv ={
          '0' : '',
          '1' : '関東',
          '2' : '関西',
          '3' : '招待',
          '4' : '無所属',
          '5' : '北海道',
          '6' : '岩手',
          '7' : '上山',
          '8' : '新潟',
          '9' : '金沢',
          '10' : '愛知',
          '11' : '笠松',
          '12' : '兵庫',
          '13' : '福山',
          '14' : '益田',
          '15' : '高知',
          '16' : '中津',
          '17' : '佐賀',
          '18' : '荒尾',
          '19' : 'ばんえい',
          '20' : '宇都宮',
          '21' : '栃木',
          '22' : '高崎',
          '23' : '大井',
          '24' : '川崎',
          '25' : '浦和',
          '26' : '船橋',
          '27' : '外国'
        }

        favList.Affiliation = innerEastWestDiv[EastWestDiv];

        //勝利数を本年（通算）
        favList.CareerWins = json[i].CareerWins;
        favList.CurrentYearRaceWins = json[i].CurrentYearRaceWins;

        //現役か引退か
        let retireDiv = json[i].RetireDiv;
        if (retireDiv === "現役") {
          retireDiv = 0;
        } else if (retireDiv === "引退") {
          retireDiv = 1;
        }
        favList.RetireDiv = retireDiv;

        favContentListAll.value.push(favList);
        favCheckList.value.push(false);
      }

      showContents(displayType.value);
    }
  } catch (e) {}
};

//「全ての騎手」「現役騎手」「引退騎手」イベント
const switchShowContents = (event) => {
  try {
    displayType.value = event.target.value;
    showContents(displayType.value);
  } catch (e) {
    console.log(e);
  }
  return false;
};

//コンテンツを表示する
const showContents = (loadingId) => {
  favContentList.value.splice(0);
  showFavList.value.splice(0);
  const favlength = favContentListAll.value.length;
  if (Number(loadingId) === 2) {
    //全ての騎手の表示
    for (let i = 0; i < favlength; i++) {
      favContentList.value.push(favContentListAll.value[i]);
    }
  } else {
    //現役騎手・引退騎手の表示
    for (let i = 0; i < favlength; i++) {
      if (favContentListAll.value[i].RetireDiv === Number(loadingId)) {
        favContentList.value.push(favContentListAll.value[i]);
      }
    }
  }
  setFavList();
};

//表示用リストを描画
const setFavList = () => {
  showFavList.value = favContentList.value.slice(0, count.value);
  moreFlag.value = showFavList.value.length !== favContentList.value.length;
};

//もっと見るボタン押下
const showMore = () => {
  count.value += 30;
  setFavList();
}

//ソートを変更
const changeSort = (id) => {
  document.body.className = "";
  loadedPage.value = false;

  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_name") {
      if (sortType.value === 0) {
        sortType.value = 1;
      } else {
        sortType.value = 0;
      }
      activeSortId.value = "sort_name";
    } else if (id === "sort_age") {
      if (sortType.value === 3) {
        sortType.value = 2;
      } else {
        sortType.value = 3;
      }
      activeSortId.value = "sort_age";
    } else if (id === "sort_sention") {
      if (sortType.value === 4) {
        sortType.value = 5;
      } else {
        sortType.value = 4;
      }
      activeSortId.value = "sort_sention";
    } else if (id === "sort_win") {
      if (sortType.value === 7) {
        sortType.value = 6;
      } else {
        sortType.value = 7;
      }
      activeSortId.value = "sort_win";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを降順でセットする
    if (id === "sort_name") {
      sortType.value = 0;
      activeSortId.value = "sort_name";
    } else if (id === "sort_age") {
      sortType.value = 3;
      activeSortId.value = "sort_age";
    } else if (id === "sort_sention") {
      sortType.value = 4;
      activeSortId.value = "sort_sention";
    } else if (id === "sort_win") {
      sortType.value = 7;
      activeSortId.value = "sort_win";
    }
  }
  doInit();

  document.body.className = "status-loaded";
};

// 「すべてを選択」クリック
const setAllSelect = (e) => {
  if (e.target.checked) {
    for (let i = 0; i < favCheckList.value.length; i++) {
      favCheckList.value[i] = true;
    }
    favFlag.value = true;
  } else {
    for (let i = 0; i < favCheckList.value.length; i++) {
      favCheckList.value[i] = false;
    }
    favFlag.value = false;
  }
};

//チェックリストクリック時更新
const setSelect = (index) => {
  favCheckList.value[index] = !favCheckList.value[index];
  moreLength.value = favCheckList.value.filter((n) => n === true).length;
  favFlag.value = moreLength.value > 0;
};

// 「削除」クリック
const deleteSelected = () => {
  try {
    document.body.className = "";

    if (favFlag.value === true) {
      mCheckNum = 0;
      mTasks = [];
      mFavApiFailed = false;

      // チェック済み項目の個数チェック
      for (let i = 0; i < favCheckList.value.length; i++) {
        if (favCheckList.value[i] && favContentList.value[i]) {
          mCheckNum++;
          mTasks.push({
            id: favContentList.value[i].JockeyCd,
            name: favContentList.value[i].JockeyName,
          });
          const id = favContentList.value[i].JockeyCd;
          const name = favContentList.value[i].JockeyName;
          const request =
            HOST +
            PATH +
            "MyFavorite/deleteMyFavoriteJockey.js" +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(mUserId) +
            "&jockeyCd=" +
            id +
            "&narFlag=" +
            isNar()
          getJSON(
            request,
            false,
            function (status, json) {
              if (status == SUCCESS) {
                // cookieからお気に入り情報を削除
                changeFavoriteFromCookie(FJOCKEY, id, REMOVE);
              } else {
                mFavApiFailed = true;
              }
              doInit();
            },
          );
        }
      }
    }
    document.body.className = "status-loaded";
  } catch (e) {
    console.log(e);
    document.body.className = "status-loaded";
  }
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -fav">
      <h1 class="heading-page">お気に入り</h1>
      <span class="btn-basic -tip" @click="thisModalUpdate()" v-if="!isSugotoku">
        自動登録機能について
      </span>
    </section>

    <FavTabs :currentTab="currentTab" />

    <section
      v-if="favContentListAll.length && loadedPage"
      class="layout-section"
    >
      <div class="layout-title">
        <h2 class="heading-circle -padding">騎手</h2>
        <div class="nav">
          <div class="form-select">
            <select v-model="displayType" @change="switchShowContents">
              <option value="0">現役騎手</option>
              <option value="1">引退騎手</option>
              <option value="2">全ての騎手</option>
            </select>
          </div>
        </div>
      </div>

      <table class="table-grid -fit -middle -padding">
        <thead>
          <tr class="-center -middle">
            <th>
              <span class="form-checkbox -zen"
                ><label
                  ><input
                    @click="setAllSelect($event)"
                    type="checkbox"
                    value=""
                    name="" /><i></i></label
              ></span>
            </th>
            <th>
              騎手名<i
                class="filter-sort"
                :class="{ '-asc': sortType === 0, '-desc': sortType === 1 }"
                @click="changeSort('sort_name')"
              ></i>
            </th>
            <th>
              年齢<i
                class="filter-sort"
                :class="{ '-asc': sortType === 2, '-desc': sortType === 3 }"
                @click="changeSort('sort_age')"
              ></i>
            </th>
            <th>
              所属<i
                class="filter-sort"
                :class="{ '-asc': sortType === 4, '-desc': sortType === 5 }"
                @click="changeSort('sort_sention')"
              ></i>
            </th>
            <th>
              勝利数<br />本年(通算)<i
                class="filter-sort"
                :class="{ '-asc': sortType === 6, '-desc': sortType === 7 }"
                @click="changeSort('sort_win')"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(favContent, index) in showFavList"
            :key="index"
            :class="{ '-disable': favContent.RetireDiv === 1 }"
          >
            <td class="-center">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="setSelect(index)"
                    v-model="favCheckList[index]"
                    :value="favContent.JockeyCd"
                    :name="favContent.JockeyName" /><i></i></label
              ></span>
            </td>
            <td>
              <router-link :to="'/data/jockey?JockeyCd=' + favContent.JockeyCd">{{
                favContent.JockeyName
              }}</router-link>
            </td>
            <td class="-center">{{ favContent.RetireDiv === 1 ? '' : favContent.Age }}</td>
            <td class="-center">{{ favContent.Affiliation }}</td>
            <td v-if="favContent.RetireDiv === 1" class="-center">
              {{ favContent.CareerWins }}
            </td>
            <td v-else class="-center">
              {{ favContent.CurrentYearRaceWins ?? '-' }}<br />({{
                favContent.CareerWins
              }})
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="narFlag">

      </p>
      <p v-if="updateDate" class="paragraph-leading">
        {{ updateDate }}<br>
      </p>

      <div class="nav-group -borderbottom">
        <span v-if="moreFlag" @click="showMore" class="btn-basic -more">もっと見る</span>
      </div>
      <nav class="nav-group -left">
        <span
          @click="deleteSelected"
          class="btn-basic -delete"
          :class="{ '-active': favFlag === true }"
          >削除</span
        >
      </nav>
    </section>
    <section v-else-if="loadedPage" class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">騎手</h2>
      </div>
      <div class="inner">
        <h3 class="heading-message">お気に入りは登録されていません。</h3>
        <p>
          気になるキーワードで検索してお気に入りに登録してください。<br />お気に入りに登録すると
        </p>
        <ul class="list-number">
          <li>お気に入り競走馬、騎手、調教師の出場レースが一目でわかる</li>
          <li>お気に入り競走馬、騎手、調教師が出場するレースをお知らせ</li>
        </ul>
      </div>
    </section>
    <AutoRegistAbout
      @modal-close="modalOpen = false"
      :modalOpen="modalOpen"
    ></AutoRegistAbout>

    <div class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/fav/style.scss" scoped></style>
