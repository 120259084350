import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreNewsArticle = defineStore('news-article', () => {
  const fileCreateDate = ref('');
  const seqNo = ref('');
  const page = ref('');
  const article = ref({});
  const snsShareLink = ref('');
  const snsShareTitle = ref('');

  const hasArticle = () => {
    return seqNo.value && !!Object.keys(article.value)?.length
  }
  const isSameArticle = (argFileCreateDate, argSeqNo, argPage) => {
    return hasArticle() &&
      argFileCreateDate === fileCreateDate.value &&
      argSeqNo === seqNo.value &&
      argPage === page.value
  }

  return {
    fileCreateDate,
    seqNo,
    page,
    article,
    snsShareLink,
    snsShareTitle,
    hasArticle,
    isSameArticle
  }
})

export default {
  useStoreNewsArticle,
}