<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">レースデータ</h1>
    </section>
    <section class="layout-section">
      <div class="inner" v-if="raceData">
        <h2 class="heading-circle">{{ raceData.courseTitle }}</h2>
        <div class="course-illust">
          <img :src="raceData.thumbnailPath" />
        </div>

        <table class="table-cell -middle -center" v-if="recordData">
          <tr>
            <th class="time">タイム</th>
            <th class="bamei">馬名</th>
            <th class="date">年月日</th>
          </tr>
          <tr>
            <th colspan="3" class="header -left">コースレコード</th>
          </tr>
          <tr>
            <td class="time">{{ recordData.time }}</td>
            <td class="bamei">
              {{ recordData.HorseName }}{{ recordData.Sex }}{{ recordData.Age
              }}<br />{{ recordData.JockyName }}{{ recordData.jockeyWeight }}
            </td>
            <td class="date">
              {{ recordData.date }}<br /><span class="weather">{{
                recordData.conditionText
              }}</span>
            </td>
          </tr>
          <tr v-if="raceInfo2years">
            <th colspan="3" class="header -left">2歳レコード</th>
          </tr>
          <tr v-if="raceInfo2years">
            <td class="time">{{ raceInfo2years.time }}</td>
            <td class="bamei">
              {{ raceInfo2years.HorseName }}{{ raceInfo2years.Sex
              }}{{ raceInfo2years.Age }}<br />{{ raceInfo2years.JockyName
              }}{{ raceInfo2years.jockeyWeight }}
            </td>
            <td class="date">
              {{ raceInfo2years.date }}<br /><span class="weather">{{
                raceInfo2years.conditionText
              }}</span>
            </td>
          </tr>
        </table>
      </div>

      <div
        v-if="courseId !== 'none'"
        data-tab='["data-trigger", "data-target"]'
      >
        <h2 class="heading-circle -padding -bordertop">勝馬頭数</h2>
        <div class="nav-tab -fit2">
          <!-- tabの条件分岐 -->
          <ul data-trigger>
            <li
              @click="tabChange('ByRace')"
              :class="{ '-current': isCurrentTab === 'ByRace' }"
            >
              <span class="label">レース別</span>
            </li>
            <li
              @click="tabChange('ByCourse')"
              :class="{ '-current': isCurrentTab === 'ByCourse' }"
            >
              <span class="label">コース別</span>
            </li>
          </ul>
        </div>
        <div class="container-tabtarget" data-target>
          <ByRace
            v-if="tab === 'ByRace'"
            :raceName="raceName"
            :yearType="yearType"
            :raceWinRate="raceWinRate"
            :NARFLAG="NARFLAG"
          />
          <ByCourse
            v-if="tab === 'ByCourse'"
            :courseName="courseName"
            :courseYearType="courseYearType"
            :courseWinRate="courseWinRate"
            :NARFLAG="NARFLAG"
          />
        </div>
      </div>

      <div v-else data-tab='["data-trigger", "data-target"]'>
        <h2 class="heading-circle -padding -bordertop">勝馬頭数</h2>
        <div class="nav-tab -fit2">
          <ul data-trigger="">
            <li class="-current"><span class="label">レース別</span></li>
            <li><span class="label">コース別</span></li>
          </ul>
          <div class="system-registmember -sub">
            <a
              v-if="!isWebView"
              href="/member"
              target="_blank"
              class="btn-basic -wide -window">
              こちらの機能をご利用いただくには、<br/>
              月額コースへの会員登録が必要です。
            </a>
            <router-link v-else to="/member" class="btn-basic -wide -window">
              こちらの機能をご利用いただくには、<br/>
              月額コースへの会員登録が必要です。
            </router-link>
          </div>
        </div>
      </div>
      <div class="inner">
        <h2 class="heading-circle">歴代優勝馬</h2>
        <table class="table-cell -hascelllink">
          <tr v-for="(data, index) in winningHorses" :key="index">
            <td v-if="data.DOR >= '2003'">
              <a :href="data.link" class="link-cell">
                <div class="list-winhorse">
                  <span class="year">{{ data.DOR }}年</span>
                  <span class="umaban">
                    <i :class="'icon-umaban -waku0' + data.BracketNum">{{ data.HorseNum }}</i>
                  </span>
                  <span class="horse">{{ data.HorseName }}</span>
                  <span class="popular">{{ data.WinPopularRank }}人気</span>
                  <span class="jockey">{{ data.JockeyName.replace('　', ' ') }}</span>
                </div>
              </a>
            </td>
            <td v-else>
              <div class="link-cell -disable">
                <div class="list-winhorse">
                  <span class="year">{{ data.DOR }}年</span>
                  <span class="umaban">
                    <i class="icon-umaban -waku0"></i>
                  </span>
                  <span class="horse">{{ data.HorseName }}</span>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>

    <section class="layout-section">
      <div class="nav-group -bordertop">
        <span onclick="javascript:history.back();" class="btn-basic -prev -gray"
          >戻る</span
        >
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import { ref } from "vue";
import ByRace from "../../components/race/date/ByRace.vue";
import ByCourse from "../../components/race/date/ByCourse.vue";

import {
  getMenmerCourse,
  getUrlVars,
  getJSON,
  yearDiv,
  monthDiv,
  dayDiv,
  winFormat,
  SUCCESS,
  PAYINGNONE,
  retrieveCourse,
  getUserAgent,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  RACEPATH,
} from "../../assets/js/define";

export default {
  data() {
    return {
      NARFLAG: null,
      raceInfo2years: null,
      activeClass: "active",
      errorClass: "text-danger",

      raceData: {
        courseTitle: null,
        thumbnailPath: null,
      },
      recordData: null,
      twoYearsOldRecordData: null,
      winningHorses: null,
      raceName: null,
      yearType: [],
      raceWinRate: null,
      courseName: null,
      courseYearType: [],
      courseWinRate: null,
      isWebView: false,
      styles: {
        content: "none",
      },
      courseId: PAYINGNONE,
    };
  },
  components: { ByRace, ByCourse },
  setup() {
    const tab = ref("ByRace");
    const isCurrentTab = ref("ByRace");
    const tabChange = (data) => {
      tab.value = data;
      isCurrentTab.value = data;
    };
    return {
      tab,
      tabChange,
      isCurrentTab,
    };
  },
  mounted() {
    const self = this;

    self.NARFLAG = isNar();
    self.isWebView = getUserAgent();
    //パラメータ取得
    let params = getUrlVars();
    //会員コース確認
    callRetrieveCourse(function (courseId) {
      self.courseId = courseId;
      //コースデータ差し込み
      courseDataInfo(
        params.racetrackCd,
        params.trackTypeCd,
        params.distance,
        params.trackCd
      );
      //レースデータ表示
      displayRaceInfo(params.raceCd);
    });

    function callRetrieveCourse(callback) {
      try {
        retrieveCourse(callback);
      } catch (e) {
        callback("none", false);
      }
    }
    /******************************************************************
     *  コースデータ表示
     ******************************************************************/
    function courseDataInfo(racetrackCd, trackTypeCd, distance, trackCd) {
      const request =
        HOST +
        PATH +
        "RaceInfo/courseInfo.js" +
        BACKWORDPATH +
        "?racetrackCd=" +
        racetrackCd +
        "&trackTypeCd=" +
        trackTypeCd +
        "&distance=" +
        distance +
        "&trackCd=" +
        trackCd +
        "&narFlag=" +
        isNar();
      document.body.className = "";
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          try {
            let courseName = {
              "01": "札幌",
              "02": "函館",
              "03": "福島",
              "04": "新潟",
              "05": "東京",
              "06": "中山",
              "07": "中京",
              "08": "京都",
              "09": "阪神",
              10: "小倉",
              30: "門別",
              35: "盛岡",
              36: "水沢",
              42: "浦和",
              43: "船橋",
              44: "大井",
              45: "川崎",
              46: "金沢",
              47: "笠松",
              48: "名古屋",
              50: "園田",
              51: "姫路",
              54: "高知",
              55: "佐賀",
            };
            let courseImgHead = {
              "01": "sapporo",
              "02": "hakodate",
              "03": "fukusima",
              "04": "niigata",
              "05": "tokyo",
              "06": "nakayama",
              "07": "tyuukyou",
              "08": "kyouto",
              "09": "hansin",
              10: "kokura",
            };

            json.trackType = "";
            json.trackTypeImg = "";
            switch (trackTypeCd) {
              case "1":
                json.trackType = "芝";
                json.trackTypeImg = "siba";
                break;
              case "2":
                json.trackType = "ダート";
                json.trackTypeImg = "dirt";
                break;
              case "3":
                json.trackType = "障害";
                json.trackTypeImg = "syougai";
                break;
            }
            //#4185レース名と画像だけは表示するように対応
            json.courseTitle =
              courseName[racetrackCd] + json.trackType + distance + "m";

            //画像パスの作成
            json.thumbnailPath =
              "/assets/images/race/course/" +
              "c_" +
              racetrackCd +
              "_" +
              distance +
              "_" +
              trackCd +
              ".png";

            //データの格納
            self.courseName = json.courseTitle;
            self.raceData.courseTitle = json.courseTitle;
            self.raceData.thumbnailPath = json.thumbnailPath;

            //レコードの整形
            if (json != null && json != undefined && json != "") {
              if (json.length > 0) {
                //コースレコード情報の取得
                let useData = json[0];
                //エントリー情報の取得
                let entryData = json[1];
                let isTwoOldRecord = false; //2歳レコードの有無

                const recordData = useData.CourseRecord.find(
                  (courseRecord) => courseRecord.RecordDiv === "1"
                );
                if (recordData) {
                  //レコードタイムを整形
                  recordData.time = recordData.CourseRecord;
                  let m = recordData.time.substr(0, 1);
                  let s = recordData.time.substr(1, 2);
                  let n = recordData.time.substr(3, 1);
                  recordData.time = m + "." + s + "." + n;

                  //斤量整形
                  recordData.jockeyWeight = recordData.Weight / 10;
                  recordData.jockeyWeight =
                    "(" + parseInt(recordData.jockeyWeight).toFixed(1) + "kg)";
                  //性別
                  if (recordData.Sex == "1") {
                    recordData.Sex = " 牡";
                  } else if (recordData.Sex == "2") {
                    recordData.Sex = " 牝";
                  } else if (recordData.Sex == "3") {
                    recordData.Sex = " セン";
                  } else {
                    recordData.Sex = " ";
                  }
                  //年月日整形用
                  recordData.date = recordData.RaceDate;
                  recordData.date =
                    yearDiv(recordData.date) +
                    "/" +
                    monthDiv(recordData.date) +
                    "/" +
                    dayDiv(recordData.date);
                  //天気
                  let tenki = {
                    1: "晴",
                    2: "曇",
                    3: "雨",
                    4: "小雨",
                    5: "雪",
                    6: "小雪",
                    FC: "晴/曇",
                    FR: "晴/雨",
                    FS: "晴/雪",
                    CF: "曇/晴",
                    CR: "曇/雨",
                    CS: "曇/雪",
                    RF: "雨/晴",
                    RS: "雨/雪",
                    RC: "雨/曇",
                    SF: "雪/晴",
                    SR: "雪/雨",
                    SC: "雪/曇",
                  };
                  //馬場状態
                  let baba = {
                    "": "0",
                    良: "1",
                    稍重: "2",
                    重: "3",
                    不良: "4",
                  };
                  let count = 0;
                  for (let k in baba) {
                    if (recordData.TrackConditionCdTurf == baba[k]) {
                      recordData.turfConditionText =
                        "(" + tenki[recordData.Weather] + "・芝/" + k + ")";
                    }
                    if (recordData.TrackConditionCdDirt == baba[k]) {
                      recordData.dirtConditionText =
                        "(" + tenki[recordData.Weather] + "・ダ/" + k + ")";
                    }
                    count++;
                  }
                  recordData.conditionText = "";
                  if (recordData.turfConditionText != undefined) {
                    recordData.conditionText = recordData.turfConditionText;
                  }
                  if (recordData.dirtConditionText != undefined) {
                    if (recordData.conditionText != "") {
                      recordData.conditionText += "/";
                    }
                    recordData.conditionText += recordData.dirtConditionText;
                  }

                  self.recordData = recordData;
                }

                const twoYearsRecordData = useData.CourseRecord.find(
                  (courseRecord) => courseRecord.RecordDiv === "2"
                );
                if (twoYearsRecordData) {
                  //レコードタイムを整形
                  twoYearsRecordData.time = twoYearsRecordData.CourseRecord;
                  let m = twoYearsRecordData.time.substr(0, 1);
                  let s = twoYearsRecordData.time.substr(1, 2);
                  let n = twoYearsRecordData.time.substr(3, 1);
                  twoYearsRecordData.time = m + "." + s + "." + n;

                  //斤量整形
                  twoYearsRecordData.jockeyWeight =
                    twoYearsRecordData.Weight / 10;
                  twoYearsRecordData.jockeyWeight =
                    "(" +
                    parseInt(twoYearsRecordData.jockeyWeight).toFixed(1) +
                    "kg)";
                  //性別
                  if (twoYearsRecordData.Sex == "1") {
                    twoYearsRecordData.Sex = " 牡";
                  } else if (twoYearsRecordData.Sex == "2") {
                    twoYearsRecordData.Sex = " 牝";
                  } else if (twoYearsRecordData.Sex == "3") {
                    twoYearsRecordData.Sex = " セン";
                  } else {
                    twoYearsRecordData.Sex = " ";
                  }
                  //年月日整形用
                  twoYearsRecordData.date = twoYearsRecordData.RaceDate;
                  twoYearsRecordData.date =
                    yearDiv(twoYearsRecordData.date) +
                    "/" +
                    monthDiv(twoYearsRecordData.date) +
                    "/" +
                    dayDiv(twoYearsRecordData.date);
                  //天気
                  let tenki = {
                    1: "晴",
                    2: "曇",
                    3: "雨",
                    4: "小雨",
                    5: "雪",
                    6: "小雪",
                    FC: "晴/曇",
                    FR: "晴/雨",
                    FS: "晴/雪",
                    CF: "曇/晴",
                    CR: "曇/雨",
                    CS: "曇/雪",
                    RF: "雨/晴",
                    RS: "雨/雪",
                    RC: "雨/曇",
                    SF: "雪/晴",
                    SR: "雪/雨",
                    SC: "雪/曇",
                  };
                  //馬場状態
                  let baba = {
                    "": "0",
                    良: "1",
                    稍重: "2",
                    重: "3",
                    不良: "4",
                  };
                  for (let k in baba) {
                    if (twoYearsRecordData.TrackConditionCdTurf == baba[k]) {
                      twoYearsRecordData.turfConditionText =
                        "(" + tenki[recordData.Weather] + "・芝/" + k + ")";
                    }
                    if (twoYearsRecordData.TrackConditionCdDirt == baba[k]) {
                      twoYearsRecordData.dirtConditionText =
                        "(" + tenki[recordData.Weather] + "・ダ/" + k + ")";
                    }
                  }
                  twoYearsRecordData.conditionText = "";
                  if (twoYearsRecordData.turfConditionText != undefined) {
                    twoYearsRecordData.conditionText =
                      twoYearsRecordData.turfConditionText;
                  }
                  if (twoYearsRecordData.dirtConditionText != undefined) {
                    if (twoYearsRecordData.conditionText != "") {
                      twoYearsRecordData.conditionText += "/";
                    }
                    twoYearsRecordData.conditionText +=
                      twoYearsRecordData.dirtConditionText;
                  }

                  self.raceInfo2years = twoYearsRecordData;
                }

                if (entryData) {
                  drawCourseWinGraph(
                    useData.WinningStat,
                    entryData.EntryHorseNumAll
                  );
                }
              }
            }
          } catch (e) {
            console.log("courseDataInfo : " + e.message);
          }
        }
        document.body.className = "status-loaded";
      });
    }
    //勝馬頭数グラフ作画
    function drawCourseWinGraph(json, entryHorseNumAll) {
      //年度番号昇順に並べ替え：1~3
      json.sort(function (a, b) {
        a = a["YearType"];
        b = b["YearType"];
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });

      //過去3年間の実績のみ集計（jsonは最大3）
      let stat = []; //集計用コンテナの初期化
      let yearStat = "";
      for (let i = 0; i < json.length; i++) {
        yearStat = json[i];

        for (let j = 0; j < 18; j++) {
          if (!(j in stat)) {
            stat[j] = { t1: 0, t2: 0, t3: 0 }; //初期化
            if (!entryHorseNumAll[j]) {
              stat[j].t4 = "none";
            }
          }
          let horseNum = yearStat["Horse_" + ("0" + (j + 1)).slice(-2)] * 1;
          stat[j]["t" + (i + 1)] += horseNum;
        }
      }
      //最大馬数取得
      let maxStat = stat.map(function (value) {
        if (isNaN(value.t3)) {
          return 0;
        } else {
          return value.t1 + value.t2 + value.t3;
        }
      });
      let maxStatValue = Math.max.apply(null, maxStat);

      //グラフを描画するために配列を整形する
      let maxNum = maxStatValue;
      for (let l = 0; l < maxStatValue; l++) {
        self.courseYearType.push([]);
        for (let m = 0; m < 18; m++) {
          if (isNaN(stat[m].t1) || isNaN(stat[m].t2) || isNaN(stat[m].t3)) {
            self.courseYearType[l].push(5);
          } else if (stat[m].t1 >= maxNum) {
            self.courseYearType[l].push(1);
          } else if (stat[m].t1 + stat[m].t2 >= maxNum) {
            self.courseYearType[l].push(2);
          } else if (stat[m].t1 + stat[m].t2 + stat[m].t3 >= maxNum) {
            self.courseYearType[l].push(3);
          } else {
            self.courseYearType[l].push(0);
          }
        }
        maxNum--;
      }

      let keys = Object.keys(stat);

      //馬番ごとの勝率を設定
      let raceMaxNum = 0;
      if (isNar() === 1) {
        raceMaxNum = 16;
      } else {
        raceMaxNum = 18;
      }
      for (let i = 0; i < raceMaxNum; i++) {
        let count = 0;
        if (entryHorseNumAll.length > i) {
          entryHorseNumAll[i].win = winFormat(
            entryHorseNumAll[i].count,
            stat[keys[i]].t1 + stat[keys[i]].t2 + stat[keys[i]].t3
          );
        }
      }
      self.courseWinRate = entryHorseNumAll;

      return;
    }

    /******************************************************************
     *  レースデータ表示
     ******************************************************************/
    function displayRaceInfo(raceCd) {
      let req =
        HOST +
        PATH +
        "RaceInfo/raceInfo.js" +
        BACKWORDPATH +
        "?SpRaceCd=" +
        raceCd +
        "&NarFlag=" +
        isNar();
      document.body.className = "";
      getJSON(req, true, function (status, json) {
        if (status == SUCCESS) {
          if (json == null || json.length == 0) {
            return;
          }

          let useData = json[0];
          let entryData = json[1];

          self.raceName = useData.RaceMainName;

          drawRaceWinGraph(useData.WinningStat, entryData.EntryHorseNumAll);

          if (
            "SuccessiveWinningHorce" in useData &&
            useData.SuccessiveWinningHorce.length > 0
          ) {
            displayPastWinners(useData.SuccessiveWinningHorce);
          }
        }
        document.body.className = "status-loaded";
      });
    }
    //勝馬頭数グラフ作画
    function drawRaceWinGraph(json, entryHorseNumAll) {
      //年度番号昇順に並べ替え：1=今～10年前；2=10年前～20年前
      json.sort(function (a, b) {
        a = a["YearType"];
        b = b["YearType"];
        if (a < b) return -1;
        if (a > b) return 1;

        return 0;
      });

      //過去20年間の実績のみ集計
      let stat = []; //集計用コンテナの初期化
      let yearStat = "";
      for (let i = 0; i < json.length; i++) {
        //過去10年 or 過去20年のオブジェクトが入る
        yearStat = json[i];

        for (let j = 0; j < 18; j++) {
          if (!(j in stat)) {
            stat[j] = { t1: 0, t2: 0 }; //初期化
            if (!entryHorseNumAll[j]) {
              stat[j].t3 = "none";
            }
          }
          let horseNum = yearStat["Horse_" + ("0" + (j + 1)).slice(-2)] * 1;
          stat[j]["t" + (i + 1)] += horseNum;
        }
      }

      //最大馬数取得
      let maxStat = stat.map(function (value) {
        if (isNaN(value.t2)) {
          return 0;
        } else {
          return value.t1 + value.t2;
        }
      });
      let maxStatValue = Math.max.apply(null, maxStat);

      let maxNum = maxStatValue;
      for (let l = 0; l < maxStatValue; l++) {
        self.yearType.push([]);
        for (let m = 0; m < 18; m++) {
          if (isNaN(stat[m].t1) || isNaN(stat[m].t2)) {
            self.yearType[l].push(5);
          } else if (stat[m].t1 >= maxNum) {
            self.yearType[l].push(1);
          } else if (stat[m].t1 + stat[m].t2 >= maxNum) {
            self.yearType[l].push(2);
          } else {
            self.yearType[l].push(0);
          }
        }
        maxNum--;
      }

      let keys = Object.keys(stat);
      //馬番ごとの勝率を設定
      let raceMaxNum = 0;
      if (isNar() === 1) {
        raceMaxNum = 16;
      } else {
        raceMaxNum = 18;
      }
      for (let i = 0; i < raceMaxNum; i++) {
        let count = 0;
        if (entryHorseNumAll.length > i) {
          entryHorseNumAll[i].win = winFormat(
            entryHorseNumAll[i].count,
            stat[keys[i]].t1 + stat[keys[i]].t2
          );
        }
      }
      self.raceWinRate = entryHorseNumAll;

      return;
    }
    //
    function displayPastWinners(json) {
      if (json == null || json.length == 0) {
        return;
      }

      //年度降順に並べ替え
      json.sort(function (a, b) {
        a = a["DOR"];
        b = b["DOR"];
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      });

      for (let i = 0; i < json.length; i++) {
        let winner = json[i];

        if (winner.DOR.slice(0, 4) * 1 < 2003) {
          //2003年以前は実績がないためリンク設定しない
          winner.DOR = winner.DOR.slice(0, 4);
          winner.link = "javascript:void(0)";
          winner.isActive = true;
        } else {
          const LinkDOR = winner.DOR.slice(0, 8);
          winner.DOR = winner.DOR.slice(0, 4);
          winner.link =
            HOST +
            RACEPATH +
            "results" +
            "?DOR=" +
            LinkDOR +
            "&RacetrackCd=" +
            winner.RacetrackCd +
            "&RaceNum=" +
            winner.RaceNum;
          winner.isActive = false;
        }
      }
      self.winningHorses = json;
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/race/style.scss" scoped></style>
