<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import axios from "axios";
import {JRA_WP_HOST} from "../../../assets/js/define";

let isWeekOpen = reactive([]);

//本番用 APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=faq";

let lists = reactive({});
onMounted(() => {
  document.body.className = "";
  axios
    .get(API_URL)
    .then((response) => {
      Object.assign(lists, response.data[0].acf.faq);
      for (let i = 0; i < lists.length; i++) {
        isWeekOpen[i] = false;
      }

      document.body.className = "status-loaded";
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});

function handleThisToggle(index) {
  isWeekOpen[index] = !isWeekOpen[index];
}

const beforeEnter = (el) => {
  el.style.height = "0";
};

const enter = (el) => {
  el.style.height = el.scrollHeight + "px";
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + "px";
};

const leave = (el) => {
  el.style.height = "0";
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">よくあるご質問</h1>
    </section>

    <section class="layout-section">
      <ul class="list-line" v-if="lists">
        <li class="item" v-for="(list, index) in lists" :key="index">
          <dl
            class="container-faq"
            :class="{ '-active': isWeekOpen[index] }"
            data-accordion='["data-trigger","data-target"]'
          >
            <dt :class="{ '-active': isWeekOpen[index] }" data-trigger>
              <span class="q">Q{{ Number(index) + 1 }}</span>
              <div class="title">{{ list.question }}</div>
              <div class="nav">
                <span
                  @click="handleThisToggle(index)"
                  class="icon-accordion"
                ></span>
              </div>
            </dt>
            <transition
              name="topSlide"
              @before-enter="beforeEnter"
              @enter="enter"
              @before-leave="beforeLeave"
              @leave="leave"
            >
              <div
                data-target
                v-if="isWeekOpen[index]"
                class="topSlide"
                :class="{ '-active': isWeekOpen[index] }"
                style="padding: 0"
              >
                <dd
                  :style="[
                    isWeekOpen[index] ? 'display:block' : 'display:none',
                  ]"
                  style="padding: 10px 20px 20px 20px"
                >
                  <div v-html="list.answer"></div>
                </dd>
              </div>
            </transition>
          </dl>
        </li>
      </ul>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../../assets/css/support/style.scss" scoped></style>
