<script setup>
import {ref, reactive, onMounted, onServerPrefetch, watch} from "vue";
import {
  getJSON,
  SUCCESS,
  PAYINGSUGOTOKU,
  PAYINGECONOMY,
  PAYINGNONE,
  PAYINGPREMIUM,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  setCookie,
  DATETYPE,
  getUserAgent,
  getUserId,
  getTopBanner,
  getCustomizeOddsType,
  setOddsUrl,
  isAtag, getGradeIconClassNar,
} from "../../assets/js/common";
import {HOST, NAR_WP_HOST, PATH, BACKWORDPATH} from "../../assets/js/define";
import {
  showLastFixedResultRaceInfo,
  loadTodayRaceInfo,
  loadRecommend,
  loadPays1x,
  loadHighDividend,
  loadMultiWinners,
  loadUpcomingRaceInfo,
  loadNewsHeadline,
  loadChangeInfo,
  shouldShowFavoriteButton,
} from "../../functions/top/common";
import Swiper from "swiper/bundle";
import moment from "moment";
import {setData} from "../../functions/topInit";
import TrendTable from "../info/TrendTable.vue"
import CheckPoint from "./CheckPoint.vue";

//スライダー表示
const initSlider = () => {
  const swiper = new Swiper(".swiper", {
    direction: "horizontal",
    autoplay: {
      delay: 2000,
    },
    loop: true,
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button.-next",
      prevEl: ".swiper-button.-prev",
    },
  });

    buttonClass.value = slider.length === 1 ? '-buttonNone' : '';
  };

let slider = reactive([]);
let advertisement = reactive([]);
let buttonClass = ref('')
let announce = reactive([]);
let importantrace = reactive([]);
let campaign = reactive([]);
let information = reactive([]);
const banner = ref("");
const scrollGuid = ref(true);
const displayScroll = ref(false);
const isWebView = ref(false);
const isAndroidWebView = ref(false);
const isIphoneWebView = ref(false);
const isSugotoku = ref(false);
const isLogin = ref(true);
let mUserId = "";
const oddsPath = ref("win")
let defaultTOB = ref(1)
import {useStoreTopNar} from "../../stores/top/nar-states";
import {fetch as fetchStoreTopNar} from "../../functions/top/nar";
import {storeToRefs} from "pinia";
const storeTopNar = useStoreTopNar();
const {
  wpContents,
  userCourse,  //1:無課金 2:300会員 3:スゴ得会員 4:1000会員
} = storeToRefs(storeTopNar);

onServerPrefetch(async () => {
  await fetchStoreTopNar();
  await loadWp(1);
  loadedPage.value = true;
});

onMounted(async() => {
  isWebView.value = getUserAgent();
  if(Object.keys(wpContents.value || {}).length === 0) {
    await fetchStoreTopNar();
  }

  const request =
      HOST +
      PATH +
      "getLoginInfo.js" +
      BACKWORDPATH +
      "/?NarFlag=1";
  getJSON(request, false, async(status, json) => {
    setData(status, json);
    //ユーザコースをセット
    let courseId = json.courseIdStr;
    mUserId = json.userId || getUserId();
    isLogin.value = !!mUserId;
    isIphoneWebView.value = json.isIphone;
    isAndroidWebView.value = json.isAndroid;

    if (courseId === PAYINGNONE) {
      userCourse.value = 1;
    } else if (courseId === PAYINGECONOMY) {
      userCourse.value = 2;
    } else if (courseId === PAYINGSUGOTOKU) {
      userCourse.value = 3;
      isSugotoku.value = true;
      defaultTOB.value = await getCustomizeOddsType(mUserId)
      oddsPath.value = await setOddsUrl(defaultTOB.value)
    } else if ([PAYINGPREMIUM, PAYINGPACK03, PAYINGPACK06, PAYINGPACK12].includes(courseId)) {
      userCourse.value = 4;
      defaultTOB.value = await getCustomizeOddsType(mUserId)
      oddsPath.value = await setOddsUrl(defaultTOB.value)
    }

    if (courseId !== PAYINGNONE) {
      loadFav();
    }
    loadRace();
    loadWp(userCourse.value);
    //banner
    if (!isWebView.value) {
      banner.value = await getTopBanner(isAndroidWebView.value, isIphoneWebView.value)
    }
  });
});

const callDart = () => {
  window.flutter_inappwebview.callHandler('open_login_modal');
}
// スクロールアイコンの制御
const displayGuid = () => {
  const grid = document.getElementById("scrollGrid");
  if (grid) {
    //スクロールアクション追加
    grid.addEventListener("scroll", () => {
      scrollGuid.value = document.getElementById("scrollGrid").scrollLeft < 10
    });

    window.addEventListener('resize', () => {
      widthCheck()
    });
  }
}

const widthCheck = () => {
  const windowWidth = window.innerWidth + 15;
  const tabList = [...document.querySelectorAll('#scrollGrid > ul > li')];
  const tabListWidth = tabList.reduce((acc, tabItem) => acc + tabItem.clientWidth, 0);
  displayScroll.value = windowWidth < tabListWidth;
}

const loadFav = () => {
  //今週のレース情報を取得
  const request =
      HOST +
      PATH +
      "MyFavorite/getMyFavorite.js" +
      BACKWORDPATH +
      "?userID=" +
      encodeURIComponent(mUserId) +
      '&narFlag=1'
  getJSON(request, true, function (status, json) {})
}

const loadWp = async (course) => {
  const courseKey = {
    2: 'member-300',
    4: 'member-1000',
    1: 'member-none',
    3: 'member-sugotoku-app',
  }[course] ?? 1
  //advertisement
  wpContents.value.advertisement?.forEach(element => {
    element.member[courseKey] ? advertisement.push(element) : '';
  });
  //slider
  wpContents.value.top_slider?.forEach(element => slider.push(element));
  initSlider();

  //announce
  wpContents.value.announce?.forEach(element => element.url = "/announce/?id=" + element.ID);
  wpContents.value.announce?.forEach(element => {
    element.member[courseKey] ? announce.push(element) : '';
  });
  //importantrace
  wpContents.value.importantrace?.forEach((element) =>
      importantrace.push(element)
  );
  //campaign
  wpContents.value.campaign?.forEach(element => {
    element.member[courseKey] ? campaign.push(element) : '';
  });
  //information
  wpContents.value.information?.forEach(element => element.url = "/information?id=" + element.ID);
  wpContents.value.information?.forEach(element => {
    element.member[courseKey] ? information.push(element) : '';
  });
}

const shouldShowFavButton = ref(false)
let raceInfo = reactive({});
let hassomajika = reactive([]);
let kakutei = reactive({});
let pays1xData = reactive({});
let recommendData = reactive({});
let highDividendData = reactive({});
let multiWinnersData = reactive({});
let establishRecordData = reactive([]);
let weightLossJockeyData = reactive([]);
let newsHeadlineData = reactive({});
let todayRace = reactive({});
let targetDOR = ref("");
let TargetchangeInfo = reactive({});
let targetTrackCd = ref("");
let targetRaceInfo = reactive({});
let loadedPage = ref(false);
const loadRace = async () => {
  document.body.className = "";
  let params;
  //本番の時
  let mDate = new Date();
  let strDate =
      mDate.getFullYear() +
      ("0" + (mDate.getMonth() + 1)).slice(-2) +
      ("0" + mDate.getDate()).slice(-2);
  params = { DOR: strDate };

  if (userCourse.value !== 1) {
    shouldShowFavoriteButton(shouldShowFavButton, mUserId);
  }

  //APIコールstep1
  let req =
      HOST +
      PATH +
      "Top/getTopInfo.js" +
      BACKWORDPATH +
      "?dor=" +
      params.DOR +
      "&narFlag=1"

  await getJSON(req, true, function (status, jsonall) {
    if (status == SUCCESS) {
      Object.assign(raceInfo, jsonall);
      let json = jsonall.results1;
      let json2 = jsonall.results2;
      let json3 = jsonall.results3;
      let showMarks = jsonall.showMarks;
      if (Object.keys(json).length > 0) {
        let gtri = json.getTodayRaceInfo;
        setCookie(DATETYPE, gtri.DateType); //曜日情報

        //レース開催中の場合
        //当日表示レース情報読み込み
        Object.assign(
            todayRace,
            loadTodayRaceInfo(
                params.DOR,
                gtri,
                json2,
                json3,
                json2.getChangeInfo,
                showMarks
            )
        );

        if (
            Object.keys(todayRace).length &&
            todayRace.mDateArray.length &&
            Object.keys(todayRace.placeTab).length
        ) {
          targetDOR.value = todayRace.mDateArray[0].DOR;
          targetTrackCd.value = Object.keys(todayRace.placeTab).length
              ? todayRace.placeTab[targetDOR.value][0].RacetrackCd
              : "";
          Object.assign(
              targetRaceInfo,
              todayRace.raceList[targetDOR.value][targetTrackCd.value]
          );
        }

        //変更情報取得
        let changeInfo = loadChangeInfo(
            targetDOR.value,
            "index",
            json2.getChangeInfo
        );
        Object.assign(TargetchangeInfo, changeInfo[0]);

        //発走間近情報読み込み
        const upcomingRaceInfo = loadUpcomingRaceInfo(
            json.getUpcomingRacesInfo
        );
        upcomingRaceInfo.race.forEach((element) => hassomajika.push(element));

        //厳選1頭推奨馬
        const recommend= loadRecommend(json.getRecommend);
        Object.assign(recommendData, recommend);

        //単勝1倍台情報読み込み
        const pays1x = loadPays1x(params.DOR, json.getPays1x);
        Object.assign(pays1xData, pays1x);

        //高額配当情報読み込み
        const highDividend = loadHighDividend(params.DOR, json.getHighDividend);
        Object.assign(highDividendData, highDividend);

        //固め打ち騎手情報読み込み
        const multiWinners = loadMultiWinners(params.DOR, json.getMultiWinners);
        Object.assign(multiWinnersData, multiWinners);

        //記録達成間近読み込み
        json.getEstablishRecord.top.forEach(val => establishRecordData.push(val))

        //減量騎手ランキング近読み込み
        json.getWeightLossJockeyLeading.forEach(val => weightLossJockeyData.push(val))

        //ニュース情報読み込み
        const newsHeadline = loadNewsHeadline(json.getNewsHeadline);
        Object.assign(newsHeadlineData, newsHeadline);
      }
    }
  });

  // 最終確定表示処理
  const lastFixedResultRaceInfo = await showLastFixedResultRaceInfo();
  Object.assign(kakutei, lastFixedResultRaceInfo);

  widthCheck();
  displayGuid();

  document.body.className = "status-loaded";
  loadedPage.value = true;
};

//日付タブの変更
const changeDate = (DOR) => {
  targetDOR.value = DOR;
  //競馬場タブの初期化
  targetTrackCd.value = todayRace.placeTab[targetDOR.value][0].RacetrackCd;
  //変更情報の変更
  let changeInfo = loadChangeInfo(
      targetDOR.value,
      "index",
      raceInfo.results2.getChangeInfo
  );

  Object.assign(
    targetRaceInfo, todayRace.raceList[targetDOR.value][targetTrackCd.value]
  );

  if (changeInfo[0]) {
    //変更情報取得
    Object.assign(TargetchangeInfo, changeInfo[0]);
  } else {
    for (let key in TargetchangeInfo) {
      delete TargetchangeInfo[key];
    }
  }
  setTimeout(function () {
    widthCheck()
    displayGuid()
  }, 10);
};

//競馬場タブの変更
const changeField = (racetrackCd) => {
  targetTrackCd.value = racetrackCd;

  Object.assign(
    targetRaceInfo, todayRace.raceList[targetDOR.value][targetTrackCd.value]
  )
};

//レースのURL取得
const getRaceUrl = (mainRace) => {
  const param = '?DOR=' + mainRace.DOR +
      '&RacetrackCd=' + mainRace.RacetrackCd +
      '&RaceNum=' + mainRace.RaceNum
  return mainRace.ResultFlg ? '/race/results' + param :
      mainRace.UnResultFlg === 1 ? '/race/odds/inquire' + param :
          '/race/card' + param
}

</script>
<template>
  <main class="layout-main">
    <div class="welcome" v-if="!isLogin && !isWebView">
      こちらから <router-link to="/login">ログイン</router-link> /
      <router-link to="/member">会員登録</router-link><span> してください</span>
    </div>

    <div v-if="banner" v-html="banner"></div>

    <section class="layout-section">
      <div
          v-if="userCourse === 2 || userCourse === 3 || userCourse === 4"
          class="container-announce"
      >
        <template v-if="hassomajika.length">
          <router-link
              v-for="(hasso, index) in hassomajika"
              :to="hasso.mHref"
              :key="index"
              :class="
                hasso.UnResultFlg === 1
                  ? '-nyusensokuho'
                  : hasso.fiveMin === true
                  ? '-hassomajika'
                  : ''
              "
          >
            <dl>
              <dt v-if="hasso.UnResultFlg === 1">入線速報！</dt>
              <dt v-else>発走間近！</dt>
              <dd>
                <span class="sub">{{ hasso.mArray.RaceShortName }}</span>
                <span class="main"
                >{{ hasso.raceFieldName
                  }}{{ Number(hasso.mArray.RaceNum) }}R/<time>{{
                      hasso.mTime
                    }}</time></span
                >
              </dd>
            </dl>
          </router-link>
        </template>
      </div>
      <div class="container-kakutei">
        <dl v-if="Object.keys(kakutei).length > 0">
          <dt>最新確定</dt>
          <dd>
            {{ kakutei.Racetrack }}{{ kakutei.RaceNum }}R {{ kakutei.RaceName }}
          </dd>
        </dl>
      </div>
    </section>

    <section class="layout-section">
      <div class="swiper -index" :class="buttonClass">
        <div class="swiper-wrapper">
          <div
              class="swiper-slide"
              v-for="slide in slider"
              :key="slide.tile"
          >
            <a v-if="isAtag(slide.url)" :href="slide.url">
              <img
                src="../../assets/images/index/blank_slide.png"
                v-bind:alt="slide.title"
                :style="{ 'background-image': 'url(' + slide.img + ')' }"/>
            </a>
            <router-link v-else :to="slide.url">
              <img
                src="../../assets/images/index/blank_slide.png"
                v-bind:alt="slide.title"
                :style="{ 'background-image': 'url(' + slide.img + ')' }"/>
            </router-link>

          </div>
        </div>
        <div class="swiper-button -prev"></div>
        <div class="swiper-button -next"></div>
        <div class="swiper-pagination"></div>
      </div>

      <div class="container-banner" v-if="Object.keys(advertisement).length">
        <div
          v-for="item in advertisement"
          :class="item.size"
          class="link"
        >
          <a
              v-if="item.url"
              :href="item.url"
              :target="item.window ? '_blank' : '_self'"
          >
            <img
                :src="item.img"
                v-bind:alt="item.title"
            />
          </a>
          <router-link
              v-else
              :to="'/advertisement/?id=' + item.ID"
              :target="item.window ? '_blank' : '_self'"
          >
            <img
                :src="item.img"
                v-bind:alt="item.title"
            />
          </router-link>
        </div>
      </div>
      <div class="container-wrapper -pad"
           v-if="announce.length > 0 || shouldShowFavButton">
        <ul class="container-report" v-if="announce.length > 0">
          <li v-for="item in announce">
            <a class="link-cell" v-if="item.link_url && isAtag(item.link_url)" :href="item.link_url">
              <span>{{ item.title }}</span>
              <time class="caption">{{ moment(item.date).format("YYYY.MM.DD HH:mm") }}</time>
            </a>
            <router-link v-else-if="item.link_url" class="link-cell" :to="item.link_url">
              <span>{{ item.title }}</span>
              <time class="caption">{{ moment(item.date).format("YYYY.MM.DD HH:mm") }}</time>
            </router-link>
            <router-link class="link-cell" v-else :to="item.url">
              <span>{{ item.title }}</span>
              <time class="caption">{{ moment(item.date).format("YYYY.MM.DD H:mm") }}</time>
            </router-link>
          </li>
        </ul>
        <router-link
            v-if="shouldShowFavButton"
            to="/fav/race"
            class="btn-basic -wide -center -imporntat"
        >MYお気に入り/次走狙い 出走予定</router-link
        >
      </div>
    </section>

    <section class="layout-section">
      <div class="layout-sectionheader -race">
        <h1 class="heading-section">レース情報</h1>
      </div>
      <div class="nav-tab -date -fit3">
        <ul>
          <li
              v-for="date in todayRace.mDateArray"
              :class="date.DOR === targetDOR ? '-current' : ''"
              @click="changeDate(date.DOR)"
          >
            <div class="label" v-html="date.date"></div>
          </li>
        </ul>
      </div>
      <div class="container-box">
        <div
            v-if="Object.keys(TargetchangeInfo).length"
            class="container-changeinfo"
        >
          <div class="summary">
            <span>{{ TargetchangeInfo.Contents }}</span>
          </div>
          <div class="nav">
            <router-link :to="'/info?dor=' + TargetchangeInfo.DOR" class="btn-basic"
            >変更情報一覧</router-link
            >
          </div>
        </div>
      </div>
      <div
          v-if="targetDOR.length"
          id="scrollGrid"
          class="nav-tab -scroll -hasweather"
          :class="scrollGuid && displayScroll ? '' : '-moving'"
      >
        <ul>
          <li
              v-for="place in todayRace.placeTab[targetDOR]"
              :class="place.RacetrackCd === targetTrackCd ? '-current' : ''"
              @click="changeField(place.RacetrackCd)"
              :key="place.Racetrack"
          >
            <div class="label">
              <div class="place">
                <span class="name">{{ place.Racetrack }}</span>
                <div v-if="place.isNighter" class="nighter">
                  <i class="icon-time -night"></i>
                </div>
                <div class="container-condition">
                  <div class="weather">
                    <i
                        :class="
                          place.Weather != undefined
                            ? 'icon-weather -w' + place.Weather
                            : ''
                        "
                    >{{ place.Weather === undefined ? "-" : "" }}</i
                    >
                  </div>
                  <div class="status">
                    <ul>
                      <li>
                        {{
                          place.TrackConditionCdDirt != undefined
                              ? place.TrackConditionCdDirt
                              : "-"
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="inner">
        <div
          v-if="Object.keys(targetRaceInfo).length && targetRaceInfo != undefined"
          class="layout-sub -min">
          <ul class="grid-block -cols2 -haspadding">
            <li>
              <router-link
                :to="
                  '/race?DOR=' +
                  targetRaceInfo.mainRace.DOR +
                  '&RacetrackCd=' +
                  targetRaceInfo.mainRace.RacetrackCd
                "
                class="btn-basic -hasicon -race"
                >レース一覧</router-link
              >
            </li>
            <li>
              <router-link
                :to="
                  '/race/odds/payout?DOR=' +
                  targetRaceInfo.mainRace.DOR +
                  '&RacetrackCd=' +
                  targetRaceInfo.mainRace.RacetrackCd
                "
                class="btn-basic -hasicon -payout"
                >払戻金一覧</router-link
              >
            </li>
          </ul>
        </div>
        <div
            v-if="
              Object.keys(targetRaceInfo).length &&
              todayRace.raceList[targetDOR][targetTrackCd] != undefined
            "
            class="container-line"
        >
          <div
              class="title"
              :class="
                todayRace.raceList[targetDOR][targetTrackCd].mainRace.Status === '2'
                  ? '-cancel'
                  : todayRace.raceList[targetDOR][targetTrackCd].mainRace.ResultFlg === true
                  ? '-kakutei'
                  : todayRace.raceList[targetDOR][targetTrackCd].mainRace.UnResultFlg === 1
                  ? '-mikakutei'
                  : ''
              "
          >
            <router-link :to="getRaceUrl(todayRace.raceList[targetDOR][targetTrackCd].mainRace)">
              <h3 class="heading-forbox">
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RaceMainName
                }}
                <i :class="getGradeIconClassNar(
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace.GradeCd,
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace.RaceMainName
                  )">
                </i>
              </h3>
            </router-link>
            <ul class="list-meta">
              <li>
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .racetrackCd
                }}
                {{ todayRace.raceList[targetDOR][targetTrackCd].mainRace.RaceNum >= 10 ?
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace.RaceNum :
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace.RaceNum.slice(1) }}R
              </li>
              <li>
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .TrackType
                }}{{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .DistanceShow
                }}m
              </li>
              <li>
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .StartTimeShow
                }}
                発走
              </li>
            </ul>
          </div>
          <div class="layout-sub -min">
            <ul class="grid-block -cols2 -haspadding">
              <li>
                <router-link
                    :to="
                    userCourse === 1 && !isWebView
                    ? '/member'
                    : '/race/odds/'  + oddsPath + '?DOR=' +
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace
                        .DOR +
                      '&RacetrackCd=' +
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace
                        .RacetrackCd +
                      '&RaceNum=' +
                      todayRace.raceList[targetDOR][targetTrackCd].mainRace
                        .RaceNum
                    "
                    class="btn-basic -hasicon -odds"
                >オッズ</router-link
                >
              </li>
              <li>
                <router-link
                  :to="
                  userCourse === 1
                  ? '/member'
                  : '/race/analysis?DOR=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .DOR +
                    '&RacetrackCd=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RacetrackCd +
                    '&RaceNum=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RaceNum
                  "
                  class="btn-basic -hasicon -data"
                >データ分析</router-link
                >
              </li>
            </ul>
          </div>
          <div
              v-if="
                todayRace.raceList[targetDOR][targetTrackCd].candidacyTable.length &&
                !todayRace.raceList[targetDOR][targetTrackCd].candidacyTable[0]?.iconColor.includes('NaN') &&
                todayRace.raceList[targetDOR][targetTrackCd].candidacyTable[0]?.iconColor
              "
              class="layout-sub"
          >
            <div class="layout-title -marginbottom">
              <h4 class="heading-circle">
                単勝人気<span class="caption">{{
                  todayRace.oddsObj[targetDOR][targetTrackCd]
                }}</span>
              </h4>
              <div class="nav">
                <router-link
                    :to="
                        '/race/odds/win?DOR=' +
                        todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .DOR +
                        '&RacetrackCd=' +
                        todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .RacetrackCd +
                        '&RaceNum=' +
                        todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .RaceNum
                    "
                    class="btn-basic"
                >もっと見る</router-link
                >
              </div>
              <div v-if="userCourse === 1 && !isWebView" class="system-registmember -flow">
                <div class="inner">
                  <router-link
                    to="/member"
                    target="_blank"
                    class="btn-basic -wide -center -window"
                    >こちらの機能をご利用いただくには、<br />
                    月額コースへの会員登録が必要です。
                  </router-link>
                </div>
              </div>
            </div>
            <table class="table-line -center -middle -popular">
              <tbody>
              <tr>
                <td class="ranking"><i class="icon-ranking -no1st"></i></td>
                <td class="umaban">
                  <i
                      :class="
                          'icon-umaban -waku0' +
                          todayRace.raceList[targetDOR][targetTrackCd]
                            .candidacyTable[0].BracketNum
                        "
                  >{{
                      todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].HorseNum
                    }}</i
                  >
                </td>
                <td class="horse">
                  <router-link
                      :to="
                          todayRace.raceList[targetDOR][targetTrackCd]
                            .candidacyTable[0].dataUrl
                        "
                  >{{
                      todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].horseName
                    }}</router-link
                  >
                </td>
                <td
                    class="odds"
                    v-html="
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].iconColor
                      "
                ></td>
              </tr>
              <tr>
                <td class="ranking"><i class="icon-ranking -no2st"></i></td>
                <td class="umaban">
                  <i
                      :class="
                          'icon-umaban -waku0' +
                          todayRace.raceList[targetDOR][targetTrackCd]
                            .candidacyTable[1].BracketNum
                        "
                  >{{
                      todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].HorseNum
                    }}</i
                  >
                </td>
                <td class="horse">
                  <router-link
                      :to="
                          todayRace.raceList[targetDOR][targetTrackCd]
                            .candidacyTable[1].dataUrl
                        "
                  >{{
                      todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].horseName
                    }}</router-link
                  >
                </td>
                <td
                    class="odds"
                    v-html="
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].iconColor
                      "
                ></td>
              </tr>
              <tr>
                <td class="ranking"><i class="icon-ranking -no3st"></i></td>
                <td class="umaban">
                  <i
                      :class="
                          'icon-umaban -waku0' +
                          todayRace.raceList[targetDOR][targetTrackCd]
                            .candidacyTable[2].BracketNum
                        "
                  >{{
                      todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].HorseNum
                    }}</i
                  >
                </td>
                <td class="horse">
                  <router-link
                      :to="
                          todayRace.raceList[targetDOR][targetTrackCd]
                            .candidacyTable[2].dataUrl
                        "
                  >{{
                      todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].horseName
                    }}</router-link
                  >
                </td>
                <td
                    class="odds"
                    v-html="
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].iconColor
                      "
                ></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="Object.keys(targetRaceInfo).length && todayRace.raceTrend[targetDOR][targetTrackCd] &&
                   todayRace.raceTrend[targetDOR][targetTrackCd].trend.length"
             class="container-line">
          <div class="layout-title -marginbottom">
            <h4 class="heading-circle">本日のレース傾向</h4>
            <div class="nav">
              <router-link :to="todayRace.raceTrend[targetDOR][targetTrackCd].mHref"
                 class="btn-basic">
                前回の傾向
              </router-link>
            </div>
          </div>
          <div class="layout-sub">
            <trend-table :is-jra="false"
                         :is-top="true"
                         :race-info-array="todayRace.raceTrend[targetDOR][targetTrackCd].trend"
                         :jockey-array="todayRace.raceTrend[targetDOR][targetTrackCd].jockeyArray"
                         :stud-array="todayRace.raceTrend[targetDOR][targetTrackCd].studArray"
                         :loaded-page="loadedPage"
                         :is-non-member="userCourse === 1">
            </trend-table>
          </div>
        </div>
      </div>
    </section>

    <check-point :user-course="userCourse"
                 :loaded-page="loadedPage"
                 :recommend-data="recommendData"
                 :pays1x-data="pays1xData"
                 :high-dividend-data="highDividendData"
                 :multi-winners-data="multiWinnersData"
                 :establish-record-data="establishRecordData"
                 :weight-loss-jockey-data="weightLossJockeyData">
    </check-point>

    <section
        class="layout-section"
        v-if="Object.keys(importantrace).length || Object.keys(newsHeadlineData).length"
    >
      <div class="layout-sectionheader -news">
        <h1 class="heading-section">ニュース</h1>
        <div class="nav">
          <router-link class="btn-basic" to="/news/">ニュース一覧</router-link>
        </div>
      </div>

      <ul class="list-news">
        <li
            v-if="Object.keys(importantrace).length"
            class="item"
            v-for="race in importantrace"
            :key="race.title"
        >
          <div class="summary">
            <a v-if="isAtag(race.url)" :href="race.url" class="title" v-html="race.title"></a>
            <router-link v-else :to="race.url" class="title" v-html="race.title"></router-link>
            <time class="caption"
            >{{ moment(race.date).format("YYYY.MM.DD HH:mm") }} 更新</time
            >
          </div>
          <div class="thumb">
            <img
                v-if="race.thumbnail"
                src="../../assets/images/common/blank_square.png"
                alt=""
                :style="{ 'backgroundImage': 'url(' + race.thumbnail + ')' }"
            />
            <!-- デジマース側でno image画像作成する可能性あるためコメントアウトで対応(小林) -->
            <!-- <img
                v-else
                src="../../assets/images/common/blank_square.png"
                alt=""
                style="
                  background-image: url(/assets/images/common/blank_winkeiba.png);
                "
            /> -->
          </div>
          <div class="important"><span class="icon-caution">注目!</span></div>
        </li>

        <!--以下通常NEWS-->
        <li
            v-if="Object.keys(newsHeadlineData).length"
            v-for="news in newsHeadlineData"
            :key="news.Headline"
            class="item"
        >
          <div class="summary">
            <a v-if="isAtag(news.mHref)" :href="news.mHref" class="title">{{ news.Headline }}</a>
            <router-link v-else :to="news.mHref" class="title">{{ news.Headline }}</router-link>
            <time class="caption">{{ news.mUpdateTime }} 更新</time>
          </div>
          <div class="thumb">
            <img
                v-if="news.ImageFileURL"
                src="../../assets/images/common/blank_square.png"
                alt=""
                :style="{ backgroundImage: 'url(' + news.ImageFileURL + ')' }"
            />
          </div>
        </li>
      </ul>
    </section>

    <section class="layout-section" v-if="Object.keys(campaign).length && !isSugotoku">
      <div class="layout-sectionheader -oshirase">
        <h1 class="heading-section">キャンペーン</h1>
      </div>
      <div class="container-banner">
        <div
            v-for="item in campaign"
            :key="item.title"
            :class="item.size"
            class="link"
        >
          <a
              v-if="item.url"
              :href="item.url"
              :target="item.window ? '_blank' : '_self'"
          >
            <img
                src="../../assets/images/index/blank_campaign.png"
                v-bind:alt="item.title"
                :style="{ 'background-image': 'url(' + item.img + ')' }"
            />
          </a>
          <router-link
              v-else
              :to="'/campaign/?id=' + item.ID"
              :target="item.window ? '_blank' : '_self'"
          >
            <img
                src="../../assets/images/index/blank_campaign.png"
                v-bind:alt="item.title"
                :style="{ 'background-image': 'url(' + item.img + ')' }"
            />
          </router-link>
        </div>
      </div>
    </section>

    <section class="layout-section" v-if="Object.keys(information).length &&!isWebView">
      <div class="layout-sectionheader -information">
        <h1 class="heading-section">INFORMATION</h1>
      </div>
      <ul class="list-news">
        <li
            class="item"
            v-for="info in information"
            :key="info.title"
        >
          <div class="summary">
            <a v-if="info.link_url && isAtag(info.link_url)" :href="info.link_url" target="_blank">
              <div class="title" v-html="info.title"></div>
              <time class="caption">{{moment(info.date).format("YYYY.MM.DD HH:mm")}}</time>
            </a>
            <router-link v-else-if="info.link_url" :to="info.link_url" target="_blank">
              <div class="title" v-html="info.title"></div>
              <time class="caption">{{moment(info.date).format("YYYY.MM.DD HH:mm")}}</time>
            </router-link>
            <router-link v-else :to="info.url">
              <div class="title" v-html="info.title"></div>
              <time class="caption">{{moment(info.date).format("YYYY.MM.DD HH:mm")}}</time>
            </router-link>
          </div>
        </li>
      </ul>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style scoped>
.link-cell .inner {
  display: block;
}

a {
  cursor: pointer;
}

.welcome {
  background-color: #9fc97a;
  text-align: center;
  padding: 6px 0;
}
.welcome a {
  color:white;
  text-decoration:underline;
}
.welcome span {
  color:black;
}

/* firefox レイアウト崩れ対策 */
@-moz-document url-prefix() {
  .swiper {
    height: auto !important;
  }
  .swiper-wrapper {
    height: auto !important;
  }
}
</style>

<style lang="scss" src="../../assets/css/lib/swiper/swiper.scss" scoped></style>
