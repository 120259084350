<script setup>
import {onMounted, onServerPrefetch, ref, watch} from "vue";
import Blood from "../../components/data/mare/Blood.vue";
import MainFoalLists from "../../components/data/mare/MainFoalLists.vue";
import {
  ADD,
  changeFavoriteFromCookie,
  FBROODMARE,
  getJSON,
  getUrlVars,
  getUserId,
  isFavorite,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  REMOVE,
  retrieveCourse,
  SUCCESS,
} from "../../assets/js/common";
import {BACKWORDPATH, HOST, PATH,} from "../../assets/js/define";
import {useStoreMareData} from '../../stores/data/mare-states'
import {storeToRefs} from 'pinia'
import {useRoute} from 'vue-router'
import {fetch as fetchMare} from '../../functions/data/mare'

const favClass = ref('');
const isDisplayFav = ref(false);
const loadedPage = ref(false);
const courseId = ref('');
const userId = ref('');
const tab = ref("blood");
const isCurrentTab = ref("blood");

const storeMare = useStoreMareData();
const {
  breedingNumValue,
  mareData,
  bloodData,
  crossAppend,
  offspringData,
} = storeToRefs(storeMare);

const route = useRoute();
onServerPrefetch(async () => {
  await fetchMare(route.query["BreedingNum"]);
});

watch(route, async (from, to) => {
  await fetchMare(to.query["BreedingNum"]);
})

onMounted(async () => {
  //パラメータ取得
  document.body.className = "";
  let params = getUrlVars();

  if (breedingNumValue.value !== route.query["BreedingNum"]) {
    await fetchMare(route.query["BreedingNum"]);
  }

  if (!mareData.value) {
    alert('繁殖牝馬データが存在しません。')
    loadedPage.value = true;
    document.body.className = "status-loaded";
    return;
  }

  callRetrieveCourse(async function (jsonCourseId, showMarks, jsonUserId) {
    courseId.value = jsonCourseId;
    userId.value = jsonUserId || getUserId();
    breedingNumValue.value = params.BreedingNum

    //お気に入りの表示
    if (courseId.value !== PAYINGNONE && courseId.value !== PAYINGSUGOTOKU_NONE) {
      isDisplayFav.value = true;
      favClass.value = isFavorite(FBROODMARE, breedingNumValue.value) === 1 ? '-registed' : '';
    }

    loadedPage.value = true;
    document.body.className = "status-loaded";
  });
})

const tabChange = (data) => {
  tab.value = data;
  isCurrentTab.value = data;
}

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false, '');
  }
}
const clickFav = async () => {
  try {
    document.body.className = "";
    if (isFavorite(FBROODMARE, breedingNumValue.value) === 1) {
      // お気に入り登録済みなので削除する
      const request =
        HOST +
        PATH +
        "MyFavorite/deleteMyFavoriteBroodMare.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(userId.value) +
        "&breedingNum=" +
        breedingNumValue.value;
      const order = REMOVE;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの削除に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FBROODMARE,
        breedingNumValue.value,
        order
      );
      favClass.value = favJudge ? '' : '-registed';
    } else {
      // お気に入り未登録なので登録する
      const request =
        HOST +
        PATH +
        "MyFavorite/setMyFavoriteBroodMare.js" +
        BACKWORDPATH +
        "?" +
        "userID=" +
        encodeURIComponent(userId.value) +
        "&breedingNum=" +
        breedingNumValue.value +
        "&isAuto=0";
      const order = ADD;
      await getJSON(request, false, function (status, json) {
        if (status !== SUCCESS) {
          alert("お気に入りの登録に失敗しました。");
        }
      });
      const favJudge = await changeFavoriteFromCookie(
        FBROODMARE,
        breedingNumValue.value,
        order
      );
      favClass.value = favJudge ? '-registed' : '';
    }

    document.body.className = "status-loaded";
  } catch (e) {
    console.log("error" + e);
  }
}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -horse" v-if="mareData">
      <h1 class="heading-page">繁殖牝馬データ</h1>
      <a class="btn-basic" v-if="mareData.UrlLink" :href="mareData.UrlLink">競走馬データ</a>
    </section>
    <section class="layout-section">
      <div class="summary-title">
        <div class="summary">
          <div class="inner" v-if="mareData">
            <h2 class="title">{{ mareData.HorseName }}</h2>
            <div class="caption">
              <span v-if="mareData.YOB">{{ mareData.YOB }}年</span> {{ mareData.HairColorName }}
            </div>
          </div>
          <ul class="list-icon -bottomleft">
            <li>
              <span
                v-if="isDisplayFav"
                class="icon-fav"
                :class="favClass"
                @click="clickFav"
              ></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="inner" v-if="mareData">
        <table class="table-grid -padding -data">
          <tr>
            <th>産地</th>
            <td>{{ mareData.BreedingCenterName }}</td>
          </tr>
        </table>
      </div>

      <div data-tab='["data-trigger", "data-target"]'>
        <div class="nav-tab -fit2">
          <ul data-trigger>
            <li
              @click="tabChange('blood')"
              :class="{ '-current': isCurrentTab === 'blood' }"
            >
              <span class="label">血統</span>
            </li>

            <li
              @click="tabChange('mainFoalLists')"
              :class="{ '-current': isCurrentTab === 'mainFoalLists' }"
            >
              <span class="label">産駒一覧</span>
            </li>
          </ul>
        </div>
        <div class="container-tabtarget" data-target>
          <Blood
            v-if="tab === 'blood'"
            :bloodData="bloodData"
            :crossAppend="crossAppend"
            :loadedPage="loadedPage"
          />
          <MainFoalLists
            v-if="tab === 'mainFoalLists'"
            :offspringData="offspringData"
          />
        </div>
      </div>
    </section>

    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading"/>
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/data/style.scss" scoped></style>
