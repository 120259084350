<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div @click.self="modalClose" v-if="modalOpen" class="modal-frame">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                {{ g1DataDetails.name }} / {{ g1DataDetails.totalRes }}
              </div>
              <div class="modal-body">
                <table class="table-grid">
                  <tbody>
                    <tr class="-center">
                      <th>年</th>
                      <th>馬名</th>
                      <th>人気</th>
                      <th>倍率</th>
                      <th>着順</th>
                    </tr>
                    <tr
                      v-for="(data, index) in g1DataDetails.detail"
                      :key="index"
                    >
                      <td>{{ data.year }}年</td>
                      <td>
                        <div>
                          <span
                            class="icon-umaban"
                            :class="'-' + data.horseStyle"
                          >
                            {{ data.horseNum }}</span
                          >
                          <a
                            :href="
                              '/data/horse?PedigreeNum=' + data.pedigreeNum
                            "
                            >{{ data.horseName }}</a
                          >
                        </div>
                      </td>
                      <td class="-center">
                        <span v-if="displayPopularRank(data.accidentCd)">
                          {{ data.winPopularRank }}人気
                        </span>
                      </td>
                      <td class="-center">{{ data.winOdds }}</td>
                      <td class="-right">
                        <a v-if="Number(data.year) > 2002"
                           :href="
                            '/race/results?DOR=' +
                            data.dor +
                            '&RacetrackCd=' +
                            data.trackCd +
                            '&RaceNum=' +
                            data.raceNum">
                          {{ getRanking(data.accidentCd, data.ranking) }}
                        </a>
                        <span v-else>
                          {{ getRanking(data.accidentCd, data.ranking) }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    modalOpen: Boolean,
    g1DataDetails: Object,
  },
  emits: ["modal-close"],
  setup(props, context) {
    const modalClose = () => {
      context.emit("modal-close");
    };

    return {
      props,
      modalClose,
    };
  },
  methods: {
    displayPopularRank(accidentCd) {
      //取消・除外の場合は人気を表示しない
      return Number(accidentCd) < 1 || Number(accidentCd) > 3
    },
    getRanking(accidentCd, rank) {
      switch (accidentCd) {
        case '0':
          return rank + '着'
        case '1':
          return '取消'
        case '2':
        case '3':
          return '除外'
        case '4':
          return '中止'
        case '5':
          return '失格'
        default:
          return rank + '着'
      }
    }
  }
};
</script>

<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
