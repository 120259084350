<script setup>
import {ref, reactive, onMounted, computed, toRefs, onUpdated} from "vue";
import {HOST, PATH, BACKWORDPATH, JRA_WP_HOST} from "../../../assets/js/define";
import {getJSON} from "../../../assets/js/common";
import axios from "axios";
import moment from "moment";

// APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=quit";

let list = reactive({});
onMounted(() => {
  document.body.className = "";
  const request_url = API_URL;
  axios
    .get(request_url)
    .then((response) => {
      Object.assign(list, response.data[0]);
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});

let cancellationCourseLink = HOST;

onUpdated(() => {
  init();
  document.body.className = "status-loaded";
});

function init() {
  const request = HOST + PATH + "getLoginInfo.js" + BACKWORDPATH;

  getJSON(request, false, function (status, json) {
    if (json != null) {
      const joinStatusArr = json.joinStatus.split(",");
      let forMailMember = false;

      if (checkDuplication(joinStatusArr)) {
        let cancellationTab = document.getElementById("plan-cancel");
        cancellationCourseLink += "support/quit/confirm_select";
        cancellationTab.href = cancellationCourseLink;
      } else if (json.courseId === "3" || json.courseId === "7") {
        if (
          json.payType === 1 &&
          (joinStatusArr.indexOf("12") >= 0 || joinStatusArr.indexOf("13") >= 0)
        ) {
          let cancellationTab = document.getElementById("plan-cancel");

          if (cancellationTab.href != undefined) {
            cancellationCourseLink += "support/quit/premium_credit";
            cancellationTab.href = cancellationCourseLink;
          }
        } else if (joinStatusArr.indexOf("5") >= 0) {
          let cancellationTab = document.getElementById("plan-cancel");
          if (cancellationTab.href != undefined) {
            cancellationCourseLink += "support/quit/premium";
            cancellationTab.href = cancellationCourseLink;
          }
        } else {
          let cancellationTab = document.getElementById("plan-cancel");
          if (cancellationTab.href != undefined) {
            cancellationCourseLink += "support/quit/premium";
            cancellationTab.href = cancellationCourseLink;
          }
        }
      } else if (json.courseId === "2" || json.courseId === "6") {
        let cancellationTab = document.getElementById("plan-cancel");
        if (cancellationTab.href != undefined) {
          cancellationCourseLink += "support/quit/premium_plus";
          cancellationTab.href = cancellationCourseLink;
        }
      } else if (json.courseId === "1") {
        if (json.payType === 1 && joinStatusArr.indexOf("11") >= 0) {
          let cancellationTab = document.getElementById("plan-cancel");
          if (cancellationTab.href != undefined) {
            cancellationCourseLink += "support/quit/economy_credit";
            cancellationTab.href = cancellationCourseLink;
          }
        } else {
          let cancellationTab = document.getElementById("plan-cancel");
          if (cancellationTab.href != undefined) {
            cancellationCourseLink += "support/quit/economy";
            cancellationTab.href = cancellationCourseLink;
          }
        }
      } else if (json.courseId === "0" && json.payType === 1) {
        forMailMember = true;
        let cancellationTab = document.getElementById("plan-cancel");
        if (cancellationTab.href != undefined) {
          cancellationCourseLink += "support/quit/mail_member";
          cancellationTab.href = cancellationCourseLink;
        }
      } else if (json.courseId === "11") {
        let cancellationTab = document.getElementById("plan-cancel");
        if (cancellationTab.href != undefined) {
          cancellationCourseLink += "support/quit/pack03_credit";
          cancellationTab.href = cancellationCourseLink;
        }
      } else if (json.courseId === "12") {
        let cancellationTab = document.getElementById("plan-cancel");
        if (cancellationTab.href != undefined) {
          cancellationCourseLink += "support/quit/pack06_credit";
          cancellationTab.href = cancellationCourseLink;
        }
      } else if (json.courseId === "13") {
        let cancellationTab = document.getElementById("plan-cancel");
        if (cancellationTab.href != undefined) {
          cancellationCourseLink += "support/quit/pack12_credit";
          cancellationTab.href = cancellationCourseLink;
        }
      } else {
        alert("ログインしてください。");
        let cancellationTab = document.getElementById("plan-cancel");
        cancellationTab.href = "#";
        let confirmBalanceLink = document.getElementById("confirm-balance");
        confirmBalanceLink.href = "#";
        let confirmFavLink = document.getElementById("confirm-fav");
        confirmFavLink.href = "#";
        let confirmMyPageLink = document.getElementById("confirm-mypage");
        confirmMyPageLink.href = "#";
      }

      if (forMailMember) {
        let confirmBalanceLink = document.getElementById("confirm-balance");
        let confirmFavLink = document.getElementById("confirm-fav");
        let confirmMyPageLink = document.getElementById("confirm-mypage");

        confirmBalanceLink.href = "#";
        confirmFavLink.href = "#";
        confirmMyPageLink.href = "#";
        confirmBalanceLink.classList.add("remove-quit-arrow");
        confirmFavLink.classList.add("remove-quit-arrow");
        confirmMyPageLink.classList.add("remove-quit-arrow");
      }
    }
  });
}

// 重複入会確認
const checkDuplication = (joinStatusArr) => {
  //クレジット決済のプレミアムコースとエコノミーコースに重複入会
  const premiumAndEconomy = joinStatusArr.indexOf("11") >= 0 && joinStatusArr.indexOf("12") >= 0
  //クレジット決済のエコノミーコースとパックコースに重複入会
  const economyAndPack = joinStatusArr.indexOf("11") >= 0 &&
    (joinStatusArr.indexOf("21") >= 0 || joinStatusArr.indexOf("22") >= 0 || joinStatusArr.indexOf("23") >= 0)
  //クレジット決済のプレミアムコースとパックコースに重複入会
  const premiumAndPack = (joinStatusArr.indexOf("12") >= 0 || joinStatusArr.indexOf("13") >= 0) &&
    (joinStatusArr.indexOf("21") >= 0 || joinStatusArr.indexOf("22") >= 0 || joinStatusArr.indexOf("23") >= 0)
  //クレジット決済のパックコースに重複入会
  const creditAndPack = (joinStatusArr.indexOf("21") >= 0 && joinStatusArr.indexOf("22") >= 0) ||
    (joinStatusArr.indexOf("21") >= 0 && joinStatusArr.indexOf("23") >= 0) ||
    (joinStatusArr.indexOf("22") >= 0 && joinStatusArr.indexOf("23") >= 0)
  //クレジット決済とキャリア決済の有料コースに入会
  const creditAndCareer = (joinStatusArr.indexOf("11") >= 0 || joinStatusArr.indexOf("12") >= 0 ||
      joinStatusArr.indexOf("13") >= 0 || joinStatusArr.indexOf("21") >= 0 ||
      joinStatusArr.indexOf("22") >= 0 || joinStatusArr.indexOf("23") >= 0) &&
    (joinStatusArr.indexOf("1") >= 0 || joinStatusArr.indexOf("2") >= 0 ||
      joinStatusArr.indexOf("3") >= 0 || joinStatusArr.indexOf("4") >= 0 || joinStatusArr.indexOf("5") >= 0)

  return premiumAndEconomy || economyAndPack || premiumAndPack || creditAndPack || creditAndCareer
}
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">会員解約</h1>
    </section>

    <div class="body" v-if="list.content" v-html="list.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../../assets/css/support/style.scss" scoped></style>
<style>
.remove-quit-arrow::after {
  content: none !important;
}
</style>
