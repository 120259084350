<script setup>
import {
  loadIPATLoginInfo,
  saveIPATLoginInfo,
  isIPAT_CONFIRM,
  retrieveCourse,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  getUserAgent,
} from "../../assets/js/common";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
const router = useRouter()

const inetId = ref("")
const userId = ref("")
const pass = ref("")
const pars = ref("")
const agreement = ref(false)
const isWebView = ref(false)

onMounted( () => {
  isWebView.value = getUserAgent()
  callRetrieveCourse(function (courseId, showMarks, jsonUserId) {
    if ( (courseId === PAYINGNONE && !isWebView.value) || courseId === PAYINGSUGOTOKU_NONE) {
      router.push('/member')
    } else {
      const loginInfo = loadIPATLoginInfo()

      if (Object.keys(loginInfo).length) {
        inetId.value = loginInfo.inetid
        userId.value = loginInfo.userid
        pass.value = loginInfo.pass
        pars.value = loginInfo.pars
      }

      agreement.value = isIPAT_CONFIRM();
    }
  });
  document.body.className = "status-loaded";
});

const callRetrieveCourse = function (callback) {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
}

const saveIpatData = () => {
  if (inetId.value && userId.value && pass.value && pars.value) {
    isWebView.value ? callDartForSaveIpatInfo() : setIpat()
  } else {
    alert('全ての項目に値を入力してください')
  }
}

const setIpat = () => {
  saveIPATLoginInfo(inetId.value, userId.value, pass.value, pars.value);
  router.push('/purchase/ipat')
}

const callDartForSaveIpatInfo = () => {
  saveIPATLoginInfo(inetId.value, userId.value, pass.value, pars.value);
  window.flutter_inappwebview.callHandler('save_ipat_info', {
    inet_id: inetId.value,
    user_id: userId.value,
    pars: pars.value
  })

  router.push('/purchase/ipat')
}
</script>

<template>
  <main class="layout-main">

    <section class="layout-pageheader -default">
      <h1 class="heading-page">IPAT登録内容の設定</h1>
    </section>

    <section class="layout-section">

      <div v-if="!agreement" class="p-agreement">
        IPAT連動機能の利用には<br>
        「
        <router-link to="/purchase/confirm">
          IPAT連動利用規約
        </router-link>
        」への同意が必要です。
      </div>

      <div class="inner">
        <p class="_center">IPATログイン情報を入力してください。</p>
        <table class="table-form">
          <tr>
            <th>INET-ID</th>
            <td><input v-model="inetId" type="text" class="form-text"></td>
          </tr>
          <tr>
            <th>加入者番号</th>
            <td><input v-model="userId" type="text" class="form-text"></td>
          </tr>
          <tr>
            <th>暗証番号</th>
            <td><input v-model="pass" type="password" class="form-text" pattern="*"></td>
          </tr>
          <tr>
            <th>P-ARS番号</th>
            <td><input v-model="pars" type="text" class="form-text"></td>
          </tr>
        </table>
      </div>

      <div class="nav-group -save">
        <span class="btn-basic" @click="saveIpatData()">登録情報を保存する</span>
      </div>

      <div class="nav-group">
        <router-link to="/purchase/ipat" class="btn-basic -gray -prev">購入予定一覧画面へ戻る</router-link>
      </div>
    </section>

  </main>
</template>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>