<script setup>
import { ref, onMounted, reactive, watch } from "vue";

import InnerRegister from "../../components/purchase/AnniversaryInnerRegister.vue";
import InnerSelectNumber from "../../components/purchase/AnniversaryInnerSelectNumber.vue";
import InnerSelectRace from "../../components/purchase/AnniversaryInnerSelectRace.vue";

import {
  getCookie,
  getJSON,
  retrieveCourse,
  PAYINGNONE,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  SUCCESS,
  USERID,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  RACEINFOPATH,
  JOINPATH
} from "../../assets/js/define";
import moment from "moment";

let today = ref("")
let raceHead = ref([])
let dateList = ref([])
let raceList = ref([])
let memorialData = ref([])
let mMaxRaceNum = ref(12)
let totalCount = ref(0)
let raceTrack = ref("")
let canSelect = ref(false)

onMounted(() => {
  callRetrieveCourse(function (courseId) {
    if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
      location.href = HOST + JOINPATH;
    } else if (courseId === PAYINGAPPPASS) {
      location.href = "https://www.winkeiba.jp/wp/static/apps_info_close_2022/"
      // location.href = HOST + "app_pass/incompatible";
    } else {
      getMemorialData();
      getTodayRaceInfo();
    }
  });
})

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
}
const getMemorialData = async () => {
  const userId = getCookie(USERID);
  const req =
      HOST +
      PATH +
      "MemorialTicket/memorialBettingTicketGetAPI.js" +
      BACKWORDPATH +
      "?userid=" +
      encodeURIComponent(userId) +
      "&narFlag=" +
      isNar()
  await getJSON(req, true, async function (status, json) {
    if (json && json.length > 0) {
      let purchaseNumData = json[0].MemorialBettingTicketList;
      for (let i = 0; i < purchaseNumData.length; i++) {
        purchaseNumData[i].CheckNumList = false;
      }
      memorialData.value.splice(0, memorialData.value.length)
      purchaseNumData.forEach(val => memorialData.value.push(val))
    }
  });
}
const getTodayRaceInfo = async () => {
  const req =
      HOST +
      RACEINFOPATH +
      "getTodayRaceInfo.js" +
      BACKWORDPATH +
      "?NarFlag=" +
      isNar();
  document.body.className = "";
  await getJSON(req, true, async function (status, json) {
    if (status === SUCCESS) {
      // canSelect: todayRaceInfoに表示させたい日付が存在するか
      canSelect.value = json.TargetDate.some(val => val.DOR === moment('YYYYMMDD'))
      today.value = canSelect.value ? moment('YYYYMMDD') : json.TargetDate[0].DOR;
      raceHead.value = json.TargetRaces.filter(value => value.DOR === today.value)[0].Races;
      json.TargetDate.forEach(function (value) {
        dateList.value.push({
          DOR: value.DOR,
          date: value.DOR.slice(4, 6) + "/" + value.DOR.slice(6, 8),
          week: ["日", "月", "火", "水", "木", "金", "土"][moment(value.DOR).day()]
        })
      })
      getRaceData(today.value, raceHead.value[0].RacetrackCd);
    } else {
      document.body.className = "status-loaded";
    }
  });
}
const getRaceData = async (dor, raceTrackCd) => {
  raceTrack.value = raceHead.value.find(
      (item) => item.RacetrackCd === raceTrackCd
  ).Racetrack;
  today.value = dor;
  let lst = [];
  for (let i = 0; i < mMaxRaceNum.value; i++) {
    lst.push(dor + raceTrackCd + ("0" + (i + 1)).slice(-2));
  }
  const request =
      HOST +
      PATH +
      "Master/getOddsAiO.js" +
      BACKWORDPATH +
      "?lst2=" +
      lst.join("_") +
      "&narFlag=" +
      isNar();
  document.body.className = "";
  await getJSON(request, true, async function (status, json) {
    if (status == SUCCESS) {
      let raceData2 = [];
      let k = 0;

      for (let i = 0; i < mMaxRaceNum.value; i++) {
        let key = "r" + dor + raceTrackCd + ("0" + (i + 1)).slice(-2);
        if (json[key] != undefined && json[key].length) {
          raceData2[k] = json[key][0];
          raceData2[k].StartTime =
              raceData2[k].StartTime.slice(0, 2) +
              ":" +
              raceData2[k].StartTime.slice(2, 4);
          raceData2[k].RaceUrl =
              HOST +
              "race/card?DOR=" +
              raceData2[k].DOR +
              "&RacetrackCd=" +
              raceData2[k].RacetrackCd +
              "&RaceNum=" +
              raceData2[k].RaceNum;

          //馬券が発売済みか確認
          let oddsReq =
              HOST +
              PATH +
              "Odds/winTicketOdds.js" +
              BACKWORDPATH +
              "?DOR=" +
              raceData2[k].DOR +
              "&RacetrackCd=" +
              raceData2[k].RacetrackCd +
              "&RaceNum=" +
              raceData2[k].RaceNum +
              "&NarFlag=" +
              isNar();
          
          await getJSON(oddsReq, true, async function (status, json) {
            let isReleased = false; //馬券発売フラグ
            if (status === SUCCESS) {
              //データは常に１件が正しい。
              if (json.length === 1) {
                let displayData = json[0];
                for (let j = 1; j <= 18; j++) {
                  if (displayData["Odds" + j] !== "---.-") {
                    isReleased = true;
                  }
                }
              }
            }
            raceData2[k].isReleased = isReleased;
          });
          raceData2[k].isChecked = false;

          k++;
        }
      }
      raceData2.forEach(val => raceList.value.push(val))
    }
    document.body.className = "status-loaded";
  });
}
const search = ($event) => {
  raceList.value.splice(0, raceList.value.length)
  getRaceData($event.dor, $event.raceTrackCd);
}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">記念日馬券購入</h1>
    </section>
    <section class="layout-section">
      <div class="container-box">
        <InnerRegister @add="getMemorialData" />
        <InnerSelectNumber
          :memorialData="memorialData"
          :raceList="raceList"
          :totalCount="totalCount"
          @delete="getMemorialData"
        />
        <InnerSelectRace
          :raceHead="raceHead"
          :raceList="raceList"
          :dateList="dateList"
          :canSelect="canSelect"
          :memorialData="memorialData"
          :today="today"
          :raceTrack="raceTrack"
          @search="search($event)"
          v-if="today && raceTrack && memorialData"
        />
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>
