<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import {
  getJSON,
  SUCCESS,
  PAYINGSUGOTOKU,
  PAYINGECONOMY,
  PAYINGNONE,
  PAYINGPREMIUM,
  PAYINGSUGOTOKU_NONE,
  setCookie,
  getCourse,
  DATETYPE,
  getMenmerCourse,
  isSaveINETID,
  loadIPATLoginInfo,
  replaceComma,
  getUserAgent,
  isNar,
} from "../assets/js/common";
import {
  DEBUG,
  HOST,
  PATH,
  BACKWORDPATH,
  JOINPATH,
  NAR_WP_HOST,
  JRA_WP_HOST,
} from "../assets/js/define";
import {
  showLastFixedResultRaceInfo,
  loadTodayRaceInfo,
  loadTodayRaceInfoVer2,
  loadPays1x,
  loadHighDividend,
  loadMultiWinners,
  loadUpcomingRaceInfo,
  loadNewsHeadline,
  loadChangeInfo,
  addFigure,
  getYesterday,
  innerRaceSymbol,
} from "../functions/top/common";
import { setData } from "../functions/topInit";
import axios from "axios";
import Swiper from "swiper/bundle";
import moment from "moment";

let API_URL = "";
//デバッグ用 APIURL
if (isNar() === 1) {
  API_URL = NAR_WP_HOST + "wp-json/nar/index";
} else {
  API_URL = JRA_WP_HOST + "wp-json/jra/index";
}

//スライダー表示
const initSlider = () => {
  const swiper = new Swiper(".swiper", {
    direction: "horizontal",
    autoplay: {
      delay: 2000,
    },
    loop: true,
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button.-next",
      prevEl: ".swiper-button.-prev",
    },
  });
};

let slider = reactive([]);
let announce = reactive([]);
let importantrace = reactive([]);
let campaign = reactive([]);
let information = reactive([]);
let localPrize = reactive([]);
const scrollGuid = ref(true);
const isWebView = ref(false);
const userCourse = ref(1); //1:無課金 2:300会員 3:スゴ得会員 4:1000会員
let mUserId = "";
onMounted(() => {
  //デバッグ
  //setCookie(IPAT_INETID, "sadsada11");
  isWebView.value = getUserAgent();
  axios
    .get(API_URL)
    .then((response) => {
      //console.log("response", response);
      //slider
      response.data.top_slider.forEach((element) => slider.push(element));
      initSlider();

      //announce
      response.data.announce.forEach((ele, idx) => {
        ele.url = "/announce/?id=" + ele.ID;
      });

      response.data.announce.forEach((element) => announce.push(element));

      //importantrace
      response.data.importantrace.forEach((element) =>
        importantrace.push(element)
      );

      //campaign
      response.data.campaign.forEach((element) => campaign.push(element));

      //information
      response.data.information.forEach((ele, idx) => {
        ele.url = "/information?id=" + ele.ID;
      });
      response.data.information.forEach((element) => information.push(element));

      //local_exchange_prize
      Object.assign(localPrize, response.data.local_exchange_prize);
    })
    .catch((e) => console.log(e));

  const request_id =
    new Date().getTime().toString(16) +
    Math.floor(10000000 * Math.random()).toString(16);
  const request =
    HOST +
    PATH +
    "getLoginInfo.js" +
    BACKWORDPATH +
    "/?requestid=" +
    request_id;
  getJSON(request, false, (status, json) => {
    setData(status, json);
    //ユーザコースをセット
    let courseId = PAYINGNONE;
    const req = HOST + PATH + "getUserCourse.js" + BACKWORDPATH;
    getJSON(req, true, (status, json) => {
      if (status == SUCCESS && json) {
        courseId = json.courseId;
        mUserId = json.userId;

        if (courseId === PAYINGNONE) {
          userCourse.value = 1;
        } else if (courseId === PAYINGECONOMY) {
          userCourse.value = 2;
        } else if (courseId === PAYINGSUGOTOKU) {
          userCourse.value = 3;
        } else if (courseId === PAYINGPREMIUM) {
          userCourse.value = 4;
        }

        if (courseId !== PAYINGNONE) {
          loadFav();
        }
        loadRace();
      }
    });
  });
});

onUpdated(() => {
  document.addEventListener("load", scroll());
});

const scroll = () => {
  let grid = document.getElementById("scrollGrid");
  if (grid) {
    //スクロールアクション追加
    grid.addEventListener("scroll", () => {
      scrollGuid.value = grid.scrollLeft < 10;
    });
  }
};

const loadFav = () => {
  //今週のレース情報を取得
  const request =
    HOST +
    PATH +
    "MyFavorite/getMyFavorite.js" +
    BACKWORDPATH +
    "?userID=" +
      encodeURIComponent(mUserId) +
    "&narFlag=" +
    isNar();
  getJSON(request, true, function (status, json) {});
};

const favflg = ref(false);
const nextrunflg = ref(false);
let raceInfo = reactive({});
let hassomajika = reactive([]);
let kakutei = reactive({});
let pays1xData = reactive({});
let highDividendData = reactive({});
let multiWinnersData = reactive({});
let win5Data = reactive({});
let newsHeadlineData = reactive({});
let lastWeekRaceData = reactive([]);
let nextWeekG1Data = reactive({});
let todayRace = reactive({});
let targetDOR = ref("");
let TargetchangeInfo = reactive({});
let targetTrackCd = ref("");
let targetRaceInfo = reactive({});
let loadedPage = ref(false);

let strDate = ref("");
let currentTime = ref(0);
let closingTime = ref(0);
let openingTime = ref(0);
let dateType = ref("");
const loadRace = async () => {
  document.body.className = "";

  let params;
  //本番の時
  let mDate = new Date();
  strDate.value =
    mDate.getFullYear() +
    ("0" + (mDate.getMonth() + 1)).slice(-2) +
    ("0" + mDate.getDate()).slice(-2);
  params = { DOR: strDate.value };

  //お気に入り競走馬の取得
  const request =
    HOST +
    PATH +
    "MyFavorite/getMyFavoriteRacehorseAll.js" +
    BACKWORDPATH +
    "?userID=" +
      encodeURIComponent(mUserId);
  let messageFlg = true;
  let mainKey = [];
  await getJSON(request, messageFlg, function (status, json) {
    if (status == SUCCESS) {
      try {
        if (json != undefined && json != null && json.length > 0) {
          //お気に入り競走馬から検索条件を作成
          for (let i = 0; i < json.length; i++) {
            mainKey.push(json[i].PedigreeNum);
          }

          //今週のレース情報を取得
          const request =
            HOST +
            PATH +
            "Master/weeklyRacingUpdateAPI.js" +
            BACKWORDPATH +
            "?ViewKbn=4&MainKey=" +
            mainKey +
            "&NarFlag=" +
            isNar();
          getJSON(request, messageFlg, function (statusWeekly, jsonWeekly) {
            if (status == SUCCESS) {
              try {
                if (
                  jsonWeekly != undefined &&
                  jsonWeekly != null &&
                  jsonWeekly.length > 0
                ) {
                  favflg.value = true;
                } else {
                  //特別レース登録情報を取得
                  const request =
                    HOST +
                    PATH +
                    "Master/getSpecialEntryInfoAPI.js" +
                    BACKWORDPATH +
                    "?MainKey=" +
                    mainKey +
                    "&NarFlag=" +
                    isNar();
                  getJSON(
                    request,
                    messageFlg,
                    function (statusSpecial, jsonSpecial) {
                      if (status == SUCCESS) {
                        try {
                          if (
                            jsonSpecial != undefined &&
                            jsonSpecial != null &&
                            jsonSpecial.length > 0
                          ) {
                            favflg.value = true;
                          }
                        } catch (e) {
                          alert(
                            "お気に入り競走馬の特別レース登録情報の取得に失敗しました。" +
                              e
                          );
                        }
                      }
                    }
                  );
                }
              } catch (e) {
                alert("お気に入り競走馬のレース情報の取得に失敗しました。" + e);
              }
            }
          });
        }
      } catch (e) {
        alert("お気に入り競走馬の取得に失敗しました。" + e);
      }
    }
  });

  //次走狙い馬の取得
  const requestNextTarget =
    HOST +
    PATH +
    "MyNextTarget/getMyNextTargetRacehorseAll.js" +
    BACKWORDPATH +
    "?userID=" +
      encodeURIComponent(mUserId);
    messageFlg = true;
    mainKey = [];
  await getJSON(requestNextTarget, messageFlg, function (status, json) {
    if (status == SUCCESS) {
      try {
        if (json != undefined && json != null && json.length > 0) {
          //次走狙い馬から検索条件を作成
          for (let i = 0; i < json.length; i++) {
            mainKey.push(json[i].PedigreeNum);
          }

          //今週のレース情報を取得
          const requestNextTarget =
            HOST +
            PATH +
            "Master/weeklyRacingUpdateAPI.js" +
            BACKWORDPATH +
            "?ViewKbn=5&MainKey=" +
            mainKey +
            "&NarFlag=" +
            isNar();
          getJSON(requestNextTarget, messageFlg, function (statusWeekly, jsonWeekly) {
            if (status == SUCCESS) {
              try {
                if (
                  jsonWeekly != undefined &&
                  jsonWeekly != null &&
                  jsonWeekly.length > 0
                ) {
                  nextrunflg.value = true;
                } else {
                  //特別レース登録情報を取得
                  const requestNextTarget =
                    HOST +
                    PATH +
                    "Master/getSpecialEntryInfoAPI.js" +
                    BACKWORDPATH +
                    "?ViewKbn=5&MainKey=" +
                    mainKey +
                    "&NarFlag=" +
                    isNar();
                  getJSON(
                    requestNextTarget,
                    messageFlg,
                    function (statusSpecial, jsonSpecial) {
                      if (status == SUCCESS) {
                        try {
                          if (
                            jsonSpecial != undefined &&
                            jsonSpecial != null &&
                            jsonSpecial.length > 0
                          ) {
                            nextrunflg.value = true;
                          }
                        } catch (e) {
                          alert(
                            "次走狙い馬の特別レース登録情報の取得に失敗しました。" +
                              e
                          );
                        }
                      }
                    }
                  );
                }
              } catch (e) {
                alert("次走狙い馬のレース情報の取得に失敗しました。" + e);
              }
            }
          });
        }
      } catch (e) {
        alert("次走狙い馬の取得に失敗しました。" + e);
      }
    }
  });
  //APIコールstep1
  let req =
    HOST +
    PATH +
    "Top/getTopInfo.js" +
    BACKWORDPATH +
    "?dor=" +
    params.DOR +
    "&narFlag=" +
    isNar();

  await getJSON(req, true, function (status, jsonall) {
    if (status == SUCCESS) {
      Object.assign(raceInfo, jsonall);
      let json = jsonall.results1;
      let json2 = jsonall.results2;
      let json3 = jsonall.results3;
      let showMarks = jsonall.showMarks;
      if (Object.keys(json).length > 0) {
        let gtri = json.getTodayRaceInfo;
        dateType.value = gtri.DateType;
        setCookie(DATETYPE, gtri.DateType); //曜日情報

        //当日表示レース情報読み込み
        if (dateType.value === "0") {
          //レース開催中の場合
          Object.assign(
            todayRace,
            loadTodayRaceInfo(
              params.DOR,
              gtri,
              json2,
              json3,
              json2.getChangeInfo,
              showMarks
            )
          );

          if (
            Object.keys(todayRace).length &&
            todayRace.mDateArray.length &&
            Object.keys(todayRace.placeTab).length
          ) {
            targetDOR.value = todayRace.mDateArray[0].DOR;
            targetTrackCd.value = Object.keys(todayRace.placeTab).length
              ? todayRace.placeTab[targetDOR.value][0].RacetrackCd
              : "";
            Object.assign(
              targetRaceInfo,
              todayRace.raceList[targetDOR.value][targetTrackCd.value]
            );
          }

          //変更情報取得
          let changeInfo = loadChangeInfo(
            targetDOR.value,
            "index",
            json2.getChangeInfo
          );
          Object.assign(TargetchangeInfo, changeInfo[0]);

          //発走間近情報読み込み
          const upcomingRaceInfo = loadUpcomingRaceInfo(
            json.getUpcomingRacesInfo
          );
          upcomingRaceInfo.race.forEach((element) => hassomajika.push(element));

          //単勝1倍台情報読み込み
          const pays1x = loadPays1x(params.DOR, json.getPays1x);
          Object.assign(pays1xData, pays1x);

          //高額配当情報読み込み
          const highDividend = loadHighDividend(
            params.DOR,
            json.getHighDividend
          );
          Object.assign(highDividendData, highDividend);

          //固め打ち騎手情報読み込み
          const multiWinners = loadMultiWinners(
            params.DOR,
            json.getMultiWinners
          );
          Object.assign(multiWinnersData, multiWinners);

          //WIN5情報読み込み（JRAのみ）
          if (isNar() === 0) {
            Object.assign(win5Data, json.getWin5TopInfo);
            const mDate = new Date();
            currentTime.value = mDate.getHours() * 60 + mDate.getMinutes();
            closingTime.value =
              Math.floor(win5Data.ClosingTime / 10000) * 60 +
              Math.floor((win5Data.ClosingTime % 10000) / 100);
            openingTime.value = 18 * 60 + 30;

            showRemainsVote();
          }
        } else {
          //レース非開催日の場合
          Object.assign(
            todayRace,
            loadTodayRaceInfoVer2(
              params.DOR,
              gtri,
              json2,
              json3,
              json2.getChangeInfo
            )
          );

          //先週の開催結果読み込み
          Object.assign(lastWeekRaceData, json2.getLastWeekPatternRacesResult);

          // 次週開催G1情報読み込み
          Object.assign(nextWeekG1Data, json2.getNextWeekG1RacesInfo);
        }

        //ニュース情報読み込み
        const newsHeadline = loadNewsHeadline(json.getNewsHeadline);
        Object.assign(newsHeadlineData, newsHeadline);
      }
    }
  });

  // 最終確定表示処理
  const lastFixedResultRaceInfo = await showLastFixedResultRaceInfo();
  Object.assign(kakutei, lastFixedResultRaceInfo);

  document.body.className = "status-loaded";
  loadedPage.value = true;
};

//日付タブの変更
const changeDate = (DOR) => {
  targetDOR.value = DOR;
  //競馬場タブの初期化
  targetTrackCd.value = todayRace.placeTab[targetDOR.value][0].RacetrackCd;
  //変更情報の変更
  let changeInfo = loadChangeInfo(
    targetDOR.value,
    "index",
    raceInfo.results2.getChangeInfo
  );

  if (changeInfo[0]) {
    //変更情報取得
    Object.assign(TargetchangeInfo, changeInfo[0]);
  } else {
    for (let key in TargetchangeInfo) {
      delete TargetchangeInfo[key];
    }
  }
};

//競馬場タブの変更
const changeField = (racetrackCd) => {
  targetTrackCd.value = racetrackCd;
};

//WIN5購入ボタン押下処理
const purchaseWIN5Flg = ref(false);
const purchaseWIN5 = () => {
  document.body.className = "";
  if (
    isNar() === 0 &&
    (/^0/.test(dateType.value) ||
      (/^2/.test(dateType.value) && strDate.value === json.DOR) ||
      (currentTime.value - openingTime.value >= 0 &&
        strDate.value === yesterday))
  ) {
    //#1363 #4935 非会員の場合は入会画面へ遷移
    if (getMenmerCourse() === PAYINGNONE) {
      location.href = HOST + JOINPATH;
      return;
    } else if (getMenmerCourse() === PAYINGSUGOTOKU_NONE) {
      location.href = location.pathname + "?url=" + HOST + "joinSugotoku.js";
      return;
    } else {
      if (isSaveINETID() == false) {
        //INET ID未入力の場合
        location.href = "./purchase/ipat/setting?ipatBet=gowin5";
        return;
      }
      let loginInfo = loadIPATLoginInfo();
      const request =
        HOST +
        PATH +
        "Odds/purchase.js" +
        BACKWORDPATH +
        "?inetId=" +
        loginInfo.inetid +
        "&uid=" +
        loginInfo.userid +
        "&pass=" +
        loginInfo.pass +
        "&pars=" +
        loginInfo.pars;
      getJSON(request, true, function (status, json) {
        if (status == SUCCESS) {
          try {
            if (json.status == "NG") {
              alert(json.message);
              return false;
            }
            //https://www.ipat.jra.go.jp/sp/pw_760_i.cgi?uh=json.jra_uh&inetid=json.jra_inetid&g=732&u=json.jra_u&nm=json.jra_nm&zj=json.jra_zj
            //戻り値でログイン情報を設定
            const url =
              "https://www.ipat.jra.go.jp/sp/pw_760_i.cgi?uh=" +
              json.jra_uh +
              "&inetid=" +
              json.jra_inetid +
              "&g=732&u=" +
              json.jra_u +
              "&nm=" +
              json.jra_nm +
              "&zj=" +
              json.jra_zj;
            //遷移
            document.body.className = "status-loaded";
            location.href = url;
          } catch (e) {
            if (DEBUG) alert(e);
            document.body.className = "status-loaded";
          }
        }
      });
    }
  } else {
    alert("WIN5は現在購入できません。");
    document.body.className = "status-loaded";
  }
};

//WIN5 払戻金・票数表示
const sellVote = ref("");
const hitVote = ref("");
const refundSum = ref("");
const remainVote = ref("");
const showRemainsVote = () => {
  if (isNar() === 0 && Object.keys(win5Data).length > 0) {
    sellVote.value = replaceComma(win5Data.SellVote);
    if (win5Data.RefundInfo.length > 0) {
      //全レース確定時には、的中票数/発売票数を表示
      let refund = win5Data.RefundInfo[0].Refund;
      let hit = win5Data.RefundInfo[0].HitVote;
      if (refund === 0 && hit === 0) {
        refund = "-"; //#4910 的中がない場合の払戻金表示修正 0円→-円
      }
      hitVote.value = replaceComma(hit);
      refundSum.value = replaceComma(refund);
    } else {
      //確定前の場合は、RemainsVoteが格納されている最後のデータを表示
      let mArray = win5Data.SearchHorseList;
      let isResult = false;
      for (let i = 0; i < mArray.length; i++) {
        let vote = "";
        //描画
        if (
          "HorseNum" in mArray[i] &&
          mArray[i].HorseNum != null &&
          mArray[i].HorseNum != ""
        ) {
          //「残り票数/発売票数」
          if (
            "RemainsVote" in mArray[i] &&
            mArray[i].RemainsVote != null &&
            mArray[i].RemainsVote != ""
          ) {
            vote = replaceComma(mArray[i].RemainsVote);
          }
        }
        //「残り票数/発売票数」表示
        if (
          vote != "" &&
          win5Data.RefundInfo.length == 0 &&
          i == mArray.length - 1
        ) {
          remainVote.value = vote;
        }
      }
    }
  }
};

//レース表示部で使用する日付フォーマット取得関数（例：○/△（□））
const getDisplayDateStrForMain = (yyyymmdd) => {
  let DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  let month = parseInt(yyyymmdd.slice(4, 6), 10);
  let date = parseInt(yyyymmdd.slice(6, 8), 10);
  let mDate = new Date(parseInt(yyyymmdd.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day == "土") {
    day = '<span class="_sat">' + day + "</span>";
  } else if (day == "日") {
    day = '<span class="_sun">' + day + "</span>";
  }
  let str = month + "/" + date + "(" + day + ")";

  return str;
};
</script>
<template>
  <main class="layout-main">
    <section v-if="dateType === '0'" class="layout-section">
      <div
        v-if="userCourse === 2 || userCourse === 3 || userCourse === 4"
        class="container-announce"
      >
        <template v-if="hassomajika.length">
          <a
            v-for="(hasso, index) in hassomajika"
            :href="hasso.mHref"
            :key="index"
            :class="
              hasso.UnResultFlg === 1
                ? '-nyusensokuho'
                : hasso.fiveMin === true
                ? '-hassomajika'
                : ''
            "
          >
            <dl>
              <dt v-if="hasso.UnResultFlg === 1">入線速報！</dt>
              <dt v-else>発走間近！</dt>
              <dd>
                <span class="sub">{{ hasso.mArray.RaceShortName }}</span>
                <span class="main"
                  >{{ hasso.raceFieldName
                  }}{{ Number(hasso.mArray.RaceNum) }}R/<time>{{
                    hasso.mTime
                  }}</time></span
                >
              </dd>
            </dl>
          </a>
        </template>
      </div>
      <div class="container-kakutei">
        <dl v-if="Object.keys(kakutei).length > 0">
          <dt>最新確定</dt>
          <dd>
            {{ kakutei.Racetrack }}{{ kakutei.RaceNum }}R {{ kakutei.RaceName }}
          </dd>
        </dl>
      </div>
    </section>

    <section class="layout-section">
      <div class="swiper -index">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="slide in slider">
            <a :href="isWebView ? '' : slide.url" target="_blank"
              ><img
                src="../assets/images/index/blank_slide.png"
                v-bind:alt="slide.title"
                :style="{ 'background-image': 'url(' + slide.img + ')' }"
            /></a>
          </div>
        </div>
        <div class="swiper-button -prev"></div>
        <div class="swiper-button -next"></div>
        <div class="swiper-pagination"></div>
      </div>

      <div class="container-wrapper -pad">
        <ul class="container-report">
          <li v-for="item in announce">
            <a
              class="link-cell"
              v-if="item.link_url"
              :href="item.link_url"
              target="_blank"
              ><span>{{ item.title }}</span
              ><time class="caption">{{
                moment(item.date).format("YYYY.MM.DD HH:mm")
              }}</time></a
            >
            <a class="link-cell" v-else v-bind:href="item.url"
              ><span>{{ item.title }}</span
              ><time class="caption">{{
                moment(item.date).format("YYYY.MM.DD H:mm")
              }}</time></a
            >
          </li>
        </ul>
        <a
          v-if="(favflg || nextrunflg) && (userCourse === 3 || userCourse === 4)"
          href="/fav/race"
          class="btn-basic -wide -center -imporntat"
          >MYお気に入り/次走狙い 出走予定</a
        >
      </div>
    </section>

    <section
      v-if="dateType === '0' && 'mDateArray' in todayRace"
      class="layout-section"
    >
      <!-- レース開催日 -->
      <div class="layout-sectionheader -race">
        <h1 class="heading-section">レース情報</h1>
      </div>
      <div
        class="nav-tab -date"
        :class="todayRace.mDateArray.length === 3 ? '-fit3' : '-fit2'"
      >
        <ul>
          <li
            v-for="date in todayRace.mDateArray"
            :class="date.DOR === targetDOR ? '-current' : ''"
            @click="changeDate(date.DOR)"
          >
            <div class="label" v-html="date.date"></div>
          </li>
        </ul>
      </div>
      <div class="container-box">
        <div
          v-if="Object.keys(TargetchangeInfo).length"
          class="container-changeinfo"
        >
          <div class="summary">
            <span>{{ TargetchangeInfo.Type }}</span>
          </div>
          <div class="nav">
            <a :href="'info?dor=' + TargetchangeInfo.DOR" class="btn-basic"
              >変更情報一覧</a
            >
          </div>
        </div>
      </div>
      <div
        v-if="targetDOR.length && isNar() === 1"
        id="scrollGrid"
        class="nav-tab -scroll -hasweather"
        :class="scrollGuid === true ? '' : '-moving'"
      >
        <ul>
          <li
            v-for="place in todayRace.placeTab[targetDOR]"
            :class="place.RacetrackCd === targetTrackCd ? '-current' : ''"
            @click="changeField(place.RacetrackCd)"
          >
            <div class="label">
              <div class="place">
                <span class="name">{{ place.Racetrack }}</span>
                <div v-if="place.isNighter" class="nighter">
                  <i class="icon-time -night"></i>
                </div>
                <div class="container-condition">
                  <div class="weather">
                    <i
                      :class="
                        place.Weather != undefined
                          ? 'icon-weather -w' + place.Weather
                          : ''
                      "
                      >{{ place.Weather === undefined ? "-" : "" }}</i
                    >
                  </div>
                  <div class="status">
                    <ul>
                      <li>
                        {{
                          place.TrackConditionCdDirt != undefined &&
                          place.TrackConditionCdDirt != ""
                            ? place.TrackConditionCdDirt
                            : "-"
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-else-if="targetDOR.length && isNar() === 0"
        class="nav-tab -hasweather -jra"
        :class="todayRace.placeTab[targetDOR].length === 3 ? '-fit3' : '-fit2'"
      >
        <ul>
          <li
            v-for="place in todayRace.placeTab[targetDOR]"
            :class="place.RacetrackCd === targetTrackCd ? '-current' : ''"
            @click="changeField(place.RacetrackCd)"
          >
            <div class="label">
              <div class="place">
                <span class="name">{{ place.Racetrack }}</span>
                <div class="container-condition -jra">
                  <div class="weather">
                    <i
                      :class="
                        place.Weather != undefined
                          ? 'icon-weather -w' + place.Weather
                          : ''
                      "
                      >{{ place.Weather === undefined ? "-" : "" }}</i
                    >
                  </div>
                  <div class="status">
                    <ul>
                      <li>
                        {{
                          place.TrackConditionCdTurf != undefined &&
                          place.TrackConditionCdTurf != ""
                            ? "芝/" + place.TrackConditionCdTurf
                            : "芝/-"
                        }}
                      </li>
                      <li>
                        {{
                          place.TrackConditionCdDirt != undefined &&
                          place.TrackConditionCdDirt != ""
                            ? "ダ/" + place.TrackConditionCdDirt
                            : "ダ/-"
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="inner">
        <div v-if="isNar() === 0" class="layout-sub -min">
          <ul class="grid-block -cols2 -haspadding -jra">
            <li>
              <a
                :href="
                  '/race?DOR=' +
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR +
                  '&RacetrackCd=' +
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .RacetrackCd
                "
                class="btn-basic -hasicon -race"
                >レース一覧</a
              >
            </li>
            <li>
              <a
                :href="
                  '/race/odds/payout?DOR=' +
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR +
                  '&RacetrackCd=' +
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .RacetrackCd
                "
                class="btn-basic -hasicon -payout"
                >払戻金一覧</a
              >
            </li>
          </ul>
        </div>
        <div
          v-if="
            Object.keys(targetRaceInfo).length &&
            todayRace.raceList[targetDOR][targetTrackCd] != undefined
          "
          class="container-line"
        >
          <div class="title">
            <a
              :href="
                '/race/card?DOR=' +
                todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR +
                '&RacetrackCd=' +
                todayRace.raceList[targetDOR][targetTrackCd].mainRace
                  .RacetrackCd +
                '&RaceNum=' +
                todayRace.raceList[targetDOR][targetTrackCd].mainRace.RaceNum
              "
            >
              <h3 class="heading-forbox">
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .RaceMainName
                }}<i
                  :class="
                    isNar() === 1
                      ? todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .GradeCd === 'A'
                        ? 'icon-race -jpn1'
                        : todayRace.raceList[targetDOR][targetTrackCd].mainRace
                            .GradeCd === 'B'
                        ? 'icon-race -jpn2'
                        : todayRace.raceList[targetDOR][targetTrackCd].mainRace
                            .GradeCd === 'C'
                        ? 'icon-race -jpn3'
                        : ''
                      : todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .GradeCd === 'A'
                      ? 'icon-race -g1'
                      : todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .GradeCd === 'B'
                      ? 'icon-race -g2'
                      : todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .GradeCd === 'C'
                      ? 'icon-race -g3'
                      : ''
                  "
                ></i>
              </h3>
            </a>
            <ul class="list-meta">
              <li>
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .racetrackCd
                }}
              </li>
              <li>
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .TrackTypeCd
                }}{{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .DistanceShow
                }}m
              </li>
              <li>
                {{
                  todayRace.raceList[targetDOR][targetTrackCd].mainRace
                    .StartTimeShow
                }}
                発走
              </li>
            </ul>
          </div>
          <div class="layout-sub -min">
            <ul
              class="grid-block -haspadding"
              :class="isNar() === 0 ? '-cols3' : '-cols2'"
            >
              <li>
                <a
                  :href="
                    '/race/odds/win?DOR=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR +
                    '&RacetrackCd=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RacetrackCd +
                    '&RaceNum=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RaceNum
                  "
                  class="btn-basic -hasicon -odds"
                  :class="userCourse === 1 ? '-disable' : ''"
                  >オッズ</a
                >
              </li>
              <li>
                <a
                  :href="
                    '/race/analysis?DOR=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR +
                    '&RacetrackCd=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RacetrackCd +
                    '&RaceNum=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RaceNum
                  "
                  class="btn-basic -hasicon -data"
                  :class="userCourse === 1 ? '-disable' : ''"
                  >データ分析</a
                >
              </li>
              <li v-if="isNar() === 0">
                <a
                  :href="
                    userCourse === 4 || userCourse === 3
                      ? '/race/marks?DOR=' +
                        todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .DOR +
                        '&RacetrackCd=' +
                        todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .RacetrackCd +
                        '&RaceNum=' +
                        todayRace.raceList[targetDOR][targetTrackCd].mainRace
                          .RaceNum
                      : userCourse === 2
                      ? '/member/join_economy'
                      : '/member'
                  "
                  class="btn-basic -hasicon -newspaper"
                  >新聞印<i v-if="userCourse != 4" class="icon-premium"></i
                ></a>
              </li>
            </ul>
          </div>
          <div
            v-if="
              todayRace.raceList[targetDOR][targetTrackCd].candidacyTable.length
            "
            class="layout-sub"
          >
            <div class="layout-title -marginbottom">
              <h4 class="heading-circle">
                単勝人気<span class="caption">{{
                  todayRace.oddsObj[targetDOR][targetTrackCd]
                }}</span>
              </h4>
              <div class="nav">
                <a
                  :href="
                    '/race/odds/win?DOR=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace.DOR +
                    '&RacetrackCd=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RacetrackCd +
                    '&RaceNum=' +
                    todayRace.raceList[targetDOR][targetTrackCd].mainRace
                      .RaceNum
                  "
                  class="btn-basic"
                  >もっと見る</a
                >
              </div>
            </div>
            <table class="table-line -center -middle -popular">
              <tbody>
                <tr>
                  <td class="ranking"><i class="icon-ranking -no1st"></i></td>
                  <td class="umaban">
                    <i
                      :class="
                        'icon-umaban -waku0' +
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].BracketNum
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].HorseNum
                      }}</i
                    >
                  </td>
                  <td class="horse">
                    <a
                      :href="
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].dataUrl
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[0].horseName
                      }}</a
                    >
                  </td>
                  <td
                    class="odds"
                    v-html="
                      todayRace.raceList[targetDOR][targetTrackCd]
                        .candidacyTable[0].iconColor
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="ranking"><i class="icon-ranking -no2st"></i></td>
                  <td class="umaban">
                    <i
                      :class="
                        'icon-umaban -waku0' +
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].BracketNum
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].HorseNum
                      }}</i
                    >
                  </td>
                  <td class="horse">
                    <a
                      :href="
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].dataUrl
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[1].horseName
                      }}</a
                    >
                  </td>
                  <td
                    class="odds"
                    v-html="
                      todayRace.raceList[targetDOR][targetTrackCd]
                        .candidacyTable[1].iconColor
                    "
                  ></td>
                </tr>
                <tr>
                  <td class="ranking"><i class="icon-ranking -no3st"></i></td>
                  <td class="umaban">
                    <i
                      :class="
                        'icon-umaban -waku0' +
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].BracketNum
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].HorseNum
                      }}</i
                    >
                  </td>
                  <td class="horse">
                    <a
                      :href="
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].dataUrl
                      "
                      >{{
                        todayRace.raceList[targetDOR][targetTrackCd]
                          .candidacyTable[2].horseName
                      }}</a
                    >
                  </td>
                  <td
                    class="odds"
                    v-html="
                      todayRace.raceList[targetDOR][targetTrackCd]
                        .candidacyTable[2].iconColor
                    "
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="
            Object.keys(targetRaceInfo).length &&
            todayRace.raceTrend[targetDOR][targetTrackCd] != undefined &&
            !todayRace.raceTrend[targetDOR][targetTrackCd]?.noTrend === true
          "
          class="container-line"
        >
          <div class="layout-title -marginbottom">
            <h4 class="heading-circle">本日のレース傾向</h4>
            <div class="nav">
              <a
                :href="todayRace.raceTrend[targetDOR][targetTrackCd].mHref"
                class="btn-basic"
                :class="userCourse === 1 ? '-disable' : ''"
                >前回の傾向</a
              >
            </div>
          </div>
          <div class="layout-sub">
            <div v-if="userCourse === 1" class="system-registmember -flow">
              <div class="inner">
                <a
                  href="/member"
                  target="_blank"
                  class="btn-basic -wide -center -window"
                  >こちらの機能をご利用いただくには、<br />
                  月額コースへの会員登録が必要です。</a
                >
              </div>
            </div>
            <table class="table-grid -middle -center -trend">
              <thead>
                <tr>
                  <th>R</th>
                  <th>距離</th>
                  <th>馬場<br />状態</th>
                  <th>決め手</th>
                  <th>勝馬<br />通過順</th>
                  <th>勝馬<br />馬番</th>
                  <th>勝利騎手</th>
                </tr>
              </thead>
              <tbody v-if="userCourse === 1 || isNar() === 1">
                <tr
                  :class="
                    isNar() === 1
                      ? '-dart'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                          .trackType === '芝'
                      ? '-turf'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                          .trackType === 'ダ'
                      ? '-dart'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                          .trackType === '障'
                      ? '-hurdle'
                      : ''
                  "
                >
                  <td class="race">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                        .RaceNum
                    }}<i
                      :class="
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                          .WeatherCd != undefined
                          ? 'icon-weather -w' +
                            todayRace.raceTrend[targetDOR][targetTrackCd]
                              .trend[0].WeatherCd
                          : 'icon-weather'
                      "
                    ></i>
                  </td>
                  <td class="distance">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                        .trackType
                    }}{{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                        .distance
                    }}
                  </td>
                  <td class="baba">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                        .surface
                    }}
                  </td>
                  <td
                    class="base"
                    v-html="
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                        .runningStyle
                    "
                  ></td>
                  <td class="passing">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                        .corneringOrder
                    }}
                  </td>
                  <td
                    :class="
                      'umaban bgcolor-waku0' +
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                        .bracketNum
                    "
                  >
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                        .horseNum
                    }}
                  </td>
                  <td class="jockey">
                    <a
                      :href="
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                          .jockeyHref
                      "
                    >
                      {{
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[0]
                          .jockeyName
                      }}</a
                    >
                  </td>
                </tr>
                <tr
                  :class="
                    isNar() === 1
                      ? '-dart'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                          .trackType === '芝'
                      ? '-turf'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                          .trackType === 'ダ'
                      ? '-dart'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                          .trackType === '障'
                      ? '-hurdle'
                      : ''
                  "
                >
                  <td class="race">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                        .RaceNum
                    }}<i
                      :class="
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                          .WeatherCd != undefined
                          ? 'icon-weather -w' +
                            todayRace.raceTrend[targetDOR][targetTrackCd]
                              .trend[1].WeatherCd
                          : 'icon-weather'
                      "
                    ></i>
                  </td>
                  <td class="distance">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                        .trackType
                    }}{{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                        .distance
                    }}
                  </td>
                  <td class="baba">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                        .surface
                    }}
                  </td>
                  <td
                    class="base"
                    v-html="
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                        .runningStyle
                    "
                  ></td>
                  <td class="passing">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                        .corneringOrder
                    }}
                  </td>
                  <td
                    :class="
                      'umaban bgcolor-waku0' +
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                        .bracketNum
                    "
                  >
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                        .horseNum
                    }}
                  </td>
                  <td class="jockey">
                    <a
                      :href="
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                          .jockeyHref
                      "
                    >
                      {{
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[1]
                          .jockeyName
                      }}</a
                    >
                  </td>
                </tr>

                <tr
                  :class="
                    isNar() === 1
                      ? '-dart'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                          .trackType === '芝'
                      ? '-turf'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                          .trackType === 'ダ'
                      ? '-dart'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                          .trackType === '障'
                      ? '-hurdle'
                      : ''
                  "
                >
                  <td class="race">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                        .RaceNum
                    }}<i
                      :class="
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                          .WeatherCd != undefined
                          ? 'icon-weather -w' +
                            todayRace.raceTrend[targetDOR][targetTrackCd]
                              .trend[2].WeatherCd
                          : 'icon-weather'
                      "
                    ></i>
                  </td>
                  <td class="distance">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                        .trackType
                    }}{{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                        .distance
                    }}
                  </td>
                  <td class="baba">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                        .surface
                    }}
                  </td>
                  <td
                    class="base"
                    v-html="
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                        .runningStyle
                    "
                  ></td>
                  <td class="passing">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                        .corneringOrder
                    }}
                  </td>
                  <td
                    :class="
                      'umaban bgcolor-waku0' +
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                        .bracketNum
                    "
                  >
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                        .horseNum
                    }}
                  </td>
                  <td class="jockey">
                    <a
                      :href="
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                          .jockeyHref
                      "
                    >
                      {{
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[2]
                          .jockeyName
                      }}</a
                    >
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else-if="isNar() === 0"
                v-for="(trend, index) in todayRace.raceTrend[targetDOR][
                  targetTrackCd
                ].trend"
                :key="index"
              >
                <tr
                  :class="
                    isNar() === 1
                      ? '-dart'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[
                          index
                        ].trackType === '芝'
                      ? '-turf'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[
                          index
                        ].trackType === 'ダ'
                      ? '-dart'
                      : todayRace.raceTrend[targetDOR][targetTrackCd].trend[
                          index
                        ].trackType === '障'
                      ? '-hurdle'
                      : ''
                  "
                >
                  <td class="race">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[index]
                        .RaceNum
                    }}<i
                      :class="
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[
                          index
                        ].WeatherCd != undefined
                          ? 'icon-weather -w' +
                            todayRace.raceTrend[targetDOR][targetTrackCd].trend[
                              index
                            ].WeatherCd
                          : 'icon-weather'
                      "
                    ></i>
                  </td>
                  <td class="distance">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[index]
                        .trackType
                    }}{{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[index]
                        .distance
                    }}
                  </td>
                  <td class="baba">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[index]
                        .surface
                    }}
                  </td>
                  <td
                    class="base"
                    v-html="
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[index]
                        .runningStyle
                    "
                  ></td>
                  <td class="passing">
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[index]
                        .corneringOrder
                    }}
                  </td>
                  <td
                    :class="
                      'umaban bgcolor-waku0' +
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[index]
                        .bracketNum
                    "
                  >
                    {{
                      todayRace.raceTrend[targetDOR][targetTrackCd].trend[index]
                        .horseNum
                    }}
                  </td>
                  <td class="jockey">
                    <a
                      :href="
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[
                          index
                        ].jockeyHref
                      "
                    >
                      {{
                        todayRace.raceTrend[targetDOR][targetTrackCd].trend[
                          index
                        ].jockeyName
                      }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section
      v-else-if="
        (dateType === '1' || dateType === '2') && 'raceList' in todayRace
      "
      class="layout-section"
    >
      <!-- レース非開催日 -->
      <div class="layout-sectionheader -race">
        <h1 class="heading-section">レース情報</h1>
      </div>
      <div class="container-box">
        <div
          v-for="(day, index) in todayRace.raceList"
          :key="index"
          class="inner"
        >
          <div class="layout-title -marginbottom">
            <h4 class="heading-circle" v-html="day.date"></h4>
            <div class="nav">
              <a :href="day.raceURL" class="btn-basic">その他のレース一覧</a>
            </div>
          </div>

          <div
            v-if="day.changeData.changeFlg != 0"
            class="container-changeinfo -white"
          >
            <div class="summary">
              <span>{{
                day.changeData.changeMsg != "" &&
                day.changeData.changeMsg != undefined
                  ? day.changeData.changeMsg
                  : "取消・変更等はございません（" +
                    day.changeData.changeTime.getHours() +
                    "時" +
                    day.changeData.changeTime.getMinutes() +
                    "分現在）"
              }}</span>
            </div>
            <div class="nav">
              <a :href="day.changeData.changeURL" class="btn-basic"
                >変更情報一覧</a
              >
            </div>
          </div>

          <div
            v-for="(race, idx) in day.races"
            :key="idx"
            class="link-cell card-point"
          >
            <div class="corner">
              <a
                class="label"
                :href="
                  '/race?DOR=' +
                  day.DOR +
                  '&RacetrackCd=' +
                  race.mainRace.RacetrackCd
                "
                >{{ race.mainRace.racetrackCd
                }}<i
                  v-if="'Weather' in race.condition"
                  :class="'icon-weather -w' + race.condition.Weather"
                ></i
              ></a>
            </div>
            <a class="summary" :href="'/race/card' + race.mainRaceLinks.mQuery">
              <div class="inner">
                <div class="race">
                  <h3 class="name">
                    {{ race.mainRace.RaceMainName
                    }}<i
                      v-if="
                        race.mainRace.GradeCd != '' ||
                        race.mainRace.RaceRegulationCd != ''
                      "
                      :class="
                        race.mainRace.GradeCd === 'A'
                          ? 'icon-race -g1'
                          : race.mainRace.GradeCd === 'B'
                          ? 'icon-race -g2'
                          : race.mainRace.GradeCd === 'C'
                          ? 'icon-race -g3'
                          : race.mainRace.GradeCd === 'L'
                          ? 'icon-race -L'
                          : race.mainRace.RaceRegulationCd === 'オープン'
                          ? 'icon-race -op'
                          : 'icon-race'
                      "
                      >{{
                        race.mainRace.RaceRegulationCd === "500万円以下"
                          ? "1勝"
                          : race.mainRace.RaceRegulationCd === "1000万円以下"
                          ? "2勝"
                          : race.mainRace.RaceRegulationCd === "1600万円以下"
                          ? "3勝"
                          : ""
                      }}</i
                    >
                  </h3>
                  <span v-if="'FullGate' in race.mainRace" class="sub"
                    >フルゲート{{ race.mainRace.FullGate }}頭</span
                  >
                </div>
                <ul class="list-meta">
                  <li>{{ race.mainRace.StartTimeShow }}</li>
                  <li>
                    {{ race.mainRace.TrackTypeCd }}{{ race.mainRace.Distance }}m
                  </li>
                  <li v-if="'symbol' in race">{{ race.symbol }}</li>
                </ul>
              </div>
            </a>
          </div>
        </div>
        <div v-if="Object.keys(nextWeekG1Data).length > 0" class="inner">
          <h2 class="heading-circle">次週開催G1情報</h2>
          <div
            v-for="(nextData, index) in nextWeekG1Data"
            :key="index"
            class="link-cell card-point"
          >
            <div class="image">
              <div class="thumb">
                <img
                  :src="
                    nextData.RaceImgURL != ''
                      ? '/assets/images/uploads/top_' + nextData.RaceImgURL
                      : '/assets/images/common/blank_horse.png'
                  "
                  alt=""
                />
              </div>
            </div>
            <a
              class="summary"
              :href="
                '/race?DOR=' +
                nextData.DOR +
                '&RacetrackCd=' +
                nextData.RacetrackCd
              "
            >
              <div class="inner">
                <div class="race">
                  <h3 class="name">
                    {{ nextData.RaceName
                    }}<i
                      :class="
                        nextData.Grade === 'G1'
                          ? 'icon-race -g1'
                          : nextData.Grade === 'G2'
                          ? 'icon-race -g2'
                          : nextData.Grade === 'G3'
                          ? 'icon-race -g3'
                          : ''
                      "
                    ></i>
                  </h3>
                  <span v-if="'FullGate' in nextData" class="sub"
                    >フルゲート{{ nextData.FullGate }}頭</span
                  >
                </div>
                <ul class="list-meta">
                  <li>
                    {{ nextData.StartTime.slice(0, 2) }}:{{
                      nextData.StartTime.slice(2, 4)
                    }}
                  </li>
                  <li>{{ nextData.TrackType }}{{ nextData.Distance }}m</li>
                  <li v-if="nextData.RaceSymbolCd != ''">
                    {{ innerRaceSymbol(nextData.RaceSymbolCd) }}
                  </li>
                </ul>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section v-if="dateType === '0'" class="layout-section">
      <div class="layout-sectionheader -check">
        <h1 class="heading-section">チェックポイント</h1>
      </div>
      <!-- TODO: 厳選1頭馬は新規実装のため、後ほど実装 -->
      <!-- <div class="link-cell card-point -fit">
        <div class="corner">
          <a class="label" href="/race/analysis/">厳選1頭<br />推奨馬</a>
        </div>
        <a class="summary" href="/race/card.html">
          <div class="inner">
            <p class="title">スルーセブンシーズ</p>
            <p class="sub">東京11R フェブラリーステークス</p>
            <ul class="list-meta">
              <li>ダート1200m</li>
              <li>発走 15:30</li>
            </ul>
          </div>
        </a>
      </div> -->

      <div class="link-cell card-point -fit">
        <div class="corner">
          <a class="label" href="/info/pays1">単勝<br />1倍台</a>
        </div>
        <a
          v-if="Object.keys(pays1xData).length"
          :href="pays1xData.mHref"
          class="summary"
        >
          <div class="inner">
            <p class="title">
              {{ pays1xData.race.HorseName
              }}<span class="txt-odds -no1"
                >{{ pays1xData.race.WinOdds }}倍</span
              >
            </p>
            <ul class="list-meta">
              <li>
                {{ pays1xData.race.Racetrack
                }}{{ Number(pays1xData.race.RaceNum) }}R
              </li>
              <li>
                {{ pays1xData.race.BracketNum }}枠{{
                  pays1xData.race.HorseNum
                }}番
              </li>
              <li>{{ pays1xData.race.RaceName }}</li>
              <li>
                {{ pays1xData.TrackTypeCd }}{{ pays1xData.race.Distance }}m
              </li>
            </ul>
          </div>
        </a>
        <a v-else-if="loadedPage" href="/info/pays1" class="summary">
          <div class="inner">
            <p class="title">単勝1倍台はありません。</p>
          </div>
        </a>
      </div>
      <div class="link-cell card-point -fit">
        <div class="corner">
          <a href="/info/bigdividend" class="label">高額配当<br />一覧</a>
        </div>
        <a
          v-if="Object.keys(highDividendData).length"
          :href="highDividendData.mHref"
          class="summary"
        >
          <div class="inner">
            <p class="title">
              {{ highDividendData.race.Racetrack
              }}{{ Number(highDividendData.race.RaceNum) }}Rで{{
                highDividendData.race.Contents[0].TOB
              }}{{ highDividendData.Refund }}円
            </p>
          </div>
        </a>
        <a v-else-if="loadedPage" href="/info/bigdividend" class="summary">
          <div class="inner">
            <p class="title">高額配当は出ていません。</p>
          </div>
        </a>
      </div>
      <div class="link-cell card-point -fit">
        <div class="corner">
          <a href="/info/jockeylist" class="label">固め打ち<br />騎手</a>
        </div>
        <a
          v-if="Object.keys(multiWinnersData).length"
          :href="multiWinnersData.mHref"
          class="summary"
        >
          <div class="inner">
            <p class="title">
              {{ multiWinnersData.JockeyName }}騎手が{{
                multiWinnersData.jockey.Contents[0].Racetrack
              }}{{ multiWinnersData.RaceNum }}Rで本日{{
                multiWinnersData.jockey.WinNum
              }}勝！
            </p>
          </div>
        </a>
        <a v-else-if="loadedPage" href="/info/jockeylist" class="summary">
          <div class="inner">
            <p class="title">2勝以上の騎手はいません。</p>
          </div>
        </a>
      </div>
    </section>

    <section
      v-if="dateType === '1' && lastWeekRaceData.length > 0"
      class="layout-section"
    >
      <div class="layout-sectionheader -race -nomargin">
        <h1 class="heading-section">先週の開催結果</h1>
        <div class="nav">
          <a
            class="btn-basic"
            :href="'/race/list?DOR=' + lastWeekRaceData[0].DOR"
            >結果一覧</a
          >
        </div>
      </div>

      <div class="container-box">
        <div class="inner">
          <div class="layout-sub -min">
            <div
              v-for="(lastrace, index) in lastWeekRaceData"
              :key="index"
              class="link-cell card-point"
            >
              <a
                class="summary"
                :href="
                  '/race/results?DOR=' +
                  lastrace.DOR +
                  '&RacetrackCd=' +
                  lastrace.RacetrackCd +
                  '&RaceNum=' +
                  lastrace.RaceNum
                "
              >
                <div class="inner">
                  <div class="race">
                    <h3 class="name">
                      {{ lastrace.RaceName
                      }}<i
                        :class="
                          lastrace.Grade === 'G1'
                            ? 'icon-race -g1'
                            : lastrace.Grade === 'G2'
                            ? 'icon-race -g2'
                            : lastrace.Grade === 'G3'
                            ? 'icon-race -g3'
                            : ''
                        "
                      ></i>
                    </h3>
                  </div>
                  <ul class="list-meta">
                    <li v-html="getDisplayDateStrForMain(lastrace.DOR)"></li>
                    <li>{{ lastrace.Racetrack }}</li>
                    <li>{{ lastrace.TrackType }}{{ lastrace.Distance }}m</li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div class="layout-narrow -padding">
            <a href="/race/importantrace" class="btn-basic -wide"
              >重賞カレンダー</a
            >
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="isNar() === 0 && Object.keys(win5Data).length"
      class="layout-section"
    >
      <div class="layout-sectionheader -win5">
        <h1 class="heading-section">win5</h1>
        <div class="nav">
          <a
            :href="'/win5?YearMonth=' + win5Data.DOR.slice(0, 6)"
            class="btn-basic"
            >過去の結果一覧</a
          >
        </div>
      </div>
      <div class="container-box">
        <div class="inner">
          <div class="layout-title -marginbottom -top">
            <h2 class="heading-item -wi5logo">
              <img src="/assets/images/win5/logo.png" alt="WIN5" />
            </h2>
            <div class="info">
              {{ win5Data.DOR.slice(0, 4) }}.{{
                ("00" + win5Data.DOR.slice(4, 6)).slice(-2)
              }}.{{ ("00" + win5Data.DOR.slice(6, 8)).slice(-2) }}
            </div>
          </div>

          <dl class="container-win5carryover">
            <dt>
              {{
                win5Data.RefundInfo.length > 0 ? "次回" : "今回"
              }}キャリーオーバー
            </dt>
            <dd>
              &yen;
              {{
                win5Data.RefundInfo.length > 0
                  ? addFigure(win5Data.CarryOver)
                  : addFigure(win5Data.PreCarryOver)
              }}
            </dd>
          </dl>

          <div class="container-win5result -marginbottom">
            <div class="layout-title">
              <h3 class="title">的中馬番</h3>
              <div v-if="win5Data.RefundInfo.length > 0">
                {{ getDisplayDateStrForMain(strDate) }}
              </div>
              <div
                v-else-if="
                  currentTime - closingTime + 1 >= 0 && strDate === win5Data.DOR
                "
              >
                発売締切
              </div>
              <div
                v-else-if="
                  strDate === win5Data.DOR ||
                  (currentTime - openingTime >= 0 &&
                    strDate === getYesterday(win5Data.DOR, -1))
                "
                class="info"
              >
                締切 {{ win5Data.ClosingTime.slice(0, 2) }}:{{
                  win5Data.ClosingTime.slice(2, 4)
                }}
              </div>
            </div>
            <ul>
              <li
                v-for="(race, index) in win5Data.SearchHorseList"
                :key="index"
              >
                <dl>
                  <dt v-if="race.BracketNum === ''">{{ index + 1 }}R</dt>
                  <dt v-else class="kakutei">{{ index + 1 }}R</dt>
                  <dd>
                    <div>
                      {{ race.Racetrack + race.RaceNum * 1 + "R" }}
                    </div>
                    {{ race.length }}
                    <i :class="'icon-umaban -waku0' + race.BracketNum">{{
                      race.BracketNum != "" ? race.HorseNum * 1 : "?"
                    }}</i>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
          <table v-if="win5Data.RefundInfo.length > 0" class="table-grid -win5">
            <tbody>
              <tr>
                <th>払戻金</th>
                <td>{{ refundSum }}円</td>
              </tr>
              <tr>
                <th>的中票数 / 発売票数</th>
                <td>{{ hitVote }}票 / {{ sellVote }}票</td>
              </tr>
            </tbody>
          </table>
          <table v-else-if="remainVote != ''" class="table-grid -win5">
            <tbody>
              <tr>
                <th>残り票数 / 発売票数</th>
                <td>{{ remainVote }}票 / {{ sellVote }}票</td>
              </tr>
            </tbody>
          </table>
          <div class="layout-narrow -padding">
            <a
              v-if="
                win5Data.RefundInfo.length <= 0 &&
                !(
                  currentTime - closingTime + 1 >= 0 && strDate === win5Data.DOR
                ) &&
                !(
                  strDate === win5Data.DOR ||
                  (currentTime - openingTime >= 0 &&
                    strDate === getYesterday(win5Data.DOR, -1))
                )
              "
              @click="purchaseWIN5()"
              href="#"
              class="btn-basic -wide -important"
              >WIN5を購入</a
            >
            <a
              v-if="
                ((currentTime - openingTime >= 0 &&
                  strDate === getYesterday(win5Data.DOR, -1)) ||
                  strDate === win5Data.DOR) &&
                (getCourse() == PAYINGSUGOTOKU ||
                  getCourse() == PAYINGSUGOTOKU_NONE)
              "
              :href="
                location.pathname +
                '?url=' +
                HOST +
                '/win5/results_more?YearMonth=' +
                win5Data.DOR.slice(0, 6)
              "
              class="btn-basic -wide"
              >対象レース一覧</a
            >
            <a
              v-else-if="
                (currentTime - openingTime >= 0 &&
                  strDate === getYesterday(win5Data.DOR, -1)) ||
                strDate === win5Data.DOR
              "
              :href="'/win5/results_more?YearMonth=' + win5Data.DOR.slice(0, 6)"
              class="btn-basic -wide"
              >対象レース一覧</a
            >
          </div>
        </div>
      </div>
    </section>

    <section class="layout-section">
      <div class="layout-sectionheader -news">
        <h1 class="heading-section">ニュース</h1>
        <div class="nav">
          <a class="btn-basic" href="/news/">ニュース一覧</a>
        </div>
      </div>

      <ul class="list-news">
        <li
          v-if="Object.keys(importantrace).length"
          class="item"
          v-for="race in importantrace"
        >
          <div class="summary">
            <a
              :href="race.url"
              class="title"
              v-html="race.title"
              target="_blank"
            ></a>
            <time class="caption"
              >{{ moment(race.date).format("YYYY.MM.DD HH:mm") }} 更新</time
            >
          </div>
          <div class="thumb">
            <img
              v-if="race.thumbnail"
              src="../assets/images/common/blank_square.png"
              alt=""
              :style="{ 'background-image': 'url(' + race.thumbnail + ')' }"
            />
            <!-- デジマース側でno image画像作成する可能性あるためコメントアウトで対応(小林 2022/12/22) -->
            <!-- <img
              v-else
              src="../assets/images/common/blank_square.png"
              alt=""
              style="
                background-image: url(/assets/images/common/blank_winkeiba.png);
              "
            /> -->
          </div>
          <div class="important"><span class="icon-caution">注目!</span></div>
        </li>

        <!--以下通常NEWS-->
        <li
          v-if="Object.keys(newsHeadlineData).length"
          v-for="news in newsHeadlineData"
          class="item"
        >
          <div class="summary">
            <a :href="isWebView ? '' : news.mHref" class="title">{{
              news.Headline
            }}</a>
            <time class="caption">{{ news.mUpdateTime }} 更新</time>
          </div>
          <div class="thumb">
            <img
              v-if="news.ImageFileURL"
              src="../assets/images/common/blank_square.png"
              alt=""
              :style="
                ('background-image: url(/news/images/', news.ImageFileURL, ');')
              "
            />
          </div>
        </li>
      </ul>
    </section>

    <section class="layout-section">
      <div class="layout-sectionheader -app">
        <h1 class="heading-section">SNSで使えるスタンプ画像</h1>
      </div>

      <div class="container-image">
        <a href="/page?slug=sns_pictures"
          ><img src="/assets/images/uploads/linepicture_top.png"
        /></a>
      </div>
    </section>

    <section
      v-if="dateType === '2' && lastWeekRaceData.length > 0"
      class="layout-section"
    >
      <div class="layout-sectionheader -race -nomargin">
        <h1 class="heading-section">先週の開催結果</h1>
        <div class="nav">
          <a
            class="btn-basic"
            :href="'/race/list?DOR=' + lastWeekRaceData[0].DOR"
            >結果一覧</a
          >
        </div>
      </div>

      <div class="container-box">
        <div class="inner">
          <div class="layout-sub -min">
            <div
              v-for="(lastrace, index) in lastWeekRaceData"
              :key="index"
              class="link-cell card-point"
            >
              <a
                class="summary"
                :href="
                  '/race/results?DOR=' +
                  lastrace.DOR +
                  '&RacetrackCd=' +
                  lastrace.RacetrackCd +
                  '&RaceNum=' +
                  lastrace.RaceNum
                "
              >
                <div class="inner">
                  <div class="race">
                    <h3 class="name">
                      {{ lastrace.RaceName
                      }}<i
                        :class="
                          lastrace.Grade === 'G1'
                            ? 'icon-race -g1'
                            : lastrace.Grade === 'G2'
                            ? 'icon-race -g2'
                            : lastrace.Grade === 'G3'
                            ? 'icon-race -g3'
                            : ''
                        "
                      ></i>
                    </h3>
                  </div>
                  <ul class="list-meta">
                    <li v-html="getDisplayDateStrForMain(lastrace.DOR)"></li>
                    <li>{{ lastrace.Racetrack }}</li>
                    <li>{{ lastrace.TrackType }}{{ lastrace.Distance }}m</li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div class="layout-narrow -padding">
            <a href="/race/importantrace" class="btn-basic -wide"
              >重賞カレンダー</a
            >
          </div>
        </div>
      </div>
    </section>
    <section v-if="Object.keys(localPrize).length > 0" class="layout-section">
      <div class="layout-sectionheader -oshirase">
        <h1 class="heading-section">地方交流重賞</h1>
      </div>
      <a :href="localPrize.url" class="link-cell card-point -padding">
        <div class="image">
          <div class="thumb">
            <img :src="localPrize.image ? localPrize.image.url : ''" alt="" />
          </div>
        </div>
        <div class="summary">
          <div class="inner">
            <div v-html="localPrize.title" class="race"></div>
            <ul v-html="localPrize.meta" class="list-meta"></ul>
          </div>
        </div>
      </a>
    </section>

    <section class="layout-section">
      <div class="layout-sectionheader -app">
        <h1 class="heading-section">サポート</h1>
      </div>

      <ul class="list-line -padding">
        <li class="item">
          <div class="summary">
            <a href="https://www.sugotoku.docomo.ne.jp/cs/faq.html" class="link-cell"
              ><em>よくある質問/お問合わせ</em></a
            >
          </div>
        </li>
        <li class="item">
          <div class="summary">
            <a
              href="https://www.sugotoku.docomo.ne.jp/cs/representation.html"
              class="link-cell"
              ><em>提供事業者に関する表示</em></a
            >
          </div>
        </li>
      </ul>
      <!-- TODO: アプリ改修時に起動リンクを修正 -->
      <div class="inner">
        <a href="" class="btn-basic -wide -window"
          >アプリ起動<br /><span class="caption"
            >(PUSH通知設定・ポリシー確認)</span
          ></a
        >
        <ul class="list-option">
          <li>
            アプリをインストールされていない場合、エラーが表示される場合がございますのでご注意ください。
          </li>
        </ul>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style scoped>
.link-cell .inner {
  display: block;
}

a {
  cursor: pointer;
}

/* firefox レイアウト崩れ対策 */
@-moz-document url-prefix() {
  .swiper {
    height: auto !important;
  }
  .swiper-wrapper {
    height: auto !important;
  }
}
</style>

<style lang="scss" src="../assets/css/lib/swiper/swiper.scss" scoped></style>
