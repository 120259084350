<script setup>
import { ref, reactive, onMounted, computed, toRefs } from "vue";
import axios from "axios";
import { JRA_WP_HOST } from "../../assets/js/define";
import PogHeader from "../../components/pog/PogHeader.vue";
import pogFunc from "../../functions/pog.js";
const { loadPogUserJudge } = pogFunc();

// APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=prize";

let post = reactive({});
onMounted(() => {
  loadPogUserJudge(() => {
    const request_url = API_URL;
    axios
      .get(request_url)
      .then((response) => {
        Object.assign(post, response.data[0]);
        document.body.className = "status-loaded";
      })
      .catch((error) => {
        console.log(error);
        document.body.className = "status-loaded";
      });
  });
});
</script>
<template>
  <main class="layout-main">
    <PogHeader></PogHeader>

    <div class="body" v-if="post.content" v-html="post.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
