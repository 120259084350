import {JRA_HOST, NAR_HOST, NEWSIMAGEPATH, NEWSPATH} from '../../assets/js/define';
import {getJSON, SUCCESS} from '../../assets/js/common';
import {useStoreCommon} from '../../stores/common-states';
import {useStoreNewsList} from '../../stores/news/list-states';
import {storeToRefs} from 'pinia';

export async function fetch() {
  const storeCommon = useStoreCommon();
  let host = '';
  let apiUrl = '';

  if(storeCommon.isNarSite) {
    host = NAR_HOST;
    apiUrl = `${host}webapi/getNewsHeadline.js/webapi?mode=top&narFlag=1`;
  } else {
    host = JRA_HOST;
    apiUrl = `${host}webapi/getNewsHeadline.js/webapi?mode=top&narFlag=0`;
  }

  const storeList = useStoreNewsList();
  const {list} = storeToRefs(storeList);

  await getJSON(apiUrl, true, function (status, json) {
    if (status !== SUCCESS) {
      list.value = [];
      return;
    }
    if (!json?.SearchResult?.length) {
      list.value = [];
      return;
    }
    list.value = json.SearchResult.map(r => {
      return {
        ...r,
        AnnouncementTime: r.AnnouncementTime.replaceAll('/', '.'),
        urlLink: `/${NEWSPATH}article?FileCreateDate=${r.FileCreateDate}&SeqNo=${r.SeqNo}&Page=1`,
        Path: r.ImageFileURL ? `${host}${NEWSIMAGEPATH}${r.ImageFileURL}` : ''
      }
    });
  });

}
