<script setup>
import {onMounted, reactive, ref, defineEmits, defineProps, withDefaults} from "vue";
import {BACKWORDPATH, HOST, PATH, RACEINFOPATH} from "../../../assets/js/define";
import {dayWeek, getJSON, getUrlVars, isNar, SUCCESS} from "../../../assets/js/common";
import moment from "moment/moment";

const isRaceExist = ref(false);
const raceList = ref({});
const raceDisplayList = ref([]);
const params = ref({});
const toDay = ref("");
const thisWeek = ref("");
const todayRaceFlag = ref(false);
const DOR = ref("");
const scrollGuid = ref(true);
const loadedPage = ref(false);
const displayScroll = ref(false);

//JRA用
const setDOR = ref(""); //現在のDOR
const setRacetrack = ref(""); //現在の競馬場
const DORTab = ref(2); //日付タブのインデックス
const PlaceTab = ref(3); //場所タブのインデックス
let DORArray = reactive([]); //日付タブのデータ
let placeArray = reactive([]); //場所タブのデータ

const narFlag = ref(null);

//DORからレース名とレース情報を取得（本日指定）
let todayRaceTrackCd = ref([]);
const targetRacetrackCd = ref("");
const targetRacetrackName = ref("");

onMounted(() => {
  document.body.className = "";
  //パラメータ取得
  Object.assign(params, getUrlVars());
  narFlag.value = isNar()
  loadedPage.value = false;
  if (isNar() === 0) {
    //JRAのみ
    getRaceDay(function () {
      loadRaceHeader(DOR.value, setRacetrack.value, function () {
        const placeParam = []
        for(const dor of DORArray) {
          const placeRow = placeArray[dor]
          for(const place of placeRow) {
            const pushPlace = placeRow.find(item => item === place['RacetrackCd'])
            if(pushPlace === undefined) {
              placeParam.push(place['RacetrackCd'])
            }
          }
        }
        getStudRaceList(DORArray.join(","), placeParam.join(","));
      });
    })
  } else {
    //NARのみ
    doInit(params);
  }
  document.body.className = "status-loaded";
});

const doInit = async (params) => {
  let dor;
  let numMonth, numDay;
  todayRaceFlag.value = true;
  //クエリパラメータがない場合は本日の日付をDORに変換し設定する
  const today = new Date();

  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  toDay.value = numMonth = month;
  if (String(month).length == 1) {
    month = "0" + month;
  } else {
    month = String(month);
  }
  let day = (numDay = today.getDate());
  toDay.value += "/" + day;
  if (String(day).length == 1) {
    day = "0" + day;
  } else {
    day = String(day);
  }
  dor = String(year) + month + day;
  DOR.value = dor;
  thisWeek.value = dayWeek(year, numMonth, numDay);
  await getThisDayRaceInfo(dor);
};

const getThisDayRaceInfo = (dor) => {
  const request =
      HOST +
      RACEINFOPATH +
      "getThisAndLastWeekRaceInfo.js" +
      BACKWORDPATH +
      "?NarFlag=" +
      isNar();
  try {
    getJSON(request, true, function (statusToday, jsonToday) {
      if (statusToday === SUCCESS) {
        if (jsonToday.length > 0) {
          const todayRaceInfo =
              jsonToday[0].TodayRaceInfo.TargetRacetrackCd[dor];
          if (todayRaceInfo) {
            if (isNar() === 1) {
              const sortArray = [44, 45, 43, 42, 30, 31, 32, 34, 35, 36, 37, 37, 38, 38, 40, 41, 46, 47, 48, 49, 50,
                51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 33];
              todayRaceInfo.sort(function (x, y) {
                return sortArray.indexOf(Number(x.RacetrackCd)) - sortArray.indexOf(Number(y.RacetrackCd));
              })
            }
            Object.assign(todayRaceTrackCd.value, todayRaceInfo);

            targetRacetrackCd.value = todayRaceTrackCd.value[0].RacetrackCd;
            targetRacetrackName.value = todayRaceTrackCd.value[0].Racetrack;
            const paramRaceTackCd = []
            for(const raceTrackCdItem of todayRaceTrackCd.value) {
              paramRaceTackCd.push(raceTrackCdItem.RacetrackCd)
            }

            getStudRaceList(DOR.value, paramRaceTackCd.join(","));
          } else {
            document.body.className = "status-loaded";
            loadedPage.value = true;
          }
        }
      }
    });
  } catch (e) {
    console.log("raceList : " + e);
  }
};
const getRaceDay = (callback) => {
  if (params.DOR && params.RacetrackCd) {
    DOR.value = params.DOR
    setDOR.value = params.DOR
    setRacetrack.value = params.RacetrackCd
    callback();
  } else {
    const request =
        HOST +
        RACEINFOPATH +
        "getTodayRaceInfo.js" +
        BACKWORDPATH +
        "?narFlag=" +
        isNar();
    try {
      getJSON(request, true, function (status, json) {
        let dor = getDOR(json.TargetDate)
        DOR.value = params.DOR || dor;
        setDOR.value = params.DOR || dor;
        setRacetrack.value = params.RacetrackCd || json.TargetRacetrackCd[0].RacetrackCd;
        callback();
      })
    } catch (e) {
      alert("getStallionList : " + e);
    }
  }
}

const getDOR = (targetDateArray) => {
  const today = moment().format('YYYYMMDD')
  return targetDateArray.some(val => val.DOR === today) ? today : targetDateArray[0]?.DOR
}

const loadRaceHeader = (setdor, setracetrackCd, callback) => {
  //パラメータからAPIコールを生成
  let req =
      HOST + RACEINFOPATH + "getRaceHeader.js" + BACKWORDPATH + "?dor=" + setdor;

  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      try {
        json = json[0]
        if (
            json.TargetDate &&
            json.TargetDate.length > 0 &&
            json.TargetRacetrackCd
        ) {
          let DORList = json.TargetDate;
          let RacetrackList = json.TargetRacetrackCd;

          //開催年月日タブと表示番号の設定
          let dayTab = {};
          for (let i = 0; i < DORList.length; i++) {
            dayTab[DORList[i]] = i + 1;
          }

          //タブの列数
          DORTab.value = DORList.length > 2 ? 3 : 2;
          PlaceTab.value = RacetrackList.length > 2 ? 3 : 2;


          //競馬場データの挿入
          Object.assign(placeArray, RacetrackList);

          for (let i = 0; i < DORList.length; i++) {
            //描画
            DORArray.push(DORList[i]);
          }
        }

        if (callback) {
          callback();
        }
      } catch (e) {
        console.log(e);
      }
    }
  });
};
//表示する競馬場の変更
const changeTrack = (raceTrackCd, raceTrack) => {
  targetRacetrackCd.value = raceTrackCd;
  targetRacetrackName.value = raceTrack;
  setRaceDisplayList();
};

const trackRowCheck = () => {
  for(const dor in raceList.value) {
    for(const trackCd in raceList.value[dor]) {
      if(raceList.value[dor][trackCd].length > 0) {
        isRaceExist.value = true
      }
    }
  }
}

const setRaceDisplayList = () => {
  const dor = isNar() ? DOR.value : setDOR.value
  const raceTrack = isNar() ? targetRacetrackCd.value : setRacetrack.value
  if(dor in raceList.value) {
    if(raceTrack in raceList.value[dor]) {
      raceDisplayList.value = raceList.value[dor][raceTrack]
      return
    }
  }
  raceDisplayList.value = []
}

//表示する日付の変更(JRAのみ)
const changeDOR = (dor) => {
  setDOR.value = dor;
  setRaceDisplayList()
};

//表示する競馬場の変更(JRAのみ)
const changeTrackJRA = (racetrack) => {
  setRacetrack.value = racetrack;
  setRaceDisplayList()
};

const getStudRaceList = (dor, racetrackCd) => {
  document.body.className = "";
  const request =
      HOST +
      PATH +
      "RaceInfo/getStudRaceList.js" +
      BACKWORDPATH +
      "?dor=" +
      dor +
      "&racetrackCd=" +
      racetrackCd +
      "&breedingNum=" +
      params.BreedingNum +
      "&narFlag=" +
      isNar();
  getJSON(request, true, function (status, json) {
    if (status == SUCCESS) {
      raceList.value = json
      trackRowCheck()
      setRaceDisplayList()
    }
    loadedPage.value = true;
    document.body.className = "status-loaded";
  });
}


//レース表示部で使用する日付フォーマット取得関数（例：○/△（□））
const getDisplayDateStrForMain = (yyyymmdd) => {
  const DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  const month = parseInt(yyyymmdd.slice(4, 6), 10);
  const date = parseInt(yyyymmdd.slice(6, 8), 10);
  const mDate = new Date(parseInt(yyyymmdd.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  if (day == "土") {
    day = '<span class="week _sat">' + day + "</span>";
  } else if (day == "日") {
    day = '<span class="week _sun">' + day + "</span>";
  } else {
    day = '<span class="week">' + day + "</span>";
  }
  const str = month + "/" + date + " (" + day + ")";

  return str;
};

const iconClass = (raceData) => {
  //グレードコード
  let gradeIcon = "";
  if (Number(raceData.RacetrackCd) >= 30 && Number(raceData.RacetrackCd) <= 57) {
    // NAR競走場
    gradeIcon = raceData.RaceMainName.includes('東京大賞典') ? 'icon-race -g1'
        : raceData.GradeCd == 'G1' ? 'icon-race -jpn1'
            : raceData.GradeCd == 'G2' ? 'icon-race -jpn2'
                : raceData.GradeCd == 'G3' ? 'icon-race -jpn3'
                    : raceData.RaceRank ? 'icon-race -nograde'
                        : ''
  } else {
    // JRA競走場
    if (raceData.GradeCd == 'G1') {
      gradeIcon = 'icon-race -g1'
      if (raceData.Track == "障害") {
        gradeIcon = 'icon-race -jg1'
      }
    } else if (raceData.GradeCd == 'G2') {
      gradeIcon = 'icon-race -g2'
      if (raceData.Track == "障害") {
        gradeIcon = 'icon-race -jg2'
      }
    } else if (raceData.GradeCd == 'G3') {
      gradeIcon = 'icon-race -g3'
      if (raceData.Track == "障害") {
        gradeIcon = 'icon-race -jg3'
      }
    } else if (raceData.GradeCd == 'L') {
      gradeIcon = 'icon-race -L'
    }
    if (!raceData.GradeCd && 'RaceTitle' in raceData && raceData.RaceMainName !== '') {
      //#4838 重賞でない特別競走には競争条件をアイコン表示
      if (raceData.RaceRegulationCd == "005") {
        //500万下
        gradeIcon = "icon-race -p1";
      } else if (
          raceData.RaceRegulationCd == "010"
      ) {
        //1000万下
        gradeIcon = "icon-race -p2";
      } else if (
          raceData.RaceRegulationCd == "016"
      ) {
        //1600万下
        gradeIcon = "icon-race -p3";
      } else if (
          raceData.RaceRegulationCd == "999"
      ) {
        //オープン
        gradeIcon = "OP";
      }
    }
    return gradeIcon;
  }
}

const WinOddsColor = (odds) => {
  if(odds >= 100) {
    return '3'
  } else if(odds >= 10) {
    return '2'
  } else {
    return '1'
  }
}


</script>

<template>
  <div class="item -current" style="">
    <div class="inner">
      <template v-if="isRaceExist && loadedPage">
        <h2 class="heading-circle">出走産駒</h2>
        <h3 v-if="toDay" class="heading-item">
          {{ toDay }} (<span v-html="thisWeek"/>)
        </h3>
        <section class="layout-section -tab">
          <div
              v-if="narFlag===0"
              class="nav-tab -date -jra" :class="'-fit' + DORTab">
            <ul>
              <li
                  v-for="(dor, index) in DORArray"
                  :key="index"
                  :class="setDOR === dor ? '-current' : ''"
              >
                <div
                    @click="changeDOR(dor)"
                    class="label"
                    v-html="getDisplayDateStrForMain(dor)"
                ></div>
              </li>
            </ul>
          </div>
          <div
              v-else-if="narFlag === 1"
              id="scrollGrid"
              class="nav-tab -scroll -raceinfo"
              :class="scrollGuid && displayScroll ? '' : '-moving'"
          >

            <ul>
              <li
                  v-for="(track, index) in todayRaceTrackCd"
                  :key="index"
                  :class="track.RacetrackCd === targetRacetrackCd ? '-current' : ''"
                  @click="changeTrack(track.RacetrackCd, track.Racetrack)"
              >
                <div class="label">
                  <div class="place">
                    {{ track.Racetrack }}
                    <i v-if="track.isNighter" class="icon-time -night"></i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="nav-tab -raceinfo -jra" :class="'-fit' + PlaceTab">
            <ul>
              <li
                  v-for="(place, index) in placeArray[setDOR]"
                  :key="index"
                  :class="setRacetrack === place.RacetrackCd ? '-current' : ''"
              >
                <div @click="changeTrackJRA(place.RacetrackCd)" class="label">
                  <div class="place">{{ place.Racetrack }}</div>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section v-if="loadedPage && narFlag === 1" class="layout-section">

        </section>
        <section class="layout-section">
          <div class="inner" >
            <template v-if="raceDisplayList.length > 0">
              <div class="layout-sub" v-for="race of raceDisplayList">
                <div class="heading-bar">{{ race.RaceNum }}R</div>
                <h2 class="heading-item">
                  <router-link
                      :to="`/race/card?DOR=${race.DOR}&RacetrackCd=${race.RacetrackCd}&RaceNum=${race.RaceNum}`">
                    {{ race.RaceMainName }}
                  </router-link>
                  <i :class="iconClass(race)"></i>
                </h2>
                <table class="table-grid -middle -stallion">
                  <thead>
                  <tr class="-center">
                    <th class="umaban">馬番</th>
                    <th class="horse">産駒名</th>
                    <th class="jockey">騎手</th>
                    <th class="odds">単オッズ</th>
                    <th class="rank">着順</th>
                  </tr>
                  </thead>
                  <tbody class="-center">
                  <tr v-for="entryHorse of race.EntryHorses">
                    <td class="umaban" :class="'bgcolor-waku0' + entryHorse.BracketNum">
                      {{ entryHorse.HorseNum }}
                    </td>
                    <td class="horse -left">
                      <router-link :to="'/data/horse?PedigreeNum=' + entryHorse.PedigreeNum">
                        {{ entryHorse.HorseName }}
                      </router-link>
                    </td>
                    <td class="jockey -left">
                      <router-link :to="'/data/jockey?JockeyCd=' + entryHorse.JockeyCd">{{ entryHorse.JockeyName }}</router-link>
                    </td>


                    <td class="odds">
                      <div class="txt-odds" :class="'-no' + WinOddsColor(entryHorse.WinOdds)">{{ entryHorse.WinOdds }}</div>
                      <div class="txt-odds -popular" v-if="entryHorse.WinPopularRank">{{ entryHorse.WinPopularRank }}人気</div>
                    </td>
                    <td class="rank">
                      <template v-if="entryHorse.RaceRank">
                        {{ entryHorse.RaceRank }}着
                      </template>
                      <template v-else>
                        --
                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <p
                v-else
                class="p-message -hasheight"
            >出走産駒はいません。
            </p>
          </div>
        </section>
      </template>
      <template v-else-if="!isRaceExist && loadedPage">
        <p class="p-message -hasheight">
        　<template>

        </template>
          <template>
          </template>
          今週の出走産駒はいません。
        </p>
      </template>
    </div>
  </div>
</template>


<style scoped>

</style>