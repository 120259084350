<template>
  <main class="layout-main" v-if="isLeadingPage">
    <section class="layout-pageheader -leading">
      <h1 class="heading-page">リーディング</h1>
    </section>

    <section class="layout-section">
      <p class="p-caption" v-if="updateDate">
        {{updateDate}}
      </p>

      <ranking-list-for-index :leading-list="jockeyArray" :type="'jockey'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="weightLossJockeyArray" :type="'jockey_weightloss'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="trainerArray" :type="'trainer'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="horseOwnerArray" :type="'owner'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="breederArray" :type="'breeder'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="stallionArray" :type="'stud'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="newStallionArray" :type="'new_stallion'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="bmsArray" :type="'bms'">
      </ranking-list-for-index>

    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>

  <template v-else>
    <section class="layout-pageheader -leading">
      <h1 class="heading-page">リーディング</h1>
    </section>

    <section class="layout-section">
      <p class="p-caption" v-if="updateDate">
        {{updateDate}}
      </p>

      <ranking-list-for-index :leading-list="jockeyArray" :type="'jockey'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="weightLossJockeyArray" :type="'jockey_weightloss'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="trainerArray" :type="'trainer'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="horseOwnerArray" :type="'owner'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="breederArray" :type="'breeder'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="stallionArray" :type="'stud'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="newStallionArray" :type="'new_stallion'">
      </ranking-list-for-index>

      <ranking-list-for-index :leading-list="bmsArray" :type="'bms'">
      </ranking-list-for-index>

    </section>
  </template>
</template>

<script>
import { SUCCESS, getJSON, isNar } from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH } from "../../assets/js/define";
import RankingListForIndex from "../../components/leading/RankingListForIndex.vue";

export default {
  components: {RankingListForIndex},
  props: {
    isLeadingPage: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      updateDate: '',
      jockeyArray: [],
      weightLossJockeyArray: [],
      trainerArray: [],
      horseOwnerArray: [],
      breederArray: [],
      stallionArray: [],
      newStallionArray: [],
      bmsArray: [],
    };
  },
  mounted() {
    let self = this;
    document.body.className = "";

    // 年度取得
    let date = new Date();
    let raceYear = date.getFullYear();

    let request =
        HOST +
        PATH +
        "Leading/leadingListAPI.js" +
        BACKWORDPATH +
        "?RaceYear=" +
        raceYear +
        "&NarFlag=" +
        isNar();
    getJSON(request, true, function (status, json) {
      if (status == SUCCESS) {
        if (json != undefined && json != null && json.length == 1) {
          try {
            let useData = json[0];
            self.jockeyArray = useData.JockeyLeading
            self.weightLossJockeyArray = useData.WeightLossJockeyLeading
            self.trainerArray = useData.TrainerLeading
            self.horseOwnerArray = useData.HorseOwnerLeading
            self.breederArray = useData.BreederLeading
            self.stallionArray = useData.StallionLeading
            self.newStallionArray = useData.NewStallionLeading
            self.bmsArray = useData.MothersFatherLeading
            self.updateDate = useData.LeadingUpdDate
          } catch (e) {
            console.log("取得データの表示に失敗しました ");
          }
        } else {
          console.log("不正なデータです。");
        }
      }
      document.body.className = "status-loaded";
    });
  },
};
</script>

<style lang="scss" src="../../assets/css/leading/style.scss" scoped></style>
