import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreAnnounceArticle = defineStore('announce-article', () => {
  const articleId = ref('');
  const title = ref('');
  const content = ref('');
  const modified = ref('');
  const hasArticle = () => {
    return title.value || content.value
  }
  const isSameArticle = (id) => {
    return id === articleId.value
  }

  return { articleId, title, content, modified, hasArticle, isSameArticle }
})

export default {
  useStoreAnnounceArticle,
}