import {getJSON, SUCCESS} from '../../assets/js/common';
import {JRA_WP_HOST, NAR_WP_HOST} from '../../assets/js/define';
import {useStoreAdvertisementArticle} from "../../stores/advertisement-states";
import {useStoreCommon} from "../../stores/common-states";
import {storeToRefs} from 'pinia';

export async function fetch(contentId) {
  const storeCommon = useStoreCommon();
  const apiUrl = storeCommon.isNarSite
    ? `${NAR_WP_HOST}wp-json/wp/v2/advertisement/`
    : `${JRA_WP_HOST}wp-json/wp/v2/advertisement/`

  const storeArticle = useStoreAdvertisementArticle();
  const {
    articleId,
    title,
    content,
    date
  } = storeToRefs(storeArticle);

  articleId.value = contentId;

  await getJSON(`${apiUrl}${contentId}`, true, function (status, json) {
    if (status !== SUCCESS || !json) {
      return;
    }
    title.value = json.title?.rendered ?? '';
    content.value = json.content?.rendered ?? '';
    date.value = json.date ?? '';
  });
}

