<script setup>
import {onMounted, onServerPrefetch, ref, watch} from "vue";
import {getUrlVars, monthDiv, replaceComma, yearDiv,} from "../../assets/js/common";
import {useStoreWin5Detail} from '../../stores/win5-states'
import {storeToRefs} from 'pinia'
import {useRoute, useRouter} from 'vue-router'
import {fetch as fetchWin5Detail} from '../../functions/race/win5-detail'

const month = ref("");
const year = ref("");
const loadedPage = ref(false);

const storeWin5Result = useStoreWin5Detail();
const {
  currentTime,
  yyyymmdd,
  win5DorHtml,
  win5DorStr,
  resultCount,
  win5DetailData,
} = storeToRefs(storeWin5Result);

const route = useRoute();
const router = useRouter();
onServerPrefetch(async () => {
  await fetchWin5Detail(route.query["DOR"]);
});

watch(route, async (from, to) => {
  await fetchWin5Detail(to.query["DOR"]);
})

onMounted(async () => {
  document.body.className = "";

  if (yyyymmdd.value !== route.query["DOR"]) {
    await fetchWin5Detail(route.query["DOR"]);
  }

  const params = getUrlVars();
  month.value = monthDiv(params.DOR);
  year.value = yearDiv(params.DOR);

  document.body.className = "status-loaded";
  loadedPage.value = true;
});

//yyyymmdd日のx日前後の日付をyyyymmdd型で取得する共通関数
function getYesterday(yyyymmdd, x) {
  const standard = getTimeStamp(yyyymmdd);
  const date = getBeforeAfterDate(standard, x, "日")
  return getYyyymmdd(date);
}

// yyyymmdd型で指定された日付からタイムスタンプを取得;
function getTimeStamp(yyyymmdd) {
  return new Date(
    parseInt(yyyymmdd.slice(0, 4), 10),
    parseInt(yyyymmdd.slice(4, 6), 10) - 1,
    parseInt(yyyymmdd.slice(6, 8), 10)
  );
}

//x（日、分）前、後のDateを取得する関数
//dateは基準となる時刻
//xは数値：プラス＝･･･後；マイナス＝･･･前
//modeは、'日'or'分'
function getBeforeAfterDate(date, x, mode) {
  if (typeof date === "undefined" || !(date instanceof Date)) {
    return false;
  }
  let differTime;
  if (mode === "日") {
    //x日前
    differTime = x * 24 * 60 * 60 * 1000;
  } else if (mode === "分") {
    //x分前
    differTime = x * 60 * 1000;
  }

  const timestamp = date.getTime(); //現在のシステム時間を取得
  const xTimestamp = timestamp + differTime; //4分前を取得

  return new Date(xTimestamp);
}

//yyyymmdd形式の日付を取得する関数
function getYyyymmdd(date) {
  if (typeof date === "undefined" || !(date instanceof Date)) {
    return false;
  }

  return (date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2));
}

const goRacePage = (race, dor) => {
  let url = race.BracketNum ? '/race/results?' : '/race/card?';
  url += 'DOR=' + dor + '&RacetrackCd=' + race.RacetrackCd + '&RaceNum=' + race.RaceNum;

  return url;
}
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">WIN5</h1>
      <div class="nav">
        <router-link
          :to="'/win5?YearMonth=' + year + month"
          class="btn-basic">
          過去の結果一覧へ
        </router-link>
      </div>
    </section>

    <section class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">結果詳細</h2>
      </div>

      <div class="container-box">
        <div class="inner">
          <h2 v-html="win5DorHtml"></h2>
          <table class="table-cell -center -middle -win5carryover">
            <tbody v-if="resultCount >= 5">
            <tr>
              <th>今回キャリーオーバー</th>
              <th>次回キャリーオーバー</th>
            </tr>
            <tr v-if="loadedPage">
              <td class="total">
                {{
                  replaceComma(win5DetailData.PreCarryOver || 0)
                }}<span class="unit">円</span>
              </td>
              <td class="total">
                {{
                  replaceComma(win5DetailData.CarryOver || 0)
                }}<span class="unit">円</span>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <th>今回キャリーオーバー</th>
            </tr>
            <tr>
              <td class="total">
                {{
                  replaceComma(win5DetailData.PreCarryOver || 0)
                }}<span class="unit">円</span>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="container-win5result">
            <div class="layout-title">
              <h3 class="title">的中馬番</h3>
              <div v-if="win5DetailData.SearchHorseList?.length" class="info">
                {{ win5DorStr.slice(5) }}
              </div>
              <div
                v-else-if="
                  currentTime -
                    Math.floor(win5DetailData.ClosingTime / 10000) * 60 +
                    Math.floor((win5DetailData.ClosingTime % 10000) / 100) >=
                    0 && yyyymmdd === win5DetailData.DOR
                "
                class="info">
                発売締切
              </div>
              <div
                v-else-if="
                  (win5DetailData.ClosingTime &&
                    yyyymmdd === win5DetailData.DOR) ||
                  (currentTime - (19 * 60 + 30) >= 0 &&
                    yyyymmdd === getYesterday(win5DetailData.DOR, -1))
                "
                class="info">
                締切 {{ win5DetailData.ClosingTime.slice(0, 2) }}:{{ win5DetailData.ClosingTime.slice(2, 4) }}
              </div>
            </div>
            <ul>
              <li v-for="(race, index) in win5DetailData.RaceInfo" :key="index">
                <dl>
                  <!-- 同着の表示 -->
                  <dt :class="{ 'kakutei' : race[0].BracketNum }">{{ index + 1 }}R</dt>
                  <dd>
                    <div>
                      {{ race[0].RacetrackName + race[0].RaceNum * 1 + "R" }}
                    </div>
                    <template v-for="r in race" class="race">
                      <i v-if="!r.BracketNum" class="icon-umaban -waku0">
                        ?
                      </i>
                      <i v-else :class="'icon-umaban -waku0' + r.BracketNum">
                        {{ r.HorseNum * 1 }}
                      </i>
                    </template>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
          <table v-if="win5DetailData.SearchHorseList?.length"
                 class="table-grid -win5"
                 v-for="(horse, index) in win5DetailData?.SearchHorseList"
                 :key="index">
            <tbody>
            <tr>
              <th>払戻金</th>
              <td>{{ replaceComma(horse.Refund) }}円</td>
            </tr>
            <tr>
              <th>的中票数 / 発売票数</th>
              <td>
                {{ replaceComma(horse.HitVote) }}票 /
                {{ replaceComma(win5DetailData.SellVote) }}票
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="inner">
          <h2 class="heading-item">対象レース</h2>
          <table class="table-grid -padding -win5race">
            <tbody>
            <template v-for="(race, index) in win5DetailData.RaceInfo" :key="index">
              <tr class="racelist">
                <!-- 同着の表示 -->
                <th colspan="4">
                  <router-link :to="goRacePage(race[0], win5DetailData.DOR)">
                    {{ race[0].RacetrackName }}{{ race[0].RaceNum }}R　{{ race[0].RaceName }}
                  </router-link>
                </th>
              </tr>
              <template v-for="r in race">
                <tr v-if="r.HorseNum !== '' && /[0-9]/.test(r.HorseNum)">
                  <td colspan="2" class="horse">
                    <i :class="'icon-umaban -waku0' + r.BracketNum">
                      {{ r.HorseNum * 1 }}
                    </i>
                    <router-link v-if="r.PedigreeNum"
                                 :to="'/data/horse?PedigreeNum=' + r.PedigreeNum">
                      {{ r.HorseName }}
                    </router-link>
                  </td>
                  <td class="odds">
                      <span
                        :class="{
                          'txt-odds -no1': 1.0 <= r.WinOdds && r.WinOdds < 10.0,
                          'txt-odds -no2':
                            10.0 <= r.WinOdds && r.WinOdds < 100.0,
                        }">
                        {{ r.WinOdds }}
                      </span>
                  </td>
                  <td class="ranking">{{ r.WinPopularRank }}人気</td>
                </tr>
                <tr v-if="r.HorseNum !== '' && /[0-9]/.test(r.HorseNum)">
                  <td class="jockey">
                    <router-link v-if="r.JockeyCd"
                                 :to="'/data/jockey?JockeyCd=' + r.JockeyCd">
                      {{ r.JockeyName }}
                    </router-link>
                  </td>
                  <td class="trainer">
                    <router-link v-if="r.TrainerCd"
                                 :to="'/data/trainer?TrainerCd=' + r.TrainerCd">
                      {{ r.TrainerName }}
                    </router-link>
                  </td>
                  <td class="vote" colspan="2">
                    残り票数　{{ replaceComma(r.RemainsVote) }}
                  </td>
                </tr>
              </template>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading"/>
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/win5/style.scss" scoped></style>
