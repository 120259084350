<script setup>
import {setSUGOTOKU_IPAT_CONFIRM, loadIPATLoginInfo, getUserAgent, isIPAT_CONFIRM} from "../../assets/js/common";
import {useRouter} from "vue-router";
const router = useRouter()

const displayOff = () => {
  // スゴ得IPAT利用同意済にする
  setSUGOTOKU_IPAT_CONFIRM();
  const ipatLoginInfo = loadIPATLoginInfo()
  let isIpatFlag = true
  // IPAT情報があるか確認する(cookie,LocalStorageどちらかに4つとも値があればOK)
  Object.values(ipatLoginInfo).forEach(value => {
    if (value === '' || value === null || value === 'null') isIpatFlag = false
  });

  let url = '/purchase'
  const isIpatConfirm = isIPAT_CONFIRM()

  if (isIpatConfirm && !isIpatFlag) {
    url = getUserAgent() ? '/purchase/staticIpat' : '/purchase/ipat/setting'
  } else if (!isIpatConfirm) {
    url = '/purchase/confirm'
  }

  router.push(url);
}

</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">IPAT連動の利用について</h1>
    </section>
    <section class="layout-section">
      <div class="inner">
        <p>
          IPAT連携のご利用には下記の同意が必要となります。
        </p>
        <ul class="list-disc">
          <li>20歳以上であること</li>
          <li>提供する情報、目的、提供先</li>
          <li>IPAT連携先はJRAのサービスであり、株式会社ドコモの責任の範囲外です。</li>
        </ul>
        <p>
          <br/>上記に同意する場合のみIPAT連携がご利用いだけます。
        </p>
      </div>
      <div class="nav-group">
        <div class="layout-sub -bordernone">
          <router-link to="/purchase/ipat" class="btn-basic -gray">同意しない</router-link>
          <button class="btn-basic -regist" @click="displayOff">同意する</button>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>