<script setup>
import {ref, onMounted, onUpdated, onServerPrefetch, watch} from "vue";
import {useRoute} from "vue-router";
import {storeToRefs} from "pinia";
import {useStorePageArticle} from "../../stores/page-states"
import {fetch as fetchArticle} from "../../functions/page/article";
import {hiddenNonMember, hiddenMonthlyWeb} from '../../../spa/assets/js/wordpress'

const store = useStorePageArticle();
const { title, content, format } = storeToRefs(store);
const route = useRoute();

const hiddenFlg = ref(false);
const narFlag = ref(false);
const emit = defineEmits(["hiddenFlg"]);

onServerPrefetch(async () => {
  await fetchArticle(route.query);
  hiddenFlg.value = format.value === 'blank';
  emit("hiddenFlg", hiddenFlg.value);
})

watch(route, async (from, to) => {
  document.body.className = "";
  await fetchArticle(to.query);
  hiddenFlg.value = format.value === 'blank';
  emit("hiddenFlg", hiddenFlg.value);
  window.scrollTo(0,0);
  document.body.className = "status-loaded";
})

onMounted(async () => {
  window.scrollTo(0,0);
  document.body.className = "";
  if(!store.hasArticle() || !store.isSameArticle(JSON.stringify(route.query))) {
    await fetchArticle(route.query);
    hiddenFlg.value = format.value === 'blank';
    emit("hiddenFlg", hiddenFlg.value);
  }
  setAccordion();
  scroll();
  hiddenNonMember();
  hiddenMonthlyWeb();
  document.body.className = "status-loaded";
});
onUpdated(() => {
  setAccordion();
  scroll();
  hiddenNonMember();
  hiddenMonthlyWeb();
  document.body.className = "status-loaded";
});
const setAccordion = () => {
  let dataAccordion = document.querySelectorAll(".data-accordion");

  dataAccordion.forEach((elem, index) => {
    let accordion = elem;
    let dataTrigger = elem.querySelector(".data-trigger");
    let dataTarget = elem.querySelector(".data-target");
    let boxHeight = dataTarget.offsetHeight;

    dataTarget.style.display = "none";

    dataTarget.style.overflow = "hidden";

    dataTrigger.addEventListener("click", (e) => {
      let trigger = e.target;
      if (accordion.classList.contains("-active")) {
        accordion.classList.remove("-active");
        trigger.classList.remove("-active");
        dataTarget.classList.remove("-active");
        slideUp(dataTarget, 2);
      } else {
        accordion.classList.add("-active");
        trigger.classList.add("-active");
        dataTarget.classList.add("-active");
        dataTarget.style.display = "block";
        slideDown(dataTarget, 5);
      }
    });
  });
};

const slideDown = (target, speed) => {
  let distance = 0;

  target.style.display = "block";
  target.style.height = "auto";
  let myheight = target.offsetHeight;
  target.style.height = 0 + "px";

  let myInterval = setInterval(function () {
    target.style.height = distance + "px";
    distance += (myheight - distance) / speed;
    if (distance >= myheight - 1) {
      clearInterval(myInterval);
      target.style.height = myheight + "px";
    }

  }, 10);
};

const slideUp = (target, speed) => {
  let distance = target.offsetHeight;

  let myInterval = setInterval(function () {
    distance -= distance / speed;

    target.style.height = distance + "px";
    if (distance <= 1) {
      clearInterval(myInterval);
      target.style.height = 0 + "px";
    }
  }, 10);
};

const scroll = () => {
  const links = document.querySelectorAll('a[href^="#"]');
  links.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      const targetId = link.getAttribute('href');
      const targetElement = document.querySelector(targetId);
      const targetPosition = targetElement.offsetTop - 50;
      const startPosition = window.pageYOffset;
      const distance = targetPosition - startPosition;
      const duration = 800;
      let start = null;
      const step = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const percentage = Math.min(progress / duration, 1);
        window.scrollTo(0, startPosition + distance * easeInOutQuad(percentage));
        if (progress < duration) {
          requestAnimationFrame(step);
        }
      }
      requestAnimationFrame(step);
    });
  });
};
const easeInOutQuad = (t) => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

</script>
<template>
  <main v-if="hiddenFlg === false" class="layout-main">
    <section class="layout-pageheader -default -editpage">
      <h1
        class="heading-page"
        v-if="title"
        v-html="title"
      ></h1>
    </section>

    <section class="layout-section">
      <div class="edit-block">
        <div
          class="body"
          v-if="content"
          v-html="content"
        ></div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
  <main v-else>
    <section class="layout-section">
      <div class="edit-block">
        <div
          class="body"
          v-if="content"
          v-html="content"
        ></div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
