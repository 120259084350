<template>
  <teleport to="main">
    <div
      @click.self="modalClose('outerClick')"
      class="container-stamp"
      :style="{ left: left + 'px', top: top + 'px' }"
    >
      <ul>
        <li
          v-for="m in marks"
          :key="m.id"
          :class="[m.current == true ? `-current` : ``]"
          @click="clickMark(m.id)"
        >
          <span class="mark"><img :src="m.src" alt="" class="select" /></span>
        </li>
      </ul>
    </div>
    <div @click="modalClose('outerClick')" class="layout-layerbg"></div>
  </teleport>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
export default {
  props: {
    top: Number,
    left: Number,
    stampNum: Number,
    selectStamp: Number,
  },
  emits: ["closeModal"],
  setup(props, context) {
    const marks = reactive([
      {
        id: 0,
        current: false,
        src: "/assets/images/common/mystamp/none.svg",
      },
      {
        id: 1,
        current: false,
        src: "/assets/images/common/mystamp/honmei.svg",
      },
      {
        id: 2,
        current: false,
        src: "/assets/images/common/mystamp/taikou.svg",
      },
      {
        id: 3,
        current: false,
        src: "/assets/images/common/mystamp/tanana.svg",
      },
      {
        id: 4,
        current: false,
        src: "/assets/images/common/mystamp/renka.svg",
      },
      {
        id: 5,
        current: false,
        src: "/assets/images/common/mystamp/hoshi.svg",
      },
    ]);

    marks[props.selectStamp].current = true;
    const selectedNum = ref(0);
    const modalClose = (id) => {
      if (id === "outerClick") {
        context.emit("closeModal", {
          num: props.stampNum,
          selectedNum: props.selectStamp,
        });
      } else {
        context.emit("closeModal", {
          num: props.stampNum,
          selectedNum: selectedNum.value,
        });
      }
    };
    const clickMark = (num) => {
      selectedNum.value = num;
      modalClose();
    };
    return {
      modalClose,
      marks,
      clickMark,
    };
  },
};
</script>

<style></style>
