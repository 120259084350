import { createRouter } from "vue-router";
import axios from "axios";
import axiosAdapter from "axios/lib/adapters/http"
import { useStoreCommon } from "../stores/common-states"

// Component
import Index from "../pages/Index.vue";
import IndexSugotoku from "../pages/IndexSugotoku.vue";
import Data from "../pages/data/Index.vue";
import Horse from "../pages/data/Horse.vue";
import Jockey from "../pages/data/Jockey.vue";
import Trainer from "../pages/data/Trainer.vue";
import Owner from "../pages/data/Owner.vue";
import Breeder from "../pages/data/Breeder.vue";
import Stud from "../pages/data/Stud.vue";
import Mare from "../pages/data/Mare.vue";
import Info from "../pages/info/Index.vue";
import trends from "../pages/info/Trends.vue";
import InfoPays1 from "../pages/info/Pays1.vue";
import InfoBigDividend from "../pages/info/Bigdividend.vue";
import InfoJockeyList from "../pages/info/JockeyList.vue";
import EntriesIndex from "../pages/info/EntriesIndex.vue";
import EstablishRecord from "../pages/info/EstablishRecord.vue";
import Collection from "../pages/collection/Index.vue";
import Card from "../pages/collection/Card.vue";
import SearchHorse from "../pages/search/Horse.vue";
import SearchJockey from "../pages/search/Jockey.vue";
import SearchTrainer from "../pages/search/Trainer.vue";
import SearchOwner from "../pages/search/Owner.vue";
import SearchBreeder from "../pages/search/Breeder.vue";
import SearchStud from "../pages/search/Stud.vue";
import SearchMare from "../pages/search/Mare.vue";
import SearchRace from "../pages/search/Race.vue";
import SearchIndex from "../pages/search/Index.vue";
import SearchJockeyList from "../pages/search/JockeyList.vue";
import SearchTrainerList from "../pages/search/TrainerList.vue";
import Leading from "../pages/leading/Index.vue";
import LeadingJockey from "../pages/leading/Jockey.vue";
import LeadingTrainer from "../pages/leading/Trainer.vue";
import LeadingHorseOwner from "../pages/leading/Owner.vue";
import LeadingBreeder from "../pages/leading/Breeder.vue";
import LeadingStud from "../pages/leading/Stud.vue";
import LeadingBms from "../pages/leading/Bms.vue";
import LeadingWeightLossJockey from "../pages/leading/WeightLossJockey.vue";
import LeadingNewStud from "../pages/leading/NewStud.vue";
import EarningsRanking from "../pages/earningsRanking/EarningsRanking.vue";
import Schedule from "../pages/race/Card.vue";
import RaceHelp from "../pages/race/Help.vue";
import Race from "../pages/race/Race.vue";
import Analysis from "../pages/race/Analysis.vue";
import RaceCalendar from "../pages/race/RaceCalendar.vue";
import Results from "../pages/race/Results.vue";
import RaceCourse from "../pages/race/Course.vue";
import RaceData from "../pages/race/Data.vue";
import ImportantRace from "../pages/race/ImportantRace.vue";
import Program from "../pages/program/Index.vue";
import Win from "../pages/odds/Win.vue";
import Place from "../pages/odds/Place.vue";
import Bracket from "../pages/odds/Bracket.vue";
import BracketExacta from "../pages/odds/BracketExacta.vue";
import Quinella from "../pages/odds/Quinella.vue";
import Exacta from "../pages/odds/Exacta.vue";
import Wide from "../pages/odds/Wide.vue";
import Trifecta from "../pages/odds/Trifecta.vue";
import Trio from "../pages/odds/Trio.vue";
import Payout from "../pages/odds/Payout.vue";
import Inquire from "../pages/odds/Inquire.vue";
import Purchase from "../pages/purchase/Index.vue";
import PurchaseIpat from "../pages/purchase/IndexIpat.vue";
import PurchaseSpat4 from "../pages/purchase/IndexSpat4.vue";
import PurchaseAnniversary from "../pages/purchase/Anniversary.vue";
import Ipat from "../pages/purchase/Ipat.vue";
import PurchaseStaticIpat from "../pages/purchase/StaticIpat.vue";
import PurchaseAppStaticIpat from "../pages/purchase/AppStaticIpat.vue";
import PurchaseAppStaticSpat4 from "../pages/purchase/AppStaticSpat4.vue";
import PurchaseSugotokuConfirm from "../pages/purchase/SugotokuConfirm.vue";
import PurchaseStaticConfirm from "../pages/purchase/StaticConfirm.vue";
import PurchaseConfirm from "../pages/purchase/Confirm.vue";
import FavHorse from "../pages/fav/Index.vue";
import FavJockey from "../pages/fav/Jockey.vue";
import FavTrainer from "../pages/fav/Trainer.vue";
import FavBreeder from "../pages/fav/Breeder.vue";
import FavOwner from "../pages/fav/Owner.vue";
import FavStud from "../pages/fav/Stud.vue";
import FavMare from "../pages/fav/Mare.vue";
import FavRace from "../pages/fav/Race.vue";
import FavMyNextTarget from "../pages/fav/MyNextTarget.vue";
import News from "../pages/news/Index.vue";
import NewsArticle from "../pages/news/Article.vue";
import HorseOdds from "../pages/horse_odds/Index.vue";
import Balance from "../pages/balance/Index.vue";
import Category from "../pages/balance/Category.vue";
import PurchaseDaily from "../pages/balance/PurchaseDaily.vue";
import PurchaseMonthly from "../pages/balance/PurchaseMonthly.vue";
import PurchaseYear from "../pages/balance/PurchaseYear.vue";
import SumBettype from "../pages/balance/SumBettype.vue";
import SumDatePlace from "../pages/balance/SumDatePlace.vue";
import SumDistance from "../pages/balance/SumDistance.vue";
import SumGrade from "../pages/balance/SumGrade.vue";
import SumHorseAge from "../pages/balance/SumHorseAge.vue";
import SumHorseNumber from "../pages/balance/SumHorseNumber.vue";
import SumJockey from "../pages/balance/SumJockey.vue";
import SumMonthly from "../pages/balance/SumMonthly.vue";
import SumSurface from "../pages/balance/SumSurface.vue";
import SumTrainer from "../pages/balance/SumTrainer.vue";
import SumVenue from "../pages/balance/SumVenue.vue";
import Page from "../pages/page/Index.vue";
import Campaign from "../pages/campaign/Index.vue";
import Advertisement from "../pages/advertisement/Index.vue";
import Announce from "../pages/announce/Index.vue";
import Information from "../pages/information/Index.vue";
import PogMypage from "../pages/pog/MyPage.vue";
import PogSearchStudList from "../pages/pog/search/StudList.vue";
import PogHorseList from "../pages/pog/search/HorseList.vue";
import PogSearchBreeder from "../pages/pog/search/Breeder.vue";
import HorseDetail from "../pages/pog/HorseDetail.vue";
import UserHorse from "../pages/pog/UserHorse.vue";
import HorseRanking from "../pages/pog/HorseRanking.vue";
import MonthlyRanking from "../pages/pog/MonthlyRanking.vue";
import YearlyRanking from "../pages/pog/YearlyRanking.vue";
import PastYearlyRanking from "../pages/pog/PastYearlyRanking.vue";
import PogSearchList from "../pages/pog/search/List.vue";
import PogSearchMareList from "../pages/pog/search/MareList.vue";
import PogSearchBms from "../pages/pog/search/Bms.vue";
import PogSearchTrainer from "../pages/pog/search/Trainer.vue";
import Quit from "../pages/support/quit/Index.vue";
import winIndex from "../pages/win5/Index.vue";
import win5ResultMore from "../pages/win5/ResultMore.vue";
import Help from "../pages/support/help/Index.vue";
import SettingMailChange from "../pages/support/mail/Change.vue";
import MailSetting from "../pages/support/mail/Setting.vue";
import SettingsNoLogin from "../pages/support/mail/SettingsNoLogin.vue";
import SettingsRegistMail from "../pages/support/mail/SettingsRegistMail.vue";
import Unsubscribe from "../pages/support/mail/Unsubscribe.vue";
import PogBreederHorseList from "../pages/pog/search/BreederHorseList.vue";
import PogManageHorseList from "../pages/pog/search/ManageHorseList.vue";
import Twitter from "../pages/twitter/index.vue";
import TermsAll from "../pages/support/terms_all/Index.vue";
import AppPolicy from "../pages/support/apppolicy/Index.vue";
import Policy from "../pages/support/policy/Index.vue";
import Law from "../pages/support/law/Index.vue";
import Contract from "../pages/member/Contract.vue";
import RaceList from "../pages/race/RaceList.vue";
import PogName from "../pages/pog/Name.vue";
import PogJoin from "../pages/pog/Join.vue";
import MemberInformationIntegration from "../pages/member/migrate/Data.vue";
import MemberInformationNonMember from "../pages/member/migrate/NonMember.vue";
import CustomizeOdds from "../pages/member/CustomizeOdds.vue";
import CustomizeCard from "../pages/member/CustomizeCard.vue";
import Faq from "../pages/support/faq/Index.vue";
import Device from "../pages/support/device/Index.vue";
import Inquiry from "../pages/support/inquiry/Index.vue";
import PogWinner from "../pages/pog/Winner.vue";
import MemberRegistration from "../pages/member/Index.vue";
import Marks from "../pages/race/Marks.vue";
import NonMember from "../pages/support/quit/NonMember.vue";
import HorseListDetail from "../pages/horselist/Detail.vue";
import HorseList from "../pages/horselist/Index.vue";
import JoinEconomy from "../pages/member/JoinEconomy.vue";
import ConfirmSelect from "../pages/support/quit/ConfirmSelect.vue";
import Prize from "../pages/pog/Prize.vue";
import PogInfo from "../pages/pog/Info.vue";
import QuitEconomy from "../pages/support/quit/Economy.vue";
import QuitEconomyCredit from "../pages/support/quit/EconomyCredit.vue";
import QuitPremiumPlus from "../pages/support/quit/PremiumPlus.vue";
import QuitPremium from "../pages/support/quit/Premium.vue";
import QuitPremiumCredit from "../pages/support/quit/PremiumCredit.vue";
import QuitPack03Credit from "../pages/support/quit/Pack03Credit.vue";
import QuitPack06Credit from "../pages/support/quit/Pack06Credit.vue";
import QuitPack12Credit from "../pages/support/quit/Pack12Credit.vue";
import QuitMailMember from "../pages/support/quit/MailMember.vue";
import ColumnArticle from "../pages/column/sumii/Article.vue";
import SalesList from "../pages/sales/List.vue";
import Sales from "../pages/sales/Index.vue";
import SalesArticle from "../pages/sales/Article.vue";
import Column from "../pages/column/sumii/Index.vue";
import JoinPremiumOnly from "../pages/member/JoinPremiumOnly.vue";
import PackRule from "../pages/member/PackRule.vue";
import FeatureArticle from "../pages/feature/Article.vue";
import Feature from "../pages/feature/Index.vue";
import Login from "../pages/login/Index.vue";
import Error01 from "../pages/member/error/01.vue";
import Error02 from "../pages/member/error/02.vue";
import Error03 from "../pages/member/error/03.vue";
import Error04 from "../pages/member/error/04.vue";
import YPremiumClose from "../pages/member/error/YPremiumClose.vue";
import Error404 from "../pages/error/404.vue";
import Error500 from "../pages/error/500.vue";
import Authentication from "../pages/auth/Index.vue";
import Stallion from "../pages/race/Stallion.vue";
import CouponList from "../pages/dPoint/CouponList.vue";
import DPointRaceList from "../pages/dPoint/RaceList.vue";

export const createAppRouter = ({ history, hostname, isClient }) => {
  const store = useStoreCommon();
  const isNar = store.isNarSite;
  const routes = [
    {
      path: "/",
      component: Index,
      meta: {
        title: `WIN!競馬${isNar ? "【地方競馬版】" : ""} - スマホで使いやすい中央/地方競馬（JRA/NAR）情報アプリ`,
        description: "JRA/NAR主催の中央/地方競馬情報アプリ。JRA/NARの公式データを使用し、オッズ、レース結果などリアルタイム更新中。更にレース動画やIPAT連動、通知機能など便利機能を多数搭載。スマホで使いやすいアプリはWIN!競馬（ウインケイバ）",
        canonical_param: []
      }
    },
    {
      path: "/index_sugotoku",
      component: IndexSugotoku,
      meta: {
        title: `WIN!競馬${isNar ? "【地方競馬版】" : ""} - スマホで使いやすい中央/地方競馬（JRA/NAR）情報アプリ`,
        description: "JRA/NAR主催の中央/地方競馬情報アプリ。JRA/NARの公式データを使用し、オッズ、レース結果などリアルタイム更新中。更にレース動画やIPAT連動、通知機能など便利機能を多数搭載。スマホで使いやすいアプリはWIN!競馬（ウインケイバ）",
      }
    },
    {
      path: "/data/",
      component: Data,
    },
    {
      path: "/data/horse",
      component: Horse,
      meta: {
        title: `競走馬の競走成績・血統・次走情報はWIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "競走馬の最新ニュースや、コース・騎手・馬場状態・競馬場別の実績、全レースの競走成績、血統や兄弟馬などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!",
        dynamic: {
          type: "name",
          api: "getRacehorseName.js",
          query: ["PedigreeNum"],
          template_title: `__name__の競走成績・血統・次走情報はWIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__の最新ニュースや、コース・騎手・馬場状態・競馬場別の実績、全レースの競走成績、血統や兄弟馬などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!",
          template_keywords: "__name__,競走馬,騎手,調教師,競走馬データ,競馬,データベース"
        },
        canonical_param: ["PedigreeNum"],
      }
    },
    {
      path: "/data/jockey",
      component: Jockey,
      meta: {
        title: `騎手データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "騎手の最新ニュースや、コース・条件場別の実績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!",
        dynamic: {
          type: "name",
          api: "getJockeyName.js",
          query: ["JockeyCd"],
          template_title: `__name__騎手データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__騎手の最新ニュースや、コース・条件場別の実績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!"
        }
      }
    },
    {
      path: "/data/trainer",
      component: Trainer,
      meta: {
        title: `調教師データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "調教師の最新ニュースや、コース・条件場別の実績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!",
        dynamic: {
          type: "name",
          api: "getTrainerName.js",
          query: ["TrainerCd"],
          template_title: `__name__調教師データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__調教師の最新ニュースや、コース・条件場別の実績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!"
        }
      }
    },
    {
      path: "/data/breeder",
      component: Breeder,
      meta: {
        title: `生産者データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "生産馬や通算成績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!",
        dynamic: {
          type: "name",
          api: "getBreederName.js",
          query: ["BreederCd"],
          template_title: `__name__データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__の生産馬や通算成績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!"
        }
      }
    },
    {
      path: "/data/owner",
      component: Owner,
      meta: {
        title: `馬主データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "所有馬や通算成績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!",
        dynamic: {
          type: "name",
          api: "getHorseOwnerName.js",
          query: ["HorseOwnerCd"],
          template_title: `__name__データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__の所有馬や通算成績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!"
        }
      }
    },
    {
      path: "/data/mare",
      component: Mare,
      meta: {
        title: `繁殖牝馬データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "繁殖牝馬の産駒一覧や血統などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!",
        dynamic: {
          type: "name",
          api: "getBreedingName.js",
          query: ["BreedingNum"],
          template_title: `__name__繁殖牝馬データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "繁殖牝馬__name__の産駒一覧や血統などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!"
        }
      }
    },
    {
      path: "/data/stud",
      component: Stud,
      meta: {
        title: `種牡馬データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "種牡馬の主な産駒や通算成績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!",
        dynamic: {
          type: "name",
          api: "getBreedingName.js",
          query: ["BreedingNum"],
          template_title: `__name__種牡馬データ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "種牡馬__name__の主な産駒や通算成績などの予想に役立つ多彩なデータをスマホに特化した見やすいレイアウトで掲載。競馬データならWIN!競馬（ウインケイバ）!"
        }
      }
    },
    {
      path: "/fav/index",
      component: FavHorse,
    },
    {
      path: "/fav/jockey",
      component: FavJockey,
    },
    {
      path: "/fav/trainer",
      component: FavTrainer,
    },
    {
      path: "/fav/breeder",
      component: FavBreeder,
    },
    {
      path: "/fav/owner",
      component: FavOwner,
    },
    {
      path: "/fav/mare",
      component: FavMare,
    },
    {
      path: "/fav/stud",
      component: FavStud,
    },
    {
      path: "/fav/race",
      component: FavRace,
    },
    {
      path: "/fav/myNextTarget",
      component: FavMyNextTarget,
    },
    {
      path: "/data/search/horse",
      component: SearchHorse,
    },
    {
      path: "/data/search/jockey",
      component: SearchJockey,
    },
    {
      path: "/data/search/trainer",
      component: SearchTrainer,
    },
    {
      path: "/data/search/owner",
      component: SearchOwner,
    },
    {
      path: "/data/search/breeder",
      component: SearchBreeder,
    },
    {
      path: "/data/search/stud",
      component: SearchStud,
    },
    {
      path: "/data/search/mare",
      component: SearchMare,
    },
    {
      path: "/data/search/race",
      component: SearchRace,
    },
    {
      path: "/data/search",
      component: SearchIndex,
    },
    {
      path: "/data/search/jockey_list",
      component: SearchJockeyList,
    },
    {
      path: "/data/search/trainer_list",
      component: SearchTrainerList,
    },
    {
      path: "/race/",
      component: Race,
      meta: {
        title: `レース一覧 - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "今週のJRA/NARのレースを見やすく一覧で表示！競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!",
      }
    },
    {
      path: "/race/odds/bracket",
      component: Bracket,
    },
    {
      path: "/race/odds/bracket_exacta",
      component: BracketExacta,
    },
    {
      path: "/race/odds/win",
      component: Win,
    },
    {
      path: "/race/odds/place",
      component: Place,
    },
    {
      path: "/race/odds/wide",
      component: Wide,
    },
    {
      path: "/race/odds/trifecta",
      component: Trifecta,
    },
    {
      path: "/race/odds/quinella",
      component: Quinella,
    },
    {
      path: "/race/odds/exacta",
      component: Exacta,
    },
    {
      path: "/race/odds/trio",
      component: Trio,
    },
    {
      path: "/balance",
      component: Balance,
    },
    {
      path: "/balance/category",
      component: Category,
    },
    {
      path: "/balance/purchase_daily",
      component: PurchaseDaily,
    },
    {
      path: "/balance/purchase_monthly",
      component: PurchaseMonthly,
    },
    {
      path: "/balance/purchase_year",
      component: PurchaseYear,
    },
    {
      path: "/balance/sum_venue",
      component: SumVenue,
    },
    {
      path: "/balance/sum_bettype",
      component: SumBettype,
    },
    {
      path: "/balance/sum_date_place",
      component: SumDatePlace,
    },
    {
      path: "/balance/sum_distance",
      component: SumDistance,
    },
    {
      path: "/balance/sum_grade",
      component: SumGrade,
    },
    {
      path: "/balance/sum_horse_age",
      component: SumHorseAge,
    },
    {
      path: "/balance/sum_horse_number",
      component: SumHorseNumber,
    },
    {
      path: "/balance/sum_jockey",
      component: SumJockey,
    },
    {
      path: "/balance/sum_monthly",
      component: SumMonthly,
    },
    {
      path: "/balance/sum_surface",
      component: SumSurface,
    },
    {
      path: "/balance/sum_trainer",
      component: SumTrainer,
    },
    {
      path: "/balance/sum_venue",
      component: SumVenue,
    },
    {
      path: "/race/analysis",
      component: Analysis,
    },
    {
      path: "/race/calendar",
      component: RaceCalendar,
      meta: {
        title: `レースカレンダー - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "JRA/NARのレースを見やすくカレンダーで表示！競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!",
      }
    },
    {
      path: "/race/card",
      component: Schedule,
      meta: {
        title: `出馬表 - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "出馬表です。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!",
        dynamic: {
          type: "name",
          api: "getRaceData.js",
          query: ["DOR", "RacetrackCd", "RaceNum"],
          template_title: `__name__出馬表 - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__の出馬表です。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!",
          template_keywords: "__racename__,__year__,出馬表,競馬,オッズ,予想,払戻,レース結果,データ分析"
        }
      }
    },
    {
      path: "/race/results",
      component: Results,
      meta: {
        title: `レース結果 - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "レース結果です。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!",
        dynamic: {
          type: "name",
          api: "getRaceData.js",
          query: ["DOR", "RacetrackCd", "RaceNum"],
          template_title: `__name__レース結果 - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__のレース結果です。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!",
          template_keywords: "__racename__,__year__,出馬表,競馬,オッズ,予想,払戻,レース結果,データ分析"
        }
      }
    },
    {
      path: "/race/help",
      component: RaceHelp,
    },
    {
      path: "/collection/",
      component: Collection,
    },
    {
      path: "/collection/card",
      component: Card,
    },
    {
      path: "/leading",
      component: Leading,
      meta: {
        title: `リーディング - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "騎手・調教師など各リーディングが一覧表示されます。それぞれのリーディングを選択すると、詳細がチェックできます。"
      },
    },
    {
      path: "/leading/jockey",
      component: LeadingJockey,
      meta: {
        title: `騎手リーディング - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "騎手リーディング詳細がチェックできます。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!"
      }
    },
    {
      path: "/leading/trainer",
      component: LeadingTrainer,
      meta: {
        title: `調教師リーディング - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "調教師リーディング詳細がチェックできます。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!",
      }
    },
    {
      path: "/leading/owner",
      component: LeadingHorseOwner,
      meta: {
        title: `馬主リーディング - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "馬主リーディング詳細がチェックできます。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!"
      }
    },
    {
      path: "/leading/breeder",
      component: LeadingBreeder,
      meta: {
        title: `生産者リーディング - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "生産者リーディング詳細がチェックできます。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!"
      }
    },
    {
      path: "/leading/stud",
      component: LeadingStud,
      meta: {
        title: `種牡馬リーディング - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "種牡馬リーディング詳細がチェックできます。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!"
      }
    },
    {
      path: "/leading/bms",
      component: LeadingBms,
      meta: {
        title: `母の父リーディング - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "母の父リーディング詳細がチェックできます。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!"
      }
    },
    {
      path: "/leading/jockey_weightloss",
      component: LeadingWeightLossJockey,
      meta: {
        title: `減量騎手リーディング - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "減量騎手リーディング詳細がチェックできます。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!"
      }
    },
    {
      path: "/leading/new_stallion",
      component: LeadingNewStud,
      meta: {
        title: `新種牡馬リーディング - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "新種牡馬リーディング詳細がチェックできます。競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!"
      }
    },
    {
      path: "/earningsRanking",
      component: EarningsRanking,
    },
    {
      path: "/race/data",
      component: RaceData,
    },
    {
      path: "/purchase/",
      component: Purchase,
    },
    {
      path: "/purchase/ipat",
      component: PurchaseIpat,
    },
    {
      path: "/purchase/spat4",
      component: PurchaseSpat4,
    },
    {
      path: "/purchase/anniversary",
      component: PurchaseAnniversary,
    },
    {
      path: "/purchase/ipat/setting",
      component: Ipat,
    },
    {
      path: "/purchase/staticConfirm",
      component: PurchaseStaticConfirm,
    },
    {
      path: "/purchase/confirm",
      component: PurchaseConfirm,
    },
    {
      path: "/purchase/staticIpat",
      component: PurchaseStaticIpat,
    },
    {
      path: "/purchase/appStaticIpat",
      component: PurchaseAppStaticIpat,
    },
    {
      path: "/purchase/appStaticSpat4",
      component: PurchaseAppStaticSpat4,
    },
    {
      path: "/purchase/sugotokuConfirm",
      component: PurchaseSugotokuConfirm,
    },
    {
      path: "/horse_odds",
      component: HorseOdds,
    },
    {
      path: "/race/odds/payout",
      component: Payout,
    },
    {
      path: "/race/odds/inquire",
      component: Inquire,
    },
    {
      path: "/news/",
      component: News,
      meta: {
        title: `ニュース一覧 - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "WIN!競馬では最新の競馬ニュースを毎日配信中！今週出走する注目馬の追い切り情報や、GⅠのレース結果など注目のニュースが満載！"
      }
    },
    {
      path: "/news/article",
      component: NewsArticle,
      meta: {
        title: `ニュース詳細 - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "WIN!競馬では最新の競馬ニュースを毎日配信中！今週出走する注目馬の追い切り情報や、GⅠのレース結果など注目のニュースが満載！",
        canonical_param: ['FileCreateDate', 'SeqNo'],
      }
    },
    {
      path: "/info/",
      component: Info,
    },
    {
      path: "/info/trends",
      component: trends,
    },
    {
      path: "/info/pays1",
      component: InfoPays1,
    },
    {
      path: "/info/bigdividend",
      component: InfoBigDividend,
    },
    {
      path: "/info/jockeylist",
      component: InfoJockeyList,
    },
    {
      path: "/info/entries_index",
      component: EntriesIndex,
    },
    {
      path: "/info/establish_record",
      component: EstablishRecord,
    },
    {
      path: "/race/course",
      component: RaceCourse,
    },
    {
      path: "/race/importantrace",
      component: ImportantRace,
      meta: {
        title: `重賞カレンダー - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "中央/地方競馬（JRA/NAR主催）の重賞レースとその勝ち馬を一覧表示。WIN!競馬ならではのスマホで見やすいデザインで、重賞スケジュールを確認できます。",
        dynamic: {
          type: "query",
          query: "year",
          template_title: `重賞カレンダー__name__ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__年の中央/地方競馬（JRA/NAR主催）の重賞レースとその勝ち馬を一覧表示。WIN!競馬ならではのスマホで見やすいデザインで、重賞スケジュールを確認できます。",
        }
      }
    },
    {
      path: "/race/stallion",
      component: Stallion,
    },
    {
      path: "/program",
      component: Program,
    },
    {
      path: "/page",
      component: Page,
      meta: {
        keywords: "競馬",
        dynamic: {
          type: "article",
          category: "pages",
          query: ["slug"],
          template_title: `__name__ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__",
          template_keywords: "__name__"
        },
      }
    },
    {
      path: "/campaign",
      component: Campaign,
    },
    {
      path: "/advertisement",
      component: Advertisement,
    },
    {
      path: "/announce",
      component: Announce,
    },
    {
      path: "/information",
      component: Information,
    },
    {
      path: "/pog/mypage",
      component: PogMypage,
    },
    {
      path: "/pog/search_studlist",
      component: PogSearchStudList,
    },
    {
      path: "/pog/search_horselist",
      component: PogHorseList,
    },
    {
      path: "/pog/search_breeder",
      component: PogSearchBreeder,
    },
    {
      path: "/pog/horse_detail",
      component: HorseDetail,
    },
    {
      path: "/pog/user_horse",
      component: UserHorse,
    },
    {
      path: "/pog/horse_ranking",
      component: HorseRanking,
    },
    {
      path: "/pog/monthly_ranking",
      component: MonthlyRanking,
    },
    {
      path: "/pog/yearly_ranking",
      component: YearlyRanking,
    },
    {
      path: "/pog/past_yearly_ranking",
      component: PastYearlyRanking,
    },
    {
      path: "/pog/search_list",
      component: PogSearchList,
    },
    {
      path: "/pog/search_marelist",
      component: PogSearchMareList,
    },
    {
      path: "/pog/search_bms",
      component: PogSearchBms,
    },
    {
      path: "/pog/search_trainer",
      component: PogSearchTrainer,
    },
    {
      path: "/support/quit",
      component: Quit,
      meta: {
        noindex: true
      }
    },
    {
      path: "/win5",
      component: winIndex,
      meta: {
        title: "WIN5 過去の結果一覧 - WIN!競馬",
        description: "WIN5の過去の結果が一覧表示されます。それぞれの日付を選択すると、日別の勝ち馬、的中票数、払戻金がチェックできます。",
        dynamic: {
          type: "date",
          query: ["YearMonth"],
          template_title: "__name__ WIN5 過去の結果一覧 - WIN!競馬",
          template_description: "__name__ WIN5の過去の結果が一覧表示されます。それぞれの日付を選択すると、日別の勝ち馬、的中票数、払戻金がチェックできます。"
        }
      }
    },
    {
      path: "/win5/results_more",
      component: win5ResultMore,
      meta: {
        title: "WIN5 結果詳細 - WIN!競馬",
        description: "WIN5の結果が詳細表示されます。勝ち馬やその馬の人気、的中票数、払戻金がチェックできます。",
        dynamic: {
          type: "date",
          query: ["DOR"],
          template_title: "__name__ WIN5 結果詳細 - WIN!競馬",
          template_description: "__name__ WIN5の結果が詳細表示されます。勝ち馬やその馬の人気、的中票数、払戻金がチェックできます。"
        }
      }
    },
    {
      path: "/support/help",
      component: Help,
    },
    {
      path: "/support/mail/change",
      component: SettingMailChange,
    },
    {
      path: "/support/mail/setting",
      component: MailSetting,
    },
    {
      path: "/support/mail/settings_nologin",
      component: SettingsNoLogin,
    },
    {
      path: "/support/mail/settings_registmail",
      component: SettingsRegistMail,
    },
    {
      path: "/support/mail/unsubscribe",
      component: Unsubscribe,
    },
    {
      path: "/pog/search_breederhorselist",
      component: PogBreederHorseList,
    },
    {
      path: "/pog/search_managehorselist",
      component: PogManageHorseList,
    },
    {
      path: "/twitter",
      component: Twitter,
    },
    {
      path: "/support/terms_all",
      component: TermsAll,
    },
    {
      path: "/support/apppolicy",
      component: AppPolicy,
    },
    {
      path: "/support/policy",
      component: Policy,
    },
    {
      path: "/support/law",
      component: Law,
    },
    {
      path: "/member/contract",
      component: Contract,
    },
    {
      path: "/race/list",
      component: RaceList,
      meta: {
        title: `レース一覧 - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "今週のJRA/NARのレースを見やすく一覧で表示！競馬データならWIN!競馬!出馬表・オッズ・結果など最新情報をあなたのスマホで!",
      }
    },
    {
      path: "/pog/name",
      component: PogName,
    },
    {
      path: "/pog/join",
      component: PogJoin,
    },
    {
      path: "/member/migrate/data",
      component: MemberInformationIntegration,
    },
    {
      path: "/member/migrate/nonmember",
      component: MemberInformationNonMember,
    },
    {
      path: "/member/customize_odds",
      component: CustomizeOdds,
    },
    {
      path: "/member/customize_card",
      component: CustomizeCard,
    },
    {
      path: "/support/faq",
      component: Faq,
    },
    {
      path: "/support/device",
      component: Device,
    },
    {
      path: "/support/inquiry",
      component: Inquiry,
    },
    {
      path: "/pog/winner",
      component: PogWinner,
    },
    {
      path: "/member",
      component: MemberRegistration,
      meta: {
        title: "会員登録（お試し2週間無料）",
      },
    },
    {
      path: "/race/marks",
      component: Marks,
    },
    {
      path: "/support/quit/nonmember",
      component: NonMember,
      meta: {
        noindex: true
      }
    },
    {
      path: "/horselist/detail",
      component: HorseListDetail,
    },
    {
      path: "/horselist",
      component: HorseList,
    },
    {
      path: "/member/join_economy",
      component: JoinEconomy,
    },
    {
      path: "/support/quit/confirm_select",
      component: ConfirmSelect,
      meta: {
        noindex: true
      }
    },
    {
      path: "/pog/prize",
      component: Prize,
    },
    {
      path: "/pog/info",
      component: PogInfo,
    },
    {
      path: "/support/quit/economy",
      component: QuitEconomy,
      meta: {
        noindex: true
      }
    },
    {
      path: "/support/quit/economy_credit",
      component: QuitEconomyCredit,
      meta: {
        noindex: true
      }
    },
    {
      path: "/support/quit/premium_plus",
      component: QuitPremiumPlus,
      meta: {
        noindex: true
      }
    },
    {
      path: "/support/quit/premium",
      component: QuitPremium,
      meta: {
        noindex: true
      }
    },
    {
      path: "/support/quit/premium_credit",
      component: QuitPremiumCredit,
      meta: {
        noindex: true
      }
    },
    {
      path: "/support/quit/pack03_credit",
      component: QuitPack03Credit,
      meta: {
        noindex: true
      }
    },
    {
      path: "/support/quit/pack06_credit",
      component: QuitPack06Credit,
      meta: {
        noindex: true
      }
    },
    {
      path: "/support/quit/pack12_credit",
      component: QuitPack12Credit,
      meta: {
        noindex: true
      }
    },
    {
      path: "/support/quit/mail_member",
      component: QuitMailMember,
      meta: {
        noindex: true
      }
    },
    {
      path: "/column/sumii/article",
      component: ColumnArticle,
    },
    {
      path: "/sales/list",
      component: SalesList,
    },
    {
      path: "/sales",
      component: Sales,
    },
    {
      path: "/sales/article",
      component: SalesArticle,
    },
    {
      path: "/column/sumii",
      component: Column,
    },
    {
      path: "/member/join-premiumonly",
      component: JoinPremiumOnly,
    },
    {
      path: "/member/pack_rule",
      component: PackRule,
    },
    {
      path: "/feature/article",
      component: FeatureArticle,
      meta: {
        title: `特集ページ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "WIN!競馬(ウインケイバ)の特集ページ一覧です。中央/地方競馬（JRA/NAR）のG1特集ページや日本最大級の競走馬のセリであるセレクトセールの特集ページを一覧にまとめています。",
        keywords: "競馬",
        dynamic: {
          type: "article",
          category: "feature",
          query: ["slug"],
          template_title: `__name__ - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
          template_description: "__name__",
          template_keywords: "__name__"
        },
      }
    },
    {
      path: "/feature",
      component: Feature,
      meta: {
        title: `特集ページ一覧 - WIN!競馬${isNar ? "【地方競馬版】" : ""}`,
        description: "WIN!競馬(ウインケイバ)の特集ページ一覧です。中央/地方競馬（JRA/NAR）のG1特集ページや日本最大級の競走馬のセリであるセレクトセールの特集ページを一覧にまとめています。"
      },
    },
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/member/error/01",
      component: Error01,
    },
    {
      path: "/member/error/02",
      component: Error02,
    },
    {
      path: "/member/error/03",
      component: Error03,
    },
    {
      path: "/member/error/04",
      component: Error04,
    },
    {
      path: "/member/error/y_premium_info_close",
      component: YPremiumClose,
    },
    {
      path: "/error/404",
      component: Error404,
    },
    {
      path: "/error/500",
      component: Error500,
    },
    {
      path: "/authentication",
      component: Authentication,
    },
    {
      path: "/dpoint/coupon_list",
      component: CouponList,
    },
    {
      path: "/dpoint/race_list",
      component: DPointRaceList,
    },
    {
      path: "/:catchAll(.*)",
      component: Error404,
      redirect: "/"
    },
  ];

  const router = createRouter({
    history,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.fullPath === from.fullPath) return false;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 500)
      })
    },
  });

  router.beforeEach(async (to, from, next) => {
    try {
      const store = useStoreCommon();
      const isNar = store.isNarSite;

      if(to.meta?.canonical_param && typeof to.query === 'object' && Object.keys(to.query).length) {
        const filteredQuery = Object.fromEntries(
          Object.entries(to.query).filter(([key]) => to.meta?.canonical_param.includes(key))
        ) || [];
        const query = Object.keys(filteredQuery)
          .map(key => `${key}=${filteredQuery[key]}`)
          .join('&');
        to.meta['canonical_href'] = `${to.path}${query ? '?' : ''}${query}`
      } else {
        to.meta['canonical_href'] = to.href ?? '';
      }

      const dmeta = to.meta?.dynamic;
      if(!dmeta) {
        next();
        return;
      }

      // type=name: APIからmetaを作成
      if(dmeta?.type === 'name') {
        let url = `${process.env.JRA_HOST}webapi/Meta/${dmeta.api}/webapi?`;
        dmeta.query.forEach(q => {
          url += `${q}=${encodeURIComponent(String(to.query[q])) ?? ''}&`
        });
        url = url.slice(0, -1);
        const {data} = await axios.get(url,{ adapter: axiosAdapter }) ?? '';
        to.meta.title = dmeta.template_title
          ? String(dmeta.template_title).replace(/__(\w+)__/g, (match, p1) => {return data[p1] || match})
          : '';
        to.meta.description = dmeta.template_description
          ? String(dmeta.template_description).replace(/__(\w+)__/g, (match, p1) => {return data[p1] || match})
          : '';
        to.meta.keywords = dmeta.template_keywords
          ? String(dmeta.template_keywords).replace(/__(\w+)__/g, (match, p1) => {return data[p1] || match})
          : '';

        // type=query: クエリからmetaを作成
      } else if(dmeta?.type === 'query') {
        to.meta.title = String(dmeta.template_title).replace('__name__', to.query[dmeta.query] ?? '');
        to.meta.description = String(dmeta.template_description).replace('__name__', to.query[dmeta.query] ?? '');

        // type=article: WordPressからmeta情報を取得して作成
      } else if(dmeta?.type === 'article') {
        const host = isNar ? process.env.NAR_WP_HOST : process.env.JRA_WP_HOST;
        let url = `${host}wp-json/wp/v2/${dmeta.category}?_fields=acf,title,content&`;
        dmeta.query.forEach(q => {
          url += `${q}=${encodeURIComponent(String(to.query[q])) ?? ''}&`
        });
        url = url.slice(0, -1);
        const meta = await axios.get(url,{ adapter: axiosAdapter }) ?? '';
        const metaTitle = meta.data[0]?.acf?.long_title || meta.data[0]?.title?.rendered || '';
        const metaDescription = meta.data[0]?.acf?.description ?? '';
        const metaKeywords = meta.data[0]?.acf?.keywords ?? '';
        const metaContent = meta.data[0]?.content?.rendered || '';

        if(metaTitle) {
          to.meta.title = String(dmeta.template_title).replace('__name__', metaTitle ?? '');
        }
        if(metaDescription) {
          to.meta.description = String(dmeta.template_description).replace('__name__', metaDescription ?? '');
        }
        if(metaKeywords) {
          to.meta.keywords = String(dmeta.template_keywords).replace('__name__', metaKeywords ?? '');
        }
      } else if(dmeta?.type === 'date') {
        const queryStr = to.query[dmeta.query] ?? '';
        let dateStr = '';
        if(String(queryStr).length === 6) {
          dateStr = `${parseInt(queryStr.slice(0, 4))}年${parseInt(queryStr.slice(4, 6))}月`;
        } else if(String(queryStr).length === 8) {
          dateStr = `${queryStr.slice(0, 4)}年${parseInt(queryStr.slice(4, 6))}月${parseInt(queryStr.slice(6, 8))}日`;
        }
        to.meta.title = String(dmeta.template_title).replace('__name__', dateStr);
        to.meta.description = String(dmeta.template_description).replace('__name__', dateStr);
      }

    } catch (e) {
      console.log(e)
    }

    next();
  });

  return router;
};
