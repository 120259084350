<script setup>
import {getJSON, isNar, SUCCESS} from "../assets/js/common";
import {JRA_HOST} from "../assets/js/define";
import {ref, onMounted, onServerPrefetch} from "vue";
import moment from "moment";
import {storeToRefs} from "pinia";
import { useStoreCommon } from "../stores/common-states";
const storeCommon = useStoreCommon();
const { host, isNarSite } = storeToRefs(storeCommon);

import { useStoreTopCommon } from "../stores/top/common-states";
const storeTopCommon = useStoreTopCommon();
const { display } = storeToRefs(storeTopCommon);

onServerPrefetch(async () => {
  await loadDisplay()
})

onMounted(async () => {
  if(!display.value) {
    await loadDisplay();
  }
})

async function loadDisplay() {
  const today = moment().format('YYYYMMDD')
  if (isNarSite.value) {
    display.value = 3
  } else {
    const req = `${JRA_HOST}webapi/Top/getTopDisplayMode.js${BACKWORDPATH}?NarFlag=0`;
    await getJSON(req, true, function (status, json) {
      if (status === SUCCESS) {
        display.value = json?.DateType === '0' && json?.TargetDate.some((value) => value.DOR === today)
          ? 1
          : 2
      }
    });
  }
}

import IndexJra from "../components/top/IndexJra.vue";
import IndexNar from "../components/top/IndexNar.vue";
import IndexWeekDay from "../components/top/IndexWeekDay.vue";
import {BACKWORDPATH} from "../assets/js/define";

</script>
<template>
  <IndexJra v-if="display === 1"></IndexJra>
  <IndexWeekDay v-if="display === 2"></IndexWeekDay>
  <IndexNar v-if="display === 3"></IndexNar>
</template>
