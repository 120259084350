<script setup>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";
import {JRA_WP_HOST} from "../../../assets/js/define";

//本番用 APIURL
const API_URL = JRA_WP_HOST +"wp-json/wp/v2/pages?slug=support-device";

let list = reactive({});
onMounted(() => {
  document.body.className = "";
  axios
    .get(API_URL)
    .then((response) => {
      Object.assign(list, response.data[0]);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      console.log(error);
      document.body.className = "status-loaded";
    });
});
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">推奨環境</h1>
    </section>
    <div v-if="list.content" v-html="list.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../../assets/css/support/style.scss"></style>
