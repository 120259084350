<template>
  <div class="container-tabtarget" data-target>
    <div class="item">
      <div class="inner">
        <h2 class="heading-circle">コース</h2>
        <section class="layout-sub">
          <h3 class="heading-bar">中央実績</h3>
          <table class="table-cell -center" v-if="Object.keys(courseData).length">
            <tbody>
            <tr>
              <th>コース</th>
              <th>実績</th>
            </tr>
            <tr v-if="courseData.turf">
              <th>芝</th>
              <td>{{ courseData.turf }}</td>
            </tr>
            <tr v-if="courseData.dirt">
              <th>ダ</th>
              <td>{{ courseData.dirt }}</td>
            </tr>
            <tr v-if="courseData.obstacle">
              <th>障</th>
              <td>{{ courseData.obstacle }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            競走実績はありません
          </div>
        </section>
        <section class="layout-sub">
          <h3 class="heading-bar">地方実績</h3>
          <table class="table-cell -center" v-if="Object.keys(narCourseData).length">
            <tbody>
            <tr>
              <th>コース</th>
              <th>実績</th>
            </tr>
            <tr v-if="narCourseData.turf">
              <th>芝</th>
              <td>{{ narCourseData.turf }}</td>
            </tr>
            <tr v-if="narCourseData.dirt">
              <th>ダ</th>
              <td>{{ narCourseData.dirt }}</td>
            </tr>
            <tr v-if="narCourseData.obstacle">
              <th>障</th>
              <td>{{ narCourseData.obstacle }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            競走実績はありません
          </div>
        </section>
      </div>

      <div class="inner">
        <h2 class="heading-circle">騎手</h2>
        <section class="layout-sub">
          <h3 class="heading-bar">中央実績</h3>
          <table class="table-cell -center" v-if="jockeyData.length">
            <tbody>
            <tr>
              <th>名前</th>
              <th>実績</th>
              <th>勝率</th>
              <th>連対率</th>
              <th>3着内率</th>
            </tr>
            <tr v-for="(result, index) in jockeyData" :key="index">
              <th>{{ result.name }}</th>
              <td>
                {{ result.jockeyresult.total }}<br />{{ result.jockeyresult.all }}
              </td>
              <td>{{ result.first }}</td>
              <td>{{ result.second }}</td>
              <td>{{ result.third }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            競走実績はありません
          </div>
        </section>
        <section class="layout-sub">
          <h3 class="heading-bar">地方実績</h3>
          <table class="table-cell -center" v-if="narJockeyData.length">
            <tbody>
            <tr>
              <th>名前</th>
              <th>実績</th>
              <th>勝率</th>
              <th>連対率</th>
              <th>3着内率</th>
            </tr>
            <tr v-for="(result, index) in narJockeyData" :key="index">
              <th>{{ result.name }}</th>
              <td>
                {{ result.jockeyresult.total }}<br />{{ result.jockeyresult.all }}
              </td>
              <td>{{ result.first }}</td>
              <td>{{ result.second }}</td>
              <td>{{ result.third }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            競走実績はありません
          </div>
        </section>
      </div>

      <div class="inner">
        <h2 class="heading-circle">馬場</h2>
        <section class="layout-sub">
          <h3 class="heading-bar">中央実績</h3>
          <table class="table-cell -center" v-if="Object.keys(conditionData).length">
            <tbody>
            <tr>
              <th>馬場状態</th>
              <th>実績</th>
            </tr>
            <tr>
              <th>良</th>
              <td>{{ conditionData.firm }}</td>
            </tr>
            <tr>
              <th>稍重</th>
              <td>{{ conditionData.good }}</td>
            </tr>
            <tr>
              <th>重</th>
              <td>{{ conditionData.yielding }}</td>
            </tr>
            <tr>
              <th>不良</th>
              <td>{{ conditionData.soft }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            競走実績はありません
          </div>
        </section>
        <section class="layout-sub">
          <h3 class="heading-bar">地方実績</h3>
          <table class="table-cell -center" v-if="Object.keys(narConditionData).length">
            <tbody>
            <tr>
              <th>馬場状態</th>
              <th>実績</th>
            </tr>
            <tr>
              <th>良</th>
              <td>{{ narConditionData.firm }}</td>
            </tr>
            <tr>
              <th>稍重</th>
              <td>{{ narConditionData.good }}</td>
            </tr>
            <tr>
              <th>重</th>
              <td>{{ narConditionData.yielding }}</td>
            </tr>
            <tr>
              <th>不良</th>
              <td>{{ narConditionData.soft }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            競走実績はありません
          </div>
        </section>
      </div>

      <div class="inner">
        <h2 class="heading-circle">距離</h2>
        <section class="layout-sub">
          <h3 class="heading-bar">中央実績</h3>
          <div v-if="distanceData.length">
            <Chart :options="distanceChartData" catchLegendEvents="true" />
            <table class="table-cell -center">
              <tbody>
              <tr>
                <th>距離</th>
                <th>実績</th>
                <th>勝率</th>
                <th>連対率</th>
                <th>3着内率</th>
              </tr>
              <tr v-for="(result, index) in distanceData" :key="index">
                <th>{{ result.distance }}</th>
                <td>{{ result.total }}<br />{{ result.totalscore }}</td>
                <td>{{ result.rate1st }}</td>
                <td>{{ result.rate2nd }}</td>
                <td>{{ result.rate3rd }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            競走実績はありません
          </div>
        </section>
        <section class="layout-sub">
          <h3 class="heading-bar">地方実績</h3>
          <div v-if="narDistanceData.length">
            <Chart :options="narDistanceChartData" catchLegendEvents="true" />
            <table class="table-cell -center">
              <tbody>
              <tr>
                <th>距離</th>
                <th>実績</th>
                <th>勝率</th>
                <th>連対率</th>
                <th>3着内率</th>
              </tr>
              <tr v-for="(result, index) in narDistanceData" :key="index">
                <th>{{ result.distance }}</th>
                <td>{{ result.total }}<br />{{ result.totalscore }}</td>
                <td>{{ result.rate1st }}</td>
                <td>{{ result.rate2nd }}</td>
                <td>{{ result.rate3rd }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            競走実績はありません
          </div>
        </section>
      </div>

      <div class="inner">
        <h2 class="heading-circle">競馬場</h2>
        <section class="layout-sub">
          <h3 class="heading-bar">中央実績</h3>
          <div v-if="Object.keys(raceTrackData).length">
            <table class="table-line -center -middle -resultgraph">
              <tbody>
                <template v-if="raceChartData.tokyo.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span class="-ccw">東京</span></th>
                    <td class="data">{{ raceChartData.tokyo.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.tokyo.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.nakayama.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2">中山</th>
                    <td class="data">{{ raceChartData.nakayama.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.nakayama.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.kyoto.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2">京都</th>
                    <td class="data">{{ raceChartData.kyoto.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.kyoto.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.hanshin.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2">阪神</th>
                    <td class="data">{{ raceChartData.hanshin.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.hanshin.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.chukyo.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span class="-ccw">中京</span></th>
                    <td class="data">{{ raceChartData.chukyo.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.chukyo.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.sapporo.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2">札幌</th>
                    <td class="data">{{ raceChartData.sapporo.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.sapporo.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.hakodate.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2">函館</th>
                    <td class="data">{{ raceChartData.hakodate.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.hakodate.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.fukushima.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2">福島</th>
                    <td class="data">{{ raceChartData.fukushima.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.fukushima.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.nigata.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span class="-ccw">新潟</span></th>
                    <td class="data">{{ raceChartData.nigata.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.nigata.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.kokura.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2">小倉</th>
                    <td class="data">{{ raceChartData.kokura.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.kokura.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <table class="table-cell -center">
              <tbody>
              <tr>
                <th>着順</th>
                <th><span class="-ccw">東</span></th>
                <th>中</th>
                <th>京</th>
                <th>阪</th>
                <th><span class="-ccw">名</span></th>
                <th>札</th>
                <th>函</th>
                <th>福</th>
                <th><span class="-ccw">新</span></th>
                <th>小</th>
              </tr>
              <tr>
                <th class="bg-rank01">1着</th>
                <td v-for="(order, index) in raceTrackData.jra" :key="index">
                  {{ order.place1st }}
                </td>
              </tr>
              <tr>
                <th class="bg-rank02">2着</th>
                <td v-for="(order, index) in raceTrackData.jra" :key="index">
                  {{ order.place2nd }}
                </td>
              </tr>
              <tr>
                <th class="bg-rank03">3着</th>
                <td v-for="(order, index) in raceTrackData.jra" :key="index">
                  {{ order.place3rd }}
                </td>
              </tr>
              <tr>
                <th>着外</th>
                <td v-for="(order, index) in raceTrackData.jra" :key="index">
                  {{ order.unplaced }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            競走実績はありません
          </div>
        </section>

        <section class="layout-sub">
          <h3 class="heading-bar">地方実績</h3>
          <div v-if="Object.keys(narRaceTrackData).length">
            <table class="table-line -center -middle -resultgraph">
              <tbody>
                <template v-if="raceChartData.kawasaki.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span class="-ccw">川崎</span></th>
                    <td class="data">{{ raceChartData.kawasaki.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.kawasaki.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.oi.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>大井</span></th>
                    <td class="data">{{ raceChartData.oi.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.oi.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.hunabashi.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span class="-ccw">船橋</span></th>
                    <td class="data">{{ raceChartData.hunabashi.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.hunabashi.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.urawa.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span class="-ccw">浦和</span></th>
                    <td class="data">{{ raceChartData.urawa.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.urawa.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.monbetsu.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>門別</span></th>
                    <td class="data">{{ raceChartData.monbetsu.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.monbetsu.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.morioka.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span class="-ccw">盛岡</span></th>
                    <td class="data">{{ raceChartData.morioka.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.morioka.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.mizusawa.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>水沢</span></th>
                    <td class="data">{{ raceChartData.mizusawa.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.mizusawa.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.kanazawa.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>金沢</span></th>
                    <td class="data">{{ raceChartData.kanazawa.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.kanazawa.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.kasamatsu.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>笠松</span></th>
                    <td class="data">{{ raceChartData.kasamatsu.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.kasamatsu.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.nagoya.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>名古屋</span></th>
                    <td class="data">{{ raceChartData.nagoya.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.nagoya.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.sonoda.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>園田</span></th>
                    <td class="data">{{ raceChartData.sonoda.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.sonoda.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.himezi.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>姫路</span></th>
                    <td class="data">{{ raceChartData.himezi.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.himezi.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.kochi.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>高知</span></th>
                    <td class="data">{{ raceChartData.kochi.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.kochi.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="raceChartData.saga.raceRes !== '[0 0 0 0]'">
                  <tr>
                    <th rowspan="2"><span>佐賀</span></th>
                    <td class="data">{{ raceChartData.saga.raceRes }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="graph-stack">
                        <Chart
                            :options="raceChartData.saga.raceChart"
                            catchLegendEvents="true"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <table class="table-cell _marginbtn -center">
              <tbody>
              <tr>
                <th>着順</th>
                <th><span class="-ccw">川</span></th>
                <th>大</th>
                <th><span class="-ccw">船</span></th>
                <th><span class="-ccw">浦</span></th>
                <th>門</th>
                <th><span class="-ccw">盛</span></th>
                <th>水</th>
              </tr>
              <tr>
                <th class="bg-rank01">1着</th>
                <td
                    v-for="(order, index) in narRaceTrackData.nar_top"
                    :key="index"
                >
                  {{ order.place1st }}
                </td>
              </tr>
              <tr>
                <th class="bg-rank02">2着</th>
                <td
                    v-for="(order, index) in narRaceTrackData.nar_top"
                    :key="index"
                >
                  {{ order.place2nd }}
                </td>
              </tr>
              <tr>
                <th class="bg-rank03">3着</th>
                <td
                    v-for="(order, index) in narRaceTrackData.nar_top"
                    :key="index"
                >
                  {{ order.place3rd }}
                </td>
              </tr>
              <tr>
                <th>着外</th>
                <td
                    v-for="(order, index) in narRaceTrackData.nar_top"
                    :key="index"
                >
                  {{ order.unplaced }}
                </td>
              </tr>
              </tbody>
            </table>
            <table class="table-cell _marginbtn -center">
              <tbody>
              <tr>
                <th>着順</th>
                <th><div>金</div></th>
                <th>笠</th>
                <th><div>名</div></th>
                <th><div>園</div></th>
                <th>姫</th>
                <th><div>高</div></th>
                <th>佐</th>
              </tr>
              <tr>
                <th class="bg-rank01">1着</th>
                <td
                    v-for="(order, index) in narRaceTrackData.nar_bottom"
                    :key="index"
                >
                  {{ order.place1st }}
                </td>
              </tr>
              <tr>
                <th class="bg-rank02">2着</th>
                <td
                    v-for="(order, index) in narRaceTrackData.nar_bottom"
                    :key="index"
                >
                  {{ order.place2nd }}
                </td>
              </tr>
              <tr>
                <th class="bg-rank03">3着</th>
                <td
                    v-for="(order, index) in narRaceTrackData.nar_bottom"
                    :key="index"
                >
                  {{ order.place3rd }}
                </td>
              </tr>
              <tr>
                <th>着外</th>
                <td
                    v-for="(order, index) in narRaceTrackData.nar_bottom"
                    :key="index"
                >
                  {{ order.unplaced }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            競走実績はありません
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script setup>
const { Chart } = process.browser ? require('highcharts-vue') : null;
</script>
<script>
import {
  totalRes,
  winFormat,
} from "../../../assets/js/common.js";
export default {
  data() {
    return {
      courseData: {},
      narCourseData: {},
      jockeyData: [],
      narJockeyData: [],
      conditionData: {},
      narConditionData: {},
      distanceData: [],
      narDistanceData: [],
      distanceInfo: {},
      raceTrackData: {},
      narRaceTrackData: {},
      distanceChartData: null,
      narDistanceChartData: null,
      raceChartData: {
        sapporo: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        hakodate: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        fukushima: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        nakayama: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        tokyo: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        nigata: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        chukyo: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        kyoto: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        hanshin: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        kokura: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        monbetsu: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        morioka: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        mizusawa: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        urawa: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        hunabashi: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        oi: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        kawasaki: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        kanazawa: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        kasamatsu: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        nagoya: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        sonoda: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        himezi: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        kochi: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
        saga: {
          raceRes: "[0 0 0 0]",
          raceChart: null,
        },
      },
    };
  },
  props: {
    horseResultData: Object,
    narHorseResultData: Object,
  },
  methods: {
    getCourseData: function (horseResultData) {
      let courseData = {};

      if ("TrackStat" in horseResultData && horseResultData.TrackStat.length > 0) {
        courseData = {
          turf: "",
          dirt: "",
          obstacle: "",
        };
        let courseInfo = new Object();
        for (let i in horseResultData.TrackStat) {
          const trackStat = horseResultData.TrackStat[i];
          const first = Number(trackStat.Place1st);
          const second = Number(trackStat.Place2nd);
          const third = Number(trackStat.Place3rd);
          const unplaced = Number(trackStat.Unplaced);
          const result = totalRes(first, second, third, unplaced) + "戦 ["
              + first + " " + second + " " + third + " " + unplaced + "]";

          switch (trackStat.TrackCd) {
            case "1":
              courseData.turf = result;
              courseInfo[trackStat.TrackCd] = new Array(
                  "芝 " +
                  totalRes(first, second, third, unplaced) +
                  "戦 <br>[" +
                  first +
                  " " +
                  second +
                  " " +
                  third +
                  " " +
                  unplaced +
                  "]",
                  totalRes(first, second, third, unplaced)
              );
              break;
            case "2":
              courseData.dirt = result;
              courseInfo[trackStat.TrackCd] = new Array(
                  "ダ " +
                  totalRes(first, second, third, unplaced) +
                  "戦 <br>[" +
                  first +
                  " " +
                  second +
                  " " +
                  third +
                  " " +
                  unplaced +
                  "]",
                  totalRes(first, second, third, unplaced)
              );
              break;
            case "3":
              courseData.obstacle = result;
              courseInfo[trackStat.TrackCd] = new Array(
                  "障 " +
                  totalRes(first, second, third, unplaced) +
                  "戦 <br>[" +
                  first +
                  " " +
                  second +
                  " " +
                  third +
                  " " +
                  unplaced +
                  "]",
                  totalRes(first, second, third, unplaced)
              );
              break;
          }
        }
      }
      return courseData;
    },
    getJockeyData: function (horseResultData) {
      const jockeyData = [];
      if (horseResultData.JockeyStat !== undefined && horseResultData.JockeyStat !== null) {
        for (let i in horseResultData.JockeyStat) {
          const jockeyStat = horseResultData.JockeyStat[i];
          const first = Number(jockeyStat.Place1st);
          const second = Number(jockeyStat.Place2nd);
          const third = Number(jockeyStat.Place3rd);
          const unplaced = Number(jockeyStat.Unplaced);
          const total = totalRes(first, second, third, unplaced);
          const jockeyDataSingle = {
            name: jockeyStat.JockeyName,
            jockeyresult: {
              total: totalRes(first, second, third, unplaced) + "戦",
              all:
                  "[" + first + " " + second + " " + third + " " + unplaced + "]",
            },
            first: parseFloat(winFormat(total, first)) + "%",
            second: parseFloat(winFormat(total, first + second)) + "%",
            third: parseFloat(winFormat(total, first + second + third)) + "%",
          };

          jockeyData.push(jockeyDataSingle);
        }
      }
      return jockeyData;
    },
    getConditionData: function (horseResultData) {
      let conditionData = {};
      if (horseResultData.TrackConditionStat.length > 0) {
        conditionData = {
          firm: "0戦 [0 0 0 0]",
          good: "0戦 [0 0 0 0]",
          yielding: "0戦 [0 0 0 0]",
          soft: "0戦 [0 0 0 0]",
        };
        for (let j in horseResultData.TrackConditionStat) {
          const track = horseResultData.TrackConditionStat[j];
          const first = Number(track.Place1st);
          const second = Number(track.Place2nd);
          const third = Number(track.Place3rd);
          const unplaced = Number(track.Unplaced);
          const total = totalRes(first, second, third, unplaced);
          const result = total + "戦 [" + first + " " + second + " " + third + " " + unplaced + "]";
          switch (track.TrackConditionCd) {
            case "1":
              conditionData.firm = result;
              break;
            case "2":
              conditionData.good = result;
              break;
            case "3":
              conditionData.yielding = result;
              break;
            case "4":
              conditionData.soft = result;
              break;
          }
        }
      }
      return conditionData;
    },
    getDistanceData: function (horseResultData) {
      let distanceData = [];
      this.distanceInfo = {};
      if (horseResultData.DistanceStat.length > 0) {
        distanceData = [
          {
            distance: "~1200m",
            total: "0戦",
            totalscore: "[0 0 0 0]",
            rate1st: "0%",
            rate2nd: "0%",
            rate3rd: "0%",
          },
          {
            distance: "~1600m",
            total: "0戦",
            totalscore: "[0 0 0 0]",
            rate1st: "0%",
            rate2nd: "0%",
            rate3rd: "0%",
          },
          {
            distance: "~2000m",
            total: "0戦",
            totalscore: "[0 0 0 0]",
            rate1st: "0%",
            rate2nd: "0%",
            rate3rd: "0%",
          },
          {
            distance: "~2400m",
            total: "0戦",
            totalscore: "[0 0 0 0]",
            rate1st: "0%",
            rate2nd: "0%",
            rate3rd: "0%",
          },
          {
            distance: "~3000m",
            total: "0戦",
            totalscore: "[0 0 0 0]",
            rate1st: "0%",
            rate2nd: "0%",
            rate3rd: "0%",
          },
          {
            distance: "3001m~",
            total: "0戦",
            totalscore: "[0 0 0 0]",
            rate1st: "0%",
            rate2nd: "0%",
            rate3rd: "0%",
          },
        ];
        for (let j in horseResultData.DistanceStat) {
          const track = horseResultData.DistanceStat[j];
          const first = Number(track.Place1st);
          const second = Number(track.Place2nd);
          const third = Number(track.Place3rd);
          const unplaced = Number(track.Unplaced);
          const total = totalRes(first, second, third, unplaced);
          let targetRow = "";
          switch (track.DistanceDiv) {
            case "1": //~1200m
              targetRow = 0;
              break;
            case "2": //~1600m
              targetRow = 1;
              break;
            case "3": //~2000m
              targetRow = 2;
              break;
            case "4": //~2400m
              targetRow = 3;
              break;
            case "5": //~3000m
              targetRow = 4;
              break;
            case "6": //3001m~
              targetRow = 5;
              break;
          }
          distanceData[targetRow].total =
              totalRes(first, second, third, unplaced) + "戦";
          distanceData[targetRow].totalscore =
              "[" + first + " " + second + " " + third + " " + unplaced + "]";
          distanceData[targetRow].rate1st =
              parseFloat(winFormat(total, first)) + "%";
          distanceData[targetRow].rate2nd =
              parseFloat(winFormat(total, first + second)) + "%";
          distanceData[targetRow].rate3rd =
              parseFloat(winFormat(total, first + second + third)) + "%";
          this.distanceInfo[track.DistanceDiv] = [
            parseFloat(winFormat(total, first)),
            parseFloat(winFormat(total, first + second)),
            parseFloat(winFormat(total, first + second + third))
          ];
        }
      }
      return distanceData;
    },
    setDistanceGraph: function (info) {
      let param1 = info["1"] != undefined ? info["1"] : new Array(0, 0, 0);
      let param2 = info["2"] != undefined ? info["2"] : new Array(0, 0, 0);
      let param3 = info["3"] != undefined ? info["3"] : new Array(0, 0, 0);
      let param4 = info["4"] != undefined ? info["4"] : new Array(0, 0, 0);
      let param5 = info["5"] != undefined ? info["5"] : new Array(0, 0, 0);
      let param6 = info["6"] != undefined ? info["6"] : new Array(0, 0, 0);

      let distanceChartData = {
        credits: {
          enabled: false,
        },
        chart: {
          type: "bar",
          marginRight: 30,
          height: 300,
        },
        colors: ["#D13636", "#2F93C6", "#FFCC00"],
        title: {
          text: null,
        },
        xAxis: {
          categories: [
            "～1200m",
            "～1600m",
            "～2000m",
            "～2400m",
            "～3000m",
            "3001m～",
          ],
        },
        yAxis: {
          max: 100,
          title: {
            text: null,
          },
          labels: {
            format: "{value} %",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 10,
          },
        },
        series: [
          {
            name: "勝率",
            data: [
              param1[0],
              param2[0],
              param3[0],
              param4[0],
              param5[0],
              param6[0],
            ],
          },
          {
            name: "連対率",
            data: [
              param1[1],
              param2[1],
              param3[1],
              param4[1],
              param5[1],
              param6[1],
            ],
          },
          {
            name: "3着内率",
            data: [
              param1[2],
              param2[2],
              param3[2],
              param4[2],
              param5[2],
              param6[2],
            ],
          },
        ],
      };
      return distanceChartData;
    },
    getRaceTrackData: function (horseResultData) {
      let self = this;
      let raceTrackData = {};
      if (horseResultData.RacetrackStat.length > 0) {
        raceTrackData = { jra: [
            {
              name: "東京",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "中山",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "京都",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "阪神",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "名古屋",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "札幌",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "函館",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "福島",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "新潟",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "小倉",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
          ] };
        for (let j in horseResultData.RacetrackStat) {
          let track = horseResultData.RacetrackStat[j];
          let targetRow = "";
          let raceTrackType = "";
          switch (track.RacetrackCd) {
            case "01": //札幌
              targetRow = 5;
              raceTrackType = "jra";
              self.raceChartData.sapporo.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "sapporo",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "02": //函館
              targetRow = 6;
              raceTrackType = "jra";
              self.raceChartData.hakodate.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "hakodate",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "03": //福島
              targetRow = 7;
              raceTrackType = "jra";
              self.raceChartData.fukushima.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "fukushima",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "04": //新潟
              targetRow = 8;
              raceTrackType = "jra";
              self.raceChartData.nigata.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "nigata",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "05": //東京
              targetRow = 0;
              raceTrackType = "jra";
              self.raceChartData.tokyo.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "tokyo",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "06": //中山
              targetRow = 1;
              raceTrackType = "jra";
              self.raceChartData.nakayama.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "nakayama",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "07": //中京(名古屋)
              targetRow = 4;
              raceTrackType = "jra";
              self.raceChartData.chukyo.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "chukyo",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "08": //京都
              targetRow = 2;
              raceTrackType = "jra";
              self.raceChartData.kyoto.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "kyoto",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "09": //阪神
              targetRow = 3;
              raceTrackType = "jra";
              self.raceChartData.hanshin.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "hanshin",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "10": //小倉
              targetRow = 9;
              raceTrackType = "jra";
              self.raceChartData.kokura.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "kokura",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
          }
          raceTrackData.jra[targetRow] = {
            place1st: track.Place1st,
            place2nd: track.Place2nd,
            place3rd: track.Place3rd,
            unplaced: track.Unplaced,
          };
        }
      }
      return raceTrackData;
    },
    getNarRaceTrackData: function (horseResultData) {
      let self = this;
      let raceTrackData = {};
      if (horseResultData.RacetrackStat.length > 0) {
        raceTrackData = {
          nar_top: [
            {
              name: "川崎",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "大井",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "船橋",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "浦和",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "門別",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "盛岡",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "水沢",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
          ],
          nar_bottom: [
            {
              name: "金沢",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "笠松",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "名古屋",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "園田",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "姫路",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "高知",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
            {
              name: "佐賀",
              place1st: "0",
              place2nd: "0",
              place3rd: "0",
              unplaced: "0",
            },
          ],
        };

        for (let j in horseResultData.RacetrackStat) {
          let track = horseResultData.RacetrackStat[j];
          let targetRow = "";
          let raceTrackType = "";
          switch (track.RacetrackCd) {
            case "30": //門別
              targetRow = 4;
              raceTrackType = "narTop";
              self.raceChartData.monbetsu.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "monbetsu",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "35": //盛岡
              targetRow = 5;
              raceTrackType = "narTop";
              self.raceChartData.morioka.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "morioka",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "36": //水沢
              targetRow = 6;
              raceTrackType = "narTop";
              self.raceChartData.mizusawa.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "mizusawa",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "42": //浦和
              targetRow = 3;
              raceTrackType = "narTop";
              self.raceChartData.urawa.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "urawa",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "43": //船橋
              targetRow = 2;
              raceTrackType = "narTop";
              self.raceChartData.hunabashi.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "hunabashi",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "44": //大井
              targetRow = 1;
              raceTrackType = "narTop";
              self.raceChartData.oi.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "oi",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "45": //川崎
              targetRow = 0;
              raceTrackType = "narTop";
              self.raceChartData.kawasaki.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "kawasaki",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "46": //金沢
              targetRow = 0;
              raceTrackType = "narBottom";
              self.raceChartData.kanazawa.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "kanazawa",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "47": //笹松
              targetRow = 1;
              raceTrackType = "narBottom";
              self.raceChartData.kasamatsu.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "kasamatsu",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "48": //名古屋
              targetRow = 2;
              raceTrackType = "narBottom";
              self.raceChartData.nagoya.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "nagoya",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "50": //園田
              targetRow = 3;
              raceTrackType = "narBottom";
              self.raceChartData.sonoda.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "sonoda",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "51": //姫路
              targetRow = 4;
              raceTrackType = "narBottom";
              self.raceChartData.himezi.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "himezi",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "54": //高知
              targetRow = 5;
              raceTrackType = "narBottom";
              self.raceChartData.kochi.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "kochi",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
            case "55": //佐賀
              targetRow = 6;
              raceTrackType = "narBottom";
              self.raceChartData.saga.raceRes =
                  "[" +
                  track.Place1st +
                  " " +
                  track.Place2nd +
                  " " +
                  track.Place3rd +
                  " " +
                  track.Unplaced +
                  "]";
              self.setRacetrackGraph(
                  "saga",
                  track.Place1st,
                  track.Place2nd,
                  track.Place3rd,
                  track.Unplaced
              );
              break;
          }
          if (raceTrackType == "narTop") {
            raceTrackData.nar_top[targetRow] = {
              place1st: track.Place1st,
              place2nd: track.Place2nd,
              place3rd: track.Place3rd,
              unplaced: track.Unplaced,
            };
          } else if (raceTrackType == "narBottom") {
            raceTrackData.nar_bottom[targetRow] = {
              place1st: track.Place1st,
              place2nd: track.Place2nd,
              place3rd: track.Place3rd,
              unplaced: track.Unplaced,
            };
          }
        }
      }
      return raceTrackData;
    },
    setRacetrackGraph: function (raceTrackName, first, second, third, unplaced) {
      first = parseInt(first, 10);
      second = parseInt(second, 10);
      third = parseInt(third, 10);
      unplaced = parseInt(unplaced, 10);
      let racetrackChartData = {
        credits: {
          enabled: false,
        },
        chart: {
          type: "bar",
          margin: 0,
          width: 260,
          height: 14,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: ["累計"],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
          },
          lineColor: "#fff",
          gridLineWidth: 0,
        },
        yAxis: {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            enabled: false,
          },
          gridLineWidth: 0,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            enableMouseTracking: false,
            stacking: "percent",
            borderWidth: 0,
            pointPadding: 0,
            groupPadding: 0,
            dataLabels: {
              enabled: false,
              color: "white",
            },
          },
        },
        series: [
          { name: "着外", data: [unplaced], color: "#aaa" },
          { name: "3着", data: [third], color: "#FFCC00" },
          { name: "2着", data: [second], color: "#2F93C6" },
          { name: "1着", data: [first], color: "#D13636" },
        ],
      };
      this.raceChartData[raceTrackName].raceChart = racetrackChartData;
    }
  },
  created() {
    let self = this;
    //実績
    //コース別実績
    self.courseData = self.getCourseData(self.horseResultData);
    self.narCourseData = self.getCourseData(self.narHorseResultData);

    //騎手別実績
    self.jockeyData = self.getJockeyData(self.horseResultData);
    self.narJockeyData = self.getJockeyData(self.narHorseResultData);

    //馬場状態別実績
    self.conditionData = self.getConditionData(self.horseResultData);
    self.narConditionData = self.getConditionData(self.narHorseResultData);

    //距離別実績
    self.distanceData = self.getDistanceData(self.horseResultData);
    self.distanceChartData = self.setDistanceGraph(self.distanceInfo);

    self.narDistanceData = self.getDistanceData(self.narHorseResultData);
    self.narDistanceChartData = self.setDistanceGraph(self.distanceInfo);

    //競馬場別
    self.raceTrackData = self.getRaceTrackData(self.horseResultData);
    self.narRaceTrackData = self.getNarRaceTrackData(self.narHorseResultData);
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>