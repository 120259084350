<script setup>
import { ref } from "vue";
import { setDismissDialog } from "../../assets/js/common";
let dialogChecked = ref(false);
const emit = defineEmits(["closeDialog", "delItem"]);

const props = defineProps({
  modalBalanceOpen: Boolean,
});

const modalClose = () => {
  emit("closeDialog", false);
};

const delSelectItem = () => {
  emit("delItem", true);
  emit("closeDialog", false);
}

</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalBalanceOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div @click.self="modalClose" v-if="modalBalanceOpen" class="modal-frame">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                収支登録追加
              </div>
              <div class="modal-body">
                <div class="modal-message">
                  <p>
                    収支登録完了しました。<br>登録した買い目を購入予定一覧から削除しますか？
                  </p>
                </div>
                <div class="modal-nav">
                  <span class="btn-basic -gray close-modal" @click="modalClose">キャンセル</span>
                  <span class="btn-basic close-modal" @click="delSelectItem">OK</span>
                </div>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
