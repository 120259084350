<template>
  <swiper :navigation="navigation"
          :pagination="pagination"
          :loop="true"
          :autoplay="autoplay">
    <swiper-slide v-for="(slide, index) in slider" :key="index">
      <div v-html="slide"></div>
    </swiper-slide>
    <div class="swiper-button -prev"></div>
    <div class="swiper-button -next"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
  name: "Slider",
  props: {
    slider: Array
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      navigation: {
        enabled: true,
        nextEl: ".swiper-button.-next",
        prevEl: ".swiper-button.-prev",
      },
      pagination: {
        enabled: true,
      },
    };
  },
}
</script>

<style scoped>
/* firefox レイアウト崩れ対策 */
@-moz-document url-prefix() {
  .swiper {
    height: auto !important;
  }
  .swiper-wrapper {
    height: auto !important;
  }
}
</style>

<style lang="scss" src="../../assets/css/lib/swiper/swiper.scss"></style>
