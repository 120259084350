<script setup>
import { ref, onMounted } from "vue";
import { HOST } from "../../../assets/js/define";
import {
  getJoinStatus,
  setCookie,
  JOINSTATUS,
} from "../../../assets/js/common";

const creditEconomy = ref(false);
const creditPremium = ref(false);
const creditPack03 = ref(false);
const creditPack06 = ref(false);
const creditPack12 = ref(false);
const careerEconomy = ref(false);
const careerPlus = ref(false);
const careerPremium = ref(false);
const PaymentCredit = ref(false);
const PaymentCarrer = ref(false);

const creditEconomyLink = HOST + "support/quit/economy_credit";
const creditPremiumLink = HOST + "support/quit/premium_credit";
const creditPack03Link = HOST + "support/quit/pack03_credit";
const creditPack06Link = HOST + "support/quit/pack06_credit";
const creditPack12Link = HOST + "support/quit/pack12_credit";
const careerEconomyLink = HOST + "support/quit/economy";
const careerPlusLink = HOST + "support/quit/premium_plus";
const careerPremiumLink = HOST + "support/quit/premium";

onMounted(() => {
  const joinStatus = getJoinStatus();

  const joinStatusArr = joinStatus.split(",");
  if (
    joinStatusArr.indexOf("11") >= 0 ||
    joinStatusArr.indexOf("12") >= 0 ||
    joinStatusArr.indexOf("13") >= 0
  ) {
    PaymentCredit.value = true;
    if (joinStatusArr.indexOf("11") >= 0) {
      creditEconomy.value = true;
    }

    if (joinStatusArr.indexOf("12") >= 0 || joinStatusArr.indexOf("13") >= 0) {
      creditPremium.value = true;
    }
  }

  if (
    joinStatusArr.indexOf("21") >= 0 ||
    joinStatusArr.indexOf("22") >= 0 ||
    joinStatusArr.indexOf("23") >= 0
  )
    PaymentCredit.value = true;
  {
    if (joinStatusArr.indexOf("21") >= 0) {
      creditPack03.value = true;
    }

    if (joinStatusArr.indexOf("22") >= 0) {
      creditPack06.value = true;
    }

    if (joinStatusArr.indexOf("23") >= 0) {
      creditPack12.value = true;
    }
  }

  if (
    joinStatusArr.indexOf("1") >= 0 ||
    joinStatusArr.indexOf("2") >= 0 ||
    joinStatusArr.indexOf("3") >= 0 ||
    joinStatusArr.indexOf("4") >= 0 ||
    joinStatusArr.indexOf("5") >= 0
  ) {
    PaymentCarrer.value = true;
    if (joinStatusArr.indexOf("1") >= 0) {
      careerEconomy.value = true;
    }
    if (joinStatusArr.indexOf("2") >= 0 || joinStatusArr.indexOf("4") >= 0) {
      careerPlus.value = true;
    }
    if (joinStatusArr.indexOf("3") >= 0 || joinStatusArr.indexOf("5") >= 0) {
      careerPremium.value = true;
    }
  }
});
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">会員解約</h1>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner">
          <h2 class="heading-circle">退会決済選択</h2>
          <p>
            お客様は複数のコースに重複入会されております。以下のボタンより、退会するコースを選択してください。
          </p>
          <div v-if="PaymentCredit === true" class="layout-sub">
            <h3 class="heading-bar">クレジット決済</h3>
            <p>クレジット決済の退会画面に遷移します。</p>
            <ul class="list-btn">
              <li :style="[creditPremium === true ? '' : 'display:none']">
                <a :href="creditPremiumLink" class="btn-basic -wide"
                  >クレジット決済を退会（プレミアムコース)</a
                >
              </li>
              <li :style="[creditEconomy === true ? '' : 'display:none']">
                <a :href="creditEconomyLink" class="btn-basic -wide"
                  >クレジット決済を退会（エコノミーコース)</a
                >
              </li>
              <li :style="[creditPack03 === true ? '' : 'display:none']">
                <a :href="creditPack03Link" class="btn-basic -wide"
                  >クレジット決済を退会（3ヶ月パックコース)</a
                >
              </li>
              <li :style="[creditPack06 === true ? '' : 'display:none']">
                <a :href="creditPack06Link" class="btn-basic -wide"
                  >クレジット決済を退会（6ヶ月パックコース)</a
                >
              </li>
              <li :style="[creditPack12 === true ? '' : 'display:none']">
                <a :href="creditPack12Link" class="btn-basic -wide"
                  >クレジット決済を退会（12ヶ月パックコース)</a
                >
              </li>
            </ul>
          </div>
          <div v-if="PaymentCarrer === true" class="layout-sub">
            <h3 class="heading-bar">キャリア決済</h3>
            <p>キャリア決済の退会画面に遷移します。</p>
            <ul class="list-btn">
              <li :style="[careerPremium === true ? '' : 'display:none']">
                <a :href="careerPremiumLink" class="btn-basic -wide"
                  >キャリア決済を退会（プレミアムコース)</a
                >
              </li>
              <li :style="[careerPlus === true ? '' : 'display:none']">
                <a :href="careerPlusLink" class="btn-basic -wide"
                  >キャリア決済を退会（プレミアム機能追加コース)</a
                >
              </li>
              <li :style="[careerEconomy === true ? '' : 'display:none']">
                <a :href="careerEconomyLink" class="btn-basic -wide"
                  >キャリア決済を退会（エコノミーコース)</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="inner">
          <h2 class="heading-circle">メール会員退会</h2>
          <ul class="list-option">
            <li>
              メール会員退会は、ご契約されているすべての有料コースを退会後、サポートメニューの会員解約より実施してください。
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>
