<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getUrlVars,
  getJSON,
  replaceComma,
  getCookie,
  getCourse,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  SUCCESS,
  USERID,
  PAYINGNONE,
  retrieveCourse,
  isNar,
  getUserAgent,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  URLPATH,
  METHOD_NORMAL,
  METHOD_BOX,
  METHOD_FORMATION,
  METHOD_WHEEL,
  METHOD_WHEEL_1,
  METHOD_WHEEL_2,
  METHOD_WHEEL_3,
  METHOD_WHEEL_1_2,
  METHOD_WHEEL_1_3,
  METHOD_WHEEL_2_3,
  JOINPATH
} from "../../assets/js/define";

let params = "";
const isWebView = ref(false);
const isSugotoku = ref(false);
onMounted(() => {
  isWebView.value = getUserAgent();
  callRetrieveCourse(function (courseId) {
    isSugotoku.value = courseId === PAYINGSUGOTOKU_NONE || courseId === PAYINGSUGOTOKU
    if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
      location.href = HOST + JOINPATH;
    } else {
      params = getUrlVars();
      setCanvas();
    }
  });
});

const callRetrieveCourse = function (callback) {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
}

//canvasの表示設定
// 日付
let fullDate = ref("");
let raceName = ref("");
let myBet = ref("");
let sameFrame = ref("");
let myHitRefund = ref("");
let myReturn = ref("");
let ticket = reactive({});
const setCanvas = () => {
  document.body.className = "";

  const req =
    HOST +
    PATH +
    "TicketCollection/bettingTicketImageGetAPI.js" +
    BACKWORDPATH +
    "?id=" +
    params.id +
    "&narFlag=" +
    isNar();
  getJSON(req, true, (status, json) => {
    if (status == SUCCESS) {
      if (json == null || json.length == 0) {
        return;
      }
      ticket = Object.assign(ticket, json[0]);
      let BAKEN_LBL = {};

      BAKEN_LBL[METHOD_NORMAL] = {
        //通常
        単勝: { mode: "tansyou", branch: "box", lbl: "tansyou" },
        複勝: { mode: "fukusyou", branch: "box", lbl: "fukusyou" },
        枠連: { mode: "wakuren", branch: "wakuren", lbl: "wakuren" },
        枠単: { mode: "wakuren", branch: "wakuren", lbl: "wakutan" },
        馬連: { mode: "wakuren", branch: "wakuren", lbl: "umaren" },
        ワイド: { mode: "wakuren", branch: "wakuren", lbl: "wide" },
        馬単: { mode: "wakuren", branch: "wakuren", lbl: "umatan" },
        "３連単": { mode: "sanrenfuku", branch: "wakuren", lbl: "sanrentan" },
        "３連複": { mode: "sanrenfuku", branch: "wakuren", lbl: "sanrennhuku" },
      };
      BAKEN_LBL[METHOD_BOX] = {
        //ボックス
        枠連: { mode: "wakuren", branch: "box", lbl: "wakuren" },
        枠単: { mode: "wakutan", branch: "box", lbl: "wakutan" },
        馬連: { mode: "umaren", branch: "box", lbl: "umaren" },
        ワイド: { mode: "wide", branch: "box", lbl: "wide" },
        馬単: { mode: "umatan", branch: "box", lbl: "umatan" },
        "３連単": { mode: "sanrentan", branch: "box", lbl: "sanrentan" },
        "３連複": { mode: "sanrenfuku", branch: "box", lbl: "sanrennhuku" },
      };
      BAKEN_LBL[METHOD_FORMATION] = {
        //フォーメーション
        枠単: { mode: "wakutan", branch: "formation", lbl: "wakutan" },
        馬連: { mode: "umaren", branch: "formation", lbl: "umaren" },
        ワイド: { mode: "wide", branch: "formation", lbl: "wide" },
        馬単: { mode: "umatan", branch: "formation", lbl: "umatan" },
        "３連単": { mode: "sanrentan", branch: "formation", lbl: "sanrentan" },
        "３連複": {
          mode: "sanrenfuku",
          branch: "formation",
          lbl: "sanrennhuku",
        },
      };
      BAKEN_LBL[METHOD_WHEEL] = {
        //ながし（含：馬単の1着ながし）
        枠連: { mode: "wakuren", branch: "nagasi", lbl: "wakuren" },
        馬連: { mode: "umaren", branch: "nagasi", lbl: "umaren" },
        ワイド: { mode: "wide", branch: "nagasi", lbl: "wide" },
      };
      BAKEN_LBL[METHOD_WHEEL_1] = {
        //軸1頭ながし（3連単の場合は1着指定）
        枠単: { mode: "wakutan", branch: "nagasi", lbl: "wakutan" },
        枠単マルチ: { mode: "wakutanmulti", branch: "nagasi", lbl: "wakutan" },
        馬単: { mode: "umatan", branch: "nagasi", lbl: "umatan" },
        馬単マルチ: { mode: "umatanmulti", branch: "nagasi", lbl: "umatan" },
        "３連単": {
          mode: "sanrentan",
          branch: "jikuittounagasi",
          lbl: "sanrentan",
        },
        "３連単マルチ": {
          mode: "sanrentanmulti",
          branch: "jikuittounagasi",
          lbl: "sanrentan",
        },
        "３連複": {
          mode: "sanrenfuku",
          branch: "jikuittounagasi",
          lbl: "sanrennhuku",
        },
      };
      BAKEN_LBL[METHOD_WHEEL_2] = {
        //馬単の2着ながし、3連複の軸2頭ながし、3連単の軸1頭ながしで2着指定
        枠単: { mode: "wakutan", branch: "nagasi", lbl: "wakutan" },
        枠単マルチ: { mode: "wakutanmulti", branch: "nagasi", lbl: "wakutan" },
        馬単: { mode: "umatan", branch: "nityakunagasi", lbl: "umatan" },
        馬単マルチ: { mode: "umatanmulti", branch: "nagasi", lbl: "umatan" },
        "３連単": {
          mode: "sanrentan",
          branch: "jikuittounagasi",
          lbl: "sanrentan",
        },
        "３連単マルチ": {
          mode: "sanrentanmulti",
          branch: "jikuittounagasi",
          lbl: "sanrentan",
        },
        "３連複": {
          mode: "sanrenfuku",
          branch: "jikunitounagasi",
          lbl: "sanrennhuku",
        },
      };
      BAKEN_LBL[METHOD_WHEEL_3] = {
        //3連単の軸1頭ながし3着指定
        "３連単": {
          mode: "sanrentan",
          branch: "jikuittounagasi",
          lbl: "sanrentan",
        },
        "３連単マルチ": {
          mode: "sanrentanmulti",
          branch: "jikuittounagasi",
          lbl: "sanrentan",
        },
      };
      BAKEN_LBL[METHOD_WHEEL_1_2] = {
        //3連単の軸2頭ながし1,2着指定
        "３連単": {
          mode: "sanrentan",
          branch: "jikunitounagasi",
          lbl: "sanrentan",
        },
        "３連単マルチ": {
          mode: "sanrentanmulti",
          branch: "jikunitounagasi",
          lbl: "sanrentan",
        },
      };
      BAKEN_LBL[METHOD_WHEEL_1_3] = {
        //3連単の軸2頭ながし1,3着指定
        "３連単": {
          mode: "sanrentan",
          branch: "jikunitounagasi",
          lbl: "sanrentan",
        },
        "３連単マルチ": {
          mode: "sanrentanmulti",
          branch: "jikunitounagasi",
          lbl: "sanrentan",
        },
      };
      BAKEN_LBL[METHOD_WHEEL_2_3] = {
        //3連単の軸2頭ながし2,3着指定
        "３連単": {
          mode: "sanrentan",
          branch: "jikunitounagasi",
          lbl: "sanrentan",
        },
        "３連単マルチ": {
          mode: "sanrentanmulti",
          branch: "jikunitounagasi",
          lbl: "sanrentan",
        },
      };
      if (
        !(ticket.Method in BAKEN_LBL) ||
        !(ticket.TOB in BAKEN_LBL[ticket.Method]) ||
        BAKEN_LBL[ticket.Method][ticket.TOB] == null
      ) {
        return;
      }
      let mode = BAKEN_LBL[ticket.Method][ticket.TOB].mode;
      let branch = BAKEN_LBL[ticket.Method][ticket.TOB].branch;
      let lbl = BAKEN_LBL[ticket.Method][ticket.TOB].lbl;

      //馬券変数を設定
      let dorCap =
        ticket.DOR.slice(4, 6) * 1 + "月" + ticket.DOR.slice(6, 8) * 1 + "日";
      let raceNumCap = ticket.RaceNum * 1; //競走番号
      let racetrackCap = ticket.Racetrack; //競馬場名
      let yearCap = ticket.DOR.slice(0, 4); //開催年次
      let meetingCap = ticket.RaceMeeting * 1; //開催回次
      let dayCap = ticket.RaceDay * 1; //開催日次
      let raceNameCap = ticket.RaceName; //競走名
      let displayName = ticket.DisplayName //馬券コレクション表示競走名
      let refund = ticket.Refund * 1; //払戻金：的中判定に使用

      let templ = "★★★★★★★";
      let totalCap =
        templ.slice(0, 7 - String(ticket.Spend).length) +
        replaceComma(ticket.Spend); //合計金額
      templ = "☆☆☆☆☆☆";
      let counter = ticket.Count * 1; //組合数
      let unitPrice = (ticket.Spend * 1) / counter; //各組単価
      let unitPriceCap =
        templ.slice(0, 6 - String(unitPrice).length) +
        replaceComma(String(unitPrice));
      let horseNameCap = ticket.HorseName; //競走馬名
      let lst1 = ticket.BetInfo[0].split("_");
      //ボックスで表示される総頭数
      let lst1Length = lst1.length;
      //購入していない馬番を0でつめる
      let diff = 18 - lst1.length;
      for (let i = 0; i < diff; i++) {
        lst1.push(0);
      }
      let lst2 = null;
      let lst3 = null;
      let lst2Length = 0;
      let lst3Length = 0;
      if (ticket.BetInfo.length > 1) {
        lst2 = ticket.BetInfo[1].split("_");
        //フォーメーションやながしで表示される総頭数
        lst2Length = lst2.length;
        //購入していない馬番を0でつめる
        let diff = 18 - lst2.length;
        for (let i = 0; i < diff; i++) {
          lst2.push(0);
        }

        if (ticket.BetInfo.length > 2) {
          lst3 = ticket.BetInfo[2].split("_");
          let diff = 18 - lst3.length;
          //フォーメーションやながしで表示される総頭数
          lst3Length = lst3.length;
          //購入していない馬番を0でつめる
          for (let i = 0; i < diff; i++) {
            lst3.push(0);
          }
        }
      }
      //見出し日付の表示
      fullDate.value = date2StringTypeYmdd(ticket.DOR);

      //脚注情報の表示
      raceName.value = raceNameCap;
      myBet.value = ticket.TOB;
      if (/^wakuren/.test(mode) && /^box/.test(branch) && lst2Length > 1) {
        sameFrame.value = ticket.BetInfo[1].split("_").join(" ");
      }

      let myHit = ticket.HitBet.split(",");
      let myRefund = ticket.HitRefund.split(",");
      for (let i = 0; i < myRefund.length; i++) {
        if (myRefund[i] * 1 == 0) {
          continue;
        }
        if (myHit.length < i + 1) {
          continue;
        }
        if (i > 0) {
          myHitRefund.value += "\n";
        }

        let hit = myHit[i];
        if (/^(wakutan|umatan|sanrentan)/.test(mode)) {
          hit = hit.replace(/(-|ー)/g, "→");
        }

        myHitRefund.value +=
          hit + " " + replaceComma(String((myRefund[i] * 1) / 100)) + "倍 ";
      }

      if (ticket.Refund * 1 > 0) {
        myReturn.value = replaceComma(ticket.Refund) + "円";
      }
      //キャンバスに描画
      let canvasNode = document.getElementById("xCanvas");

      let pw = canvasNode.parentNode.clientWidth;
      let ph = canvasNode.parentNode.clientHeight;

      canvasNode.width = pw * 1;
      canvasNode.height = pw * 1 * 0.623;
      canvasNode.style.top = (ph - canvasNode.height) / 2 + "px";
      canvasNode.style.left = (pw - canvasNode.width) / 2 + "px";

      let i = 0; //setTimeout関数で使用するパラメーターの初期化
      //馬券
      let imgObj1 = new Image();
      // imgObj1.crossOrigin = "Anonymous";
      imgObj1.src = HOST + "../../assets/images/collection/baken_back.png";
      imgObj1.onload = function () {
        i++;
      };
      //レースナンバー
      let imgObj2 = new Image();
      //      imgObj2.crossOrigin = "Anonymous";
      imgObj2.src =
        HOST + "../../assets/images/collection/baken_" + raceNumCap + "R.png";
      imgObj2.onload = function () {
        i++;
      };
      //馬券の種類
      let imgObj3 = new Image();
      //      imgObj3.crossOrigin = "Anonymous";
      imgObj3.src =
        HOST + "../../assets/images/collection/baken_" + lbl + ".png";
      imgObj3.onload = function () {
        i++;
      };
      //馬券の種類2
      let imgObj4 = new Image();
      let bakenCap = branch;
      if (/^(wakutan|umatan)$/.test(mode) && /^nagasi/.test(branch)) {
        bakenCap = "ittyakunagasi";
      }
      //      imgObj4.crossOrigin = "Anonymous";
      imgObj4.src =
        HOST + "../../assets/images/collection/baken_" + bakenCap + ".png";
      imgObj4.onload = function () {
        i++;
      };
      //馬券の種類3（マルチ）
      let imgObj5 = new Image();
      //      imgObj5.crossOrigin = "Anonymous";
      imgObj5.src = HOST + "../../assets/images/collection/baken_maruchi.png";
      imgObj5.onload = function () {
        i++;
      };
      //的中
      let imgObj6 = new Image();
      //      imgObj6.crossOrigin = "Anonymous";
      imgObj6.src = HOST + "../../assets/images/collection/baken_tekityu.png";
      imgObj6.onload = function () {
        i++;
      };
      //□番号/☆_白
      let imgno_w = new Array();
      for (let j = 0; j < 19; j++) {
        imgno_w[j] = new Image();
        //        imgno_w[j].crossOrigin = "Anonymous";
        if (j == 0) {
          imgno_w[0].src =
            HOST + "../../assets/images/collection/umaban_star_w.png";
        } else {
          imgno_w[j].src =
            HOST + "../../assets/images/collection/umaban_" + j + "_w.png";
        }
        imgno_w[j].onload = function () {
          i++;
        };
      }
      //■番号/★_黒
      let imgno_b = new Array();
      for (let j = 0; j < 19; j++) {
        imgno_b[j] = new Image();
        //        imgno_b[j].crossOrigin = "Anonymous";
        if (j == 0) {
          imgno_b[0].src =
            HOST + "../../assets/images/collection/umaban_star_b.png";
        } else {
          imgno_b[j].src =
            HOST + "../../assets/images/collection/umaban_" + j + "_b.png";
        }
        imgno_b[j].onload = function () {
          i++;
        };
      }

      let cnt = 0;

      (function draw() {
        if (i == 44) {
          //canvasの描画
          //let canvas = document.getElementById('canvas');
          let ctx = canvasNode && canvasNode.getContext("2d");
          //画像
          ctx.drawImage(imgObj1, 0, 0, canvasNode.width, canvasNode.height);
          //左（レース）
          ctx.drawImage(
            imgObj2,
            22 * (canvasNode.width / 600),
            117 * (canvasNode.height / 374),
            canvasNode.width * 0.285,
            canvasNode.height * 0.104
          );
          //中央（馬券の種類）
          ctx.drawImage(
            imgObj3,
            207 * (canvasNode.width / 600),
            17 * (canvasNode.height / 374),
            canvasNode.width * 0.128,
            canvasNode.height * 0.773
          );
          //右上
          if (
            /^(box|formation|nagasi|nityakunagasi|jikuittounagasi|jikunitounagasi)$/.test(
              branch
            ) &&
            !/^(tansyou|fukusyou)$/.test(mode)
          ) {
            //ボックス、フォーメーション、2着ながし、軸1頭ながし、軸2頭ながしの場合
            ctx.drawImage(
              imgObj4,
              300 * (canvasNode.width / 600),
              17 * (canvasNode.height / 374),
              canvasNode.width * 0.47,
              canvasNode.height * 0.136
            );
          }
          //右下（マルチ）
          if (/^(wakutanmulti|umatanmulti|sanrentanmulti)$/.test(mode)) {
            ctx.drawImage(
              imgObj5,
              300 * (canvasNode.width / 600),
              262 * (canvasNode.height / 374),
              canvasNode.width * 0.137,
              canvasNode.height * 0.115
            );
          }
          //テキスト
          ctx.textBaseline = "top";
          ctx.textAlign = "left";
          ctx.font = 28 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
          ctx.fillText(
            yearCap + "年" + meetingCap + "回" + dayCap + "日",
            22 * (canvasNode.width / 600),
            20 * (canvasNode.height / 374),
            canvasNode.width * 0.27
          );
          ctx.font = 40 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
          ctx.fillText(
            racetrackCap,
            22 * (canvasNode.width / 600),
            65 * (canvasNode.height / 374),
            canvasNode.width * 0.27
          );
          ctx.font = 28 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
          //ctx.font = 28*(canvasNode.width/600) + "px 'ヒラギノ明朝 ProN' , 'Hiragino Mincho ProN' , 'ＭＳ 明朝' , 'MS Mincho' , HiraMinProN-W3 , 'TakaoEx明朝' , TakaoExMincho , 'MotoyaLCedar' , 'Droid Sans Japanese' , serif";
          //ctx.fillText('第63回 (G1)', 22*(canvasNode.width/600), 244*(canvasNode.height/374), canvasNode.width*0.27);
          ctx.fillText(
            displayName,
            22 * (canvasNode.width / 600),
            275 * (canvasNode.height / 374),
            canvasNode.width * 0.27
          );
          ctx.font = 24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
          ctx.fillText(
            "WIN!競馬",
            22 * (canvasNode.width / 600),
            311 * (canvasNode.height / 374),
            canvasNode.width * 0.27
          );
          ctx.fillText(
            dorCap,
            22 * (canvasNode.width / 600),
            336 * (canvasNode.height / 374),
            canvasNode.width * 0.27
          );
          let mArrow = "";
          if (/^(tansyou|fukusyou)$/.test(mode)) {
            //単勝、複勝
            ctx.drawImage(
              imgno_w[lst1[0]],
              300 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.068,
              canvasNode.height * 0.1
            );
            drawBorderCanvas(
              ctx,
              imgno_w[lst1[0]],
              300 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.068,
              canvasNode.height * 0.1
            );
            //馬名ここから
            ctx.font =
              44 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            let metrics = ctx.measureText(horseNameCap);
            let scale = 1;
            if (metrics.width > canvasNode.width * 0.41) {
              scale = (canvasNode.width * 0.41) / metrics.width;
              ctx.save();
              ctx.scale(scale, 1);
            }
            ctx.fillText(
              horseNameCap,
              (340 * (canvasNode.width / 600)) / scale,
              140 * (canvasNode.height / 374),
              canvasNode.width * 0.41
            );
            ctx.restore();
            //馬名ここまで
            ctx.font =
              24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textAlign = "right";
            ctx.fillText(
              unitPriceCap + "円",
              587 * (canvasNode.width / 600),
              190 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
          } else if (/^wakuren/.test(mode) && /^wakuren/.test(branch)) {
            //枠連
            let wakuBox = imgno_w;
            if (/^(枠単|枠連)$/.test(ticket.TOB)) {
              wakuBox = imgno_b;
            }

            ctx.drawImage(
              wakuBox[lst1[0]],
              300 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.068,
              canvasNode.height * 0.1
            );
            drawBorderCanvas(
              ctx,
              wakuBox[lst1[0]],
              300 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.068,
              canvasNode.height * 0.1
            );
            ctx.font =
              30 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(
              "-",
              352 * (canvasNode.width / 600),
              165 * (canvasNode.height / 374),
              canvasNode.width * 0.03
            );
            ctx.drawImage(
              wakuBox[lst2[0]],
              365 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.068,
              canvasNode.height * 0.1
            );
            drawBorderCanvas(
              ctx,
              wakuBox[lst2[0]],
              365 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.068,
              canvasNode.height * 0.1
            );
            ctx.font =
              24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textAlign = "right";
            ctx.textBaseline = "top";
            ctx.fillText(
              unitPriceCap + "円",
              587 * (canvasNode.width / 600),
              149 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
          } else if (
            /^(sanrenfuku|sanrentan)$/.test(mode) &&
            /^wakuren/.test(branch)
          ) {
            //3連複 通常
            ctx.drawImage(
              imgno_w[lst1[0]],
              290 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.05,
              canvasNode.height * 0.1
            );
            drawBorderCanvas(
              ctx,
              imgno_w[lst1[0]],
              290 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.05,
              canvasNode.height * 0.1
            );
            ctx.font =
              30 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(
              "-",
              330 * (canvasNode.width / 600),
              165 * (canvasNode.height / 374),
              canvasNode.width * 0.03
            );
            ctx.drawImage(
              imgno_w[lst2[0]],
              340 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.05,
              canvasNode.height * 0.1
            );
            drawBorderCanvas(
              ctx,
              imgno_w[lst2[0]],
              340 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.05,
              canvasNode.height * 0.1
            );
            ctx.fillText(
              "-",
              380 * (canvasNode.width / 600),
              165 * (canvasNode.height / 374),
              canvasNode.width * 0.03
            );
            ctx.drawImage(
              imgno_w[lst3[0]],
              390 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.05,
              canvasNode.height * 0.1
            );
            drawBorderCanvas(
              ctx,
              imgno_w[lst3[0]],
              390 * (canvasNode.width / 600),
              145 * (canvasNode.height / 374),
              canvasNode.width * 0.05,
              canvasNode.height * 0.1
            );
            ctx.font =
              24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textAlign = "right";
            ctx.textBaseline = "top";
            ctx.fillText(
              unitPriceCap + "円",
              587 * (canvasNode.width / 600),
              149 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
          } else if (
            !/^(tansyou|fukusyou)$/.test(mode) &&
            /^box/.test(branch)
          ) {
            //ボックス
            let wakuBox = imgno_w;
            let wakuCounter = 10;
            if (/^wakuren/.test(mode)) {
              wakuBox = imgno_b;
              wakuCounter = 8;
            }

            //ボックス群
            if (lst1Length > 10) {
              //買い目数>10の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.1;

              for (let k = 0; k < 18; k++) {
                const xParam = [300, 358, 416, 474, 532];
                const xPos = k % xParam.length;
                const yDiff = 45;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (lst1Length != 0) {
              //1<買い目数<=10の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.2;

              for (let k = 0; k < wakuCounter; k++) {
                const xParam = [300, 358, 416, 474, 532];
                const xPos = k % xParam.length;
                const yDiff = 83;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  wakuBox[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  wakuBox[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //合計欄
            ctx.font =
              24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textAlign = "right";
            ctx.fillText(
              "組合せ数" + counter,
              587 * (canvasNode.width / 600),
              271 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
            ctx.fillText(
              "各組 " + unitPriceCap + "円",
              587 * (canvasNode.width / 600),
              302 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
          } else if (
            /^(umaren|wide|umatan|wakutan)$/.test(mode) &&
            /^formation/.test(branch)
          ) {
            //馬連、ワイド、馬単用フォーメーション
            if (/^(wakutan|umatan)$/.test(mode)) {
              mArrow = "►";
            } else {
              mArrow = "-";
            }

            //第1ボックス群
            if (lst1Length == 1) {
              //買い目数=1の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.2;

              ctx.drawImage(
                imgno_w[lst1[0] * 1],
                345 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                imgno_w[lst1[0] * 1],
                345 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
            } else if (lst1Length > 10) {
              //買い目数>10の場合
              const mWidth = canvasNode.width * 0.06;
              const mHeight = canvasNode.height * 0.07;

              for (let k = 0; k < 18; k++) {
                const xParam = [300, 340, 380];
                const xPos = k % xParam.length;
                const yDiff = 31;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (lst1Length != 0) {
              //1<買い目数<=10の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.09;

              for (let k = 0; k < 10; k++) {
                const xParam = [300, 358];
                const xPos = k % xParam.length;
                const yDiff = 37;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //矢印
            ctx.font =
              44 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.fillText(
              mArrow,
              433 * (canvasNode.width / 600),
              140 * (canvasNode.height / 374),
              canvasNode.width * 0.03
            );

            //第2ボックス群
            if (lst2Length == 1) {
              //買い目数=1の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.2;

              ctx.drawImage(
                imgno_w[lst2[0] * 1],
                487 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                imgno_w[lst2[0] * 1],
                487 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
            } else if (lst2Length > 10) {
              //買い目数>10の場合
              const mWidth = canvasNode.width * 0.06;
              const mHeight = canvasNode.height * 0.07;

              for (let k = 0; k < 18; k++) {
                const xParam = [465, 505, 545];
                const xPos = k % xParam.length;
                const yDiff = 31;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (lst2Length != 0) {
              //1<買い目数<=10の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.09;

              for (let k = 0; k < 10; k++) {
                const xParam = [474, 532];
                const xPos = k % xParam.length;
                const yDiff = 37;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //合計欄
            ctx.font =
              24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textAlign = "right";
            ctx.fillText(
              "組合せ数" + counter,
              587 * (canvasNode.width / 600),
              271 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
            ctx.fillText(
              "各組 " + unitPriceCap + "円",
              587 * (canvasNode.width / 600),
              302 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
          } else if (
            /^(sanrentan|sanrenfuku)$/.test(mode) &&
            /^formation/.test(branch)
          ) {
            //3連単、3連複用フォーメーション
            if (/^sanrentan$/.test(mode)) {
              mArrow = "►";
            } else {
              mArrow = "-";
            }

            //第1ボックス群
            if (lst1Length == 1) {
              //買い目数=1の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.2;

              ctx.drawImage(
                imgno_w[lst1[0] * 1],
                310 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                imgno_w[lst1[0] * 1],
                310 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
            } else if (lst1Length > 10) {
              //買い目数>10の場合
              const mWidth = canvasNode.width * 0.04;
              const mHeight = canvasNode.height * 0.07;

              for (let k = 0; k < 18; k++) {
                const xParam = [300, 326, 352];
                const xPos = k % xParam.length;
                const yDiff = 31;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (lst1Length != 0) {
              //1<買い目数<=10の場合
              let mWidth = canvasNode.width * 0.06;
              let mHeight = canvasNode.height * 0.09;

              for (let k = 0; k < 10; k++) {
                const xParam = [300, 340];
                const xPos = k % xParam.length;
                const yDiff = 37;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst1[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //第1矢印
            ctx.font =
              44 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.fillText(
              mArrow,
              382 * (canvasNode.width / 600),
              140 * (canvasNode.height / 374),
              canvasNode.width * 0.03
            );

            //第2ボックス群
            if (lst2Length == 1) {
              //買い目数=1の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.2;

              ctx.drawImage(
                imgno_w[lst2[0] * 1],
                416 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                imgno_w[lst2[0] * 1],
                416 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
            } else if (lst2Length > 10) {
              //買い目数>10の場合
              const mWidth = canvasNode.width * 0.04;
              const mHeight = canvasNode.height * 0.07;

              for (let k = 0; k < 18; k++) {
                const xParam = [403, 429, 455];
                const xPos = k % xParam.length;
                const yDiff = 31;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (lst2Length != 0) {
              //1<買い目数<=10の場合
              const mWidth = canvasNode.width * 0.06;
              const mHeight = canvasNode.height * 0.09;

              for (let k = 0; k < 10; k++) {
                const xParam = [403, 443];
                const xPos = k % xParam.length;
                const yDiff = 37;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //第2矢印
            ctx.fillText(
              mArrow,
              485 * (canvasNode.width / 600),
              140 * (canvasNode.height / 374),
              canvasNode.width * 0.03
            );

            //第3ボックス群
            if (lst3Length == 1) {
              //買い目数=1の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.2;

              ctx.drawImage(
                imgno_w[lst3[0] * 1],
                522 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                imgno_w[lst3[0] * 1],
                522 * (canvasNode.width / 600),
                128 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
            } else if (lst3Length > 10) {
              //買い目数>10の場合
              const mWidth = canvasNode.width * 0.04;
              const mHeight = canvasNode.height * 0.07;

              for (let k = 0; k < 18; k++) {
                const xParam = [506, 532, 558];
                const xPos = k % xParam.length;
                const yDiff = 31;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst3[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst3[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (lst3Length != 0) {
              //1<買い目数<=10の場合
              const mWidth = canvasNode.width * 0.06;
              const mHeight = canvasNode.height * 0.09;

              for (let k = 0; k < 10; k++) {
                const xParam = [506, 546];
                const xPos = k % xParam.length;
                const yDiff = 37;
                const yBase = 75;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst3[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst3[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //合計欄
            ctx.font =
              24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textAlign = "right";
            ctx.fillText(
              "組合せ数" + counter,
              587 * (canvasNode.width / 600),
              271 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
            ctx.fillText(
              "各組 " + unitPriceCap + "円",
              587 * (canvasNode.width / 600),
              302 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
          } else if (
            (/^(wakuren|umaren|wide|umatan|umatanmulti|wakutan|wakutanmulti)$/.test(mode) &&
              /^nagasi/.test(branch)) ||
            (/^sanrenfuku/.test(mode) &&
              /^(jikuittounagasi|jikunitounagasi)/.test(branch))
          ) {
            //枠連、馬連、ワイド、馬単用ながし or 三連複用軸1頭ながし軸2頭ながし
            let wakuBox = imgno_w;
            let wakuCounter = 10;
            if (/^wakuren/.test(mode)) {
              wakuBox = imgno_b;
              wakuCounter = 8;
            }

            if (/^(umatan|wakutan)$/.test(mode)) {
              mArrow = "►";
            } else {
              mArrow = "-";
            }

            //軸見出し
            ctx.font =
              16 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.fillText(
              "(軸)",
              316 * (canvasNode.width / 600),
              75 * (canvasNode.height / 374),
              canvasNode.width * 0.13
            );
            ctx.fillText(
              "(相手)",
              462 * (canvasNode.width / 600),
              75 * (canvasNode.height / 374),
              canvasNode.width * 0.13
            );

            //第1ボックス群
            const mWidth = canvasNode.width * 0.1;
            const mHeight = canvasNode.height * 0.13;

            for (let k = 0; k < 2; k++) {
              let xParam = [300];
              let xPos = k % xParam.length;
              let yDiff = 55;
              let yBase = 95;
              let yPos =
                ((yDiff * Math.floor(k / xParam.length) + yBase) *
                  canvasNode.height) /
                374;

              ctx.drawImage(
                wakuBox[lst1[k] * 1],
                xParam[xPos] * (canvasNode.width / 600),
                yPos,
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                wakuBox[lst1[k] * 1],
                xParam[xPos] * (canvasNode.width / 600),
                yPos,
                mWidth,
                mHeight
              );
              if (!/^jikunitounagasi/.test(branch)) {
                break; //3連複の軸2頭ながし以外はループせず終了
              }
            }

            //矢印
            ctx.font =
              44 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textBaseline = "middle";
            if (lst2Length == 1) {
              //買い目数=1の場合
              ctx.fillText(
                mArrow,
                400 * (canvasNode.width / 600),
                118 * (canvasNode.height / 374),
                canvasNode.width * 0.03
              );
            } else if (lst2Length != 0) {
              //買い目数>1の場合
              ctx.fillText(
                mArrow,
                365 * (canvasNode.width / 600),
                118 * (canvasNode.height / 374),
                canvasNode.width * 0.03
              );
            }

            //第2ボックス群
            if (lst2Length == 1) {
              //買い目数=1の場合
              const mWidth = canvasNode.width * 0.1;
              const mHeight = canvasNode.height * 0.13;

              ctx.drawImage(
                wakuBox[lst2[0] * 1],
                454 * (canvasNode.width / 600),
                95 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                wakuBox[lst2[0] * 1],
                454 * (canvasNode.width / 600),
                95 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
            } else if (lst2Length > 9) {
              //買い目数>9の場合
              const mWidth = canvasNode.width * 0.062;
              const mHeight = canvasNode.height * 0.1;

              for (let k = 0; k < 17; k++) {
                let xParam = [386, 426, 466, 506, 546];
                let xPos = k % xParam.length;
                let yDiff = 40;
                let yBase = 95;
                let yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (lst2Length != 0) {
              //1<買い目数<=9の場合
              const mWidth = canvasNode.width * 0.1;
              const mHeight = canvasNode.height * 0.13;

              for (let k = 0; k < 9; k++) {
                const xParam = [386, 454, 522];
                const xPos = k % xParam.length;
                const yDiff = 55;
                const yBase = 95;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //合計欄
            ctx.font =
              24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textBaseline = "top";
            ctx.textAlign = "right";
            ctx.fillText(
              "組合せ数" + counter,
              587 * (canvasNode.width / 600),
              271 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
            ctx.fillText(
              "各組 " + unitPriceCap + "円",
              587 * (canvasNode.width / 600),
              302 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
          } else if (
            /^(sanrentan|sanrentanmulti)$/.test(mode) &&
            /^(jikuittounagasi|jikunitounagasi)/.test(branch)
          ) {
            //3連単用ながし
            mArrow = "►";

            //軸見出し
            ctx.font =
              16 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            if (
              ticket.Method == METHOD_WHEEL_1 ||
              ticket.Method == METHOD_WHEEL_1_2 ||
              ticket.Method == METHOD_WHEEL_1_3
            ) {
              ctx.fillText(
                "(軸)",
                324 * (canvasNode.width / 600),
                75 * (canvasNode.height / 374),
                canvasNode.width * 0.13
              );
            }
            if (
              ticket.Method == METHOD_WHEEL_2 ||
              ticket.Method == METHOD_WHEEL_1_2 ||
              ticket.Method == METHOD_WHEEL_2_3
            ) {
              ctx.fillText(
                "(軸)",
                427 * (canvasNode.width / 600),
                75 * (canvasNode.height / 374),
                canvasNode.width * 0.13
              );
            }
            if (
              ticket.Method == METHOD_WHEEL_3 ||
              ticket.Method == METHOD_WHEEL_1_3 ||
              ticket.Method == METHOD_WHEEL_2_3
            ) {
              ctx.fillText(
                "(軸)",
                530 * (canvasNode.width / 600),
                75 * (canvasNode.height / 374),
                canvasNode.width * 0.13
              );
            }

            //第1ボックス群
            let mLength = lst1Length;
            let mLst = lst1;
            if (
              ticket.Method == METHOD_WHEEL_2 ||
              ticket.Method == METHOD_WHEEL_3
            ) {
              mLength = lst2Length;
              mLst = lst2;
            }
            if (mLength == 1) {
              //買い目数=1の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.18;

              ctx.drawImage(
                imgno_w[mLst[0] * 1],
                310 * (canvasNode.width / 600),
                144 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                imgno_w[mLst[0] * 1],
                310 * (canvasNode.width / 600),
                144 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
            } else if (mLength > 10) {
              //買い目数>10の場合
              const mWidth = canvasNode.width * 0.04;
              const mHeight = canvasNode.height * 0.065;

              for (let k = 0; k < 17; k++) {
                const xParam = [300, 326, 352];
                const xPos = k % xParam.length;
                const yDiff = 28;
                const yBase = 95;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (mLength != 0) {
              //1<買い目数<=10の場合
              const mWidth = canvasNode.width * 0.06;
              const mHeight = canvasNode.height * 0.08;

              for (let k = 0; k < 10; k++) {
                const xParam = [300, 340];
                const xPos = k % xParam.length;
                const yDiff = 34;
                const yBase = 95;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //第1矢印
            ctx.font =
              44 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.fillText(
              mArrow,
              382 * (canvasNode.width / 600),
              156 * (canvasNode.height / 374),
              canvasNode.width * 0.03
            );

            //第2ボックス群
            mLength = lst2Length;
            mLst = lst2;
            if (ticket.Method == METHOD_WHEEL_2) {
              mLength = lst1Length;
              mLst = lst1;
            }
            if (mLength == 1) {
              //買い目数=1の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.18;

              ctx.drawImage(
                imgno_w[mLst[0] * 1],
                416 * (canvasNode.width / 600),
                144 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                imgno_w[mLst[0] * 1],
                416 * (canvasNode.width / 600),
                144 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
            } else if (mLength > 10) {
              //買い目数>10の場合
              const mWidth = canvasNode.width * 0.04;
              const mHeight = canvasNode.height * 0.065;

              for (let k = 0; k < 17; k++) {
                const xParam = [403, 429, 455];
                const xPos = k % xParam.length;
                const yDiff = 28;
                const yBase = 95;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (mLength != 0) {
              //1<買い目数<=10の場合
              const mWidth = canvasNode.width * 0.06;
              const mHeight = canvasNode.height * 0.08;

              for (let k = 0; k < 10; k++) {
                const xParam = [403, 443];
                const xPos = k % xParam.length;
                const yDiff = 34;
                const yBase = 95;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //第2矢印
            ctx.fillText(
              mArrow,
              485 * (canvasNode.width / 600),
              156 * (canvasNode.height / 374),
              canvasNode.width * 0.03
            );

            //第3ボックス群
            mLength = lst3Length;
            mLst = lst3;
            if (
              ticket.Method == METHOD_WHEEL_1 ||
              ticket.Method == METHOD_WHEEL_2
            ) {
              mLength = lst2Length;
              mLst = lst2;
            } else if (ticket.Method == METHOD_WHEEL_3) {
              mLength = lst1Length;
              mLst = lst1;
            }
            if (mLength == 1) {
              //買い目数=1の場合
              const mWidth = canvasNode.width * 0.082;
              const mHeight = canvasNode.height * 0.18;

              ctx.drawImage(
                imgno_w[mLst[0] * 1],
                522 * (canvasNode.width / 600),
                144 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
              drawBorderCanvas(
                ctx,
                imgno_w[mLst[0] * 1],
                522 * (canvasNode.width / 600),
                144 * (canvasNode.height / 374),
                mWidth,
                mHeight
              );
            } else if (mLength > 10) {
              //買い目数>10の場合
              const mWidth = canvasNode.width * 0.04;
              const mHeight = canvasNode.height * 0.065;

              for (let k = 0; k < 17; k++) {
                const xParam = [506, 532, 558];
                const xPos = k % xParam.length;
                const yDiff = 28;
                const yBase = 95;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (mLength != 0) {
              //1<買い目数<=10の場合
              const mWidth = canvasNode.width * 0.06;
              const mHeight = canvasNode.height * 0.08;

              for (let k = 0; k < 10; k++) {
                const xParam = [506, 546];
                const xPos = k % xParam.length;
                const yDiff = 34;
                const yBase = 95;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[mLst[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //合計欄
            ctx.font =
              24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textAlign = "right";
            ctx.fillText(
              "組合せ数" + counter,
              587 * (canvasNode.width / 600),
              271 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
            ctx.fillText(
              "各組 " + unitPriceCap + "円",
              587 * (canvasNode.width / 600),
              302 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
          } else if (/^umatan|wakutan/.test(mode) && /^nityakunagasi/.test(branch)) {
            //馬単 2着ながし
            mArrow = "►";

            //軸見出し
            ctx.font =
              16 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.fillText(
              "(相手)",
              376 * (canvasNode.width / 600),
              75 * (canvasNode.height / 374),
              canvasNode.width * 0.13
            );
            ctx.fillText(
              "(軸)",
              538 * (canvasNode.width / 600),
              75 * (canvasNode.height / 374),
              canvasNode.width * 0.13
            );

            //第1ボックス群
            if (lst2Length > 9) {
              //買い目数>9の場合
              const mWidth = canvasNode.width * 0.062;
              const mHeight = canvasNode.height * 0.1;

              for (let k = 0; k < 17; k++) {
                const xParam = [300, 340, 380, 420, 460];
                const xPos = k % xParam.length;
                const yDiff = 40;
                const yBase = 95;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            } else if (lst2Length != 0) {
              //1<=買い目数<=9の場合
              const mWidth = canvasNode.width * 0.1;
              const mHeight = canvasNode.height * 0.13;

              for (let k = 0; k < 9; k++) {
                const xParam = [300, 368, 436];
                const xPos = k % xParam.length;
                const yDiff = 55;
                const yBase = 95;
                const yPos =
                  ((yDiff * Math.floor(k / xParam.length) + yBase) *
                    canvasNode.height) /
                  374;

                ctx.drawImage(
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
                drawBorderCanvas(
                  ctx,
                  imgno_w[lst2[k] * 1],
                  xParam[xPos] * (canvasNode.width / 600),
                  yPos,
                  mWidth,
                  mHeight
                );
              }
            }

            //矢印
            ctx.font =
              44 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textBaseline = "middle";
            ctx.fillText(
              mArrow,
              501 * (canvasNode.width / 600),
              118 * (canvasNode.height / 374),
              canvasNode.width * 0.03
            );

            //第2ボックス群
            const mWidth = canvasNode.width * 0.1;
            const mHeight = canvasNode.height * 0.13;

            const xParam = [522];
            const yBase = 95;
            const yPos = (yBase * canvasNode.height) / 374;

            ctx.drawImage(
              imgno_w[lst1[0] * 1],
              xParam[0] * (canvasNode.width / 600),
              yPos,
              mWidth,
              mHeight
            );
            drawBorderCanvas(
              ctx,
              imgno_w[lst1[0] * 1],
              xParam[0] * (canvasNode.width / 600),
              yPos,
              mWidth,
              mHeight
            );

            //合計欄
            ctx.font =
              24 * (canvasNode.width / 600) + "px 'Verdana, sans-serif'";
            ctx.textBaseline = "top";
            ctx.textAlign = "right";
            ctx.fillText(
              "組合せ数" + counter,
              587 * (canvasNode.width / 600),
              271 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
            ctx.fillText(
              "各組 " + unitPriceCap + "円",
              587 * (canvasNode.width / 600),
              302 * (canvasNode.height / 374),
              canvasNode.width * 0.48
            );
          }

          ctx.fillText(
            "合計" + totalCap + "円",
            587 * (canvasNode.width / 600),
            333 * (canvasNode.height / 374),
            canvasNode.width * 0.48
          );
          //的中
          if (refund != 0) {
            ctx.drawImage(
              imgObj6,
              261 * (canvasNode.width / 600),
              110 * (canvasNode.height / 374),
              canvasNode.width * 0.31,
              canvasNode.height * 0.497
            );
          }
        } else {
          cnt++;
          if (cnt < 100) setTimeout(draw, 1000);
        }
        document.getElementById("canvasImg").src = canvasNode.toDataURL();
      })();
    }
  });

  document.body.className = "status-loaded";
};
const drawBorderCanvas = (
  ctx,
  element,
  xpos,
  ypos,
  cavasWidth,
  canvasHeight
) => {
  if (
    element.getAttribute("src").indexOf("umaban_") >= 0 &&
    element.getAttribute("src").indexOf("umaban_star") < 0
  ) {
    const boderColor = "#000";
    const borderThick = 1.2;
    ctx.strokeStyle = boderColor;
    ctx.lineWidth = borderThick;
    ctx.strokeRect(
      xpos + borderThick / 2,
      ypos + borderThick / 2,
      cavasWidth - borderThick,
      canvasHeight - borderThick
    );
  }
};

const date2StringTypeYmdd = (dateStr) => {
  let DAY_LBL = ["日", "月", "火", "水", "木", "金", "土"];
  let month = parseInt(dateStr.slice(4, 6), 10);
  let date = parseInt(dateStr.slice(6, 8), 10);
  let mDate = new Date(parseInt(dateStr.slice(0, 4), 10), month - 1, date);
  let day = DAY_LBL[mDate.getDay()];
  let str =
    dateStr.slice(0, 4) + "年" + month + "月" + date + "日(" + day + ")";

  return str;
};
const deleteMe = () => {
  let isDelete = window.confirm("削除してもよろしいですか?");
  if (isDelete) {
    document.body.className = "";

    const userid = getCookie(USERID);
    const req =
      HOST +
      PATH +
      "TicketCollection/bettingTicketCollectionDeleteAPI.js" +
      BACKWORDPATH +
      "?userId=" +
      encodeURIComponent(userid) +
      "&id=" +
      params.id +
      "&narFlag=" +
      isNar()
    // default馬券コレクションを取得～描画
    getJSON(req, true, (status, json) => {
      if (status == SUCCESS) {
        let url = HOST + "collection/";
        if (
          (getCourse() == PAYINGSUGOTOKU ||
          getCourse() == PAYINGSUGOTOKU_NONE) && !isWebView
        ) {
          url = location.pathname + "?url=" + HOST + URLPATH + "collection/";
        }
        window.location.href = url;
      }
    });

    document.body.className = "status-loaded";
  }
};
const saveCanvas = () => {
  let canvas = document.getElementById("xCanvas");
  let link = document.createElement("a");
  link.href = canvas.toDataURL("image/png");
  link.download = "";
  link.click();
};
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">馬券コレクション</h1>
    </section>

    <section class="layout-section">
      <div class="inner">
        <h2 class="heading-circle">{{ fullDate }}</h2>
        <div class="image-card">
          <img id="canvasImg" alt="" />
          <canvas id="xCanvas" style="display: none"> </canvas>
        </div>
        <a v-if="!isSugotoku && !isWebView" target="_blank" class="btn-basic -wide -window" @click="saveCanvas">
          画像をダウンロード
        </a>
        <table class="table-cell -carddetail -left">
          <tr>
            <th>レース名</th>
            <td>{{ raceName }}</td>
          </tr>
          <tr>
            <th>式別</th>
            <td>
              {{ myBet }}
              <br v-show="sameFrame" />
              <span v-show="sameFrame">※ 同枠選択 {{ sameFrame }}</span>
            </td>
          </tr>
          <tr>
            <th>的中オッズ</th>
            <td>{{ myHitRefund }}</td>
          </tr>
          <tr>
            <th>払戻金</th>
            <td v-if="myReturn">{{ myReturn }}</td>
            <td v-else>-</td>
          </tr>
        </table>
        <div class="nav-group">
          <span class="btn-basic -delete" @click="deleteMe">削除する</span>
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/collection/style.scss" scoped></style>
