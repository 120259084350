<template>
  <div class="container-tabtarget" data-target>
    <div class="item">
      <div v-if="bigPrizeFormatData.length">
        <div
            v-for="(prize, index) in bigPrizeFormatData"
            :key="index"
            class="inner"
        >
          <h2 class="heading-circle">{{ prize.year }}年</h2>
          <table class="table-grid -gradeWinner">
            <tbody>
            <tr v-for="(detail, idx) in prize.details" :key="idx">
              <td class="date">
                {{ detail.winMonth }}月{{ detail.winDay }}日(<span
                  :class="{
                    _sun: detail.week == '日',
                    _sat: detail.week == '土',
                  }"
              >{{ detail.week }}</span
              >)
              </td>
              <td class="race">
                {{ detail.stat.raceName }}
                <i v-if="detail.stat.icon"
                   class="icon-race"
                   :class="detail.stat.icon">
                </i>
              </td>
              <td class="horse">
                <a :href="detail.url">
                  {{ detail.stat.horseName }}
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="inner" v-else>重賞勝鞍はありません。</div>
    </div>
  </div>
</template>

<script>
import { HOST } from "../../../assets/js/define";

export default {
  data() {
    return {
      bigPrizeFormatData: [],
    };
  },
  props: {
    bigPrizeData: Object,
  },
  created() {
    let self = this;

    let winsYearData = ""; //self.bigPrizeData[0].year;
    let x = 0;
    for (let i in self.bigPrizeData) {
      let bigWinsData = self.bigPrizeData[i];
      let winsDor = bigWinsData.dor;
      const winsYear = winsDor.substr(0, 4);
      const winsMonth = winsDor.charAt(4) === '0' ? winsDor.charAt(5) : winsDor.substr(4, 2);
      const winsDay = winsDor.substr(6, 2);

      //曜日算出
      const myWeekTbl = new Array("日", "月", "火", "水", "木", "金", "土");
      const myDate = new Date(winsYear, winsMonth - 1, winsDay);
      const myWeek = myDate.getDay();
      const week = myWeekTbl[myWeek];
      let url = HOST + 'data/horse?PedigreeNum=' + self.bigPrizeData[i].pedigreeNum

      if (bigWinsData.year === winsYearData) {
        // 同じ年数のデータをまとめる
        self.bigPrizeFormatData[x - 1].details.push({
          stat: self.bigPrizeData[i],
          winYear: winsYear,
          winMonth: winsMonth,
          winDay: winsDay,
          week: week,
          url: url
        });
      } else {
        //年数が違う場合は、新しい年数を設定する
        self.bigPrizeFormatData.push({
          year: self.bigPrizeData[i].year,
          details: [
            {
              stat: self.bigPrizeData[i],
              winYear: winsYear,
              winMonth: winsMonth,
              winDay: winsDay,
              week: week,
              url: url
            },
          ],
        });
        winsYearData = self.bigPrizeData[i].year;
        x++;
      }
    }
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>