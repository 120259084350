<script setup>
import { ref, onMounted } from 'vue';
import {getJSON, getUrlVars, isNar} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH } from "../../assets/js/define";
import ButtonList from "../../components/leading/ButtonList.vue";
import RankingList from "../../components/leading/RankingList.vue";
import moment from "moment";
import { useStoreLeadingNewStud } from "../../stores/leading-states";
import { storeToRefs } from "pinia";

const updateDate = ref('');
const stallionList = ref([]);
const years = ref([]);
const params = ref({});
const narFlag = ref(false);
const thisYear = ref('');

const store = useStoreLeadingNewStud();
const {selectYear} = storeToRefs(store);
const {resetList} = store;

function loadData() {
  document.body.className = "";

  let request =
    HOST + PATH + "Leading/newStallionLeadingAPI.js" + BACKWORDPATH +
    "?RaceYear=" + selectYear.value +
    "&NarFlag=" + isNar() +
    "&IsLatest=" + (selectYear.value === Number(thisYear.value) ? '1' : '0')
  getJSON(request, true, function (status, json) {
    updateDate.value = json[0]?.LeadingUpdDate
    stallionList.value = json[0]?.Leading || []
  });

  document.body.className = "status-loaded";
}

function resetData() {
  resetList();
  loadData();
}

onMounted(() => {
  narFlag.value = isNar() === 1
  // 1ページに表示する要素数
  const startMonth = narFlag.value ? 4 : 6
  thisYear.value = moment().format('M') >= startMonth
    ? moment().format('YYYY')
    : moment().subtract(1, 'years').format('YYYY');
  params.value = getUrlVars();

  for (let i = 2022; i <= thisYear.value; i++) {
    years.value.unshift(i);
  }

  selectYear.value = selectYear.value || Number(thisYear.value);

  loadData();
})
</script>


<template>
  <main class="layout-main">
    <button-list current="new_stallion"></button-list>

    <section class="layout-section">
      <nav class="layout-title">
        <h2 class="heading-circle -padding">新種牡馬</h2>
        <div class="nav">
          <div class="form-select">
            <select v-model="selectYear" @change="resetData">
              <option v-for="(year, index) in years" :key="index" :value="year">
                <span v-if="narFlag">
                  {{ year }}年4月〜{{ year + 1 }}年3月
                </span>
                <span v-else>
                  {{ year }}年6月〜{{ year + 1 }}年5月
                </span>
              </option>
            </select>
          </div>
        </div>
      </nav>

      <ranking-list ref="rankingList"
                    :leading-list="stallionList"
                    :is-nar="narFlag"
                    :update-date="updateDate"
                    type="new_stallion">
      </ranking-list>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/leading/style.scss" scoped></style>
