import {ref} from 'vue'
import {defineStore} from 'pinia'

export const useStoreMareData = defineStore('mare-data', () => {
  const breedingNumValue = ref('');
  const mareData = ref(null);
  const bloodData = ref({});
  const crossAppend = ref([]);
  const offspringData = ref({});

  return {
    breedingNumValue,
    mareData,
    bloodData,
    crossAppend,
    offspringData,
  }
})

export default {
  useStoreMareData,
}