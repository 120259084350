<template>
  <div class="item">
    <h3 class="heading-item">良</h3>
    <table
      class="table-grid -center -middle -padding"
      v-if="condition1Data.length"
    >
      <tbody>
        <tr>
          <th>番</th>
          <th>出走馬名</th>
          <th>1着</th>
          <th>2着</th>
          <th>3着</th>
          <th>着外</th>
        </tr>
        <tr v-for="(data, index) in condition1Data" :key="index">
          <td v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`">
            {{ data.horseNum }}
          </td>
          <td class="-left">
            <router-link
              v-if="data.pedigreeNum && data.IsExistRaceHorseMaster"
              :to="`/data/horse?PedigreeNum=${data.pedigreeNum}`"
              :class="data.favClass"
            >
              {{ data.horseName }}
            </router-link>
            <span v-else>{{ data.horseName }}</span>
          </td>
          <td class="bg-rank01">{{ data.place1st }}</td>
          <td class="bg-rank02">{{ data.place2nd }}</td>
          <td class="bg-rank03">{{ data.place3rd }}</td>
          <td>{{ data.unplaced }}</td>
        </tr>
      </tbody>
    </table>
    <h3 class="heading-item">稍重</h3>
    <table
      class="table-grid -center -middle -padding"
      v-if="condition2Data.length"
    >
      <tbody>
        <tr>
          <th>番</th>
          <th>出走馬名</th>
          <th>1着</th>
          <th>2着</th>
          <th>3着</th>
          <th>着外</th>
        </tr>
        <tr v-for="(data, index) in condition2Data" :key="index">
          <td v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`">
            {{ data.horseNum }}
          </td>
          <td class="-left">
            <router-link
              v-if="data.pedigreeNum && data.IsExistRaceHorseMaster"
              :to="`/data/horse?PedigreeNum=${data.pedigreeNum}`"
              :class="data.favClass"
            >
              {{ data.horseName }}
            </router-link>
            <span v-else>{{ data.horseName }}</span>
          </td>
          <td class="bg-rank01">{{ data.place1st }}</td>
          <td class="bg-rank02">{{ data.place2nd }}</td>
          <td class="bg-rank03">{{ data.place3rd }}</td>
          <td>{{ data.unplaced }}</td>
        </tr>
      </tbody>
    </table>
    <h3 class="heading-item">重</h3>
    <table
      class="table-grid -center -middle -padding"
      v-if="condition3Data.length"
    >
      <tbody>
        <tr>
          <th>番</th>
          <th>出走馬名</th>
          <th>1着</th>
          <th>2着</th>
          <th>3着</th>
          <th>着外</th>
        </tr>
        <tr v-for="(data, index) in condition3Data" :key="index">
          <td v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`">
            {{ data.horseNum }}
          </td>
          <td class="-left">
            <router-link
              v-if="data.pedigreeNum && data.IsExistRaceHorseMaster"
              :to="`/data/horse?PedigreeNum=${data.pedigreeNum}`"
              :class="data.favClass"
            >
              {{ data.horseName }}
            </router-link>
            <span v-else>{{ data.horseName }}</span>
          </td>
          <td class="bg-rank01">{{ data.place1st }}</td>
          <td class="bg-rank02">{{ data.place2nd }}</td>
          <td class="bg-rank03">{{ data.place3rd }}</td>
          <td>{{ data.unplaced }}</td>
        </tr>
      </tbody>
    </table>
    <h3 class="heading-item">不良</h3>
    <table
      class="table-grid -center -middle -padding"
      v-if="condition4Data.length"
    >
      <tbody>
        <tr>
          <th>番</th>
          <th>出走馬名</th>
          <th>1着</th>
          <th>2着</th>
          <th>3着</th>
          <th>着外</th>
        </tr>
        <tr v-for="(data, index) in condition4Data" :key="index">
          <td v-bind:class="`umaban bgcolor-waku0${data.bracketNum}`">
            {{ data.horseNum }}
          </td>
          <td class="-left">
            <router-link
              v-if="data.pedigreeNum && data.IsExistRaceHorseMaster"
              :to="`/data/horse?PedigreeNum=${data.pedigreeNum}`"
              :class="data.favClass"
            >
              {{ data.horseName }}
            </router-link>
            <span v-else>{{ data.horseName }}</span>
          </td>
          <td class="bg-rank01">{{ data.place1st }}</td>
          <td class="bg-rank02">{{ data.place2nd }}</td>
          <td class="bg-rank03">{{ data.place3rd }}</td>
          <td>{{ data.unplaced }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Condition",
  data() {
    return {};
  },
  props: {
    condition1Data: Array,
    condition2Data: Array,
    condition3Data: Array,
    condition4Data: Array,
  },
  setup(props) {
    return {
      props,
    };
  },
  created() {
    let self = this;
  },
};
</script>

<style lang="scss" src="../../../assets/css/race/style.scss" scoped></style>
