<script setup>
import {ref, reactive, onMounted, onServerPrefetch, watch} from "vue";
import { useRouter,useRoute } from "vue-router";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  SUCCESS,
  ADD,
  REMOVE,
  PAYINGECONOMY,
  PAYINGPREMIUM,
  PAYINGNONE,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGPACK03,
  PAYINGPACK06,
  PAYINGPACK12,
  getUserAgent,
  getRequest,
  isFavorite,
  isNextTarget,
  changeNextTargetFromCookie,
  FRACEHORSE,
  TRACEHORSE,
  isNar, getCourse,
  getUserId,
} from "../../assets/js/common";

import {
  HOST,
  BACKWORDPATH,
  RACEINFOPATH,
  MEDIAPATH,
  RACEPATH, PATH,
} from "../../assets/js/define";
import {fetchPredictedSalesBanner} from '../../../spa/assets/js/wordpress'

import RaceHeader from "../../components/race/RaceHeader.vue";
import WeightModal from "../../components/utils/WeightModal.vue";
import ShowMovie from "../../components/utils/ShowMovie.vue";
import ShowMovieConfirm from "../../components/utils/ShowMovieConfirm.vue";

const router = useRouter();
const route = useRoute();
let mCourseVal = ref("");
let params = {};
let mCourse = (param) => (mCourseVal.value = param);
let videoUrl = ref("");
let videoIsExist = ref(false);

const modalOpen = ref(false);
const distance = ref(0);
const nextTargetClassObj = ref({})
const userId = ref('')

let raceTypeCdVal = ref("");
let raceRegulationCdVal = ref("");
const myNextTargetHorses = ref([]);
const predictedSalesBanner = ref('');

const raceTypeCd = (param) => {
  raceTypeCdVal.value = param;
}
const raceRegulationCd = (param) => {
  raceRegulationCdVal.value = param;
}

import {fetch as fetchResults} from "../../functions/race/race-results";
import {useStoreCommon} from "../../stores/common-states";
const storeCommon = useStoreCommon();
const { host, isNarSite } = storeToRefs(storeCommon);
import {useStoreRaceResults} from "../../stores/race-results-states";
const storeRaceResults = useStoreRaceResults();
const {
  raceResultList,
  raceRankingList,
  isDPointRace,
  bracketFlag,
  placeFlag,
  showPayBackFlg,
  returnNumber,
  cornerLastRank,
  refunds,
  raceResultParams,
} = storeToRefs(storeRaceResults);

import {storeToRefs} from "pinia";

const narFlag = ref(null);

onServerPrefetch(async () => {
  await fetchResults(
    route.query["DOR"],
    route.query["RacetrackCd"],
    route.query["RaceNum"],
    isNarSite.value,
  );
});

watch(route, async (from, to) => {
  await fetchResults(
    route.query["DOR"],
    route.query["RacetrackCd"],
    route.query["RaceNum"],
    isNarSite.value,
  );
  await doInit();
})

onMounted(async () => {
  narFlag.value = isNar()
  params = getUrlVars();
  fetchPredictedSalesBanner(predictedSalesBanner, 'result');

  //パラメータチェック
  if (checkUrlVars(params)) {
    await doInit();
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
  document.body.className = "status-loaded";
});

const doInit = async () => {
  if(raceResultParams.value['dor'] !== route.query["DOR"] ||
    raceResultParams.value['racetrackCd'] !== route.query["RacetrackCd"] ||
    raceResultParams.value['RaceNum'] !== route.query["RaceNum"]) {
    await fetchResults(
      route.query["DOR"],
      route.query["RacetrackCd"],
      route.query["RaceNum"],
      isNarSite.value,
    );
  }

  if (raceResultList.value?.length === 0) {
    await router.push(`/${RACEPATH}card?DOR=${route.query["DOR"]}&RacetrackCd=${route.query["RacetrackCd"]}&RaceNum=${route.query["RaceNum"]}`);
    return;
  }

  //レース結果表示
  if (params.RacetrackCd === '45') {
    await getDistance()
  }
  await setFavoriteHorsesStyle();
  await setVideoLink(params)
};

const setFavoriteHorsesStyle = async () => {
  await getMyNextTargetRacehorse();
  raceRankingList.value.forEach((horse, idx) => {
    // お気に入り競走馬 強調スタイルのクラス設定
    if(isFavorite(FRACEHORSE, horse.PedigreeNum) ||
      (isNextTarget(TRACEHORSE, horse.PedigreeNum) && myNextTargetHorses.value.includes(horse.PedigreeNum))) {
      raceRankingList.value[idx].favClass = '-favorite';
    } else {
      raceRankingList.value[idx].favClass = '';
    }

    // 次走狙いの処理追加
    if (isNextTarget(TRACEHORSE, horse.PedigreeNum) === 1) {
      //自動解除済みの場合か判定
      isAutoDeletedNextTarget(userId.value, horse.PedigreeNum);
    } else {
      nextTargetClassObj.value[horse.PedigreeNum] = ''
    }
  });
}

const getDistance = async () => {
  const dor = params.DOR
  const racetrackCd = params.RacetrackCd
  const raceNum = params.RaceNum
  const request =
    HOST +
    RACEINFOPATH +
    "raceBasicInfo.js" +
    BACKWORDPATH +
    "?dor=" +
    dor +
    "&racetrackCd=" +
    racetrackCd +
    "&raceNum=" +
    raceNum +
    "&narFlag=" +
    isNar()
  const result = await getRequest(request)
  distance.value = result.Distance
}

//馬体重クラス付与
const weightClass = (weightDifference) => {
  const weightDifferenceNum = Number(weightDifference)
  if (weightDifferenceNum > 0) return "_plus";
  if (weightDifferenceNum < 0) return "_minus";
  return "";
};

//オッズクラス付与
const oddsClass = (val) => {
  if (val == "---.-") {
    return "txt-odds";
  } else if (val > 0 && val < 10) {
    return "txt-odds -no1";
  } else if (val > 0 && val < 100) {
    return "txt-odds -no2";
  } else return "txt-odds -other";
};
let targetWeightData = reactive({});
const weightModal = (
  pedigreeNum,
  dor,
  horseWeight,
  horseName,
  weightArray,
  weightDifference
) => {
  //会員情報を取得し、ページ遷移
  if (mCourseVal.value === PAYINGNONE) {
    location.href = HOST + "member";
  } else if (mCourseVal.value === PAYINGSUGOTOKU_NONE) {
    location.href = HOST + "joinSugotoku.js";
  } else {
    let horse_weight = "";
    if (horseWeight) {
      if (weightDifference === 999) {
        horse_weight = horseWeight
      } else if (weightDifference > 0) {
        horse_weight = horseWeight + "(+" + weightDifference + ")";
      } else {
        horse_weight = horseWeight + "(" + weightDifference + ")";
      }
    }
    let weightData = {
      pedigreeNum: pedigreeNum,
      dor: dor,
      horseWeight: horseWeight,
      horseName: horseName,
      weightArray: weightArray,
      horse_weight: horse_weight,
    };
    Object.assign(targetWeightData, weightData);
    modalOpen.value = true;
  }
};

const getMyNextTargetRacehorse = async () => {
  userId.value = getUserId();
  let request =
    HOST +
    PATH +
    "MyNextTarget/getMyNextTargetRacehorse.js" +
    BACKWORDPATH +
    "?userID=" + encodeURIComponent(userId.value)
    await getJSON(request, true, function (status, json) {
      myNextTargetHorses.value = status === SUCCESS
        ? json
        : [];
    });
}

//次走狙い馬登録用
const clickNextTarget = async (horse) => {
  try {
    document.body.className = "";
    if(mCourseVal.value === PAYINGECONOMY){
      router.push('/member/join_economy');
      return;
    } else {
      if (isNextTarget(TRACEHORSE, horse.PedigreeNum) == 1) {
        // 次走狙い馬登録済みなので削除する
        const request =
          HOST +
          PATH +
          "MyNextTarget/deleteMyNextTargetRacehorse.js" +
          BACKWORDPATH +
          "?" +
          "userID=" +
          encodeURIComponent(userId.value) +
          "&pedigreeNum=" +
          horse.PedigreeNum +
          "&narFlag=" +
          isNar();
        const order = REMOVE;
        await getJSON(request, false, async function (status, json) {
          if (status == SUCCESS) {
            await changeNextTargetFromCookie(
              TRACEHORSE,
              horse.PedigreeNum,
              order
            );
            nextTargetClassObj.value[horse.PedigreeNum] = "";
          } else {
            alert("次走狙い馬の削除に失敗しました。");
          }
        });
      } else {
        // 次走狙い馬未登録なので登録する
        const request =
          HOST +
          PATH +
          "MyNextTarget/setMyNextTargetRacehorse.js" +
          BACKWORDPATH +
          "?" +
          "userID=" +
          encodeURIComponent(userId.value) +
          "&pedigreeNum=" +
          horse.PedigreeNum +
          "&isPush=1" +
          "&narFlag=" +
          isNar();
        const order = ADD;
        await getJSON(request, false, async function (status, json) {
          if (status == SUCCESS) {
            const nextTargetJudge = await changeNextTargetFromCookie(
              TRACEHORSE,
              horse.PedigreeNum,
              order
            );
            if (nextTargetJudge) {
              nextTargetClassObj.value[horse.PedigreeNum] = "-registed";
            }
          } else {
            alert("次走狙い馬の登録に失敗しました。");
          }
        });
      }
    }
    document.body.className = "status-loaded";
  } catch (e) {
    console.log("error" + e);
  }
}

//次走狙い馬が自動削除されていた場合はクッキーから削除
const isAutoDeletedNextTarget = async (userId, pedigreeNum) => {
  let request =
    HOST +
    PATH +
    "MyNextTarget/getMyNextTargetRacehorse.js" +
    BACKWORDPATH +
    "?userID=" + encodeURIComponent(userId)  +
    "&pedigreeNum=" + pedigreeNum
  await getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      if(json.includes(pedigreeNum)){
        nextTargetClassObj.value[pedigreeNum] = "-registed";
      } else {
        // 自動解除済みの場合cookieから次走狙い馬を削除
        changeNextTargetFromCookie(TRACEHORSE, pedigreeNum, REMOVE);
        nextTargetClassObj.value[pedigreeNum] = "";
      }
    }
  });
}

//レース動画リンク
const setVideoLink = function (params) {
  const COMMON_URL = HOST + MEDIAPATH + "videoOnDemand.js" + BACKWORDPATH;

  const request =
      HOST +
      MEDIAPATH +
      "getVideoUrl.js" +
      BACKWORDPATH +
      "?Lst=" +
      params.DOR + params.RacetrackCd + params.RaceNum +
      "&NarFlag=" +
      isNar();
  getJSON(request, true, function (status, result) {
    if (status == SUCCESS) {

      let logReq = HOST + PATH + "getUserCourse.js" + BACKWORDPATH;
      getJSON(logReq, true, function (status, json) {
        if (status == SUCCESS) {
          const key = result[0].Key;
          videoIsExist.value = result[0].Exist;

          if (
              PAYINGPREMIUM === json.courseId ||
              PAYINGPACK03 === json.courseId ||
              PAYINGPACK06 === json.courseId ||
              PAYINGPACK12 === json.courseId ||
              getCourse() === PAYINGSUGOTOKU
          ) {
            //premium
            videoUrl.value =
                COMMON_URL +
                "?DOR=" +
                key.substr(0, 8) +
                "&RacetrackCd=" +
                key.substr(8, 2) +
                "&RaceNum=" +
                key.substr(10, 2) +
                "&Expir=" +
                result[0].Expir +
                "&x=.mp4";
          } else if (PAYINGSUGOTOKU_NONE === getCourse()) {
            videoUrl.value = HOST + "joinSugotoku.js";
          } else {
            videoUrl.value = HOST + (json.courseId === PAYINGECONOMY ? "member/join_economy" : "member")
          }
        }
      });
    }
  });
};
let openMovie = ref(false);
let openMovieConfirm = ref(false);
const openVideoModal = function () {
  if (PAYINGSUGOTOKU === mCourseVal.value) {
    //動画視聴確認モーダルを開く
    openMovieConfirm.value = true
  } else if (PAYINGPREMIUM === mCourseVal.value ||
      PAYINGPACK03 === mCourseVal.value ||
      PAYINGPACK06 === mCourseVal.value ||
      PAYINGPACK12 === mCourseVal.value) {
    //動画モーダルを開く
    openMovie.value = true;
  } else {
    location.href = videoUrl.value;
  }
}
const openVideoConfirmed = (confirmed) => {
  openMovieConfirm.value = false;
  openMovie.value = confirmed;
}
const setProgramUrl = () => {
  return (
    "/program?RaceTypeCd=" +
    raceTypeCdVal.value +
    "&RaceRegulationCd=" +
    raceRegulationCdVal.value
  )
}
const shortenName = (name) => {
  if(name) {
    return name.replace(/　/g, " ").slice(0,6)
  }
  return
}
</script>
<template>
  <main class="layout-main">
    <ShowMovieConfirm
        @openVideoConfirmed="openVideoConfirmed"
        :openMovieConfirm="openMovieConfirm">
    </ShowMovieConfirm>
    <ShowMovie
        @closeModal="openMovie = false"
        :openMovie="openMovie"
        :movieURL="videoUrl">
    </ShowMovie>
    <RaceHeader
      @mCourse="mCourse"
      @raceTypeCd="raceTypeCd"
      @raceRegulationCd="raceRegulationCd"
      :isDPointRace="isDPointRace">
    </RaceHeader>

    <section class="layout-section">
      <div class="container-box">
        <div
            v-if="narFlag === 0 && videoUrl !== null && videoIsExist"
          class="inner -transparent _center"
        >
          <a class="btn-basic -movie" @click="openVideoModal()">
            <i :class="[PAYINGPREMIUM, PAYINGPACK03, PAYINGPACK06, PAYINGPACK12, PAYINGSUGOTOKU].includes(mCourseVal) ?
             'icon-mov' : 'icon-mov -premium'">
            </i>
            <span class="text">レース結果ムービーを見る</span>
          </a>
        </div>
        <div class="inner">
          <div class="layout-title">
            <h2 class="heading-circle -padding">払戻金</h2>
          </div>
          <table class="table-grid -middle -center -results">
            <tbody v-if="showPayBackFlg">
              <template v-for="(win, index) in refunds.win" :key="win.Popular">
                <tr :class="refunds.win.length === index + 1 ? '' : '-noborderbottom'">
                  <td v-if="index === 0" :rowspan="index === 0 ? `${refunds.win.length}` : ''" class="line -win">単勝</td>
                  <td class="-left">
                    <i class="icon-umaban">{{ win.WinNum }}</i>
                  </td>
                  <td class="-right -cashback">{{ win.Refund }}円</td>
                  <td class="-center">{{ win.Popular }}人気</td>
                </tr>
              </template>
              <template v-if="refunds.place.length">
                <template  v-for="(place, index) in refunds.place" :key="place['_id']">
                  <tr :class="refunds.place.length === index + 1 ? '' : '-noborderbottom'">
                    <td v-if="index === 0" :rowspan="index === 0 ? `${refunds.place.length}` : ''" class="-borderbottom line -place">複勝</td>
                      <td class="-left">
                        <i class="icon-umaban">{{ place.WinNum }}</i>
                      </td>
                      <td class="-right -cashback">{{ place.Refund }}円</td>
                      <td class="-center">{{ place.Popular }}人気</td>
                  </tr>
                </template>
              </template>
              <template v-for="(bracket, index) in refunds.bracket" :key="bracket.Popular">
                <tr :class="refunds.bracket.length === index + 1 ? '' : '-noborderbottom'">
                  <td v-if="index === 0" :rowspan="index === 0 ? `${refunds.bracket.length}` : ''" class="line -bracket">枠連</td>
                  <td class="-left">
                    <ul class="list-kaimoku">
                      <li>
                        <i class="icon-umaban">{{
                          bracket.WinNum.split("-")[0]
                        }}</i>
                      </li>
                      <li>
                        <i class="icon-umaban">{{
                          bracket.WinNum.split("-")[1]
                        }}</i>
                      </li>
                    </ul>
                  </td>
                  <td class="-right -cashback">{{ bracket.Refund }}円</td>
                  <td class="-center">{{ bracket.Popular }}人気</td>
                </tr>
              </template>
              <template v-for="(bracket, index) in refunds.bracketExacta" :key="bracket.Popular">
                <tr :class="refunds.bracketExacta.length === index + 1 ? '' : '-noborderbottom'">
                  <td v-if="index === 0" :rowspan="index === 0 ? `${refunds.bracketExacta.length}` : ''" class="line -bracket-exacta">枠単</td>
                  <td class="-left">
                    <ul class="list-kaimoku -arrow">
                      <li>
                        <i class="icon-umaban">{{
                          bracket.WinNum.split("-")[0]
                        }}</i>
                      </li>
                      <li>
                        <i class="icon-umaban">{{
                          bracket.WinNum.split("-")[1]
                        }}</i>
                      </li>
                    </ul>
                  </td>
                  <td class="-right -cashback">{{ bracket.Refund }}円</td>
                  <td class="-center">{{ bracket.Popular }}人気</td>
                </tr>
              </template>
              <template v-for="(quinella, index) in refunds.quinella" :key="quinella.Popular">
                <tr :class="refunds.quinella.length === index + 1 ? '' : '-noborderbottom'">
                  <td v-if="index === 0" :rowspan="index === 0 ? `${refunds.quinella.length}` : ''" class="line -quinella">馬連</td>
                  <td class="-left">
                    <ul class="list-kaimoku">
                      <li>
                        <i class="icon-umaban">{{
                          quinella.WinNum.split("-")[0]
                        }}</i>
                      </li>
                      <li>
                        <i class="icon-umaban">{{
                          quinella.WinNum.split("-")[1]
                        }}</i>
                      </li>
                    </ul>
                  </td>
                  <td class="-right -cashback">{{ quinella.Refund }}円</td>
                  <td class="-center">{{ quinella.Popular }}人気</td>
                </tr>
              </template>
              <template v-for="(exacta, index) in refunds.exacta" :key="exacta.Popular">
                <tr :class="refunds.exacta.length === index + 1 ? '' : '-noborderbottom'">
                  <td v-if="index === 0" :rowspan="index === 0 ? `${refunds.exacta.length}` : ''" class="line -exacta">馬単</td>
                  <td class="-left">
                    <ul class="list-kaimoku -arrow">
                      <li>
                        <i class="icon-umaban">{{
                          exacta.WinNum.split("-")[0]
                        }}</i>
                      </li>
                      <li>
                        <i class="icon-umaban">{{
                          exacta.WinNum.split("-")[1]
                        }}</i>
                      </li>
                    </ul>
                  </td>
                  <td class="-right -cashback">{{ exacta.Refund }}円</td>
                  <td class="-center">{{ exacta.Popular }}人気</td>
                </tr>
              </template>
              <template v-for="(wide, index) in refunds.wide" :key="wide.Popular">
                <tr :class="refunds.wide.length === index + 1 ? '' : '-noborderbottom'">
                  <td v-if="index === 0" :rowspan="index === 0 ? `${refunds.wide.length}` : ''" class="-borderbottom line -wide">ワイド</td>
                  <td class="-left">
                    <ul class="list-kaimoku">
                      <li>
                        <i class="icon-umaban">{{
                          wide.WinNum.split("-")[0]
                        }}</i>
                      </li>
                      <li>
                        <i class="icon-umaban">{{
                          wide.WinNum.split("-")[1]
                        }}</i>
                      </li>
                    </ul>
                  </td>
                  <td class="-right -cashback">
                    {{ wide.Refund }}円
                  </td>
                  <td class="-center">{{ wide.Popular }}人気</td>
                </tr>
              </template>
              <template v-for="(trio, index) in refunds.trio" :key="trio.Popular">
                <tr :class="refunds.trio.length === index + 1 ? '' : '-noborderbottom'">
                  <td v-if="index === 0" :rowspan="index === 0 ? `${refunds.trio.length}` : ''" class="-borderbottom line -trio">3連複</td>
                  <td class="-left">
                    <ul class="list-kaimoku">
                      <li>
                        <i class="icon-umaban">{{
                          trio.WinNum.split("-")[0]
                        }}</i>
                      </li>
                      <li>
                        <i class="icon-umaban">{{
                          trio.WinNum.split("-")[1]
                        }}</i>
                      </li>
                      <li>
                        <i class="icon-umaban">{{
                          trio.WinNum.split("-")[2]
                        }}</i>
                      </li>
                    </ul>
                  </td>
                  <td class="-right -cashback">
                    {{ trio.Refund }}円
                  </td>
                  <td class="-center">{{ trio.Popular }}人気</td>
                </tr>
              </template>
              <template v-for="(trifecta, index) in refunds.trifecta" :key="trifecta.Popular">
                <tr :class="refunds.trifecta.length === index + 1 ? '' : '-noborderbottom'">
                  <td v-if="index === 0" :rowspan="index === 0 ? `${refunds.trifecta.length}` : ''" class="-borderbottom line -trifecta">3連単</td>
                  <td class="-left">
                    <i v-if="trifecta.WinNum === '特払い'" class="icon-umaban">特払い</i>
                    <ul v-else class="list-kaimoku -arrow">
                      <li>
                        <i class="icon-umaban">{{
                          trifecta.WinNum.split("-")[0]
                        }}</i>
                      </li>
                      <li>
                        <i class="icon-umaban">{{
                          trifecta.WinNum.split("-")[1]
                        }}</i>
                      </li>
                      <li>
                        <i class="icon-umaban">{{
                          trifecta.WinNum.split("-")[2]
                        }}</i>
                      </li>
                    </ul>
                  </td>
                  <td class="-right -cashback">
                    {{ trifecta.Refund }}円
                  </td>
                  <td class="-center">{{ trifecta.Popular === 0 ? '-' : trifecta.Popular }}人気</td>
                </tr>
              </template>
            </tbody>
          </table>
          <div v-if="returnNumber" class="p-caption -caution">
            {{ returnNumber }}
          </div>
        </div>
      </div>
    </section>

    <section class="layout-section">
      <div class="inner" v-if="predictedSalesBanner" v-html="predictedSalesBanner"></div>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner">
          <div class="layout-title">
            <h2 class="heading-circle">着順</h2>
          </div>
          <table class="table-grid -middle -center -ranking">
            <tbody>
              <tr v-for="(result, index) in raceRankingList" :key="index">
                <td v-if="result.AccidentCd === '1'" class="rank">
                  取<br />消
                </td>
                <td
                  v-else-if="
                    result.AccidentCd === '2' || result.AccidentCd === '3'
                  "
                  class="rank"
                >
                  除<br />外
                </td>
                <td v-else-if="result.AccidentCd === '4'" class="rank">
                  中<br />止
                </td>
                <td v-else-if="result.AccidentCd === '5'" class="rank">
                  失<br />格
                </td>
                <td v-else-if="result.AccidentCd === '6'" class="rank">
                  {{ result.RaceRank }}<br />着<br />再<br />騎
                </td>
                <td v-else-if="result.AccidentCd === '8'" class="rank"></td>
                <td
                  v-else
                  class="rank"
                  :class="{
                    'bg-rank01': result.RaceRank === 1,
                    'bg-rank02': result.RaceRank === 2,
                    'bg-rank03': result.RaceRank === 3,
                  }"
                >
                  {{ result.RaceRank }}<br />着
                </td>

                <td class="umaban" :class="'bgcolor-waku0' + result.BracketNum">
                  {{ result.HorseNum }}
                </td>
                {{racetrackCd}}
                <td class="main -left">
                  <div class="title">
                    <router-link v-if="result.PedigreeNum"
                      :to="'/data/horse?PedigreeNum=' + result.PedigreeNum"
                      class="horse"
                      :class="result.favClass"
                      >{{ result.HorseName }}</router-link
                    >
                    <span v-else class="horse">
                      {{ result.HorseName }}
                    </span>
                    <span 
                      class="icon-nextrun" 
                      :class="{'-registed': nextTargetClassObj[result.PedigreeNum], '-premium': mCourseVal === PAYINGECONOMY }" 
                      @click="clickNextTarget(result)" 
                      v-if="![PAYINGNONE, PAYINGSUGOTOKU_NONE].includes(mCourseVal)"
                    >
                      <span class="form-checkboxgroup">
                        <label>
                          <input type="checkbox" name="cat">
                          <span>次走<br>狙い</span>
                        </label>
                      </span>
                    </span>
                  </div>
                  <ul class="info">
                    <li class="kind">
                      {{ result.CoatColor }}/{{ result.Sex }}{{ result.Age }}
                    </li>
                    <li class="corner">{{ result.CorneringOrder }}</li>
                    <li>
                      <div 
                        v-if="(
                          !result.AccidentCd ||
                          result.AccidentCd === '0' ||
                          result.AccidentCd === '3' ||
                          result.AccidentCd === '4' ||
                          result.AccidentCd === '5' ||
                          result.AccidentCd === '7'
                          ) && result.PedigreeNum"
                        class="-showgraph linkpointer"
                        @click="
                          weightModal(
                            result.PedigreeNum,
                            result.WeightArray[0].DOR,
                            result.HorseWeight,
                            result.HorseName,
                            result.WeightArray,
                            result.WeightDifference
                          )
                        "
                      >
                        {{ result.HorseWeight == 999 ? '計不' : result.HorseWeight }}
                        <span v-if="result.WeightDifference < 999 && result.HorseWeight !== result.WeightDifference">
                          (<span :class="weightClass(result.WeightDifference)">
                            {{result.WeightDifference > 0 ? "+" + result.WeightDifference: result.WeightDifference }}
                          </span>)
                        </span>
                      </div>
                    </li>
                  </ul>
                  <ul class="info">
                    <li>
                      <router-link
                        :to="'/data/jockey?JockeyCd=' + result.JockeyCd"
                        class="jockey"
                        >{{ shortenName(result.JockeyName) }}</router-link
                      >({{ params.RacetrackCd === '33' ? Math.floor(result.Weight / 10) : (parseInt(result.Weight, 10) / 10).toFixed(1) }})
                    </li>
                    <li>
                      <router-link
                        :to="'/data/trainer?TrainerCd=' + result.TrainerCd"
                        class="trainer"
                        >{{ shortenName(result.TrainerName) }}</router-link
                      >[{{ result.EastWestDivLbl }}]
                    </li>
                  </ul>
                </td>
                <td class="odds -center">
                  <div>
                    <span :class="oddsClass(result.WinOdds)" v-if="result.WinOdds">
                      {{ result.WinOdds }}倍
                    </span>
                    <br/>
                    {{ result.WinPopularRank }}
                  </div>
                </td>
                <td class="time -center">
                  <div 
                    v-if="(
                      !result.AccidentCd ||
                      result.AccidentCd === '0' ||
                      result.AccidentCd === '5' ||
                      result.AccidentCd === '7')">
                    <div class="record" v-if="result.RecordDiv === '2'">
                      <span class="time" style="font-weight: bold">
                        {{ result.Time }}
                      </span>
                      <br />
                      <span class="label" style="font-weight: bold">
                        レコード</span>
                      <br />
                      <div v-if="result.FurlongTime">
                        (
                        <span class="txt-rank"
                              :class="result.FurlongFlg === 1 ? '-no1'
                              : result.FurlongFlg === 2 ? '-no2'
                              : result.FurlongFlg === 3 ? '-no3' : ''">
                          {{ result.FurlongTime }}
                        </span>
                        )
                      </div>
                    </div>
                    <div v-else>
                      <span class="time">{{ result.Time }}</span>
                      <br v-if="result.HorseLengths && result.RaceRank !== 1" />
                      <span v-if="result.HorseLengths && result.RaceRank !== 1" class="time2">
                        {{ result.HorseLengths }}
                      </span>
                      <br />
                      <div v-if="result.FurlongTime">
                        (
                        <span class="txt-rank"
                              :class="result.FurlongFlg === 1 ? '-no1'
                              : result.FurlongFlg === 2 ? '-no2'
                              : result.FurlongFlg === 3 ? '-no3' : ''">
                          {{ result.FurlongTime }}
                        </span>
                        )
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="inner">
          <div class="layout-title">
            <h2 class="heading-circle">コーナー通過順位</h2>
          </div>
          <table v-if="raceResultList[0]" class="table-grid -middle">
            <tbody>
              <tr v-if="raceResultList[0].CornerRanking1st">
                <th nowrap>1コーナー</th>
                <td><div v-html="raceResultList[0].CornerRanking1st"></div></td>
              </tr>
              <tr v-if="raceResultList[0].CornerRanking2nd">
                <th nowrap v-if="params.RacetrackCd === '45' && distance === 900">向正面</th>
                <th nowrap v-else>2コーナー</th>
                <td><div v-html="raceResultList[0].CornerRanking2nd"></div></td>
              </tr>
              <tr v-if="raceResultList[0].CornerRanking3rd">
                <th nowrap>3コーナー</th>
                <td><div v-html="raceResultList[0].CornerRanking3rd"></div></td>
              </tr>
              <tr v-if="raceResultList[0].CornerRanking4th">
                <th nowrap class="-left">4コーナー</th>
                <td><div v-html="raceResultList[0].CornerRanking4th"></div></td>
              </tr>
              <tr>
                <th nowrap>最終着順</th>
                <td><div v-html="cornerLastRank"></div></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="inner" v-if="!narFlag">
          <router-link
            :to="setProgramUrl()"
            class="btn-basic -wide -channel"
            :class="[PAYINGPREMIUM, PAYINGPACK03, PAYINGPACK06, PAYINGPACK12, PAYINGSUGOTOKU].includes(mCourseVal)
              ? ''
              : '-premium' ">
            番組表へ
          </router-link>
        </div>

        <p class="p-caption p-white -padding">
          ※出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。
        </p>
      </div>
    </section>

    <WeightModal
      v-if="modalOpen === true"
      v-on:closeDialog="modalOpen = false"
      :modalOpen="modalOpen"
      :targetWeightData="targetWeightData"
    ></WeightModal>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/race/style.scss" scoped></style>
