<script setup>
import {ref, reactive, onMounted, watch} from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  SUCCESS,
  isDismissDialog,
  isNar,
  isAndroid,
  getUserAgent, isSugotoku,
} from "../../../assets/js/common";

import {
  HOST,
  PATH,
  BACKWORDPATH,
  METHOD_WHEEL_1,
  TOB_BRACKET_EXACTA,
  TOB_BRACKET_EXACTA_MULTI,
} from "../../../assets/js/define";

import SetBetConfModal from "../../../components/utils/SetBetConfModal.vue";
import raceOdds from "../../../functions/odds/race-odds";
import raceDeadline from "../../../functions/odds/race-deadline_countdown";
import {useRoute, useRouter} from "vue-router";

const {setRaceBetByArray, loadOddsWin, retrieveOdds, sortDoubleOdds, oddsClass} = raceOdds();
const {TimerCount} = raceDeadline();

let checkAllJudge = ref(false);
let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let deadLineJudge = ref(false);
let loadedPage = ref(false)
let oddsUpdInfo = ref("");
let isOddsInfo = ref(false);
let cancelList = reactive([]);
const router = useRouter();
const isWebView = ref(false);
const isSugotokuMember = ref(false);

let params = {};

const props = defineProps({
  raceDORVal: String,
  raceTrackCdVal: String,
  raceNumVal: String,
  deadLineJudge: Boolean,
});

let time = ref("");

const route = useRoute();
watch(route, (from, to) => {
  clearSelected();
  loadPromisingBet();
  doInit(to.query);
})

onMounted(async () => {
  document.body.className = "";
  params = getUrlVars();
  isWebView.value = getUserAgent();
  isSugotokuMember.value = isSugotoku();
  //パラメータチェック
  if (checkUrlVars(params)) {
    loadPromisingBet()
    await doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});

//チェックリスト関連
let checkList = reactive({});
let checkedSum = ref(0);
//　選択した馬の組み合わせ表示用
let info = reactive([]);
let axisHorse = ref(null);
let checkStorageArray = reactive([])
let axisHorseStorage = ref(null)
const loadPromisingBet = () => {
  const checkListStorage = localStorage.getItem('bracket_exacta_wheel_1' + location.search)
  const axisStorage = localStorage.getItem('bracket_exacta_axis_1' + location.search)
  if (checkListStorage) checkStorageArray = JSON.parse(checkListStorage)
  if (axisStorage) axisHorseStorage.value = Number(axisStorage)
}
const checkChange = (num, horseNum) => {
  if (horseNum === "horse1") {
    axisHorse.value = num;
    axisHorseStorage.value = axisHorse.value
    localStorage.setItem('bracket_exacta_axis_1' + location.search, axisHorseStorage.value)
  } else {
    if (checkList[num] === true) {
      checkList[num] = false;
      checkStorageArray?.splice(checkStorageArray?.indexOf(String(num)), 1)
    } else {
      checkList[num] = true;
      checkStorageArray?.push(String(num))
    }
    localStorage.setItem('bracket_exacta_wheel_1' + location.search, JSON.stringify(checkStorageArray))
  }
  setSelected();
};
let checkNumInfo = new Array();
let oddsTable = reactive([]);
const wheelHeading = ref("");
const setSelected = () => {
  info.splice(0);
  let horse1 = 0;
  let horse2 = 0;
  let conf = 0;
  checkNumInfo.splice(0);
  if (axisHorse.value != null) {
    Object.keys(checkList).forEach((c) => {
      if (isNotCouple(c)) return;
      horse1 = axisHorse.value + 1;
      horse2 = Number(c) + 1;

      conf = info.findIndex((i) => i.horse1 === horse1 && i.horse2 === horse2);
      if (conf == -1) {
        info.push({
          horse1: horse1,
          horse2: horse2,
          odds: oddsJson["Odds" + horse1 + "_" + horse2],
        });
        wheelHeading.value = "1着軸流し　選択枠番";
        if (checkMulti.value && horse1 !== horse2) {
          wheelHeading.value = "1着軸流しマルチ　選択枠番";
          info.push({
            horse1: horse2,
            horse2: horse1,
            odds: oddsJson["Odds" + horse2 + "_" + horse1],
          });
        }
      }
    });
  }
  checkNumInfo = Object.assign(checkNumInfo, info);
  checkNumInfo.sort(sortDoubleOdds(sortType.value));
  checkedHorseSum();
};
const checkedHorseSum = () => {
  checkedSum.value = info.length;
};
const checkedAll = () => {
  if (checkAllJudge.value == true) {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      checkList[i] = false;
      checkStorageArray?.splice(checkStorageArray?.indexOf(String(i)), 1)
    }
    checkAllJudge.value = false;
  } else {
    for (let i = 0; i < Object.keys(checkList).length; i++) {
      if (cancelList[i]) {
        //取消・中止はチェックしない
        checkList[i] = false;
      } else {
        checkList[i] = true;
        if (checkStorageArray?.includes(String(i))) continue
        checkStorageArray?.push(String(i))
      }
    }
    checkAllJudge.value = true;
  }
  localStorage.setItem('bracket_exacta_wheel_1' + location.search, JSON.stringify(checkStorageArray))
  setSelected();
};

// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkList, () => {
  const checkLists = Object.values(checkList)
  if (checkLists.length) checkAllJudge.value = checkLists.find(check => check === false) === undefined
}, {immediate: true})
const checkListNum = () => {
  let arr = [];

  for (let i = 0; i < Object.keys(checkList).length; i++) {
    if (isNotCouple(i)) continue;
    arr.push(i);
  }

  return arr;
};

const checkMulti = ref(false);
//マルチ対応
const checkedMulti = () => {
  if (checkMulti.value) {
    checkMulti.value = false;
  } else {
    checkMulti.value = true;
  }
  setSelected();
};

let oddsJson = reactive({});
const doInit = async (params) => {
  //オッズ情報取得
  retrieveOdds(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum,
    TOB_BRACKET_EXACTA,
    function (result) {
      oddsJson = result;
    }
  );

  //枠単オッズ読み込み
  loadOddsBracketExacta(params.DOR, params.RacetrackCd, params.RaceNum);
};

//レース詳細情報を取得する
let bracketArray = reactive([]);
let oddsInfo = reactive([]);
let wakuRow = reactive([]);
const oddsUpInfo = ref("");
const loadOddsBracketExacta = async (dor, racetrackCd, raceNum, callback) => {
  //振り直したOddsIdで単勝オッズを設定
  const oddsList = await loadOddsWin(dor, racetrackCd, raceNum);
  oddsInfo.splice(0, oddsInfo.length);
  for (let i = 0; i < oddsList.odds.length; i++) {
    oddsInfo.push(oddsList.odds[i]);
  }
  //出馬表から取消・除外などの情報を取得
  //パラメータからAPIコールを生成
  let req =
    HOST +
    PATH +
    "RaceInfo/candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      let intEntryNum = parseInt(json["EntryNum"], 10);
      let entryHorsesArray = json["EntryHorses"];

      let limitHorseNo = [];
      limitHorseNo.push("dummy");
      limitHorseNo.push([0, 1, 0, 0, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 0, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 0, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 0, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 0, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 0, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 0]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 8]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 7, 9]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 6, 8, 10]);
      limitHorseNo.push([0, 1, 2, 3, 4, 5, 7, 9, 11]);
      limitHorseNo.push([0, 1, 2, 3, 4, 6, 8, 10, 12]);
      limitHorseNo.push([0, 1, 2, 3, 5, 7, 9, 11, 13]);
      limitHorseNo.push([0, 1, 2, 4, 6, 8, 10, 12, 14]);
      limitHorseNo.push([0, 1, 3, 5, 7, 9, 11, 13, 15]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 14, 16]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 14, 17]);
      limitHorseNo.push([0, 2, 4, 6, 8, 10, 12, 15, 18]);

      //出頭数から上記の表のどれを使用するか決定
      let limitHorseNoArray = limitHorseNo[intEntryNum];
      let countNum = 0;
      //全馬について
      bracketArray.splice(0, bracketArray.length);
      for (let waku = 1; waku <= 8; waku++) {
        try {
          let isCancel = false;

          let row = 0;
          //前の枠との差が１頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 1) {
            const horse1 = entryHorsesArray[limitHorseNoArray[waku] - 1];
            const index = horse1['HorseNum'] - 1;
            row = 1;

            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3" || !oddsInfo[index]) {
              isCancel = true;
            }
            bracketArray.push({
              waku: waku,
              horseNum: horse1["HorseNum"],
              horseName: horse1["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse1["AbnormalDiv"],
            });
          }

          //前の枠との差が２頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 2) {
            let clancelCount = 0;
            let horse1 = entryHorsesArray[limitHorseNoArray[waku - 1]];
            let horse2 = entryHorsesArray[limitHorseNoArray[waku - 1] + 1];
            const index1 = horse1['HorseNum'] - 1;
            const index2 = horse2['HorseNum'] - 1;
            row = 2;
            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3" || !oddsInfo[index1]) {
              clancelCount++;
            }
            if (horse2["AbnormalDiv"] == "1" || horse2["AbnormalDiv"] == "3" || !oddsInfo[index2]) {
              clancelCount++;
            }

            if (clancelCount > 0) {
              if (clancelCount == 2) {
                //チェック不可
                isCancel = true;
              }
            }

            bracketArray.push({
              waku: waku,
              horseNum: horse1["HorseNum"],
              horseName: horse1["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse1["AbnormalDiv"],
            });

            bracketArray.push({
              waku: waku,
              horseNum: horse2["HorseNum"],
              horseName: horse2["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse2["AbnormalDiv"],
            });
          }

          //前の枠との差が３頭の場合
          if (limitHorseNoArray[waku] - limitHorseNoArray[waku - 1] == 3) {
            let clancelCount = 0;
            let horse1 = entryHorsesArray[limitHorseNoArray[waku - 1]];
            let horse2 = entryHorsesArray[limitHorseNoArray[waku - 1] + 1];
            let horse3 = entryHorsesArray[limitHorseNoArray[waku - 1] + 2];
            const index1 = horse1['HorseNum'] - 1;
            const index2 = horse2['HorseNum'] - 1;
            const index3 = horse3['HorseNum'] - 1;
            row = 3;
            if (horse1["AbnormalDiv"] == "1" || horse1["AbnormalDiv"] == "3" || !oddsInfo[index1]) {
              clancelCount++;
            }
            if (horse2["AbnormalDiv"] == "1" || horse2["AbnormalDiv"] == "3" || !oddsInfo[index2]) {
              clancelCount++;
            }
            if (horse3["AbnormalDiv"] == "1" || horse3["AbnormalDiv"] == "3" || !oddsInfo[index3]) {
              clancelCount++;
            }

            if (clancelCount > 1) {
              if (clancelCount == 3) {
                //チェック不可
                isCancel = true;
              }
            }

            bracketArray.push({
              waku: waku,
              horseNum: horse1["HorseNum"],
              horseName: horse1["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse1["AbnormalDiv"],
            });

            bracketArray.push({
              waku: waku,
              horseNum: horse2["HorseNum"],
              horseName: horse2["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse2["AbnormalDiv"],
            });

            bracketArray.push({
              waku: waku,
              horseNum: horse3["HorseNum"],
              horseName: horse3["HorseName"],
              isCancel: isCancel,
              rows: row,
              abnormalDiv: horse3["AbnormalDiv"],
            });
          }
          cancelList[countNum] = isCancel;
          wakuRow[countNum] = row;
          checkList[countNum] = !!checkStorageArray?.includes(String(countNum));
          countNum++;
        } catch (e) {
          console.log(e);
        }
      }
    }
  });

  req =
    HOST +
    PATH +
    "Odds/bracketExactaOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS && json.length == 1) {
      const displayData = json[0];
      if (displayData["OddsUpdInfo"] != null)
        oddsUpInfo.value = displayData["OddsUpdInfo"];
    }
  });
};

// 買い目登録
const setRaceBetWin = () => {
  const sum = checkedSum.value;
  let openWindow
  if (isDismissDialog() == 1 && !isAndroid() && !getUserAgent() && !isSugotoku()) {
    openWindow = window.open('', '_blank')
  }

  let setRaceBetParams = getUrlVars();
  let mainChekFlg = "";
  let isFirst = false;
  let isSecond = false;
  if (sum != 0) {
    // レースヘッダから情報を取得
    if (props.raceDORVal != null) {
      setRaceBetParams["DOR"] = props.raceDORVal;
    }
    if (props.raceTrackCdVal != null) {
      setRaceBetParams["RacetrackCd"] = props.raceTrackCdVal;
    }
    if (props.raceNumVal != null) {
      setRaceBetParams["RaceNum"] = props.raceNumVal;
    }

    for (let i = 0; i < 18; i++) {
      if (i == axisHorse.value) {
        mainChekFlg += "1";
        isFirst = true;
      } else {
        mainChekFlg += "0";
      }
    }
    for (let i = 0; i < 18; i++) {
      if (isNotCouple(i)) {
        mainChekFlg += "0";
      } else {
        mainChekFlg += "1";
        isSecond = true;
      }
    }
  }
  if (isFirst && isSecond) {
    let betArray = new Array();
    let doFlgArray = new Array();
    const methodType = METHOD_WHEEL_1;
    betArray.push(mainChekFlg);
    doFlgArray.push(true);
    let tobType = TOB_BRACKET_EXACTA;
    if (checkMulti.value) tobType = TOB_BRACKET_EXACTA_MULTI;
    //順番に登録させるため再帰的に登録
    setRaceBetByArray(
      setRaceBetParams,
      tobType,
      methodType,
      betArray,
      doFlgArray,
      function () {
        if (isDismissDialog() != 1) {
          //モーダルウィンドウ表示
          raceBetCheck.value = true;
          modalBetOpen.value = true;
        } else {
          if (isAndroid() || getUserAgent() || isSugotoku()) {
            router.push("/purchase/spat4")
          }
          else {
            openWindow.location.href = HOST + "purchase/spat4"
          }
        }
      }
    );
    checkStorageArray.splice(0)
    axisHorseStorage.value = null
    localStorage.removeItem('bracket_exacta_wheel_1' + location.search)
    localStorage.removeItem('bracket_exacta_axis_1' + location.search)
  } else if (!isFirst) {
    alert("軸馬を選択してください");
  } else {
    alert("有効な相手馬を選択してください");
  }
};

// 買い目削除
const deleteSelected = () => {
  const result = confirm('枠単/1着軸流しに対して全ての買い目を削除しますか？')
  if (result) {
    clearStorage()
    clearSelected()
  }
}

const clearStorage = () => {
  localStorage.removeItem('bracket_exacta_wheel_1' + location.search)
  localStorage.removeItem('bracket_exacta_axis_1' + location.search)
}

const clearSelected = () => {
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    checkList[i] = false;
  }
  axisHorse.value = null;
  axisHorseStorage.value = null;
  checkStorageArray.splice(0)
  checkAllJudge.value = false
  setSelected();

}

//ソートを変更
const activeSortId = ref("sort_num");
const sortType = ref(3);
const changeSort = (id) => {
  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_num") {
      if (sortType.value === 3) {
        sortType.value = 4;
      } else {
        sortType.value = 3;
      }
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      if (sortType.value === 5) {
        sortType.value = 6;
      } else {
        sortType.value = 5;
      }
      activeSortId.value = "sort_odds";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを昇順でセットする
    if (id === "sort_num") {
      sortType.value = 3;
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      sortType.value = 5;
      activeSortId.value = "sort_odds";
    }
  }
  setSelected();
};

const isNotCouple = (i) => {
  const bracketNum = Number(i) + 1;
  const bracketData = bracketArray.filter(bracket => bracket.waku === bracketNum && bracket.abnormalDiv === '0');
  return !checkList[i] || (axisHorse.value === Number(i) && bracketData.length < 2);
}

watch(oddsInfo, (val) => {
  loadedPage.value = true
  document.body.className = "status-loaded";
});
</script>

<template>
  <section class="layout-section" v-if="loadedPage">
    <div class="layout-title">
      <h2 class="heading-circle -padding">枠単 / 1着軸流し</h2>
      <div class="nav">
        <span v-if="props.deadLineJudge" class="status">締め切り間近</span>
        <span :class="[oddsUpInfo ? 'icon-kakutei' : '']">{{
            oddsUpInfo
          }}</span>
      </div>
    </div>
    <table class="table-grid -fit -middle -center -odds">
      <thead>
      <tr>
        <th>枠番</th>
        <th>馬番</th>
        <th>馬名</th>
        <th>単オッズ</th>
        <th>1着軸</th>
        <th class="ranking">
          相手馬<br/><span class="form-checkbox"
        ><label
        ><input
          type="checkbox"
          @click="checkedAll()"
          v-model="checkAllJudge"
          value=""
          name=""/><i></i></label
        ></span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(bracket, index) in bracketArray"
        :key="index"
        :class="[
            index === 0
              ? bracket.waku === bracketArray[index + 1].waku
                ? '-rows'
                : ''
              : bracket.waku != bracketArray[index - 1].waku
              ? bracket.waku === bracketArray[index + 1].waku
                ? '-rows'
                : ''
              : '',
          ]"
      >
        <td v-if="bracket.rows === 1" :class="'bgcolor-waku0' + bracket.waku">
          {{ bracket.waku }}
        </td>
        <td
          v-else-if="
              (index === 0 && bracket.waku === bracketArray[index + 1].waku) ||
              (index != 0 &&
                bracket.waku != bracketArray[index - 1].waku &&
                bracket.waku === bracketArray[index + 1].waku)
            "
          :class="'bgcolor-waku0' + bracket.waku"
          :rowspan="bracket.rows"
        >
          {{ bracket.waku }}
        </td>

        <td
          :style="[
              bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '',
            ]"
        >
          <i :class="['icon-umaban -waku0' + bracket.waku]">{{
              bracket.horseNum
            }}</i>
        </td>
        <td
          class="name"
          :style="[
              bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '',
            ]"
        >
          {{ bracket.horseName }}
        </td>
        <td
          class="-right"
          :class="[bracket.waku != bracketArray[index + 1] ? '-last' : '']"
          :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '']"
        >
          <div v-if="!oddsInfo[index]"
               class="txt-odds -other">
          </div>
          <div class="txt-odds -other" v-else-if="bracket.abnormalDiv === '1' || bracket.abnormalDiv === '3'">
            <span v-if="bracket.abnormalDiv === '1'">取消</span>
            <span v-else-if="bracket.abnormalDiv === '3'">除外</span>
          </div>
          <div
            v-else
            class="txt-odds"
            :class="oddsClass(oddsInfo[index])">
            <span>{{ oddsInfo[index] }}</span>
          </div>
        </td>
        <td v-if="bracket.rows === 1" :rowspan="bracket.rows"
            :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '']">
            <span class="form-radio">
              <label v-if="!bracket.isCancel  && oddsInfo[index]">
                <input
                  type="radio"
                  v-model="axisHorse"
                  :value="bracket.waku - 1"
                  name=""
                  @click="checkChange(bracket.waku - 1, 'horse1')"/>
                <i></i>
              </label>
            </span>
        </td>
        <td v-else-if="
              (index === 0 && bracket.waku === bracketArray[index + 1].waku) ||
              (index != 0 &&
                bracket.waku != bracketArray[index - 1].waku &&
                bracket.waku === bracketArray[index + 1].waku)
            "
            :rowspan="bracket.rows"
            :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '']"
            class="-row"
            ref="rm">
            <span class="form-radio">
              <label v-if="!bracket.isCancel  && oddsInfo[index]">
                <input
                  type="radio"
                  v-model="axisHorse"
                  :value="bracket.waku - 1"
                  name=""
                  @click="checkChange(bracket.waku - 1, 'horse1')"/>
                <i></i>
              </label>
            </span>
        </td>
        <td v-if="bracket.rows === 1"
            :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '']">
          <span class="form-checkbox">
              <label v-if="!bracket.isCancel  && oddsInfo[index]">
                <input
                  type="checkbox"
                  @click="checkChange(bracket.waku - 1, 'horse2')"
                  v-model="checkList[bracket.waku - 1]"
                  :value="bracket.waku - 1"
                  name=""/>
                <i></i>
              </label>
            </span>
        </td>
        <td
          v-else-if="
              (index === 0 && bracket.waku === bracketArray[index + 1].waku) ||
              (index != 0 &&
                bracket.waku != bracketArray[index - 1].waku &&
                bracket.waku === bracketArray[index + 1].waku)
            "
          :style="[bracket.isCancel || !oddsInfo[index] ? 'background-color:#ccc' : '']"
          class="-row"
          :rowspan="bracket.rows">
            <span class="form-checkbox">
              <label v-if="!bracket.isCancel  && oddsInfo[index]">
                <input
                  type="checkbox"
                  @click="checkChange(bracket.waku - 1, 'horse2')"
                  v-model="checkList[bracket.waku - 1]"
                  :value="bracket.waku - 1"
                  name=""/>
                <i></i>
              </label>
            </span>
        </td>
      </tr>
      </tbody>
    </table>

    <nav class="nav-group -right">
        <span
          @click="deleteSelected"
          class="btn-basic -delete">
          削除
        </span>
    </nav>
    <div class="container-total">
      <div class="form-checkboxgroup -multi">
        <label>
          <input
            type="checkbox"
            value=""
            name="sample1"
            @click="checkedMulti()"
            v-model="checkMulti"/>
          <span>マルチ</span>
        </label>
      </div>
      <dl class="amount">
        <dt>点数合計</dt>
        <dd>
          <span class="num">{{ checkedSum }}</span>点
        </dd>
      </dl>
      <div
        v-show="checkNumInfo.length"
        @click="setRaceBetWin"
        class="btn-basic">
        買い目登録
      </div>
    </div>
    <div class="container-wrapper -pad" v-show="checkNumInfo.length">
      <h3 class="heading-bar _center">{{ wheelHeading }}</h3>
      <table class="table-grid -middle -selectednumber">
        <tr>
          <th class="_nowrap">1着軸</th>
          <td>
            <ul class="list-umaban">
              <li v-if="axisHorse != null">
                <i :class="`icon-umaban -waku0${axisHorse + 1}`">
                  {{ axisHorse + 1 }}
                </i>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th class="_nowrap">相手</th>
          <td>
            <ul class="list-umaban">
              <li v-show="checkListNum()" v-for="c in checkListNum()" :key="c">
                <i :class="`icon-umaban -waku0${c + 1}`">
                  {{ c + 1 }}
                </i>
              </li>
            </ul>
          </td>
        </tr>
      </table>

      <div class="icon-next"></div>

      <table class="table-grid -middle -padding -center">
        <thead>
        <tr>
          <th>
            組み合わせ<i
            class="filter-sort"
            :class="sortType === 3 ? '-asc' : sortType === 4 ? '-desc' : ''"
            @click="changeSort('sort_num')"
          ></i>
          </th>
          <th>
            オッズ<i
            class="filter-sort"
            :class="sortType === 5 ? '-asc' : sortType === 6 ? '-desc' : ''"
            @click="changeSort('sort_odds')"
          ></i>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(conbi, index) in checkNumInfo" :key="index">
          <td>
            <ul class="list-kaimoku -arrow">
              <li>
                <i class="icon-umaban" :class="'-waku0' + conbi.horse1">{{
                    conbi.horse1
                  }}</i>
              </li>
              <li>
                <i class="icon-umaban" :class="'-waku0' + conbi.horse2">{{
                    conbi.horse2
                  }}</i>
              </li>
            </ul>
          </td>
          <td v-if="!conbi.odds" class="txt-odds" :class="oddsClass('---.-')">
            ---.-
          </td>
          <td v-else class="txt-odds" :class="oddsClass(conbi.odds)">
            {{ conbi.odds }}
          </td>
        </tr>
        </tbody>
      </table>

      <div class="container-total">
        <div class="form-checkboxgroup -multi">
          <label
          ><input
            type="checkbox"
            value=""
            name="sample1"
            @click="checkedMulti()"
            v-model="checkMulti"
          /><span>マルチ</span></label
          >
        </div>
        <dl class="amount">
          <dt>点数合計</dt>
          <dd>
            <span class="num">{{ checkedSum }}</span
            >点
          </dd>
        </dl>
        <div @click="setRaceBetWin" class="btn-basic">買い目登録</div>
      </div>
    </div>
    <div class="container-schedule">
      <router-link to="/purchase/spat4/" v-if="isWebView || isSugotokuMember" class="btn-basic -normal">購入予定一覧</router-link>
      <a href="/purchase/spat4/" v-else target="_blank" class="btn-basic -normal">購入予定一覧</a>
      <p class="_caption">
        ※出馬表・結果・成績・オッズなどのデータについては、<br/>必ず主催者発表のものと照合し確認してください。
      </p>
    </div>
  </section>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading"/>
    </div>
  </div>
  <SetBetConfModal
    @closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
    :isSpat4="true"
  ></SetBetConfModal>
</template>

<style
  lang="scss"
  src="../../../assets/css/race/odds/style.scss"
  scoped
></style>
