<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import {getJSON, SUCCESS} from "../../assets/js/common";
import {HOST, PATH, BACKWORDPATH, POGAPIPATH} from "../../assets/js/define";

import PogHeader from "../../components/pog/PogHeader.vue";
import pogFunc from "../../functions/pog.js";
const { loadPogUserJudge, getPogStartDate } = pogFunc();

onMounted(() => {
  loadPogUserJudge(() => {
    init();
  });
});

let rankingHorses = reactive([]);
const year = ref("");
const month = ref("");
const pogSeasonSecond = ref("");
const pogSeasonFirst = ref("");
watch(rankingHorses, (val) => {
  document.body.className = "status-loaded";
});
let init = async () => {
  const pogDate = await getPogStartDate();

  pogSeasonSecond.value = pogDate.second;
  pogSeasonFirst.value = pogDate.first;

  let paramYear, paramMonth;
  let url = window.location.href;
  if (url.indexOf("Year=") > -1) {
    paramYear = url.split("Year=")[1];
    paramYear = paramYear.slice(0, 4);
    year.value = paramYear;
    month.value = paramMonth;
  }

  const request =
    HOST +
    PATH +
    "POG/getPogRanking.js" +
    BACKWORDPATH +
    "?ScreenDiv=4&Year=" +
    paramYear +
    "&Month=" +
    paramMonth;

  getJSON(request, true, function (_, json) {
    rankingHorses = Object.assign(rankingHorses, json.SearchResult);
    createSelectorOption(Number(paramYear));
  });
};

const horseLink = (code) => {
  if (code) {
    return HOST + "pog/" + "user_horse?userId=" + code + "&Year=" + year.value;
  } else return "";
};

let yearOptions = reactive([]);
let createSelectorOption = (year) => {
  let result = "";
  let first = pogSeasonFirst.value;
  let second = pogSeasonSecond.value;
  const yearNumber = Number(first) - 2012;
  for (let i = 0; i < yearNumber; i++) {
    let optionValue = "";
    let optionStr = "";
    first--;
    second--;
    // yearの年度
    optionValue += first + 1;
    optionStr = `${Number(first) + 1}-${Number(second) + 1}`;
    yearOptions.push({ optionValue, optionStr });
  }
  return result;
};

// もっと見る
const showLimit = 30;
let count = ref(30);
const ListItem = () => {
  const list = [...rankingHorses];
  if (list != undefined && list != null && list.length > 0) {
    return list.slice(0, count.value);
  } else {
    return [];
  }
};
const isMore = () => {
  count.value += showLimit;
};
const changeYear = (e) => {
  if (e != null && e.target.value != undefined && e.target.value != null) {
    const year = e.target.value.substring(0, 4);
    let mon = e.target.value.substring(4, 6);
    mon = String(Number(mon)); //一桁の場合0を削除
    const url = `${HOST}pog/past_yearly_ranking?ScreenDiv=4&Year=${year}&Month=5`;
    location.href = url;
  } else {
    alert("セレクターの値が不正です");
  }
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader">
      <h1 class="heading-page">POG ランキング</h1>
      <div class="nav">
        <a href="/pog/mypage" class="btn-basic">マイページへ</a>
      </div>
    </section>

    <section class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">
          <span v-if="year">{{ year }}-{{ Number(year) + 1 }} </span>
          年間ランキング
        </h2>
        <div class="nav">
          <div class="form-select">
            <select @change="changeYear" v-model="year">
              <option
                v-for="yearOption in yearOptions"
                :key="yearOption"
                :value="yearOption.optionValue"
              >
                {{ yearOption.optionStr }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <ul class="list-line -ranking">
        <li v-for="(rankingHorse, index) in ListItem()">
          <a :href="horseLink(rankingHorse.Code)" class="link-cell">
            <span v-if="index === 0" class="ranking"
              ><i class="icon-ranking -no1st"></i
            ></span>
            <span v-else-if="index === 1" class="ranking"
              ><i class="icon-ranking -no2nd"></i
            ></span>
            <span v-else-if="index === 2" class="ranking"
              ><i class="icon-ranking -no3rd"></i
            ></span>
            <span v-else class="ranking"
              ><i>{{ index + 1 }}</i></span
            >
            <span v-if="String(rankingHorse.Name)" class="name"
              >{{ rankingHorse.Name }}
            </span>
            <span v-if="String(rankingHorse.Pts)" class="total"
              >{{ rankingHorse.Pts }}pt</span
            >
          </a>
        </li>
      </ul>

      <div v-if="ListItem().length - count >= 0" class="nav-group">
        <span @click="isMore" class="btn-basic -more">もっと見る</span>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/pog/style.scss" scoped></style>
