import {getJSON, SUCCESS} from '../../assets/js/common';
import {JRA_WP_HOST} from '../../assets/js/define';
import useStore, {useStoreFeatureArticle} from "../../stores/feature-states";
import {storeToRefs} from 'pinia';

const API_URL = JRA_WP_HOST + "wp-json/wp/v2/feature";

export async function fetch(slug) {
  const store = useStoreFeatureArticle();
  const {articleSlug, title, content} = storeToRefs(store);
  articleSlug.value = slug;
  await getJSON(`${API_URL}?slug=${slug}`, true, function (status, json) {
    if (status !== SUCCESS || !json?.length) {
      return;
    }
    title.value = json[0]?.title?.rendered ?? '';
    content.value = json[0]?.content?.rendered ?? '';
  });
}

