import {ref} from 'vue';
import {defineStore} from 'pinia'

export const useStoreTopJra = defineStore('top-jra', () => {
  const wpContents = ref({});
  const userCourse = ref(1);
  return {
    wpContents,
    userCourse,
  }
})

export default {
  useStoreTopJra,
}