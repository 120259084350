<script setup>
import {BACKWORDPATH, HOST, MEDIAPATH} from "../../assets/js/define";
import {getJSON, getUserId} from "../../assets/js/common";

const emit = defineEmits(["openVideoConfirmed"]);
const props = defineProps({
  openMovieConfirm: Boolean
});
const modalClose = () => {
  emit("openVideoConfirmed", false);
};
const confirmed = () => {
  emit("openVideoConfirmed", true);
}
const agreeVideo = () => {
  const request = HOST + MEDIAPATH + "setVidioHistory.js" + BACKWORDPATH + "?userId=" + encodeURIComponent(getUserId());
  getJSON(request, true, function (status, results) {
    confirmed();
  });
}
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="openMovieConfirm" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div @click.self="modalClose" v-if="openMovieConfirm" class="modal-frame">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                レース動画視聴について
              </div>
              <div class="modal-body">
                <div class="modal-message">
                  <p>
                    レース動画の視聴をご希望のお客様は以下の視聴するボタンを押して頂き、レース動画をお楽しみください。<br><br>
                    ※レース動画サイズ<br>
                    約1分～4分（約7MB～35MB）
                  </p>
                </div>
                <div class="modal-nav">
                  <span class="btn-basic -gray close-modal" @click="modalClose">閉じる</span>
                  <span class="btn-basic close-modal" @click="agreeVideo">視聴する</span>
                </div>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
