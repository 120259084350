<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import {
  getUrlVars,
  checkUrlVars,
  getJSON,
  setDismissDialog,
  getUserId,
  SUCCESS,
  isDismissDialog,
  getCourse,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  PAYINGAPPPASS,
  isNar,
  isAndroid,
  getUserAgent,
  scrollFormationFunc
} from "../../../assets/js/common";

import {
  HOST,
  PATH,
  BACKWORDPATH,
  METHOD_FORMATION,
  TOB_QUINELLA,
  RACEPATH,
} from "../../../assets/js/define";

import MyStampModal from "../../../components/utils/MyStampModal.vue";
import SetBetConfModal from "../../../components/utils/SetBetConfModal.vue";
import raceOdds from "../../../functions/odds/race-odds";
import raceDeadline from "../../../functions/odds/race-deadline_countdown";
import myMark from "../../../functions/odds/race-mymark";
import {useRoute} from "vue-router";

const { setRaceBetByArray, sortDoubleOdds } = raceOdds();
const { TimerCount } = raceDeadline();
const { loadMyMarkForDrop2, setMyMark } = myMark();

let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let loadedPage = ref(false)
let oddsUpdInfo = ref("");
const checkedHorse = reactive({});
const currentTab = "bracket";

let params = {};
let pointViewFlag = ref(true)

const route = useRoute();
watch(route, (from, to) => {
  clearSelected();
  loadPromisingBet();
  doInit(to.query);
})

onMounted(() => {
  document.body.className = "";
  params = getUrlVars();
  window.addEventListener('scroll', () => {
    pointViewFlag.value = scrollFormationFunc()
  });
  //パラメータチェック
  if (checkUrlVars(params)) {
    loadPromisingBet()
    doInit(params);
  } else {
    alert("パラメータが不正です。TOP画面からやり直してください。");
  }
});

const props = defineProps({
  raceDORVal: String,
  raceTrackCdVal: String,
  raceNumVal: String,
  deadLineJudge: Boolean,
});

let stamp = reactive({});
const stampClass = (num) => {
  if (stamp[num].selectedNum == 0) {
    return "stamp-mark -none";
  } else if (stamp[num].selectedNum == 1) {
    return "stamp-mark -honmei";
  } else if (stamp[num].selectedNum == 2) {
    return "stamp-mark -taikou";
  } else if (stamp[num].selectedNum == 3) {
    return "stamp-mark -tanana";
  } else if (stamp[num].selectedNum == 4) {
    return "stamp-mark -renka";
  } else if (stamp[num].selectedNum == 5) {
    return "stamp-mark -hoshi";
  } else return "stamp-mark -none";
};
const stampClassTable = (num) => {
  if (num == 0) {
    return "stamp-mark -none";
  } else if (num == 1) {
    return "stamp-mark -honmei";
  } else if (num == 2) {
    return "stamp-mark -taikou";
  } else if (num == 3) {
    return "stamp-mark -tanana";
  } else if (num == 4) {
    return "stamp-mark -renka";
  } else if (num == 5) {
    return "stamp-mark -hoshi";
  } else return "stamp-mark -none";
};
const oddsClass = (val) => {
  if (val == "---.-") {
    return "txt-odds";
  } else if (val > 0 && val < 10) {
    return "txt-odds -no1";
  } else if (val > 0 && val < 100) {
    return "txt-odds -no2";
  } else return "txt-odds -other";
};
//チェックリスト関連
let checkList1 = reactive({});
let checkList2 = reactive({});
let checkedSum1 = ref(0);
let checkedSum2 = ref(0);
let checkAllJudge1 = ref(false);
let checkAllJudge2 = ref(false);
let check1StorageArray = reactive([])
let check2StorageArray = reactive([])

//　選択した馬の組み合わせ表示用
let info = reactive([]);
const onlyChecked = (checkList) => {
  let trueArr = new Array();
  for (let i = 0; i < Object.keys(checkList).length; i++) {
    if (checkList[i] === true) {
      trueArr.push(i + 1);
    }
  }
  return trueArr;
};
const loadPromisingBet = () => {
  const check1ListStorage = localStorage.getItem('quinella_formation_1' + location.search)
  const check2ListStorage = localStorage.getItem('quinella_formation_2' + location.search)
  if(check1ListStorage) check1StorageArray = JSON.parse(check1ListStorage)
  if(check2ListStorage) check2StorageArray = JSON.parse(check2ListStorage)
}
const checkChange = (n, num) => {
  if (n == 1) {
    if (checkList1[num] === true) {
      checkList1[num] = false;
      check1StorageArray?.splice(check1StorageArray?.indexOf(String(num)),1)
    } else {
      checkList1[num] = true;
      check1StorageArray?.push(String(num))
    }
  } else {
    if (checkList2[num] === true) {
      checkList2[num] = false;
      check2StorageArray?.splice(check2StorageArray?.indexOf(String(num)),1)
    } else {
      checkList2[num] = true;
      check2StorageArray?.push(String(num))
    }
  }
  localStorage.setItem('quinella_formation_1' + location.search, JSON.stringify(check1StorageArray))
  localStorage.setItem('quinella_formation_2' + location.search, JSON.stringify(check2StorageArray))
  setSelected(n);
};
let checkNumInfo = reactive([]);
let oddsTable = reactive([]);
const setSelected = (n) => {
  info.splice(0);
  let horse1 = 0;
  let horse2 = 0;
  let conf = 0;
  let odds1 = 0;
  let odds2 = 0;
  checkNumInfo.splice(0);
  Object.keys(checkList1).forEach((f) => {
    if (checkList1[f] == true) {
      Object.keys(checkList2).forEach((s) => {
        if (checkList2[s] == false) {
          return;
        } else if (checkList2[s] == true && Number(f) == Number(s)) {
          return;
        } else if (checkList2[s] == true && Number(f) < Number(s)) {
          horse1 = String(Number(f) + 1);
          horse2 = String(Number(s) + 1);
        } else if (checkList2[s] == true && Number(f) > Number(s)) {
          horse1 = String(Number(s) + 1);
          horse2 = String(Number(f) + 1);
        }

        conf = info.findIndex(
          (i) => i.horse1 === horse1 && i.horse2 === horse2
        );
        if (conf == -1) {
          info.push({
            horse1: horse1,
            horse2: horse2,
            mark1: translateMyMarkToChar(stamp[horse1 - 1].selectedNum),
            mark2: translateMyMarkToChar(stamp[horse2 - 1].selectedNum),
            odds: oddsForTable["Odds" + horse1 + "_" + horse2]
              ? oddsForTable["Odds" + horse1 + "_" + horse2]
              : "---.-",
          });
        }
      });
    }
  });
  checkNumInfo = Object.assign(checkNumInfo, info);
  checkNumInfo.sort(sortDoubleOdds(sortType.value));
  oddsTable.splice(0);
  checkedHorseSum(n);
  setOdds();
};
const isShowMore = ref(false);
const showLimit = 50;
const setOdds = () => {
  //初回は現在表示しているところから、50件分のHTMLを表示
  //もっと見るを押下するごとに+50件表示させる
  const nowOddsTableLength = oddsTable.length;

  for (
    let i = nowOddsTableLength;
    i < checkNumInfo.length && i < nowOddsTableLength + showLimit;
    i++
  ) {
    oddsTable.push(checkNumInfo[i]);
  }
  if (oddsTable.length === info.length) {
    isShowMore.value = false;
  } else {
    isShowMore.value = true;
  }
};
const translateMyMarkToChar = (m) => {
  let result = "-";
  if (m == 1) {
    result = "◎";
  } else if (m == 2) {
    result = "○";
  } else if (m == 3) {
    result = "▲";
  } else if (m == 4) {
    result = "△";
  } else if (m == 5) {
    result = "☆";
  } else {
    result = "-";
  }
  return result;
};
const checkedHorseSum = (n) => {
  let checkedNum = 0;
  if (n == 1) {
    for (let i = 0; i < Object.keys(checkList1).length; i++) {
      if (checkList1[i] === true) {
        checkedNum++;
      }
    }
    checkedSum1.value = checkedNum;
  } else if (n == 2) {
    for (let i = 0; i < Object.keys(checkList2).length; i++) {
      if (checkList2[i] === true) {
        checkedNum++;
      }
    }
    checkedSum2.value = checkedNum;
  }
};
const checkedAll = (n) => {
  if (n == 1) {
    if (checkAllJudge1.value == true) {
      for (let i = 0; i < Object.keys(checkList1).length; i++) {
        checkList1[i] = false;
        check1StorageArray?.splice(check1StorageArray?.indexOf(String(i)),1)
      }
      checkAllJudge1.value = false;
    } else {
      for (let i = 0; i < Object.keys(checkList1).length; i++) {
        if (horses[i].AbnormalDiv === "1" || horses[i].AbnormalDiv === "3" || !horses[i].Odds) {
          //取消・中止はチェックしない
          checkList1[i] = false;
        } else {
          checkList1[i] = true;
          if (check1StorageArray?.includes(String(i))) continue
          check1StorageArray?.push(String(i))
        }
      }
      checkAllJudge1.value = true;
    }
    localStorage.setItem('quinella_formation_1' + location.search, JSON.stringify(check1StorageArray))
    checkedHorseSum(1);
  } else {
    if (checkAllJudge2.value == true) {
      for (let i = 0; i < Object.keys(checkList2).length; i++) {
        checkList2[i] = false;
        check2StorageArray?.splice(check2StorageArray?.indexOf(String(i)),1)
      }
      checkAllJudge2.value = false;
    } else {
      for (let i = 0; i < Object.keys(checkList2).length; i++) {
        if (horses[i].AbnormalDiv === "1" || horses[i].AbnormalDiv === "3" || !horses[i].Odds) {
          //取消・中止はチェックしない
          checkList2[i] = false;
        } else {
          checkList2[i] = true;
          if (check2StorageArray?.includes(String(i))) continue
          check2StorageArray?.push(String(i))
        }
      }
      checkAllJudge2.value = true;
    }
    localStorage.setItem('quinella_formation_2' + location.search, JSON.stringify(check2StorageArray))
    checkedHorseSum(2);
  }
  setSelected(n);
};
// チェックリストに全てチェックがついてたら自動で全てにチェックをつける。一つでも外すと全てのチェックが外れる
watch(checkList1,() => {
  const checkLists = Object.values(checkList1)
  if(checkLists.length) checkAllJudge1.value = checkLists.find(check => check === false) === undefined
},{immediate:true})
watch(checkList2,() => {
  const checkLists = Object.values(checkList2)
  if(checkLists.length) checkAllJudge2.value = checkLists.find(check => check === false) === undefined
},{immediate:true})
// modal
let left = ref("");
let top = ref("");
const openModal = (event, win) => {
  document.body.className = "status-loaded status-haslayerbg status-showstamp";
  // x座標
  left.value = event.pageX;
  // y座標
  top.value = event.pageY;
  stamp[win - 1].status = true;
};
const closeStampModal = (win) => {
  document.body.className = "status-loaded";
  if (stamp[win.num].selectedNum != win.selectedNum) {
    //my印を新しくセットした場合は、my印を更新する
    stamp[win.num].selectedNum = win.selectedNum;
    setMyMark(
      getUserId(),
      props.raceDORVal,
      props.raceTrackCdVal,
      props.raceNumVal,
      win.num + 1,
      stamp[win.num].selectedNum
    );
  }
  stamp[win.num].status = false;
  setSelected(0);
};
// horses
let horses = reactive([]);
let mark = reactive({});
let marks = reactive({});
let oddsForTable = reactive({});
const doInit = async (params) => {
  marks = await loadMyMarkForDrop2(
    getUserId(),
    params.DOR,
    params.RacetrackCd,
    params.RaceNum
  );
  loadCandidacyTableForOddsBasic(
    params.DOR,
    params.RacetrackCd,
    params.RaceNum,
    (result) => {
      // オッズ
      loadOddsWin(params.DOR, params.RacetrackCd, params.RaceNum);
    }
  );
  // 組み合わせのオッズ
  retrieveOdds(params.DOR, params.RacetrackCd, params.RaceNum, (result) => {
    oddsForTable = Object.assign(oddsForTable, result);
  });


};
const loadCandidacyTableForOddsBasic = (
  dor,
  racetrackCd,
  raceNum,
  callback
) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "RaceInfo/candidacyTable.js" +
    BACKWORDPATH +
    "?DOR=" +
    dor +
    "&RacetrackCd=" +
    racetrackCd +
    "&RaceNum=" +
    raceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == "success") {
      horses = Object.assign(horses, json["EntryHorses"]);
      //my印及びチェックボックスをデフォルト値をセット

      for (let i = 0; i < horses.length; i++) {
        let selectNum = 0;
        if (
          marks &&
          marks["MyNo" + (i + 1)] != undefined &&
          marks["MyNo" + (i + 1)] != "" &&
          marks["MyNo" + (i + 1)] != null
        ) {
          selectNum = marks["MyNo" + (i + 1)];
        }

        stamp[i] = { status: false, selectedNum: selectNum };
        checkList1[i] = check1StorageArray?.includes(String(i)) ? true : false;
        checkList2[i] = check2StorageArray?.includes(String(i)) ? true : false;
      }
      setSelected(1);
      setSelected(2);
    }
    if (callback != undefined) {
      callback();
    }
  });
};

const retrieveOdds = (dor, racetrackCd, raceNum, callback) => {
  const jsName = "quinellaOdds.js";

  try {
    const req =
      HOST +
      PATH +
      "Odds/" +
      jsName +
      BACKWORDPATH +
      "?DOR=" +
      dor +
      "&RacetrackCd=" +
      racetrackCd +
      "&RaceNum=" +
      raceNum +
      "&NarFlag=" +
      isNar();
    getJSON(req, true, function (status, json) {
      if (status == SUCCESS) {
        //データは常に１件が正しい。
        if (json.length == 1) {
          oddsUpdInfo.value = json[0]["OddsUpdInfo"];
          if (callback != undefined) {
            callback(json[0]);
          }
        } else {
          if (callback != undefined) {
            callback({});
          }
        }
      } else {
        if (callback != undefined) {
          callback({});
        }
      }
    });
  } catch (e) {
    if (callback != undefined) {
      callback({});
    }
  }
};
//オッズを取得する
let oddsWin = reactive([]);
const loadOddsWin = (DOR, RacetrackCd, RaceNum) => {
  //パラメータからAPIコールを生成
  const req =
    HOST +
    PATH +
    "Odds/winTicketOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    DOR +
    "&RacetrackCd=" +
    RacetrackCd +
    "&RaceNum=" +
    RaceNum +
    "&NarFlag=" +
    isNar();
  getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      //データは常に１件が正しい。
      if (json.length == 1) {
        const displayData = json[0];
        // オッズを格納
        for (let i = 0; i < horses.length; i++) {
          horses[i].Odds = displayData["Odds" + (i + 1)];
        }
      } else {
        location.href = HOST +
            RACEPATH +
            "card?DOR=" +
            DOR +
            "&RacetrackCd=" +
            RacetrackCd +
            "&RaceNum=" +
            RaceNum;
      }
    }
    loadedPage.value = true
    document.body.className = "status-loaded";
  });
};

// 買い目登録
const setRaceBetWin = () => {
  let setRaceBetParams = getUrlVars();
  let openWindow
  if (isDismissDialog() == 1 && !isAndroid() && !getUserAgent()) {
    openWindow = window.open('', '_blank')
  }

  if (props.raceDORVal != null) {
    setRaceBetParams["DOR"] = props.raceDORVal;
  }
  if (props.raceTrackCdVal != null) {
    setRaceBetParams["RacetrackCd"] = props.raceTrackCdVal;
  }
  if (props.raceNumVal != null) {
    setRaceBetParams["RaceNum"] = props.raceNumVal;
  }
  let mainChekFlg = "";
  let isFirst = false;
  let isSecond = false;

  // 1着馬
  for (let i = 0; i < 18; i++) {
    if (checkList1[i] === true) {
      mainChekFlg += "1";
      isFirst = true;
    } else {
      mainChekFlg += "0";
    }
  }

  // 2着馬
  for (let i = 0; i < 18; i++) {
    if (checkList2[i] === true) {
      mainChekFlg += "1";
      isSecond = true;
    } else {
      mainChekFlg += "0";
    }
  }
  if (isFirst && isSecond) {
    let betArray = new Array();
    let doFlgArray = new Array();
    let methodType = METHOD_FORMATION;
    betArray.push(mainChekFlg);
    doFlgArray.push(true);
    //順番に登録させるため再帰的に登録
    setRaceBetByArray(
      setRaceBetParams,
      TOB_QUINELLA,
      methodType,
      betArray,
      doFlgArray,
      function () {
        if (isDismissDialog() != 1) {
          // AppPassはダイアログ非表示
          if (getCourse() == PAYINGAPPPASS) {
            //ダイアログ非表示フラグON
            setDismissDialog();
          } else {
            //モーダルウィンドウ表示
            raceBetCheck.value = true;
            modalBetOpen.value = true;
          }
        } else {
          let url = HOST + "purchase";
          if (
            (getCourse() == PAYINGSUGOTOKU ||
            getCourse() == PAYINGSUGOTOKU_NONE) && !getUserAgent()
          ) {
            url = location.pathname + "?url=" + HOST + "purchase";
          }

          if (isAndroid() || getUserAgent()) {
            location.href = url;
          }
          else { openWindow.location.href = url }
        }
      }
    );
    check1StorageArray.splice(0)
    check2StorageArray.splice(0)
    localStorage.removeItem('quinella_formation_1' + location.search)
    localStorage.removeItem('quinella_formation_2' + location.search)
  } else {
    alert("1着馬と2着馬に有効な馬番を設定してください");
  }
};

// 買い目削除
const deleteSelected = () => {
  const result = confirm('馬連/フォーメーションに対して全ての買い目を削除しますか？')
  if (result) {
    clearStorage()
    clearSelected()
  }
}

const clearStorage = () => {
  localStorage.removeItem('quinella_formation_1' + location.search)
  localStorage.removeItem('quinella_formation_2' + location.search)
}

const clearSelected = () => {
  for (let i = 0; i < Object.keys(checkList1).length; i++) {
    checkList1[i] = false;
  }
  for (let i = 0; i < Object.keys(checkList2).length; i++) {
    checkList2[i] = false;
  }
  check1StorageArray.splice(0)
  check2StorageArray.splice(0)
  checkAllJudge1.value = false
  checkAllJudge2.value = false
  setSelected(1);
  setSelected(2);
}

//ソートを変更
const activeSortId = ref("sort_num");
const sortType = ref(3);

const changeSort = (id) => {
  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_my") {
      if (sortType.value === 2) {
        sortType.value = 1;
      } else {
        sortType.value = 2;
      }
      activeSortId.value = "sort_my";
    } else if (id === "sort_num") {
      if (sortType.value === 3) {
        sortType.value = 4;
      } else {
        sortType.value = 3;
      }
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      if (sortType.value === 5) {
        sortType.value = 6;
      } else {
        sortType.value = 5;
      }
      activeSortId.value = "sort_odds";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを昇順でセットする
    if (id === "sort_my") {
      sortType.value = 1;
      activeSortId.value = "sort_my";
    } else if (id === "sort_num") {
      sortType.value = 3;
      activeSortId.value = "sort_num";
    } else if (id === "sort_odds") {
      sortType.value = 5;
      activeSortId.value = "sort_odds";
    }
  }
  setSelected(0);
};
</script>

<template>
  <section class="layout-section" v-if="loadedPage">
    <div class="nav-odds" v-if="pointViewFlag && info.length > 0">
      <dl>
        <dt>点数合計</dt>
        <dd>{{ info.length }}<span class="unit">点</span></dd>
      </dl>
    </div>
    <div class="layout-title">
      <h2 class="heading-circle -padding">馬連 / フォーメーション</h2>
      <div class="nav">
        <span v-if="props.deadLineJudge" class="status">締め切り間近</span>
        <span :class="[oddsUpdInfo ? 'icon-kakutei' : '']">{{
          oddsUpdInfo
        }}</span>
      </div>
    </div>
    <table class="table-grid -fit -middle -center -odds">
      <thead>
        <tr>
          <th class="mystamp">my印</th>
          <th>馬番</th>
          <th>馬名</th>
          <th>単オッズ</th>
          <th class="ranking">
            1着馬<br /><span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  value=""
                  name=""
                  @click="checkedAll(1)"
                  v-model="checkAllJudge1" /><i></i></label
            ></span>
          </th>
          <th class="ranking">
            2着馬<br /><span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  value=""
                  name=""
                  @click="checkedAll(2)"
                  v-model="checkAllJudge2" /><i></i></label
            ></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="h in horses"
          :key="h.horseNum"
          :class="[
            h.AbnormalDiv === '1' ? '-cancel' : '',
            h.AbnormalDiv === '3' ? '-cancel' : '',
            h.Odds ? '' : '-cancel',
          ]"
        >
          <td class="mystamp">
            <span
              :class="stampClass(h.HorseNum - 1)"
              @click="openModal($event, h.HorseNum)"
            ></span>
            <MyStampModal
              @closeModal="closeStampModal($event)"
              v-if="stamp[h.HorseNum - 1].status === true"
              :top="top"
              :left="left"
              :stampNum="h.HorseNum - 1"
              :selectStamp="stamp[h.HorseNum - 1].selectedNum"
            />
          </td>
          <td>
            <i
              :class="[
                h.BracketNum < 10
                  ? `icon-umaban -waku0${h.BracketNum}`
                  : `icon-umaban -waku${h.BracketNum}`,
              ]"
              >{{ h.HorseNum }}</i
            >
          </td>
          <td class="name">{{ h.HorseName }}</td>
          <td class="-right">
            <div class="txt-odds -other" v-if="h.AbnormalDiv === '1' || h.AbnormalDiv === '3'">
              <span v-if="h.AbnormalDiv === '1'">取消</span>
              <span v-else>除外</span>
            </div>
            <div v-else class="txt-odds" :class="oddsClass(h.Odds)">
              {{ h.Odds }}
            </div>
          </td>
          <td>
            <span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  @click="checkChange(1, h.HorseNum - 1)"
                  v-model="checkList1[h.HorseNum - 1]"
                  value=""
                  name="" /><i></i></label
            ></span>
          </td>
          <td>
            <span class="form-checkbox"
              ><label
                ><input
                  type="checkbox"
                  @click="checkChange(2, h.HorseNum - 1)"
                  v-model="checkList2[h.HorseNum - 1]"
                  value=""
                  name="" /><i></i></label
            ></span>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="nav-group -right">
      <span
        @click="deleteSelected"
        class="btn-basic -delete"
        >削除</span
      >
    </nav>  
    <div class="container-total">
      <dl class="amount">
        <dt>点数合計</dt>
        <dd>
          <span class="num">{{ info.length }}</span
          >点
        </dd>
      </dl>
      <div
        v-show="checkNumInfo.length"
        @click="setRaceBetWin"
        class="btn-basic"
      >
        買い目登録
      </div>
    </div>
    <div class="container-wrapper -pad" v-show="checkNumInfo.length">
      <h3 class="heading-bar _center">フォーメーション　選択馬番</h3>
      <table class="table-grid -middle -selectednumber">
        <tr>
          <th class="_nowrap">1頭目</th>
          <td>
            <ul class="list-umaban">
              <li v-for="a in onlyChecked(checkList1)" :key="a">
                <i
                  :class="[
                    horses[a - 1].BracketNum < 10
                      ? `icon-umaban -waku0${horses[a - 1].BracketNum}`
                      : `icon-umaban -waku${horses[a - 1].BracketNum}`,
                  ]"
                  >{{ a }}
                </i>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th class="_nowrap">2頭目</th>
          <td>
            <ul class="list-umaban">
              <li v-for="a in onlyChecked(checkList2)" :key="a">
                <i
                  :class="[
                    horses[a - 1].BracketNum < 10
                      ? `icon-umaban -waku0${horses[a - 1].BracketNum}`
                      : `icon-umaban -waku${horses[a - 1].BracketNum}`,
                  ]"
                  >{{ a }}</i
                >
              </li>
            </ul>
          </td>
        </tr>
      </table>

      <div class="icon-next"></div>

      <table class="table-grid -middle -padding -center">
        <thead>
          <tr>
            <th>
              my印<i
                class="filter-sort"
                :class="sortType === 1 ? '-asc' : sortType === 2 ? '-desc' : ''"
                @click="changeSort('sort_my')"
              ></i>
            </th>
            <th>
              馬番<i
                class="filter-sort"
                :class="sortType === 3 ? '-asc' : sortType === 4 ? '-desc' : ''"
                @click="changeSort('sort_num')"
              ></i>
            </th>
            <th>
              オッズ<i
                class="filter-sort"
                :class="sortType === 5 ? '-asc' : sortType === 6 ? '-desc' : ''"
                @click="changeSort('sort_odds')"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody v-for="i in oddsTable" :key="i">
          <tr>
            <td>
              <ul class="list-kaimoku">
                <li>
                  <span
                    :class="
                      stampClassTable(stamp[Number(i.horse1) - 1].selectedNum)
                    "
                  ></span>
                </li>
                <li>
                  <span
                    :class="
                      stampClassTable(stamp[Number(i.horse2) - 1].selectedNum)
                    "
                  ></span>
                </li>
              </ul>
            </td>
            <td>
              <ul class="list-kaimoku">
                <li>
                  <i
                    :class="[
                      horses[Number(i.horse1) - 1].BracketNum < 10
                        ? `icon-umaban -waku0${
                            horses[Number(i.horse1) - 1].BracketNum
                          }`
                        : `icon-umaban -waku${
                            horses[Number(i.horse1) - 1].BracketNum
                          }`,
                    ]"
                    >{{ i.horse1 }}</i
                  >
                </li>
                <li>
                  <i
                    :class="[
                      horses[Number(i.horse2 - 1)].BracketNum < 10
                        ? `icon-umaban
                      -waku0${horses[Number(i.horse2 - 1)].BracketNum}`
                        : `icon-umaban
                      -waku${horses[Number(i.horse2 - 1)].BracketNum}`,
                    ]"
                    >{{ i.horse2 }}</i
                  >
                </li>
              </ul>
            </td>
            <td
              v-if="i.odds === 999999999"
              class="-right"
              :class="oddsClass('---.-')"
            >
              ---.-
            </td>
            <td v-else class="-right" :class="oddsClass(i.odds)">
              {{ i.odds }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="isShowMore" class="nav-group">
        <span class="btn-basic" @click="setOdds"> もっと見る </span>
      </div>

      <div class="container-total -noborder">
        <dl class="amount">
          <dt>点数合計</dt>
          <dd>
            <span class="num">{{ info.length }}</span
            >点
          </dd>
        </dl>
        <!-- <div class="btn-basic -disable">買い目登録</div> -->
        <div @click="setRaceBetWin" class="btn-basic">買い目登録</div>
      </div>
    </div>
    <div class="container-schedule">
      <a href="/purchase/" target="_blank" class="btn-basic -normal">購入予定一覧</a>
      <p class="_caption">
        ※出馬表・結果・成績・オッズなどのデータについては、<br />必ず主催者発表のものと照合し確認してください。
      </p>
    </div>
  </section>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
  <SetBetConfModal
    v-on:closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
  ></SetBetConfModal>
</template>

<style
  lang="scss"
  src="../../../assets/css/race/odds/style.scss"
  scoped
></style>
