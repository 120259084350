<script setup>
import { ref, onMounted } from "vue";
import { prizeToString, isNar } from "../../assets/js/common";

const props = defineProps({
  leadingList: Array,
  type: String
});

const narFlag = ref(0);

onMounted(() => {
  narFlag.value = isNar();
})

const getPrize = (prize) => {
  return prizeToString(prize)
}

const getTitle = (type) => {
  const titles = {
    jockey: '騎手',
    jockey_weightloss: '減量騎手',
    trainer: '調教師',
    owner:'馬主',
    breeder: '生産者',
    stud: '種牡馬',
    new_stallion: '新種牡馬',
    bms: '母の父'
  }
  return titles[type] ?? '';
}
</script>
<template>
  <div class="layout-title">
    <h2 class="heading-circle -padding">{{ getTitle(type) }}</h2>
    <div class="nav">
      <router-link :to="'/leading/' + type" class="btn-basic">一覧へ</router-link>
    </div>
  </div>
  <ul class="list-line -dotted" id="jockey_winner_0">
    <li v-if="leadingList.length" v-for="(item, index) in leadingList" :key="index">
      <div class="list-wins">
        <div class="item">
          <i :class="`icon-prize -no${item.Order}`"></i>
        </div>
        <div class="item">
          <router-link :to="item.Url">
            <span :class="[item.Mark ? `icon-weight ${item.Mark}` : '' ]">
              {{ item.Name }}
            </span>
            <span v-if="narFlag && item.Syozoku && !item.Mark">
              （{{ item.Syozoku }}）
            </span>
          </router-link>
          <template v-if="type === 'jockey_weightloss'">
            <span class="meta">
              ({{ item.LicenseDate }}/{{ item.Syozoku }})
            </span>
            <span class="meta">
              {{ item.CareerWins }}
            </span>
          </template>
        </div>
        <div class="item -winner">
          <ul class="list-numofwins" v-if="item.Place1st">
            <li>{{ item.Place1st }}</li>
            <li>{{ item.Place2nd }}</li>
            <li>{{ item.Place3rd }}</li>
            <li>{{ item.Unplaced }}</li>
          </ul>
          <div class="p-prizemoney">{{ getPrize(item.TotalEarnings) }}</div>
        </div>
      </div>
    </li>
  </ul>
</template>

<style lang="scss" src="../../assets/css/leading/style.scss" scoped></style>
