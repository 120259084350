import {getJSON, isNar, SUCCESS} from '../../assets/js/common';
import {
  JRA_HOST,
  NAR_HOST,
  NEWSIMAGEPATH,
} from '../../assets/js/define';
import {useStoreNewsArticle} from "../../stores/news/article-states";
import {useStoreCommon} from "../../stores/common-states";
import {storeToRefs} from 'pinia';

export async function fetch(fileCreateDate, seqNo, page = '1') {
  const storeCommon = useStoreCommon();
  let host = '';
  let apiUrl = '';

  if(storeCommon.isNarSite) {
    host = NAR_HOST;
    apiUrl = `${host}webapi/getNewsDetail.js/webapi?`
      + `fileCreateDate=${fileCreateDate}&seqNo=${seqNo}&page=${page}&narFlag=1`;
  } else {
    host = JRA_HOST;
    apiUrl = `${host}webapi/getNewsDetail.js/webapi?`
      + `fileCreateDate=${fileCreateDate}&seqNo=${seqNo}&page=${page}&narFlag=0`;
  }
  const storeArticle = useStoreNewsArticle();
  const {
    fileCreateDate: storeFileCreateDate,
    seqNo: storeSeqNo,
    page: storePage,
    article,
    snsShareLink,
    snsShareTitle
  } = storeToRefs(storeArticle);

  storeFileCreateDate.value = fileCreateDate;
  storeSeqNo.value = seqNo;
  storePage.value = page;

  await getJSON(apiUrl, true, function (status, json) {
    if (status !== SUCCESS) {
      return;
    }
    if (!json?.Contents) {
      console.log('No Contents');
      return;
    }
    try {
      //リンクの生成
      article.value = {
        ...json,
        Path: json.ImageFileURL ? `${host}${NEWSIMAGEPATH}${json.ImageFileURL}` : ''
      }
      snsShareLink.value = encodeURIComponent(`${host}news/article?FileCreateDate=${fileCreateDate}&SeqNo=${seqNo}&Page=${page}`);
      snsShareTitle.value = `${json.Headline}｜WIN!競馬`;
    } catch (e) {
      console.log(e);
    }
  });
}
