<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import {
  getJSON,
  getUserId,
  getUlrParams,
  getMenmerCourse,
  SUCCESS,
  setCookie,
  getExpirationdate03,
  getExpirationdate06,
  getExpirationdate12,
  date2StringTypeNextDay,
  getJoinStatus,
  JOINSTATUS,
  EXPIRATIONDATE03,
  EXPIRATIONDATE06,
  EXPIRATIONDATE12,
  PAYINGNONE,
  retrieveCourse,
  userSettingURL,
} from "../../assets/js/common";
import { HOST, PATH, BACKWORDPATH, JOINPATH } from "../../assets/js/define";
import ChoicePackCourseModal from "../../components/utils/ChoicePackCourseModal.vue";

const changeForCredit = ref(false);
const changeForCarrer = ref(false);
const changeForCreditMail = ref(false);
const changeForCarrerMail = ref(false);
const planChange = ref(true);
const cardChange = ref(true);
const mailAddressChange = ref(true);
const passwordChange = ref(true);

const changeAccountLink = "alterMailaddress";
const changeCardLink = "alterCredit";
const changePasswordLink = "alterPassword";
const changeCreditMailLink = "changeCreditMail";
const changeCareerEconomyLink = "changeCareerEconomy";
const changeCareerPremiumLink = "changeCareerPremium";
const changeCreditEconomyLink = "changeCreditEconomy";
const changeCreditPremiumLink = "changeCreditPremium";

const isPack03 = ref(0);
const isPack06 = ref(0);
const isPack12 = ref(0);

let joinCreditPack12 = "joinCreditPack12";
let joinCreditPack06 = "joinCreditPack06";
let joinCreditPack03 = "joinCreditPack03";

const joinPack12Flag = ref(0);
const joinPack06Flag = ref(0);
const joinPack03Flag = ref(0);

const errFlag = ref(0);
const errMessage = ref("");

const myPlan = ref([]);

const modalOpen = ref(false);

const packRuleLink = HOST + "member/pack_rule";

const changeForCreditLink = ref("");

const changeForCarrerLink = ref("");

function confirmPlan() {
  const req = HOST + PATH + "getLoginInfo.js" + BACKWORDPATH;
  getJSON(req, true, (status, json) => {
    if (status == SUCCESS) {
      if (json.paymentMethod === "mobile") {
        planChange.value = false;
        cardChange.value = false;
        mailAddressChange.value = false;
        passwordChange.value = false;

        errFlag.value = 1;
      }
    }
  });

  let joinStatus = getJoinStatus();

  let joinStatusArr = joinStatus.split(",");

  let numberStatus = 1;
  let numberDispStr = "";

  let expirationdate03 = getExpirationdate03();
  let expirationdate06 = getExpirationdate06();
  let expirationdate12 = getExpirationdate12();

  let nextpaydate03 = date2StringTypeNextDay(expirationdate03);
  let nextpaydate06 = date2StringTypeNextDay(expirationdate06);
  let nextpaydate12 = date2StringTypeNextDay(expirationdate12);

  if (3 <= joinStatusArr.length) {
    numberDispStr = "1";
  }
  for (let i = 0; i < joinStatusArr.length; i++) {
    if (numberStatus >= 2) {
      numberDispStr = numberStatus + "";
    }

    if (joinStatusArr[i] === "1") {
      numberStatus++;

      myPlan.value.push({
        registrationCourse: "エコノミーコース　（月額３３０円・税込）",
        paymentMethod: "キャリア決済　（毎月１日課金) ",
      });
    } else if (joinStatusArr[i] === "2") {
      numberStatus++;

      myPlan.value.push({
        registrationCourse: "プレミアム機能追加コース　（月額７７０円・税込）",
        paymentMethod: "キャリア決済　（毎月１日課金) ",
      });
    } else if (joinStatusArr[i] === "3") {
      numberStatus++;

      myPlan.value.push({
        registrationCourse: "プレミアムコース　（月額１１００円・税込）",
        paymentMethod: "キャリア決済　（毎月１日課金) ",
      });
    } else if (joinStatusArr[i] === "4") {
      numberStatus++;

      myPlan.value.push({
        registrationCourse: "プレミアム機能追加コーストライアル　（月額７７０円・税込）",
        paymentMethod: "キャリア決済　（毎月１日課金) ",
      });
    } else if (joinStatusArr[i] === "5") {
      numberStatus++;

      myPlan.value.push({
        registrationCourse: "プレミアムコーストライアル　（月額１１００円・税込）",
        paymentMethod: "キャリア決済　（毎月１日課金) ",
      });
    } else if (joinStatusArr[i] === "10") {
      if (
        joinStatusArr.indexOf("11") === -1 &&
        joinStatusArr.indexOf("12") === -1 &&
        joinStatusArr.indexOf("13") === -1 &&
        joinStatusArr.indexOf("21") === -1 &&
        joinStatusArr.indexOf("22") === -1 &&
        joinStatusArr.indexOf("23") === -1
      ) {
        numberStatus++;
        myPlan.value.push({
          registrationCourse: "メール会員コース　（無料）",
          paymentMethod: "",
        });
      }
    } else if (joinStatusArr[i] === "11") {
      numberStatus++;

      myPlan.value.push({
        registrationCourse: "エコノミーコース　（月額３３０円・税込）",
        paymentMethod: "クレジットカード決済　（毎月１日課金）",
      });
    } else if (joinStatusArr[i] === "12") {
      numberStatus++;

      myPlan.value.push({
        registrationCourse: "プレミアムコース　（月額１１００円・税込）",
        paymentMethod: "クレジットカード決済　（毎月１日課金）",
      });
    } else if (joinStatusArr[i] === "13") {
      numberStatus++;

      myPlan.value.push({
        registrationCourse: "プレミアムコーストライアル　（月額１１００円・税込）",
        paymentMethod: "クレジットカード決済　（毎月１日課金）",
      });
    } else if (joinStatusArr[i] === "21") {
      numberStatus++;

      isPack03.value = 1;
      myPlan.value.push({
        registrationCourse: "3ヶ月パックコース",
        paymentMethod:
          "クレジットカード決済  （次回課金日" + nextpaydate03 + "）",
      });
    } else if (joinStatusArr[i] === "22") {
      numberStatus++;

      isPack06.value = 1;
      myPlan.value.push({
        registrationCourse: "6ヶ月パックコース",
        paymentMethod:
          "クレジットカード決済  （次回課金日" + nextpaydate06 + "）",
      });
    } else if (joinStatusArr[i] === "23") {
      numberStatus++;

      isPack12.value = 1;
      myPlan.value.push({
        registrationCourse: "12ヶ月パックコース",
        paymentMethod:
          "クレジットカード決済  （次回課金日" + nextpaydate12 + "）",
      });
    }
  }

  if (joinStatusArr.indexOf("11") >= 0 && joinStatusArr.indexOf("12") >= 0) {
    errFlag.value = 2;
    errMessage.value =
      "クレジット決済のプレミアムコースとエコノミーコースに重複入会されております。";
  } else if (
    joinStatusArr.indexOf("11") >= 0 &&
    (joinStatusArr.indexOf("21") >= 0 ||
      joinStatusArr.indexOf("22") >= 0 ||
      joinStatusArr.indexOf("23") >= 0)
  ) {
    errFlag.value = 2;
    errMessage.value =
      "クレジット決済のエコノミーコースとパックコースに重複入会されております。";
  } else if (
    (joinStatusArr.indexOf("12") >= 0 || joinStatusArr.indexOf("13") >= 0) &&
    (joinStatusArr.indexOf("21") >= 0 ||
      joinStatusArr.indexOf("22") >= 0 ||
      joinStatusArr.indexOf("23") >= 0)
  ) {
    errFlag.value = 2;
    errMessage.value =
      "クレジット決済のプレミアムコースとパックコースに重複入会されております。";
  } else if (
    (joinStatusArr.indexOf("21") >= 0 && joinStatusArr.indexOf("22") >= 0) ||
    (joinStatusArr.indexOf("21") >= 0 && joinStatusArr.indexOf("23") >= 0) ||
    (joinStatusArr.indexOf("22") >= 0 && joinStatusArr.indexOf("23") >= 0)
  ) {
    errFlag.value = 2;
    errMessage.value = "クレジット決済のパックコースに重複入会されております。";
  } else if (
    (joinStatusArr.indexOf("11") >= 0 ||
      joinStatusArr.indexOf("12") >= 0 ||
      joinStatusArr.indexOf("13") >= 0 ||
      joinStatusArr.indexOf("21") >= 0 ||
      joinStatusArr.indexOf("22") >= 0 ||
      joinStatusArr.indexOf("23") >= 0) &&
    (joinStatusArr.indexOf("1") >= 0 ||
      joinStatusArr.indexOf("2") >= 0 ||
      joinStatusArr.indexOf("3") >= 0 ||
      joinStatusArr.indexOf("4") >= 0 ||
      joinStatusArr.indexOf("5") >= 0)
  ) {
    errFlag.value = 2;
    errMessage.value =
      "クレジット決済とキャリア決済の有料コースに入会されております。";
  } else {
    if (joinStatusArr.indexOf("10") >= 0) {
      cardChange.value = true;
      mailAddressChange.value = true;
      passwordChange.value = true;

      if (
        joinStatusArr.indexOf("21") >= 0 ||
        joinStatusArr.indexOf("22") >= 0 ||
        joinStatusArr.indexOf("23") >= 0
      ) {
        changeForCredit.value = false;
        changeForCreditMail.value = false;
        changeForCarrer.value = false;
        changeForCarrerMail.value = false;
      } else if (
        joinStatusArr.indexOf("1") >= 0 ||
        joinStatusArr.indexOf("2") >= 0 ||
        joinStatusArr.indexOf("3") >= 0 ||
        joinStatusArr.indexOf("4") >= 0 ||
        joinStatusArr.indexOf("5") >= 0
      ) {
        changeForCredit.value = false;
        changeForCreditMail.value = false;
        changeForCarrer.value = false;
        changeForCarrerMail.value = true;

        cardChange.value = false;
        mailAddressChange.value = false;
        passwordChange.value = false;
      } else if (
        joinStatusArr.indexOf("11") >= 0 ||
        joinStatusArr.indexOf("12") >= 0
      ) {
        changeForCredit.value = true;
        changeForCreditMail.value = false;
        changeForCarrer.value = false;
        changeForCarrerMail.value = false;
      } else {
        changeForCredit.value = false;
        changeForCreditMail.value = true;
        changeForCarrer.value = false;
        changeForCarrerMail.value = false;
      }
    } else {
      changeForCredit.value = false;
      changeForCreditMail.value = false;
      changeForCarrer.value = true;
      changeForCarrerMail.value = false;
    }
  }

  if (joinStatusArr.indexOf("12") >= 0 || joinStatusArr.indexOf("13") >= 0) {
    changeForCreditLink.value = "changeCareerPremium";
  } else if (joinStatusArr.indexOf("11") >= 0) {
    changeForCreditLink.value = "changeCareerEconomy";
  }

  if (
    joinStatusArr.indexOf("2") >= 0 ||
    joinStatusArr.indexOf("3") >= 0 ||
    joinStatusArr.indexOf("4") >= 0 ||
    joinStatusArr.indexOf("5") >= 0
  ) {
    changeForCarrerLink.value = "changeCreditPremium";
  } else if (
    joinStatusArr.indexOf("1") >= 0 &&
    joinStatusArr.indexOf("4") == -1 &&
    joinStatusArr.indexOf("5") == -1
  ) {
    changeForCarrerLink.value = "changeCreditEconomy";
  }
}

const isCurrentTab = ref("confirmPlan");
function tabChange(item) {
  isCurrentTab.value = item;
}

onMounted(() => {
  callRetrieveCourse(function (courseId) {
    if (courseId === PAYINGNONE) {
      location.href = HOST + JOINPATH;
    } else {
      confirmPlan();
    }
  });
});

const callRetrieveCourse = (callback) => {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none", false);
  }
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">会員情報 設定</h1>
    </section>

    <section class="layout-section" data-tab='["data-trigger", "data-target"]'>
      <nav class="nav-multi -cols3">
        <ul class="list" data-trigger="">
          <li
            @click="tabChange('confirmPlan')"
            :class="{
              '-current': isCurrentTab === 'confirmPlan',
            }"
          >
            <span class="label">プラン確認</span>
          </li>
          <li
            @click="tabChange('changePlan')"
            :class="{ '-current': isCurrentTab === 'changePlan' }"
            :style="[planChange === false ? 'display:none' : '']"
          >
            <span class="label">プラン変更</span>
          </li>
          <li
            class=""
            :style="[mailAddressChange === false ? 'display:none' : '']"
          >
            <a @click="userSettingURL(changeAccountLink)" class="label -window"
              >アカウント変更</a
            >
          </li>
          <li class="" :style="[cardChange === false ? 'display:none' : '']">
            <a @click="userSettingURL(changeCardLink)" class="label -window"
              >利用カード変更</a
            >
          </li>
          <li
            class=""
            :style="[passwordChange === false ? 'display:none' : '']"
          >
            <a @click="userSettingURL(changePasswordLink)" class="label -window"
              >パスワード変更</a
            >
          </li>
        </ul>
      </nav>

      <div data-target="">
        <div v-if="isCurrentTab === 'confirmPlan'" class="item">
          <div class="container-box -member">
            <div v-if="errFlag === 1" class="inner">
              <ul class="list-option -nomargin -important">
                <li>
                  アプリで会員登録された方のメールアドレス、パスワード、課金プランの変更は、アプリの設定画面から行ってください。
                </li>
              </ul>
            </div>
            <div v-if="errFlag === 2" class="inner">
              <ul class="list-option -nomargin -important">
                <li>
                  {{ errMessage }}
                </li>
                <li>
                  解約される場合は、TOPページ下部「会員解約」より解約手続きの実施をお願い致します。
                </li>
              </ul>
            </div>

            <template v-for="(item, key) in myPlan" :key="key">
              <div class="inner">
                <table class="table-grid -padding -plan">
                  <tbody>
                    <tr>
                      <th>登録コース</th>
                      <td>{{ item.registrationCourse }}</td>
                    </tr>
                    <tr>
                      <th>決済方法</th>
                      <td>{{ item.paymentMethod }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
        </div>
        <div v-if="isCurrentTab === 'changePlan'" class="item">
          <div class="container-box -member">
            <div class="inner">
              <h2 class="heading-circle">パックコースへ変更</h2>
              <p>
                <em
                  >月額プランよりもプレミアムコースをお得にご利用いただけるパックコースが登場！</em
                ><br />
                <span class="_caption"
                  >※ご登録時に一括で期間分の利用料をお支払いいただきます。</span
                >
              </p>

              <p>
                登録月にお支払いとなりますので、ご注意ください。<br />
                パックコースについて、より詳しい説明は<a :href="packRuleLink"
                  >こちら</a
                >
              </p>
              <span
                @click="modalOpen = true"
                class="btn-basic -wide -center -imporntat"
                id="selectPack"
                >パックコースを選ぶ</span
              >
            </div>

            <div
              v-if="changeForCarrer === true"
              class="inner"
              id="change-for-career"
            >
              <h2 class="heading-circle">支払方法の変更</h2>
              <ul class="list-box -padding">
                <li>
                  <h3 class="heading-item _center">
                    クレジット決済を早期に変更する場合
                  </h3>
                  <p>
                    クレジット決済への変更をご希望のお客様は以下のボタンを押し、遷移先の画面よりクレジット決済の登録をお願いいたします。
                  </p>
                  <p class="_caption">
                    ※
                    キャリア決済と<strong>同月重複料金が発生いたします</strong>ので、ご注意ください。<br />
                    ※同月の重複料金が発生しないように、下記手順でのクレジット決済への変更を推奨します。
                  </p>
                  <a
                    @click="userSettingURL(changeForCarrerLink)"
                    class="btn-basic -wide -center"
                    >クレジット決済の同じコースへ変更</a
                  >
                </li>
                <li>
                  <h2 class="heading-item _center">
                    重複料金が発生しないように変更する場合
                  </h2>
                  <ol class="list-circlenumber">
                    <li>
                      下記メール会員入会ボタンよりクレジットカード情報の登録（メールアドレス登録）を月末までに行う。
                    </li>
                    <li>
                      ①まで行った後に、同月末までにサポートの会員解約より、キャリア決済を退会する。
                    </li>
                    <li>
                      翌月にクレジット決済（メールアドレス）でのログインを行い、クレジット決済にて入会する。
                    </li>
                  </ol>
                  <p class="p-caption">
                    ※お気に入り等の登録情報は引き継がれます。
                  </p>
                  <a
                    @click="userSettingURL(changeCreditMailLink)"
                    class="btn-basic -wide -center"
                    >クレジット決済のメール会員入会</a
                  >
                </li>
              </ul>
            </div>

            <div
              v-if="changeForCredit === true"
              class="inner"
              id="change-for-credit"
            >
              <h2 class="heading-circle">支払方法の変更</h2>
              <p>
                キャリア決済への変更をご希望のお客様は以下のボタンを押し、遷移先の画面よりキャリア決済の登録をお願いいたします。
              </p>
              <a
                @click="userSettingURL(changeForCreditLink)"
                class="btn-basic -wide -center"
                >キャリア決済の同じコースへ変更</a
              >
              <ul class="list-option">
                <li>
                  クレジット決済と同月重複料金が発生いたしますので、ご注意ください。
                </li>
                <li>お気に入り等の登録情報は引き継がれます。</li>
              </ul>
            </div>

            <div
              v-if="changeForCreditMail === true"
              class="inner"
              id="change-for-creditmail"
            >
              <h2 class="heading-circle">支払方法の変更</h2>
              <p>
                キャリア決済への変更をご希望のお客様は以下のボタンを押し、遷移先の画面よりキャリア決済の登録をお願いいたします。
              </p>
              <a
                @click="userSettingURL(changeCareerEconomyLink)"
                class="btn-basic -wide -center"
                >キャリア決済のエコノミーコースへ変更</a
              >
              <a
                @click="userSettingURL(changeCareerPremiumLink)"
                class="btn-basic -wide -center"
                >キャリア決済のプレミアムコースへ変更</a
              >
              <ul class="list-option">
                <li>
                  クレジット決済と同月重複料金が発生いたしますので、ご注意ください。
                </li>
                <li>お気に入り等の登録情報は引き継がれます。</li>
              </ul>
            </div>

            <div
              v-if="changeForCarrerMail === true"
              class="inner"
              id="change-for-careermail"
            >
              <h2 class="heading-circle">支払方法の変更</h2>
              <p>
                クレジット決済への変更をご希望のお客様は以下のボタンを押し、遷移先の画面よりクレジット決済の登録をお願いいたします。
              </p>
              <a
                @click="userSettingURL(changeCreditEconomyLink)"
                class="btn-basic -wide -center"
                >クレジット決済のエコノミーコースへ変更</a
              >
              <a
                @click="userSettingURL(changeCreditPremiumLink)"
                class="btn-basic -wide -center"
                >クレジット決済のプレミアムコースへ変更</a
              >
              <ul class="list-option">
                <li>
                  クレジット決済と同月重複料金が発生いたしますので、ご注意ください。
                </li>
                <li>お気に入り等の登録情報は引き継がれます。</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <choicePackCourseModal
      @modal-close="modalOpen = false"
      :modalOpen="modalOpen"
      :isPack03="isPack03"
      :isPack06="isPack06"
      :isPack12="isPack12"
    />
  </main>
</template>
