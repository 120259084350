<script setup>
import { ref, reactive, onMounted, onUpdated } from "vue";
import {HOST, JRA_WP_HOST} from "../../assets/js/define";
import {userSettingURL, getUserAgent, getCourseId} from "../../assets/js/common";
import axios from "axios";

// APIURL
const API_URL = JRA_WP_HOST + "wp-json/wp/v2/pages?slug=member";

let list = reactive({});
let isWebview = ref(false);
onMounted(() => {
  document.body.className = "";
  axios
    .get(API_URL)
    .then((response) => {
      Object.assign(list, response.data[0]);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });

    isWebview.value = getUserAgent()
    if (isWebview.value) callDart()
});

onUpdated(() => {
  const modal = initModal();

  let modalInner = document.getElementById("modal-inner");

  //不要な要素が存在するとモーダル先のボタンが反応しないための対策
  let elements = document.getElementsByClassName("for-modal");
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }

  let bPremiumTrial = document.getElementById("b-premium-trial");
  bPremiumTrial.addEventListener("click", function (e) {
    e.preventDefault();

    modal.show(
      "プレミアムコースお試し入会決済方法選択",
      modalInner.innerHTML,
      "joinCreditPremiumTrial",
      "joinPremiumTrial"
    );
  });

  let bEconomy = document.getElementById("b-economy");
  bEconomy.addEventListener("click", function (e) {
    e.preventDefault();
    modal.show(
      "エコノミーコース決済方法選択",
      modalInner.innerHTML,
      "joinCreditEconomy",
      "joinEconomy"
    );
  });

  let bPremium = document.getElementById("b-premium");
  bPremium.addEventListener("click", function (e) {
    e.preventDefault();
    modal.show(
      "プレミアムコース決済方法選択",
      modalInner.innerHTML,
      "joinCreditPremium",
      "joinPremium"
    );
  });

  let pack12 = document.getElementById("b-pack-12");
  pack12.addEventListener("click", function (e) {
    e.preventDefault();
    userSettingURL("joinCreditPack12");
  });
  let pack06 = document.getElementById("b-pack-06");
  pack06.addEventListener("click", function (e) {
    e.preventDefault();
    userSettingURL("joinCreditPack06");
  });
  let pack03 = document.getElementById("b-pack-03");
  pack03.addEventListener("click", function (e) {
    e.preventDefault();
    userSettingURL("joinCreditPack03");
  });

  let digitalCourse = document.getElementById("detail-course");
  digitalCourse.href = HOST + "member/pack_rule";
});

const callDart = () => {
  getCourseId() ? window.flutter_inappwebview.callHandler("subscription_upgrade") : window.flutter_inappwebview.callHandler("open_login_modal")
}

const initModal = () => {
  const layer = document.createElement("div");
  layer.classList.add("modal-layer");
  const frame = document.createElement("div");
  frame.classList.add("modal-frame");
  const content = document.createElement("div");
  content.classList.add("modal-content");

  const inner = document.createElement("div");
  inner.classList.add("modal-inner");
  const header = document.createElement("div");
  header.classList.add("modal-title", "heading-bar");
  const body = document.createElement("div");
  body.classList.add("modal-body");
  const close = document.createElement("span");
  close.classList.add("modal-close");

  layer.appendChild(frame);
  frame.appendChild(inner);
  inner.appendChild(content);
  inner.appendChild(close);
  content.appendChild(header);
  content.appendChild(body);

  document.body.appendChild(layer);
  document.body.appendChild(frame);

  inner.addEventListener("click", (e) => {
    e.stopPropagation();
  });
  frame.addEventListener("click", () => {
    document.body.classList.remove("status-modal");
    body.innerHTML = "";
  });
  close.addEventListener("click", () => {
    document.body.classList.remove("status-modal");
    body.innerHTML = "";
  });

  function setClose() {
    const closeButton = document.getElementsByClassName("close-modal");
    if (closeButton.length === 0) {
      return;
    }
    for (let i = 0; i < closeButton.length; i++) {
      closeButton[i].addEventListener("click", () => {
        document.body.classList.remove("status-modal");
        body.innerHTML = "";
      });
    }
  }

  return {
    show: (title, html, credit, career) => {
      if (title) {
        header.innerHTML = title;
        header.style.display = "block";
      } else {
        header.style.display = "none";
      }
      body.innerHTML = html;
      document.body.classList.add("status-modal");

      //モーダル先のイベント指定はこちらで実施
      let creditElement = document.getElementById("b-credit");
      let careerElement = document.getElementById("b-career");

      if (creditElement) {
        creditElement.addEventListener("click", function (e) {
          userSettingURL(credit);
        });
      }
      if (careerElement) {
        careerElement.addEventListener("click", function (e) {
          userSettingURL(career);
        });
      }

      setClose();
    },
    watchMovie: (url) => {
      body.innerHTML = '<video autoplay src="' + url + '"></video>';
      header.style.display = "none";
      frame.classList.add("-movie");
      document.body.classList.add("status-modal");
    },
    showImage: (url) => {
      body.innerHTML = '<img src="' + url + '">';
      header.style.display = "none";
      frame.classList.add("-image");
      document.body.classList.add("status-modal");
    },
    showHorseWeight: (title, url) => {
      if (title) {
        header.innerHTML = title;
        header.style.display = "block";
      } else {
        header.style.display = "none";
      }
      body.innerHTML = '<iframe src="' + url + '"></iframe>';
      frame.classList.add("-weightgraph");
      document.body.classList.add("status-modal");
    },
    showURL: (title, url) => {
      if (title) {
        header.innerHTML = title;
        header.style.display = "block";
      } else {
        header.style.display = "none";
      }
      const xhr = new XMLHttpRequest();
      xhr.open("get", url);
      xhr.send();
      xhr.addEventListener("readystatechange", () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          body.innerHTML = xhr.response;
          document.body.classList.add("status-modal");
        }
      });
    },
  };
};
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">会員登録</h1>
    </section>
    <div v-if="list.content && !isWebview" v-html="list.content.rendered"></div>
    <div v-else>
      <p>アプリ会員の方は以下のボタンから登録をお願いします。</p>
        <a
          class="btn-basic -wide -center -imporntat"
          @click="callDart()"
          >アプリ登録ページへ</a
        >
    </div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/member/style.scss"></style>
