
export const RacetrackCd = {
  '01' : '札幌',
  '02' : '函館',
  '03' : '福島',
  '04' : '新潟',
  '05' : '東京',
  '06' : '中山',
  '07' : '中京',
  '08' : '京都',
  '09' : '阪神',
  '10' : '小倉',
  '30' : '門別',
  '31' : '北見',
  '32' : '岩見',
  '33' : '帯広',
  '34' : '旭川',
  '35' : '盛岡',
  '36' : '水沢',
  '37' : '上山',
  '38' : '三条',
  '39' : '足利',
  '40' : '宇都',
  '41' : '高崎',
  '42' : '浦和',
  '43' : '船橋',
  '44' : '大井',
  '45' : '川崎',
  '46' : '金沢',
  '47' : '笠松',
  '48' : '名古屋',
  '49' : '紀三',
  '50' : '園田',
  '51' : '姫路',
  '52' : '益田',
  '53' : '福山',
  '54' : '高知',
  '55' : '佐賀',
  '56' : '荒尾',
  '57' : '中津',
  '58' : '札幌',
  '59' : '函館',
  '60' : '新潟',
  '61' : '中京',
  'A0' : '他外',
  'A2' : '日本',
  'A4' : 'アメ',
  'A6' : 'イギ',
  'A8' : 'フラ',
  'B0' : 'イン',
  'B2' : 'アイ',
  'B4' : 'ニュ',
  'B6' : 'オー',
  'B8' : 'カナ',
  'C0' : 'イタ',
  'C2' : 'ドイ',
  'C5' : 'オマ',
  'C6' : 'イラ',
  'C7' : '(ア)',
  'C8' : 'シリ',
  'D0' : 'スウ',
  'D2' : 'ハン',
  'D4' : 'ポル',
  'D6' : 'ロシ',
  'D8' : 'ウル',
  'E0' : 'ペル',
  'E2' : 'アル',
  'E4' : 'ブラ',
  'E6' : 'ベル',
  'E8' : 'トル',
  'F0' : '韓国',
  'F1' : '中国',
  'F2' : 'チリ',
  'F8' : 'パナ',
  'G0' : '香港',
  'G2' : 'スペ',
  'H0' : '西独',
  'H2' : '南ア',
  'H4' : 'スイ',
  'H6' : 'モナ',
  'H8' : 'フィ',
  'I0' : 'プエ',
  'I2' : 'コロ',
  'I4' : 'チェ',
  'I6' : 'チェ',
  'I8' : 'スロ',
  'J0' : 'エク',
  'J2' : 'ギリ',
  'J4' : 'マレ',
  'J6' : 'メキ',
  'J8' : 'モロ',
  'K0' : 'パキ',
  'K2' : 'ポー',
  'K4' : 'パラ',
  'K6' : 'サウ',
  'K8' : 'キプ',
  'L0' : 'タイ',
  'L2' : 'ウク',
  'L4' : 'ベネ',
  'L6' : 'ユー',
  'L8' : 'デン',
  'M0' : 'シン',
  'M2' : '澳門',
  'M4' : '墺',
  'M6' : 'ヨル',
  'M8' : 'カタ'
}

export const accidentObject = {
  1: "取消",
  2: "除外",
  3: "除外",
  4: "中止",
  5: "失格",
}