<script setup>
import { ref, reactive, onMounted, computed, toRefs } from "vue";
import axios from "axios";
import moment from "moment";
import {JRA_WP_HOST} from "../../../assets/js/define";

//本番用 APIURL
const API_URL = JRA_WP_HOST +"wp-json/wp/v2/pages?slug=apppolicy";

let post = reactive({});
onMounted(() => {
  axios
    .get(API_URL)
    .then((response) => {
      Object.assign(post, response.data[0]);
    })
    .catch((error) => {
      console.log(error);
      document.body.className = "status-loaded";
    });

  document.body.className = "status-loaded";
});
</script>
<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">アプリケーション・プライバシーポリシー</h1>
    </section>

    <div class="body" v-if="post.content" v-html="post.content.rendered"></div>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
