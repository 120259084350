<script setup>
import {onMounted, watch, onServerPrefetch} from "vue";
import { useRoute } from "vue-router";
import useStore from "../../stores/feature-states"
import {storeToRefs} from "pinia";
import {fetch as fetchArticle} from "../../functions/feature/article";
import {
  hiddenNonMember,
  hiddenSugotoku,
  hiddenMonthlyWeb,
} from '../../../spa/assets/js/wordpress'

const store = useStore.useStoreFeatureArticle();
const { title, content } = storeToRefs(store);
const route = useRoute();

onServerPrefetch(async () => {
  await fetchArticle(route.query.slug);
})

watch(route, async (from, to) => {
  document.body.className = "";
  await fetchArticle(to.query.slug);
  document.body.className = "status-loaded";
})

onMounted(async () => {
  window.scrollTo(0,0);
  document.body.className = "";
  if(!store.hasArticle() || !store.isSameArticle(route.query.id)) {
    await fetchArticle(route.query.slug);
  }
  setAccordion();
  hiddenSugotoku();
  hiddenNonMember();
  hiddenMonthlyWeb();
  document.body.className = "status-loaded";
});

const setAccordion = () => {
  let dataAccordion = document.querySelectorAll(".data-accordion");

  dataAccordion.forEach((elem, index) => {
    let accordion = elem;
    let dataTrigger = elem.querySelector(".data-trigger");
    let dataTarget = elem.querySelector(".data-target");
    let boxHeight = dataTarget.offsetHeight;

    dataTarget.style.display = "none";
    dataTarget.style.overflow = "hidden";

    dataTrigger.addEventListener("click", (e) => {
      let trigger = e.target;
      if (accordion.classList.contains("-active")) {
        accordion.classList.remove("-active");
        trigger.classList.remove("-active");
        dataTarget.classList.remove("-active");
        slideUp(dataTarget, 2);
      } else {
        accordion.classList.add("-active");
        trigger.classList.add("-active");
        dataTarget.classList.add("-active");
        dataTarget.style.display = "block";
        slideDown(dataTarget, 5);
      }
    });
  });
};

const slideDown = (target, speed) => {
  let distance = 0;
  target.style.display = "block";
  target.style.height = "auto";
  let myheight = target.offsetHeight;
  target.style.height = 0 + "px";
  let myInterval = setInterval(function () {
    target.style.height = distance + "px";
    distance += (myheight - distance) / speed;
    if (distance >= myheight - 1) {
      clearInterval(myInterval);
      target.style.height = myheight + "px";
    }
  }, 10);
};

const slideUp = (target, speed) => {
  let distance = target.offsetHeight;
  let myInterval = setInterval(function () {
    distance -= distance / speed;
    target.style.height = distance + "px";
    if (distance <= 1) {
      clearInterval(myInterval);
      target.style.height = 0 + "px";
    }
  }, 10);
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">{{ title }}</h1>
    </section>
    <div v-html="content"></div>
    <section class="layout-section -bordertop">
      <div class="nav-group">
        <a class="btn-basic -prev" href="/feature/">
          <span>特集ページ一覧へ</span>
        </a>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<style lang="scss" src="../../assets/css/feature/style.scss" scoped></style>
