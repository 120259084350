import { HOST, PATH, BACKWORDPATH } from "../../assets/js/define";

import { SUCCESS } from "../../assets/js/common";
import { getJSON, isNar } from "../../assets/js/common";

export const loadBasicInfo = async (dor, racetrackCd, raceNum) => {
  //ユーザのコース情報を取得 #4999ブックマーク対策
  let param = "";
  try {
    //パラメータからAPIコールを生成
    raceNum = ("0" + raceNum).slice(-2);
    const req =
      HOST +
      PATH +
      "RaceInfo/raceBasicInfo.js" +
      BACKWORDPATH +
      "?dor=" +
      dor +
      "&racetrackCd=" +
      racetrackCd +
      "&raceNum=" +
      raceNum +
      "&narFlag=" +
      isNar();
    await getJSON(req, true, function (status, json) {
      if (status == SUCCESS) {
        param = json[0];
      }
    });
    return param;
  } catch (e) {
    console.log(e)
  }
};

// オッズ値表示用HTMLを生成
function genValueShow(val) {
  if (100.0 < parseFloat(val)) {
    return "txt-odds";
  } else if (10.0 < parseFloat(val)) {
    return "txt-odds -no2";
  } else {
    return "txt-odds -no1";
  }
}

// キー配列のソート用ファンクション
export function compKey(a, b) {
  let keyA = a.split("_");
  let keyB = b.split("_");

  for (let i = 0; i < keyA.length; i++) {
    keyA[i] = keyA[i].length < 2 ? "0" + keyA[i] : keyA[i];
  }

  for (let i = 0; i < keyB.length; i++) {
    keyB[i] = keyB[i].length < 2 ? "0" + keyB[i] : keyB[i];
  }

  let strA = keyA.join("");
  let strB = keyB.join("");

  return parseInt(strA, 10) - parseInt(strB, 10);
}

// 単勝形式のキーセットを生成
export function genKeySetExacta(ar) {
  if (ar.length < 2) {
    return null;
  }

  let key = null;
  let keys = [];

  for (let i = 0; i < ar.length; i++) {
    let used = [i];
    for (let j = 0; j < ar.length; j++) {
      if (used.indexOf(j) < 0) {
        if (0 === i && 1 === j) {
          key = genKey([ar[i], ar[j]]);
        } else {
          keys.push(genKey([ar[i], ar[j]]));
        }
      }
    }
  }
  //オッズ照合の重複を削除
  keys = keys.filter((value, index, self) => self.indexOf(value) === index && key !== value);

  return { single: key, other: keys.sort(compKey) };
}

// 単勝形式のキー(番号指定順)を生成
function genKey(ar, prefix) {
  let key = ar.join("_");
  if (prefix) {
    key = prefix + key;
  }
  return key;
}

// 3連単のキーセットを生成
export function genKeySetTrifecta(ar) {
  if (ar.length < 3) {
    return null;
  }

  let key = null;
  let keys = [];

  for (let i = 0; i < ar.length; i++) {
    let used = [i];
    for (let j = 0; j < ar.length; j++) {
      if (used.indexOf(j) < 0) {
        used = [i, j];
        for (let k = 0; k < ar.length; k++) {
          if (used.indexOf(k) < 0) {
            if (0 == i && 1 == j && 2 == k) {
              key = genKey([ar[i], ar[j], ar[k]]);
            } else {
              keys.push(genKey([ar[i], ar[j], ar[k]]));
            }
          }
        }
      }
    }
  }

  return { single: key, other: keys.sort(compKey) };
}

export function sold(ref) {
  if (
    typeof ref.baseInfo == "object" &&
    typeof ref.baseInfo.SaleDiv == "number"
  ) {
    return 7 <= ref.baseInfo.SaleDiv ? true : false;
  }

  return false;
}

export function buildRow(o) {
  if (typeof o.value == "object" && "min" in o.value && "max" in o.value) {
    return {
      key: o.key,
      value: o.value.min !== '0.0' ?
          `<span class="${genValueShow(o.value.min) ?? 0}">${
          o.value.min ?? 0
        }</span>-<span class="${genValueShow(o.value.max) ?? 0}">${
          o.value.max ?? 0
        }</span> 倍` :
          '<span>未投票</span>',
    };
  } else {
    return {
      key: o.key,
      value: o.value !== '0.0' ?
          `<span class="${genValueShow(o.value)}">${o.value ?? 0}</span> 倍` :
          '<span>未投票</span>',
    };
  }
}
