<template>
  <div class="item">
    <div class="inner">
      <h2 class="heading-circle">コース</h2>
      <table class="table-cell -center">
        <tbody>
          <tr v-if="NARFLAG === 0">
            <th>着順</th>
            <th><span class="-ccw">東京</span></th>
            <th>中山</th>
            <th>京都</th>
            <th>阪神</th>
            <th><span class="-ccw">中京</span></th>
          </tr>
          <tr v-else-if="NARFLAG === 1">
            <th>着順</th>
            <th>大井</th>
            <th><span class="-ccw">川崎</span></th>
            <th><span class="-ccw">船橋</span></th>
            <th><span class="-ccw">浦和</span></th>
            <th>門別</th>
          </tr>
          <tr>
            <th class="bg-rank01">1着</th>
            <template v-if="NARFLAG === 0"
              ><td
                v-for="(results, index) in raceTrackData.RaceFirstRowJRA"
                :key="index"
              >
                {{ results.place1st }}
              </td></template
            >
            <template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRow"
                :key="index"
              >
                {{ results.place1st }}
              </td></template
            >
          </tr>

          <tr>
            <th class="bg-rank02">2着</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRowJRA"
                :key="index"
              >
                {{ results.place2nd }}
              </td></template
            >
            <template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRow"
                :key="index"
              >
                {{ results.place2nd }}
              </td></template
            >
          </tr>
          <tr>
            <th class="bg-rank03">3着</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRowJRA"
                :key="index"
              >
                {{ results.place3rd }}
              </td></template
            >
            <template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRow"
                :key="index"
              >
                {{ results.place3rd }}
              </td></template
            >
          </tr>
          <tr>
            <th>着外</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRowJRA"
                :key="index"
              >
                {{ results.unplaced }}
              </td></template
            >
            <template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRow"
                :key="index"
              >
                {{ results.unplaced }}
              </td></template
            >
          </tr>
          <tr>
            <th>勝率</th>
            <template v-if="NARFLAG === 0"
              ><td
                v-for="(results, index) in raceTrackData.RaceFirstRowJRA"
                :key="index"
              >
                {{ results.rate1st }}
              </td></template
            >
            <template v-else-if="NARFLAG === 1"
              ><td
                v-for="(results, index) in raceTrackData.RaceFirstRow"
                :key="index"
              >
                {{ results.rate1st }}
              </td></template
            >
          </tr>
          <tr>
            <th>連対率</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRowJRA"
                :key="index"
              >
                {{ results.rate2nd }}
              </td></template
            >
            <template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRow"
                :key="index"
              >
                {{ results.rate2nd }}
              </td></template
            >
          </tr>
          <tr>
            <th>３着内率</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRowJRA"
                :key="index"
              >
                {{ results.rate3rd }}
              </td></template
            ><template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceFirstRow"
                :key="index"
              >
                {{ results.rate3rd }}
              </td></template
            >
          </tr>
        </tbody>
      </table>
      <table class="table-cell -center">
        <tbody>
          <tr v-if="NARFLAG === 0">
            <th>着順</th>
            <th>札幌</th>
            <th>函館</th>
            <th>福島</th>
            <th><span class="-ccw">新潟</span></th>
            <th>小倉</th>
          </tr>
          <tr v-else-if="NARFLAG === 1">
            <th>着順</th>
            <th><span class="-ccw">盛岡</span></th>
            <th>水沢</th>
            <th>名古屋</th>
            <th>笠松</th>
            <th>園田</th>
          </tr>
          <tr>
            <th class="bg-rank01">1着</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceSecondRowJRA"
                :key="index"
              >
                {{ results.place1st }}
              </td></template
            ><template v-else-if="NARFLAG === 1"
              ><td
                v-for="(results, index) in raceTrackData.RaceSecondRow"
                :key="index"
              >
                {{ results.place1st }}
              </td></template
            >
          </tr>

          <tr>
            <th class="bg-rank02">2着</th>
            <template v-if="NARFLAG === 0"
              ><td
                v-for="(results, index) in raceTrackData.RaceSecondRowJRA"
                :key="index"
              >
                {{ results.place2nd }}
              </td></template
            >
            <template v-else-if="NARFLAG === 1"
              ><td
                v-for="(results, index) in raceTrackData.RaceSecondRow"
                :key="index"
              >
                {{ results.place2nd }}
              </td></template
            >
          </tr>
          <tr>
            <th class="bg-rank03">3着</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceSecondRowJRA"
                :key="index"
              >
                {{ results.place3rd }}
              </td></template
            >
            <template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceSecondRow"
                :key="index"
              >
                {{ results.place3rd }}
              </td></template
            >
          </tr>
          <tr>
            <th>着外</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceSecondRowJRA"
                :key="index"
              >
                {{ results.unplaced }}
              </td></template
            ><template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceSecondRow"
                :key="index"
              >
                {{ results.unplaced }}
              </td></template
            >
          </tr>
          <tr>
            <th>勝率</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceSecondRowJRA"
                :key="index"
              >
                {{ results.rate1st }}
              </td></template
            ><template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceSecondRow"
                :key="index"
              >
                {{ results.rate1st }}
              </td></template
            >
          </tr>
          <tr>
            <th>連対率</th>
            <template v-if="NARFLAG === 0"
              ><td
                v-for="(results, index) in raceTrackData.RaceSecondRowJRA"
                :key="index"
              >
                {{ results.rate2nd }}
              </td></template
            ><template v-else-if="NARFLAG === 1"
              ><td
                v-for="(results, index) in raceTrackData.RaceSecondRow"
                :key="index"
              >
                {{ results.rate2nd }}
              </td></template
            >
          </tr>
          <tr>
            <th>３着内率</th>
            <template v-if="NARFLAG === 0">
              <td
                v-for="(results, index) in raceTrackData.RaceSecondRowJRA"
                :key="index"
              >
                {{ results.rate3rd }}
              </td></template
            ><template v-else-if="NARFLAG === 1">
              <td
                v-for="(results, index) in raceTrackData.RaceSecondRow"
                :key="index"
              >
                {{ results.rate3rd }}
              </td></template
            >
          </tr>
        </tbody>
      </table>
      <table v-if="NARFLAG === 1" class="table-cell -center">
        <tbody>
          <tr>
            <th>着順</th>
            <th>姫路</th>
            <th>金沢</th>
            <th>高知</th>
            <th>佐賀</th>
            <th>帯広ば</th>
          </tr>
          <tr>
            <th class="bg-rank01">1着</th>
            <td
              v-for="(results, index) in raceTrackData.RaceThirdRow"
              :key="index"
            >
              {{ results.place1st }}
            </td>
          </tr>

          <tr>
            <th class="bg-rank02">2着</th>
            <td
              v-for="(results, index) in raceTrackData.RaceThirdRow"
              :key="index"
            >
              {{ results.place2nd }}
            </td>
          </tr>
          <tr>
            <th class="bg-rank03">3着</th>
            <td
              v-for="(results, index) in raceTrackData.RaceThirdRow"
              :key="index"
            >
              {{ results.place3rd }}
            </td>
          </tr>
          <tr>
            <th>着外</th>
            <td
              v-for="(results, index) in raceTrackData.RaceThirdRow"
              :key="index"
            >
              {{ results.unplaced }}
            </td>
          </tr>
          <tr>
            <th>勝率</th>
            <td
              v-for="(results, index) in raceTrackData.RaceThirdRow"
              :key="index"
            >
              {{ results.rate1st }}
            </td>
          </tr>
          <tr>
            <th>連対率</th>
            <td
              v-for="(results, index) in raceTrackData.RaceThirdRow"
              :key="index"
            >
              {{ results.rate2nd }}
            </td>
          </tr>
          <tr>
            <th>３着内率</th>
            <td
              v-for="(results, index) in raceTrackData.RaceThirdRow"
              :key="index"
            >
              {{ results.rate3rd }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="item">
      <div class="inner">
        <h2 class="heading-circle">牡・牝・セン</h2>
        <div id="graph-horse-sex"></div>
        <table class="table-cell -center">
          <tbody>
            <tr>
              <th>牡馬</th>
              <td>{{ sexData[0] }}</td>
            </tr>
            <tr>
              <th>牝馬</th>
              <td>{{ sexData[1] }}</td>
            </tr>
            <tr>
              <th>セン馬</th>
              <td>{{ sexData[2] }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="inner">
        <h2 class="heading-circle">馬齢</h2>
        <Chart v-if="ageGraph" :options="ageGraph" catchLegendEvents="true" />
        <table class="table-cell -center">
          <tbody>
            <tr>
              <th>馬齢</th>
              <th>実績</th>
              <th>勝率</th>
              <th>連対率</th>
              <th>3着内率</th>
            </tr>
            <tr v-for="(hotseago, index) in ageData" :key="index">
              <th>{{ hotseago.title }}</th>
              <td>
                {{ hotseago.totalRes }}<br />{{ hotseago.totalResDetail }}
              </td>
              <td>{{ hotseago.rate1st }}</td>
              <td>{{ hotseago.rate2nd }}</td>
              <td>{{ hotseago.rate3rd }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="NARFLAG === 0" class="inner">
        <h2 class="heading-circle">条件</h2>
        <Chart
          v-if="conditionGraph"
          :options="conditionGraph"
          catchLegendEvents="true"
        />
        <table class="table-cell -center">
          <tbody>
            <tr>
              <th>条件</th>
              <th>勝率</th>
              <th>連対率</th>
              <th>3着内率</th>
            </tr>
            <tr v-for="(conditions, index) in raceConditionData" :key="index">
              <th>
                {{ conditions.title }}
              </th>
              <td>{{ conditions.rate1st }}</td>
              <td>{{ conditions.rate2nd }}</td>
              <td>{{ conditions.rate3rd }}</td>
            </tr>
          </tbody>
        </table>
        <p class="_caption">※2006年6月17日以降の成績を集計</p>
      </div>

      <div class="inner">
        <h2 class="heading-circle">距離</h2>
        <Chart
          v-if="distanceGraph"
          :options="distanceGraph"
          catchLegendEvents="true"
        />
        <table class="table-cell -center">
          <tbody>
            <tr>
              <th>距離</th>
              <th>実績</th>
              <th>勝率</th>
              <th>連対率</th>
              <th>3着内率</th>
            </tr>
            <tr v-for="(result, index) in distanceData" :key="index">
              <th>{{ result.title }}</th>
              <td>{{ result.totalRes }}<br />{{ result.totalResDetail }}</td>
              <td>{{ result.rate1st }}</td>
              <td>{{ result.rate2nd }}</td>
              <td>{{ result.rate3rd }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
const { Chart } = process.browser ? require("highcharts-vue") : null;
</script>
<script>
export default {
  props: {
    raceTrackData: Object,
    sexData: Object,
    ageData: Object,
    raceConditionData: Object,
    distanceData: Object,
    ageGraph: Object,
    conditionGraph: Object,
    distanceGraph: Object,
    NARFLAG: Number,
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>
