<script setup>
import {onMounted, ref} from "vue";
import {isNar, setDismissDialog} from "../../assets/js/common";
let dialogChecked = ref(false);
const emit = defineEmits(["closeDialog"]);

const props = defineProps({
  modalAnniversaryOpen: Boolean,
});

const isNarView = ref(false);
onMounted(async () => {
  isNarView.value = isNar() === 1;
});

const modalClose = () => {
  //「今後このダイアログを表示しない」確認
  if (dialogChecked.value) {
    //ダイアログ非表示フラグON
    setDismissDialog();
  }
  emit("closeDialog", false);
};
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div
          v-if="modalAnniversaryOpen"
          class="modal-layer"
          style="display: block"
        ></div>
      </transition>
      <transition name="status-modal">
        <div @click.self="modalClose" v-if="modalAnniversaryOpen" class="modal-frame">
          <div class="modal-inner">
            <div class="modal-content">
              <div class="modal-title heading-bar" style="display: block">
                購入予定リストに追加しました。
              </div>
              <div class="modal-body">
                <div class="modal-message">
                  <p>
                    購入予定リストに追加しましたが、投票は完了しておりません。投票を完了するには、画面の「購入予定」から
                    <template v-if="isNarView">「IPAT/SPAT4送信」</template>
                    <template v-else>「IPAT送信」</template>
                    をタップしてください。
                  </p>
                  <div class="modal-dialog _center">
                    <div class="form-checkboxgroup">
                      <label
                        ><input
                          v-model="dialogChecked"
                          type="checkbox"
                          name="cat"
                        /><span>今後このダイアログを表示しない</span></label
                      >
                    </div>
                  </div>
                </div>
                <div class="modal-nav">
                  <span class="btn-basic close-modal" @click="modalClose"
                    >OK</span
                  ><a href="/purchase/" class="btn-basic">購入予定一覧へ</a>
                </div>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
</style>
