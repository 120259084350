<template>
  <div class="inner">
    <h2 class="heading-circle">購入ナンバー登録</h2>
    <div class="container-purchasenumber">
      <div class="field">
        <table>
          <tr>
            <th>ナンバー</th>
            <td>
              <div class="form-anniversary">
                <span class="form-select">
                  <select v-model="select1">
                    <option v-for="n in selectNum" :value="n" :key="n">
                      {{ n }}
                    </option>
                  </select>
                </span>
                <span class="arrow"></span>
                <span class="form-select">
                  <select v-model="select2">
                    <option v-for="n in selectNum" :value="n" :key="n">
                      {{ n }}
                    </option>
                  </select>
                </span>
                <span class="arrow"></span>
                <span class="form-select">
                  <select v-model="select3">
                    <option v-for="n in selectNum" :value="n" :key="n">
                      {{ n }}
                    </option>
                  </select>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <th>登録名</th>
            <td>
              <input
                v-model="addName"
                type="text"
                class="form-text"
                placeholder="登録名を入力してください"
              />
            </td>
          </tr>
        </table>
      </div>
      <nav class="nav-group">
        <span class="btn-basic -gray" @click="clear()">クリア</span>
        <span class="btn-basic -regist" @click="register()">登録</span>
      </nav>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { getJSON, getCookie, USERID, isNar } from "../../assets/js/common";
import {HOST, BACKWORDPATH, PATH} from "../../assets/js/define";
export default {
  setup(props, context) {
    const select1 = ref("1");
    const select2 = ref("1");
    const select3 = ref("1");
    const addName = ref("");
    const clear = () => {
      select1.value = "1";
      select2.value = "1";
      select3.value = "1";
      addName.value = "";
    };
    const register = () => {
      document.body.className = "";
      const userId = getCookie(USERID);
      if (userId == null || userId === "") {
        alert("登録できません");
      } else if (
        select1.value === select2.value ||
        select1.value === select3.value ||
        select2.value === select3.value
      ) {
        alert("同じ番号の組み合わせは登録できません");
      } else {
        const number =
          select1.value + "→" + select2.value + "→" + select3.value;
        const keyword = addName.value;
        const req =
          HOST +
          PATH +
          "MemorialTicket/memorialBettingTicketAddAPI.js" +
          BACKWORDPATH +
          "?userid=" +
          encodeURIComponent(userId) +
          "&number=" +
          number +
          "&keyword=" +
          keyword +
          "&narFlag=" +
          isNar()
        if (!select1.value || !select2.value || !select3.value) {
          alert("３連単を登録してください");
        } else if (!addName.value) {
          alert("登録名は必須です");
        } else {
          document.body.className = "";
          getJSON(req, true, function (status, json) {
            if (json != null && json.length > 0 && json[0] == 2) {
              alert("購入ナンバーは3件までしか登録できません");
            } else if (json[0] === 1) {
              alert("指定の購入ナンバーは既に登録されています");
            } else {
              context.emit("add");
            }
          });
          document.body.className = "status-loaded";
        }
      }
      document.body.className = "status-loaded";
    };
    return {
      register,
      clear,
      addName,
      select1,
      select2,
      select3,
    };
  },
  data () {
    return {
      selectNum: null,
    }
  },
  mounted() {
    this.selectNum = isNar() ? 16 : 18
  }
};
</script>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>
