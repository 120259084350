<template>
  <div>
    <div class="item">
      <div class="inner">
        <h2 class="heading-circle">G1実績一覧</h2>
        <nav class="nav-multi -g1win">
          <div v-if="!g1ResultData.length">G1実績はありません。</div>
          <ul v-else class="list">
            <li v-for="(g1Results, index) in g1ResultData" :key="index">
              <div v-if="Object.keys(g1Results.detail).length"
                   class="label"
                   @click="thisModalCreate(g1Results)">
                <span class="caption">{{ g1Results.name }}</span>
                <span class="main" :class="g1Results.winColor">{{
                    g1Results.totalRes
                  }}</span>
              </div>
              <div v-else class="label">
                <span class="caption">{{ g1Results.name }}</span>
                <span class="main">
                  0戦0勝
                </span>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <G1ResultsModal
      v-on:modal-close="modalOpen = false"
      :modalOpen="modalOpen"
      :g1DataDetails="g1DataDetails"
    ></G1ResultsModal>
  </div>
</template>

<script>
import G1ResultsModal from "../../utils/G1ResultsModal.vue";

export default {
  data() {
    return {
      g1ResultData: [],
      modalOpen: false,
      g1DataDetails: null,
    };
  },
  props: {
    g1Results: Array,
    g1RaceResultData: Object,
  },
  components: { G1ResultsModal },
  methods: {
    modal(modalOpen) {
      this.modalOpen = modalOpen;
    },
    thisModalCreate(g1Results) {
      this.modalOpen = true;
      this.g1DataDetails = g1Results;
    },
  },
  watch: {
    modalOpen: function (news, old) {},
  },
  setup(props) {
    return {
      props,
    };
  },
  created() {
    let self = this;

    let raceName = {
      "0020": "フェブラリーS",
      "0019": "高松宮記念",
      "0045": "大阪杯",
      "0001": "桜花賞",
      "0163": "中山グランドJ",
      "0002": "皐月賞",
      "0006": "天皇賞・春",
      "0017": "NHKマイルC",
      "0202": "ヴィクトリアM",
      "0003": "オークス",
      "0004": "日本ダービー",
      "0010": "安田記念",
      "0009": "宝塚記念",
      "0016": "スプリンターズ",
      "0018": "秋華賞",
      "0005": "菊花賞",
      "0007": "天皇賞・秋",
      "0011": "エリザベス女王杯",
      "0014": "マイルCS",
      "0015": "ジャパンC",
      "0200": "チャンピオンズC",
      "0013": "阪神ジュベナイルF",
      "0012": "朝日杯FS",
      "0152": "中山大障害",
      "0008": "有馬記念",
      "0135": "ホープフルS",
    };
    for (let key in raceName) {
      const g1Data = self.g1RaceResultData.find(val => val.raceCd === key)

      let data = {
        raceCd: key,
        name: raceName[key],
        winColor: "",
        totalRes: "0戦0勝",
        detail: {},
      }
      if (g1Data) {
        //戦績
        data.totalRes = g1Data.raceCounter + "戦" + g1Data.winCounter + "勝";

        //勝敗状況によって色を変える
        let winColor = "";
        if (g1Data.raceCounter != 0 && g1Data.winCounter != 0) {
          winColor = "-no1";
        } else if (g1Data.raceCounter != 0 && g1Data.winCounter === 0) {
          winColor = "-no2";
        }
        data.winColor = winColor

        let details = [];
        for (let j in g1Data.detail) {
          //実績詳細の馬番号を変換する
          let horseNum = "";
          if (
              g1Data.detail[j].horseNum === 1 ||
              g1Data.detail[j].horseNum === 2
          ) {
            horseNum = "waku01";
          } else if (
              g1Data.detail[j].horseNum === 3 ||
              g1Data.detail[j].horseNum === 4
          ) {
            horseNum = "waku02";
          } else if (
              g1Data.detail[j].horseNum === 5 ||
              g1Data.detail[j].horseNum === 6
          ) {
            horseNum = "waku03";
          } else if (
              g1Data.detail[j].horseNum === 7 ||
              g1Data.detail[j].horseNum === 8
          ) {
            horseNum = "waku04";
          } else if (
              g1Data.detail[j].horseNum === 9 ||
              g1Data.detail[j].horseNum === 10
          ) {
            horseNum = "waku05";
          } else if (
              g1Data.detail[j].horseNum === 11 ||
              g1Data.detail[j].horseNum === 12
          ) {
            horseNum = "waku06";
          } else if (
              g1Data.detail[j].horseNum === 13 ||
              g1Data.detail[j].horseNum === 14 ||
              g1Data.detail[j].horseNum === 15
          ) {
            horseNum = "waku07";
          } else if (
              g1Data.detail[j].horseNum === 16 ||
              g1Data.detail[j].horseNum === 17 ||
              g1Data.detail[j].horseNum === 18
          ) {
            horseNum = "waku08";
          }

          const detail = {
            dor: g1Data.detail[j].dor,
            horseName: g1Data.detail[j].horseName,
            horseStyle: horseNum,
            horseNum: g1Data.detail[j].horseNum,
            pedigreeNum: g1Data.detail[j].pedigreeNum,
            raceNum: g1Data.detail[j].raceNum,
            ranking: g1Data.detail[j].ranking,
            trackCd: g1Data.detail[j].trackCd,
            winOdds: g1Data.detail[j].winOdds,
            winPopularRank: g1Data.detail[j].winPopularRank,
            year: g1Data.detail[j].year,
            accidentCd: g1Data.detail[j].accidentCd,
          };

          details.push(detail);
        }
        data.detail = details
      }
      let g1 = self.g1ResultData;
      g1.push(data);
    }
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>