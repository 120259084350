<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getJSON,
  getUserId,
  changeFavoriteFromCookie,
  SUCCESS,
  prizeToString,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  REMOVE,
  FSTALLION,
  PAYINGNONE,
  retrieveCourse,
  isNar,
} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
  JOINPATH
} from "../../assets/js/define";

import FavTabs from "../../components/fav/FavTabs.vue";
import AutoRegistAbout from "../../components/utils/AutoRegistAbout.vue";
import useStore from "../../stores/favorite-states";
import {storeToRefs} from "pinia";

const currentTab = "stud";

let mCheckNum = 0; // API使用回数
let mTasks = [];
let mFavApiFailed = false; // API終了フラグ

// ユーザID
let mUserId = "";

let mInfo = null;

let favContentListAll = ref([]);
let favContentList = ref([]);
let favCheckList = ref([]);
let showFavList = ref([]);
const updateDate = ref("");
const narFlag = ref(false);
const isSugotoku = ref(false);

// もっと見る表示フラグ
const moreFlag = ref(false);
//削除ボタン表示フラグ
const favFlag = ref(false);

const loadedPage = ref(false);

const sortType = ref(0);
const activeSortId = ref("sort_name");
const moreLength = ref(0);

const store = useStore.useStoreFavoriteStud();
const {count} = storeToRefs(store);
const {resetList} = store;

onMounted(() => {
  callRetrieveCourse(function (courseId, showMarks, userId) {
    narFlag.value = isNar() === 1
    if (courseId === PAYINGNONE || courseId === PAYINGSUGOTOKU_NONE) {
      location.href = HOST + JOINPATH;
    } else {
      mUserId = userId || getUserId();
      isSugotoku.value = courseId === PAYINGSUGOTOKU || courseId === PAYINGSUGOTOKU_NONE
      doInit();
      document.body.className = "";
    }
  });
});

const callRetrieveCourse = function (callback) {
  try {
    retrieveCourse(callback);
  } catch (e) {
    callback("none");
  }
}

//自動登録機能モーダルの表示切り替え
const modalOpen = ref(false);
const thisModalUpdate = () => {
  modalOpen.value = true;
};

const doInit = () => {
  //データリセット
  moreLength.value = 0;
  favFlag.value = false;
  moreFlag.value = false;
  loadedPage.value = false;

  favContentListAll.value.splice(0);
  favContentList.value.splice(0);
  favCheckList.value.splice(0);
  showFavList.value.splice(0);

  let request =
      HOST +
      PATH +
      "MyFavorite/listMyFavoriteStallion.js" +
      BACKWORDPATH +
      "?" +
      "userId=" +
      encodeURIComponent(mUserId) +
      "&sort=" +
      sortType.value +
      "&narFlag=" +
      isNar();
  getJSON(request, true, function (status, json) {
    if (status === SUCCESS) {
      favContentListAll.value.splice(0);
      setData(json);
    }
    document.body.className = "status-loaded";
    loadedPage.value = true;
  });
};

// 表示用にデータを加工してオブジェクトに格納する
const setData = (json) => {
  try {
    if (json != null) {
      updateDate.value =json[0].LeadingUpdateDate

      for (let i = 0; i < json.length; i++) {
        let favList = {};
        // 名前
        favList.HorseName = json[i].HorseName;
        favList.BreedingNum = json[i].BreedingNum;

        //性別・馬齢
        favList.RacehorseDiv = json[i].RacehorseDiv;

        favList.YOB = json[i].YOB;

        // 収益合計
        let totalEarnings = "";
        if (json[i].TotalEarnings != null) {
          if (json[i].TotalEarnings == 0) {
            totalEarnings = "-";
          } else {
            totalEarnings = prizeToString(json[i].TotalEarnings);
          }
          favList.TotalEarnings = totalEarnings;
        } else {
          favList.TotalEarnings = totalEarnings;
        }

        // 収益
        let Earnings = "";
        if (json[i].Earnings != null) {
          if (json[i].Earnings == 0) {
            Earnings = "-";
          } else {
            Earnings = prizeToString(json[i].Earnings);
          }
          favList.Earnings = Earnings;
        } else {
          Earnings = "-";
          favList.Earnings = Earnings;
        }

        favContentListAll.value.push(favList);
        favCheckList.value.push(false);
      }
      showContents(2);
    }
  } catch (e) {}
};


//コンテンツを表示する
const showContents = (loadingId) => {
  favContentList.value.splice(0);
  showFavList.value.splice(0);
  const favlength = favContentListAll.value.length;
  if (Number(loadingId) === 2) {
    //全ての競走馬の表示
    for (let i = 0; i < favlength; i++) {
      favContentList.value.push(favContentListAll.value[i]);
    }
  } else {
    //現役馬・抹消馬の表示
    for (let i = 0; i < favlength; i++) {
      if (favContentListAll[i].RacehorseDiv === Number(loadingId)) {
        favContentList.value.push(favContentListAll.value[i]);
      }
    }
  }
  setFavList();
};

//表示用リストを描画
const setFavList = () => {
  showFavList.value = favContentList.value.slice(0, count.value);
  moreFlag.value = showFavList.value.length !== favContentList.value.length;
};

//もっと見るボタン押下
const showMore = () => {
  count.value += 30;
  setFavList();
}

//ソートを変更
const changeSort = (id) => {
  document.body.className = "";
  loadedPage.value = false;

  if (activeSortId.value === id) {
    // ソートIDが同じ場合は、逆順にする
    if (id === "sort_name") {
      if (sortType.value === 0) {
        sortType.value = 1;
      } else {
        sortType.value = 0;
      }
      activeSortId.value = "sort_name";
    } else if (id === "sort_age") {
      if (sortType.value === 3) {
        sortType.value = 2;
      } else {
        sortType.value = 3;
      }
      activeSortId.value = "sort_age";
    } else if (id === "sort_prize") {
      if (sortType.value === 5) {
        sortType.value = 4;
      } else {
        sortType.value = 5;
      }
      activeSortId.value = "sort_prize";
    }
  } else {
    // ソートIDが異なる場合は、選択したソートを降順でセットする
    if (id === "sort_name") {
      sortType.value = 0;
      activeSortId.value = "sort_name";
    } else if (id === "sort_age") {
      sortType.value = 3;
      activeSortId.value = "sort_age";
    } else if (id === "sort_prize") {
      sortType.value = 5;
      activeSortId.value = "sort_prize";
    }
  }
  doInit();

  document.body.className = "status-loaded";
};

// 「すべてを選択」クリック
const setAllSelect = (e) => {
  if (e.target.checked) {
    for (let i = 0; i < favCheckList.value.length; i++) {
      favCheckList.value[i] = true;
    }
    favFlag.value = true;
  } else {
    for (let i = 0; i < favCheckList.value.length; i++) {
      favCheckList.value[i] = false;
    }
    favFlag.value = false;
  }
};

//チェックリストクリック時更新
const setSelect = (index) => {
  favCheckList.value[index] = !favCheckList.value[index];
  moreLength.value = favCheckList.value.filter((n) => n === true).length;
  favFlag.value = moreLength.value > 0;
};

// 「削除」クリック
const deleteSelected = () => {
  try {
    document.body.className = "";

    if (favFlag.value === true) {
      mCheckNum = 0;
      mTasks = [];
      mFavApiFailed = false;

      // チェック済み項目の個数チェック
      for (let i = 0; i < favCheckList.value.length; i++) {
        if (favCheckList.value[i] && favContentList.value[i]) {
          mCheckNum++;
          mTasks.push({
            id: favContentList.value[i].BreedingNum,
            name: favContentList.value[i].HorseName,
          });
          const id = favContentList.value[i].BreedingNum;
          const name = favContentList.value[i].HorseName;
          const request =
            HOST +
            PATH +
            "MyFavorite/deleteMyFavoriteStallion.js" +
            BACKWORDPATH +
            "?" +
            "userID=" +
            encodeURIComponent(mUserId) +
            "&breedingNum=" +
            id;
          getJSON(
            request,
            false,
            function (status, json) {
              if (status == SUCCESS) {
                // cookieからお気に入り情報を削除
                changeFavoriteFromCookie(FSTALLION, id, REMOVE);
              } else {
                mFavApiFailed = true;
              }
              doInit();
            },
          );
        }
      }
    }

    document.body.className = "status-loaded";
  } catch (e) {
    console.log(e);
    document.body.className = "status-loaded";
  }
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -fav">
      <h1 class="heading-page">お気に入り</h1>
      <span class="btn-basic -tip" @click="thisModalUpdate()" v-if="!isSugotoku">
        自動登録機能について
      </span>
    </section>

    <FavTabs :currentTab="currentTab" />

    <section
      v-if="favContentListAll.length && loadedPage"
      class="layout-section"
    >
      <div class="layout-title">
        <h2 class="heading-circle -padding">種牡馬</h2>
      </div>

      <table class="table-grid -fit -middle -padding">
        <thead>
          <tr class="-center -middle">
            <th>
              <span class="form-checkbox -zen"
                ><label
                  ><input
                    @click="setAllSelect($event)"
                    type="checkbox"
                    value=""
                    name="" /><i></i></label
              ></span>
            </th>
            <th>
              種牡馬名<i
                class="filter-sort"
                :class="{ '-asc': sortType === 0, '-desc': sortType === 1 }"
                @click="changeSort('sort_name')"
              ></i>
            </th>
            <th>
              生年<i
                class="filter-sort"
                :class="{ '-asc': sortType === 2, '-desc': sortType === 3 }"
                @click="changeSort('sort_age')"
              ></i>
            </th>
            <th>
              獲得賞金<br />本年(通算)<i
                class="filter-sort"
                :class="{ '-asc': sortType === 4, '-desc': sortType === 5 }"
                @click="changeSort('sort_prize')"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(favContent, index) in showFavList"
            :key="index"
            :class="{ '-disable': favContent.RacehorseDiv === 1 }"
          >
            <td class="-center">
              <span class="form-checkbox"
                ><label
                  ><input
                    type="checkbox"
                    @click="setSelect(index)"
                    v-model="favCheckList[index]"
                    :value="favContent.BreedingNum"
                    :name="favContent.HorseName" /><i></i></label
              ></span>
            </td>
            <td>
              <router-link :to="'/data/stud?BreedingNum=' + favContent.BreedingNum">{{
                favContent.HorseName
              }}</router-link>
            </td>
            <td class="-center">{{ favContent.YOB }}</td>
            <td class="-center">
              {{ favContent.Earnings }}<br />({{ favContent.TotalEarnings }})
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="updateDate" class="paragraph-leading">
        {{ updateDate }}<br>
        <span v-if="narFlag">
          (成績集計期間: 2020年04月01日以降)
        </span>
      </p>

      <div class="nav-group -borderbottom">
        <span v-if="moreFlag" @click="showMore" class="btn-basic -more">もっと見る</span>
      </div>
      <nav class="nav-group -left">
        <span
          @click="deleteSelected"
          class="btn-basic -delete"
          :class="{ '-active': favFlag === true }"
          >削除</span
        >
      </nav>
    </section>
    <section v-else-if="loadedPage" class="layout-section">
      <div class="layout-title">
        <h2 class="heading-circle -padding">種牡馬</h2>
      </div>
      <div class="inner">
        <h3 class="heading-message">お気に入りは登録されていません。</h3>
        <p>
          気になるキーワードで検索してお気に入りに登録してください。<br />お気に入りに登録すると
        </p>
        <ul class="list-number">
          <li>お気に入り競走馬、騎手、調教師の出場レースが一目でわかる</li>
          <li>お気に入り競走馬、騎手、調教師が出場するレースをお知らせ</li>
        </ul>
      </div>
    </section>
    <AutoRegistAbout
      @modal-close="modalOpen = false"
      :modalOpen="modalOpen"
    ></AutoRegistAbout>

    <div v-if="!loadedPage" class="loading-anim">
      <div class="inner">
        <img src="../../assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/fav/style.scss" scoped></style>
