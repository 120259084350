<script setup>
import { ref, reactive, onMounted, watchEffect } from "vue";
import { setDismissDialog, isAccessFromAndroid2 } from "../../assets/js/common";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts from "highcharts";
HighchartsMore(Highcharts);

let dialogChecked = ref(false);
const emit = defineEmits(["closeDialog"]);
let chartWeight = reactive({});
const renWeightTitle = ref("");
let weightTable = reactive([]);
let keys = reactive([]);

const props = defineProps({
  modalOpen: Boolean,
  weightTableData: Object,
});

const modalClose = () => {
  //「今後このダイアログを表示しない」確認
  if (dialogChecked.value) {
    //ダイアログ非表示フラグON
    setDismissDialog();
  }
  emit("closeDialog", false);
};

// 斤量ごとのグルーピング
const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const getDisplayHorseNum = (horseNum) => {
  return horseNum === 99 ? null : horseNum
}

onMounted(() => {
  weightTable.push(groupBy(props.weightTableData, "Weight"));
  Object.assign(keys, Object.keys(weightTable[0]));
  //斤量を降順にソート
  keys.sort(function (a, b) {
    if (a > b) return -1;
    else if (b > a) return 1;
    else return 0;
  });
});
</script>
<template>
  <teleport to="body">
    <div>
      <transition>
        <div v-if="modalOpen" class="modal-layer" style="display: block"></div>
      </transition>
      <transition name="status-modal">
        <div @click.self="modalClose" v-if="modalOpen" class="modal-frame">
          <div class="modal-inner">
            <div class="modal-content">
              <div
                v-if="renWeightTitle"
                class="modal-title heading-bar"
                style="display: block"
              >
                {{ renWeightTitle }}
              </div>
              <div class="modal-title heading-bar" style="display: block">
                斤量順表示
              </div>
              <div class="modal-body">
                <div class="container-handicap">
                  <template v-for="(value, key) in keys" :key="key"
                    ><h2 class="heading-circle">
                      {{ (value / 10).toFixed(1) }}
                    </h2>
                    <table class="table-cell -middle -center -handicap">
                      <tr v-for="data in weightTable[0][value]">
                        <td
                          class="umaban"
                          :class="'bgcolor-waku0' + data.BracketNum"
                        >
                          {{ getDisplayHorseNum(data.HorseNum) }}
                        </td>
                        <td class="name -left">{{ data.HorseName }}</td>
                        <td v-if="data.SexCd === '1'" class="age">
                          牡{{ data.Age }}
                        </td>
                        <td v-else-if="data.SexCd === '2'" class="age">
                          牝{{ data.Age }}
                        </td>
                        <td v-else-if="data.SexCd === '3'" class="age">
                          セン{{ data.Age }}
                        </td>
                      </tr>
                    </table>
                  </template>
                </div>
              </div>
            </div>
            <span @click="modalClose" class="modal-close"></span>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>
<style scoped>
.status-modal-enter-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.status-modal-leave {
  display: none;
}

/* .modal-body {
  height: 500px;
} */

.modal-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.layout-section {
  height: 500px !important;
}

.page-hourseweight {
  height: 500px;
  min-height: 500px;
}

.highcharts-container {
  margin: 0 auto;
}
</style>
