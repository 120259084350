import {reactive, ref} from 'vue'
import { defineStore } from 'pinia'

export const useStoreRaceCommon = defineStore('race-common', () => {
  const raceInfo = ref({});
  const formatDay = ref("");
  const raceTrackCdDisplay = ref("")
  const raceNumDisplay = ref("")
  const resultsURL = ref("");
  const oddsInquireURL = ref("");
  const weightTableData = ref([]);
  const raceURL = ref("");
  const courseURL = ref("");
  const gradeIcon = ref("");
  const fullgateCount = ref("");
  const methodVal = ref("");
  const raceDORVal = ref("");
  const raceNumVal = ref("");
  const raceTrackCdVal = ref("");
  const paramsVal = ref({});
  const deadLineVal = ref(false);
  const entryNumVal = ref("");
  const oddsExists = ref(false);
  const racePrev = ref("");
  const raceNext = ref("");

  const reset = () => {
    Object.assign(raceInfo.value, {});
    formatDay.value = "";
    raceTrackCdDisplay.value = ""
    raceNumDisplay.value = ""
    resultsURL.value = "";
    oddsInquireURL.value = "";
    weightTableData.value?.splice(0, weightTableData.value.length);
    raceURL.value = "";
    courseURL.value = "";
    gradeIcon.value = "";
    fullgateCount.value = "";
    methodVal.value = "";
    raceDORVal.value = "";
    raceNumVal.value = "";
    raceTrackCdVal.value = "";
    Object.assign(paramsVal.value, {});
    deadLineVal.value = false;
    entryNumVal.value = "";
    oddsExists.value = false;
    racePrev.value = "";
    raceNext.value = "";
  }

  return {
    raceInfo,
    formatDay,
    raceTrackCdDisplay,
    raceNumDisplay,
    resultsURL,
    oddsInquireURL,
    weightTableData,
    raceURL,
    courseURL,
    gradeIcon,
    fullgateCount,
    methodVal,
    raceDORVal,
    raceNumVal,
    raceTrackCdVal,
    paramsVal,
    deadLineVal,
    entryNumVal,
    oddsExists,
    racePrev,
    raceNext,

    reset,
  }
})


export default {
  useStoreRaceCommon,
}