<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">出走馬名INDEX</h1>
    </section>

    <nav class="layout-title -hasborder">
      <h2 class="heading-circle -padding">
        {{ select ? select.name : "ア行" }}
      </h2>
      <div class="nav">
        <div v-if="select" class="form-select">
          <select v-model="select.id" @change="changeData(select.id)">
            <option disabled>馬名絞込み</option>
            <option value="ア">ア行</option>
            <option value="カ">カ行</option>
            <option value="サ">サ行</option>
            <option value="タ">タ行</option>
            <option value="ナ">ナ行</option>
            <option value="ハ">ハ行</option>
            <option value="マ">マ行</option>
            <option value="ヤ">ヤ行</option>
            <option value="ラ">ラ行</option>
            <option value="ワ">ワ行</option>
          </select>
        </div>
      </div>
    </nav>
    <div class="container-wrapper -pad">
      <div class="container-toggle" v-if="select">
        <ul class="list">
          <li v-for="(item, index) in select.data" :key="index">
            <a
              class="label -anchor"
              :class="[item.disable ? '-disable' : '']"
              @click="scroll('kana_' + index)"
            >
              {{ item.kana }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <section class="layout-section">
      <table v-if="select" class="table-grid -fit -padding">
        <thead>
          <tr class="-center header">
            <th>馬名</th>
            <th>性別/馬齢</th>
            <th>出走レース</th>
          </tr>
        </thead>
        <tbody v-if="select.data">
          <template v-if="consonantNum === 0">
            <tr :id="'kana_' + 0">
              <th colspan="3">{{ select.data[0].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[0].data" :key="k">
              <td>
                <a :href="content.pedigreeHref">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">
                <a :href="content.entryHref">{{ content.trackName }}</a>
              </td>
            </tr>

            <tr :id="'kana_' + 1">
              <th colspan="3">{{ select.data[1].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[1].data" :key="k">
              <td>
                <a :href="content.pedigreeHref">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">
                <a :href="content.entryHref">{{ content.trackName }}</a>
              </td>
            </tr>
            <tr :id="'kana_' + 2">
              <th colspan="3">{{ select.data[2].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[2].data" :key="k">
              <td>
                <a :href="content.pedigreeHref">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">
                <a :href="content.entryHref">{{ content.trackName }}</a>
              </td>
            </tr>

            <tr :id="'kana_' + 3">
              <th colspan="3">{{ select.data[3].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[3].data" :key="k">
              <td>
                <a :href="content.pedigreeHref">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">
                <a :href="content.entryHref">{{ content.trackName }}</a>
              </td>
            </tr>

            <tr :id="'kana_' + 4">
              <th colspan="3">{{ select.data[4].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[4].data" :key="k">
              <td>
                <a :href="content.pedigreeHref">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">
                <a :href="content.entryHref">{{ content.trackName }}</a>
              </td>
            </tr>
          </template>
          <template v-else-if="consonantNum === 1">
            <tr :id="'kana_' + 0">
              <th colspan="3">{{ select.data[0].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[0].data" :key="k">
              <td>
                <a :href="content.pedigreeHref">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">
                <a :href="content.entryHref">{{ content.trackName }}</a>
              </td>
            </tr>

            <tr :id="'kana_' + 1" v-if="!select.data[1].disable">
              <th colspan="3">{{ select.data[1].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[1].data" :key="k">
              <td>
                <a :href="content.pedigreeHref">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">
                <a :href="content.entryHref">{{ content.trackName }}</a>
              </td>
            </tr>
            <tr :id="'kana_' + 2" v-if="!select.data[2].disable">
              <th colspan="3">{{ select.data[2].kana }}</th>
            </tr>
            <tr v-for="(content, k) in select.data[2].data" :key="k">
              <td>
                <a :href="content.pedigreeHref">{{ content.name }}</a>
              </td>
              <td class="-center">{{ content.age }}</td>
              <td class="-center">
                <a :href="content.entryHref">{{ content.trackName }}</a>
              </td>
            </tr></template
          >
        </tbody>
      </table>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import {
  PAYINGNONE,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
  SUCCESS,
  getJSON,
  getCourse,
  isNar,
  getUrlVars,
} from "../../assets/js/common";
import {
  HOST,
  DATAPATH,
  RACEPATH,
  BACKWORDPATH,
  RACEINFOPATH,
} from "../../assets/js/define";
export default {
  data() {
    return {
      select: null,
      selected: "",
      data: null,
      mainKey: "ア",
      consonantNum: null,
      dor: "",
      env: {
        GYOU: {
          A: {
            id: "ア",
            name: "ア行",
            data: ["ア", "イ", "ウ", "エ", "オ"],
          },
          K: {
            id: "カ",
            name: "カ行",
            data: ["カ", "キ", "ク", "ケ", "コ"],
          },
          S: {
            id: "サ",
            name: "サ行",
            data: ["サ", "シ", "ス", "セ", "ソ"],
          },
          T: {
            id: "タ",
            name: "タ行",
            data: ["タ", "チ", "ツ", "テ", "ト"],
          },
          N: {
            id: "ナ",
            name: "ナ行",
            data: ["ナ", "二", "ヌ", "ネ", "ノ"],
          },
          H: {
            id: "ハ",
            name: "ハ行",
            data: ["ハ", "ヒ", "フ", "ヘ", "ホ"],
          },
          M: {
            id: "マ",
            name: "マ行",
            data: ["マ", "ミ", "ム", "メ", "モ"],
          },
          Y: {
            id: "ヤ",
            name: "ヤ行",
            data: ["ヤ", "ユ", "ヨ"],
          },
          R: {
            id: "ラ",
            name: "ラ行",
            data: ["ラ", "リ", "ル", "レ", "ロ"],
          },
          W: {
            id: "ワ",
            name: "ワ行",
            data: ["ワ", "ヲ", "ン"],
          },
        },
      },
    };
  },
  mounted() {
    const self = this;
    const params = getUrlVars();

    self.dor = params.DOR;
    self.getEntriesData(self.dor);
  },
  methods: {
    changeData(obj) {
      document.body.className = "";

      //セレクトボックスから選択した際に実行
      let self = this;
      if (obj) {
        self.mainKey = obj;
        self.getEntriesData(self.dor);
      }

      document.body.className = "status-loaded";
    },
    changeTargetNum() {
      //現在選択されている母音の配列の位置を返す
      let self = this;
      let targetNum = 0;
      if (self.mainKey === "ア") {
        targetNum = 0;
      } else if (self.mainKey === "カ") {
        targetNum = 1;
      } else if (self.mainKey === "サ") {
        targetNum = 2;
      } else if (self.mainKey === "タ") {
        targetNum = 3;
      } else if (self.mainKey === "ナ") {
        targetNum = 4;
      } else if (self.mainKey === "ハ") {
        targetNum = 5;
      } else if (self.mainKey === "マ") {
        targetNum = 6;
      } else if (self.mainKey === "ヤ") {
        targetNum = 7;
      } else if (self.mainKey === "ラ") {
        targetNum = 8;
      } else if (self.mainKey === "ワ") {
        targetNum = 9;
      }
      return targetNum;
    },
    getEntriesData(dor) {
      let self = this;
      let strDate = dor;
      let request =
        HOST +
        RACEINFOPATH +
        "entryListIndexViewAPI.js" +
        BACKWORDPATH +
        "?ymd=" +
        strDate +
        "&horsename=" +
        self.mainKey +
        "&narFlag=" +
        isNar();
      const useData = [];
      const messageFlag = true;
      Object.keys(self.env.GYOU).forEach((key) => {
        useData.push({
          id: self.env.GYOU[key].id,
          name: self.env.GYOU[key].name,
          data: [],
        });
      });
      getJSON(request, messageFlag, function (status, json) {
        if (status == SUCCESS) {
          if (json && json instanceof Array) {
            const data = json;
            const responseData = [];
            data.forEach((item) => {
              const kanaId = item.HorseKana.substring(0, 1);

              //性別+年齢
              const sexAge = item.Sex + item.Age;

              // 競走馬データリンク
              let pedigreeHref =
                HOST + DATAPATH + "horse?PedigreeNum=" + item.PedigreeNum;

              // 出馬表リンク
              let entryHref =
                HOST +
                RACEPATH +
                "card?DOR=" +
                strDate +
                "&RacetrackCd=" +
                item.RacetrackCd +
                "&RaceNum=" +
                item.RaceNum;
              if (
                getCourse() == PAYINGSUGOTOKU ||
                getCourse() == PAYINGSUGOTOKU_NONE
              ) {
                pedigreeHref = pedigreeHref.replace("?", "&");
                entryHref = entryHref.replace("?", "&");
                pedigreeHref = pedigreeHref.replace(
                  HOST,
                  location.pathname + "?url=" + HOST
                );
                entryHref = entryHref.replace(
                  HOST,
                  location.pathname + "?url=" + HOST
                );
              }

              //出走レース
              const raceTrack = item.RacetrackName + Number(item.RaceNum) + "R";

              responseData.push({
                id: kanaId,
                data: {
                  name: item.HorseName,
                  pedigreeHref: pedigreeHref,
                  age: sexAge,
                  trackName: raceTrack,
                  entryHref: entryHref,
                },
              });
            });

            Object.keys(self.env.GYOU).forEach((gyouKey) => {
              const gyou = self.env.GYOU[gyouKey];
              const gyouId = gyou.id;
              const gyouName = gyou.name;
              let record = useData.find(
                (useDataItem) => useDataItem.id === gyouId
              );
              if (!record) {
                record = {
                  id: gyouId,
                  name: gyouName,
                  data: [],
                };
                useData.push(record);
              }
              gyou.data.forEach((gyouDataId) => {
                const GyouData = {
                  id: gyouDataId,
                  name: gyouName,
                  kana: gyouDataId,
                  data: responseData
                    .filter((rData) => {
                      return rData.id === gyouDataId;
                    })
                    .map((rData) => rData.data),
                };

                if (gyouDataId === "ヲ" || gyouDataId === "ン") {
                  GyouData["disable"] = true;
                }

                record.data.push(GyouData);
              });
            });
          }
        }
      }).then(() => {
        self.data = useData;
        const targetId = self.changeTargetNum();
        if (useData[targetId].data.length === 3) {
          self.consonantNum = 1;
        } else {
          self.consonantNum = 0;
        }
        self.select =
          useData.length > 0
            ? useData[targetId]
            : {
                id: "ア",
                name: "ア行",
                data: [
                  { id: "ア", data: [] },
                  { id: "イ", data: [] },
                  { id: "ウ", data: [] },
                  { id: "エ", data: [] },
                  { id: "オ", data: [] },
                ],
              };
      });
      document.body.className = "status-loaded";
    },
    scroll(id) {
      const scrollY = window.scrollY || window.pageYOffset;
      const kanaPosition = document
        .getElementById(id)
        .getBoundingClientRect().top;
      window.scrollTo({
        top: kanaPosition + scrollY - 50,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" src="../../assets/css/info/style.scss" scoped></style>
