<template>
  <main class="layout-main">
    <RaceHeader :inquirePageFlag="true" />
    <div class="nav-checkodds"></div>

    <section class="layout-section -inquire">
      <div class="inner">
        <h2 class="heading-circle">オッズ照合</h2>
        <p>入線順にタップして下さい。(最大3つ)</p>
        <transition name="trans_slide">
          <div v-if="!onHide" class="checkodds-collation">
            <ul>
              <li v-for="index in Object.keys(horse)" :key="index">
                <i
                  v-bind:class="`icon-umaban -waku${(
                    '0' + parseInt(horse[index])
                  ).slice(-2)} ${
                    current.indexOf(index) !== -1 ? '-current' : ''
                  }`"
                  @click="chooseUmaban(index,horse[index])"
                  >{{ index }}</i
                >
              </li>
            </ul>
          </div>
        </transition>
        <div class="checkodds-nav">
          <ul class="checkodds-my">
            <li v-for="(value, index) in 3" :key="index">
              <i
                v-if="current.length >= index"
                v-bind:class="`icon-umaban -waku${(
                  '0' + parseInt(horse[current[index]])
                ).slice(-2)}`"
                >{{ current[index] }}</i
              >
              <i v-else></i>
            </li>
          </ul>

          <transition
            name="topSlide"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          >
            <div
              v-if="current.length > 0 && current.length < 3"
              class="checkodds-collate topSlide"
              :class="{ '-active': current.length > 0 && current.length < 3 }"
            >
              <span class="btn-basic -wide" @click="showResult()"
                >照合する</span
              >
            </div>
          </transition>
          <transition
            name="topSlide"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          >
            <div
              v-if="current.length > 0"
              class="checkodds-reset topSlide"
              :class="{ '-active': current.length > 0 }"
            >
              <span class="btn-basic -gray -wide" @click="clearResult()"
                >リセット</span
              >
            </div>
          </transition>
        </div>
      </div>

      <transition
        name="topSlide"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <section
          v-if="!onHide"
          class="checkodds-predict topSlide"
          :class="{ '-active': !onHide }"
        >
          <div class="layout-section">
            <p class="title">入線はこちらではありませんか？</p>
            <ul class="checkodds-my">
              <li v-for="(value, index) in 3" :key="index">
                <i
                  v-bind:class="`icon-umaban -waku${(
                    '0' + parseInt(horse[predict[index]])
                  ).slice(-2)}`"
                  >{{ predict[index] }}</i
                >
              </li>
            </ul>
            <div class="checkodds-collate -show">
              <span class="btn-basic -wide" @click="showPredict()">照合する</span>
            </div>
          </div>
        </section>
      </transition>

      <div class="inner checkodds-result" v-if="onHide">
        <div class="checkodds-collationlist">
          <ul>
            <li v-for="(data, index) in horseListData" :key="index">
              <i
                v-bind:class="`icon-umaban -waku${(
                  '00' + String(data.BracketNum)
                ).slice(-2)}`"
                >{{ data.HorseNum }}</i
              >{{ data.HorseName }}
            </li>
          </ul>
        </div>

        <p class="p-caption">
          ※ 最終オッズの照合結果であり、確定結果ではありません。
        </p>

        <section class="layout-sub">
          <table class="table-grid -middle -center -results">
            <tbody v-for="(odd, indexs) in oddsData ?? []" :key="indexs">
                <tr
                  v-for="(data, index) in odd.oddsList ?? []"
                  :key="index"
                  v-bind:class="`${
                    index !== odd.oddsList.length - 1 ? '-noborderbottom' : ''
                  }`"
                >
                  <td
                    v-if="index === 0 "
                    v-bind:class="`line -${camelToKebabCase(odd.oddsType)}`"
                    v-bind:rowspan="odd.oddsList.length"
                  >
                    {{ getTypes(odd.oddsType) }}
                  </td>

                  <td
                    class="-left"
                    v-if="typeof data.key == 'object' && data.key.length > 1"
                  >
                    <ul v-bind:class="getTypesClass(odd.oddsType)">
                      <li v-for="(key, indexss) in data.key ?? []" :key="indexss">
                        <i class="icon-umaban -waku">{{ key }}</i>
                      </li>
                    </ul>
                  </td>
                  <td class="-left" v-else>
                    <i class="icon-umaban -waku">
                      {{ data.key }}
                    </i>
                  </td>
                  <td class="-right" v-html="data.value"></td>
                </tr>
            </tbody>
          </table>
        </section>

        <section class="layout-sub">
          <h3 class="heading-item">関連オッズ</h3>
          <table class="table-grid -middle -center -results">
            <tbody v-for="(other, indexs) in otherData ?? []" :key="indexs">
              <tr
                v-for="(data, index) in other.otherList ?? []"
                :key="index"
                v-bind:class="`${
                  index != other.otherList.length - 1 ? '-noborderbottom' : ''
                }`"
              >
                <td
                  v-if="index == 0"
                  v-bind:class="`line -${camelToKebabCase(other.otherType)}`"
                  v-bind:rowspan="other.otherList.length"
                >
                  {{ getTypes(other.otherType) }}
                </td>
                <td
                  class="-left"
                  v-if="typeof data.key == 'object' && data.key.length > 1"
                >
                  <ul v-bind:class="getTypesClass(other.otherType)">
                    <li v-for="(key, indexss) in data.key ?? []" :key="indexss">
                      <i class="icon-umaban -waku">{{ key }}</i>
                    </li>
                  </ul>
                </td>
                <td class="-left" v-else>
                  <i class="icon-umaban -waku">
                    {{ data.key }}
                  </i>
                </td>
                <td class="-right" v-html="data.value"></td>
              </tr>
            </tbody>
          </table>
        </section>
        <p class="p-caption">
          ※
          出馬表・結果・成績・オッズなどのデータについては、必ず主催者発表のものと照合し確認してください。
        </p>

        <div class="checkodds-reset">
          <span class="btn-basic -gray -wide" @click="clearResult()"
            >リセット</span
          >
        </div>
      </div>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>
<script>
import RaceHeader from "../../components/race/RaceHeader.vue";
import { HOST } from "../../assets/js/define";
import {
  loadBasicInfo,
  compKey,
  genKeySetExacta,
  genKeySetTrifecta,
  sold,
  buildRow,
} from "./inquire";
import {
  PAYINGNONE,
  PAYINGSUGOTOKU,
  PAYINGSUGOTOKU_NONE,
} from "../../assets/js/common";
import {
  getJSON,
  getUrlVars,
  retrieveCourse,
  checkUrlVars,
  isNar,
  hasBracketExactaOdds,
} from "../../assets/js/common";

let raceParam = {}; // レース基本情報(DOR, raceTrackCd, raceNum)を取得
const urlApi = HOST + "webapi/Odds/inquireOdds.js/webapi?";
const urlApiLastInquire = HOST + "webapi/Odds/getLastInquireOdds.js/webapi?";
const urlResultFixed = HOST + "w/race/results?";
let cond = null;
let bNum = null;
let runHorseNum = null;

function genParam(p,b) {
  let ar = [];

  for (let k in raceParam) {
    if (k != "") ar.push(k + "=" + raceParam[k]);
  }

  if (typeof p == "object") {
    for (let k in p) {
      if (typeof p[k] == "object" && p[k] instanceof Array) {
        for (let i = 0; i < p[k].length; i++) {
          ar.push("hNum" + "=" + p[k][i]);
          ar.push("bNum" + "=" + b[k][i]);
        }
      } else {
        ar.push("hNum" + "=" + p[k]);
        //ar.push("bNum" + "=" + b[k]);
      }
    }
    for(let k in p){
      ar.push("bNum" + "=" + b[k]);
    }
  }

  return ar.join("&");
}

// 枠連, 馬連、ワイド用のキーセットを生成
function genKeySetQuinella(ar, wantSingle) {
  if (ar.length < 2) {
    return null;
  }

  let keys = [];

  let newKey = null;

  for (let i = 0; i < ar.length; i++) {
    for (let j = 0; j < ar.length; j++) {
      if (i == j) {
        continue;
      }

      newKey = genQuinellaKey([ar[i], ar[j]]);
      if (keys.indexOf(newKey) < 0) {
        keys.push(newKey);
      }
    }
  }

  if (wantSingle) {
    return {
      single: keys[0],
      other: keys.slice(1).sort(compKey),
    };
  } else {
    return keys.sort(compKey);
  }
}

// リダイレクト
function redirect(urlBase) {
  let url = urlBase ? urlBase : urlResultFixed;
  if (false == /\?$/.test(url)) {
    url += "?";
  }

  let param = genParam();
  if (getCourse() == PAYINGSUGOTOKU || getCourse() == PAYINGSUGOTOKU_NONE) {
    param = param.replace("odds_inquire", "race_cards");
    location.href = location.pathname + "?" + param;
  } else {
    location.href = url + param;
  }
}

// 連勝形式のキー(番号がソートされている)を生成
function genQuinellaKey(ar) {
  ar.sort(function (a, b) {
    return a - b;
  });

  return ar.join("_");
}

// 表示するためのキー(馬番/枠番)を生成
function genKeyShow(str) {
  if (typeof str != "string" || !/_/.test(str)) {
    return str;
  }

  return str.split("_");
}

// apiから取得したデータを表示用の整形
function buildOddsData(p, refs, horseList) {
  const hList = Object.keys(p).map(function (key) {
    return p[key];
  });
  const bList = Object.keys(bNum).map(function (key) {
    return bNum[key];
  });
  let odds = {
    win: [],
    place: [],
    bracket: [],
    bracketExacta: [],
    quinella: [],
    exacta: [],
    wide: [],
    trio: [],
    trifecta: [],
  };
  let oddsOther = {
    win: [],
    place: [],
    bracket: [],
    bracketExacta: [],
    quinella: [],
    exacta: [],
    wide: [],
    trio: [],
    trifecta: [],
  };

  // 単勝
  odds.win.push({ key: hList[0], value: refs.win[hList[0]] ?? 0, saleDiv: 1 });

  if (runHorseNum > 4) {
    // 複勝
    odds.place.push({
      key: hList[0],
      value: {
        min: refs.place["min" + hList[0]],
        max: refs.place["max" + hList[0]],
      },
      saleDiv: refs.place["baseInfo"].SaleDiv
    });
  }

  if (hList.length >= 2) {
    // 単勝
    oddsOther.win.push({ key: hList[1], value: refs.win[hList[1]] ?? 0 , saleDiv: 1 });

    if (runHorseNum > 4) {
      // 複勝
      odds.place.push({
        key: hList[1],
        value: {
          min: refs.place["min" + hList[1]],
          max: refs.place["max" + hList[1]],
        },
        saleDiv: refs.place["baseInfo"].SaleDiv
      });
    }

    // 馬連
    const baren = genKeySetQuinella(hList, true);
    if (baren) {
      odds.quinella.push({
        key: genKeyShow(baren.single),
        value: refs.quinella[baren.single],
        saleDiv: refs.quinella["baseInfo"].SaleDiv
      });

      for (let i = 0; i < baren.other.length; i++) {
        oddsOther.quinella.push({
          key: genKeyShow(baren.other[i]),
          value: refs.quinella[baren.other[i]],
          saleDiv: refs.quinella["baseInfo"].SaleDiv
        });
      }
    }

    // 馬単
    const singles = genKeySetExacta(hList);
    if (singles) {
      odds.exacta.push({
        key: genKeyShow(singles.single),
        value: refs.exacta[singles.single],
        saleDiv: refs.exacta["baseInfo"].SaleDiv
      });

      for (let i = 0; i < singles.other.length; i++) {
        oddsOther.exacta.push({
          key: genKeyShow(singles.other[i]),
          value: refs.exacta[singles.other[i]],
          saleDiv: refs.exacta["baseInfo"].SaleDiv
        });
      }
    }

    // ワイド
    const wides = genKeySetQuinella(hList, true);

    if (wides) {
      odds.wide.push({
        key: genKeyShow(wides.single),
        value: {
          min: refs.wide["min" + wides.single],
          max: refs.wide["max" + wides.single],
        },
        saleDiv: refs.wide["baseInfo"].SaleDiv
      });

      // ワイドは、とりあえずこの順で
      for (let i = 0; i < wides.other.length; i++) {
        odds.wide.push({
          key: genKeyShow(wides.other[i]),
          value: {
            min: refs.wide["min" + wides.other[i]],
            max: refs.wide["max" + wides.other[i]],
          },
          saleDiv: refs.wide["baseInfo"].SaleDiv
        });
      }
    }

    // 3連単
    const tri = genKeySetTrifecta(hList);
    if (tri) {
      odds.trifecta.push({
        key: genKeyShow(tri.single),
        value: refs.trifecta[tri.single],
        saleDiv: refs.trifecta["baseInfo"].SaleDiv
      });

      for (let i = 0; i < tri.other.length; i++) {
        oddsOther.trifecta.push({
          key: genKeyShow(tri.other[i]),
          value: refs.trifecta[tri.other[i]],
          saleDiv: refs.trifecta["baseInfo"].SaleDiv
        });
      }
    }

    if (3 <= hList.length) {
      // 単勝
      oddsOther.win.push({ key: hList[2], value: refs.win[hList[2]] ?? 0, saleDiv: 1 });

      // 複勝
      if (runHorseNum > 4) {
        if (runHorseNum > 7) {
          odds.place.push({
            key: hList[2],
            value: {
              min: refs.place["min" + hList[2]],
              max: refs.place["max" + hList[2]],
            },
            saleDiv: refs.place["baseInfo"].SaleDiv
          });
        } else {
          oddsOther.place.push({
            key: hList[2],
            value: {
              min: refs.place["min" + hList[2]],
              max: refs.place["max" + hList[2]],
            },
            saleDiv: refs.place["baseInfo"].SaleDiv
          });
        }
      }

      // 3連複
      const tri = genQuinellaKey(hList);
      odds.trio.push({ key: genKeyShow(tri), value: refs.trio[tri], saleDiv: refs.trio["baseInfo"].SaleDiv });
    }
  }

  // 枠連

  if (2 <= bList.length) {
    const waku = genKeySetQuinella(bList, true);
    if (waku && sold(refs.bracketquinella)) {
      odds.bracket.push({
        key: genKeyShow(waku.single),
        value: refs.bracketquinella[waku.single],
        saleDiv: refs.bracketquinella["baseInfo"].SaleDiv
      });

      for (let i = 0; i < waku.other.length; i++) {
        oddsOther.bracket.push({
          key: genKeyShow(waku.other[i]),
          value: refs.bracketquinella[waku.other[i]],
          saleDiv: refs.bracketquinella["baseInfo"].SaleDiv
        });
      }
    }
  }
  //枠単

  if (2 <= bList.length) {
    const waku = genKeySetExacta(bList, true);
    if (waku && sold(refs.bracketExacta)) {

      odds.bracketExacta.push({
        key: genKeyShow(waku.single),
        value: refs.bracketExacta[waku.single],
        saleDiv: refs.bracketExacta["baseInfo"].SaleDiv
      });

      for (let i = 0; i < waku.other.length; i++) {
        oddsOther.bracketExacta.push({
          key: genKeyShow(waku.other[i]),
          value: refs.bracketExacta[waku.other[i]],
          saleDiv: refs.bracketExacta["baseInfo"].SaleDiv
        });
      }
    }
  }
  return { odds: odds, other: oddsOther, horseList: horseList };
}

export default {
  name: "Inquire",
  data() {
    return {
      current: [],
      bNum: [],
      horse: {},
      predict: [],
      bPredict: [],
      onHide: false,
      horseListData: [],
      oddsData: [],
      otherData: [],
    };
  },
  components: { RaceHeader },
  methods: {
    getTypes(type) {
      if (type === "win") return "単勝";
      if (type === "place") return "複勝";
      if (type === "bracket") return "枠連";
      if (type === "bracketExacta") return "枠単";
      if (type === "quinella") return "馬連";
      if (type === "exacta") return "馬単";
      if (type === "wide") return "ワイド";
      if (type === "trio") return "３連複";
      if (type === "trifecta") return "３連単";
      return "";
    },
    getTypesClass(type) {
      if (
        type == "bracket" ||
        type == "quinella" ||
        type == "wide" ||
        type == "trio"
      )
        return "list-kaimoku";
      if (type == "exacta" || type == "trifecta" || type == "bracketExacta") return "list-kaimoku -arrow";
    },
    chooseUmaban(num, bNum) {
      let self = this;
      self.current.push(num);
      self.bNum.push(bNum);
      if (self.current.length > 0) {
      } else {
      }
      if (self.current.length === 3) {
        self.onHide = true;
        self.showResult();
      } else {
        self.onHide = false;
      }
    },
    showPredict() {
      let self = this;
      self.current = self.predict;
      self.bNum = self.bPredict;
      self.showResult();
    },
    clearResult() {
      let self = this;
      self.onHide = false;
      self.current = [];
      self.bNum = [];
    },
    showResult() {
      let self = this;
      if (self.current.length === 0) return;
      self.onHide = true;
      self.getResult();
    },
    getResult() {
      let self = this;
      cond = self.current;
      bNum = self.bNum;
      let url = urlApi + genParam(cond, bNum);
      url += "&narFlag=" + isNar();
      document.body.className = "";
      getJSON(url, false, function (stat, json) {
        const racetrackCd = json.odds.bracketExacta.baseInfo.RacetrackCd
        if(!hasBracketExactaOdds(racetrackCd)) json.odds.bracketExacta = {};
        // check status
        if (json.isNotFixedRaceFlag == 1) {
          const showData = buildOddsData(
            self.current,
            json.odds,
            json.horseList
          );

          // display it
          self.horseListData = showData.horseList;

          let odds = [];
          for (let oddsType in showData.odds) {
            let oddsList = [];
            if (showData.odds[oddsType].length > 0) {
              showData.odds[oddsType].forEach((value, index) => {
                if (value.saleDiv !== 0 && value.value) {
                  oddsList.push(buildRow(value));
                }
              });
            }
            odds.push({
              oddsType: oddsType,
              oddsList: oddsList,
            });
          }
          self.oddsData = odds;

          // 関連
          let other = [];
          for (let otherType in showData.other) {
            let otherList = [];
            if (0 < showData.other[otherType].length) {
              showData.other[otherType].forEach((value, index) => {
                if (value.saleDiv !== 0 && value.value) {
                  otherList.push(buildRow(value));
                }
              });
            }
            other.push({
              otherType: otherType,
              otherList: otherList,
            });
          }
          self.otherData = other;
        } else {
          redirect();
        }
        document.body.className = "status-loaded";
      });
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
    camelToKebabCase(oddsType) {
      return oddsType.replace(/[A-Z]/g, m => '-' + m.toLowerCase());
    },
    hasBracketExactaOdds,
  },
  mounted() {
    raceParam = getUrlVars();
    if (checkUrlVars(raceParam)) {
      let self = this;

      // 課金コース
      let mCourse = PAYINGNONE;

      // パラメータ取得
      let params = getUrlVars();

      const newDate = new Date();
      const year = newDate.getFullYear();
      const month = newDate.getMonth() + 1;
      const date = newDate.getDate();

      let DOR =
        String(year) +
        ("00" + String(month)).slice(-2) +
        ("00" + String(date)).slice(-2);
      let RacetrackCd = "06";
      let RaceNum = "09";

      if (params.DOR) {
        DOR = params.DOR;
      }
      if (params.RacetrackCd) {
        RacetrackCd = params.RacetrackCd;
      }
      if (params.RaceNum) {
        RaceNum = params.RaceNum;
      }

      callRetrieveCourse(function (courseId) {
        setLastInquire();
        doInit(DOR, RacetrackCd, RaceNum);
      });

      function callRetrieveCourse(callback) {
        try {
          retrieveCourse(callback);
        } catch (e) {
          callback("none");
        }
      }

      //フロント表示
      async function doInit(DOR, RacetrackCd, RaceNum) {
        try {
          //レース基本情報読み込み
          const basicInfo = await loadBasicInfo(DOR, RacetrackCd, RaceNum);
          if (basicInfo) {
            runHorseNum = basicInfo.EntryHorses.length;

            let horseData = {};
            for (let i in basicInfo.EntryHorses) {
              horseData[basicInfo.EntryHorses[i].HorseNum] =
                basicInfo.EntryHorses[i].BracketNum;
            }
            self.horse = horseData;
          }
        } catch (e) {
          console.log(e);
        }
      }

      function setLastInquire() {
        let url = urlApiLastInquire + genParam([]);
        document.body.className = "";
        getJSON(url, false, function (stat, json) {
          if (
            typeof json == "object" &&
            "hNum" in json &&
            3 <= json.hNum.length
          ) {
            self.predict = json.hNum;
            self.bPredict = json.bNum;
          }
          document.body.className = "status-loaded";
        });
      }
    } else {
      alert("パラメータが不正です。TOP画面からやり直してください。");
    }
  },
};
</script>

<style lang="scss" src="../../assets/css/race/odds/style.scss" scoped></style>
