import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreHorseData = defineStore('horse-data', () => {
  const horseData = ref(null)
  const auctionInfo = ref(null)
  const raceResult = ref(null)
  const news = ref([])
  const registerInfo = ref(null)
  const bestRecordData = ref(null)
  const narBestRecordData = ref(null)
  const pedigreeStatData = ref(null)
  const crossDegreeData = ref(null)
  const horseHairColor = ref(null)
  const offspringData = ref(null)
  const horsePedigreeNum = ref(null)
  const horseResultData = ref(null)
  const narHorseResultData = ref(null)
  const jraOutHorseResultData = ref(null)
  const specialEntryData = ref({})
  const nextRacesData = ref([])
  const videoUrlArray = ref([])
  const raceResultData = ref([])
  const isNotPremium = ref(false)
  const horseSymbol1 = ref('')
  const horseSymbol2 = ref('')
  const pogUrl = ref(null)
  const isPogHorse = ref(false)
  const loadingInfo = ref(false)
  const breedHorseURL = ref(null)
  const breedingTitle = ref(null)

  const reset = () => {
    horseData.value = null;
    auctionInfo.value = null;
    raceResult.value = null;
    news.value?.splice(0, news.value.length);
    registerInfo.value = null;
    bestRecordData.value = null;
    narBestRecordData.value = null;
    pedigreeStatData.value = null;
    crossDegreeData.value = null;
    horseHairColor.value = null;
    offspringData.value = null;
    horsePedigreeNum.value = null;
    horseResultData.value = null;
    narHorseResultData.value = null;
    jraOutHorseResultData.value = null;
    Object.assign(specialEntryData.value, {});
    nextRacesData.value?.splice(0, nextRacesData.value.length);
    videoUrlArray.value?.splice(0, videoUrlArray.value.length);
    raceResultData.value?.splice(0, raceResultData.value.length);
    isNotPremium.value = false;
    horseSymbol1.value = '';
    horseSymbol2.value = '';
    pogUrl.value = null;
    isPogHorse.value = false;
    loadingInfo.value = false;
    breedHorseURL.value = null;
    breedingTitle.value = null;
  }

  return {
    horseData,
    auctionInfo,
    raceResult,
    news,
    registerInfo,
    bestRecordData,
    narBestRecordData,
    pedigreeStatData,
    crossDegreeData,
    horseHairColor,
    offspringData,
    horsePedigreeNum,
    horseResultData,
    narHorseResultData,
    jraOutHorseResultData,
    specialEntryData,
    nextRacesData,
    videoUrlArray,
    raceResultData,
    isNotPremium,
    horseSymbol1,
    horseSymbol2,
    pogUrl,
    isPogHorse,
    loadingInfo,
    breedHorseURL,
    breedingTitle,
    reset,
  }
})

export default {
  useStoreHorseData,
}