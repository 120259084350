import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useStoreLeadingJockey = defineStore('leading-jockey', () => {
  const selectYear = ref(null);
  const selectArea = ref(0);
  const isWin = ref(true);
  const moneyFlag = ref(null);
  const count = ref(20);
  const isWeekOpen = ref([]);
  const isAllOpen = ref(false);
  const resetList = () => {
    count.value = 20;
    isWeekOpen.value = [];
    isAllOpen.value = false;
  }
  
  return { selectYear, selectArea, isWin, moneyFlag, count, isWeekOpen, isAllOpen, resetList }
})

export const useStoreLeadingWeightLossJockey = defineStore('leading-weight_loss_jockey', () => {
  const selectYear = ref(null);
  const selectArea = ref(0);
  const isWin = ref(true);
  const moneyFlag = ref(null);
  const count = ref(20);
  const isWeekOpen = ref([]);
  const isAllOpen = ref(false);
  const resetList = () => {
    count.value = 20;
    isWeekOpen.value = [];
    isAllOpen.value = false;
  }
  
  return { selectYear, selectArea, isWin, moneyFlag, count, isWeekOpen, isAllOpen, resetList }
})

export const useStoreLeadingTrainer = defineStore('leading-trainer', () => {
  const selectYear = ref(null);
  const selectArea = ref(0);
  const isWin = ref(true);
  const moneyFlag = ref(null);
  const count = ref(20);
  const isWeekOpen = ref([]);
  const isAllOpen = ref(false);
  const resetList = () => {
    count.value = 20;
    isWeekOpen.value = [];
    isAllOpen.value = false;
  }
  
  return { selectYear, selectArea, isWin, moneyFlag, count, isWeekOpen, isAllOpen, resetList }
})

export const useStoreLeadingOwner = defineStore('leading-owner', () => {
  const selectYear = ref(null);
  const isWin = ref(true);
  const moneyFlag = ref(null);
  const count = ref(20);
  const isWeekOpen = ref([]);
  const isAllOpen = ref(false);
  const resetList = () => {
    count.value = 20;
    isWeekOpen.value = [];
    isAllOpen.value = false;
  }
  
  return { selectYear, isWin, moneyFlag, count, isWeekOpen, isAllOpen, resetList }
})

export const useStoreLeadingBreeder = defineStore('leading-breeder', () => {
  const selectYear = ref(null);
  const isWin = ref(true);
  const moneyFlag = ref(null);
  const count = ref(20);
  const isWeekOpen = ref([]);
  const isAllOpen = ref(false);
  const resetList = () => {
    count.value = 20;
    isWeekOpen.value = [];
    isAllOpen.value = false;
  }
  
  return { selectYear, isWin, moneyFlag, count, isWeekOpen, isAllOpen, resetList }
})

export const useStoreLeadingStud = defineStore('leading-stud', () => {
  const selectYear = ref(null);
  const count = ref(20);
  const isWeekOpen = ref([]);
  const isAllOpen = ref(false);
  const resetList = () => {
    count.value = 20;
    isWeekOpen.value = [];
    isAllOpen.value = false;
  }
  
  return { selectYear, count, isWeekOpen, isAllOpen, resetList }
})

export const useStoreLeadingNewStud = defineStore('leading-new_stallion', () => {
  const selectYear = ref(null);
  const count = ref(20);
  const isWeekOpen = ref([]);
  const isAllOpen = ref(false);
  const resetList = () => {
    count.value = 20;
    isWeekOpen.value = [];
    isAllOpen.value = false;
  }
  
  return { selectYear, count, isWeekOpen, isAllOpen, resetList }
})

export const useStoreLeadingBms = defineStore('leading-bms', () => {
  const selectYear = ref(null);
  const count = ref(20);
  const isWeekOpen = ref([]);
  const isAllOpen = ref(false);
  const resetList = () => {
    count.value = 20;
    isWeekOpen.value = [];
    isAllOpen.value = false;
  }
  
  return { selectYear, count, isWeekOpen, isAllOpen, resetList }
})

export default {
  useStoreLeadingJockey,
  useStoreLeadingTrainer,
  useStoreLeadingWeightLossJockey,
  useStoreLeadingOwner,
  useStoreLeadingBreeder,
  useStoreLeadingStud,
  useStoreLeadingBms,
  useStoreLeadingNewStud,
}