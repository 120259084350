<template>
  <div class="container-tabtarget" data-target>
    <div class="item" v-if="displayTurf || displayDirt || displayBlock">
      <div v-if="displayTurf" class="inner">
        <h2 class="heading-circle">芝</h2>
        <section class="layout-sub" v-for="recordData in turfData">
          <h3 class="heading-bar">{{ recordData.name }}実績</h3>
          <table class="table-cell -center -middle" v-if="recordData.result.length">
            <tbody>
            <tr>
              <th>距離</th>
              <th>記録条件</th>
              <th>場所</th>
              <th>馬場</th>
              <th>タイム</th>
              <th>同条件下<br />1着タイム平均</th>
            </tr>
            <tr v-for="(item, index) in recordData.result" :key="index">
              <th class="text-center turf">{{ item.distance }}m</th>
              <td>{{ item.raceType }}{{ item.raceRegulation }}</td>
              <td>{{ item.raceTrack }}</td>
              <td>{{ item.trackCondition }}</td>
              <td>{{ item.bestTime }}</td>
              <td>{{ item.averageTime }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            競走実績はありません
          </div>
        </section>
      </div>
      <div v-if="displayDirt" class="inner">
        <h2 class="heading-circle">ダート</h2>
        <section class="layout-sub" v-for="recordData in dirtData">
          <h3 class="heading-bar">{{ recordData.name }}実績</h3>
          <table class="table-cell -center -middle" v-if="recordData.result.length">
            <tbody>
            <tr>
              <th>距離</th>
              <th>記録条件</th>
              <th>場所</th>
              <th>馬場</th>
              <th>タイム</th>
              <th>同条件下<br />1着タイム平均</th>
            </tr>
            <tr v-for="(item, index) in recordData.result" :key="index">
              <th class="text-center turf">{{ item.distance }}m</th>
              <td>{{ item.raceType }}{{ item.raceRegulation }}</td>
              <td>{{ item.raceTrack }}</td>
              <td>{{ item.trackCondition }}</td>
              <td>{{ item.bestTime }}</td>
              <td>{{ item.averageTime }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            競走実績はありません
          </div>
        </section>
      </div>
      <div v-if="displayBlock" class="inner">
        <h2 class="heading-circle">障害</h2>
        <section class="layout-sub" v-for="recordData in blockData">
          <h3 class="heading-bar">{{ recordData.name }}実績</h3>
          <table class="table-cell -center -middle" v-if="recordData.result.length">
            <tbody>
            <tr>
              <th>距離</th>
              <th>記録条件</th>
              <th>場所</th>
              <th>馬場</th>
              <th>タイム</th>
              <th>同条件下<br />1着タイム平均</th>
            </tr>
            <tr v-for="(item, index) in recordData.result" :key="index">
              <th class="text-center turf">{{ item.distance }}m</th>
              <td>{{ item.raceType }}{{ item.raceRegulation }}</td>
              <td>{{ item.raceTrack }}</td>
              <td>{{ item.trackCondition }}</td>
              <td>{{ item.bestTime }}</td>
              <td>{{ item.averageTime }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            競走実績はありません
          </div>
        </section>
      </div>
    </div>
    <div v-else class="item">
      <div class="inner">
        <div>持ちタイムはまだありません</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recordData: {},
      narRecordData: {},
      displayTurf: false,
      displayDirt: false,
      displayBlock: false,
      turfData: [],
      dirtData: [],
      blockData: [],
    };
  },
  props: {
    bestRecordData: Object,
    narBestRecordData: Object,
  },
  methods: {
    setData: function (recordData, isNar) {
      let result = [];
      if (recordData.length > 0) {
        result = this.setHorseTime(recordData);
      }
      return {
        name: isNar ? '地方/海外' : '中央',
        result: result
      };
    },
    setHorseTime: function (infos) {
      const array = [];
      let preDistance = "";
      let bestInfo;
      let newInfo = new Array();
      for (let m = 0; m < infos.length; m++) {
        if (preDistance !== infos[m].Distance && infos[m].BestTime !== '00') {
          preDistance = infos[m].Distance;
          if (bestInfo !== undefined) {
            newInfo.push(bestInfo);
          }
          bestInfo = infos[m];
        } else {
          if (bestInfo.BestTime > infos[m].BestTime && infos[m].BestTime !== '00') {
            bestInfo = infos[m];
          }
        }
      }
      if (bestInfo !== undefined) {
        newInfo.push(bestInfo);
      }

      for (let m = 0; m < newInfo.length; m++) {
        let bestTime = newInfo[m].BestTime;
        if (bestTime >= 5) {
          if (parseInt(bestTime.slice(0, bestTime.length - 3), 10) === 0) {
            bestTime = bestTime.slice(bestTime.length - 3, bestTime.length);
            bestTime =
                bestTime.slice(0, bestTime.length - 1) +
                "." +
                bestTime.slice(bestTime.length - 1, bestTime.length);
          } else {
            bestTime =
                parseInt(bestTime.slice(0, bestTime.length - 3), 10) +
                ":" +
                bestTime.slice(bestTime.length - 3, bestTime.length);
            bestTime =
                bestTime.slice(0, bestTime.length - 1) +
                "." +
                bestTime.slice(bestTime.length - 1, bestTime.length);
          }
        }
        let averageTime = newInfo[m].WinTimeAVG;
        let averageTime1 = String(parseInt(averageTime / 600));
        let averageTime2 = String(parseInt(averageTime % 600));
        let averageTime3 = "0";
        let raceClass = "";
        if (averageTime != 0 && newInfo[m].DOR > "20060617") {
          if (averageTime2.length == 3) {
            averageTime3 = averageTime2.slice(2);
            averageTime2 = averageTime2.slice(0, 2);
          } else if (averageTime2.length == 2) {
            averageTime2 = "0" + averageTime2;
            averageTime3 = averageTime2.slice(2);
            averageTime2 = averageTime2.slice(0, 2);
          }
          if (averageTime1.length == 1) {
            averageTime1 = "0" + String(averageTime1);
          }
          if (averageTime2.length == 1) {
            averageTime2 = "0" + String(averageTime2);
          }
          if (parseInt(averageTime1, 10) == 0) {
            averageTime = averageTime2 + "." + averageTime3;
          } else {
            averageTime = parseInt(averageTime1, 10) + ':' + averageTime2 + "." + averageTime3;
          }
          if (parseInt(newInfo[m].BestTime,10) < parseInt(averageTime1 + averageTime2 + averageTime3,10)) {
            raceClass = "first";
          }
        } else {
          averageTime = "-";
        }

        let records = {
          distance: newInfo[m].Distance,
          raceType: newInfo[m].RaceType,
          raceRegulation: newInfo[m].RaceRegulation,
          raceTrack: newInfo[m].Racetrack,
          trackCondition: newInfo[m].TrackCondition,
          bestTime: bestTime,
          averageTime: averageTime,
        };

        array.push(records);
      }
      return array;
    }
  },
  created() {
    this.turfData.push(this.setData(this.bestRecordData.GrassRecord, false));
    this.turfData.push(this.setData(this.narBestRecordData.GrassRecord, true));

    this.dirtData.push(this.setData(this.bestRecordData.DirtRecord, false));
    this.dirtData.push(this.setData(this.narBestRecordData.DirtRecord, true));

    this.blockData.push(this.setData(this.bestRecordData.BlockRecord, false));
    this.blockData.push(this.setData(this.narBestRecordData.BlockRecord, true));

    this.displayTurf = this.turfData[0].result.length || this.turfData[1].result.length;
    this.displayDirt = this.dirtData[0].result.length || this.dirtData[1].result.length;
    this.displayBlock = this.blockData[0].result.length || this.blockData[1].result.length;
  },
};
</script>

<style lang="scss" src="../../../assets/css/data/style.scss" scoped></style>