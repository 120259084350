<script setup>
import { ref, reactive, onMounted, computed, toRefs } from "vue";
import { HOST, JRA_WP_HOST } from "../../assets/js/define";
import axios from "axios";

const API_URL =
  JRA_WP_HOST + "wp-json/wp/v2/sale-cat?_fields=name,slug,id,acf,parent&parent=0";

let lists = reactive({});
onMounted(() => {
  document.body.className = "";
  const request_url = API_URL;
  axios
    .get(request_url)
    .then((response) => {
      processLink(response.data);
      document.body.className = "status-loaded";
    })
    .catch((error) => {
      document.body.className = "status-loaded";
    });
});

const processLink = (list) => {
  for (let i = 0; i < list.length; i++) {
    list[i].urlLink = HOST + "sales/list?slug=" + list[i].slug;
  }
  Object.assign(lists, list);
};
</script>

<template>
  <main class="layout-main">
    <section class="layout-pageheader -default">
      <h1 class="heading-page">セール特集一覧</h1>
    </section>

    <section class="layout-section">
      <div class="container-box">
        <div class="inner" v-for="(list, index) in lists" :key="index">
          <h2 class="heading-circle">{{ list.slug }} セレクトセール特集</h2>
          <ul class="list-feature">
            <li class="is-banner">
              <a :href="list.urlLink"
                ><img :src="list.acf.banner.url" alt=""
              /></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="loading-anim">
        <div class="inner">
          <img src="/assets/images/common/loading.svg" alt="" class="loading" />
        </div>
      </div>
    </section>
  </main>
</template>
