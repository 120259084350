<script setup>
import {onMounted} from "vue";
import {getDefaultPurchase, isNar} from '../../assets/js/common'
import {useRouter} from "vue-router";
const router = useRouter();

onMounted(() => {
  const url = getDefaultPurchase() === 'ipat' || isNar() !== 1 ? '/purchase/ipat' : '/purchase/spat4';
  router.replace(url);
});
</script>

<template>
  <div class="loading-anim">
    <div class="inner">
      <img src="/assets/images/common/loading.svg" alt="" class="loading" />
    </div>
  </div>
</template>

<style lang="scss" src="../../assets/css/purchase/style.scss" scoped></style>