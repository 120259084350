<script setup>
import {ref, onMounted} from "vue";
import {getJSON, isNar} from "../../assets/js/common";
import {HOST, PATH, BACKWORDPATH} from "../../assets/js/define";
import ButtonList from "../../components/leading/ButtonList.vue";
import RankingList from "../../components/leading/RankingList.vue";
import {useStoreLeadingOwner} from "../../stores/leading-states";
import {storeToRefs} from "pinia";

const updateDate = ref('');
const horseOwnerList = ref([]);
const years = ref([]);
const narFlag = ref(false);

const store = useStoreLeadingOwner();
const {selectYear, isWin, moneyFlag} = storeToRefs(store);
const {resetList} = store;

function loadData() {
  document.body.className = "";

  let request =
    HOST + PATH + "Leading/horseOwnerLeadingAPI.js" + BACKWORDPATH + "?";
  request +=
    "RaceYear=" +
    selectYear.value +
    "&MoneyFlag=" +
    moneyFlag.value +
    "&NarFlag=" +
    isNar();
  getJSON(request, true, function (status, json) {
    updateDate.value = json[0]?.LeadingUpdDate
    horseOwnerList.value = json[0]?.Leading || [];
  });

  document.body.className = "status-loaded";
}

function resetData() {
  resetList();
  loadData();
}

function changeWinSort(bool) {
  isWin.value = bool;
  moneyFlag.value = bool ? 0 : 1;
  resetData();
}

onMounted(() => {
  narFlag.value = isNar() === 1
  // 1ページに表示する要素数
  const current = new Date();

  let minYear = isNar() ? 2022 : 2003;
  for (let i = minYear; i <= current.getFullYear(); i++) {
    years.value.unshift(i);
  }

  selectYear.value = selectYear.value || current.getFullYear();

  loadData();
});
</script>

<template>
  <main class="layout-main">
    <button-list current="owner"></button-list>

    <section class="layout-section">
      <nav class="layout-title">
        <h2 class="heading-circle -padding">馬主</h2>
        <div class="nav">
          <div class="form-select">
            <select v-model="selectYear" @change="resetData">
              <option v-for="(year, index) in years" :key="index" :value="year">
                {{ year }}年
              </option>
            </select>
          </div>
        </div>
      </nav>

      <nav class="nav-sort">
        <ul>
          <li :class="{ '-current': isWin }">
            <span @click="changeWinSort(true)" class="label">勝利数順</span>
          </li>
          <li :class="{ '-current': !isWin }">
            <span @click="changeWinSort(false)" class="label">総賞金順</span>
          </li>
        </ul>
      </nav>

      <ranking-list ref="rankingList"
                    :leading-list="horseOwnerList"
                    :is-nar="narFlag"
                    :update-date="updateDate"
                    type="owner">
      </ranking-list>
    </section>
    <div class="loading-anim">
      <div class="inner">
        <img src="/assets/images/common/loading.svg" alt="" class="loading" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../../assets/css/leading/style.scss" scoped></style>
