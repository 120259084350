import {
  BACKWORDPATH,
  JRA_HOST,
  NAR_HOST,
  RACEINFOPATH,
} from "../../assets/js/define";
import {
  getJSON,
  replaceComma,
  SUCCESS,
} from "../../assets/js/common";
import { storeToRefs } from "pinia";
import {useStoreRaceResults} from "../../stores/race-results-states";

export async function fetch(dor, racetrackCd, raceNum, isNar) {
  const store = useStoreRaceResults();
  const {raceResultParams} = storeToRefs(store);

  raceResultParams.value = {dor, racetrackCd, raceNum, isNar}
  await Promise.all([
    raceResultDisplay(dor, racetrackCd, raceNum, isNar),
  ]);
}

const raceResultDisplay = async (dor, racetrackCd, raceNum, isNar) => {
  const store = useStoreRaceResults();
  const {
    raceResultList,
    raceRankingList,
    isDPointRace,
    returnNumber,
    cornerLastRank,
  } = storeToRefs(store);
  const host = isNar ? NAR_HOST : JRA_HOST;

  const request = `${host}${RACEINFOPATH}raceResult.js${BACKWORDPATH}?dor=${dor}&racetrackCd=${racetrackCd}&raceNum=${raceNum}&narFlag=${isNar ? "1" : "0"}`;
  await getJSON(request, true, function (status, json) {
    if (status !== SUCCESS) {
      return;
    }
    if (json?.length === 0) {
      raceResultList.value = [];
      return;
    }
    raceResultList.value = json;

    if (!isNar) {
      const accidentCdSort = ["0", "7", "4", "2", "3", "1", "5", "6", "8"]
      json[0].RaceRanking = json[0].RaceRanking.sort(function (a, b) {
        if(
          a.AccidentCd === b.AccidentCd ||
          a.AccidentCd === "7" ||
          b.AccidentCd === "7"
        ){
          return a.RaceRank - b.RaceRank
        }
        return accidentCdSort.indexOf(a.AccidentCd) - accidentCdSort.indexOf(b.AccidentCd)
      })
    }
    raceRankingList.value = json[0].RaceRanking;

    let useData = json[0];
    isDPointRace.value = useData.IsDPointRace

    if (useData.Dividend) {
      //払戻金表の出力
      displayDividendList(useData.Dividend);

      let reNum = ""; //返還馬番、枠番、同枠番
      //返還馬番
      if (
        useData.ReturnHorseNumInfo &&
        useData.ReturnHorseNumInfo.length
      ) {
        if (useData.ReturnHorseNumInfo[0].HorseNum !== "000000000000000000") {
          reNum += "返還馬番 ";
          for (let j = 0; j < 18; j++) {
            if (useData.ReturnHorseNumInfo[0].HorseNum.substr(j, 1) === "1") {
              reNum += j + 1 + "番 ";
            }
          }
        }
      }
      //返還枠番
      if (
        useData.ReturnBracketNumInfo &&
        useData.ReturnBracketNumInfo.length
      ) {
        if (useData.ReturnBracketNumInfo[0].BracketNum !== "00000000") {
          reNum += " 返還枠番 ";
          for (let j = 0; j < 8; j++) {
            if (
              useData.ReturnBracketNumInfo[0].BracketNum.substr(j, 1) === "1"
            ) {
              reNum += j + 1 + "枠 ";
            }
          }
        }
      }
      //返還同枠番
      if (
        useData.ReturnSameBracketInfo &&
        useData.ReturnSameBracketInfo.length
      ) {
        if (useData.ReturnSameBracketInfo[0].BracketNum !== "00000000") {
          reNum += " 返還同枠 ";
          for (let j = 0; j < 8; j++) {
            if (
              useData.ReturnSameBracketInfo[0].BracketNum.substr(j, 1) === "1"
            ) {
              reNum += j + 1 + "枠 ";
            }
          }
        }
      }
      returnNumber.value = reNum;
    }

    let no1;
    let no2;
    let no3;
    cornerLastRank.value = "";
    for (let j in useData.RaceRanking) {
      if (
        "HorseNum" in useData.RaceRanking[j] &&
        useData.RaceRanking[j].HorseNum !== ""
      ) {
        let rankData = useData.RaceRanking[j];
        rankData.WinPopularRank = '(' + rankData.WinPopularRank + '人気)'
        if (
          rankData.AccidentCd &&
          rankData.AccidentCd !== '0' &&
          rankData.AccidentCd !== '4' &&
          rankData.AccidentCd !== '5' &&
          rankData.AccidentCd !== '7'
        ) {
          rankData.WinOdds = '---.-'
          rankData.WinPopularRank = ''
        }
        if ("HorseNum" in rankData && rankData.HorseNum != null) {
          if (
            rankData.AccidentCd !== "1" &&
            rankData.AccidentCd !== "2" &&
            rankData.AccidentCd !== "3" &&
            rankData.AccidentCd !== "4"
          ) {
            let horseNum = rankData.HorseNum;
            switch (rankData.RaceRank) {
              case 1:
                no1 = horseNum;
                horseNum =
                  '<span class="txt-rank -no1">' +
                  rankData.HorseNum +
                  "</span>";
                break;
              case 2:
                no2 = horseNum;
                horseNum =
                  '<span class="txt-rank -no2">' +
                  rankData.HorseNum +
                  "</span>";
                break;
              case 3:
                no3 = horseNum;
                horseNum =
                  '<span class="txt-rank -no3">' +
                  rankData.HorseNum +
                  "</span>";
                break;
            }
            cornerLastRank.value += (j > 0 ? ',' : '') + horseNum;
          }
        }
      }
    }
    //1コーナー通過順位
    if (raceResultList.value[0].CornerRanking1st) {
      raceResultList.value[0].CornerRanking1st = createConnerText(
        raceResultList.value[0].CornerRanking1st,
        no1,
        no2,
        no3
      );
    }
    //2コーナー通過順位
    if (raceResultList.value[0].CornerRanking2nd) {
      raceResultList.value[0].CornerRanking2nd = createConnerText(
        raceResultList.value[0].CornerRanking2nd,
        no1,
        no2,
        no3
      );
    }
    //3コーナー通過順位
    if (raceResultList.value[0].CornerRanking3rd) {
      raceResultList.value[0].CornerRanking3rd = createConnerText(
        raceResultList.value[0].CornerRanking3rd,
        no1,
        no2,
        no3
      );
    }
    //4コーナー通過順位
    if (raceResultList.value[0].CornerRanking4th) {
      raceResultList.value[0].CornerRanking4th = createConnerText(
        raceResultList.value[0].CornerRanking4th,
        no1,
        no2,
        no3
      );
    }
    // showPageFlg.value = true;
  });
};

const displayDividendList = (dividend) => {
  const store = useStoreRaceResults();
  const {
    refunds,
    showPayBackFlg,
    bracketFlag,
    placeFlag,
  } = storeToRefs(store);
  refunds.value = {
    win: [],
    place: [],
    bracket: [],
    bracketExacta: [],
    quinella: [],
    wide: [],
    exacta: [],
    trio: [],
    trifecta: [],
  };
  for (let i = 0; i < dividend.length; i++) {
    const WinNum = dividend[i].WinNum;
    const refund = replaceComma(String(dividend[i].Refund * 1));
    const popular = dividend[i].PopularRanking;

    refunds.value[TOBMap(dividend[i].TOB)].push({ WinNum: WinNum, Refund: refund, Popular: popular });
  }
  if (refunds.value.bracket.length > 0) bracketFlag.value = true;
  if (refunds.value.place.length > 2) placeFlag.value = true;
  showPayBackFlg.value = true;
};

const TOBMap = (tob) => {
  const map = {
    '単勝': 'win',
    '複勝': 'place',
    '枠連': 'bracket',
    '枠単': 'bracketExacta',
    '馬連': 'quinella',
    'ワイド': 'wide',
    '馬単': 'exacta',
    "３連複": 'trio',
    "３連単": 'trifecta',
  }
  return map[tob] ?? null
}

const createConnerText = (text, first, second, third) => {
  text = text.replace(/\s.+$/, '');
  let forthCornerRank = text.split(/[\*,\(\)\-=]/);
  let forthCornerMark = text.split(/[0-9]*/);
  for (let i in forthCornerRank) {
    let rank_flg = false;
    if (forthCornerRank[i] === String(first)) {
      forthCornerRank[i] = '<span class="txt-rank -no1">' + first + "</span>";
      rank_flg = true;
    }
    if (!rank_flg) {
      if (forthCornerRank[i] === String(second)) {
        forthCornerRank[i] =
          '<span class="txt-rank -no2">' + second + "</span>";
        rank_flg = true;
      }
    }
    if (!rank_flg) {
      if (forthCornerRank[i] === String(third)) {
        forthCornerRank[i] = '<span class="txt-rank -no3">' + third + "</span>";
      }
    }
  }
  let forthText = "";
  for (let i = 0; i < forthCornerRank.length; i++) {
    let cornerRank = "";
    if (forthCornerRank[i] != null) {
      cornerRank = forthCornerRank[i];
    }
    let cornerMark = "";
    if (forthCornerMark[i] != null) {
      cornerMark = forthCornerMark[i];
    }
    if (text.substr(0, 1) === "(") {
      forthText += cornerRank + cornerMark;
    } else {
      forthText += cornerMark + cornerRank;
    }
  }
  return forthText;
};
