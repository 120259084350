<script setup>
import {ref, reactive, onMounted, watch} from "vue";
import {useRoute} from "vue-router";

import SetBetConfModal from "../../components/utils/SetBetConfModal.vue";
import OddsTabs from "../../components/odds/OddTabs.vue";
import RaceHeader from "../../components/race/RaceHeader.vue";

import TrifectaFormation from "../../components/odds/trifecta/Formation.vue";
import TrifectaPopularity from "../../components/odds/trifecta/Popularity.vue";
import TrifectaWheel1 from "../../components/odds/trifecta/Wheel1.vue";
import TrifectaWheel2 from "../../components/odds/trifecta/Wheel2.vue";
import TrifectaWheel3 from "../../components/odds/trifecta/Wheel3.vue";
import TrifectaWheel12 from "../../components/odds/trifecta/Wheel1_2.vue";
import TrifectaWheel13 from "../../components/odds/trifecta/Wheel1_3.vue";
import TrifectaWheel23 from "../../components/odds/trifecta/Wheel2_3.vue";
import TrifectatBox from "../../components/odds/trifecta/Box.vue";

import raceOdds from "../../functions/odds/race-odds";
import raceDeadline from "../../functions/odds/race-deadline_countdown";
import {getUrlVars, isNar, getJSON, SUCCESS} from "../../assets/js/common";
import {
  HOST,
  PATH,
  BACKWORDPATH,
} from "../../assets/js/define";

import {useStoreRaceCommon} from "../../stores/race-common-states";
import {storeToRefs} from "pinia";
const storeRaceCommon = useStoreRaceCommon();
const {
  raceDORVal,
  raceTrackCdVal,
  raceNumVal,
  deadLineVal,
} = storeToRefs(storeRaceCommon);

const { setRaceBetByArray } = raceOdds();
const { TimerCount } = raceDeadline();

let checkAllJudge = ref(false);
let raceBetCheck = ref(false);
let modalBetOpen = ref(false);
let oddsUpdInfo = ref("");
const checkedHorse = reactive({});
const currentTab = "trifecta";
const selected = ref("");
const selectedWheel = ref("流し");

let params = ref({});
const bracketFlag = ref(true);
let method = ref("");

// time
let time = ref("");

// 馬券種類タブ
const currentSubTab = ref("formation");

const route = useRoute();
watch(route, async (from, to) => {
  params.value = to.query;
  await fetchOdds(to.query);
});

onMounted(async () => {
  params.value = getUrlVars();
  await fetchOdds(params.value);
});

const fetchOdds = async (params) => {
  bracketFlag.value = true;
  if (params.method) {
    currentSubTab.value = params.method;
    method.value = params.method;
    selected.value = params.method.indexOf('wheel') !== -1 ? params.method :"";

    if (selected.value === "wheel_1") {
      selectedWheel.value = "1着軸流し";
    } else if (selected.value === "wheel_2") {
      selectedWheel.value = "2着軸流し";
    } else if (selected.value === "wheel_3") {
      selectedWheel.value = "3着軸流し";
    } else if (selected.value === "wheel_1_2") {
      selectedWheel.value = "1・2着軸流し";
    } else if (selected.value === "wheel_1_3") {
      selectedWheel.value = "1・3着軸流し";
    } else if (selected.value === "wheel_2_3") {
      selectedWheel.value = "2・3着軸流し";
    } else {
      selectedWheel.value = "流し";
    }
  }

  var req =
    HOST +
    PATH +
    "Odds/bracketQuinellaOdds.js" +
    BACKWORDPATH +
    "?DOR=" +
    params.DOR +
    "&RacetrackCd=" +
    params.RacetrackCd +
    "&RaceNum=" +
    params.RaceNum +
    "&NarFlag=" +
    isNar();
  await getJSON(req, true, function (status, json) {
    if (status == SUCCESS) {
      if (json[0].SaleDiv === 0) bracketFlag.value = false
    }
  });
}

const changeVoteTab = (data) => {
  const url = new URL(window.location);
  if (data === "wheel") {
    currentSubTab.value = selected.value;
    url.searchParams.set('method', selected.value);
    method.value = selected.value;

    if (selected.value === "wheel_1") {
      selectedWheel.value = "1着軸流し";
    } else if (selected.value === "wheel_2") {
      selectedWheel.value = "2着軸流し";
    } else if (selected.value === "wheel_3") {
      selectedWheel.value = "3着軸流し";
    } else if (selected.value === "wheel_1_2") {
      selectedWheel.value = "1・2着軸流し";
    } else if (selected.value === "wheel_1_3") {
      selectedWheel.value = "1・3着軸流し";
    } else if (selected.value === "wheel_2_3") {
      selectedWheel.value = "2・3着軸流し";
    }
  } else {
    selectedWheel.value = "流し";
    selected.value = "";
    currentSubTab.value = data;

    if (data !== "formation") {
      url.searchParams.set('method', data);
      method.value = data;
    } else {
      url.searchParams.delete('method');
      method.value = '';
    }
  }
  window.history.pushState({}, '', url);
};
</script>

<template>
  <main class="layout-main">
    <RaceHeader
      :method="method"
    ></RaceHeader>

    <!--  Odds Tabs -->
    <nav class="nav-multi">
      <OddsTabs v-if="Object.keys(params).length" :currentTab="currentTab" :params="params" :bracketFlag="bracketFlag"></OddsTabs>

      <div class="container-wrapper -pad">
        <div class="container-toggle -kinds">
          <ul>
            <li
              @click="changeVoteTab('formation')"
              class="-free"
              :class="[currentSubTab === 'formation' ? '-current' : '']"
            >
              <div class="label">フォーメーション</div>
            </li>
            <li
              @click="changeVoteTab('popular')"
              class="-popular"
              :class="[currentSubTab === 'popular' ? '-current' : '']"
            >
              <div class="label">人気順</div>
            </li>
            <li
              class="-nagashi"
              :class="[
                currentSubTab === 'wheel_1' ||
                currentSubTab === 'wheel_2' ||
                currentSubTab === 'wheel_3' ||
                currentSubTab === 'wheel_1_2' ||
                currentSubTab === 'wheel_1_3' ||
                currentSubTab === 'wheel_2_3'
                  ? '-current'
                  : '',
              ]"
            >
              <div class="label nav-pulldown">{{ selectedWheel }}</div>
              <select v-model="selected" @change="changeVoteTab('wheel')">
                <option disabled value="initial">選択</option>
                <option value="wheel_1">1着軸流し</option>
                <option value="wheel_2">2着軸流し</option>
                <option value="wheel_3">3着軸流し</option>
                <option value="wheel_1_2">1・2着軸流し</option>
                <option value="wheel_1_3">1・3着軸流し</option>
                <option value="wheel_2_3">2・3着軸流し</option>
              </select>
            </li>
            <li
              @click="changeVoteTab('box')"
              class="-box"
              :class="[currentSubTab === 'box' ? '-current' : '']"
            >
              <div class="label">ボックス</div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <TrifectaFormation
      v-if="currentSubTab === 'formation'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></TrifectaFormation>
    <TrifectaPopularity
      v-if="currentSubTab === 'popular'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></TrifectaPopularity>
    <TrifectaWheel1
      v-if="currentSubTab === 'wheel_1'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></TrifectaWheel1>
    <TrifectaWheel2
      v-if="currentSubTab === 'wheel_2'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></TrifectaWheel2>
    <TrifectaWheel3
      v-if="currentSubTab === 'wheel_3'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></TrifectaWheel3>
    <TrifectaWheel12
      v-if="currentSubTab === 'wheel_1_2'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></TrifectaWheel12>
    <TrifectaWheel13
      v-if="currentSubTab === 'wheel_1_3'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></TrifectaWheel13>
    <TrifectaWheel23
      v-if="currentSubTab === 'wheel_2_3'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></TrifectaWheel23>
    <TrifectatBox
      v-if="currentSubTab === 'box'"
      :raceDORVal="raceDORVal"
      :raceTrackCdVal="raceTrackCdVal"
      :raceNumVal="raceNumVal"
      :deadLineJudge="deadLineVal"
    ></TrifectatBox>
  </main>

  <SetBetConfModal
    v-on:closeDialog="modalBetOpen = false"
    :modalBetOpen="modalBetOpen"
  ></SetBetConfModal>
</template>

<style lang="scss" src="../../assets/css/race/odds/style.scss" scoped></style>
